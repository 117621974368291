import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Button, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap'
import ColoredModal from '../modals/ColorModal'
import LoaderComponent from '../../components/loader'
import { changePassword, verifyToken } from '../../redux/actions/register'

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!#%*?&]{8,20}$/,
      'Password should contain minimum 8 characters, at least one uppercase, one lowercase, one number and one special character.',
    ),
  verifyPassword: Yup.string()
    .required('Verify Password is Required')
    .test(
      'verifyPassword',
      "Ops! Passwords doesn't match, Please enter matching passwords.",
      function (value) {
        return this.parent.password === value
      },
    ),
})

const ChangePassword = () => {
  const { changePasswordSuccess, changePasswordError, errorMessage, verifySuccess, verifyError } =
    useSelector(({ register }) => register)
  const dispatch = useDispatch()
  const history = useNavigate()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [changeFlag, setChangeFlag] = useState('')

  useEffect(() => {
    handleVerifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (verifySuccess) {
      setLoading(false)
    }
  }, [verifySuccess])

  useEffect(() => {
    if (verifyError) {
      setLoading(false)
      // alert('Fail to verify token.')
      // dispatch(clearLoginFlags())
    }
  }, [verifyError])

  useEffect(() => {
    if (changePasswordSuccess) {
      // history(`/`)
      // dispatch({ type: 'CHANGE_PASSWORD_CLEAR' })
      // alert('Password changed')
      setChangeFlag(true)
    }
  }, [changePasswordSuccess])

  const handleVerifyToken = async () => {
    if (params?.token) {
      dispatch(verifyToken(params.token))
    }
  }

  const onCloseModal = () => {
    setChangeFlag(false)
    history(`/`)
    dispatch({ type: 'CHANGE_PASSWORD_CLEAR' })
  }

  const resetPassoword = (data) => {
    let dataValue = {
      password: data.password,
      verifyPassword: data.verifyPassword,
      token: params?.token,
    }
    dispatch(changePassword(dataValue))
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      verifyPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      resetPassoword(values)
    },
  })

  return (
    <React.Fragment>
      {loading ? <LoaderComponent /> : <></>}
      {!loading ? (
        <>
          {changePasswordError ? (
            <ColoredModal
              isOpen={changePasswordError}
              message={errorMessage}
              onClose={() => dispatch({ type: 'CHANGE_PASSWORD_CLEAR' })}
            />
          ) : (
            <></>
          )}
          {changeFlag ? (
            <ColoredModal
              type={'success'}
              isOpen={changeFlag}
              message={'Password has been changed successfully.'}
              onClose={onCloseModal}
            />
          ) : (
            <></>
          )}
          <div className="text-center mt-4">
            <h1 className="h2">Change password</h1>
            <p className="lead">Please enter new password</p>
          </div>
          <Card>
            <CardBody>
              <div className="m-sm-4">
                <Form>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formik.values.password}
                      className={`${formik.errors.password && formik.touched.password ? 'is-invalid' : ''} `}
                      onChange={(e) => formik.setFieldValue('password', e.target.value)}
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <div className="invalid-feedback error-text">{formik.errors.password}</div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label>Verify Password</Label>
                    <Input
                      bsSize="lg"
                      type="password"
                      name="verifyPassword"
                      placeholder="Verify Password"
                      value={formik.values.verifyPassword}
                      className={`${
                        formik.errors.verifyPassword && formik.touched.verifyPassword ? 'is-invalid' : ''
                      } `}
                      onChange={(e) => formik.setFieldValue('verifyPassword', e.target.value)}
                    />
                    {formik.errors.verifyPassword && formik.touched.verifyPassword ? (
                      <div className="invalid-feedback error-text">{formik.errors.verifyPassword}</div>
                    ) : null}
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button color="primary" size="lg" onClick={formik.handleSubmit}>
                      Change password
                    </Button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

export default ChangePassword

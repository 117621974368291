import React from 'react'
import { Outlet } from 'react-router-dom'

import Wrapper from 'components/Wrapper.js'
import Sidebar from 'components/sidebar/Sidebar.js'
import Main from 'components/Main.js'
import Navbar from 'components/navbar/Navbar.js'
import Content from 'components/Content.js'
import Footer from 'components/footer.js'

import dashboardItems from 'components/sidebar/dashboardItems.js'
import QuickScanModal from 'components/QuickScanModal'

const Dashboard = ({ children }) => {

  return(
  <React.Fragment>
    <QuickScanModal />  
    <Wrapper>
      <Sidebar items={dashboardItems} />
      <Main>
        <Navbar />
        <Content>
          {children}
          <Outlet />
        </Content>
        <Footer />
      </Main>
    </Wrapper>
    {/* <Settings /> */}
  </React.Fragment>
)}

export default Dashboard

import React from 'react'

const MobileScreenshot = ({ data, showDetail }) => {
  return (
    <>
      <div className="vr-detail-block">
        <h5>{data?.url}</h5>
        <p>
          {data?.mobileSnapShotTest?.data?.length
            ? 'Great ! We found snapshot of your webpage'
            : 'Ops! we are not able to find the snapshot of your webpage'}
        </p>
        {showDetail ? (
          data?.mobileSnapShotTest?.data?.length ? (
            <>
              {' '}
              <div style={{ marginBottom: 16 }}>
                {' '}
                <img width="25%" alt="mobile snapshot" src={data?.mobileSnapShotTest?.data} />{' '}
              </div>{' '}
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default MobileScreenshot

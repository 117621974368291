import { useEffect, useState } from 'react'
import { Card, Pagination, Table } from 'react-bootstrap'

import 'pages/dashboards/GenerateResearchTable.scss'

const TrafficAnalyticsTable = ({ analytics, redirectUrl }) => {
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1)
  const [websiteData, setWebsiteData] = useState([])

  useEffect(() => {
    const dataToDisplay =
      analytics?.oldReport?.rows?.length &&
      analytics?.oldReport?.rows?.map((obj) => {
        if (obj?.dimensionValues[2]?.value?.includes(redirectUrl)) {
          return {
            country: obj?.dimensionValues[0]?.value,
            sessionSource: obj?.dimensionValues[1]?.value,
            pagePath: obj?.dimensionValues[2]?.value,
            activeUsers: obj?.metricValues?.[0]?.value,
          }
        } else {
          const url = redirectUrl?.slice(0, redirectUrl?.length - 1)
          return {
            country: obj?.dimensionValues[0]?.value,
            sessionSource: obj?.dimensionValues[1]?.value,
            pagePath: `${url}${obj?.dimensionValues[2]?.value}`,
            activeUsers: obj?.metricValues?.[0]?.value,
          }
        }
      })

    setWebsiteData(dataToDisplay)
  }, [analytics])

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = websiteData?.length && websiteData?.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(websiteData?.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1)
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(pageNumber)
    }
  }

  let paginationItems = []
  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }
  } else {
    // More than 5 pages, handle ellipsis
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage <= 2) {
      endPage = 5
    } else if (currentPage >= totalPages - 1) {
      startPage = totalPages - 4
    }

    if (startPage > 2) {
      paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" disabled />)
    }

    // Pages
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }

    if (endPage < totalPages - 1) {
      paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" disabled />)
    }
  }

  return websiteData?.length ? (
      <Card>
        <Card.Header>
            <h3 className='f-bold'>Analytics based on active users traffic</h3>
            <hr />
        </Card.Header>
        <Card.Body>
        <Table striped responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Country</th>
              <th scope="col">Sessions</th>
              <th>page path</th>
              <th>Active users</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length &&
              currentItems?.map((item, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{item.country}</td>
                  <td>{item.sessionSource}</td>
                  <td>{item.pagePath}</td>
                  <td>{item.activeUsers}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Pagination className="research-data-pagination">
          <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
          {paginationItems}
          <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>{' '}
      </Card.Body>
      </Card>
    ) : null
}

export default TrafficAnalyticsTable

import React from 'react'

import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { Spinner } from 'reactstrap'

const Activities = () => {
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink)
  const item = usersDomain && activeLink !== undefined && usersDomain[activeLink]

  return (
    <Card className="flex-fill mb-3">
      <Card.Header>
        <Card.Title tag="h2" className="mb-0">
          Account Details
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <p className="my-1">
              <strong>Location</strong>
            </p>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[0]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[0] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>

        <hr className="my-2" />

        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <h6 className="my-1">
              <strong>Joined</strong>
            </h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[2]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[2] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>

        <hr className="my-2" />

        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <h6 className="my-1">
              <strong>Followers</strong>
            </h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[4]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[4] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>
        <hr className="my-2" />
        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <h6 className="my-1">
              <strong>Following</strong>
            </h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[5]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[5] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>
        <hr className="my-2" />
        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <h6 className="my-1">
              <strong>Followers Ratio</strong>
            </h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[6]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[6] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>
        <hr className="my-2" />
        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <h6 className="my-1">
              <strong>Tweets</strong>
            </h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[7]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[7] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>
        <hr className="my-2" />
        <div className="d-flex">
          <div className="flex-grow-1 ms-3">
            <h6 className="my-1">
              <strong>Listed</strong>
            </h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.valueData ? (
                        <>
                          {
                            item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                              ?.valueData?.[8]
                          }
                        </>
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.valueData?.[8] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default Activities

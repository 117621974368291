import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const ResponsiveImage = () => {
  return (
    <CommonFreeTool
      heading={freeTools.responsiveImageTest.heading}
      whatIsIT={freeTools.responsiveImageTest.what}
      howToFixBeforeCode={freeTools.responsiveImageTest.howSectionBeforeCode}
    />
  )
}

export default ResponsiveImage

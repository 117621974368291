import React from 'react'
import { CommonAlert } from 'components/uiCore'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const ContentfulPaint = ({ data }) => {
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.contentfulPaint?.data?.testResults?.status ? (
            ''
          ) : data?.contentfulPaint?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.contentfulPaint?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />

        <div>
          {' '}
          <p>{data?.contentfulPaint?.data?.testResults?.message}</p>{' '}
        </div>
      </div>
    </>
  )
}

export default ContentfulPaint

import * as Icon from 'react-feather'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import 'pages/free-trial/tests-components/TestInformationText.scss'

const TestInformationText = ({ message }) => {
  return (
    <>
      <div>
        {
          <p className="mb-0">
            {' '}
            <OverlayTrigger
              placement={'left'}
              overlay={
                <Tooltip bsPrefix="custom-tooltip" id={`tooltip-left`}>
                  {message}
                </Tooltip>
              }
            >
              <Icon.Info color={'#cfcfcf'} />
            </OverlayTrigger>
          </p>
        }
      </div>
    </>
  )
}

export default TestInformationText

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const ConsoleErrors = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [consoleErrorsFlag, setConsoleErrorsFlag] = useState(false)

  const columns = [
    {
      id: 'description',
      displayName: 'Description',
    },
    {
      id: 'source',
      displayName: 'Source',
    },
    {
      id: 'url',
      displayName: 'Url',
    },
  ]
  const consoleErrorCsv = data?.consoleErrors?.data?.consoleErrorsResults?.details?.items?.map(item => {
    return {
      Description: item?.description,
      Source: item?.source,
      Url: item?.sourceLocation?.url
    }
  })
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.consoleErrors?.data?.testResults?.status ? (
            ''
          ) : data?.consoleErrors?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.consoleErrors?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data?.consoleErrors?.data?.testResults?.message}</p>
        {showDetail && data?.consoleErrors?.data?.testResults?.status !== 1 && data?.consoleErrors?.data?.consoleErrorsResults?.details?.items?.length ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setConsoleErrorsFlag(!consoleErrorsFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={consoleErrorsFlag}
        toggleShow={() => setConsoleErrorsFlag(!consoleErrorsFlag)}
        title={'Historic Console Error Signal Check'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns={columns}
        datas={consoleErrorCsv}
        fileName='Historic Console Error Signal Check'
        alternativeDownload = {true}
      >
        <Table striped className="d-none d-sm-block">
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Description</th>
              <th style={{ width: '10%' }}>Source</th>
              <th style={{ width: '40%' }}>url</th>
            </tr>
          </thead>
          <tbody>
            {data?.consoleErrors?.data?.consoleErrorsResults?.details?.items?.length &&
              data?.consoleErrors?.data?.consoleErrorsResults?.details?.items.map((item, index) => {
                return (
                  <tr key={`ConsoleErrors-${index+1}`}>
                    <td>{item?.description}</td>
                    <td>{item?.source}</td>
                    <td>{item?.sourceLocation?.url}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {/*Start Historic Console Error Signal Check Mobile view */}
        <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
          {data?.consoleErrors?.data?.consoleErrorsResults?.details?.items?.length
            ? data?.consoleErrors?.data?.consoleErrorsResults?.details?.items.map((item) => (
                <div key={item.description}>
                  <li className="mb-3">
                    <p className="mb-0 f-bold">Description</p>
                    <p className="mb-2">{item.description}</p>
                  </li>
                  <li className="mb-3">
                    <p className="mb-0 f-bold">Source</p>
                    <p className="mb-2">{item.source}</p>
                  </li>
                  <li className="border-bottom mb-3">
                    <p className="mb-0 f-bold">url</p>
                    <p className="mb-2">{item?.sourceLocation?.url}</p>
                  </li>
                </div>
              ))
            : null}{' '}
        </ul>
        {/*End Historic Console Error Signal Check Mobile view */}
      </ModalHOC>
    </>
  )
}

export default ConsoleErrors

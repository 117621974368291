import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const JSError = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [jsErrorFlag, setJsErrorFlag] = useState(false)
  
  const columns = [
    {
      id: "type",
      displayName: 'Type'
    },
    {
      id: "errors",
      displayName: 'Errors'
    },
    {
      id: "file",
      displayName: 'File'
    },
    {
      id: "lineNo",
      displayName: 'Line No'
    },
    {
      id: "characterNo",
      displayName: 'Character No'
    }
  ]

  const jsErrorCsv = data?.jsError?.data?.finalData?.errors?.map(item => {
    return {
      type: item?.type,
      errors: item?.error,
      file: item?.file,
      lineNo: item?.lineno,
      characterNo: item?.charno
    }
  })
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.jsError?.data?.testResults?.status ? (
            ''
          ) : data?.jsError?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.jsError?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>

      <div className="vr-detail-block">
        <p>{data?.jsError?.data?.testResults?.message}</p>

        {showDetail && data?.jsError?.data?.testResults?.status !== 1 && data?.jsError?.data?.finalData?.errors?.length ? (
          <>
            <p className="text-primary cursor-pointer" onClick={() => {
              if (testType === "") {
                setJsErrorFlag(!jsErrorFlag)
              } else {
                dispatch(openRegisterModal(true))
              }
            }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={jsErrorFlag}
        toggleShow={() => setJsErrorFlag(!jsErrorFlag)}
        title={'JS Error Signal Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '600', fontSize: '22px' }}
        showDownload
        columns={columns}
        datas = {jsErrorCsv}
        fileName='JS Error Signal Test'
      >
        {data?.jsError?.data?.finalData?.errors?.length ? (
          <>
            <Table bordered>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>Type</th>
                  <th style={{ width: '40%' }}>Errors</th>
                  <th style={{ width: '30%' }}>File</th>
                  <th style={{ width: '10%' }}>Line No</th>
                  <th style={{ width: '10%' }}>Character No</th>
                </tr>
              </thead>
              <tbody>
                {data?.jsError?.data?.finalData?.errors?.length ? (
                  data?.jsError?.data?.finalData?.errors.map((item) => {
                    return (
                      <tr>
                        <td>{item.type}</td>
                        <td>{item.error}</td>
                        <td>{item.file}</td>
                        <td>{item.lineno}</td>
                        <td>{item.charno}</td>
                      </tr>
                    )
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </>
        ) : (
          <></>
        )}
      </ModalHOC>
    </>
  )
}

export default JSError

import { put, takeLatest, takeEvery, all, call } from 'redux-saga/effects'

// action type
import {
  BACKLINKS_CHECK_START,
  BACKLINKS_CHECK_SUCCESS,
  BACKLINKS_CHECK_FAIL,
  GET_USER_DOMAINS_FAILURE,
  GET_USER_DOMAINS,
  GET_USER_DOMAINS_SUCCESS,
  GET_BACKLINKS,
  GET_BACKLINKS_SUCCESS,
  GET_BACKLINKS_FAILURE,
  GET_BACKLINKS_STATS,
  GET_BACKLINKS_STATS_SUCCESS,
  GET_BACKLINKS_STATS_FAILURE,
  DELETE_WEBSITE,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  GET_USER_DATA,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  REMOVE_KEYWORD_FAILURE,
  REMOVE_KEYWORD_SUCCESS,
  REMOVE_KEYWORD,
  ADD_KEYWORDS_SETTINGS,
  ADD_KEYWORDS_SETTINGS_SUCCESS,
  ADD_KEYWORDS_SETTINGS_FAILURE,
  ADD_COMPETITORS_SETTINGS,
  ADD_COMPETITORS_SETTINGS_SUCCESS,
  ADD_COMPETITORS_SETTINGS_FAILURE,
  CHANGE_REGION_SETTINGS,
  CHANGE_REGION_SETTINGS_SUCCESS,
  CHANGE_REGION_SETTINGS_FAILURE,
  REMOVE_COMPETITORS_SETTINGS,
  REMOVE_COMPETITORS_SETTINGS_FAILURE,
  REMOVE_COMPETITORS_SETTINGS_SUCCESS,
  BROKEN_LINKS_TEST,
  BROKEN_LINKS_TEST_SUCCESS,
  BROKEN_LINKS_TEST_FAILURE,
  PATCH_USER_DOMAINS_DASHBOARD,
  PATCH_USER_DOMAINS_DASHBOARD_FAILURE,
  GET_SITEOUTAGE_DATA,
  GET_SITEOUTAGE_DATA_SUCCESS,
  GET_SITEOUTAGE_DATA_FAILURE,
  CHANGE_SOCIAL_SETTINGS,
  CHANGE_SOCIAL_SETTINGS_FAILURE,
  CHANGE_SOCIAL_SETTINGS_SUCCESS,
  GET_BACKLINKS_GRAPH_SUCCESS,
  GET_BACKLINKS_GRAPH_FAILURE,
  GET_BACKLINKS_GRAPH,
  GET_BACKLINKS_MOST_RECENTLY,
  GET_BACKLINKS_MOST_RECENTLY_SUCCESS,
  GET_BACKLINKS_MOST_RECENTLY_FAILURE,
  GET_BACKLINKS_MOST_IMPORTANT_SUCCESS,
  GET_BACKLINKS_MOST_IMPORTANT_FAILURE,
  GET_BACKLINKS_REFERRING_DOMAIN_SUCCESS,
  GET_BACKLINKS_REFERRING_DOMAIN_FAILURE,
  GET_BACKLINKS_REFERRING_DOMAIN,
  GET_BACKLINKS_MOST_IMPORTANT,
  GET_BACKLINKS_ANCHOR_TEXT,
  GET_BACKLINKS_ANCHOR_TEXT_FAILURE,
  GET_BACKLINKS_ANCHOR_TEXT_SUCCESS,
  GET_REGIONAL_LOCATION_CONFIGURATION,
  GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS,
  GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE,
  GET_COMPETITOR_SEO_SCORE,
  GET_COMPETITOR_SEO_SCORE_SUCCESS,
  GET_COMPETITOR_SEO_SCORE_FAILURE,
  MAIN_URL_SEO_SCORE,
  MAIN_URL_SEO_SCORE_SUCCESS,
  MAIN_URL_SEO_SCORE_FAILURE,
  WHITELIST_BROKEN_URL_SUCCESS,
  WHITELIST_BROKEN_URL_FAILURE,
  WHITELIST_BROKEN_URL,
  ADD_EVERYWHERE_KEYWORDS,
  ADD_EVERYWHERE_KEYWORDS_SUCCESS,
  ADD_EVERYWHERE_KEYWORDS_FAILURE,
  KEYWORDS_RESEARCH_DATA,
  KEYWORDS_RESEARCH_DATA_SUCCESS,
  KEYWORDS_RESEARCH_DATA_FAILURE,
  REMOVE_COMPETITORS,
  REMOVE_COMPETITORS_FAILURE,
  REMOVE_COMPETITORS_SUCCESS_1,
  REMOVE_COMPETITORS_SUCCESS_2,
  URL_SCAN_STATUS,
  GET_URL_SCAN_STATUS,
  GET_URL_SCAN_STATUS_SUCCESS,
  GET_URL_SCAN_STATUS_FAILURE,
  URL_SCAN_STATUS_FAILURE,
  KEYWORD_POSITION_TRACKING,
  KEYWORD_POSITION_TRACKING_SUCCESS,
  KEYWORD_POSITION_TRACKING_FAILURE,
  PERSONAL_INFO_UPDATE_ERROR,
  DELETE_USER_ERROR,
  PERSONAL_INFO_UPDATE,
  DELETE_USER,
  PERSONAL_INFO_UPDATE_SUCESS,
  DELETE_USER_SUCESS,
  GET_LIGHTHOUSE_QUICK_SEO,
  GET_LIGHTHOUSE_QUICK_SEO_SUCCESS,
  GET_LIGHTHOUSE_QUICK_SEO_FAILURE,
  WEBSITE_LIMIT,
  WEBSITE_LIMIT_FAILURE,
  WEBSITE_LIMIT_SUCCESS,
  KEYWORD_LIMIT,
  KEYWORD_LIMIT_SUCCESS,
  KEYWORD_LIMIT_FAILURE,
  COMPETITOR_LIMIT_SUCCESS,
  COMPETITOR_LIMIT_FAILURE,
  COMPETITOR_LIMIT,
  QUICK_SCAN_LIMIT,
  QUICK_SCAN_LIMIT_SUCCESS,
  QUICK_SCAN_LIMIT_FAILURE,
  AVAILABLE_KEYWORD_LIMIT,
  AVAILABLE_KEYWORD_LIMIT_SUCCESS,
  AVAILABLE_KEYWORD_LIMIT_FAILURE,
  GET_TEST_RESULTS,
  GET_TEST_RESULTS_SUCCESS,
  GET_TEST_RESULTS_FAILURE,
  PATCH_USER_DOMAINS_SUCCESS,
  STORE_USER_DATA_START,
  NEW_NOTIFICATION,
  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_FAILURE,
  READ_SINGLE_NOTIFICATION,
  READ_SINGLE_NOTIFICATION_SUCCESS,
  READ_SINGLE_NOTIFICATION_FAILURE,
  READ_ALL_NOTIFICATION,
  READ_ALL_NOTIFICATION_SUCCESS,
  READ_ALL_NOTIFICATION_FAILURE,
  GET_LOST_BACKLINKS_SUCCESS,
  GET_LOST_BACKLINKS_FAILURE,
  GET_LOST_BACKLINKS,
  GET_AUTH_URL,
  GET_AUTH_URL_SUCCESS,
  GET_AUTH_URL_FAILURE,
  GENERATE_RESEARCH,
  GENERATE_RESEARCH_SUCCESS,
  GENERATE_RESEARCH_FAILURE,
  ANALYTICS_DATA,
  DISCONNECT_GOOGLE,
  DISCONNECT_GOOGLE_SUCCESS,
  DISCONNECT_GOOGLE_FAILURE,
  STORE_ANALYTICS_DATA,
  STORE_ANALYTICS_DATA_SUCCESS,
  STORE_ANALYTICS_DATA_FAILURE,
  GET_ANALYTICS_DATA,
  GET_ANALYTICS_DATA_SUCCESS,
  GET_ANALYTICS_DATA_FAILURE,
  GET_AUTH_TOKEN,
  GET_AUTH_TOKEN_SUCCESS,
  GET_AUTH_TOKEN_FAILURE,
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  SPEED_GRAPH_CHART_DATA_SUCCESS,
  SPEED_GRAPH_CHART_DATA_FAILURE,
  SPEED_GRAPH_CHART_DATA_REQUEST,
  GENERATE_TICKET,
  GENERATE_TICKET_SUCCESS,
  GENERATE_TICKET_FAILURE,
} from 'redux/actionType.js'

// api
import {
  getRequest,
  putRequestWithToken,
  postLocalRequestWithToken,
  deleteRequestWithToken,
  getDashboardChartData,
} from 'constants/httpHelperService.js'

const backLinksApi = async (input) => {
  return await getRequest(
    `get-backlinks?url=${input.url}&userId=${input.userId}&urlInfo=${input.urlInfo}&webCached=${input.webCached}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* backLinksData(action) {
  try {
    const data = yield backLinksApi(action.payload)
    // const data = {};
    if (data && data.length) {
      yield put({ type: BACKLINKS_CHECK_SUCCESS, payload: data })
      // yield put({ type: GET_USER_DOMAINS_SUCCESS, payload: data })
      yield put({ type: PATCH_USER_DOMAINS_DASHBOARD })
    } else {
      yield put({ type: BACKLINKS_CHECK_FAIL, data: data?.response?.data || [] })
    }
  } catch (e) {
    yield put({ type: BACKLINKS_CHECK_FAIL, data: [] })
  }
}

function* backLinksDataSaga() {
  yield takeLatest(BACKLINKS_CHECK_START, backLinksData)
}
////////////////////////////////////////////

const getBackLinksApi = async (input) => {
  return await getRequest(`backlinks-check`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksData(action) {
  try {
    const data = yield getBackLinksApi(action.payload)
    yield put({ type: GET_BACKLINKS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_FAILURE, e })
  }
}

function* getBackLinksDataSaga() {
  yield takeEvery(GET_BACKLINKS, getBackLinksData)
}
////////////////////////////////////////////

////////////////////////////////////////////

const getBackLinksStatsApi = async () => {
  return await getRequest(`backlinks-check-stats`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksStatsData() {
  try {
    const data = yield getBackLinksStatsApi()
    yield put({ type: GET_BACKLINKS_STATS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_STATS_FAILURE, e })
  }
}

function* getBackLinksDataStatsSaga() {
  yield takeEvery(GET_BACKLINKS_STATS, getBackLinksStatsData)
}
////////////////////////////////////////////

////////////////////////////////////////////

const getBackLinksGraphApi = async (input) => {
  return await getRequest(`get-backlinks-graph`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksGraphsData(action) {
  try {
    const data = yield getBackLinksGraphApi(action.payload)
    yield put({ type: GET_BACKLINKS_GRAPH_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_GRAPH_FAILURE, e })
  }
}

function* getBackLinksGraphDataSaga() {
  yield takeEvery(GET_BACKLINKS_GRAPH, getBackLinksGraphsData)
}
////////////////////////////////////////////

////////////////////////////////////////////
const getBackLinksMostRecently = async (input) => {
  return await getRequest(`backlinks/most-recently?limit=${input.limit}&page=${input.page}`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksMostRecentlyData(action) {
  try {
    const data = yield getBackLinksMostRecently(action.payload)
    yield put({ type: GET_BACKLINKS_MOST_RECENTLY_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_MOST_RECENTLY_FAILURE, e })
  }
}

function* getBackLinksMostRecentlySaga() {
  yield takeEvery(GET_BACKLINKS_MOST_RECENTLY, getBackLinksMostRecentlyData)
}
////////////////////////////////////////////

////////////////////////////////////////////
const getBackLinksMostImp = async (input) => {
  return await getRequest(`backlinks/most-important?limit=${input.limit}&page=${input.page}`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksMostImpData(action) {
  try {
    const data = yield getBackLinksMostImp(action.payload)
    yield put({ type: GET_BACKLINKS_MOST_IMPORTANT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_MOST_IMPORTANT_FAILURE, e })
  }
}

function* getBackLinksMostImpSaga() {
  yield takeEvery(GET_BACKLINKS_MOST_IMPORTANT, getBackLinksMostImpData)
}
////////////////////////////////////////////

////////////////////////////////////////////
const getBackLinksReferringDomains = async (input) => {
  return await getRequest(`backlinks/referring-domain?limit=${input.limit}&page=${input.page}`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksReferringDomainData(action) {
  try {
    const data = yield getBackLinksReferringDomains(action.payload)
    yield put({ type: GET_BACKLINKS_REFERRING_DOMAIN_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_REFERRING_DOMAIN_FAILURE, e })
  }
}

function* getBackLinksReferringDomainSaga() {
  yield takeEvery(GET_BACKLINKS_REFERRING_DOMAIN, getBackLinksReferringDomainData)
}
////////////////////////////////////////////

////////////////////////////////////////////
const getBackLinksAnchorText = async (input) => {
  return await getRequest(`backlinks/achor-text?limit=${input.limit}&page=${input.page}`)
    .then((res) => res)
    .catch((error) => error)
}

function* getBackLinksAnchorTextData(action) {
  try {
    const data = yield getBackLinksAnchorText(action.payload)
    yield put({ type: GET_BACKLINKS_ANCHOR_TEXT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_ANCHOR_TEXT_FAILURE, e })
  }
}

function* getBackLinksAnchorTextSaga() {
  yield takeEvery(GET_BACKLINKS_ANCHOR_TEXT, getBackLinksAnchorTextData)
}
////////////////////////////////////////////

const getUserDomainsApi = async (input) => {
  return await getRequest(`users-domains?userId=${input.userId}`)
    .then((res) => res)
    .catch((error) => error)
}

function* getUserDomainsData(action) {
  try {
    const data = yield getUserDomainsApi(action.payload)
    yield put({ type: GET_USER_DOMAINS_SUCCESS, payload: data })
    yield put({ type: PATCH_USER_DOMAINS_DASHBOARD, payload: action.payload })
  } catch (e) {
    yield put({ type: GET_USER_DOMAINS_FAILURE, e })
  }
}

function* getUserDomainsSaga() {
  yield takeLatest(GET_USER_DOMAINS, getUserDomainsData)
}

////////////////////////////////////////////

const patchUserDomainsDashboardApi = async () => {
  return await getRequest(`users-domains-patch-dashboard`)
    .then((res) => res)
    .catch((error) => error)
}

const dashboardChartData = async (payload) => {
  try {
    return await getDashboardChartData('speed-overview', payload)
  } catch (error) {
    throw error
  }
}

function* patchUserDomainsDashboardData() {
  try {
    const data = yield patchUserDomainsDashboardApi()

    // yield put({ type: GET_USER_DOMAINS_SUCCESS, payload: data })
    yield put({ type: PATCH_USER_DOMAINS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: PATCH_USER_DOMAINS_DASHBOARD_FAILURE, e })
  }
}

function* getGraphData(action) {
  try {
    const data = yield dashboardChartData(action.payload)
    yield put({ type: SPEED_GRAPH_CHART_DATA_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: SPEED_GRAPH_CHART_DATA_FAILURE, error: error?.response?.data })
  }
}

function* handleGraphData() {
  yield takeLatest(SPEED_GRAPH_CHART_DATA_REQUEST, getGraphData)
}

function* patchUserDomainsDashboardSaga() {
  yield takeLatest(PATCH_USER_DOMAINS_DASHBOARD, patchUserDomainsDashboardData)
}

////////////////////////////////////////////

const getSiteOutageApi = async () => {
  return await getRequest(`get-site-outage-data`)
    .then((res) => res)
    .catch((error) => error)
}

function* getSiteOutageData() {
  try {
    const data = yield getSiteOutageApi()

    yield put({ type: GET_SITEOUTAGE_DATA_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_SITEOUTAGE_DATA_FAILURE, e })
  }
}

function* getSiteOutageSaga() {
  yield takeLatest(GET_SITEOUTAGE_DATA, getSiteOutageData)
}

////////////////////////////////////////////

const deleteWebsiteApi = async (input) => {
  return await deleteRequestWithToken(`domain/${input.domainId}/${input.userId}`)
    .then((res) => res)
    .catch((error) => error)
}

function* deleteWebsiteData(action) {
  try {
    const data = yield deleteWebsiteApi(action.payload)

    yield put({ type: DELETE_WEBSITE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: DELETE_WEBSITE_FAILURE, e })
  }
}

function* deleteWebsiteSaga() {
  yield takeEvery(DELETE_WEBSITE, deleteWebsiteData)
}

/////////////////////////////////////////////

const getUserDataApi = async () => {
  return await getRequest(`get-user-data`)
    .then((res) => res)
    .catch((error) => error)
}

function* getUserData() {
  try {
    const data = yield getUserDataApi()
    const token = localStorage.getItem('token')

    let store_data = {
      ...data?.searchData,
      token,
    }

    yield put({ type: GET_USER_DATA_SUCCESS, data: data })
    yield put({ type: STORE_USER_DATA_START, payload: store_data })
  } catch (e) {
    yield put({ type: GET_USER_DATA_FAILURE, e })
  }
}

function* getUserDataSaga() {
  yield takeLatest(GET_USER_DATA, getUserData)
}

////////////////////////////////////////////

/////////////////////////////////////////////

const removeKeywordApi = async (input) => {
  return await postLocalRequestWithToken(`remove-keyword`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* removeKeywordData(action) {
  try {
    const data = yield removeKeywordApi(action.data)

    yield put({ type: REMOVE_KEYWORD_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: REMOVE_KEYWORD_FAILURE, e })
  }
}

function* removeKeywordSaga() {
  yield takeLatest(REMOVE_KEYWORD, removeKeywordData)
}

////////////////////////////////////////////

const removeCompetitorApi = async (input) => {
  return await postLocalRequestWithToken(`remove-competitors`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* removeCompetitorsData(action) {
  try {
    const data = yield removeCompetitorApi(action?.payload)
    yield put({ type: REMOVE_COMPETITORS_SUCCESS_1, data: data })
    yield put({ type: REMOVE_COMPETITORS_SUCCESS_2, data: data })
  } catch (e) {
    yield put({ type: REMOVE_COMPETITORS_FAILURE, e })
  }
}
function* removeCompetitorSaga() {
  yield takeLatest(REMOVE_COMPETITORS, removeCompetitorsData)
}

/////////////////////////////////////////////
const brokenLinkApi = async (input) => {
  return await postLocalRequestWithToken(`re-check-brokenlinks`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* brokenLinkData(action) {
  try {
    const data = yield brokenLinkApi(action.data)

    yield put({ type: BROKEN_LINKS_TEST_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: BROKEN_LINKS_TEST_FAILURE, e })
  }
}

function* brokenLinkSaga() {
  yield takeLatest(BROKEN_LINKS_TEST, brokenLinkData)
}

/////////////////////////////////////////////

const addCompetitorsSettingsApi = async (input) => {
  return await postLocalRequestWithToken(`add-competitor`, input)
}

function* addCompetitorsSettingsData(action) {
  try {
    const data = yield addCompetitorsSettingsApi(action.data)

    yield put({ type: ADD_COMPETITORS_SETTINGS_SUCCESS, data: data })
  } catch (error) {
    yield put({ type: ADD_COMPETITORS_SETTINGS_FAILURE, error: error?.response })
  }
}

function* addCompetitorsSettingsSaga() {
  yield takeLatest(ADD_COMPETITORS_SETTINGS, addCompetitorsSettingsData)
}

/////////////////////////////////////////////

/////////////////////////////////////////////

const changeSocialSettingsApi = async (input) => {
  return await postLocalRequestWithToken(`change-social`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* changeSocialSettingsData(action) {
  try {
    const data = yield changeSocialSettingsApi(action.data)

    yield put({ type: CHANGE_SOCIAL_SETTINGS_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: CHANGE_SOCIAL_SETTINGS_FAILURE, e })
  }
}

function* changeSocialSettingsSaga() {
  yield takeLatest(CHANGE_SOCIAL_SETTINGS, changeSocialSettingsData)
}

/////////////////////////////////////////////

const addKeywordsSettingsApi = async (input) => {
  return await postLocalRequestWithToken(`add-keyword`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* addKeywordsSettingsData(action) {
  try {
    const data = yield addKeywordsSettingsApi(action.data)

    yield put({ type: ADD_KEYWORDS_SETTINGS_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: ADD_KEYWORDS_SETTINGS_FAILURE, e })
  }
}

function* addKeywordsSettingsSaga() {
  yield takeLatest(ADD_KEYWORDS_SETTINGS, addKeywordsSettingsData)
}

/////////////////////////////////////////////

const removeCompetitorsSettingsApi = async (input) => {
  return await postLocalRequestWithToken(`remove-competitor`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* removeCompetitorsSettingsData(action) {
  try {
    const data = yield removeCompetitorsSettingsApi(action.data)

    yield put({ type: REMOVE_COMPETITORS_SETTINGS_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: REMOVE_COMPETITORS_SETTINGS_FAILURE, e })
  }
}

function* removeCompetitorsSettingsSaga() {
  yield takeLatest(REMOVE_COMPETITORS_SETTINGS, removeCompetitorsSettingsData)
}

/////////////////////////////////////////////

const changeRegionApi = async (input) => {
  return await postLocalRequestWithToken(`change-region`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* changeRegionData(action) {
  try {
    const data = yield changeRegionApi(action.data)

    yield put({ type: CHANGE_REGION_SETTINGS_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: CHANGE_REGION_SETTINGS_FAILURE, e })
  }
}

function* changeRegionSaga() {
  yield takeLatest(CHANGE_REGION_SETTINGS, changeRegionData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const saveRegionalLocationConfiguration = async (input) => {
  let url = ''
  if (input.type === 'top-keywords') {
    url = `keywords/set-region-for-top-keywords`
  } else {
    url = `save/regional-configuration`
  }

  return await postLocalRequestWithToken(url, input)
    .then((res) => res)
    .catch((error) => error)
}

function* storeRegionalLocationConfiguration(action) {
  try {
    const data = yield saveRegionalLocationConfiguration(action.payload)
    yield put({ type: GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE, e })
  }
}

function* regionalLocationConfiguration() {
  yield takeEvery(GET_REGIONAL_LOCATION_CONFIGURATION, storeRegionalLocationConfiguration)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////

const getCompetitorSeoScoreDataApi = async (input) => {
  return await postLocalRequestWithToken(`competitor/seo-score`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* getCompetitorSeoScoreData(action) {
  try {
    const data = yield getCompetitorSeoScoreDataApi(action.payload)

    yield put({ type: GET_COMPETITOR_SEO_SCORE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_COMPETITOR_SEO_SCORE_FAILURE, e })
  }
}

function* getCompetitorSeoScoreSaga() {
  yield takeLatest(GET_COMPETITOR_SEO_SCORE, getCompetitorSeoScoreData)
}

////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getMainUrlSeoScoreDataApi = async (input) => {
  return await postLocalRequestWithToken(`main-url/seo-score`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* getMainUrlSeoScoreData(action) {
  try {
    const data = yield getMainUrlSeoScoreDataApi(action.payload)

    yield put({ type: MAIN_URL_SEO_SCORE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: MAIN_URL_SEO_SCORE_FAILURE, e })
  }
}

function* getMainUrlSeoScoreSaga() {
  yield takeLatest(MAIN_URL_SEO_SCORE, getMainUrlSeoScoreData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const whitelistBrokenUrlDataApi = async (input) => {
  return await postLocalRequestWithToken(`whitelist/broken-links`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* whitelistBrokenUrlData(action) {
  try {
    const data = yield whitelistBrokenUrlDataApi(action.payload)

    yield put({ type: WHITELIST_BROKEN_URL_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: WHITELIST_BROKEN_URL_FAILURE, e })
  }
}

function* whitelistBrokenUrl() {
  yield takeLatest(WHITELIST_BROKEN_URL, whitelistBrokenUrlData)
}

const addKeywordsEverywhereApi = async (input) => {
  return await postLocalRequestWithToken(`get-volume-data`, input)
}

function* addKeywordsEverywhereData(action) {
  try {
    const data = yield addKeywordsEverywhereApi(action.data)

    yield put({ type: ADD_EVERYWHERE_KEYWORDS_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: ADD_EVERYWHERE_KEYWORDS_FAILURE, e })
  }
}

function* addKeywordsEverywhereSaga() {
  yield takeLatest(ADD_EVERYWHERE_KEYWORDS, addKeywordsEverywhereData)
}

const keywordsResearchApi = async (input) => {
  return await getRequest(`get-keywords-research`)
    .then((res) => res)
    .catch((error) => error)
}

function* keywordsResearchData(action) {
  try {
    const data = yield keywordsResearchApi(action.data)

    yield put({ type: KEYWORDS_RESEARCH_DATA_SUCCESS, data: data })
  } catch (e) {
    yield put({ type: KEYWORDS_RESEARCH_DATA_FAILURE, e })
  }
}

function* keywordsResearchSaga() {
  yield takeLatest(KEYWORDS_RESEARCH_DATA, keywordsResearchData)
}

const urlScanStatusApi = async (input) => {
  return await putRequestWithToken('update-live-scan-status', input)
    .then((res) => res)
    .catch((error) => error)
}

const PersnoalInformationApi = async (input) => {
  return await putRequestWithToken('user/update-user', input)
    .then((res) => res)
    .catch((error) => error)
}

const deleteUSerApi = async () => {
  return await putRequestWithToken('user/delete-user')
    .then((res) => res)
    .catch((error) => error)
}

function* urlScanStatusData(action) {
  try {
    const data = yield urlScanStatusApi(action.payload)
  } catch (e) {
    yield put({ type: URL_SCAN_STATUS_FAILURE, e })
  }
}

function* personalInfoUpdateData(action) {
  try {
    const data = yield PersnoalInformationApi(action.payload)
    yield put({ type: PERSONAL_INFO_UPDATE_SUCESS, payload: data })
  } catch (e) {
    yield put({ type: PERSONAL_INFO_UPDATE_ERROR, error: error?.response?.data })
  }
}

function* deleteUserData() {
  try {
    const data = yield deleteUSerApi()
    yield put({ type: DELETE_USER_SUCESS, payload: data })
  } catch (e) {
    yield put({ type: DELETE_USER_ERROR, error: error?.response?.data })
  }
}

function* urlScanStatusSaga() {
  yield takeLatest(URL_SCAN_STATUS, urlScanStatusData)
}

function* personalInfoSaga() {
  yield takeLatest(PERSONAL_INFO_UPDATE, personalInfoUpdateData)
}

function* deleteUserSaga() {
  yield takeLatest(DELETE_USER, deleteUserData)
}

const geturlScanStatusApi = async () => {
  return await getRequest(`get-scan-status`)
    .then((res) => res)
    .catch((error) => error)
}

function* geturlScanStatusData() {
  try {
    const data = yield geturlScanStatusApi()
    yield put({ type: GET_URL_SCAN_STATUS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_URL_SCAN_STATUS_FAILURE, e })
  }
}

function* geturlScanStatusSaga() {
  yield takeLatest(GET_URL_SCAN_STATUS, geturlScanStatusData)
}

const keywordPositionTrackingApi = async (input) => {
  return await getRequest(`keywords-position`)
    .then((res) => {
      return res
    })
    .catch((error) => error)
}

function* keywordPositionTrackingData(action) {
  try {
    const data = yield call(keywordPositionTrackingApi, action.payload)
    yield put({ type: KEYWORD_POSITION_TRACKING_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: KEYWORD_POSITION_TRACKING_FAILURE, e })
  }
}

function* keywordPositionTrackingSaga() {
  yield takeLatest(KEYWORD_POSITION_TRACKING, keywordPositionTrackingData)
}

const getLighthouseQuickSeoApi = async (input) => {
  return await getRequest(`quick-lighthouse?url=${input}`)
    .then((res) => {
      return res
    })
    .catch((error) => error)
}

function* getLighthouseQuickSeoData(action) {
  try {
    const data = yield getLighthouseQuickSeoApi(action?.payload?.url)
    yield put({ type: GET_LIGHTHOUSE_QUICK_SEO_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_LIGHTHOUSE_QUICK_SEO_FAILURE, e })
  }
}

function* getLighthouseQuickSeoSaga() {
  yield takeLatest(GET_LIGHTHOUSE_QUICK_SEO, getLighthouseQuickSeoData)
}

const getLimitRestrictionsApi = async (input) => {
  return await postLocalRequestWithToken(`website-limit`, input)
}

function* getLimitRestrictionsData(action) {
  try {
    const data = yield getLimitRestrictionsApi(action?.payload)
    if (action?.type === WEBSITE_LIMIT) {
      yield put({ type: WEBSITE_LIMIT_SUCCESS, payload: data })
    }

    if (action?.type === KEYWORD_LIMIT) {
      yield put({ type: KEYWORD_LIMIT_SUCCESS, payload: data })
    }

    if (action?.type === COMPETITOR_LIMIT) {
      yield put({ type: COMPETITOR_LIMIT_SUCCESS, payload: data })
    }

    if (action?.type === QUICK_SCAN_LIMIT) {
      yield put({ type: QUICK_SCAN_LIMIT_SUCCESS, payload: data })
    }
  } catch (error) {
    if (action?.type === WEBSITE_LIMIT) {
      yield put({ type: WEBSITE_LIMIT_FAILURE, error: error?.response?.data })
    }

    if (action?.type === KEYWORD_LIMIT) {
      yield put({ type: KEYWORD_LIMIT_FAILURE, error: error?.response?.data })
    }

    if (action?.type === COMPETITOR_LIMIT) {
      yield put({ type: COMPETITOR_LIMIT_FAILURE, error: error?.response?.data })
    }

    if (action?.type === QUICK_SCAN_LIMIT) {
      yield put({ type: QUICK_SCAN_LIMIT_FAILURE, error: error?.response?.data })
    }
  }
}

function* getWebsiteLimitSaga() {
  yield takeLatest(WEBSITE_LIMIT, getLimitRestrictionsData)
}

function* getKeywordLimitSaga() {
  yield takeLatest(KEYWORD_LIMIT, getLimitRestrictionsData)
}

function* getCompetitorLimitSaga() {
  yield takeLatest(COMPETITOR_LIMIT, getLimitRestrictionsData)
}

function* getQuickScanLimitSaga() {
  yield takeLatest(QUICK_SCAN_LIMIT, getLimitRestrictionsData)
}

const getAvailableKeywordLimitApi = async () => {
  return await getRequest(`keywords-limit`)
}

function* getAvailableKeywordLimitData() {
  try {
    const data = yield getAvailableKeywordLimitApi()
    yield put({ type: AVAILABLE_KEYWORD_LIMIT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: AVAILABLE_KEYWORD_LIMIT_FAILURE, error: error?.response?.data })
  }
}

function* getAvailableKeywordLimitSaga() {
  yield takeLatest(AVAILABLE_KEYWORD_LIMIT, getAvailableKeywordLimitData)
}

const getTestResultsApi = async () => {
  return await getRequest(`test-results`)
    .then((res) => res)
    .catch((error) => error)
}

function* getTestResults() {
  try {
    const data = yield getTestResultsApi()
    yield put({ type: GET_TEST_RESULTS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_TEST_RESULTS_FAILURE, e })
  }
}
function* getTestResultsSaga() {
  yield takeLatest(GET_TEST_RESULTS, getTestResults)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getNotificationsApi = async () => {
  return await getRequest(`notification`)
}

function* getNotifications() {
  try {
    const data = yield getNotificationsApi()
    yield put({ type: NEW_NOTIFICATION_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: NEW_NOTIFICATION_FAILURE, error: error?.response?.data })
  }
}

function* getNotificationsSaga() {
  yield takeLatest(NEW_NOTIFICATION, getNotifications)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const readSingleNotificationApi = async (input) => {
  return await putRequestWithToken(`notification`, input)
}

function* readSingleNotification(action) {
  try {
    const data = yield readSingleNotificationApi(action?.payload)
    yield put({ type: READ_SINGLE_NOTIFICATION_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: READ_SINGLE_NOTIFICATION_FAILURE, error: error?.response?.data })
  }
}

function* readSingleNotificationSaga() {
  yield takeLatest(READ_SINGLE_NOTIFICATION, readSingleNotification)
}

const readAllNotificationsApi = async () => {
  return await putRequestWithToken(`notification/read-all`)
}

function* readAllNotifications() {
  try {
    const data = yield readAllNotificationsApi()
    yield put({ type: READ_ALL_NOTIFICATION_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: READ_ALL_NOTIFICATION_FAILURE, error: error?.response?.data })
  }
}

function* readAllNotificationsSaga() {
  yield takeLatest(READ_ALL_NOTIFICATION, readAllNotifications)
}

const getLostBackLinks = async (payload) => {
  return await postLocalRequestWithToken(`lost-backlinks`, payload)
    .then((res) => res)
    .catch((error) => error)
}

function* getLostBackLinksData(action) {
  try {
    const data = yield getLostBackLinks(action?.payload)
    yield put({ type: GET_LOST_BACKLINKS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_LOST_BACKLINKS_FAILURE, error: e?.response?.data })
  }
}

function* getLostBackLinksSaga() {
  yield takeEvery(GET_LOST_BACKLINKS, getLostBackLinksData)
}

const getAuthUrlApi = async (input) => {
  return await getRequest(`auth-url?url=${input?.url}`)
}

function* getAuthUrlData(action) {
  try {
    const data = yield getAuthUrlApi(action?.payload)
    yield put({ type: GET_AUTH_URL_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_AUTH_URL_FAILURE, error: e?.response?.data })
  }
}

function* getAuthUrlSaga() {
  yield takeEvery(GET_AUTH_URL, getAuthUrlData)
}

const getAuthTokenApi = async (input) => {
  return await getRequest(`auth-token?url=${input?.url}&code=${input?.authCode}`)
}

function* getAuthTokenData(action) {
  try {
    const data = yield getAuthTokenApi(action?.payload)
    yield put({ type: GET_AUTH_TOKEN_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_AUTH_TOKEN_FAILURE, error: e?.response?.data })
  }
}

function* getAuthTokenSaga() {
  yield takeLatest(GET_AUTH_TOKEN, getAuthTokenData)
}

const generateResearchApi = async (input) => {
  return await postLocalRequestWithToken(`generate-research`, input)
}

function* generateResearchData(action) {
  try {
    const data = yield generateResearchApi(action?.payload)
    yield put({ type: GENERATE_RESEARCH_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GENERATE_RESEARCH_FAILURE, error: e?.response?.data })
  }
}

function* generateResearchSaga() {
  yield takeEvery(GENERATE_RESEARCH, generateResearchData)
}

const getAnalyticsAccountApi = async (input) => {
  return await getRequest(`analytics-account?url=${input?.url}`)
}

function* getAnalyticsAccountData(action) {
  try {
    const data = yield getAnalyticsAccountApi(action?.payload)
    yield put({ type: GET_AUTH_TOKEN_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_AUTH_TOKEN_FAILURE, error: e?.response?.data })
  }
}

function* getAnalyticsAccountDataSaga() {
  yield takeLatest(ANALYTICS_DATA, getAnalyticsAccountData)
}

const disconnectGoogleApi = async (input) => {
  return await getRequest(`disconnect-google?url=${input?.url}`)
}

function* disconnectGoogleData(action) {
  try {
    const data = yield disconnectGoogleApi(action?.payload)
    yield put({ type: DISCONNECT_GOOGLE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: DISCONNECT_GOOGLE_FAILURE, error: e?.response?.data })
  }
}

function* disconnectGoogleSaga() {
  yield takeLatest(DISCONNECT_GOOGLE, disconnectGoogleData)
}

const storeSearchConsoleDataApi = async (input) => {
  return await postLocalRequestWithToken(`save-analytics-data`, input)
}

function* storeSearchConsoleData(action) {
  try {
    const data = yield storeSearchConsoleDataApi(action?.payload)
    yield put({ type: STORE_ANALYTICS_DATA_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: STORE_ANALYTICS_DATA_FAILURE, error: e?.response?.data })
  }
}

function* storeSearchConsoleDataSaga() {
  yield takeLatest(STORE_ANALYTICS_DATA, storeSearchConsoleData)
}

const getAnalyticsDataApi = async (input) => {
  return await getRequest(`analytics-data?url=${input?.url}`)
}

function* getAnalyticsData(action) {
  try {
    const data = yield getAnalyticsDataApi(action?.payload)
    yield put({ type: GET_ANALYTICS_DATA_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ANALYTICS_DATA_FAILURE, error: e?.response?.data })
  }
}

function* getAnalyticsDataSaga() {
  yield takeLatest(GET_ANALYTICS_DATA, getAnalyticsData)
}

const contactUsApi = (input) => {
  return postLocalRequestWithToken(`user/contact-us`, input)
}

function* contactUsData(action) {
  try {
    const data = yield contactUsApi(action?.payload)
    yield put({ type: CONTACT_US_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CONTACT_US_FAILURE, error: e?.response?.data })
  }
}
function* contactUsSaga() {
  yield takeLatest(CONTACT_US, contactUsData)
}

const generateTicketApi = async (input) => {
  return await postLocalRequestWithToken(`user/support`, input)
}

function* generateTicket(action) {
  try {
    const data = yield generateTicketApi(action?.payload)
    yield put({ type: GENERATE_TICKET_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GENERATE_TICKET_FAILURE, error: e?.response?.data })
  }
}
function* generateTicketSaga() {
  yield takeLatest(GENERATE_TICKET, generateTicket)
}

export default function* dashboardSaga() {
  yield all([
    backLinksDataSaga(),
    getBackLinksDataSaga(),
    getBackLinksDataStatsSaga(),
    getUserDomainsSaga(),
    patchUserDomainsDashboardSaga(),
    handleGraphData(),
    deleteWebsiteSaga(),
    getUserDataSaga(),

    removeKeywordSaga(),

    addCompetitorsSettingsSaga(),
    addKeywordsSettingsSaga(),
    removeCompetitorsSettingsSaga(),

    brokenLinkSaga(),
    getSiteOutageSaga(),
    changeSocialSettingsSaga(),
    getBackLinksGraphDataSaga(),
    getBackLinksMostRecentlySaga(),
    getBackLinksMostImpSaga(),
    getBackLinksReferringDomainSaga(),
    getBackLinksAnchorTextSaga(),
    regionalLocationConfiguration(),
    getCompetitorSeoScoreSaga(),
    getMainUrlSeoScoreSaga(),
    whitelistBrokenUrl(),
    addKeywordsEverywhereSaga(),
    changeRegionSaga(),
    keywordsResearchSaga(),
    removeCompetitorSaga(),
    urlScanStatusSaga(),
    personalInfoSaga(),
    deleteUserSaga(),
    geturlScanStatusSaga(),
    keywordPositionTrackingSaga(),
    getLighthouseQuickSeoSaga(),
    getWebsiteLimitSaga(),
    getKeywordLimitSaga(),
    getCompetitorLimitSaga(),
    getQuickScanLimitSaga(),
    getAvailableKeywordLimitSaga(),
    getTestResultsSaga(),
    getNotificationsSaga(),
    readSingleNotificationSaga(),
    readAllNotificationsSaga(),
    getLostBackLinksSaga(),
    getAuthUrlSaga(),
    generateResearchSaga(),
    getAnalyticsAccountDataSaga(),
    disconnectGoogleSaga(),
    storeSearchConsoleDataSaga(),
    getAnalyticsDataSaga(),
    getAuthTokenSaga(),
    contactUsSaga(),
    generateTicketSaga(),
  ])
}

import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  Container,
  Col,
  Row,
  Tab,
  Nav,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Alert,
} from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import { findLastIndex } from 'lodash'
import * as Icon from 'react-feather'
import * as moment from 'moment'

import { Loader } from 'components/uiCore'
import UrlDetail from 'pages/dashboards/Url-detaills-revised.js'
import SeoScoreProgress from 'pages/dashboards/SeoProgress.js'
import BacklinksGraph from 'pages/dashboards/Backlinks.js'
import SpeedGraph from 'pages/dashboards/SpeedGraph.js'
import BacklinksHeader from 'pages/dashboards/BacklinksHeader.js'
import TopKeywordsTable from 'pages/dashboards/TopKeywordsTable.js'
import TopKeywordsHeader from 'pages/dashboards/TopKeyowrdsHeader.js'
import WorldMap from 'pages/dashboards/WordMap.js'
import IssuesFooter from 'pages/dashboards/IssuesFooter.js'
import Issues from 'pages/dashboards/Issues.js'
import DashboardLoading from 'pages/dashboards/DashboardLoading.js'
import UrlScanStatus from 'pages/dashboards/DashboardRevised/UrlScanStatus.jsx'
import GooglePageRank from 'pages/dashboards/DashboardRevised/GooglePageRank.js'
import { getDataForDashboard } from 'utils/seoIssue.js'
import SpaceProgress from 'pages/dashboards/DashboardRevised/SpaceProgress.js'
import DashboardTopKeywords from 'pages/dashboards/DashboardRevised/TopKeywords.js'

import paths from 'constants/paths'
import { ReactComponent as ExclamationMark } from '../../../assets/img/exclamation-mark.svg'

import {
  patchUserDomainsDashboard,
  getBacklinksStats,
  getUserData,
  changeActiveLink,
  getSiteOutageData,
  getCompetitorSeoScore,
  getMainUrlSeoScore,
  removeCompetitors,
  geturlScanStatus,
  urlScanStatus,
  getAvailableKeywordLimit,
  getTestResults,
  getNotifications,
  personalInfoUpdate,
  setGraphPayload,
} from 'redux/actions/dashboard/index.js'
import { getUrlReport } from 'redux/actions/freeTools/index.js'
import { storeUserData, verifyTokenStart } from 'redux/actions/register/index.js'
import {
  getCountries,
  getLocations,
  ifwebSiteData,
  isCallForAddWebsite,
  lightHouseSuccessClear,
  openModalLighthouse,
  setStartScan,
  stopAddWebsiteLoader,
} from 'redux/actions/freetrial/startFreeTrial.js'
import { getTopFiveKeywordApi, getAvgPositionKeywordApi } from '../../../redux/actions/topKeywords'

import 'pages/dashboards/DashboardRevised/urlScanStatus.scss'
import { message } from 'antd'
import { getActiveUserData, getRefreshToken, isMainUrlMatch } from 'constants/Utils'
import { PERSONAL_INFO_UPDATE_CLEAR } from 'redux/actionType'
import { PLAN } from 'constants/enum'
import MainUrlNav from 'components/MainUrlNav'
import SpeedGraphForDashboard from '../SpeedGraphForDashboard'

const DashboardRevised = () => {
  const userId = useSelector(({ register }) => register?.registerData?._id)
  const {
    usersDomains: usersDomain,
    activeLink,
    competitorSeoScoreData,
    userData,
    activeKeywordData,
    activeSiteOutageData,
    chartDataForAntD,
    mainUrlScoreDataArray,
    dashboardTopKeywords,
    stopPatchApiCall,
    stopBacklinksStatsApiCall,
    backlinksDataForPopup,
    removeCompetitorsLoader,
    urlScanDetails,
    seoProgressPercentage,
    seoCompetitorPercentage,
    testResultData,
    persnoalInfoLoading,
    toastMessage: personalInfoMessage,
    personalInfoSuccess,
    personalInfoFailure,
    dashboardGraphData,
  } = useSelector(({ dashboard }) => dashboard)
  const { avgPosition: averagePositionMasterData } = useSelector(({ topKeywords }) => topKeywords)
  const { addWebsiteDataForPopup, addWebsiteloader } = useSelector(({ startFreeTrial }) => startFreeTrial)
  const { toastMessage, toastError, supportRequestLoader } = useSelector(({ register }) => register)

  const [siteOutageError, setSiteOutageError] = useState(false)
  const [siteOutageLoader, setSiteOutageLoader] = useState(true)
  const [avgData, setAvgData] = useState('')
  const [lastUpdateAt, setLastUpdatedAt] = useState('-')
  const [lastFoundAt, setLastFoundAt] = useState('-')
  const [topKeywordsData, setTopKeywordsData] = useState([])
  const [avgKeywordPositionData, setAvgKeywordPositionData] = useState(0)
  const [isTabChanged, setIsTabChanged] = useState(false)
  const [negativeCounter, setNegativeCounter] = useState(0)
  const [competitorSeoPercentage, setCompetitorSeoPercentage] = useState(0)
  const [finalSeoScore, setFinalSeoScore] = useState(0)

  const [competitorThreshold, setCompetitorThreshold] = useState(false)
  const [upgradeToggle, setUpgradeToggle] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [popup, setPopup] = useState(false)
  const [removeButtonLoader, setRemoveButtonLoader] = useState(false)
  const [ignoreButtonLoader, setIgnoreButtonLoader] = useState(false)
  const [redMarkerSiteOutageFlag, setRedMarkerSiteOutageFlag] = useState(false)
  const [trialWarning, setTrialWarning] = useState(false)
  const [timezoneModal, setTimezoneModal] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const interval = useRef(null)
  const getUserDataInterval = useRef(null)
  const backlinksInterval = useRef(null)
  const backlinksStatsInterval = useRef(null)
  const patchUserRemainInterval = useRef(null)

  const checkThreshold = {
    ENTERPRISE: 5,
    BUSINESS_PRO: 3,
    BUSINESS_PLUS: 1,
  }

  let competitorThreshold1,
    keywordThreshold = 1

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  const competitorsLength = activeSearchData?.competitors?.length || 0
  const mainUrlScanStatus = urlScanDetails?.[activeLink]?.data?.[0]?.isScanCompleted
  const competitiorScanStatus = urlScanDetails?.[activeLink]?.data?.[0]?.isCompetitorScanCompleted

  if (userData && userData.planData && userData?.planData.name === PLAN.PRO_PLAN.name) {
    competitorThreshold1 = checkThreshold.BUSINESS_PRO
    keywordThreshold = 100
  } else if (userData && userData.planData && userData?.planData.name === PLAN.BASIC_PLAN.name) {
    competitorThreshold1 = checkThreshold.BUSINESS_PLUS
    keywordThreshold = 50
  } else if (userData && userData.planData && userData?.planData.name === PLAN.PREMIUM_PLAN.name) {
    competitorThreshold1 = checkThreshold.ENTERPRISE
    keywordThreshold = 300
  }

  useEffect(() => {
    const userData = localStorage.getItem('userData')
    if (userData && Object.keys(JSON.parse(userData))?.length) {
      if (JSON.parse(userData)?.timezone) {
        return
      } else {
        setTimezoneModal(true)
      }
    }
  }, [])

  useEffect(() => {
    if (!supportRequestLoader && (toastError || toastMessage)) {
      if (toastMessage) {
        message.success(toastMessage)
      } else if (toastError) {
        message.error(toastError)
      } else {
        message.warn('Something went wrong')
      }
      setTimeout(() => {
        dispatch({ type: 'CLEAR_SUPPORT_REQUEST' })
      }, 1500)
    }
  }, [supportRequestLoader])

  useEffect(() => {
    if (
      usersDomain &&
      activeLink > -1 &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.items &&
      usersDomain[activeLink]?.items?.length &&
      averagePositionMasterData &&
      averagePositionMasterData?.length
    ) {
      const data = averagePositionMasterData?.filter((element) => {
        const redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        const avgPostionMainurl = element?.url
        const isUrlMatched = isMainUrlMatch(avgPostionMainurl, redirect_url)

        return isUrlMatched
      })
      setAvgKeywordPositionData(data?.[0]?.data?.[0]?.avgPosition)
      setAvgData(data)
    }
  }, [usersDomain, activeLink, averagePositionMasterData])

  useEffect(() => {
    getCompetitorLength()
    if (usersDomain && usersDomain?.length) {
      if (userData?.subscriptions?.[0]?.isCancel) {
        setUpgradeToggle(false)
      } else if (userData?.planData?.name === PLAN.PREMIUM_PLAN.name) {
        setUpgradeToggle(false)
      } else if (usersDomain?.length === 3 && userData?.planData?.name === PLAN.PRO_PLAN.name) {
        setUpgradeToggle(true)
      } else if (usersDomain?.length === 1 && userData?.planData?.name === PLAN.BASIC_PLAN.name) {
        setUpgradeToggle(true)
      } else {
        setUpgradeToggle(false)
      }
    }
  }, [usersDomain, activeLink, userData])

  // Component Will mount
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(lightHouseSuccessClear())
    dispatch(setStartScan(false))
    dispatch(ifwebSiteData(false))
    dispatch(openModalLighthouse(false))
    async function fetchMyAPI() {
      dispatch({ type: 'CLEAR_URL_DETAILS' })
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
      dispatch({ type: 'CLEAR_GET_LIGHTHOUSE_STATUS' })
      const data = await localStorage.getItem('userData')
      await verifyTokenStart()
      if (data && JSON.parse(data)._id) {
        // if (JSON.parse(data)?.websites?.length) {
        dispatch(storeUserData(JSON.parse(data)))
        dispatch(getCountries())
        dispatch(getLocations())
        dispatch(getNotifications())
        dispatch(geturlScanStatus())
        dispatch(setGraphPayload({ dateRange: 'last7days' }))
        setTimeout(() => {
          dispatch(getAvailableKeywordLimit())
          dispatch(patchUserDomainsDashboard())
          dispatch(getUserData())
          dispatch(getTopFiveKeywordApi())
          dispatch(getAvgPositionKeywordApi())
          dispatch(getTestResults())
        }, 2000)

        setTimeout(() => {
          dispatch(getBacklinksStats())
          dispatch(getSiteOutageData())
        }, 3500)

        setTimeout(() => {
          dispatch(getSiteOutageData())
        }, 5000)
        // }
      } else {
        navigate('/')
      }
      patchDomains()
      patchUserData()
      patchBacklinks()
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    async function redirectPage() {
      let data = await localStorage.getItem('userData')
      if (addWebsiteloader && userData && userData?.websites?.length > 0) {
        let updatedUserData = {
          ...userData,
          token: JSON.parse(data)?.token,
        }
        await localStorage.removeItem('userData')
        await localStorage.setItem('userData', JSON.stringify(updatedUserData))
        dispatch(stopAddWebsiteLoader())
      }

      data = await localStorage.getItem('userData')
      if (
        JSON.parse(data)?.subscriptions?.length &&
        (JSON.parse(data)?.subscriptions?.[0]?.isActive || JSON.parse(data)?.subscriptions?.[0]?.isTrial)
      ) {
        if (data && !JSON.parse(data)?.websites?.length) {
          if (!addWebsiteloader) {
            navigate(paths.newUserWelcomePage)
          }
        }
      } else {
        if (!addWebsiteloader) {
          navigate(paths.newUserSubscriptionPage)
        }
      }
    }
    redirectPage()
  }, [addWebsiteloader, userData])

  // Component Will unmount
  useEffect(() => {
    return () => {
      clearInterval(interval.current)
      clearInterval(getUserDataInterval.current)
      clearInterval(backlinksInterval.current)
      clearInterval(backlinksStatsInterval.current)
      clearInterval(patchUserRemainInterval.current)
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
    }
  }, [])

  useEffect(() => {
    if (isTabChanged) {
      if (!stopPatchApiCall && usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
        clearInterval(interval.current)
        clearInterval(backlinksInterval.current)
        clearInterval(backlinksStatsInterval.current)
        patchDomains()
        patchBacklinks()
      }

      if (usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
        clearInterval(getUserDataInterval.current)
        patchUserData()
      }

      if (competitorSeoPercentage < 100 && !interval && !interval.current) {
        patchDomains()
      }
    }
  }, [activeLink, competitorSeoPercentage])

  useEffect(() => {
    if (stopPatchApiCall) {
      clearInterval(interval.current)
      clearInterval(backlinksInterval.current)
    }
    if (stopBacklinksStatsApiCall) {
      clearInterval(backlinksStatsInterval.current)
    }
  }, [stopPatchApiCall, stopBacklinksStatsApiCall])

  useEffect(() => {
    if (
      (backlinksDataForPopup?.statusCode === 500 && backlinksDataForPopup?.competitorUrls?.length) ||
      (addWebsiteDataForPopup?.statusCode === 500 && addWebsiteDataForPopup?.competitorUrls?.length)
    ) {
      setPopup(true)
    }
  }, [
    backlinksDataForPopup?.statusCode,
    backlinksDataForPopup?.competitorUrls?.length,
    addWebsiteDataForPopup?.statusCode,
    addWebsiteDataForPopup?.competitorUrls?.length,
  ])

  useEffect(() => {
    if (!removeCompetitorsLoader) {
      setIgnoreButtonLoader(false)
      setRemoveButtonLoader(false)
    }
  }, [removeCompetitorsLoader])

  useEffect(() => {
    async function triggerOnChange() {
      if (usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
        const userData = await localStorage.getItem('userData')
        const userDataParse = JSON.parse(userData)
        const mainUrlList = []
        if (userData && userDataParse?._id) {
          usersDomain &&
            usersDomain?.length &&
            usersDomain.forEach((domain) => {
              if (domain?.items && domain?.items?.length) {
                domain?.items.forEach((domainItem) => {
                  if (userDataParse?._id == domainItem?.user) {
                    mainUrlList.push(domainItem?.urlDataId ? domainItem?.urlDataId : domainItem?._id)
                  }
                })
              }
            })
          let finalUrl = mainUrlList?.toString()
          if (finalUrl && finalUrl?.length && finalUrl?.includes(',')) {
            finalUrl = finalUrl.split(',')
          } else {
            finalUrl = [finalUrl]
          }
          if (finalUrl && finalUrl?.length) {
            if (progressPercentage && progressPercentage > 99) {
            } else {
              dispatch(getMainUrlSeoScore({ mainUrlList: finalUrl }))
            }
            if (competitorsLength) {
              if (competitorSeoPercentage > -1 && competitorSeoPercentage < 100) {
                dispatch(getCompetitorSeoScore({ mainUrlList: finalUrl }))
              } else {
              }
            }
          }
        }
        let { hostName: urlHostName } = getActiveUserData(usersDomain, activeLink, userData)
        if (urlHostName.includes('www.')) {
          urlHostName = urlHostName.replace('www.', '')
        }
        if (dashboardTopKeywords && dashboardTopKeywords?.length) {
          const filteredKeywordsData = dashboardTopKeywords.filter((element) => {
            return usersDomain[activeLink].items[0]?.url === element.url
          })
          setTopKeywordsData(filteredKeywordsData?.[0]?.data)
        }
        let totalCompleted = 0

        setLastUpdatedAt(
          moment.utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MMM DD, YYYY hh:mm:ss A'),
        )
        setLastFoundAt(moment.utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MMM DD, YYYY'))
        for (let key in usersDomain?.[activeLink]?.items?.[0]) {
          if (
            (usersDomain?.[activeLink]?.items?.[0] &&
              usersDomain?.[activeLink]?.items?.[0]?.[key] &&
              usersDomain?.[activeLink]?.items?.[0]?.[key] > 0) ||
            (usersDomain?.[activeLink]?.items?.[0]?.[key] &&
              usersDomain?.[activeLink]?.items?.[0]?.[key].seoCheckup) ||
            (usersDomain?.[activeLink]?.items?.[0]?.[key] &&
              usersDomain?.[activeLink]?.items?.[0]?.[key].length) ||
            (usersDomain?.[activeLink]?.items?.[0]?.[key]?._id &&
              usersDomain?.[activeLink]?.items?.[0]?.[key]?._id.length) ||
            (usersDomain?.[activeLink]?.items?.[0]?.[key]?.id &&
              usersDomain?.[activeLink]?.items?.[0]?.[key]?.id.length) ||
            (usersDomain?.[activeLink]?.items?.[0]?.[key]?.category &&
              usersDomain?.[activeLink]?.items?.[0]?.[key]?.category.length)
          ) {
            totalCompleted++
          } else {
          }
        }
        if (
          activeSiteOutageData &&
          activeSiteOutageData.length &&
          activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset &&
          activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length &&
          activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset[
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length - 1
          ]?.values.length > 12 &&
          activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset[
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length - 1
          ]?.values?.[0].url?.includes(urlHostName)
        ) {
          if (
            activeSiteOutageData &&
            activeSiteOutageData.length &&
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset &&
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length &&
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset[
              activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length - 1
            ]?.values &&
            activeSiteOutageData &&
            activeSiteOutageData.length &&
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset &&
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length &&
            activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset[
              activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length - 1
            ]?.values.length >= 13
          ) {
            setSiteOutageLoader(false)
            totalCompleted++
          } else {
            setSiteOutageLoader(true)
          }
        } else {
        }

        if (
          activeSiteOutageData &&
          activeSiteOutageData?.length &&
          activeSiteOutageData?.[0]?.dataset &&
          activeSiteOutageData?.[0]?.dataset.length &&
          activeSiteOutageData?.[0]?.dataset[activeSiteOutageData?.[0]?.dataset.length - 1]?.values.length
        ) {
          const todayData =
            (activeSiteOutageData &&
              activeSiteOutageData?.length &&
              activeSiteOutageData?.[0]?.dataset &&
              activeSiteOutageData?.[0]?.dataset?.length &&
              activeSiteOutageData?.[0]?.dataset) ||
            []

          let siteOutageData = _.sortBy(todayData, 'hour')
          siteOutageData = siteOutageData?.[0]?.values || []
          const dataForMap =
            siteOutageData?.length &&
            siteOutageData?.filter((item) => {
              if (item.status !== '200') {
                return true
              }
            })

          if (dataForMap && dataForMap.length) {
            setSiteOutageError(true)
          } else {
            setSiteOutageError(false)
          }
        }

        const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)
        const localKeywords =
          (activeSearchData &&
            activeSearchData.keywords &&
            activeSearchData.keywords.length &&
            activeSearchData.keywords.map((item) => {
              return { name: item }
            })) ||
          []

        const tableNewDataTemp =
          (activeKeywordData &&
            activeKeywordData?.data &&
            activeKeywordData?.data.length &&
            activeKeywordData?.data[activeKeywordData?.data.length - 1]?.dataset &&
            activeKeywordData?.data[activeKeywordData?.data.length - 1]?.dataset.map((item) => {
              return {
                name: item.keyword,
                regions: item.regions,

                volumnData: item.volumnData,
              }
            })) ||
          []

        const filteredKeywords = tableNewDataTemp.filter((element) => {
          let flag = false
          localKeywords.forEach((element1) => {
            if (element1.name === element.name) {
              flag = true
            }
          })
          return flag
        })

        const tableFilteredData = []

        activeSearchData?.keywords?.length &&
          activeSearchData?.keywords.forEach((element) => {
            const data = findLastIndex(filteredKeywords, function (o) {
              return o.name == element
            })

            if (data > -1) {
              tableFilteredData.push(filteredKeywords[data])
            }
          })

        activeSearchData?.keywords?.length &&
          activeSearchData?.keywords.forEach((element) => {
            let found = false
            tableFilteredData.forEach((innerElement) => {
              if (innerElement.name === element) {
                found = true
              }
            })

            if (found === false) {
              keywordsCompleted = false
            }
          })
      }
    }
    triggerOnChange()
  }, [usersDomain, activeLink, activeSiteOutageData, chartDataForAntD, activeKeywordData])

  useEffect(() => {
    if (usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
      const data = getDataForDashboard(testResultData?.[activeLink]?.results?.[0])
      setNegativeCounter(data?.negativeCounter)
      const filteredData =
        mainUrlScoreDataArray?.length &&
        mainUrlScoreDataArray.filter((item) => {
          let mainUrl = item?.data?.[0]?.mainUrl
          let redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url

          const isUrlMatched = isMainUrlMatch(mainUrl, redirect_url)
          return isUrlMatched
        })
      const result =
        filteredData?.length &&
        filteredData
          .filter((item) => {
            return item.data.some((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment
                .utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt)
                .format('MM-DD')
              return checkDate === lastUpdatedDate
            })
          })
          .map((item) => {
            return {
              id: item._id,
              data: item.data.filter((obj) => {
                const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                  'MM-DD',
                )
                const lastUpdatedDate = moment
                  .utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt)
                  .format('MM-DD')
                return checkDate === lastUpdatedDate
              }),
            }
          })
      const sum =
        result?.length &&
        result?.[0]?.data.reduce((accumulator, subItem) => {
          return accumulator + subItem.count
        }, 0)
      let totalSeoScore

      if (sum) {
        totalSeoScore = sum + finalSeoScore
      } else {
        totalSeoScore = finalSeoScore
      }
      let totalCompetitorPercent
      if (competitorSeoScoreData?.length > 0) {
        const filterDataOfCompetitor = []
        competitorSeoScoreData.forEach((item) => {
          item?.data &&
            item?.data?.length &&
            item?.data?.forEach((itemData) => {
              let mainUrl = itemData?.mainUrl

              let redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url

              const isUrlMatched = isMainUrlMatch(mainUrl, redirect_url)

              if (isUrlMatched) {
                const dataToPush = {
                  ...itemData,
                  competitiorUrl: item._id,
                }
                filterDataOfCompetitor.push(dataToPush)
              }
            })
        })

        const updatedFilteredCompetitorData = filterDataOfCompetitor?.filter((item) => {
          return activeSearchData?.competitors?.includes(item?.competitiorUrl)
        })
        const todayFilterDataOfCompetitor = updatedFilteredCompetitorData.filter((item) => {
          const checkDate = moment(`${item?.year}-${item?.month}-${item?.day}`, 'YYYY-MM-DD').format('MM-DD')
          const lastUpdatedDate = moment.utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')

          return checkDate === lastUpdatedDate
        })

        const sumForToday = todayFilterDataOfCompetitor.reduce((accumulator, subItem) => {
          return accumulator + subItem.count
        }, 0)
        dispatch({ type: 'SEO_COMPETITOR_PERCENTAGE_ACTUAL', payload: sumForToday })

        if (competitorsLength === 1) {
          totalCompetitorPercent = (sumForToday / 70) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 2) {
          totalCompetitorPercent = (sumForToday / 140) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 3) {
          totalCompetitorPercent = (sumForToday / 210) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 4) {
          totalCompetitorPercent = (sumForToday / 280) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 5) {
          totalCompetitorPercent = (sumForToday / 350) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 6) {
          totalCompetitorPercent = (sumForToday / 420) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        }
      } else {
      }

      if (totalSeoScore !== 0) {
        let totalPercent
        if (finalSeoScore === 0) {
          totalPercent = (totalSeoScore / 74) * 100
        } else {
          totalPercent = (totalSeoScore / 84) * 100
        }

        if (competitorsLength) {
          if (totalPercent >= 100 && totalCompetitorPercent >= 100) {
            setTimeout(() => {
              dispatch({ type: 'STOP_DASHBOARD_PATCH_API_CALLS' })
              dispatch({ type: 'STOP_BACKLINKS_STATS_API_CALL' })
              clearInterval(getUserDataInterval.current)
            }, 2000)
          }
        } else {
          if (totalPercent >= 100) {
            setTimeout(() => {
              dispatch({ type: 'STOP_DASHBOARD_PATCH_API_CALLS' })
              dispatch({ type: 'STOP_BACKLINKS_STATS_API_CALL' })
              clearInterval(getUserDataInterval.current)
            }, 2000)
          }
        }

        dispatch({ type: 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE', payload: totalPercent })
        dispatch({
          type: 'UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE',
          payload: totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent?.toFixed(0),
        })
        setProgressPercentage(totalPercent.toFixed(0))
      } else {
        if (!totalSeoScore || totalSeoScore === 0 || totalSeoScore === undefined) {
          dispatch({ type: 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE', payload: 1 })
          setProgressPercentage(1)
          if (competitorsLength > 0) {
            dispatch({ type: 'UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE', payload: 1 })
          }
        } else {
          let totalPercent = (totalSeoScore / 75) * 100

          dispatch({ type: 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE', payload: totalPercent.toFixed(0) })
          setProgressPercentage(totalPercent.toFixed(0))

          if (competitorsLength > 0) {
            dispatch({
              type: 'UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE',
              payload: totalCompetitorPercent?.toFixed(0),
            })
          }
        }
      }
    }
  }, [usersDomain, activeLink, competitorSeoScoreData, finalSeoScore])

  useEffect(() => {
    if (!persnoalInfoLoading && personalInfoMessage) {
      if (personalInfoSuccess) {
        message.success('Default toronto timezone set successfully')
        dispatch({ type: PERSONAL_INFO_UPDATE_CLEAR })
        setTimezoneModal(false)
      } else if (personalInfoFailure) {
        message.error(personalInfoMessage)
        dispatch({ type: PERSONAL_INFO_UPDATE_CLEAR })
        setTimezoneModal(false)
      } else {
        message.warn(personalInfoMessage)
        dispatch({ type: PERSONAL_INFO_UPDATE_CLEAR })
        setTimezoneModal(false)
      }
    }
  }, [persnoalInfoLoading, personalInfoMessage, personalInfoSuccess, personalInfoFailure])

  const getCompetitorLength = () => {
    if (usersDomain && usersDomain?.length && competitorThreshold1 === checkThreshold.ENTERPRISE) {
      if (usersDomain?.length < checkThreshold.ENTERPRISE) {
        setCompetitorThreshold(true)
      }
    } else if (usersDomain && usersDomain?.length && competitorThreshold1 === checkThreshold.BUSINESS_PRO) {
      if (usersDomain?.length < checkThreshold.BUSINESS_PRO) {
        setCompetitorThreshold(true)
      }
    } else if (usersDomain && usersDomain?.length && competitorThreshold1 === checkThreshold.BUSINESS_PLUS) {
      if (usersDomain?.length < checkThreshold.BUSINESS_PLUS) {
        setCompetitorThreshold(true)
      }
    }
  }

  const patchDomains = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id && !stopPatchApiCall) {
      interval.current = setInterval(() => {
        if (JSON.parse(data)._id) {
          dispatch(patchUserDomainsDashboard())
          dispatch(getSiteOutageData())
          dispatch(geturlScanStatus())
          dispatch(getTestResults())
          dispatch(setGraphPayload({ dateRange: 'last7days' }))
        }
      }, 10000)
    }
  }

  const patchBacklinks = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      if (!stopPatchApiCall) {
        backlinksInterval.current = setInterval(() => {
          if (
            usersDomain &&
            activeLink !== undefined &&
            usersDomain[activeLink] &&
            usersDomain[activeLink].items &&
            usersDomain[activeLink].items.length &&
            usersDomain[activeLink].items?.[0] &&
            usersDomain[activeLink].items?.[0]?.['_id']
          ) {
            dispatch(getUrlReport(usersDomain[activeLink].items?.[0]?.['_id']))
          }
          dispatch(getBacklinksStats())
          dispatch(getTopFiveKeywordApi())
          dispatch(getSiteOutageData())
          dispatch(getAvgPositionKeywordApi())
        }, 20000)
      }
      backlinksStatsInterval.current = setInterval(() => {
        dispatch(getBacklinksStats())
      }, 10000)
    }
  }

  const patchUserData = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      getUserDataInterval.current = setInterval(() => {
        dispatch(getUserData())
      }, 10000)
    }
  }

  useEffect(() => {
    let totalCount = 0
    const dashboardItem = usersDomain && activeLink !== undefined && usersDomain?.[activeLink]

    if (
      dashboardItem?.items?.[0]?.performance ||
      dashboardItem?.items?.[0]?.accessbility ||
      dashboardItem?.items?.[0]?.bestPractices
    ) {
      totalCount++
    }
    if (dashboardItem?.statsData?.[0]?.googlePageRank) {
      totalCount++
    }
    if (dashboardItem?.statsData?.[0]?.siteIP || dashboardItem?.statsData?.[0]?.siteIP === null) {
      totalCount++
    }

    if (dashboardItem?.statsData?.[0]?.backlinks || dashboardItem?.statsData?.[0]?.backlinks === 0) {
      totalCount++
    }

    // if(avgData) {
    totalCount++
    // }

    if (dashboardItem?.items?.[0]?.desktopSpeed?.displayValue) {
      totalCount++
    }

    if (dashboardItem?.items?.[0]?.mobileSpeed?.displayValue) {
      totalCount++
    }

    if (
      activeSiteOutageData?.[activeSiteOutageData?.length - 1]?.dataset?.[
        activeSiteOutageData?.[activeSiteOutageData?.length - 1]?.dataset?.length - 1
      ]?.values.length >= 13
    ) {
      totalCount++
    }

    if (
      dashboardItem?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts !== null &&
      dashboardItem?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts !== undefined
    ) {
      totalCount++
    }

    if (
      dashboardItem?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls !== null &&
      dashboardItem?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls !== undefined
    ) {
      totalCount++
    }

    setFinalSeoScore(totalCount)
    if (usersDomain?.[activeLink]?.items?.[0]?.indexUrlTest?.[0]?.data && chartDataForAntD?.[0]?.y) {
      clearInterval(patchUserRemainInterval.current)
    }
  }, [usersDomain, activeLink, activeSiteOutageData, chartDataForAntD, activeKeywordData])

  useEffect(() => {
    if (!mainUrlScanStatus && seoProgressPercentage >= 100) {
      dispatch(urlScanStatus({ mainUrlScan: true, urlDataId: usersDomain[activeLink]?.items[0]?.urlDataId }))
    }

    if (competitorsLength > 0) {
      if (!competitiorScanStatus && seoCompetitorPercentage >= 100) {
        dispatch(
          urlScanStatus({ competitorUrlScan: true, urlDataId: usersDomain[activeLink]?.items[0]?.urlDataId }),
        )
      }
    }
  }, [mainUrlScanStatus, competitiorScanStatus, seoProgressPercentage, seoCompetitorPercentage])

  const deleteCompetitors = () => {
    const url = usersDomain?.[activeLink]?.items[0]?.url
    const data = {
      competitorUrls: backlinksDataForPopup?.competitorUrls || addWebsiteDataForPopup?.competitorUrls,
      userId,
      url,
    }
    dispatch(removeCompetitors(data))
    setTimeout(() => {
      if (!removeCompetitorsLoader) {
        setPopup(false)
      }
    }, 3000)
  }

  const setDefaultTimezone = () => {
    const userDataFromLocalStorage = localStorage.getItem('userData')
    const defaultTimezone = 'America/Toronto'
    const updateUser = {
      ...JSON.parse(userDataFromLocalStorage),
      timezone: defaultTimezone,
    }
    const payload = {
      userData: {
        timezone: defaultTimezone,
      },
    }
    dispatch(personalInfoUpdate(payload))
    dispatch(storeUserData(updateUser))
  }

  return (
    <>
      <Modal
        show={popup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
      >
        <Modal.Header>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h3 style={{ margin: 0 }}>Competitor Scan Alert!</h3>
            <ExclamationMark style={{ height: '58px' }} />
          </div>
        </Modal.Header>

        <Modal.Body>
          <p>{backlinksDataForPopup?.message || addWebsiteDataForPopup?.message}</p>
          <br />

          <p>Would you like to proceed using PositionMySite's cached version of the website?</p>
          <ul style={{ listStyleType: 'none', padding: '0' }}>
            {backlinksDataForPopup?.competitorUrls
              ? backlinksDataForPopup?.competitorUrls?.map((item, index) => {
                  return <li key={`competitorList -${index + 1}`}>{item}</li>
                })
              : addWebsiteDataForPopup?.competitorUrls?.map((item, index) => {
                  return <li key={`competitorList -${index + 1}`}>{item}</li>
                })}
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '7px', justifyContent: 'flex-start' }}
            >
              <span>
                <Button color="primary">Yes</Button>
              </span>
              <span>
                <Button
                  style={{ background: '#D6982A', border: 'none' }}
                  onClick={() => {
                    setRemoveButtonLoader(true)
                    deleteCompetitors()
                  }}
                >
                  {removeButtonLoader && removeCompetitorsLoader ? (
                    <div className="text-center">
                      <Spinner size="sm" animation="border" key={1} variant="primary" />
                    </div>
                  ) : (
                    'Remove Website Competitor'
                  )}
                </Button>
              </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="primary"
                style={{ background: '#D8534F', border: 'none' }}
                onClick={() => {
                  setIgnoreButtonLoader(true)
                  deleteCompetitors()
                }}
              >
                {ignoreButtonLoader && removeCompetitorsLoader ? (
                  <div className="text-center">
                    <Spinner size="sm" animation="border" key={1} variant="primary" />
                  </div>
                ) : (
                  'Ignore'
                )}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={timezoneModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
      >
        <Modal.Header>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h3 style={{ margin: 0 }}>Timezone alert!</h3>
          </div>
        </Modal.Header>

        <Modal.Body>
          <p>
            🎉 Congratulations! You've taken the first step towards mastering your SEO. To ensure we pull data
            at the most convenient time for you, please let us know your timezone. If you don't provide it,
            we'll default to midnight in the Toronto timezone (UTC-5). 🚀
          </p>
          <br />

          <p>Ready to optimize? Let's get started!</p>
        </Modal.Body>

        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '7px', justifyContent: 'flex-start' }}
            >
              <span>
                <Button
                  color="primary"
                  onClick={() => {
                    navigate(paths.profilePage)
                    setTimezoneModal(false)
                  }}
                >
                  Add timezone
                </Button>
              </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="primary"
                style={{ background: '#D8534F', border: 'none' }}
                disabled={persnoalInfoLoading}
                onClick={() => setDefaultTimezone()}
                className="d-flex justify-content-center align-items-center gap-1"
              >
                Ignore
                {persnoalInfoLoading ? (
                  <Spinner size="sm" animation="border" key={1} variant="primary" />
                ) : null}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {upgradeToggle ? (
        <Alert variant="warning" className="alert-outline-coloured" key="meta-title">
          <div className="alert-icon" />
          <div className="alert-message">
            <span className="alert-for-mobile">
              <p className="mb-0">
                Your limit for adding website is reached, please consider to:{' '}
                <span onClick={() => navigate(paths.upgradePlan)} className={'text-danger cursor-pointer'}>
                  Upgrade your plan
                </span>
              </p>
            </span>
          </div>
        </Alert>
      ) : null}

      <Helmet title="Dashboard Page" />
      <Container fluid className="p-0 dashboard">
        <Row>
          <SpaceProgress />
          <UrlScanStatus
            progressPercentage={progressPercentage}
            competitorSeoPercentage={competitorSeoPercentage}
            competitorsLength={competitorsLength}
            lastUpdateAt={lastUpdateAt}
          />
          <Col lg="2" xs="12 mb-4" sm="12 mb-4">
            <GooglePageRank />
          </Col>
          <Col lg="2" xs="12 mb-4" sm="12 mb-4">
            <div className="card-container-dashboard" style={{ alignItems: 'start', paddingLeft: 20 }}>
              {usersDomain && usersDomain?.length ? (
                <>
                  <p className="mb-1 fw-bold">Your Current Plan</p>
                  <p className="mb-1">
                    {(userData && userData.planData && userData.planData.name) || ''}
                    {userData?.isTrialInUse && ' (Trial Plan)'}
                  </p>
                  <p className="mb-1 fw-bold">Currently Using</p>
                  <p className="mb-2">
                    {(activeSearchData && activeSearchData.keywords && activeSearchData.keywords.length) || 0}
                    / {keywordThreshold || ''} Keywords
                  </p>
                  <p className="mb-1 fw-bold">Currently Tracking</p>
                  <p className="mb-1">
                    {(usersDomain && usersDomain?.length) || 0} / {competitorThreshold1 || ''} URL's
                  </p>
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="text-center">
                    <Loader size="sm" />
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0} activeKey={activeLink || 0}>
          <Nav
            variant="tabs"
            onSelect={(selectedKey) => {
              dispatch(changeActiveLink(parseInt(selectedKey)))
              dispatch(getUrlReport(usersDomain?.[selectedKey]?.items?.[0]?._id))
              setIsTabChanged(true)
            }}
          >
            {usersDomain && usersDomain?.length ? (
              <>
                {usersDomain?.map((item, index) => {
                  return <MainUrlNav key={index + 1} item={item} index={index} />
                })}
                {trialWarning ? (
                  <Modal
                    show={trialWarning}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => {
                      setTrialWarning(false)
                    }}
                    style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
                    className="br-15 pe-0"
                  >
                    <Modal.Header>
                      <h3 style={{ margin: 0 }}>You are using trial version!</h3>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '12px 0' }}>
                      Your trial period will end tomorrow, you will be able to add more websites starting
                      tomorrow.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={() => setTrialWarning(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                ) : null}
                {competitorThreshold ? (
                  <Button
                    className="m-1 plus-button-desktop"
                    color="primary dashboard-checkup-url-btn"
                    onClick={() => {
                      if (userData?.isTrialInUse) {
                        setTrialWarning(true)
                      } else {
                        navigate({
                          pathname: '/dashboard/add-website',
                          search: '',
                        })
                        dispatch(isCallForAddWebsite('dashboard'))
                      }
                    }}
                  >
                    +
                  </Button>
                ) : null}
              </>
            ) : (
              <DashboardLoading />
            )}
          </Nav>
          <Tab.Content>
            {usersDomain && usersDomain.length ? (
              <>
                {usersDomain.map((item, index) => {
                  return (
                    <Tab.Pane eventKey={index} key={`NavContent${index + 1}`}>
                      <Row>
                        <Col className="mt-3">
                          {competitorThreshold ? (
                            <Button
                              className="add-website-mobile"
                              color="primary dashboard-checkup-url-btn"
                              onClick={() => {
                                navigate(paths.dashboardAddWebsite)
                              }}
                            >
                              Add Website
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mb-0 mt-3">
                        <Col lg="6" xl="3" xs="12 mb-4" className="fixed-height">
                          <UrlDetail
                            item={item}
                            topKeywordsData={topKeywordsData}
                            activeLink={activeLink}
                            siteOutageError={siteOutageError}
                            siteOutageLoader={siteOutageLoader}
                            avgPosition={avgKeywordPositionData}
                            avgData={avgData}
                            avgPreviousPosition={avgKeywordPositionData}
                            progressPercentage={parseInt(progressPercentage)}
                            graphData={dashboardGraphData}
                            activeSearchData={activeSearchData}
                          />
                        </Col>
                        <Col lg="6" xl="5" xs="12" className="fixed-height mt-10 mt-md-0">
                          <SeoScoreProgress
                            item={item}
                            SeoProgressPercentage={
                              progressPercentage >= 100 ? 100 : parseInt(progressPercentage)
                            }
                          />
                        </Col>
                        <Col lg="12" xs="12 mb-3" xl="4" className="fixed-height mt-4 mt-md-0">
                          <Card className="url-details mb-2">
                            <Card.Header className="url-details-card-header">
                              <div className="url-details-title">
                                <Card.Title tag="h5" className="mb-0 f-bold">
                                  Issues to fix
                                </Card.Title>
                                <p className="mb-0">Last found on {lastFoundAt}</p>
                              </div>
                              <div className="card-actions float-end">
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => {
                                    navigate(paths.signalScanPage, {
                                      state: { url: item },
                                    })
                                  }}
                                >
                                  View Report
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              {testResultData?.[activeLink]?.results === 0 ||
                              testResultData?.[activeLink]?.results === undefined ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 380,
                                  }}
                                >
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {negativeCounter < 73 ? (
                                    <>
                                      <Issues
                                        data={testResultData?.[activeLink]?.websiteId || ''}
                                        issuesData={testResultData?.[activeLink]?.results?.[0] || {}}
                                        showIssueTitle
                                      />
                                      <br />
                                      <IssuesFooter
                                        data={testResultData?.[activeLink]?.websiteId || ''}
                                        issuesData={testResultData?.[activeLink]?.results?.[0] || {}}
                                      />
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1,
                                        alignSelf: 'center',
                                        flexDirection: 'row',
                                        minHeight: 380,
                                      }}
                                    >
                                      <div className="text-center">
                                        <Loader />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row className="mb-0">
                        <Col xl="12" xxl="6" className="fixed-height">
                          <Card className="url-details">
                            <Card.Header className="url-details-card-header">
                              <div className="url-details-title">
                                <Card.Title tag="h5" className="mb-0 f-bold">
                                  Backlinks Overview
                                </Card.Title>
                                <p className="mb-0">Over the last 30 days</p>
                              </div>
                              <div className="card-actions float-end">
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => navigate(paths.backlinksSignal)}
                                >
                                  View All Backlinks
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              {usersDomain &&
                              activeLink !== undefined &&
                              usersDomain[activeLink] &&
                              usersDomain[activeLink]?.statsData?.[0]?.backlinks ? (
                                <>
                                  <BacklinksHeader usersDomain={usersDomain} activeLink={activeLink} />
                                  <BacklinksGraph usersDomain={usersDomain} activeLink={activeLink} />
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 380,
                                  }}
                                >
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </div>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xl="12" xxl="6" className="fixed-height">
                          <DashboardTopKeywords progressPercentage={parseInt(progressPercentage)} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card className="url-details">
                            <Card.Header className="url-details-card-header">
                              <div className="url-details-title">
                                <Card.Title tag="h5" className="mb-0 f-bold">
                                  Top Keywords
                                </Card.Title>
                                <div className="d-flex align-items-center gap-2">
                                  <p className="mb-0">Over the last 7 days</p>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        In the dashboard, we are tracking the movement of your keywords over
                                        seven days for your primary location only. Inside the Real Time
                                        Keyword Tracking Tool, you can see the average movement of keywords
                                        for all locations.
                                      </Tooltip>
                                    }
                                  >
                                    <Icon.AlertCircle
                                      className="d-none d-sm-block"
                                      size="16"
                                      color="#afafaf"
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div className="card-actions float-end">
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => navigate(paths.keywordPositionTracker)}
                                >
                                  Check your Keywords
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <TopKeywordsHeader item={item} />
                              <TopKeywordsTable
                                showHistory={true}
                                onClick={() => {
                                  navigate(paths.dashboardSettings, {
                                    state: { activeTab: '3' },
                                  })
                                }}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card className="url-details">
                            <Card.Header className="url-details-card-header">
                              <div className="url-details-title">
                                <Card.Title tag="h5" className="mb-0 f-bold">
                                  Loading Time
                                  <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        Please note that we scan websites daily, so a variation in loading
                                        speed is normal due to regular updates and changes.
                                      </Tooltip>
                                    }
                                  >
                                    <Icon.Info style={{ marginLeft: '0.5rem' }} size="16" color="#afafaf" />
                                  </OverlayTrigger>
                                </Card.Title>
                                <p className="mb-0">Over the last 7 days</p>
                              </div>
                              <div className="card-actions float-end">
                                <Button
                                  onClick={() => {
                                    navigate('/dashboard/site-speed', {
                                      state: { activeTab: '1' },
                                    })
                                  }}
                                  variant="outline-secondary"
                                >
                                  More Speed Data
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              {usersDomain?.[activeLink]?.items?.[0]?.user?.length === 0 ||
                              usersDomain?.[activeLink]?.items?.[0]?.user?.length === undefined ||
                              !item?.items[0]?.desktopSpeed ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 380,
                                  }}
                                >
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </div>
                              ) : (
                                <SpeedGraphForDashboard
                                  graphData={dashboardGraphData}
                                  activeSearchData={activeSearchData}
                                />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card className="url-details">
                            <Card.Header className="url-details-card-header">
                              <div className="url-details-title">
                                <Card.Title tag="h5" className="mb-0 f-bold">
                                  Site Outage
                                </Card.Title>
                                <p className="mb-0">Over the last 24 hours</p>
                              </div>
                              <div className="card-actions float-end">
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => navigate(paths.siteOutageTracker)}
                                >
                                  More Outage Data
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              {(activeSearchData && activeSearchData?.webCached) ||
                              (redMarkerSiteOutageFlag && activeSiteOutageData) ? (
                                <>
                                  <Alert
                                    variant="warning"
                                    className="alert-outline-coloured"
                                    key="meta-title"
                                  >
                                    <div className="alert-icon" />
                                    <div className="alert-message">
                                      <span className="alert-for-mobile">
                                        This specific test is being blocked, and therefore, we are unable to
                                        detect a ranking signal because data access is restricted by your
                                        firewall settings. To resolve this, please{' '}
                                        <Link to="/pages/firewall-options">click here</Link> for instructions
                                        on how to add PMS SEO Site Signals to your firewall's allow list.
                                      </span>
                                    </div>
                                  </Alert>
                                  <br />
                                </>
                              ) : null}
                              <WorldMap setFlagForRedMarker={setRedMarkerSiteOutageFlag} user={userId} />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  )
                })}
              </>
            ) : null}
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  )
}

export default DashboardRevised

import './demo.css'

const TestDescription = ({ className, description }) => {
  return (
    <>
      <tr>
        <td colspan="2">
          <div className={className}>
            {description}
            <br />
          </div>
        </td>
      </tr>
    </>
  )
}

export default TestDescription

import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { Card, CardBody } from 'reactstrap'

import { useDeviceCheck } from 'hooks/deviceHook'
import paths from 'constants/paths'

import Captcha from 'components/captcha.js'
import SignUp from 'components/auth/SignUp.js'
import ColoredModal from 'pages/modals/ColorModal.js'

import signUp from 'assets/img/signup/sign-up.png'
import user from 'assets/img/avatars/user.png'

import 'components/auth/auth.scss'


const SignUpPage = () => {
  const [registrationSuccess, setRegristrationSuccess] = useState(false)
  const registerSuccess = useSelector(({ register }) => register.registerSuccess)
  const registerError = useSelector(({ register }) => register.registerError)
  const errorMessage = useSelector(({ register }) => register.errorMessage)
  const registerData = useSelector(({ register }) => register.registerData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  useEffect(() => {
    if (registerSuccess) {
      dispatch({ type: 'REGISTER_CLEAR' })
      if (registerData && registerData.planData && registerData.planData.name) {
        navigate('/')
      } else {
        if (registerData && registerData.userActivated) {
          navigate(paths.newUserSubscriptionPage)
        } else {
          setRegristrationSuccess(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerSuccess, registerData])

  const onReCaptchaChange = (data) => {
    // if(data.length) {
    //     this.setState({verified: true})
    // }
  }

  return (
      <React.Fragment>
        <Helmet title="Sign Up" />
        {registerError ? (
          <ColoredModal
            isOpen={registerError}
            message={errorMessage}
            onClose={() => dispatch({ type: 'REGISTER_CLEAR' })}
          />
        ) : (
          <></>
        )}
        {registrationSuccess ? (
          <ColoredModal
            type={'success'}
            isOpen={registrationSuccess}
            message={'Thanks for Signing Up! Please check your email for verification process'}
            onClose={() => {
              setRegristrationSuccess(false)
              navigate('/')
            }}
          />
        ) : (
          <></>
        )}
      <div className={`${isMobile ? "signup-message-mobile" : 'mb-4 d-none'}`} style={{ width: isMobile ? "100vw" : ''}}>
          <div>
          <p className="sign-in-font"><b>Empower Your SEO Journey: Precision, Simplicity, Results</b></p>
          <p className="sign-in-font">Ready to Unlock Full Signal SEO Analysis & Reporting? Sign Up Now!</p>

          <Button
            className='mt-4 d-flex flex-row justify-content-start align-items-center gap-1 d-lg-none d-md-none'
            style={{ fontSize: 12, background: 'white', border: '1px solid grey', color: 'grey', width: '200px', padding: '6px', fontWeight: '500' }}
            onClick={() => navigate("/auth/sign-in")}
          >
            <img
              alt="user"
              src={user}
              className="rounded-circle img-responsive"
              width="15"
              height="15"
              style={{ marginRight: '5px', marginLeft: '5px' }}
            />I Already Have An Account
          </Button>
          </div>
          <div className='signup-female-mobile'>
            <img
              src={signUp}
              width="160"
              height="190"
            />
          </div>
      </div>
      <Card className={isMobile ? "mb-0" : 'sign-up-card-container'} >
        <CardBody className={`pt-0 ${isMobile ? '' : 'sign-up-card pb-0'}`} style={{background:'#f0f4fc'}}>
          <div className="m-sm-4">
            <SignUp />
          </div>
          <Captcha onChange={onReCaptchaChange} />
          </CardBody>
        </Card>
          {isMobile ? null : (<div className="sign-up-img">
            <img src={signUp} alt="sign up" width="500" height="650" />
          </div>)}
      </React.Fragment>
  )
}

export default SignUpPage

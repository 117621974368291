import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGaugeSimpleHigh,
  faUserDoctor,
  faRankingStar,
  faLink,
  faHashtag,
  faTableList,
  faFilePen,
  faLinkSlash,
  faHardDrive,
  faNotesMedical,
  faFileWaveform,
  faFileSignature,
  faEnvelope,
  faChartLine,
  faUsers,
  faStopwatch,
  faTachometer,
} from '@fortawesome/free-solid-svg-icons'

const ICON1 = (icon) => {
  return <FontAwesomeIcon className="align-middle me-2" icon={icon} fixedWidth />
}

const pagesSection = [
  {
    href: '/dashboard/revised',
    icon: () => ICON1(faGaugeSimpleHigh),
    title: 'Dashboard',
  },
  {
    href: '/dashboard',
    icon: () => ICON1(faUserDoctor),
    title: 'SEO Assessments',
    children: [
      {
        href: '/dashboard/seo/analysis',
        icon: () => ICON1(faRankingStar),
        title: 'Signal Scans',
      },
      {
        href: '/backlinks',
        icon: () => ICON1(faLink),
        title: 'Backlinks Signals',
      },
      {
        href: '/dashboard/new-top-keywords',
        icon: () => ICON1(faHashtag),
        title: 'Top Keywords Signals',
      },
      {
        href: '/dashboard/keyword-tracker',
        icon: () => ICON1(faTableList),
        title: 'Keyword Position Tracking',
      },
      {
        href: '/dashboard/keyword-research',
        icon: () => ICON1(faFilePen),
        title: 'Keyword Research',
      },
      {
        href: '/dashboard/broken-links',
        icon: () => ICON1(faLinkSlash),
        title: 'Broken Links Signals',
      },
      {
        href: '/dashboard/google-search-console',
        icon: () => ICON1(faChartLine),
        title: 'Google Search Console Data',
      },
      {
        href: '/dashboard/traffic-analytics',
        icon: () => ICON1(faUsers),
        title: 'Traffic Analytics',
      },
    ],
  },
  {
    href: '/pages',
    title: 'Monitoring Signals',
    icon: () => ICON1(faHardDrive),
    children: [
      {
        href: '/pages/site-outage',
        icon: () => ICON1(faNotesMedical),
        title: 'Site Outage Tracker',
      },
      {
        href: '/dashboard/site-speed',
        icon: () => ICON1(faStopwatch),
        title: 'Site speed',
      },
      {
        href: '/dashboard/core-web-vitals',
        icon: () => ICON1(faTachometer),
        title: 'Core Web Vitals',
      },
    ],
  },
  {
    href: '/reporting',
    title: 'Reporting',
    icon: () => ICON1(faFileWaveform),
    children: [
      {
        href: '/reporting/white-label/reports',
        icon: () => ICON1(faFileSignature),
        title: 'White Label Reports ',
      },
    ],
  },
  {
    href: '/dashboard/contact-us',
    icon: () => ICON1(faEnvelope),
    title: 'Contact Us',
  },
]

const navItems = [
  {
    title: '',
    pages: pagesSection,
  },
]

export default navItems

import { useState } from 'react'
import { Pagination, Table } from 'react-bootstrap'

import 'pages/dashboards/GenerateResearchTable.scss'

const GenerateResearchTable = ({ generateResearchData, hostName }) => {
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1)

  const researchDataForActiveLink =
    generateResearchData?.length &&
    generateResearchData?.filter((obj) => {
      if (obj?.url?.includes(hostName)) {
        return obj
      }
    })

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems =
    researchDataForActiveLink?.length &&
    researchDataForActiveLink?.[0]?.result?.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(researchDataForActiveLink?.[0]?.result?.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1)
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(pageNumber)
    }
  }

  let paginationItems = []
  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }
  } else {
    // More than 5 pages, handle ellipsis
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage <= 2) {
      endPage = 5
    } else if (currentPage >= totalPages - 1) {
      startPage = totalPages - 4
    }

    if (startPage > 2) {
      paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" disabled />)
    }

    // Pages
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }

    if (endPage < totalPages - 1) {
      paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" disabled />)
    }
  }

  return researchDataForActiveLink?.length ? (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Keywords</th>
            <th scope="col">Clicks</th>
            <th>impressions</th>
            <th>CTR</th>
            <th scope="col">Positions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.length &&
            currentItems?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.keywords}</td>
                <td>{item?.clicks}</td>
                <td>{item?.impressions}</td>
                <td>{item?.ctr?.toFixed(3)}</td>
                <td>{item?.position?.toFixed(3)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Pagination className="research-data-pagination">
        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
        {paginationItems}
        <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
      </Pagination>{' '}
    </>
  ) : (
    <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              fontSize: '17px'
            }}
          >
        <h3 className='text-center f-bold'>
             This website has no search console data yet
        </h3>
    </div>
  )
}

export default GenerateResearchTable

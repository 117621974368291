import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const CssAnalysisTest = () => {
  return (
    <CommonFreeTool heading={freeTools.cssAnalysisTest.heading} whatIsIT={freeTools.cssAnalysisTest.what} />
  )
}

export default CssAnalysisTest

import { Line } from '@ant-design/charts'
import * as _ from 'lodash'
import * as moment from 'moment'
import * as numeral from 'numeral'

import './DashboardRevised/urlScanStatus.scss'

const Page = ({ usersDomain, activeLink }) => {
  let data =
    (usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.stats1Data) ||
    []
  let currentData =
    usersDomain &&
    activeLink !== undefined &&
    usersDomain[activeLink] &&
    usersDomain[activeLink]?.statsData?.[0]?.backlinks
      ? usersDomain[activeLink]?.statsData?.[0]?.backlinks
      : '0'
  let data1 =
    data && data?.length
      ? data.map((item) => {
          return {
            date1: moment(item.createdAt).format('MM-DD'),
            ...item,
            // value: getCummulativeSumForBacklinks(data, index)
          }
        })
      : []
      
  let backlinksData = _.sortBy(data1, ['date1'])
  let result =
    backlinksData && backlinksData?.length
      ? backlinksData.map((item) => {
          // return {
          //   date: new Date(2022, item.month, item.day),
          //   date1: `${item.month}-${item.day}`,
          //   value: getCummulativeSumForBacklinks(backlinksData, index, parseInt(currentData))
          // }
          let currentData1 = item.backlinks
          return {
            //date: new Date(2022, item.month, item.day),
            date1: moment(item.createdAt).format('MM-DD-YYYY'),
            value: parseInt(currentData1),
          }
        })
      : [
          {
            date: new Date(),
            date1: `${moment().format('MM')}-${moment().format('DD')}-${moment().format('YYYY')}`,
            value: parseInt(currentData),
          },
        ]

  result = result?.sort((a, b) => {
    const [monthA, dayA, yearA] = a?.date1?.split('-').map(Number);
    const [monthB, dayB, yearB] = b?.date1?.split('-').map(Number);
  
    if (yearA !== yearB) {
      return yearA - yearB;
    }
    if (monthA !== monthB) {
      return monthA - monthB;
    }
    return dayA - dayB;
  });
  result = _.uniqBy(result, 'date1')

  const config = {
    data: result,
    width: 800,
    height: 380,
    autoFit: true,
    animation: false,
    xField: 'date1',
    yField: 'value',
    yAxis: {
      label: {
        formatter: (datum) => {
          return numeral(datum).format('0,0')
        },
      },
    },
    tooltip: {
      title: (datum) => {
        return moment(datum, 'MM-DD').format('DD MMM')
      },
      formatter: (datum) => {
        return { name: 'Backlinks', value: numeral(datum.value).format('0,0') }
      },
      text: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    smooth: true,
    legend: { marker: { symbol: 'line' } },
    point: {
      size: 5,
      shape: 'circle',
    },
    yAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    // label: {
    //   style: {
    //     fill: '#aaa',
    //   },
    // },
  }
  // eslint-disable-next-line no-unused-vars
  let chart
  return (
    <div className="backilinks-chart md-0">
      <Line {...config} onReady={(chartInstance) => (chart = chartInstance)} />
    </div>
  )
}

export default Page

import React from 'react'
import { Table } from 'react-bootstrap'

const CompetitorDomain = ({ data }) => {
  return (
    <>
      {data ? (
        <>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <h5>{data?.url}</h5>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <p>{data.domainCompetitors?.data?.testResults?.message}</p>
          </div>

          <div className="vr-detail-block">
            {/* <ul>{data?.domainCompetitors?.data?.competitors && data?.domainCompetitors?.data?.competitors?.length ? data?.domainCompetitors?.data?.competitors?.slice(0, 5)?.map((item) => {
                    return (<li> {item}</li>)
                }) : ''}</ul> */}
            {data?.domainCompetitors?.data?.competitorsDetails &&
              data?.domainCompetitors?.data?.competitorsDetails?.length ? (
              <>
                <div className="mt-3 ">
                  <Table responsive className="custom-table-report">
                    <thead>
                      <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }}>Competitors</th>
                      <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                        Average Position
                      </th>
                      <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                        Sum Position
                      </th>
                      <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                        Intersections
                      </th>
                    </thead>
                    <tbody>
                      {data?.domainCompetitors?.data?.competitorsDetails &&
                        data?.domainCompetitors?.data?.competitorsDetails?.length ? (
                        data?.domainCompetitors?.data?.competitorsDetails?.slice(1, 6)?.map((item, index) => {
                          return (
                            <>
                              <tr key={`CompetitorsDomain-${index +1}`}>
                                <td width="55%">{item.competitors}</td>
                                <td width="15%">{Math.round(item.avgPosition)}</td>
                                <td width="15%">{item.sumPosition}</td>
                                <td width="15%">{item.intersections}</td>
                              </tr>
                            </>
                          )
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CompetitorDomain

import Chart from 'react-apexcharts'

const TrendsAreaChart = ({ chartData }) => {
  let categories = []
  let seriesData = []
  
  const getMonthNumberFromName = (monthName) => {
    const year = new Date().getFullYear();
    return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
  }

  const chartDisplay =
  chartData &&
  chartData?.length &&
  chartData.forEach((item) => {
    seriesData.push(item?.value)
    const monthNumber = getMonthNumberFromName(item?.month)
    categories.push(`${monthNumber}/01/${item?.year}`)
  })
  
  const data = [
    {
      name: 'Trends',
      data: seriesData,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
    },
    tooltip: {
      x: {
        format: 'MMM yyyy',
      },
    },
  }

  return (
    <div className="chart w-100">
      <Chart options={options} series={data} type="area" height="350" />
    </div>
  )
}

export default TrendsAreaChart

import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Card, Col, Row, Button, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getAbbr } from 'constants/Utils'
import * as _ from 'lodash'
import 'react-credit-cards/es/styles-compiled.css'
import { CardBody } from 'reactstrap'
import GoogleAutoLocation from 'components/GoogleAutoLocation'
import paths from 'constants/paths'
import { Info } from 'react-feather'
import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import {
  getCountries,
  getLocations,
  getLightHouseRequest,
  startFreeTrialAction,
  getSitemaps,
} from 'redux/actions/freetrial/startFreeTrial'
import { getAvailableKeywordLimit } from 'redux/actions/dashboard'
import "modules/welcomeBoard/components/addLocation/AddLocation.scss"
import { useDeviceCheck } from 'hooks/deviceHook'
import { PLAN } from 'constants/enum'
let countriesOptions
const AddLocationMain = ({ callFrom }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const dispatch = useDispatch()
  const { isMobile } = useDeviceCheck()

  const {
    countries,
    locations,
    linkArray: urlDetails,
    prevPageData,
    randomNumber,
    validateURL,
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const planName = useSelector(({ register }) => register?.registerData?.plan?.name)
  const userId = useSelector(({ register }) => register?.registerData?._id)

  const registerData = useSelector(({ register }) => register.registerData)
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  // const [primaryLocation, setPrimaryLocation] = useState('');
  const [cityOptions, setCityOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])

  const [selectedRegion2, setSelectedRegion2] = useState('')
  const [selectedState2, setSelectedState2] = useState('')
  const [selectedCity2, setSelectedCity2] = useState('')
  // const [primaryLocation, setPrimaryLocation] = useState('');
  const [cityOptions2, setCityOptions2] = useState([])
  const [stateOptions2, setStateOptions2] = useState([])

  const [selectedRegion3, setSelectedRegion3] = useState('')
  const [selectedState3, setSelectedState3] = useState('')
  const [selectedCity3, setSelectedCity3] = useState('')
  // const [primaryLocation, setPrimaryLocation] = useState('');
  const [cityOptions3, setCityOptions3] = useState([])
  const [stateOptions3, setStateOptions3] = useState([])

  const [selectedRegion4, setSelectedRegion4] = useState('')
  const [selectedState4, setSelectedState4] = useState('')
  const [selectedCity4, setSelectedCity4] = useState('')
  // const [primaryLocation, setPrimaryLocation] = useState('');
  const [cityOptions4, setCityOptions4] = useState([])
  const [stateOptions4, setStateOptions4] = useState([])

  const [selectedRegion5, setSelectedRegion5] = useState('')
  const [selectedState5, setSelectedState5] = useState('')
  const [selectedCity5, setSelectedCity5] = useState('')
  // const [primaryLocation, setPrimaryLocation] = useState('');
  const [cityOptions5, setCityOptions5] = useState([])
  const [stateOptions5, setStateOptions5] = useState([])

  const [selectedRegion6, setSelectedRegion6] = useState('')
  const [selectedState6, setSelectedState6] = useState('')
  const [selectedCity6, setSelectedCity6] = useState('')
  // const [primaryLocation, setPrimaryLocation] = useState('');
  const [cityOptions6, setCityOptions6] = useState([])
  const [stateOptions6, setStateOptions6] = useState([])
  const [latAndLong, setLatAndLong] = useState({})
  const [latAndLong2, setLatAndLong2] = useState({})
  const [latAndLong3, setLatAndLong3] = useState({})
  const [latAndLong4, setLatAndLong4] = useState({})
  const [latAndLong5, setLatAndLong5] = useState({})
  const [latAndLong6, setLatAndLong6] = useState({})

  const [enableGoogleAutocomplete, setEnableGoogleAutoComplete] = useState(true)

  useEffect(() => {
    countriesOptions =
      countries && countries.length
        ? countries.map((item) => {
            return { label: item, value: item }
          })
        : []

    window.scrollTo(0, 0)      
    dispatch(getCountries())
    dispatch(getLocations())
    // countriesOptions.unshift({ label: "Canada", value: "Canada" })
    // countriesOptions.unshift({ label: "United States", value: "United States" })
    if (prevPageData) {
      if (prevPageData?.regions?.length) {
        setSelectedRegion(
          enableGoogleAutocomplete ? prevPageData?.regions?.[0]?.region : prevPageData?.regions?.[0]?.country,
        )
        setSelectedState(prevPageData?.regions?.[0]?.state)
        setSelectedCity(prevPageData?.regions?.[0]?.city)
        setLatAndLong({
          lat: prevPageData?.regions?.[0]?.lat,
          long: prevPageData?.regions?.[0]?.long,
          country: prevPageData?.regions?.[0]?.country,
          address: prevPageData?.regions?.[0]?.address,
        })
      }
      if (prevPageData?.regions?.length > 1) {
        setSelectedRegion2(
          enableGoogleAutocomplete ? prevPageData?.regions?.[1]?.region : prevPageData?.regions?.[1]?.country,
        )
        setSelectedState2(prevPageData?.regions?.[1]?.state)
        setSelectedCity2(prevPageData?.regions?.[1]?.city)
        setLatAndLong2({
          lat: prevPageData?.regions?.[1]?.lat,
          long: prevPageData?.regions?.[1]?.long,
          country: prevPageData?.regions?.[1]?.country,
          address: prevPageData?.regions?.[1]?.address,
        })
      }
      if (prevPageData?.regions?.length > 2) {
        setSelectedRegion3(
          enableGoogleAutocomplete ? prevPageData?.regions?.[2]?.region : prevPageData?.regions?.[2]?.country,
        )
        setSelectedState3(prevPageData?.regions?.[2]?.state)
        setSelectedCity3(prevPageData?.regions?.[2]?.city)
        setLatAndLong3({
          lat: prevPageData?.regions?.[2]?.lat,
          long: prevPageData?.regions?.[2]?.long,
          country: prevPageData?.regions?.[2]?.country,
          address: prevPageData?.regions?.[2]?.address,
        })
      }
      if (prevPageData?.regions?.length > 3) {
        setSelectedRegion4(
          enableGoogleAutocomplete ? prevPageData?.regions?.[3]?.region : prevPageData?.regions?.[3]?.country,
        )
        setSelectedState4(prevPageData?.regions?.[3]?.state)
        setSelectedCity4(prevPageData?.regions?.[3]?.city)
        setLatAndLong4({
          lat: prevPageData?.regions?.[3]?.lat,
          long: prevPageData?.regions?.[3]?.long,
          country: prevPageData?.regions?.[3]?.country,
          address: prevPageData?.regions?.[3]?.address,
        })
      }
      if (prevPageData?.regions?.length > 4) {
        setSelectedRegion5(
          enableGoogleAutocomplete ? prevPageData?.regions?.[4]?.region : prevPageData?.regions?.[4]?.country,
        )
        setSelectedState5(prevPageData?.regions?.[4]?.state)
        setSelectedCity5(prevPageData?.regions?.[4]?.city)
        setLatAndLong5({
          lat: prevPageData?.regions?.[4]?.lat,
          long: prevPageData?.regions?.[4]?.long,
          country: prevPageData?.regions?.[4]?.country,
          address: prevPageData?.regions?.[4]?.address,
        })
      }
      if (prevPageData?.regions?.length > 5) {
        setSelectedRegion6(
          enableGoogleAutocomplete ? prevPageData?.regions?.[5]?.region : prevPageData?.regions?.[5]?.country,
        )
        setSelectedState6(prevPageData?.regions?.[5]?.state)
        setSelectedCity6(prevPageData?.regions?.[5]?.city)
        setLatAndLong6({
          lat: prevPageData?.regions?.[5]?.lat,
          long: prevPageData?.regions?.[5]?.long,
          country: prevPageData?.regions?.[5]?.country,
          address: prevPageData?.regions?.[5]?.address,
        })
      }
    }
  }, [])

  useEffect(() => {
    countriesOptions =
      countries && countries.length
        ? countries.map((item) => {
            return { label: item, value: item }
          })
        : []
    // countriesOptions.unshift({ label: "Canada", value: "Canada" })
    // countriesOptions.unshift({ label: "United States", value: "United States" })
  }, [countries])

  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
    } else {
      navigate(callFrom === 'dashboard' ? paths.dashboardAddWebsite : paths.addNewWebsite)
    }
  }, [randomNumber])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions(stateOptions)
    }
  }, [selectedRegion])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion && item.state === selectedState
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions(cityOptions)
    }
  }, [selectedState])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion2
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))

      setStateOptions2(stateOptions)
    }
  }, [selectedRegion2])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion2 && item.state === selectedState2
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions2(cityOptions)
    }
  }, [selectedState2])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion3
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions3(stateOptions)
    }
  }, [selectedRegion3])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion3 && item.state === selectedState3
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions3(cityOptions)
    }
  }, [selectedState3])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion4
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions4(stateOptions)
    }
  }, [selectedRegion4])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion4 && item.state === selectedState4
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions4(cityOptions)
    }
  }, [selectedState4])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion5
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions5(stateOptions)
    }
  }, [selectedRegion5])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion5 && item.state === selectedState5
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions5(cityOptions)
    }
  }, [selectedState5])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion6
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions6(stateOptions)
    }
  }, [selectedRegion6])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion6 && item.state === selectedState6
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions6(cityOptions)
    }
  }, [selectedState6])

  const selectRegion = async () => {
    let regions = []
    let firstLocation
    if (selectedCity !== '') {
      firstLocation = await getLocationData(selectedRegion, selectedState, selectedCity)
      let data = {
        region: enableGoogleAutocomplete ? selectedRegion : firstLocation.region,
        long: enableGoogleAutocomplete ? latAndLong.long : firstLocation.long,
        lat: enableGoogleAutocomplete ? latAndLong.lat : firstLocation.lat,
        iso3: enableGoogleAutocomplete ? '' : firstLocation.iso3,
        city: enableGoogleAutocomplete ? selectedCity : firstLocation.city,
        state: enableGoogleAutocomplete ? selectedState : firstLocation.state,
        country: enableGoogleAutocomplete ? latAndLong.country : firstLocation.country,
        address: latAndLong.address,
      }
      regions.push(data)
    }

    if (selectedCity2 !== '') {
      let secondLocation = await getLocationData(selectedRegion2, selectedState2, selectedCity2)
      let data = {
        region: enableGoogleAutocomplete ? selectedRegion2 : secondLocation.region,
        long: enableGoogleAutocomplete ? latAndLong2.long : secondLocation.long,
        lat: enableGoogleAutocomplete ? latAndLong2.lat : secondLocation.lat,
        iso3: enableGoogleAutocomplete ? '' : secondLocation.iso3,
        city: enableGoogleAutocomplete ? selectedCity2 : secondLocation.city,
        state: enableGoogleAutocomplete ? selectedState2 : secondLocation.state,
        country: enableGoogleAutocomplete ? latAndLong2.country : secondLocation.country,
        address: latAndLong2.address,
      }
      regions.push(data)
    }

    if (selectedCity3 !== '') {
      let thirdLocation = await getLocationData(selectedRegion3, selectedState3, selectedCity3)
      let data = {
        region: enableGoogleAutocomplete ? selectedRegion3 : thirdLocation.region,
        long: enableGoogleAutocomplete ? latAndLong3.long : thirdLocation.long,
        lat: enableGoogleAutocomplete ? latAndLong3.lat : thirdLocation.lat,
        iso3: enableGoogleAutocomplete ? '' : thirdLocation.iso3,
        city: enableGoogleAutocomplete ? selectedCity3 : thirdLocation.city,
        state: enableGoogleAutocomplete ? selectedState3 : thirdLocation.state,
        country: enableGoogleAutocomplete ? latAndLong3.country : thirdLocation.country,
        address: latAndLong3.address,
      }
      regions.push(data)
    }

    if (selectedCity4 !== '') {
      let forthLocation = await getLocationData(selectedRegion4, selectedState4, selectedCity4)
      let data = {
        region: enableGoogleAutocomplete ? selectedRegion4 : forthLocation.region,
        long: enableGoogleAutocomplete ? latAndLong4.long : forthLocation.long,
        lat: enableGoogleAutocomplete ? latAndLong4.lat : forthLocation.lat,
        iso3: enableGoogleAutocomplete ? '' : forthLocation.iso3,
        city: enableGoogleAutocomplete ? selectedCity4 : forthLocation.city,
        state: enableGoogleAutocomplete ? selectedState4 : forthLocation.state,
        country: enableGoogleAutocomplete ? latAndLong4.country : forthLocation.country,
        address: latAndLong4.address,
      }
      regions.push(data)
    }

    if (selectedCity5 !== '') {
      let fifthLocation = await getLocationData(selectedRegion5, selectedState5, selectedCity5)
      let data = {
        region: enableGoogleAutocomplete ? selectedRegion5 : fifthLocation.region,
        long: enableGoogleAutocomplete ? latAndLong5.long : fifthLocation.long,
        lat: enableGoogleAutocomplete ? latAndLong5.lat : fifthLocation.lat,
        iso3: enableGoogleAutocomplete ? '' : fifthLocation.iso3,
        city: enableGoogleAutocomplete ? selectedCity5 : fifthLocation.city,
        state: enableGoogleAutocomplete ? selectedState5 : fifthLocation.state,
        country: enableGoogleAutocomplete ? latAndLong5.country : fifthLocation.country,
        address: latAndLong5.address,
      }
      regions.push(data)
    }

    if (selectedCity6 !== '') {
      let sixthLocation = await getLocationData(selectedRegion6, selectedState6, selectedCity6)
      let data = {
        region: enableGoogleAutocomplete ? selectedRegion6 : sixthLocation?.region,
        long: enableGoogleAutocomplete ? latAndLong6.long : sixthLocation.long,
        lat: enableGoogleAutocomplete ? latAndLong6.lat : sixthLocation.lat,
        iso3: enableGoogleAutocomplete ? '' : sixthLocation.iso3,
        city: enableGoogleAutocomplete ? selectedCity6 : sixthLocation.city,
        state: enableGoogleAutocomplete ? selectedState6 : sixthLocation.state,
        country: enableGoogleAutocomplete ? latAndLong6.country : sixthLocation.country,
        address: latAndLong6.address,
      }
      regions.push(data)
    }

    if (selectedRegion === 'Global') {
      let data = {
        region: '',
        long: '-',
        lat: '-',
        iso3: '-',
        city: '-',
        state: '-',
        country: 'Global',
      }
      regions.push(data)
    }
    const mainURL = urlDetails.filter((item) => item.checked === true)
    dispatch({
      type: 'STORE_PREVIOUS_PAGE_DATA',
      payload: { ...prevPageData, regions: regions, url: mainURL?.[0]?.effective_url || prevPageData?.url },
    })

    const url = mainURL?.[0]?.effective_url || prevPageData?.url

    if ((mainURL && mainURL?.length) || prevPageData?.url) {
      let uData = {
        userId: registerData?._id,
        url: mainURL?.[0]?.effective_url || prevPageData?.url,
        action: 'startLightHouse',
      }

      dispatch(startFreeTrialAction(uData))
      dispatch(getLightHouseRequest(url))
    }
    dispatch(getSitemaps(url))

    const input = {
      sessionId: randomNumber,
      action: 'getKeywords',
      domain: prevPageData.url,
      regionalDB:
        selectedRegion === 'Global'
          ? 'Global'
          : (enableGoogleAutocomplete ? selectedRegion : firstLocation?.region) || 'CA',
      region:
        selectedRegion === 'Global'
          ? 'Global'
          : enableGoogleAutocomplete
            ? selectedRegion
            : regions?.[0]?.region,
      regions: regions,
      primaryLocation: selectedRegion === 'Global' ? '' : prevPageData.primaryLocation || 'com.au',
      long:
        selectedRegion === 'Global'
          ? '-'
          : (enableGoogleAutocomplete ? latAndLong.long : firstLocation?.long) || '43.7417',
      lat:
        selectedRegion === 'Global'
          ? '-'
          : (enableGoogleAutocomplete ? latAndLong.lat : firstLocation?.lat) || '-79.3733',
      iso3: selectedRegion === 'Global' ? '-' : (enableGoogleAutocomplete ? '' : firstLocation?.iso3) || '',
      city: selectedRegion === 'Global' ? '' : selectedCity || 'Toronto',
      state: selectedRegion === 'Global' ? '' : selectedState || 'Ontario',
      country:
        selectedRegion === 'Global'
          ? 'Global'
          : (enableGoogleAutocomplete ? latAndLong.country : selectedRegion) || 'Canada',
    }
    dispatch({ type: 'GET_URL_DETAILS', payload: input })

    navigate(callFrom === 'dashboard' ? paths.dashboardAddGoogle : paths.addGoogle, {
      state: { autoComplete: enableGoogleAutocomplete },
    })
  }

  const getLocationData = (country, state, city) => {
    let region = ''
    let long = '-'
    let lat = '-'
    let iso3 = '-'

    let locationData =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === country && item.city === city && item.state === state
          })
        : []

    if (locationData && locationData.length) {
      region = locationData[0].countryShortCodeISO2
      long = locationData[0].long
      lat = locationData[0].lat
      iso3 = locationData[0].countryShortCodeISO3
    }

    return { region, long, lat, iso3, country, state, city }
  }

  const handleLocationSelected = (place) => {
    switch (place.callFrom) {
      case 'primaryLocation':
        setSelectedRegion(place.region)
        setSelectedState(place.state)
        setSelectedCity(place.city)
        setLatAndLong({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location1':
        setSelectedRegion2(place.region)
        setSelectedState2(place.state)
        setSelectedCity2(place.city)
        setLatAndLong2({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location2':
        setSelectedRegion3(place.region)
        setSelectedState3(place.state)
        setSelectedCity3(place.city)
        setLatAndLong3({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location3':
        setSelectedRegion4(place.region)
        setSelectedState4(place.state)
        setSelectedCity4(place.city)
        setLatAndLong4({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location4':
        setSelectedRegion5(place.region)
        setSelectedState5(place.state)
        setSelectedCity5(place.city)
        setLatAndLong5({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location5':
        setSelectedRegion6(place.region)
        setSelectedState6(place.state)
        setSelectedCity6(place.city)
        setLatAndLong6({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      default:
        setSelectedRegion(place.region)
        setSelectedState(place.state)
        setSelectedCity(place.city)
        setLatAndLong({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
    }
  }

  return (
    <div className={callFrom === "trial" ? "add-location" : ""}>
      <Helmet title="Start trail" />
      {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={callFrom === "trial" ? "add-location-text" : "add-location-text-dashboard"}>
         {isMobile ? <div className="add-location-stepnumber-mobile">
            <h3 className="mb-0">3</h3>
          </div> : null}
          <div className="add-location-text-mobile">
            <p className="add-location-text-first">Empower Your SEO Journey: Precision, Simplicity, Results</p>
            <p className="add-location-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      <Card
        className={`trail-flow mb-0 add-website-card-tranparency ${callFrom === "trial" ? 'add-location-card' : 'add-location-dashboard-card'}`}
        style={{
          padding: isMobile ? 0 : 15,
        }}
      >
        <CardBody className={`add-website-card-tranparency ${isMobile ? "p-0" : ''}`}>
          <Card className={`mb-0 add-website-card-tranparency ${callFrom === "trial" ? 'add-location-nested-card' : 'add-location-dashboard-nested-card'}`}>
            <CommonCardHeader
              startSpanClass={'done-step'}
              websiteSpanClass={'done-step'}
              locationSpanClass={'active-step'}
              googleSpanClass={'inactive-step'}
              keywordSpanClass={'inactive-step'}
              competitorSpanClass={'inactive-step'}
              sitemapSpanClass={'inactive-step'}
              callFrom={callFrom}
            />

            <CardBody className={`bg-white add-website-card-tranparency ${isMobile ? 'p-0 text-center' : ''}`}>
              <Row className={isMobile ? "mr-0 add-location-row" : ''}>
                <Col className={isMobile ? "pr-0 add-location-dashboard-card-col" : ''}>
                  <h3 className={`f-bold add-location-header`}>Primary Location for {prevPageData?.url}</h3>
                  <p style={{padding: isMobile ? '0 8% 0 8%' : ''}}>
                    Our keyword tracking tool offers precise, region-specific data. It's tailored to focus on
                    your primary target audience's location&nbsp;
                    <OverlayTrigger
                      placement={'right'}
                      overlay={
                        <Tooltip>
                          Additionally, the tool accurately tracks your keyword rankings within a 25km radius
                          of a specified address.
                          <br /> This ensures localized and relevant insights.
                          <br /> Please provide a complete address: this includes the specific street address,
                          city, state/province, and postal code/ZIP code.
                        </Tooltip>
                      }
                    >
                      <Info size="16" color="#afafaf" />
                    </OverlayTrigger>{' '}
                  </p> 
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="tabs" className={`d-none d-sm-none d-md-block d-lg-block ${callFrom === "trial" ? "add-location-nav" : ''}`}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className={callFrom === "trial" ? "add-location-nav-single" : ''}
                          style={{ width: selectedCity ? 'fit-content' : '10rem', textAlign: 'center'}}
                        >
                          {selectedRegion !== '' && selectedState !== '' && selectedCity !== ''
                            ? `${
                                selectedCity?.trim()?.length > 0 ? selectedCity + ',' : ''
                              } ${selectedState} ${
                                enableGoogleAutocomplete ? selectedRegion : getAbbr(selectedRegion, locations)
                              }`
                            : 'Location 1'}{' '}
                          {latAndLong?.address?.length ? (
                            <OverlayTrigger
                              placement={'right'}
                              overlay={<Tooltip>{latAndLong?.address}</Tooltip>}
                            >
                              <Info size="16" color="#afafaf" />
                            </OverlayTrigger>
                          ) : null}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <h6 className={`mt-2 f-bold ${isMobile ? "p-3 text-center" : ''}`}>
                          Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                          <OverlayTrigger
                            placement={'right'}
                            overlay={
                              <Tooltip>
                                Based on this address, we'll automatically select the regional keyword
                                database. This ensures the most accurate keyword rank tracking for your
                                specified location.
                              </Tooltip>
                            }
                          >
                            <Info size="16" color="#afafaf" />
                          </OverlayTrigger>
                        </h6>
                        {!enableGoogleAutocomplete ? (
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={countriesOptions}
                            isSearchable
                            onChange={(e) => {
                              setSelectedRegion(e.value)
                              if (selectedRegion !== e.value) {
                                setStateOptions([])
                                setCityOptions([])
                                setSelectedState('')
                                setSelectedCity('')
                              }
                            }}
                          />
                        ) : (
                          <GoogleAutoLocation
                            handleLocationSelected={handleLocationSelected}
                            callFrom="primaryLocation"
                            websiteFlow={callFrom}
                            customClass={`add-website-card-tranparency trail-container-input`}
                          ></GoogleAutoLocation>
                        )}
                        <br />
                        {!enableGoogleAutocomplete &&
                        selectedRegion !== 'Global' &&
                        stateOptions &&
                        stateOptions.length ? (
                          <>
                            <h6>Province / State</h6>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={stateOptions}
                              isSearchable
                              onChange={(e) => {
                                setSelectedState(e.value)
                                if (selectedState !== e.value) {
                                  setCityOptions([])
                                  setSelectedCity('')
                                }
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        {!isMobile ? <br /> : null}
                        {!enableGoogleAutocomplete && cityOptions && cityOptions.length ? (
                          <>
                            <h6>City</h6>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={cityOptions}
                              isSearchable
                              onChange={(e) => {
                                setSelectedCity(e.value)
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                  {!isMobile ? <br /> : null}
                  <h3 className={`f-bold add-location-header`} style={{padding: isMobile ? '2% 8% 2% 8%' : ''}}>Additional Locations</h3>
                  <p style={{padding: isMobile ? '0 8% 4% 8%' : ''}}>
                    What's the primary location of your targeted audience? Choosing a specific target audience
                    is essential; PMS uses AI-powered tools to target and use the correct SEO signals in the
                    audience's geographical location.{' '}
                  </p>

                  <Tab.Container id="left-tabs-example-1" defaultActiveKey="first">
                    <Nav variant="tabs" className={callFrom === "trial" ? "add-location-nav add-location-additional-nav" : isMobile ? 'add-location-nav-dashboard' : ''}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          style={{ width: selectedCity2 ? 'auto' : '10rem' }}
                          className={`d-flex flex-row justify-content-center align-items-center  add-location-nav-single`}
                        >
                          {selectedRegion2 !== '' && selectedState2 !== '' && selectedCity2 !== ''
                            ? `${
                                selectedCity2?.trim()?.length > 0 ? selectedCity2 + ',' : ''
                              } ${selectedState2} ${
                                enableGoogleAutocomplete
                                  ? selectedRegion2
                                  : getAbbr(selectedRegion2, locations)
                              }`
                            : 'Location 1'}
                          &nbsp;
                          {latAndLong2?.address?.length ? (
                            <OverlayTrigger
                              placement={'right'}
                              overlay={<Tooltip>{latAndLong2?.address}</Tooltip>}
                            >
                              <Info size="16" color="#afafaf" />
                            </OverlayTrigger>
                          ) : null}
                        </Nav.Link>
                      </Nav.Item>
                      {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              style={{ width: selectedCity3 ? 'auto' : '10rem' }}
                              className={`d-flex flex-row justify-content-center align-items-center add-location-nav-single`}
                            >
                              {selectedRegion3 !== '' && selectedState3 !== '' && selectedCity3 !== ''
                                ? `${
                                    selectedCity3?.trim()?.length > 0 ? selectedCity3 + ',' : ''
                                  } ${selectedState3} ${
                                    enableGoogleAutocomplete
                                      ? selectedRegion3
                                      : getAbbr(selectedRegion3, locations)
                                  }`
                                : 'Location 2'}
                              &nbsp;
                              {latAndLong3?.address?.length ? (
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={<Tooltip>{latAndLong3?.address}</Tooltip>}
                                >
                                  <Info size="16" color="#afafaf" />
                                </OverlayTrigger>
                              ) : null}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      ) : (
                        <></>
                      )}
                      {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              style={{ width: selectedCity4 ? 'auto' : '10rem' }}
                              className={`d-flex flex-row justify-content-center align-items-center add-location-nav-single`}
                            >
                              {selectedRegion4 !== '' && selectedState4 !== '' && selectedCity4 !== ''
                                ? `${
                                    selectedCity4?.trim()?.length > 0 ? selectedCity4 + ',' : ''
                                  } ${selectedState4} ${
                                    enableGoogleAutocomplete
                                      ? selectedRegion4
                                      : getAbbr(selectedRegion4, locations)
                                  }`
                                : 'Location 3'}
                              &nbsp;
                              {latAndLong4?.address?.length ? (
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={<Tooltip>{latAndLong4?.address}</Tooltip>}
                                >
                                  <Info size="16" color="#afafaf" />
                                </OverlayTrigger>
                              ) : null}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      ) : (
                        <></>
                      )}
                      {planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="four"
                              style={{ width: selectedCity5 ? 'auto' : '10rem' }}
                              className={`d-flex flex-row justify-content-center align-items-center add-location-nav-single`}
                            >
                              {selectedRegion5 !== '' && selectedState5 !== '' && selectedCity5 !== ''
                                ? `${
                                    selectedCity5?.trim()?.length > 0 ? selectedCity5 + ',' : ''
                                  } ${selectedState5} ${
                                    enableGoogleAutocomplete
                                      ? selectedRegion5
                                      : getAbbr(selectedRegion5, locations)
                                  }`
                                : 'Location 4'}
                              &nbsp;
                              {latAndLong5?.address?.length ? (
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={<Tooltip>{latAndLong5?.address}</Tooltip>}
                                >
                                  <Info size="16" color="#afafaf" />
                                </OverlayTrigger>
                              ) : null}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      ) : (
                        <></>
                      )}
                      {planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="five"
                              style={{ width: selectedCity6 ? 'auto' : '10rem' }}
                              className={`d-flex flex-row justify-content-center align-items-center add-location-nav-single`}
                            >
                              {selectedRegion6 !== '' && selectedState6 !== '' && selectedCity6 !== ''
                                ? `${
                                    selectedCity6?.trim()?.length > 0 ? selectedCity6 + ',' : ''
                                  } ${selectedState6} ${
                                    enableGoogleAutocomplete
                                      ? selectedRegion6
                                      : getAbbr(selectedRegion6, locations)
                                  }`
                                : 'Location 5'}
                              &nbsp;
                              {latAndLong6?.address?.length ? (
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={<Tooltip>{latAndLong6?.address}</Tooltip>}
                                >
                                  <Info size="16" color="#afafaf" />
                                </OverlayTrigger>
                              ) : null}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                      ) : (
                        <></>
                      )}
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <h6 className={`mt-2 f-bold ${isMobile ? "p-4 text-center" : ''}`}>
                          Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                          <OverlayTrigger
                            placement={'right'}
                            overlay={
                              <Tooltip>
                                Based on this address, we'll automatically select the regional keyword
                                database. This ensures the most accurate keyword rank tracking for your
                                specified location.
                              </Tooltip>
                            }
                          >
                            <Info size="16" color="#afafaf" />
                          </OverlayTrigger>
                        </h6>
                        {!enableGoogleAutocomplete ? (
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={countriesOptions}
                            isSearchable
                            onChange={(e) => {
                              setSelectedRegion2(e.value)
                              if (selectedRegion2 !== e.value) {
                                setStateOptions2([])
                                setCityOptions2([])
                                setSelectedState2('')
                                setSelectedCity2('')
                              }
                            }}
                          />
                        ) : (
                          <GoogleAutoLocation
                            handleLocationSelected={handleLocationSelected}
                            callFrom="location1"
                            websiteFlow={callFrom}
                            customClass={`add-website-card-tranparency trail-container-input`}
                          ></GoogleAutoLocation>
                        )}
                        <br />
                        {!enableGoogleAutocomplete &&
                        selectedRegion2 !== 'Global' &&
                        stateOptions2 &&
                        stateOptions2.length ? (
                          <>
                            <h6>Province / State</h6>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={stateOptions2}
                              isSearchable
                              onChange={(e) => {
                                setSelectedState2(e.value)
                                if (selectedState2 !== e.value) {
                                  setCityOptions2([])
                                  setCityOptions2([])
                                  setSelectedCity2('')
                                }
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        <br />
                        {!enableGoogleAutocomplete && cityOptions2 && cityOptions2.length ? (
                          <>
                            <h6>City</h6>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={cityOptions2}
                              isSearchable
                              onChange={(e) => {
                                setSelectedCity2(e.value)
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Tab.Pane eventKey="second">
                            <h6 className={`mt-2 f-bold ${isMobile ? "p-4 text-center" : ''}`}>
                              Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                              <OverlayTrigger
                                placement={'right'}
                                overlay={
                                  <Tooltip>
                                    Based on this address, we'll automatically select the regional keyword
                                    database. This ensures the most accurate keyword rank tracking for your
                                    specified location.
                                  </Tooltip>
                                }
                              >
                                <Info size="16" color="#afafaf" />
                              </OverlayTrigger>
                            </h6>
                            {!enableGoogleAutocomplete ? (
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={countriesOptions}
                                isSearchable
                                onChange={(e) => {
                                  setSelectedRegion3(e.value)
                                  if (selectedRegion3 !== e.value) {
                                    setStateOptions3([])
                                    setCityOptions3([])
                                    setSelectedState3('')
                                    setSelectedCity3('')
                                  }
                                }}
                              />
                            ) : (
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location2"
                                websiteFlow={callFrom}
                                customClass={`add-website-card-tranparency trail-container-input`}
                              ></GoogleAutoLocation>
                            )}
                            <br />
                            {!enableGoogleAutocomplete &&
                            selectedRegion3 !== 'Global' &&
                            stateOptions3 &&
                            stateOptions3.length ? (
                              <>
                                <h6>Province / State</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={stateOptions3}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedState3(e.value)
                                    if (selectedState3 !== e.value) {
                                      setCityOptions3([])
                                      setCityOptions3([])
                                      setSelectedCity3('')
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <br />
                            {!enableGoogleAutocomplete && cityOptions3 && cityOptions3.length ? (
                              <>
                                <h6>City</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={cityOptions3}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedCity3(e.value)
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Tab.Pane>
                        </>
                      ) : (
                        <></>
                      )}
                      {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Tab.Pane eventKey="third">
                            <h6 className={`mt-2 f-bold ${isMobile ? "p-4 text-center" : ''}`}>
                              Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                              <OverlayTrigger
                                placement={'right'}
                                overlay={
                                  <Tooltip>
                                    Based on this address, we'll automatically select the regional keyword
                                    database. This ensures the most accurate keyword rank tracking for your
                                    specified location.
                                  </Tooltip>
                                }
                              >
                                <Info size="16" color="#afafaf" />
                              </OverlayTrigger>
                            </h6>
                            {!enableGoogleAutocomplete ? (
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={countriesOptions}
                                isSearchable
                                onChange={(e) => {
                                  setSelectedRegion4(e.value)
                                  if (selectedRegion4 !== e.value) {
                                    setStateOptions4([])
                                    setCityOptions4([])
                                    setSelectedState4('')
                                    setSelectedCity4('')
                                  }
                                }}
                              />
                            ) : (
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location3"
                                websiteFlow={callFrom}
                                customClass={`add-website-card-tranparency trail-container-input`}
                              ></GoogleAutoLocation>
                            )}

                            <br />
                            {!enableGoogleAutocomplete &&
                            selectedRegion4 !== 'Global' &&
                            stateOptions4 &&
                            stateOptions4.length ? (
                              <>
                                <h6>Province / State</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={stateOptions4}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedState4(e.value)
                                    if (selectedState4 !== e.value) {
                                      setCityOptions4([])
                                      setCityOptions4([])
                                      setSelectedCity4('')
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <br />
                            {!enableGoogleAutocomplete && cityOptions4 && cityOptions4.length ? (
                              <>
                                <h6>City</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={cityOptions4}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedCity4(e.value)
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Tab.Pane>
                        </>
                      ) : (
                        <></>
                      )}
                      {planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Tab.Pane eventKey="four">
                            <h6 className={`mt-2 f-bold ${isMobile ? "p-4 text-center" : ''}`}>
                              Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                              <OverlayTrigger
                                placement={'right'}
                                overlay={
                                  <Tooltip>
                                    Based on this address, we'll automatically select the regional keyword
                                    database. This ensures the most accurate keyword rank tracking for your
                                    specified location.
                                  </Tooltip>
                                }
                              >
                                <Info size="16" color="#afafaf" />
                              </OverlayTrigger>
                            </h6>
                            {!enableGoogleAutocomplete ? (
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={countriesOptions}
                                isSearchable
                                onChange={(e) => {
                                  setSelectedRegion5(e.value)
                                  if (selectedRegion5 !== e.value) {
                                    setStateOptions5([])
                                    setCityOptions5([])
                                    setSelectedState5('')
                                    setSelectedCity5('')
                                  }
                                }}
                              />
                            ) : (
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location4"
                                websiteFlow={callFrom}
                                customClass={`add-website-card-tranparency trail-container-input`}
                              ></GoogleAutoLocation>
                            )}
                            <br />
                            {!enableGoogleAutocomplete &&
                            selectedRegion5 !== 'Global' &&
                            stateOptions5 &&
                            stateOptions5.length ? (
                              <>
                                <h6>Province / State</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={stateOptions5}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedState5(e.value)
                                    if (selectedState5 !== e.value) {
                                      setCityOptions5([])
                                      setCityOptions5([])
                                      setSelectedCity5('')
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <br />
                            {!enableGoogleAutocomplete && cityOptions5 && cityOptions5.length ? (
                              <>
                                <h6>City</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={cityOptions5}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedCity5(e.value)
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Tab.Pane>
                        </>
                      ) : (
                        <></>
                      )}
                      {planName === PLAN.PREMIUM_PLAN.name ? (
                        <>
                          <Tab.Pane eventKey="five">
                            <h6 className={`mt-2 f-bold ${isMobile ? "p-4 text-center" : ''}`}>
                              Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                              <OverlayTrigger
                                placement={'right'}
                                overlay={
                                  <Tooltip>
                                    Based on this address, we'll automatically select the regional keyword
                                    database. This ensures the most accurate keyword rank tracking for your
                                    specified location.
                                  </Tooltip>
                                }
                              >
                                <Info size="16" color="#afafaf" />
                              </OverlayTrigger>
                            </h6>
                            {!enableGoogleAutocomplete ? (
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={countriesOptions}
                                isSearchable
                                onChange={(e) => {
                                  setSelectedRegion6(e.value)
                                  if (selectedRegion6 !== e.value) {
                                    setStateOptions6([])
                                    setCityOptions6([])
                                    setSelectedState6('')
                                    setSelectedCity6('')
                                  }
                                }}
                              />
                            ) : (
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location5"
                                websiteFlow={callFrom}
                                customClass={`add-website-card-tranparency trail-container-input`}
                              ></GoogleAutoLocation>
                            )}
                            <br />
                            {!enableGoogleAutocomplete &&
                            selectedRegion6 !== 'Global' &&
                            stateOptions6 &&
                            stateOptions6.length ? (
                              <>
                                <h6>Province / State</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={stateOptions6}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedState6(e.value)
                                    if (selectedState6 !== e.value) {
                                      setCityOptions6([])
                                      setCityOptions6([])
                                      setSelectedCity6('')
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <br />
                            {!enableGoogleAutocomplete && cityOptions6 && cityOptions6.length ? (
                              <>
                                <h6>City</h6>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  options={cityOptions6}
                                  isSearchable
                                  onChange={(e) => {
                                    setSelectedCity6(e.value)
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Tab.Pane>
                        </>
                      ) : (
                        <></>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </Row>
              <Row className={`mt-2rem ${callFrom === "trial" ? "add-location-navigate-btn add-location-row" : 'add-location-navigate-dashboard'}`}>
                <Col className={`${isMobile ? "text-center" : ''}`}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    variant={`outline-secondary`}
                    onClick={() => {
                      if (validateURL?.status === 1) {
                        navigate(
                          callFrom === 'dashboard' ? paths.dashboardSelectWebsite : paths.selectWebsite,
                        )
                      } else {
                        navigate(callFrom === 'dashboard' ? paths.dashboardAddWebsite : paths.addNewWebsite)
                      }
                    }}
                    style={{ height: 40, width: 250 }}
                  >
                    Back
                  </Button>
                </Col>
                <Col className="display-next-end">
                  <Button
                    className="addwebsite-navigate-buttons"
                    onClick={() => {
                      selectRegion()
                    }}
                    style={{ height: 40, width: 250 }}
                    disabled={!(Boolean(selectedRegion) && Boolean(selectedState) && Boolean(selectedCity))}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  )
}

export default AddLocationMain

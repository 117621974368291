import { Card, Col, Row } from 'react-bootstrap'

import * as _ from 'lodash'

import { Line } from '@ant-design/charts'
import 'pages/dashboards/speedGraph.scss'

const SpeedGraphForDashboard = ({ activeSearchData, graphData }) => {
  const activeUrlGraphdata = graphData?.filter((item) => activeSearchData?.websiteId === item?.websiteId)

  const formatData = (data) => {
    return data?.map((dataPoint) => ({
      x: dataPoint?.date,
      y: parseFloat((dataPoint?.loadingSpeed / 1000).toFixed(2)),
    }))
  }

  let newDesktopData = formatData(activeUrlGraphdata?.[0]?.desktopData)

  let newMobileData = formatData(activeUrlGraphdata?.[0]?.mobileData)

  let dData = _.uniqBy(newDesktopData, 'x')
  let mData = _.uniqBy(newMobileData, 'x')

  let desktopData = dData?.sort((a, b) => {
    const [monthA, dayA, yearA] = a?.x?.split('-').map(Number)
    const [monthB, dayB, yearB] = b?.x?.split('-').map(Number)

    if (yearA !== yearB) {
      return yearA - yearB
    }
    if (monthA !== monthB) {
      return monthA - monthB
    }
    return dayA - dayB
  })

  desktopData = desktopData?.map((item) => {
    return {
      ...item,
      x: item?.x.substring(0, 5),
    }
  })

  let mobileData = mData?.sort((a, b) => {
    const [monthA, dayA, yearA] = a?.x?.split('-').map(Number)
    const [monthB, dayB, yearB] = b?.x?.split('-').map(Number)

    if (yearA !== yearB) {
      return yearA - yearB
    }
    if (monthA !== monthB) {
      return monthA - monthB
    }
    return dayA - dayB
  })

  mobileData = mobileData?.map((item) => {
    return {
      ...item,
      x: item?.x.substring(0, 5),
    }
  })

  const desktopConfig = {
    data: (desktopData && desktopData.length && desktopData) || [],
    width: 800,
    height: 400,
    autoFit: true,
    xField: 'x',
    yField: 'y',
    smooth: true,
    animation: false,
    tooltip: {
      formatter: (datum) => {
        return { name: 'Mobile Speed', value: datum.y + ' sec' }
      },
      formatter: (datum) => {
        return { name: 'Desktop Speed', value: datum.y + ' sec' }
      },
    },
    legend: { marker: { symbol: 'circle' } },
    point: {
      size: 5,
      shape: 'circle',
      fill: '#bbb',
    },
  }

  const mobileConfig = {
    data: (mobileData && mobileData.length && mobileData) || [],
    width: 800,
    height: 400,
    autoFit: true,
    animation: false,
    xField: 'x',
    yField: 'y',

    tooltip: {
      formatter: (datum) => {
        return { name: 'Mobile Speed', value: datum.y + ' sec' }
      },
      text: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    smooth: true,
    legend: { marker: { symbol: 'circle' } },
    point: {
      size: 5,
      shape: 'circle',
    },
    meta: {},
    yAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    color: '#26d29f',
  }
  // eslint-disable-next-line no-unused-vars
  let chart
  return (
    <Row>
      {activeUrlGraphdata?.length > 0 ? (
        <>
          <Col lg="6">
            <Card className="flex-fill url-details-inner">
              <Card.Header>
                <Card.Title tag="h1" className="mb-0 f-bold">
                  Desktop Loading Speed
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Line {...desktopConfig} onReady={(chartInstance) => (chart = chartInstance)} />
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="flex-fill  url-details-inner">
              <Card.Header>
                <Card.Title tag="h1" className="mb-0 f-bold">
                  Mobile Loading Speed
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Line {...mobileConfig} onReady={(chartInstance) => (chart = chartInstance)} />
              </Card.Body>
            </Card>
          </Col>
        </>
      ) : (
        <div className="text-center">
          <h3 className="f-bold">Loading speed data is not available</h3>
        </div>
      )}
    </Row>
  )
}
export default SpeedGraphForDashboard

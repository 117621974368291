import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'

import { message } from 'antd'
import { useFormik } from 'formik'
import { object, string } from 'yup'

import { CLEAR_CONTACT_US } from 'redux/actionType'
import { contactUs, getUserData, patchUserDomainsDashboard } from 'redux/actions/dashboard'

import PhoneInput from 'react-phone-input-2'

const ContactUs = () => {
  const dispatch = useDispatch()

  const { contactUsLoader, contactUsFailure, contactUsSuccess, contactUsError, contactUsMessage } =
    useSelector(({ dashboard }) => dashboard)
  const contactFormOptions = [
    { value: 'I need help setting up my website site', label: 'I need help setting up my website site' },
    { value: 'I need help with my SEO', label: 'I need help with my SEO' },
    { value: 'I need help with understanding my report', label: 'I need help with understanding my report' },
    { value: 'I need help with my account', label: 'I need help with my account' },
    { value: 'Something else', label: 'Something else' },
  ]

  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(getUserData())
      dispatch(patchUserDomainsDashboard())
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    if (!contactUsLoader && contactUsSuccess) {
      if (contactUsMessage) {
        message.success(contactUsMessage?.message)
        dispatch({ type: CLEAR_CONTACT_US })
      }
    } else if (!contactUsLoader && contactUsFailure) {
      if (contactUsError) {
        message.error(contactUsError?.message)
        dispatch({ type: CLEAR_CONTACT_US })
      }
    }
  }, [contactUsLoader, contactUsFailure, contactUsSuccess, contactUsError, contactUsMessage, dispatch])

  const initialValues = {
    name: '',
    email: '',
    help: '',
    message: '',
    phoneNumber: '',
  }

  const validationSchema = object().shape({
    name: string().required('Please enter your name'),
    email: string().email('Please provide a valid email').required('Please enter your email'),
    help: string().required('Please select your concern'),
  })

  const validateForm = (values) => {
    const errors = {}

    if (values.help === contactFormOptions[contactFormOptions.length - 1].value && !values.message) {
      errors.message = 'Please provide detailed description of your issue'
    }

    return errors
  }
  const handleContactUs = (values) => {
    const errors = validateForm(values)

    if (Object.keys(errors).length === 0) {
      const payload = {
        templateId: 45,
        subject: 'Request for Support', 
        to: [{"email": "support@positionmysite.com","name":"PMS support"}],
        cc: [{"email":"micks.9093@gmail.com","name":"micks"}],
        bcc: [{"email":"micks.9093@gmail.com","name":"micks"}],
        params: {
          customerEmail: values?.email,
          customerName: values?.name,
          customerPhone: `+${values?.phoneNumber}` || '[NOT PROVIDED]',
          customerIssue: values?.help,
          customerMessage: values?.message || '[NOT PROVIDED]',
        }
      }

      dispatch(contactUs(payload))
    } else {
      contactFormik.setErrors(errors)
    }
  }

  const contactFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleContactUs,
  })

  const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue } = contactFormik

  return (
    <Card>
      <Card.Header className="pb-0">
        <Card.Title className="mb-0">
          <h3 className="f-bold">Contact Us</h3>
        </Card.Title>
        <hr />
      </Card.Header>
      <Card.Body className="pt-0">
        <Row className="px-3">
          <Col xs={12} md={6}>
            <p>
              Welcome to our Contact Us page!
              <br />
              we are dedicated to providing you with excellent support and assistance. Whether you need help
              setting up your website, optimizing your SEO, understanding your analytics reports, managing
              your account, or anything else related to our services, we are here to help.
            </p>

            <h4>How We Can Assist You</h4>
            <ul className="d-flex gap-3 flex-column">
              <li>
                <strong>Setting Up Your Website:</strong> Let us assist you in getting your website up and
                running smoothly.
              </li>
              <li>
                <strong>SEO Optimization:</strong> Enhance your online presence with our expert SEO strategies
                and advice.
              </li>
              <li>
                <strong>Understanding Your Report:</strong> Gain insights into your website's performance with
                detailed analytics reports and explanations.
              </li>
              <li>
                <strong>Account Assistance:</strong> Manage your account effectively with our support team.
              </li>
              <li>
                <strong>Others:</strong> If you have a specific concern that isn't covered above, please feel
                free to reach out to us. We are here to address any questions or issues you may have.
              </li>
            </ul>

            <p>
              Our goal is to ensure your experience with <strong>PositionMySite</strong> is seamless and
              successful.
              <br />
              Please don't hesitate to contact us using the form provided or reach out to our support team
              directly at <strong>support@positionmysite.com</strong>.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <div>
              <p>
                If you have any questions or concerns, please feel free to reach out to us. We are here to
                help!
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="my-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    isInvalid={Boolean(touched.name && errors.name)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.name && (
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    isInvalid={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.email && (
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneInput
                    country={'us'}
                    name="phoneNumber"
                    onChange={(phone) => setFieldValue('phoneNumber', phone)}
                    value={values.phoneNumber}
                    inputStyle={{ width: '100%' }}
                    containerClass={`${errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''} `}
                  />

                  {!!touched.phoneNumber && (
                    <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label>Help options</Form.Label>
                  <Select
                    name="help"
                    placeholder="Select your concern"
                    onChange={(option) => {
                      setFieldValue('help', option.value)
                    }}
                    options={contactFormOptions}
                  />
                  {contactFormik.errors.help && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {contactFormik.errors.help}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {values.help === contactFormOptions[contactFormOptions.length - 1].value && (
                  <Form.Group className="my-3">
                    <Form.Label>Additional Information</Form.Label>
                    <Form.Control
                      name="message"
                      as="textarea"
                      rows={3}
                      placeholder="Describe your issue"
                      value={values.message}
                      isInvalid={Boolean(contactFormik.errors.message)}
                      onChange={handleChange}
                    />
                    {!!contactFormik.errors.message && (
                      <Form.Control.Feedback type="invalid">
                        {contactFormik.errors.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}

                <Form.Group>
                  <Button
                    className="d-flex gap-2 align-items-center"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={contactUsLoader}
                  >
                    Send
                    {contactUsLoader ? <Spinner size="sm" animation="border" /> : ''}
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ContactUs

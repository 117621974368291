import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const ImageAlt = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [imageAltFlag, setImageAltFlag] = useState(false)

  const columns = [
    {
    id:'ImageAlt',
    displayName:'Image alt',
  },
]

let imageAltData = data?.imageAlt?.data?.imgAlt?.length && data?.imageAlt?.data?.imgAlt?.map(item => {
  return {
    ['Image alt']: item && item?.length && item?.replace(/\n|\t|\s{2,}/g, '')
  }
})


  return (
    <>
      <ModalHOC
        show={imageAltFlag}
        toggleShow={() => setImageAltFlag(!imageAltFlag)}
        title={'Image Alt Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        columns={columns}
        datas={imageAltData}
        showDownload
        fileName='Image Alt Test'
        alternativeDownload={true}
      >
        <Table striped className="d-none d-sm-block">
          <thead>
            <th>Image Alt</th>
          </thead>
          <tbody>
            {data?.imageAlt?.data?.imgAlt?.length ? (
              <>
                {data?.imageAlt?.data?.imgAlt?.map((item, index) => {
                  if (typeof item === 'string') {
                    return (
                      <tr key={`imageAlt-${index+1}`}>
                        <td>{item}</td>
                      </tr>
                    )
                  } else if (item instanceof Object) {
                    return (
                      <tr key={`imageAlt-${index+1}`}>
                        <td>{item?.node?.snippet}</td>
                      </tr>
                    )
                  }
                })}{' '}
              </>
            ) : null }
          </tbody>
        </Table>
      </ModalHOC>

      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.imageAlt?.data?.testResults?.status ? (
            ''
          ) : data?.imageAlt?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.imageAlt?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
            {data.imageAlt?.data?.testResults ? (
              <>{data.imageAlt?.data?.testResults?.message || ''}</>
            ) : null}    
        </p>
      </div>
      <div className="vr-detail-block">
        {showDetail && data?.imageAlt?.data?.testResults?.status === 3 ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setImageAltFlag(!imageAltFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default ImageAlt

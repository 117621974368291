import CommonFreeTool from '../CommonFreeTool'
import { freeTools } from 'constants.js'

const MetaDescription = () => {
  return (
    <CommonFreeTool
      heading={freeTools.metaDescription.heading}
      whatIsIT={freeTools.metaDescription.what}
      howToFixBeforeCode={freeTools.metaDescription.howSectionBeforeCode}
      codeSection={freeTools.metaDescription.codeSection}
      howToFixAfterCode={freeTools.metaDescription.howSectionAfterCode}
    />
  )
}

export default MetaDescription

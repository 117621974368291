import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Col, Container, Row } from 'react-bootstrap'

const TermsOfUse = () => (
  <React.Fragment>
    <Helmet title="Terms of Use" />
    <Container className="p-0">
      <Row>
        <Col md={12}>
          <i>Last updated: October 5, 2022</i>
          <br />
          <br />
          The following Terms and Conditions ("Terms", "Terms and Conditions") apply to your use of the{' '}
          <a href="https://tools.positionmysite.com" target="_blank" rel="noreferrer">
            https://tools.positionmysite.com
          </a>{' '}
          website operated by PMS ("us", "we", or "our").
          <br />
          To access or use this service, you must accept and comply with these Terms. These Terms apply to all
          visitors, users, and others who wish to access or use the service.
          <br />
          You agree to be bound by these Terms by accessing or using the service. You cannot access the
          service if you disagree with any part of these Terms.
          <br />
          <br />
          <b>Paid Subscriptions.</b>
          <br />
          You will be billed in advance on a recurring and periodic basis ("Billing Cycle") for some parts of
          the Service (Subscription(s)). Depending on the subscription plan you choose, billing cycles can be
          set either on a monthly or an annual basis.
          <br />
          If you do not cancel your Subscription on your online account management page, or if PMS cancels it,
          your Subscription will automatically renew under the same conditions unless you cancel it or PMS
          cancels it.
          <br />
          Subscription payments must be processed using a valid payment method such as a credit card or
          PayPal. PMS requires complete and accurate billing information, including full name, address, state,
          zip code, telephone number, and a valid payment method. By providing such payment information, you
          automatically authorize PMS to charge all Subscription fees incurred through your account to any
          such payment instrument.
          <br />
          If automatic billing does not occur for any reason, PMS will issue a formal invoice indicating that
          the complete payment corresponding to the billing period, as indicated on the invoice, must be made
          manually by a specific deadline date.
          <br />
          <br />
          <b>Trial Offer.</b>
          <br />
          PMS may offer a Subscription with a free trial for a limited time ("Free Trial").
          <br />
          You may need to enter your billing information to sign up for the Free Trial.
          <br />
          When you sign up for a Free Trial, PMS won't charge you until the trial expires if you enter your
          billing information. Unless you cancel your Subscription, you will be charged the applicable
          Subscription fee for the type of Subscription you selected on the last day of the Free Trial period.
          Free Trial offers can be modified or cancelled anytime without notice by PMS.
          <br />
          <br />
          <b>Modifications to fees.</b>
          <br />
          PMS may modify subscription fees at any time at its sole discretion. Changes will take effect at the
          end of the then-current billing cycle.
          <br />
          If your Subscription fees change, PMS will provide you with reasonable prior notice so that you can
          terminate your Subscription before the change takes effect.
          <br />
          If you continue to use the service after the Subscription fee change takes effect, you agree to pay
          the modified Subscription fee.
          <br />
          <br />
          <b>Cancellations and refunds.</b>
          <br />
          Subscription fees are non-refundable except when required by law.
          <br />
          <br />
          <b>User accounts.</b>
          The information you provide us when you create an account with us must be accurate, complete, and
          current. You must keep your account and password confidential and restrict access to your computer,
          as PMS may terminate your account on the service immediately if your information is inaccurate,
          incomplete, or outdated. You agree to take responsibility for any activities and actions carried out
          under your account and password, whether with our service or a third-party service. If you become
          aware of a security breach or unauthorized use of your account, please notify us immediately.
          <br />
          <br />
          <b>Policy on Copyright.</b>
          <br />
          The service respects the intellectual property rights of others. If you claim that any Content
          posted on the service infringes on the intellectual property rights of another person or entity (an
          "Infringement"), we will investigate the claim.
          <br />
          You may claim that a copyrighted work has been copied in a manner that constitutes copyright
          infringement if you are a copyright owner or authorized representative of a copyright owner. Please
          submit your claim by email to info@positionmysite.com with the subject line "Copyright
          Infringement," as detailed below, under "DMCA Notice and Procedure for Copyright Infringement
          Claims."
          <br />
          For misrepresentation or bad-faith claims on the infringement of your copyright on content found on
          and/or through the service, you may be held responsible for damages (including attorneys' fees).
          <br />
          <br />
          <b>Copyright Infringement Notice and Procedure</b>
          <br />
          Please provide our Copyright Agent with the following information in writing, following the Digital
          Millennium Copyright Act (DMCA) (see 17 USC 512(c)(3) for further detail):
          <br />
          <ul>
            <li>
              An electronic or physical signature of the person authorized to act on behalf of the copyright
              owner;
            </li>
            <li>
              It would help if you described the copyrighted work that you claim had been infringed, including
              its URL (i.e., website address) or a copy of it;
            </li>
            <li>
              Identify the URL or other specific location on the Service where PMS can find the allegedly
              infringing material;
            </li>
            <li>Name, address, and telephone number;</li>
            <li>
              You must affirm that you have a good faith belief that the disputed use is prohibited by law,
              the owner of the copyright, or its agent;
            </li>
            <li>
              You must state under penalty of perjury that the above information in your notice is accurate
              and that you are the copyright owner or authorized to act on behalf of the copyright owner.
            </li>
            <li>Please send an email to info@positionmysite.com to reach our Copyright Agent.</li>
          </ul>
          <br />
          <br />
          <b>Patents and intellectual property.</b>
          <br />
          The service and its original content, features and functionality are and will remain the exclusive
          property of PMS and its licensors. PMS' trademarks and trade dress may not be used in connection
          with any product or service without our prior written consent. The service is protected by
          copyright, trademark, and other laws of the United States and abroad.
          <br />
          <br />
          <b>The following are links to other websites.</b>
          <br />
          PMS may provide links to third party web sites or services that are not owned or controlled by us.
          <br />
          Any third-party websites or services are not under the control or responsibility of PMS, and we are
          not responsible for their content, privacy policies, or practices.
          <br />
          In no event shall PMS be liable for any direct or indirect damage or loss caused by or in connection
          with the use or reliance on any of these third party web sites or services.
          <br />
          Any third-party website or service you visit should have its terms and conditions and privacy
          policies.
          <br />
          <br />
          <b>Assumption of termination.</b>
          <br />
          For any reason whatsoever and without limitation, such as but not limited to a breach of the Terms,
          we may suspend or terminate your account and bar access to the service immediately.
          <br />
          Discontinue using the service if you wish to terminate your account.
          <br />
          As a general rule, all provisions of the Terms that are meant to survive termination will do so,
          including, without limitation, ownership provisions, warranty disclaimers, indemnity provisions, and
          limitations of liability.
          <br />
          <br />
          <b>Providing indemnification.</b>
          <br />
          The licensee and licensors of PMS, as well as their employees, contractors, agents, officers and
          directors, agree that you are responsible for defending, indemnifying and holding harmless them
          against all claims, damages, obligations, losses, liabilities, costs and debts, as well as expenses
          (including attorney's fees), resulting from or arising from (a) your use and access to the service,
          by you, by any person who uses your account and password, or b) a breach of these Terms.
          <br />
          <br />
          <b>Liability Limitation.</b>
          <br />
          There shall be no indirect, incidental, special, consequential, or punitive damages of any kind or
          nature suffered by PMS, its directors, employees, partners, agents, suppliers, or affiliates,
          including but not limited to loss of profit, data, use, goodwill, or other intangible losses,
          resulting from Using the service or not being able to use it; Third-party content or conduct on the
          service; From the service, any content obtained; and PMS may access your transmissions or content,
          used or altered unauthorized, whether based on warranty, contract, or tort (including negligence),
          Regardless of whether or not the possibility of such damage has been disclosed to us and regardless
          of whether a remedy provided herein is found to have failed.
          <br />
          <br />
          <b> Notice of disclaimer.</b>
          <br />
          Your use of the service is at your sole risk. The service is provided on an "AS IS" and "AS
          AVAILABLE" basis. The service is provided without warranties of any kind, whether express or
          implied, including, but not limited to, implied warranties of merchantability, fitness for a
          particular purpose, non-infringement or course of performance.
          <br />
          PMS, its subsidiaries, affiliates, and licensors do not warrant that
          <br />
          <ul>
            <li>
              There is no guarantee that the service will be uninterrupted, secure, or available at any
              particular time or place;
            </li>
            <li>We will correct any errors or defects;</li>
            <li>Viruses or other harmful components are not present in the service;</li>
            <li>As a result of using the service, you will be satisfied with the results.</li>
          </ul>
          <br />
          <br />
          <b>Limitations.</b>
          <br />
          The limitations above may not apply to you if your jurisdiction does not allow the exclusion of
          certain warranties or the exclusion or limitation of liability for consequential or incidental
          damages.
          <br />
          <br />
          <b>Law that governs.</b>
          <br />
          Florida, United States law shall govern and construe these Terms without regard to its conflict of
          law provisions.
          <br />
          It will not be viewed as a waiver of our rights if we fail to enforce any of these Terms. If any
          provision of these Terms is invalidated or unenforceable by a court, the remaining provisions will
          remain in effect. In addition to superseding and replacing any previous agreements we might have had
          regarding the service, these Terms constitute our entire agreement regarding our service.
          <br />
          <br />
          <b> Modifications.</b>
          <br />
          PMS may modify these Terms at our sole discretion at any time. If a revision is a material, we will
          notify you 30 days before the new terms take effect. What constitutes PMS may determine a material
          change at our sole discretion.
          <br />
          Please note that if you continue to use the service after any revisions become effective, you agree
          to be bound by the revised terms. If you disagree with the new terms, you will no longer be able to
          use them.
          <br />
          <br />
          <b>Feel free to contact us.</b>
          You may contact us at info@positionmysite.com with any questions about these Terms.
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)

export default TermsOfUse

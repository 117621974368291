import React, { useMemo } from 'react'
import { VectorMap } from 'react-jvectormap'
import { useSelector } from 'react-redux'

import usePalette from '../../hooks/usePalette'

function WorldMap({setFlagForRedMarker}) {
  const palette = usePalette()
  const activeSiteOutageData = useSelector(({ dashboard }) => dashboard?.activeSiteOutageData)
  const siteOutageLoading = useSelector(({ dashboard }) => dashboard?.siteOutageLoading)
  
  const options = useMemo(() => {
    const todayData =
    (activeSiteOutageData &&
      activeSiteOutageData?.length &&
      activeSiteOutageData?.[0]?.dataset &&
      activeSiteOutageData?.[0]?.dataset.length &&
      activeSiteOutageData?.[0]?.dataset) ||
      []

     let siteOutageData = _.sortBy(todayData, 'hour') 
     siteOutageData = siteOutageData?.[0]?.values || []
     const seriesData = []
    
     let dataForMap =
      siteOutageData &&
      siteOutageData?.length &&
      siteOutageData?.map((item) => {
        const latlongArray = []
        latlongArray.push(item?.lat)
        latlongArray.push(item?.long)
        seriesData.push(item?.status === '200' ? 'green' : 'red')
        return {
          latLng: latlongArray,
          name: item?.region || '-',
        }
      })

    const redMarkerFlag = seriesData?.every(item => {
      return item === "red"
    })
   
    if(!siteOutageLoading &&  seriesData?.length){
      setFlagForRedMarker(redMarkerFlag)
    }
    
    return {
      map: 'world_mill',
      normalizeFunction: 'polynomial',
      hoverOpacity: 0.7,
      hoverColor: false,
      zoomOnScroll: false,
      regionStyle: {
        initial: {
          fill: palette['gray-200'],
        },
      },
      markersSelectableOne: true,
      onRegionTipShow: function () {
        return false /* Don't show the standard region tip */
      },
      onMarkerOver: function () {
      },
      onMarkerClick: function () {
      },
      containerStyle: {
        width: '100%',
        height: '100%',
      },
      backgroundColor: 'transparent',
      markers: dataForMap && dataForMap?.length ? dataForMap : [],
      series: {
        markers: [
          {
            attribute: 'fill',
            scale: {
              green: '#4BBF73',
              red: '#ff0000',
            },
            values: Object.assign({}, seriesData),
          },
        ],
      },
    }
  }, [activeSiteOutageData, activeSiteOutageData?.[activeSiteOutageData.length - 1]?.dataset?.length, siteOutageLoading])

  return (
    <>
      <div className="map-container" style={{ height: 279 }}>
        {options ? <VectorMap {...options} /> : ''}
      </div>
    </>
  )
}

const MemoizedMap = React.memo(WorldMap)

export default MemoizedMap

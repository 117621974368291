import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Accordion } from 'react-bootstrap'
import { Table } from 'reactstrap'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'
import { useDispatch } from 'react-redux'
const HeadingAnalysis = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()

  const [headingAnalysisFlag, setHeadingAnalysisFlag] = useState(false)
  
  const datas1 = data?.headingAnalysis?.data?.h1?.map((item) => {
    return { ['H1 Tags']: item.replace(/\n|\s{2,}/g, '') }
  })

  const datas2 = data?.headingAnalysis?.data?.h2?.map((item) => {
    return { ['H2 Tags']: item?.replace(/\n|\s{2,}/g, '') }
  })

  const datas3 = data?.headingAnalysis?.data?.h3?.map((item) => {
    return { ['H3 Tags']: item?.replace(/\n|\s{2,}/g, '') }
  })

  const datas4 = data?.headingAnalysis?.data?.h4?.map((item) => {
    return { ['H4 Tags']: item?.replace(/\n|\s{2,}/g, '') }
  })

  const datas5 = data?.headingAnalysis?.data?.h5?.map((item) => {
    return { ['H5 Tags']: item?.replace(/\n|\s{2,}/g, '') }
  })

  const datas6 = data?.headingAnalysis?.data?.h6?.map((item) => {
    return { ['H6 Tags']: item?.replace(/\n|\s{2,}/g, '') }
  })

  let headingAnalysisCSVData = []
  let dataForDownload=[
    { key: 'H1 Tags', datas: datas1},
    { key: 'H2 Tags', datas: datas2},
    { key: 'H3 Tags', datas: datas3},
    { key: 'H4 Tags', datas: datas4},
    { key: 'H5 Tags', datas: datas5},
    { key: 'H6 Tags', datas: datas6},
  ]

  dataForDownload?.forEach(({key, datas}) => {
    if(datas?.length){
      headingAnalysisCSVData?.push([key])
    
    datas?.forEach((row) => {
      headingAnalysisCSVData?.push([row?.[key]])
    })
    headingAnalysisCSVData.push([])
  }
});
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.headingAnalysis?.data?.testResults?.status ? (
            ''
          ) : data?.headingAnalysis?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.headingAnalysis?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        {data?.headingAnalysis?.data?.headingOrder?.list ? (
          <>
            <p>
              {data.headingAnalysis?.data?.testResults ? (
                <>{data.headingAnalysis?.data?.testResults?.message || ''}</>
              ) : (
                <></>
              )}
            </p>

            {/* <p>{data?.headingAnalysis?.data?.headingOrder?.title}</p> */}
            <ul>
              {data?.headingAnalysis?.data?.headingOrder?.list.map((item, index) => {
                return (
                  <li key={`headingAnalysis-${index +1}`}>
                    <p>{item.explanation}</p>
                    <p>{item.snippet}</p>
                  </li>
                )
              })}{' '}
            </ul>
          </>
        ) : (
          <></>
        )}
        {showDetail && data?.headingAnalysis?.data?.testResults?.status !== 1 ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setHeadingAnalysisFlag(!headingAnalysisFlag)
                } else {
                  dispatch(openRegisterModal(true))
                 }
                }
              } 
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={headingAnalysisFlag}
        toggleShow={() => setHeadingAnalysisFlag(!headingAnalysisFlag)}
        title={'Heading Analysis'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload = {datas1?.length || datas2?.length || datas3?.length || datas4?.length || datas5?.length || datas6?.length ? true : false}
        datas={headingAnalysisCSVData}
        fileName='Heading Tag Analysis Signals'
        alternativeDownload={true}
      >
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="bg-white">
            <Accordion.Header>{`H1 Tags`}</Accordion.Header>
            <Accordion.Body className="bg-white">
              {data?.headingAnalysis?.data?.h1?.length ? (
                <>
                  <Table striped>
                    <tbody>
                      {data?.headingAnalysis?.data?.h1.map((item, index) => {
                        return (
                          <tr key={`headingAnalysisH1-${index+1}`}>
                            <td>{item}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {data?.headingAnalysis?.data?.h1.filter((item) => item.length === 0).length ? (
                    <p className="text-danger">
                      {data?.headingAnalysis?.data?.h1.filter((item) => item.length === 0).length} empty H1
                      tags found{' '}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <p className="text-danger">No H1 Tag Found in the website.</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="bg-white">
            <Accordion.Header>{`H2 Tags`}</Accordion.Header>
            <Accordion.Body className="bg-white">
              {data?.headingAnalysis?.data?.h2?.length ? (
                <>
                  <Table striped>
                    <tbody>
                      {data?.headingAnalysis?.data?.h2.map((item, index) => {
                        return (
                          <tr key={`headingAnalysisH2-${index+1}`}>
                            <td>{item}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {data?.headingAnalysis?.data?.h2.filter((item) => item.length === 0).length ? (
                    <p className="text-danger">
                      {data?.headingAnalysis?.data?.h2.filter((item) => item.length === 0).length} empty H2
                      tags found{' '}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <p className="text-danger">No H2 Tag Found in the website.</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="bg-white">
            <Accordion.Header>{`H3 Tags`}</Accordion.Header>
            <Accordion.Body className="bg-white">
              {data?.headingAnalysis?.data?.h3?.length ? (
                <>
                  <Table striped>
                    <tbody>
                      {data?.headingAnalysis?.data?.h3.map((item, index) => {
                        return (
                          <tr key={`headingAnalysisH3-${index+1}`}>
                            <td>{item}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {data?.headingAnalysis?.data?.h3.filter((item) => item.length === 0).length ? (
                    <p className="text-danger">
                      {data?.headingAnalysis?.data?.h3.filter((item) => item.length === 0).length} empty H3
                      tags found{' '}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <p className="text-danger">No H3 Tag Found in the website.</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="bg-white">
            <Accordion.Header>{`H4 Tags`}</Accordion.Header>
            <Accordion.Body className="bg-white">
              {data?.headingAnalysis?.data?.h4?.length ? (
                <>
                  <Table striped>
                    <tbody>
                      {data?.headingAnalysis?.data?.h4.map((item, index) => {
                        return (
                          <tr key={`headingAnalysisH4-${index+1}`}>
                            <td>{item}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {data?.headingAnalysis?.data?.h4.filter((item) => item.length === 0).length ? (
                    <p className="text-danger">
                      {data?.headingAnalysis?.data?.h4.filter((item) => item.length === 0).length} empty H4
                      tags found{' '}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <p className="text-danger">No H4 Tag Found in the website.</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className="bg-white">
            <Accordion.Header>{`H5 Tags`}</Accordion.Header>
            <Accordion.Body className="bg-white">
              {data?.headingAnalysis?.data?.h5?.length ? (
                <>
                  <Table striped>
                    <tbody>
                      {data?.headingAnalysis?.data?.h5.map((item, index) => {
                        return (
                          <tr key={`headingAnalysisH5-${index+1}`}>
                            <td>{item}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {data?.headingAnalysis?.data?.h5.filter((item) => item.length === 0).length ? (
                    <p className="text-danger">
                      {data?.headingAnalysis?.data?.h5.filter((item) => item.length === 0).length} empty H5
                      tags found{' '}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <p className="text-danger">No H5 Tag Found in the website.</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="bg-white">
            <Accordion.Header>{`H6 Tags`}</Accordion.Header>
            <Accordion.Body className="bg-white">
              {data?.headingAnalysis?.data?.h6?.length ? (
                <>
                  <Table striped>
                    <tbody>
                      {data?.headingAnalysis?.data?.h6.map((item, index) => {
                        return (
                          <tr key={`headingAnalysisH6-${index+1}`}>
                            <td>{item}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {data?.headingAnalysis?.data?.h6.filter((item) => item.length === 0).length ? (
                    <p className="text-danger">
                      {data?.headingAnalysis?.data?.h6.filter((item) => item.length === 0).length} empty H6
                      tags found{' '}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <p className="text-danger">No H6 Tag Found in the website.</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </ModalHOC>
    </>
  )
}

export default HeadingAnalysis

import { Table, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'

import 'pages/dashboards/TrafficAnalytics/TrafficAnalytics.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const TrafficAnalyticsData = ({ analytics }) => {
 
  const { isMobile } = useDeviceCheck()
  const [usersByCountry, setUsersByCountry] = useState([])
  const [viewsByPageTitle, setViewsByPageTitle] = useState([])
  const [usersByCity, setUsersByCity] = useState([])

  useEffect(() => {
    let usersByCountryData = []

    analytics?.usersByCountry?.length &&
      analytics?.usersByCountry?.map((obj, index) => {
        const indexOfCoutry = usersByCountryData?.findIndex(
          (item) => item.country === obj?.country,
        )

        if (indexOfCoutry > -1) {
          usersByCountryData[indexOfCoutry] = {
            ...usersByCountryData[indexOfCoutry],
            activeUsers: usersByCountryData[indexOfCoutry]?.activeUsers + parseInt(obj?.users),
          }
        } else {
          usersByCountryData.push({
            key: index + 1,
            country: obj?.country,
            activeUsers: parseInt(obj?.users),
          })
        }
      })

    setUsersByCountry(usersByCountryData)

    let viewsByPageTitleData = []

    analytics?.viewsByPageTitle?.length &&
      analytics?.viewsByPageTitle?.map((obj, index) => {
        const indexOfTitle = viewsByPageTitleData?.findIndex(
          (item) => item.pageTitle === obj?.pageTitle,
        )

        if (indexOfTitle > -1) {
          viewsByPageTitleData[indexOfTitle] = {
            ...viewsByPageTitleData[indexOfTitle],
            activeUsers: viewsByPageTitleData[indexOfTitle]?.views + parseInt(obj?.views),
          }
        } else {
          viewsByPageTitleData.push({
            key: index + 1,
            pageTitle: obj?.pageTitle,
            views: parseInt(obj?.views),
          })
        }
      })

    setViewsByPageTitle(viewsByPageTitleData)

    let usersByCityData = []

    analytics?.usersByCity?.length &&
      analytics?.usersByCity?.map((obj, index) => {
        const indexOfCity = usersByCityData?.findIndex(
          (item) => item.city === obj?.city,
        )

        if (indexOfCity > -1) {
          usersByCityData[indexOfCity] = {
            ...usersByCityData[indexOfCity],
            activeUsers: usersByCityData[indexOfCity]?.users + parseInt(obj?.users),
          }
        } else {
          usersByCityData.push({
            key: index + 1,
            city: obj?.city,
            activeUsers: parseInt(obj?.users),
          })
        }
      })

      setUsersByCity(usersByCityData)
  }, [analytics])

  const maxUsersForCountry =
    usersByCountry?.length &&
    usersByCountry?.reduce((acc, item) => {
      return acc + parseInt(item?.activeUsers)
    }, 0)

  const columnsForUsersByCountry = [
    {
      title: 'COUNTRY',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'USERS',
      dataIndex: 'activeUsers',
      key: 'activeUsers',
      align: 'right',
      render: (activeUsers) => (
        <>
          <div>{activeUsers}</div>
          <ProgressBar now={(activeUsers / maxUsersForCountry) * 100} />
        </>
      ),
    },
  ]

  const maxViews =
  viewsByPageTitle?.length &&
  viewsByPageTitle?.reduce((acc, item) => {
    return acc + parseInt(item?.views)
  }, 0)

  const columnsForViewsByPageTitle = [
    {
      title: 'PAGE TITLE',
      dataIndex: 'pageTitle',
      key: 'pageTitle',
      render: (text) => (
        <Tooltip title={text}>
          <div 
            style={{ 
              maxWidth: !isMobile ? 250 : 160,
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap' 
              }}
            >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'VIEWS',
      dataIndex: 'views',
      key: 'views',
      align: 'right',
      render: (views) => (
        <>
          <div>{views}</div>
          <ProgressBar now={(views / maxViews) * 100} />
        </>
      ),
    },
  ]

  const maxUsersForCity =
    usersByCity?.length &&
    usersByCity?.reduce((acc, item) => {
      return acc + parseInt(item?.activeUsers)
    }, 0)

  const columnsForUsersByCity = [
    {
      title: 'CITY',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'USERS',
      dataIndex: 'activeUsers',
      key: 'activeUsers',
      align: 'right',
      render: (activeUsers) => (
        <>
          <div>{activeUsers}</div>
          <ProgressBar now={(activeUsers / maxUsersForCity) * 100} />
        </>
      ),
    },
  ]

  return (
      <div className='traffic-analytics-card-wrapper'>
      <Card className='traffic-analytics-card'>
        <Card.Header>
          <h3 className="f-bold">Users by country</h3>
        </Card.Header>
        <Card.Body>
          <Table 
            dataSource={usersByCountry} 
            columns={columnsForUsersByCountry} 
            pagination={{ pageSize: 5, showSizeChanger: false, size: 'small'   }} 
          />
        </Card.Body>
      </Card>
      <Card className='traffic-analytics-card'>
        <Card.Header>
          <h3 className="f-bold">Views by page title and screen class</h3>
        </Card.Header>
        <Card.Body>
          <Table 
            dataSource={viewsByPageTitle} 
            columns={columnsForViewsByPageTitle} 
            pagination={{ pageSize: 5, showSizeChanger: false, size: 'small'   }} 
          />
        </Card.Body>
      </Card>
      <Card className='traffic-analytics-card'>
        <Card.Header>
          <h3 className="f-bold">Users by city</h3>
        </Card.Header>
        <Card.Body>
          <Table 
            dataSource={usersByCity} 
            columns={columnsForUsersByCity} 
            pagination={{ pageSize: 5, showSizeChanger: false, size: 'small' }} 
          />
        </Card.Body>
      </Card>
      </div>
  )
}

export default TrafficAnalyticsData

import AddKeywordsMain from 'modules/welcomeBoard/components/addKeyword/AddKeywordsMain.jsx'

const AddKeywordsTrial = () => {

  return (
    <>
      <AddKeywordsMain callFrom="trial" />
    </>
  )
}

export default AddKeywordsTrial

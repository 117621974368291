import { put, takeLatest, takeEvery, all } from 'redux-saga/effects'

// action type
import {
  FREE_WHOIS_FAIL,
  FREE_WHOIS_START,
  FREE_WHOIS_SUCCESS,
  FREE_DOMAIN_SCORE_SUCCESS,
  FREE_DOMAIN_SCORE_START,
  FREE_DOMAIN_SCORE_FAIL,
  FREE_PAGE_OBJECT,
  FREE_PAGE_OBJECT_SUCCESS,
  FREE_PAGE_OBJECT_FAILURE,
  FREE_SPF_RECORD,
  FREE_SPF_RECORD_SUCCESS,
  FREE_SPF_RECORD_FAILURE,
  FREE_NO_FOLLOW,
  FREE_NO_FOLLOW_SUCCESS,
  FREE_NO_FOLLOW_FAILURE,
  FREE_TEST,
  FREE_TEST_SUCCESS,
  FREE_TEST_FAILURE,
  QUICK_SEO_CHECKUP,
  QUICK_SEO_CHECKUP_SUCCESS,
  QUICK_SEO_CHECKUP_FAILURE,
  GET_QUICK_CHECKUP_REPORT,
  GET_QUICK_CHECKUP_REPORT_FAILURE,
  GET_QUICK_CHECKUP_REPORT_SUCCESS,
  GET_URL_REPORT,
  GET_URL_REPORT_SUCCESS,
  GET_URL_REPORT_FAILURE,
} from '../../actionType'

// api
import {
  getRequest,
  postLocalRequestWithToken,
  postLocalRequestWithTokenSeoCheckup,
} from '../../../constants/httpHelperService'
const WhoisApi = async (input) => {
  return await postLocalRequestWithToken(`whois`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* WhoisData(action) {
  try {
    const data = yield WhoisApi(action.payload)
    yield put({ type: FREE_WHOIS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FREE_WHOIS_FAIL, e })
  }
}

function* WhoisDataSaga() {
  yield takeLatest(FREE_WHOIS_START, WhoisData)
}

const domainScoreApi = async (input) => {
  return await postLocalRequestWithToken(`domain-score`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* domainScoreData(action) {
  try {
    const data = yield domainScoreApi(action.payload)
    yield put({ type: FREE_DOMAIN_SCORE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FREE_DOMAIN_SCORE_FAIL, e })
  }
}

function* domainScoreDataSaga() {
  yield takeLatest(FREE_DOMAIN_SCORE_START, domainScoreData)
}

const pageObjectFreeApi = async (input) => {
  return await postLocalRequestWithToken(`page-object`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* pageObjectFreeData(action) {
  try {
    const data = yield pageObjectFreeApi(action.payload)
    yield put({ type: FREE_PAGE_OBJECT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FREE_PAGE_OBJECT_FAILURE, e })
  }
}

function* pageObjectFreeDataSaga() {
  yield takeLatest(FREE_PAGE_OBJECT, pageObjectFreeData)
}

const spfRecordFreeApi = async (input) => {
  return await postLocalRequestWithToken(`spf-record`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* spfRecordFreeData(action) {
  try {
    const data = yield spfRecordFreeApi(action.payload)
    yield put({ type: FREE_SPF_RECORD_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FREE_SPF_RECORD_FAILURE, e })
  }
}

function* spfRecordFreeDataSaga() {
  yield takeLatest(FREE_SPF_RECORD, spfRecordFreeData)
}

const noFollowFreeApi = async (input) => {
  return await postLocalRequestWithToken(`no-follow`, input)
    .then((res) => res)
    .catch((error) => error)
}

function* noFollowFreeData(action) {
  try {
    const data = yield noFollowFreeApi(action.payload)
    yield put({ type: FREE_NO_FOLLOW_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FREE_NO_FOLLOW_FAILURE, e })
  }
}

function* noFollowFreeDataSaga() {
  yield takeLatest(FREE_NO_FOLLOW, noFollowFreeData)
}

const freeTestApi = async (input) => {
  switch (input.type) {
    case 'pageObject': {
      return await postLocalRequestWithToken(`page-object`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'noFollow': {
      return await postLocalRequestWithToken(`no-follow`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'spfRecords': {
      return await postLocalRequestWithToken(`spf-record`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'disallowDirective': {
      return await postLocalRequestWithToken(`disallow-directive`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'noIndex': {
      return await postLocalRequestWithToken(`no-index`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'socialMedia': {
      return await postLocalRequestWithToken(`social-media`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'urlCanonicalization': {
      return await postLocalRequestWithToken(`url-canonicalization`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'safeBrowsing': {
      return await postLocalRequestWithToken(`safe-browser`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'serverSignature': {
      return await postLocalRequestWithToken(`server-signature`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'redirect': {
      return await postLocalRequestWithToken(`redirect`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'pageSize': {
      return await postLocalRequestWithToken(`page-size`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'schema': {
      return await postLocalRequestWithToken(`schema`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'mediaQuery': {
      return await postLocalRequestWithToken(`media-query`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'pageCache': {
      return await postLocalRequestWithToken(`page-cache`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'jsError': {
      return await postLocalRequestWithToken(`js-error`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'jsCache': {
      return await postLocalRequestWithToken(`js-cache`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'nestedTable': {
      return await postLocalRequestWithToken(`nested-table`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'keywordCloud': {
      return await postLocalRequestWithToken(`keyword-cloud`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'inlineCss': {
      return await postLocalRequestWithToken(`inline-css`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'imageCache': {
      return await postLocalRequestWithToken(`image-cache`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'imageAlt': {
      return await postLocalRequestWithToken(`image-alt`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'https': {
      return await postLocalRequestWithToken(`https-test`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'htmlValidation': {
      return await postLocalRequestWithToken(`html-validation`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'htmlCompression': {
      return await postLocalRequestWithToken(`html-compression`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'headingAnalysis': {
      return await postLocalRequestWithToken(`heading-analysis`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'googleAnalytics': {
      return await postLocalRequestWithToken(`google-analytics`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'siteSpeed': {
      return await postLocalRequestWithToken(`google-speed`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'flashTest': {
      return await postLocalRequestWithToken(`flash-test`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'frameset': {
      return await postLocalRequestWithToken(`frameset`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'cssCache': {
      return await postLocalRequestWithToken(`css-cache`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'cssAnalysis': {
      return await postLocalRequestWithToken(`css-analysis`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'cssMinification': {
      return await postLocalRequestWithToken(`css-minification`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'docType': {
      return await postLocalRequestWithToken(`doc-type`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'canonicalUrl': {
      return await postLocalRequestWithToken(`canonical-url`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'cdnTest': {
      return await postLocalRequestWithToken(`cdn-test`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'competitorsTest': {
      return await postLocalRequestWithToken(`competitor-test`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'emailChecker': {
      return await postLocalRequestWithToken(`email-checker`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'faviconTest': {
      return await postLocalRequestWithToken(`favicon-test`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'jsMinifaction': {
      return await postLocalRequestWithToken(`js-minification`, input)
        .then((res) => res)
        .catch((error) => error)
    }
    case 'directoryBrowsing': {
      return await postLocalRequestWithToken(`directory-browsing`, input)
        .then((res) => res)
        .catch((error) => error)
    }

    default:
      return input
  }
}

function* freeTestData(action) {
  try {
    const data = yield freeTestApi(action.payload)

    if (data) {
      yield put({ type: FREE_TEST_SUCCESS, payload: { ...data, type: action.payload.type } })
    } else {
      yield put({ type: FREE_TEST_FAILURE, e: {} })
    }
  } catch (e) {
    yield put({ type: FREE_TEST_FAILURE, e })
  }
}

function* freeTestDataSaga() {
  yield takeLatest(FREE_TEST, freeTestData)
}

const quickSeoCheckupApi = async (input) => {
  return await postLocalRequestWithTokenSeoCheckup(`quick-seo-checkup`, {
    url: input.url,
    keywords: input.keywords,
  })
    .then((res) => res)
    .catch((error) => error)
}

function* quickSeoCheckupData(action) {
  try {
    const data = yield quickSeoCheckupApi(action.payload)
    yield put({ type: QUICK_SEO_CHECKUP_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: QUICK_SEO_CHECKUP_FAILURE, e })
  }
}

function* quickSeoCheckupDataSaga() {
  yield takeLatest(QUICK_SEO_CHECKUP, quickSeoCheckupData)
}

const getQuickSeoCheckupReportApi = async (input) => {
  return await postLocalRequestWithToken(`get-quick-seo-checkup-report`, { url: input })
    .then((res) => res)
    .catch((error) => error)
}

function* getQuickSeoCheckupReportData(action) {
  try {
    const data = yield getQuickSeoCheckupReportApi(action.payload)
    yield put({ type: GET_QUICK_CHECKUP_REPORT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_QUICK_CHECKUP_REPORT_FAILURE, e })
  }
}

function* getQuickSeoCheckupReportDataSaga() {
  yield takeLatest(GET_QUICK_CHECKUP_REPORT, getQuickSeoCheckupReportData)
}

const getUrlReportApi = async (input) => {
  return await getRequest(`get-url-report?url=${input}`)
    .then((res) => res)
    .catch((error) => error)
}

function* getUrlReportData(action) {
  try {
    const data = yield getUrlReportApi(action.payload)
    if (data?._id) {
      yield put({ type: GET_URL_REPORT_SUCCESS, payload: data })
    } else {
      yield put({ type: GET_URL_REPORT_FAILURE, data })
    }
  } catch (e) {
    yield put({ type: GET_URL_REPORT_FAILURE, e })
  }
}

function* getUrlReportDataSaga() {
  yield takeEvery(GET_URL_REPORT, getUrlReportData)
}

export default function* freeToolsSaga() {
  yield all([
    WhoisDataSaga(),
    domainScoreDataSaga(),
    pageObjectFreeDataSaga(),
    spfRecordFreeDataSaga(),
    noFollowFreeDataSaga(),
    freeTestDataSaga(),
    quickSeoCheckupDataSaga(),
    getQuickSeoCheckupReportDataSaga(),
    getUrlReportDataSaga(),
  ])
}

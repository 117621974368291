import {
  REGISTER_START,
  REGISTER_CLEAR,
  LOGIN_START,
  LOGIN_CLEAR,
  STORE_USER_DATA_START,
  LOGOUT,
  VERIFY_START,
  VERIFY_TOKEN,
  ADD_KEYWORDS_SETTINGS,
  ADD_COMPETITORS_SETTINGS,
  CHANGE_REGION_SETTINGS,
  REMOVE_COMPETITORS_SETTINGS,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_PLAN,
  CHANGE_SOCIAL_SETTINGS,
  VERIFY_PASSWORD,
  SUPPORT_REQUEST,
  GET_STRIPE_SECRET_KEY,
  GET_PAYMENT_METHODS,
} from '../../actionType'

export const registerAction = (data) => ({
  type: REGISTER_START,
  payload: data,
})

export const clearRegisterFlags = () => ({
  type: REGISTER_CLEAR,
})

export const loginAction = (data) => ({
  type: LOGIN_START,
  payload: data,
})

export const clearLoginFlags = () => ({
  type: LOGIN_CLEAR,
})

export const storeUserData = (data) => ({
  type: STORE_USER_DATA_START,
  payload: data,
})

export const logout = () => ({
  type: LOGOUT,
})

export const resetPassword = (data) => ({
  type: RESET_PASSWORD,
  payload: data,
})

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  payload: data,
})

export const verifyToken = (data) => ({
  type: VERIFY_START,
  payload: data,
})

export const verifyTokenStart = () => ({
  type: VERIFY_TOKEN,
})

export const addKeywordSettings = (data) => ({
  type: ADD_KEYWORDS_SETTINGS,
  data,
})

export const addCompetitorsSettings = (data) => ({
  type: ADD_COMPETITORS_SETTINGS,
  data,
})

export const changeSocialSettings = (data) => ({
  type: CHANGE_SOCIAL_SETTINGS,
  data,
})

export const changeRegionSettings = ({ data, loaderType }) => ({
  type: CHANGE_REGION_SETTINGS,
  data,
  loaderType,
})

export const removeCompetitorSettings = (data) => ({
  type: REMOVE_COMPETITORS_SETTINGS,
  data,
})

export const changePlan = (data) => ({
  type: CHANGE_PLAN,
  data,
})

export const verifyPassword = (data) => ({
  type: VERIFY_PASSWORD,
  payload: data
})

export const supportRequest = (data) => ({
  type: SUPPORT_REQUEST,
  payload: data
})

export const getstripeSecretKey = (data) => ({
  type: GET_STRIPE_SECRET_KEY,
  payload: data
})

export const getPaymentMethods = (data) => ({
  type:GET_PAYMENT_METHODS,
  payload: data
})
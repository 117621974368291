import React from 'react'

import { Dropdown, ListGroup, Spinner } from 'react-bootstrap'

const NavbarDropdown = ({ children, count, showBadge, header, footer, icon: Icon, dropDownOnClick, handleFooter, footerLoader, customeClass, isCustomeClass = false }) => (
  <Dropdown className="me-2 nav-item" align="end">
    <Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} onClick={dropDownOnClick} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </Dropdown.Toggle>
    <Dropdown.Menu drop="end" className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup className={isCustomeClass ? customeClass : ''}>{children}</ListGroup>
     { footer && footer?.length ?  <Dropdown.Header className="dropdown-menu-footer cursor-pointer" onClick={() => handleFooter()}>
        <span className="text-muted d-flex flex-row justify-content-center gap-1">
          {footer}
          {
            footerLoader ?
              <Spinner animation='border' size='sm' />
              : null
          }
        </span>
      </Dropdown.Header> : null }
    </Dropdown.Menu>
  </Dropdown>
)

export default NavbarDropdown

import { useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const CSSAnalysis = ({ data, showDetail, testType= "" }) => {

  const dispatch = useDispatch()
  const [cssAnalysisFlag, setCssAnalysisFlag] = useState(false)
  const errorsColumns = [
    {
      id: 'url',
      displayName: 'URL',
      children: [
        {
          id: 'errors',
          displayName: 'Errors',
        },
      ],
    },
  ]
  const warningsColumns = [{
    id: 'url',
    displayName: 'URL',
    children: [
      {
        id: 'warnings',
        displayName: 'Warnings',
      },
    ],
  },]

  let cssAnalysisErrorCsv = data?.cssAnalysis?.data?.errorText?.map(item => {
        return {
          url:item?.errorUrl,        
        }      
  })

  let cssAnalysisWarningCsv = data?.cssAnalysis?.data?.warningText?.map(item => {
    // item?.tempLinks?.map(innerItem => {
      return {
        url:item?.warningUrl,
        // warnings: innerItem?.label
      }
    // })
})

  if(cssAnalysisErrorCsv?.length){
    cssAnalysisErrorCsv = ["ERRORS", ...cssAnalysisErrorCsv]
  }
  if(cssAnalysisWarningCsv?.length){
    cssAnalysisWarningCsv = ["WARNINGS", ...cssAnalysisWarningCsv]
  }

  let dataForDownload = [
    {datas:cssAnalysisErrorCsv},
    {datas:cssAnalysisWarningCsv}
  ]

  let cssAnalysisFinalCsv = []
  dataForDownload.forEach(({datas}) => {
    if(datas?.[0] === "ERRORS"){
      cssAnalysisFinalCsv?.push(["ERRORS"])
      datas?.map(item => {
        const urlErrors = data?.cssAnalysis?.data?.errorText?.find(errorItem => {
          return errorItem.errorUrl === item?.url 
        })

        if(urlErrors){
          cssAnalysisFinalCsv?.push([item?.url])
          cssAnalysisFinalCsv?.push([])
          urlErrors?.tempLinks?.map(error => {
            cssAnalysisFinalCsv?.push([`"${error?.label}"`])
          })

          if(urlErrors?.tempLinks?.length){
            cssAnalysisFinalCsv?.push([])
          }
        }
      })
    }

    if(datas?.[0] === "WARNINGS"){
      cssAnalysisFinalCsv?.push(["WARNINGS"])
      datas?.map(item => {
        const urlWarnings = data?.cssAnalysis?.data?.warningText?.find(warningItem => {
          return warningItem.warningUrl === item?.url 
        })

        if(urlWarnings){
          cssAnalysisFinalCsv?.push([item?.url])
          cssAnalysisFinalCsv?.push([])
          urlWarnings?.tempLinks?.map(warning => {
            cssAnalysisFinalCsv?.push([`"${warning?.label}"`])
          })

          if(urlWarnings?.tempLinks?.length){
            cssAnalysisFinalCsv?.push([])
          }
        }
      })
    }
  })

  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.cssAnalysis?.data?.testResults?.status ? (
            ''
          ) : data?.cssAnalysis?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.cssAnalysis?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data?.cssAnalysis?.data?.testResults?.message}</p>

        {showDetail &&
        data?.cssAnalysis?.data?.testResults?.status !== 1 &&
        data.cssAnalysis?.data?.errors &&
        data.cssAnalysis?.data?.errors?.length ? (
          <>
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                if (testType === "") {
                  setCssAnalysisFlag(!cssAnalysisFlag)
                } else {
                   dispatch(openRegisterModal(true))
                }
              }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={cssAnalysisFlag}
        toggleShow={() => setCssAnalysisFlag(!cssAnalysisFlag)}
        title={'CSS Analysis'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        alternativeDownload = {true}
        fileName='CSS Analysis Test'
        datas={cssAnalysisFinalCsv}
      >
        {data.cssAnalysis?.data?.errorText?.length ? (
          <>
            <h5 className='f-bold'>ERRORS</h5>
            <ul>
              {' '}
              {data.cssAnalysis?.data?.errorText.map((item, index) => {
                return (
                  <li key={`CSSAnalysis-${index + 1}`}>
                    {' '}
                    <b>{item.errorUrl}</b>
                    <ul>
                      {item &&
                        item.tempLinks &&
                        item.tempLinks.length &&
                        item.tempLinks.map((item, index) => {
                          return <li key={`tempLinks-${index + 1}`}> {item.label}</li>
                        })}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </>
        ) : (
          <></>
        )}
        {data.cssAnalysis?.data?.warningText?.length ? (
          <>
            <h5 className='f-bold'>WARNING</h5>
            <ul>
              {' '}
              {data.cssAnalysis?.data?.warningText.map((item, index) => {
                return (
                  <li key={`warningText-${index + 1}`}>
                    {' '}
                    <b>{item.warningUrl}</b>
                    <ul>
                      {item &&
                        item.tempLinks &&
                        item.tempLinks.length &&
                        item.tempLinks.map((item, index) => {
                          return <li key={`warningTextTempLinks-${index + 1}`}> {item.label}</li>
                        })}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </>
        ) : (
          <></>
        )}
      </ModalHOC>
    </>
  )
}

export default CSSAnalysis

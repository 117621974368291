import { Link, useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import pmsLogo from '../assets/img/pms/PMS-SEO-Site-Signals-350-150-Dark.png'

const TrialFlowHeader = ({email, planName, className}) => {
    const navigate = useNavigate()
    return (
        <Row style={{ marginBottom: 10, marginTop: 10 }} className={`login-logo-header ${className}`}>
            <Col style={{ position: 'relative' }} className='pms-logo'>
                <img
                    src={pmsLogo}
                    alt="positionmysite"
                    width={150}
                    height={64}
                    className="cursor-pointer"
                    onClick={() => navigate('/')}
                />
                <span style={{ fontSize: 12, position: 'absolute', bottom: 14 }}>
                    SEO Site Signals
                </span>
            </Col>
             <Col className="pyament-card-logout">
                {planName ? `${planName} | `: null}{email || ''} | <Link to="/"> &nbsp; Logout</Link>
            </Col> 
          </Row>
    )
}

export default TrialFlowHeader

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap"

import { getUserData, personalInfoUpdate } from "redux/actions/dashboard"

import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import { object, string } from 'yup'

import ToastMessage from "components/uiCore/ToastMessage"
import { cancelSubsciption, discardTrialPeriod, getBillingInformation } from "redux/actions/freetrial/startFreeTrial"
import { message } from "antd"
import { CLEAR_CANCEL_SUBSCRIPTION, CLEAR_DISCARD_TRIAL_PERIOD, PERSONAL_INFO_UPDATE_CLEAR } from "redux/actionType"
import { Loader } from "components/uiCore"
import paths from "constants/paths"
import { PLAN } from "constants/enum"


const BillingInformation = () => {

    const { persnoalInfoLoading, toastMessage, toastError, userData } = useSelector(({ dashboard }) => dashboard)
    const { billingInformation,
        bllingInformationfailure,
        cancelSubscriptionLoader,
        cancelSubscriptionError,
        cancelSubscriptionMessage,
        endTrialPeriodLoader,
        endTrialPeriodMessage,
        endTrialPeriodError } = useSelector(({ startFreeTrial }) => startFreeTrial)

    const { registerData } = useSelector(({ register }) => register)

    const [showToast, setShowToast] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [isEndTrialPeriod, setIsEndTrialPeriod] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { dueDateMessage, invoices, planMessage, isCancel } = billingInformation
    const userDataLocalStorage = localStorage.getItem('userData')
    const [userName, setUserName] = useState({
        firstName: registerData?.firstName ? registerData?.firstName : localStorage.getItem('firstName'),
        lastName: registerData?.lastName ? registerData?.lastName : localStorage.getItem('lastName'),
        vatNumber: registerData?.vatNumber ? registerData?.vatNumber : userData?.vatNumber,
        companyName: registerData?.companyName ? registerData?.companyName : userData?.companyName,
    })

    useEffect(() => {
        async function updatedData() {
            const data = await localStorage.getItem('userData')
            if (!persnoalInfoLoading && (toastError || toastMessage)) {
                setShowToast(true)
                dispatch(getUserData())
                setTimeout(() => {
                    dispatch({type: PERSONAL_INFO_UPDATE_CLEAR})
                }, 1000)
            }
        }
        updatedData()
    }, [persnoalInfoLoading])

    useEffect(() => {
        if (!cancelSubscriptionLoader && cancelSubscriptionMessage) {
            const data = localStorage.getItem('userData')
            message.success(cancelSubscriptionMessage)
            dispatch({ type: CLEAR_CANCEL_SUBSCRIPTION })
            dispatch(getBillingInformation())
            setTimeout(() => {
                dispatch(getUserData())
            }, 1500)
            setIsConfirm(false)
        } else {
            const data = localStorage.getItem('userData')
            if (!cancelSubscriptionLoader && cancelSubscriptionError) {
                message.error(cancelSubscriptionError)
                dispatch({ type: CLEAR_CANCEL_SUBSCRIPTION })
                dispatch(getBillingInformation())
                setTimeout(() => {
                    dispatch(getUserData())
                }, 1500)
                setIsConfirm(false)
            }
        }
    }, [cancelSubscriptionLoader, cancelSubscriptionError, cancelSubscriptionMessage])

    useEffect(() => {
        async function handleTrialPeriod() {
            const data = await localStorage.getItem('userData')
            if (!endTrialPeriodLoader && endTrialPeriodMessage) {
                message.success(endTrialPeriodMessage)
                dispatch({ type: CLEAR_DISCARD_TRIAL_PERIOD })
                setIsEndTrialPeriod(false)
                dispatch(getUserData())
                dispatch(getBillingInformation())
            } else {
                if (!endTrialPeriodLoader && endTrialPeriodError) {
                    message.error(endTrialPeriodError?.message)
                    dispatch({ type: CLEAR_DISCARD_TRIAL_PERIOD })
                    setIsEndTrialPeriod(false)
                    dispatch(getUserData())
                    dispatch(getBillingInformation())
                }
            }
        }
        handleTrialPeriod()
    }, [endTrialPeriodLoader, endTrialPeriodError, endTrialPeriodMessage])

    const initialValues = {
        firstName: userName?.firstName,
        lastName: userName?.lastName,
        companyName: userName?.companyName,
        vatNumber: userName?.vatNumber,
    };

    const billingInformationSchema = object().shape({
        firstName: string()
            .required("Please Enter First Name."),
        lastName: string()
            .required("Please Enter Last Name."),
        companyName: string()
            .required("Please Enter Company Name"),
        vatNumber: string()
            .required("Please Enter VAT Number"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: billingInformationSchema,
        onSubmit: (values) => {
            const { firstName, lastName, companyName, vatNumber } = values
            const payload = { userData: { firstName, lastName, companyName, vatNumber } }
            dispatch(personalInfoUpdate(payload))
            setUserName({ firstName: values.firstName, lastName: values.lastName, vatNumber: values.vatNumber, companyName: values.companyName })
        },
    })

    useEffect(() => {
        if (!persnoalInfoLoading && toastMessage) {
            const payload = {
                ...JSON.parse(userDataLocalStorage),
                firstName: userName.firstName,
                lastName: userName.lastName,
                vatNumber: userName?.vatNumber,
                companyName: userName?.companyName,
            }
            localStorage.removeItem('firstName')
            localStorage.removeItem('lastName')
            localStorage.setItem('firstName', userName.firstName)
            localStorage.setItem('lastName', userName.lastName)
            dispatch({ type: 'CHANGE_PLAN_SUCCESS', payload })
        }
    }, [toastMessage, persnoalInfoLoading])

    const UpgradePlan = () => {
      if (userData?.plan?.name === PLAN.PRO_PLAN.name || userData?.plan?.name === PLAN.BASIC_PLAN.name) {
        return (
          <Button
            variant="outline-primary"
            className="mt-1"
            onClick={() => {
                navigate(paths.upgradePlan)
            }}
          >
            Upgrade plan
          </Button>
        )
      }
    }

    const { values, handleSubmit, handleChange, handleBlur } = formik;
    return (
        <>
            {(toastMessage || toastError) ? <ToastMessage show={showToast} onClose={() => { setShowToast(false) }} variant={toastMessage ? "success" : toastError ? "danger" : "warning"} toastMessage={toastMessage ? toastMessage : toastError ? toastError : "Something went wrong!"} /> : null}

            <Modal
                show={isEndTrialPeriod}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setIsEndTrialPeriod(false)}
                style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
                className="br-15"
            >
                <Modal.Header className="bb-none">
                    <h3 className='mb-0'>End Trial Period</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-0'>Are you sure that you want to end trial period?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="primary"
                        className="w-20 btn-primary d-flex flex-row justify-content-center align-items-center"
                        variant="primary"
                        onClick={() => {
                            dispatch(discardTrialPeriod())
                        }}
                    >
                        {endTrialPeriodLoader ? (
                            <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                            <></>
                        )}
                        Yes
                    </Button>
                    <Button
                        color="primary"
                        className="w-20 btn-primary"
                        variant="danger"
                        onClick={() => {
                            setIsEndTrialPeriod(false)
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card className="mb-0">

                <Card.Body>

                    <Row>
                        <Col md="6" xs="12 mb-3" sm="12 mb-3">
                            <Alert
                                variant="warning"
                                className="alert-outline-coloured"
                                key="meta-title"
                            >
                                <div className="alert-icon" />
                                <div className="alert-message">
                                    <span>
                                        Changes to your billing information will take effect starting with next scheduled payment and will be reflected on your next invoice
                                    </span>
                                </div>
                            </Alert>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="inputFirstName">First Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputFirstName"
                                        name="firstName"
                                        value={values?.firstName}
                                        placeholder="First name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <span className="fs-6" style={{ color: 'red' }}>{formik.errors.firstName}</span>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="inputLastName">Last Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputLastName"
                                        name="lastName"
                                        value={values?.lastName}
                                        placeholder="Last name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <span className="fs-6" style={{ color: 'red' }}>{formik.errors.lastName}</span>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="inputCompanyName">Company Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputCompanyName"
                                        name="companyName"
                                        value={values?.companyName}
                                        placeholder="Company name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {formik.touched.companyName && formik.errors.companyName ? (
                                        <span className="fs-6" style={{ color: 'red' }}>{formik.errors.companyName}</span>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="inputVATNumber">VAT Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputVATNumber"
                                        name="vatNumber"
                                        value={values?.vatNumber}
                                        placeholder="VAT Number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {formik.touched.vatNumber && formik.errors.vatNumber ? (
                                        <span className="fs-6" style={{ color: 'red' }}>{formik.errors.vatNumber}</span>
                                    ) : null}
                                </Form.Group>
                                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                            </Form>
                        </Col>

                        {Object.keys(billingInformation)?.length ? <Col md="6">
                            <Card className="url-details-inner">
                                <Card.Body className="p-2">
                                    <p style={{ fontWeight: '500', paddingLeft: '1px' }} className='mb-0 fs-4'>
                                        {planMessage}
                                    </p>
                                    <p className='mb-0 fs-5' style={{ paddingLeft: '2px' }}>{dueDateMessage}</p>
                                    {!isCancel ?
                                        <Link to={''} onClick={() => setIsConfirm(true)}>
                                            <Button variant="outline-primary" className="mt-1" >
                                                Cancel subscription
                                            </Button> </Link> : null}

                                    {userData?.isTrialInUse && !userData?.subscriptions?.[0]?.isCancel ? <Button variant="outline-primary" className="mt-1" style={{marginRight:'3px'}} onClick={() => setIsEndTrialPeriod(true)}>
                                        End trial period
                                    </Button> : null}
                                    {UpgradePlan()}            
                                </Card.Body>
                            </Card>
                            <Card className="url-details-inner mt-3">
                                <Card.Body className="p-0">
                                    <Table className="mb-0">
                                        <thead>
                                            <tr style={{ borderBottom: "2px solid #6C757D" }}>
                                                <h3 className='mb-0'>Invoice history</h3>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {invoices?.length ? invoices?.map((invoice, index) => {
                                                return (
                                                    <tr key={`invoice-${index+1}`} className="border-single-invoice">
                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                            <div>
                                                                <div className='d-flex flex-row gap-1 align-items-center fs-4 fw-bold'>
                                                                    <span>{invoice?.invoiceDate}</span>
                                                                    <span className='border rounded px-1 fs-5 invoice-status'>{invoice?.paid ? "PAID" : "PENDING"}</span>
                                                                </div>
                                                                <span className="fs-4">${invoice?.amountPaid}</span>
                                                            </div>
                                                            <a href={invoice?.downloadUrl} className="invoice-download-button" download="invoice.pdf">
                                                                <FontAwesomeIcon className='fs-3 cursor-pointer' icon={faDownload} />
                                                            </a>
                                                        </div>
                                                    </tr>
                                                )
                                            }) : null}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col> : bllingInformationfailure && Object.keys(bllingInformationfailure)?.length ?
                            <Col md="6">
                                <Alert
                                    variant="danger"
                                    className="alert-outline-coloured"
                                    key="meta-title"
                                >
                                    <div className="alert-icon" />
                                    <div className="alert-message">
                                        <span className="alert-for-mobile">
                                            Subscription details are temporarily not available, you might need to subscribe one of our plans for at least monthly or annually
                                        </span>
                                    </div>
                                </Alert>
                                <Card className="url-details-inner mt-3">
                                    <Card.Body className="p-0">
                                        <Table className="mb-0">
                                            <thead>
                                                <tr>
                                                    <h3 className='mb-0 fw-bolder'>Invoice history</h3>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr className="border-single-invoice">
                                                    <h4>
                                                        Invoice history is not available
                                                    </h4>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            : <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 380,
                                }}
                            >
                                <div className="text-center">
                                    <Loader />
                                </div>
                            </div>}
                        {isConfirm ? <Modal
                            show={isConfirm}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={() => { }}
                            style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
                            className="br-15"
                        >
                            <Modal.Header className="bb-none">
                                <h3 className='mb-0'>Cancel Subscription</h3>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='mb-0'>Are you sure that you want to cancel subscription?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    color="primary"
                                    className="w-20 btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        dispatch(cancelSubsciption())
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    color="primary"
                                    className="w-20 btn-primary"
                                    variant="danger"
                                    onClick={() => {
                                        setIsConfirm(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal> : null}
                    </Row>

                </Card.Body>
            </Card>
        </>
    )
}
export default BillingInformation
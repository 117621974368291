import React from 'react'
import { Card, Row, Col, ProgressBar, Accordion, Container } from 'react-bootstrap'

const PieChart = (props) => {
  let totalTest = props?.positiveCounter + props?.warningCounter + props?.negativeCounter
  let totalPercent = (totalTest / 72) * 100
  return (
    <Card>
      <Card.Body>
        {props?.isQuickCheck && totalPercent < 100 ? (
          <>
            <p> Signal Scan Loading</p>
            <ProgressBar
              striped
              style={{ borderRadius: 0 }}
              animated={totalPercent < 100 ? true : false}
              variant="primary"
              now={totalPercent > 100 ? 100 : totalPercent}
              label={`${totalPercent > 100 ? 100 : totalPercent.toFixed(2)}%`}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Row>
          {/* <Col>
            <div className="chart">
              <Chart options={options} series={data} type="donut" width="200" />
            </div>
          </Col> */}
          <Col>
            <Row>
              <Col sm="12" md="4">
                <p>
                  {' '}
                  {props?.positiveCounter || 0} Passed / {((props?.positiveCounter * 100) / 74).toFixed(1)}%
                </p>
                <ProgressBar className="mb-3" variant="success" now={props?.positiveCounter || 0} label="" />
              </Col>

              <Col sm="12" md="4">
                <p>
                  {props?.warningCounter || 0} Warning / {((props?.warningCounter * 100) / 74).toFixed(1)}%
                </p>
                <ProgressBar className="mb-3" variant="warning" now={props?.warningCounter || 0} label="" />
              </Col>

              <Col sm="12" md="4">
                <p>
                  {props?.negativeCounter || 0} Failed / {((props?.negativeCounter * 100) / 74).toFixed(1)}%
                </p>
                <ProgressBar className="mb-3" variant="danger" now={props?.negativeCounter || 0} label="" />
              </Col>
            </Row>
          </Col>
        </Row>
        {props?.showDetails &&
        (props?.competitor1?.length ||
          props?.competitor2?.length ||
          props?.competitor3?.length ||
          props?.competitor4?.length ||
          props?.competitor5?.length) ? (
          <>
            <hr />
            <h4 className='f-bold'>Competitors</h4>
            <Row>
              <Accordion defaultActiveKey="6">
                {props?.competitor1?.length ? (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col>{props?.competitor1}</Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white">
                      <Col>
                        <Row>
                          <Col>
                            <p>
                              {' '}
                              {props?.positiveCounter1 || 0} Passed /{' '}
                              {((props?.positiveCounter1 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="success"
                              now={props?.positiveCounter1 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.warningCounter1 || 0} Warning /{' '}
                              {((props?.warningCounter1 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="warning"
                              now={props?.warningCounter1 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.negativeCounter1 || 0} Failed /{' '}
                              {((props?.negativeCounter1 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="danger"
                              now={props?.negativeCounter1 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}
                {props?.competitor2?.length ? (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col>{props?.competitor2}</Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white">
                      <Col>
                        <Row>
                          <Col>
                            <p>
                              {' '}
                              {props?.positiveCounter2 || 0} Passed /{' '}
                              {((props?.positiveCounter2 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="success"
                              now={props?.positiveCounter2 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.warningCounter2 || 0} Warning /{' '}
                              {((props?.warningCounter2 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="warning"
                              now={props?.warningCounter2 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.negativeCounter2 || 0} Failed /{' '}
                              {((props?.negativeCounter2 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="danger"
                              now={props?.negativeCounter2 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}
                {props?.competitor3?.length ? (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col>{props?.competitor3}</Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white">
                      <Col>
                        <Row>
                          <Col>
                            <p>
                              {' '}
                              {props?.positiveCounter3 || 0} Passed /{' '}
                              {((props?.positiveCounter3 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="success"
                              now={props?.positiveCounter3 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.warningCounter3 || 0} Warning /{' '}
                              {((props?.warningCounter3 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="warning"
                              now={props?.warningCounter3 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.negativeCounter3 || 0} Failed /{' '}
                              {((props?.negativeCounter3 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="danger"
                              now={props?.negativeCounter3 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}
                {props?.competitor4?.length ? (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col>{props?.competitor4}</Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white">
                      <Col>
                        <Row>
                          <Col>
                            <p>
                              {' '}
                              {props?.positiveCounter4 || 0} Passed /{' '}
                              {((props?.positiveCounter4 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="success"
                              now={props?.positiveCounter4 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.warningCounter4 || 0} Warning /{' '}
                              {((props?.warningCounter4 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="warning"
                              now={props?.warningCounter4 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.negativeCounter4 || 0} Failed /{' '}
                              {((props?.negativeCounter4 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="danger"
                              now={props?.negativeCounter4 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}
                {props?.competitor5?.length ? (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col>{props?.competitor5}</Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white">
                      <Col>
                        <Row>
                          <Col>
                            <p>
                              {' '}
                              {props?.positiveCounter5 || 0} Passed /{' '}
                              {((props?.positiveCounter5 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="success"
                              now={props?.positiveCounter5 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.warningCounter5 || 0} Warning /{' '}
                              {((props?.warningCounter5 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="warning"
                              now={props?.warningCounter5 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {props?.negativeCounter5 || 0} Failed /{' '}
                              {((props?.negativeCounter5 * 100) / 74).toFixed(1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="danger"
                              now={props?.negativeCounter5 || 0}
                              label=""
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}
              </Accordion>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Card.Body>
    </Card>
  )
}

export default PieChart
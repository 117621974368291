import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const ViewportTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.viewportTest.heading}
      whatIsIT={freeTools.viewportTest.what}
      howToFixBeforeCode={freeTools.viewportTest.howSectionBeforeCode}
      codeSection={freeTools.viewportTest.codeSection}
      howToFixAfterCode={freeTools.viewportTest.howSectionAfterCode}
    />
  )
}

export default ViewportTest

import * as Yup from 'yup'
import { useFormik } from 'formik'

import 'modules/Free tools/components/CommonFreeTool.scss'
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trialSeoCheckup } from 'redux/actions/freetrial/startFreeTrial'
import { useNavigate, useParams } from 'react-router-dom'
import { TEST_TYPES } from 'constants/enum'
import { message } from 'antd'
import { CLEAR_TRIAL_SEO_CHECKUP } from 'redux/actionType'
import { encryptData } from 'constants/Utils'

const CharsetDeclarationTest = () => {
  const { 
    trialSeoCheckupLoader, 
    trialSeoCheckupSuccess,
    trialFailureMessage 
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { 'test-type': type } = useParams()


  const URL_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)*$/
  const testType = TEST_TYPES?.[type] || 'ALL'

  const [isAddCompetitor, setIsAddCompetitor] = useState(false)
  const [checkupUrl, setCheckupUrl] = useState({})

  const initialValues = {
    url: '',
    competitorUrl: '',
  }

  const handleFreeToolSubmit = (values) => {
    const encryptMainUrl = encryptData(values.url)
    if (values?.competitorUrl) {
      const encryptCompetitorUrl = encryptData(values.competitorUrl)
      setCheckupUrl({mainUrl: encryptMainUrl, competitorUrl: encryptCompetitorUrl})
    } else {
      setCheckupUrl({ mainUrl: encryptMainUrl })
    }
  }

  useEffect(() => {
    if (!trialSeoCheckupLoader && trialSeoCheckupSuccess) {
      if (checkupUrl?.competitorUrl) {
        const path = `/seo-report/${type}/${checkupUrl?.mainUrl}/${checkupUrl?.competitorUrl}`
        navigate(path, { state: { mainUrl: toolsFormik.values.url } })
      } else {
        const path = `/seo-report/${type}/${checkupUrl?.mainUrl}`
        navigate(path, { state: { mainUrl: toolsFormik.values.url } })
      }
    } else if (trialFailureMessage) {
      if (trialFailureMessage?.message !== 'You have exceded your weekly limit') {
        message.error(trialFailureMessage?.message)
      }
      dispatch({ type: CLEAR_TRIAL_SEO_CHECKUP })
    }
  }, [trialSeoCheckupSuccess, trialSeoCheckupLoader, trialFailureMessage])

  useEffect(() => {
    if (checkupUrl?.mainUrl) {
      if (checkupUrl?.competitorUrl) {
        const payload = { type: testType, url: checkupUrl?.mainUrl, competitor: checkupUrl?.competitorUrl }
        dispatch(trialSeoCheckup(payload))
      } else {
        const payload = { type: testType, url: checkupUrl?.mainUrl }
        dispatch(trialSeoCheckup(payload))
      }
    }
  }, [checkupUrl])

  const urlValidationSchema = Yup.object().shape({
    url: Yup.string().trim().required('Please provide url').matches(URL_REGEX, 'Invalid format'),
    competitorUrl: isAddCompetitor
      ? Yup.string().trim().required('Please provide url').matches(URL_REGEX, 'Invalid format')
      : null,
  })

  const toolsFormik = useFormik({
    initialValues,
    validationSchema: urlValidationSchema,
    onSubmit: handleFreeToolSubmit,
  })

  const { values, handleChange, handleSubmit, errors, touched } = toolsFormik
  return (
    <div className="tools-wrapper">
      <h1 className="tools-heading">Charset Declaration Test</h1>
      <Form className="tools-form" onSubmit={handleSubmit}>
        <Form.Group className="tools-form-group">
          <div className="d-flex align-items-center gap-1">
            <Form.Control
              type="text"
              name="url"
              value={values.url}
              onChange={handleChange}
              isInvalid={!!errors.url}
              placeholder={'Website URL'}
              className="tools-form-input tools-form-url"
              disabled={trialSeoCheckupLoader}
            />
            {!isAddCompetitor ? (
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Add competitor url</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: '#3F80EA', fontSize: '24px', cursor: 'pointer' }}
                  onClick={() => setIsAddCompetitor(true)}
                />
              </OverlayTrigger>
            ) : null}
          </div>

          {!!touched.url && (
            <span className="fs-6" style={{ color: 'red' }}>
              {errors.url}
            </span>
          )}
        </Form.Group>
        {isAddCompetitor ? (
          <Form.Group>
            <Form.Control
              type="text"
              name="competitorUrl"
              value={values.competitorUrl}
              onChange={handleChange}
              isInvalid={!!errors.competitorUrl}
              placeholder={'Competitor URL'}
              className="tools-form-input tools-form-competitor"
              disabled={trialSeoCheckupLoader}
            />
            {!!touched.competitorUrl && (
              <span className="fs-6" style={{ color: 'red' }}>
                {errors.competitorUrl}
              </span>
            )}
          </Form.Group>
        ) : null}
        <Button
          disabled={trialSeoCheckupLoader}
          type="submit"
          variant="primary"
          className={`d-flex align-items-center gap-1 ${isAddCompetitor ? 'tools-submit-btn' : ''}`}
        >
          Checkup
          {trialSeoCheckupLoader ? <Spinner animation="border" key={1} size="sm" /> : null}
        </Button>
      </Form>
      <div className="tools-info">
        <h2 className="tools-info-heading">What is it?</h2>
        <p className="tools-info-description">
          This test will check if the webpage has a character encoding declaration ("charset"). If the server
          doesn't specify a character encoding format when it sends an HTML file, the browser may display some
          characters incorrectly to users. Google recommends using the Unicode/UTF-8 character set when
          possible.
        </p>
      </div>
      <div className="tools-solution">
        <h2 className="tools-solution-heading">How do I fix it ?</h2>
        <p className="tools-solution-description">
          In order to pass this test you have to add a proper character encoding declaration to your webpage.
          Google recommends <br />
          using the Unicode/UTF-8 character set when possible. You can do it by using one of the following
          methods: <br /> 1.Add a meta charset element in your webpage. The element must be fully contained
          within the first 1024 bytes. The best practice is to add this tag as the first element in the head
          section of your document.
          <div className="tools-code">
            {`<!DOCTYPE html>`}
            <br />
            {`<html>`}
            <br />
            &nbsp;&nbsp;{`<head>`}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{`<meta charset="utf-8" />`}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{`…`}
          </div>
          2.Add a meta element with an http-equiv attribute:
          <div className="tools-code">
            {`<!DOCTYPE html>`}
            <br />
            {`<html>`}
            <br />
            &nbsp;&nbsp;{`<head>`}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{`<meta http-equiv="Content-Type" content="text/html; charset=utf-8">`}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;{`…`}
          </div>
          3.Configure your server to add a Content-Type header that includes a charset directive.
          <div className="tools-code">{`Content-Type: text/html; charset=utf-8`}</div>
        </p>
      </div>
    </div>
  )
}

export default CharsetDeclarationTest

import {
  GET_NEW_TOP_KEYWORDS,
  GET_TOP_FIVE_KEYWORD,
  GET_TOP_KEYWORDS,
  GET_TOP_KEYWORDS_LOCATION,
  STORE_NEW_TOP_KEYWORDS,
  GET_NUMBER_OF_RESULT,
  GET_UPDATED_TOP_KEYWORD,
  GET_AVG_POSITION_KEYWORD,
} from '../../actionType'

export const getTopKeywords = (data) => ({
  type: GET_TOP_KEYWORDS,
  payload: data,
})

export const getNewTopKeywords = (data) => ({
  type: GET_NEW_TOP_KEYWORDS,
  payload: data,
})

export const getTopKeywordsLocation = () => ({
  type: GET_TOP_KEYWORDS_LOCATION,
})

export const storeNewTopKeywords = (data) => ({
  type: STORE_NEW_TOP_KEYWORDS,
  payload: data,
})

export const getTopFiveKeywordApi = () => ({
  type: GET_TOP_FIVE_KEYWORD,
})

export const getAvgPositionKeywordApi = () => ({
  type: GET_AVG_POSITION_KEYWORD,
})

export const getNumberOfResultApi = (data) => ({
  type: GET_NUMBER_OF_RESULT,
  payload: data,
})

export const getUpdatedKeywords = (data) => ({
  type: GET_UPDATED_TOP_KEYWORD,
  payload: data,
})

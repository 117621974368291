import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Button, FormControl, InputGroup } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import TwitterLogin from 'react-twitter-login'
import { Link } from 'react-router-dom'
import 'react-credit-cards/es/styles-compiled.css'
import { CardBody, CardHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import * as axios from 'axios'

import pmsLogo from '../../../assets/img/pms/unnamed.gif'
import PricingPlan from '../../auth/PricingPlan'
import ColoredModal from '../../modals/ColorModal'
import { changePlan } from '../../../redux/actions/register/index'
import { addWebsite } from '../../../redux/actions/freetrial/startFreeTrial'

import FBSocialButton from '../../../components/fbButton'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { InstagramLogin } from '@amraneze/react-instagram-login'
import { appendInFormData } from '../../../constants/Utils'
import paths from 'constants/paths'

const twitterAPIKey = 'hhSgwFRWhuj5XwHoe4X2XF84r'
const twitterAPIKeySecret = 'aLkUfhzA1UmJOYJegBHwTH0KbqwbsE6eMa7eZD568rvNuKVYKp'

const fbdemo1id = '608249477436898'

const AddSocialHandle = () => {
  const [competitor1, setCompetitor1] = useState('')
  const [competitor2, setCompetitor2] = useState('')
  const [competitor3, setCompetitor3] = useState('')
  const [pageList, setPageList] = useState([])

  const [planName, setPlanName] = useState('Business Plus')
  // const [planPrice, setPlanPrice] = useState('$49.95');
  // const [planDuration, setPlanDuration] = useState('Monthly');

  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const registerData = useSelector(({ register }) => register.registerData)
  const randomNumber = useSelector(({ startFreeTrial }) => startFreeTrial.randomNumber)
  const userId = useSelector(({ register }) => register?.registerData?._id)
  const prevPageData = useSelector(({ startFreeTrial }) => startFreeTrial?.prevPageData)
  const competitorListArray = useSelector(({ startFreeTrial }) => startFreeTrial?.competitorListArray)
  const keywordsListArray = useSelector(({ startFreeTrial }) => startFreeTrial?.keywordsListArray)

  const dispatch = useDispatch()
  const history = useNavigate()

  useEffect(() => {
    setPlanName(registerData?.plan?.name || 'Business Plus')
    // setPlanDuration(registerData?.plan?.type || "Monthly");
  }, [])

  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
    } else {
      history(paths.addNewWebsite)
    }
  }, [randomNumber])

  const checkAddCompetitors = () => {
    if (prevPageData && prevPageData.url && prevPageData.url.length > 3) {
      const input = {
        sessionId: randomNumber,
        userId: userId,
        twitterDetail: competitor1 || '',
        facebookDetail: competitor2 || '',
        instagramDetail: competitor3 || '',
        domain: prevPageData && prevPageData.url,
        action: 'getSocial',
      }

      dispatch({ type: 'GET_URL_DETAILS', payload: input })
      // history('/trial/add-keywords')

      setTimeout(() => {
        // const { prevPageData, email, addWebsite } = this.props;
        // const splitedValue = prevPageData && prevPageData.url.split("/").slice(0, 3);
        // let hostName = splitedValue[splitedValue.length - 1];
        // if (hostName.includes("www.")) {
        //     hostName = hostName.replace("www.", "");
        // }
        let reqData = {
          url: prevPageData.url,
          randomNumber: randomNumber,
          email: registerData.email,
          verified: false,
        }

        let finalData = {
          competitors: competitorListArray,
          keywords: keywordsListArray,
        }

        dispatch({
          type: 'ADD_WEBSITE_FROM_TRIAL',
          payload: {
            hostName: prevPageData.url,
            userId,
            email: registerData.email,
            urlInfo: JSON.stringify(finalData),
          },
        })
        dispatch(addWebsite(reqData))
        dispatch({ type: 'CLEAR_URL_DETAILS' })
        history(paths.mainDashboard)
      }, 2000)
    } else {
      alert('Please enter website properly')
    }
  }

  const changeExisitingPlan = (data) => {
    //setPlanName={setPlanName} setPlanPrice={setPlanPrice} setPlanDuration={setPlanDuration}
    setIsOpen(!isOpen)
    setPlanName(data.planName)
    // setPlanPrice(data.planPrice)
    // setPlanDuration(data.planType)
    dispatch(
      changePlan({
        email: registerData.email,
        planName: data.planName,
        planType: 'Stripe',
        planDuration: data.planType,
      }),
    )
  }

  const authHandler = (err, data) => {
    if (data && data.screen_name) {
      setCompetitor1(data.screen_name)
    }
  }

  const responseInstagram = (response) => {
    let data = {
      client_id: 477695527609399,
      client_secret: '07c8259b548b2b18ee0cd99c7b73bad0',
      grant_type: 'authorization_code',
      redirect_uri: 'https://tools1.dev-positionmysite.com/auth/redirecting',
      code: response,
    }
    let option = {
      headers: {
        origin: 'example.com',
        'x-requested-with': 'example.com',
        'X-RapidAPI-Key': '5e08c4ffc1msh094e1ac06718e0bp1670e5jsn646a2d197bfc',
        'X-RapidAPI-Host': 'http-cors-proxy.p.rapidapi.com',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }

    axios
      .post(
        'https://http-cors-proxy.p.rapidapi.com/https://api.instagram.com/oauth/access_token',
        appendInFormData(data),
        option,
      )
      .then((data1) => {
        // axios.request(options).then((data1) => {
        axios
          .get(`https://graph.instagram.com/me?fields=id,username&access_token=${data1?.data?.access_token}`)
          .then((data2) => {
            // this.setState({instagramHandle: data2?.data?.username })
            setCompetitor3(data2?.data?.username)
          })
          .catch((e1) => {
            console.log(e1, 'e1')
          })
      })
      .catch((err) => console.log(err))
  }

  return (
    <React.Fragment>
      <Helmet title="Start trail" />
      {error ? (
        <ColoredModal
          isOpen={error}
          message={errorMessage}
          onClose={() => {
            setError(false)
            setErrorMessage(false)
          }}
        />
      ) : (
        <></>
      )}
      <PricingPlan
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        changeExisitingPlan={(data) => changeExisitingPlan(data)}
      />
      <Row style={{ marginBottom: 10, marginTop: 10 }}>
        <Col>
          <img
            src={pmsLogo}
            alt="positionmysite"
            width={150}
            className="cursor-pointer"
            onClick={() => history('/')}
          />
        </Col>
        <Col className="pyament-card-logout">
          {planName} | {registerData.email || ''} | <Link to="/"> &nbsp;Logout</Link>
        </Col>
      </Row>

      <Card className="trail-flow" style={{ padding: 15 }}>
        <CardBody>
          <Card>
            <CardHeader style={{ backgroundColor: '#fbfbfb' }}>
              <Row>
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>{' '}
                    Start
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>{' '}
                    Website
                  </div>
                </Col>
                {/* <Col>
                                    <div className={'text-center'}>
                                        <span className={'done-step'}><FontAwesomeIcon
                                            className="align-middle me-2"
                                            icon={faCheck}
                                        /></span> Connect
                                    </div>
                                </Col> */}
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>{' '}
                    Location
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>{' '}
                    Keywords
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>
                    Competitors
                  </div>
                </Col>
                {/* <Col>
                                    <div className={'text-center'}>
                                    <span className={'active-step'}>7</span> 
                                        Social Info
                                    </div>
                                </Col> */}
              </Row>
            </CardHeader>
            <CardBody className="bg-white">
              <Row>
                <Col>
                  <h3> Add Social Media Information </h3>
                  <p>Add Handle for Twitter</p>
                  <TwitterLogin
                    authCallback={authHandler}
                    consumerKey={twitterAPIKey}
                    consumerSecret={twitterAPIKeySecret}
                    children={
                      <Button variant="twitter" className="me-1 mb-1">
                        <FontAwesomeIcon icon={faTwitter} className="align-middle" /> Twitter
                      </Button>
                    }
                  />
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder={'Twitter Handle'}
                      value={competitor1}
                      onChange={(e) => {
                        setCompetitor1(e.target.value)
                      }}
                    />
                  </InputGroup>

                  <p>Add page name of Facebook</p>

                  <FBSocialButton
                    containerStyle={isMobile ? { width: '100%' } : {}}
                    provider="facebook"
                    // appId="378272869423880"
                    scope={
                      'user_birthday, manage_pages, pages_show_list, user_birthday, user_hometown, user_location, user_likes, user_events, user_photos, user_videos, user_friends, user_posts, user_gender, user_link, user_age_range, email, manage_fundraisers, user_managed_groups, public_profile'
                    }
                    appId={fbdemo1id}
                    onLoginSuccess={(data) => {

                      axios
                        .get(
                          `https://graph.facebook.com/me/accounts?access_token=${data?._token?.accessToken}`,
                        )
                        .then((res) => {
                          if (res && res.data && res.data.data && res.data.data.length) {
                            // this.setState({ pageList: res.data.data })
                            setPageList(res?.data?.data || [])
                          } else {
                            alert('no Page found')
                          }
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                    }}
                    onLoginFailure={(data) => {
                      alert(data)
                    }}
                  >
                    <Button variant="facebook" className="me-1 mb-1">
                      <FontAwesomeIcon icon={faFacebook} className="align-middle" /> Facebook
                    </Button>
                  </FBSocialButton>
                  {/* <FacebookLogin
                                            appId="378272869423880"
                                            autoLoad={true}
                                            fields="name,email,picture"
                                            scope="user_birthday, manage_pages, pages_show_list, user_birthday, user_hometown, user_location, user_likes, user_events, user_photos, user_videos, user_friends, user_posts, user_gender, user_link, user_age_range, email, manage_fundraisers, user_managed_groups, public_profile"
                                            callback={responseFacebook}
                                        /> */}
                  {pageList && pageList.length ? (
                    <>
                      <div className="mb-3 mt-3">
                        <p>Click on one page from below options</p>
                        {pageList &&
                          pageList.length &&
                          pageList.map((data) => {
                            return (
                              <div className={'mb-3 cursor-pointer'} key={data.id}>
                                <div
                                  id={`${data.id}`}
                                  name="urlRadioButton"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setCompetitor2(data.name)
                                  }}
                                >
                                  <label className={'f-black ml-3 cursor-pointer'}>{` ${data.name}`}</label>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder={'Facebook Page Name'}
                      value={competitor2}
                      onChange={(e) => {
                        setCompetitor2(e.target.value)
                      }}
                    />
                  </InputGroup>

                  <p>Add account name for Instagram</p>
                  <br />
                  <InstagramLogin
                    clientId={477695527609399}
                    onSuccess={responseInstagram}
                    onFailure={responseInstagram}
                    redirectUri="https://tools1.dev-positionmysite.com/auth/redirecting"
                    cssClass="me-1 mb-1 btn btn-instagram"
                    buttonText="Instagram"
                    height={32}
                    width={120}
                  >
                    <FontAwesomeIcon icon={faInstagram} className="align-middle" /> <span>Instagram</span>
                  </InstagramLogin>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder={'Instagram Account Name'}
                      value={competitor3}
                      onChange={(e) => {
                        setCompetitor3(e.target.value)
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mt-2rem">
                <Col>
                  <Button
                    variant={`outline-secondary`}
                    onClick={() => {
                      dispatch({ type: 'CLEAR_COMPETITORS_DETAILS' })
                      history(paths.addCompetitor)
                    }}
                    className={'w-50'}
                    style={{ height: 40 }}
                  >
                    Back
                  </Button>
                </Col>
                <Col className={'display-end'}>
                  <Button onClick={() => checkAddCompetitors()} className={'w-50'} style={{ height: 40 }}>
                    Finish
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AddSocialHandle

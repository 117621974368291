import { put, takeEvery, all } from 'redux-saga/effects'

import {
  GET_TOP_KEYWORDS,
  GET_TOP_KEYWORDS_SUCCESS,
  GET_TOP_KEYWORDS_FAILURE,
  GET_NEW_TOP_KEYWORDS,
  GET_NEW_TOP_KEYWORDS_SUCCESS,
  GET_NEW_TOP_KEYWORDS_FAILURE,
  GET_TOP_KEYWORDS_LOCATION,
  GET_TOP_KEYWORDS_LOCATION_SUCCESS,
  GET_TOP_KEYWORDS_LOCATION_FAILURE,
  STORE_NEW_TOP_KEYWORDS,
  STORE_NEW_TOP_KEYWORDS_SUCCESS,
  STORE_NEW_TOP_KEYWORDS_FAILURE,
  GET_TOP_FIVE_KEYWORD,
  GET_TOP_FIVE_KEYWORD_SUCCESS,
  GET_TOP_FIVE_KEYWORD_FAILURE,
  GET_AVG_POSITION_KEYWORD,
  GET_AVG_POSITION_KEYWORD_SUCCESS,
  GET_AVG_POSITION_KEYWORD_FAILURE,
  GET_NUMBER_OF_RESULT,
  GET_NUMBER_OF_RESULT_SUCCESS,
  GET_NUMBER_OF_RESULT_FAILURE,
} from '../../actionType'

// api
import {
  getLocalRequestWithoutToken,
  getRequest,
} from '../../../constants/httpHelperService'

const getTopKeywordApi = async (input) => {
  let url = ''
  if (input?.sort?.length) {
    url = `keywords/get-top-keywords?limit=${input?.limit}&skip=${input?.skip}&sort=${input?.sort}`
  } else {
    url = `keywords/get-top-keywords?limit=${input?.limit}&skip=${input?.skip}`
  }
  return await getRequest(url)
    .then((res) => res)
    .catch((error) => error)
}

function* getTopKeywordData(action) {
  try {
    const data = yield getTopKeywordApi(action.payload)
    yield put({ type: GET_TOP_KEYWORDS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_TOP_KEYWORDS_FAILURE, e })
  }
}

function* getTopKeywordSaga() {
  yield takeEvery(GET_TOP_KEYWORDS, getTopKeywordData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getNewTopKeywordApi = async (input) => {
  let url = ''
  if (input?.sort?.length) {
    url = `get-all-top-keywords?limit=${input?.limit}&skip=${input?.skip}&sort=${input?.sort}`
  } else {
    url = `get-all-top-keywords?limit=${input?.limit}&skip=${input?.skip}`
  }
  return await getRequest(url)
    .then((res) => res)
    .catch((error) => error)
}

function* getNewTopKeywordData(action) {
  try {
    const data = yield getNewTopKeywordApi(action.payload)
    yield put({ type: GET_NEW_TOP_KEYWORDS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_NEW_TOP_KEYWORDS_FAILURE, e })
  }
}

function* getNewTopKeywordSaga() {
  yield takeEvery(GET_NEW_TOP_KEYWORDS, getNewTopKeywordData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getNewTopKeywordLocationApi = async () => {
  return await getRequest('get-top-keyword-all-location')
    .then((res) => res)
    .catch((error) => error)
}

function* getNewTopKeywordLocationData(action) {
  try {
    const data = yield getNewTopKeywordLocationApi(action.payload)
    yield put({ type: GET_TOP_KEYWORDS_LOCATION_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_TOP_KEYWORDS_LOCATION_FAILURE, e })
  }
}

function* getNewTopKeywordLocationSaga() {
  yield takeEvery(GET_TOP_KEYWORDS_LOCATION, getNewTopKeywordLocationData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getMoreKeywordApi = async (input) => {
  return await getRequest(
    `store-new-top-keywords?url=${input.url}&region=${input.region}&country=${input.countryName}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getMoreKeywordData(action) {
  try {
    const data = yield getMoreKeywordApi(action.payload)
    yield put({ type: STORE_NEW_TOP_KEYWORDS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: STORE_NEW_TOP_KEYWORDS_FAILURE, e })
  }
}

function* getMoreNewTopKewordsKeywordSaga() {
  yield takeEvery(STORE_NEW_TOP_KEYWORDS, getMoreKeywordData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getTopFiveKeywordApi = async () => {
  return await getRequest(`get-top-five-keywords`)
    .then((res) => res)
    .catch((error) => error)
}

function* getTopFiveKeywordData() {
  try {
    const data = yield getTopFiveKeywordApi()
    yield put({ type: GET_TOP_FIVE_KEYWORD_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_TOP_FIVE_KEYWORD_FAILURE, e })
  }
}

function* getTopFiveKeywordSaga() {
  yield takeEvery(GET_TOP_FIVE_KEYWORD, getTopFiveKeywordData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getAvgpositionApi = async () => {
  return await getRequest(`get-average-position`)
    .then((res) => res)
    .catch((error) => error)
}

function* getAvgpositionData() {
  try {
    const data = yield getAvgpositionApi()
    yield put({ type: GET_AVG_POSITION_KEYWORD_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_AVG_POSITION_KEYWORD_FAILURE, e })
  }
}

function* getAvgpositionSaga() {
  yield takeEvery(GET_AVG_POSITION_KEYWORD, getAvgpositionData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getNumberOfResultApi = async (input) => {
  return await getLocalRequestWithoutToken(
    `get-number-of-results?keyword=${input.keyword}&region=${input.region}&userId=${input.userId}&url=${input.url}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getNumberOfResultData(action) {
  try {
    const data = yield getNumberOfResultApi(action.payload)
    yield put({ type: GET_NUMBER_OF_RESULT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_NUMBER_OF_RESULT_FAILURE, e })
  }
}

function* getNumberOfResultSaga() {
  yield takeEvery(GET_NUMBER_OF_RESULT, getNumberOfResultData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function* TopKeywordsSaga() {
  yield all([
    getTopKeywordSaga(),
    getNewTopKeywordSaga(),
    getNewTopKeywordLocationSaga(),
    getMoreNewTopKewordsKeywordSaga(),
    getTopFiveKeywordSaga(),
    getNumberOfResultSaga(),
    getAvgpositionSaga(),
  ])
}

import { useEffect, useState } from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import useSidebar from '../../hooks/useSidebar'
import SidebarFooter from './SidebarFooter'
import SidebarNav from './SidebarNav'
import { useSelector } from 'react-redux'
import { PLAN } from 'constants/enum'
import pmsLogo from 'assets/img/logo/logo_1.svg'

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar()
  const { userData } = useSelector(({ dashboard }) => dashboard)

  const [upgradeToggle, setUpgradeToggle] = useState(false)

  const webesitesLength = userData?.searchResults?.length

  useEffect(() => {
    function getwebsitesLength() {
      if (userData?.subscriptions?.[0]?.isCancel) {
        setUpgradeToggle(false)
      } else if (userData?.planData?.name === PLAN.PREMIUM_PLAN.name) {
        setUpgradeToggle(false)
      } else if (webesitesLength === 3 && userData?.planData?.name === PLAN.PRO_PLAN.name) {
        setUpgradeToggle(true)
      } else if (webesitesLength === 1 && userData?.planData?.name === PLAN.BASIC_PLAN.name) {
        setUpgradeToggle(true)
      } else {
        setUpgradeToggle(false)
      }
    }
    getwebsitesLength()
  }, [userData, webesitesLength])

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/dashboard/revised">
            {/* <Logo /> */}
            <img alt="" src={pmsLogo} style={{ height: 45 }} />
          </a>

          <SidebarNav items={items} />
          {!!showFooter && upgradeToggle && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default Sidebar

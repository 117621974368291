import { put, takeLatest, takeEvery, all } from 'redux-saga/effects'

// action type
import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_START,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_TOKEN,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PLAN,
  CHANGE_PLAN_SUCCESS,
  CHANGE_PLAN_FAILURE,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_FAILURE,
  VERIFY_PASSWORD_SUCCESS,
  SUPPORT_REQUEST,
  SUPPORT_REQUEST_SUCCESS,
  SUPPORT_REQUEST_FAILURE,
  GET_STRIPE_SECRET_KEY,
  GET_STRIPE_SECRET_KEY_SUCCESS,
  GET_STRIPE_SECRET_KEY_FAILURE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  REGISTER_SOCIAL_SUCCESS,
  STORE_USER_DATA_START,
} from '../../actionType'

// api
import {
  postLocalRequestWithoutToken,
  getLocalRequestWithoutToken,
  getRequest,
  paymentRequest,
  postLocalRequestWithToken,
} from '../../../constants/httpHelperService'

const registerApi = async (input) => {
  return await postLocalRequestWithoutToken('users/register', input)
}

const registerSocialApi = async (input) => {
  return await postLocalRequestWithoutToken('users/register-social', input)
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })
}

function* registerData(action) {
  try {
    let data
    if (action.payload.social) {
      data = yield registerSocialApi(action.payload)
    } else {
      data = yield registerApi(action.payload)
    }
    if (data.status === 500) {
      yield put({ type: REGISTER_FAILURE, payload: data })
    } else {
      if(action?.payload?.social){
        localStorage.setItem("token", data?.token)
        yield put({ type: REGISTER_SOCIAL_SUCCESS, payload: data })
        yield put({ type: STORE_USER_DATA_START, payload: data })

      }else{
        yield put({ type: REGISTER_SUCCESS, payload: data })
      }
    }
  } catch (e) {
    yield put({ type: REGISTER_FAILURE, e })
  }
}

function* registerDataSaga() {
  yield takeLatest(REGISTER_START, registerData)
}

const loginApi = async (input) => {
  return await postLocalRequestWithoutToken('user/login', input)
    .then((res) => res)
    .catch((error) => error)
}

const loginSocialApi = async (input) => {
  return await postLocalRequestWithoutToken('user/login-social', input)
    .then((res) => res)
    .catch((error) => error)
}

function* loginData(action) {
  try {
    let data
    if (action.payload.social) {
      data = yield loginSocialApi(action.payload)
    } else {
      data = yield loginApi(action.payload)
    }
    if (data.token) {
      yield put({ type: LOGIN_SUCCESS, payload: data })
    } else {
      yield put({ type: LOGIN_FAILURE, payload: data })
    }
  } catch (e) {
    yield put({ type: LOGIN_FAILURE, e })
  }
}

function* loginDataSaga() {
  yield takeLatest(LOGIN_START, loginData)
}

const verifyApi = async (input) => {
  return await getLocalRequestWithoutToken(`user/verify/${input}`)
  // .then(res => res)
  // .catch(error => error);
}

function* verifyData(action) {
  try {
    let data = yield verifyApi(action.payload)
    if (data.status === 500) {
      yield put({ type: VERIFY_FAILURE, payload: data })
    } else {
      yield put({ type: VERIFY_SUCCESS, payload: data })
    }
  } catch (e) {
    yield put({ type: VERIFY_FAILURE, e })
  }
}

function* verifyDataSaga() {
  yield takeEvery(VERIFY_START, verifyData)
}

const verifyTokenApi = async () => {
  return await getRequest(`verify-token`)
  // .then(res => res)
  // .catch(error => error);
}

function* verifyTokenData(action) {
  try {
    let data = yield verifyTokenApi(action.payload)

    if (data.verify) {
      yield put({ type: VERIFY_TOKEN_SUCCESS, payload: data })
    } else {
      yield put({ type: VERIFY_TOKEN_FAILURE, payload: data })
    }
  } catch (e) {
    yield put({ type: VERIFY_TOKEN_FAILURE, e })
  }
}

function* verifyTokenSaga() {
  yield takeEvery(VERIFY_TOKEN, verifyTokenData)
}

const verifyTokenApiTest = async () => {
  return await getRequest(`verify-token`)
  // .then(res => res)
  // .catch(error => error);
}

function* verifyTokenDataTest(action) {
  try {
    let data = yield verifyTokenApiTest(action.payload)

    if (data.verify) {
      yield put({ type: VERIFY_TOKEN_SUCCESS, payload: data })
    } else {
      yield put({ type: VERIFY_TOKEN_FAILURE, payload: data })
    }
  } catch (e) {
    yield put({ type: VERIFY_TOKEN_FAILURE, e })
  }
}

function* verifyTokenSagaTest() {
  yield takeEvery(VERIFY_TOKEN, verifyTokenDataTest)
}

const resetPasswordApiTest = async (input) => {
  return await postLocalRequestWithoutToken(`auth/reset-password`, { email: input })
    .then((res) => res)
    .catch((error) => error)
}

function* resetPasswordDataTest(action) {
  try {
    let data = yield resetPasswordApiTest(action.payload)

    if (data?._id) {
      yield put({ type: RESET_PASSWORD_SUCCESS, payload: data })
    } else {
      yield put({ type: RESET_PASSWORD_FAILURE, payload: data })
    }
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAILURE, e })
  }
}

function* resetPasswordSagaTest() {
  yield takeEvery(RESET_PASSWORD, resetPasswordDataTest)
}

//Change Password API
const changePasswordApiTest = async (input) => {
  return await postLocalRequestWithoutToken(`auth/change-password`, input)
}

function* changePasswordDataTest(action) {
  try {
    let data = yield changePasswordApiTest(action.payload)
    yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CHANGE_PASSWORD_FAILURE, e })
  }
}

function* changePasswordSagaTest() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordDataTest)
}

const changePlanApiTest = async (input) => {
  return await paymentRequest(`auth/change-plan`, input)
}

function* changePlanDataTest(action) {
  try {
    let data = yield changePlanApiTest(action.data)
    yield put({ type: CHANGE_PLAN_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CHANGE_PLAN_FAILURE, e })
  }
}

function* changePlanSagaTest() {
  yield takeEvery(CHANGE_PLAN, changePlanDataTest)
}

async function verifyPasswordApi(data){
  return await postLocalRequestWithToken(`auth/verify-password`, data)
}

function* verifyPasswordData(action){
  try {
    const data = yield verifyPasswordApi(action?.payload)
    yield put({type: VERIFY_PASSWORD_SUCCESS, payload: data})
  } catch (error) {
    yield put({type: VERIFY_PASSWORD_FAILURE, error:error?.response?.data})
  }
}

function* verifyPasswordSaga() {
  yield takeLatest(VERIFY_PASSWORD, verifyPasswordData)
}

const supportRequestApi = async (data) => {
  return await postLocalRequestWithToken(`user/support-request`, data)
}
function* supportRequestData(action){
  try {
    const data = yield supportRequestApi(action?.payload)
    yield put({type: SUPPORT_REQUEST_SUCCESS, payload: data}) 
  } catch (error) {
    yield put({type: SUPPORT_REQUEST_FAILURE, error: error?.response?.data?.message}) 
  }
}
function* supportRequestSaga() {
  yield takeLatest(SUPPORT_REQUEST, supportRequestData)
}

async function getStripeSecretKeyApi(input){
  return await getRequest(`payment/setup-intent?customerId=${input?.customerId}`)
}

function* getStripeSecretKeyData(action){
  try {
    const data = yield getStripeSecretKeyApi(action?.payload)
    yield put({type: GET_STRIPE_SECRET_KEY_SUCCESS, payload: data})
  } catch (error) {
    yield put({type: GET_STRIPE_SECRET_KEY_FAILURE, error:error?.response?.data})
  }
}

function* getStripeSecretSaga() {
  yield takeLatest(GET_STRIPE_SECRET_KEY, getStripeSecretKeyData)
}

const getPaymentsMethodsApi = async (input) => {
  return await getRequest(`payment/payment-methods?customerId=${input?.customerId}`)
}
function* getPaymentMethodsData(action) {
  try {
    const data = yield getPaymentsMethodsApi(action?.payload)
    yield put({type: GET_PAYMENT_METHODS_SUCCESS, payload: data})
  } catch (error) {
    yield put({type: GET_PAYMENT_METHODS_FAILURE, error:error?.response?.data})
  }
}
function* getPaymentMethodsSaga(){
  yield takeLatest(GET_PAYMENT_METHODS, getPaymentMethodsData)
}
export default function* registerSaga() {
  yield all([
    registerDataSaga(),
    loginDataSaga(),
    verifyDataSaga(),
    verifyTokenSaga(),
    verifyTokenSagaTest(),
    resetPasswordSagaTest(),
    changePasswordSagaTest(),
    changePlanSagaTest(),
    verifyPasswordSaga(),
    supportRequestSaga(),
    getStripeSecretSaga(),
    getPaymentMethodsSaga()
  ])
}

import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ModalContainer = (props) => {
  const { show, onHide, message, toggleShow, children, showFooter, headerTitle, title, titleStyles } = props
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="br-15"
      show={show}
      toggle={toggleShow}
      onClose={toggleShow}
      onHide={toggleShow}
    >
      <Modal.Header closeButton style={titleStyles} className="bb-none">
        {headerTitle} {title ? <div className="top-domain-model-header">&nbsp; {title} </div> : <></>}
      </Modal.Header>
      <Modal.Body className="p-left-right">
        {message && <p className="modalMessage">{message}</p>}
        {children}
      </Modal.Body>
      {showFooter && (
        <div className="d-flex justify-content-center mb-55">
          <div>
            <Button onClick={() => onHide(false)} className="w-20 btn-primary" variant="primary">
              Yes
            </Button>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <Button onClick={() => onHide(false)} className="w-20 mr-20 btn-primary" variant="danger">
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ModalContainer

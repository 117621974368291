import { put, takeEvery, all } from 'redux-saga/effects'

import {
  GET_BACKLINKS_MOSTRECENTLY_CSV,
  GET_BACKLINKS_MOSTRECENTLY_CSV_SUCCESS,
  GET_BACKLINKS_MOSTRECENTLY_CSV_FAILURE,
  GET_BACKLINKS_MOSTIMPORTANT_CSV,
  GET_BACKLINKS_MOSTIMPORTANT_CSV_SUCCESS,
  GET_BACKLINKS_MOSTIMPORTANT_CSV_FAILURE,
  GET_BACKLINKS_MOSTIMPORTANT_JSON,
  GET_BACKLINKS_MOSTIMPORTANT_JSON_SUCCESS,
  GET_BACKLINKS_MOSTIMPORTANT_JSON_FAILURE,
  GET_BACKLINKS_MOSTRECENTLY_JSON,
  GET_BACKLINKS_MOSTRECENTLY_JSON_SUCCESS,
  GET_BACKLINKS_MOSTRECENTLY_JSON_FAILURE,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON_SUCCESS,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON_FAILURE,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV_SUCCESS,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV_FAILURE,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV,
} from '../../actionType'

// api
import { getRequest } from '../../../constants/httpHelperService'

const callBacklinksMostRecentlyCSV = async (input) => {
  return await getRequest(
    `backlinks/get-all?url=${input.url}&type=${input.type}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getBacklinksMostRecentlyCSVData(action) {
  try {
    const data = yield callBacklinksMostRecentlyCSV(action.payload)
    yield put({ type: GET_BACKLINKS_MOSTRECENTLY_CSV_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_MOSTRECENTLY_CSV_FAILURE, e })
  }
}

function* getBacklinksMostRecentlyCSV() {
  yield takeEvery(GET_BACKLINKS_MOSTRECENTLY_CSV, getBacklinksMostRecentlyCSVData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const callBacklinksMostImpCSV = async (input) => {
  return await getRequest(
    `backlinks/get-all?url=${input.url}&type=${input.type}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getBacklinksMostImpCSVData(action) {
  try {
    const data = yield callBacklinksMostImpCSV(action.payload)
    yield put({ type: GET_BACKLINKS_MOSTIMPORTANT_CSV_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_MOSTIMPORTANT_CSV_FAILURE, e })
  }
}

function* getBacklinksMostImpCSV() {
  yield takeEvery(GET_BACKLINKS_MOSTIMPORTANT_CSV, getBacklinksMostImpCSVData)
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const callBacklinksMostImpJSON = async (input) => {
  return await getRequest(
    `backlinks/get-all?url=${input.url}&type=${input.type}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getBacklinksMostImpJSONData(action) {
  try {
    const data = yield callBacklinksMostImpJSON(action.payload)
    yield put({ type: GET_BACKLINKS_MOSTIMPORTANT_JSON_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_MOSTIMPORTANT_JSON_FAILURE, e })
  }
}

function* getBacklinksMostImpJSON() {
  yield takeEvery(GET_BACKLINKS_MOSTIMPORTANT_JSON, getBacklinksMostImpJSONData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const callBacklinksMostRecentJSON = async (input) => {
  return await getRequest(
    `backlinks/get-all?url=${input.url}&type=${input.type}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getBacklinksMostRecentJSONData(action) {
  try {
    const data = yield callBacklinksMostRecentJSON(action.payload)
    yield put({ type: GET_BACKLINKS_MOSTRECENTLY_JSON_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_MOSTRECENTLY_JSON_FAILURE, e })
  }
}

function* getBacklinksMostRecentJSON() {
  yield takeEvery(GET_BACKLINKS_MOSTRECENTLY_JSON, getBacklinksMostRecentJSONData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const callReferringDomainJSON = async (input) => {
  return await getRequest(
    `backlinks/get-all?url=${input.url}&type=${input.type}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getReferringDomainJSONData(action) {
  try {
    const data = yield callReferringDomainJSON(action.payload)
    yield put({ type: GET_BACKLINKS_REFERRINGDOMAINS_JSON_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_REFERRINGDOMAINS_JSON_FAILURE, e })
  }
}

function* getReferringDomainJSON() {
  yield takeEvery(GET_BACKLINKS_REFERRINGDOMAINS_JSON, getReferringDomainJSONData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const callReferringDomainCSV = async (input) => {
  return await getRequest(
    `backlinks/get-all?url=${input.url}&type=${input.type}`,
  )
    .then((res) => res)
    .catch((error) => error)
}

function* getReferringDomainCSVData(action) {
  try {
    const data = yield callReferringDomainCSV(action.payload)
    yield put({ type: GET_BACKLINKS_REFERRINGDOMAINS_CSV_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BACKLINKS_REFERRINGDOMAINS_CSV_FAILURE, e })
  }
}

function* getReferringDomainCSV() {
  yield takeEvery(GET_BACKLINKS_REFERRINGDOMAINS_CSV, getReferringDomainCSVData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function* backlinksDataSaga() {
  yield all([
    getBacklinksMostRecentlyCSV(),
    getBacklinksMostImpCSV(),
    getBacklinksMostImpJSON(),
    getBacklinksMostRecentJSON(),
    getReferringDomainJSON(),
    getReferringDomainCSV(),
  ])
}

import {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { 
    Alert, 
    Button,
    Card, 
    Modal, 
   } from 'react-bootstrap'

import { deleteUser } from 'redux/actions/dashboard'

import ToastMessage from 'components/uiCore/ToastMessage'
import { useNavigate } from 'react-router-dom'


const DeleteAccount = () => {
  const { deleteUserLoading, toastMessageUser, toastErrorUser } = useSelector(({ dashboard }) => dashboard)
  const [toggle, setToggle] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDelete = () =>{
    const data = localStorage.getItem('userData')
    dispatch(deleteUser())
  }
  useEffect(() => {
    if(!deleteUserLoading && (toastErrorUser || toastMessageUser)){
        setShowToast(true)
    }
  }, [deleteUserLoading])

  useEffect(() => {
    if(toastMessageUser){
      navigate('/auth/sign-in')
    }
  }, [toastMessageUser])
return(
<>
        {(toastMessageUser || toastErrorUser) ? <ToastMessage show={showToast} onClose={() => {setShowToast(false)}} variant={toastMessageUser ? "success" : toastErrorUser ? "danger" : "warning"} toastMessage={toastMessageUser ? toastMessageUser : toastErrorUser ? toastErrorUser : "Something went wrong!"} /> :null} 
<Card>
        <Card.Body>

            <Alert
                variant="danger"
                className="alert-outline-coloured"
                key="meta-title"
            >
                <div className="alert-icon" />
                <div className="alert-message">
                    <h4>Please note!</h4>
                    <span className='alert-for-mobile'>
                        After deleting your account, you will not be able to use our services: your subscription will be canceled and your content, saved reports and other personal information will be erased.
                    </span>
                </div>
            </Alert>
            <Button variant="primary" onClick={()=>setToggle(true)}>Delete Account</Button>
            <Modal
                  show={toggle}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => {}}
                  style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
                  className="br-15"
                >
                  <Modal.Body>
                  <p className='fs-4'>Are you sure that you want to remove Account?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      color="primary"
                      onClick={() => {
                        setToggle(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleDelete()
                      }}
                    >
                      Confirm
                    </Button>
                  </Modal.Footer>
                </Modal>
        </Card.Body>
    </Card>
</>
)
}
  
  export default DeleteAccount
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Button } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import 'react-credit-cards/es/styles-compiled.css'
import { CardBody, CardHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import pmsLogo from '../../../assets/img/pms/unnamed.gif'
import ColoredModal from '../../modals/ColorModal'
import SocialButton from '../../../components/socialButton'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import paths from 'constants/paths'

const Authentication = () => {
  const [planName, setPlanName] = useState('Business Plus')

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const registerData = useSelector(({ register }) => register.registerData)
  const randomNumber = useSelector(({ startFreeTrial }) => startFreeTrial.randomNumber)

  const history = useNavigate()

  useEffect(() => {
    setPlanName(registerData?.plan?.name || 'Business Plus')
    setPlanDuration(registerData?.plan?.type || 'Monthly')
  }, [])

  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
      return
    } else {
      history(paths.addNewWebsite)
    }
  }, [randomNumber])

  const checkAddCompetitors = () => {
    history(paths.addLocation)
  }

  return (
    <React.Fragment>
      <Helmet title="Start trail" />
      {error ? (
        <ColoredModal
          isOpen={error}
          message={errorMessage}
          onClose={() => {
            setError(false)
            setErrorMessage(false)
          }}
        />
      ) : (
        <></>
      )}

      <Row style={{ marginBottom: 10, marginTop: 10 }}>
        <Col>
          <img
            src={pmsLogo}
            alt="positionmysite"
            width={150}
            className="cursor-pointer"
            onClick={() => history('/')}
          />
        </Col>
        <Col className="pyament-card-logout">
          {planName} | {registerData.email || ''} | <Link to="/"> &nbsp;Logout</Link>
        </Col>
      </Row>

      <Card className="trail-flow" style={{ padding: 15 }}>
        <CardBody>
          <Card>
            <CardHeader style={{ backgroundColor: '#fbfbfb' }}>
              <Row>
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>{' '}
                    Start
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'done-step'}>
                      <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
                    </span>{' '}
                    Website
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'active-step'}>3</span>
                    Connect
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'inactive-step'}>4</span> Location
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'inactive-step'}>5</span> Keywords
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'inactive-step'}>6</span> Competitors
                  </div>
                </Col>
                <Col>
                  <div className={'text-center'}>
                    <span className={'inactive-step'}>7</span> Social Info
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="bg-white">
              <Row>
                <Col>
                  <h3> Add Authentication </h3>

                  <p>Google Search Console</p>
                  <SocialButton
                    containerStyle={isMobile ? { width: '100%' } : {}}
                    provider="google"
                    scope="https://www.googleapis.com/auth/webmasters"
                    appId="74542118637-c261k854oj1m08ov29tcvrsvkcbrnhpm.apps.googleusercontent.com" // My Project170
                    onLoginSuccess={(data) => {
                      if (data._profile.email && data._token.accessToken) {
                        let apiData = {
                          token: data._token.accessToken,
                          // authCode : data.authCode.code,
                          social: true,
                          type: 'google',
                        }

                      }
                    }}
                    onLoginFailure={(data) => {
                      alert(data)
                    }}
                  >
                    {/* <img
                                            src={google}
                                            alt="positionmysite"
                                        /> */}
                    <Button variant="google">
                      <FontAwesomeIcon icon={faGoogle} /> Google
                    </Button>
                  </SocialButton>
                  <br />
                  <br />
                  <br />
                  {/* Do you want to skip this step ? &nbsp;
                                        <Button variant={`outline-secondary`} onClick={() => checkAddCompetitors()}  className={''} style={{ height: 40 }}>
                                            Skip
                                        </Button> */}
                </Col>
              </Row>

              <Row className="mt-2rem">
                <Col>
                  <Button
                    variant={`outline-secondary`}
                    onClick={() => {
                      history('/trial/select-website')
                    }}
                    className={'w-50'}
                    style={{ height: 40 }}
                  >
                    Back
                  </Button>
                </Col>

                <Col className={'display-end'}>
                  <Button
                    variant={`outline-primary`}
                    className={'w-50'}
                    onClick={() => checkAddCompetitors()}
                    style={{ height: 40 }}
                  >
                    Skip
                  </Button>{' '}
                  &nbsp;
                  <Button onClick={() => checkAddCompetitors()} className={'w-50'} style={{ height: 40 }}>
                    Next
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Authentication

import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const DocType = ({ data }) => {
  return (
    <>
      {data && data?.docType ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.docType?.data?.testResults?.status ? (
                ''
              ) : data?.docType?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.docType?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              {' '}
              <p>{data?.docType?.data?.testResults?.message}</p>{' '}
            </div>
          </div>
          {data.docType?.data?.testResults?.status === 1 ? (
            <>
              <div className="vr-detail-block">
                <div className="vr-report-status-icon" />
                <div>
                  {' '}
                  <p>{data?.docType?.data?.docType?.element}</p>{' '}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DocType

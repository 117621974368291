import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const NoFollowTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.noFollowTest.heading}
      whatIsIT={freeTools.noFollowTest.what}
      howToFixBeforeCode={freeTools.noFollowTest.howSectionBeforeCode}
    />
  )
}

export default NoFollowTest

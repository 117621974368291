import { MoreHorizontal } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Dropdown, Nav, Spinner } from 'react-bootstrap'
import paths from 'constants/paths'

const MainUrlNav = ({ item, index }) => {
  const navigate = useNavigate()

  const faviconCheckerTest = item?.items?.[0]?.information?.seoCheckup?.faviconCheckerTest
  const faviconTestStatus = item?.items?.[0]?.faviconCheckerTest?.[0]?.data?.faviconTest?.status

  const imgSrc1 = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=16&url=${item?.items?.[0]?.information?.seoCheckup?.redirect_url}`
  const imgSrc2 = item?.items?.[0]?.information?.seoCheckup?.faviconCheckerTest?.favicon
  const imgSrc3 = item?.items?.[0]?.faviconCheckerTest?.[0]?.data?.favicon
  const imgSrc4 = 'http://via.placeholder.com/24x24/cfcfcf/000000%20C'

  const imgSrc = imgSrc1 || imgSrc2 || imgSrc3 || imgSrc4

  const mainUrl =
    item && item?.items && item?.items?.length && item?.items?.[0]?.url
      ? item?.items?.[0]?.url?.length > 25
        ? `${item?.items?.[0]?.url?.substring(0, 25)}...`
        : item?.items?.[0]?.url
      : ''

  return (
    <Nav.Item>
      <Nav.Link eventKey={index} style={{ width: isMobile ? 328 : 295 }}>
        {faviconCheckerTest || faviconTestStatus ? (
          <img src={imgSrc} width={22} height={22} alt="" className="mr-2" />
        ) : (
          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {mainUrl}
        <div className="float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  navigate(paths.dashboardSettings, {
                    state: { activeTab: '1' },
                  })
                }}
              >
                Competitor
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate(paths.dashboardSettings, {
                    state: { activeTab: '2' },
                  })
                }}
              >
                Keywords
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate(paths.dashboardSettings, {
                    state: { activeTab: '3' },
                  })
                }}
              >
                Region
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  navigate(paths.dashboardSettings, {
                    state: { activeTab: '4' },
                  })
                }}
              >
                Connections 
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  navigate(paths.dashboardSettings, {
                    state: { activeTab: '5' },
                  })
                }}
              >
                Remove URL
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Nav.Link>
    </Nav.Item>
  )
}

export default MainUrlNav

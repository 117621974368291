import React from 'react'
import { Table } from 'react-bootstrap'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { Link } from 'react-router-dom'
import { CommonAlert } from 'components/uiCore'

const SocialMediaMetaTags = ({ data }) => {
  return (
    <>
      {data && data?.microDataSchema ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.microDataSchema?.data?.metaTagsResults?.status ? (
                ''
              ) : data?.microDataSchema?.data?.metaTagsResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.microDataSchema?.data?.metaTagsResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : data?.microDataSchema?.data?.metaTagsResults?.status === 4 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <h5>{data?.url}</h5>
          </div>
          <div className="vr-detail-block">
            {data?.microDataSchema?.data?.metaTagsResults?.status === 4 ? (
              <CommonAlert
                variant={'warning'}
                key={'meta-title'}
                alertText={
                  <div>
                    <span className='alert-for-mobile'>{data?.microDataSchema?.data?.metaTagsResults?.cachedMessage}</span>
                    <Link
                      to="/pages/firewall-options"
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                    >
                      click here
                    </Link>
                  </div>
                }
              />
            ) : (
              <></>
            )}

            {data.microDataSchema?.data?.metaTagsResults ? (
              <>
                <p> {data.microDataSchema?.data?.metaTagsResults?.message || ''}</p>
              </>
            ) : (
              <></>
            )}
            {data.microDataSchema?.data?.data?.meta ? (
              <>
                <div className="mt-3">
                  {data.microDataSchema?.data?.data?.meta?.['og-title'] ||
                  data.microDataSchema?.data?.data?.meta?.['og-description'] ||
                  data.microDataSchema?.data?.data?.meta?.['og-url'] ||
                  data.microDataSchema?.data?.data?.meta?.['og-type'] ||
                  data.microDataSchema?.data?.data?.meta?.['og-site-name'] ||
                  data.microDataSchema?.data?.data?.meta?.['og-image'] ? (
                    <>
                      <Table
                        responsive
                        className="custom-table-report social-media-meta-table d-none d-sm-block"
                      >
                        <thead>
                          <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }} colSpan="2">
                            Open Graph Meta Tags
                          </th>
                        </thead>
                        <tbody>
                          {data.microDataSchema?.data?.data?.meta?.['og-title'] ? (
                            <tr>
                              <td> og:title </td>
                              <td> {data.microDataSchema?.data?.data?.meta?.['og-title']} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          {data.microDataSchema?.data?.data?.meta?.['og-description'] ? (
                            <tr>
                              <td> og:description </td>
                              <td> {data.microDataSchema?.data?.data?.meta?.['og-description']} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          {data.microDataSchema?.data?.data?.meta?.['og-url'] ? (
                            <tr>
                              <td> og:url </td>
                              <td> {data.microDataSchema?.data?.data?.meta?.['og-url']} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          {data.microDataSchema?.data?.data?.meta?.['og-type'] ? (
                            <tr>
                              <td> og:type </td>
                              <td> {data.microDataSchema?.data?.data?.meta?.['og-type']} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          {data.microDataSchema?.data?.data?.meta?.['og-site-name'] ? (
                            <tr>
                              <td> og:site </td>
                              <td> {data.microDataSchema?.data?.data?.meta?.['og-site-name']} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          {data.microDataSchema?.data?.data?.meta?.['og-image'] ? (
                            <tr>
                              <td> og:image </td>
                              <td> {data.microDataSchema?.data?.data?.meta?.['og-image']} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.microDataSchema?.data?.data?.meta ? (
                    <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
                      <div className="test-name mb-3">Open Graph Meta Tags</div>
                      {data.microDataSchema?.data?.data?.meta?.['og-title'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> og:title </p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['og-title']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['og-description'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> og:description </p>
                          <p className="mb-2">
                            {' '}
                            {data.microDataSchema?.data?.data?.meta?.['og-description']}{' '}
                          </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['og-url'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> og:url</p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['og-url']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['og-type'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> og:type </p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['og-type']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['og-site-name'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> og:site</p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['og-site-name']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['og-image'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> og:image</p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['og-image']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  ) : null}
                  {!data.microDataSchema?.data?.data?.meta?.['og-title'] &&
                  !data.microDataSchema?.data?.data?.meta?.['og-description'] &&
                  !data.microDataSchema?.data?.data?.meta?.['og-url'] &&
                  !data.microDataSchema?.data?.data?.meta?.['og-type'] &&
                  !data.microDataSchema?.data?.data?.meta?.['og-site-name'] &&
                  !data.microDataSchema?.data?.data?.meta?.['og-image'] ? (
                    <CommonAlert
                      variant={
                        data?.microDataSchema?.data?.metaTagsResults?.status === 3 ? 'danger' : 'warning'
                      }
                      key={'social-media-meta-tag'}
                      alertText={
                        <div>
                          <span className='alert-for-mobile'> Open Graph Meta Tags, no parameter found.</span>
                        </div>
                      }
                    />
                  ) : !data.microDataSchema?.data?.data?.meta?.['og-title'] ||
                    !data.microDataSchema?.data?.data?.meta?.['og-description'] ||
                    !data.microDataSchema?.data?.data?.meta?.['og-url'] ||
                    !data.microDataSchema?.data?.data?.meta?.['og-type'] ||
                    !data.microDataSchema?.data?.data?.meta?.['og-site-name'] ||
                    !data.microDataSchema?.data?.data?.meta?.['og-image'] ? (
                    <CommonAlert
                      variant={
                        data?.microDataSchema?.data?.metaTagsResults?.status === 3 ? 'danger' : 'warning'
                      }
                      key={'social-media-meta-tag'}
                      alertText={
                        <div>
                          <span className='alert-for-mobile'>
                            Open Card Meta Tags, missing these parameters:
                            <br />
                            {!data.microDataSchema?.data?.data?.meta?.['og-title'] ? <span style={{fontWeight:400, color:"#000"}}> og:title, </span> : ''}
                            {!data.microDataSchema?.data?.data?.meta?.['og-description'] ? (
                              <span style={{fontWeight:400, color:"#000"}}> og:description, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['og-url'] ? <span style={{fontWeight:400, color:"#000"}}>og:url,</span> : ''}
                            {!data.microDataSchema?.data?.data?.meta?.['og-type'] ? <span style={{fontWeight:400, color:"#000"}}> og:type, </span> : ''}
                            {!data.microDataSchema?.data?.data?.meta?.['og-site-name'] ? (
                              <span style={{fontWeight:400, color:"#000"}}>og:site, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['og-image'] ? <span style={{fontWeight:400, color:"#000"}}>og:image</span> : ''}
                          </span>
                        </div>
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-3">
                  {data.microDataSchema?.data?.data?.meta?.['twitter-card'] ||
                  data.microDataSchema?.data?.data?.meta?.['twitter-site'] ||
                  data.microDataSchema?.data?.data?.meta?.['twitter-creator'] ||
                  data.microDataSchema?.data?.data?.meta?.['twitter-url'] ||
                  data.microDataSchema?.data?.data?.meta?.['twitter-title'] ||
                  data.microDataSchema?.data?.data?.meta?.['twitter-description'] ? (
                    <Table
                      responsive
                      className="custom-table-report social-media-meta-table d-none d-sm-block"
                    >
                      <thead>
                        <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }} colSpan="2">
                          Twitter Card Meta Tags
                        </th>
                      </thead>
                      <tbody>
                        {data.microDataSchema?.data?.data?.meta?.['twitter-card'] ? (
                          <tr>
                            <td>twitter:card </td>
                            <td> {data.microDataSchema?.data?.data?.meta?.['twitter-card']} </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {data.microDataSchema?.data?.data?.meta?.['twitter-site'] ? (
                          <tr>
                            <td>twitter:site </td>
                            <td> {data.microDataSchema?.data?.data?.meta?.['twitter-site']} </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {data.microDataSchema?.data?.data?.meta?.['twitter-creator'] ? (
                          <tr>
                            <td>twitter:creator </td>
                            <td> {data.microDataSchema?.data?.data?.meta?.['twitter-creator']} </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {data.microDataSchema?.data?.data?.meta?.['twitter-url'] ? (
                          <tr>
                            <td>twitter:url </td>
                            <td> {data.microDataSchema?.data?.data?.meta?.['twitter-url']} </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {data.microDataSchema?.data?.data?.meta?.['twitter-title'] ? (
                          <tr>
                            <td>twitter:title </td>
                            <td> {data.microDataSchema?.data?.data?.meta?.['twitter-title']} </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {data.microDataSchema?.data?.data?.meta?.['twitter-description'] ? (
                          <tr>
                            <td>twitter:description </td>
                            <td> {data.microDataSchema?.data?.data?.meta?.['twitter-description']} </td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}
                  {data.microDataSchema?.data?.data?.meta ? (
                    <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
                      <div className="test-name mb-3">Twitter Card Meta Tags</div>
                      {data.microDataSchema?.data?.data?.meta?.['twitter-card'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold">twitter:card </p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['twitter-card']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['twitter-site'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold">twitter:site </p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['twitter-site']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['twitter-creator'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> twitter:creator</p>
                          <p className="mb-2">
                            {' '}
                            {data.microDataSchema?.data?.data?.meta?.['twitter-creator']}{' '}
                          </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['twitter-url'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> twitter:url </p>
                          <p className="mb-2"> {data.microDataSchema?.data?.data?.meta?.['twitter-url']} </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['twitter-title'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> twitter:title</p>
                          <p className="mb-2">
                            {' '}
                            {data.microDataSchema?.data?.data?.meta?.['twitter-title']}{' '}
                          </p>
                        </li>
                      ) : (
                        ''
                      )}
                      {data.microDataSchema?.data?.data?.meta?.['twitter-description'] ? (
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold"> twitter:description</p>
                          <p className="mb-2">
                            {' '}
                            {data.microDataSchema?.data?.data?.meta?.['twitter-description']}{' '}
                          </p>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  ) : null}
                  {!data.microDataSchema?.data?.data?.meta?.['twitter-card'] &&
                  !data.microDataSchema?.data?.data?.meta?.['twitter-site'] &&
                  !data.microDataSchema?.data?.data?.meta?.['twitter-creator'] &&
                  !data.microDataSchema?.data?.data?.meta?.['twitter-url'] &&
                  !data.microDataSchema?.data?.data?.meta?.['twitter-title'] &&
                  !data.microDataSchema?.data?.data?.meta?.['twitter-description'] ? (
                    <CommonAlert
                      variant={
                        data?.microDataSchema?.data?.metaTagsResults?.status === 3 ? 'danger' : 'warning'
                      }
                      key={'social-media-meta-tag'}
                      alertText={
                        <div>
                          <span className='alert-for-mobile'> Twitter Card Meta Tags, no parameter found. </span>
                        </div>
                      }
                    />
                  ) : !data.microDataSchema?.data?.data?.meta?.['twitter-card'] ||
                    !data.microDataSchema?.data?.data?.meta?.['twitter-site'] ||
                    !data.microDataSchema?.data?.data?.meta?.['twitter-creator'] ||
                    !data.microDataSchema?.data?.data?.meta?.['twitter-url'] ||
                    !data.microDataSchema?.data?.data?.meta?.['twitter-title'] ||
                    !data.microDataSchema?.data?.data?.meta?.['twitter-description'] ? (
                    <CommonAlert
                      variant={
                        data?.microDataSchema?.data?.metaTagsResults?.status === 3 ? 'danger' : 'warning'
                      }
                      key={'social-media-meta-tag'}
                      alertText={
                        <div>
                          <span className='alert-for-mobile'>
                            {' '}
                            Twitter Card Meta Tags, missing these parameters: <br />
                            {!data.microDataSchema?.data?.data?.meta?.['twitter-card'] ? (
                              <span style={{fontWeight:400, color:'#000000'}}>twitter:card, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['twitter-site'] ? (
                             <span style={{fontWeight:400, color:'#000000'}}>twitter:site, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['twitter-creator'] ? (
                              <span style={{fontWeight:400, color:'#000000'}}>twitter:creator, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['twitter-url'] ? (
                             <span style={{fontWeight:400, color:'#000000'}}>twitter:url, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['twitter-title'] ? (
                             <span style={{fontWeight:400, color:'#000000'}}>twitter:title, </span>
                            ) : (
                              ''
                            )}
                            {!data.microDataSchema?.data?.data?.meta?.['twitter-description'] ? (
                              <span style={{fontWeight:400, color:'#000000'}}>twitter-description</span>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            <p>
              {' '}
              Social Meta tag{' '}
              <a href="https://metatags.io" target="_blank">
                {' '}
                Preview
              </a>{' '}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SocialMediaMetaTags

import { useState, useEffect } from 'react'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import { Spinner, Alert } from 'react-bootstrap'
import axios from 'axios'
import * as moment from 'moment'
import * as _ from 'lodash'
import 'leaflet/dist/leaflet.css'
import { useSelector } from 'react-redux'
0
import { VectorMap } from 'react-jvectormap'
import { Loader } from 'components/uiCore'
import usePalette from '../../../hooks/usePalette'

import { REGIONS_NAME } from '../../../constants'
import 'pages/dashboards/SiteOutage/MapComponent.scss'

const API_BASE_LOCAL_URL = process.env.REACT_APP_NEST_LOCAL_API

export default function SiteOutageMap(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [finalData, setFinalData] = useState([])
  const [selectedFilterDataIndex, setSelectedFilterDataIndex] = useState(0)
  const [selectedHourIndex, setSelectedHourIndex] = useState(0)
  const [overviewCircleColor, setOverviewCirecleColor] = useState('')
  const [stickBackgroundColor, setStickBackgroundColor] = useState()
  const [timeStreakBackgroundColor, setTimeStreakBorderClass] = useState()
  const [pingTime, setPingTime] = useState('')
  const mapToolTipcontent = ''
  const mapToolTipPosition = ''
  const [mapConfig, setMapConfig] = useState()
  const [timeline, setTimeline] = useState([])
  const [cronranTime, setCronRanTime] = useState([])

  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink)
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)

  const palette = usePalette()

  useEffect(() => {
    if (props.user !== undefined) {
      const token = localStorage.getItem("token")
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      setLoading(true)
      axios
        .get(`${API_BASE_LOCAL_URL}get-site-outage-data`, {headers})
        .then((response) => {
          if (activeLink > -1) {
            let filterData =
              response.data &&
              response.data &&
              response.data.siteOutageData2 &&
              response.data.siteOutageData2.length &&
              response.data.siteOutageData2.filter((item) => item.urlDataId === props.activeDomainUrl)
            setFilterData(filterData)
            setData(response.data.siteOutageData2)
          } else {
            setData(response?.data?.siteOutageData2 || [])
          }
          setLoading(false)
        })
        .catch((error) => {
          throw error
        })
    }
  }, [props.user, activeLink])

  useEffect(() => {
    if (activeLink > -1) {
      const splitedValue =
        (usersDomain &&
          activeLink !== undefined &&
          usersDomain[activeLink] &&
          usersDomain[activeLink].items &&
          usersDomain[activeLink].items[0].url.split('/').slice(0, 3)) ||
        ''
      let hostName = splitedValue[splitedValue.length - 1] || ''
      if (hostName.includes('www.')) {
        hostName = hostName.replace('www.', '')
      }
      let urlData = data && data.length && data.filter((item) => item.url.includes(hostName))
      let filterData = urlData && urlData.length && urlData[0].data
      const sortedData = _.reverse(filterData)
      setSelectedFilterDataIndex(0)
      setSelectedHourIndex(0)
      setFilterData(sortedData)
    }
  }, [activeLink, data])

  const siteOutageData = filterData?.length && filterData?.map(siteOutage => {
    let siteOutageDataset = _.sortBy(siteOutage?.dataset, 'hour')
    
    return {...siteOutage, dataset: siteOutageDataset}
  })

  useEffect(() => {
    if (
      filterData &&
      filterData.length &&
      filterData[selectedFilterDataIndex] &&
      filterData[selectedFilterDataIndex].dataset &&
      filterData[selectedFilterDataIndex].dataset &&
      filterData[selectedFilterDataIndex].dataset.length &&
      filterData[selectedFilterDataIndex].dataset[selectedHourIndex] &&
      filterData[selectedFilterDataIndex].dataset[selectedHourIndex].values
    ) {
      const sortedFilteredData = _.sortBy(filterData?.[selectedFilterDataIndex]?.dataset, 'hour')
      const has200All = _.every(sortedFilteredData?.[selectedHourIndex]?.values, {
        status: '200',
      })
      const has200andOthers =
        _.some(sortedFilteredData?.[selectedHourIndex]?.values, { status: '200' }) &&
        _.some(
          sortedFilteredData?.[selectedHourIndex]?.values,
          (items) => items.status !== '200',
        )

      pingIssue(sortedFilteredData)
      if (has200All) {
        setOverviewCirecleColor('#4BBF73')
        setStickBackgroundColor('#9fdbc1')
        setTimeStreakBorderClass('#4BBF73')
      } else if (!has200All && !has200andOthers) {
        setOverviewCirecleColor('rgb(255,0,0)')
        setStickBackgroundColor('#db9f9f')
        setTimeStreakBorderClass('red')
      } else if (has200andOthers) {
        setOverviewCirecleColor('#ff8400')
        setStickBackgroundColor('#ebb65c')
        setTimeStreakBorderClass('#ff8400')
      }
      let mapMarker = []
      sortedFilteredData?.[selectedFilterDataIndex]?.dataset?.[selectedHourIndex]?.values.map((province) => {
        mapMarker.push({
          markerOffset: -15,
          name: province?.region,
          coordinates: [province?.long, province?.lat],
          active: province.status === '200' ? '#4BBF73' : 'rgb(255,0,0)',
        })
      })
      let timeline =
        '00, 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23'
      let timelineArray = timeline?.split(", ")
      setTimeline(timelineArray)  
      let cronRanTime = _.map(filterData?.[selectedFilterDataIndex]?.dataset, 'hour')
      const sortedCronRanTime = _.sortBy(cronRanTime)
      setCronRanTime(sortedCronRanTime)
     
      const seriesData = []
      let dataForMap = siteOutageData?.[selectedFilterDataIndex]?.dataset?.[selectedHourIndex]?.values?.map((outage) => {
        const latlongArray = []
        latlongArray.push(outage.lat)
        latlongArray.push(outage.long)
        seriesData.push(outage.status === '200' ? 'green' : 'red')
        return {
          latLng: latlongArray,
          name: findValueByKey(outage?.region) || '-',
        }
      })
      const option = {
        map: 'world_mill',
        normalizeFunction: 'polynomial',
        hoverOpacity: 0.7,
        hoverColor: false,
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: palette['gray-200'],
          },
        },
        markersSelectableOne: true,
        onRegionTipShow: function () {
          return false /* Don't show the standard region tip */
        },
        onMarkerOver: function () {
        },
        onMarkerClick: function () {
        },
        containerStyle: {
          width: '100%',
          height: '100%',
        },
        backgroundColor: 'transparent',
        markers: dataForMap && dataForMap.length ? dataForMap : [],
        series: {
          markers: [
            {
              attribute: 'fill',
              scale: {
                green: '#4BBF73',
                red: '#ff0000',
              },
              values: Object.assign({}, seriesData),
            },
          ],
        },
      }
      setMapConfig(option)
    }
  }, [filterData, selectedFilterDataIndex, selectedHourIndex])

  const findValueByKey = (value) => {
    if (REGIONS_NAME.hasOwnProperty(value)) {
      return REGIONS_NAME[value]
    }
  }

  const pingIssue = (siteOutageData) => {
    if (siteOutageData && siteOutageData?.length) {
      const errorTime = []
      siteOutageData.forEach((data) => {
        let isError = false
        let regionsName = []
        if (data && data?.values && data?.values?.length) {
          data?.values.forEach((site) => {
            if (site?.status !== '200') {
              isError = true
              let regionN = findValueByKey(site?.region)
              regionsName.push(regionN)
            }
          })
        }
        if (isError) {
          errorTime.push({ hour: data?.hour, regionsName })
        }
      })

      setPingTime(errorTime)
    }
  }

  const checkTimelineDifferenceForLeft = (hour) => {
    const hourIndex = cronranTime?.indexOf(parseInt(hour))
    const difference = cronranTime?.[hourIndex] - cronranTime?.[hourIndex - 1]
   
    return cronranTime?.indexOf(parseInt(hour)) === 0 || difference > 1 ? true : false
  }

  const checkTimelineDifferenceForRight = (hour) => {
    const hourIndex = cronranTime?.indexOf(parseInt(hour))
    const difference = cronranTime?.[hourIndex + 1] - cronranTime?.[hourIndex]
   
    return cronranTime?.indexOf(parseInt(hour)) === (cronranTime?.length - 1) || difference > 1 ? true : false
  }

  const setBorderForSelectedHour = (index) => {
    const sortedFilteredData = _.sortBy(filterData?.[selectedFilterDataIndex]?.dataset, 'hour')

    const has200All = _.every(sortedFilteredData?.[index]?.values, {
      status: '200',
    })
    
    return !!(has200All);

  }

  const setBorderForSelecteDate = (dataset) => {
    const hasIssues = dataset?.map(obj => {
      const isNoOutage = obj?.values?.every(item => item?.status === '200')
      return !!isNoOutage
    })
   
    if(hasIssues.includes(false)) {
      return '#ff8400'
    } else {
      return '#4BBF73'
    }

  }

  const showIssueAlert = () => {
    const sortedFilteredData = _.sortBy(filterData?.[selectedFilterDataIndex]?.dataset, 'hour')
    const filterSelectedPingIssue = pingTime?.length && pingTime?.filter(pingData => pingData?.hour === sortedFilteredData?.[selectedHourIndex]?.hour) 

    if (filterSelectedPingIssue && filterSelectedPingIssue?.length) {
      return (
        <Alert
          variant={'danger'}
          className="alert-outline-coloured"
          key={'meta-title'}
        >
          <div className="alert-icon"> </div>
          <div className="alert-message">
            <div style={{ display: 'flex', marginBottom: 3 }}>
              <div className="ping-error-time">
                {' '}
                <b className='f-bold'>{filterSelectedPingIssue?.[0]?.hour}00</b>{' '}
              </div>
              <div>
                <span className='alert-for-mobile'>
                  {' '}
                  Site Outage in{' '}
                  {filterSelectedPingIssue?.[0]?.regionsName?.length &&
                    filterSelectedPingIssue?.[0]?.regionsName.map((region) => {
                      return (
                        <>
                          {' '}
                          <b className='f-bold'>{region}</b>{' '}
                        </>
                      )
                    })}
                </span>
              </div>
            </div>
          </div>
        </Alert>
      )
    } else {
      return (
        <Alert variant={'success'} className="alert-outline-coloured" key={'meta-title'}>
          <div className="alert-icon"> </div>
          <div className="alert-message">
            {' '}
            <span className='alert-for-mobile'>No issues found.</span>{' '}
          </div>
      </Alert>
      )
    }
  }
  
  return (
      !loading ? (
          <Card className='mb-0'> 
            <CardBody>
              <Row>
                <Col>
                  <h3 className='f-bold'>Outage Overview</h3>
                  <p>
                    {filterData && filterData?.length && filterData?.[selectedFilterDataIndex]?.date
                      ? moment(filterData[selectedFilterDataIndex].date, 'DD MM YYYY').format('DD MMM, YYYY')
                      : 'No Data Found Please check in an hour'}{' '}
                  </p>
                </Col>
                <Col>
                  {finalData && finalData.length ? (
                    <Button
                      color="secondary"
                      className={'ml-2'}
                      onClick={() => {
                        setFinalData([])
                      }}
                    >
                      Reset
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row className='site-outage-time'>
                <Col>
                  {filterData && filterData?.length ? (
                    filterData.map((item, index) => {
                      return (
                          <span
                            onClick={() => {
                              setSelectedFilterDataIndex(index)
                              setSelectedHourIndex(0)
                            }}
                            id={`TooltipExample-${index}`}
                            className={`curosr-pointer day ${
                              index === selectedFilterDataIndex ? 'active' : ''
                            }`}
                            style={{
                              display: 'inline-block',
                              margin: 5,
                              height: 40,
                              width: 40,
                              borderRadius: 20,
                              background: `${setBorderForSelecteDate(item?.dataset)}`,
                              position: 'relative',
                            }}
                          >
                            <span
                              className={`site-otage-date ${
                                timeStreakBackgroundColor === 'yellow' ? 'f-black' : 'f-white'
                              }`}
                            >
                              {moment(item.date, 'DD MM YYYY').format('DD')}
                            </span>
                            &nbsp;
                          </span>
                      )
                    })
                  ) : null }
                  <hr />
                  <div className='site-outage-hour'>
                    <ul className={'time-container'}>
                      {timeline && timeline?.length ?
                        timeline?.map((hour, index) => {
                          return (
                            <li
                              key={`hour-${index}`}
                              className={`cursor-pointer minor-stick ${
                                index === selectedHourIndex && cronranTime?.includes(parseInt(hour)) ? 'active' : ''
                              }`}
                              style={{
                                background: `${cronranTime?.includes(parseInt(hour)) ? setBorderForSelectedHour(index) ? '#9fdbc1' : '#ebb65c' : '#EAEAEC'}`,
                                borderTop: `${cronranTime?.includes(parseInt(hour)) ? setBorderForSelectedHour(index) ? '3px solid #4BBF73' : '3px solid #ff8400' : '#EAEAEC'}`,
                                borderBottom: `${cronranTime?.includes(parseInt(hour)) ? setBorderForSelectedHour(index) ? '3px solid #4BBF73' : '3px solid #ff8400' : '#EAEAEC'}`,
                                borderRight:  `${cronranTime?.includes(parseInt(hour)) && checkTimelineDifferenceForRight(parseInt(hour)) ? setBorderForSelectedHour(index) ? '3px solid #4BBF73' : '3px solid #ff8400' : '#EAEAEC'}`,
                                borderLeft:  `${cronranTime?.includes(parseInt(hour)) && checkTimelineDifferenceForLeft(parseInt(hour)) ? setBorderForSelectedHour(index)  ? '3px solid #4BBF73' : '3px solid #ff8400' : '#EAEAEC'}`,
                              }}
                              onClick={() => {
                                if(cronranTime?.includes(parseInt(hour))){
                                  setSelectedHourIndex(index)
                                }
                              }}
                            >
                              <span>{Number(hour) < 10 ? `${hour}` : hour}00</span>
                            </li>)
                        }) : null
                      }
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={9}>
                  {loading ? (
                      <Spinner color="dark" className="ml-2 mr-2" />
                  ) : null }

                  {finalData && finalData.length ? (
                      <div className="map" style={{ borderRadius: '30px', marginTop: 20 }}>
                        <div className="map-container siteOutage-map" style={{ height: 500, marginTop: 60 }}>
                          {mapConfig ? <VectorMap {...mapConfig} /> : ''}
                        </div>
                      </div>
                  ) : (
                      <div className="map" style={{ borderRadius: '30px', marginTop: 20 }}>
                        <div className="map-container siteOutage-map" style={{ height: 500, marginTop: 60 }}>
                          {mapConfig ? <VectorMap {...mapConfig} /> : ''}
                        </div>
                        {mapToolTipcontent ? (
                            <div
                              className="tooltip-map"
                              style={{
                                left: mapToolTipPosition?.x,
                                top: mapToolTipPosition?.y,
                                position: 'absolute',
                              }}
                            >
                              {mapToolTipcontent}
                            </div>
                        ) : null }
                      </div>
                  )}
                </Col>

                <Col sm={3} className='site-outage-issues'>
                  <Card>
                    <CardBody>
                      <h4 className="f-bold">Ping Issues</h4>
                      <hr />
                      <br />
                      {showIssueAlert()}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
      ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              alignSelf: 'center',
              flexDirection: 'row',
              minHeight: 380,
            }}
          >
            <div className="text-center">
              <Loader />
            </div>
          </div>
      )
  )
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AddWebsiteMain from './AddWebsiteMain'
import paths from 'constants/paths'
import AddWebsite from 'assets/img/pms/addWebsite.png'
import 'modules/welcomeBoard/components/addWebsite/addWebesiteTrial.scss'

const AddWebsiteTrial = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isTrialFlow = pathname.includes('trial')
  useEffect(() => {
    const redirectPage = async () => {
      const data = await localStorage.getItem('userData')

      if (
        JSON.parse(data)?.subscriptions?.length &&
        (JSON.parse(data)?.subscriptions?.[0]?.isActive || JSON.parse(data)?.subscriptions?.[0]?.isTrial)
      ) {
        if (JSON.parse(data)?.websites?.length) {
          navigate(paths.mainDashboard)
        }
      } else {
        navigate(paths.newUserSubscriptionPage)
      }
    }
    redirectPage()
  }, [])

  return (
    <>
      <div className="add-website">
        <AddWebsiteMain callFrom={'trial'} />
      </div>
      {isTrialFlow && (
        <img src={AddWebsite} className="add-website-img-mobile" alt="Add Website" width={100} height={100} />
      )}
    </>
  )
}

export default AddWebsiteTrial

import { routes } from './routeNames'

export const staticData = {
  description: {
    description:
      'Enjoy the videos and music you love, upload original content, and share it all with friends, family, and the world on YouTube.',
    descriptionLength: 126,
    descriptionCheck: true,
  },
  title: {
    title: 'YouTube',
    titleLength: 7,
    titleCheck: true,
  },
  topKeywords: [
    'Skip',
    'navigation',
    'Sign',
    'Search',
    'Home',
    'Trending',
    'History',
    'YouTube',
    'Premium',
    'Music',
    'Sports',
    'Gaming',
    'Movies',
    'News',
    'Live',
    'Fashion',
    'Spotlight',
    'Video',
    'Browse',
    'channels',
    'videos',
    'comment',
    'subscribe',
    'stop',
    'supporting',
    'browser',
    'experience',
    'update',
    'Close',
    'ad',
    'Show',
    'Watch',
    'Queue',
    'Remove',
    'Disconnect',
    'video',
    'starting',
    'Loading',
    'Asian',
    'Topic',
    'Recommended',
    'channel',
    'Full',
    'Song',
    'KHAIRIYAT',
    'BONUS',
    'TRACK',
    'CHHICHHORE',
    'Sushant',
    'Shraddha',
    'Pritam',
    'Amitabh',
    'Singh',
    'Duration',
    'views',
    'months',
    'ago',
    'Tera',
    'Yaar',
    'Hoon',
    'Main',
    'Bollywood',
    'Rahoon',
    'Ya',
    'Na',
    'Emraan',
    'Hashmi',
    'Esha',
    'Gupta',
    'Amaal',
    'Mallik',
    'Armaan',
    'Malik',
    'years',
    'Agar',
    'Tum',
    'Saath',
    'Ho',
    'Maahi',
    'Ve',
    'Mixtape',
    'Jubin',
    'Prakriti',
    'Abhijit',
    'Bhushan',
    'Kumar',
    'Ahmed',
    'Heartless',
    'Badshah',
    'ft',
    'Aastha',
    'Gill',
    'Gurickk',
    'Maan',
    'ALBUM',
    'Sony',
    'India',
    'year',
    'Raavanan',
    'Usure',
    'Pogudhey',
    'AR',
    'Rahman',
    'Vikram',
    'Aishwarya',
    'Rai',
    'SonyMusicSouthVEVO',
    'Dil',
    'Kyun',
    'Yeh',
    'Mera',
    'Shor',
    'Kare',
    'HD',
    'Kites',
    'Hrithik',
    'Roshan',
    'Mori',
    'Makhna',
    'Rajput',
    'Jacqueline',
    'Tanishk',
    'Bagchi',
    'Yasser',
    'Desai',
    'Asees',
    'Kaur',
    'Zee',
    'Company',
    'Lyrical',
    'Aana',
    'Marjaavaan',
    'Riteish',
    'Sidharth',
    'Tara',
    'NautiyalPayal',
    'DevKunaal',
    'VideoTu',
    'Pati',
    'Patni',
    'Aur',
    'Woh',
    'Kartik',
    'ABhumi',
    'PAnanya',
    'RochakArijit',
    'SNeha',
    'Sonu',
    'Ke',
    'Titu',
    'Ki',
    'Sweety',
    'Arijit',
    'Rochak',
    'Kohli',
    'Yaad',
    'Piya',
    'Aane',
    'Lagi',
    'Divya',
    'Khosla',
    'KTanishk',
    'BJaani',
    'Faisu',
    'Radhika',
    'Vinay',
    'Masakali',
    'Delhi',
    'Abhishek',
    'Bachchan',
    'Sonam',
    'Kapoor',
    'Mohit',
    'Chauhan',
    'Tu',
    'AUDIO',
    'Neha',
    'Kakkar',
    'HQ',
    'Rahulz',
    'Mile',
    'Reprise',
    'Version',
    'Tony',
    'Fever',
    'Bhai',
    'Behan',
    'Lockdown',
    'Harsh',
    'Beniwal',
    'day',
    'FILM',
    'FLARE',
    'CarryMinati',
    'days',
    'Instant',
    'Jalebi',
    'Recipe',
    'Kabita',
    'Kitchen',
    'suji',
    'puri',
    'golgappa',
    'aur',
    'meetha',
    'teeka',
    'pani',
    'recipe',
    'cookingshooking',
    'CookingShooking',
    'Hindi',
    'Rap',
    'Ft',
    'INDIAN',
    'YOUTUBERS',
    'Kunal',
    'Chhabhria',
    'Anmol',
    'Sachar',
    'Ashish',
    'Chanchlani',
    'ashish',
    'chanchlani',
    'vines',
    'Favorite',
    'Character',
    'Shreya',
    'Behaves',
    'Suspicious',
    'CID',
    'Episode',
    'SET',
    'Yeast',
    'Fermentation',
    'Bread',
    'MadhurasRecipe',
    'Marathi',
    'ACP',
    'Daya',
    'Missing',
    'Sweet',
    'Nirmla',
    'Nehra',
    'Husband',
    'Wife',
    'Priya',
    'Bapat',
    'Umesh',
    'Kamat',
    'Bharatiya',
    'Digital',
    'Party',
    'hours',
    'Babbu',
    'Praise',
    'Rough',
    'Track',
    'EASTER',
    'Veetu',
    'Virundhu',
    'Feast',
    'kids',
    'Family',
    'DAN',
    'JR',
    'VLOGS',
    'Controversial',
    'Jokes',
    'Comedy',
    'Abijit',
    'Ganguly',
    'Aaj',
    'Tak',
    'Streamed',
    'Flying',
    'Beast',
    'Flew',
    'ISTANBUL',
    'Aircraft',
    'Pilot',
    'VVIP',
    'Flight',
    'Finally',
    'Test',
    'Results',
    'TEST',
    'Red',
    'Zone',
    'Action',
    'Game',
    'UNDERDOG',
    'CHAMP',
    'Classic',
    'Tetris',
    'World',
    'Championship',
    'Final',
    'Round',
    'left',
    'Pubg',
    'Mobile',
    'OnePlus',
    'MortaL',
    'DYNAMO',
    'GAMEXPRO',
    'CLAN',
    'AWM',
    'HARDEST',
    'GAMEPLAY',
    'PUBG',
    'MOBILE',
    'STRIKER',
    'YT',
    'Striker',
    'weeks',
    'LETS',
    'GAME',
    'GTA',
    'Mumbiker',
    'Nikhil',
    'Reason',
    'King',
    'Tacaz',
    'Crafts',
    'make',
    'Wooden',
    'Katana',
    'hardwood',
    'flooring',
    'Woodworking',
    'Make',
    'Stuff',
    'Woodturning',
    'Coffee',
    'Mug',
    'Matt',
    'Jordan',
    'AMAZING',
    'DIY',
    'IDEAS',
    'CEMENT',
    'BALLESTA',
    'CASERA',
    'de',
    'MADERA',
    'PVC',
    'ARTESANAL',
    'TALLADA',
    'PIROGRABADA',
    'Elias',
    'Maximiliano',
    'Walnut',
    'Root',
    'Mind',
    'Made',
    'Extraction',
    'Chris',
    'Hemsworth',
    'Official',
    'Trailer',
    'Netflix',
    'week',
    'Goldmines',
    'Telefilms',
    'month',
    'Sarrainodu',
    'Ultra',
    'Dubbed',
    'Movie',
    'Allu',
    'Arjun',
    'Rakul',
    'Preet',
    'Catherine',
    'Aadhi',
    'Dear',
    'Comrade',
    'Released',
    'Vijay',
    'Devarakonda',
    'Rashmika',
    'Shruti',
    'Dishoom',
    'Parugu',
    'Dashing',
    'Detective',
    'Thupparivaalan',
    'Vishal',
    'Prasanna',
    'Super',
    'Khiladi',
    'Nenu',
    'Sailaja',
    'Telugu',
    'Ram',
    'Pothineni',
    'Keerthy',
    'Suresh',
    'Maari',
    'Great',
    'Indian',
    'Pogaru',
    'Krishna',
    'Reema',
    'Sen',
    'Sri',
    'Balaji',
    'SriBalajiMovies',
    'Yevadu',
    'Charan',
    'Hassan',
    'Kajal',
    'Aggarwal',
    'Amy',
    'Jackson',
    'Latest',
    'Kurukshetram',
    'ULTRA',
    'iDream',
    'Antony',
    'Roshagadu',
    'Nivetha',
    'Thimiru',
    'Pudichavan',
    'Filmnagar',
    'MASTERPIECE',
    'dubbed',
    'English',
    'Subtitle',
    'shreeinternational',
    'Tamil',
    'Wanted',
    'Baaghi',
    'Asin',
    'Prakash',
    'Raj',
    'Girls',
    'Marry',
    'Boys',
    'Based',
    'Kapil',
    'Sharma',
    'August',
    'Friendship',
    'Ravan',
    'Season',
    'Sat',
    'Sun',
    'PM',
    'Bachha',
    'Yadav',
    'Hilarious',
    'Steps',
    'Hazel',
    'Sep',
    'Aug',
    'Funny',
    'Celebrity',
    'moments',
    'DrMashoor',
    'Gulati',
    'Ae',
    'Hai',
    'Mushkil',
    'Kapils',
    'Oct',
    'Alia',
    'Mar',
    'Ep',
    'Durghatana',
    'Aparadh',
    'Apr',
    'masti',
    'July',
    'June',
    'Rinku',
    'Devi',
    'Santosh',
    'Woo',
    'Heroes',
    'January',
    'Sairat',
    'Load',
    'unable',
    'complete',
    'request',
    'Language',
    'Location',
    'Restricted',
    'Mode',
    'Press',
    'Copyright',
    'Creators',
    'Advertise',
    'Developers',
    'Terms',
    'Privacy',
    'Policy',
    'Safety',
    'Send',
    'feedback',
    'features',
    'Google',
    'LLC',
    'Working',
    'add',
    'Add',
    'playlists',
  ],
  competitors: [
    'wikia.com',
    'dailymotion.com',
    'reference.com',
    'dailymail.co.uk',
    'imdb.com',
    'facebook.com',
    'webmd.com',
    'linkedin.com',
    'pinterest.com',
    'healthline.com',
    'ebay.com',
    'wikipedia.org',
    'amazon.ca',
    'amazon.com',
    'wikihow.com',
    'en.wikipedia.org',
    'walmart.com',
    'wordpress.com',
    'google.com',
  ],
  h1Tag: [],
  metaKeywords: ['video', 'sharing', 'camera phone', 'video phone', 'free', 'upload'],
  commonKeywords: [
    {
      word: 'duration',
      count: 325,
    },
    {
      word: 'ago',
      count: 260,
    },
    {
      word: 'views',
      count: 260,
    },
    {
      word: 'month',
      count: 84,
    },
    {
      word: 'months',
      count: 72,
    },
    {
      word: 'year',
      count: 44,
    },
    {
      word: 'week',
      count: 40,
    },
    {
      word: 'recipe',
      count: 39,
    },
    {
      word: 'days',
      count: 36,
    },
    {
      word: 'end',
      count: 36,
    },
  ],
  h1: '',
  h2: '\n      Home\n    TrendingCOVID-19 news\n  \n COVID-19Featured\n  \n Asian Music - Topic Recommended channel\n  \n Recipes - Topic Recommended channel\n  \n Flying Beast Recommended channel\n  \n Architecture - Topic Recommended channel\n  \n Action Game - Topic Recommended channel',
  keywordCloud: [
    {
      word: 'home',
      count: 24,
    },
    {
      word: 'trendingcovid',
      count: 1,
    },
    {
      word: 'news',
      count: 9,
    },
    {
      word: 'covidfeatured',
      count: 0,
    },
    {
      word: 'asian',
      count: 4,
    },
    {
      word: 'music',
      count: 21,
    },
    {
      word: 'topicrecommended',
      count: 16,
    },
    {
      word: 'channel',
      count: 32,
    },
    {
      word: 'recipes',
      count: 12,
    },
    {
      word: 'flying',
      count: 13,
    },
    {
      word: 'beastrecommended',
      count: 4,
    },
    {
      word: 'architecture',
      count: 4,
    },
    {
      word: 'action',
      count: 4,
    },
    {
      word: 'game',
      count: 16,
    },
    {
      word: 'youtube',
      count: 11,
    },
    {
      word: 'watch',
      count: 2,
    },
    {
      word: 'queuequeuethe',
      count: 1,
    },
    {
      word: 'lockdown',
      count: 20,
    },
    {
      word: 'rap',
      count: 4,
    },
    {
      word: 'indian',
      count: 12,
    },
    {
      word: 'youtubers',
      count: 4,
    },
    {
      word: 'kunal',
      count: 4,
    },
    {
      word: 'chhabhria',
      count: 4,
    },
    {
      word: 'anmol',
      count: 4,
    },
    {
      word: 'sachar',
      count: 4,
    },
    {
      word: 'ashish',
      count: 7,
    },
    {
      word: 'chanchlani',
      count: 7,
    },
    {
      word: 'duration',
      count: 325,
    },
    {
      word: 'husband',
      count: 8,
    },
    {
      word: 'wife',
      count: 8,
    },
    {
      word: 'priya',
      count: 4,
    },
    {
      word: 'bapat',
      count: 4,
    },
    {
      word: 'umesh',
      count: 4,
    },
    {
      word: 'kamat',
      count: 4,
    },
    {
      word: 'bhadipa',
      count: 4,
    },
    {
      word: 'film',
      count: 4,
    },
    {
      word: 'flare',
      count: 4,
    },
    {
      word: 'babbu',
      count: 7,
    },
    {
      word: 'maan',
      count: 11,
    },
    {
      word: 'praise',
      count: 4,
    },
    {
      word: 'rough',
      count: 4,
    },
    {
      word: 'track',
      count: 8,
    },
    {
      word: 'instant',
      count: 4,
    },
    {
      word: 'jalebi',
      count: 4,
    },
    {
      word: 'recipe',
      count: 39,
    },
    {
      word: 'yeast',
      count: 4,
    },
    {
      word: 'fermentation',
      count: 4,
    },
    {
      word: 'bread',
      count: 12,
    },
    {
      word: 'suji',
      count: 4,
    },
    {
      word: 'puri',
      count: 4,
    },
    {
      word: 'golgappa',
      count: 4,
    },
    {
      word: 'aur',
      count: 28,
    },
    {
      word: 'meetha',
      count: 4,
    },
    {
      word: 'teeka',
      count: 4,
    },
    {
      word: 'pani',
      count: 4,
    },
    {
      word: 'cookingshooking',
      count: 14,
    },
    {
      word: 'favorite',
      count: 8,
    },
    {
      word: 'character',
      count: 8,
    },
    {
      word: 'shreya',
      count: 8,
    },
    {
      word: 'behaves',
      count: 4,
    },
    {
      word: 'suspicious',
      count: 4,
    },
    {
      word: 'cid',
      count: 8,
    },
    {
      word: 'full',
      count: 32,
    },
    {
      word: 'episode',
      count: 12,
    },
    {
      word: 'acp',
      count: 4,
    },
    {
      word: 'daya',
      count: 4,
    },
    {
      word: 'missing',
      count: 4,
    },
    {
      word: 'easter',
      count: 4,
    },
    {
      word: 'veetu',
      count: 4,
    },
    {
      word: 'virundhu',
      count: 8,
    },
    {
      word: 'feast',
      count: 4,
    },
    {
      word: 'kids',
      count: 4,
    },
    {
      word: 'family',
      count: 16,
    },
    {
      word: 'ishqiya',
      count: 4,
    },
    {
      word: 'april',
      count: 4,
    },
    {
      word: 'ary',
      count: 11,
    },
    {
      word: 'digital',
      count: 10,
    },
    {
      word: 'drama',
      count: 4,
    },
    {
      word: 'non',
      count: 4,
    },
    {
      word: 'controversial',
      count: 4,
    },
    {
      word: 'jokes',
      count: 4,
    },
    {
      word: 'standup',
      count: 0,
    },
    {
      word: 'comedy',
      count: 4,
    },
    {
      word: 'abijit',
      count: 7,
    },
    {
      word: 'ganguly',
      count: 7,
    },
    {
      word: 'easy',
      count: 7,
    },
    {
      word: 'sweet',
      count: 8,
    },
    {
      word: 'coronaupdate',
      count: 4,
    },
    {
      word: 'china',
      count: 4,
    },
    {
      word: 'lab',
      count: 4,
    },
    {
      word: 'america',
      count: 4,
    },
    {
      word: 'origin',
      count: 4,
    },
    {
      word: 'coronavirus',
      count: 11,
    },
    {
      word: 'social',
      count: 4,
    },
    {
      word: 'distancing',
      count: 4,
    },
    {
      word: 'defeat',
      count: 4,
    },
    {
      word: 'menace',
      count: 4,
    },
    {
      word: 'together',
      count: 8,
    },
    {
      word: 'fight',
      count: 12,
    },
    {
      word: 'covid',
      count: 22,
    },
    {
      word: 'precautions',
      count: 8,
    },
    {
      word: 'protect',
      count: 8,
    },
    {
      word: 'loved',
      count: 8,
    },
    {
      word: 'know',
      count: 4,
    },
    {
      word: 'practise',
      count: 4,
    },
    {
      word: 'quarantine',
      count: 4,
    },
    {
      word: 'song',
      count: 20,
    },
    {
      word: 'khairiyat',
      count: 8,
    },
    {
      word: 'bonus',
      count: 4,
    },
    {
      word: 'chhichhore',
      count: 8,
    },
    {
      word: 'sushant',
      count: 12,
    },
    {
      word: 'shraddha',
      count: 8,
    },
    {
      word: 'pritam',
      count: 12,
    },
    {
      word: 'amitabh',
      count: 8,
    },
    {
      word: 'barijit',
      count: 0,
    },
    {
      word: 'singh',
      count: 20,
    },
    {
      word: 'main',
      count: 12,
    },
    {
      word: 'rahoon',
      count: 8,
    },
    {
      word: 'video',
      count: 27,
    },
    {
      word: 'emraan',
      count: 4,
    },
    {
      word: 'hashmi',
      count: 4,
    },
    {
      word: 'esha',
      count: 4,
    },
    {
      word: 'gupta',
      count: 4,
    },
    {
      word: 'amaal',
      count: 4,
    },
    {
      word: 'mallik',
      count: 4,
    },
    {
      word: 'armaan',
      count: 4,
    },
    {
      word: 'malik',
      count: 4,
    },
    {
      word: 'yaar',
      count: 16,
    },
    {
      word: 'mera',
      count: 12,
    },
    {
      word: 'audio',
      count: 4,
    },
    {
      word: 'pati',
      count: 8,
    },
    {
      word: 'patni',
      count: 8,
    },
    {
      word: 'woh',
      count: 8,
    },
    {
      word: 'rochak',
      count: 12,
    },
    {
      word: 'arijit',
      count: 20,
    },
    {
      word: 'neha',
      count: 12,
    },
    {
      word: 'kakkar',
      count: 4,
    },
    {
      word: 'tera',
      count: 8,
    },
    {
      word: 'hoon',
      count: 16,
    },
    {
      word: 'agar',
      count: 4,
    },
    {
      word: 'tum',
      count: 4,
    },
    {
      word: 'saath',
      count: 4,
    },
    {
      word: 'maahi',
      count: 4,
    },
    {
      word: 'tseries',
      count: 0,
    },
    {
      word: 'mixtape',
      count: 4,
    },
    {
      word: 'jubin',
      count: 4,
    },
    {
      word: 'prakriti',
      count: 4,
    },
    {
      word: 'abhijit',
      count: 4,
    },
    {
      word: 'bhushan',
      count: 8,
    },
    {
      word: 'kumar',
      count: 8,
    },
    {
      word: 'ahmed',
      count: 4,
    },
    {
      word: 'makhna',
      count: 4,
    },
    {
      word: 'drive',
      count: 4,
    },
    {
      word: 'rajput',
      count: 4,
    },
    {
      word: 'jacqueline',
      count: 4,
    },
    {
      word: 'fernandez',
      count: 4,
    },
    {
      word: 'tanishk',
      count: 8,
    },
    {
      word: 'bagchi',
      count: 4,
    },
    {
      word: 'yasser',
      count: 4,
    },
    {
      word: 'desai',
      count: 4,
    },
    {
      word: 'asees',
      count: 4,
    },
    {
      word: 'kaur',
      count: 4,
    },
    {
      word: 'dil',
      count: 4,
    },
    {
      word: 'kyun',
      count: 4,
    },
    {
      word: 'yeh',
      count: 4,
    },
    {
      word: 'shor',
      count: 4,
    },
    {
      word: 'kare',
      count: 4,
    },
    {
      word: 'kites',
      count: 4,
    },
    {
      word: 'hrithik',
      count: 4,
    },
    {
      word: 'roshan',
      count: 4,
    },
    {
      word: 'brbara',
      count: 4,
    },
    {
      word: 'mori',
      count: 4,
    },
    {
      word: 'sonu',
      count: 4,
    },
    {
      word: 'titu',
      count: 4,
    },
    {
      word: 'sweety',
      count: 4,
    },
    {
      word: 'kohli',
      count: 4,
    },
    {
      word: 'videotu',
      count: 0,
    },
    {
      word: 'kartik',
      count: 4,
    },
    {
      word: 'abhumi',
      count: 0,
    },
    {
      word: 'pananya',
      count: 0,
    },
    {
      word: 'rochakarijit',
      count: 0,
    },
    {
      word: 'sneha',
      count: 0,
    },
    {
      word: 'lyrical',
      count: 4,
    },
    {
      word: 'nitesh',
      count: 4,
    },
    {
      word: 'tiwari',
      count: 4,
    },
    {
      word: 'raavanan',
      count: 4,
    },
    {
      word: 'usure',
      count: 4,
    },
    {
      word: 'pogudhey',
      count: 4,
    },
    {
      word: 'rahman',
      count: 8,
    },
    {
      word: 'vikram',
      count: 4,
    },
    {
      word: 'aishwarya',
      count: 4,
    },
    {
      word: 'rai',
      count: 12,
    },
    {
      word: 'yaad',
      count: 4,
    },
    {
      word: 'piya',
      count: 4,
    },
    {
      word: 'aane',
      count: 4,
    },
    {
      word: 'lagi',
      count: 4,
    },
    {
      word: 'divya',
      count: 4,
    },
    {
      word: 'khosla',
      count: 4,
    },
    {
      word: 'ktanishk',
      count: 0,
    },
    {
      word: 'bjaani',
      count: 0,
    },
    {
      word: 'faisu',
      count: 4,
    },
    {
      word: 'radhikavinay',
      count: 0,
    },
    {
      word: 'ghar',
      count: 4,
    },
    {
      word: 'pardesiya',
      count: 4,
    },
    {
      word: 'kalank',
      count: 4,
    },
    {
      word: 'varun',
      count: 4,
    },
    {
      word: 'alia',
      count: 4,
    },
    {
      word: 'madhuri',
      count: 4,
    },
    {
      word: 'vaishali',
      count: 4,
    },
    {
      word: 'nama',
      count: 4,
    },
    {
      word: 'shivaya',
      count: 4,
    },
    {
      word: 'lord',
      count: 4,
    },
    {
      word: 'shiva',
      count: 8,
    },
    {
      word: 'songs',
      count: 4,
    },
    {
      word: 'balasubrahmanyam',
      count: 4,
    },
    {
      word: 'laung',
      count: 4,
    },
    {
      word: 'laachi',
      count: 4,
    },
    {
      word: 'title',
      count: 4,
    },
    {
      word: 'mannat',
      count: 4,
    },
    {
      word: 'noor',
      count: 4,
    },
    {
      word: 'ammy',
      count: 4,
    },
    {
      word: 'virk',
      count: 4,
    },
    {
      word: 'neeru',
      count: 4,
    },
    {
      word: 'bajwaamberdeep',
      count: 0,
    },
    {
      word: 'latest',
      count: 6,
    },
    {
      word: 'punjabi',
      count: 4,
    },
    {
      word: 'movie',
      count: 9,
    },
    {
      word: 'choco',
      count: 8,
    },
    {
      word: 'lava',
      count: 4,
    },
    {
      word: 'cup',
      count: 4,
    },
    {
      word: 'cake',
      count: 8,
    },
    {
      word: 'ingredients',
      count: 8,
    },
    {
      word: 'egg',
      count: 4,
    },
    {
      word: 'oven',
      count: 12,
    },
    {
      word: 'soda',
      count: 4,
    },
    {
      word: 'honey',
      count: 4,
    },
    {
      word: 'chilli',
      count: 8,
    },
    {
      word: 'potato',
      count: 4,
    },
    {
      word: 'crispy',
      count: 4,
    },
    {
      word: 'restaurant',
      count: 12,
    },
    {
      word: 'style',
      count: 4,
    },
    {
      word: 'starters',
      count: 4,
    },
    {
      word: 'gordon',
      count: 18,
    },
    {
      word: 'blown',
      count: 4,
    },
    {
      word: 'small',
      count: 4,
    },
    {
      word: 'restaurants',
      count: 4,
    },
    {
      word: 'performance',
      count: 4,
    },
    {
      word: 'food',
      count: 23,
    },
    {
      word: 'ramsays',
      count: 0,
    },
    {
      word: 'maida',
      count: 4,
    },
    {
      word: 'cheesy',
      count: 4,
    },
    {
      word: 'garlic',
      count: 8,
    },
    {
      word: 'wheat',
      count: 4,
    },
    {
      word: 'terrace',
      count: 7,
    },
    {
      word: 'kitchen',
      count: 22,
    },
    {
      word: 'bucket',
      count: 8,
    },
    {
      word: 'chicken',
      count: 12,
    },
    {
      word: 'yummy',
      count: 4,
    },
    {
      word: 'grilled',
      count: 4,
    },
    {
      word: 'village',
      count: 7,
    },
    {
      word: 'street',
      count: 8,
    },
    {
      word: 'varanasi',
      count: 4,
    },
    {
      word: 'vegetarian',
      count: 4,
    },
    {
      word: 'chaat',
      count: 4,
    },
    {
      word: 'heaven',
      count: 4,
    },
    {
      word: 'holy',
      count: 4,
    },
    {
      word: 'benares',
      count: 4,
    },
    {
      word: 'india',
      count: 24,
    },
    {
      word: 'made',
      count: 4,
    },
    {
      word: 'ingredient',
      count: 12,
    },
    {
      word: 'tasty',
      count: 7,
    },
    {
      word: 'unique',
      count: 4,
    },
    {
      word: 'types',
      count: 4,
    },
    {
      word: 'maggi',
      count: 20,
    },
    {
      word: 'biryani',
      count: 4,
    },
    {
      word: 'chinese',
      count: 4,
    },
    {
      word: 'cheese',
      count: 4,
    },
    {
      word: 'gordons',
      count: 0,
    },
    {
      word: 'quick',
      count: 4,
    },
    {
      word: 'simple',
      count: 4,
    },
    {
      word: 'ramsay',
      count: 14,
    },
    {
      word: 'chole',
      count: 4,
    },
    {
      word: 'levels',
      count: 4,
    },
    {
      word: 'fried',
      count: 4,
    },
    {
      word: 'rice',
      count: 4,
    },
    {
      word: 'amateur',
      count: 4,
    },
    {
      word: 'scientist',
      count: 4,
    },
    {
      word: 'epicurious',
      count: 7,
    },
    {
      word: 'items',
      count: 4,
    },
    {
      word: 'leaf',
      count: 4,
    },
    {
      word: 'aadhirai',
      count: 4,
    },
    {
      word: 'adhiradi',
      count: 4,
    },
    {
      word: 'aloo',
      count: 4,
    },
    {
      word: 'snacks',
      count: 4,
    },
    {
      word: 'kulfi',
      count: 4,
    },
    {
      word: 'icecream',
      count: 0,
    },
    {
      word: 'creamchocolate',
      count: 0,
    },
    {
      word: 'cooker',
      count: 4,
    },
    {
      word: 'pilot',
      count: 4,
    },
    {
      word: 'flew',
      count: 8,
    },
    {
      word: 'she',
      count: 7,
    },
    {
      word: 'istanbul',
      count: 4,
    },
    {
      word: 'aircraft',
      count: 4,
    },
    {
      word: 'finally',
      count: 8,
    },
    {
      word: 'test',
      count: 11,
    },
    {
      word: 'results',
      count: 4,
    },
    {
      word: 'architects',
      count: 4,
    },
    {
      word: 'micro',
      count: 4,
    },
    {
      word: 'studio',
      count: 4,
    },
    {
      word: 'apartment',
      count: 4,
    },
    {
      word: 'inside',
      count: 4,
    },
    {
      word: 'bel',
      count: 4,
    },
    {
      word: 'air',
      count: 16,
    },
    {
      word: 'mansion',
      count: 4,
    },
    {
      word: 'hidden',
      count: 4,
    },
    {
      word: 'car',
      count: 7,
    },
    {
      word: 'elevator',
      count: 4,
    },
    {
      word: 'market',
      count: 4,
    },
    {
      word: 'architectural',
      count: 7,
    },
    {
      word: 'digest',
      count: 7,
    },
    {
      word: 'couple',
      count: 4,
    },
    {
      word: 'builds',
      count: 4,
    },
    {
      word: 'shipping',
      count: 4,
    },
    {
      word: 'container',
      count: 4,
    },
    {
      word: 'experience',
      count: 4,
    },
    {
      word: 'ultra',
      count: 4,
    },
    {
      word: 'modern',
      count: 4,
    },
    {
      word: 'tiny',
      count: 10,
    },
    {
      word: 'house',
      count: 10,
    },
    {
      word: 'blow',
      count: 8,
    },
    {
      word: 'mind',
      count: 4,
    },
    {
      word: 'left',
      count: 4,
    },
    {
      word: 'pubg',
      count: 24,
    },
    {
      word: 'mobile',
      count: 24,
    },
    {
      word: 'oneplus',
      count: 4,
    },
    {
      word: 'underdog',
      count: 4,
    },
    {
      word: 'time',
      count: 4,
    },
    {
      word: 'champ',
      count: 8,
    },
    {
      word: 'classic',
      count: 11,
    },
    {
      word: 'tetris',
      count: 7,
    },
    {
      word: 'world',
      count: 4,
    },
    {
      word: 'championship',
      count: 4,
    },
    {
      word: 'final',
      count: 12,
    },
    {
      word: 'round',
      count: 4,
    },
    {
      word: 'sea',
      count: 5,
    },
    {
      word: 'thieves',
      count: 4,
    },
    {
      word: 'stars',
      count: 4,
    },
    {
      word: 'thief',
      count: 4,
    },
    {
      word: 'tall',
      count: 4,
    },
    {
      word: 'tale',
      count: 4,
    },
    {
      word: 'found',
      count: 4,
    },
    {
      word: 'gem',
      count: 4,
    },
    {
      word: 'intense',
      count: 4,
    },
    {
      word: 'end',
      count: 36,
    },
    {
      word: 'zone',
      count: 4,
    },
    {
      word: 'dinner',
      count: 4,
    },
    {
      word: 'boombam',
      count: 4,
    },
    {
      word: 'scrims',
      count: 4,
    },
    {
      word: 'classics',
      count: 4,
    },
    {
      word: 'mortalarmy',
      count: 4,
    },
    {
      word: 'playing',
      count: 4,
    },
    {
      word: 'randoms',
      count: 4,
    },
    {
      word: 'live',
      count: 16,
    },
    {
      word: 'chat',
      count: 4,
    },
    {
      word: 'sirf',
      count: 4,
    },
    {
      word: 'ggs',
      count: 0,
    },
    {
      word: 'chalega',
      count: 4,
    },
    {
      word: 'facecam',
      count: 4,
    },
    {
      word: 'hdra',
      count: 4,
    },
    {
      word: 'alpha',
      count: 7,
    },
    {
      word: 'dynamo',
      count: 4,
    },
    {
      word: 'gamexpro',
      count: 4,
    },
    {
      word: 'clan',
      count: 4,
    },
    {
      word: 'awm',
      count: 8,
    },
    {
      word: 'hardest',
      count: 4,
    },
    {
      word: 'gameplay',
      count: 4,
    },
    {
      word: 'striker',
      count: 7,
    },
    {
      word: 'reason',
      count: 4,
    },
    {
      word: 'king',
      count: 33,
    },
    {
      word: 'add',
      count: 9,
    },
    {
      word: 'ininsign',
      count: 1,
    },
    {
      word: 'insearchtrending',
      count: 1,
    },
    {
      word: 'trending',
      count: 11,
    },
    {
      word: 'history',
      count: 6,
    },
    {
      word: 'premium',
      count: 5,
    },
    {
      word: 'sports',
      count: 5,
    },
    {
      word: 'gaming',
      count: 11,
    },
    {
      word: 'movies',
      count: 5,
    },
    {
      word: 'fashion',
      count: 5,
    },
    {
      word: 'spotlight',
      count: 5,
    },
    {
      word: 'browse',
      count: 5,
    },
    {
      word: 'channels',
      count: 5,
    },
    {
      word: 'sign',
      count: 4,
    },
    {
      word: 'inshow',
      count: 1,
    },
    {
      word: 'adwatch',
      count: 1,
    },
    {
      word: 'queuequeue',
      count: 2,
    },
    {
      word: 'remove',
      count: 3,
    },
    {
      word: 'alldisconnect',
      count: 3,
    },
    {
      word: 'alldisconnectthe',
      count: 1,
    },
    {
      word: 'startingstopstoploading',
      count: 1,
    },
    {
      word: 'loading',
      count: 10,
    },
    {
      word: 'load',
      count: 12,
    },
    {
      word: 'language',
      count: 3,
    },
    {
      word: 'englishlanguage',
      count: 1,
    },
    {
      word: 'location',
      count: 3,
    },
    {
      word: 'indialocation',
      count: 1,
    },
    {
      word: 'restricted',
      count: 3,
    },
    {
      word: 'mode',
      count: 7,
    },
    {
      word: 'offrestricted',
      count: 1,
    },
    {
      word: 'historyhelploading',
      count: 1,
    },
    {
      word: 'google',
      count: 2,
    },
    {
      word: 'llcloading',
      count: 1,
    },
    {
      word: 'working',
      count: 1,
    },
    {
      word: 'playlists',
      count: 1,
    },
    {
      word: 'videos',
      count: 1,
    },
    {
      word: 'comment',
      count: 1,
    },
    {
      word: 'subscribe',
      count: 1,
    },
    {
      word: 'alldisconnecttrending',
      count: 1,
    },
    {
      word: 'vines',
      count: 3,
    },
    {
      word: 'views',
      count: 260,
    },
    {
      word: 'days',
      count: 36,
    },
    {
      word: 'ago',
      count: 260,
    },
    {
      word: 'bharatiya',
      count: 3,
    },
    {
      word: 'party',
      count: 3,
    },
    {
      word: 'hours',
      count: 24,
    },
    {
      word: 'carryminati',
      count: 3,
    },
    {
      word: 'kabitas',
      count: 0,
    },
    {
      word: 'madhurasrecipe',
      count: 3,
    },
    {
      word: 'marathi',
      count: 3,
    },
    {
      word: 'hindi',
      count: 9,
    },
    {
      word: 'dan',
      count: 3,
    },
    {
      word: 'vlogs',
      count: 3,
    },
    {
      word: 'aaj',
      count: 3,
    },
    {
      word: 'tak',
      count: 11,
    },
    {
      word: 'viewsstreamed',
      count: 16,
    },
    {
      word: 'nirmla',
      count: 3,
    },
    {
      word: 'nehra',
      count: 3,
    },
    {
      word: 'agotrending',
      count: 1,
    },
    {
      word: 'agocovid',
      count: 1,
    },
    {
      word: 'rajasthan',
      count: 3,
    },
    {
      word: 'patrika',
      count: 3,
    },
    {
      word: 'covidfeaturedget',
      count: 0,
    },
    {
      word: 'info',
      count: 2,
    },
    {
      word: 'mohfw',
      count: 2,
    },
    {
      word: 'ministry',
      count: 12,
    },
    {
      word: 'health',
      count: 12,
    },
    {
      word: 'welfare',
      count: 12,
    },
    {
      word: 'weeks',
      count: 24,
    },
    {
      word: 'month',
      count: 84,
    },
    {
      word: 'months',
      count: 72,
    },
    {
      word: 'years',
      count: 32,
    },
    {
      word: 'just',
      count: 3,
    },
    {
      word: 'rahulz',
      count: 3,
    },
    {
      word: 'bollywood',
      count: 3,
    },
    {
      word: 'zee',
      count: 6,
    },
    {
      word: 'company',
      count: 6,
    },
    {
      word: 'sonymusicsouthvevo',
      count: 3,
    },
    {
      word: 'chikkuchichu',
      count: 0,
    },
    {
      word: 'apna',
      count: 3,
    },
    {
      word: 'punjab',
      count: 7,
    },
    {
      word: 'bristi',
      count: 6,
    },
    {
      word: 'week',
      count: 40,
    },
    {
      word: 'luke',
      count: 3,
    },
    {
      word: 'martin',
      count: 3,
    },
    {
      word: 'bharatzkitchen',
      count: 6,
    },
    {
      word: 'irfans',
      count: 0,
    },
    {
      word: 'tips',
      count: 3,
    },
    {
      word: 'year',
      count: 44,
    },
    {
      word: 'cook',
      count: 21,
    },
    {
      word: 'sarabjit',
      count: 3,
    },
    {
      word: 'beast',
      count: 13,
    },
    {
      word: 'living',
      count: 6,
    },
    {
      word: 'big',
      count: 6,
    },
    {
      word: 'life',
      count: 3,
    },
    {
      word: 'uncontained',
      count: 3,
    },
    {
      word: 'mortal',
      count: 10,
    },
    {
      word: 'ron',
      count: 21,
    },
    {
      word: 'axom',
      count: 3,
    },
    {
      word: 'shockwve',
      count: 3,
    },
    {
      word: 'clasher',
      count: 3,
    },
    {
      word: 'tacaz',
      count: 3,
    },
    {
      word: 'english',
      count: 2,
    },
    {
      word: 'aboutpresscopyrightcreatorsadvertisedeveloperstermsprivacypolicy',
      count: 1,
    },
    {
      word: 'safety',
      count: 1,
    },
    {
      word: 'send',
      count: 1,
    },
    {
      word: 'feedback',
      count: 1,
    },
    {
      word: 'features',
      count: 1,
    },
    {
      word: 'llc',
      count: 2,
    },
  ],
  domainKeywords: [
    {
      word: 'duration',
      count: 405,
    },
    {
      word: 'ago',
      count: 324,
    },
    {
      word: 'views',
      count: 324,
    },
    {
      word: 'year',
      count: 116,
    },
    {
      word: 'month',
      count: 100,
    },
    {
      word: 'years',
      count: 96,
    },
    {
      word: 'full',
      count: 90,
    },
    {
      word: 'india',
      count: 86,
    },
    {
      word: 'months',
      count: 84,
    },
    {
      word: 'movie',
      count: 55,
    },
  ],
  competitorsKeywords: [
    {
      keywords: [
        {
          word: 'undefined',
        },
        {
          word: 'Explore',
        },
      ],
      name: 'wikia.com',
    },
    {
      keywords: [
        {
          word: 'latest',
          match: false,
        },
        {
          word: 'news',
          match: false,
        },
        {
          word: 'sports',
          match: false,
        },
        {
          word: 'music',
          match: false,
        },
        {
          word: 'entertainment',
          match: false,
        },
        {
          word: 'videos',
          match: false,
        },
        {
          word: 'Dailymotion',
          match: false,
        },
        {
          word: 'home',
          match: false,
        },
        {
          word: 'matter',
          match: false,
        },
      ],
      name: 'dailymotion.com',
    },
    {
      keywords: [
        {
          word: 'Reference',
          match: false,
        },
        {
          word: 'digital',
          match: false,
        },
        {
          word: 'publisher',
          match: false,
        },
        {
          word: 'dedicated',
          match: false,
        },
        {
          word: 'answering',
          match: false,
        },
        {
          word: 'questions',
          match: false,
        },
        {
          word: 'students',
          match: false,
        },
        {
          word: 'lifelong',
          match: false,
        },
        {
          word: 'learners',
          match: false,
        },
        {
          word: 'Referencecom',
          match: false,
        },
        {
          word: 'Question',
          match: false,
        },
      ],
      name: 'reference.com',
    },
    {
      keywords: [
        {
          word: 'MailOnline',
          match: false,
        },
        {
          word: 'latest',
          match: false,
        },
        {
          word: 'breaking',
          match: false,
        },
        {
          word: 'news',
          match: false,
        },
        {
          word: 'showbiz',
          match: false,
        },
        {
          word: 'celebrity',
          match: false,
        },
        {
          word: 'photos',
          match: false,
        },
        {
          word: 'sport',
          match: false,
        },
        {
          word: 'rumours',
          match: false,
        },
        {
          word: 'viral',
          match: false,
        },
        {
          word: 'videos',
          match: false,
        },
        {
          word: 'top',
          match: false,
        },
        {
          word: 'stories',
          match: false,
        },
        {
          word: 'Daily',
          match: false,
        },
        {
          word: 'Mail',
          match: false,
        },
        {
          word: 'Sunday',
          match: false,
        },
        {
          word: 'newspapers',
          match: false,
        },
        {
          word: 'Home',
          match: false,
        },
        {
          word: 'Online',
          match: false,
        },
      ],
      name: 'dailymail.co.uk',
    },
    {
      keywords: [
        {
          word: 'IMDb',
          match: false,
        },
        {
          word: 'worlds',
          match: false,
        },
        {
          word: 'popular',
          match: false,
        },
        {
          word: 'authoritative',
          match: false,
        },
        {
          word: 'source',
          match: false,
        },
        {
          word: 'movie',
          match: false,
        },
        {
          word: 'celebrity',
          match: false,
        },
        {
          word: 'content',
          match: false,
        },
        {
          word: 'Find',
          match: false,
        },
        {
          word: 'ratings',
          match: false,
        },
        {
          word: 'reviews',
          match: false,
        },
        {
          word: 'newest',
          match: false,
        },
        {
          word: 'shows',
          match: false,
        },
        {
          word: 'personalized',
          match: false,
        },
        {
          word: 'recommendations',
          match: false,
        },
        {
          word: 'learn',
          match: false,
        },
        {
          word: 'watch',
          match: false,
        },
        {
          word: 'hundreds',
          match: false,
        },
        {
          word: 'streaming',
          match: false,
        },
        {
          word: 'providers',
          match: false,
        },
        {
          word: 'Ratings',
          match: false,
        },
        {
          word: 'Reviews',
          match: false,
        },
        {
          word: 'Watch',
          match: false,
        },
        {
          word: 'Movies',
          match: false,
        },
        {
          word: 'Shows',
          match: false,
        },
      ],
      name: 'imdb.com',
    },
    {
      keywords: [
        {
          word: 'Facebook',
        },
      ],
      name: 'facebook.com',
    },
    {
      keywords: [
        {
          word: 'undefined',
        },
        {
          word: 'moment',
        },
      ],
      name: 'webmd.com',
    },
    {
      keywords: [
        {
          word: 'million',
          match: false,
        },
        {
          word: 'members',
          match: false,
        },
        {
          word: 'Manage',
          match: false,
        },
        {
          word: 'professional',
          match: false,
        },
        {
          word: 'identity',
          match: false,
        },
        {
          word: 'Build',
          match: false,
        },
        {
          word: 'engage',
          match: false,
        },
        {
          word: 'network',
          match: false,
        },
        {
          word: 'Access',
          match: false,
        },
        {
          word: 'knowledge',
          match: false,
        },
        {
          word: 'insights',
          match: false,
        },
        {
          word: 'opportunities',
          match: false,
        },
        {
          word: 'LinkedIn',
          match: false,
        },
        {
          word: 'Log',
          match: false,
        },
        {
          word: 'Sign',
          match: false,
        },
      ],
      name: 'linkedin.com',
    },
    {
      keywords: [
        {
          word: 'Discover',
          match: false,
        },
        {
          word: 'recipes',
          match: false,
        },
        {
          word: 'home',
          match: false,
        },
        {
          word: 'ideas',
          match: false,
        },
        {
          word: 'style',
          match: false,
        },
        {
          word: 'inspiration',
          match: false,
        },
        {
          word: 'Pinterest',
          match: false,
        },
      ],
      name: 'pinterest.com',
    },
    {
      keywords: [
        {
          word: 'committed',
          match: false,
        },
        {
          word: 'source',
          match: false,
        },
        {
          word: 'expert',
          match: false,
        },
        {
          word: 'health',
          match: false,
        },
        {
          word: 'guidance',
          match: false,
        },
        {
          word: 'pursuit',
          match: false,
        },
        {
          word: 'wellness',
          match: false,
        },
        {
          word: 'Healthline',
          match: false,
        },
        {
          word: 'Medical',
          match: false,
        },
        {
          word: 'information',
          match: false,
        },
        {
          word: 'advice',
          match: false,
        },
        {
          word: 'trust',
          match: false,
        },
      ],
      name: 'healthline.com',
    },
    {
      keywords: [
        {
          word: 'Buy',
          match: false,
        },
        {
          word: 'sell',
          match: false,
        },
        {
          word: 'electronics',
          match: false,
        },
        {
          word: 'cars',
          match: false,
        },
        {
          word: 'clothes',
          match: false,
        },
        {
          word: 'collectibles',
          match: false,
        },
        {
          word: 'eBay',
          match: false,
        },
        {
          word: 'worlds',
          match: false,
        },
        {
          word: 'online',
          match: false,
        },
        {
          word: 'marketplace',
          match: false,
        },
        {
          word: 'Top',
          match: false,
        },
        {
          word: 'brands',
          match: false,
        },
        {
          word: 'low',
          match: false,
        },
        {
          word: 'prices',
          match: false,
        },
        {
          word: 'free',
          match: false,
        },
        {
          word: 'shipping',
          match: false,
        },
        {
          word: 'items',
          match: false,
        },
        {
          word: 'Electronics',
          match: false,
        },
        {
          word: 'Cars',
          match: false,
        },
        {
          word: 'Fashion',
          match: false,
        },
        {
          word: 'Collectibles',
          match: false,
        },
      ],
      name: 'ebay.com',
    },
    {
      keywords: [
        {
          word: 'Wikipedia',
          match: false,
        },
        {
          word: 'free',
          match: false,
        },
        {
          word: 'online',
          match: false,
        },
        {
          word: 'encyclopedia',
          match: false,
        },
        {
          word: 'created',
          match: false,
        },
        {
          word: 'edited',
          match: false,
        },
        {
          word: 'volunteers',
          match: false,
        },
        {
          word: 'world',
          match: false,
        },
        {
          word: 'hosted',
          match: false,
        },
        {
          word: 'Wikimedia',
          match: false,
        },
        {
          word: 'Foundation',
          match: false,
        },
      ],
      name: 'wikipedia.org',
    },
    {
      keywords: [
        {
          word: 'Canadas',
          match: false,
        },
        {
          word: 'largest',
          match: false,
        },
        {
          word: 'online',
          match: false,
        },
        {
          word: 'retailer',
          match: false,
        },
        {
          word: 'Free',
          match: false,
        },
        {
          word: 'Shipping',
          match: false,
        },
        {
          word: 'eligible',
          match: false,
        },
        {
          word: 'orders',
          match: false,
        },
        {
          word: 'Easy',
          match: false,
        },
        {
          word: 'Returns',
          match: false,
        },
        {
          word: 'Shop',
          match: false,
        },
        {
          word: 'Electronics',
          match: false,
        },
        {
          word: 'Books',
          match: false,
        },
        {
          word: 'Apparel',
          match: false,
        },
        {
          word: 'Prime',
          match: false,
        },
        {
          word: 'free',
          match: false,
        },
        {
          word: 'Amazonca',
          match: false,
        },
        {
          word: 'Low',
          match: false,
        },
        {
          word: 'Prices',
          match: false,
        },
        {
          word: 'Fast',
          match: false,
        },
        {
          word: 'Millions',
          match: false,
        },
        {
          word: 'Items',
          match: false,
        },
      ],
      name: 'amazon.ca',
    },
    {
      keywords: [
        {
          word: 'Free',
          match: false,
        },
        {
          word: 'delivery',
          match: false,
        },
        {
          word: 'millions',
          match: false,
        },
        {
          word: 'items',
          match: false,
        },
        {
          word: 'Prime',
          match: false,
        },
        {
          word: 'Low',
          match: false,
        },
        {
          word: 'prices',
          match: false,
        },
        {
          word: 'earths',
          match: false,
        },
        {
          word: 'biggest',
          match: false,
        },
        {
          word: 'selection',
          match: false,
        },
        {
          word: 'books',
          match: false,
        },
        {
          word: 'music',
          match: false,
        },
        {
          word: 'DVDs',
          match: false,
        },
        {
          word: 'electronics',
          match: false,
        },
        {
          word: 'computers',
          match: false,
        },
        {
          word: 'software',
          match: false,
        },
        {
          word: 'apparel',
          match: false,
        },
        {
          word: 'accessories',
          match: false,
        },
        {
          word: 'shoes',
          match: false,
        },
        {
          word: 'jewelry',
          match: false,
        },
        {
          word: 'tools',
          match: false,
        },
        {
          word: 'hardware',
          match: false,
        },
        {
          word: 'housewares',
          match: false,
        },
        {
          word: 'furniture',
          match: false,
        },
        {
          word: 'sporting',
          match: false,
        },
        {
          word: 'goods',
          match: false,
        },
        {
          word: 'beauty',
          match: false,
        },
        {
          word: 'personal',
          match: false,
        },
        {
          word: 'care',
          match: false,
        },
        {
          word: 'groceries',
          match: false,
        },
        {
          word: 'Amazoncom',
          match: false,
        },
        {
          word: 'Online',
          match: false,
        },
        {
          word: 'Shopping',
          match: false,
        },
        {
          word: 'Electronics',
          match: false,
        },
        {
          word: 'Apparel',
          match: false,
        },
        {
          word: 'Computers',
          match: false,
        },
        {
          word: 'Books',
          match: false,
        },
      ],
      name: 'amazon.com',
    },
    {
      keywords: [
        {
          word: 'Learn',
          match: false,
        },
        {
          word: 'wikiHow',
          match: false,
        },
        {
          word: 'worlds',
          match: false,
        },
        {
          word: 'popular',
          match: false,
        },
        {
          word: 'howto',
          match: false,
        },
        {
          word: 'website',
          match: false,
        },
        {
          word: 'Easy',
          match: false,
        },
        {
          word: 'wellresearched',
          match: false,
        },
        {
          word: 'trustworthy',
          match: false,
        },
        {
          word: 'instructions',
          match: false,
        },
      ],
      name: 'wikihow.com',
    },
    {
      keywords: [
        {
          word: 'undefined',
          match: false,
        },
        {
          word: 'Wikipedia',
          match: false,
        },
        {
          word: 'free',
          match: false,
        },
        {
          word: 'encyclopedia',
          match: false,
        },
      ],
      name: 'en.wikipedia.org',
    },
    {
      keywords: [
        {
          word: 'Shop',
          match: false,
        },
        {
          word: 'Walmartcom',
          match: false,
        },
        {
          word: 'Day',
          match: false,
        },
        {
          word: 'Low',
          match: false,
        },
        {
          word: 'Prices',
          match: false,
        },
        {
          word: 'Free',
          match: false,
        },
        {
          word: 'Shipping',
          match: false,
        },
        {
          word: 'Orders',
          match: false,
        },
        {
          word: 'Pickup',
          match: false,
        },
        {
          word: 'InStore',
          match: false,
        },
        {
          word: 'Discount',
          match: false,
        },
        {
          word: 'Open',
          match: false,
        },
        {
          word: 'Walmart',
          match: false,
        },
        {
          word: 'Credit',
          match: false,
        },
        {
          word: 'Card',
          match: false,
        },
        {
          word: 'Save',
          match: false,
        },
        {
          word: 'Money',
          match: false,
        },
        {
          word: 'Live',
          match: false,
        },
      ],
      name: 'walmart.com',
    },
    {
      keywords: [
        {
          word: 'Create',
          match: false,
        },
        {
          word: 'free',
          match: false,
        },
        {
          word: 'website',
          match: false,
        },
        {
          word: 'build',
          match: false,
        },
        {
          word: 'blog',
          match: false,
        },
        {
          word: 'ease',
          match: false,
        },
        {
          word: 'WordPresscom',
          match: false,
        },
        {
          word: 'Dozens',
          match: false,
        },
        {
          word: 'customizable',
          match: false,
        },
        {
          word: 'mobileready',
          match: false,
        },
        {
          word: 'designs',
          match: false,
        },
        {
          word: 'themes',
          match: false,
        },
        {
          word: 'Free',
          match: false,
        },
        {
          word: 'hosting',
          match: false,
        },
        {
          word: 'support',
          match: false,
        },
        {
          word: 'Website',
          match: false,
        },
        {
          word: 'Blog',
          match: false,
        },
      ],
      name: 'wordpress.com',
    },
    {
      keywords: [
        {
          word: 'undefined',
        },
        {
          word: 'Google',
        },
      ],
      name: 'google.com',
    },
  ],
}

export const threeStatus = {
  header: ['Keyword', 'Status', 'Count'],
  rowData: [
    {
      keyword: 'Learn',
      status: 'Passed',
      count: 8,
    },
    {
      keyword: 'Free',
      status: 'Passed',
      count: 3,
    },
    {
      keyword: 'Display',
      status: 'Passed',
      count: 3,
    },
    {
      keyword: 'Shop',
      status: 'Passed',
      count: 3,
    },
    {
      keyword: 'Everything',
      status: 'Passed',
      count: 2,
    },
    {
      keyword: 'iPhone',
      status: 'Passed',
      count: 2,
    },
    {
      keyword: 'Watch',
      status: 'Passed',
      count: 2,
    },
    {
      keyword: 'Delivery',
      status: 'Passed',
      count: 2,
    },
  ],
}

export const pdfHeader = {
  constHeader: `<!DOCTYPE html>\n<html lang=\"en\">\n\n<head>\n    <meta charset="UTF-8" />\n    <meta name="viewport" content="width=device-width, initial-scale=1.0" />\n  <title>Document</title>\n <link rel="stylesheet" href="https://tools1.dev-positionmysite.com/css/reports.css" type="text/css" />\n  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer"/>\n <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" />\n    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" />\n    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/antd@4.24.11/dist/antd.min.css" />\n  <script src="https://cdn.jsdelivr.net/npm/antd@4.24.11/dist/antd.min.js"></script>\n    <link href=\"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap\" rel=\"stylesheet\">\n  <link href=\"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap\" rel=\"stylesheet\">\n  <style></style>\n</head>\n\n<body></body>\n\n</html>`,
}

export const keywords = [
  'googledocs',
  'googledrive',
  'dropboxa',
  'dropboxe',
  'rob ox',
  'bado',
  'Skyppe',
  'itunes',
  'realator ca',
  'appel store',
  'gmail',
  'netflix',
  'scotiabanking',
  'paypal',
  'imdb',
  'www google com',
  'gmail com',
  'old navy',
  'movie star planet star',
  'edm journal',
]

export const domains = [
  'microsoft.com',
  'imdb.com',
  'google.com',
  'amazon.ca',
  'twitter.com',
  'instagram.com',
  'facebook.com',
  'theguardian.com',
  'amazon.com',
  'scotiabank.com',
  'linkedin.com',
  'wikipedia.org',
  'youtube.com',
]

export const twoStatus = ['Learn', 'Free', 'Display', 'Shop', 'Everything', 'iPhone', 'Watch', 'Delivery']

export const dashboardData = [
  {
    name: 'Dashboard',
    imgPath: '/images/dashboard/browser.svg',
    url: routes.dashboardItem,
  },
  {
    name: 'SEO Checkup',
    imgPath: '/images/dashboard/list.svg',
    url: routes.seoCheckup,
  },
  {
    name: 'SEO Checkup Report',
    imgPath: '/images/dashboard/list.svg',
    url: '/seo-checkup-report',
  },
  {
    name: 'Backlinks',
    imgPath: '/images/dashboard/planet-earth.svg',
    url: routes.backlinks,
  },
  {
    name: 'SEO Keyword Tracking',
    imgPath: '/images/dashboard/approval.svg',
    url: routes.seoCheckup,
  },
  {
    name: 'Keyword Research',
    imgPath: '/images/dashboard/result.svg',
    url: routes.keywordSearch,
  },
  {
    name: 'Broken Links',
    imgPath: '/images/dashboard/ui.svg',
    url: routes.brokenLinks,
  },
  {
    name: 'Site Speed',
    imgPath: '/images/dashboard/speed.svg',
    url: '/',
  },
  {
    name: 'Site Uptime',
    imgPath: '/images/dashboard/progress.svg',
    url: '/',
  },
  {
    name: 'White Label Reports',
    imgPath: '/images/dashboard/newspaper.svg',
    url: '/',
  },
  {
    name: 'Help',
    imgPath: '/images/dashboard/lifebuoy.svg',
    url: '/',
  },
  {
    name: 'Contact Us',
    imgPath: '/images/dashboard/contact.svg',
    url: '/',
  },
]

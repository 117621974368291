import {
  faAd,
  faAddressBook,
  faAddressCard,
  faAdjust,
  faAirFreshener,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAllergies,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faAnchor,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngry,
  faAnkh,
  faAppleAlt,
  faArchive,
  faArchway,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faArrowsAltH,
  faArrowsAltV,
  faAssistiveListeningSystems,
  faAsterisk,
  faAt,
  faAtlas,
  faAtom,
  faAudioDescription,
  faAward,
  faBaby,
  faBabyCarriage,
  faBackspace,
  faBackward,
  faBacon,
  faBalanceScale,
  faBan,
  faBandAid,
  faBarcode,
  faBars,
  faBaseballBall,
  faBasketballBall,
  faBath,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBed,
  faBeer,
  faBell,
  faBellSlash,
  faBezierCurve,
  faBible,
  faBicycle,
  faBinoculars,
  faBiohazard,
  faBirthdayCake,
  faBlender,
  faBlenderPhone,
  faBlind,
  faBlog,
  faBold,
  faBolt,
  faBomb,
  faBone,
  faBong,
  faBook,
  faBookDead,
  faBookMedical,
  faBookOpen,
  faBookReader,
  faBookmark,
  faBowlingBall,
  faBox,
  faBoxOpen,
  faBoxes,
  faBraille,
  faBrain,
  faBreadSlice,
  faBriefcase,
  faBriefcaseMedical,
  faBroadcastTower,
  faBroom,
  faBrush,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBurn,
  faBus,
  faBusAlt,
  faBusinessTime,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarMinus,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarWeek,
  faCamera,
  faCameraRetro,
  faCampground,
  faCandyCane,
  faCannabis,
  faCapsules,
  faCar,
  faCarAlt,
  faCarBattery,
  faCarCrash,
  faCarSide,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretSquareDown,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCaretSquareUp,
  faCaretUp,
  faCarrot,
  faCartArrowDown,
  faCartPlus,
  faCashRegister,
  faCat,
  faCertificate,
  faChair,
  faChalkboard,
  faChalkboardTeacher,
  faChargingStation,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faCheese,
  faChess,
  faChessBishop,
  faChessBoard,
  faChessKing,
  faChessKnight,
  faChessPawn,
  faChessQueen,
  faChessRook,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faChurch,
  faCircle,
  faCircleNotch,
  faCity,
  faClinicMedical,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faClosedCaptioning,
  faCloud,
  faCloudDownloadAlt,
  faCloudMeatball,
  faCloudMoon,
  faCloudMoonRain,
  faCloudRain,
  faCloudShowersHeavy,
  faCloudSun,
  faCloudSunRain,
  faCloudUploadAlt,
  faCocktail,
  faCode,
  faCodeBranch,
  faCoffee,
  faCog,
  faCogs,
  faCoins,
  faColumns,
  faComment,
  faCommentAlt,
  faCommentDollar,
  faCommentDots,
  faCommentMedical,
  faCommentSlash,
  faComments,
  faCommentsDollar,
  faCompactDisc,
  faCompass,
  faCompress,
  faCompressArrowsAlt,
  faConciergeBell,
  faCookie,
  faCookieBite,
  faCopy,
  faCopyright,
  faCouch,
  faCreditCard,
  faCrop,
  faCropAlt,
  faCross,
  faCrosshairs,
  faCrow,
  faCrown,
  faCrutch,
  faCube,
  faCubes,
  faCut,
  faDatabase,
  faDeaf,
  faDemocrat,
  faDesktop,
  faDharmachakra,
  faDiagnoses,
  faDice,
  faDiceD20,
  faDiceD6,
  faDiceFive,
  faDiceFour,
  faDiceOne,
  faDiceSix,
  faDiceThree,
  faDiceTwo,
  faDigitalTachograph,
  faDirections,
  faDivide,
  faDizzy,
  faDna,
  faDog,
  faDollarSign,
  faDolly,
  faDollyFlatbed,
  faDonate,
  faDoorClosed,
  faDoorOpen,
  faDotCircle,
  faDove,
  faDownload,
  faDraftingCompass,
  faDragon,
  faDrawPolygon,
  faDrum,
  faDrumSteelpan,
  faDrumstickBite,
  faDumbbell,
  faDumpster,
  faDumpsterFire,
  faDungeon,
  faEdit,
  faEgg,
  faEject,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopeSquare,
  faEquals,
  faEraser,
  faEthernet,
  faEuroSign,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faEye,
  faEyeDropper,
  faEyeSlash,
  faFastBackward,
  faFastForward,
  faFax,
  faFeather,
  faFeatherAlt,
  faFemale,
  faFighterJet,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileContract,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileMedical,
  faFileMedicalAlt,
  faFilePdf,
  faFilePowerpoint,
  faFilePrescription,
  faFileSignature,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFill,
  faFillDrip,
  faFilm,
  faFilter,
  faFingerprint,
  faFire,
  faFireAlt,
  faFireExtinguisher,
  faFirstAid,
  faFish,
  faFistRaised,
  faFlag,
  faFlagCheckered,
  faFlagUsa,
  faFlask,
  faFlushed,
  faFolder,
  faFolderMinus,
  faFolderOpen,
  faFolderPlus,
  faFont,
  faFontAwesomeLogoFull,
  faFootballBall,
  faForward,
  faFrog,
  faFrown,
  faFrownOpen,
  faFunnelDollar,
  faFutbol,
  faGamepad,
  faGasPump,
  faGavel,
  faGem,
  faGenderless,
  faGhost,
  faGift,
  faGifts,
  faGlassCheers,
  faGlassMartini,
  faGlassMartiniAlt,
  faGlassWhiskey,
  faGlasses,
  faGlobe,
  faGlobeAfrica,
  faGlobeAmericas,
  faGlobeAsia,
  faGlobeEurope,
  faGolfBall,
  faGopuram,
  faGraduationCap,
  faGreaterThan,
  faGreaterThanEqual,
  faGrimace,
  faGrin,
  faGrinAlt,
  faGrinBeam,
  faGrinBeamSweat,
  faGrinHearts,
  faGrinSquint,
  faGrinSquintTears,
  faGrinStars,
  faGrinTears,
  faGrinTongue,
  faGrinTongueSquint,
  faGrinTongueWink,
  faGrinWink,
  faGripHorizontal,
  faGripLines,
  faGripLinesVertical,
  faGripVertical,
  faGuitar,
  faHSquare,
  faHamburger,
  faHammer,
  faHamsa,
  faHandHolding,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHandLizard,
  faHandMiddleFinger,
  faHandPaper,
  faHandPeace,
  faHandPointDown,
  faHandPointLeft,
  faHandPointRight,
  faHandPointUp,
  faHandPointer,
  faHandRock,
  faHandScissors,
  faHandSpock,
  faHands,
  faHandsHelping,
  faHandshake,
  faHanukiah,
  faHardHat,
  faHashtag,
  faHatWizard,
  faHdd,
  faHeading,
  faHeadphones,
  faHeadphonesAlt,
  faHeadset,
  faHeart,
  faHeartBroken,
  faHeartbeat,
  faHelicopter,
  faHighlighter,
  faHiking,
  faHippo,
  faHistory,
  faHockeyPuck,
  faHollyBerry,
  faHome,
  faHorse,
  faHorseHead,
  faHospital,
  faHospitalAlt,
  faHospitalSymbol,
  faHotTub,
  faHotdog,
  faHotel,
  faHourglass,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faHouseDamage,
  faHryvnia,
  faICursor,
  faIceCream,
  faIcicles,
  faIdBadge,
  faIdCard,
  faIdCardAlt,
  faIgloo,
  faImage,
  faImages,
  faInbox,
  faIndent,
  faIndustry,
  faInfinity,
  faInfo,
  faInfoCircle,
  faItalic,
  faJedi,
  faJoint,
  faJournalWhills,
  faKaaba,
  faKey,
  faKeyboard,
  faKhanda,
  faKiss,
  faKissBeam,
  faKissWinkHeart,
  faKiwiBird,
  faLandmark,
  faLanguage,
  faLaptop,
  faLaptopCode,
  faLaptopMedical,
  faLaugh,
  faLaughBeam,
  faLaughSquint,
  faLaughWink,
  faLayerGroup,
  faLeaf,
  faLemon,
  faLessThan,
  faLessThanEqual,
  faLevelDownAlt,
  faLevelUpAlt,
  faLifeRing,
  faLightbulb,
  faLink,
  faLiraSign,
  faList,
  faListAlt,
  faListOl,
  faListUl,
  faLocationArrow,
  faLock,
  faLockOpen,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLongArrowAltUp,
  faLowVision,
  faLuggageCart,
  faMagic,
  faMagnet,
  faMailBulk,
  faMale,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faMapPin,
  faMapSigns,
  faMarker,
  faMars,
  faMarsDouble,
  faMarsStroke,
  faMarsStrokeH,
  faMarsStrokeV,
  faMask,
  faMedal,
  faMedkit,
  faMeh,
  faMehBlank,
  faMehRollingEyes,
  faMemory,
  faMenorah,
  faMercury,
  faMeteor,
  faMicrochip,
  faMicrophone,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faMicrophoneSlash,
  faMicroscope,
  faMinus,
  faMinusCircle,
  faMinusSquare,
  faMitten,
  faMobile,
  faMobileAlt,
  faMoneyBill,
  faMoneyBillAlt,
  faMoneyBillWave,
  faMoneyBillWaveAlt,
  faMoneyCheck,
  faMoneyCheckAlt,
  faMonument,
  faMoon,
  faMortarPestle,
  faMosque,
  faMotorcycle,
  faMountain,
  faMousePointer,
  faMugHot,
  faMusic,
  faNetworkWired,
  faNeuter,
  faNewspaper,
  faNotEqual,
  faNotesMedical,
  faObjectGroup,
  faObjectUngroup,
  faOilCan,
  faOm,
  faOtter,
  faOutdent,
  faPager,
  faPaintBrush,
  faPaintRoller,
  faPalette,
  faPallet,
  faPaperPlane,
  faPaperclip,
  faParachuteBox,
  faParagraph,
  faParking,
  faPassport,
  faPastafarianism,
  faPaste,
  faPause,
  faPauseCircle,
  faPaw,
  faPeace,
  faPen,
  faPenAlt,
  faPenFancy,
  faPenNib,
  faPenSquare,
  faPencilAlt,
  faPencilRuler,
  faPeopleCarry,
  faPepperHot,
  faPercent,
  faPercentage,
  faPersonBooth,
  faPhone,
  faPhoneSlash,
  faPhoneSquare,
  faPhoneVolume,
  faPiggyBank,
  faPills,
  faPizzaSlice,
  faPlaceOfWorship,
  faPlane,
  faPlaneArrival,
  faPlaneDeparture,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPodcast,
  faPoll,
  faPollH,
  faPoo,
  faPooStorm,
  faPoop,
  faPortrait,
  faPoundSign,
  faPowerOff,
  faPray,
  faPrayingHands,
  faPrescription,
  faPrescriptionBottle,
  faPrescriptionBottleAlt,
  faPrint,
  faProcedures,
  faProjectDiagram,
  faPuzzlePiece,
  faQrcode,
  faQuestion,
  faQuestionCircle,
  faQuidditch,
  faQuoteLeft,
  faQuoteRight,
  faQuran,
  faRadiation,
  faRadiationAlt,
  faRainbow,
  faRandom,
  faReceipt,
  faRecycle,
  faRedo,
  faRedoAlt,
  faRegistered,
  faReply,
  faReplyAll,
  faRepublican,
  faRestroom,
  faRetweet,
  faRibbon,
  faRing,
  faRoad,
  faRobot,
  faRocket,
  faRoute,
  faRss,
  faRssSquare,
  faRubleSign,
  faRuler,
  faRulerCombined,
  faRulerHorizontal,
  faRulerVertical,
  faRunning,
  faRupeeSign,
  faSadCry,
  faSadTear,
  faSatellite,
  faSatelliteDish,
  faSave,
  faSchool,
  faScrewdriver,
  faScroll,
  faSdCard,
  faSearch,
  faSearchDollar,
  faSearchLocation,
  faSearchMinus,
  faSearchPlus,
  faSeedling,
  faServer,
  faShapes,
  faShare,
  faShareAlt,
  faShareAltSquare,
  faShareSquare,
  faShekelSign,
  faShieldAlt,
  faShip,
  faShippingFast,
  faShoePrints,
  faShoppingBag,
  faShoppingBasket,
  faShoppingCart,
  faShower,
  faShuttleVan,
  faSign,
  faSignInAlt,
  faSignLanguage,
  faSignOutAlt,
  faSignal,
  faSignature,
  faSimCard,
  faSitemap,
  faSkating,
  faSkiing,
  faSkiingNordic,
  faSkull,
  faSkullCrossbones,
  faSlash,
  faSleigh,
  faSlidersH,
  faSmile,
  faSmileBeam,
  faSmileWink,
  faSmog,
  faSmoking,
  faSmokingBan,
  faSms,
  faSnowboarding,
  faSnowflake,
  faSnowman,
  faSnowplow,
  faSocks,
  faSolarPanel,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faSortDown,
  faSortNumericDown,
  faSortNumericUp,
  faSortUp,
  faSpa,
  faSpaceShuttle,
  faSpider,
  faSpinner,
  faSplotch,
  faSprayCan,
  faSquare,
  faSquareFull,
  faSquareRootAlt,
  faStamp,
  faStar,
  faStarAndCrescent,
  faStarHalf,
  faStarHalfAlt,
  faStarOfDavid,
  faStarOfLife,
  faStepBackward,
  faStepForward,
  faStethoscope,
  faStickyNote,
  faStop,
  faStopCircle,
  faStopwatch,
  faStore,
  faStoreAlt,
  faStream,
  faStreetView,
  faStrikethrough,
  faStroopwafel,
  faSubscript,
  faSubway,
  faSuitcase,
  faSuitcaseRolling,
  faSun,
  faSuperscript,
  faSurprise,
  faSwatchbook,
  faSwimmer,
  faSwimmingPool,
  faSynagogue,
  faSync,
  faSyncAlt,
  faSyringe,
  faTable,
  faTableTennis,
  faTablet,
  faTabletAlt,
  faTablets,
  faTachometerAlt,
  faTag,
  faTags,
  faTape,
  faTasks,
  faTaxi,
  faTeeth,
  faTeethOpen,
  faTemperatureHigh,
  faTemperatureLow,
  faTenge,
  faTerminal,
  faTextHeight,
  faTextWidth,
  faTh,
  faThLarge,
  faThList,
  faTheaterMasks,
  faThermometer,
  faThermometerEmpty,
  faThermometerFull,
  faThermometerHalf,
  faThermometerQuarter,
  faThermometerThreeQuarters,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTint,
  faTintSlash,
  faTired,
  faToggleOff,
  faToggleOn,
  faToilet,
  faToiletPaper,
  faToolbox,
  faTools,
  faTooth,
  faTorah,
  faToriiGate,
  faTractor,
  faTrademark,
  faTrafficLight,
  faTrain,
  faTram,
  faTransgender,
  faTransgenderAlt,
  faTrash,
  faTrashAlt,
  faTrashRestore,
  faTrashRestoreAlt,
  faTree,
  faTrophy,
  faTruck,
  faTruckLoading,
  faTruckMonster,
  faTruckMoving,
  faTruckPickup,
  faTshirt,
  faTty,
  faTv,
  faUmbrella,
  faUmbrellaBeach,
  faUnderline,
  faUndo,
  faUndoAlt,
  faUniversalAccess,
  faUniversity,
  faUnlink,
  faUnlock,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserAlt,
  faUserAltSlash,
  faUserAstronaut,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserEdit,
  faUserFriends,
  faUserGraduate,
  faUserInjured,
  faUserLock,
  faUserMd,
  faUserMinus,
  faUserNinja,
  faUserNurse,
  faUserPlus,
  faUserSecret,
  faUserShield,
  faUserSlash,
  faUserTag,
  faUserTie,
  faUserTimes,
  faUsers,
  faUsersCog,
  faUtensilSpoon,
  faUtensils,
  faVectorSquare,
  faVenus,
  faVenusDouble,
  faVenusMars,
  faVial,
  faVials,
  faVideo,
  faVideoSlash,
  faVihara,
  faVolleyballBall,
  faVolumeDown,
  faVolumeMute,
  faVolumeOff,
  faVolumeUp,
  faVoteYea,
  faVrCardboard,
  faWalking,
  faWallet,
  faWarehouse,
  faWater,
  faWeight,
  faWeightHanging,
  faWheelchair,
  faWifi,
  faWind,
  faWindowClose,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faWineBottle,
  faWineGlass,
  faWineGlassAlt,
  faWonSign,
  faWrench,
  faXRay,
  faYenSign,
  faYinYang,
} from '@fortawesome/free-solid-svg-icons'

const icons = [
  {
    icon: faAd,
    name: 'faAd',
  },
  {
    icon: faAddressBook,
    name: 'faAddressBook',
  },
  {
    icon: faAddressCard,
    name: 'faAddressCard',
  },
  {
    icon: faAdjust,
    name: 'faAdjust',
  },
  {
    icon: faAirFreshener,
    name: 'faAirFreshener',
  },
  {
    icon: faAlignCenter,
    name: 'faAlignCenter',
  },
  {
    icon: faAlignJustify,
    name: 'faAlignJustify',
  },
  {
    icon: faAlignLeft,
    name: 'faAlignLeft',
  },
  {
    icon: faAlignRight,
    name: 'faAlignRight',
  },
  {
    icon: faAllergies,
    name: 'faAllergies',
  },
  {
    icon: faAmbulance,
    name: 'faAmbulance',
  },
  {
    icon: faAmericanSignLanguageInterpreting,
    name: 'faAmericanSignLanguageInterpreting',
  },
  {
    icon: faAnchor,
    name: 'faAnchor',
  },
  {
    icon: faAngleDoubleDown,
    name: 'faAngleDoubleDown',
  },
  {
    icon: faAngleDoubleLeft,
    name: 'faAngleDoubleLeft',
  },
  {
    icon: faAngleDoubleRight,
    name: 'faAngleDoubleRight',
  },
  {
    icon: faAngleDoubleUp,
    name: 'faAngleDoubleUp',
  },
  {
    icon: faAngleDown,
    name: 'faAngleDown',
  },
  {
    icon: faAngleLeft,
    name: 'faAngleLeft',
  },
  {
    icon: faAngleRight,
    name: 'faAngleRight',
  },
  {
    icon: faAngleUp,
    name: 'faAngleUp',
  },
  {
    icon: faAngry,
    name: 'faAngry',
  },
  {
    icon: faAnkh,
    name: 'faAnkh',
  },
  {
    icon: faAppleAlt,
    name: 'faAppleAlt',
  },
  {
    icon: faArchive,
    name: 'faArchive',
  },
  {
    icon: faArchway,
    name: 'faArchway',
  },
  {
    icon: faArrowAltCircleDown,
    name: 'faArrowAltCircleDown',
  },
  {
    icon: faArrowAltCircleLeft,
    name: 'faArrowAltCircleLeft',
  },
  {
    icon: faArrowAltCircleRight,
    name: 'faArrowAltCircleRight',
  },
  {
    icon: faArrowAltCircleUp,
    name: 'faArrowAltCircleUp',
  },
  {
    icon: faArrowCircleDown,
    name: 'faArrowCircleDown',
  },
  {
    icon: faArrowCircleLeft,
    name: 'faArrowCircleLeft',
  },
  {
    icon: faArrowCircleRight,
    name: 'faArrowCircleRight',
  },
  {
    icon: faArrowCircleUp,
    name: 'faArrowCircleUp',
  },
  {
    icon: faArrowDown,
    name: 'faArrowDown',
  },
  {
    icon: faArrowLeft,
    name: 'faArrowLeft',
  },
  {
    icon: faArrowRight,
    name: 'faArrowRight',
  },
  {
    icon: faArrowUp,
    name: 'faArrowUp',
  },
  {
    icon: faArrowsAlt,
    name: 'faArrowsAlt',
  },
  {
    icon: faArrowsAltH,
    name: 'faArrowsAltH',
  },
  {
    icon: faArrowsAltV,
    name: 'faArrowsAltV',
  },
  {
    icon: faAssistiveListeningSystems,
    name: 'faAssistiveListeningSystems',
  },
  {
    icon: faAsterisk,
    name: 'faAsterisk',
  },
  {
    icon: faAt,
    name: 'faAt',
  },
  {
    icon: faAtlas,
    name: 'faAtlas',
  },
  {
    icon: faAtom,
    name: 'faAtom',
  },
  {
    icon: faAudioDescription,
    name: 'faAudioDescription',
  },
  {
    icon: faAward,
    name: 'faAward',
  },
  {
    icon: faBaby,
    name: 'faBaby',
  },
  {
    icon: faBabyCarriage,
    name: 'faBabyCarriage',
  },
  {
    icon: faBackspace,
    name: 'faBackspace',
  },
  {
    icon: faBackward,
    name: 'faBackward',
  },
  {
    icon: faBacon,
    name: 'faBacon',
  },
  {
    icon: faBalanceScale,
    name: 'faBalanceScale',
  },
  {
    icon: faBan,
    name: 'faBan',
  },
  {
    icon: faBandAid,
    name: 'faBandAid',
  },
  {
    icon: faBarcode,
    name: 'faBarcode',
  },
  {
    icon: faBars,
    name: 'faBars',
  },
  {
    icon: faBaseballBall,
    name: 'faBaseballBall',
  },
  {
    icon: faBasketballBall,
    name: 'faBasketballBall',
  },
  {
    icon: faBath,
    name: 'faBath',
  },
  {
    icon: faBatteryEmpty,
    name: 'faBatteryEmpty',
  },
  {
    icon: faBatteryFull,
    name: 'faBatteryFull',
  },
  {
    icon: faBatteryHalf,
    name: 'faBatteryHalf',
  },
  {
    icon: faBatteryQuarter,
    name: 'faBatteryQuarter',
  },
  {
    icon: faBatteryThreeQuarters,
    name: 'faBatteryThreeQuarters',
  },
  {
    icon: faBed,
    name: 'faBed',
  },
  {
    icon: faBeer,
    name: 'faBeer',
  },
  {
    icon: faBell,
    name: 'faBell',
  },
  {
    icon: faBellSlash,
    name: 'faBellSlash',
  },
  {
    icon: faBezierCurve,
    name: 'faBezierCurve',
  },
  {
    icon: faBible,
    name: 'faBible',
  },
  {
    icon: faBicycle,
    name: 'faBicycle',
  },
  {
    icon: faBinoculars,
    name: 'faBinoculars',
  },
  {
    icon: faBiohazard,
    name: 'faBiohazard',
  },
  {
    icon: faBirthdayCake,
    name: 'faBirthdayCake',
  },
  {
    icon: faBlender,
    name: 'faBlender',
  },
  {
    icon: faBlenderPhone,
    name: 'faBlenderPhone',
  },
  {
    icon: faBlind,
    name: 'faBlind',
  },
  {
    icon: faBlog,
    name: 'faBlog',
  },
  {
    icon: faBold,
    name: 'faBold',
  },
  {
    icon: faBolt,
    name: 'faBolt',
  },
  {
    icon: faBomb,
    name: 'faBomb',
  },
  {
    icon: faBone,
    name: 'faBone',
  },
  {
    icon: faBong,
    name: 'faBong',
  },
  {
    icon: faBook,
    name: 'faBook',
  },
  {
    icon: faBookDead,
    name: 'faBookDead',
  },
  {
    icon: faBookMedical,
    name: 'faBookMedical',
  },
  {
    icon: faBookOpen,
    name: 'faBookOpen',
  },
  {
    icon: faBookReader,
    name: 'faBookReader',
  },
  {
    icon: faBookmark,
    name: 'faBookmark',
  },
  {
    icon: faBowlingBall,
    name: 'faBowlingBall',
  },
  {
    icon: faBox,
    name: 'faBox',
  },
  {
    icon: faBoxOpen,
    name: 'faBoxOpen',
  },
  {
    icon: faBoxes,
    name: 'faBoxes',
  },
  {
    icon: faBraille,
    name: 'faBraille',
  },
  {
    icon: faBrain,
    name: 'faBrain',
  },
  {
    icon: faBreadSlice,
    name: 'faBreadSlice',
  },
  {
    icon: faBriefcase,
    name: 'faBriefcase',
  },
  {
    icon: faBriefcaseMedical,
    name: 'faBriefcaseMedical',
  },
  {
    icon: faBroadcastTower,
    name: 'faBroadcastTower',
  },
  {
    icon: faBroom,
    name: 'faBroom',
  },
  {
    icon: faBrush,
    name: 'faBrush',
  },
  {
    icon: faBug,
    name: 'faBug',
  },
  {
    icon: faBuilding,
    name: 'faBuilding',
  },
  {
    icon: faBullhorn,
    name: 'faBullhorn',
  },
  {
    icon: faBullseye,
    name: 'faBullseye',
  },
  {
    icon: faBurn,
    name: 'faBurn',
  },
  {
    icon: faBus,
    name: 'faBus',
  },
  {
    icon: faBusAlt,
    name: 'faBusAlt',
  },
  {
    icon: faBusinessTime,
    name: 'faBusinessTime',
  },
  {
    icon: faCalculator,
    name: 'faCalculator',
  },
  {
    icon: faCalendar,
    name: 'faCalendar',
  },
  {
    icon: faCalendarAlt,
    name: 'faCalendarAlt',
  },
  {
    icon: faCalendarCheck,
    name: 'faCalendarCheck',
  },
  {
    icon: faCalendarDay,
    name: 'faCalendarDay',
  },
  {
    icon: faCalendarMinus,
    name: 'faCalendarMinus',
  },
  {
    icon: faCalendarPlus,
    name: 'faCalendarPlus',
  },
  {
    icon: faCalendarTimes,
    name: 'faCalendarTimes',
  },
  {
    icon: faCalendarWeek,
    name: 'faCalendarWeek',
  },
  {
    icon: faCamera,
    name: 'faCamera',
  },
  {
    icon: faCameraRetro,
    name: 'faCameraRetro',
  },
  {
    icon: faCampground,
    name: 'faCampground',
  },
  {
    icon: faCandyCane,
    name: 'faCandyCane',
  },
  {
    icon: faCannabis,
    name: 'faCannabis',
  },
  {
    icon: faCapsules,
    name: 'faCapsules',
  },
  {
    icon: faCar,
    name: 'faCar',
  },
  {
    icon: faCarAlt,
    name: 'faCarAlt',
  },
  {
    icon: faCarBattery,
    name: 'faCarBattery',
  },
  {
    icon: faCarCrash,
    name: 'faCarCrash',
  },
  {
    icon: faCarSide,
    name: 'faCarSide',
  },
  {
    icon: faCaretDown,
    name: 'faCaretDown',
  },
  {
    icon: faCaretLeft,
    name: 'faCaretLeft',
  },
  {
    icon: faCaretRight,
    name: 'faCaretRight',
  },
  {
    icon: faCaretSquareDown,
    name: 'faCaretSquareDown',
  },
  {
    icon: faCaretSquareLeft,
    name: 'faCaretSquareLeft',
  },
  {
    icon: faCaretSquareRight,
    name: 'faCaretSquareRight',
  },
  {
    icon: faCaretSquareUp,
    name: 'faCaretSquareUp',
  },
  {
    icon: faCaretUp,
    name: 'faCaretUp',
  },
  {
    icon: faCarrot,
    name: 'faCarrot',
  },
  {
    icon: faCartArrowDown,
    name: 'faCartArrowDown',
  },
  {
    icon: faCartPlus,
    name: 'faCartPlus',
  },
  {
    icon: faCashRegister,
    name: 'faCashRegister',
  },
  {
    icon: faCat,
    name: 'faCat',
  },
  {
    icon: faCertificate,
    name: 'faCertificate',
  },
  {
    icon: faChair,
    name: 'faChair',
  },
  {
    icon: faChalkboard,
    name: 'faChalkboard',
  },
  {
    icon: faChalkboardTeacher,
    name: 'faChalkboardTeacher',
  },
  {
    icon: faChargingStation,
    name: 'faChargingStation',
  },
  {
    icon: faChartArea,
    name: 'faChartArea',
  },
  {
    icon: faChartBar,
    name: 'faChartBar',
  },
  {
    icon: faChartLine,
    name: 'faChartLine',
  },
  {
    icon: faChartPie,
    name: 'faChartPie',
  },
  {
    icon: faCheck,
    name: 'faCheck',
  },
  {
    icon: faCheckCircle,
    name: 'faCheckCircle',
  },
  {
    icon: faCheckDouble,
    name: 'faCheckDouble',
  },
  {
    icon: faCheckSquare,
    name: 'faCheckSquare',
  },
  {
    icon: faCheese,
    name: 'faCheese',
  },
  {
    icon: faChess,
    name: 'faChess',
  },
  {
    icon: faChessBishop,
    name: 'faChessBishop',
  },
  {
    icon: faChessBoard,
    name: 'faChessBoard',
  },
  {
    icon: faChessKing,
    name: 'faChessKing',
  },
  {
    icon: faChessKnight,
    name: 'faChessKnight',
  },
  {
    icon: faChessPawn,
    name: 'faChessPawn',
  },
  {
    icon: faChessQueen,
    name: 'faChessQueen',
  },
  {
    icon: faChessRook,
    name: 'faChessRook',
  },
  {
    icon: faChevronCircleDown,
    name: 'faChevronCircleDown',
  },
  {
    icon: faChevronCircleLeft,
    name: 'faChevronCircleLeft',
  },
  {
    icon: faChevronCircleRight,
    name: 'faChevronCircleRight',
  },
  {
    icon: faChevronCircleUp,
    name: 'faChevronCircleUp',
  },
  {
    icon: faChevronDown,
    name: 'faChevronDown',
  },
  {
    icon: faChevronLeft,
    name: 'faChevronLeft',
  },
  {
    icon: faChevronRight,
    name: 'faChevronRight',
  },
  {
    icon: faChevronUp,
    name: 'faChevronUp',
  },
  {
    icon: faChild,
    name: 'faChild',
  },
  {
    icon: faChurch,
    name: 'faChurch',
  },
  {
    icon: faCircle,
    name: 'faCircle',
  },
  {
    icon: faCircleNotch,
    name: 'faCircleNotch',
  },
  {
    icon: faCity,
    name: 'faCity',
  },
  {
    icon: faClinicMedical,
    name: 'faClinicMedical',
  },
  {
    icon: faClipboard,
    name: 'faClipboard',
  },
  {
    icon: faClipboardCheck,
    name: 'faClipboardCheck',
  },
  {
    icon: faClipboardList,
    name: 'faClipboardList',
  },
  {
    icon: faClock,
    name: 'faClock',
  },
  {
    icon: faClone,
    name: 'faClone',
  },
  {
    icon: faClosedCaptioning,
    name: 'faClosedCaptioning',
  },
  {
    icon: faCloud,
    name: 'faCloud',
  },
  {
    icon: faCloudDownloadAlt,
    name: 'faCloudDownloadAlt',
  },
  {
    icon: faCloudMeatball,
    name: 'faCloudMeatball',
  },
  {
    icon: faCloudMoon,
    name: 'faCloudMoon',
  },
  {
    icon: faCloudMoonRain,
    name: 'faCloudMoonRain',
  },
  {
    icon: faCloudRain,
    name: 'faCloudRain',
  },
  {
    icon: faCloudShowersHeavy,
    name: 'faCloudShowersHeavy',
  },
  {
    icon: faCloudSun,
    name: 'faCloudSun',
  },
  {
    icon: faCloudSunRain,
    name: 'faCloudSunRain',
  },
  {
    icon: faCloudUploadAlt,
    name: 'faCloudUploadAlt',
  },
  {
    icon: faCocktail,
    name: 'faCocktail',
  },
  {
    icon: faCode,
    name: 'faCode',
  },
  {
    icon: faCodeBranch,
    name: 'faCodeBranch',
  },
  {
    icon: faCoffee,
    name: 'faCoffee',
  },
  {
    icon: faCog,
    name: 'faCog',
  },
  {
    icon: faCogs,
    name: 'faCogs',
  },
  {
    icon: faCoins,
    name: 'faCoins',
  },
  {
    icon: faColumns,
    name: 'faColumns',
  },
  {
    icon: faComment,
    name: 'faComment',
  },
  {
    icon: faCommentAlt,
    name: 'faCommentAlt',
  },
  {
    icon: faCommentDollar,
    name: 'faCommentDollar',
  },
  {
    icon: faCommentDots,
    name: 'faCommentDots',
  },
  {
    icon: faCommentMedical,
    name: 'faCommentMedical',
  },
  {
    icon: faCommentSlash,
    name: 'faCommentSlash',
  },
  {
    icon: faComments,
    name: 'faComments',
  },
  {
    icon: faCommentsDollar,
    name: 'faCommentsDollar',
  },
  {
    icon: faCompactDisc,
    name: 'faCompactDisc',
  },
  {
    icon: faCompass,
    name: 'faCompass',
  },
  {
    icon: faCompress,
    name: 'faCompress',
  },
  {
    icon: faCompressArrowsAlt,
    name: 'faCompressArrowsAlt',
  },
  {
    icon: faConciergeBell,
    name: 'faConciergeBell',
  },
  {
    icon: faCookie,
    name: 'faCookie',
  },
  {
    icon: faCookieBite,
    name: 'faCookieBite',
  },
  {
    icon: faCopy,
    name: 'faCopy',
  },
  {
    icon: faCopyright,
    name: 'faCopyright',
  },
  {
    icon: faCouch,
    name: 'faCouch',
  },
  {
    icon: faCreditCard,
    name: 'faCreditCard',
  },
  {
    icon: faCrop,
    name: 'faCrop',
  },
  {
    icon: faCropAlt,
    name: 'faCropAlt',
  },
  {
    icon: faCross,
    name: 'faCross',
  },
  {
    icon: faCrosshairs,
    name: 'faCrosshairs',
  },
  {
    icon: faCrow,
    name: 'faCrow',
  },
  {
    icon: faCrown,
    name: 'faCrown',
  },
  {
    icon: faCrutch,
    name: 'faCrutch',
  },
  {
    icon: faCube,
    name: 'faCube',
  },
  {
    icon: faCubes,
    name: 'faCubes',
  },
  {
    icon: faCut,
    name: 'faCut',
  },
  {
    icon: faDatabase,
    name: 'faDatabase',
  },
  {
    icon: faDeaf,
    name: 'faDeaf',
  },
  {
    icon: faDemocrat,
    name: 'faDemocrat',
  },
  {
    icon: faDesktop,
    name: 'faDesktop',
  },
  {
    icon: faDharmachakra,
    name: 'faDharmachakra',
  },
  {
    icon: faDiagnoses,
    name: 'faDiagnoses',
  },
  {
    icon: faDice,
    name: 'faDice',
  },
  {
    icon: faDiceD20,
    name: 'faDiceD20',
  },
  {
    icon: faDiceD6,
    name: 'faDiceD6',
  },
  {
    icon: faDiceFive,
    name: 'faDiceFive',
  },
  {
    icon: faDiceFour,
    name: 'faDiceFour',
  },
  {
    icon: faDiceOne,
    name: 'faDiceOne',
  },
  {
    icon: faDiceSix,
    name: 'faDiceSix',
  },
  {
    icon: faDiceThree,
    name: 'faDiceThree',
  },
  {
    icon: faDiceTwo,
    name: 'faDiceTwo',
  },
  {
    icon: faDigitalTachograph,
    name: 'faDigitalTachograph',
  },
  {
    icon: faDirections,
    name: 'faDirections',
  },
  {
    icon: faDivide,
    name: 'faDivide',
  },
  {
    icon: faDizzy,
    name: 'faDizzy',
  },
  {
    icon: faDna,
    name: 'faDna',
  },
  {
    icon: faDog,
    name: 'faDog',
  },
  {
    icon: faDollarSign,
    name: 'faDollarSign',
  },
  {
    icon: faDolly,
    name: 'faDolly',
  },
  {
    icon: faDollyFlatbed,
    name: 'faDollyFlatbed',
  },
  {
    icon: faDonate,
    name: 'faDonate',
  },
  {
    icon: faDoorClosed,
    name: 'faDoorClosed',
  },
  {
    icon: faDoorOpen,
    name: 'faDoorOpen',
  },
  {
    icon: faDotCircle,
    name: 'faDotCircle',
  },
  {
    icon: faDove,
    name: 'faDove',
  },
  {
    icon: faDownload,
    name: 'faDownload',
  },
  {
    icon: faDraftingCompass,
    name: 'faDraftingCompass',
  },
  {
    icon: faDragon,
    name: 'faDragon',
  },
  {
    icon: faDrawPolygon,
    name: 'faDrawPolygon',
  },
  {
    icon: faDrum,
    name: 'faDrum',
  },
  {
    icon: faDrumSteelpan,
    name: 'faDrumSteelpan',
  },
  {
    icon: faDrumstickBite,
    name: 'faDrumstickBite',
  },
  {
    icon: faDumbbell,
    name: 'faDumbbell',
  },
  {
    icon: faDumpster,
    name: 'faDumpster',
  },
  {
    icon: faDumpsterFire,
    name: 'faDumpsterFire',
  },
  {
    icon: faDungeon,
    name: 'faDungeon',
  },
  {
    icon: faEdit,
    name: 'faEdit',
  },
  {
    icon: faEgg,
    name: 'faEgg',
  },
  {
    icon: faEject,
    name: 'faEject',
  },
  {
    icon: faEllipsisH,
    name: 'faEllipsisH',
  },
  {
    icon: faEllipsisV,
    name: 'faEllipsisV',
  },
  {
    icon: faEnvelope,
    name: 'faEnvelope',
  },
  {
    icon: faEnvelopeOpen,
    name: 'faEnvelopeOpen',
  },
  {
    icon: faEnvelopeOpenText,
    name: 'faEnvelopeOpenText',
  },
  {
    icon: faEnvelopeSquare,
    name: 'faEnvelopeSquare',
  },
  {
    icon: faEquals,
    name: 'faEquals',
  },
  {
    icon: faEraser,
    name: 'faEraser',
  },
  {
    icon: faEthernet,
    name: 'faEthernet',
  },
  {
    icon: faEuroSign,
    name: 'faEuroSign',
  },
  {
    icon: faExchangeAlt,
    name: 'faExchangeAlt',
  },
  {
    icon: faExclamation,
    name: 'faExclamation',
  },
  {
    icon: faExclamationCircle,
    name: 'faExclamationCircle',
  },
  {
    icon: faExclamationTriangle,
    name: 'faExclamationTriangle',
  },
  {
    icon: faExpand,
    name: 'faExpand',
  },
  {
    icon: faExpandArrowsAlt,
    name: 'faExpandArrowsAlt',
  },
  {
    icon: faExternalLinkAlt,
    name: 'faExternalLinkAlt',
  },
  {
    icon: faExternalLinkSquareAlt,
    name: 'faExternalLinkSquareAlt',
  },
  {
    icon: faEye,
    name: 'faEye',
  },
  {
    icon: faEyeDropper,
    name: 'faEyeDropper',
  },
  {
    icon: faEyeSlash,
    name: 'faEyeSlash',
  },
  {
    icon: faFastBackward,
    name: 'faFastBackward',
  },
  {
    icon: faFastForward,
    name: 'faFastForward',
  },
  {
    icon: faFax,
    name: 'faFax',
  },
  {
    icon: faFeather,
    name: 'faFeather',
  },
  {
    icon: faFeatherAlt,
    name: 'faFeatherAlt',
  },
  {
    icon: faFemale,
    name: 'faFemale',
  },
  {
    icon: faFighterJet,
    name: 'faFighterJet',
  },
  {
    icon: faFile,
    name: 'faFile',
  },
  {
    icon: faFileAlt,
    name: 'faFileAlt',
  },
  {
    icon: faFileArchive,
    name: 'faFileArchive',
  },
  {
    icon: faFileAudio,
    name: 'faFileAudio',
  },
  {
    icon: faFileCode,
    name: 'faFileCode',
  },
  {
    icon: faFileContract,
    name: 'faFileContract',
  },
  {
    icon: faFileCsv,
    name: 'faFileCsv',
  },
  {
    icon: faFileDownload,
    name: 'faFileDownload',
  },
  {
    icon: faFileExcel,
    name: 'faFileExcel',
  },
  {
    icon: faFileExport,
    name: 'faFileExport',
  },
  {
    icon: faFileImage,
    name: 'faFileImage',
  },
  {
    icon: faFileImport,
    name: 'faFileImport',
  },
  {
    icon: faFileInvoice,
    name: 'faFileInvoice',
  },
  {
    icon: faFileInvoiceDollar,
    name: 'faFileInvoiceDollar',
  },
  {
    icon: faFileMedical,
    name: 'faFileMedical',
  },
  {
    icon: faFileMedicalAlt,
    name: 'faFileMedicalAlt',
  },
  {
    icon: faFilePdf,
    name: 'faFilePdf',
  },
  {
    icon: faFilePowerpoint,
    name: 'faFilePowerpoint',
  },
  {
    icon: faFilePrescription,
    name: 'faFilePrescription',
  },
  {
    icon: faFileSignature,
    name: 'faFileSignature',
  },
  {
    icon: faFileUpload,
    name: 'faFileUpload',
  },
  {
    icon: faFileVideo,
    name: 'faFileVideo',
  },
  {
    icon: faFileWord,
    name: 'faFileWord',
  },
  {
    icon: faFill,
    name: 'faFill',
  },
  {
    icon: faFillDrip,
    name: 'faFillDrip',
  },
  {
    icon: faFilm,
    name: 'faFilm',
  },
  {
    icon: faFilter,
    name: 'faFilter',
  },
  {
    icon: faFingerprint,
    name: 'faFingerprint',
  },
  {
    icon: faFire,
    name: 'faFire',
  },
  {
    icon: faFireAlt,
    name: 'faFireAlt',
  },
  {
    icon: faFireExtinguisher,
    name: 'faFireExtinguisher',
  },
  {
    icon: faFirstAid,
    name: 'faFirstAid',
  },
  {
    icon: faFish,
    name: 'faFish',
  },
  {
    icon: faFistRaised,
    name: 'faFistRaised',
  },
  {
    icon: faFlag,
    name: 'faFlag',
  },
  {
    icon: faFlagCheckered,
    name: 'faFlagCheckered',
  },
  {
    icon: faFlagUsa,
    name: 'faFlagUsa',
  },
  {
    icon: faFlask,
    name: 'faFlask',
  },
  {
    icon: faFlushed,
    name: 'faFlushed',
  },
  {
    icon: faFolder,
    name: 'faFolder',
  },
  {
    icon: faFolderMinus,
    name: 'faFolderMinus',
  },
  {
    icon: faFolderOpen,
    name: 'faFolderOpen',
  },
  {
    icon: faFolderPlus,
    name: 'faFolderPlus',
  },
  {
    icon: faFont,
    name: 'faFont',
  },
  {
    icon: faFontAwesomeLogoFull,
    name: 'faFontAwesomeLogoFull',
  },
  {
    icon: faFootballBall,
    name: 'faFootballBall',
  },
  {
    icon: faForward,
    name: 'faForward',
  },
  {
    icon: faFrog,
    name: 'faFrog',
  },
  {
    icon: faFrown,
    name: 'faFrown',
  },
  {
    icon: faFrownOpen,
    name: 'faFrownOpen',
  },
  {
    icon: faFunnelDollar,
    name: 'faFunnelDollar',
  },
  {
    icon: faFutbol,
    name: 'faFutbol',
  },
  {
    icon: faGamepad,
    name: 'faGamepad',
  },
  {
    icon: faGasPump,
    name: 'faGasPump',
  },
  {
    icon: faGavel,
    name: 'faGavel',
  },
  {
    icon: faGem,
    name: 'faGem',
  },
  {
    icon: faGenderless,
    name: 'faGenderless',
  },
  {
    icon: faGhost,
    name: 'faGhost',
  },
  {
    icon: faGift,
    name: 'faGift',
  },
  {
    icon: faGifts,
    name: 'faGifts',
  },
  {
    icon: faGlassCheers,
    name: 'faGlassCheers',
  },
  {
    icon: faGlassMartini,
    name: 'faGlassMartini',
  },
  {
    icon: faGlassMartiniAlt,
    name: 'faGlassMartiniAlt',
  },
  {
    icon: faGlassWhiskey,
    name: 'faGlassWhiskey',
  },
  {
    icon: faGlasses,
    name: 'faGlasses',
  },
  {
    icon: faGlobe,
    name: 'faGlobe',
  },
  {
    icon: faGlobeAfrica,
    name: 'faGlobeAfrica',
  },
  {
    icon: faGlobeAmericas,
    name: 'faGlobeAmericas',
  },
  {
    icon: faGlobeAsia,
    name: 'faGlobeAsia',
  },
  {
    icon: faGlobeEurope,
    name: 'faGlobeEurope',
  },
  {
    icon: faGolfBall,
    name: 'faGolfBall',
  },
  {
    icon: faGopuram,
    name: 'faGopuram',
  },
  {
    icon: faGraduationCap,
    name: 'faGraduationCap',
  },
  {
    icon: faGreaterThan,
    name: 'faGreaterThan',
  },
  {
    icon: faGreaterThanEqual,
    name: 'faGreaterThanEqual',
  },
  {
    icon: faGrimace,
    name: 'faGrimace',
  },
  {
    icon: faGrin,
    name: 'faGrin',
  },
  {
    icon: faGrinAlt,
    name: 'faGrinAlt',
  },
  {
    icon: faGrinBeam,
    name: 'faGrinBeam',
  },
  {
    icon: faGrinBeamSweat,
    name: 'faGrinBeamSweat',
  },
  {
    icon: faGrinHearts,
    name: 'faGrinHearts',
  },
  {
    icon: faGrinSquint,
    name: 'faGrinSquint',
  },
  {
    icon: faGrinSquintTears,
    name: 'faGrinSquintTears',
  },
  {
    icon: faGrinStars,
    name: 'faGrinStars',
  },
  {
    icon: faGrinTears,
    name: 'faGrinTears',
  },
  {
    icon: faGrinTongue,
    name: 'faGrinTongue',
  },
  {
    icon: faGrinTongueSquint,
    name: 'faGrinTongueSquint',
  },
  {
    icon: faGrinTongueWink,
    name: 'faGrinTongueWink',
  },
  {
    icon: faGrinWink,
    name: 'faGrinWink',
  },
  {
    icon: faGripHorizontal,
    name: 'faGripHorizontal',
  },
  {
    icon: faGripLines,
    name: 'faGripLines',
  },
  {
    icon: faGripLinesVertical,
    name: 'faGripLinesVertical',
  },
  {
    icon: faGripVertical,
    name: 'faGripVertical',
  },
  {
    icon: faGuitar,
    name: 'faGuitar',
  },
  {
    icon: faHSquare,
    name: 'faHSquare',
  },
  {
    icon: faHamburger,
    name: 'faHamburger',
  },
  {
    icon: faHammer,
    name: 'faHammer',
  },
  {
    icon: faHamsa,
    name: 'faHamsa',
  },
  {
    icon: faHandHolding,
    name: 'faHandHolding',
  },
  {
    icon: faHandHoldingHeart,
    name: 'faHandHoldingHeart',
  },
  {
    icon: faHandHoldingUsd,
    name: 'faHandHoldingUsd',
  },
  {
    icon: faHandLizard,
    name: 'faHandLizard',
  },
  {
    icon: faHandMiddleFinger,
    name: 'faHandMiddleFinger',
  },
  {
    icon: faHandPaper,
    name: 'faHandPaper',
  },
  {
    icon: faHandPeace,
    name: 'faHandPeace',
  },
  {
    icon: faHandPointDown,
    name: 'faHandPointDown',
  },
  {
    icon: faHandPointLeft,
    name: 'faHandPointLeft',
  },
  {
    icon: faHandPointRight,
    name: 'faHandPointRight',
  },
  {
    icon: faHandPointUp,
    name: 'faHandPointUp',
  },
  {
    icon: faHandPointer,
    name: 'faHandPointer',
  },
  {
    icon: faHandRock,
    name: 'faHandRock',
  },
  {
    icon: faHandScissors,
    name: 'faHandScissors',
  },
  {
    icon: faHandSpock,
    name: 'faHandSpock',
  },
  {
    icon: faHands,
    name: 'faHands',
  },
  {
    icon: faHandsHelping,
    name: 'faHandsHelping',
  },
  {
    icon: faHandshake,
    name: 'faHandshake',
  },
  {
    icon: faHanukiah,
    name: 'faHanukiah',
  },
  {
    icon: faHardHat,
    name: 'faHardHat',
  },
  {
    icon: faHashtag,
    name: 'faHashtag',
  },
  {
    icon: faHatWizard,
    name: 'faHatWizard',
  },
  {
    icon: faHdd,
    name: 'faHdd',
  },
  {
    icon: faHeading,
    name: 'faHeading',
  },
  {
    icon: faHeadphones,
    name: 'faHeadphones',
  },
  {
    icon: faHeadphonesAlt,
    name: 'faHeadphonesAlt',
  },
  {
    icon: faHeadset,
    name: 'faHeadset',
  },
  {
    icon: faHeart,
    name: 'faHeart',
  },
  {
    icon: faHeartBroken,
    name: 'faHeartBroken',
  },
  {
    icon: faHeartbeat,
    name: 'faHeartbeat',
  },
  {
    icon: faHelicopter,
    name: 'faHelicopter',
  },
  {
    icon: faHighlighter,
    name: 'faHighlighter',
  },
  {
    icon: faHiking,
    name: 'faHiking',
  },
  {
    icon: faHippo,
    name: 'faHippo',
  },
  {
    icon: faHistory,
    name: 'faHistory',
  },
  {
    icon: faHockeyPuck,
    name: 'faHockeyPuck',
  },
  {
    icon: faHollyBerry,
    name: 'faHollyBerry',
  },
  {
    icon: faHome,
    name: 'faHome',
  },
  {
    icon: faHorse,
    name: 'faHorse',
  },
  {
    icon: faHorseHead,
    name: 'faHorseHead',
  },
  {
    icon: faHospital,
    name: 'faHospital',
  },
  {
    icon: faHospitalAlt,
    name: 'faHospitalAlt',
  },
  {
    icon: faHospitalSymbol,
    name: 'faHospitalSymbol',
  },
  {
    icon: faHotTub,
    name: 'faHotTub',
  },
  {
    icon: faHotdog,
    name: 'faHotdog',
  },
  {
    icon: faHotel,
    name: 'faHotel',
  },
  {
    icon: faHourglass,
    name: 'faHourglass',
  },
  {
    icon: faHourglassEnd,
    name: 'faHourglassEnd',
  },
  {
    icon: faHourglassHalf,
    name: 'faHourglassHalf',
  },
  {
    icon: faHourglassStart,
    name: 'faHourglassStart',
  },
  {
    icon: faHouseDamage,
    name: 'faHouseDamage',
  },
  {
    icon: faHryvnia,
    name: 'faHryvnia',
  },
  {
    icon: faICursor,
    name: 'faICursor',
  },
  {
    icon: faIceCream,
    name: 'faIceCream',
  },
  {
    icon: faIcicles,
    name: 'faIcicles',
  },
  {
    icon: faIdBadge,
    name: 'faIdBadge',
  },
  {
    icon: faIdCard,
    name: 'faIdCard',
  },
  {
    icon: faIdCardAlt,
    name: 'faIdCardAlt',
  },
  {
    icon: faIgloo,
    name: 'faIgloo',
  },
  {
    icon: faImage,
    name: 'faImage',
  },
  {
    icon: faImages,
    name: 'faImages',
  },
  {
    icon: faInbox,
    name: 'faInbox',
  },
  {
    icon: faIndent,
    name: 'faIndent',
  },
  {
    icon: faIndustry,
    name: 'faIndustry',
  },
  {
    icon: faInfinity,
    name: 'faInfinity',
  },
  {
    icon: faInfo,
    name: 'faInfo',
  },
  {
    icon: faInfoCircle,
    name: 'faInfoCircle',
  },
  {
    icon: faItalic,
    name: 'faItalic',
  },
  {
    icon: faJedi,
    name: 'faJedi',
  },
  {
    icon: faJoint,
    name: 'faJoint',
  },
  {
    icon: faJournalWhills,
    name: 'faJournalWhills',
  },
  {
    icon: faKaaba,
    name: 'faKaaba',
  },
  {
    icon: faKey,
    name: 'faKey',
  },
  {
    icon: faKeyboard,
    name: 'faKeyboard',
  },
  {
    icon: faKhanda,
    name: 'faKhanda',
  },
  {
    icon: faKiss,
    name: 'faKiss',
  },
  {
    icon: faKissBeam,
    name: 'faKissBeam',
  },
  {
    icon: faKissWinkHeart,
    name: 'faKissWinkHeart',
  },
  {
    icon: faKiwiBird,
    name: 'faKiwiBird',
  },
  {
    icon: faLandmark,
    name: 'faLandmark',
  },
  {
    icon: faLanguage,
    name: 'faLanguage',
  },
  {
    icon: faLaptop,
    name: 'faLaptop',
  },
  {
    icon: faLaptopCode,
    name: 'faLaptopCode',
  },
  {
    icon: faLaptopMedical,
    name: 'faLaptopMedical',
  },
  {
    icon: faLaugh,
    name: 'faLaugh',
  },
  {
    icon: faLaughBeam,
    name: 'faLaughBeam',
  },
  {
    icon: faLaughSquint,
    name: 'faLaughSquint',
  },
  {
    icon: faLaughWink,
    name: 'faLaughWink',
  },
  {
    icon: faLayerGroup,
    name: 'faLayerGroup',
  },
  {
    icon: faLeaf,
    name: 'faLeaf',
  },
  {
    icon: faLemon,
    name: 'faLemon',
  },
  {
    icon: faLessThan,
    name: 'faLessThan',
  },
  {
    icon: faLessThanEqual,
    name: 'faLessThanEqual',
  },
  {
    icon: faLevelDownAlt,
    name: 'faLevelDownAlt',
  },
  {
    icon: faLevelUpAlt,
    name: 'faLevelUpAlt',
  },
  {
    icon: faLifeRing,
    name: 'faLifeRing',
  },
  {
    icon: faLightbulb,
    name: 'faLightbulb',
  },
  {
    icon: faLink,
    name: 'faLink',
  },
  {
    icon: faLiraSign,
    name: 'faLiraSign',
  },
  {
    icon: faList,
    name: 'faList',
  },
  {
    icon: faListAlt,
    name: 'faListAlt',
  },
  {
    icon: faListOl,
    name: 'faListOl',
  },
  {
    icon: faListUl,
    name: 'faListUl',
  },
  {
    icon: faLocationArrow,
    name: 'faLocationArrow',
  },
  {
    icon: faLock,
    name: 'faLock',
  },
  {
    icon: faLockOpen,
    name: 'faLockOpen',
  },
  {
    icon: faLongArrowAltDown,
    name: 'faLongArrowAltDown',
  },
  {
    icon: faLongArrowAltLeft,
    name: 'faLongArrowAltLeft',
  },
  {
    icon: faLongArrowAltRight,
    name: 'faLongArrowAltRight',
  },
  {
    icon: faLongArrowAltUp,
    name: 'faLongArrowAltUp',
  },
  {
    icon: faLowVision,
    name: 'faLowVision',
  },
  {
    icon: faLuggageCart,
    name: 'faLuggageCart',
  },
  {
    icon: faMagic,
    name: 'faMagic',
  },
  {
    icon: faMagnet,
    name: 'faMagnet',
  },
  {
    icon: faMailBulk,
    name: 'faMailBulk',
  },
  {
    icon: faMale,
    name: 'faMale',
  },
  {
    icon: faMap,
    name: 'faMap',
  },
  {
    icon: faMapMarked,
    name: 'faMapMarked',
  },
  {
    icon: faMapMarkedAlt,
    name: 'faMapMarkedAlt',
  },
  {
    icon: faMapMarker,
    name: 'faMapMarker',
  },
  {
    icon: faMapMarkerAlt,
    name: 'faMapMarkerAlt',
  },
  {
    icon: faMapPin,
    name: 'faMapPin',
  },
  {
    icon: faMapSigns,
    name: 'faMapSigns',
  },
  {
    icon: faMarker,
    name: 'faMarker',
  },
  {
    icon: faMars,
    name: 'faMars',
  },
  {
    icon: faMarsDouble,
    name: 'faMarsDouble',
  },
  {
    icon: faMarsStroke,
    name: 'faMarsStroke',
  },
  {
    icon: faMarsStrokeH,
    name: 'faMarsStrokeH',
  },
  {
    icon: faMarsStrokeV,
    name: 'faMarsStrokeV',
  },
  {
    icon: faMask,
    name: 'faMask',
  },
  {
    icon: faMedal,
    name: 'faMedal',
  },
  {
    icon: faMedkit,
    name: 'faMedkit',
  },
  {
    icon: faMeh,
    name: 'faMeh',
  },
  {
    icon: faMehBlank,
    name: 'faMehBlank',
  },
  {
    icon: faMehRollingEyes,
    name: 'faMehRollingEyes',
  },
  {
    icon: faMemory,
    name: 'faMemory',
  },
  {
    icon: faMenorah,
    name: 'faMenorah',
  },
  {
    icon: faMercury,
    name: 'faMercury',
  },
  {
    icon: faMeteor,
    name: 'faMeteor',
  },
  {
    icon: faMicrochip,
    name: 'faMicrochip',
  },
  {
    icon: faMicrophone,
    name: 'faMicrophone',
  },
  {
    icon: faMicrophoneAlt,
    name: 'faMicrophoneAlt',
  },
  {
    icon: faMicrophoneAltSlash,
    name: 'faMicrophoneAltSlash',
  },
  {
    icon: faMicrophoneSlash,
    name: 'faMicrophoneSlash',
  },
  {
    icon: faMicroscope,
    name: 'faMicroscope',
  },
  {
    icon: faMinus,
    name: 'faMinus',
  },
  {
    icon: faMinusCircle,
    name: 'faMinusCircle',
  },
  {
    icon: faMinusSquare,
    name: 'faMinusSquare',
  },
  {
    icon: faMitten,
    name: 'faMitten',
  },
  {
    icon: faMobile,
    name: 'faMobile',
  },
  {
    icon: faMobileAlt,
    name: 'faMobileAlt',
  },
  {
    icon: faMoneyBill,
    name: 'faMoneyBill',
  },
  {
    icon: faMoneyBillAlt,
    name: 'faMoneyBillAlt',
  },
  {
    icon: faMoneyBillWave,
    name: 'faMoneyBillWave',
  },
  {
    icon: faMoneyBillWaveAlt,
    name: 'faMoneyBillWaveAlt',
  },
  {
    icon: faMoneyCheck,
    name: 'faMoneyCheck',
  },
  {
    icon: faMoneyCheckAlt,
    name: 'faMoneyCheckAlt',
  },
  {
    icon: faMonument,
    name: 'faMonument',
  },
  {
    icon: faMoon,
    name: 'faMoon',
  },
  {
    icon: faMortarPestle,
    name: 'faMortarPestle',
  },
  {
    icon: faMosque,
    name: 'faMosque',
  },
  {
    icon: faMotorcycle,
    name: 'faMotorcycle',
  },
  {
    icon: faMountain,
    name: 'faMountain',
  },
  {
    icon: faMousePointer,
    name: 'faMousePointer',
  },
  {
    icon: faMugHot,
    name: 'faMugHot',
  },
  {
    icon: faMusic,
    name: 'faMusic',
  },
  {
    icon: faNetworkWired,
    name: 'faNetworkWired',
  },
  {
    icon: faNeuter,
    name: 'faNeuter',
  },
  {
    icon: faNewspaper,
    name: 'faNewspaper',
  },
  {
    icon: faNotEqual,
    name: 'faNotEqual',
  },
  {
    icon: faNotesMedical,
    name: 'faNotesMedical',
  },
  {
    icon: faObjectGroup,
    name: 'faObjectGroup',
  },
  {
    icon: faObjectUngroup,
    name: 'faObjectUngroup',
  },
  {
    icon: faOilCan,
    name: 'faOilCan',
  },
  {
    icon: faOm,
    name: 'faOm',
  },
  {
    icon: faOtter,
    name: 'faOtter',
  },
  {
    icon: faOutdent,
    name: 'faOutdent',
  },
  {
    icon: faPager,
    name: 'faPager',
  },
  {
    icon: faPaintBrush,
    name: 'faPaintBrush',
  },
  {
    icon: faPaintRoller,
    name: 'faPaintRoller',
  },
  {
    icon: faPalette,
    name: 'faPalette',
  },
  {
    icon: faPallet,
    name: 'faPallet',
  },
  {
    icon: faPaperPlane,
    name: 'faPaperPlane',
  },
  {
    icon: faPaperclip,
    name: 'faPaperclip',
  },
  {
    icon: faParachuteBox,
    name: 'faParachuteBox',
  },
  {
    icon: faParagraph,
    name: 'faParagraph',
  },
  {
    icon: faParking,
    name: 'faParking',
  },
  {
    icon: faPassport,
    name: 'faPassport',
  },
  {
    icon: faPastafarianism,
    name: 'faPastafarianism',
  },
  {
    icon: faPaste,
    name: 'faPaste',
  },
  {
    icon: faPause,
    name: 'faPause',
  },
  {
    icon: faPauseCircle,
    name: 'faPauseCircle',
  },
  {
    icon: faPaw,
    name: 'faPaw',
  },
  {
    icon: faPeace,
    name: 'faPeace',
  },
  {
    icon: faPen,
    name: 'faPen',
  },
  {
    icon: faPenAlt,
    name: 'faPenAlt',
  },
  {
    icon: faPenFancy,
    name: 'faPenFancy',
  },
  {
    icon: faPenNib,
    name: 'faPenNib',
  },
  {
    icon: faPenSquare,
    name: 'faPenSquare',
  },
  {
    icon: faPencilAlt,
    name: 'faPencilAlt',
  },
  {
    icon: faPencilRuler,
    name: 'faPencilRuler',
  },
  {
    icon: faPeopleCarry,
    name: 'faPeopleCarry',
  },
  {
    icon: faPepperHot,
    name: 'faPepperHot',
  },
  {
    icon: faPercent,
    name: 'faPercent',
  },
  {
    icon: faPercentage,
    name: 'faPercentage',
  },
  {
    icon: faPersonBooth,
    name: 'faPersonBooth',
  },
  {
    icon: faPhone,
    name: 'faPhone',
  },
  {
    icon: faPhoneSlash,
    name: 'faPhoneSlash',
  },
  {
    icon: faPhoneSquare,
    name: 'faPhoneSquare',
  },
  {
    icon: faPhoneVolume,
    name: 'faPhoneVolume',
  },
  {
    icon: faPiggyBank,
    name: 'faPiggyBank',
  },
  {
    icon: faPills,
    name: 'faPills',
  },
  {
    icon: faPizzaSlice,
    name: 'faPizzaSlice',
  },
  {
    icon: faPlaceOfWorship,
    name: 'faPlaceOfWorship',
  },
  {
    icon: faPlane,
    name: 'faPlane',
  },
  {
    icon: faPlaneArrival,
    name: 'faPlaneArrival',
  },
  {
    icon: faPlaneDeparture,
    name: 'faPlaneDeparture',
  },
  {
    icon: faPlay,
    name: 'faPlay',
  },
  {
    icon: faPlayCircle,
    name: 'faPlayCircle',
  },
  {
    icon: faPlug,
    name: 'faPlug',
  },
  {
    icon: faPlus,
    name: 'faPlus',
  },
  {
    icon: faPlusCircle,
    name: 'faPlusCircle',
  },
  {
    icon: faPlusSquare,
    name: 'faPlusSquare',
  },
  {
    icon: faPodcast,
    name: 'faPodcast',
  },
  {
    icon: faPoll,
    name: 'faPoll',
  },
  {
    icon: faPollH,
    name: 'faPollH',
  },
  {
    icon: faPoo,
    name: 'faPoo',
  },
  {
    icon: faPooStorm,
    name: 'faPooStorm',
  },
  {
    icon: faPoop,
    name: 'faPoop',
  },
  {
    icon: faPortrait,
    name: 'faPortrait',
  },
  {
    icon: faPoundSign,
    name: 'faPoundSign',
  },
  {
    icon: faPowerOff,
    name: 'faPowerOff',
  },
  {
    icon: faPray,
    name: 'faPray',
  },
  {
    icon: faPrayingHands,
    name: 'faPrayingHands',
  },
  {
    icon: faPrescription,
    name: 'faPrescription',
  },
  {
    icon: faPrescriptionBottle,
    name: 'faPrescriptionBottle',
  },
  {
    icon: faPrescriptionBottleAlt,
    name: 'faPrescriptionBottleAlt',
  },
  {
    icon: faPrint,
    name: 'faPrint',
  },
  {
    icon: faProcedures,
    name: 'faProcedures',
  },
  {
    icon: faProjectDiagram,
    name: 'faProjectDiagram',
  },
  {
    icon: faPuzzlePiece,
    name: 'faPuzzlePiece',
  },
  {
    icon: faQrcode,
    name: 'faQrcode',
  },
  {
    icon: faQuestion,
    name: 'faQuestion',
  },
  {
    icon: faQuestionCircle,
    name: 'faQuestionCircle',
  },
  {
    icon: faQuidditch,
    name: 'faQuidditch',
  },
  {
    icon: faQuoteLeft,
    name: 'faQuoteLeft',
  },
  {
    icon: faQuoteRight,
    name: 'faQuoteRight',
  },
  {
    icon: faQuran,
    name: 'faQuran',
  },
  {
    icon: faRadiation,
    name: 'faRadiation',
  },
  {
    icon: faRadiationAlt,
    name: 'faRadiationAlt',
  },
  {
    icon: faRainbow,
    name: 'faRainbow',
  },
  {
    icon: faRandom,
    name: 'faRandom',
  },
  {
    icon: faReceipt,
    name: 'faReceipt',
  },
  {
    icon: faRecycle,
    name: 'faRecycle',
  },
  {
    icon: faRedo,
    name: 'faRedo',
  },
  {
    icon: faRedoAlt,
    name: 'faRedoAlt',
  },
  {
    icon: faRegistered,
    name: 'faRegistered',
  },
  {
    icon: faReply,
    name: 'faReply',
  },
  {
    icon: faReplyAll,
    name: 'faReplyAll',
  },
  {
    icon: faRepublican,
    name: 'faRepublican',
  },
  {
    icon: faRestroom,
    name: 'faRestroom',
  },
  {
    icon: faRetweet,
    name: 'faRetweet',
  },
  {
    icon: faRibbon,
    name: 'faRibbon',
  },
  {
    icon: faRing,
    name: 'faRing',
  },
  {
    icon: faRoad,
    name: 'faRoad',
  },
  {
    icon: faRobot,
    name: 'faRobot',
  },
  {
    icon: faRocket,
    name: 'faRocket',
  },
  {
    icon: faRoute,
    name: 'faRoute',
  },
  {
    icon: faRss,
    name: 'faRss',
  },
  {
    icon: faRssSquare,
    name: 'faRssSquare',
  },
  {
    icon: faRubleSign,
    name: 'faRubleSign',
  },
  {
    icon: faRuler,
    name: 'faRuler',
  },
  {
    icon: faRulerCombined,
    name: 'faRulerCombined',
  },
  {
    icon: faRulerHorizontal,
    name: 'faRulerHorizontal',
  },
  {
    icon: faRulerVertical,
    name: 'faRulerVertical',
  },
  {
    icon: faRunning,
    name: 'faRunning',
  },
  {
    icon: faRupeeSign,
    name: 'faRupeeSign',
  },
  {
    icon: faSadCry,
    name: 'faSadCry',
  },
  {
    icon: faSadTear,
    name: 'faSadTear',
  },
  {
    icon: faSatellite,
    name: 'faSatellite',
  },
  {
    icon: faSatelliteDish,
    name: 'faSatelliteDish',
  },
  {
    icon: faSave,
    name: 'faSave',
  },
  {
    icon: faSchool,
    name: 'faSchool',
  },
  {
    icon: faScrewdriver,
    name: 'faScrewdriver',
  },
  {
    icon: faScroll,
    name: 'faScroll',
  },
  {
    icon: faSdCard,
    name: 'faSdCard',
  },
  {
    icon: faSearch,
    name: 'faSearch',
  },
  {
    icon: faSearchDollar,
    name: 'faSearchDollar',
  },
  {
    icon: faSearchLocation,
    name: 'faSearchLocation',
  },
  {
    icon: faSearchMinus,
    name: 'faSearchMinus',
  },
  {
    icon: faSearchPlus,
    name: 'faSearchPlus',
  },
  {
    icon: faSeedling,
    name: 'faSeedling',
  },
  {
    icon: faServer,
    name: 'faServer',
  },
  {
    icon: faShapes,
    name: 'faShapes',
  },
  {
    icon: faShare,
    name: 'faShare',
  },
  {
    icon: faShareAlt,
    name: 'faShareAlt',
  },
  {
    icon: faShareAltSquare,
    name: 'faShareAltSquare',
  },
  {
    icon: faShareSquare,
    name: 'faShareSquare',
  },
  {
    icon: faShekelSign,
    name: 'faShekelSign',
  },
  {
    icon: faShieldAlt,
    name: 'faShieldAlt',
  },
  {
    icon: faShip,
    name: 'faShip',
  },
  {
    icon: faShippingFast,
    name: 'faShippingFast',
  },
  {
    icon: faShoePrints,
    name: 'faShoePrints',
  },
  {
    icon: faShoppingBag,
    name: 'faShoppingBag',
  },
  {
    icon: faShoppingBasket,
    name: 'faShoppingBasket',
  },
  {
    icon: faShoppingCart,
    name: 'faShoppingCart',
  },
  {
    icon: faShower,
    name: 'faShower',
  },
  {
    icon: faShuttleVan,
    name: 'faShuttleVan',
  },
  {
    icon: faSign,
    name: 'faSign',
  },
  {
    icon: faSignInAlt,
    name: 'faSignInAlt',
  },
  {
    icon: faSignLanguage,
    name: 'faSignLanguage',
  },
  {
    icon: faSignOutAlt,
    name: 'faSignOutAlt',
  },
  {
    icon: faSignal,
    name: 'faSignal',
  },
  {
    icon: faSignature,
    name: 'faSignature',
  },
  {
    icon: faSimCard,
    name: 'faSimCard',
  },
  {
    icon: faSitemap,
    name: 'faSitemap',
  },
  {
    icon: faSkating,
    name: 'faSkating',
  },
  {
    icon: faSkiing,
    name: 'faSkiing',
  },
  {
    icon: faSkiingNordic,
    name: 'faSkiingNordic',
  },
  {
    icon: faSkull,
    name: 'faSkull',
  },
  {
    icon: faSkullCrossbones,
    name: 'faSkullCrossbones',
  },
  {
    icon: faSlash,
    name: 'faSlash',
  },
  {
    icon: faSleigh,
    name: 'faSleigh',
  },
  {
    icon: faSlidersH,
    name: 'faSlidersH',
  },
  {
    icon: faSmile,
    name: 'faSmile',
  },
  {
    icon: faSmileBeam,
    name: 'faSmileBeam',
  },
  {
    icon: faSmileWink,
    name: 'faSmileWink',
  },
  {
    icon: faSmog,
    name: 'faSmog',
  },
  {
    icon: faSmoking,
    name: 'faSmoking',
  },
  {
    icon: faSmokingBan,
    name: 'faSmokingBan',
  },
  {
    icon: faSms,
    name: 'faSms',
  },
  {
    icon: faSnowboarding,
    name: 'faSnowboarding',
  },
  {
    icon: faSnowflake,
    name: 'faSnowflake',
  },
  {
    icon: faSnowman,
    name: 'faSnowman',
  },
  {
    icon: faSnowplow,
    name: 'faSnowplow',
  },
  {
    icon: faSocks,
    name: 'faSocks',
  },
  {
    icon: faSolarPanel,
    name: 'faSolarPanel',
  },
  {
    icon: faSort,
    name: 'faSort',
  },
  {
    icon: faSortAlphaDown,
    name: 'faSortAlphaDown',
  },
  {
    icon: faSortAlphaUp,
    name: 'faSortAlphaUp',
  },
  {
    icon: faSortAmountDown,
    name: 'faSortAmountDown',
  },
  {
    icon: faSortAmountUp,
    name: 'faSortAmountUp',
  },
  {
    icon: faSortDown,
    name: 'faSortDown',
  },
  {
    icon: faSortNumericDown,
    name: 'faSortNumericDown',
  },
  {
    icon: faSortNumericUp,
    name: 'faSortNumericUp',
  },
  {
    icon: faSortUp,
    name: 'faSortUp',
  },
  {
    icon: faSpa,
    name: 'faSpa',
  },
  {
    icon: faSpaceShuttle,
    name: 'faSpaceShuttle',
  },
  {
    icon: faSpider,
    name: 'faSpider',
  },
  {
    icon: faSpinner,
    name: 'faSpinner',
  },
  {
    icon: faSplotch,
    name: 'faSplotch',
  },
  {
    icon: faSprayCan,
    name: 'faSprayCan',
  },
  {
    icon: faSquare,
    name: 'faSquare',
  },
  {
    icon: faSquareFull,
    name: 'faSquareFull',
  },
  {
    icon: faSquareRootAlt,
    name: 'faSquareRootAlt',
  },
  {
    icon: faStamp,
    name: 'faStamp',
  },
  {
    icon: faStar,
    name: 'faStar',
  },
  {
    icon: faStarAndCrescent,
    name: 'faStarAndCrescent',
  },
  {
    icon: faStarHalf,
    name: 'faStarHalf',
  },
  {
    icon: faStarHalfAlt,
    name: 'faStarHalfAlt',
  },
  {
    icon: faStarOfDavid,
    name: 'faStarOfDavid',
  },
  {
    icon: faStarOfLife,
    name: 'faStarOfLife',
  },
  {
    icon: faStepBackward,
    name: 'faStepBackward',
  },
  {
    icon: faStepForward,
    name: 'faStepForward',
  },
  {
    icon: faStethoscope,
    name: 'faStethoscope',
  },
  {
    icon: faStickyNote,
    name: 'faStickyNote',
  },
  {
    icon: faStop,
    name: 'faStop',
  },
  {
    icon: faStopCircle,
    name: 'faStopCircle',
  },
  {
    icon: faStopwatch,
    name: 'faStopwatch',
  },
  {
    icon: faStore,
    name: 'faStore',
  },
  {
    icon: faStoreAlt,
    name: 'faStoreAlt',
  },
  {
    icon: faStream,
    name: 'faStream',
  },
  {
    icon: faStreetView,
    name: 'faStreetView',
  },
  {
    icon: faStrikethrough,
    name: 'faStrikethrough',
  },
  {
    icon: faStroopwafel,
    name: 'faStroopwafel',
  },
  {
    icon: faSubscript,
    name: 'faSubscript',
  },
  {
    icon: faSubway,
    name: 'faSubway',
  },
  {
    icon: faSuitcase,
    name: 'faSuitcase',
  },
  {
    icon: faSuitcaseRolling,
    name: 'faSuitcaseRolling',
  },
  {
    icon: faSun,
    name: 'faSun',
  },
  {
    icon: faSuperscript,
    name: 'faSuperscript',
  },
  {
    icon: faSurprise,
    name: 'faSurprise',
  },
  {
    icon: faSwatchbook,
    name: 'faSwatchbook',
  },
  {
    icon: faSwimmer,
    name: 'faSwimmer',
  },
  {
    icon: faSwimmingPool,
    name: 'faSwimmingPool',
  },
  {
    icon: faSynagogue,
    name: 'faSynagogue',
  },
  {
    icon: faSync,
    name: 'faSync',
  },
  {
    icon: faSyncAlt,
    name: 'faSyncAlt',
  },
  {
    icon: faSyringe,
    name: 'faSyringe',
  },
  {
    icon: faTable,
    name: 'faTable',
  },
  {
    icon: faTableTennis,
    name: 'faTableTennis',
  },
  {
    icon: faTablet,
    name: 'faTablet',
  },
  {
    icon: faTabletAlt,
    name: 'faTabletAlt',
  },
  {
    icon: faTablets,
    name: 'faTablets',
  },
  {
    icon: faTachometerAlt,
    name: 'faTachometerAlt',
  },
  {
    icon: faTag,
    name: 'faTag',
  },
  {
    icon: faTags,
    name: 'faTags',
  },
  {
    icon: faTape,
    name: 'faTape',
  },
  {
    icon: faTasks,
    name: 'faTasks',
  },
  {
    icon: faTaxi,
    name: 'faTaxi',
  },
  {
    icon: faTeeth,
    name: 'faTeeth',
  },
  {
    icon: faTeethOpen,
    name: 'faTeethOpen',
  },
  {
    icon: faTemperatureHigh,
    name: 'faTemperatureHigh',
  },
  {
    icon: faTemperatureLow,
    name: 'faTemperatureLow',
  },
  {
    icon: faTenge,
    name: 'faTenge',
  },
  {
    icon: faTerminal,
    name: 'faTerminal',
  },
  {
    icon: faTextHeight,
    name: 'faTextHeight',
  },
  {
    icon: faTextWidth,
    name: 'faTextWidth',
  },
  {
    icon: faTh,
    name: 'faTh',
  },
  {
    icon: faThLarge,
    name: 'faThLarge',
  },
  {
    icon: faThList,
    name: 'faThList',
  },
  {
    icon: faTheaterMasks,
    name: 'faTheaterMasks',
  },
  {
    icon: faThermometer,
    name: 'faThermometer',
  },
  {
    icon: faThermometerEmpty,
    name: 'faThermometerEmpty',
  },
  {
    icon: faThermometerFull,
    name: 'faThermometerFull',
  },
  {
    icon: faThermometerHalf,
    name: 'faThermometerHalf',
  },
  {
    icon: faThermometerQuarter,
    name: 'faThermometerQuarter',
  },
  {
    icon: faThermometerThreeQuarters,
    name: 'faThermometerThreeQuarters',
  },
  {
    icon: faThumbsDown,
    name: 'faThumbsDown',
  },
  {
    icon: faThumbsUp,
    name: 'faThumbsUp',
  },
  {
    icon: faThumbtack,
    name: 'faThumbtack',
  },
  {
    icon: faTicketAlt,
    name: 'faTicketAlt',
  },
  {
    icon: faTimes,
    name: 'faTimes',
  },
  {
    icon: faTimesCircle,
    name: 'faTimesCircle',
  },
  {
    icon: faTint,
    name: 'faTint',
  },
  {
    icon: faTintSlash,
    name: 'faTintSlash',
  },
  {
    icon: faTired,
    name: 'faTired',
  },
  {
    icon: faToggleOff,
    name: 'faToggleOff',
  },
  {
    icon: faToggleOn,
    name: 'faToggleOn',
  },
  {
    icon: faToilet,
    name: 'faToilet',
  },
  {
    icon: faToiletPaper,
    name: 'faToiletPaper',
  },
  {
    icon: faToolbox,
    name: 'faToolbox',
  },
  {
    icon: faTools,
    name: 'faTools',
  },
  {
    icon: faTooth,
    name: 'faTooth',
  },
  {
    icon: faTorah,
    name: 'faTorah',
  },
  {
    icon: faToriiGate,
    name: 'faToriiGate',
  },
  {
    icon: faTractor,
    name: 'faTractor',
  },
  {
    icon: faTrademark,
    name: 'faTrademark',
  },
  {
    icon: faTrafficLight,
    name: 'faTrafficLight',
  },
  {
    icon: faTrain,
    name: 'faTrain',
  },
  {
    icon: faTram,
    name: 'faTram',
  },
  {
    icon: faTransgender,
    name: 'faTransgender',
  },
  {
    icon: faTransgenderAlt,
    name: 'faTransgenderAlt',
  },
  {
    icon: faTrash,
    name: 'faTrash',
  },
  {
    icon: faTrashAlt,
    name: 'faTrashAlt',
  },
  {
    icon: faTrashRestore,
    name: 'faTrashRestore',
  },
  {
    icon: faTrashRestoreAlt,
    name: 'faTrashRestoreAlt',
  },
  {
    icon: faTree,
    name: 'faTree',
  },
  {
    icon: faTrophy,
    name: 'faTrophy',
  },
  {
    icon: faTruck,
    name: 'faTruck',
  },
  {
    icon: faTruckLoading,
    name: 'faTruckLoading',
  },
  {
    icon: faTruckMonster,
    name: 'faTruckMonster',
  },
  {
    icon: faTruckMoving,
    name: 'faTruckMoving',
  },
  {
    icon: faTruckPickup,
    name: 'faTruckPickup',
  },
  {
    icon: faTshirt,
    name: 'faTshirt',
  },
  {
    icon: faTty,
    name: 'faTty',
  },
  {
    icon: faTv,
    name: 'faTv',
  },
  {
    icon: faUmbrella,
    name: 'faUmbrella',
  },
  {
    icon: faUmbrellaBeach,
    name: 'faUmbrellaBeach',
  },
  {
    icon: faUnderline,
    name: 'faUnderline',
  },
  {
    icon: faUndo,
    name: 'faUndo',
  },
  {
    icon: faUndoAlt,
    name: 'faUndoAlt',
  },
  {
    icon: faUniversalAccess,
    name: 'faUniversalAccess',
  },
  {
    icon: faUniversity,
    name: 'faUniversity',
  },
  {
    icon: faUnlink,
    name: 'faUnlink',
  },
  {
    icon: faUnlock,
    name: 'faUnlock',
  },
  {
    icon: faUnlockAlt,
    name: 'faUnlockAlt',
  },
  {
    icon: faUpload,
    name: 'faUpload',
  },
  {
    icon: faUser,
    name: 'faUser',
  },
  {
    icon: faUserAlt,
    name: 'faUserAlt',
  },
  {
    icon: faUserAltSlash,
    name: 'faUserAltSlash',
  },
  {
    icon: faUserAstronaut,
    name: 'faUserAstronaut',
  },
  {
    icon: faUserCheck,
    name: 'faUserCheck',
  },
  {
    icon: faUserCircle,
    name: 'faUserCircle',
  },
  {
    icon: faUserClock,
    name: 'faUserClock',
  },
  {
    icon: faUserCog,
    name: 'faUserCog',
  },
  {
    icon: faUserEdit,
    name: 'faUserEdit',
  },
  {
    icon: faUserFriends,
    name: 'faUserFriends',
  },
  {
    icon: faUserGraduate,
    name: 'faUserGraduate',
  },
  {
    icon: faUserInjured,
    name: 'faUserInjured',
  },
  {
    icon: faUserLock,
    name: 'faUserLock',
  },
  {
    icon: faUserMd,
    name: 'faUserMd',
  },
  {
    icon: faUserMinus,
    name: 'faUserMinus',
  },
  {
    icon: faUserNinja,
    name: 'faUserNinja',
  },
  {
    icon: faUserNurse,
    name: 'faUserNurse',
  },
  {
    icon: faUserPlus,
    name: 'faUserPlus',
  },
  {
    icon: faUserSecret,
    name: 'faUserSecret',
  },
  {
    icon: faUserShield,
    name: 'faUserShield',
  },
  {
    icon: faUserSlash,
    name: 'faUserSlash',
  },
  {
    icon: faUserTag,
    name: 'faUserTag',
  },
  {
    icon: faUserTie,
    name: 'faUserTie',
  },
  {
    icon: faUserTimes,
    name: 'faUserTimes',
  },
  {
    icon: faUsers,
    name: 'faUsers',
  },
  {
    icon: faUsersCog,
    name: 'faUsersCog',
  },
  {
    icon: faUtensilSpoon,
    name: 'faUtensilSpoon',
  },
  {
    icon: faUtensils,
    name: 'faUtensils',
  },
  {
    icon: faVectorSquare,
    name: 'faVectorSquare',
  },
  {
    icon: faVenus,
    name: 'faVenus',
  },
  {
    icon: faVenusDouble,
    name: 'faVenusDouble',
  },
  {
    icon: faVenusMars,
    name: 'faVenusMars',
  },
  {
    icon: faVial,
    name: 'faVial',
  },
  {
    icon: faVials,
    name: 'faVials',
  },
  {
    icon: faVideo,
    name: 'faVideo',
  },
  {
    icon: faVideoSlash,
    name: 'faVideoSlash',
  },
  {
    icon: faVihara,
    name: 'faVihara',
  },
  {
    icon: faVolleyballBall,
    name: 'faVolleyballBall',
  },
  {
    icon: faVolumeDown,
    name: 'faVolumeDown',
  },
  {
    icon: faVolumeMute,
    name: 'faVolumeMute',
  },
  {
    icon: faVolumeOff,
    name: 'faVolumeOff',
  },
  {
    icon: faVolumeUp,
    name: 'faVolumeUp',
  },
  {
    icon: faVoteYea,
    name: 'faVoteYea',
  },
  {
    icon: faVrCardboard,
    name: 'faVrCardboard',
  },
  {
    icon: faWalking,
    name: 'faWalking',
  },
  {
    icon: faWallet,
    name: 'faWallet',
  },
  {
    icon: faWarehouse,
    name: 'faWarehouse',
  },
  {
    icon: faWater,
    name: 'faWater',
  },
  {
    icon: faWeight,
    name: 'faWeight',
  },
  {
    icon: faWeightHanging,
    name: 'faWeightHanging',
  },
  {
    icon: faWheelchair,
    name: 'faWheelchair',
  },
  {
    icon: faWifi,
    name: 'faWifi',
  },
  {
    icon: faWind,
    name: 'faWind',
  },
  {
    icon: faWindowClose,
    name: 'faWindowClose',
  },
  {
    icon: faWindowMaximize,
    name: 'faWindowMaximize',
  },
  {
    icon: faWindowMinimize,
    name: 'faWindowMinimize',
  },
  {
    icon: faWindowRestore,
    name: 'faWindowRestore',
  },
  {
    icon: faWineBottle,
    name: 'faWineBottle',
  },
  {
    icon: faWineGlass,
    name: 'faWineGlass',
  },
  {
    icon: faWineGlassAlt,
    name: 'faWineGlassAlt',
  },
  {
    icon: faWonSign,
    name: 'faWonSign',
  },
  {
    icon: faWrench,
    name: 'faWrench',
  },
  {
    icon: faXRay,
    name: 'faXRay',
  },
  {
    icon: faYenSign,
    name: 'faYenSign',
  },
  {
    icon: faYinYang,
    name: 'faYinYang',
  },
]

export default icons

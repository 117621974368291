import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const SpfRecord = ({ data }) => {
  return (
    <>
      {data && data?.spfRecord ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.spfRecord?.data?.testResult?.status ? (
                ''
              ) : data?.spfRecord?.data?.testResult?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.spfRecord?.data?.testResult?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              {' '}
              <p>{data?.spfRecord?.data?.testResult?.message}</p>{' '}
            </div>
          </div>

          {data?.spfRecord?.data?.testResult?.status !== 1 ? <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div className="mt-3">
              <h6>MX records</h6>
              <p>
                {data?.spfRecord?.data?.finalData?.mxData?.provider
                  ? data?.spfRecord?.data?.finalData?.mxData?.provider
                  : ''}
              </p>
              <p>
                {data?.spfRecord?.data?.finalData?.mxData?.test
                  ? data?.spfRecord?.data?.finalData?.mxData?.test
                  : ''}
              </p>
              <ul>
                {data?.spfRecord?.data?.finalData?.mxData?.domainData &&
                  data?.spfRecord?.data?.finalData?.mxData?.domainData?.length
                  ? data?.spfRecord?.data?.finalData?.mxData?.domainData?.map((item, index) => {
                    return (
                      <li key={`spfRecordDomainData-${index+1}`}>
                        {' '}
                        Hostname: {item.value.host} <br />
                        IP Address: {item.value.ipAddress} <br />
                        TTL: {item.value.ttl}
                      </li>
                    )
                  })
                  : ''}
              </ul>
              <ul>
                {data?.spfRecord?.data?.finalData?.mxData?.valueData &&
                  data?.spfRecord?.data?.finalData?.mxData?.valueData?.length
                  ? data?.spfRecord?.data?.finalData?.mxData?.valueData?.map((item, index) => {
                    return (
                      <li key={`spfRecordValueData-${index+1}`}>
                        {' '}
                        {item.key} : {item.value}
                      </li>
                    )
                  })
                  : ''}
              </ul>
            </div>
          </div> : null}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SpfRecord

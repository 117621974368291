import { useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const JSMinification = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [jsMinificationFlag, setJsMinification] = useState(false)

  const columns = [
    {
    id:'nonMinifiedJSUrl',
    displayName:'Non Minified JS'
  }
]

const jsMinificationCsv = data?.jsMinification?.data?.jsMinificationResults?.items?.map(item => {
  return {
    ['Non Minified JS']: item?.url
  }
})
  return (
    <>
      {/* <h5>{data?.url}</h5>
        <p>{data?.jsMinification?.data?.nonMinifiedJs?.length ? "Ops! Your page is not serving minified javascript files" : "Congratulations! Your page does not use HTML deprecated tags."}</p>

        {showDetail ? <>
            <p className="text-primary cursor-pointer" onClick={onShowDetail}> See Details</p>
        </> : <></>} */}

      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.jsMinification?.data?.testResults?.status ? (
            ''
          ) : data?.jsMinification?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.jsMinification?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
          {data?.jsMinification?.data?.testResults ? (
            <>{data?.jsMinification?.data?.testResults?.message || ''}</>
          ) : (
            <></>
          )}
        </p>

        {showDetail &&
        data?.jsMinification?.data?.testResults?.status !== 1 &&
        data?.jsMinification?.data?.jsMinificationResults?.items &&
        data.jsMinification?.data?.jsMinificationResults?.items?.length ? (
          <>
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                if (testType === "") {
                  setJsMinification(!jsMinificationFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={jsMinificationFlag}
        toggleShow={() => setJsMinification(!jsMinificationFlag)}
        title={'Full list of JS files'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns={columns}
        datas = {jsMinificationCsv}
        fileName='JavaScript Minification Signal Test'
        alternativeDownload={true}
      >
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Non Minified JS</th>
            </tr>
          </thead>
          <tbody>
            {data?.jsMinification?.data?.jsMinificationResults?.items.length &&
              data?.jsMinification?.data?.jsMinificationResults?.items.map((item, index) => {
                return (
                  <tr key={`JSMinification-${index+1}`}>
                    <td>{item?.url}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ModalHOC>
    </>
  )
}

export default JSMinification

import React, { useState } from 'react'

import { Button, ButtonGroup, Col, Row, Tab, Modal } from 'react-bootstrap'

const PricingPlan = (props) => {
  const [activeTab, setActiveTab] = useState('Monthly')

  return (
    <React.Fragment>
      <Modal show={props.isOpen} onHide={() => props.toggle()} size={'lg'}>
        <Modal.Header closeButton>
          <h3 className='mb-0'>Choose your free trial plan</h3>
        </Modal.Header>
        <Modal.Body className="m-3">
          <Tab.Container id="pricing-tabs" activeKey={activeTab}>
            <Row className="justify-content-center mt-3 mb-2">
              <Col xs="auto">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    onClick={() => setActiveTab('Monthly')}
                    className={activeTab === 'Monthly' ? 'active' : ''}
                  >
                    Monthly billing
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setActiveTab('Yearly')}
                    className={activeTab === 'Yearly' ? 'active' : ''}
                  >
                    Annual billing
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            <Tab.Content>
              <Tab.Pane eventKey="Monthly">
                <Row className="py-4">
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 text-left plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>Enterprise</h5>
                      <h3 className={'plan-price'}>$89.95<span className='fs-3' style={{color:'#3b82f6'}}>/mo</span></h3>
                      <p>$80.95/mo billed yearly, saved 10%</p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">15</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          props.changeExisitingPlan({
                            planName: 'Enterprise',
                            planPrice: '$89.95',
                            planType: 'Monthly',
                          })
                          // props.setPlanName('Enterprise');
                          // props.setPlanPrice('$89.95');
                          // props.setPlanDuration('Monthly');
                          // props.toggle()
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>Business Pro</h5>
                      <h3 className={'plan-price'}>$49.95<span className='fs-3' style={{color:'#3b82f6'}}>/mo</span></h3>
                      <p>$44.95/mo billed yearly, saved 10%</p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          // props.setPlanName('Business Pro');
                          // props.setPlanPrice('$ 49.95');
                          // props.setPlanDuration('Monthly');
                          // props.toggle()
                          props.changeExisitingPlan({
                            planName: 'Business Pro',
                            planPrice: '$ 49.95',
                            planType: 'Monthly',
                          })
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0 plantable" style={{ border: '1px solid #cfcfcf' }}>
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>Business Plus</h5>
                      <h3 className={'plan-price'}>$29.95<span className='fs-3' style={{color:'#3b82f6'}}>/mo</span></h3>
                      <p>$26.95/mo billed yearly, saved 10%</p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">NOT INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          // props.setPlanName('Business Plus');
                          // props.setPlanPrice('$ 29.95');
                          // props.setPlanDuration('Monthly');
                          // props.toggle()
                          props.changeExisitingPlan({
                            planName: 'Business Plus',
                            planPrice: '$ 29.95',
                            planType: 'Monthly',
                          })
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="Yearly">
                <Row className="py-4">
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>Enterprise</h5>
                      <h3 className={'plan-price'}>$80.95<span className='fs-3' style={{color:'#3b82f6'}}>/mo</span></h3>
                      <p>billed yearly, 10% off, $971.46/year</p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">15</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          // props.setPlanName('Enterprise');
                          // props.setPlanPrice('$80.95');
                          // props.setPlanDuration('Yearly');
                          // props.toggle()

                          props.changeExisitingPlan({
                            planName: 'Enterprise',
                            planPrice: '$ 80.95',
                            planType: 'Monthly',
                          })
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>Business Pro</h5>
                      <h3 className={'plan-price'}>$44.95<span className='fs-3' style={{color:'#3b82f6'}}>/mo</span></h3>
                      <p>billed yearly, 10% off, $539.46/year</p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          // props.setPlanName('Business Pro');
                          // props.setPlanPrice('$ 44.95');
                          // props.setPlanDuration('Yearly');
                          // props.toggle()
                          props.changeExisitingPlan({
                            planName: 'Business Pro',
                            planPrice: '$ 44.95',
                            planType: 'Yearly',
                          })
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0 plantable" style={{ border: '1px solid #cfcfcf' }}>
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>Business Plus</h5>
                      <h3 className={'plan-price'}>$26.95<span className='fs-3' style={{color:'#3b82f6'}}>/mo</span></h3>
                      <p>billed yearly, 10% off, $323.46/year</p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">NOT INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          // props.setPlanName('Business Plus');
                          // props.setPlanPrice('$ 26.95');
                          // props.setPlanDuration('Yearly');
                          // props.toggle()
                          props.changeExisitingPlan({
                            planName: 'Business Plus',
                            planPrice: '$ 26.95',
                            planType: 'Yearly',
                          })
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default PricingPlan

import { put, takeEvery, all, takeLatest } from 'redux-saga/effects'

import {
  TRACK_KEYWORDS_SUCCESS,
  TRACK_KEYWORDS_FAILURE,
  GET_TRACK_KEYWORDS,
  KEYWORD_POSITION_HISTORY_SUCCESS,
  KEYWORD_POSITION_HISTORY_FAILURE,
  KEYWORD_POSITION_HISTORY,
} from '../../actionType'

// api
import { getRequest } from '../../../constants/httpHelperService'
const getTrackKeywordApi = async (input) => {
  return await getRequest(`keywords/get-keywords`)
    .then((res) => res)
    .catch((error) => error)
}

function* getTrackKeywordData(action) {
  try {
    const data = yield getTrackKeywordApi(action.payload)
    yield put({ type: TRACK_KEYWORDS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: TRACK_KEYWORDS_FAILURE, e })
  }
}

function* getTrackKeywordSaga() {
  yield takeEvery(GET_TRACK_KEYWORDS, getTrackKeywordData)
}

const keywordPositionHistoryApi = async (input) => {
  return await getRequest(`keyword-position-history`)
  .then((res) => {
    return res
  })
  .catch((error) => error)  
}

function* keywordPositionHistoryData(action) {
  try {
    const data = yield keywordPositionHistoryApi(action?.payload)
    yield put({ type: KEYWORD_POSITION_HISTORY_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: KEYWORD_POSITION_HISTORY_FAILURE, e })
  }
}

function* keywordPositionHistorySaga() {
  yield takeLatest(KEYWORD_POSITION_HISTORY, keywordPositionHistoryData)
}

export default function* keywordsTrackingSaga() {
  yield all([
    getTrackKeywordSaga(),
    keywordPositionHistorySaga()
  ])
}

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const SocialMediaTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.socialMediaTest.heading}
      whatIsIT={freeTools.socialMediaTest.what}
      howToFixBeforeCode={freeTools.socialMediaTest.howSectionBeforeCode}  
    />
  )
}

export default SocialMediaTest

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import pmsLogo from 'assets/img/logo/logo.png'
import './header.scss'
import { useDeviceCheck } from 'hooks/deviceHook'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const Header = ({
  customClassName,
  isEmailMobile = false,
  isEmailLaptop = false,
  isDemoReportPage = false,
}) => {
  const registerData = useSelector(({ register }) => register.registerData)
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const token = localStorage.getItem('token')

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible)
  }

  return (
    <div
      className={`text-center main-header ${isDemoReportPage ? customClassName : ''}`}
      style={{ padding: isMobile ? '' : registerData ? '0 20px 0 20px' : '0 40px 0 40px' }}
    >
      <div className="main-header-logo-container">
        <img src={pmsLogo} alt="positionmysite" className="main-header-logo" onClick={() => navigate('/')} />
      </div>
      {isMobile && isSidebarVisible && (
        <div className="mobile-sidebar">
          <span className="main-header-mobile-tab" onClick={() => navigate('/pricing')}>
            Pricing
          </span>
          <span className="main-header-mobile-tab" onClick={() => navigate('/tools')}>
            Free Tools
          </span>
          <span className="main-header-mobile-tab" onClick={() => navigate('/articles')}>
            SEO Help Articles
          </span>
          {isEmailMobile && (
            <span className="main-header-mobile-tab">
              {registerData?.email || ''} | <Link to="/"> &nbsp; Logout</Link>
            </span>
          )}
          {isDemoReportPage && !token && (
            <span className="main-header-mobile-tab">
              <Link to="/sign-in"> &nbsp; Login/Sign up</Link>
            </span>
          )}
        </div>
      )}
      {isMobile ? (
        <>
          <FontAwesomeIcon
            icon={faBars}
            onClick={toggleSidebar}
            className="align-self-center main-header-icon"
          />
        </>
      ) : (
        <div className={`main-header-tab ${isDemoReportPage ? 'd-flex align-items-center' : ''}`}>
          <span className="cursor-pointer" onClick={() => navigate('/pricing')}>
            Pricing
          </span>
          <span className="cursor-pointer" onClick={() => navigate('/tools')}>
            Free Tools
          </span>
          <span className="cursor-pointer" onClick={() => navigate('/articles')}>
            SEO Help Articles
          </span>
          {isEmailLaptop && (
            <Col className="pyament-card-logout">
              {registerData?.email || ''} | <Link to="/"> &nbsp; Logout</Link>
            </Col>
          )}
          {isDemoReportPage && !token ? (
            <Button size="lg" variant="outline-primary" onClick={() => navigate('/sign-in')}>
              Login/Sign up
            </Button>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default Header

import { GoogleOAuthProvider } from '@react-oauth/google'
import React from 'react'

const GoogleAuth = ({ children }) => {
  return (
    // <GoogleOAuthProvider clientId='74542118637-c261k854oj1m08ov29tcvrsvkcbrnhpm.apps.googleusercontent.com'>
    <GoogleOAuthProvider clientId='609712934271-i2ip35olbvb3ioiok7spomn3ra9neke1.apps.googleusercontent.com'>
        {children}
    </GoogleOAuthProvider>
  )
}

export default GoogleAuth

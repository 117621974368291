import * as Yup from 'yup'
import { useFormik } from 'formik'

import 'modules/Free tools/components/CommonFreeTool.scss'
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trialSeoCheckup } from 'redux/actions/freetrial/startFreeTrial'
import { useNavigate, useParams } from 'react-router-dom'
import { TEST_TYPES } from 'constants/enum'
import { message } from 'antd'
import { CLEAR_TRIAL_SEO_CHECKUP } from 'redux/actionType'
import { encryptData } from 'constants/Utils'

const SpfRecordsTest = () => {
  const { 
    trialSeoCheckupLoader, 
    trialSeoCheckupSuccess,
    trialFailureMessage 
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { 'test-type': type } = useParams()

  const URL_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)*$/
  const testType = TEST_TYPES?.[type] || 'ALL'

  const [isAddCompetitor, setIsAddCompetitor] = useState(false)
  const [checkupUrl, setCheckupUrl] = useState({})
  const initialValues = {
    url: '',
    competitorUrl: '',
  }

  const handleFreeToolSubmit = (values) => {
    const encryptMainUrl = encryptData(values.url)
    if (values?.competitorUrl) {
      const encryptCompetitorUrl = encryptData(values.competitorUrl)
      setCheckupUrl({mainUrl: encryptMainUrl, competitorUrl: encryptCompetitorUrl})
    } else {
      setCheckupUrl({ mainUrl: encryptMainUrl })
    }
  }

  useEffect(() => {
    if (!trialSeoCheckupLoader && trialSeoCheckupSuccess) {
      if (checkupUrl?.competitorUrl) {
        const path = `/seo-report/${type}/${checkupUrl?.mainUrl}/${checkupUrl?.competitorUrl}`
        navigate(path, { state: { mainUrl: toolsFormik.values.url } })
      } else {
        const path = `/seo-report/${type}/${checkupUrl?.mainUrl}`
        navigate(path, { state: { mainUrl: toolsFormik.values.url } })
      }
    } else if (trialFailureMessage) {
      if (trialFailureMessage?.message !== 'You have exceded your weekly limit') {
        message.error(trialFailureMessage?.message)
      }
      dispatch({ type: CLEAR_TRIAL_SEO_CHECKUP })
    }
  }, [trialSeoCheckupSuccess, trialSeoCheckupLoader, trialFailureMessage])

  useEffect(() => {
    if (checkupUrl?.mainUrl) {
      if (checkupUrl?.competitorUrl) {
        const payload = { type: testType, url: checkupUrl?.mainUrl, competitor: checkupUrl?.competitorUrl }
        dispatch(trialSeoCheckup(payload))
      } else {
        const payload = { type: testType, url: checkupUrl?.mainUrl }
        dispatch(trialSeoCheckup(payload))
      }
    }
  }, [checkupUrl])

  const urlValidationSchema = Yup.object().shape({
    url: Yup.string().trim().required('Please provide url').matches(URL_REGEX, 'Invalid format'),
    competitorUrl: isAddCompetitor
      ? Yup.string().trim().required('Please provide url').matches(URL_REGEX, 'Invalid format')
      : null,
  })

  const toolsFormik = useFormik({
    initialValues,
    validationSchema: urlValidationSchema,
    onSubmit: handleFreeToolSubmit,
  })

  const { values, handleChange, handleSubmit, errors, touched } = toolsFormik
  return (
    <div className="tools-wrapper">
      <h1 className="tools-heading">SPF Records Test</h1>
      <Form className="tools-form" onSubmit={handleSubmit}>
        <Form.Group className="tools-form-group">
          <div className="d-flex align-items-center gap-1">
            <Form.Control
              type="text"
              name="url"
              value={values.url}
              onChange={handleChange}
              isInvalid={!!errors.url}
              placeholder={'Website URL'}
              className="tools-form-input tools-form-url"
              disabled={trialSeoCheckupLoader}
            />
            {!isAddCompetitor ? (
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Add competitor url</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: '#3F80EA', fontSize: '24px', cursor: 'pointer' }}
                  onClick={() => setIsAddCompetitor(true)}
                />
              </OverlayTrigger>
            ) : null}
          </div>

          {!!touched.url && (
            <span className="fs-6" style={{ color: 'red' }}>
              {errors.url}
            </span>
          )}
        </Form.Group>
        {isAddCompetitor ? (
          <Form.Group>
            <Form.Control
              type="text"
              name="competitorUrl"
              value={values.competitorUrl}
              onChange={handleChange}
              isInvalid={!!errors.competitorUrl}
              placeholder={'Competitor URL'}
              className="tools-form-input tools-form-competitor"
              disabled={trialSeoCheckupLoader}
            />
            {!!touched.competitorUrl && (
              <span className="fs-6" style={{ color: 'red' }}>
                {errors.competitorUrl}
              </span>
            )}
          </Form.Group>
        ) : null}
        <Button
          disabled={trialSeoCheckupLoader}
          type="submit"
          variant="primary"
          className={`d-flex align-items-center gap-1 ${isAddCompetitor ? 'tools-submit-btn' : ''}`}
        >
          Checkup
          {trialSeoCheckupLoader ? <Spinner animation="border" key={1} size="sm" /> : null}
        </Button>
      </Form>
      <div className="tools-info">
        <h2 className="tools-info-heading">What is it?</h2>
        <p className="tools-info-description">
          Check if your DNS records contains an SPF record. SPF (Sender Policy Framework) records allow email
          systems to verify if a<br /> given mail server has been authorized to send mail on behalf of your
          domain. Creating an SPF record increases email delivery
          <br /> rates by reducing the likelihood of your email being marked as spam.
        </p>
      </div>
      <div className="tools-solution">
        <h2 className="tools-solution-heading">How do I fix it ?</h2>
        <p className="tools-solution-description">
          An SPF record is a type of Domain Name Service (DNS) record that allows email systems to check if
          the sender of a message
          <br /> comes from a legitimate source and refuse an email if the source is not legitimate. Adding an
          SPF record is as easy as adding
          <br /> CNAME, MX or A records in your DNS zone. You can find more information here.
          <br />
          <br />
          Before creating the SPF record for your domain, it is important to have access at your domain's DNS
          zone and to know what
          <br /> mail servers your domain is likely to use and plan how you want any non-authorized email to
          be handled.
          <br />
          <br />
          Example: Let's say that you are planning to send emails using Google Apps and you also want to
          ensure that no other mail servers are
          <br /> authorized. You can use an SPF record like this:
          <div className="tools-code">{'v=spf1 include:_spf.google.com -all'}</div>
          "v=spf1" - This sets the SPF version
          <br />
          <br />
          "include:_spf.google.com" - This includes Google mail servers in your list of authorized sending
          servers
          <br />
          <br />
          "-all" - This means that any server not previously listed is not authorized
          <br />
          <br />
          If you are using your own VPS to send email and not any other service like Mandrill, Google Apps,
          etc. then you can create an SPF record like this:
          <div className="tools-code">{'v=spf1 mx -all'}</div>
        </p>
      </div>
    </div>
  )
}

export default SpfRecordsTest

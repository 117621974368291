// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth'
import DashboardLayout from './layouts/Dashboard'
import DocLayout from './layouts/Doc'
import TrailLayout from './layouts/Trail'

// Landing
import Landing from './pages/landing/Landing'

// Dashboards
import SaaS from './pages/dashboards/SaaS'
import Social from './pages/dashboards/Social'
import SocialInstagram from './pages/dashboards/Social-instagram'
import DashboardSettings from './pages/dashboards/Settings/Settings'
import SiteSpeed from './pages/dashboards/SiteSpeed/SiteSpeed'

// Pages
import QuickSEOCheckup from './pages/free-trial/quick-seo-checkup'
import MyAccount from 'pages/pages/MyAccount'
import Billing from 'pages/pages/Billing'

// Auth
import Page500 from './pages/auth/Page500'
import Page404 from './pages/auth/Page404'
import SignIn from './pages/auth/SignIn'
import SignUp from './pages/auth/SignUp'
import ResetPassword from './pages/auth/ResetPassword'
import WelcomeOnBoard from './pages/auth/WelcomeOnboard'
import VerifyUser from './pages/auth/VerifyUser'

// UI components
import Alerts from './pages/ui/Alerts'
import Buttons from './pages/ui/Buttons'
import Cards from './pages/ui/Cards'
import Carousel from './pages/ui/Carousel'
import EmbedVideo from './pages/ui/EmbedVideo'
import General from './pages/ui/General'
import Grid from './pages/ui/Grid'
import Modals from './pages/ui/Modals'
import Offcanvas from './pages/ui/Offcanvas'
import Tabs from './pages/ui/Tabs'
import Typography from './pages/ui/Typography'

// Icons
import Feather from './pages/icons/Feather'
import FontAwesome from './pages/icons/FontAwesome'

// Documentation
import Introduction from './pages/docs/Introduction'
import GettingStarted from './pages/docs/GettingStarted'
import Routing from './pages/docs/Routing'
import Auth0 from './pages/docs/auth/Auth0'
import Cognito from './pages/docs/auth/Cognito'
import Firebase from './pages/docs/auth/Firebase'
import JWT from './pages/docs/auth/JWT'
import Guards from './pages/docs/Guards'
import APICalls from './pages/docs/APICalls'
import Redux from './pages/docs/Redux'
import Internationalization from './pages/docs/Internationalization'
import EnvironmentVariables from './pages/docs/EnvironmentVariables'
import ESLintAndPrettier from './pages/docs/ESLintAndPrettier'
import Deployment from './pages/docs/Deployment'
import MigratingToNextJS from './pages/docs/MigratingToNextJS'
import Support from './pages/docs/Support'
import Changelog from './pages/docs/Changelog'

// Protected routes
import ChangePassword from './pages/auth/ChangePassword'
import ReportsTable from './pages/dashboards/ReportsTable'
import BackLinksNew from './pages/dashboards/Backlinks/index'
import KeywordTracker from './pages/dashboards/KeywordTracker/index'
import AddSocialHandle from './pages/trial/new-flow/add-socialhandle'
import Authentication from './pages/trial/new-flow/add-authentication'
import TermsOfUse from './pages/pages/termsOfUse'
import NewTopKeywords from './pages/dashboards/TopKeywords/newIndex'
import DashboardRevised from './pages/dashboards/DashboardRevised'
import CheckPage from './pages/free-trial/check'
import BrokenLinks from './pages/dashboards/BrokenLinks'
import SettingsComponentOutage from './pages/dashboards/SiteOutage/siteOutage'
import GoogleResearch from 'pages/dashboards/GoogleSearchConsole/index.js'
import TrafficAnalytics from 'pages/dashboards/TrafficAnalytics/index.jsx'

// Reporting
import { WhiteLabelTabs } from './pages/reporting/white-label/tabs'
import { TemplateForms } from './pages/reporting/white-label/templates/templateForms'

import FirewallOptions from './pages/firewallOptions'
import KeywordsEverywhere from 'pages/dashboards/KeywordEverywhere'
import AddWebsiteTrial from 'modules/welcomeBoard/components/addWebsite/AddWebsiteTrial'
import SelectWebsiteTrial from 'modules/welcomeBoard/components/selectWebsite/SelectWebsiteTrial.jsx'
import SelectWebsiteDashboard from 'modules/welcomeBoard/components/selectWebsite/SelectWebsiteDashboard.jsx'
import AddWebsiteDashboard from 'pages/addWebsite/AddWebsiteDashboard'
import AddLocationDashboard from 'modules/welcomeBoard/components/addLocation/AddLocationDashboard.jsx'
import AddKeywordsTrial from 'modules/welcomeBoard/components/addKeyword/AddKeywordsTrial.jsx'
import AddKeywordsDashboard from 'modules/welcomeBoard/components/addKeyword/AddKeywordsDashboard.jsx'
import AddCompetitorsDashboard from 'modules/welcomeBoard/components/addCompetitor/AddCompetitorsDashboard.jsx'
import AddCompetitorsTrial from 'modules/welcomeBoard/components/addCompetitor/AddCompetitorsTrial.jsx'
import AddSitemapsTrial from 'modules/welcomeBoard/components/addSitemap/AddSitemapsTrial.jsx'
import AddSitemapsDashboard from 'modules/welcomeBoard/components/addSitemap/AddSitemapsDashboard.jsx'
import AddLocationTrial from 'modules/welcomeBoard/components/addLocation/AddLocationTrial.jsx'
import FirewallTrial from 'pages/addWebsite/FireWallTrial'
import SubscpriptionPlans from 'modules/subscriptionPlans/SubscpriptionPlans'
import RegisterLayout from 'layouts/RegisterLayout'
import TrailLayoutWitContainer from 'layouts/TrailLayoutWithContainer'
import NewWelcomeOnBoard from 'modules/welcomeBoard/welcomeBoard'
import DashboardWebsiteFlowLayout from 'layouts/DashboardWebsiteFlow'
import UpgradePlan from 'pages/auth/UpgradePlan'
import TrialSEOCheckup from 'modules/trialSEOCheckup/TrialSEOCheckup.jsx'
import FreeTool from 'modules/Free tools/FreeTool'
import FreeToolsList from 'modules/Free tools/FreeToolsList'
import AddGoogleTrial from 'modules/welcomeBoard/components/addGoogle/AddGoogleTrial'
import AddGoogleDashboard from 'modules/welcomeBoard/components/addGoogle/AddGoogleDashboard'
import ContactUs from 'pages/contact-us'
import { CoreWebVitals } from 'modules/CoreWebVitals'
import FreeAccount from 'modules/Free tools/components/FreeAccount'

const routes = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <Landing />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: 'saas',
        element: <SaaS />,
      },
      {
        path: 'social',
        element: <Social />,
      },
      {
        path: 'settings',
        element: <DashboardSettings />,
      },
      {
        path: 'site-speed',
        element: <SiteSpeed />,
      },
      {
        path: '/dashboard/seo/analysis',
        element: <ReportsTable />,
      },
      {
        path: '/dashboard/new-top-keywords',
        element: <NewTopKeywords />,
      },
      {
        path: '/dashboard/keyword-tracker',
        element: <KeywordTracker />,
      },
      {
        path: '/dashboard/keyword-research',
        element: <KeywordsEverywhere />,
      },
      {
        path: '/dashboard/revised',
        element: <DashboardRevised />,
      },
      {
        path: '/dashboard/broken-links',
        element: <BrokenLinks />,
      },
      {
        path: '/dashboard/google-search-console',
        element: <GoogleResearch />,
      },
      {
        path: '/dashboard/traffic-analytics',
        element: <TrafficAnalytics />,
      },
      {
        path: '/dashboard/seo/analysis/report',
        element: <CheckPage />,
      },
      {
        path: '/dashboard/upgrade-plan',
        element: <UpgradePlan />,
      },
      {
        path: '/dashboard/contact-us',
        element: <ContactUs />,
      },
      {
        path: '/dashboard/core-web-vitals',
        element: <CoreWebVitals />,
      },
    ],
  },
  {
    path: 'account',
    element: <DashboardLayout />,
    children: [
      {
        path: '/account/profile',
        element: <MyAccount />,
      },
      {
        path: '/account/billing',
        element: <Billing />,
      },
    ],
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        path: '/backlinks',
        element: <BackLinksNew />,
      },
    ],
  },
  {
    path: 'pages',
    element: <DashboardLayout />,
    children: [
      {
        path: 'site-outage',
        element: <SettingsComponentOutage />,
      },
      {
        path: 'terms-of-use',
        element: <TermsOfUse />,
      },
      {
        path: 'quick-seo-checkup',
        element: <QuickSEOCheckup />,
      },
      {
        path: 'tweets-analysis',
        element: <Social />,
      },
      {
        path: 'instagram-analysis',
        element: <SocialInstagram />,
      },
      {
        path: 'firewall-options',
        element: <FirewallOptions />,
      },
      {
        path: 'keyword-everywhere',
        element: <KeywordsEverywhere />,
      },
    ],
  },
  {
    path: '/reporting',
    element: <DashboardLayout />,
    children: [
      {
        path: 'white-label/reports',
        element: <WhiteLabelTabs />,
      },
      {
        path: 'white-label/templates',
        element: <WhiteLabelTabs />,
      },
      {
        path: '/reporting/white-label/templates/new',
        element: <TemplateForms />,
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
      {
        path: 'redirecting',
        element: <Page500 />,
      },
      {
        path: 'change-password/:token/:flag',
        element: <ChangePassword />,
      },
      {
        path: 'verify/:token',
        element: <VerifyUser />,
      },
      {
        path: 'terms-of-use',
        element: <TermsOfUse />,
      },
    ],
  },
  {
    path: 'ui',
    element: <DashboardLayout />,
    children: [
      {
        path: 'alerts',
        element: <Alerts />,
      },
      {
        path: 'buttons',
        element: <Buttons />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'carousel',
        element: <Carousel />,
      },
      {
        path: 'embed-video',
        element: <EmbedVideo />,
      },
      {
        path: 'general',
        element: <General />,
      },
      {
        path: 'grid',
        element: <Grid />,
      },
      {
        path: 'modals',
        element: <Modals />,
      },
      {
        path: 'tabs',
        element: <Tabs />,
      },
      {
        path: 'offcanvas',
        element: <Offcanvas />,
      },
      {
        path: 'typography',
        element: <Typography />,
      },
    ],
  },
  {
    path: 'icons',
    element: <DashboardLayout />,
    children: [
      {
        path: 'feather',
        element: <Feather />,
      },
      {
        path: 'font-awesome',
        element: <FontAwesome />,
      },
    ],
  },
  {
    path: 'trial',
    element: <TrailLayout />,
    children: [
      {
        path: 'plans',
        element: <SubscpriptionPlans />,
      },
      {
        path: 'add-authetication',
        element: <Authentication />,
      },
      {
        path: 'add-social',
        element: <AddSocialHandle />,
      },
      {
        path: 'firewall-options',
        element: <FirewallTrial />,
      },
    ],
  },
  {
    path: '',
    element: <TrailLayout />,
    children: [
      {
        path: 'seo-report/:type/:url/:competitorUrl',
        element: <TrialSEOCheckup />,
      },
      {
        path: 'seo-report/:type/:url',
        element: <TrialSEOCheckup />,
      },
      {
        path: 'seo-report/free-account',
        element: <FreeAccount />,
      },
    ],
  },
  {
    path: '',
    element: <TrailLayout />,
    children: [
      {
        path: 'tools',
        element: <FreeToolsList />,
      },
      {
        path: 'tools/:test-type',
        element: <FreeTool />,
      },
    ],
  },
  {
    path: 'docs',
    element: <DocLayout />,
    children: [
      {
        path: 'introduction',
        element: <Introduction />,
      },
      {
        path: 'getting-started',
        element: <GettingStarted />,
      },
      {
        path: 'routing',
        element: <Routing />,
      },
      {
        path: 'auth/auth0',
        element: <Auth0 />,
      },
      {
        path: 'auth/cognito',
        element: <Cognito />,
      },
      {
        path: 'auth/firebase',
        element: <Firebase />,
      },
      {
        path: 'auth/jwt',
        element: <JWT />,
      },
      {
        path: 'guards',
        element: <Guards />,
      },
      {
        path: 'api-calls',
        element: <APICalls />,
      },
      {
        path: 'redux',
        element: <Redux />,
      },
      {
        path: 'internationalization',
        element: <Internationalization />,
      },
      {
        path: 'environment-variables',
        element: <EnvironmentVariables />,
      },
      {
        path: 'eslint-and-prettier',
        element: <ESLintAndPrettier />,
      },
      {
        path: 'deployment',
        element: <Deployment />,
      },
      {
        path: 'migrating-to-next-js',
        element: <MigratingToNextJS />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'changelog',
        element: <Changelog />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
  {
    path: '/',
    element: <RegisterLayout />,

    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
    ],
  },
  {
    path: 'auth',
    element: <RegisterLayout />,

    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
    ],
  },
  {
    path: 'trial',
    element: <TrailLayoutWitContainer />,
    children: [
      {
        path: 'welcome-on-board',
        element: <NewWelcomeOnBoard />,
      },
      {
        path: 'add-website',
        element: <AddWebsiteTrial />,
      },
      {
        path: 'select-website',
        element: <SelectWebsiteTrial />,
      },
      {
        path: 'add-location',
        element: <AddLocationTrial />,
      },
      {
        path: 'add-connections',
        element: <AddGoogleTrial />,
      },
      {
        path: 'add-competitors',
        element: <AddCompetitorsTrial />,
      },
      {
        path: 'add-keywords',
        element: <AddKeywordsTrial />,
      },
      {
        path: 'add-sitemaps',
        element: <AddSitemapsTrial />,
      },
    ],
  },

  {
    path: 'dashboard',
    element: <DashboardWebsiteFlowLayout />,
    children: [
      {
        path: 'add-website',
        element: <AddWebsiteDashboard />,
      },
      {
        path: 'select-website',
        element: <SelectWebsiteDashboard />,
      },
      {
        path: 'add-location',
        element: <AddLocationDashboard />,
      },
      {
        path: 'add-connections',
        element: <AddGoogleDashboard />,
      },
      {
        path: 'add-competitors',
        element: <AddCompetitorsDashboard />,
      },
      {
        path: 'add-keywords',
        element: <AddKeywordsDashboard />,
      },
      {
        path: 'add-sitemaps',
        element: <AddSitemapsDashboard />,
      },
    ],
  },
]

export default routes

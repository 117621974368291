import { put, takeEvery, all } from 'redux-saga/effects'

// action type
import {
  GET_BROKEN_LINKS_LIST,
  GET_BROKEN_LINKS_LIST_SUCCESS,
  GET_BROKEN_LINKS_LIST_FAILURE,
} from '../../actionType'

// api
import { getRequest } from '../../../constants/httpHelperService'

const brokenLinkListApi = async (input) => {
  return await getRequest(`get-broken-links-lists`)
    .then((res) => res)
    .catch((error) => error)
}

function* getbrokenLinkListData(action) {
  try {
    const data = yield brokenLinkListApi(action.payload)
    yield put({ type: GET_BROKEN_LINKS_LIST_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BROKEN_LINKS_LIST_FAILURE, e })
  }
}

function* getbrokenLinkListSaga() {
  yield takeEvery(GET_BROKEN_LINKS_LIST, getbrokenLinkListData)
}

export default function* brokenLinkListSaga() {
  yield all([getbrokenLinkListSaga()])
}

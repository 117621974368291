import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Button, Card, Container, Col, Row, Tab, Nav, Spinner } from 'react-bootstrap'
import { Loader } from 'components/uiCore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import UrlDetail from './Url-detaills'
import SeoScoreProgress from './SeoProgress'
import { useSelector } from 'react-redux'

const DashboardLoading = () => {
  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink || 0)
  const seoProgressPercentage = useSelector(({ dashboard }) => dashboard?.seoProgressPercentage)

  return (
    <React.Fragment>
      <Helmet title="Dashboard Page" />
      {/* Add website Modal */}

      <Container fluid className="p-0 dashboard">
        <Card>
          <Card.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey={1} style={{ width: 295 }}>
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                    <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    <div className="float-end" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey={1}>
                  <Row className={'mb-3 mt-3'}>
                    <Col md="3" className="fixed-height">
                      <UrlDetail item={{}} activeLink={activeLink} />
                    </Col>
                    <Col md="5" className="fixed-height mt-10 mt-md-0">
                      <SeoScoreProgress
                        item={{}}
                        id={''}
                        user={''}
                        SeoProgressPercentage={seoProgressPercentage}
                      />
                    </Col>
                    <Col md="" className="fixed-height mt-4 mt-md-0">
                      <Card className="url-details mb-2">
                        <Card.Header className="url-details-card-header">
                          <Card.Title tag="h5" className="mb-0">
                            Issues to fix
                          </Card.Title>
                          <div className="card-actions float-end">
                            <Button variant="outline-secondary" onClick={() => {}}>
                              View Report{' '}
                              <FontAwesomeIcon className="align-middle me-2" icon={faChevronRight} />
                            </Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                alignSelf: 'center',
                                flexDirection: 'row',
                                minHeight: 380,
                              }}
                            >
                              <div className="text-center">
                                <Loader />
                              </div>
                            </div>
                          </>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className={'mb-0 mt-3'}>
                    <Col md="6" className="fixed-height">
                      <Card className="url-details">
                        <Card.Header className="url-details-card-header">
                          <div className="url-details-title">
                            <Card.Title tag="h5" className="mb-0 f-bold">
                              Backlinks Overview
                            </Card.Title>
                            <p className="mb-0">Over the last 30 days</p>
                          </div>
                          <div className="card-actions float-end">
                            <Button variant="outline-secondary">View All Backlinks</Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                alignSelf: 'center',
                                flexDirection: 'row',
                                minHeight: 380,
                              }}
                            >
                              <div className="text-center">
                                <Loader />
                              </div>
                            </div>
                          </>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6" className="fixed-height">
                      <Card className="url-details">
                        <Card.Header>
                          <div className="card-actions float-end">
                            <Button variant="outline-secondary">
                              View All Keywords{' '}
                              <FontAwesomeIcon className="align-middle me-2" icon={faChevronRight} />
                            </Button>
                          </div>
                          <Card.Title tag="h5" className="mb-0">
                            Top Keywords on SERP
                          </Card.Title>
                          <p className="mb-0">Your progress over the last 7 days</p>
                        </Card.Header>
                        <Card.Body>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                alignSelf: 'center',
                                flexDirection: 'row',
                                minHeight: 380,
                              }}
                            >
                              <div className="text-center">
                                <Loader />
                              </div>
                            </div>
                          </>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="fixed-height">
                      <Card className="url-details">
                        <Card.Header className="url-details-card-header">
                          <div className="url-details-title">
                            <Card.Title tag="h5" className="mb-0 f-bold">
                              Top Keywords
                            </Card.Title>
                            <p className="mb-0">Over the last 7 days</p>
                          </div>
                          <div className="card-actions float-end">
                            <Button variant="outline-secondary">
                              Check your Keywords{' '}
                              <FontAwesomeIcon className="align-middle me-2" icon={faChevronRight} />
                            </Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                alignSelf: 'center',
                                flexDirection: 'row',
                                minHeight: 380,
                              }}
                            >
                              <div className="text-center">
                                <Loader />
                              </div>
                            </div>
                          </>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="url-details">
                        <Card.Header>
                          <div className="card-actions float-end">
                            <Button
                              onClick={() => {
                              }}
                              variant="outline-secondary"
                            >
                              More Speed Data{' '}
                              <FontAwesomeIcon className="align-middle me-2" icon={faChevronRight} />
                            </Button>
                          </div>
                          <Card.Title tag="h5" className="mb-0">
                            Loading Time
                          </Card.Title>
                          <p className="mb-0">Over the last 30 days </p>
                        </Card.Header>
                        <Card.Body>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                alignSelf: 'center',
                                flexDirection: 'row',
                                minHeight: 380,
                              }}
                            >
                              <div className="text-center">
                                <Loader />
                              </div>
                            </div>
                          </>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="url-details mb-0">
                        <Card.Header className="url-details-card-header">
                          <div className="url-details-title">
                            <Card.Title tag="h5" className="mb-0 f-bold">
                              Site Outage
                            </Card.Title>
                            <p className="mb-0">Over the last 24 hours</p>
                          </div>
                          <div className="card-actions float-end">
                            <Button variant="outline-secondary">More Outage Data</Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                alignSelf: 'center',
                                flexDirection: 'row',
                                minHeight: 380,
                              }}
                            >
                              <div className="text-center">
                                <Loader />
                              </div>
                            </div>
                          </>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default DashboardLoading

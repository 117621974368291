import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { CommonAlert } from 'components/uiCore'

const DescriptionTest = ({ data }) => {
  return (
    <>
      {data?.information?.seoCheckup?.description ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.information?.seoCheckup?.description?.descriptionStatus ? (
                ''
              ) : data?.information?.seoCheckup?.description?.descriptionStatus === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.information?.seoCheckup?.description?.descriptionStatus === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p> {data?.information?.seoCheckup?.description?.descriptionMessage} </p>
            {/* <p>{data?.information?.seoCheckup?.description?.description ? <p>{data?.information?.seoCheckup?.description?.description}</p> : <></>}</p> */}

            <CommonAlert
              variant={
                !data?.information?.seoCheckup?.description?.descriptionStatus
                  ? 'danger'
                  : data?.information?.seoCheckup?.description?.descriptionStatus === 3
                  ? 'danger'
                  : data?.information?.seoCheckup?.description?.descriptionStatus === 2
                  ? 'warning'
                  : 'success'
              }
              key={'meta-description'}
              alertText={
                <div className='alert-for-mobile'>
                  <span style={{fontWeight:400, color:'#000000'}}>Text:</span>{' '}
                  {data?.information?.seoCheckup?.description?.description ? (
                    <span>{data?.information?.seoCheckup?.description?.description}</span>
                  ) : (
                    <>No Parameters Found</>
                  )}
                  <div className='alert-for-mobile'> 
                  <span style={{fontWeight:400, color:'#000000'}}>Length:</span>{' '}
                    {data?.information?.seoCheckup?.description?.description ? (
                      <span>{data?.information?.seoCheckup?.description?.description.length}</span>
                    ) : (
                      <>No Parameters Found</>
                    )}
                  </div>
                </div>
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DescriptionTest

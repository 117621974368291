import React from 'react'
import { Table } from 'reactstrap'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const FramesetTest = ({ data }) => {
  return (
    <>
      {data && data?.framesetTest ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.framesetTest?.data?.testResults?.status ? (
                ''
              ) : data?.framesetTest?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.framesetTest?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <p>{data?.framesetTest?.data?.testResults?.message}</p>
            {data && data.framesetTest?.data?.frameset?.length ? (
              <>
                <Table bordered style={{ wordBreak: 'break-word' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>Frame</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.framesetTest?.data?.frameset?.length ? (
                      data.framesetTest?.data?.frameset?.map((item) => {
                        return (
                          <tr>
                            <td>{item.element}</td>
                          </tr>
                        )
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default FramesetTest

import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { 
    Card, 
    Col, 
    Container,  
    ListGroup,  
    Row } from 'react-bootstrap'

import { getUserData } from 'redux/actions/dashboard'

import { Loader } from 'components/uiCore'
import { storeUserData } from 'redux/actions/register'
import PersonalInformation from './PersonalInformation'
import DeleteAccount from './DeleteAccount'
import Security from './Security'

export const PROFILE_TABS = {
    PERSONAL_INFO: "PERSONAL INFROMATION",
    SECURITY: "SECURITY",
    DELETE_ACCOUNT: "DELETE_ACCOUNT"
  }

const Navigation = ({ activeTab, setActiveTab }) => {
  const dispatch = useDispatch()
    return (
    <Card>
      <Card.Header>
        <Card.Title tag="h3" className="mb-0">
          <b>Profile Settings</b>
        </Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item tag="a" action onClick={() => {
          dispatch({type:"PERSONAL_INFO_UPDATE_CLEAR"})
          setActiveTab(PROFILE_TABS.PERSONAL_INFO)}} active = {activeTab === PROFILE_TABS.PERSONAL_INFO ? true : false}>
          Personal Information
        </ListGroup.Item>
        <ListGroup.Item tag="a" action onClick={() => {
          setActiveTab(PROFILE_TABS.SECURITY)
          dispatch({type:"VERIFY_PASSWORD_CLEAR"})
          }} active = {activeTab === PROFILE_TABS.SECURITY ? true : false}>
          Security
        </ListGroup.Item>
        <ListGroup.Item tag="a" action onClick={() => {
          dispatch({type:"DELETE_USER_CLEAR"})
          setActiveTab(PROFILE_TABS.DELETE_ACCOUNT)}} active = {activeTab === PROFILE_TABS.DELETE_ACCOUNT ? true : false}>
          Delete Account
        </ListGroup.Item>
      </ListGroup>
    </Card>)
  
}

const MyAccount = () => {
    const { userData } = useSelector(({ dashboard }) => dashboard)

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(PROFILE_TABS.PERSONAL_INFO);

    useEffect(() => {
        const fetchUserData = async () => {
            const data = await localStorage.getItem("userData")
            dispatch(storeUserData(JSON.parse(data)))
            if(!userData){
                setTimeout(() => {
                    dispatch(getUserData())
                }, 3000)   
            } 
        }
        fetchUserData()
    }, [])

    useEffect(() => {
      return () => {
        dispatch({type:"PERSONAL_INFO_UPDATE_CLEAR"})
        dispatch({type:"VERIFY_PASSWORD_CLEAR"})
        dispatch({type:"DELETE_USER_CLEAR"})
      }
    }, [])

    const renderSelectedTab = () => {
        switch (activeTab) {
            case PROFILE_TABS.PERSONAL_INFO:
                return <PersonalInformation />;
            case PROFILE_TABS.SECURITY:
                return <Security />;
            case PROFILE_TABS.DELETE_ACCOUNT:
                return <DeleteAccount />;
            default:
                return null;
        }
    };

    return (
        <>
            <Helmet title="Settings" />
            <Container fluid className="p-0">
                {!userData ? <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            alignSelf: 'center',
                            flexDirection: 'row',
                            minHeight: 380,
                        }}
                    >
                        <div className="text-center">
                            <Loader />
                        </div>
                    </div>
                </> : <Row>
                    <Col md="3" xl="2">
                        <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
                    </Col>
                    <Col md="9" xl="10">
                        {renderSelectedTab()}
                    </Col>
                </Row>}
            </Container>
        </>
    )
}
  
  export default MyAccount
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'

const RelatedKeywordsTest = ({ data }) => {
  return (
    <>
      {data && data?.relatedKeywordsFinal ? (
        <>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <h5>{data?.url}</h5>
          </div>
          {(
            <>
              <div style={{ marginBottom: 12 }} className="vr-detail-block">
                <div className="vr-report-status-icon" />
                <p>{'Below are the keyword match results for the Top 20 organic Google listing:'}</p>
                {data?.relatedKeywordsFinal?.data?.relatedKeywordsFinal?.length > 0 ? (
                  data?.relatedKeywordsFinal?.data?.relatedKeywordsFinal?.map((item, index) => {
                    return (
                      <div key={`relatedKeywordsFinal-${index + 1}`}>
                        {item?.matched ? (
                          <img style={{ height: 15, marginRight: 5 }} src={tickIcon} />
                        ) : (
                          <img style={{ height: 15, marginTop: 4, marginRight: 5 }} src={crossIcon} />
                        )}
                        <span>&nbsp;{item?.word}</span>
                      </div>
                    )
                  })
                ) : (
                  <> No Keyword found. </>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default RelatedKeywordsTest

import CommonFreeTool from '../CommonFreeTool'
import { freeTools } from 'constants.js'

const RelatedKeywordTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.relatedKeywordsTest.heading}
      whatIsIT={freeTools.relatedKeywordsTest.what}
    />
  )
}

export default RelatedKeywordTest

import Payment from 'payment'
import { PLAN } from 'constants/enum.js'

export const appendInFormData = (data) => {
  let formBody = []
  for (var key in data) {
    if (data[key] !== undefined && data[key] !== '' && data[key] !== null) {
      var encodedKey = encodeURIComponent(key)
      var encodedValue = encodeURIComponent(data[key])
      formBody.push(encodedKey + '=' + encodedValue)
    }
  }
  formBody = formBody.join('&')
  return formBody
}

export const getRandom10Int = () => {
  const min = Math.ceil(1000000000)
  const max = Math.floor(9000000000)
  return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
}

export const getPlanPrice = (plan, frequency) => {
  if (frequency === 'Monthly') {
    switch (plan) {
      case 'Enterprise':
        return '76.95'
      case 'Business Pro':
        return '39.95'
      case 'Business Plus':
        return '24.95'
      default:
        return '24.95'
    }
  } else {
    switch (plan) {
      case 'Enterprise':
        return '699.95'
      case 'Business Pro':
        return '417.89'
      case 'Business Plus':
        return '263.89'
      default:
        return '263.89'
    }
  }
  // return '$39.95';
}

// PROD
const planData = [
  {
    _id: '665020b2386f8497d5a2683c',
    plan: 'plan_Gxhhjp47wgWgB7',
    price: PLAN.BASIC_PLAN.yearly_price.perYear,
    name: PLAN.BASIC_PLAN.name,
    description: PLAN.BASIC_PLAN.name,
    platform: 'Stripe',
    type: 'Yearly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.737Z',
    updatedAt: '2020-03-24T14:04:29.737Z',
  },
  {
    _id: '665020b2386f8497d5a2683b',
    plan: 'plan_GxhgkScr7bbrSe',
    price: PLAN.PRO_PLAN.yearly_price.perYear,
    name: PLAN.PRO_PLAN.name,
    description: PLAN.PRO_PLAN.name,
    platform: 'Stripe',
    type: 'Yearly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '665020b2386f8497d5a26838',
    plan: 'plan_GxhgMzpVhX6IYY',
    price: PLAN.PREMIUM_PLAN.yearly_price.perYear,
    name: PLAN.PREMIUM_PLAN.name,
    description: PLAN.PREMIUM_PLAN.name,
    platform: 'Stripe',
    type: 'Yearly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '665020b2386f8497d5a2683d',
    plan: 'plan_GxhfNQWvc34gBW',
    price: PLAN.BASIC_PLAN.monthly_price,
    name: PLAN.BASIC_PLAN.name,
    description: PLAN.BASIC_PLAN.name,
    platform: 'Stripe',
    type: 'Monthly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '665020b2386f8497d5a2683a',
    plan: 'plan_GxhfArQhbdhWaG',
    price: PLAN.PRO_PLAN.monthly_price,
    name: PLAN.PRO_PLAN.name,
    description: PLAN.PRO_PLAN.name,
    platform: 'Stripe',
    type: 'Monthly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '665020b2386f8497d5a26839',
    plan: 'plan_GxheYyPJQADzZE',
    price: PLAN.PREMIUM_PLAN.monthly_price,
    name: PLAN.PREMIUM_PLAN.name,
    description: PLAN.PREMIUM_PLAN.name,
    platform: 'Stripe',
    type: 'Monthly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '6566c10506e3145b08f46f39',
    name: 'Business Pro',
    type: 'Monthly',
    price: 35.95,
    plan: 'P-93W17077C99374841LZ66JGQ',
    description: 'Business Pro',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '6566c10506e3145b08f46f38',
    name: 'Business Plus',
    type: 'Monthly',
    price: 22.45,
    plan: 'P-2ED94593LK4585511LZ66JGI',
    description: 'Business Plus',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '6566c10506e3145b08f46f3c',
    name: 'Business Pro',
    type: 'Yearly',
    price: 417.89,
    plan: 'P-6JU89572565045043LZ66JHQ',
    description: 'Business Pro',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '6566c10506e3145b08f46f3a',
    name: 'Enterprise',
    type: 'Yearly',
    price: 699.95,
    plan: 'P-74C410164X8177204LZ66JHA',
    description: 'Enterprise',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '6566c10506e3145b08f46f37',
    name: 'Enterprise',
    type: 'Monthly',
    price: 71.95,
    plan: 'P-1M271313GA290305VLZ66JGA',
    description: 'Enterprise',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '6566c10506e3145b08f46f3b',
    name: 'Business Plus',
    type: 'Yearly',
    price: 263.89,
    plan: 'P-0LD362523A9163233LZ66JHI',
    description: 'Business Plus',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
]

export const PLAN_NAME = {
  ENTERPRISE : 'Enterprise',
  BUSINESS_PRO: 'Business Pro',
  BUSINESS_PLUS: 'Business Plus'
}

function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
      break
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12,
      )} ${clearValue.slice(12, 16)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  let maxLength = 4

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number)
    maxLength = issuer === 'amex' ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`)
}

export default planData

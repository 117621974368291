import {
  GET_TOP_KEYWORDS,
  GET_TOP_KEYWORDS_SUCCESS,
  GET_TOP_KEYWORDS_FAILURE,
  GET_NEW_TOP_KEYWORDS,
  GET_NEW_TOP_KEYWORDS_SUCCESS,
  GET_NEW_TOP_KEYWORDS_FAILURE,
  GET_TOP_KEYWORDS_LOCATION_SUCCESS,
  GET_TOP_KEYWORDS_LOCATION_FAILURE,
  GET_TOP_KEYWORDS_LOCATION,
  STORE_NEW_TOP_KEYWORDS,
  STORE_NEW_TOP_KEYWORDS_SUCCESS,
  STORE_NEW_TOP_KEYWORDS_FAILURE,
  CLEAR_STORE_NEW_TOP_KEYWORD,
  GET_TOP_FIVE_KEYWORD,
  GET_TOP_FIVE_KEYWORD_SUCCESS,
  GET_TOP_FIVE_KEYWORD_FAILURE,
  GET_AVG_POSITION_KEYWORD,
  GET_AVG_POSITION_KEYWORD_SUCCESS,
  GET_AVG_POSITION_KEYWORD_FAILURE,
  GET_NUMBER_OF_RESULT,
  GET_NUMBER_OF_RESULT_SUCCESS,
  GET_NUMBER_OF_RESULT_FAILURE,
  GET_UPDATED_TOP_KEYWORD,
} from '../../actionType'

const initialState = {
  getTopKeywordLoader: false,
  getTopKeywordSuccess: true,

  getNewTopKeywordLoader: false,
  getNewTopKeywordSuccess: true,

  getTopKeywordLocationLoader: false,

  storeNewTopKeywordsLoader: false,
  storeNewTopKeywordsLoaderSuccess: false,
  storeNewTopKeywordsLoaderFailure: false,

  dashboardTopKeywordLoader: false,
  dashboardTopKeywordSuccess: false,
  isNumberOfResultStart: false,
  isNumberOfResultSuccess: false,
  numberOfResultData: '',
  mergedNumberOfResultData: [],
}

const TopKeywordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_FIVE_KEYWORD: {
      return {
        ...state,
        dashboardTopKeywordLoader: true,
        dashboardTopKeywordSuccess: false,
      }
    }

    case GET_TOP_FIVE_KEYWORD_SUCCESS: {
      return {
        ...state,
        dashboardTopKeywordLoader: false,
        dashboardTopKeywordSuccess: true,
        dashboardTopKeywords: action?.payload || [],
      }
    }

    case GET_TOP_FIVE_KEYWORD_FAILURE: {
      return {
        ...state,
        dashboardTopKeywordLoader: false,
        dashboardTopKeywordSuccess: false,
      }
    }
    case GET_AVG_POSITION_KEYWORD: {
      return {
        ...state,
        avgPositionLoader: true,
        avgPositionSuccess: false,
      }
    }

    case GET_AVG_POSITION_KEYWORD_SUCCESS: {
      return {
        ...state,
        avgPositionLoader: false,
        avgPositionSuccess: true,
        avgPosition: action?.payload || [],
      }
    }

    case GET_AVG_POSITION_KEYWORD_FAILURE: {
      return {
        ...state,
        avgPositionLoader: false,
        avgPositionSuccess: false,
      }
    }

    case STORE_NEW_TOP_KEYWORDS:
      return {
        ...state,
        storeNewTopKeywordsLoader: false,
      }

    case STORE_NEW_TOP_KEYWORDS_SUCCESS:
      let isError = false
      if (action?.payload?.error) {
        isError = true
      }
      return {
        ...state,
        newTopKeywordsdata: action.payload,
        storeNewTopKeywordsLoaderFailure: isError,
      }

    case STORE_NEW_TOP_KEYWORDS_FAILURE:
      return {
        ...state,
        storeNewTopKeywordsLoader: false,
        storeNewTopKeywordsLoaderSuccess: false,
        storeNewTopKeywordsLoaderFailure: true,
      }

    case CLEAR_STORE_NEW_TOP_KEYWORD: {
      return {
        ...state,
        storeNewTopKeywordsLoaderSuccess: false,
        storeNewTopKeywordsLoader: false,
        storeNewTopKeywordsLoaderFailure: false,
      }
    }

    case GET_TOP_KEYWORDS_LOCATION:
      return {
        ...state,
        getTopKeywordLocationLoader: true,
      }
    case GET_TOP_KEYWORDS_LOCATION_SUCCESS:
      return {
        ...state,
        topKeywordLocationData: action.payload,
        getTopKeywordLocationLoader: false,
      }
    case GET_TOP_KEYWORDS_LOCATION_FAILURE:
      return {
        ...state,
        getTopKeywordLocationLoader: false,
      }

    case GET_NEW_TOP_KEYWORDS:
      return {
        ...state,
        getNewTopKeywordLoader: true,
      }
    case GET_NEW_TOP_KEYWORDS_SUCCESS:
      return {
        ...state,
        newData: action.payload,
        getNewTopKeywordLoader: false,
        getNewTopKeywordSuccess: true,
      }
    case GET_NEW_TOP_KEYWORDS_FAILURE:
      return {
        ...state,
        getNewTopKeywordLoader: false,
        getNewTopKeywordSuccess: false,
      }

    case GET_TOP_KEYWORDS:
      return {
        ...state,
        getTopKeywordLoader: true,
      }
    case GET_TOP_KEYWORDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        getTopKeywordLoader: false,
        getTopKeywordSuccess: true,
      }
    case GET_TOP_KEYWORDS_FAILURE:
      return {
        ...state,
        getTopKeywordLoader: false,
        getTopKeywordSuccess: false,
      }
    case GET_NUMBER_OF_RESULT:
      return {
        ...state,
        isNumberOfResultStart: true,
        isNumberOfResultSuccess: false,
        numberOfResultData: '',
      }
    case GET_NUMBER_OF_RESULT_SUCCESS: {
      const updatedDataOfKeywords =
        state.newData &&
        state.newData.length &&
        state.newData.map((item) => {
          if (state.activeUrl === item.url) {
            let data =
              item.data &&
              item.data.map((innerItem) => {
                if (innerItem.loading === true) {
                  return {
                    ...innerItem,
                    loading: false,
                    searchVolumn: action.payload,
                  }
                } else {
                  return innerItem
                }
              })
            return {
              ...item,
              data: data,
            }
          } else {
            return item
          }
        })
      return {
        ...state,
        newData: updatedDataOfKeywords,
      }
    }
    case GET_NUMBER_OF_RESULT_FAILURE:
      return {
        ...state,
        isNumberOfResultStart: true,
        isNumberOfResultSuccess: false,
        numberOfResultData: '',
      }

    case GET_UPDATED_TOP_KEYWORD: {
      const updatedDataOfKeywords =
        state.newData &&
        state.newData.length &&
        state.newData.map((item) => {
          if (item.url === action.payload.url) {
            return {
              ...item,
              data: action.payload.keywordsList,
            }
          } else {
            return item
          }
        })
      return {
        ...state,
        newData: updatedDataOfKeywords,
        activeUrl: action.payload.url,
      }
    }

    default:
      return state
  }
}
export default TopKeywordReducer

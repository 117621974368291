import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, Row } from 'react-bootstrap'

import * as Yup from 'yup'
import { message } from 'antd'
import { Formik } from 'formik'
import Select from 'react-select'
import moment from 'moment-timezone'
import PhoneInput from 'react-phone-input-2'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import path from 'constants/paths.js'
import { useDeviceCheck } from 'hooks/deviceHook'

import FBSocialButton from 'components/fbButton.js'
import user from 'assets/img/avatars/user.png'
import email from 'assets/img/icons/email_1.png'
import google from 'assets/img/icons/google.png'

import 'components/auth/auth.scss'
import 'react-phone-input-2/lib/style.css'

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().min(1, 'Too Short!').max(70, 'Too Long!').required('First Name is Required'),
  lastName: Yup.string().min(1, 'Too Short!').max(70, 'Too Long!').required('Last Name is Required'),
  email: Yup.string().email('Invalid email').required('Email is Required'),
  phoneNumber: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('A phone number is required'),
  password: Yup.string()
    .required('Password is Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!#%*?&]{8,20}$/,
      'Password should contain minimum 8 characters, at least one uppercase, one lowercase, one number and one special character.',
    ),
  timezone: Yup.string().required('Timezone is required'),
})

function SignUp() {
  const { registerSuccess, registerData, registerSocialSuccess } = useSelector(({ register }) => register)

  const [disabledSignUp, setDisabledSignUp] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [timezones, setTimezones] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  useEffect(() => {
    // Prepare the countries list
    const isoCountries = moment.tz.countries()
    const finalData = []
    isoCountries.map((country) => {
      const zones = moment.tz.zonesForCountry(country, { with_offset: true })
      zones.map((zone) => {
        const data = {
          value: country,
          label: `${zone.name} (GMT${moment.tz(zone.name).format('Z')})`,
          zone: zone.name,
        }
        finalData.push(data)
      })
    })
    const uniqueArray = _.uniqBy(finalData, (obj) => JSON.stringify(obj.label))

    setCountryOptions(uniqueArray)
  }, [])

  const handleCountryChange = (option) => {
    const zones = moment.tz.zonesForCountry(option.value, { with_offset: true })
    setTimezones(
      zones.map((zone) => ({
        value: zone.name,
        label: `${zone.name} (GMT${moment.tz(zone.name).format('Z')})`,
      })),
    )
  }

  useEffect(() => {
    if (registerSocialSuccess && registerData && Object.keys(registerData)?.length) {
      dispatch({ type: 'LOGIN_CLEAR' })
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })

      if (
        registerData &&
        registerData?.subscriptions &&
        registerData?.subscriptions?.length &&
        (registerData?.subscriptions?.[0]?.isActive || registerData?.subscriptions?.[0]?.isTrial)
      ) {
        if (registerData && registerData?.websites && registerData?.websites?.length) {
          navigate(path.mainDashboard)
        } else {
          navigate(path.newUserWelcomePage)
        }
      } else {
        navigate(path.newUserSubscriptionPage)
      }
    }
  }, [registerSocialSuccess, registerData])

  useEffect(() => {
    if (registerSuccess) {
      dispatch({ type: 'REGISTER_CLEAR' })
      if (registerData && registerData.planData && registerData.planData.name) {
        navigate('/dashboard-new')
      } else {
        if (registerData && registerData.userActivated) {
          navigate('/start-free-trial')
        }
      }
    }
  }, [registerSuccess, registerData, dispatch, navigate])

  const startTrial = (data) => {
    dispatch({ type: 'REGISTER_START', payload: data })
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        timezone: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          startTrial(values)
          if (registerSuccess) {
            setDisabledSignUp(true)
          }
        } catch (error) {
          const message = error.message || 'Something went wrong'

          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="signup-form">
          <Form.Group className="mb-3 px-2 signin-form-input">
            {isMobile ? null : (
              <Row className="d-flex justify-content-between">
                <Col lg={8}>
                  <div className="sign-in-font">
                    <b>Empower Your SEO Journey: Precision, Simplicity, Results</b>
                  </div>
                  <div className="mt-3 sign-in-font">
                    Ready to Unlock Full Signal SEO Analysis & Reporting?
                    <br /> Sign Up Now!
                  </div>{' '}
                </Col>
                <Col lg={4}>
                  {isMobile ? null : (
                    <div className="signUp-link">
                      <Button
                        className="d-flex flex-row justify-content-start align-items-center gap-1"
                        variant="primary"
                        color="primary"
                        style={{
                          fontSize: 12,
                          padding: '6px',
                          width: '210px',
                          fontWeight: '500',
                          height: '33px',
                        }}
                        onClick={() => navigate('/auth/sign-in')}
                      >
                        <img
                          alt="user"
                          src={user}
                          className="rounded-circle img-responsive"
                          width="15"
                          height="15"
                          style={{ marginRight: '8px', marginLeft: '5px' }}
                        />
                        I Already Have an Account
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            )}
            <Form.Label className="mt-4 signin-form-label">First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={values.firstName}
              isInvalid={Boolean(touched.firstName && errors.firstName)}
              onBlur={handleBlur}
              onChange={handleChange}
              className="auth-inputs"
            />
            {!!touched.firstName && (
              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 px-2 signin-form-input">
            <Form.Label className="signin-form-label">Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={values.lastName}
              isInvalid={Boolean(touched.lastName && errors.lastName)}
              onBlur={handleBlur}
              onChange={handleChange}
              className="auth-inputs"
            />
            {!!touched.lastName && (
              <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 px-2 signin-form-input">
            <Form.Label className="signin-form-label">Email Address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              className="auth-inputs"
            />
            {!!touched.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group className="mb-3 px-2 signin-form-input">
            <Form.Label className="signin-form-label">Your Timezone</Form.Label>
            <Select
              options={countryOptions}
              onChange={(option) => {
                handleCountryChange(option)
                setFieldValue('timezone', option?.zone)
              }}
              placeholder=""
              isSearchable={true}
              className="auth-inputs"
              classNamePrefix="auth-timezone"
              name="timezone"
            />
            {!!touched.timezone && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.timezone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 px-2 signin-form-input">
            <Form.Label className="signin-form-label">Phone Number</Form.Label>
            <PhoneInput
              country={'us'}
              name="phoneNumber"
              onChange={(phone) => setFieldValue('phoneNumber', phone)}
              value={values.phoneNumber}
              inputStyle={{ width: '100%' }}
              containerClass={`${errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''} `}
              className="auth-inputs"
            />

            {!!touched.phoneNumber && (
              <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3 px-2 signin-form-input">
            <Form.Label className="signin-form-label">Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
              className="auth-inputs"
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center login-buttons" style={{ margin: '2.5rem 0' }}>
            <div className="login-buttons-container">
              <Button
                type="Submit"
                variant="primary"
                size="md"
                disabled={disabledSignUp}
                className="text-start responsive-btn"
                style={{
                  height: '33px',
                  background: 'white',
                  color: 'grey',
                  width: '210px',
                  border: '1px solid black',
                  fontWeight: '500',
                }}
              >
                <img src={email} width={20} height={20} style={{ marginRight: '8px', marginBottom: '1px' }} />{' '}
                Sign up via Email
              </Button>

              <FBSocialButton
                containerStyle={isMobile ? { width: '100%' } : {}}
                provider="google"
                appId="74542118637-c261k854oj1m08ov29tcvrsvkcbrnhpm.apps.googleusercontent.com"
                onLoginSuccess={(data) => {
                  if (data._profile.email && data._token.accessToken) {
                    let apiData = {
                      token: data._token.accessToken,
                      social: true,
                      type: 'google',
                    }

                    dispatch({ type: 'REGISTER_START', payload: apiData })
                  }
                }}
                onLoginFailure={(data) => {
                  const error = data?.toString()
                  if (error?.includes('popup_closed_by_user')) {
                    return
                  } else {
                    message.error(data)
                  }
                }}
              >
                <Button
                  type="button"
                  variant="primary"
                  size="md"
                  className="text-start responsive-btn"
                  style={{
                    height: '33px',
                    background: 'white',
                    color: 'grey',
                    width: '210px',
                    border: '1px solid black',
                    fontWeight: '500',
                  }}
                >
                  <img
                    src={google}
                    width={15}
                    height={15}
                    style={{ marginRight: '8px', marginBottom: '2px' }}
                  />{' '}
                  Sign up with Google
                </Button>
              </FBSocialButton>

              <FBSocialButton
                containerStyle={isMobile ? { width: '100%' } : {}}
                provider="facebook"
                appId="378272869423880"
                onLoginSuccess={(data) => {
                  if (data._profile.email && data._token.accessToken) {
                    let apiData = {
                      token: data._token.accessToken,

                      social: true,
                      type: 'facebook',
                    }
                    dispatch({ type: 'REGISTER_START', payload: apiData })
                  }
                }}
              >
                <Button
                  type="button"
                  variant="primary"
                  size="md"
                  style={{ width: '210px', fontWeight: '500', height: '33px' }}
                  className="text-start responsive-btn-fb"
                >
                  <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '8px', marginBottom: '-1' }} />{' '}
                  Sign up with Facebook
                </Button>
              </FBSocialButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SignUp

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const ResponsiveImage = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [responsiveImageFlag, setResponsiveImageFlag] = useState(false)

  const columns = [
    {
      id: 'image',
      displayName: 'Image',
    },
    {
      id: 'totalSize',
      displayName: 'Total Size',
    },
    {
      id: 'potentialSavings',
      displayName: 'Potential Savings',
    },
  ]
  const datas = data?.responsiveImage?.data?.data?.details?.items?.map(item => {
    return {
      Image:item?.url,
      ['Total Size']: `${item?.totalBytes / 1000 || '-'}KB`,
      ['Potential Savings']: `${item?.wastedBytes / 1000 || '-'}KB`
    }
  })

  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.responsiveImage?.data?.testResults?.status ? (
            ''
          ) : data?.responsiveImage?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.responsiveImage?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data?.responsiveImage?.data?.testResults?.message}</p>

        {showDetail && data?.responsiveImage?.data?.testResults?.status !== 1 &&
        data?.responsiveImage?.data?.data?.details?.items &&
        data?.responsiveImage?.data?.data?.details?.items?.length ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setResponsiveImageFlag(!responsiveImageFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={responsiveImageFlag}
        toggleShow={() => setResponsiveImageFlag(!responsiveImageFlag)}
        title={'Full list of images with excessive data size'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns = {columns}
        datas = {datas}
        fileName='Excessive Image Data Size Signal Test'
        alternativeDownload = {true}
      >
        <Table bordered className="d-none d-sm-block">
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Image</th>
              <th style={{ width: '40%' }}>Total Size</th>
              <th style={{ width: '40%' }}>Potential Savings</th>
            </tr>
          </thead>
          <tbody>
            {data?.responsiveImage?.data?.data?.details?.items?.length &&
              data?.responsiveImage?.data?.data?.details?.items.map((item, index) => {
                return (
                  <tr key={`responsiveImage-${index +1}`}>
                    <td>{item.url}</td>
                    <td>{`${item?.totalBytes / 1000 || '-'} KB`}</td>
                    <td>{`${item?.wastedBytes / 1000 || '-'} KB`}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        <div>
          {data?.responsiveImage?.data?.data?.details?.items ? (
            <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
              {data?.responsiveImage?.data?.data?.details?.items?.length &&
                data?.responsiveImage?.data?.data?.details?.items.map((item) => (
                  <div key={item.url}>
                    <li className="mb-3">
                      <p className="mb-0 f-bold">Image</p>
                      <p className="mb-2">{item.url}</p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0 f-bold">Total Size</p>
                      <p className="mb-2">{`${item?.totalBytes / 1000 || '-'} KB`}</p>
                    </li>
                    <li className="border-bottom mb-3">
                      <p className="mb-0 f-bold">Potential Savings</p>
                      <p className="mb-2">{`${item?.wastedBytes / 1000 || '-'} KB`}</p>
                    </li>
                  </div>
                ))}
            </ul>
          ) : null}
        </div>
      </ModalHOC>
    </>
  )
}

export default ResponsiveImage

import SelectWebsiteMain from 'modules/welcomeBoard/components/selectWebsite/SelectWebsiteMain.jsx'

const SelectWebsiteTrial = () => {
  return (
    <>
      <SelectWebsiteMain callFrom="trial" />
    </>
  )
}

export default SelectWebsiteTrial

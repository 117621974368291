import React, { useState, useEffect } from 'react';
import QuickSEOCheckup from './quick-seo-checkup';
import DefaultNew from './new';

const CheckPage = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    const urlParam = queryString.get('url');
    setUrl(urlParam || '');
  }, []);

  return (
    <>
      {url && url.length ? <QuickSEOCheckup /> : <DefaultNew />}
    </>
  );
};

export default CheckPage;

import { memo } from 'react'
import { useSelector } from 'react-redux'
import { uniqBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import Page from 'pages/dashboards/SEO-Score-Progress.js'
import { Loader } from 'components/uiCore'

import paths from 'constants/paths'
import { Info } from 'react-feather'

const SeoScoreProgress = memo(({ item, SeoProgressPercentage }) => {
  const navigate = useNavigate()

  const { chartDataForAntD: data } = useSelector(({ dashboard }) => dashboard)

  let res = []
  let finalChartData = []

  if (data && data?.length) {
    let result = data?.sort((a, b) => {
      const [monthA, dayA, yearA] = a?.x?.split('-').map(Number)
      const [monthB, dayB, yearB] = b?.x?.split('-').map(Number)

      if (yearA !== yearB) {
        return yearA - yearB
      }
      if (monthA !== monthB) {
        return monthA - monthB
      }
      return dayA - dayB
    })
    res = uniqBy(result, ({ x, competitor }) => `${x}${competitor}`)
    finalChartData = res && res.length && res.filter((item) => item.y > 0)
  }

  return (
    <Card className="url-details mb-0">
      <Card.Header className="url-details-card-header">
        <div className="url-details-title">
          <Card.Title tag="h5" className="mb-0 f-bold d-flex align-items-center">
            SEO Score Progress
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Please note that we scan websites daily, so a variation of 3-4 counts in SEO metrics is
                  normal due to regular updates and changes.
                </Tooltip>
              }
            >
              <Info style={{ marginLeft: '0.5rem' }} size="16" color="#afafaf" />
            </OverlayTrigger>
          </Card.Title>
          <p className="mb-0">Your progress over the last 7 days</p>
        </div>

        <div className="card-actions float-end d-flex gap-3">
          <Button
            style={{ height: 'fit-content' }}
            variant="outline-secondary"
            onClick={() => {
              navigate(paths.dashboardSettings, {
                state: { activeTab: '1' },
              })
            }}
          >
            Edit Competitors
          </Button>

          <Button
            style={{ height: 'fit-content' }}
            variant="outline-secondary"
            onClick={() => {
              navigate(paths.signalScanPage, {
                state: { url: item },
              })
            }}
          >
            View Reports
          </Button>
        </div>
      </Card.Header>

      <Card.Body>
        {finalChartData?.length === 0 || SeoProgressPercentage !== 100 || SeoProgressPercentage === 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                alignSelf: 'center',
                flexDirection: 'row',
                minHeight: 380,
              }}
            >
              <div className="text-center">
                <Loader />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="chart">
              <Page finalChartData={finalChartData} />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
})

export default SeoScoreProgress

import { useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const HtmlValidation = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [htmlValidationFlag, setHtmlValidationFlag] = useState(false)

  const columns = [
    {
    id:'HTMLExtract',
    displayName:'HTML Extract',
  },
  {
    id:'reason',
    displayName:'Reason'
  }
]
  let htmlValidationErrorCsv = data?.htmlValidation?.data?.errors?.map(item => {
    return {
      ['HTML Extract']: item?.extract?.replace(/\n|\t|\s{2,}/g, ''),
      Reason: item?.message
    }
  })
  
  let htmlValidationWarningCsv = data?.htmlValidation?.data?.warnings?.map(item => {
    return {
      ['HTML Extract']: item?.extract?.replace(/\n|\t|\s{2,}/g, ''),
      Reason: item?.message
    }
  })
  
  let htmlValidationCsvData = [];

if (htmlValidationErrorCsv?.length > 0) {
  htmlValidationCsvData.push(...htmlValidationErrorCsv);
}

if (htmlValidationWarningCsv?.length > 0) {
  htmlValidationCsvData.push(...htmlValidationWarningCsv);
}
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.htmlValidation?.data?.testResults?.status ? (
            ''
          ) : data?.htmlValidation?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.htmlValidation?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data?.htmlValidation?.data?.testResults?.message}</p>
      </div>
      <div className="vr-detail-block">
        {showDetail && data?.htmlValidation?.data?.testResults?.status !== 1 && data.htmlValidation?.data?.errors && data.htmlValidation?.data?.errors?.length ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setHtmlValidationFlag(!htmlValidationFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={htmlValidationFlag}
        toggleShow={() => setHtmlValidationFlag(!htmlValidationFlag)}
        title={'Html Validation'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        columns={columns}
        datas={htmlValidationCsvData}
        showDownload
        fileName='Html Validation'
        alternativeDownload={true}
      >
        <Table striped className="d-none d-sm-block">
          <thead>
            <th>HTML Extract</th>
            <th>Reason</th>
          </thead>
          <tbody>
            {data.htmlValidation?.data?.errors?.length ? (
              <>
                {data.htmlValidation?.data?.errors.map((item, index) => {
                  return (
                    <tr key={`HtmlValidation-${index+1}`}>
                      <td>{item.extract}</td>
                      <td>{item.message}</td>
                    </tr>
                  )
                })}{' '}
              </>
            ) : (
              <></>
            )}
            {data.htmlValidation?.data?.warnings?.length ? (
              <>
                {' '}
                {data.htmlValidation?.data?.warnings.map((item, index) => {
                  return (
                    <tr key={`HtmlValidationwarnings-${index+1}`}>
                      <td>{item.extract}</td>
                      <td>{item.message}</td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </Table>
        {/* Start Mobile responsive */}
        <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
          {data.htmlValidation?.data?.errors?.length
            ? data.htmlValidation?.data?.errors.map((item, index) => (
                <div key={`HtmlValidationError-${index+1}`}>
                  <li className="mb-3">
                    <p className="mb-0 f-bold">HTML Extract</p>
                    <p className="mb-2">{item.extract}</p>
                  </li>
                  <li className="border-bottom mb-3">
                    <p className="mb-0 f-bold">Reason</p>
                    <p className="mb-2">{item.message}</p>
                  </li>
                </div>
              ))
            : null}
          {data.htmlValidation?.data?.warnings?.length
            ? data.htmlValidation?.data?.warnings.map((item, index) => (
                <div key={`HtmlValidationWarnings-${index+1}`}>
                  <li className="mb-3">
                    <p className="mb-0 f-bold">HTML Extract</p>
                    <p className="mb-2">{item.extract}</p>
                  </li>
                  <li className="border-bottom mb-3">
                    <p className="mb-0 f-bold">Reason</p>
                    <p className="mb-2">{item.message}</p>
                  </li>
                </div>
              ))
            : null}
        </ul>
        {/* End Mobile responsive */}
      </ModalHOC>
    </>
  )
}

export default HtmlValidation

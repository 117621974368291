import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const LayoutShiftTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.layoutShiftSignalsTest.heading}
      whatIsIT={freeTools.layoutShiftSignalsTest.what}
    />
  )
}

export default LayoutShiftTest

import { Line } from '@ant-design/charts'
import * as _ from 'lodash'
const ActivityGraph = (props) => {
  const data = props?.chartData || []

  let result = _.sortBy(data, ['x'])

  //   const res = _.uniqBy(result, ({ x, name }) => `${x}${name}`);
  const res = result

  const config = {
    data: (res && res.length && res) || [],
    width: 800,
    height: 400,
    autoFit: true,
    animation: false,
    xField: 'x',
    yField: 'y',
    seriesField: 'name',
    smooth: true,
    legend: { marker: { symbol: 'circle' } },
    point: {
      size: 5,
      shape: 'circle',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  }
  // eslint-disable-next-line no-unused-vars
  let chart

  // Export Image
  // const downloadImage = () => {
  //   chart?.downloadImage();
  // };

  // Get chart base64 string
  // const toDataURL = () => {
  // };

  return (
    <div>
      <Line {...config} onReady={(chartInstance) => (chart = chartInstance)} />
    </div>
  )
}
export default ActivityGraph

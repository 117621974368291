import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Icon from 'react-feather'
import { useSelector } from 'react-redux'
import { Card, Button, ProgressBar, Row, Col, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Loader } from 'components/uiCore'

import { VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { faExchangeAlt, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import paths from 'constants/paths'
import { isMainUrlMatch } from 'constants/Utils'

const DashboardTopKeywords = ({progressPercentage}) => {

  const { usersDomains: usersDomain, activeLink } = useSelector(({ dashboard }) => dashboard)
  const { dashboardTopKeywords, 
          dashboardTopKeywordSuccess, 
          avgPosition: averagePositionMasterData 
        } = useSelector(({ topKeywords }) => topKeywords)

  const history = useNavigate()

  const [topKeywordsData, setTopKeywordsData] = useState([])
  const [isLoader, setIsLoader] = useState(true)
  const [avgKeywordPositionData, setAvgKeywordPositionData] = useState(0)
  const [unchangedKeywordPositionData, setUnchangedKeywordPositionData] = useState(0)
  const [increasedKeywordPositionData, setIncreasedKeywordPositionData] = useState(0)
  const [decreasedKeywordPositionData, setDecreasedKeywordPositionData] = useState(0)

  useEffect(() => {
    if (
      usersDomain &&
      activeLink > -1 &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.items &&
      usersDomain[activeLink]?.items?.length &&
      dashboardTopKeywords?.length > 0
    ) {
      let data = dashboardTopKeywords.filter((element) => {
        let main_url = element?.url 
        let redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        
        const isUrlMatched = isMainUrlMatch(main_url, redirect_url)
        return isUrlMatched
      })
      setTopKeywordsData(data?.[0]?.data)
    }
  }, [usersDomain, activeLink, dashboardTopKeywords])

  useEffect(() => {
    if (
      usersDomain &&
      activeLink > -1 &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.items &&
      usersDomain[activeLink]?.items?.length &&
      averagePositionMasterData?.length > 0
    ) {
      let data = averagePositionMasterData?.filter((element) => {
        const redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        const avgPostionMainurl = element?.url 
        
        const isUrlMatched = isMainUrlMatch(avgPostionMainurl, redirect_url)
        return isUrlMatched
      })
      setAvgKeywordPositionData(data?.[0]?.data?.[0]?.avgPosition)
      setUnchangedKeywordPositionData(data?.[0]?.data?.[0]?.unchanged)
      setIncreasedKeywordPositionData(data?.[0]?.data?.[0]?.increased)
      setDecreasedKeywordPositionData(data?.[0]?.data?.[0]?.decreased)
    }
  }, [usersDomain, activeLink, averagePositionMasterData])

  useEffect(() => {
    if ((dashboardTopKeywordSuccess && topKeywordsData?.length > 0) || progressPercentage >= 100) {
      setIsLoader(false)
    }else{
      setIsLoader(true)
    }
  }, [dashboardTopKeywordSuccess, topKeywordsData, progressPercentage])

  const findPositionChange = (increased, decreased, unchanged) => {
    if (increased > 0) {
      return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
    } else if (decreased < 0) {
      return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
    } else if (unchanged) {
      return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
    }
  }  
  return (
    <Card className="url-details">
      <Card.Header className="url-details-card-header">
        <div className="url-details-title">
          <Card.Title tag="h5" className="mb-0 f-bold">
            Top Keywords on SERP
          </Card.Title>
          <p className="mb-0">Your progress over the last 7 days</p>
        </div>
        <div className="card-actions float-end">
          <Button
            variant="outline-secondary"
            onClick={() => {
              history(paths.topKeywordsSignal)
            }}
          >
            View All Keywords
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="mt-4">
          <Col md="8">
            <Row>
              <Col className="mt-2">
                <p>{increasedKeywordPositionData} Increased</p>
                <ProgressBar
                  className="mb-3"
                  variant="success"
                  now={increasedKeywordPositionData || 0}
                  label=""
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <p>{increasedKeywordPositionData} Dropped</p>
                <ProgressBar
                  className="mb-3"
                  variant="danger"
                  now={decreasedKeywordPositionData || 0}
                  label=""
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <p>{unchangedKeywordPositionData} Unchanged</p>
                <ProgressBar
                  className="mb-3"
                  variant="warning"
                  now={unchangedKeywordPositionData || 0}
                  label=""
                />
              </Col>
            </Row>
          </Col>
          <Col md="4" className="d-flex">
            <Card className="flex-fill url-details-inner mt-2 mt-md-0">
              <Card.Body
                className="py-4"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="d-flex align-items-start text-center">
                  <div className="flex-grow-1">
                    <p className="mb-2">Average Position</p>
                    <h3 className="mb-2 f-bold d-flex align-items-center justify-content-center gap-1">
                      {' '}
                      <VerticalAlignMiddleOutlined style={{ fontSize: '175%'}} />{' '}
                      <span>{avgKeywordPositionData || '-'}</span>
                    </h3>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Table responsive className="mt-5">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Keywords</th>
              <th scope="col">
                Position&nbsp;&nbsp;
                <OverlayTrigger
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {' '}
                      Search engine results position for a particular keyword for your domain.
                    </Tooltip>
                  }
                >
                  <Icon.AlertCircle className="d-none d-sm-block" size="16" color="#afafaf" />
                </OverlayTrigger>
              </th>
              <th scope="col">
                Traffic(%)&nbsp;&nbsp;
                <OverlayTrigger
                  placement={'top'}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {' '}
                      The proportion of visitors coming to your domain from a particular keyword as compared
                      to all visitors of your domain.
                    </Tooltip>
                  }
                >
                  <Icon.AlertCircle className="d-none d-sm-block" size="16" color="#afafaf" />
                </OverlayTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            {usersDomain?.[activeLink]?.items && topKeywordsData?.length > 0 ? (
              topKeywordsData.map((item, index) => {
                if (index < 5) {
                  return (
                    <tr key={`KeywordsData-${index + 1}`}>
                      <td>{index + 1}</td>
                      <td>{item?.keyword || '-'}</td>
                      <td>
                        {item?.position ? (
                          <>
                            {
                              <span>
                                {findPositionChange(item?.increased, item?.decreased, item?.unchanged)}
                              </span>
                            }
                          </>
                        ) : (
                          <></>
                        )}
                        &nbsp; {item?.position || '-'} &nbsp;{' '}
                        {item?.increased ? (
                          <>({`+${item?.increased}`})</>
                        ) : item?.decreased ? (
                          <>({item?.decreased})</>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{typeof item?.traffic === 'number' ? Math.floor(item?.traffic) : '-'}</td>
                    </tr>
                  )
                }
              })
            ) : (
              <></>
            )}
          </tbody>
        </Table>
       {usersDomain?.[activeLink]?.items && !isLoader ? (
         <></>
        ) : !topKeywordsData?.length ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                alignSelf: 'center',
                flexDirection: 'row',
                minHeight: 250,
              }}
            >
              <div className="seo-score-chart-alert-loader">
                <div className="text-center">
                  <Loader />
                </div>
              </div>
            </div>
          </>
        ) : null} 
        {usersDomain?.[activeLink]?.items && !topKeywordsData?.length && !isLoader && progressPercentage >= 100 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              alignSelf: 'center',
              flexDirection: 'row',
              minHeight: 250,
              fontSize: '17px'
            }}
          >
            <div className='text-center'>
              Based on our analysis, it appears that your website currently has very low traffic and ranking, which has resulted in the absence of detectable keywords. To boost your SEO performance and visibility, we recommend utilizing PMS SEO Site Signals for enhanced optimization.
            </div>
          </div>
        ) : <></>}
      </Card.Body>
    </Card>
  )
}

export default DashboardTopKeywords

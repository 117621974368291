import { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

import ModalHOC from '../tests-components/ModalHOC'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const CDNUsage = ({ data, showDetail, showUrl = true, testType = "" }) => {

  const dispatch = useDispatch()
  const cdnData = data?.cdnUsageData?.data || {}
  const [cdnUsageDataFlag, setCdnUsageDataFlag] = useState(false)
  const [cdnUsageMapData, setCdnUsageMapData] = useState([])
  const [nonCdnUsageMapData, setNonCdnUsageMapData] = useState([])
  const [servedWithSearchEngineData, setServedWithSearchEngineData] = useState([])
  const [selectedCDNResources, setSelectedCDNResources] = useState('image')
  const [isImageUrlExist, setImageUrlExist] = useState(false)
  const [isJSUrlExist, setJSUrlExist] = useState(false)
  const [isCSSUrlExist, setCSSUrlExist] = useState(false)
  
  useEffect(() => {
    if (cdnData) {
      setImageUrlExist(cdnData?.images)
      setJSUrlExist(cdnData?.javaScript)
      setCSSUrlExist(cdnData?.css)
    }
  }, [])

  const handleCDNUsageChnage = (value) => {
    setCdnUsageMapData((cdnUsageMapData) => [...cdnUsageMapData, value])
  }

  const handleNonCDNUsageChnage = (value) => {
    setNonCdnUsageMapData((nonCdnUsageMapData) => [...nonCdnUsageMapData, value])
  }

  const handleServedWithSearchEngineChnage = (value) => {
    setServedWithSearchEngineData((servedWithSearchEngineData) => [...servedWithSearchEngineData, value])
  }

  const updateSelectedCDNRes = (value) => {
    setSelectedCDNResources(value)
  }

  const onMapData = (value) => {
    handleCDNUsageChnage(cdnData?.[value].servedWithCDN)
    handleNonCDNUsageChnage(cdnData?.[value].servedWithoutCDN)
    handleServedWithSearchEngineChnage(cdnData?.[value].servedWithSearchEngine)
    setCdnUsageDataFlag(!cdnUsageDataFlag)
  }

  const served_from_cdn_column = [
    {
      id:"link",
      displayName:'Links'
    },
    {
      id:"source",
      displayName:'Source'
    }
  ]

  const servedFromCdnCsv = cdnUsageMapData?.['0']?.map(({ url, source }) => {
    return {
      Links: url,
      Source: source
    }
  })

  const not_served_from_cdn_column = [
    {
      id:"link",
      displayName:'Links'
    },
  ]
  const notServedFromCdnCsv = nonCdnUsageMapData?.['0']?.map(({ url }) => 
  {
    return {
      Links: url
    }
  })

  const search_engine_related_third_party_column = [
    {
      id:'link',
      displayName:"Links"
    }
  ]

  const searchEngineCsv = servedWithSearchEngineData?.['0']?.map(({ url }) => {
    return {
      Links: url,
    }
  }) 

  const getType = () => {
    switch (selectedCDNResources) {
      case 'image':
        return 'Image'
      case 'css':
        return 'CSS'
      case 'javascript':
        return 'Javascript'
      default:
        return ''
    }
  }

  return (
    <>
      <div className="vr-url-block">
        <img
          style={{ height: 15, marginTop: 6, marginRight: 5 }}
          src={
            cdnData?.testResult?.status === 1
              ? tickIcon
              : cdnData?.testResult?.status === 2
              ? warningIcon
              : crossIcon
          }
          className="d-none d-md-block d-xl-block"
        />
        <span>{showUrl ? <h5>{data?.url}</h5> : null}</span>
      </div>
      <div className="vr-url-block">
        <div className="vr-report-status-icon" />
        <p>{cdnData?.testResult?.message}</p>
      </div>
      <div className="vr-url-block">
        <div className="vr-report-status-icon" />
        {showDetail && isImageUrlExist ? (
          <>
            <FontAwesomeIcon
              size={'1x'}
              color={'#1890ff'}
              style={{ marginRight: 5, marginTop: 3 }}
              className="align-middle me-2"
              icon={faCaretRight}
              fixedWidth
            />
            <p
              className="text-primary cursor-pointer"
              onClick={() => {

                if (testType === "") {
                  onMapData('images')
                  updateSelectedCDNRes('images')
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              Images
            </p>
          </>
        ) : null}
      </div>
      <div className="vr-url-block">
        <div className="vr-report-status-icon" />
        {showDetail && isJSUrlExist ? (
          <>
            <FontAwesomeIcon
              size={'1x'}
              color={'#1890ff'}
              style={{ marginRight: 5, marginTop: 3 }}
              className="align-middle me-2"
              icon={faCaretRight}
              fixedWidth
            />
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  onMapData('javaScript')
                  updateSelectedCDNRes('javaScript')
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              JavaScript
            </p>
          </>
        ) : null}
      </div>
      <div className="vr-url-block">
        <div className="vr-report-status-icon" />
        {showDetail && isCSSUrlExist ? (
          <>
            <FontAwesomeIcon
              size={'1x'}
              color={'#1890ff'}
              style={{ marginRight: 5, marginTop: 3 }}
              className="align-middle me-2"
              icon={faCaretRight}
              fixedWidth
            />
            <p
              className="text-primary cursor-pointer"
              onClick={() => {

                if (testType === "") {
                  onMapData('css')
                  updateSelectedCDNRes('css')
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              CSS
            </p>
          </>
        ) : null}
      </div>
      <ModalHOC
        show={cdnUsageDataFlag}
        toggleShow={() => {
          setCdnUsageDataFlag(!cdnUsageDataFlag)
          setTimeout(() => {
            setCdnUsageMapData([])
            setNonCdnUsageMapData([])
            setServedWithSearchEngineData([])
          },700)
        }}
        title={'CDN usage for resources'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload = {cdnUsageMapData?.["0"]?.length > 0 || nonCdnUsageMapData?.["0"]?.length > 0 || servedWithSearchEngineData?.["0"]?.length > 0 ? true : false}
        dataForDownload={[
          {key:'Served from cdn', datas:servedFromCdnCsv, columns: served_from_cdn_column},
          {key:'Not Served from cdn', datas: notServedFromCdnCsv, columns: not_served_from_cdn_column},
          {key:'Search engine related third party', datas: searchEngineCsv, columns: search_engine_related_third_party_column},
        ]}
        fileName='CDN Usage Signal Test'
        alternativeDownload={true}
      >
        {cdnUsageMapData?.length && cdnUsageMapData?.['0']?.length ? (
          <>
            <div className="vr-cdn-url">
              {getType()}
              Resources served from CDNs:
            </div>
            <Table striped>
              <thead>
                <th>Links</th>
                <th>Source</th>
              </thead>
              <tbody>
                {cdnUsageMapData?.length && cdnUsageMapData?.['0']?.length ? (
                  <>
                    {cdnUsageMapData?.['0'].map(({ url, source }) => {
                      return (
                        <tr>
                          <td
                            style={{ cursor: 'pointer' }}
                            onClick={() => window.open(url, '_blank', 'noreferrer')}
                          >
                            {url}
                          </td>
                          <td>{source}</td>
                        </tr>
                      )
                    })}
                  </>
                ) : null}
              </tbody>
            </Table>
            {/* Start Mobile Resources served from CDNs */}
            <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
              {cdnUsageMapData?.length && cdnUsageMapData?.['0']?.length
                ? cdnUsageMapData?.['0'].map(({ url, source }) => (
                    <div key={url}>
                      <li className="mb-3">
                        <p className="mb-0 f-bold">Links</p>
                        <p className="mb-2">{url}</p>
                      </li>
                      <li className="border-bottom mb-3">
                        <p className="mb-0 f-bold">Source</p>
                        <p className="mb-2">{source}</p>
                      </li>
                    </div>
                  ))
                : null}
            </ul>
            {/* End Mobile Resources served from CDNs */}
          </>
        ) : null}
        <br /> <br />
        {nonCdnUsageMapData?.length && nonCdnUsageMapData?.['0']?.length ? (
          <>
            <div className="vr-cdn-url">
              {getType()}
              Resources not served from CDNs:
            </div>
            <ul className="vr-non-cdn-bullet-item" />
            <Table striped>
              <thead>
                <th>Links</th>
              </thead>
              <tbody>
                {nonCdnUsageMapData?.length && nonCdnUsageMapData?.['0']?.length ? (
                  <>
                    {nonCdnUsageMapData?.['0'].map(({ url }) => {
                      return (
                        <tr>
                          <td
                            style={{ cursor: 'pointer' }}
                            onClick={() => window.open(url, '_blank', 'noreferrer')}
                          >
                            {url}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ) : null}
              </tbody>
            </Table>
          </>
        ) : null}
        <br /> <br />
        {servedWithSearchEngineData?.length && servedWithSearchEngineData?.['0']?.length ? (
          <>
            <div className="vr-cdn-url">
              {getType()}
              Search Engine Related Third Party:
            </div>
            <ul className="vr-non-cdn-bullet-item" />
            <Table striped>
              <thead>
                <th>Links</th>
              </thead>
              <tbody>
                {servedWithSearchEngineData?.length && servedWithSearchEngineData?.['0']?.length ? (
                  <>
                    {servedWithSearchEngineData?.['0'].map(({ url }) => {
                      return (
                        <tr>
                          <td
                            style={{ cursor: 'pointer' }}
                            onClick={() => window.open(url, '_blank', 'noreferrer')}
                          >
                            {url}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ) : null}
              </tbody>
            </Table>
          </>
        ) : null}
        {!cdnUsageMapData?.['0']?.length &&
        !nonCdnUsageMapData?.['0']?.length &&
        !servedWithSearchEngineData?.['0']?.length ? (
          <span>No link found for {selectedCDNResources} resource.</span>
        ) : null}
      </ModalHOC>
    </>
  )
}

export default CDNUsage

const paths = {
  newUserPaymentPage: '/trial/payment',
  newUserSubscriptionPage: '/trial/plans',
  newUserWelcomePage: '/trial/welcome-on-board',
  mainDashboard: '/dashboard/revised',
  signInPage: '/auth/sign-in',
  addNewWebsite: '/trial/add-website',
  selectWebsite: '/trial/select-website',
  addLocation: '/trial/add-location',
  addGoogle: '/trial/add-connections',
  addSitemap: '/trial/add-sitemaps',
  addKeywords: '/trial/add-keywords',
  addCompetitor: '/trial/add-competitors',
  dashboardSettings: '/dashboard/settings',
  signalScanPage: '/dashboard/seo/analysis',
  backlinksSignal: '/backlinks',
  siteOutageTracker: '/pages/site-outage',
  topKeywordsSignal: '/dashboard/new-top-keywords',
  keywordPositionTracker: '/dashboard/keyword-tracker',
  urlReport: '/dashboard/seo/analysis/report',
  dashboardAddWebsite:'/dashboard/add-website',
  dashboardSelectWebsite:'/dashboard/select-website',
  dashboardaddLocation:'/dashboard/add-location',
  dashboardAddGoogle:'/dashboard/add-connections',
  dashboardAddKeywords:'/dashboard/add-keywords',
  dashboardAddCompetitors:'/dashboard/add-competitors',
  dashboardAddSitemaps:'/dashboard/add-sitemaps',
  upgradePlan:'/dashboard/upgrade-plan',
  resetPassword: '/auth/reset-password',
  profilePage: '/account/profile',
  billingPage: '/account/billing',
  googleSearchConsole: '/dashboard/google-search-console',
}

export default paths

import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const UrlCanonicalization = ({ data }) => {
  return (
    <>
      {data && data?.urlCanonicalization ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.urlCanonicalization?.data?.testResult?.status ? (
                ''
              ) : data?.urlCanonicalization?.data?.testResult?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.urlCanonicalization?.data?.testResult?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p>
              {data.urlCanonicalization?.data?.testResult ? (
                <>{data.urlCanonicalization?.data?.testResult?.message || ''}</>
              ) : (
                <></>
              )}
            </p>
          </div>
          <div className="vr-detail-block">
            <ul>
              {data?.urlCanonicalization?.data?.finalData?.urls &&
              data?.urlCanonicalization?.data?.finalData?.urls?.length
                ? data?.urlCanonicalization?.data?.finalData?.urls?.map((item, index) => {
                    return <li key={`UrlCanonicalization-${index+1}`}> {item}</li>
                  })
                : ''}
            </ul>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default UrlCanonicalization

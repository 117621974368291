import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar, Nav, Form, InputGroup, Button, Modal, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { Bell } from 'react-feather'

import useSidebar from '../../hooks/useSidebar'

import NavbarDropdown from './NavbarDropdown'
import NavbarDropdownItem from './NavbarDropdownItem'
import NavbarUser from './NavbarUser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons'
import { getLighthouseForQuickSeo, getNotifications, getQuickScanLimit, readAllNotifications, readSingleNotifications } from 'redux/actions/dashboard'
import { LimitOption, NotificationType } from 'constants/enum'
import { CLEAR_QUICK_SCAN_LIMIT, CLEAR_READ_ALL_NOTIFICATION, CLEAR_READ_SINGLE_NOTIFICATION } from 'redux/actionType'
import { message } from 'antd'
import paths from 'constants/paths'
import "components/navbar/navbar.scss"

const schema = Yup.object().shape({ url: Yup.string().required("Please enter url") })

const NavbarComponent = ({isCustomClass = false}) => {

  const { userData, 
          quickScanLimitData, 
          quickScanLimitLoader,  
          notificationsData, 
          singleNotificationLoader, 
          singleNotificationSuccess, 
          singleNotificationError,
          allNotificationsLoader,
          allNotificationsError,
          allNotificationsSuccess
        } = useSelector(({ dashboard }) => dashboard)

  const { t } = useTranslation()
  const { isOpen, setIsOpen } = useSidebar()
  const dispatch = useDispatch()
  const history = useNavigate()
  const [trialWarning, setTrialWarning] = useState(false)
  const [quickScanUrlData, setQuickScanUrlData] = useState({})

  const formikRef= useRef()
  let url = window.location.href
  url = url?.split("=")?.[1]

  const handleResetForm = () => {
    if(formikRef.current){
      formikRef.current.resetForm()
    }
  }

  useEffect(() => {
    url = window.location.href

    if(url?.includes("dashboard/seo/analysis/report?url")){
      return;
    }else{
      handleResetForm()
    }
  }, [url])

  useEffect(() => {
    if(quickScanLimitLoader){
      return
    }else if(!quickScanLimitLoader && quickScanLimitData && Object?.keys(quickScanLimitData)?.length){
      if(quickScanLimitData?.limitAvailable){
        analyzeUrl()
        dispatch({type:CLEAR_QUICK_SCAN_LIMIT})
      }
    }
    url = window.location.href
    if(!quickScanLimitLoader && quickScanLimitData && !Object.keys(quickScanLimitData)?.length && !url?.includes("dashboard/seo/analysis/report?url")){
      handleResetForm()
    }
  }, [quickScanLimitLoader, quickScanLimitData])

  useEffect(() => {
    if( !singleNotificationLoader && singleNotificationSuccess ){
      dispatch(getNotifications())
      dispatch({type: CLEAR_READ_SINGLE_NOTIFICATION})
    }else{
      if( !singleNotificationLoader && singleNotificationError && Object.keys(singleNotificationError)?.length ){
        message.error(singleNotificationError?.message)
        dispatch({type: CLEAR_READ_SINGLE_NOTIFICATION})
      }
    }
  }, [singleNotificationLoader, singleNotificationSuccess, singleNotificationError])

  useEffect(() => {
    if( !allNotificationsLoader && allNotificationsSuccess ){
      dispatch(getNotifications())
      dispatch({type: CLEAR_READ_ALL_NOTIFICATION})
    }else{
      if( !allNotificationsLoader && allNotificationsError && Object.keys(allNotificationsError)?.length ){
        message.error(allNotificationsError?.message)
        dispatch({type: CLEAR_READ_ALL_NOTIFICATION})
      }
    }
  }, [allNotificationsLoader, allNotificationsSuccess, allNotificationsError])

  const analyzeUrl = () => {
    if(userData?.isTrialInUse){setTrialWarning(true)}
    else{
    if (quickScanUrlData && quickScanUrlData?.url && quickScanUrlData?.url?.length) {
      if (quickScanUrlData.url.startsWith('https://')){
        dispatch(getLighthouseForQuickSeo({url: quickScanUrlData?.url}))
      }else{
        dispatch(getLighthouseForQuickSeo({ url: `https://${quickScanUrlData?.url}`}))
      }
     
        history({
          pathname: `/dashboard/seo/analysis/report`,
          search: `?url=${quickScanUrlData?.url}`,
        }, {state: {url: quickScanUrlData?.url}})
    }
  }}

  const checkQuickScanLimit = (data) => {
    dispatch(getQuickScanLimit({limitOption: LimitOption.QUICKSCAN}))
    setQuickScanUrlData(data)
  }

  const handleReadAllNotification = () => {
    dispatch(readAllNotifications())
  }

  const checkForUnreadNotification = () => {
    if(notificationsData && notificationsData?.length){
      const unreadNotification = notificationsData?.filter(notification => {return !notification?.read})
      
      if(unreadNotification && unreadNotification?.length){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }
  const updateUnreadCount = () => {
    if(notificationsData && notificationsData?.length){
      const unreadNotification = notificationsData?.filter(notification => {return !notification?.read})
      
      if(unreadNotification && unreadNotification?.length){
        return unreadNotification?.length
      }else{
        return notificationsData?.length
      }
    }
  } 
  return (
    <Navbar variant="light" expand className={`navbar-bg navbar-dashboard ${isCustomClass ? 'navbar-custom' : ''}`}>
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      {trialWarning ? (
                <Modal
                show={trialWarning}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => { setTrialWarning(false) }}
                style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
                className="br-15 pe-0"
              >
                <Modal.Header>
                  <h3 style={{ margin: 0 }}>You are using trial version!</h3>
                </Modal.Header>
                <Modal.Body style={{margin: "12px 0"}}>
                  Your trial period will end tomorrow, you will be able to add more websites starting tomorrow.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' onClick={() => setTrialWarning(false)}>Close</Button>
                </Modal.Footer>
              </Modal>
              ) : (
                null
              )}
      <Formik
        validationSchema={schema}
        onSubmit={(data) => {
          checkQuickScanLimit(data)
        }}
        initialValues={{
          url: '',
        }}
        innerRef={formikRef}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form inline="true" className="d-none d-md-inline-block" onSubmit={handleSubmit}>
            <InputGroup className="input-group-navbar quick-seo-input">
              <FontAwesomeIcon icon={faEarthAmericas} />
              <Form.Control
                type="text"
                name="url"
                value={values.url}
                onChange={handleChange}
                isInvalid={!!errors.url}
                placeholder={t('Fast URL SEO Site Signal Scan - Enter URL')}
                aria-label="Search"
                className='navQuickScanInput'
              />
              <Button type="submit" variant="Primary" className='d-flex flex-row align-items-center gap-1' disabled={quickScanLimitLoader}>
                {/* <Search className="feather" /> */}
                <p className='m-0'>Launch Scan</p>
                { quickScanLimitLoader ? 
                  (
                    <Spinner animation="border" key={1} size='sm' />
                  ) : null
                }
              </Button>
            </InputGroup>
            {touched.url && errors.url ? (
              <div style={{ width: 'inherit', marginTop: '2px' }}>
                <span className="fs-6" style={{ color: 'red' }}>{errors.url}</span>
              </div>
            ) : null}
            <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
          </Form>
        )}
      </Formik>
      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarDropdown
            header={checkForUnreadNotification() ? "New Notifications" : "Notifications"}
            icon={Bell}
            count={updateUnreadCount()}
            showBadge={checkForUnreadNotification()}
            handleFooter={handleReadAllNotification}
            footer={checkForUnreadNotification() ? "Mark all as read" : null}
            footerLoader={allNotificationsLoader}
            isCustomeClass
            customeClass={"nav-notification"}
          >
            {
              notificationsData && notificationsData?.length ?
                notificationsData?.map((notification, key) => {
                  return <NavbarDropdownItem
                    key={`Notification-${key + 1}`}
                    title={notification?.messageData?.subject}
                    description={notification?.messageData?.message}
                    time={notification?.timeAgo}
                    readed={notification?.read}
                    dropdownItemOnClick={() => {
                      if(notification?.type === NotificationType.BACKLINKS){
                        if(notification?.messageData?.subject === "Detected loss of backlinks."){
                          history(paths.backlinksSignal, {state: {tabId: "6"}})
                        }else{
                          if(notification?.messageData?.subject === "New valuable backlinks discovered."){
                            history(paths.backlinksSignal, {state: {tabId: "0"}})
                          }
                        }
                      }else if(notification?.type === NotificationType.TOP_KEYWORDS){
                        history(paths.topKeywordsSignal)
                      }else if(notification?.type === NotificationType.KEYWORD_POSITION){
                          history(paths.keywordPositionTracker)
                      }else{
                        if(notification?.type === NotificationType.SUBSCRIPTION){
                          history(paths.billingPage)
                        }
                      }
                      dispatch(readSingleNotifications({ notificationId: notification?._id }))
                    }}
                  />
                }) : <div><p className='text-center p-3 mb-0'>No new notifications</p></div>
            }
           
          </NavbarDropdown>

          {/* <NavbarLanguages /> */}
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavbarComponent

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const SafeBrowsingTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.safeBrowsingTest.heading}
      whatIsIT={freeTools.safeBrowsingTest.what}
      howToFixBeforeCode={freeTools.safeBrowsingTest.howSectionBeforeCode}
    />
  )
}

export default SafeBrowsingTest

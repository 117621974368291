import React from 'react'

const DesktopScreenshot = ({ data, showDetail }) => {
  return (
    <>
      <div className="vr-detail-block">
        <h5>{data?.url}</h5>
        <p>
          {data?.desktopSnapShotTest?.data?.length
            ? 'Great ! We found snapshot of your webpage'
            : 'Ops! we are not able to find the snapshot of your webpage'}
        </p>
        {showDetail ? (
          data?.desktopSnapShotTest?.data?.length ? (
            <>
              {' '}
              <div style={{ marginBottom: 16 }}>
                {' '}
                <img
                  width="500"
                  alt="desktop snapshot"
                  className="img-fluid"
                  src={data?.desktopSnapShotTest?.data}
                />{' '}
              </div>{' '}
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default DesktopScreenshot

import { Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { CommonAlert } from 'components/uiCore'
import { Link } from 'react-router-dom'

const KeywordUsageTest = ({ data }) => {
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 1 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          ) : data.microDataSchema?.data?.keywordsUsageTestResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data.microDataSchema?.data?.keywordsUsageTestResults?.status === 4 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <></>
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        {data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 4 ? (
          <CommonAlert
            variant={'warning'}
            key={'meta-title'}
            alertText={
              <div>
                <span>{data?.microDataSchema?.data?.keywordsUsageTestResults?.cachedMessage}</span>
                <Link to="/pages/firewall-options" style={{ textDecoration: 'underline' }} target="_blank">
                  click here
                </Link>
              </div>
            }
          />
        ) : (
          <></>
        )}
        {<p> {data?.microDataSchema?.data?.keywordsUsageTestResults?.message} </p>}
        {/* <p>{data?.microDataSchema?.data?.keywordSuccessFlag ? "The most common keywords of this webpage are distributed well across the important HTML tags. This helps search engines to properly identify the topic of this webpage." : data?.microDataSchema?.data?.keywordsUsageTestResults?.status !== 3 ? "The most common keywords of this webpage are not distributed across the important HTML tags! Primary keywords should appear in title tag, meta description and heading tags to help Search Engines to properly identify the topic of this webpage." : data?.microDataSchema?.data?.keywordsUsageTestResults?.message}</p> */}

        {data?.microDataSchema?.data?.keywordsUsageTest &&
        data?.microDataSchema?.data?.keywordsUsageTest.length ? (
          <>
            <div className="mt-3 ">
              <Table responsive className="custom-table-report">
                <thead>
                  <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }}>Keyword</th>
                  <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                    Title
                  </th>
                  <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                    Description
                  </th>
                  <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                    Headings
                  </th>
                </thead>
                <tbody>
                  {data?.microDataSchema?.data?.keywordsUsageTest &&
                  data?.microDataSchema?.data?.keywordsUsageTest.length ? (
                    data?.microDataSchema?.data?.keywordsUsageTest.map((item, index) => {
                      return (
                        <>
                          <tr key={`microDataSchema-${index + 1}`}>
                            <td width="40%">{item.keyword}</td>
                            <td width="20%" className="text-center">
                              {item?.titleCount > 0 ? (
                                <FontAwesomeIcon icon={faCheckCircle} color="green" size="sm" />
                              ) : (
                                <FontAwesomeIcon icon={faTimesCircle} color="red" size="sm" />
                              )}
                            </td>
                            <td width="20%" className="text-center">
                              {item?.descriptionCount > 0 ? (
                                <FontAwesomeIcon icon={faCheckCircle} color="green" size="sm" />
                              ) : (
                                <FontAwesomeIcon icon={faTimesCircle} color="red" size="sm" />
                              )}
                            </td>
                            <td width="20%" className="text-center">
                              {item?.headingCount > 0 ? (
                                <FontAwesomeIcon icon={faCheckCircle} color="green" size="sm" />
                              ) : (
                                <FontAwesomeIcon icon={faTimesCircle} color="red" size="sm" />
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default KeywordUsageTest

import { Button, Modal, ModalBody } from 'reactstrap'

const ColoredModal = ({ isOpen, onClose, type = 'danger', message }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} className={`modal-colored modal-${type} auth-modal`} centered>
      <ModalBody className="text-center m-2 auth-modal-body">
        <div className="auth-modal-message">
          <p>{type === 'success' ? 'Success!' : 'Error!'}</p>
          <p className="mb-0 text-white">
            {message}
          </p>
        </div>
        <div className="auth-modal-button">
          <Button color="light" onClick={onClose}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ColoredModal;

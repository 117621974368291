import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const DnsSecurityTest = () => {
  return (
    <CommonFreeTool heading={freeTools.dnsSecurityTest.heading} whatIsIT={freeTools.dnsSecurityTest.what} />
  )
}

export default DnsSecurityTest

import { Row, Col, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-scroll'
import { getColor, getPriority } from 'constants/Utils'

const Issues = (props) => {
  return (
    <div style={{ maxHeight: 250, overflowY: 'scroll', overflowX: 'hidden' }}>
      {/* <PaginationTable columns={issuesTableColumns} data={props?.errors} onClick={() => props.executeScroll()} /> */}
      {props?.errors?.length ? (
        props?.errors.map((item, index) => {
          return (
            <>
              {' '}
              {item?.message && item?.message?.length ? (
                <>
                  <Row key={`Issues-${index+1}`}>
                    <Col md="1">
                      <Badge bg={getColor(item.priority)} className="me-1 mb-1 f-bold">
                        {getPriority(item.priority)}
                      </Badge>
                    </Col>
                    <Col md="1">
                      <Link to={item.id} spy={true} smooth={true} offset={-150} duration={500}>
                        <FontAwesomeIcon icon={faLink} fixedWidth color={'#3F80EA'} />
                      </Link>
                    </Col>
                    <Col md="9">{item.message}</Col>
                    <hr className="mt-3" />
                  </Row>
                </>
              ) : (
                <></>
              )}{' '}
            </>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

export default Issues

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MoreHorizontal } from 'react-feather'
import { useCSVDownloader } from 'react-papaparse'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Dropdown, Nav, Row, Spinner, Tab, Table, Button } from 'react-bootstrap'

import * as _ from 'lodash'
import { parse } from 'json2csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faDownload } from '@fortawesome/free-solid-svg-icons'

import { Loader } from 'components/uiCore'
import BacklinksCommonCharts from 'pages/dashboards/Backlinks/BacklinksCommonCharts.js'

import {
  changeActiveLink,
  patchUserDomainsDashboard,
  getBacklinks,
  getBacklinksGraph,
  getBacklinksMostRecently,
  getBacklinksMostImportant,
  getBacklinksReferringDomain,
  getBacklinksAnchorText,
  getLostBacklinks,
  getUserData,
} from 'redux/actions/dashboard/index.js'

import {
  getMostImportantCSV,
  getMostImportantJSON,
  getMostRecentlyCSV,
  getMostRecentlyJSON,
  getReferringDomainCSV,
  getReferringDomainJSON,
} from 'redux/actions/backlinks/index.js'

import paths from 'constants/paths'
import 'pages/dashboards/ReportsTable/reportsTable.scss'
import '../../../../node_modules/flag-icons/css/flag-icons.min.css'
import { isMainUrlMatch } from 'constants/Utils'
import MainUrlNav from 'components/MainUrlNav'

const BackLinks = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()

  const {
    usersDomains: usersDomain,
    activeLink,
    lostBacklinks,
    lostBacklinksLoader,
    getBacklinksDataLoader,
    getBacklinksMostRecentlyLoader,
    getBacklinksMostImportantLoader,
    getBacklinksReferringDomainLoader,
    mostRecentlyBacklinksData,
    mostImpBacklinksData,
    referringDomains: referringDomainsData,
    backlinksAnchorTexts,
    getBacklinksAnchorTextLoader,
  } = useSelector(({ dashboard }) => dashboard)

  const {
    getMostRecentlyCSVLoader,
    mostRecentlyCSVData,
    getMostRecentlyCSVSuccess,
    getMostImportantCSVLoader,
    mostImportanCSVData,
    getMostImportanCSVSuccess,
    getMostImportantJSONLoader,
    mostImportanJSONData,
    getMostImportanJSONSuccess,
    getMostRecentlyJSONLoader,
    mostRecentlyJSONData,
    getMostRecentlyJSONSuccess,
    getReferringDomainCSVLoader,
    getReferringDomainCSVSuccess,
    referringDomainCSVData,
    getReferringDomainJSONLoader,
    getReferringDomainJSONSuccess,
    referringDomainJSONData,
  } = useSelector(({ backlinksData }) => backlinksData)

  const [tldDistributions, setTldDistribution] = useState()
  const [mostRecentlyLimit, setMostRecentlyLimit] = useState(10)
  const mostRecentlyPage = 1
  const [mostImpLimit, setMostImpLimit] = useState(10)
  const mostImpPage = 1
  const [referringDomainLimit, setReferringDomainLimit] = useState(10)
  const referringDomainPage = 1
  const [anchorText, setAnchorText] = useState()
  const [anchorTextLimit, setAnchorTextLimit] = useState(10)
  const anchorTextPage = 1
  const [lostBacklinksLimit, setLostBacklinksLimit] = useState(10)
  const lostBacklinksPage = 1
  const [mostRecently, setMostRecently] = useState()
  const [topCountries, setTopCountries] = useState()
  const [topCountriesOthers, setTopCountriesOthers] = useState()
  const [totalTopCountriesForCSV, setTotalTopCountriesForCSV] = useState()
  const [lostBacklinksData, setLostBacklinksData] = useState([])
  const { CSVDownloader, Type } = useCSVDownloader()
  const [selectedTabNav, setSelectedTabNav] = useState(state?.tabId || '0')
  const [mostFound, setMostFound] = useState()
  const [refferingDomains, setRefferingDomains] = useState()
  
  useEffect(() => {
    async function fetchMyAPI() {
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)?._id) {
        dispatch(getUserData())
        if (!usersDomain?.length) dispatch(patchUserDomainsDashboard())
        setTimeout(() => {}, 2000)
      }
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        // interval = setInterval(() => {
        dispatch(getBacklinksGraph({ userId: JSON.parse(data)._id }))
        if (!mostRecently?.length)
          dispatch(
            getBacklinksMostRecently({
              userId: JSON.parse(data)._id,
              limit: mostRecentlyLimit,
              page: mostRecentlyPage,
            }),
          )
        if (!mostFound?.length)
          dispatch(
            getBacklinksMostImportant({
              userId: JSON.parse(data)._id,
              limit: mostImpLimit,
              page: mostImpPage,
            }),
          )
        if (!refferingDomains?.length)
          dispatch(
            getBacklinksReferringDomain({
              userId: JSON.parse(data)._id,
              limit: referringDomainLimit,
              page: referringDomainPage,
            }),
          )
        if (!anchorText?.length)
          dispatch(
            getBacklinksAnchorText({
              userId: JSON.parse(data)._id,
              limit: anchorTextLimit,
              page: anchorTextPage,
            }),
          )
        if (!lostBacklinksData?.length) {
          dispatch(
            getLostBacklinks({
              limit: lostBacklinksLimit,
              page: lostBacklinksPage,
            }),
          )
        }
      }
    }
    fetchMyAPI()
  }, [usersDomain])

  const navTabsName = [
    { label: 'Most recently found', key: '0' },
    { label: 'Most important', key: '1' },
    { label: 'Referring domains', key: '2' },
    { label: 'Top anchors', key: '3' },
    { label: 'TLD distribution', key: '4' },
    { label: 'Top countries', key: '5' },
    { label: 'Lost backlinks', key: '6' },
  ]

  useEffect(() => {
    const data = localStorage.getItem('userData')

    if (data && JSON.parse(data).token) {
      // storeUserData(JSON.parse(data))
      if (!usersDomain?.length) dispatch(patchUserDomainsDashboard())
      dispatch(getBacklinks({ userId: JSON.parse(data)._id }))
    } else {
      navigate('/')
    }
    patchBacklinks()
  }, [])

  function patchBacklinks() {
    const data = localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      dispatch(getBacklinks({ userId: JSON.parse(data)._id }))
      dispatch(getBacklinksGraph({ userId: JSON.parse(data)._id }))
    }
  }

  useEffect(() => {
    if (getReferringDomainCSVSuccess && referringDomainCSVData?.length) {
      const url = window.URL.createObjectURL(new Blob([parse(referringDomainCSVData)]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'referring-domain.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch({ type: 'CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_CSV' })
    }
  }, [getReferringDomainCSVSuccess])

  useEffect(() => {
    if (getReferringDomainJSONSuccess && referringDomainJSONData?.length) {
      const url = `data:text/json;charset=utf-8,${encodeURIComponent(parse(referringDomainJSONData))}`
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'referring-domain.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch({ type: 'CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_JSON' })
    }
  }, [getReferringDomainJSONSuccess])

  useEffect(() => {
    if (getMostRecentlyCSVSuccess && mostRecentlyCSVData?.length) {
      const url = window.URL.createObjectURL(new Blob([parse(mostRecentlyCSVData)]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'most-recently.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch({ type: 'CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV' })
    }
  }, [getMostRecentlyCSVSuccess])

  useEffect(() => {
    if (getMostImportanCSVSuccess && mostImportanCSVData?.length) {
      const url = window.URL.createObjectURL(new Blob([parse(mostImportanCSVData)]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'most-important.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch({ type: 'CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV' })
    }
  }, [getMostImportanCSVSuccess])

  useEffect(() => {
    if (getMostRecentlyJSONSuccess && mostRecentlyJSONData?.length) {
      const url = `data:text/json;charset=utf-8,${encodeURIComponent(parse(mostRecentlyJSONData))}`
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'most-recently.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch({ type: 'CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON' })
    }
  }, [getMostRecentlyJSONSuccess])

  useEffect(() => {
    if (getMostImportanJSONSuccess && mostImportanJSONData?.length) {
      const url = `data:text/json;charset=utf-8,${encodeURIComponent(parse(mostImportanJSONData))}`
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'most-important.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch({ type: 'CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON' })
    }
  }, [getMostImportanJSONSuccess])

  useEffect(() => {
    // setMostRecently([])

    const activeDomain =
      usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink].items &&
      usersDomain[activeLink].items.length &&
      usersDomain[activeLink].items[0].information &&
      usersDomain[activeLink].items[0].information.seoCheckup
    let tldDistributions = []

    let topCountries
    if (
      activeDomain &&
      usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.tldDistributions &&
      usersDomain[activeLink]?.tldDistributions?.length
    ) {
      tldDistributions = usersDomain[activeLink]?.tldDistributions?.length
        ? usersDomain[activeLink]?.tldDistributions
        : []
      setTldDistribution(tldDistributions)
      topCountries = usersDomain[activeLink]?.tldDistributions
      let sortTopCountries = _.sortBy(topCountries, [
        function (country) {
          return country.domainCount
        },
      ]).reverse()
      setTotalTopCountriesForCSV(topCountries)
      let RegCountry = sortTopCountries.filter((country) => country.code)
      const allCountrycounty = _.map(RegCountry, 'county')
      let uniqcounty = _.uniq(allCountrycounty)
      let count = 0
      let takenIndex
      let uniqueCountry = []
      RegCountry.map((country) => {
        uniqcounty.forEach((name) => {
          if (country.county === name) {
            if (name === 'United States') {
              count = count + 1
              if (count === 1) {
                uniqueCountry.push({
                  county: country.county,
                  backlinkCount: country.backlinkCount,
                  domainCount: country.domainCount,
                  code: 'us',
                })
                takenIndex = uniqueCountry.length - 1
              } else if (count > 1) {
                uniqueCountry[takenIndex].backlinkCount =
                  uniqueCountry[takenIndex].backlinkCount + country.backlinkCount
                uniqueCountry[takenIndex].domainCount =
                  uniqueCountry[takenIndex].domainCount + country.domainCount
              }
            } else {
              uniqueCountry.push({
                county: country.county,
                backlinkCount: country.backlinkCount,
                domainCount: country.domainCount,
                code: country.code,
              })
            }
          }
        })
      })
      let obj = [
        {
          index: uniqueCountry.length + 1,
          domainCount: 0,
          backlinkCount: 0,
          county: 'Others',
        },
      ]
      let nonRegCountry = sortTopCountries.filter((country) => !country.code)
      if (nonRegCountry.length > 0) {
        let domainCount = 0
        let backlinkCount = 0
        nonRegCountry.forEach((country) => {
          if (country.domainCount) {
            domainCount = country.domainCount + domainCount
          }
          if (country.backlinkCount) {
            backlinkCount = country.backlinkCount + backlinkCount
          }
        })
        obj[0].domainCount = domainCount
        obj[0].backlinkCount = backlinkCount
        setTopCountriesOthers(obj)
      }
      setTopCountries(uniqueCountry)
    }
  }, [activeLink, selectedTabNav, getBacklinksDataLoader])

  useEffect(() => {
    setAnchorText([])
    const activeDomain =
      backlinksAnchorTexts &&
      activeLink !== undefined &&
      backlinksAnchorTexts[activeLink] &&
      backlinksAnchorTexts[activeLink].items &&
      backlinksAnchorTexts[activeLink].items.length &&
      backlinksAnchorTexts[activeLink].items[0].information &&
      backlinksAnchorTexts[activeLink].items[0].information.seoCheckup
    let anchorTexts

    if (
      activeDomain &&
      backlinksAnchorTexts &&
      activeLink !== undefined &&
      backlinksAnchorTexts[activeLink] &&
      backlinksAnchorTexts[activeLink].backlinksAnchorTexts &&
      backlinksAnchorTexts[activeLink].backlinksAnchorTexts.length &&
      backlinksAnchorTexts[activeLink].backlinksAnchorTexts?.length
    ) {
      anchorTexts = backlinksAnchorTexts[activeLink]?.backlinksAnchorTexts
      setAnchorText(anchorTexts)
    }
  }, [activeLink, selectedTabNav, backlinksAnchorTexts])

  useEffect(() => {
    setMostRecently([])
    const activeDomain =
      mostRecentlyBacklinksData &&
      activeLink !== undefined &&
      mostRecentlyBacklinksData[activeLink] &&
      mostRecentlyBacklinksData[activeLink].items &&
      mostRecentlyBacklinksData[activeLink].items.length &&
      mostRecentlyBacklinksData[activeLink].items[0].information &&
      mostRecentlyBacklinksData[activeLink].items[0].information.seoCheckup
    let linksArray
    if (
      activeDomain &&
      mostRecentlyBacklinksData &&
      activeLink !== undefined &&
      mostRecentlyBacklinksData[activeLink] &&
      mostRecentlyBacklinksData[activeLink].mostRecentlyBacklinksData &&
      mostRecentlyBacklinksData[activeLink].mostRecentlyBacklinksData.length
    ) {
      linksArray = mostRecentlyBacklinksData[activeLink]?.mostRecentlyBacklinksData
      setMostRecently(linksArray)
    }
  }, [activeLink, selectedTabNav, mostRecentlyBacklinksData])

  useEffect(() => {
    setMostFound([])
    const activeDomain =
      mostImpBacklinksData &&
      activeLink !== undefined &&
      mostImpBacklinksData[activeLink] &&
      mostImpBacklinksData[activeLink].items &&
      mostImpBacklinksData[activeLink].items.length &&
      mostImpBacklinksData[activeLink].items[0].information &&
      mostImpBacklinksData[activeLink].items[0].information.seoCheckup
    let mostImportantLinks = []
    if (
      activeDomain &&
      mostImpBacklinksData &&
      activeLink !== undefined &&
      mostImpBacklinksData[activeLink] &&
      mostImpBacklinksData[activeLink].mostImpBacklinksData &&
      mostImpBacklinksData[activeLink].mostImpBacklinksData.length
    ) {
      const temp1 = mostImpBacklinksData[activeLink]?.mostImpBacklinksData
      const temp2 = temp1.map((item) => {
        return {
          ...item,
          linkRating: item?.linkRating,
        }
      })
      mostImportantLinks = _.sortBy(temp2, [
        function (o) {
          return o.linkRating
        },
      ]).reverse()
      setMostFound(mostImportantLinks)
    }
  }, [activeLink, selectedTabNav, mostImpBacklinksData])

  useEffect(() => {
    setRefferingDomains([])
    const activeDomain =
      referringDomainsData &&
      activeLink !== undefined &&
      referringDomainsData[activeLink] &&
      referringDomainsData[activeLink].items &&
      referringDomainsData[activeLink].items.length &&
      referringDomainsData[activeLink].items[0].information &&
      referringDomainsData[activeLink].items[0].information.seoCheckup
    let referringDomain = []
    if (
      activeDomain &&
      referringDomainsData &&
      activeLink !== undefined &&
      referringDomainsData[activeLink] &&
      referringDomainsData[activeLink].referringDomains &&
      referringDomainsData[activeLink].referringDomains.length
    ) {
      referringDomain = referringDomainsData[activeLink]?.referringDomains
      setRefferingDomains(referringDomain)
    }
  }, [activeLink, selectedTabNav, referringDomainsData])

  useEffect(() => {
    const redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url

    const activeUrlLostBacklinks =
      (lostBacklinks?.length &&
        lostBacklinks?.filter((urlInfo) => {
          let mainUrl = urlInfo?.mainUrl
          
          const isUrlMatched = isMainUrlMatch(mainUrl, redirect_url)
          return isUrlMatched
        })) ||
      []
    setLostBacklinksData(activeUrlLostBacklinks)
  }, [activeLink, lostBacklinks])

  const loadMore = () => {
    const data = localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      if (selectedTabNav == '0') {
        dispatch(
          getBacklinksMostRecently({
            userId: JSON.parse(data)._id,
            limit: mostRecentlyLimit + 10,
            page: mostRecentlyPage,
          }),
        )
        setMostRecentlyLimit(mostRecentlyLimit + 10)
      }
      if (selectedTabNav == '1') {
        dispatch(
          getBacklinksMostImportant({
            userId: JSON.parse(data)._id,
            limit: mostImpLimit + 10,
            page: mostImpPage,
          }),
        )
        setMostImpLimit(mostImpLimit + 10)
      }
      if (selectedTabNav == '2') {
        dispatch(
          getBacklinksReferringDomain({
            userId: JSON.parse(data)._id,
            limit: referringDomainLimit + 10,
            page: referringDomainPage,
          }),
        )
        setReferringDomainLimit(referringDomainLimit + 10)
      }
      if (selectedTabNav == '3') {
        dispatch(
          getBacklinksAnchorText({
            userId: JSON.parse(data)._id,
            limit: anchorTextLimit + 10,
            page: anchorTextPage,
          }),
        )
        setAnchorTextLimit(anchorTextLimit + 10)
      }

      if (selectedTabNav === '6') {
        dispatch(
          getLostBacklinks({
            limit: lostBacklinksLimit + 10,
            page: lostBacklinksPage,
          }),
        )
        setLostBacklinksLimit(lostBacklinksLimit + 10)
      }
    }
  }

  const downloadCSV = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      if (selectedTabNav == 0)
        dispatch(
          getMostRecentlyCSV({
            url: mostRecentlyBacklinksData?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
            type: 'most-recently',
            user: JSON.parse(data)._id,
          }),
        )
      if (selectedTabNav == 1)
        dispatch(
          getMostImportantCSV({
            url: mostImpBacklinksData?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
            type: 'most-important',
            user: JSON.parse(data)._id,
          }),
        )
      if (selectedTabNav == 2)
        dispatch(
          getReferringDomainCSV({
            url: referringDomainsData?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
            type: 'referring-domain',
            user: JSON.parse(data)._id,
          }),
        )
    }
  }

  const downloadJSON = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      if (selectedTabNav == 0) {
        dispatch(
          getMostRecentlyJSON({
            url: mostRecentlyBacklinksData?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
            type: 'most-recently',
            user: JSON.parse(data)._id,
          }),
        )
      }

      if (selectedTabNav == 1) {
        dispatch(
          getMostImportantJSON({
            url: mostImpBacklinksData?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
            type: 'most-important',
            user: JSON.parse(data)._id,
          }),
        )
      }

      if (selectedTabNav == 2) {
        dispatch(
          getReferringDomainJSON({
            url: referringDomainsData?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
            type: 'referring-domain',
            user: JSON.parse(data)._id,
          }),
        )
      }

      if (selectedTabNav == 4) {
        const jsonData = JSON.stringify(tldDistributions)
        const url = `data:text/json;charset=utf-8,${encodeURIComponent(jsonData)}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'tld-distribution.json')
        document.body.appendChild(link)
        link.click()
        link.remove()
      }

      if (selectedTabNav == 3) {
        const jsonData = JSON.stringify(anchorText)
        const url = `data:text/json;charset=utf-8,${encodeURIComponent(jsonData)}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'anchor-text.json')
        document.body.appendChild(link)
        link.click()
        link.remove()
      }

      if (selectedTabNav == 5) {
        const jsonData = JSON.stringify(totalTopCountriesForCSV)
        const url = `data:text/json;charset=utf-8,${encodeURIComponent(jsonData)}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'top-country.json')
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    }
  }

  return (
    <>
      <Col xs="auto" className="d-none d-sm-block">
        <h3 className="f-bold">Backlinks</h3>
      </Col>
      <Card className="mb-0">
        {usersDomain && usersDomain.length ? (
          <>
            {' '}
            <Card className="mb-0">
              <Card.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0}>
                  <Nav
                    variant="tabs"
                    onSelect={(selectedKey) => {
                      dispatch(changeActiveLink(parseInt(selectedKey)))
                    }}
                  >
                    {usersDomain && usersDomain?.length ? (
                      <>
                        {usersDomain?.map((item, index) => {
                          return <MainUrlNav item={item} index={index} key={index + 1} />
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </Nav>
                  <Tab.Content>
                    {usersDomain && usersDomain.length
                      ? usersDomain.map((item, index) => {
                          return (
                            <Tab.Pane key={`Tab-${index + 1}`} eventKey={index}>
                              <Row className={'mt-3'} xs={'m-0'}>
                                <Card className="mb-0">
                                  <Card.Header>
                                    <Card.Title>
                                      <h2 style={{ fontWeight: 300 }}>Past data from SERP</h2>
                                    </Card.Title>
                                  </Card.Header>
                                  <Card.Body>
                                    <Row className="line-charts-section no-gutters d-flex flex-row justify-content-around gap-4">
                                      <Col xs={'12 mb-4'} sm={6} md={4} lg={2}>
                                        <BacklinksCommonCharts
                                          label="Backlinks"
                                          elementName="backlinks"
                                          chartName="Total Backlinks"
                                          tooltip="Total number of incoming links to your domain."
                                        />
                                      </Col>
                                      <Col xs={'12 mb-4'} sm={6} md={4} lg={2}>
                                        <BacklinksCommonCharts
                                          label="ReferringDomains"
                                          elementName="domains"
                                          chartName="Reffering Domains"
                                          tooltip="The number of domains that are linking back to your domain."
                                        />
                                      </Col>
                                      <Col xs={'12 mb-4'} sm={6} md={4} lg={2}>
                                        <BacklinksCommonCharts
                                          label="Domain Rating"
                                          elementName="rank"
                                          chartName="Domain Rating"
                                          tooltip="This metric is measuring the importance of the domain based on the volume and quality of links that are pointing to it. The score is on a scale from 0 to 100, with 100 meaning the domain is highly important and its links communicate a strong amount of popularity. This way, you can identify the most valuable domains that are linking to your site, or the most frequently linking domains that are linking to your site."
                                        />
                                      </Col>
                                      <Col xs={'12 mb-4'} sm={6} md={4} lg={2}>
                                        <BacklinksCommonCharts
                                          label="FollowLinks"
                                          elementName="follow"
                                          chartName="FollowLinks (%)"
                                          tooltip="Follow links are links that instruct search crawlers to follow and therefore boost the SEO of the linked-to site by communicating trust and impacting organic rankings."
                                        />
                                      </Col>
                                      <Col xs={12} sm={6} md={4} lg={2}>
                                        <BacklinksCommonCharts
                                          label="UnFollow links"
                                          elementName="noFollow"
                                          chartName="UnFollowLinks (%)"
                                          tooltip="Total number of incoming links to your domain."
                                        />
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Row>
                            </Tab.Pane>
                          )
                        })
                      : null}
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey={state?.tabId || "0"}>
                  <Nav
                    variant="tabs"
                    onSelect={(selectedKey) => {
                      setSelectedTabNav(selectedKey)
                      // dispatch(changeActiveLink(parseInt(selectedKey)));
                    }}
                  >
                    {navTabsName && navTabsName.length ? (
                      <>
                        {navTabsName.map((item, index) => {
                          return (
                            <>
                              <Nav.Item key={`Nav-${index + 1}`}>
                                <Nav.Link eventKey={item?.key} style={{ width: 200 }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {item?.label}
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </Nav>
                  <Tab.Content>
                    {
                      <>
                        <Tab.Pane eventKey="0">
                          <Row className={'mb-3 mt-3'}>
                            <Col>
                              <Card className="archived-report">
                                {mostRecently && mostRecently?.length ? (
                                  <Table responsive striped>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '2%' }}>#</th>
                                        <th style={{ width: '18%' }}>Links</th>
                                        <th style={{ width: '8%' }}>Link Rating</th>
                                        {/* <th tyle={{width: '15%'}}>Anchor Text</th> */}
                                        <th style={{ width: '10%' }}>TargetUrl</th>
                                        <th style={{ width: '12%' }}>Follow/Unfollow</th>
                                        <th style={{ width: '12%' }}>Internal Links</th>
                                        <th style={{ width: '12%' }}>External Links</th>
                                        <th style={{ width: '10%' }}>First Seen</th>
                                        <th style={{ width: '10%' }}>Last Seen</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {mostRecently?.length > 0 &&
                                        mostRecently.map((item, index) => {
                                          return (
                                            <tr key={`MostRecentTable-${index + 1}`}>
                                              <td>{index + 1}</td>
                                              <td>{item?.url}</td>
                                              <td>{item?.linkRating}</td>
                                              {/* <td>{item?.anchorText || '-'}</td> */}
                                              <td
                                                style={{
                                                  textDecorationLine: 'underline',
                                                }}
                                              >
                                                {item?.targetUrl}
                                              </td>
                                              <td>{item?.followFlag}</td>
                                              <td>{item?.internalLinks || '-'}</td>
                                              <td>{item?.externalLinks || '-'}</td>
                                              <td>{item?.firstSeen ? item?.firstSeen : '-'}</td>
                                              <td>{item?.lastSeen ? item?.lastSeen : '-'}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                ) : getBacklinksMostRecentlyLoader ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                      alignSelf: 'center',
                                      flexDirection: 'row',
                                      minHeight: 380,
                                    }}
                                  >
                                    <Spinner
                                      animation="border"
                                      key={1}
                                      variant={'primary'}
                                      className="me-2"
                                    />
                                  </div>
                                ) : (
                                  <div className="no-data-text">No Data Available.</div>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="1">
                          <Row className={'mb-3 mt-3'}>
                            <Col>
                              <Card className="archived-report">
                                {mostFound && mostFound?.length ? (
                                  <Table responsive striped>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '2%' }}>#</th>
                                        <th style={{ width: '18%' }}>Links</th>
                                        <th style={{ width: '8%' }}>Link Rating</th>
                                        {/* <th style={{width: '15%'}}>Anchor Text</th> */}
                                        <th style={{ width: '10%' }}>TargetUrl</th>
                                        <th style={{ width: '12%' }}>Follow/Unfollow</th>
                                        <th style={{ width: '12%' }}>Internal Links</th>
                                        <th style={{ width: '12%' }}>External Links</th>
                                        <th style={{ width: '10%' }}>First Seen</th>
                                        <th style={{ width: '10%' }}>Last Seen</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {mostFound &&
                                        mostFound.map((item, index) => {
                                          return (
                                            <tr key={`MostFoundTable-${index + 1}`}>
                                              <td>{index + 1}</td>
                                              <td>{item?.url}</td>
                                              <td>{item?.linkRating}</td>
                                              {/* <td>{item?.anchorText || '-'}</td> */}
                                              <td
                                                style={{
                                                  textDecorationLine: 'underline',
                                                }}
                                              >
                                                {item?.targetUrl}
                                              </td>
                                              <td>{item?.followFlag}</td>
                                              <td>{item?.internalLinks || '-'}</td>
                                              <td>{item?.externalLinks || '-'}</td>
                                              <td>{item?.firstSeen ? item?.firstSeen : '-'}</td>
                                              <td>{item?.lastSeen ? item?.lastSeen : '-'}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                ) : getBacklinksMostImportantLoader ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                      alignSelf: 'center',
                                      flexDirection: 'row',
                                      minHeight: 380,
                                    }}
                                  >
                                    <Spinner
                                      animation="border"
                                      key={1}
                                      variant={'primary'}
                                      className="me-2"
                                    />
                                  </div>
                                ) : (
                                  <div className="no-data-text">No Data Available.</div>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="2">
                          <Row className={'mb-3 mt-3'}>
                            <Col>
                              <Card className="archived-report">
                                {refferingDomains && refferingDomains?.length ? (
                                  <Table responsive striped>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '2%' }}>#</th>
                                        <th style={{ width: '18%' }}>Domain</th>
                                        <th style={{ width: '8%' }}>Domain Score</th>
                                        <th style={{ width: '15%' }}>Backlinks Number</th>
                                        <th style={{ width: '10%' }}>First Seen</th>
                                        <th style={{ width: '10%' }}>Last Seen</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {refferingDomains &&
                                        refferingDomains.map((item, index) => {
                                          return (
                                            <tr key={`ReferDomainTable-${index + 1}`}>
                                              <td>{index + 1}</td>
                                              <td>{item?.domain || '-'}</td>
                                              <td>
                                                {item?.domainScore
                                                  ? Number(item?.domainScore).toLocaleString('en-US')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {item?.backlinks
                                                  ? Number(item?.backlinks).toLocaleString('en-US')
                                                  : '-'}
                                              </td>
                                              <td>{item?.firstSeen || '-'}</td>
                                              <td>{item?.lastSeen || '-'}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                ) : getBacklinksReferringDomainLoader ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                      alignSelf: 'center',
                                      flexDirection: 'row',
                                      minHeight: 380,
                                    }}
                                  >
                                    <Spinner
                                      animation="border"
                                      key={1}
                                      variant={'primary'}
                                      className="me-2"
                                    />
                                  </div>
                                ) : (
                                  <div className="no-data-text">No Data Available.</div>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="3">
                          <Row className={'mb-3 mt-3'}>
                            <Col>
                              <Card className="archived-report">
                                {anchorText && anchorText?.length ? (
                                  <Table responsive striped>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '2%' }}>#</th>
                                        <th style={{ width: '18%' }}>Anchor Text</th>
                                        <th style={{ width: '8%' }}>Domains</th>
                                        <th style={{ width: '8%' }}>Backlinks</th>
                                        {/* <th style={{width: '15%'}}>Percentage</th> */}
                                        <th style={{ width: '15%' }}>First Seen</th>
                                        <th style={{ width: '15%' }}>Last Seen</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {anchorText &&
                                        anchorText.map((item, index) => {
                                          return (
                                            <tr key={`anchorTextTable-${index + 1}`}>
                                              <td>{index + 1}</td>
                                              <td>{item?.anchorText || '-'}</td>
                                              <td>
                                                {item?.domain
                                                  ? Number(item?.domain).toLocaleString('en-US')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {item?.backlinks
                                                  ? Number(item?.backlinks).toLocaleString('en-US')
                                                  : '-'}
                                              </td>
                                              {/* <td>{item?.percentage ? item?.percentage.replace(",", '.') : <></>}</td> */}
                                              <td>{item?.firstSeen}</td>
                                              <td>{item?.lastSeen}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                ) : getBacklinksAnchorTextLoader ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flex: 1,
                                      alignSelf: 'center',
                                      flexDirection: 'row',
                                      minHeight: 380,
                                    }}
                                  >
                                    <Spinner
                                      animation="border"
                                      key={1}
                                      variant={'primary'}
                                      className="me-2"
                                    />
                                  </div>
                                ) : (
                                  <div className="no-data-text mb-5">No Data Available.</div>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="4">
                          {!getBacklinksDataLoader ? (
                            <Row className={'mb-3 mt-3'}>
                              <Col>
                                <Card className="archived-report">
                                  {tldDistributions && tldDistributions?.length ? (
                                    <Table responsive striped>
                                      <thead>
                                        <tr>
                                          <th style={{ width: '2%' }}>#</th>
                                          <th style={{ width: '18%' }}>TLD Zone</th>
                                          <th style={{ width: '8%' }}>Domains</th>
                                          <th style={{ width: '15%' }}>Backlinks</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {tldDistributions &&
                                          tldDistributions.map((item, index) => {
                                            return (
                                              <tr key={`tldTable-${index + 1}`}>
                                                <td>{index + 1}</td>
                                                <td>{item?.tldZone}</td>
                                                <td>{item?.domainCount}</td>
                                                <td>{item?.backlinkCount}</td>
                                              </tr>
                                            )
                                          })}
                                      </tbody>
                                    </Table>
                                  ) : (
                                    <div className="no-data-text mb-5">No Data Available.</div>
                                  )}
                                </Card>
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flex: 1,
                                  alignSelf: 'center',
                                  flexDirection: 'row',
                                  minHeight: 380,
                                }}
                              >
                                <Spinner animation="border" key={1} variant={'primary'} className="me-2" />
                              </div>
                            </>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="5">
                          {!getBacklinksDataLoader ? (
                            <Row className={'mb-3 mt-3'}>
                              <Col>
                                <Card className="archived-report">
                                  {topCountries && topCountries?.length ? (
                                    <Table responsive striped>
                                      <thead>
                                        <tr>
                                          <th style={{ width: '2%' }}>#</th>
                                          <th style={{ width: '18%' }}>Country</th>
                                          <th style={{ width: '8%' }}>Domains</th>
                                          <th style={{ width: '15%' }}>Backlinks</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {topCountries &&
                                          topCountries.map((item, index) => {
                                            if (item?.code) {
                                              return (
                                                <tr key={`countriesTable-${index + 1}`}>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    <span className={`fi fi-${item?.code}`} /> &nbsp;{' '}
                                                    {item?.county || 'Other'}
                                                  </td>
                                                  <td>{item?.domainCount}</td>
                                                  <td>{item?.backlinkCount}</td>
                                                </tr>
                                              )
                                            }
                                          })}
                                        {topCountries && topCountriesOthers ? (
                                          <tr>
                                            <td>{topCountriesOthers[0].index} </td>
                                            <td>
                                              <b> {topCountriesOthers[0].county}</b>
                                            </td>
                                            <td>
                                              <b> {topCountriesOthers[0].domainCount}</b>
                                            </td>
                                            <td>
                                              <b> {topCountriesOthers[0].backlinkCount}</b>
                                            </td>
                                          </tr>
                                        ) : (
                                          <></>
                                        )}
                                      </tbody>
                                    </Table>
                                  ) : (
                                    <div className="no-data-text mb-5">No Data Available.</div>
                                  )}
                                </Card>
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flex: 1,
                                  alignSelf: 'center',
                                  flexDirection: 'row',
                                  minHeight: 380,
                                }}
                              >
                                <Spinner animation="border" key={1} variant={'primary'} className="me-2" />
                              </div>
                            </>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="6">
                          <Row className={'mb-3 mt-3'}>
                            <Col>
                              <Card className="archived-report">
                                {lostBacklinksData &&
                                lostBacklinksData?.length &&
                                lostBacklinksData[0]?.backlinksData?.length ? (
                                  <Table responsive striped>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '2%' }}>#</th>
                                        <th style={{ width: '18%' }}>Lost Backlinks</th>
                                        <th style={{ width: '8%' }}>Link Rating</th>
                                        <th style={{ width: '10%' }}>TargetUrl</th>
                                        <th style={{ width: '12%' }}>Follow/Unfollow</th>
                                        <th style={{ width: '12%' }}>Internal Links</th>
                                        <th style={{ width: '12%' }}>External Links</th>
                                        <th style={{ width: '10%' }}>First Seen</th>
                                        <th style={{ width: '10%' }}>Last Seen</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {lostBacklinksData[0]?.backlinksData &&
                                        lostBacklinksData[0]?.backlinksData?.map((item, index) => {
                                          return (
                                            <tr key={`LostBacklinksTable-${index + 1}`}>
                                              <td>{index + 1}</td>
                                              <td>{item?.url} </td>
                                              <td>{item?.linkRating} </td>
                                              <td>{item?.targetUrl} </td>
                                              <td>{item?.followFlag} </td>
                                              <td>{item?.internalLinks} </td>
                                              <td>{item?.externalLinks} </td>
                                              <td>{item?.firstSeen} </td>
                                              <td>{item?.lastSeen} </td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <div className="no-data-text mb-5">No Data Available.</div>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <div className="backlink-csv-setion backlinks-loader-mobile">
                          {selectedTabNav == 0 ? (
                            <div style={{ marginBottom: 12 }}>
                              {getBacklinksMostRecentlyLoader ? (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksMostRecentlyLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  {getBacklinksMostRecentlyLoader ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flex: 1,
                                          alignSelf: 'center',
                                          flexDirection: 'row',
                                          minHeight: 10,
                                        }}
                                      >
                                        <Spinner animation="border" key={1} className="me-2" size="sm" /> Load
                                        More
                                      </div>
                                    </>
                                  ) : (
                                    <>Load More</>
                                  )}
                                </Button>
                              ) : !getBacklinksMostRecentlyLoader && mostRecently?.length === 0 ? (
                                <></>
                              ) : (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksMostRecentlyLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  Load More{' '}
                                </Button>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 1 ? (
                            <div style={{ marginBottom: 12 }}>
                              {getBacklinksMostImportantLoader ? (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksMostImportantLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  {getBacklinksMostImportantLoader ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flex: 1,
                                          alignSelf: 'center',
                                          flexDirection: 'row',
                                          minHeight: 10,
                                        }}
                                      >
                                        <Spinner animation="border" key={1} className="me-2" size="sm" /> Load
                                        More
                                      </div>
                                    </>
                                  ) : (
                                    <>Load More</>
                                  )}
                                </Button>
                              ) : !getBacklinksMostImportantLoader && mostFound?.length === 0 ? (
                                <></>
                              ) : (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksMostImportantLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  Load More{' '}
                                </Button>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 2 ? (
                            <div style={{ marginBottom: 12, marginTop: 6 }}>
                              {getBacklinksReferringDomainLoader ? (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksReferringDomainLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  {getBacklinksReferringDomainLoader ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flex: 1,
                                          alignSelf: 'center',
                                          flexDirection: 'row',
                                          minHeight: 10,
                                        }}
                                      >
                                        <Spinner animation="border" key={1} className="me-2" size="sm" /> Load
                                        More
                                      </div>
                                    </>
                                  ) : (
                                    <>Load More</>
                                  )}
                                </Button>
                              ) : !getBacklinksReferringDomainLoader && refferingDomains?.length === 0 ? (
                                <></>
                              ) : (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksReferringDomainLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  Load More{' '}
                                </Button>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 3 ? (
                            <div style={{ marginBottom: 12, marginTop: 6 }}>
                              {getBacklinksAnchorTextLoader ? (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksAnchorTextLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  {getBacklinksAnchorTextLoader ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flex: 1,
                                          alignSelf: 'center',
                                          flexDirection: 'row',
                                          minHeight: 10,
                                        }}
                                      >
                                        <Spinner animation="border" key={1} className="me-2" size="sm" /> Load
                                        More
                                      </div>
                                    </>
                                  ) : (
                                    <>Load More</>
                                  )}
                                </Button>
                              ) : !getBacklinksAnchorTextLoader && anchorText?.length === 0 ? (
                                <></>
                              ) : (
                                <Button
                                  variant="primary"
                                  disabled={getBacklinksAnchorTextLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  Load More{' '}
                                </Button>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 6 ? (
                            <div style={{ marginBottom: 12, marginTop: 6 }}>
                              {lostBacklinksLoader && lostBacklinksData[0]?.backlinksData?.length ? (
                                <Button
                                  variant="primary"
                                  disabled={lostBacklinksLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  {lostBacklinksLoader ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flex: 1,
                                          alignSelf: 'center',
                                          flexDirection: 'row',
                                          minHeight: 10,
                                        }}
                                      >
                                        <Spinner animation="border" key={1} className="me-2" size="sm" /> Load
                                        More
                                      </div>
                                    </>
                                  ) : (
                                    <>Load More</>
                                  )}
                                </Button>
                              ) : !lostBacklinksData[0]?.backlinksData?.length ? (
                                <></>
                              ) : (
                                <Button
                                  variant="primary"
                                  disabled={lostBacklinksLoader}
                                  onClick={() => loadMore()}
                                >
                                  {' '}
                                  Load More{' '}
                                </Button>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                          {selectedTabNav == 4 || selectedTabNav == 5 ? <div> </div> : <></>}

                          {selectedTabNav == 3 && anchorText?.length ? (
                            <>
                              <div className="backlink-csv-setion backilnks-for-mobile">
                                <div>
                                  <CSVDownloader
                                    type={Type.Link}
                                    filename={'anchor-text'}
                                    bom={true}
                                    data={anchorText && anchorText?.length ? parse(anchorText) : <></>}
                                  >
                                    <Button variant="secondary" outine className="me-1 mb-1">
                                      <FontAwesomeIcon icon={faFileCsv} /> Export CSV
                                    </Button>
                                  </CSVDownloader>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    onClick={downloadJSON}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faDownload} /> Export JSON
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 4 && tldDistributions?.length ? (
                            <>
                              <div className="backlink-csv-setion backilnks-for-mobile">
                                <div>
                                  <CSVDownloader
                                    type={Type.Link}
                                    filename={'tld-distribution'}
                                    bom={true}
                                    data={parse(tldDistributions)}
                                  >
                                    <Button variant="secondary" outine className="me-1 mb-1">
                                      <FontAwesomeIcon icon={faDownload} /> Export CSV
                                    </Button>
                                  </CSVDownloader>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    onClick={downloadJSON}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faDownload} /> Export JSON
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {selectedTabNav == 5 && totalTopCountriesForCSV?.length ? (
                            <>
                              <div className="backlink-csv-setion backilnks-for-mobile">
                                <div>
                                  <CSVDownloader
                                    type={Type.Link}
                                    filename={'top-countries'}
                                    bom={true}
                                    data={parse(totalTopCountriesForCSV)}
                                  >
                                    <Button variant="secondary" outine className="me-1 mb-1">
                                      <FontAwesomeIcon icon={faFileCsv} /> Export CSV
                                    </Button>
                                  </CSVDownloader>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    onClick={downloadJSON}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faDownload} /> Export JSON
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 0 && mostRecently?.length ? (
                            <>
                              <div className="backlink-csv-setion backilnks-for-mobile">
                                <div>
                                  <Button
                                    disabled={getMostRecentlyCSVLoader}
                                    variant="secondary"
                                    onClick={downloadCSV}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faFileCsv} /> Export CSV{' '}
                                    {getMostRecentlyCSVLoader ? (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        key={1}
                                        variant={'primary'}
                                        className="me-2"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    disabled={getMostRecentlyJSONLoader}
                                    onClick={downloadJSON}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faDownload} /> Export JSON{' '}
                                    {getMostRecentlyJSONLoader ? (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        key={1}
                                        variant={'primary'}
                                        className="me-2"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 1 && mostFound?.length ? (
                            <>
                              {' '}
                              <div className="backlink-csv-setion backilnks-for-mobile">
                                <div>
                                  <Button
                                    disabled={getMostImportantCSVLoader}
                                    variant="secondary"
                                    onClick={downloadCSV}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faFileCsv} /> Export CSV{' '}
                                    {getMostImportantCSVLoader ? (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        key={1}
                                        variant={'primary'}
                                        className="me-2"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    disabled={getMostImportantJSONLoader}
                                    onClick={downloadJSON}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faDownload} /> Export JSON{' '}
                                    {getMostImportantJSONLoader ? (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        key={1}
                                        variant={'primary'}
                                        className="me-2"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectedTabNav == 2 && refferingDomains?.length ? (
                            <>
                              <div className="backlink-csv-setion backilnks-for-mobile">
                                <div>
                                  <Button
                                    disabled={getReferringDomainCSVLoader}
                                    variant="secondary"
                                    onClick={downloadCSV}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faFileCsv} /> Export CSV{' '}
                                    {getReferringDomainCSVLoader ? (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        key={1}
                                        variant={'primary'}
                                        className="me-2"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    disabled={getReferringDomainJSONLoader}
                                    onClick={downloadJSON}
                                    outine
                                    className="me-1 mb-1"
                                  >
                                    <FontAwesomeIcon icon={faDownload} /> Export JSON{' '}
                                    {getReferringDomainJSONLoader ? (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        key={1}
                                        variant={'primary'}
                                        className="me-2"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    }
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                alignSelf: 'center',
                flexDirection: 'row',
                minHeight: 380,
              }}
            >
              <div className="text-center">
                <Loader />
              </div>
            </div>
          </>
        )}
      </Card>
    </>
  )
}
export default BackLinks

import { useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Row, Col } from 'reactstrap'
import { CommonAlert } from 'components/uiCore'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const SiteMapData = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()

  const [siteMapDataFlag, setBrokenLinkFlag] = useState(false)

  const columns = [
    {
      id: 'sitemapUrls',
      displayName: 'Sitemap urls',
    },
  ]

  const sitemapDataCsv = data?.siteMapData?.data?.siteMapUrl?.map(item => {
    return {
      sitemapUrls: item
    }
  })

  return (
    <>
      {data && data?.siteMapData ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.siteMapData?.data?.testResults?.status ? (
                ''
              ) : data?.siteMapData?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.siteMapData?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : data?.siteMapData?.data?.testResults?.status === 4 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            {data?.siteMapData?.data?.testResults?.status === 4 ? (
              <CommonAlert
                variant={'warning'}
                key={'meta-title'}
                alertText={
                  <div>
                    <span>{data?.siteMapData?.data?.testResults?.cachedMessage}</span>
                    <Link
                      to="/pages/firewall-options"
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                    >
                      click here
                    </Link>
                  </div>
                }
              />
            ) : (
              <></>
            )}
            <p>
              {data.siteMapData?.data?.testResults ? (
                <>{data.siteMapData?.data?.testResults?.message || ''}</>
              ) : (
                <></>
              )}
            </p>

            {showDetail && data?.siteMapData?.data?.testResults?.status === 1 ? (
              <p 
                className="text-primary cursor-pointer" 
                onClick={() => {
                  if (testType === "") {
                    setBrokenLinkFlag(!siteMapDataFlag)
                  } else {
                    dispatch(openRegisterModal(true))
                  }
                }}>
                {' '}
                See Details
              </p>
            ) : null }
          </div>
          <ModalHOC
            show={siteMapDataFlag}
            toggleShow={() => setBrokenLinkFlag(!siteMapDataFlag)}
            title={'Sitemap list'}
            bodyClassName="m-3"
            titleStyles={{ fontWeight: '400', fontSize: '22px' }}
            showDownload
            columns={columns}
            datas={sitemapDataCsv}
            fileName='Sitemap Signal Test'
          >
            <p>Here is a list of all your primary sitemap links</p>
            <Row className="mt-3">
              <Col>
                {data?.siteMapData?.data?.siteMapUrl?.length &&
                  data?.siteMapData?.data?.siteMapUrl.map((item, index) => {
                    return (
                      <li key={`siteMap-${index +1}`}>
                        <a href={item} target="_BLANK">
                          {' '}
                          {item}
                        </a>
                      </li>
                    )
                  })}
              </Col>
            </Row>
          </ModalHOC>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SiteMapData

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const RobotsTxtTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.robotsTxtTest.heading}
      whatIsIT={freeTools.robotsTxtTest.what}
      howToFixBeforeCode={freeTools.robotsTxtTest.howSectionBeforeCode}
      codeSection={freeTools.robotsTxtTest.codeSection}
      howToFixAfterCode={freeTools.robotsTxtTest.howSectionAfterCode}
    />
  )
}

export default RobotsTxtTest

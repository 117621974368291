import React from 'react'

const UrlBox = (props) => {
  const { url, status, radioButtonClick, checked, redirectUrl, colorCode } = props
  if (colorCode === 1) {
    return (
      <div className={'green-box'}>
        <span className='green-box-input-container'>
        <input
          id={`${url}${status}`}
          type="radio"
          checked={checked}
          name="urlRadioButton"
          onChange={() => radioButtonClick(url)}
        />
        </span>
        <label htmlFor={`${url}${status}`} className={'green-box-text f-black'}>{`${url} (${
          typeof status == 'number' ? status : 200
        })`}</label>
      </div>
    )
  } else if (colorCode === 2) {
    return (
      <div className={'yellow-box'}>
        <div>
        <span className='yellow-box-input-container'>
        <input
          id={`${url}${status}`}
          type="radio"
          name="urlRadioButton"
          checked={checked}
          onChange={() => radioButtonClick(url)}
        />
        </span>
        </div>
        <div>
        <label htmlFor={`${url}${status}`} className={'yellow-box-text f-black'}>{`${url} (${
          typeof status == 'number' ? status : 503
        }) ${
          status >= 400 || typeof status !== 'number'
            ? 'is an Unknown Host'
            : `is forwarding to ${redirectUrl} (200)`
        }`}</label>
        </div>
      </div>
    )
  } else {
    return (
      <div className={'red-box'}>
        <div>
        <span className='red-box-input-container'>
        <input
          id={`${url}${status}`}
          type="radio"
          name="urlRadioButton"
          checked={checked}
          onChange={() => radioButtonClick(url)}
        />
        </span>
        </div>
        <div>
        <label htmlFor={`${url}${status}`} className={'red-box-text f-black'}>{`${url} (${
          typeof status == 'number' ? status : 503
        }) ${
          status >= 400 || typeof status !== 'number'
            ? 'is an Unknown Host'
            : typeof status !== 301
            ? `is forwarding to ${redirectUrl} (301)`
            : `is forwarding to ${redirectUrl} (200)`
        }`}</label>
        </div>
      </div>
    )
  }
}

export default UrlBox

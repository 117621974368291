import { useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

import ModalHOC from '../tests-components/ModalHOC'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const CSSCache = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [cssCacheFlag, setCssCacheFlag] = useState(false)

  const columns = [
    {
      id: 'notCachedUrl',
      displayName:'Not Cached Resources'
    }
  ]

  const cssCacheCsv = data?.cssCache?.data?.withoutCachedResults?.map(item => {
    return {
      notCachedUrl: item?.url
    }
  })
  return (
    <>
      {data && data?.cssCache ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.cssCache?.data?.testResults?.status ? (
                ''
              ) : data?.cssCache?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.cssCache?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p>
              {data.cssCache?.data?.testResults ? (
                <>{data.cssCache?.data?.testResults?.message || ''}</>
              ) : (
                <></>
              )}
            </p>

            {showDetail &&
            data?.cssCache?.data?.testResults?.status !== 1 &&
            data.cssCache?.data?.withoutCachedResults &&
            data.cssCache?.data?.withoutCachedResults.length ? (
              <>
                <p 
                  className="text-primary cursor-pointer" 
                  onClick={() => {
                    if (testType === "") {
                      setCssCacheFlag(!cssCacheFlag)
                    } else {
                      dispatch(openRegisterModal(true))
                    }
                  }}>
                  {' '}
                  See Details
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <ModalHOC
            show={cssCacheFlag}
            toggleShow={() => setCssCacheFlag(!cssCacheFlag)}
            title={'CSS Caching Signal Test'}
            bodyClassName="m-3"
            titleStyles={{ fontWeight: '400', fontSize: '22px' }}
            showDownload
            columns={columns}
            datas = {cssCacheCsv}
            fileName='CSS Caching Signal Test'
          >
            <h5>Not Cached Resources</h5>
            {data.cssCache?.data?.withoutCachedResults?.length ? (
              <ul>
                {' '}
                {data.cssCache?.data?.withoutCachedResults?.map((item) => {
                  return <li>{item.url}</li>
                })}
              </ul>
            ) : (
              <></>
            )}
          </ModalHOC>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CSSCache

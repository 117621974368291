import React, { useState, useEffect } from 'react'
import { Line } from '@ant-design/charts'
import * as moment from 'moment'

const Page = ({ finalChartData }) => {
  const [config, setConfig] = useState({
    data: [],
    width: 800,
    height: 475,
    autoFit: true,
    animation: false,
    xField: 'x',
    yField: 'y',
    seriesField: 'competitor',
    smooth: true,
    legend: { marker: { symbol: 'circle' } },
    point: {
      size: 5,
      shape: 'circle',
      fontFamily: 'Seravek',
    },
    legend: {
      itemName: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    tooltip: {
      title: (datum) => {
        return moment(datum, 'MM-DD').format('DD MMM')
      },
      text: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      domStyles: {
        'g2-tooltip': { fontFamily: 'Seravek' },
      },
    },
    label: {
      style: {
        fontFamily: 'Seravek',
      },
    },
    yAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
  })

  const sortedChartData = finalChartData.slice().sort((a, b) => {
    let dateComparison = new Date(a.x) - new Date(b.x);
    if (dateComparison !== 0) {
      return dateComparison; 
    }
    
    return b.y - a.y;
  });
  
  // eslint-disable-next-line no-unused-vars
  let chart
  useEffect(() => {
    const config = {
      data: (sortedChartData && sortedChartData?.length && sortedChartData) || [],
      width: 800,
      height: 475,
      autoFit: true,
      animation: false,
      xField: 'x',
      yField: 'y',
      seriesField: 'competitor',
      smooth: true,
      legend: { marker: { symbol: 'circle' } },
      point: {
        size: 5,
        shape: 'circle',
        fontFamily: 'Seravek',
      },
      legend: {
        itemName: {
          style: {
            fontFamily: 'Seravek',
          },
        },
      },
      tooltip: {
        title: (datum) => {
          return moment(datum, 'MM-DD').format('DD MMM')
        },
        text: {
          style: {
            fontFamily: 'Seravek',
          },
        },
        label: {
          style: {
            fontFamily: 'Seravek',
          },
        },
        domStyles: {
          'g2-tooltip': { fontFamily: 'Seravek' },
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      yAxis: {
        label: {
          style: {
            fontFamily: 'Seravek',
          },
        },
      },
      xAxis: {
        label: {
          style: {
            fontFamily: 'Seravek',
          },
        },
      },
    }

    setConfig(config)
  }, [finalChartData])

  return (
    <div className="mt-5">
      <Line {...config} onReady={(chartInstance) => (chart = chartInstance)} />
    </div>
  )
}

const MemoizedMyComponent = React.memo(Page)

export default MemoizedMyComponent

import Chart from 'react-apexcharts'

const KeywordsPoistionHistoryAreaChart = ({ chartData }) => {

  let historyData = []
  let historyCategories = []
  

  const convertDateFormat = (currentDate) => {
    const convertedDate = currentDate?.split('-')
    const formattedDate = `${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`
    return formattedDate
  }
  
  chartData && 
  chartData?.length && 
  chartData?.forEach(item => {
    historyData?.push(item?.position)
    
    historyCategories.push(convertDateFormat(item?.date))
  })

  const data = [
    {
      name: "History",
      data: historyData,
    }
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: historyCategories ,
    },
    tooltip: {
      x: {
        format: 'MMM yyyy',
      },
    },
  }

  return (
    <div className="chart w-100">
      <Chart options={options} series={data} type="area" height="350" />
    </div>
  )
}

export default KeywordsPoistionHistoryAreaChart

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { sizeDetermination } from 'constants/Utils'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const PageObject = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [pageObjectFlag, setPageObjectFlag] = useState(false)

  const content_size_by_contentType = [
    {
      id:'contentType',
      displayName:'Content Type'
    },
    {
      id:'percent',
      displayName:'Percent'
    },
    {
      id:'size',
      displayName:'Size'
    },
  ]

  const contentSizeCsvData =
    data?.pageObject?.data?.finalStatus?.pageObject?.map((item) => ({
      ['Content Type']: item?.contentType,
      Percent: `${item?.sizePercentage?.toFixed(1)} %`,
      Size: sizeDetermination(item?.size),
    })) || []

  const totalSize = sizeDetermination(data?.pageObject?.data?.finalStatus?.pageObject?.[0]?.totalSize)

  const contentSizeCsv = [
    ...contentSizeCsvData,
    {
      ['Content Type']: 'Total',
      Percent: '100 %',
      Size: totalSize,
    },
  ]

  const request_by_contentType = [
    {
      id: 'contentType',
      displayName: 'Content Type',
    },
    {
      id: 'percent',
      displayName: 'Percent',
    },
    {
      id: 'requests',
      displayName: 'Requests',
    },
  ]

  const requestByContentTypeCsvData =
    data?.pageObject?.data?.finalStatus?.pageObject?.map((item) => {
      return {
        ['Content Type']: item?.contentType,
        Percent: `${item?.requestPercentage?.toFixed(1)} %`,
        Requests: item?.requests,
      }
    }) || []
  const totalRequest = data?.pageObject?.data?.finalStatus?.pageObject?.[0]?.totalRequests
  const requestByContentTypeCsv = [
    ...requestByContentTypeCsvData,
    {
      ['Content Type']: 'Total',
      Percent: '100 %',
      Requests: totalRequest,
    },
  ]

  const content_size_by_domain = [
    {
      id: 'domain',
      displayName: 'Domain',
    },
    {
      id: 'percent',
      displayName: 'Percent',
    },
    {
      id: 'size',
      displayName: 'Size',
    },
  ]

  let contentSizeByDomainCsvData =
    data?.pageObject?.data?.finalStatus?.pageObjects?.map((item) => {
      return {
        Domain: item?.url?.length > 30 ? item?.url?.substring(0, 30).concat('...') : item?.url,
        Percent: `${item?.sizePercentage} %`,
        Size: sizeDetermination(item?.size),
      }
    }) || []
  const totalSizeByDomain = sizeDetermination(data?.pageObject?.data?.finalStatus?.pageObjects?.[0]?.totalSize)
  const contentSizeByDomainCsv = [
    ...contentSizeByDomainCsvData,
    {
      Domain: 'Total',
      Percent: '100 %',
      Size: totalSizeByDomain,
    },
  ]

  const request_by_domain = [
    {
      id: 'contentType',
      displayName: 'Content Type',
    },
    {
      id: 'percent',
      displayName: 'Percent',
    },
    {
      id: 'requests',
      displayName: 'Requests',
    },
  ]

  let requestByDomainCsvaData =
    data?.pageObject?.data?.finalStatus?.pageObjects
      ?.sort((x, y) => y.count - x.count)
      ?.map((item) => {
        return {
          ['Content Type']: item?.url?.length > 30 ? item?.url?.substring(0, 30).concat('...') : item?.url,
          Percent: `${item?.countPercentage} %`,
          Requests: item?.count,
        }
      }) || []
  const totaloccuence = data?.pageObject?.data?.finalStatus?.pageObjects?.[0]?.totalOccurenceCount
  const requestByDomainCsv = [
    ...requestByDomainCsvaData,
    {
      ['Content Type']: 'Total',
      Percent: '100 %',
      Requests: totaloccuence,
    },
  ]

  const renderRequestByDomain = (pageObjects, isMobile = false) => {
    let objectsArray = [].concat(pageObjects)
    return objectsArray
      ?.sort((x, y) => y.count - x.count)
      .map((item, index) => {
        return (
          <>
            {!isMobile ? (
              <tr key={`PageObject-${index+1}`}>
                <td>{item?.url?.length > 30 ? item?.url?.substring(0, 30).concat('...') : item?.url}</td>
                <td>{item?.countPercentage} %</td>
                <td>{item?.count} </td>
              </tr>
            ) : (
              <div key={`PageObjects-${index+1}`}>
                <li className="mb-3">
                  <p className="mb-0 f-bold">Content Type</p>
                  <p className="mb-2">
                    {item?.url?.length > 30 ? item?.url?.substring(0, 30).concat('...') : item?.url}
                  </p>
                </li>
                <li className="mb-3">
                  <p className="mb-0 f-bold">Percent</p>
                  <p className="mb-2">{item?.countPercentage} %</p>
                </li>
                <li className="border-bottom mb-3">
                  <p className="mb-0 f-bold">Requests</p>
                  <p className="mb-2">{item?.count}</p>
                </li>
              </div>
            )}
          </>
        )
      })
  }

  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.pageObject?.data?.testResults?.status ? (
            ''
          ) : data?.pageObject?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.pageObject?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />
        <div>
          {' '}
          <p>{data?.pageObject?.data?.testResults?.message}</p>{' '}
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />
        <div>
          {showDetail ? (
            <>
              <p 
                className="text-primary cursor-pointer" 
                onClick={() => {
                  if (testType === "") {
                    setPageObjectFlag(!pageObjectFlag)
                  } else {
                    dispatch(openRegisterModal(true))
                  }
                }}>
                {' '}
                See Details
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ModalHOC
        show={pageObjectFlag}
        toggleShow={() => setPageObjectFlag(!pageObjectFlag)}
        title={'Page Objects Signal Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        dataForDownload = {
          [
            {key:"Content size by content type", datas: contentSizeCsv, columns: content_size_by_contentType},
            {key:"Request by content type", datas: requestByContentTypeCsv, columns: request_by_contentType},
            {key:"Content size by domain", datas: contentSizeByDomainCsv, columns: content_size_by_domain},
            {key:"Request by domain", datas: requestByDomainCsv, columns: request_by_domain},
          ]
        }
        fileName='Page Objects Signal Test'
        alternativeDownload={true}
      >
        <div className="page-object-table-container d-none d-sm-block">
          <div className="page-object-table">
            <div className="size-table">
              <p className="po-table-title" style={{fontWeight:'400'}}> Content size by content type </p>
              {data?.pageObject?.data?.finalStatus?.pageObject?.length ? (
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Content Type</th>
                      <th>Percent</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.pageObject?.data?.finalStatus?.pageObject.map((item, index) => {
                      return (
                        <>
                            <tr key={`PageObject-${index+1}`}>
                              <td>
                                {item?.contentType}
                              </td>
                              <td>{item?.sizePercentage?.toFixed(1)} %</td>
                              <td>{sizeDetermination(item?.size)}</td>
                              {/* <td>{(item?.transferSize / 1000000).toFixed(2) >= 1 ? `${(item?.transferSize / 1000000).toFixed(2)} MB` : `${numeral(item?.transferSize).format('0.0.[000] a')}`}</td> */}
                            </tr>
                        </>
                      )
                    })}
                  </tbody>{' '}
                  <tbody>
                    <tr>
                      {' '}
                      <td>
                        <b className='f-bold'>Total</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>100 %</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>
                          {sizeDetermination(
                            data?.pageObject?.data?.finalStatus?.pageObject?.[0]?.totalSize,
                          )}
                        </b>
                      </td>{' '}
                    </tr>
                  </tbody>{' '}
                </Table>
              ) : (
                <p>No Objects Found</p>
              )}
            </div>

            <div className="request-count-table">
              <p className="po-table-title" style={{fontWeight:'400'}}> Request by content type </p>
              {data?.pageObject?.data?.finalStatus?.pageObject?.length ? (
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Content Type</th>
                      <th>Percent</th>
                      <th>Requests</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.pageObject?.data?.finalStatus?.pageObject.map((item, index) => {
                      return (
                        <>
                            <tr key={`PageObjectRequest-${index+1}`}>
                              <td>
                                {item?.contentType}
                              </td>
                              <td>{item?.requestPercentage?.toFixed(1)} %</td>
                              <td>{item?.requests} </td>
                            </tr>
                        </>
                      )
                    })}
                  </tbody>{' '}
                  <tbody>
                    <tr>
                      {' '}
                      <td>
                        <b className='f-bold'>Total</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>100 %</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>{data?.pageObject?.data?.finalStatus?.pageObject?.[0]?.totalRequests}</b>
                      </td>{' '}
                    </tr>
                  </tbody>{' '}
                </Table>
              ) : (
                <p>No Objects Found</p>
              )}
            </div>
          </div>
          <div className="page-object-table">
            <div className="size-table">
              <p className="po-table-title" style={{fontWeight:'400'}}> Content size by domain </p>
              {data?.pageObject?.data?.finalStatus?.pageObjects?.length ? (
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Domain</th>
                      <th>Percent</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.pageObject?.data?.finalStatus?.pageObjects.map((item, index) => {
                      // const sizes = ['Bytes', 'KB', 'MB']
                      // const i = Math.floor(Math.log(item?.size) / Math.log(1024))
                      // const size = `${parseFloat((item?.size / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`
                      return (
                        <tr key={`PageObjectContent-${index+1}`}>
                          <td>
                            {item?.url?.length > 30 ? item?.url?.substring(0, 30).concat('...') : item?.url}
                          </td>
                          <td>{item?.sizePercentage} %</td>
                          <td>{sizeDetermination(item?.size)}</td>
                        </tr>
                      )
                    })}
                  </tbody>{' '}
                  <tbody>
                    <tr>
                      {' '}
                      <td>
                        <b className='f-bold'>Total</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>100 %</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>
                          {sizeDetermination(data?.pageObject?.data?.finalStatus?.pageObjects?.[0].totalSize)}
                        </b>
                      </td>{' '}
                    </tr>
                  </tbody>{' '}
                </Table>
              ) : (
                <p>No Objects Found</p>
              )}
            </div>
            <div className="size-table">
              <p className="po-table-title" style={{fontWeight:'400'}}> Request by domain </p>
              {data?.pageObject?.data?.finalStatus?.pageObjects?.length ? (
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Content Type</th>
                      <th>Percent</th>
                      <th>Requests</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderRequestByDomain(data?.pageObject?.data?.finalStatus?.pageObjects, false)}
                  </tbody>{' '}
                  <tbody>
                    <tr>
                      {' '}
                      <td>
                        <b className='f-bold'>Total</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>100 %</b>
                      </td>{' '}
                      <td>
                        <b className='f-bold'>{data?.pageObject?.data?.finalStatus?.pageObjects?.[0].totalOccurenceCount}</b>
                      </td>{' '}
                    </tr>
                  </tbody>{' '}
                </Table>
              ) : (
                <p>No Objects Found</p>
              )}
            </div>
          </div>
        </div>
        {/* Start Mobile Responsive */}
        <div className="page-object-table-responsive d-block d-sm-none">
          {/* Start Content size */}
          <div className="border-bottom mb-3">
            <p className="f-bold">Content size by content type aaa </p>
            {data?.pageObject?.data?.finalStatus?.pageObject?.length ? (
              <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
                {data?.pageObject?.data?.finalStatus?.pageObject.map((item, index) => (
                  <div key={`PageObjectContentSize-${index+1}`}>
                    {index !== 0 && item.label !== 'Third-party' && (
                      <>
                        <li className="mb-3">
                          <p className="mb-0 f-bold">Content Type</p>
                          {/* <p className="mb-2">
                            {item.resourceType.charAt(0).toUpperCase() + item.resourceType.slice(1)}
                          </p> */}
                        </li>
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold">Size</p>
                          <p className="mb-2">{sizeDetermination(item?.transferSize)}</p>
                        </li>
                      </>
                    )}
                  </div>
                ))}
                <li className="mb-3">
                  <p className="mb-0 f-bold">Total</p>
                  <p className="mb-2">
                    {sizeDetermination(data?.pageObject?.data?.finalStatus?.pageObject?.[0]?.transferSize)}
                  </p>
                </li>
              </ul>
            ) : (
              <p>No Objects Found</p>
            )}
          </div>
          {/* End Content size */}
          {/* Start Request by content type  */}
          <div className="border-bottom mb-3">
            <p className="f-bold"> Request by content type </p>
            {data?.pageObject?.data?.finalStatus?.pageObject?.length ? (
              <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
                {data?.pageObject?.data?.finalStatus?.pageObject.map((item, index) => (
                  <div key={`PageObjectRequest-${index+1}`}>
                    {index !== 0 && item?.label !== 'Third-party' && (
                      <>
                        <li className="mb-3">
                          <p className="mb-0 f-bold">Content Type</p>
                          {/* <p className="mb-2">
                            {item.resourceType.charAt(0).toUpperCase() + item.resourceType.slice(1)}
                          </p> */}
                        </li>
                        <li className="border-bottom mb-3">
                          <p className="mb-0 f-bold">Requests</p>
                          <p className="mb-2">{item?.requestCount}</p>
                        </li>
                      </>
                    )}
                  </div>
                ))}
                <li className="mb-3">
                  <p className="mb-0 f-bold">Total</p>
                  <p className="mb-2">{data?.pageObject?.data?.finalStatus?.pageObject?.[0]?.requestCount}</p>
                </li>
              </ul>
            ) : (
              <p>No Objects Found</p>
            )}
          </div>
          {/* End Request by content type */}
          {/* Start Content size by domain */}
          <div className="border-bottom mb-3">
            <p className="f-bold"> Content size by domain </p>
            {data?.pageObject?.data?.finalStatus?.pageObjects?.length ? (
              <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
                {data?.pageObject?.data?.finalStatus?.pageObjects.map((item, index) => (
                  <div key={`PageObject-${index+1}`}>
                    <li className="mb-3">
                      <p className="mb-0 f-bold">Domain</p>
                      <p className="mb-2">
                        {item?.url?.length > 30 ? item?.url?.substring(0, 30).concat('...') : item?.url}
                      </p>
                    </li>
                    <li className="mb-3">
                      <p className="mb-0 f-bold">Percent</p>
                      <p className="mb-2">{item?.sizePercentage} %</p>
                    </li>
                    <li className="border-bottom mb-3">
                      <p className="mb-0 f-bold">Size</p>
                      <p className="mb-2">{sizeDetermination(item?.size)}</p>
                    </li>
                  </div>
                ))}
                <li className="mb-3">
                  <p className="mb-0 f-bold">Total</p>
                  <p className="mb-1">100 %</p>
                  <p className="mb-2">
                    {sizeDetermination(data?.pageObject?.data?.finalStatus?.pageObjects?.[0].totalSize)}
                  </p>
                </li>
              </ul>
            ) : (
              <p>No Objects Found</p>
            )}
          </div>
          {/* End Content size by domain */}
          {/* Start Request by domain */}
          <div className="border-bottom mb-3">
            <p className="f-bold"> Request by domain </p>
            {data?.pageObject?.data?.finalStatus?.pageObjects?.length ? (
              <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
                {renderRequestByDomain(data?.pageObject?.data?.finalStatus?.pageObjects, true)}
                <li className="mb-3">
                  <p className="mb-0 f-bold">Total</p>
                  <p className="mb-1">100 %</p>
                  <p className="mb-2">
                    {data?.pageObject?.data?.finalStatus?.pageObjects?.[0].totalOccurenceCount}
                  </p>
                </li>
              </ul>
            ) : (
              <p>No Objects Found</p>
            )}
          </div>
          {/* End Request by domain */}
        </div>
        {/* End Mobile Responsive */}
      </ModalHOC>
    </>
  )
}

export default PageObject

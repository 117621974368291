import { InputGroup, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { CommonAlert } from 'components/uiCore'
import paths from 'constants/paths'

const MostCommonTest = ({ data, showUrl = true }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="vr-detail-block">
        {data && data.keywordCloudTest ? (
          <>
            {showUrl ? <h5>{data?.url}</h5> : <></>}
            {/* <ul>
                    {(data?.keywordCloudTest?.data?.commonKeywords && data?.keywordCloudTest?.data?.commonKeywords?.length && data?.keywordCloudTest?.data?.commonKeywords.map((item, index) => {
                        if (item.word && item.word.length < 10) {
                            return (<li>{item.word} </li>)
                        } else {
                            return '';
                        }
                    })) || ''}
                </ul> */}
            <p>Total Words: {data?.keywordCloudTest?.data?.totalWord || ''}</p>
            <p>{data?.keywordCloudTest?.data?.testResults?.message || ''}</p>
            {data?.keywordCloudTest?.data?.keywordResults?.status === 2 ? (
              <CommonAlert
                variant={'warning'}
                key={'most-common-keyword-alert'}
                alertText={
                  <div className='alert-for-mobile'>
                    {data?.keywordCloudTest?.data?.keywordResults?.message || ''}{' '}
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate(paths.dashboardSettings, { state: { activeTab: '2' } })}
                    >
                      {' '}
                      Click here to add keywords.{' '}
                    </a>
                  </div>
                }
              />
            ) : (
              ''
            )}
            {(data?.keywordCloudTest?.data?.positiveKeywords &&
              data?.keywordCloudTest?.data?.positiveKeywords?.length &&
              data?.keywordCloudTest?.data?.positiveKeywords.map((item, index) => {
                if (item.word && item.word.length) {
                  return (
                    <InputGroup className="mb-3" key={`keywordCloudPositive-${index + 1}`}>
                      <Button variant="secondary" id="button-addon1">
                        {item.count}
                      </Button>
                      <span
                        className="ml-2"
                        style={{
                          display: 'inline-block',
                          background: '#d6d6d6',
                          padding: 4,
                          borderRadius: 4,
                        }}
                      >
                        {item.word}
                      </span>
                    </InputGroup>
                  )
                } else {
                  return ''
                }
              })) ||
              ''}

            {(data?.keywordCloudTest?.data?.negativeKeywords &&
              data?.keywordCloudTest?.data?.negativeKeywords?.length &&
              data?.keywordCloudTest?.data?.negativeKeywords.map((item, index) => {
                if (item.word && item.word.length) {
                  return (
                    <InputGroup className="mb-3" key={`keywordCloudNegative-${index + 1}`}>
                      <Button variant="secondary" id="button-addon1">
                        {item.count}
                      </Button>
                      <span
                        className="ml-2"
                        style={{
                          display: 'inline-block',
                          background: '#d6d6d6',
                          padding: 4,
                          borderRadius: 4,
                        }}
                      >
                        {item.word}
                      </span>
                    </InputGroup>
                  )
                } else {
                  return ''
                }
              })) ||
              ''}

            {(data?.keywordCloudTest?.data?.warningKeywords &&
              data?.keywordCloudTest?.data?.warningKeywords?.length &&
              data?.keywordCloudTest?.data?.warningKeywords.map((item, index) => {
                if (item.word && item.word.length) {
                  return (
                    <InputGroup className="mb-3" key={`keywordCloudWarning-${index + 1}`}>
                      <Button variant="secondary" id="button-addon1">
                        {item.count}
                      </Button>
                      <span
                        className="ml-2"
                        style={{
                          display: 'inline-block',
                          background: '#d6d6d6',
                          padding: 4,
                          borderRadius: 4,
                        }}
                      >
                        {item.word}
                      </span>
                    </InputGroup>
                  )
                } else {
                  return ''
                }
              })) ||
              ''}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default MostCommonTest

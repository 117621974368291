import CommonFreeTool from '../CommonFreeTool'
import { freeTools } from 'constants.js'

const KeywordUsageTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.keywordsUsageTest.heading}
      whatIsIT={freeTools.keywordsUsageTest.what}
      howToFixBeforeCode={freeTools.keywordsUsageTest.howSectionBeforeCode}
    />
  )
}

export default KeywordUsageTest

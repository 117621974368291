import React from 'react'

import { Row, Col, ListGroup } from 'react-bootstrap'

const NavbarDropdownItem = ({ icon, title, description, time, spacing, dropdownItemOnClick, readed }) => (
  <ListGroup.Item onClick={dropdownItemOnClick} className='cursor-pointer'>
    <Row className="align-items-center g-0">
      {icon && icon !== null && icon !== undefined ? <Col xs={2}>{icon}</Col> : null}
      <Col xs={10} className={spacing ? 'pl-2' : null}>
        <div className="text-dark" style={{fontWeight: readed ? 'normal' : '600'}}>{title}</div>
        <div className="text-muted small mt-1" style={{fontWeight: readed ? 'normal' : '600'}}>{description}</div>
        <div className="text-muted small mt-1" style={{fontWeight: readed ? 'normal' : '600'}}>{time}</div>
      </Col>
    </Row>
  </ListGroup.Item>
)

export default NavbarDropdownItem

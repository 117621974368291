import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as _ from 'lodash'
import { Card, Table, Form, Modal, Tab, Nav, Spinner, Dropdown, Button } from 'react-bootstrap'

import {
  addKeywordEverywhere,
  getUpdatedKeywordsResearch,
  keywordResearch,
  changeActiveLink,
  getUserData,
  patchUserDomainsDashboard,
  getBacklinksStats,
  getSiteOutageData,
  getKeywordLimit,
  openUpgradePlanModal,
} from 'redux/actions/dashboard'
import { getAvgPositionKeywordApi } from 'redux/actions/topKeywords'
import NewTopKeywordsTrendsChart from '../TopKeywords/newTopKeywordsTredChart'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from 'components/uiCore'

import { addKeywordSettings } from 'redux/actions/register'
import { MoreHorizontal } from 'react-feather'
import paths from 'constants/paths'
import { useNavigate } from 'react-router-dom'
import { CLEAR_KEYWORD_LIMIT } from 'redux/actionType'
import { LimitOption, PLAN } from 'constants/enum'
import MainUrlNav from 'components/MainUrlNav'
import { getActiveUserData } from 'constants/Utils'

const KeywordsEverywhere = () => {
  const [addKeyword, setAddKeyword] = useState('')
  const [openTrendsModal, setOpenTrendsModal] = useState(false)
  const [activeTrendsData, setActiveTrendsData] = useState([])
  const [modifiedTrend, setModifiedTrend] = useState([])

  const [searchVolumeMin, setSearchVolumeMin] = useState()
  const [searchVolumeMax, setSearchVolumeMax] = useState()
  const [cpcMin, setCpcMin] = useState()
  const [cpcMax, setCpcMax] = useState()
  const [competitionMin, setCompetitionMin] = useState()
  const [competitionMax, setCompetitionMax] = useState()
  const [filteredData, setFilteredData] = useState('')
  const [filterKeyword, setFilterKeyword] = useState([])
  const [keywordsUrl, setKeywordsUrl] = useState('')
  const [openKeywordLimitExceed, setOpenKeywordLimitExceed] = useState(false)
  const [keywordData, setKeywordData] = useState({})
  const [loadUntillLenghtFound, setLoadUntillLenghtFound] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    addKeywordEverywhereSuccess,
    addKeywordEverywhereData,
    addKeywordEverywheres,
    keywordResearchData,
    settingsAddKeywordLoader,
    settingsAddKeywordSuccess,
    keywordResearchSuccess,
    userData,
    usersDomains: usersDomain,
    activeLink,
    keywordLimitLoader,
    keywordLimitData,
    keywordLimitError,
    keywordResearch: keywordResearchLoader,
    loadingForActiveLink
  } = useSelector(({ dashboard }) => dashboard)

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)
  
    let keywordResearchDataInitial =
    keywordResearchData &&
    keywordResearchData?.length &&
    keywordResearchData?.filter((researchData) => {
      return activeSearchData?.websiteId === researchData?.websiteId
    }) || []

  useEffect(() => {
    let keywordsUrl = usersDomain?.[activeLink]?.items[0]?.information?.seoCheckup?.redirect_url

    let filterKeyword =
      keywordResearchData &&
      keywordResearchData?.filter((obj) => {
        return obj?.websiteId === activeSearchData?.websiteId
      })
    setFilterKeyword(filterKeyword)
    setKeywordsUrl(keywordsUrl)
  }, [keywordResearchData, usersDomain, activeLink])

  useEffect(() => {
    async function fetchMyAPI() {
      const data = await localStorage.getItem('userData')
      dispatch(getUserData())
      dispatch(patchUserDomainsDashboard())
      dispatch(keywordResearch({ userId: JSON.parse(data)._id }))
      dispatch(getBacklinksStats())
      dispatch(getUserData())
      dispatch(getSiteOutageData())
      dispatch(getAvgPositionKeywordApi())
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    async function fetchWhenNewKeywordAdded() {
      const data = await localStorage.getItem('userData')
      if (settingsAddKeywordSuccess || !settingsAddKeywordLoader) {
        dispatch(getUserData())
      }

      if(!addKeywordEverywheres && !keywordLimitLoader){
        setAddKeyword("")
      }
    }
    fetchWhenNewKeywordAdded()
  }, [settingsAddKeywordSuccess, keywordResearchSuccess, keywordResearchData, settingsAddKeywordLoader, keywordLimitLoader, addKeywordEverywheres])

  useEffect(() => {
    async function fetchAPI() {
      const data = await localStorage.getItem('userData')
      dispatch(keywordResearch({ userId: JSON.parse(data)?._id }))
    }
    fetchAPI()
  }, [activeLink])

  useEffect(() => {
    let modifiedTrend = []
    modifiedTrend =
      filterKeyword &&
      filterKeyword.length &&
      filterKeyword?.[0]?.data?.map((item) => ({
        ...item,
        trend: item?.keywordsData?.trend?.map((trendItem) => ({
          year: trendItem?.year,
          month: trendItem?.month,
          value: trendItem?.value,
        })),
      }))
    setModifiedTrend(modifiedTrend?.[0]?.trend)
  }, [addKeywordEverywhereData, addKeywordEverywhereSuccess, keywordResearchSuccess])

  useEffect(() => {
    applyFilters()
  }, [settingsAddKeywordSuccess])

  useEffect(() => {
    applyFilters()
  }, [searchVolumeMin, searchVolumeMax, cpcMin, cpcMax, competitionMin, competitionMax])

  useEffect(() => {
    if(keywordLimitLoader){
      return
    }else if(!keywordLimitLoader && keywordLimitData && Object?.keys(keywordLimitData)?.length){
      if(keywordLimitData?.limitAvailable){
        onGetKeyword(keywordData?.keyword, keywordData?.keywordIndex)
        dispatch({type:CLEAR_KEYWORD_LIMIT})
      }else {
        if(keywordLimitData && Object.keys(keywordLimitData)?.length && !keywordLimitData?.limitAvailable){
          setOpenKeywordLimitExceed(true)
        }
      }
    }else {
      if(!keywordLimitLoader && keywordLimitError && Object.keys(keywordLimitError)?.length){
        message.error(keywordLimitError?.message)
        dispatch({type:CLEAR_KEYWORD_LIMIT})
      }
    }
  }, [keywordLimitLoader, keywordLimitData, keywordLimitError])

  useEffect(() => {

    if( loadUntillLenghtFound && !addKeywordEverywheres ){
      const data = localStorage.getItem("userData")
      dispatch(keywordResearch({ userId: JSON.parse(data)._id }))
      setLoadUntillLenghtFound(false)
    }

  }, [dispatch, addKeywordEverywheres, loadUntillLenghtFound])

  useEffect(() => {
    if(!addKeywordEverywheres && !keywordResearchLoader && loadingForActiveLink !== null && !loadUntillLenghtFound){
      dispatch({type: 'CLEAR_KEYWORD_RESEARCH_LOADING'})
    }
  }, [addKeywordEverywheres, keywordResearchLoader, loadUntillLenghtFound])

  const resetFinters = () => {
    setSearchVolumeMin('')
    setSearchVolumeMax('')
    setCpcMin('')
    setCpcMax('')
    setCompetitionMin('')
    setCompetitionMax('')
  }

  const onAddKeyword = async () => {
    if (addKeyword) {
      let splittedKeyword = addKeyword.split(/,|\n/)
      let finalKeyword = splittedKeyword.filter((item) => item !== '')
      let trimmedKeyword = finalKeyword.map((keyword) => keyword?.trimStart())
      let uniqueKeyword = _.uniq(trimmedKeyword)
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        let obj = {
          keywords: uniqueKeyword,
          websiteId: activeSearchData?.websiteId
        }
        dispatch(addKeywordEverywhere(obj))
        setLoadUntillLenghtFound(true)
        dispatch({type: "KEYWORD_RESEARCH_LOADING", payload: {activeLink}})

        setTimeout(() => {
          dispatch(keywordResearch({ userId: JSON.parse(data)._id }))
        }, 2500)

        setTimeout(() => {
          dispatch(keywordResearch({ userId: JSON.parse(data)._id }))
        }, 5000)
      }
    }
  }

  const onGetKeyword = async (keyword, keywordIndex) => {
    if (usersDomain) {
      let url = usersDomain[activeLink].items[0].url
      let website = usersDomain[activeLink].items[0].id
      const dataForUser = await localStorage.getItem('userData')
      if (filteredData) {
        const updatedFilterKeyword = [...filteredData]

        if (updatedFilterKeyword.length > 0) {
          let data =
            filteredData &&
            filteredData?.filter((obj) => {
              return obj?.websiteId === activeSearchData?.websiteId
            })
          const data1 = data?.[0].data

          if (data1 && keywordIndex >= 0 && keywordIndex < data1.length) {
            const updatedKeywordData = { ...data1[keywordIndex].keywordsData }

            updatedKeywordData.loading = true
            updatedKeywordData.added = true

            data1[keywordIndex].keywordsData = updatedKeywordData

            dispatch(getUpdatedKeywordsResearch(updatedFilterKeyword))
            dispatch(
              addKeywordSettings({ keywords: [keyword], url, website }),
            )
          }
        }
      } else {
        const updatedFilterKeyword = [...keywordResearchData]

        if (updatedFilterKeyword.length > 0) {
          let data =
            keywordResearchData &&
            keywordResearchData?.filter((obj) => {
              return obj?.websiteId === activeSearchData?.websiteId
            })
          const data1 = data?.[0].data

          if (data1 && keywordIndex >= 0 && keywordIndex < data1.length) {
            const updatedKeywordData = { ...data1[keywordIndex].keywordsData }

            updatedKeywordData.loading = true
            updatedKeywordData.added = true

            data1[keywordIndex].keywordsData = updatedKeywordData

            dispatch(getUpdatedKeywordsResearch(updatedFilterKeyword))
            dispatch(
              addKeywordSettings({ keywords: [keyword], url, website }),
            )
          }
        }
      }
    }
  }

  const applyFilters = () => {
    const filteredData =
      keywordResearchData &&
      keywordResearchData.length &&
      keywordResearchData?.[activeLink]?.data?.filter((item) => {
        const searchVolume = item.keywordsData.vol
        const cpc = item.keywordsData?.cpc?.value
        const competition = item.keywordsData.competition

        return (
          (!searchVolumeMin || searchVolume >= parseFloat(searchVolumeMin)) &&
          (!searchVolumeMax || searchVolume <= parseFloat(searchVolumeMax)) &&
          (!cpcMin || cpc >= parseFloat(cpcMin)) &&
          (!cpcMax || cpc <= parseFloat(cpcMax)) &&
          (!competitionMin || competition >= parseFloat(competitionMin)) &&
          (!competitionMax || competition <= parseFloat(competitionMax))
        )
      })

    const newFilteredData =
      keywordResearchData?.length &&
      keywordResearchData?.map((item) => ({
        ...item,
        data: item?.data?.filter((innerItem) =>
          filteredData?.some((filteredItem) => filteredItem._id === innerItem._id),
        ),
      }))

    setFilteredData(newFilteredData)
    if (!searchVolumeMin && !searchVolumeMax && !cpcMin && !cpcMax && !competitionMin && !competitionMax) {
      setFilteredData('')
    }
  }

  return (
    <>
      <Modal
         show={openKeywordLimitExceed}
         aria-labelledby="contained-modal-title-vcenter"
         centered
         toggle={() => {
          setOpenKeywordLimitExceed(!openKeywordLimitExceed)
          dispatch({type: CLEAR_KEYWORD_LIMIT})
        }}
         onClose={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({type: CLEAR_KEYWORD_LIMIT})
        }}
         onHide={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({type: CLEAR_KEYWORD_LIMIT})
        }}
         style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)'}}
         className="br-15 pe-0"
       >
          <Modal.Header>
            <h3 className='mb-0'>Keyword Tracking Limit Reached</h3>
          </Modal.Header>        
        <Modal.Body>
          You've maximized the keyword tracking capabilities of your current plan. To continue expanding your insights and to add more keywords, an upgrade is necessary. Our plans are designed to scale with your growing needs, offering more keywords and location tracking options.
          <br /><br />
          Current Plan: {keywordLimitData?.keywordsLimit} Keywords
          <br /><br />
          Upgrade Now and unlock your full SEO potential.
          <br /><br />
          Thank you for using our services to enhance your digital marketing efforts. We're committed to supporting your growth every step of the way.
        </Modal.Body>
         <Modal.Footer className={`d-flex flex-row ${userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "justify-content-end" : 'justify-content-between'}`}>
         {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : <Button
             color="primary"
             variant="primary"
             onClick={() => {
              navigate(paths.upgradePlan)
              setOpenKeywordLimitExceed(false)
              dispatch({type: CLEAR_KEYWORD_LIMIT})
             }}
           >
             Upgrade your plan
           </Button>}

           <Button
             color="danger"
             variant="danger"
             onClick={() => {
              setOpenKeywordLimitExceed(false)
              dispatch({type: CLEAR_KEYWORD_LIMIT})
             }}
           >
             Close
           </Button>
         </Modal.Footer>
       </Modal>
      <Card className="url-details mb-0">
        <Card.Header className="url-details-card-header">
          <div className="m-2">
            <h1 className="text-black f-bold">Bulk Keywords Data Tool</h1>
            <h3 className='f-bold'>Enter Keywords Below (separated by comma, new line)</h3>
          </div>
        </Card.Header>

        <Modal
          show={openTrendsModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            setOpenTrendsModal(false)
          }}
          style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
          className="br-15"
        >
          <Modal.Header>
            <h3>Trends Data</h3>
          </Modal.Header>
          <Modal.Body>
            <NewTopKeywordsTrendsChart chartData={activeTrendsData} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={() => {
                setOpenTrendsModal(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Card.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0}>
            <Nav
              variant="tabs"
              onSelect={(selectedKey) => {
                dispatch(changeActiveLink(parseInt(selectedKey)))
                setFilteredData('')
                resetFinters()
              }}
            >
              {usersDomain && usersDomain?.length ? (
                <>
                  {usersDomain?.map((item, index) => {
                    return <MainUrlNav index={index} item={item} key={index+1} />
                  })}
                </>
              ) : (
                <></>
              )}
            </Nav>
            <div>
              <div className="mt-3">
                <Form.Control
                  as={'input'}
                  placeholder='Enter Keywords'
                  onChange={(event) => setAddKeyword(event?.target?.value)}
                  onKeyDown={(event) => {
                    if (event?.key === 'Enter') {
                      onAddKeyword()
                    }
                  }}
                  value={addKeyword}
                />
              </div>
              <div className="d-flex mt-3 justify-content-end">
              { (loadingForActiveLink === activeLink) && (addKeywordEverywheres || keywordResearchLoader) && (addKeyword !== "") ? (
                    <Spinner color='primary' variant='primary' animation="border" key={1} className="me-2" />
                  ) : <Button
                  type="primary"
                  onClick={() => onAddKeyword()}
                  outine
                  loading={addKeywordEverywheres ? true : false}
                  className="generate-report-btn d-flex flex-row align-items-center"
                >
                  Get Research
                </Button>
                }
              </div>
              <Tab.Content>
                {usersDomain && usersDomain?.length ? (
                  usersDomain?.map((item, index) => {
                    return (
                      <Tab.Pane eventKey={index} key={`tabContent${index + 1}`}>
                        <Table striped responsive id="myTable" className="mt-5">
                          <thead>
                            <tr className="f-bold text-black">
                              <th style={{ width: '6%' }}>Add</th>
                              <th style={{ width: '18%' }}>Keywords</th>
                              <th style={{ width: '14%' }}>Search Volume (Global)</th>
                              <th style={{ width: '14%' }}>CPC (Global)</th>
                              <th style={{ width: '14%' }}>Competition (Global)</th>
                              <th style={{ width: '14%' }}>Trend (Global)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td />
                              <td>
                                <Button
                                  type="primary"
                                  onClick={() => resetFinters()}
                                  outine
                                  className="text-white fs-4 f-bold col-auto"
                                >
                                  Reset filters
                                </Button>
                              </td>
                              <td>
                                <Form.Control
                                  as={'input'}
                                  rows={5}
                                  placeholder="min"
                                  onChange={(event) => {
                                    setSearchVolumeMin(event?.target?.value)
                                  }}
                                  value={searchVolumeMin}
                                />
                                <Form.Control
                                  as={'input'}
                                  rows={5}
                                  placeholder="max"
                                  onChange={(event) => {
                                    setSearchVolumeMax(event?.target?.value)
                                  }}
                                  value={searchVolumeMax}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  as={'input'}
                                  rows={5}
                                  placeholder="min"
                                  onChange={(event) => {
                                    setCpcMin(event?.target?.value)
                                  }}
                                  value={cpcMin}
                                />
                                <Form.Control
                                  as={'input'}
                                  rows={5}
                                  placeholder="max"
                                  onChange={(event) => {
                                    setCpcMax(event?.target?.value)
                                  }}
                                  value={cpcMax}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  as={'input'}
                                  rows={5}
                                  placeholder="min"
                                  onChange={(event) => {
                                    setCompetitionMin(event?.target?.value)
                                  }}
                                  value={competitionMin}
                                />
                                <Form.Control
                                  as={'input'}
                                  rows={5}
                                  placeholder="max"
                                  onChange={(event) => {
                                    setCompetitionMax(event?.target?.value)
                                  }}
                                  value={competitionMax}
                                />
                              </td>
                              <td />
                            </tr>
                            {!filteredData ? (
                              keywordResearchDataInitial && keywordResearchDataInitial?.length ? (
                                keywordResearchDataInitial?.[0]?.data?.map((item, index) => (
                                  <tr key={`researchData-${index + 1}`}>
                                    <td>
                                      {
                                        <Button
                                          type="primary"
                                          onClick={() => {
                                            setKeywordData({keyword:item.keywordsData.keyword, keywordIndex: index})
                                            dispatch(getKeywordLimit({keywords:[item.keywordsData.keyword], limitOption: LimitOption.KEYWORD}))
                                          }}
                                          outine
                                          disabled={
                                            (item.keywordsData?.added && !item.keywordsData?.loading) ||
                                            activeSearchData?.keywords?.includes(item?.keywordsData?.keyword.toLowerCase())
                                          }
                                          loading={
                                            item.keywordsData?.loading &&
                                            !activeSearchData?.keywords?.includes(item?.keywordsData?.keyword)
                                          }
                                          className="generate-report-btn d-flex flex-row align-items-center"
                                        >
                                          {(settingsAddKeywordLoader || keywordLimitLoader) && item?.keywordsData?.keyword === keywordData?.keyword ? (
                                            <Spinner animation="border" key={1} className="me-2" size="sm" />
                                          ) : (
                                            null
                                          )}
                                          {(item.keywordsData?.added && !item.keywordsData?.loading) ||
                                            activeSearchData?.keywords?.includes(item?.keywordsData?.keyword.toLowerCase())
                                            ? 'Added'
                                            : 'Add'}
                                        </Button>
                                      }
                                    </td>
                                    <td>{item.keywordsData?.keyword}</td>
                                    <td>{item.keywordsData?.vol}</td>
                                    <td>{item.keywordsData?.cpc?.value}</td>
                                    <td>{item.keywordsData?.competition}</td>
                                    <td>
                                      {item?.keywordsData.trend ? (
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setOpenTrendsModal(true)
                                            setActiveTrendsData(item.keywordsData?.trend)
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faChartArea} className="align-middle" />
                                        </span>
                                      ) : (
                                        <FontAwesomeIcon icon={faChartArea} className="align-middle" />
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : keywordResearchDataInitial?.length === 0 && !addKeywordEverywheres && !keywordResearchLoader && loadingForActiveLink === null ? (
                                <td colSpan="6">
                                  <div className="no-data-text" style={{ fontWeight: '400' }}>No Data Available.</div>
                                </td>
                                ) : keywordResearchDataInitial?.length === 0 &&
                                  (addKeywordEverywheres || keywordResearchLoader || loadUntillLenghtFound) &&
                                  loadingForActiveLink === activeLink ? (
                                  <td colSpan="6">
                                    <div style={{ textAlign: 'center' }} className="colSpan-7">
                                      <Loader />
                                    </div>
                                </td>
                              ) : (
                                <td colSpan="6">
                                  <div className="no-data-text" style={{ fontWeight: '400' }}>No Data Available.</div>
                                </td>
                              )
                            ) : filteredData && filteredData?.length ? (
                              filteredData?.[activeLink]?.data?.map((item, index) => (
                                <tr key={`filetredData-${index + 1}`}>
                                  <td>
                                    {
                                      <Button
                                        type="primary"
                                        onClick={() => {
                                          setKeywordData({keyword:item.keywordsData.keyword, keywordIndex: index})
                                          dispatch(getKeywordLimit({keywords:[item.keywordsData.keyword], limitOption: LimitOption.KEYWORD}))
                                         }}
                                        outine
                                        disabled={
                                          (item.keywordsData?.added && !item.keywordsData?.loading) ||
                                          activeSearchData?.keywords?.includes(item?.keywordsData?.keyword.toLowerCase())
                                        }
                                        loading={
                                          item?.keywordsData?.loading &&
                                          !activeSearchData?.keywords?.includes(item?.keywordsData?.keyword)
                                        }
                                        className="generate-report-btn d-flex flex-row align-items-center"
                                      >
                                        {(settingsAddKeywordLoader || keywordLimitLoader) && item?.keywordsData?.keyword === keywordData?.keyword ? (
                                            <Spinner animation="border" key={1} className="me-2" size="sm" />
                                          ) : (
                                            null
                                          )}
                                        {(item.keywordsData?.added && !item.keywordsData?.loading) ||
                                          activeSearchData?.keywords?.includes(item?.keywordsData?.keyword.toLowerCase())
                                          ? 'Added'
                                          : 'Add'}
                                      </Button>
                                    }
                                  </td>
                                  <td>{item.keywordsData?.keyword}</td>
                                  <td>{item.keywordsData?.vol}</td>
                                  <td>{item.keywordsData?.cpc.value}</td>
                                  <td>{item.keywordsData?.competition}</td>
                                  <td>
                                    {item.keywordsData?.trend ? (
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setOpenTrendsModal(true)
                                          setActiveTrendsData(modifiedTrend)
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faChartArea} className="align-middle" />
                                      </span>
                                    ) : (
                                      <FontAwesomeIcon icon={faChartArea} className="align-middle" />
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                    )
                  })
                ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        alignSelf: 'center',
                        flexDirection: 'row',
                        minHeight: 380,
                      }}
                    >
                      <div className="text-center">
                        <Loader />
                      </div>
                    </div>
                )}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  )
}

export default KeywordsEverywhere

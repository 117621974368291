import { useRoutes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import configureStore from './redux/store'

import './i18n'
import routes from './routes'

import { ThemeProvider } from './contexts/ThemeContext'
import { SidebarProvider } from './contexts/SidebarContext'
import { LayoutProvider } from './contexts/LayoutContext'
import ChartJsDefaults from './utils/ChartJsDefaults'

import { AuthProvider } from './contexts/JWTContext'

const { store } = configureStore()

const App = () => {
  const content = useRoutes(routes)

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | AppStack - React Admin & Dashboard Template"
        defaultTitle="AppStack - React Admin & Dashboard Template"
      />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <ChartJsDefaults />
              <AuthProvider>{content}</AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  )
}

export default App

import {
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST,
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_SUCCESS,
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_ERROR,
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_CLEAR,
} from 'redux/actionType'

export function generateReportDownloadPDFRequest(payload) {
  return {
    type: WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST,
    payload,
  }
}

export function generateReportDownloadPDFSuccess(payload) {
  return {
    type: WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_SUCCESS,
    payload,
  }
}

export function generateReportDownloadPDFError(payload) {
  return {
    type: WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_ERROR,
    payload,
  }
}

export function generateReportDownloadPDFClear() {
  return {
    type: WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_CLEAR,
  }
}

import { useEffect, useState } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import {
  changeActiveLink,
  getAnalyticsData,
  getUserData,
  patchUserDomainsDashboard,
} from 'redux/actions/dashboard'
import { CLEAR_GET_ANALYTICS_DATA, CLEAR_GET_ANALYTICS_ERROR } from 'redux/actionType'

import TrafficAnalyticsData from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsData.jsx'
import TrafficAnalyticsCountryTable from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsCountryTable.jsx'
import TrafficAnalyticsEventTable from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsEventTable.jsx'
import PagePathAnalyticsTable from 'pages/dashboards/TrafficAnalytics/PagePathAnalyticsTable.jsx'
import TrafficAnalyticsTable from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsTable.jsx'
import TrafficAnalyticsChannelTable from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsChannelTable.jsx'
import TrafficAnalyticsEventsHeader from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsEventsHeader.jsx'
import TrafficAnalyticsSourceTable from 'pages/dashboards/TrafficAnalytics/TrafficAnalyticsSourceTable.jsx'
import MainUrlNav from 'components/MainUrlNav'
import { Loader } from 'components/uiCore'
import { message } from 'antd'
import { getRefreshToken } from 'constants/Utils'

const TrafficAnalytics = () => {
  const dispatch = useDispatch()

  const {
    usersDomains: usersDomain,
    activeLink,
    analytics,
    getAnalyticsDataLoader,
    getAnalyticsDataFailure,
    analyticsError,
    userData,
  } = useSelector(({ dashboard }) => dashboard)

  const [selectedNavTab, setSelectedNavTab] = useState(0)

  const tokenData = getRefreshToken(
    usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
    userData,
  )
  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(getUserData())
      dispatch(patchUserDomainsDashboard())
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    if (tokenData && tokenData?.refreshToken && Object.keys(tokenData)?.length > 3) {
      if (usersDomain?.length && analytics && !Object.keys(analytics)?.length) {
        dispatch(
          getAnalyticsData({
            url: usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
          }),
        )
      }
    }
  }, [usersDomain, analytics, tokenData, dispatch])

  useEffect(() => {
    if (!getAnalyticsDataLoader && getAnalyticsDataFailure) {
      message.error(analyticsError?.message)
      dispatch({ type: CLEAR_GET_ANALYTICS_ERROR })
    }
  }, [getAnalyticsDataLoader, getAnalyticsDataFailure, analyticsError])

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_GET_ANALYTICS_DATA })
    }
  }, [])

  const handleChangeActiveLink = (selectedIndex, activeIndex) => {
    if (selectedIndex !== activeIndex) {
      dispatch(changeActiveLink(parseInt(selectedIndex)))
      setSelectedNavTab(parseInt(selectedIndex))
      dispatch({ type: CLEAR_GET_ANALYTICS_DATA })
    }
  }

  return (
    <Card className="mb-0">
      <Card.Body>
        {usersDomain?.length ? (
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || selectedNavTab || 0}>
            <Nav
              variant="tabs"
              onSelect={(selectedKey) => {
                handleChangeActiveLink(parseInt(selectedKey), selectedNavTab)
              }}
            >
              {usersDomain && usersDomain?.length
                ? usersDomain?.map((item, index) => {
                    return <MainUrlNav index={index} item={item} key={`Nav-${index + 1}`} />
                  })
                : null}
            </Nav>

            {!getAnalyticsDataLoader ? (
              <Tab.Content>
                <TrafficAnalyticsEventsHeader analytics={analytics} />
                <TrafficAnalyticsData
                  analytics={analytics}
                  redirectUrl={usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url}
                />
                <TrafficAnalyticsTable
                  analytics={analytics}
                  redirectUrl={usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url}
                />
                <TrafficAnalyticsCountryTable analytics={analytics} />
                <TrafficAnalyticsEventTable analytics={analytics} />
                <PagePathAnalyticsTable analytics={analytics} />
                <TrafficAnalyticsChannelTable analytics={analytics} />
                <TrafficAnalyticsSourceTable analytics={analytics} />
              </Tab.Content>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                  minHeight: 380,
                }}
              >
                <div className="text-center">
                  <Loader />
                </div>
              </div>
            )}
          </Tab.Container>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              alignSelf: 'center',
              flexDirection: 'row',
              minHeight: 380,
            }}
          >
            <div className="text-center">
              <Loader />
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default TrafficAnalytics
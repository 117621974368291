import Header from 'components/Header/Header'
import CommonFreeTool from './components/CommonFreeTool'

import MetaTitle from './components/tests/MetaTitle'
import MetaDescription from './components/tests/MetaDescription'
import KeywordUsageTest from './components/tests/KeywordUsageTest'
import RelatedKeywordTest from './components/tests/RelatedKeywordTest'
import CompetitorDomain from './components/tests/CompetitorDomain'
import HeadingTagsTest from './components/tests/headingTagsTest'
import RobotsTxtTest from './components/tests/RobotsTxtTest'
import SitemapTest from './components/tests/SitemapTest'

import { freeTools, metaInformation } from 'constants.js'
import { TEST_TYPES } from 'constants/enum'
import SeoFriendlyUrlTest from './components/tests/SeoFriendlyUrlTest'
import ImageAlt from './components/tests/ImageAlt'
import ResponsiveImage from './components/tests/ResponsiveImage'
import ImageAspectRatio from './components/tests/ImageAspectRatio'
import InlineCss from './components/tests/InlineCss'
import GoogleAnalytics from './components/tests/GoogleAnalytics'
import FaviconTest from './components/tests/FaviconTest'
import JsError from './components/tests/JsError'
import ConsoleErrorTest from './components/tests/ConsoleErrorTest'
import SocialMediaTest from './components/tests/SocialMediaTest'
import HtmlPageSize from './components/tests/HtmlPageSizeTest'
import DomSizeTest from './components/tests/DomSizeTest'
import HtmlCompressionTest from './components/tests/HtmlCompressionTest'
import SiteSpeed from './components/tests/SiteSpeed'
import JsExecutionTime from './components/tests/JsExecutionTime'
import PageObjectTest from './components/tests/PageObjectTest'
import PageCacheTest from './components/tests/PageCacheTest'
import FlashTest from './components/tests/FlashTest'
import CDNUsageTest from './components/tests/CDNUsageTest'
import MordenImageTest from './components/tests/MordenImageTest'
import ImageCacheTest from './components/tests/ImageCacheTest'
import JsCacheTest from './components/tests/JsCacheTest'
import CssCacheTest from './components/tests/CssCacheTest'
import JsMinificationTest from './components/tests/JsMinificationTest'
import RenderBlockingResourceTest from './components/tests/RenderBlockingResourceTest'
import NestedTableTest from './components/tests/NestedTable'
import FramesetTest from './components/tests/FramesetTest'
import UrlRedirectTest from './components/tests/UrlRedirectTest'
import FirstContenfulPaintTest from './components/tests/FirstContentfulPaint'
import LargestContenfulPaintTest from './components/tests/LargestContentfulPaint'
import CumulativeLayoutShiftTest from './components/tests/CumulativeLayoutShiftTest'
import SslFreakTest from './components/tests/SslFreakTest'
import HttpsTest from './components/tests/HttpsTest'
import Http2Test from './components/tests/Http2Test'
import HstsTest from './components/tests/HstsTest'
import SafeBrowsingTest from './components/tests/SafeBrowsingTest'
import ServerSignatureTest from './components/tests/ServerSignatureTest'
import DirectoryBrowsingTest from './components/tests/DirectoryBrowsingTest'
import PlainTextEmailTest from './components/tests/PlainTextEmailTest'
import UnsafeCrossOriginTest from './components/tests/UnsafeCrossOriginTest'
import ViewportTest from './components/tests/ViewportTest'
import MobileScreenshotTest from './components/tests/MobileScreenshotTest'
import MicrodataSchemaAnalysisTest from './components/tests/MicrodataSchemaAnalysisTest'
import Custom404Test from './components/tests/Custom404Test'
import NoIndexTest from './components/NoIndexTest'
import CanonicalTagTest from './components/tests/CanonicalTagTest'
import NoFollowTest from './components/tests/NoFollowTest'
import DisallowDirectiveTest from './components/tests/DisallowDirectiveTest'
import MetaRefreshTest from './components/tests/MetaRefreshTest'
import AdsTxtTest from './components/tests/AdsTxtTest'
import SocialMediaMetaTagsTest from './components/tests/SocialMediaMetaTags'
import CharsetDeclarationTest from './components/tests/CharsetDeclarationTest'
import DocTypeTest from './components/tests/DocTypeTest'
import UrlCanonicalizationTest from './components/tests/UrlCanonicalizationTest'
import SpfRecordsTest from './components/tests/SpfRecordsTest'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import BrotliTest from './components/tests/BrotliTest'
import CachePolicyTest from './components/tests/CachePolicyTest'
import ContentWidthTest from './components/tests/ContentWidthTest'
import ContentfulPaintTest from './components/tests/ContentfulPaintTest'
import DescriptiveLinksTest from './components/tests/DescriptiveLinksTest'
import DesktopScreenshotTest from './components/tests/DesktopScreenshotTest'
import DnsSecurityTest from './components/tests/dnsSecurity'
import FirstInputDelayTest from './components/tests/FirstInputDelayTest'
import FontSizeTest from './components/tests/FontSizeTest'
import HtmlValidationTest from './components/tests/HtmlValidationTest'
import InteractiveTest from './components/tests/InteractiveTest'
import LayoutShiftTest from './components/tests/LayoutShiftTest'
import SpeedIndexTest from './components/tests/SpeedIndexTest'
import TaptargetTest from './components/tests/TapTargetTest'
import TlsLogJamTest from './components/tests/TlsLogJamTest'
import AdvancedConsoleErrorsTest from './components/tests/AdvancedConsoleErrorsTest'
import CssAnalysisTest from './components/tests/CssAnalysisTest'
import LimitExceed from './components/LimitExceed'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const FreeTool = () => {
  const { 'test-type': type } = useParams()
  const testType = TEST_TYPES?.[type] || 'ALL'
  const metaTitle = metaInformation?.[type]?.title

 const { trialFailureMessage } = useSelector(({ startFreeTrial }) => startFreeTrial)

 const [isLimitExceed, setIsLimitExceed] = useState(false)

  useEffect(() => {
    if (trialFailureMessage?.message === 'You have exceded your weekly limit') {
      setIsLimitExceed(true)
    }
  }, [trialFailureMessage])

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <Header isDemoReportPage customClassName="layout-container trial-header" />

      { !isLimitExceed ?
      <>
      {testType === TEST_TYPES['meta-title'] ? <MetaTitle /> : null}

      {testType === TEST_TYPES['meta-description'] ? <MetaDescription /> : null}

      {testType === TEST_TYPES['meta-tags'] ? <SocialMediaMetaTagsTest /> : null}

      {testType === TEST_TYPES['keywords-usage-test'] ? <KeywordUsageTest /> : null}

      {testType === TEST_TYPES['keyword-cloud'] ? (
        <CommonFreeTool
          heading={freeTools.keywordsCloudTest.heading}
          whatIsIT={freeTools.keywordsCloudTest.what}
        />
      ) : null}

      {testType === TEST_TYPES['related-keywords'] ? <RelatedKeywordTest /> : null}

      {testType === TEST_TYPES['competitor-domain'] ? <CompetitorDomain /> : null}

      {testType === TEST_TYPES['heading-analysis'] ? <HeadingTagsTest /> : null}

      {testType === TEST_TYPES['robots-txt'] ? <RobotsTxtTest /> : null}

      {testType === TEST_TYPES['sitemap-data'] ? <SitemapTest /> : null}

      {testType === TEST_TYPES['seo-friendly-url-analysis'] ? <SeoFriendlyUrlTest /> : null}

      {testType === TEST_TYPES['image-alt'] ? <ImageAlt /> : null}

      {testType === TEST_TYPES['responsive-image'] ? <ResponsiveImage /> : null}

      {testType === TEST_TYPES['aspect-ratio'] ? <ImageAspectRatio /> : null}

      {testType === TEST_TYPES['inline-css'] ? <InlineCss /> : null}

      {testType === TEST_TYPES['google-analytics'] ? <GoogleAnalytics /> : null}

      {testType === TEST_TYPES['favicon-checker-test'] ? <FaviconTest /> : null}

      {testType === TEST_TYPES['js-error'] ? <JsError /> : null}

      {testType === TEST_TYPES['console-errors'] ? <ConsoleErrorTest /> : null}

      {testType === TEST_TYPES['character-set'] ? <CharsetDeclarationTest /> : null}

      {testType === TEST_TYPES['social-media-twitter-data'] ? <SocialMediaTest /> : null}

      {testType === TEST_TYPES['html-page-size'] ? <HtmlPageSize /> : null}

      {testType === TEST_TYPES['dom-size'] ? <DomSizeTest /> : null}

      {testType === TEST_TYPES['html-compression'] ? <HtmlCompressionTest /> : null}

      {testType === TEST_TYPES['page-speed'] ? <SiteSpeed /> : null}

      {testType === TEST_TYPES['bootup-time'] ? <JsExecutionTime /> : null}

      {testType === TEST_TYPES['page-object'] ? <PageObjectTest /> : null}

      {testType === TEST_TYPES['page-cache'] ? <PageCacheTest /> : null}

      {testType === TEST_TYPES['flash-test'] ? <FlashTest /> : null}

      {testType === TEST_TYPES['cdn-usage-data'] ? <CDNUsageTest /> : null}

      {testType === TEST_TYPES['morden-image'] ? <MordenImageTest /> : null}

      {testType === TEST_TYPES['image-cache'] ? <ImageCacheTest /> : null}

      {testType === TEST_TYPES['js-cache'] ? <JsCacheTest /> : null}

      {testType === TEST_TYPES['css-cache'] ? <CssCacheTest /> : null}

      {testType === TEST_TYPES['js-minification'] ? <JsMinificationTest /> : null}

      {testType === TEST_TYPES['css-minification'] ? <JsMinificationTest /> : null}

      {testType === TEST_TYPES['render-blocking'] ? <RenderBlockingResourceTest /> : null}

      {testType === TEST_TYPES['nested-table'] ? <NestedTableTest /> : null}

      {testType === TEST_TYPES['frameset-test'] ? <FramesetTest /> : null}

      {testType === TEST_TYPES['doc-type'] ? <DocTypeTest /> : null}

      {testType === TEST_TYPES['redirect-test'] ? <UrlRedirectTest /> : null}

      {testType === TEST_TYPES['first-contentful-paint'] ? <FirstContenfulPaintTest /> : null}

      {testType === TEST_TYPES['largest-contentful-paint'] ? <LargestContenfulPaintTest /> : null}

      {testType === TEST_TYPES['cumulative-layout-shift'] ? <CumulativeLayoutShiftTest /> : null}

      {testType === TEST_TYPES['url-canonicalization'] ? <UrlCanonicalizationTest /> : null}

      {testType === TEST_TYPES['ssl-freak-test'] ? <SslFreakTest /> : null}

      {testType === TEST_TYPES['https-test'] ? <HttpsTest /> : null}

      {testType === TEST_TYPES['http2-test'] ? <Http2Test /> : null}

      {testType === TEST_TYPES['hsts-test'] ? <HstsTest /> : null}

      {testType === TEST_TYPES['safe-browsing'] ? <SafeBrowsingTest /> : null}

      {testType === TEST_TYPES['server-signature'] ? <ServerSignatureTest /> : null}

      {testType === TEST_TYPES['directory-browsing'] ? <DirectoryBrowsingTest /> : null}

      {testType === TEST_TYPES['plain-text-email'] ? <PlainTextEmailTest /> : null}

      {testType === TEST_TYPES['unsafe-cross-origin-links-test'] ? <UnsafeCrossOriginTest /> : null}

      {testType === TEST_TYPES['viewport'] ? <ViewportTest /> : null}

      {testType === TEST_TYPES['mobile-screenshot'] ? <MobileScreenshotTest /> : null}

      {testType === TEST_TYPES['microdata-schema-analysis'] ? <MicrodataSchemaAnalysisTest /> : null}

      {testType === TEST_TYPES['custom-404'] ? <Custom404Test /> : null}

      {testType === TEST_TYPES['no-index-checker'] ? <NoIndexTest /> : null}

      {testType === TEST_TYPES['canonical-url'] ? <CanonicalTagTest /> : null}

      {testType === TEST_TYPES['no-follow'] ? <NoFollowTest /> : null}

      {testType === TEST_TYPES['disallow-directive'] ? <DisallowDirectiveTest /> : null}

      {testType === TEST_TYPES['meta-refresh'] ? <MetaRefreshTest /> : null}

      {testType === TEST_TYPES['spf-record'] ? <SpfRecordsTest /> : null}

      {testType === TEST_TYPES['ads-txt'] ? <AdsTxtTest /> : null}

      {testType === TEST_TYPES['brotli-test'] ? <BrotliTest /> : null}

      {testType === TEST_TYPES['cache-policy'] ? <CachePolicyTest /> : null}

      {testType === TEST_TYPES['content-width'] ? <ContentWidthTest /> : null}

      {testType === TEST_TYPES['contentful-paint'] ? <ContentfulPaintTest /> : null}

      {testType === TEST_TYPES['descriptive-link'] ? <DescriptiveLinksTest /> : null}

      {testType === TEST_TYPES['desktop-screenshot'] ? <DesktopScreenshotTest /> : null}

      {testType === TEST_TYPES['dns-security'] ? <DnsSecurityTest /> : null}

      {testType === TEST_TYPES['first-input-delay'] ? <FirstInputDelayTest /> : null}

      {testType === TEST_TYPES['font-size'] ? <FontSizeTest /> : null}

      {testType === TEST_TYPES['html-validation'] ? <HtmlValidationTest /> : null}

      {testType === TEST_TYPES['interactive'] ? <InteractiveTest /> : null}

      {testType === TEST_TYPES['layout-shift'] ? <LayoutShiftTest /> : null}

      {testType === TEST_TYPES['speed-index-test'] ? <SpeedIndexTest /> : null}

      {testType === TEST_TYPES['tap-target'] ? <TaptargetTest /> : null}

      {testType === TEST_TYPES['tls-log-jam-test'] ? <TlsLogJamTest /> : null}

      {testType === TEST_TYPES['advanced-console-errors'] ? <AdvancedConsoleErrorsTest /> : null}

      {testType === TEST_TYPES['css-analysis'] ? <CssAnalysisTest /> : null} </> : <LimitExceed />}
    </div>
  )
}

export default FreeTool

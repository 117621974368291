import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const DisallowDirectiveTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.disallowDirectiveTest.heading}
      whatIsIT={freeTools.disallowDirectiveTest.what}
      howToFixBeforeCode={freeTools.disallowDirectiveTest.howSectionBeforeCode}
    />
  )
}

export default DisallowDirectiveTest

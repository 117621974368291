import AddKCompetitorsMain from 'modules/welcomeBoard/components/addCompetitor/AddCompetitorsMain.jsx'

const AddCompetitorsTrial = () => {

  return (
    <>
      <AddKCompetitorsMain callFrom="trial" />
    </>
  )
}

export default AddCompetitorsTrial

import { Row, Card, Col, Typography, Space, Divider, Select, Button, Progress, Skeleton, message } from 'antd'
import * as _ from 'lodash'
import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  PictureOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import * as Icon from 'react-feather'

import { Loader } from 'components/uiCore'

import { getLocalStoraGeItem } from 'utils/helper'
import images from 'constants/images'
import { pdfHeader } from 'constants/data'
import { getDataForReport } from 'utils/seoIssue'
import { getFontSize, shortMessageForWhiteLabel, sizeDetermination } from 'constants/Utils'
import CommonSEOWhiteLabel from './reportStatusName'
import TestDescription from './testDescription'

import {
  generateWhiteLabelReportRequest,
  listTemplateRequest,
  generateWhiteLabelReportClear,
} from 'redux/actions/reporting/template'
import { getBacklinksStats, getUserData, patchUserDomainsDashboard } from 'redux/actions/dashboard'
import {
  generateReportDownloadPDFRequest,
  generateReportDownloadPDFClear,
} from 'redux/actions/reporting/generateReport'

import '../templates/templateForms/style.scss'
import './demo.css'
import './report.scss'

export const Report = () => {
  const [templateList, setTemplateList] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [mainUrlList, setMainUrlList] = useState([])
  const [selectedMainUrl, setSelectedMainUrl] = useState()
  const [mainUrlData, setMainUrlData] = useState()
  const [positiveCounter, setPositiveCounter] = useState(0)
  const [negativeCounter, setNegativeCOunter] = useState(0)
  const [warningCounter, setWarningCounter] = useState(0)
  const [keywordCloudData, setKeywordCloudTest] = useState([])
  const [instagramData, setInstagramData] = useState()
  const [twitterData, setTwitterData] = useState()

  const [commonSeoSCounter, setCommonSeoSCounter] = useState(0)
  const [commonSeoWCounter, setCommonSeoWCounter] = useState(0)
  const [commonSeoFCounter, setCommonSeoFCounter] = useState(0)

  const [speedOptimizeSCounter, setSpeedOptimizeSCounter] = useState(0)
  const [speedOptimizeWCounter, setSpeedOptimizeWCounter] = useState(0)
  const [speedOptimizeFCounter, setSpeedOptimizeFCounter] = useState(0)

  const [serverSCounter, setServerSCounter] = useState(0)
  const [serverWCounter, setServerWCounter] = useState(0)
  const [serverFCounter, setServerFCounter] = useState(0)

  const [mobileUsabilitySCounter, setMobileUsabilitySCounter] = useState(0)
  const [mobileUsabilityWCounter, setMobileUsabilityWCounter] = useState(0)
  const [mobileUsabilityFCounter, setMobileUsabilityFCounter] = useState(0)

  const [advanceSeoSCounter, setAdvanceSeoSCounter] = useState(0)
  const [advanceSeoWCounter, setAdvanceSeoWCounter] = useState(0)
  const [advanceSeoFCounter, setAdvanceSeoFCounter] = useState(0)

  const dispatch = useDispatch()
  const constHeader = pdfHeader.constHeader

  const { generateReportDownloadPDFData, isReportStartProcessing } = useSelector((state) => state.report)
  const targetDivRef = useRef(null)

  const { Title } = Typography

  const { listTemplateData, isGenerateReportLoading, generatedReportData, isGetUrlDetailsSuccess } =
    useSelector((state) => state.template)
  const { usersDomains: usersDomain } = useSelector(({ dashboard }) => dashboard)

  const { backLinksStatsData } = useSelector((state) => state.dashboard)
  const userData = getLocalStoraGeItem('userData')
  const { _id } = userData || {}
  useLayoutEffect(() => {
    return () => {
      dispatch(generateWhiteLabelReportClear())
    }
  }, [])

  useEffect(() => {
    if (_id) {
      dispatch(listTemplateRequest({ userId: _id }))
      dispatch(patchUserDomainsDashboard())
      dispatch(getUserData())
    }
  }, [])
  useEffect(() => {
    let templateArray = []
    const defaultSelectedTemplate = []
    if (listTemplateData && listTemplateData?.resData && listTemplateData?.resData?.length) {
      listTemplateData?.resData?.map((data) => {
        templateArray.push({ value: data?.templateName, label: data?.templateName, id: data?._id })
      })
      defaultSelectedTemplate.push(listTemplateData?.resData[0])
      setSelectedTemplate(defaultSelectedTemplate)
    }
    setTemplateList(templateArray)
  }, [listTemplateData])

  useEffect(() => {
    let mainUrlList = []
    if (usersDomain && usersDomain.length) {
      usersDomain.forEach((element) => {
        element &&
          element?.items?.length &&
          element?.items?.forEach((elementItem, index) => {
            if (elementItem && elementItem?._id && index === 0) {
              mainUrlList.push({ value: elementItem?.url, label: elementItem?.url, id: elementItem?._id })
            }
          })
      })
    }
    setMainUrlList(mainUrlList)
  }, [usersDomain])

  useEffect(() => {
    if (isGetUrlDetailsSuccess && generatedReportData && generatedReportData?.length > 0) {
      setMainUrlData(generatedReportData)
      const urlDataToDisplay = getDataForReport(generatedReportData[0])
      setPositiveCounter(urlDataToDisplay.positiveCounter)
      setNegativeCOunter(urlDataToDisplay.negativeCounter)
      setWarningCounter(urlDataToDisplay.warningCounter)
      setCommonSeoSCounter(urlDataToDisplay.commonSeoS)
      setCommonSeoWCounter(urlDataToDisplay.commonSeoW)
      setCommonSeoFCounter(urlDataToDisplay.commonSeoF)

      setSpeedOptimizeSCounter(urlDataToDisplay?.speedOptS)
      setSpeedOptimizeWCounter(urlDataToDisplay?.speedOptW)
      setSpeedOptimizeFCounter(urlDataToDisplay?.speedOptF)

      setServerSCounter(urlDataToDisplay?.serverS)
      setServerWCounter(urlDataToDisplay?.serverW)
      setServerFCounter(urlDataToDisplay?.serverF)

      setMobileUsabilitySCounter(urlDataToDisplay?.mobileUseS)
      setMobileUsabilityWCounter(urlDataToDisplay?.mobileUseW)
      setMobileUsabilityFCounter(urlDataToDisplay?.mobileUseF)

      setAdvanceSeoSCounter(urlDataToDisplay?.advanceS)
      setAdvanceSeoWCounter(urlDataToDisplay?.advanceW)
      setAdvanceSeoFCounter(urlDataToDisplay?.advanceF)

      if (generatedReportData && generatedReportData?.length) {
        setKeywordCloudTest([])
        let keywordCloud
        keywordCloud = _.sortBy(generatedReportData?.[0]?.keywordCloudTest?.data?.keywordCloud, 'count')
        keywordCloud = keywordCloud.reverse()
        let keywordCloudFinalData = keywordCloud.slice(0, 125)
        let displayData = generatedReportData?.[0]?.keywordCloudTest?.data?.keywordCloud.filter(
          (element1) => {
            let flag = false
            keywordCloudFinalData.forEach((element2) => {
              if (element1.word === element2.word) {
                flag = true
              }
            })
            return flag
          },
        )
        setKeywordCloudTest(displayData)
        let instagramData = generatedReportData?.[0]?.socialMediaInstagramData?.data
          ? generatedReportData?.[0]?.socialMediaInstagramData?.data?.testResults?.status === 3
            ? false
            : true
          : false
        let twitterData = generatedReportData?.[0]?.socialMediaTwitterData?.data
          ? generatedReportData?.[0]?.socialMediaTwitterData?.data?.testResults?.status === 3
            ? false
            : true
          : false
        setInstagramData(instagramData)
        setTwitterData(twitterData)
      }
    }
  }, [isGetUrlDetailsSuccess])

  useEffect(() => {
    if (generateReportDownloadPDFData) {
      const domain = new URL(selectedMainUrl).hostname
      const url = window.URL.createObjectURL(generateReportDownloadPDFData)
      const link = document.createElement('a')
      link.href = url
      link.download = `${domain}_latest.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success(`Report Downloaded successfully`)
      dispatch(generateReportDownloadPDFClear())
    }
  }, [generateReportDownloadPDFData])

  const handleChange = (value) => {
    let selectedTemp =
      listTemplateData &&
      listTemplateData?.resData &&
      listTemplateData?.resData?.length &&
      listTemplateData?.resData?.filter((list) => list?.templateName === value)
    setSelectedTemplate(selectedTemp)
  }

  const handleMainUrlChange = (value) => {
    setSelectedMainUrl(value)
    let dataToDownload = mainUrlList?.length && mainUrlList?.filter((list) => list?.label === value)
    if (dataToDownload && dataToDownload.length) {
      dispatch(generateWhiteLabelReportRequest({ id: dataToDownload?.[0]?.id }))
      dispatch(getBacklinksStats())
    }
  }

  const triggerDownload = () => {
    const targetReportBodyDiv = document.getElementById('report-body')
    const htmlBody = targetReportBodyDiv.innerHTML

    const bodyElement = document.createElement('div')
    bodyElement.innerHTML = htmlBody

    const footerElement = bodyElement.querySelector('.template-footer')
    const headerElement = bodyElement.querySelector('.header-container')
    if (footerElement) {
      footerElement.remove()
    }
    if (headerElement) {
      headerElement.remove()
    }
    bodyElement.innerHTML = bodyElement.innerHTML.replace('custom-pdf-section', '')

    let data = JSON.stringify({
      head: constHeader,
      header: headerElement.outerHTML,
      body: bodyElement.innerHTML,
      footer: footerElement.outerHTML,
    })

    // const htmlss = `${constHeader} ${headerElement.outerHTML} ${bodyElement.innerHTML} ${footerElement.outerHTML}`
    // console.log('🚀 ~ triggerDownload ~ htmlss:', htmlss)
    dispatch(generateReportDownloadPDFRequest(data))
  }

  const getColor = (score) => {
    let webScore = typeof score === 'string' ? parseInt(score) : score
    if (webScore <= 65) {
      return 'rgb(217, 83, 80)'
    }
    if (webScore >= 66 && webScore <= 75) {
      return 'rgb(255, 171, 0)'
    }
    if (webScore >= 76 && webScore <= 84) {
      return 'rgb(255, 171, 0)'
    }
    if (webScore >= 85 && webScore <= 100) {
      return 'rgb(82, 196, 26)'
    }
  }

  const totalBackLinksData = () => {
    const splitedValue = selectedMainUrl?.split('/').slice(0, 3)
    let hostName = splitedValue?.[splitedValue?.length - 1]
    if (hostName?.includes('www.')) {
      hostName = hostName.replace('www.', '')
    }

    const filteredData = backLinksStatsData?.graphData?.backlinksStats?.filter((item) =>
      item.hostName.includes(hostName),
    )
    if (filteredData && filteredData.length > 0) {
      return filteredData[0].data[1]
    }
    return null
  }

  return (
    <Card className="new-template-card generate-report-card" bordered={false}>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="left-side-section">
            <Space align="center" className="pb-3">
              <Title level={4} className="mb-0">
                New Branded PDF Report
              </Title>
            </Space>
            <Title level={5} className="mb-0 pb-2 create-template-description">
              Provide customized professional SEO reports for your clients
            </Title>
            <Divider />
            <div className="d-flex justify-content-between gap-2">
              <div className="input-filed-with-label w-50">
                <label className="template-name-label">Choose a template</label> <br />
                <br />
                <Select
                  value={selectedTemplate?.[0]?.templateName}
                  style={{ width: '100%' }}
                  size="large"
                  onChange={handleChange}
                  options={templateList}
                />
              </div>

              <div className="input-filed-with-label w-50">
                <label className="template-name-label">Select one of your Saved Reports</label> <br />
                <br />
                <Select
                  defaultValue="Select"
                  style={{ width: '100%' }}
                  size="large"
                  onChange={handleMainUrlChange}
                  options={mainUrlList}
                />
              </div>
            </div>
            <Button
              loading={isReportStartProcessing ? true : false}
              disabled={mainUrlData ? false : true}
              type="primary"
              onClick={triggerDownload}
              className="generate-report-btn"
            >
              Download
            </Button>
          </div>
        </Col>
        <Divider />
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <>
            {isGenerateReportLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <div className="text-center py-3">
                  <Loader />
                </div>
              </div>
            ) : (
              <>
                <div ref={targetDivRef} id="report-body">
                  {selectedMainUrl && mainUrlData && mainUrlData?.length ? (
                    <>
                      <section id="report-body" className="custom-pdf-section pdf-page-container">
                        {/* Start Header */}
                        <div className="d-flex align-items-center header-container">
                          <div className="template-logo">
                            {selectedTemplate && selectedTemplate?.length && selectedTemplate?.[0]?.logo ? (
                              <img
                                src={selectedTemplate?.[0]?.logo}
                                alt="company-logo"
                                width={100}
                                height={100}
                                className="company-logo-preview"
                              />
                            ) : (
                              <PictureOutlined className="picture-icon" />
                            )}
                          </div>
                          <Divider type="vertical" className="vertical-line" />
                          <div className="template-header">
                            <span className="header-title">
                              {selectedTemplate && selectedTemplate?.length
                                ? selectedTemplate?.[0]?.header
                                : 'Sample text in the header'}
                            </span>
                          </div>
                        </div>
                        {/*End Header */}
                        <div className="report-render-engine">
                          <div className="ant-card seo-report-card seo-report-card-border">
                            <div className="ant-card-head">
                              <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">
                                  <span className="seo-report-card-title">
                                    {selectedMainUrl ? selectedMainUrl : 'https://your-url-goes-here.com'}
                                  </span>
                                  <p className="report-description mb-0">SEO Report</p>
                                </div>
                              </div>
                            </div>
                            <div className="ant-card-body report-body">
                              <div className="seo-report-graph">
                                <div className="seo-report-wrapper">
                                  <div className="seo-report-total">
                                    <div
                                      className="ant-progress ant-progress-circle ant-progress-status-normal ant-progress-show-info ant-progress-default seo-progress-bar w-100 h-100 m-auto"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-inner ant-progress-circle-gradient">
                                        <svg
                                          className="ant-progress-circle"
                                          viewBox="-50 -50 100 100"
                                          role="presentation"
                                        >
                                          <defs>
                                            <linearGradient
                                              id="rc_progress_0-gradient"
                                              x1="100%"
                                              y1="0%"
                                              x2="0%"
                                              y2="0%"
                                            >
                                              <stop offset="66%" stop-color="#3f81ea" />
                                            </linearGradient>
                                          </defs>
                                          <circle
                                            className="ant-progress-circle-trail tes1"
                                            r="47"
                                            cx="0"
                                            cy="0"
                                            stroke-linecap="round"
                                            stroke-width="6"
                                            style={{
                                              strokeDasharray: '295.31px, 289.027px',
                                              strokeDashoffset: '0px',
                                              transform: 'rotate(-90deg)',
                                              transformOrigin: '0px 0px',
                                              transition:
                                                'stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s, opacity 0.3s ease 0s',
                                              fillOpacity: 0,
                                            }}
                                          />
                                          <circle
                                            className="ant-progress-circle-path test2"
                                            r="47"
                                            cx="0"
                                            cy="0"
                                            stroke="url(#rc_progress_0-gradient)"
                                            stroke-linecap="round"
                                            stroke-width="6"
                                            opacity="1"
                                            style={{
                                              stroke: `${getColor(
                                                Math.round(((positiveCounter + warningCounter) / 64) * 100),
                                              )}`,
                                              strokeDasharray: `${Math.round(
                                                ((positiveCounter + warningCounter) / 64) * 292,
                                              )}px, 289.027px`,
                                              strokeDashoffset: '0px',
                                              transform: 'rotate(-90deg)',
                                              transformOrigin: '0px 0px',
                                              transition:
                                                'stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke ease 0s, stroke-width ease 0.3s, opacity ease 0s',
                                              fillOpacity: 0,
                                            }}
                                          />
                                          <circle
                                            className="ant-progress-circle-path test3"
                                            r="47"
                                            cx="0"
                                            cy="0"
                                            stroke-linecap="round"
                                            stroke-width="6"
                                            opacity="0"
                                            style={{
                                              stroke: 'rgb(82, 196, 26)',
                                              strokeDasharray: '295.31px, 289.027px',
                                              strokeDashoffset: '0px',
                                              transform: 'rotate(-90deg)',
                                              transformOrigin: '0px 0px',
                                              transition:
                                                'stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke ease 0s, stroke-width ease 0.3s, opacity ease 0s',
                                              fillOpacity: 0,
                                            }}
                                          />
                                        </svg>
                                        <span
                                          className="ant-progress-text fw-bold fs-2 mt-2"
                                          title={`${Math.round(
                                            ((positiveCounter + warningCounter) / 64) * 100,
                                          )} / 100`}
                                        >
                                          {`${Math.round(((positiveCounter + warningCounter) / 64) * 100)}`} /
                                          100
                                        </span>
                                      </div>
                                    </div>
                                    <span className="progress-label">SEO Score</span>
                                  </div>
                                </div>
                                <div className="seo-graph-split">
                                  <div className="progress-failed mb-2">
                                    <label>{negativeCounter} Failed</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-exception ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${negativeCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#d95350',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="progress-warning mb-2">
                                    <label>{warningCounter} Warnings</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${warningCounter}%`,
                                              height: '8px',
                                              backgroundImage:
                                                'linear-gradient(to right, rgb(255, 171, 0) 0%, rgb(255, 171, 0) 5%)',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="progress-passed">
                                    <label>{positiveCounter} Passed</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg test4"
                                            style={{
                                              width: `${positiveCounter}%`,
                                              height: '8px',
                                              // backgroundImage: "linear-gradient(to right, rgb(52, 188, 110) 0%, rgb(52, 188, 110) 39%)"
                                              backgroundColor: '#4bbf73',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ant-card seo-report-card mt-4 mb-4 customs-card-border">
                            <div className="ant-card-head">
                              <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">
                                  <span className="seo-report-card-title fw-bolder fs-6">
                                    PRIMARY SEO SIGNALS
                                  </span>
                                </div>
                              </div>
                              <div className="ant-card-extra">
                                <div className="d-flex gap-4">
                                  <div>
                                    <label>
                                      Score:{' '}
                                      {(
                                        (commonSeoSCounter * 100) /
                                        (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                      ).toFixed(0)}
                                    </label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${(
                                                (commonSeoSCounter * 100) /
                                                (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                              ).toFixed(0)}%`,
                                              height: '8px',
                                              backgroundColor: `${
                                                (
                                                  (commonSeoSCounter * 100) /
                                                  (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                                ).toFixed(0) > 75
                                                  ? '#4bbf73'
                                                  : (
                                                        (commonSeoSCounter * 100) /
                                                        (commonSeoFCounter +
                                                          commonSeoWCounter +
                                                          commonSeoSCounter)
                                                      ).toFixed(0) <= 50
                                                    ? '#d95350'
                                                    : '#e5a54b'
                                              }`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Failed: {commonSeoFCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-exception ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${commonSeoFCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#d95350',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Warning: {commonSeoWCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default w-100"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${commonSeoWCounter}%`,
                                              height: '8px',
                                              backgroundImage:
                                                'linear-gradient(to right, rgb(255, 171, 0) 0%, rgb(255, 171, 0) 5%)',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Success: {commonSeoSCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${commonSeoSCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#4bbf73',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ant-card-body p-0">
                              <section className="meta-title-section">
                                <div className="table-list-container">
                                  <table className="list-table">
                                    <tbody>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.information?.seoCheckup?.title
                                                ?.titleStatus === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.information?.seoCheckup?.title
                                                      ?.titleStatus === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Meta Title Signal</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.information?.seoCheckup?.title?.titleMessage,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      {mainUrlData?.[0]?.information?.seoCheckup?.title?.title ? (
                                        <tr>
                                          <td colspan="2">
                                            <div className="text-with-border pb-2 mb-2">
                                              <div>
                                                Text:{' '}
                                                {mainUrlData?.[0]?.information?.seoCheckup?.title?.title ? (
                                                  <span>
                                                    {mainUrlData?.[0]?.information?.seoCheckup?.title?.title}
                                                  </span>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <div>
                                                Length:{' '}
                                                {mainUrlData?.[0]?.information?.seoCheckup?.title?.title ? (
                                                  <span>
                                                    {
                                                      mainUrlData?.[0]?.information?.seoCheckup?.title?.title
                                                        .length
                                                    }
                                                  </span>
                                                ) : (
                                                  <></>
                                                )}{' '}
                                                characters
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : null}
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.information?.seoCheckup?.description
                                                ?.descriptionStatus === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.information?.seoCheckup?.description
                                                      ?.descriptionStatus === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Meta Description Signal</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.information?.seoCheckup?.description
                                                ?.descriptionMessage,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      {mainUrlData?.[0]?.information?.seoCheckup?.description?.description ? (
                                        <tr>
                                          <td colspan="2">
                                            <div className="text-with-border pb-2 mb-2">
                                              <div>
                                                Text:{' '}
                                                {mainUrlData?.[0]?.information?.seoCheckup?.description
                                                  ?.description ? (
                                                  <span>
                                                    {
                                                      mainUrlData?.[0]?.information?.seoCheckup?.description
                                                        ?.description
                                                    }
                                                  </span>
                                                ) : (
                                                  <>{'-'}</>
                                                )}
                                              </div>
                                              <div>
                                                Length:{' '}
                                                {mainUrlData?.[0]?.information?.seoCheckup?.description
                                                  ?.description ? (
                                                  <span>
                                                    {
                                                      mainUrlData?.[0]?.information?.seoCheckup?.description
                                                        ?.description.length
                                                    }
                                                  </span>
                                                ) : (
                                                  <>{'0'}</>
                                                )}{' '}
                                                characters
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : null}
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <h6 className="mb-0">Google Search Results Preview - SERP View</h6>
                                          <small className="m-0 small-title">No SEO impact</small>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3">
                                            <span className="stats-title">Desktop version</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div
                                            className="google-search-result ps-3"
                                            style={{ height: '120px' }}
                                          >
                                            <span className="url-format">
                                              {mainUrlData?.length &&
                                              mainUrlData?.[0]?.information?.seoCheckup?.redirect_url ? (
                                                <>{mainUrlData?.[0]?.information?.seoCheckup?.redirect_url}</>
                                              ) : (
                                                ''
                                              )}
                                            </span>
                                            <span className="title-format line-clamp lines1">
                                              {mainUrlData?.[0]?.information?.seoCheckup?.title?.title ? (
                                                mainUrlData?.[0]?.information?.seoCheckup?.title?.title
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                            <span className="description-format line-clamp lines2">
                                              {mainUrlData?.[0]?.information?.seoCheckup?.description
                                                ?.description ? (
                                                mainUrlData?.[0]?.information?.seoCheckup?.description
                                                  ?.description
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3">
                                            <span className="stats-title">Mobile version</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div
                                            className="google-search-result mobile ps-3"
                                            style={{ height: '140px' }}
                                          >
                                            <span className="url-format">
                                              {mainUrlData?.length &&
                                              mainUrlData?.[0]?.information?.seoCheckup?.redirect_url ? (
                                                <>{mainUrlData?.[0]?.information?.seoCheckup?.redirect_url}</>
                                              ) : (
                                                ''
                                              )}
                                            </span>
                                            <span className="title-format line-clamp lines2">
                                              {mainUrlData?.[0]?.information?.seoCheckup?.title?.title ? (
                                                mainUrlData?.[0]?.information?.seoCheckup?.title?.title
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                            <span className="description-format line-clamp lines3">
                                              {mainUrlData?.[0]?.information?.seoCheckup?.description
                                                ?.description ? (
                                                mainUrlData?.[0]?.information?.seoCheckup?.description
                                                  ?.description
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.microDataSchema?.data?.metaTagsResults
                                                ?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.microDataSchema?.data?.metaTagsResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <div>
                                            <h6 className="mb-0">Social Media Meta Tags Signals</h6>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="pb-2 ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.microDataSchema?.data?.metaTagsResults
                                                ?.message,
                                            ) || ''}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            <table className="common-table keyword-table table">
                                              <thead className="keyword-table-header">
                                                <th
                                                  style={{
                                                    paddingLeft: '15px',
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                  }}
                                                  colspan="2"
                                                >
                                                  Open Graph Meta Tags
                                                </th>
                                              </thead>
                                              <tbody className="common-table-body">
                                                <tr>
                                                  <td>og:title</td>
                                                  <td>
                                                    {
                                                      mainUrlData?.[0]?.microDataSchema?.data?.data?.meta?.[
                                                        'og-title'
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>og:description</td>
                                                  <td>
                                                    {
                                                      mainUrlData?.[0]?.microDataSchema?.data?.data?.meta?.[
                                                        'og-description'
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>og:url</td>
                                                  <td>
                                                    {
                                                      mainUrlData?.[0]?.microDataSchema?.data?.data?.meta?.[
                                                        'og-url'
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>og:type</td>
                                                  <td>
                                                    {
                                                      mainUrlData?.[0]?.microDataSchema?.data?.data?.meta?.[
                                                        'og-type'
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>og:site</td>
                                                  <td>
                                                    {
                                                      mainUrlData?.[0]?.microDataSchema?.data?.data?.meta?.[
                                                        'og-site-name'
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>og:image</td>
                                                  <td>
                                                    <div className="text-break">
                                                      {
                                                        mainUrlData?.[0]?.microDataSchema?.data?.data?.meta?.[
                                                          'og-image'
                                                        ]
                                                      }
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            <table className="common-table keyword-table table">
                                              <thead className="keyword-table-header">
                                                <th
                                                  style={{
                                                    paddingLeft: '15px',
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                  }}
                                                  colspan="2"
                                                >
                                                  Twiiter Card Meta Tags
                                                </th>
                                              </thead>
                                              <tbody className="common-table-body">
                                                <tr>
                                                  <td>twitter:card </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      mainUrlData.microDataSchema?.data?.data?.meta?.[
                                                        'twitter-card'
                                                      ]
                                                    }{' '}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>twitter:site </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      mainUrlData.microDataSchema?.data?.data?.meta?.[
                                                        'twitter-site'
                                                      ]
                                                    }{' '}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>twitter:creator </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      mainUrlData.microDataSchema?.data?.data?.meta?.[
                                                        'twitter-creator'
                                                      ]
                                                    }{' '}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>twitter:url </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      mainUrlData.microDataSchema?.data?.data?.meta?.[
                                                        'twitter-url'
                                                      ]
                                                    }{' '}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>twitter:title </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      mainUrlData.microDataSchema?.data?.data?.meta?.[
                                                        'twitter-title'
                                                      ]
                                                    }{' '}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>twitter:description </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      mainUrlData.microDataSchema?.data?.data?.meta?.[
                                                        'twitter-description'
                                                      ]
                                                    }{' '}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <h6>Keyword Density Signals Check</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="text-gray pb-2">
                                            <div className="margin-left-33 mb-2">
                                              Total Words:{' '}
                                              {mainUrlData?.[0]?.keywordCloudTest?.data?.totalWord || ''}
                                            </div>
                                            {(mainUrlData?.[0]?.keywordCloudTest?.data?.positiveKeywords &&
                                              mainUrlData?.[0]?.keywordCloudTest?.data?.positiveKeywords
                                                ?.length &&
                                              mainUrlData?.[0]?.keywordCloudTest?.data?.positiveKeywords.map(
                                                (item, index) => {
                                                  if (index >= 4) return
                                                  if (item.word && item.word.length) {
                                                    return (
                                                      <tr>
                                                        <td colspan="2">
                                                          <div
                                                            className="btn-group margin-left-33"
                                                            role="group"
                                                          >
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                            >
                                                              {item.count}
                                                            </button>
                                                            <span className="btn-group-text">
                                                              {item.word}
                                                            </span>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  } else {
                                                    return ''
                                                  }
                                                },
                                              )) ||
                                              ''}
                                            {mainUrlData?.[0]?.keywordCloudTest?.data?.positiveKeywords
                                              ?.length &&
                                            mainUrlData?.[0]?.keywordCloudTest?.data?.negativeKeywords
                                              ?.length ? (
                                              <tr>
                                                <td colspan="2">
                                                  <div className="border-bottom mb-2 pdf-page-break" />
                                                  <div className="pdf-page-space" />
                                                </td>
                                              </tr>
                                            ) : null}
                                            {(mainUrlData?.[0]?.keywordCloudTest?.data?.negativeKeywords &&
                                              mainUrlData?.[0]?.keywordCloudTest?.data?.negativeKeywords
                                                ?.length &&
                                              mainUrlData?.[0]?.keywordCloudTest?.data?.negativeKeywords.map(
                                                (item, index) => {
                                                  if (index >= 4) return
                                                  if (item.word && item.word.length) {
                                                    return (
                                                      <tr>
                                                        <td colspan="2">
                                                          <div
                                                            className="btn-group margin-left-33"
                                                            role="group"
                                                          >
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                            >
                                                              {item.count}
                                                            </button>
                                                            <span className="btn-group-text">
                                                              {item.word}
                                                            </span>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  } else {
                                                    return ''
                                                  }
                                                },
                                              )) ||
                                              ''}
                                            {(mainUrlData?.[0]?.keywordCloudTest?.data?.warningKeywords &&
                                              mainUrlData?.[0]?.keywordCloudTest?.data?.warningKeywords
                                                ?.length &&
                                              mainUrlData?.[0]?.keywordCloudTest?.data?.warningKeywords.map(
                                                (item, index) => {
                                                  if (index >= 4) return
                                                  if (item.word && item.word.length) {
                                                    return (
                                                      <tr>
                                                        <td colspan="2">
                                                          <div
                                                            className="btn-group margin-left-33"
                                                            role="group"
                                                          >
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                            >
                                                              {item.count}
                                                            </button>
                                                            <span className="btn-group-text">
                                                              {item.word}
                                                            </span>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  } else {
                                                    return ''
                                                  }
                                                },
                                              )) ||
                                              ''}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.microDataSchema?.data
                                                ?.keywordsUsageTestResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.microDataSchema?.data
                                                      ?.keywordsUsageTestResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="cross-mark-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <h6>Keyword Usage Test Signals</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" className="text-gray">
                                          <div className="ms-3 ps-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.microDataSchema?.data
                                                ?.keywordsUsageTestResults?.message,
                                            ) || ''}{' '}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            <table className="w-100 keyword-table table">
                                              <thead className="keyword-table-header common-table">
                                                <th data-column="0" tabindex="0" unselectable="on">
                                                  <div>Keyword</div>
                                                </th>
                                                <th
                                                  data-column="1"
                                                  tabindex="0"
                                                  unselectable="on"
                                                  className="text-center"
                                                >
                                                  <div>Title tag</div>
                                                </th>
                                                <th
                                                  data-column="2"
                                                  tabindex="0"
                                                  unselectable="on"
                                                  className="text-center"
                                                >
                                                  <div>Meta description</div>
                                                </th>
                                                <th
                                                  data-column="3"
                                                  tabindex="0"
                                                  unselectable="on"
                                                  className="text-center"
                                                >
                                                  <div>Headings</div>
                                                </th>
                                              </thead>
                                              <tbody
                                                className="common-table-body"
                                                style={{ height: '300px' }}
                                              >
                                                {mainUrlData?.[0]?.microDataSchema?.data?.keywordsUsageTest &&
                                                mainUrlData?.[0]?.microDataSchema?.data?.keywordsUsageTest
                                                  .length ? (
                                                  mainUrlData?.[0]?.microDataSchema?.data?.keywordsUsageTest.map(
                                                    (item, index) => {
                                                      if (index >= 7) return
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>{item.keyword}</td>
                                                            <td className="text-center">
                                                              {item?.titleCount > 0 ? (
                                                                <i
                                                                  className="fa-solid fa-circle-check fa-lg"
                                                                  style={{ color: '#148a14' }}
                                                                />
                                                              ) : (
                                                                <i
                                                                  className="fa-solid fa-circle-xmark fa-lg"
                                                                  style={{ color: '#ff2b2b' }}
                                                                />
                                                              )}
                                                            </td>
                                                            <td className="text-center">
                                                              {item?.descriptionCount > 0 ? (
                                                                <i
                                                                  className="fa-solid fa-circle-check fa-lg"
                                                                  style={{ color: '#148a14' }}
                                                                />
                                                              ) : (
                                                                <i
                                                                  className="fa-solid fa-circle-xmark fa-lg"
                                                                  style={{ color: '#ff2b2b' }}
                                                                />
                                                              )}
                                                            </td>
                                                            <td className="text-center">
                                                              {item?.headingCount > 0 ? (
                                                                <i
                                                                  className="fa-solid fa-circle-check fa-lg"
                                                                  style={{ color: '#148a14' }}
                                                                />
                                                              ) : (
                                                                <i
                                                                  className="fa-solid fa-circle-xmark fa-lg"
                                                                  style={{ color: '#ff2b2b' }}
                                                                />
                                                              )}
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <h6>Keyword Cloud Signals - Visual & Count</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div
                                            className="cloud-tag-listing d-flex flex-wrap gap-2 margin-left-33 mb-2"
                                            style={{ height: '400px' }}
                                          >
                                            {keywordCloudData &&
                                              keywordCloudData.length &&
                                              keywordCloudData.map((item, index) => {
                                                if (index >= 65) return
                                                if (item.word && item.word.length < 10) {
                                                  return (
                                                    <span style={{ fontSize: getFontSize(item.count) }}>
                                                      {item.word}
                                                    </span>
                                                  )
                                                } else {
                                                  return ''
                                                }
                                              })}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <h6>Related Keywords Test Signals</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="text-gray">
                                            <div className="ms-3 ps-3">
                                              Some of the most relevant competitors for this domain are listed
                                              below:
                                            </div>
                                            <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                              {mainUrlData?.[0]?.relatedKeywordsFinal?.data
                                                ?.relatedKeywordsFinal &&
                                              mainUrlData?.[0]?.relatedKeywordsFinal?.data
                                                ?.relatedKeywordsFinal?.length
                                                ? mainUrlData?.[0]?.relatedKeywordsFinal?.data?.relatedKeywordsFinal
                                                    ?.slice(0, 5)
                                                    ?.map((item) => {
                                                      return (
                                                        <li>
                                                          {item.matched ? (
                                                            <img
                                                              style={{ height: 15, marginRight: 10 }}
                                                              src={images.tickIcon}
                                                              alt="tick-icon"
                                                            />
                                                          ) : (
                                                            <img
                                                              style={{ height: 15, marginRight: 10 }}
                                                              src={images.crossIcon}
                                                              alt="cross-icon"
                                                            />
                                                          )}
                                                          {item?.word}
                                                        </li>
                                                      )
                                                    })
                                                : ''}
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td colspan="2">
                                          <h6>Competitor Domain Signals</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="text-gray">
                                            <div className="ms-3 ps-3">
                                              Some of the most relevant competitors for this domain are listed
                                              below:
                                            </div>
                                            <ul className="mt-1 list-group ms-4 ps-4 mb-2">
                                              {mainUrlData?.[0]?.domainCompetitors?.data?.competitors &&
                                              mainUrlData?.[0]?.domainCompetitors?.data?.competitors?.length
                                                ? mainUrlData?.[0]?.domainCompetitors?.data?.competitors
                                                    ?.slice(0, 5)
                                                    ?.map((item) => {
                                                      return <li> {item}</li>
                                                    })
                                                : ''}
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      {/* ********  Heading Tag Analysis Signals ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.headingAnalysis?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Heading Tag Analysis Signals"
                                      />
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.headingAnalysis?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h1?.length == 0 ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <td colSpan="2">
                                            <div className="text-break">
                                              <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                                <li
                                                  className="list-group-item header-bg-color"
                                                  aria-current="true"
                                                >
                                                  H1 Tags
                                                </li>
                                                {mainUrlData?.[0]?.headingAnalysis?.data?.h1?.map(
                                                  (item, index) => {
                                                    if (index >= 6) {
                                                      return
                                                    }
                                                    return <li className="list-group-item">{item}</li>
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h2?.length == 0 ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <td colSpan="2">
                                            <div className="text-break">
                                              <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                                <li
                                                  className="list-group-item header-bg-color"
                                                  aria-current="true"
                                                >
                                                  H2 Tags
                                                </li>
                                                {mainUrlData?.[0]?.headingAnalysis?.data?.h2?.map(
                                                  (item, index) => {
                                                    if (index >= 10) {
                                                      return
                                                    }
                                                    return <li className="list-group-item">{item}</li>
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h3?.length == 0 ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <td colSpan="2">
                                            <div className="text-break">
                                              <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                                <li
                                                  className="list-group-item header-bg-color"
                                                  aria-current="true"
                                                >
                                                  H3 Tags
                                                </li>
                                                {mainUrlData?.[0]?.headingAnalysis?.data?.h3?.map(
                                                  (item, index) => {
                                                    if (index >= 7) {
                                                      return
                                                    }
                                                    return <li className="list-group-item">{item}</li>
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h1?.length !== 0 &&
                                      mainUrlData?.[0]?.headingAnalysis?.data?.h2?.length !== 0 &&
                                      mainUrlData?.[0]?.headingAnalysis?.data?.h3?.length !== 0 &&
                                      (mainUrlData?.[0]?.headingAnalysis?.data?.h4?.length !== 0 ||
                                        mainUrlData?.[0]?.headingAnalysis?.data?.h5?.length !== 0 ||
                                        mainUrlData?.[0]?.headingAnalysis?.data?.h6?.length !== 0) ? (
                                        <tr>
                                          <td colspan="2">
                                            <div className="pdf-page-break" />
                                            <div className="pdf-page-space" />
                                          </td>
                                        </tr>
                                      ) : null}
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h4?.length == 0 ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <td colSpan="2">
                                            <div className="text-break">
                                              <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                                <li
                                                  className="list-group-item header-bg-color"
                                                  aria-current="true"
                                                >
                                                  H4 Tags
                                                </li>
                                                {mainUrlData?.[0]?.headingAnalysis?.data?.h4?.map(
                                                  (item, index) => {
                                                    if (index >= 3) {
                                                      return
                                                    }
                                                    return <li className="list-group-item">{item}</li>
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h5?.length == 0 ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <td colSpan="2">
                                            <div className="text-break">
                                              <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                                <li
                                                  className="list-group-item header-bg-color"
                                                  aria-current="true"
                                                >
                                                  H5 Tags
                                                </li>
                                                {mainUrlData?.[0]?.headingAnalysis?.data?.h5?.map(
                                                  (item, index) => {
                                                    if (index >= 3) {
                                                      return
                                                    }
                                                    return <li className="list-group-item">{item}</li>
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {mainUrlData?.[0]?.headingAnalysis?.data?.h6?.length == 0 ? (
                                        <></>
                                      ) : (
                                        <tr>
                                          <td colSpan="2">
                                            <div className="text-break">
                                              <ul className="mt-1 list-group ms-3 ps-3 mb-2 list-unstyled">
                                                <li
                                                  className="list-group-item header-bg-color"
                                                  aria-current="true"
                                                >
                                                  H6 Tags
                                                </li>
                                                {mainUrlData?.[0]?.headingAnalysis?.data?.h6?.map(
                                                  (item, index) => {
                                                    if (index >= 3) {
                                                      return
                                                    }
                                                    return <li className="list-group-item">{item}</li>
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      {/* ******** Robots.txt Signal Analysis Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.robotsTxtTest?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName={'Robots.txt Signal Analysis'}
                                      />
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.robotsTxtTest?.data?.testResults?.message,
                                            )}
                                            <br />
                                            {mainUrlData?.[0]?.robotsTxtTest?.data?.testResults?.status ===
                                            1 ? (
                                              <a href="javascript:void(0)">
                                                {' '}
                                                {
                                                  mainUrlData?.[0]?.robotsTxtTest?.data?.robotsTxt
                                                    ?.robotsTxtUrl
                                                }{' '}
                                              </a>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Sitemap Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.siteMapData?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName={'Sitemap Signal Test'}
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {mainUrlData?.[0]?.siteMapData?.data?.testResults?.status ? (
                                              shortMessageForWhiteLabel(
                                                mainUrlData?.[0]?.siteMapData?.data?.testResults?.message,
                                              )
                                            ) : (
                                              <></>
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ********  SEO Friendly URL Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={
                                          mainUrlData?.[0]?.seoFriendlyUrlAnalysis?.data?.testResults?.status
                                        }
                                        isConsidered={true}
                                        testName="SEO Friendly URL Signal Test"
                                      />
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.seoFriendlyUrlAnalysis?.data?.testResults
                                                ?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Image Alt Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.imageAlt?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName={'Image Alt Test'}
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {mainUrlData?.[0]?.imageAlt?.data?.testResults?.status ? (
                                              shortMessageForWhiteLabel(
                                                mainUrlData?.[0]?.imageAlt?.data?.testResults?.message,
                                              )
                                            ) : (
                                              <></>
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Excessive Image Data Size Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.responsiveImage?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Excessive Image Data Size Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.responsiveImage?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Image Aspect Ratio Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.aspectRatio?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Image Aspect Ratio Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.aspectRatio?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Inline CSS Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.inlineCss?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Inline CSS Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.inlineCss?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      {/* ******** Html Validation ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.htmlValidation?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Html Validation"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.htmlValidation?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Google Analytics Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.googleAnalytics?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Google Analytics Signal Test"
                                      />
                                      <TestDescription
                                        className="ps-3 ms-3 mb-2"
                                        description={shortMessageForWhiteLabel(
                                          mainUrlData?.[0]?.googleAnalytics?.data?.testResults?.message,
                                        )}
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Favicon Checker Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.faviconCheckerTest?.data?.status}
                                        isConsidered={true}
                                        testName="Favicon Checker Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3 mb-2">
                                                {mainUrlData?.[0]?.faviconCheckerTest?.data?.result}
                                                <br />
                                              </div>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Charset Declaration Signal Test ********* */}

                                      {/* ******** Internal Broken Links Checker ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.brokenLinks?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Internal Broken Links Checker"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3 mb-2">
                                                {shortMessageForWhiteLabel(
                                                  mainUrlData?.[0]?.brokenLinks?.data?.testResults?.message,
                                                )}
                                                <br />
                                              </div>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** JS Error Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.jsError?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="JS Error Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3 mb-2">
                                                {shortMessageForWhiteLabel(
                                                  mainUrlData?.[0]?.jsError?.data?.testResults?.message,
                                                )}
                                                <br />
                                              </div>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Historic Console Error Signal Check ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.consoleErrors?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Historic Console Error Signal Check"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3 mb-2">
                                                {shortMessageForWhiteLabel(
                                                  mainUrlData?.[0]?.consoleErrors?.data?.testResults?.message,
                                                )}
                                                <br />
                                              </div>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Advanced Console Error Signal Check ********* */}
                                      <CommonSEOWhiteLabel
                                        status={
                                          mainUrlData?.[0]?.advancedConsoleErrors?.data?.testResults?.status
                                        }
                                        isConsidered={true}
                                        testName="Advanced Console Error Signal Check"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3 mb-2">
                                                {shortMessageForWhiteLabel(
                                                  mainUrlData?.[0]?.advancedConsoleErrors?.data?.testResults
                                                    ?.message,
                                                )}
                                                <br />
                                              </div>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      {/* ******** Charset Declaration Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.characterSet?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Charset Declaration Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3 mb-2">
                                                {shortMessageForWhiteLabel(
                                                  mainUrlData?.[0]?.characterSet?.data?.testResults?.message,
                                                )}
                                                <br />
                                              </div>
                                              {/* <div className={"ps-3 ms-3"}>
                                            { mainUrlData?.[0]?.characterSet?.data?.characterSetResult }<br/>
                                        </div> */}
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Social Media Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={
                                          mainUrlData?.[0]?.socialMediaInstagramData?.data?.testResults
                                            ?.status === 1 ||
                                          mainUrlData?.[0]?.socialMediaTwitterData?.data?.testResults
                                            ?.status === 1
                                            ? 1
                                            : 3
                                        }
                                        isConsidered={true}
                                        testName={'Social Media Signal Test'}
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <tr>
                                            <td colspan="2">
                                              <div className="ps-3 ms-3">
                                                {instagramData || twitterData
                                                  ? 'This webpage is connected successfully with social media using:'
                                                  : `This webpage is not connected with social media using the API's provided by Twitter or Instagram`}
                                                <br />
                                              </div>
                                            </td>
                                          </tr>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </section>
                            </div>
                          </div>

                          <div className="pdf-page-break" />
                          <div className="pdf-page-space" />
                          <div className="ant-card seo-report-card mt-4 customs-card-border">
                            <div className="ant-card-head">
                              <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">
                                  <span className="seo-report-card-title fw-bolder fs-6">
                                    WEBSITE SITE LOADING SIGNALS
                                  </span>
                                </div>
                              </div>
                              <div className="ant-card-extra">
                                <div className="d-flex gap-4">
                                  <div>
                                    <label>
                                      Score:{' '}
                                      {(
                                        (speedOptimizeSCounter * 100) /
                                        (speedOptimizeFCounter +
                                          speedOptimizeWCounter +
                                          speedOptimizeSCounter)
                                      ).toFixed(0)}
                                    </label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${(
                                                (speedOptimizeSCounter * 100) /
                                                (speedOptimizeFCounter +
                                                  speedOptimizeWCounter +
                                                  speedOptimizeSCounter)
                                              ).toFixed(0)}%`,
                                              height: '8px',
                                              backgroundColor: `${
                                                (
                                                  (speedOptimizeSCounter * 100) /
                                                  (speedOptimizeFCounter +
                                                    speedOptimizeWCounter +
                                                    speedOptimizeSCounter)
                                                ).toFixed(0) > 75
                                                  ? '#4bbf73'
                                                  : (
                                                        (speedOptimizeSCounter * 100) /
                                                        (speedOptimizeFCounter +
                                                          speedOptimizeWCounter +
                                                          speedOptimizeSCounter)
                                                      ).toFixed(0) <= 50
                                                    ? '#d95350'
                                                    : '#e5a54b'
                                              }`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Failed: {speedOptimizeFCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-exception ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${speedOptimizeFCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#d95350',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Warnings: {speedOptimizeWCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default w-100"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${speedOptimizeWCounter}%`,
                                              height: '8px',
                                              backgroundImage:
                                                'linear-gradient(to right, rgb(255, 171, 0) 0%, rgb(255, 171, 0) 5%)',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Passed: {speedOptimizeSCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${speedOptimizeSCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#4bbf73',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ant-card-body p-0">
                              <section className="meta-title-section">
                                <div className="table-list-container">
                                  <table className="list-table">
                                    <tbody>
                                      {/* ******** HTML Page Size Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.htmlPageSize?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="HTML Page Size Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.htmlPageSize?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** DOM Size Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.domSize?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="DOM Size Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.domSize?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Backlinks ********* */}
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Backlinks</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {`This domain has ${
                                              totalBackLinksData()?.domains
                                            } total backlinks from ${
                                              totalBackLinksData()?.backlinks
                                            } referring domains.`}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** HTML Compression/GZIP Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.htmlCompression?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Web Content Compression Check"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.htmlCompression?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      {/* ******** Site Loading Speed Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.siteSpeed?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Site Loading Speed Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.siteSpeed?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** JS Execution Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.bootupTime?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="JS Execution Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.bootupTime?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      {/* ******** Page Objects Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.pageObject?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Page Objects Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.pageObject?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            <p className="po-table-title"> Content size by content type </p>
                                            <table className="w-100 keyword-table table">
                                              <thead className="keyword-table-header common-table">
                                                <th>Content Type</th>
                                                <th>Percent</th>
                                                <th>Size</th>
                                              </thead>
                                              <tbody className="common-table-body">
                                                {mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObject
                                                  ?.length ? (
                                                  mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObject.map(
                                                    (item) => {
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>{item?.contentType}</td>
                                                            <td>{item?.sizePercentage?.toFixed(1)} %</td>
                                                            <td>{sizeDetermination(item?.size)}</td>
                                                          </tr>
                                                        </>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            <p className="po-table-title"> Request by content type </p>
                                            <table className="w-100 keyword-table table">
                                              <thead className="keyword-table-header common-table">
                                                <tr>
                                                  <th>Content Type</th>
                                                  <th>Percent</th>
                                                  <th>Requests</th>
                                                </tr>
                                              </thead>
                                              <tbody className="common-table-body">
                                                {mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObject
                                                  ?.length ? (
                                                  mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObject.map(
                                                    (item) => {
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>{item?.contentType}</td>
                                                            <td>{item?.requestPercentage?.toFixed(1)} %</td>
                                                            <td>{item?.requests} </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            <p className="po-table-title"> Content size by domain </p>
                                            <table className="w-100 keyword-table table">
                                              <thead className="keyword-table-header common-table">
                                                <th>
                                                  <div>Domain</div>
                                                </th>
                                                <th>
                                                  <div>Percent</div>
                                                </th>
                                                <th>
                                                  <div>Size</div>
                                                </th>
                                              </thead>
                                              <tbody className="common-table-body">
                                                {mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObjects
                                                  ?.length ? (
                                                  mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObjects.map(
                                                    (item, index) => {
                                                      if (index >= 10) return
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>
                                                              {item?.url?.length > 30
                                                                ? item?.url?.substring(0, 30).concat('...')
                                                                : item?.url}
                                                            </td>
                                                            <td>{item?.sizePercentage} %</td>
                                                            <td>{sizeDetermination(item.size)}</td>
                                                          </tr>
                                                        </>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="ms-3 ps-3">
                                            <p className="po-table-title"> Request by domain </p>
                                            <table className="w-100 keyword-table table">
                                              <thead className="keyword-table-header common-table">
                                                <th>
                                                  <div>Content Type</div>
                                                </th>
                                                <th>
                                                  <div>Percent</div>
                                                </th>
                                                <th>
                                                  <div>Requests</div>
                                                </th>
                                              </thead>
                                              <tbody className="common-table-body">
                                                {mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObjects
                                                  ?.length ? (
                                                  mainUrlData?.[0]?.pageObject?.data?.finalStatus?.pageObjects.map(
                                                    (item, index) => {
                                                      if (index >= 10) return
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>
                                                              {item?.url?.length > 30
                                                                ? item?.url?.substring(0, 30).concat('...')
                                                                : item?.url}
                                                            </td>
                                                            <td>{item?.countPercentage} %</td>
                                                            <td>{item.count}</td>
                                                          </tr>
                                                        </>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      {/* ******** Page Cache Test (Server Side Caching) Signal Analysis ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.pageCache?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Page Cache Test (Server Side Caching) Signal Analysis"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.pageCache?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.flashTest?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.flashTest?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Flash Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.flashTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.cdnUsageData?.data?.testResult?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.cdnUsageData?.data?.testResult?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>CDN Usage Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.cdnUsageData?.data?.testResult?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr className="">
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** Morden Image Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.mordenImage?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Morden Image Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.mordenImage?.data?.testResults?.message,
                                            )}
                                            <br />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ********  Image Cache Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.imageCache?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="Image Cache Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.imageCache?.data?.testResults?.message,
                                            )}
                                            <div></div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ********  JavaScript Caching Signal Test ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.jsCache?.data?.testResults?.status}
                                        isConsidered={true}
                                        testName="JavaScript Caching Signal Test"
                                      />
                                      <tr>
                                        <td colspan="2">
                                          <div className="ps-3 ms-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.jsCache?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.cssCache?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.cssCache?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>CSS Caching Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.cssCache?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.jsMinification?.data?.testResults?.status ===
                                              1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.jsMinification?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>JavaScript Minification Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.jsMinification?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.cssMinification?.data?.testResults?.status ===
                                              1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.cssMinification?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>CSS Minification Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.cssMinification?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.renderBlocking?.data?.testResults?.status ===
                                              1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.renderBlocking?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Render Blocking Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.renderBlocking?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.nestedTable?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.nestedTable?.data?.testResults?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Nested Tables Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.nestedTable?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.framesetTest?.data?.testResults.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.framesetTest?.data?.testResults.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Frameset Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.framesetTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr className="">
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.docType?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.docType?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Doctype Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.docType?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.redirectTest?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.redirectTest?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Redirect Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.redirectTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.contentfulPaint?.data?.testResults?.status ===
                                              1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.contentfulPaint?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Contentful Paint Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.contentfulPaint?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.layoutShift?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.layoutShift?.data?.testResults?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Cumulative Layout Shift Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.layoutShift?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.cssAnalysis?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.cssAnalysis?.data?.testResults?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>CSS Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.cssAnalysis?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </section>
                            </div>
                          </div>

                          <div className="pdf-page-break" />
                          <div className="pdf-page-space" />
                          <div className="ant-card seo-report-card mt-4 mb-4 customs-card-border">
                            <div className="ant-card-head">
                              <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">
                                  <span className="seo-report-card-title fw-bolder fs-6">
                                    SERVER CONFIGURATION & SECURITY SIGNALS
                                  </span>
                                </div>
                              </div>
                              <div className="ant-card-extra">
                                <div className="d-flex gap-4">
                                  <div>
                                    <label>
                                      Score:{' '}
                                      {(
                                        (serverSCounter * 100) /
                                        (serverFCounter + serverWCounter + serverSCounter)
                                      ).toFixed(0)}
                                    </label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${(
                                                (serverSCounter * 100) /
                                                (serverFCounter + serverWCounter + serverSCounter)
                                              ).toFixed(0)}%`,
                                              height: '8px',
                                              backgroundColor: `${
                                                (
                                                  (serverSCounter * 100) /
                                                  (serverFCounter + serverWCounter + serverSCounter)
                                                ).toFixed(0) > 75
                                                  ? '#4bbf73'
                                                  : (
                                                        (serverSCounter * 100) /
                                                        (serverFCounter + serverWCounter + serverSCounter)
                                                      ).toFixed(0) <= 50
                                                    ? '#d95350'
                                                    : '#e5a54b'
                                              }`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Failed: {serverFCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-exception ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${serverFCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#d95350',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Warning: {serverWCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default w-100"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${serverWCounter}%`,
                                              height: '8px',
                                              backgroundImage:
                                                'linear-gradient(to right, rgb(255, 171, 0) 0%, rgb(255, 171, 0) 5%)',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Success: {serverSCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${serverSCounter}%`,
                                              height: '8px',
                                              // backgroundImage: "linear-gradient(to right, rgb(52, 188, 110) 0%, rgb(52, 188, 110) 39%)"
                                              backgroundColor: '#4bbf73',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ant-card-body p-0">
                              <section className="meta-title-section">
                                <div className="table-list-container">
                                  <table className="list-table">
                                    <tbody>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.urlCanonicalization?.data?.testResult
                                                ?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.urlCanonicalization?.data?.testResult
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>URL Canonicalization Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.urlCanonicalization?.data?.testResult
                                                ?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.httpsTest?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.httpsTest?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Mixed Content Test (HTTP over HTTPS)</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.httpsTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.http2Test?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.http2Test?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>HTTP2 Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.http2Test?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.hstsTest?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.hstsTest?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>HSTS Signal Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.hstsTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.safeBrowsing?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.safeBrowsing?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Safe Browsing Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.safeBrowsing?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.serverSignature?.data?.testResults?.status ===
                                              1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.serverSignature?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Server Signature Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.serverSignature?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.directoryBrowsing?.data?.testResults
                                                ?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.directoryBrowsing?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Directory Browsing Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.directoryBrowsing?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr className="">
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.plainTextEmail?.data?.testResults?.status ===
                                              1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.plainTextEmail?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Plaintext Emails Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.plainTextEmail?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.unsafeCrossOriginLinksTest?.data?.testResults
                                                ?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.unsafeCrossOriginLinksTest?.data
                                                      ?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Unsafe Cross-Origin Signal Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.unsafeCrossOriginLinksTest?.data?.testResults
                                                ?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.sslFreakTest?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.sslFreakTest?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>SSL and HTTPS Signals</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.sslFreakTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                          <div className="ms-3 ps-3">
                                            <div>
                                              {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslhopperData
                                                ?.body?.label4 &&
                                              mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslhopperData
                                                ?.body?.label4?.length ? (
                                                <>
                                                  {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslhopperData?.body?.label4.map(
                                                    (item) => {
                                                      return (
                                                        <div>
                                                          {item.status ? (
                                                            <Icon.Check
                                                              size={16}
                                                              style={{ marginRight: 5 }}
                                                              color="green"
                                                            />
                                                          ) : (
                                                            <Icon.X
                                                              size={16}
                                                              style={{ marginRight: 5 }}
                                                              color="red"
                                                            />
                                                          )}
                                                          <span className="my-0">{item.text}</span>
                                                        </div>
                                                      )
                                                    },
                                                  )}
                                                  <div>
                                                    {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak
                                                      ?.sslFreakData?.length &&
                                                    mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslFreakData.includes(
                                                      'Not',
                                                    ) ? (
                                                      <Icon.Check
                                                        size={16}
                                                        style={{ marginRight: 5 }}
                                                        color="green"
                                                      />
                                                    ) : (
                                                      <Icon.X
                                                        size={16}
                                                        style={{ marginRight: 5 }}
                                                        color="red"
                                                      />
                                                    )}
                                                    {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak
                                                      ?.sslFreakData?.length ? (
                                                      <span>
                                                        &nbsp;
                                                        {`${mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslFreakData.slice(
                                                          5,
                                                        )}`}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <br />
                                              {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslCertData
                                                ?.label3 &&
                                              mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslCertData
                                                ?.label3?.length ? (
                                                <h6>Certificate Chain</h6>
                                              ) : (
                                                <></>
                                              )}
                                              {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslCertData
                                                ?.label3 && (
                                                <ul>
                                                  {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslCertData
                                                    ?.label3 &&
                                                  mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslCertData
                                                    ?.label3?.length ? (
                                                    <>
                                                      {' '}
                                                      {mainUrlData?.[0]?.sslFreakTest?.data?.sslFreak?.sslCertData?.label3.map(
                                                        (item) => {
                                                          return (
                                                            <li
                                                              style={{ marginBottom: 10 }}
                                                              dangerouslySetInnerHTML={{ __html: item }}
                                                            />
                                                          )
                                                        },
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </ul>
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** BROTIL TEST ********* */}
                                      <CommonSEOWhiteLabel
                                        status={mainUrlData?.[0]?.brotilTest?.data?.testResults?.status}
                                        testName={'Brotil Analysis Test'}
                                      />
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.brotilTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      {/* ******** DNS SECURITY TEST ********* */}
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>DNS Security</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ps-3 ms-3 mb-2">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.dnsSecurityTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.tlsLogjamTest?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.tlsLogjamTest?.data?.testResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>TLS Logjam Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.tlsLogjamTest?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </section>
                            </div>
                          </div>

                          <div className="pdf-page-break" />
                          <div className="pdf-page-space" />
                          <div className="ant-card seo-report-card mt-4 mb-4 customs-card-border">
                            <div className="ant-card-head">
                              <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">
                                  <span className="seo-report-card-title fw-bolder fs-6">
                                    MOBILE RESPONSIVE & MOBILE SPEED SIGNALS
                                  </span>
                                </div>
                              </div>
                              <div className="ant-card-extra">
                                <div className="d-flex gap-4">
                                  <div>
                                    <label>
                                      Score:{' '}
                                      {(
                                        (mobileUsabilitySCounter * 100) /
                                        (mobileUsabilityFCounter +
                                          mobileUsabilityWCounter +
                                          mobileUsabilitySCounter)
                                      ).toFixed(0)}
                                    </label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${(
                                                (mobileUsabilitySCounter * 100) /
                                                (mobileUsabilityFCounter +
                                                  mobileUsabilityWCounter +
                                                  mobileUsabilitySCounter)
                                              ).toFixed(0)}%`,
                                              height: '8px',
                                              backgroundColor: `${
                                                (
                                                  (mobileUsabilitySCounter * 100) /
                                                  (mobileUsabilityFCounter +
                                                    mobileUsabilityWCounter +
                                                    mobileUsabilitySCounter)
                                                ).toFixed(0) > 75
                                                  ? '#4bbf73'
                                                  : (
                                                        (mobileUsabilitySCounter * 100) /
                                                        (mobileUsabilityFCounter +
                                                          mobileUsabilityWCounter +
                                                          mobileUsabilitySCounter)
                                                      ).toFixed(0) <= 50
                                                    ? '#d95350'
                                                    : '#e5a54b'
                                              }`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Failed: {mobileUsabilityFCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-exception ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${mobileUsabilityFCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#d95350',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Warning: {mobileUsabilityWCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default w-100"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${mobileUsabilityWCounter}%`,
                                              height: '8px',
                                              backgroundImage:
                                                'linear-gradient(to right, rgb(255, 171, 0) 0%, rgb(255, 171, 0) 5%)',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Success: {mobileUsabilitySCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${mobileUsabilitySCounter}%`,
                                              height: '8px',
                                              // backgroundImage: "linear-gradient(to right, rgb(52, 188, 110) 0%, rgb(52, 188, 110) 39%)"
                                              backgroundColor: '#4bbf73',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ant-card-body p-0">
                              <section className="meta-title-section">
                                <div className="table-list-container">
                                  <table className="list-table">
                                    <tbody>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.viewport?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.viewport?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Viewport Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.viewport?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.mediaQuery?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.mediaQuery?.data?.testResults?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>MediaQuery Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.mediaQuery?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Mobile Screenshot Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {mainUrlData?.[0]?.mobileSnapShotTest?.data?.length
                                              ? 'Great ! We found snapshot of your webpage'
                                              : 'Ops! we are not able to find the snapshot of your webpage'}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {mainUrlData?.map((item) =>
                                              item ? (
                                                <img
                                                  width="25%"
                                                  alt="mobile snapshot"
                                                  src={item?.mobileSnapShotTest?.data}
                                                />
                                              ) : (
                                                <></>
                                              ),
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Desktop Screenshot Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {mainUrlData?.[0]?.desktopSnapShotTest?.data?.length
                                              ? 'Great ! We found snapshot of your webpage'
                                              : 'Ops! we are not able to find the snapshot of your webpage'}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {mainUrlData?.map((item) =>
                                              item ? (
                                                <img
                                                  width="25%"
                                                  alt="mobile snapshot"
                                                  src={item?.desktopSnapShotTest?.data}
                                                />
                                              ) : (
                                                <></>
                                              ),
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </section>
                            </div>
                          </div>

                          <div className="pdf-page-break" />
                          <div className="pdf-page-space" />
                          <div className="ant-card seo-report-card mt-4 mb-4 customs-card-border">
                            <div className="ant-card-head">
                              <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">
                                  <span className="seo-report-card-title fw-bolder fs-6">
                                    Advanced SEO Signal Test
                                  </span>
                                </div>
                              </div>
                              <div className="ant-card-extra">
                                <div className="d-flex gap-4">
                                  <div>
                                    <label>
                                      Score:{' '}
                                      {(
                                        (advanceSeoSCounter * 100) /
                                        (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                      ).toFixed(0)}
                                    </label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${(
                                                (advanceSeoSCounter * 100) /
                                                (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                              ).toFixed(0)}%`,
                                              height: '8px',
                                              backgroundColor: `${
                                                (
                                                  (advanceSeoSCounter * 100) /
                                                  (advanceSeoFCounter +
                                                    advanceSeoWCounter +
                                                    advanceSeoSCounter)
                                                ).toFixed(0) > 75
                                                  ? '#4bbf73'
                                                  : (
                                                        (advanceSeoSCounter * 100) /
                                                        (advanceSeoFCounter +
                                                          advanceSeoWCounter +
                                                          advanceSeoSCounter)
                                                      ).toFixed(0) <= 50
                                                    ? '#d95350'
                                                    : '#e5a54b'
                                              }`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Failed: {advanceSeoFCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-exception ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${advanceSeoFCounter}%`,
                                              height: '8px',
                                              backgroundColor: '#d95350',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Warning: {advanceSeoWCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default w-100"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${advanceSeoWCounter}%`,
                                              height: '8px',
                                              backgroundImage:
                                                'linear-gradient(to right, rgb(255, 171, 0) 0%, rgb(255, 171, 0) 5%)',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label>Success: {advanceSeoSCounter}</label>
                                    <div
                                      className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"
                                      role="progressbar"
                                    >
                                      <div className="ant-progress-outer">
                                        <div className="ant-progress-inner">
                                          <div
                                            className="ant-progress-bg"
                                            style={{
                                              width: `${advanceSeoSCounter}%`,
                                              height: '8px',
                                              // backgroundImage: "linear-gradient(to right, rgb(52, 188, 110) 0%, rgb(52, 188, 110) 39%)"
                                              backgroundColor: '#4bbf73',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ant-card-body p-0">
                              <section className="meta-title-section">
                                <div className="table-list-container">
                                  <table className="list-table">
                                    <tbody>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.microDataSchema?.data?.microSchemaResults
                                                ?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.microDataSchema?.data?.microSchemaResults
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Microdata Schema Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.microDataSchema?.data?.microSchemaResults
                                                ?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.custom404?.data?.testResult?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.custom404?.data?.testResult?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Custom 404 Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.custom404?.data?.testResult?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.noIndexChecker?.data?.testResult?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.noIndexChecker?.data?.testResult
                                                      ?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>No Index Checker Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.noIndexChecker?.data?.testResult?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.canonicalUrl?.data?.testResult?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.canonicalUrl?.data?.testResult?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Canonical URL Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.canonicalUrl?.data?.testResult?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      {/* Start No Follow Signal Test */}
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td className="meta-title">
                                          <h6>No Follow Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.noFollow?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      {/* End No Follow Signal Test */}

                                      {/* Start Disallow Directive Signal Test */}
                                      <tr>
                                        <th scope="row" className="status-icon">
                                          <img
                                            src={images.statusIcon}
                                            width="24"
                                            height="24"
                                            alt="status-mark-icon"
                                          />
                                        </th>
                                        <td className="meta-title">
                                          <h6>Disallow Directive Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.disallowDirectiveChecker?.data?.finalRes
                                                ?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                      {/* End Disallow Directive Signal Test */}

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.metaRefresh?.data?.testResult?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.metaRefresh?.data?.testResult?.status ===
                                                    2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Meta Refresh Signal Test</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.metaRefresh?.data?.testResult?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2 pdf-page-break" />
                                          <div className="pdf-page-space" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.spfRecord?.data?.testResult?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.spfRecord?.data?.testResult?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>SPF Record Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.spfRecord?.data?.testResult?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="status-icon">
                                          <img
                                            src={
                                              mainUrlData?.[0]?.adsTxt?.data?.testResults?.status === 1
                                                ? images.tickIcon
                                                : mainUrlData?.[0]?.adsTxt?.data?.testResults?.status === 2
                                                  ? 'https://tools1.dev-positionmysite.com/warning.png'
                                                  : images.crossIcon
                                            }
                                            width="24"
                                            height="24"
                                            alt="tick-mark-icon"
                                          />
                                        </td>
                                        <td className="meta-title">
                                          <h6>Ads.txt Analysis</h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="pb-2" colspan="2">
                                          <div className="ms-3 ps-3">
                                            {shortMessageForWhiteLabel(
                                              mainUrlData?.[0]?.adsTxt?.data?.testResults?.message,
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2">
                                          <div className="border-bottom mb-2" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                        <Divider className="m-0" />
                        <div className="template-footer">
                          <span className="header-title">
                            {selectedTemplate && selectedTemplate?.length
                              ? selectedTemplate?.[0]?.footer
                              : 'Sample text in the footer'}
                          </span>
                        </div>
                      </section>
                    </>
                  ) : (
                    <Card className="right-side-section">
                      <div className="header-container d-flex align-items-center">
                        <div className="template-logo">
                          {selectedTemplate && selectedTemplate?.[0]?.logo ? (
                            <img
                              src={selectedTemplate?.[0]?.logo}
                              alt="company-logo"
                              width={100}
                              height={100}
                              className="company-logo-preview"
                            />
                          ) : (
                            <PictureOutlined className="picture-icon" />
                          )}
                        </div>
                        <Divider type="vertical" className="vertical-line" />
                        <div className="template-header">
                          <span className="header-title">
                            {selectedTemplate && selectedTemplate?.[0]?.header
                              ? selectedTemplate?.[0]?.header
                              : 'Sample text in the header'}
                          </span>
                        </div>
                      </div>
                      <Card
                        bordered
                        className="template-seo-report"
                        title={
                          <>
                            <span className="title">https://your-url-goes-here.com </span>
                            <p className="description mb-0">SEO Report</p>
                          </>
                        }
                      >
                        <div className="summary-graph">
                          <div className="summary-graph-wrapper">
                            <div className="summary-graph-total">
                              <Progress
                                type="circle"
                                className=""
                                percent={66}
                                format={(percent) => `${percent} / 100`}
                                strokeColor={{ '66%': '#3f81ea' }}
                              />
                              <span className="progress-label">SEO Score</span>
                            </div>
                          </div>
                          <div className="summary-graph-split">
                            <div className="progress-failed mb-2">
                              <label>12 Failed</label>
                              <Progress type="line" status="exception" percent={12} showInfo={false} />
                            </div>
                            <div className="progress-warning mb-2">
                              <label>0 Warnings</label>
                              <Progress type="line" showInfo={false} />
                            </div>
                            <div className="progress-passed">
                              <label>39 Passed</label>
                              <Progress
                                type="line"
                                showInfo={false}
                                percent={39}
                                strokeColor={{
                                  '0%': '#34bc6e',
                                  '39%': '#34bc6e',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Card
                        title="Common SEO issues"
                        className="mt-4 mb-4 common-seo-issue-card"
                        bordered
                        extra={
                          <div className="d-flex gap-4">
                            <div>
                              <label>Score:</label>
                              <Progress percent={0} showInfo={false} />
                            </div>
                            <div>
                              <label>Failed: 2</label>
                              <Progress status="exception" percent={2} showInfo={false} />
                            </div>
                            <div>
                              <label>Warnings: 5</label>
                              <Progress
                                className="w-100"
                                percent={5}
                                showInfo={false}
                                strokeColor={{
                                  '0%': ' #ffab00',
                                  '5%': ' #ffab00',
                                }}
                              />
                            </div>
                            <div>
                              <label>Passed: 13</label>
                              <Progress
                                percent={13}
                                showInfo={false}
                                strokeColor={{
                                  '0%': '#34bc6e',
                                  '39%': '#34bc6e',
                                }}
                              />
                            </div>
                          </div>
                        }
                      >
                        <Row justify="space-around" align="middle">
                          <Col span={10} className="d-flex align-items-center gap-2">
                            <CheckCircleFilled className="passed status-icon" />
                            <Skeleton
                              size="small"
                              block="true"
                              shape="round"
                              className="icon-skeleton"
                              title={true}
                              paragraph={false}
                            />
                          </Col>
                          <Col span={14}>
                            <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                          </Col>
                        </Row>
                        <Divider className="mt-0 mb-0" />
                        <Row>
                          <Col span={10} className="d-flex align-items-center gap-2">
                            <CloseCircleFilled className="failed status-icon" />
                            <Skeleton
                              size="small"
                              block="true"
                              shape="round"
                              className="icon-skeleton"
                              title={true}
                              paragraph={false}
                            />
                          </Col>
                          <Col span={14}>
                            <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                          </Col>
                        </Row>
                        <Divider className="mt-0 mb-0" />
                        <Row>
                          <Col span={10} className="d-flex align-items-center gap-2">
                            <ExclamationCircleFilled className="warning status-icon" />
                            <Skeleton
                              size="small"
                              block="true"
                              shape="round"
                              className="icon-skeleton"
                              title={true}
                              paragraph={false}
                            />
                          </Col>
                          <Col span={14}>
                            <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                          </Col>
                        </Row>
                        <Divider className="mt-0 mb-0" />
                        <Row>
                          <Col span={10} className="d-flex align-items-center gap-2">
                            <div className="status-icon default-icon" />
                            <Skeleton
                              size="small"
                              block="true"
                              shape="round"
                              className="icon-skeleton"
                              title={true}
                              paragraph={false}
                            />
                          </Col>
                          <Col span={14}>
                            <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                          </Col>
                        </Row>
                      </Card>
                      <Divider className="mt-0 mb-0" />
                      <div className="template-footer">
                        <span className="title">
                          {selectedTemplate && selectedTemplate?.[0]?.footer
                            ? selectedTemplate?.[0]?.footer
                            : 'Sample text in the footer'}
                        </span>
                      </div>
                    </Card>
                  )}
                </div>
              </>
            )}
          </>
        </Col>
      </Row>
    </Card>
  )
}

import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { CommonAlert } from 'components/uiCore'
import { Link } from 'react-router-dom'

const Custom404 = ({ data }) => {
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.custom404?.data?.testResult?.status ? (
            ''
          ) : data?.custom404?.data?.testResult?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.custom404?.data?.testResult?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : data?.custom404?.data?.testResult?.status === 4 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />
        {data?.custom404?.data?.testResult?.status === 4 ? (
          <CommonAlert
            variant={'warning'}
            key={'meta-title'}
            alertText={
              <div>
                <span>{data?.unsafeCrossOriginLinksTest?.data?.testResults?.cachedMessage}</span>
                <Link to="/pages/firewall-options" style={{ textDecoration: 'underline' }} target="_blank">
                  click here
                </Link>
              </div>
            }
          />
        ) : (
          <></>
        )}
        <div>
          {' '}
          <p>{data?.custom404?.data?.testResult?.message}</p>{' '}
        </div>
      </div>
    </>
  )
}

export default Custom404

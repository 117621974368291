import { Line } from '@ant-design/charts'

import { useSelector } from 'react-redux'
import * as moment from 'moment'
import * as numeral from 'numeral'
import * as _ from 'lodash'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as Icon from 'react-feather'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

const BacklinksCommonCharts = ({ label, elementName, chartName, tooltip }) => {

  const chartType = elementName == 'rank'

  const { backlinkGraphData, activeLink } = useSelector(({ dashboard }) => dashboard)

  let activeDomainInformation =
    backlinkGraphData &&
    activeLink !== undefined &&
    backlinkGraphData[activeLink] &&
    backlinkGraphData[activeLink].statsData
  const labels = []
  let result = []
  let datasetsTemp = [
    {
      label: label,
      fill: true,
      backgroundColor: 'transparent',
      borderColor: '#3b5998',
      data: [],
    },
  ]

  if (activeDomainInformation && activeDomainInformation?.length) {
    activeDomainInformation = _.sortBy(activeDomainInformation, ['createdAt'])
  }

  activeDomainInformation &&
    activeDomainInformation.length &&
    activeDomainInformation.forEach((element, index) => {
        if (element) {
          labels.push(moment(`${element.createdAt}`).format('DD-MMM'))
          datasetsTemp[0].data.push(parseInt(element[elementName]))
          result.push({
            date1: moment(element.createdAt).format('DD-MMM'),
            value: chartType
              ? element[elementName] / 10
              : parseInt(element[elementName]?.toString().replace(/\,/g, '')),
          })
        }
    })

  const getScoreDifference = () => {
    if (datasetsTemp[0].data.length) {
      if (datasetsTemp[0].data.length === 1) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentValue = datasetsTemp[0].data[datasetsTemp[0].data.length - 1]
        let previousValue = datasetsTemp[0].data[datasetsTemp[0].data.length - 2]
        if (currentValue - previousValue === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentValue - previousValue > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    } else {
      return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
    }
  }

  const config = {
    data: result,
    width: 150,
    height: 150,
    autoFit: true,
    animation: false,
    xField: 'date1',
    yField: 'value',
    yAxis: {
      label: {
        formatter: (datum) => {
          return numeral(datum).format('0,0')
        },
      },
    },
    tooltip: {
      title: (datum) => {
        return moment(datum, 'DD MMM').format('DD MMM')
      },
      formatter: (datum) => {
        return {
          name: chartName,
          value: chartType ? numeral(datum.value).format('0,0.0') : numeral(datum.value).format('0,0'),
        }
      },
      text: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    smooth: true,
    legend: { marker: { symbol: 'line' } },
    point: {
      size: 5,
      shape: 'circle',
    }, 
    yAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    
  }
  // eslint-disable-next-line no-unused-vars
  let chart
  return (
    <>
      <h5 className='f-bold'>
        {chartName} &nbsp;&nbsp;
        <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {tooltip}</Tooltip>}>
          <Icon.AlertCircle size="16" color="#afafaf" />
        </OverlayTrigger>{' '}
      </h5>
      {getScoreDifference()}&nbsp;&nbsp;{' '}
      <span>
        {activeDomainInformation ? (
          chartType ? (
            <> {activeDomainInformation[activeDomainInformation?.length - 1]?.[elementName] / 10} / 100 </>
          ) : (
            <> {activeDomainInformation[activeDomainInformation?.length - 1]?.[elementName]} </>
          )
        ) : (
          '0'
        )}
      </span>{' '}
      <hr />
      <br />
      <div className="chart  backlinks-page-chart">
        <Line {...config} onReady={(chartInstance) => (chart = chartInstance)} />
      </div>
    </>
  )
}

export default BacklinksCommonCharts

import AddSitemapsMain from 'modules/welcomeBoard/components/addSitemap/AddSitemapsMain.jsx'

const AddSitemapsTrial = () => {

  return (
    <>
      <AddSitemapsMain callFrom="trial" />
    </>
  )
}

export default AddSitemapsTrial

import {
  GET_URL_DETAILS,
  GET_URL_DETAILS_SUCCESS,
  GET_URL_DETAILS_FAILURE,
  STORE_PREVIOUS_PAGE_DATA,
  TOGGLE_CHECKBOX,
  CHECK_URL,
  CHECK_URL_SUCCESS,
  CHECK_URL_FAILURE,
  CHECK_URL_CLEAR,
  TOGGLE_KEYWORD_CHECKBOX,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  ADD_USER_CLEAR,
  ADD_WEBSITE,
  STORE_RANDOM_NUMBER,
  WEBSITE_SUCCESS,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_START,
  PROCESS_PAYMENT_FAIL,
  START_SUBSCRIPTION,
  START_SUBSCRIPTION_SUCCESS,
  START_SUBSCRIPTION_FAILURE,
  START_SUBSCRIPTION_CLEAR,
  ADD_WEBSITE_TO_USER,
  CLEAR_URL_DETAILS,
  CLEAR_KEYWORDS_DETAILS,
  CLEAR_COMPETITORS_DETAILS,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  CLEAR_URL_DETAILS_2,
  VALIDATE_URL_DETAILS_SUCCESS,
  VALIDATE_URL_DETAILS_FAILURE,
  CLEAR_VALIDATE_URL_DETAILS,
  VALIDATE_URL_ABOUT_TO_HAPPEN,
  CLEAR_URL_DETAILS_3,
  TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT,
  ABOUT_TO_TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT,
  GET_SITEMAP,
  GET_SITEMAP_SUCCESS,
  GET_SITEMAP_FAILURE,
  SITEMAP_DATA_TO_SENT,
  GET_LIGHTHOUSE,
  GET_LIGHTHOUSE_SUCCESS,
  GET_LIGHTHOUSE_FAILURE,
  GET_LIGHTHOUSE_CLEAR,
  SET_BACKLINKS_DATA,
  IS_START_SCAN,
  MODAL_OPEN_LIGHTHOUSE,
  REMOVE_COMPETITORS_SUCCESS_2,
  IS_WEBSITE_DATA,
  STOP_ADD_WEBSITE_LOADER,
  GET_LIGHTHOUSE_STATUS_SUCCESS,
  GET_LIGHTHOUSE_STATUS_FAILURE,
  CLEAR_GET_LIGHTHOUSE_STATUS,
  GET_BILLING_INFORMATION,
  GET_BILLING_INFORMATION_SUCCESS,
  GET_BILLING_INFORMATION_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CLEAR_CANCEL_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION_SUCCESS,
  UPGRADE_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION_FAILURE,
  CLEAR_UPGRADE_SUBSCRIPTION,
  IS_CALL_FOR,
  CLEAR_TOGGLE_CHECKBOX,
  DISCARD_TRIAL_PERIOD,
  DISCARD_TRIAL_PERIOD_SUCCESS,
  DISCARD_TRIAL_PERIOD_FAILURE,
  CLEAR_DISCARD_TRIAL_PERIOD,
  STRIPE_CHECKOUT,
  STRIPE_CHECKOUT_SUCCESS,
  STRIPE_CHECKOUT_FAILURE,
  CLEAR_STRIPE_CHECKOUT,
  SAVE_SUBSCRIPTION,
  CLEAR_SAVE_SUBSCRIPTION,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  CLEAR_ADD_PAYMENT_METHOD,
  TRIAL_SEO_CHECKUP,
  TRIAL_SEO_CHECKUP_SUCCESS,
  TRIAL_SEO_CHECKUP_FAILURE,
  GET_TRIAL_SEO_REPORT,
  GET_TRIAL_SEO_REPORT_SUCCESS,
  GET_TRIAL_SEO_REPORT_FAILURE,
  CLEAR_TRIAL_SEO_CHECKUP,
  OPEN_REGISTER_MODAL,
  CLEAR_GET_TRIAL_SEO_REPORT,
  GET_PUBLIC_KEY_SUCCESS,
  GET_PUBLIC_KEY_FAILURE,
  CLEAR_GET_PUBLIC_KEY,
  GET_PUBLIC_KEY
} from '../../actionType'
import { getRandom10Int } from '../../../constants/Utils'

import * as _ from 'lodash'

const InitialState = {
  freeTrialSuccess: false,
  freeTrialError: false,
  linkArray: [],
  competitorManualList: [],
  competitorListArray: [],
  keywordsListArray: [],
  keywordsString: [],
  prevPageData: {},
  keywords: [],
  competitorList: [],
  competitorSuccess: false,
  topKeywordSuccess: false,
  loading: false,
  payLoader: false,
  paymentStart: false,
  paymentSuccess: false,
  paymentError: false,
  payPalLoading: false,
  SubscriptionErrorMessage: '',
  SubscriptionPaymentError: false,
  checkUrlError: false,
  checkUrlErrorMessage: '',
  quickSEOUrl: '',
  validateURLSuccess: false,
  validateURLFailure: false,
  validateURL: {},
  validateURLLoading: false,
  step1Done: false,
  sitemaps: [],
  sitemapLoader: false,
  sitemapDataToSent: [],
  lightHouseData: {},
  isLightHouseLoading: false,
  storedBacklinksData: {},
  isScanStarted: false,
  isModalOpenLighthouse: false,
  addWebsiteDataForPopup: {},
  isWebsiteData: false,
  addWebsiteloader: false,
  lightHouseStatusData: {},
  lightHouseStatusError: {},
  billingInformation: {},
  billingInformationLoader: false,
  cancelSubscriptionError: '',
  cancelSubscriptionLoader: false,
  cancelSubscriptionMessage:'',
  upgradeSubscriptionLoader: false,
  upgradeSubscriptionMessage: '',
  upgradeSubscriptionError:'',
  isCallFromAddWebsite:"",
  competitorUpdated: false,
  stripeLoader: false,
  stripeSuccess: false,
  stripeFailure: false,
  stripeError: {},
  redirectUrlForStripe: '',
  isSubscriptionSaved: false,
  choosenPlan: {},
  addPaymentMethodLoader: false,
  addPaymentMethodSuccess: false,
  addPaymentMethodError: {},
  addPaymentMethodFailure: false,
  redirectUrlForPaymentMethod: '',
  quickSeoId: '',
  showRegisterModal: false
}
const freeTrialReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_URL_DETAILS:
      const { payload } = action
      let url
      if (action.payload?.action === 'getUrlDetails') {
        if (action?.payload?.url.includes('http://') || action?.payload?.url.includes('https://')) {
          url = action?.payload?.url
        } else {
          url = `https://${action?.payload?.url}`
        }
        return {
          ...state,
          freeTrialSuccess: false,
          validateURLSuccess: false,
          validateURLFailure: false,
          competitorSuccess: state.competitorSuccess,
          freeTrialError: false,
          currentAction: payload.action,
          prevPageData: {
            ...state.prevPageData,
            url: action.payload.selectedUrl ? action.payload.selectedUrl : url,
            selectedUrl: null,
          },
          loading: true,
          keywordsListArray:
            action.payload.action === 'getTopKeyword' ? action?.payload?.keywords : state.keywordsListArray,
          keywordsString:
            action?.payload?.action === 'getTopKeyword'
              ? action?.payload?.keywords.join(',')
              : state?.keywordsString,
        }
      } else {
        return {
          ...state,
          freeTrialSuccess: false,
          validateURLSuccess: false,
          validateURLFailure: false,
          competitorSuccess: state.competitorSuccess,
          freeTrialError: false,
          currentAction: payload.action,
          loading: true,
          keywordsListArray:
            action?.payload?.action === 'getTopKeyword'
              ? action?.payload?.keywords
              : state?.keywordsListArray,
          keywordsString:
            action?.payload?.action === 'getTopKeyword'
              ? action?.payload?.keywords.join(',')
              : state?.keywordsString,
        }
      }

    case STORE_RANDOM_NUMBER:
      return {
        ...state,
        randomNumber: getRandom10Int(),
      }

    case GET_URL_DETAILS_SUCCESS: {
      let urlUpdatedList = []
      let updatedList = []
      let statusOkArray = []
      let stopWebScan = true
      // let url = '';
      if (state.currentAction === 'getUrlDetails') {
        statusOkArray = action.payload.filter((item) => item.http_code === 200 || item.http_code === "200 + META REFRESH")

        if (statusOkArray.length === 1) {
          urlUpdatedList = action?.payload?.map((item) => {
            if (item.http_code === 200 || item.http_code === "200 + META REFRESH") {
              item.checked = true
              // url = item.url
              return { ...item }
            } else {
              item.checked = false
              return { ...item }
            }
          })
        } else {
          urlUpdatedList = action?.payload?.map((item) => {
            return { ...item, checked: false }
          })
        }
      } else {
        urlUpdatedList = action.payload
      }

      if (action?.payload?.status === 1 && state?.currentAction === 'getTopKeyword') {
        updatedList =
          action?.payload &&
          action?.payload?.data &&
          action?.payload?.data?.length &&
          action?.payload?.data?.map((item) => {
            return { competitor: item, checked: false }
          })
      } else {
        updatedList = action.payload
      }

      let keywordList = []
      if (
        state.currentAction === 'getTopKeywords' &&
        action.payload.status === 1 &&
        state.competitorList &&
        state.competitorList.length === 0
      ) {
        keywordList = action?.payload?.data?.map((item) => {
          return {
            item: item,
            checked: false,
          }
        })
      }
      if (state.currentAction === 'getKeywords' && action.payload.status === 1) {
        keywordList = action?.payload?.data?.map((item) => {
          return {
            item: item,
            checked: false,
          }
        })
      }

      if (state?.startWebScan) {
        stopWebScan = false
      }
      return {
        ...state,
        currentAction: state.currentAction === 'getCompetitors' ? '' : state.currentAction,
        freeTrialSuccess: state.currentAction === 'getUrlDetails' ? true : state.freeTrialSuccess,
        competitorSuccess: state.currentAction === 'getCompetitors' ? true : state.competitorSuccess,
        topKeywords: state.currentAction === 'getTopKeywords' ? true : state.topKeywords,
        topKeywordSuccess: state.currentAction === 'getTopKeywords' ? true : state.topKeywordSuccess,
        // keywords: state.currentAction === 'getTopKeywords' ? keywordList : state.keywords,
        keywords: state?.currentAction === 'getKeywords' ? keywordList : state?.keywords,
        freeTrialError: false,
        initiateWebScan: !stopWebScan,
        loading: false,
        //prevPageData: { url: url && url.length ? url : state.prevPageData.url },
        linkArray: state.currentAction === 'getUrlDetails' ? urlUpdatedList : state.linkArray,
        competitorList: state.currentAction === 'getTopKeyword' ? updatedList : state.competitorList,
      }
    }

    case TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT: {
      return {
        ...state,
        startWebScan: true,
      }
    }

    case ABOUT_TO_TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT: {
      return {
        ...state,
        startWebScan: false,
      }
    }

    case GET_URL_DETAILS_FAILURE:
      return {
        ...state,
        freeTrialSuccess: false,
        freeTrialError: true,
        linkArray: [],
        loading: false,
      }

    case CLEAR_URL_DETAILS:
      return {
        ...state,
        linkArray: [],
        competitorListArray: [],
        keywordsListArray: [],
        keywordsString: '',
        regions: [],
        competitorList: [],
        keywords: [],
        prevPageData: {}
      }
    case CLEAR_URL_DETAILS_2:
      return {
        ...state,
        competitorListArray: [],
        competitorList: [],
        keywordsListArray: [],
        keywordsString: '',
      }
    case CLEAR_URL_DETAILS_3:
      return {
        ...state,
        competitorList: [],
        keywords: [],
      }
    case CLEAR_VALIDATE_URL_DETAILS:
      return {
        ...state,
        validateURLLoading: false,
        step1Done: true,
      }

    case VALIDATE_URL_DETAILS_SUCCESS: {
      state.validateURLSuccess = action?.payload
      return {
        ...state,
        validateURLLoading: false,
        validateURLSuccess: true,
        validateURLFailure: false,
        validateURL: action?.payload,
      }
    }
    case VALIDATE_URL_DETAILS_FAILURE: {
      return {
        ...state,
        validateURLLoading: false,
        validateURLFailure: true,
        validateURLSuccess: false,
        validateURL: {},
      }
    }
    case VALIDATE_URL_ABOUT_TO_HAPPEN:
      return {
        ...state,
        validateURLSuccess: false,
        step1Done: false,
        validateURL: {},
      }

    case CLEAR_KEYWORDS_DETAILS: {
      let updatedList =
        state.keywords &&
        state.keywords.length &&
        state.keywords.map((item) => {
          return { ...item, checked: false }
        })
      return {
        ...state,
        keywordsListArray: [],
        keywordsString: [],
        keywords: updatedList,
      }
    }
    case CLEAR_COMPETITORS_DETAILS: {
      return {
        ...state,
      }
    }
    case STORE_PREVIOUS_PAGE_DATA:
      let updatedData = state.linkArray.map((item) => {
        return { ...item, checked: item.url === action.payload.url }
      })
      return {
        ...state,
        linkArray: updatedData,
        prevPageData: { ...action.payload },
      }

    case TOGGLE_CHECKBOX: {
      let { competitorListArray } = state
     
      const filteredCompetitors = state?.competitorList?.filter(item => item?.competitor === action?.payload?.competitor)

      const checkForCompetitor = state?.competitorListArray.filter(item => item === action?.payload?.competitor)

      let updatedList
      if(filteredCompetitors?.length){
         updatedList = state.competitorList.map((item) => {
          if (item.competitor === action.payload.competitor) {
            if (item.checked) {
              let index = competitorListArray.findIndex((item) => action.payload.competitor === item)
              if (index > -1) {
                competitorListArray.splice(index, 1)
              }
            } else {
              competitorListArray.push(item.competitor)
            }
          }
          return {
            ...item,
            checked: item.competitor === action.payload.competitor ? !item.checked : item.checked,
          }
        })
      }else if(checkForCompetitor?.length){
        const index = competitorListArray.findIndex((item) => checkForCompetitor[0] === item)
        if(index > -1){
          competitorListArray.splice(index, 1)
        }
      }else{
        competitorListArray.push(action?.payload?.competitor)
      }
      let tempCompetitorListArray = _.uniq(competitorListArray)
      return {
        ...state,
        competitorUpdated: true,
        competitorList: updatedList?.length ? updatedList : state.competitorList ,
        competitorListArray: tempCompetitorListArray,
      }
    }

    case CLEAR_TOGGLE_CHECKBOX: {
      return {
        ...state,
        competitorUpdated: false
      }
    }

    case CHECK_URL:
      return {
        ...state,
        loading: true,
        checkUrlError: false,
        checkUrlErrorMessage: '',
      }
    case CHECK_URL_SUCCESS:
      const { competitorManualList: list } = state

      let tempArray = []
      if (action.payload.status && list.indexOf(action.payload.url) === -1) {
        tempArray.push(action.payload.url)
      }
      const listData = list.concat(tempArray)

      return {
        ...state,
        loading: false,
        competitorManualList: listData,
        checkUrlError: false,
        checkUrlErrorMessage: '',
      }
    case CHECK_URL_FAILURE:
      return {
        ...state,
        loading: false,
        checkUrlError: true,
        checkUrlErrorMessage: action.e,
      }

    case CHECK_URL_CLEAR:
      return {
        ...state,
        loading: false,
        checkUrlError: false,
      }
    case TOGGLE_KEYWORD_CHECKBOX: {
      let { keywordsListArray } = state
      const keywordsUpdatedList = state.keywords?.map((item) => {
        if (item.item === action.payload) {
          if (item.checked) {
            let index = keywordsListArray.findIndex((item) => action.payload === item)
            if (index > -1) {
              keywordsListArray.splice(index, 1)
            }
          } else {
            keywordsListArray.push(item.item)
          }
        }
        return {
          ...item,
          checked: item.item === action.payload ? !item.checked : item.checked,
        }
      })
      return {
        ...state,
        keywords: keywordsUpdatedList,
        keywordsListArray: keywordsListArray,
        keywordsString: keywordsListArray.join(','),
      }
    }
    case SITEMAP_DATA_TO_SENT: {
      return {
        ...state,
        sitemapDataToSent: action.payload,
      }
    }

    case ADD_USER:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      }
    case STOP_ADD_WEBSITE_LOADER:
      return {
        ...state,
        addWebsiteloader: false
      }  
    case ADD_WEBSITE:
      return {
        ...state,
        loading: true,
        addWebsiteloader: true,
      }
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        analyticsData: action.payload.analytics || {},
      }
    case ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        addWebsiteDataForPopup: action?.payload,
      }

    case REMOVE_COMPETITORS_SUCCESS_2: {
      return {
        ...state,
        addWebsiteDataForPopup: {},
        removeCompetitorsLoader: false,
      }
    }

    case PROCESS_PAYMENT_START:
      return {
        ...state,
        payPalLoading: true,
        paymentSuccess: false,
      }
    case PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        payPalLoading: false,
        paymentSuccess: true,
      }
    case PROCESS_PAYMENT_FAIL:
      return {
        ...state,
        payPalLoading: false,
        paymentSuccess: true,
      }

    case WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        websiteSuccess: true,
        websiteData: action.payload.websiteData,
        email: action.payload.email,
        token: action.payload.token,
        authCode: action.payload.authCode,
      }
    case ADD_USER_CLEAR:
      return {
        ...state,
        addUserSuccess: false,
        startWebScan: false,
      }
    case START_SUBSCRIPTION:
      return {
        ...state,
        payLoader: true,
        paymentStart: true,
        paymentSuccess: false,
        SubscriptionPaymentSuccess: false,
        SubscriptionPaymentError: false,
      }
    case START_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        payLoader: false,
        paymentStart: false,
        SubscriptionPaymentSuccess: true,
        SubscriptionPaymentError: false,
        paymentSuccess: true,
      }
    case START_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        payLoader: false,
        paymentStart: false,
        SubscriptionPaymentError: true,
        SubscriptionErrorMessage: action.e.response.data.message,
      }
    case START_SUBSCRIPTION_CLEAR:
      return {
        ...state,
        paymentStart: false,
        payLoader: false,
        SubscriptionPaymentSuccess: false,
        SubscriptionPaymentError: false,
      }
    case ADD_WEBSITE_TO_USER: {
      return {
        ...state,
      }
    }

    case GET_COUNTRIES:
      return {
        ...state,
      }
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      }
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
      }

    case GET_SITEMAP:
      return {
        ...state,
        sitemaps: [],
        sitemapLoader: true,
      }
    case GET_SITEMAP_SUCCESS:
      return {
        ...state,
        sitemaps: action.payload,
        sitemapLoader: false,
      }
    case GET_SITEMAP_FAILURE:
      return {
        ...state,
        sitemapLoader: false,
      }

    case GET_LIGHTHOUSE:
      return {
        ...state,
        isLightHouseLoading: true,
      }
    case GET_LIGHTHOUSE_SUCCESS:
      return {
        ...state,
        lightHouseData: action.payload,
        isLightHouseLoading: false,
      }
    case GET_LIGHTHOUSE_FAILURE:
      return {
        ...state,
        lightHouseData: {},
        lightHouseError: action.e,
        isLightHouseLoading: false,
      }
    case GET_LIGHTHOUSE_STATUS_SUCCESS: 
      return {
        ...state,
        lightHouseStatusData: action?.payload,
      } 
    case GET_LIGHTHOUSE_STATUS_FAILURE:
      return {
        ...state,
        lightHouseStatusError: action?.e,
        lightHouseStatusData: {}
      }
    case CLEAR_GET_LIGHTHOUSE_STATUS: 
      return {
        ...state,
        lightHouseStatusData: {},
        lightHouseStatusError: {}
      }  
    case GET_LIGHTHOUSE_CLEAR:
      return {
        ...state,
        lightHouseData: {},
        isLightHouseLoading: false,
      }

    case GET_LOCATIONS:
      return {
        ...state,
      }
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
      }
    case GET_LOCATIONS_FAILURE:
      return {
        ...state,
      }

    case SET_BACKLINKS_DATA:
      return {
        ...state,
        storedBacklinksData: action.payload,
      }
    case IS_START_SCAN:
      return {
        ...state,
        isScanStarted: action.payload,
      }
    case IS_WEBSITE_DATA:
      return {
        ...state,
        isWebsiteData: action.payload,
      }
    case MODAL_OPEN_LIGHTHOUSE:
      return {
        ...state,
        isModalOpenLighthouse: action.payload,
      }

      case GET_BILLING_INFORMATION:{
        return{
          ...state,
          billingInformationLoader: true
        }
      }

      case GET_BILLING_INFORMATION_SUCCESS:{
        return {
          ...state,
          billingInformationLoader: false,
          billingInformation: action?.payload
        }
      }

      case GET_BILLING_INFORMATION_FAILURE: {
        return {
          ...state,
          bllingInformationfailure: action?.error
        }
      }

      case CANCEL_SUBSCRIPTION:{
        return {
          ...state,
          cancelSubscriptionLoader: true
        }
      }
      case CANCEL_SUBSCRIPTION_SUCCESS:{
        return {
          ...state,
          cancelSubscriptionLoader: false,
          cancelSubscriptionMessage: action?.payload?.message
        }
      }
      case CANCEL_SUBSCRIPTION_FAILURE:{
        return {
          ...state,
          cancelSubscriptionLoader: false,
          cancelSubscriptionError: action?.error
        }
      }

      case CLEAR_CANCEL_SUBSCRIPTION:{
        return {
          ...state,
          cancelSubscriptionLoader: false,
          cancelSubscriptionError: "",
          cancelSubscriptionMessage:''
        }
      }

      case UPGRADE_SUBSCRIPTION:{
        return {
          ...state,
          upgradeSubscriptionLoader: true
        }
      }

      case UPGRADE_SUBSCRIPTION_SUCCESS: {
        return {
          ...state,
          upgradeSubscriptionLoader: false,
          upgradeSubscriptionMessage: action?.payload?.message
        }
      }

      case UPGRADE_SUBSCRIPTION_FAILURE:{
        return {
          ...state,
          upgradeSubscriptionLoader: false,
          upgradeSubscriptionError: action?.error
        }
      }

      case CLEAR_UPGRADE_SUBSCRIPTION:{
        return {
          ...state,
          upgradeSubscriptionLoader: false,
          upgradeSubscriptionMessage:'',
          upgradeSubscriptionError: ''
        }
      }

      case IS_CALL_FOR:
      return {
        ...state,
        isCallFromAddWebsite: action.payload,
      }

      case DISCARD_TRIAL_PERIOD: {
        return {
          ...state,
          endTrialPeriodLoader: true,
          endTrialPeriodSuccess: false,
        }
      }

      case DISCARD_TRIAL_PERIOD_SUCCESS: {
        return {
          ...state,
          endTrialPeriodLoader: false,
          endTrialPeriodSuccess: true,
          endTrialPeriodMessage: action?.payload
        }
      }

      case DISCARD_TRIAL_PERIOD_FAILURE: {
        return {
          ...state,
          endTrialPeriodLoader: false,
          endTrialPeriodFailure: true,
          endTrialPeriodError: action?.error
        }
      }

      case CLEAR_DISCARD_TRIAL_PERIOD: {
        return {
          ...state,
          endTrialPeriodLoader: false,
          endTrialPeriodSuccess: false,
          endTrialPeriodFailure: false,
          endTrialPeriodError: "",
          endTrialPeriodMessage: ""
        }
      }

      case STRIPE_CHECKOUT: {
        return {
          ...state,
          stripeLoader: true
        }
      }

      case STRIPE_CHECKOUT_SUCCESS:{
        return {
          ...state,
          stripeLoader: false,
          stripeSuccess: true,
          redirectUrlForStripe: action?.payload?.redirectUrl
        }
      }

      case STRIPE_CHECKOUT_FAILURE: {
        return {
          ...state,
          stripeLoader: false,
          stripeFailure: true,
          stripeError: action?.error
        }
      }

      case CLEAR_STRIPE_CHECKOUT: {
        return {
          ...state,
          stripeLoader: false,
          stripeFailure: false,
          stripeError: {},
          stripeSuccess: false,
          redirectUrlForStripe: ''  
        }
      }

      case SAVE_SUBSCRIPTION: {
        return {
          ...state,
          choosenPlan: action?.payload,
          isSubscriptionSaved: true
        }
      }

      case CLEAR_SAVE_SUBSCRIPTION:{
        return {
          ...state,
          isSubscriptionSaved: false,
          choosenPlan: action?.payload?.removePlanDetails ? {} : state?.choosenPlan
        }
      }

      case ADD_PAYMENT_METHOD:{
        return {
          ...state,
          addPaymentMethodLoader: true
        }
      }

      case ADD_PAYMENT_METHOD_SUCCESS:{
        return {
          ...state,
          addPaymentMethodLoader: false,
          addPaymentMethodSuccess: true,
          redirectUrlForPaymentMethod: action?.payload?.redirectUrl
        }
      }

      case ADD_PAYMENT_METHOD_FAILURE:{
        return {
          ...state,
          addPaymentMethodLoader: false,
          addPaymentMethodFailure: true,
          addPaymentMethodError: action?.error
        }
      }
      case CLEAR_ADD_PAYMENT_METHOD:{
        return {
          ...state,
          addPaymentMethodLoader: false,
          addPaymentMethodFailure: false,
          addPaymentMethodError: {},
          redirectUrlForPaymentMethod: '',
          addPaymentMethodSuccess: false
        }
      }

      case TRIAL_SEO_CHECKUP: {
        return {
          ...state,
          trialSeoCheckupLoader: true
        }
      }

      case TRIAL_SEO_CHECKUP_SUCCESS: {
        return {
          ...state,
          trialSeoCheckupLoader: false,
          trialSeoCheckupSuccess: true,
          quickSeoId: action?.payload?.quickSeoId
        }
      }

      case TRIAL_SEO_CHECKUP_FAILURE: {
        return {
          ...state,
          trialSeoCheckupLoader: false,
          trialSeoCheckupFailure: true,
          trialFailureMessage: action?.error
        }
      }

      case CLEAR_TRIAL_SEO_CHECKUP: {
        return {
          ...state,
          trialSeoCheckupLoader: false,
          trialSeoCheckupFailure: false,
          trialSeoCheckupSuccess: false,
          trialFailureMessage: ''
        }
      }

      case GET_TRIAL_SEO_REPORT: {
        return {
          ...state,
          trialSeoReportLoader: true
        }
      }

      case GET_TRIAL_SEO_REPORT_SUCCESS: {
        return {
          ...state,
          trialSeoReportLoader: false,
          trialSeoReportSuccess: true,
          trialReport: action?.payload
        }
      }

      case GET_TRIAL_SEO_REPORT_FAILURE: {
        return {
          ...state,
          trialSeoReportLoader: false,
          trialSeoReportFailure: true
        }
      }

      case CLEAR_GET_TRIAL_SEO_REPORT: {
        return {
          trialSeoReportLoader: false,
          trialSeoReportSuccess: false,
          trialSeoReportFailure: false,
          trialReport: {}
        }
      }

      case OPEN_REGISTER_MODAL: {
        return {
          ...state,
          showRegisterModal: action?.payload
        }
      }

      case GET_PUBLIC_KEY: {
        return {
          ...state,
          getPublicKeyLoader: true
        }
      }  

      case GET_PUBLIC_KEY_SUCCESS: {
        return {
          ...state,
          getPublicKeyLoader: false,
          publicKey: action?.payload
        }
      } 
      
      case GET_PUBLIC_KEY_FAILURE: {
        return {
          ...state,
          getPublicKeyLoader: false,
          publicKeyError: action?.error
        }
      } 

      case CLEAR_GET_PUBLIC_KEY: {
        return {
          ...state,
          getPublicKeyLoader: false,
          publicKey: '',
          publicKeyError: ''
        }
      }
      
    default:
      return state
  }
}
export default freeTrialReducer

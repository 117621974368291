const ResultPreviewTest = ({ data }) => {
  return (
    <>
      <div className="search-engine-block">
        {data?.information?.seoCheckup?.title ? (
          <>
            <div className="vr-detail-block">
              <h5>{data?.url}</h5>

              <p className="stats-title">Desktop version</p>
              <div className="google-search-preview desktop">
                <span className="url-format">
                  {data && data?.information?.seoCheckup?.redirect_url ? (
                    <>{data?.information?.seoCheckup?.redirect_url}</>
                  ) : (
                    ''
                  )}
                </span>
                <span className="title-format line-clamp lines1">
                  {data?.information?.seoCheckup?.title?.title ? (
                    data?.information?.seoCheckup?.title?.title
                  ) : (
                    <></>
                  )}
                </span>
                <span className="description-format line-clamp lines2">
                  {data?.information?.seoCheckup?.description?.description ? (
                    data?.information?.seoCheckup?.description?.description
                  ) : (
                    <></>
                  )}
                </span>
              </div>

              <p className="stats-title">Mobile version</p>
              <div className="google-search-preview mobile">
                <span className="url-format">
                  {data && data?.information?.seoCheckup?.redirect_url ? (
                    <>{data?.information?.seoCheckup?.redirect_url}</>
                  ) : (
                    ''
                  )}
                </span>
                <span className="title-format line-clamp lines1">
                  {data?.information?.seoCheckup?.title?.title ? (
                    data?.information?.seoCheckup?.title?.title
                  ) : (
                    <></>
                  )}
                </span>
                <span className="description-format line-clamp lines2">
                  {data?.information?.seoCheckup?.description?.description ? (
                    data?.information?.seoCheckup?.description?.description
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export default ResultPreviewTest

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const JSCache = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [jsCacheFlag, setJsCacheFlag] = useState(false)

  const columns = [
    {
      id:'javascriptResourceUrl',
      displayName: 'Uncached Javascript Resource list'
    }
  ]

  const jsCacheCsv = data?.jsCache?.data?.withoutCachedResults?.map(item => {
    return {
      javascriptResourceUrl: item?.url
    }
  })
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.jsCache?.data?.testResults?.status ? (
            ''
          ) : data?.jsCache?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.jsCache?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
          {data.jsCache?.data?.testResults ? <>{data.jsCache?.data?.testResults?.message || ''}</> : <></>}
        </p>

        {showDetail &&
        data?.jsCache?.data?.testResults?.status !== 1 &&
        data.jsCache?.data?.withoutCachedResults &&
        data.jsCache?.data?.withoutCachedResults.length ? (
          <>
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                if (testType === "") {
                  setJsCacheFlag(!jsCacheFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={jsCacheFlag}
        toggleShow={() => setJsCacheFlag(!jsCacheFlag)}
        title={'JavaScript Caching Signal Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        alert={data?.jsCache?.data?.alertInfo}
        showDownload
        columns={columns}
        datas = {jsCacheCsv}
        fileName='JavaScript Caching Signal Test'
      >
        {data?.jsCache?.data?.withoutCachedResults && data?.jsCache?.data?.withoutCachedResults.length ? (
          <>
            {' '}
            <h4 className='f-bold'> Uncached Javascript Resource list </h4>
            <ul>
              {' '}
              {data?.jsCache?.data?.withoutCachedResults.map((item, index) => {
                return <li key={`JSCache-${index+1}`}>{item.url}</li>
              })}
            </ul>{' '}
          </>
        ) : (
          <></>
        )}
      </ModalHOC>
    </>
  )
}

export default JSCache

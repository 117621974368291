import { Toast, ToastContainer } from 'react-bootstrap'

const ToastMessage = ({toastMessage, onClose, variant, show}) => {
  return (
    <ToastContainer
    className="mt-6"
    position='top-end'
    style={{ zIndex: 1 }}
>
    <Toast
        className="d-inline-block m-1"
        bg={variant}
        onClose={onClose}
        show={show}
        delay={3000}
        autohide
    >
        <Toast.Body>
            <p style={{color:'white', marginBottom: '0'}}>{toastMessage}</p>
        </Toast.Body>
    </Toast>
</ToastContainer>
  )
}

export default ToastMessage

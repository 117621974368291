import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import {       
    Card, 
    Col, 
    Container,   
    ListGroup,  
    Row,  
     } from 'react-bootstrap'

import { getUserData } from 'redux/actions/dashboard'
import { Loader } from 'components/uiCore'


import { getBillingInformation } from 'redux/actions/freetrial/startFreeTrial'
import { getPaymentMethods, storeUserData } from 'redux/actions/register'
import PaymentMethod from './PaymentMethod'
import BillingInformation from './BillingInformation'


export const BILLING_TABS = {
    BILLING_INFO: "BILLING INFROMATION",
    PAYMENT_METHOD: 'PAYMENT_METHOD'
}

const Navigation = ({ activeTab, setActiveTab }) => {
    const dispatch = useDispatch()
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h3" className="mb-0">
                    <b>Billing Settings</b>
                </Card.Title>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item tag="a" action onClick={() => {
                    dispatch({type:"PERSONAL_INFO_UPDATE_CLEAR"})
                    setActiveTab(BILLING_TABS.BILLING_INFO)}} active={activeTab === BILLING_TABS.BILLING_INFO ? true : false}>
                    Billing information
                </ListGroup.Item>
                <ListGroup.Item tag="a" action onClick={() => setActiveTab(BILLING_TABS.PAYMENT_METHOD)} active={activeTab === BILLING_TABS.PAYMENT_METHOD ? true : false}>
                    Payment method
                </ListGroup.Item>
            </ListGroup>
        </Card>)

}

const Billing = () => {
    const { userData } = useSelector(({ dashboard }) => dashboard)
    const { billingInformation, billingInformationLoader } = useSelector(({startFreeTrial}) => startFreeTrial)
    const {paymentMethods, paymentMethodsLoader} = useSelector(({register}) => register)



    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(BILLING_TABS.BILLING_INFO);

    useEffect(() => {
        const fetchUserData = async () => {
            const data = await localStorage.getItem("userData")
            dispatch(storeUserData(JSON.parse(data)))
            dispatch(getBillingInformation())
            if(!userData){
                    dispatch(getUserData())
            }
        }
        
        const getpaymentMethods = async () => {
            const userData = await localStorage.getItem('userData')    
            dispatch(getPaymentMethods({customerId:JSON.parse(userData)?.customerId}))
        }
        getpaymentMethods()
        fetchUserData()
    }, [])

    useEffect(() => {
        return () => {
          dispatch({type:"PERSONAL_INFO_UPDATE_CLEAR"})
        }
      }, [])

    const renderSelectedTab = () => {
        switch (activeTab) {
            case BILLING_TABS.BILLING_INFO:
                return <BillingInformation />;
            case BILLING_TABS.PAYMENT_METHOD:
                return <PaymentMethod />;
            default:
                return null;
        }
    };
    return (
        <>
            <Helmet title="Settings" />
            <Container fluid className="p-0">

                {billingInformationLoader && !Object.keys(billingInformation)?.length && !paymentMethods?.length && paymentMethodsLoader ? <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            alignSelf: 'center',
                            flexDirection: 'row',
                            minHeight: 380,
                        }}
                    >
                        <div className="text-center">
                            <Loader />
                        </div>
                    </div>
                </> : <Row>
                    <Col md="3" xl="2">
                        <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
                    </Col>
                    <Col md="9" xl="10">
                        {renderSelectedTab()}
                    </Col>
                </Row>}
            </Container>
        </>
    )
}

export default Billing
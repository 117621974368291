import React from 'react'

const NoFollow = ({ data }) => {
  return (
    <>
      <div className="vr-url-block">
        <div>
          <div className="vr-report-status-icon" />
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />
        <p> {data?.noFollow?.data?.testResults?.message} </p>
      </div>
    </>
  )
}

export default NoFollow

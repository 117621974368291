import { Spinner } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'

export const LoaderForButton = () => {
    return (
        <>
            <span style={{ position: 'relative', top: 10, padding: "0 10px" }}>
                <Spinner animation="border" key={1} variant={'primary'} className={`${isMobile ? "mb-4" : ''} me-2`} />
            </span>
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import SignIn from '../../components/auth/SignIn'
import pmsLogo from '../../assets/img/pms/PMS-SEO-Site-Signals-350-150-Dark.png'
import { CLEAR_REGISTER_USERDATA, CLEAR_USER_DATA } from 'redux/actionType'
import ColoredModal from 'pages/modals/ColorModal'
import user from '../../assets/img/avatars/user.png'
import man from '../../assets/img/sign-in/man.png'
import '../../components/auth/auth.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const SignInPage = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [profilePicture, setProfilePicture] = useState('')
  const { loginSuccess, registerData, registerError, errorMessage } = useSelector(({ register }) => register)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()


  useEffect(() => {
    setTimeout(() => {
      dispatch({type: CLEAR_USER_DATA})
      dispatch({type: CLEAR_REGISTER_USERDATA})
    }, 2000)
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      if (loginSuccess) {
        dispatch({ type: 'LOGIN_CLEAR' })
        dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
        dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
        await localStorage.setItem('firstName', registerData.firstName || '')
        await localStorage.setItem('lastName', registerData.lastName || '')
        await localStorage.setItem('profilePicture', registerData.profilePicture || '')
        if (registerData && registerData.planData && registerData.planData.name) {
          // history('/trial/payment');
        } else {
          // history('/trial/payment');
        }
      }
      setTimeout(() => {
        dispatch({ type: 'CLEAR_DOMAINS' })
      }, 2000)
    }
    fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess, registerData])

  useEffect(() => {
    async function fetchMyAPI() {
      await localStorage.removeItem('token')
      await localStorage.removeItem('userData')

      let firstName = await localStorage.getItem('firstName')
      if (firstName?.length > 1) {
        setFirstName(firstName)
      }
      let lastName = await localStorage.getItem('lastName')
      if (lastName?.length > 0) {
        setLastName(lastName)
      }
      let profilePicture = await localStorage.getItem('profilePicture')
      if (profilePicture?.length > 0) {
        setProfilePicture(profilePicture)
      }
    }
    fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      {registerError ? (
        <ColoredModal
          isOpen={registerError}
          message={errorMessage}
          onClose={() => dispatch({ type: 'REGISTER_CLEAR' })}
        />
      ) : null}
      <div className="main-container">
        <div style={{ zIndex: 999, width: isMobile ? '100vw' : '' }}>
          <div className={`${isMobile ? 'message-mobile' : 'mb-4 '}`}>
            <div className='signin-message-mobile'>
              <p className="sign-in-font">
                <b>
                  Elevate Your SEO Strategy with Advanced Analysis & Monitoring Tools: <br /> Simplifying
                  Search Engine Optimization
                </b>
              </p>
              <p className="sign-in-font">
                Track, Verify, Notify: Accurate Keyword Positioning Across Regions, Visualized!
              </p>

              <Button
                className="mt-4 d-flex flex-row justify-content-start align-items-center gap-1 d-lg-none d-md-none"
                style={{
                  fontSize: 12,
                  background: 'white',
                  border: '1px solid grey',
                  color: 'grey',
                  width: '200px',
                  padding: '6px',
                  fontWeight: '500',
                }}
                onClick={() => navigate('/auth/sign-up')}
              >
                <img
                  alt="user"
                  src={profilePicture || user}
                  className="rounded-circle img-responsive"
                  width="15"
                  height="15"
                  style={{ marginRight: '8px', marginLeft: '5px' }}
                />
                Sign Up for Free Trial
              </Button>
            </div>
            <div className="signin-man-mobile">
              <img src={man} width="160" height="190" />
            </div>
          </div>

          <Card className={`mb-0 ${!isMobile ? 'transparent-card' : ''}`}>
            <Card.Body
              className={`pt-0 ${isMobile ? '' : 'sign-in-card pb-0'}`}
              style={{ background: '#f0f4fc' }}
            >
              <div className="m-sm-4">
                <div className="my-4 px-1 d-flex flex-row gap-1 align-items-center signin-card-content">
                      <img
                        alt="user"
                        src={profilePicture || user}
                        className="rounded-circle img-responsive mobile-form-header-img"
                        width="95"
                        height="95"
                      />
                  <Row className='mobile-form-header'>
                    <Col lg={7} className='mobile-form-header-message'>
                      <div className="mt-3">
                        <p className="sign-in-font mobile-form-header-message-one">
                          <b>Empower Your SEO Journey: Precision, Simplicity, Results</b>
                        </p>
                        <p className="sign-in-font">Let's Dive Deeper: Your Insights Await!</p>
                      </div>
                    </Col>
                    <Col lg={5} className=''>
                      <div className="text-center mt-2 signIn-btn">
                        <Row className="text-center">
                          <Col className="login-link d-flex flex-row justify-content-end">
                            <Button
                              className="d-flex flex-row justify-content-start align-items-center gap-1"
                              variant='primary'
                              color='primary'
                              style={{
                                fontSize: 12,
                                width: '200px',
                                padding: '6px',
                                fontWeight: '500',
                              }}
                              onClick={() => navigate('/auth/sign-up')}
                            >
                              <img
                                alt="user"
                                src={profilePicture || user}
                                className="rounded-circle img-responsive"
                                width="15"
                                height="15"
                                style={{ marginRight: '15px', marginLeft: '5px' }}
                              />
                              Sign Up for Free Trial
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <SignIn />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="signIn-man">
          <img src={man} width="475" height="560" />
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignInPage
import SocialLogin from 'react-social-login'
import { LoaderForButton } from './uiCore/LoaderForButton'
import { useDeviceCheck } from 'hooks/deviceHook'
const SocialButton = ({ triggerLogin, containerStyle, children, ...props }) => {

  const { isMobile } = useDeviceCheck()
  return (
    <button
      type="button"
      onClick={triggerLogin}
      style={{ padding: 0, borderRadius: 50, background: isMobile ? "#f0f4fc" : 'white', border: 0, ...containerStyle }}
      {...props}
    >
      {children}
    </button>
  )
}

export default SocialLogin(SocialButton, LoaderForButton)
import { useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import * as _ from 'lodash'
import { Progress, Space } from 'antd'
import * as moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Area } from '@ant-design/plots'
import { isMobile } from 'react-device-detect'
import { Loader } from 'components/uiCore'

import './urlScanStatus.scss'
import { getColor } from 'constants/Utils'
import { Spinner } from 'reactstrap'

const GooglePageRank = () => {
  const [currentGooglePageRank, setCurrentGooglePageRank] = useState()
  const [data, setData] = useState([])

  const { usersDomains: usersDomain, activeLink } = useSelector(({ dashboard }) => dashboard)

  const item = usersDomain && activeLink !== undefined && usersDomain[activeLink]

  useEffect(() => {
    setCurrentGooglePageRank('')
    if (
      usersDomain &&
      activeLink > -1 &&
      usersDomain?.[activeLink] &&
      usersDomain?.[activeLink]?.statsData?.length &&
      usersDomain?.[activeLink]?.statsData?.[0]?.googlePageRank
    ) {
      setCurrentGooglePageRank(usersDomain?.[activeLink]?.statsData?.[0]?.googlePageRank)
      let chart = []
      item?.statsData?.map((data) => {
        if (data) {
          chart.push({
            Date: moment(data?.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('D MMM'),
            rank: Number(data?.googlePageRank).toFixed(1) || 0,
          })
        }
      })
      chart = _.reverse(chart)
      setData(chart)
    }
  }, [activeLink, usersDomain])

  data.forEach((item) => {
    item.rank = parseFloat(item.rank)
  })

  const processedData = useMemo(() => {
    let config

    if (isMobile) {
      config = {
        height: 60,
        renderer: 'svg',
        width: 300,
        data,
        xField: 'Date',
        yField: 'rank',
        yAxis: {},
        smooth: false,
        areaStyle: () => {
          return {
            fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
            cursor: 'pointer',
            r: 4,
          }
        },
      }
    } else {
      config = {
        height: 75,
        renderer: 'svg',
        width: 200,
        data,
        xField: 'Date',
        yField: 'rank',
        animation: false,
        yAxis: {},
        smooth: false,
        areaStyle: () => {
          return {
            fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
            cursor: 'pointer',
            r: 4,
          }
        },
      }
    }
    return config
  }, [data])

  const checkgooglePageRankPosChange = () => {
    if (item && item?.statsData && item?.statsData?.length && item?.statsData?.[0]?.googlePageRank) {
      if (item?.statsData?.length === 0) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentRank = Number(item?.statsData?.[0]?.googlePageRank) || 0
        let previousRank = Number(item?.statsData?.[1]?.googlePageRank) || 0
        if (currentRank - previousRank === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentRank - previousRank > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    }
  }

  const countPageRankPercent = (rank) => {
    const percent = (rank * 100) / 10
    return percent
  }

  return (
      <div className="card-container-dashboard" style={{ position: 'relative', alignItems: 'start', justifyContent: usersDomain?.[activeLink]?.statsData?.length ? 'flex-start' : 'center' }}>
        <Space wrap>
          <p className="mb-1 fw-bold">Page Rank</p>
        </Space>
        {usersDomain?.[activeLink]?.statsData?.length ? (
          <div className="w-100">
            {currentGooglePageRank ? (
              <div className='mb-1 d-flex align-items-start justify-content-between'>
              <p className="mb-2 f-bold" style={{ fontSize: 22 }}>
                {checkgooglePageRankPosChange()} &nbsp;&nbsp;
                {Math.round(currentGooglePageRank)}/10
              </p>
             
                <div style={{ position: 'relative', top:-22}}>
                  {currentGooglePageRank > -1 ? (
                    <Progress
                      strokeLinecap={'square'}
                      strokeWidth={18}
                      type="circle"
                      strokeColor={getColor(Math.round(currentGooglePageRank))}
                      format={(percent) => ''}
                      percent={countPageRankPercent(Math.round(currentGooglePageRank))}
                      className='page-rank'
                    />
                  ) : (
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                  )}
                </div>
            </div>
            ) : null}

            {processedData && processedData?.data?.length ? <Area {...processedData} /> : null}
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <div className="text-center">
                <Loader size="sm" />
              </div>
            </div>
          </>
        )}
      </div>
  )
}

export default GooglePageRank

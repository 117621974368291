import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { message } from 'antd'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SocialButton from 'components/fbButton.js'
import { useDeviceCheck } from 'hooks/deviceHook'
import Registration from 'modules/Free tools/components/RegisterForm.jsx'

import 'modules/Free tools/components/LimitExceed.scss'
import google from 'assets/img/icons/google.png'

const LimitExceed = () => {
  const dispatch = useDispatch()
  const { isMobile } = useDeviceCheck()

  return (
    <div className="limit-exceed-wrapper">
      <div className='limit-exceed-info'>
        <h1 className="limit-exceed-heading">You have exceeded your weekly limit</h1>
        <p className="limit-exceed-message">
          Please wait for one week to scan new websites or <br /> register now to analyze more sites and
          access more features
        </p>
        <p className='fs-4'>Subscribing to one of our plans will provide you better insights,<br /> strategies, and guidance to significantly enhance your online presence.</p>
      </div>

      <div className="limit-exceed-social">
        <Registration />
        <SocialButton
          containerStyle={isMobile ? { width: '100%' } : {}}
          provider="google"
          appId="74542118637-c261k854oj1m08ov29tcvrsvkcbrnhpm.apps.googleusercontent.com"
          onLoginSuccess={(data) => {
            if (data._profile.email && data._token.accessToken) {
              let apiData = {
                token: data._token.accessToken,
                social: true,
                type: 'google',
              }

              dispatch({ type: 'REGISTER_START', payload: apiData })
            }
          }}
          onLoginFailure={(data) => {
            const error = data?.toString()
            if (error?.includes('popup_closed_by_user')) {
              return
            } else {
              message.error(data)
            }
          }}
        >
          <Button type="button" variant="outline-primary" className="limit-exceed-social-login">
            <img src={google} width={20} height={20} />
            <p className="mb-0">Sign up with Google</p>
          </Button>
        </SocialButton>

        <SocialButton
          containerStyle={isMobile ? { width: '100%' } : {}}
          provider="facebook"
          appId="378272869423880"
          onLoginSuccess={(data) => {
            if (data._profile.email && data._token.accessToken) {
              let apiData = {
                token: data._token.accessToken,

                social: true,
                type: 'facebook',
              }
              dispatch({ type: 'REGISTER_START', payload: apiData })
            }
          }}
        >
          <Button type="button" variant="primary" className="limit-exceed-social-login">
            <FontAwesomeIcon icon={faFacebook} className="fs-3" />
            <p className="mb-0">Sign up with Facebook</p>
          </Button>
        </SocialButton>
      </div>
    </div>
  )
}

export default LimitExceed

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const SpeedIndexTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.speedIndexSignalsTest.heading}
      whatIsIT={freeTools.speedIndexSignalsTest.what}
    />
  )
}

export default SpeedIndexTest

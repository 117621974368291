import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { Button } from 'react-bootstrap'

import paths from 'constants/paths'
import { clearUrlDetails } from 'redux/actions/freetrial/startFreeTrial'

import 'react-credit-cards/es/styles-compiled.css'
import 'modules/welcomeBoard/welcomeBoard.scss'
import laptop from 'assets/img/pms/laptop1.png'
import { getUserData } from 'redux/actions/dashboard'

const NewWelcomeOnBoard = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(({ register }) => register.registerData)
  const { userData } = useSelector(({ dashboard }) => dashboard)
  const { toastMessage, toastError, supportRequestLoader } = useSelector(({ register }) => register)

  useEffect(() => {
    const fetchUserdata = async () => {
      const data = await localStorage.getItem('userData')
      const query = new URLSearchParams(window.location.search)

      if (query.get('userId')) {
        const userId = query.get('userId')
        dispatch(getUserData())
      } else {
        dispatch(getUserData())
      }
    }
    fetchUserdata()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    async function fetchMyAPI() {
      const data = await localStorage.getItem('userData')

      dispatch(clearUrlDetails())

      if (data && JSON.parse(data)._id && !currentUser._id) {
        dispatch({ type: 'STORE_USER_DATA_START', payload: JSON.parse(data) })
      }
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    const redirectPage = async () => {
      if (
        userData &&
        userData?.subscriptions &&
        userData?.subscriptions?.length &&
        (userData?.subscriptions?.[0]?.isActive || userData?.subscriptions?.[0]?.isTrial)
      ) {
        if (userData && userData?.websites && userData?.websites?.length) {
          history(paths.mainDashboard)
        }
      } else {
        history(paths.newUserSubscriptionPage)
      }
    }

    if (!userData || !userData?._id) {
      return
    } else {
      redirectPage()
    }
  }, [userData])

  useEffect(() => {
    if (!supportRequestLoader && (toastError || toastMessage)) {
      if (toastMessage) {
        message.success(toastMessage)
      } else if (toastError) {
        message.error(toastError)
      } else {
        message.warn('Something went wrong')
      }
      setTimeout(() => {
        dispatch({ type: 'CLEAR_SUPPORT_REQUEST' })
      }, 1500)
    }
  }, [supportRequestLoader])

  return (
    <div className="welcome">
      <div className="welcome-header">
        <p>
          <b>Empower Your SEO Journey: Precision, Simplicity, Results</b>
        </p>
        <p>Let’s Complete Your Full Signal Account Setup</p>
      </div>
      <div className="welcome-content">
        <p>Follow five easy steps to access your FREE 24-hour trial!</p>
        <img src={laptop} className="welcome-content-laptop" />
        <Button onClick={() => history(paths.addNewWebsite)}>Let's Start</Button>
      </div>
    </div>
  )
}

export default NewWelcomeOnBoard

import axios from 'axios'
import { appendInFormData } from './Utils'

const BASE_URL = process.env.REACT_APP_BASE_URL
const API_BASE_LOCAL_URL = process.env.REACT_APP_NEST_LOCAL_API

export async function getRequestWithoutToken(url) {
  const headers = {
    'Content-Type': 'application/json',
  }
  return axios
    .get(`${API_BASE_LOCAL_URL}${url}`, {headers})
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export async function getRequest(url) {
  let token = await localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return axios
    .get(`${API_BASE_LOCAL_URL}${url}`, { headers: headers })
    .then((response) => response.data)
    .catch((error) => {
      if(error.response && error.response.status === 401){
        window.location.href = '/sign-in'
      }
      throw error
    })
}

export async function getDashboardChartData(url, data) {
  try {
    const token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${API_BASE_LOCAL_URL}${url}`, data, { headers });
    return response.data || [];
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = '/sign-in'
    }
    throw error;
  }
}

export async function paymentRequest(url, data) {
  let token = await localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return axios
    .post(`${API_BASE_LOCAL_URL}${url}`, data, { headers: headers })
    .then((response) => response.data)
    .catch((error) => {
      if(error.response && error.response.status === 401){
        window.location.href = '/sign-in'
      }
      throw error
    })
}

export async function putRequestWithTokenFormData(url, data) {
  const getFormData = appendInFormData(data)
  const header = {
    'Content-Type': 'multipart/form-data',
  }

  return axios
    .put(`${BASE_URL}${url}`, getFormData, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response.status === 401) {
      }
      throw error
    })
}

export async function putRequestWithToken(url, data) {
  const token = await localStorage.getItem('token')
  const header = {
    'Content-Type': 'application/json',
    Authorization : `Bearer ${token}`
  }

  return axios
    .put(`${API_BASE_LOCAL_URL}${url}`, data, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if(error.response && error.response.status === 401){
        window.location.href = '/sign-in'
      }
      throw error
    })
}

export async function putRequestWithoutToken(url, data) {
  const header = {
    'Content-Type': 'application/json',
  }

  return axios
    .put(`${API_BASE_LOCAL_URL}${url}`, data, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response.status === 401) {
      }
      throw error
    })
}

export async function getRequestWithToken(url) {
  const header = {
    'Content-Type': 'application/json',
  }

  return axios
    .get(`${BASE_URL}${url}`, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response.status === 401) {
      }
      throw error
    })
}

export async function postRequestWithoutToken(url, data) {
  const header = {
    'Content-Type': 'application/json',
  }
  return axios
    .post(`${BASE_URL}${url}`, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export async function postRequestWithoutTokenFormData(url, data) {
  const header = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
  }

  return axios
    .post(`${BASE_URL}${url}`, appendInFormData(data), header)
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export async function postRequestWithTokenFormData(url, data) {
  let newData = { ...data, busToken: localStorage.getItem('busToken') }

  const getFormData = appendInFormData(newData)

  const header = {
    'Content-Type': 'multipart/form-data',
  }
  return axios
    .post(`${BASE_URL}${url}`, getFormData, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response.status === 401) {
      }
      throw error
    })
}

export async function postRequestWithToken(url, data) {
  let newData = { ...data, busToken: localStorage.getItem('busToken') }
  const header = {
    'Content-Type': 'application/json',
  }
  return axios
    .post(`${BASE_URL}${url}`, newData, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response.status === 401) {
      }
      throw error
    })
}

export async function deleteRequestWithToken(url) {
  const token = await localStorage.getItem('token')
  const header = {
    'Content-Type': 'application/json',
    Authorization : `Bearer ${token}`
  }

  return axios
    .delete(`${API_BASE_LOCAL_URL}${url}`, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if(error.response && error.response.status === 401){
        window.location.href = '/sign-in'
      }
      throw error
    })
}

export async function postLocalRequestWithoutToken(url, data) {
  const header = {
    'Content-Type': 'application/json',
  }
  return axios
    .post(`${API_BASE_LOCAL_URL}${url}`, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export async function postLocalRequestWithToken(url, data) {
  let token = await localStorage.getItem('token')
  const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  return axios
    .post(`${API_BASE_LOCAL_URL}${url}`, data, { headers: header })
    .then((response) => response.data)
    .catch((error) => {
      if(error.response && error.response.status === 401){
        window.location.href = '/sign-in'
      }
      throw error
    })
}

export async function postLocalRequestWithTokenSeoCheckup(url, data) {
  let token = await localStorage.getItem('token')
  const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
  return axios
    .post(`${API_BASE_LOCAL_URL}${url}`, data, { headers: header })
    .then((response) => response)
    .catch((error) => {
      if(error.response && error.response.status === 401){
        window.location.href = '/sign-in'
      }
      throw error
    })
}

export async function getLocalRequestWithoutToken(url) {
  const header = {
    'Content-Type': 'application/json',
  }
  return axios
    .get(`${API_BASE_LOCAL_URL}${url}`, { headers: header })
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export async function deleteLocalRequestWithoutToken(url) {
  const header = {
    'Content-Type': 'application/json',
  }

  return axios
    .delete(`${API_BASE_LOCAL_URL}${url}`, { headers: header })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response.status === 401) {
      }
      throw error
    })
}

import AddLocationMain from 'modules/welcomeBoard/components/addLocation/AddLocationMain.jsx'

const AddLocationTrial = () => {
  return (
    <>
      <AddLocationMain callFrom="trial" />
    </>
  )
}

export default AddLocationTrial

import {
  GET_BACKLINKS_MOSTRECENTLY_CSV,
  GET_BACKLINKS_MOSTRECENTLY_CSV_SUCCESS,
  GET_BACKLINKS_MOSTRECENTLY_CSV_FAILURE,
  CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV,
  GET_BACKLINKS_MOSTIMPORTANT_CSV,
  GET_BACKLINKS_MOSTIMPORTANT_CSV_SUCCESS,
  GET_BACKLINKS_MOSTIMPORTANT_CSV_FAILURE,
  CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV,
  GET_BACKLINKS_MOSTRECENTLY_JSON,
  GET_BACKLINKS_MOSTRECENTLY_JSON_SUCCESS,
  GET_BACKLINKS_MOSTRECENTLY_JSON_FAILURE,
  CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON,
  GET_BACKLINKS_MOSTIMPORTANT_JSON,
  GET_BACKLINKS_MOSTIMPORTANT_JSON_SUCCESS,
  GET_BACKLINKS_MOSTIMPORTANT_JSON_FAILURE,
  CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON_SUCCESS,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON_FAILURE,
  CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_JSON,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV_SUCCESS,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV_FAILURE,
  CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_CSV,
} from '../../actionType'

const initialState = {
  loading: false,
  getMostRecentlyCSVLoader: false,
  getMostRecentlyCSVSuccess: false,
  getMostRecentlyCSVError: false,
  mostRecentlyCSVData: [],

  getMostImportantCSVLoader: false,
  getMostImportanCSVSuccess: false,
  getMostImportanCSVError: false,
  mostImportanCSVData: [],

  getMostRecentlyJSONLoader: false,
  getMostRecentlyJSONSuccess: false,
  getMostRecentlyJSONError: false,
  mostRecentlyJSONData: [],

  getMostImportantJSONLoader: false,
  getMostImportanJSONSuccess: false,
  getMostImportanJSONError: false,
  mostImportanJSONData: [],

  getReferringDomainJSONLoader: false,
  getReferringDomainJSONSuccess: false,
  getReferringDomainJSONError: false,
  referringDomainJSONData: [],

  getReferringDomainCSVLoader: false,
  getReferringDomainCSVSuccess: false,
  getReferringDomainCSVError: false,
  referringDomainCSVData: [],
}

const backlinksDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BACKLINKS_MOSTRECENTLY_CSV: {
      return {
        ...state,
        getMostRecentlyCSVLoader: true,
        getMostRecentlyCSVError: false,
        getMostRecentlyCSVSuccess: false,
      }
    }

    case GET_BACKLINKS_MOSTRECENTLY_CSV_SUCCESS: {
      return {
        ...state,
        getMostRecentlyCSVLoader: false,
        getMostRecentlyCSVSuccess: true,
        getMostRecentlyCSVError: false,
        mostRecentlyCSVData: action.payload,
      }
    }

    case GET_BACKLINKS_MOSTRECENTLY_CSV_FAILURE: {
      return {
        ...state,
        getMostRecentlyCSVLoader: false,
        getMostRecentlyCSVError: true,
        getMostRecentlyCSVSuccess: false,
        mostRecentlyCSVData: [],
      }
    }

    case CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV: {
      return {
        ...state,
        getMostRecentlyCSVLoader: false,
        getMostRecentlyCSVSuccess: false,
        getMostRecentlyCSVError: false,
        mostRecentlyCSVData: [],
      }
    }

    case GET_BACKLINKS_MOSTRECENTLY_JSON: {
      return {
        ...state,
        getMostRecentlyJSONLoader: true,
        getMostRecentlyJSONError: false,
        getMostRecentlyJSONSuccess: false,
      }
    }

    case GET_BACKLINKS_MOSTRECENTLY_JSON_SUCCESS: {
      return {
        ...state,
        getMostRecentlyJSONLoader: false,
        getMostRecentlyJSONSuccess: true,
        getMostRecentlyJSONError: false,
        mostRecentlyJSONData: action.payload,
      }
    }

    case GET_BACKLINKS_MOSTRECENTLY_JSON_FAILURE: {
      return {
        ...state,
        getMostRecentlyJSONLoader: false,
        getMostRecentlyJSONError: true,
        getMostRecentlyJSONSuccess: false,
        mostRecentlyJSONData: [],
      }
    }

    case CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON: {
      return {
        ...state,
        getMostRecentlyJSONLoader: false,
        getMostRecentlyJSONSuccess: false,
        getMostRecentlyJSONError: false,
        mostRecentlyJSONData: [],
      }
    }

    case GET_BACKLINKS_MOSTIMPORTANT_JSON: {
      return {
        ...state,
        getMostImportantJSONLoader: true,
        getMostImportanJSONSuccess: false,
        getMostImportanJSONError: false,
      }
    }

    case GET_BACKLINKS_MOSTIMPORTANT_JSON_SUCCESS: {
      return {
        ...state,
        getMostImportantJSONLoader: false,
        getMostImportanJSONSuccess: true,
        getMostImportanJSONError: false,
        mostImportanJSONData: action.payload,
      }
    }

    case GET_BACKLINKS_MOSTIMPORTANT_JSON_FAILURE: {
      return {
        ...state,
        getMostImportantJSONLoader: false,
        getMostImportanJSONSuccess: true,
        getMostImportanJSONError: false,
        mostImportanJSONData: [],
      }
    }

    case CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON: {
      return {
        ...state,
        getMostImportantJSONLoader: false,
        getMostImportanJSONSuccess: false,
        getMostImportanJSONError: false,
        mostImportanJSONData: [],
      }
    }

    case GET_BACKLINKS_MOSTIMPORTANT_CSV: {
      return {
        ...state,
        getMostImportantCSVLoader: true,
        getMostImportanCSVSuccess: false,
        getMostImportanCSVError: false,
      }
    }

    case GET_BACKLINKS_MOSTIMPORTANT_CSV_SUCCESS: {
      return {
        ...state,
        getMostImportantCSVLoader: false,
        getMostImportanCSVSuccess: true,
        getMostImportanCSVError: false,
        mostImportanCSVData: action.payload,
      }
    }

    case GET_BACKLINKS_MOSTIMPORTANT_CSV_FAILURE: {
      return {
        ...state,
        getMostImportantCSVLoader: false,
        getMostImportanCSVSuccess: true,
        getMostImportanCSVError: false,
        mostImportanCSVData: [],
      }
    }

    case CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV: {
      return {
        ...state,
        getMostImportantCSVLoader: false,
        getMostImportanCSVSuccess: false,
        getMostImportanCSVError: false,
        mostImportanCSVData: [],
      }
    }

    case GET_BACKLINKS_REFERRINGDOMAINS_JSON: {
      return {
        ...state,
        getReferringDomainJSONLoader: true,
        getReferringDomainJSONSuccess: false,
        getReferringDomainJSONError: false,
      }
    }

    case GET_BACKLINKS_REFERRINGDOMAINS_JSON_SUCCESS: {
      return {
        ...state,
        getReferringDomainJSONLoader: false,
        getReferringDomainJSONSuccess: true,
        getReferringDomainJSONError: false,
        referringDomainJSONData: action.payload,
      }
    }

    case GET_BACKLINKS_REFERRINGDOMAINS_JSON_FAILURE: {
      return {
        ...state,
        getReferringDomainJSONLoader: false,
        getReferringDomainJSONSuccess: false,
        getReferringDomainJSONError: true,
        referringDomainJSONData: [],
      }
    }

    case CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_JSON: {
      return {
        ...state,
        getReferringDomainJSONLoader: false,
        getReferringDomainJSONSuccess: false,
        getReferringDomainJSONError: false,
        referringDomainJSONData: [],
      }
    }

    case GET_BACKLINKS_REFERRINGDOMAINS_CSV: {
      return {
        ...state,
        getReferringDomainCSVLoader: true,
        getReferringDomainCSVSuccess: false,
        getReferringDomainCSVError: false,
      }
    }

    case GET_BACKLINKS_REFERRINGDOMAINS_CSV_SUCCESS: {
      return {
        ...state,
        getReferringDomainCSVLoader: false,
        getReferringDomainCSVSuccess: true,
        getReferringDomainCSVError: false,
        referringDomainCSVData: action.payload,
      }
    }

    case GET_BACKLINKS_REFERRINGDOMAINS_CSV_FAILURE: {
      return {
        ...state,
        getReferringDomainCSVLoader: false,
        getReferringDomainCSVSuccess: false,
        getReferringDomainCSVError: true,
        referringDomainCSVData: [],
      }
    }

    case CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_CSV: {
      return {
        ...state,
        getReferringDomainCSVLoader: false,
        getReferringDomainCSVSuccess: false,
        getReferringDomainCSVError: false,
        referringDomainCSVData: [],
      }
    }

    default:
      return state
  }
}
export default backlinksDataReducer

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const NoIndexTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.noIndexTest.heading}
      whatIsIT={freeTools.noIndexTest.what}
      howToFixBeforeCode={freeTools.noIndexTest.howSectionBeforeCode}
    />
  )
}

export default NoIndexTest

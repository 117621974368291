import * as Yup from 'yup'
import { useFormik } from 'formik'

import 'modules/Free tools/components/CommonFreeTool.scss'
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trialSeoCheckup } from 'redux/actions/freetrial/startFreeTrial'
import { useNavigate, useParams } from 'react-router-dom'
import { TEST_TYPES } from 'constants/enum'
import { CLEAR_TRIAL_SEO_CHECKUP } from 'redux/actionType'
import { message } from 'antd'
import { encryptData } from 'constants/Utils'

const SocialMediaMetaTagsTest = () => {
  const { 
    trialSeoCheckupLoader, 
    trialSeoCheckupSuccess, 
    trialFailureMessage 
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { 'test-type': type } = useParams()

  const URL_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)*$/
  const testType = TEST_TYPES?.[type] || 'ALL'

  const [isAddCompetitor, setIsAddCompetitor] = useState(false)
  const [checkupUrl, setCheckupUrl] = useState({})
  const initialValues = {
    url: '',
    competitorUrl: '',
  }

  const handleFreeToolSubmit = (values) => {
    const encryptMainUrl = encryptData(values.url)
    if (values?.competitorUrl) {
      const encryptCompetitorUrl = encryptData(values.competitorUrl)
      setCheckupUrl({mainUrl: encryptMainUrl, competitorUrl: encryptCompetitorUrl})
    } else {
      setCheckupUrl({ mainUrl: encryptMainUrl })
    }
  }

  useEffect(() => {
    if (!trialSeoCheckupLoader && trialSeoCheckupSuccess) {
      if (checkupUrl?.competitorUrl) {
        const path = `/seo-report/${type}/${checkupUrl?.mainUrl}/${checkupUrl?.competitorUrl}`
        navigate(path, { state: { mainUrl: toolsFormik.values.url } })
      } else {
        const path = `/seo-report/${type}/${checkupUrl?.mainUrl}`
        navigate(path, { state: { mainUrl: toolsFormik.values.url } })
      }
    } else if (trialFailureMessage) {
      if (trialFailureMessage?.message !== 'You have exceded your weekly limit') {
        message.error(trialFailureMessage?.message)
      }
      dispatch({ type: CLEAR_TRIAL_SEO_CHECKUP })
    }
  }, [trialSeoCheckupSuccess, trialSeoCheckupLoader, trialFailureMessage])

  useEffect(() => {
    if (checkupUrl?.mainUrl) {
      if (checkupUrl?.competitorUrl) {
        const payload = { type: testType, url: checkupUrl?.mainUrl, competitor: checkupUrl?.competitorUrl }
        dispatch(trialSeoCheckup(payload))
      } else {
        const payload = { type: testType, url: checkupUrl?.mainUrl }
        dispatch(trialSeoCheckup(payload))
      }
    }
  }, [checkupUrl])

  const urlValidationSchema = Yup.object().shape({
    url: Yup.string().trim().required('Please provide url').matches(URL_REGEX, 'Invalid format'),
    competitorUrl: isAddCompetitor
      ? Yup.string().trim().required('Please provide url').matches(URL_REGEX, 'Invalid format')
      : null,
  })

  const toolsFormik = useFormik({
    initialValues,
    validationSchema: urlValidationSchema,
    onSubmit: handleFreeToolSubmit,
  })

  const { values, handleChange, handleSubmit, errors, touched } = toolsFormik
  return (
    <div className="tools-wrapper">
      <h1 className="tools-heading">Social Media Meta Tags Test</h1>
      <Form className="tools-form" onSubmit={handleSubmit}>
        <Form.Group className="tools-form-group">
          <div className="d-flex align-items-center gap-1">
            <Form.Control
              type="text"
              name="url"
              value={values.url}
              onChange={handleChange}
              isInvalid={!!errors.url}
              placeholder={'Website URL'}
              className="tools-form-input tools-form-url"
              disabled={trialSeoCheckupLoader}
            />
            {!isAddCompetitor ? (
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Add competitor url</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: '#3F80EA', fontSize: '24px', cursor: 'pointer' }}
                  onClick={() => setIsAddCompetitor(true)}
                />
              </OverlayTrigger>
            ) : null}
          </div>

          {!!touched.url && (
            <span className="fs-6" style={{ color: 'red' }}>
              {errors.url}
            </span>
          )}
        </Form.Group>
        {isAddCompetitor ? (
          <Form.Group>
            <Form.Control
              type="text"
              name="competitorUrl"
              value={values.competitorUrl}
              onChange={handleChange}
              isInvalid={!!errors.competitorUrl}
              placeholder={'Competitor URL'}
              className="tools-form-input tools-form-competitor"
              disabled={trialSeoCheckupLoader}
            />
            {!!touched.competitorUrl && (
              <span className="fs-6" style={{ color: 'red' }}>
                {errors.competitorUrl}
              </span>
            )}
          </Form.Group>
        ) : null}
        <Button
          disabled={trialSeoCheckupLoader}
          type="submit"
          variant="primary"
          className={`d-flex align-items-center gap-1 ${isAddCompetitor ? 'tools-submit-btn' : ''}`}
        >
          Checkup
          {trialSeoCheckupLoader ? <Spinner animation="border" key={1} size="sm" /> : null}
        </Button>
      </Form>
      <div className="tools-info">
        <h2 className="tools-info-heading">What is it?</h2>
        <p className="tools-info-description">
          This test will check if the analyzed webpage is using social media meta tags. While this type of
          meta tags don't affect what people see when they visit the webpage, they exist to provide
          information about it to search engines and social media platforms. Also, when a link is shared, both
          Facebook and Twitter will scrape the associated webpage and read its meta tags to display the
          appropriate information. Thus, social media meta tags will help to amplify your webpage's content,
          will improve brand visibility and will help to generate website traffic.
        </p>
      </div>
      <div className="tools-solution">
        <h2 className="tools-solution-heading">How do I fix it ?</h2>
        <p className="tools-solution-description">
          In order to pass this test, you'll have to add social media meta tags into your webpage's "head"
          section. Social media meta <br /> tags are snippets of HTML code that control how URLs are displayed
          when shared on social media. Facebook and Twitter are, <br /> by far, the most popular social media
          platforms, so let's focus on those two. Facebook uses meta tags leveraging the Open Graph protocol,
          which enables any web page to become a rich object in a social graph. A complete list of meta tags
          available can be found in the Open Graph website. You'll find there multiple tags and how to use
          them, but only four are required for Facebook to understand the basics of your page:
          <div className="tools-code">
            {`<meta property="og:title" content="Add title here">`}
            <br />
            {`<meta property="og:description" content="Add description here">`}
            <br />
            {`<meta property="og:image" content="https://your-website.com/og-image.png">`}
            <br />
            {`<meta property="og:url" content="https://your-website.com">`}
          </div>
          Twitter has its own meta tags that are similar to the Open Graph protocol, but uses the "twitter"
          prefix instead of "og". As with Facebook, only a few are required:
          <div className="tools-code">
            {`<meta name="twitter:title" content="Add title here">`}
            <br />
            {`<meta name="twitter:description" content="Add description here">`}
            <br />
            {`<meta name="twitter:url" content="https://your-website.com/twitter-image.png">`}
            <br />
            {`<meta name="twitter:card" content="summary">`}
          </div>
        </p>
      </div>
    </div>
  )
}

export default SocialMediaMetaTagsTest

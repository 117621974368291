import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import * as moment from 'moment'
import { Spinner } from 'reactstrap'
import ActivityGraph from './ActivityGraph'

const TwitterActivity = () => {
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink)
  const item = usersDomain && activeLink !== undefined && usersDomain[activeLink]
  let chartData = []

  item?.items.forEach((element) => {
    let data1 =
      element.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData?.valueData?.[4].replace(',', '') ||
      0
    let data2 =
      element.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData?.valueData?.[7].replace(',', '') ||
      0
    let data3 =
      element.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData?.valueData?.[5].replace(',', '') ||
      0
    chartData.push({
      name: 'Follower',
      y: parseInt(data1) || 0,
      x: moment(element.createdAt).format('MM-DD'),
    })
    chartData.push({ name: 'Tweets', y: parseInt(data2) || 0, x: moment(element.createdAt).format('MM-DD') })
    chartData.push({
      name: 'Following',
      y: parseInt(data3) || 0,
      x: moment(element.createdAt).format('MM-DD'),
    })
  })

  return (
    <>
      <Card className="flex-fill">
        <Card.Header>
          <Card.Title tag="h2" className="mb-0">
            Tweet Analysis
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="flex">
            <ActivityGraph chartData={chartData} />
          </div>
          <br />
          <hr />
          <div className="flex">
            <h6>Tweets with @mentions</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisValues?.[0],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisValues?.[0] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
          <hr />
          <div className="flex">
            <h6>Tweets with #hashtags</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisValues?.[1],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisValues?.[1] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
          <hr />
          <div className="flex">
            <h6>Tweets with links</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisValues?.[2],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisValues?.[2] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
          <hr />
          <div className="flex">
            <h6>Tweets with media</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisValues?.[3],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisValues?.[3] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
          <hr />
          <div className="flex">
            <h6>Retweets</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisValues?.[4],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisValues?.[4] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
          <hr />
          <div className="flex">
            <h6>Replies</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisValues?.[5],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisValues?.[5] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
          <hr />
          <div className="flex">
            <h6>ActivityTime</h6>
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.activityTime ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.activityTime?.[0],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.activityTime?.[2] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </Card.Body>
      </Card>

      <Card className="flex-fill">
        <Card.Body>
          <div className="flex">
            <h3>Topics</h3>
            <hr />
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisLabelValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisLabelValues?.[0],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisLabelValues?.[0] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </Card.Body>
      </Card>
      <Card className="flex-fill">
        <Card.Body>
          <div className="flex">
            <h3>Hashtags</h3>
            <hr />
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisLabelValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisLabelValues?.[1],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisLabelValues?.[1] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </Card.Body>
      </Card>
      <Card className="flex-fill">
        <Card.Body>
          <div className="flex">
            <h3>Mentioned</h3>
            <hr />
            <p>
              {item?.items?.[0]?.socialMediaTwitterData ? (
                <>
                  {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData ? (
                    <>
                      {item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                        ?.twitteAnalysisLabelValues ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                                ?.twitteAnalysisLabelValues?.[2],
                          }}
                        />
                      ) : item?.items?.[0]?.socialMediaTwitterData?.[0]?.data?.twitterData?.twitterData
                          ?.twitteAnalysisLabelValues?.[2] === '' ? (
                        ''
                      ) : (
                        <>
                          <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </p>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default TwitterActivity

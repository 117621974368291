import { useEffect } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { storeUserData } from 'redux/actions/register'
import { changeActiveLink, getUserData, patchUserDomainsDashboard } from 'redux/actions/dashboard'

import { CoreWebVitalGraph } from 'modules/CoreWebVitals/CoreWebVitalGraph.js'
import MainUrlNav from 'components/MainUrlNav'
import { Loader } from 'components/uiCore'

import 'modules/CoreWebVitals/CoreWebVitals.css'
import lcp from 'assets/img/core web vital/largest-contentful-paint.svg'
import fid from 'assets/img/core web vital/first-input-delay.svg'
import cls from 'assets/img/core web vital/cumulative-layout-shift.svg'

export const CoreWebVitals = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { usersDomains: usersDomain, activeLink } = useSelector(({ dashboard }) => dashboard)

  useEffect(() => {
    const data = localStorage.getItem('userData')
    if (data && JSON.parse(data)?._id) {
      dispatch(storeUserData(JSON.parse(data)))
      dispatch(patchUserDomainsDashboard())
      dispatch(getUserData())
    } else {
      navigate('/')
    }
  }, [])

  return (
    <Card className="mb-0">
      <Card.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0} activeKey={activeLink || 0}>
          <Nav variant="tabs" onSelect={(selectedKey) => dispatch(changeActiveLink(parseInt(selectedKey)))}>
            {usersDomain && usersDomain?.length ? (
              <>
                {usersDomain?.map((item, index) => {
                  return <MainUrlNav key={index + 1} item={item} index={index} />
                })}
              </>
            ) : (
              <></>
            )}
          </Nav>
          <Tab.Content className="mt-2">
            {usersDomain?.length ? (
              <Card>
                <Card.Body>
                  <div className="main-container">
                    <section className="cwv-images">
                      <img src={lcp} className="cwv-image" alt="Largest contentful paint" />
                      <img src={fid} className="cwv-image" alt="First input delay" />
                      <img src={cls} className="cwv-image" alt="Cumulative layout shift" />
                    </section>
                    <CoreWebVitalGraph />
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                  minHeight: 380,
                }}
              >
                <div className="text-center">
                  <Loader />
                </div>
              </div>
            )}
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  )
}

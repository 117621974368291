import * as _ from 'lodash'
import * as moment from 'moment'
import * as Icon from 'react-feather'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

import usePalette from '../../hooks/usePalette'
import { Col, Card, Row, Tooltip, OverlayTrigger } from 'react-bootstrap'
import numeral from 'numeral'

const BacklinksHeader = ({ activeLink, usersDomain }) => {
  const palette = usePalette()
  let data =
    (usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.stats1Data) ||
    []
  let currentData =
    usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.statsData?.[0]?.backlinks
      ? usersDomain[activeLink]?.statsData?.[0]?.backlinks
      : '0'
  let currentDomainData =
    usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.statsData?.[0]?.domains
      ? usersDomain[activeLink]?.statsData?.[0]?.domains
      : '0'

  let domainFlag, backlinksFlag, scoreFlag

  let data1 =
    data && data?.length
      ? data.map((item) => {
        return {
          date1: moment(item.createdAt).format('MM-DD'),
          ...item,
        }
      })
      : []

  let backlinksData = _.sortBy(data1, ['date'])

  // Calculations for backlinks
  let result =
    backlinksData && backlinksData?.length
      ? backlinksData.map((item) => {
        let currentData1 = item.backlinks
        return {
          date1: moment(item.createdAt).format('MM-DD'),
          value: parseInt(currentData1),
        }
      })
      : [
        {
          date: new Date(),
          date1: `${moment().format('MM')}-${moment().format('DD')}`,
          value: parseInt(currentData),
        },
      ]
  result = result.reverse()
  result = _.uniqBy(result, 'date1')

  // Calculations for domains
  let domainResult =
    backlinksData && backlinksData?.length
      ? backlinksData.map((item) => {
        let currentDomainData1 = item.domains
        return {
          date1: moment(item.createdAt).format('MM-DD'),
          value: parseInt(currentDomainData1),
        }
      })
      : [
        {
          date: new Date(),
          date1: `${moment().format('MM')}-${moment().format('DD')}`,
          value: parseInt(currentDomainData),
        },
      ]
  domainResult = domainResult.reverse()
  domainResult = _.uniqBy(domainResult, 'date1')
  if (domainResult && domainResult.length && domainResult.length > 1) {
    if (domainResult[0].value === domainResult[1].value) {
      domainFlag = 1
    } else if (domainResult[0].value < domainResult[1].value) {
      domainFlag = 0
    } else {
      domainFlag = 2
    }
  } else {
    domainFlag = 1
  }
  if (result && result.length && result.length > 1) {
    result = result.reverse()
    if (result[0].value === result[1].value) {
      backlinksFlag = 1
    } else if (result[0].value < result[1].value) {
      backlinksFlag = 0
    } else {
      backlinksFlag = 2
    }
  } else {
    backlinksFlag = 1
  }

  if (
    usersDomain &&
    activeLink !== undefined &&
    usersDomain[activeLink] &&
    usersDomain[activeLink]?.statsData &&
    usersDomain[activeLink]?.statsData?.[0] &&
    usersDomain[activeLink]?.statsData?.[0]?.rank
  ) {
    let currentScore = usersDomain?.[activeLink]?.statsData?.[0]?.rank
    let previousScore = usersDomain?.[activeLink]?.statsData?.[0]?.rank
    currentScore = parseInt(currentScore)
    previousScore = parseInt(previousScore)
    if (currentScore === previousScore) {
      scoreFlag = 1
    } else if (currentScore < previousScore) {
      scoreFlag = 0
    } else {
      scoreFlag = 2
    }
  } else {
    scoreFlag = 1
  }

  const getElement = (status) => {
    return (
      <FontAwesomeIcon
        color={status === 0 ? palette.danger : status === 1 ? '#afafaf' : palette.success}
        icon={status === 0 ? faArrowDown : status === 1 ? faExchangeAlt : faArrowUp}
        className="align-middle"
      />
    )
  }

  return (
    <Row className="mt-3">
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill url-details-inner">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-2 f-bold">
                  {getElement(backlinksFlag)}{' '}
                  {usersDomain &&
                    activeLink !== undefined &&
                    usersDomain[activeLink] &&
                    usersDomain[activeLink]?.statsData?.[0]?.backlinks ? (
                    numeral(usersDomain[activeLink]?.statsData?.[0]?.backlinks).format('0,0')
                  ) : (
                    <>{'-'}</>
                  )}
                </h4>

                <p className="mb-2">
                  Total Backlinks &nbsp;
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top`}>Total number of incoming links to your domain</Tooltip>
                    }
                  >
                    <Icon.AlertCircle size="16" color="#afafaf" />
                  </OverlayTrigger>
                </p>
              </div>
              <div className="d-inline-block ms-3">
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill url-details-inner">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-2 f-bold">
                  {getElement(domainFlag)}{' '}
                  {usersDomain &&
                    activeLink !== undefined &&
                    usersDomain[activeLink] &&
                    usersDomain[activeLink]?.statsData?.[0]?.domains ? (
                    numeral(usersDomain[activeLink]?.statsData?.[0]?.domains).format('0,0')
                  ) : (
                    <>{'-'}</>
                  )}
                </h4>
                <p className="mb-2">
                  Reffering Domains &nbsp;
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        The number of domains that are linking back to your domain
                      </Tooltip>
                    }
                  >
                    <Icon.AlertCircle size="16" color="#afafaf" />
                  </OverlayTrigger>
                </p>
              </div>
              <div className="d-inline-block ms-3">
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill url-details-inner">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-2 f-bold">
                  {usersDomain?.[activeLink]?.statsData?.[0]?.rank &&
                    usersDomain?.[activeLink]?.statsData?.[0]?.rank !== '0' &&
                    getElement(scoreFlag)}
                  &nbsp;{' '}
                  {usersDomain &&
                    activeLink !== undefined &&
                    usersDomain[activeLink] &&
                    usersDomain[activeLink]?.statsData?.[0]?.rank &&
                    usersDomain[activeLink]?.statsData?.[0]?.rank !== '0' &&
                    usersDomain[activeLink]?.statsData?.[0]?.rank
                    ? `${usersDomain[activeLink]?.statsData?.[0]?.rank / 10} / 100`
                    : 'No Data'}
                </h4>
                <p className="mb-2">
                  Domain Score &nbsp;
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        This metric is measuring the importance of the domain based on the volume and quality
                        of links that are pointing to it. The score is on a scale from 0 to 100, with 100
                        meaning the domain is highly important and its links communicate a strong amount of
                        popularity. This way, you can identify the most valuable domains that are linking to
                        your site, or the most frequently linking domains that are linking to your site.
                      </Tooltip>
                    }
                  >
                    <Icon.AlertCircle size="16" color="#afafaf" />
                  </OverlayTrigger>
                </p>
              </div>
              <div className="d-inline-block ms-3">
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill url-details-inner">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="mb-2 f-bold">
                  {usersDomain?.[activeLink]?.statsData?.[0]?.spamScore !== null &&
                    usersDomain[activeLink]?.statsData?.[0]?.spamScore !== undefined
                    ? getElement(scoreFlag)
                    : ''}
                  &nbsp;{' '}
                  {usersDomain &&
                    activeLink !== undefined &&
                    usersDomain[activeLink] &&
                    usersDomain[activeLink]?.statsData?.[0]?.spamScore !== null &&
                    usersDomain[activeLink]?.statsData?.[0]?.spamScore !== undefined
                    ? `${usersDomain[activeLink]?.statsData?.[0]?.spamScore} / 100`
                    : 'No Data'}
                </h4>
                <p className="mb-2">
                  Spam Score &nbsp;
                  <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip id={`tooltip-top`}>Spam Score.</Tooltip>}
                  >
                    <Icon.AlertCircle size="16" color="#afafaf" />
                  </OverlayTrigger>
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default BacklinksHeader

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Button, Card, Col, Row, Spinner } from "react-bootstrap"

import { addPaymentMethod } from "redux/actions/freetrial/startFreeTrial"
import { message } from "antd"

import visa from '../../assets/img/brands/visa_light.png'
import mastercard from '../../assets/img/brands/mastercard_light.png'
import amex from '../../assets/img/brands/american_express_light.png'
import jcb from '../../assets/img/brands/jcb_light.png'
import discover from '../../assets/img/brands/discover_light.png'
import dinner from '../../assets/img/brands/dinners_club_light.png'

const PaymentMethod = () => {
    const dispatch = useDispatch()

    const { paymentMethods, registerData } = useSelector(({ register }) => register)
    const { redirectUrlForPaymentMethod,
        addPaymentMethodLoader,
        addPaymentMethodSuccess,
        addPaymentMethodFailure,
        addPaymentMethodError
    } = useSelector(({ startFreeTrial }) => startFreeTrial)

    useEffect(() => {
        if (addPaymentMethodFailure && addPaymentMethodError && Object.keys(addPaymentMethodError)?.length) {
            message.error(addPaymentMethodError?.message)
            dispatch({ type: 'CLEAR_ADD_PAYMENT_METHOD' })
        }

        if (addPaymentMethodSuccess && redirectUrlForPaymentMethod) {
            window.open(redirectUrlForPaymentMethod, '_self')
            dispatch({ type: 'CLEAR_ADD_PAYMENT_METHOD' })
        }
    }, [addPaymentMethodError, addPaymentMethodFailure, addPaymentMethodSuccess, redirectUrlForPaymentMethod])

    const displayCard = (cardName) => {
        switch (cardName) {
            case 'visa':
                return <img src={visa} alt="card" width={60} />
            case 'amex':
                return <img src={amex} alt="american express" width={60} />
            case 'mastercard':
                return <img src={mastercard} alt="mastercard" width={60} />
            case 'jcb':
                return <img src={jcb} alt="jcb" width={60} />
            case 'discover':
                return <img src={discover} alt="discover" width={60} />
            case 'diners':
                return <img src={dinner} alt="dinner" width={60} />
            default:
                return cardName;
        }
    }
    return (
        <Card className="mb-0">
            <Card.Body>
                <Row md="12">
                    <Col md="6">
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>
                                    <h3> Attach a payment method</h3>
                                </Card.Title>
                                <hr />
                            </Card.Header>

                            <Card.Body>
                                <Alert
                                    variant="primary"
                                    className="alert-outline-coloured"
                                    key="meta-title"
                                >
                                    <div className="alert-icon" />
                                    <div className="alert-message">
                                        <span>
                                            You'll be redirected to another page where you can add your payment method by selecting 'Add Payment Method'
                                        </span>
                                    </div>
                                </Alert>
                                <div>
                                    <Button onClick={() => dispatch(addPaymentMethod({ customerId: registerData?.customerId }))}>
                                        {
                                            addPaymentMethodLoader ? (<Spinner animation="border" key={1} className="me-2 mt-1" size="sm" />) : null
                                        }
                                        Add payment method
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>
                                    <h3> Current payment method</h3>
                                </Card.Title>
                                <hr />
                            </Card.Header>
                            <Card.Body>
                                {paymentMethods?.length ? paymentMethods?.map((card) => (
                                    <p>
                                        {displayCard(card?.cardName)} **** {card?.lastFourDigit}
                                    </p>
                                )) :
                                    <Alert
                                        variant="warning"
                                        className="alert-outline-coloured"
                                        key="meta-title"
                                    >
                                        <div className="alert-icon" />
                                        <div className="alert-message">
                                            <span className="alert-for-mobile">
                                                You do not have any payment method, please add one!
                                            </span>
                                        </div>
                                    </Alert>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>)
}
export default PaymentMethod
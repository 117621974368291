import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const HtmlCompressionTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.htmlCompressionTest.heading}
      whatIsIT={freeTools.htmlCompressionTest.what}
      howToFixBeforeCode={freeTools.htmlCompressionTest.howSectionBeforeCode}  
      codeSection={freeTools.htmlCompressionTest.codeSection}
      howToFixAfterCode={freeTools.htmlCompressionTest.howSectionAfterCode}
    />
  )
}

export default HtmlCompressionTest

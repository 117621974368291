import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const JsError = () => {
  return (
    <CommonFreeTool
      heading={freeTools.jsError.heading}
      whatIsIT={freeTools.jsError.what}
      howToFixBeforeCode={freeTools.jsError.howSectionBeforeCode}
    />
  )
}

export default JsError

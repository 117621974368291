import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { CommonAlert } from 'components/uiCore'
import { Link } from 'react-router-dom'

import ModalHOC from '../tests-components/ModalHOC'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const MicroDataSchema = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [microSchemaData, setMicroSchemaData] = useState(false)

  const getDataToDisplay = (jsonld, index = 0, key) => {
    let keys = Object.keys(jsonld || {})
    let data = keys.map((innerkey, i) => {
      if (jsonld.hasOwnProperty(innerkey)) {
        if (typeof jsonld?.[innerkey] == 'object') {
          return getDataToDisplay(jsonld?.[innerkey], index + 1, innerkey)
        } else {
          // if(innerkey === '@context') {
          //     return (<div>&nbsp;</div>)
          // } else {
          return (
            <>
              <p key={`data-${i + 1}`}>
                {i === 0 ? (
                  <p style={{ paddingLeft: 20 * index }}>
                    <b>{key && key.toString().length > 2 ? key : ''}</b>{' '}
                  </p>
                ) : (
                  <></>
                )}
                <p style={{ paddingLeft: 20 * index }}>
                  <b>{innerkey}</b>:{' '}
                  {jsonld?.[innerkey].length > 100
                    ? jsonld?.[innerkey].substring(0, 50) + '...'
                    : jsonld?.[innerkey]}
                </p>
              </p>{' '}
              {i === keys.length - 1 ? <hr /> : <></>}{' '}
            </>
          )
          // }
        }
      } else {
        return ''
      }
    })
    return data
  }

  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.microDataSchema?.data?.microSchemaResults?.status ? (
            ''
          ) : data?.microDataSchema?.data?.microSchemaResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.microDataSchema?.data?.microSchemaResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : data?.microDataSchema?.data?.microSchemaResults?.status === 4 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>

      <div className="vr-detail-block">
        {data?.microDataSchema?.data?.microSchemaResults?.status === 4 ? (
          <CommonAlert
            variant={'warning'}
            key={'meta-title'}
            alertText={
              <div>
                <span>{data?.microDataSchema?.data?.microSchemaResults?.cachedMessage}</span>
                <Link to="/pages/firewall-options" style={{ textDecoration: 'underline' }} target="_blank">
                  click here
                </Link>
              </div>
            }
          />
        ) : (
          <></>
        )}
        <p>{data?.microDataSchema?.data?.microSchemaResults?.message}</p>
        {showDetail && data?.microDataSchema?.data?.microSchemaResults?.status !== 1 && data?.microDataSchema?.data?.data?.jsonld ? (
          <>
            {' '}
            <p className="text-primary cursor-pointer" onClick={() => {
              if (testType === "") {
                setMicroSchemaData(!microSchemaData)
              } else {
                dispatch(openRegisterModal(true))
              }
            }}>
              {' '}
              See Details
            </p>{' '}
          </>
        ) : (
          <></>
        )}
      </div>

      <ModalHOC
        show={microSchemaData}
        toggleShow={() => setMicroSchemaData(!microSchemaData)}
        title={'Microdata Schema Analysis'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '600', fontSize: '22px' }}
      >
        {data?.microDataSchema?.data?.data?.jsonld ? (
          <>
            <div>
              {Object.keys(data?.microDataSchema?.data?.data?.jsonld).map((key, i) => {
                if (data?.microDataSchema?.data?.data?.jsonld.hasOwnProperty(key)) {
                  if (typeof data?.microDataSchema?.data?.data?.jsonld?.[key]?.[0] == 'object') {
                    return getDataToDisplay(data?.microDataSchema?.data?.data?.jsonld?.[key]?.[0])
                  } else {
                    return (
                      <p key={`mircorData-${i + 1}`}>
                        <span>
                          {key} {'1'}: {data?.microDataSchema?.data?.data?.jsonld?.[key]}
                        </span>
                      </p>
                    )
                  }
                } else {
                  return ''
                }
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </ModalHOC>
    </>
  )
}

export default MicroDataSchema

import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Button, Card, Form, Modal } from "react-bootstrap"

import { verifyPassword } from "redux/actions/register"

import ToastMessage from "components/uiCore/ToastMessage"

import { useFormik } from "formik"
import * as Yup from 'yup'

const Security = () => {

    const { toastMessage, toastError, passwordVerificationLoading } = useSelector(({register}) => register)
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch()

      useEffect(() => {
        if(!passwordVerificationLoading && (toastError || toastMessage)){
            setShowToast(true)
        }
      }, [passwordVerificationLoading])

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      };
      const onSubmit = (values, { resetForm }) => {
        resetForm();
        setShowPasswordModal(false);
      };

      const PasswordChangeSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Current Password is required'),
        newPassword: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('New Password is required')
          .notOneOf([Yup.ref('currentPassword'), null], 'New Password must be different from Current Password')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!#%*?&]{8,20}$/,
            'Password should contain minimum 8 characters, at least one uppercase, one lowercase, one number and one special character.',
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('newPassword'), null], "Passwords doesn't match")
          .required('Confirm Password is required'),
      });

    const formik = useFormik({
        initialValues,
        validationSchema: PasswordChangeSchema,
        onSubmit,
      });

    const getToken = async () => {
        const token = await localStorage.getItem('token')        
        return token
    }

    return (
    <>
        {(toastMessage || toastError) ? <ToastMessage show={showToast} onClose={() => {setShowToast(false)}} variant={toastMessage ? "success" : toastError ? "danger" : "warning"} toastMessage={toastMessage ? toastMessage : toastError ? toastError : "Something went wrong!"} />: null}  
        {showPasswordModal ? (<Modal
           show={showPasswordModal}
           onHide={() => {
            setShowPasswordModal(false)
            dispatch({type: "VERIFY_PASSWORD_CLEAR"})
           }} 
           aria-labelledby="contained-modal-title-vcenter"
           style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
           centered
           className="br-15"
        >
            <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="currentPassword">Current Password:</Form.Label>
                <Form.Control
                  type={showPassword ? 'text' : "password"}
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Enter Current Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentPassword}
                />
                {formik.touched.currentPassword && formik.errors.currentPassword ? (
                  <span  className = "fs-6"style={{color:'red'}}>{formik.errors.currentPassword}</span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="newPassword">New Password:</Form.Label>
                <Form.Control
                  type={showPassword ? 'text' : "password"}
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter New Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <span className = "fs-6" style={{color:'red'}}>{formik.errors.newPassword}</span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="confirmPassword">Confirm Password:</Form.Label>
                <Form.Control
                  type={showPassword ? 'text' : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Re-type New Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <span className = "fs-6" style={{color:'red'}}>{formik.errors.confirmPassword}</span>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="showPassword"
                  name="showPassword"
                  onChange={() => setShowPassword(!showPassword)}
                  className="d-inline me-1"
                />
                <Form.Label htmlFor="showPassword">Show Password</Form.Label>
              </Form.Group>

              <Button type="submit" onClick={async() => {
                    if(!Object.keys(formik.errors)?.length){
                         (formik.values.currentPassword  && formik.values.newPassword && formik.values.confirmPassword) && dispatch(verifyPassword({
                            token: await getToken(),
                            password: formik.values.currentPassword,
                            newPassword: formik.values.newPassword
                        }))
                    }
              }} variant="outline-primary">
                Submit
              </Button>
            </Form>
            </Modal.Body>
        </Modal>) : null}

        <Card>
            <Card.Body>
                <p className='fs-4'>First, we need to check your current password</p>
                <Button variant="primary" onClick={() => setShowPasswordModal(true)}>Verify password</Button>
            </Card.Body>
        </Card>
    </>
)}

export default Security
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const MetaRobotsText = ({ data }) => {
  return (
    <>
      {data?.metaData?.data?.response?.robotsTestResults ? (
        <>
          {/* <h5>{data?.url}</h5> */}
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.metaData?.data?.response?.robotsTestResults?.status ? (
                ''
              ) : data?.metaData?.data?.response?.robotsTestResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.metaData?.data?.response?.robotsTestResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p> {data?.metaData?.data?.response?.robotsTestResults?.message} </p>
            <p>
              {' '}
              {data?.metaData?.data?.response?.robotsTestResults?.status === 1 ? (
                <a href="javascript:void(0)"> {data?.metaData?.data?.response?.robotsTxtUrl} </a>
              ) : (
                ''
              )}{' '}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MetaRobotsText

import { freeTools } from "constants.js"
import CommonFreeTool from "modules/Free tools/components/CommonFreeTool.jsx"

const SeoFriendlyUrlTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.seoFriendlyUrlTest.heading}
      whatIsIT={freeTools.seoFriendlyUrlTest.what}
      howToFixBeforeCode={freeTools.seoFriendlyUrlTest.howSectionBeforeCode}
    />
  )
}

export default SeoFriendlyUrlTest

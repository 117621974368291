import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'components/uiCore'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { quickSeoCheckup } from 'redux/actions/freeTools'

import video from '../../../assets/video/progressBar-scanner.mp4'
import { SEO_TESTS } from 'constants/enum'

import 'pages/dashboards/DashboardRevised/urlScanStatus.scss'
import { getActiveUserData } from 'constants/Utils'

const UrlScanStatus = ({ progressPercentage, competitorSeoPercentage, competitorsLength, lastUpdateAt }) => {
  const {
    usersDomains: usersDomain,
    activeLink,
    activeKeywordData,
    userData,
    urlScanDetails,
    seoCompetitorPercentageActual,
    testResultData,
  } = useSelector(({ dashboard }) => dashboard)

  const [testData, setTestData] = useState([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let mainUrlScanStatus
  let competitiorScanStatus

  if (urlScanDetails?.length) {
    mainUrlScanStatus = urlScanDetails?.[activeLink]?.data?.[0]?.isScanCompleted
    competitiorScanStatus = urlScanDetails?.[activeLink]?.data?.[0]?.isCompetitorScanCompleted
  } else {
    mainUrlScanStatus = false
    competitiorScanStatus = false
  }
  const competitorText = {
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Five',
  }

  const userDomainsLength = usersDomain?.length

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  const [scanningCompetitior, setScaningCompetitor] = useState({
    active: 1,
    url: activeSearchData?.competitors ? activeSearchData?.competitors[0] : '',
  })

  useEffect(() => {
    if (competitorsLength > 0 && activeSearchData) {
      if (seoCompetitorPercentageActual >= 0 && seoCompetitorPercentageActual <= 69) {
        setScaningCompetitor({
          active: 1,
          url: activeSearchData?.competitors ? activeSearchData?.competitors[0] : '',
        })
      } else if (seoCompetitorPercentageActual > 69 && seoCompetitorPercentageActual <= 138) {
        setScaningCompetitor({
          active: 2,
          url: activeSearchData?.competitors ? activeSearchData?.competitors[1] : '',
        })
      } else if (seoCompetitorPercentageActual > 138 && seoCompetitorPercentageActual <= 207) {
        setScaningCompetitor({
          active: 3,
          url: activeSearchData?.competitors ? activeSearchData?.competitors[2] : '',
        })
      } else if (seoCompetitorPercentageActual > 207 && seoCompetitorPercentageActual <= 276) {
        setScaningCompetitor({
          active: 4,
          url: activeSearchData?.competitors ? activeSearchData?.competitors[3] : '',
        })
      } else if (seoCompetitorPercentageActual > 276) {
        setScaningCompetitor({
          active: 5,
          url: activeSearchData?.competitors ? activeSearchData?.competitors[4] : '',
        })
      }
    }
  }, [seoCompetitorPercentageActual])

  useEffect(() => {
    if (testResultData && testResultData?.length) {
      const activeLinkTests = testResultData?.[activeLink]?.results?.[0]
      const testResultKeys =
        activeLinkTests && Object.keys(activeLinkTests)?.length ? Object.keys(activeLinkTests) : []
      const seoTestKeys = Object.keys(SEO_TESTS)

      const notScannedTests = seoTestKeys?.filter((testKey) => !testResultKeys?.includes(testKey))

      if (notScannedTests && notScannedTests?.length) {
        setTestData(notScannedTests)
      }
    }
  }, [activeLink, testResultData])

  const random = testData[Math.floor(Math.random() * testData.length)]

  const runReportNow = () => {
    if (
      usersDomain &&
      activeLink > -1 &&
      usersDomain?.[activeLink] &&
      usersDomain?.[activeLink]?.items &&
      usersDomain?.[activeLink]?.items?.length &&
      usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
    ) {
      const urlForCheckup = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
      const userEnteredKeywords =
        (activeKeywordData &&
          activeKeywordData?.data &&
          activeKeywordData?.data.length &&
          activeKeywordData?.data[activeKeywordData?.data.length - 1]?.dataset &&
          activeKeywordData?.data[activeKeywordData?.data.length - 1]?.dataset.map((item) => item.keyword)) ||
        []
      dispatch(quickSeoCheckup({ url: urlForCheckup, keywords: userEnteredKeywords }))
      setTimeout(() => {
        navigate({
          pathname: `/dashboard/seo/analysis?url=${urlForCheckup}`,
          search: '',
          state: { url: urlForCheckup },
        })
      }, 1000)
    }
  }

  return (
    <Col xs="12 mb-4" lg="4">
      <div className="url-scan-container">
        <div className="scanning-title">
          {/* scanning title */}
          <div className="w-100 spacing-wo-border">
            <Col xs="12" className="d-flex direction-row justify-content-end">
              <p className="mb-0 fs-4">
                {progressPercentage >= 100 ? (
                  competitorsLength > 0 &&
                  competitorSeoPercentage < 100 &&
                  urlScanDetails?.length > 0 &&
                  !competitiorScanStatus ? (
                    <>
                      Website Scanned! Now scanning your competitors...{' '}
                      <span className="text-primary f-bold">
                        {progressPercentage > 100 ? 100 : progressPercentage}%
                      </span>
                    </>
                  ) : (
                    <>
                      Congratulations, Website Scanned!{' '}
                      <span className="text-primary f-bold">
                        {progressPercentage > 100 ? 100 : progressPercentage}%
                      </span>
                    </>
                  )
                ) : urlScanDetails?.length > 0 && !mainUrlScanStatus ? (
                  <>
                    Scanning{' '}
                    {activeSearchData?.url?.length > 18
                      ? activeSearchData?.url?.slice(0, 19) + '...'
                      : activeSearchData?.url}
                    . this will just take a minute!{' '}
                    <span className="text-primary">
                      {progressPercentage > 100
                        ? '100%'
                        : progressPercentage > 0
                          ? `${progressPercentage}%`
                          : null}
                    </span>
                  </>
                ) : null}
              </p>
            </Col>
          </div>
          {/* progressPercentage */}
          <div className="w-100 spacing-wo-border">
            <Col xs="12 pb-0">
              <ProgressBar
                style={{ borderRadius: 0 }}
                variant="primary"
                now={progressPercentage > 100 ? 100 : progressPercentage}
              />
            </Col>
          </div>
        </div>

        {/* vedio competitor scan */}
        <Row className="w-100 d-flex flex-row justify-content-end">
          <Col xs={3}>
            {competitorsLength > 0 ? (
              ((progressPercentage < 100 && progressPercentage > 0) || competitorSeoPercentage < 100) &&
              urlScanDetails?.length > 0 &&
              (!mainUrlScanStatus || !competitiorScanStatus) ? (
                <video
                  autoPlay
                  loop
                  width="100%"
                  height="100%"
                  muted
                  src={video}
                  playsInline
                  controlsList="nodownload noremoteplayback nofullscreen"
                  disableremoteplayback
                />
              ) : null
            ) : progressPercentage < 100 && progressPercentage > 0 ? (
              <video
                autoPlay
                loop
                width="100%"
                height="100%"
                muted
                src={video}
                playsInline
                controlsList="nodownload noremoteplayback nofullscreen"
                disableremoteplayback
              />
            ) : null}
          </Col>
          <Col xs={9} className="text-end d-flex flex-column justify-content-between align-items-end">
            {/* redo scan */}
            <Row className="w-100 px-0 pe-1 d-flex flex-column align-items-end">
              {progressPercentage >= 100 ? (
                <>
                  <p className="mb-0 d-flex flex-row-reverse p-0">Full Scan Completed at {lastUpdateAt}</p>
                </>
              ) : urlScanDetails?.length > 0 &&
                !mainUrlScanStatus &&
                usersDomain &&
                usersDomain?.length &&
                progressPercentage < 100 ? (
                <div className="w-100 d-flex flex-row-reverse">
                  <p className="text-muted text-success mb-0">Scanning {SEO_TESTS[random]}...</p>
                </div>
              ) : null}
            </Row>
            <Row className="w-100 d-flex justify-content-end mt-3">
              {competitorSeoPercentage >= 0 &&
              competitorSeoPercentage < 100 &&
              competitorsLength > 0 &&
              urlScanDetails?.length > 0 &&
              !competitiorScanStatus &&
              usersDomain &&
              usersDomain.length ? (
                <>
                  <Row className="w-100 d-flex align-items-end px-0 pe-1">
                    <div className="d-flex flex-column align-items-end">
                      <p className="mb-0 fs-4 f-bold">
                        Competitor scan {competitorText[scanningCompetitior?.active]} out of{' '}
                        {competitorText[competitorsLength]}
                      </p>
                      <p className="mb-0 fs-4">
                        {scanningCompetitior?.url
                          ? scanningCompetitior?.url?.length > 25
                            ? scanningCompetitior?.url.slice(0, 26) + '...'
                            : scanningCompetitior?.url
                          : null}
                      </p>
                      <div className="text-center">
                        <Loader size="sm" />
                      </div>
                    </div>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          {competitorSeoPercentage >= 100 && competitorsLength > 0 ? (
            <Row
              className="w-100 ms-auto d-flex flex-row align-items-end"
              style={{
                marginTop:
                  (userDomainsLength === 1 || userDomainsLength === 3 || userDomainsLength === 5) &&
                  progressPercentage >= 100
                    ? '2.5rem'
                    : null,
              }}
            >
              <div className="spacing-without-border">
                <Col xs="12 d-flex flex-row justify-content-end pe-1">
                  <p className="mb-0 fs-4 f-bold text-end">Competitor Scan Completed, Dashboard Updated!</p>
                </Col>
              </div>
            </Row>
          ) : null}
        </Row>
      </div>
    </Col>
  )
}
export default UrlScanStatus

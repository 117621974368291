import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'

const SocialMediaTest = ({
  data,
  twitterURL,
  instaURL,
  instagramData,
  twitterData,
  facebookURL,
  facebookData,
  linkedInURL,
  linkedInData,
}) => {
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {instagramData || twitterData || facebookData || linkedInData ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
          {' '}
          {instagramData || twitterData || facebookData || linkedInData
            ? 'This webpage is connected successfully with social media using:'
            : `This webpage is not connected with social media using the API's provided by Twitter or Instagram or Facebook or LinkedIn`}{' '}
        </p>
        <p>
          {' '}
          {twitterData ? (
            <a href={twitterURL} target="_BLANK">
              {' '}
              Twitter
            </a>
          ) : (
            <></>
          )}{' '}
          {instagramData ? (
            <a href={instaURL} target="_BLANK">
              {' '}
              Instagram
            </a>
          ) : (
            <></>
          )}{' '}
          {facebookData ? (
            <a href={facebookURL} target="_BLANK">
              {' '}
              Facebook
            </a>
          ) : (
            <></>
          )}{' '}
          {linkedInData ? (
            <a href={linkedInURL} target="_BLANK">
              {' '}
              LinkedIn
            </a>
          ) : (
            <></>
          )}{' '}
        </p>
      </div>
    </>
  )
}

export default SocialMediaTest

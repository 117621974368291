import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'


const FirstContentfulPaint = ({ data }) => {
  const statusCode = {
  1:tickIcon,
  2:warningIcon,
  3:crossIcon
  } 
  const { firstContentfulPaintTest,url } = data;
  return (
    <>
      {data && firstContentfulPaintTest ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={statusCode[firstContentfulPaintTest?.data?.testResults?.status]} />
            </div>
            <div>
              <h5>{url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
              {firstContentfulPaintTest?.data?.testResults?.message}
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              <ul>
                {firstContentfulPaintTest?.data?.finalData?.res_issues &&
                  firstContentfulPaintTest?.data?.finalData?.res_issues.length ? (
                  firstContentfulPaintTest?.data?.finalData?.res_issues.map((item) => {
                    return <li>{item?.rule || ''}</li>
                  })
                ) : (
                  null
                )}
              </ul>
            </div>
          </div>
        </>
      ) : (
        null
      )}
    </>
  )
}

export default FirstContentfulPaint

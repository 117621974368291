import CommonFreeTool from '../CommonFreeTool'
import { freeTools } from 'constants.js'

const MetaTitle = () => {
  return (
    <CommonFreeTool
      heading={freeTools.metaTitle.heading}
      whatIsIT={freeTools.metaTitle.what}
      howToFixBeforeCode={freeTools.metaTitle.howSectionBeforeCode}
      codeSection={freeTools.metaTitle.codeSection}
      howToFixAfterCode={freeTools.metaTitle.howSectionAfterCode}
    />
  )
}

export default MetaTitle

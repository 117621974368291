import { useEffect, useState, useRef } from 'react'
import {
  Card,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Spinner,
  Tab,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from 'react-bootstrap'
import { MoreHorizontal } from 'react-feather'
import {
  faChartArea,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faExchangeAlt,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from 'react-feather'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import * as _ from 'lodash'
import { GlobalOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { faFileCsv, faDownload } from '@fortawesome/free-solid-svg-icons'

import {
  changeActiveLink,
  getUserData,
  patchUserDomainsDashboard,
  saveRegionalLocation,
  getBacklinksStats,
  getSiteOutageData,
} from '../../../redux/actions/dashboard'
import { Loader } from 'components/uiCore'
import '../ReportsTable/reportsTable.scss'
import ModalContainer from '../../../components/modal'
import {
  getNewTopKeywords,
  getTopKeywordsLocation,
  storeNewTopKeywords,
  getNumberOfResultApi,
  getUpdatedKeywords,
  getAvgPositionKeywordApi,
} from '../../../redux/actions/topKeywords'
import NewTopKeywordsTrendsChart from './newTopKeywordsTredChart'
import { getLocations } from '../../../redux/actions/freetrial/startFreeTrial'
import { getActiveUserData, getDifficulty, isMainUrlMatch } from 'constants/Utils'
import { useCSVDownloader } from 'react-papaparse'
import { parse } from 'json2csv'
import { getAbbr } from 'constants/Utils'
import paths from 'constants/paths'
import MainUrlNav from 'components/MainUrlNav'

const NewTopKeywords = () => {
  const [openModal, setOpenModal] = useState(false)
  const [loader, setLoader] = useState(true)
  const [openTrendsModal, setOpenTrendsModal] = useState(false)
  const [activeTrendsData, setActiveTrendsData] = useState([])
  const [regionalCountry, setRegionalCountry] = useState('')
  const [selectedRegion, setSelectedRegion] = useState()
  const [countriesOptions, setCountriesOptions] = useState('Canada')
  const [isRegionChanged, setIsRegionChanged] = useState(false)
  const [topKeywordList, setTopKeywordList] = useState()
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [notLoadMoreLoader, setNotLoadMoreLoader] = useState(false)
  const [allSortType, setAllSortType] = useState()
  const [avgKeywordPositionData, setAvgKeywordPositionData] = useState(0)
  const [unchangedKeywordPositionData, setUnchangedKeywordPositionData] = useState(0)
  const [increasedKeywordPositionData, setIncreasedKeywordPositionData] = useState(0)
  const [decreasedKeywordPositionData, setDecreasedKeywordPositionData] = useState(0)

  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink)
  const topKeywordsData = useSelector(({ topKeywords }) => topKeywords?.newData)
  const getTopKeywordLoader = useSelector(({ topKeywords }) => topKeywords?.getNewTopKeywordLoader)
  const userData = useSelector(({ dashboard }) => dashboard?.userData)
  const countries = useSelector(({ topKeywords }) => topKeywords.topKeywordLocationData)
  const locations = useSelector(({ startFreeTrial }) => startFreeTrial.locations)
  const averagePositionMasterData = useSelector(({ topKeywords }) => topKeywords?.avgPosition)
  const saveRegionalLocationSuccess = useSelector(
    ({ keywordsTracking }) => keywordsTracking.saveRegionalLocationSuccess,
  )
  const storeNewTopKeywordsLoaderFailure = useSelector(
    ({ topKeywords }) => topKeywords.storeNewTopKeywordsLoaderFailure,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { CSVDownloader, Type } = useCSVDownloader()

  let interval = useRef()

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  useEffect(() => {
    async function fetchMyapi() {
      const data = await localStorage.getItem('userData')
      dispatch(getLocations())
      if (data && JSON.parse(data)._id) {
        dispatch(getUserData())
        dispatch(patchUserDomainsDashboard())
        dispatch(getNewTopKeywords({ userId: JSON.parse(data)._id, limit: 10, skip: 0 }))
        dispatch(getAvgPositionKeywordApi())
        setTimeout(() => {
          dispatch(patchUserDomainsDashboard())
          dispatch(getBacklinksStats())
          dispatch(getUserData())
          dispatch(getSiteOutageData())
          dispatch(getAvgPositionKeywordApi())
        }, 2000)
      }
    }
    fetchMyapi()
  }, [])

  useEffect(() => {
    if (
      usersDomain &&
      activeLink > -1 &&
      usersDomain[activeLink] &&
      usersDomain[activeLink]?.items &&
      usersDomain[activeLink]?.items?.length &&
      averagePositionMasterData?.length > 0
    ) {
      let data = averagePositionMasterData?.filter((element) => {

        const mainUrl = element?.url
        const redirectUrl = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        
        const isUrlMatched = isMainUrlMatch(mainUrl, redirectUrl)
        return isUrlMatched
      })
      setAvgKeywordPositionData(data?.[0]?.data?.[0]?.avgPosition)
      setUnchangedKeywordPositionData(data?.[0]?.data?.[0]?.unchanged)
      setIncreasedKeywordPositionData(data?.[0]?.data?.[0]?.increased)
      setDecreasedKeywordPositionData(data?.[0]?.data?.[0]?.decreased)
    }
  }, [usersDomain, activeLink, averagePositionMasterData])

  useEffect(() => {
    let country =
      countries && countries.length
        ? countries.map((item) => {
            return { label: item?.location_name, value: item?.location_name }
          })
        : []

    setCountriesOptions(country)
  }, [countries])


  useEffect(() => {
    if (storeNewTopKeywordsLoaderFailure) {
      clearInterval(interval.current)
      setIsFilterApplied(false)
      setTopKeywordList([])
      dispatch({ type: 'CLEAR_STORE_NEW_TOP_KEYWORD' })
    }
  }, [storeNewTopKeywordsLoaderFailure])

  useEffect(() => {
    if (usersDomain && usersDomain.length && topKeywordsData && topKeywordsData.length) {
      setTopKeywordList([])
      setRegionalCountry(activeSearchData?.topKeywordregionalCountry)
      if (allSortType === undefined) {
        let allSort = usersDomain.map((domain) => {
          if (
            domain &&
            domain?.items?.length &&
            domain?.items?.[0]?.information?.seoCheckup?.redirect_url?.length
          ) {
            return {
              url: domain?.items?.[0]?.information?.seoCheckup?.redirect_url,
              sortType: 'high-traffic',
            }
          }
        })
        setAllSortType(allSort)
      }
      // get-all-top-keywords
      let data = topKeywordsData.filter((element) => {
        const mainUrl = element?.url
        const redirectUrl = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        const isUrlMatched = isMainUrlMatch(mainUrl, redirectUrl)
        return (
          activeLink !== undefined &&
          usersDomain[activeLink] &&
          usersDomain[activeLink].items &&
          usersDomain[activeLink].items.length &&
          isUrlMatched
        )
      })

      if (data.length && data[0]?.data?.[0]?.region === activeSearchData?.topKeywordRegionalRegion) {
        if (data[0]?.data?.[0]?.region === activeSearchData?.topKeywordRegionalRegion) {
          // clearInterval(interval.current);
        }
        /* eslint-disable no-unused-vars */
        let positive = 0
        let negative = 0
        let unChanged = 0
        /* eslint-disable no-unused-vars */

        usersDomain[activeLink].items[0].trackingData = data[0]?.data
        setTopKeywordList(data[0]?.data)
        setLoader(false)
        setIsFilterApplied(false)
        usersDomain[activeLink].items[0].region = data?.[0]?.data?.[0]?.region || 'ca'
        let x =
          data?.[0]?.data &&
          data[0]?.data.length &&
          data[0]?.data.map((item) => {
            return {
              ...item,
              position: parseInt(item.position),
            }
          })
        let y = _.orderBy(x, 'position', 'asc')
        data?.[0]?.data &&
          data[0]?.data.length &&
          data[0]?.data.forEach((item, index) => {
            if (index < 101) {
              if (item?.keywordPosition?.positive) {
                positive++
              }
              if (item?.keywordPosition?.negative) {
                negative++
              }
              if (item?.keywordPosition?.unchanged) {
                unChanged++
              }
            }
          })
      } else {
        setLoader(false)
      }
    } else {
      setRegionalCountry(activeSearchData?.topKeywordregionalCountry)
      setLoader(false)
    }
  }, [usersDomain, activeLink, topKeywordsData, userData])

  useEffect(() => {
    if (saveRegionalLocationSuccess) {
      const data = localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        dispatch(getUserData())
        interval.current = setInterval(() => {
          dispatch(
            getNewTopKeywords({
              userId: JSON.parse(data)._id,
              limit: 10,
              skip: 0,
              sort: JSON.stringify(allSortType),
            }),
          )
          dispatch(getAvgPositionKeywordApi())
        }, 5000)
      }
      setNotLoadMoreLoader(true)
      setSelectedRegion('')
      setTimeout(() => {
        handleClose()
        setIsRegionChanged(false)
        dispatch({ type: 'CLEAR_REGIONAL_LOCATION_CONFIGURATION' })
      }, 2000)
    }
  }, [saveRegionalLocationSuccess])

  useEffect(() => {
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  const getLocationData = (country, state, city) => {
    let region = 'CA'
    let long = '43.7417'
    let lat = '-79.3733'
    let iso3 = 'CAN'

    let locationData =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === country && item.city === city && item.state === state
          })
        : []

    if (locationData && locationData.length) {
      region = locationData[0].countryShortCodeISO2
      long = locationData[0].long
      lat = locationData[0].lat
      iso3 = locationData[0].countryShortCodeISO3
    }
    return { region, long, lat, iso3, country, state, city }
  }

  const activeDomainUrl =
    usersDomain &&
    activeLink !== undefined &&
    usersDomain[activeLink] &&
    usersDomain[activeLink].items &&
    usersDomain[activeLink].items.length &&
    usersDomain[activeLink].items[0].url

  const sortTopKeywordData = async (sortType) => {
    if (sortType !== '') {
      let tempAllSortTypeData = allSortType
      tempAllSortTypeData?.length &&
        tempAllSortTypeData.forEach((data) => {

          const mainUrl = data?.url
          const redirectUrl = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
          const isUrlMatched = isMainUrlMatch(mainUrl, redirectUrl)

          if (isUrlMatched) {
            data['sortType'] = sortType
          }
        })
      setAllSortType([])
      setAllSortType(tempAllSortTypeData)

      clearInterval(interval.current)
      setNotLoadMoreLoader(false)
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        setIsFilterApplied(true)
        dispatch(
          getNewTopKeywords({
            userId: JSON.parse(data)._id,
            limit: topKeywordList?.length,
            skip: 0,
            sort: JSON.stringify(tempAllSortTypeData),
          }),
        )
      }
    }
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleShow = () => setOpenModal(true)

  const saveRegionConfiguration = async () => {
    // let region = [];
    clearInterval(interval.current)
    let currentUrl = usersDomain[activeLink].items?.[0]?.information?.seoCheckup?.redirect_url
    setIsRegionChanged(true)
    // let newLocation = getLocationData(selectedRegion, selectedState, selectedCity);
    // region.push(newLocation);
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      dispatch(
        saveRegionalLocation({
          userId: JSON.parse(data)._id,
          url: currentUrl,
          country: selectedRegion,
          region: getAbbr(selectedRegion, locations),
          type: 'top-keywords',
        }),
      )
      setTimeout(() => {
        dispatch(
          storeNewTopKeywords({
            url: currentUrl,
            region: getAbbr(selectedRegion, locations),
            countryName: selectedRegion,
          }),
        )
      }, 3500)
      setIsFilterApplied(true)
    }
  }

  const loadMore = async () => {
    clearInterval(interval.current)
    setNotLoadMoreLoader(false)
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      dispatch(
        getNewTopKeywords({
          userId: JSON.parse(data)._id,
          limit: topKeywordList?.length + 10,
          skip: 0,
          sort: JSON.stringify(allSortType),
        }),
      )
    }
  }

  const getNumberOfResult = async (keyword, keywordIndex) => {
    const data = await localStorage.getItem('userData')
    let currentUrl = usersDomain[activeLink].items?.[0]?.information?.seoCheckup?.redirect_url
    if (data && JSON.parse(data)._id) {
      // setKeyword((prevSetKeyword) => ({
      //   ...prevSetKeyword,
      //   [keywordIndex]: true,
      // }));

      const updatedKeyword = topKeywordList.map((item, index) => {
        if (item.keyword === keyword && index === keywordIndex) {
          return {
            ...item,
            loading: true,
          }
        } else {
          return item
        }
      })
      setTopKeywordList(updatedKeyword)
      dispatch(getUpdatedKeywords({ url: currentUrl, keywordsList: updatedKeyword }))
      dispatch(
        getNumberOfResultApi({
          url: currentUrl,
          keyword: keyword,
          region: topKeywordList?.[0]?.region,
          userId: JSON.parse(data)._id,
        }),
      )
    }
  }

  const getTopKeywordLocationData = async () => {
    setSelectedRegion('')
    dispatch(getTopKeywordsLocation())
  }

  const findPositionChange = (increased, decreased, unchanged) => {
    if (increased > 0) {
      return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
    } else if (decreased < 0) {
      return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
    } else if (unchanged) {
      return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
    }
  }

  const downloadJSON = async () => {
    if (topKeywordList && topKeywordList?.length) {
      const jsonData = JSON.stringify(topKeywordList)
      const url = `data:text/json;charset=utf-8,${encodeURIComponent(jsonData)}`
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'top-keyword.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const message =
    'Please select the location of your primary target audience - our keyword tracking tool will retrieve region specific data from the appropriate keyword database'

  return (
      <Card className='mb-0'>
        <Card.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0}>
            <Nav
              variant="tabs"
              onSelect={(selectedKey) => {
                dispatch(changeActiveLink(parseInt(selectedKey)))
              }}
            >
              {usersDomain && usersDomain?.length ? (
                <>
                  {usersDomain?.map((item, index) => {
                    return <MainUrlNav index={index} item={item} key={index+1} />
                  })}
                  </>
              ) : null }
            </Nav>

            <ModalContainer
              show={openModal}
              onHide={handleClose}
              toggleShow={() => setOpenModal(!openModal)}
              showRegional={openModal}
              headerTitle={'Regional database:'}
              message={message}
              title={`${getAbbr(regionalCountry, locations)}`}
              titleStyles={{ fontWeight: '500', fontSize: '16px' }}
            >
              <div className="pl-1 pt-3">
                <h6>Country</h6>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={countriesOptions}
                  isSearchable
                  onChange={(e) => {
                    setSelectedRegion(e.value)
                  }}
                />
              </div>
              <div className="d-flex justify-content-center mt-5 mb-55">
                <div>
                  {/* !selectedRegion?.length || !selectedState?.length || !selectedCity?.length */}
                  <Button
                    onClick={() => saveRegionConfiguration()}
                    disabled={!selectedRegion?.length}
                    className="w-20 btn-primary"
                    variant="primary"
                  >
                    {isRegionChanged ? (
                      <Spinner animation="border" key={1} className="me-2" size="sm" />
                    ) : (
                      <></>
                    )}{' '}
                    Change
                  </Button>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div>
                  <Button
                    onClick={() => setOpenModal(!openModal)}
                    className="w-20 mr-20 btn-primary"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </ModalContainer>

            <Modal
              show={openTrendsModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                setOpenTrendsModal(false)
              }}
              style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
              className="br-15"
            >
              <Modal.Header>
                <h3>Trends Data</h3>
              </Modal.Header>
              <Modal.Body>
                <NewTopKeywordsTrendsChart chartData={activeTrendsData} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  color="primary"
                  onClick={() => {
                    setOpenTrendsModal(false)
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Tab.Content>
              <Row style={{ marginTop: 22 }}>
                {usersDomain && usersDomain?.length && !loader ? (
                  !isFilterApplied ? (
                    <>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Average Position </h4>

                                {topKeywordList?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                      <VerticalAlignMiddleOutlined style={{ fontSize: '175%' }} />
                                    </div>
                                    <div>
                                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                        {avgKeywordPositionData} &nbsp;{' '}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Increased </h4>
                                {topKeywordList?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginBottom: 4 }}>
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleUp}
                                        color={'#20c997'}
                                        className="align-middle"
                                      />
                                    </div>
                                    <div>
                                      <h3
                                        style={{ color: '#20c997', marginLeft: 5, marginTop: 5 }}
                                        className="mb-2 f-bold"
                                      >
                                        {increasedKeywordPositionData} &nbsp;{' '}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Unchanged </h4>
                                {topKeywordList?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faExchangeAlt}
                                        className="align-middle"
                                      />
                                    </div>
                                    <div>
                                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                        {unchangedKeywordPositionData} &nbsp;{' '}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Dropped </h4>
                                {topKeywordList?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginBottom: 4 }}>
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleDown}
                                        color={'#d9534f'}
                                        className="align-middle"
                                      />
                                    </div>
                                    <div>
                                      <h3
                                        style={{ color: '#d9534f', marginLeft: 5, marginTop: 5 }}
                                        className="mb-2 f-bold"
                                      >
                                        {decreasedKeywordPositionData} &nbsp;
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flex: 1,
                          alignSelf: 'center',
                          flexDirection: 'row',
                          minHeight: 180,
                        }}
                      >
                        <div className="text-center">
                          <Loader />
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
              </Row>
              {usersDomain && usersDomain.length && !loader ? (
                usersDomain.map((item, index) => {
                  return (
                    <Tab.Pane eventKey={index}>
                      <Row>
                        <Col>
                          <Card className="archived-report">
                            <Card.Header className='topkeyword-header'>
                              <Card.Title tag="h5" className="keyword-header d-flex flex-wrap justify-content-between gap-2">
                                <div className='topkeyword-title'>
                                  <h3 className='f-bold'>Top Keywords</h3>
                                  <div>
                                    <h6 className="card-subtitle text-muted">
                                      {item?.items?.length && item?.items?.[0]?.url}
                                    </h6>
                                  </div>
                                </div>
                                <div className="keyword-header d-flex flex-wrap gap-2">
                                  <div>
                                    <Form.Select
                                      name="state"
                                      id="state"
                                      className="form-control-select"
                                      // value={selectedSortType}
                                      onChange={(e) => sortTopKeywordData(e.target.value)}
                                    >
                                      <option value={'high-traffic'}>High Traffic</option>
                                      <option value={'high-position'}>High Position</option>
                                      <option value={'low-traffic'}>Low Traffic</option>
                                      <option value={'low-position'}>Low Position</option>
                                    </Form.Select>
                                  </div>
                                  <div>
                                    <Button className="keyword-header-buttons" onClick={handleShow}>
                                      <span
                                        onClick={() => {
                                          getTopKeywordLocationData()
                                        }}
                                        className="keyword-header-buttons-content"
                                      >
                                        <GlobalOutlined /> &nbsp;{getAbbr(regionalCountry, locations)}
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                              </Card.Title>
                            </Card.Header>

                            {!getTopKeywordLoader && !isFilterApplied ? (
                              <Table striped responsive>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th style={{ wordBreak: 'break-word', width: '10%' }}>Keyword</th>
                                    <th>
                                      Position&nbsp;&nbsp;
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            {' '}
                                            Search engine results position for a particular keyword for your
                                            domain.
                                          </Tooltip>
                                        }
                                      >
                                        <Icon.AlertCircle size="16" color="#afafaf" />
                                      </OverlayTrigger>
                                    </th>
                                    <th>
                                      Traffic&nbsp;&nbsp;
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            {' '}
                                            The proportion of visitors coming to your domain from a particular
                                            keyword as compared to all visitors of your domain.
                                          </Tooltip>
                                        }
                                      >
                                        <Icon.AlertCircle size="16" color="#afafaf" />
                                      </OverlayTrigger>
                                    </th>
                                    <th>
                                      Difficulty(%)&nbsp;&nbsp;
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            {' '}
                                            An estimate of how difficult it would be to rank well in organic
                                            search results for a particular keyword. The higher the
                                            percentage, the harder it is to achieve high rankings for the
                                            given keyword.
                                          </Tooltip>
                                        }
                                      >
                                        <Icon.AlertCircle size="16" color="#afafaf" />
                                      </OverlayTrigger>
                                    </th>
                                    <th>
                                      Search Volume&nbsp;&nbsp;
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            {' '}
                                            The average number of search queries for a particular keyword per
                                            month.
                                          </Tooltip>
                                        }
                                      >
                                        <Icon.AlertCircle size="16" color="#afafaf" />
                                      </OverlayTrigger>
                                    </th>
                                    <th>
                                      CPC&nbsp;&nbsp;
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            {' '}
                                            The average price (in US dollars) advertisers pay for a user's
                                            click on an AdWords ad containing a particular keyword.
                                          </Tooltip>
                                        }
                                      >
                                        <Icon.AlertCircle size="16" color="#afafaf" />
                                      </OverlayTrigger>
                                    </th>
                                    <th>
                                      Trends&nbsp;&nbsp;
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            {' '}
                                            The interest of searchers in a given keyword during the period of
                                            12 months. This metric helps you understand the seasonality of
                                            your target audience and predict the seasonal spikes.
                                          </Tooltip>
                                        }
                                      >
                                        <Icon.AlertCircle size="16" color="#afafaf" />
                                      </OverlayTrigger>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {topKeywordList?.length > 0 &&
                                    topKeywordList.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{item?.keyword || '-'}</td>
                                          <td className="d-md-table-cell">
                                            {item?.position ? (
                                              <>
                                                {
                                                  <span>
                                                    {findPositionChange(
                                                      item?.increased,
                                                      item?.decreased,
                                                      item?.unchanged,
                                                    )}
                                                  </span>
                                                }
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            &nbsp; {item?.position || '-'} &nbsp;{' '}
                                            {item?.increased ? (
                                              <>({`+${item?.increased}`})</>
                                            ) : item?.decreased ? (
                                              <>({item?.decreased})</>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td className="d-md-table-cell">
                                            {typeof item?.traffic === 'number'
                                              ? Math.floor(item?.traffic)
                                              : '-'}
                                          </td>
                                          <td>{getDifficulty(item?.competition)}</td>
                                          <td className="d-md-table-cell">
                                            {Number(item?.volumn).toLocaleString('en-US') || '-'}
                                          </td>
                                          <td>{item?.cpc}</td>
                                          <td className="d-md-table-cell">
                                            {item?.trends ? (
                                              <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  setOpenTrendsModal(true)
                                                  setActiveTrendsData(item?.trends)
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faChartArea}
                                                  className="align-middle"
                                                />
                                              </span>
                                            ) : (
                                              <FontAwesomeIcon icon={faChartArea} className="align-middle" />
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </Table>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 380,
                                  }}
                                >
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </div>
                              </>
                            )}
                            {topKeywordList?.length && !isFilterApplied ? (
                              <div className="backlink-csv-setion backlinks-loader-mobile">
                                {topKeywordList?.length > 9 && topKeywordList?.length % 10 === 0 ? (
                                  <div style={{ marginBottom: 12, marginTop: 18 }}>
                                    <Button
                                      disabled={getTopKeywordLoader && !notLoadMoreLoader}
                                      onClick={() => loadMore()}
                                      variant="primary"
                                    >
                                      {' '}
                                      {getTopKeywordLoader && !notLoadMoreLoader ? (
                                        <Spinner animation="border" size="sm" className="me-2" />
                                      ) : (
                                        <></>
                                      )}{' '}
                                      Load More{' '}
                                    </Button>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="backlink-csv-setion backilnks-for-mobile" style={{marginTop: 18}}>
                                  <div>
                                    <CSVDownloader
                                      type={Type.Link}
                                      filename={'top-keywords'}
                                      bom={true}
                                      data={
                                        topKeywordList && topKeywordList?.length ? (
                                          parse(topKeywordList)
                                        ) : (
                                          <></>
                                        )
                                      }
                                    >
                                      <Button
                                        disabled={getTopKeywordLoader && !notLoadMoreLoader}
                                        variant="secondary"
                                        outine
                                        className="me-1 mb-1"
                                      >
                                        <FontAwesomeIcon icon={faFileCsv} /> Export CSV
                                      </Button>
                                    </CSVDownloader>
                                  </div>
                                  <div>
                                    <Button
                                      disabled={getTopKeywordLoader && !notLoadMoreLoader}
                                      variant="secondary"
                                      onClick={downloadJSON}
                                      outine
                                      className="me-1 mb-1"
                                    >
                                      <FontAwesomeIcon icon={faDownload} /> Export JSON
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </Card>
                          {topKeywordList?.length == 0 && !isFilterApplied && (
                            <div className="no-data-text" style={{fontWeight:'400'}}>No Data Available.</div>
                          )}
                        </Col>
                      </Row>
                    </Tab.Pane>
                  )
                })
              ) : (

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      alignSelf: 'center',
                      flexDirection: 'row',
                      minHeight: 380,
                    }}
                  >
                    <div className="text-center">
                      <Loader />
                    </div>
                  </div>
              )}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
  )
}
export default NewTopKeywords

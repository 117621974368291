import React from 'react'
import { Outlet } from 'react-router-dom'

import { Col } from 'react-bootstrap'

import Main from '../components/Main'
import Header from 'components/Header/Header'
import { useDeviceCheck } from 'hooks/deviceHook'

const RegisterLayout = ({ children }) => {
  const { isMobile } = useDeviceCheck()

  return (
    <React.Fragment>
      <Header />
      <Main className="w-100 registerContainer">
        <div className={`h-100 ${isMobile ? '' : 'register-row'}`}>
          <Col sm="12" md="12" lg="10" className="d-table h-100">
            <div className={`d-table-cell align-middle ${isMobile ? "layout-alignment" : ''}`}>
              {children}
              <Outlet />
            </div>
          </Col>
        </div>
      </Main>
    </React.Fragment>
  )
}

export default RegisterLayout

import AddWebsiteMain from '../../modules/welcomeBoard/components/addWebsite/AddWebsiteMain'

const AddWebsiteDashboard = () => {
  return (
    <>
      <AddWebsiteMain callFrom="dashboard" />
    </>
  )
}

export default AddWebsiteDashboard

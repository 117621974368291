import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Card, Col, Row, Button, Alert, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import 'react-credit-cards/es/styles-compiled.css'
import { CardBody } from 'reactstrap'
import UrlBox from 'components/urlBox'
import paths from 'constants/paths'
import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import { getWebsiteLimit } from 'redux/actions/dashboard'
import { message } from 'antd'
import { CLEAR_WEBSITE_LIMIT } from 'redux/actionType'
import { Loader } from 'components/uiCore'
import { LimitOption, PLAN } from 'constants/enum'
import "modules/welcomeBoard/components/selectWebsite/SelectWebsite.scss"
import { useDeviceCheck } from 'hooks/deviceHook'

const SelectWebsiteMain = ({ callFrom }) => {
  const {
    linkArray: urlDetails,
    randomNumber,
    prevPageData,
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const { websiteLimitData, websiteLimitLoader, websiteLimitError } = useSelector(
    ({ dashboard }) => dashboard,
  )

  const registerData = useSelector(({ register }) => register.registerData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  const [openWebsiteLimitExceed, setOpenWebsiteLimitExceed] = useState(false)

  useEffect(() => {
      window.scrollTo(0, 0)  
  }, [])
  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
    } else {
      navigate(callFrom === 'dashboard' ? paths.dashboardSelectWebsite : paths.addNewWebsite)
    }
  }, [randomNumber])

  useEffect(() => {
    if (websiteLimitLoader) {
      return
    } else if (!websiteLimitLoader && websiteLimitData && Object?.keys(websiteLimitData)?.length) {
      if (websiteLimitData?.limitAvailable) {
        navigate(callFrom === 'dashboard' ? paths.dashboardaddLocation : paths.addLocation)
        dispatch({ type: CLEAR_WEBSITE_LIMIT })
      } else {
        if (websiteLimitData && Object.keys(websiteLimitData)?.length && !websiteLimitData?.limitAvailable) {
          setOpenWebsiteLimitExceed(true)
        }
      }
    } else {
      if (!websiteLimitLoader && websiteLimitError && Object.keys(websiteLimitError)?.length) {
        message.error(websiteLimitError?.message)
        dispatch({ type: CLEAR_WEBSITE_LIMIT })
      }
    }
  }, [websiteLimitLoader, websiteLimitData, websiteLimitError])

  const renderLinks = (links, handleModal) => {
    return (
      <>
        {links.map((item, index) => (
          <div key={`select-website${index + 1}`}>
            <UrlBox
              checked={item.checked}
              url={item.url}
              status={item.http_code}
              radioButtonClick={handleModal}
              redirectUrl={item.effective_url}
              colorCode={item?.colorCode}
            />
          </div>
        ))}
      </>
    )
  }

  const handleModal = (url) => {
    //setShowModal(data)

    if (url) {
      dispatch({ type: 'STORE_PREVIOUS_PAGE_DATA', payload: { ...prevPageData, url } })
    }
  }

  const handleSelectWebsite = () => {
    const selectedUrl = urlDetails?.filter((item) => item?.checked)
    if (selectedUrl && selectedUrl.http_code && selectedUrl.http_code === 200) {
      dispatch(
        getWebsiteLimit({
          url: selectedUrl?.[0]?.effective_url || prevPageData?.url,
          limitOption: LimitOption?.WEBSITE,
        }),
      )
    } else {
      dispatch(
        getWebsiteLimit({
          url: selectedUrl?.[0]?.effective_url || prevPageData?.url,
          limitOption: LimitOption?.WEBSITE,
        }),
      )
    }
  }

  return (
    <div className={callFrom === 'trial' ? `select-website` : ''} style={{height: urlDetails?.length > 4 && urlDetails?.[urlDetails.length - 1]?.message ? 'auto' : isMobile && callFrom === 'trial' ? "100vh" : !isMobile ?  '80.5vh' : "auto"}}>
      <Helmet title="Start trail" />

      {openWebsiteLimitExceed ? (
        <Modal
          show={openWebsiteLimitExceed}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          toggle={() => {
            setOpenWebsiteLimitExceed(!openWebsiteLimitExceed)
            dispatch({ type: CLEAR_WEBSITE_LIMIT })
          }}
          onClose={() => {
            setOpenWebsiteLimitExceed(false)
            dispatch({ type: CLEAR_WEBSITE_LIMIT })
          }}
          onHide={() => {
            setOpenWebsiteLimitExceed(false)
            dispatch({ type: CLEAR_WEBSITE_LIMIT })
          }}
          style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
          className="br-15 pe-0"
        >
          <Modal.Header>
            <h3 className="mb-0">Website Tracking Limit Reached</h3>
          </Modal.Header>
          <Modal.Body>
            You've reached your limit of adding or replacing websites for this period. Our real-time internet
            scanning service incurs significant costs to ensure accurate and up-to-date data for your tracked
            websites. This is why we've set a limit on the number of websites you can track or replace each
            month, to maintain the quality of our service for all customers.
            <br /> <br />
            Current Plan: {websiteLimitData?.websiteLimit}{' '}
            {websiteLimitData?.websiteLimit === 1 ? 'Website' : 'Websites'}
            <br /> <br />
            You may re-add any previously tracked websites from the list below, but the total cannot exceed
            your plan's active website limit at any time.
            <br /> <br />
            {websiteLimitData?.websites?.map((website) => {
              return (
                <Row>
                  <Col lg="8" className="d-flex flex-row justify-content-between align-items-center mb-1">
                    <p className="mb-0">{website}</p>
                  </Col>
                </Row>
              )
            })}
            <br />
            Looking for more flexibility? Our {PLAN.PREMIUM_PLAN.name} Plan offers unlimited website tracking, tailored for
            businesses requiring extensive monitoring.
            <br /> <br />
            Thank you for choosing us for your SEO needs. We're here to help you make the most of your digital
            presence!
          </Modal.Body>
          <Modal.Footer className={`d-flex flex-row ${registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "justify-content-end" : 'justify-content-between'}`}>
            {registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : <Button
              color="primary"
              variant="primary"
              onClick={() => {
                navigate(paths.upgradePlan)
                setOpenWebsiteLimitExceed(false)
                dispatch({ type: CLEAR_WEBSITE_LIMIT })
              }}
            >
              Upgrade your plan
            </Button>}

            <Button
              color="danger"
              variant="danger"
              className='text-end'
              onClick={() => {
                setOpenWebsiteLimitExceed(false)
                dispatch({ type: CLEAR_WEBSITE_LIMIT })
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
     {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={callFrom === "trial" ? "select-website-text": "select-website-text-dashboard"}>
         {isMobile ? <div className="select-website-stepnumber-mobile">
            <h3 className="mb-0">2</h3>
          </div> : null}
          <div className="select-website-text-mobile">
            <p className="select-website-text-first">Empower Your SEO Journey: Precision, Simplicity, Results</p>
            <p className="select-website-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      <Card
        className={`trail-flow mb-0 add-website-card-tranparency ${callFrom === "trial" ? "select-website-card" : 'select-website-dashboard-card'}`}
        style={{
          padding: !isMobile ? 15 : null,
        }}
      >
        <CardBody className={`d-flex flex-column justify-content-center add-website-card-tranparency ${callFrom === "trial" ? 'select-website-card-body' : ''}`}>
          <CommonCardHeader
            startSpanClass={'done-step'}
            websiteSpanClass={'active-step'}
            locationSpanClass={'inactive-step'}
            googleSpanClass={'inactive-step'}
            keywordSpanClass={'inactive-step'}
            competitorSpanClass={'inactive-step'}
            sitemapSpanClass={'inactive-step'}
            callFrom={callFrom}
          />
          <Card className={`mb-0 add-website-card-tranparency ${callFrom === "trial" ? 'select-website-nested-card' : 'select-website-dashboard-nested-card'}`}>
            <CardBody className={`bg-white add-website-card-tranparency`}>
              <Row>
                <Col>
                  <h3 className={`f-bold select-website-step-header`}>Select Website</h3>
                  <p>
                    Choose the available website version shown below, we scan and display your URL's current
                    status codes.
                  </p>

                  {urlDetails && urlDetails.length ? (
                    <>
                      <div className="w-100 select-website-links">{renderLinks(urlDetails, handleModal)}</div>
                      {urlDetails?.[urlDetails.length - 1]?.message?.length ? (
                        <>
                          <br />
                          <Alert
                            variant={'warning'}
                            className="alert-outline-coloured"
                            key={'url-redirection-pattern'}
                          >
                            <div className="alert-icon">
                              {/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}
                            </div>
                            <div className="alert-message alert-for-mobile">
                              {urlDetails?.[urlDetails.length - 1]?.message}
                            </div>
                          </Alert>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              {websiteLimitLoader &&
              !Object?.keys(websiteLimitError)?.length &&
              !Object.keys(websiteLimitData)?.length ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    alignSelf: 'center',
                    flexDirection: 'row',
                    minHeight: 100,
                  }}
                >
                  <Row className="w-100">
                    <div className="spacing-wo-border">
                      <Col xs="12">
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                          <div className="text-center">
                            <Loader />
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Row>
                </div>
              ) : null}
              <Row className={`select-website-button ${isMobile ? '' : 'mt-2rem '}`}>
                <Col className={isMobile ? "text-center" : ''}>
                  <Button
                    className={`addwebsite-navigate-buttons select-website-button-back`}
                    variant={`outline-secondary`}
                    onClick={() => {
                      navigate(callFrom === 'dashboard' ? paths.dashboardAddWebsite : paths.addNewWebsite)
                      dispatch({ type: 'CLEAR_URL_DETAILS' })
                    }}
                    style={{ height: 40, width: 250 }}
                  >
                    Back
                  </Button>
                </Col>
                <Col className="display-next-end">
                  <Button
                    className={`addwebsite-navigate-buttons select-website-button-next`}
                    onClick={() => handleSelectWebsite()}
                    style={{ height: 40, width: 250 }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  )
}

export default SelectWebsiteMain

import { useNavigate } from 'react-router-dom'

import paths from 'constants/paths'

const SidebarFooter = () => {
  const navigate = useNavigate()
   
  return (
    <div className="sidebar-cta">
      <div className="sidebar-cta-content d-flex flex-column align-items-center">
        <span><strong className="d-inline-block f-bold">Upgrade Your Account</strong></span>
        <br />
        <div className="w-100 d-grid">
          <a className="btn btn-primary" rel="noreferrer" onClick={() => navigate(paths.upgradePlan)}>
            Upgrade
          </a>
        </div>
      </div>
    </div>
  )
}

export default SidebarFooter

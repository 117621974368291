import { Spinner } from 'react-bootstrap'

export const Loader = ({ size, isText = true, loaderText = 'Live Scan in Progress' }) => {
  return (
    <>
      <Spinner animation="border" key={1} variant={'primary'} className="me-2" size={size} />
      <Spinner animation="border" key={2} variant={'danger'} className="me-2" size={size} />
      <Spinner animation="border" key={3} variant={'warning'} className="me-2" size={size} />
      <Spinner animation="border" key={4} variant={'primary'} className="me-2" size={size} />
      <Spinner animation="border" key={5} variant={'success'} className="me-2" size={size} />
      <Spinner animation="border" key={6} variant={'danger'} className="me-2" size={size} />
      {isText ? <p className="text-muted text-success mb-0">{loaderText}</p> : null}
    </>
  )
}

import { useEffect } from 'react'
import Select from 'react-select'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import 'components/GoogleAnalyticsModal.scss'
import { disconnectGoogle, saveAnalyticsData } from 'redux/actions/dashboard'
import { message } from 'antd'
import { getRefreshToken } from 'constants/Utils'
import { CLEAR_GENERATE_RESEARCH_DATA, CLEAR_GET_ANALYTICS_DATA } from 'redux/actionType'

const GoogleAnalyticsModal = () => {

  const dispatch = useDispatch()
  const { 
    userData,
    activeLink, 
    analyticsData, 
    disconnectGoogleLoader, 
    storeAnalyticsDataLoader, 
    usersDomains: usersDomain, 
  } = useSelector(({ dashboard }) => dashboard)

  const tokenData = getRefreshToken(usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url, userData)

  const accountOptions =
    analyticsData?.analyticsData?.accountSummaries?.length &&
    analyticsData?.analyticsData?.accountSummaries?.map((item) => {
      return {
        value: item?.displayName,
        label: item?.displayName,
      }
    })

  const searchConsoleOptions =
    analyticsData?.consoleData?.length &&
    analyticsData?.consoleData?.map((item) => {
      return {
        value: item,
        label: item,
      }
    })

  const initialValues = {
    email: tokenData?.email || analyticsData?.analyticsData?.email,
    account: '',
    analyticsProperty: '',
    searchConsoleProperty: '',
  }

  const googleAnalyticsSchema = object().shape({
    email: string().required('Please select anyone from the dropdown'),
    account: string().required('Please select account'),
    analyticsProperty: string().test('options', 'Please select property for selected account', function(value) {
      if (this.parent.account && propertyOptions) {
        const label = propertyOptions?.[0]?.label
        if (label === 'NONE') {
          return true;
        }
      }
   
      return !!value;
    }),
    searchConsoleProperty: string().required('Please select console property'),
  })

  const googleAnalyticsFormik = useFormik({
    initialValues,
    validationSchema: googleAnalyticsSchema,
    onSubmit: (values) => {
      let payload
      const label = propertyOptions?.[0]?.label
      if (label === 'NONE') {
        payload = {
          email: values.email,
          url: usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
          analyticsAccount: values.account,
          consoleProperty: values.searchConsoleProperty,
        }
      } else {
        payload = {
          email: values.email,
          url: usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
          analyticsAccount: values.account,
          analyticsProperty: values.analyticsProperty,
          consoleProperty: values.searchConsoleProperty,
          analyticsAccountNumber: propertyOptions?.[0]?.propertyNumber
        }
      }
      dispatch(saveAnalyticsData(payload))
    },
  })


  useEffect(() => {
    if (analyticsData?.analyticsData?.email) {
      if (!googleAnalyticsFormik.values.email) {
        googleAnalyticsFormik.setFieldValue('email', analyticsData?.analyticsData?.email)
      }
    }
  }, [analyticsData])
  
  const filterPropertyForSelectedAccount =
  analyticsData?.analyticsData?.accountSummaries?.length &&
  analyticsData?.analyticsData?.accountSummaries?.filter(
    (item) => item?.displayName === googleAnalyticsFormik?.values?.account,
    )

  const propertyOptions =
    filterPropertyForSelectedAccount?.length &&
    filterPropertyForSelectedAccount?.[0]?.propertySummaries?.map((item) => {
      return {
        value: item?.displayName,
        label: item?.displayName,
        propertyNumber: item?.property
      }
    }) || [ {value: '', label: 'NONE'}]

  const removeGoogleAccount = () => {
    const url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
    if (url) {
      dispatch(disconnectGoogle({url}))
      dispatch({type: CLEAR_GENERATE_RESEARCH_DATA})
      dispatch({type: CLEAR_GET_ANALYTICS_DATA})
    } else {
      message.error("Please provide url to disconnect google account")
    }
  }  

  const { handleSubmit, values } = googleAnalyticsFormik

  return (
    <>
    <Form className="google-analytics-form" onSubmit={handleSubmit}>
      <h3 className="google-analytics-form-heading">Google Account</h3>
      <Form.Group>
        <div className='google-analytics-form-group'>
        <Form.Label className="google-analytics-form-label">Email:</Form.Label>
        <Form.Control
          type="email"
          className="google-analytics-form-control"
          name="email"
          value={values?.email}
          disabled={true}
        />
        </div>
        <div className='google-analytics-form-revoke' style={{width: disconnectGoogleLoader ? '290px' : ''}}>
          <Button 
            className='d-flex align-items-center' 
            color="danger" 
            variant="danger" 
            type="button" 
            onClick={removeGoogleAccount} 
            disabled={disconnectGoogleLoader}
            >
              Revoke google account
              {disconnectGoogleLoader ? (
                  <Spinner
                    animation="border"
                    style={{ marginLeft: '10px' }}
                    key={1}
                    size="sm"
                  />
                ) : null}
          </Button>
        </div>
      </Form.Group>
      {googleAnalyticsFormik.touched.email && googleAnalyticsFormik.errors.email ? (
        <span className="fs-6 google-analytics-form-error">*{googleAnalyticsFormik.errors.email}</span>
      ) : null}

      {!tokenData?.analyticsAccount ? <hr /> : null}
      { !tokenData?.analyticsAccount ? 
        <>
          <h3 className="google-analytics-form-heading">Google Analytics</h3>
          <Form.Group className="google-analytics-form-group">
            <Form.Label className="google-analytics-form-label">Account:</Form.Label>
            <Select
              className="google-analytics-form-input"
              options={accountOptions}
              placeholder="Account"
              name="account"
              onChange={(option) => {
                googleAnalyticsFormik.setFieldValue('account', option?.value)
                googleAnalyticsFormik.setFieldValue('analyticsProperty', '')
              }}
            />
          </Form.Group> 
          {googleAnalyticsFormik.touched.account && googleAnalyticsFormik.errors.account ? (
            <span className="fs-6 google-analytics-form-error">*{googleAnalyticsFormik.errors.account}</span>
          ) : null} 
        </> : null }

      {googleAnalyticsFormik?.values?.account && !tokenData?.analyticsAccount  ? (
        <Form.Group className="google-analytics-form-group">
          <Form.Label className="google-analytics-form-label">Property:</Form.Label>
          <Select
            className="google-analytics-form-input"
            options={propertyOptions}
            placeholder="Property"
            name="analyticsProperty"
            onChange={(option) => {
              googleAnalyticsFormik.setFieldValue('analyticsProperty', option?.value)
            }}
          />
        </Form.Group>
      ) : null}

      { googleAnalyticsFormik.touched.analyticsProperty && 
        googleAnalyticsFormik.errors.analyticsProperty && 
        googleAnalyticsFormik?.values?.account && 
        !tokenData?.analyticsProperty ? (
        <span className="fs-6 google-analytics-form-error">
          *{googleAnalyticsFormik.errors.analyticsProperty}
        </span>
      ) : null}

      {!tokenData?.consoleProperty ? <hr /> : null}
      {!tokenData?.consoleProperty ? <h3 className="google-analytics-form-heading">Google Search Console</h3> : null }
      { !tokenData?.consoleProperty ?
      <>
      <Form.Group className="google-analytics-form-group">
        <Form.Label className="google-analytics-form-label">Property:</Form.Label>
        <Select
          className="google-analytics-form-input"
          options={searchConsoleOptions}
          placeholder=""
          name="searchConsoleProperty"
          onChange={(option) => {
            googleAnalyticsFormik.setFieldValue('searchConsoleProperty', option?.value)
          }}
        />
      </Form.Group>
      {googleAnalyticsFormik.touched.searchConsoleProperty &&
      googleAnalyticsFormik.errors.searchConsoleProperty ? (
        <span className="fs-6 google-analytics-form-error">
          *{googleAnalyticsFormik.errors.searchConsoleProperty}
        </span>
      ) : null}
      <div className="google-analytics-form-submit-btn" style={{width: storeAnalyticsDataLoader ? '235px' : ''}}>
        <Button 
          className='d-flex align-items-center' 
          color="primary" 
          variant="primary" 
          type="submit" 
          onClick={handleSubmit} 
          disabled={storeAnalyticsDataLoader}
        >
          Save changes
          {storeAnalyticsDataLoader ? (
                  <Spinner
                    animation="border"
                    style={{ marginLeft: '10px' }}
                    key={1}
                    size="sm"
                  />
                ) : null}
        </Button>
      </div>
      </> : null }
    </Form>
    <br />
    { tokenData?.consoleProperty ? 
        <Alert variant="primary" className="alert-outline-coloured" key="meta-title">
          <div className="alert-icon" />
          <div className="alert-message">
            <span className="alert-for-mobile">
              <p className="mb-0">
                Showing google search console data for url <span style={{fontWeight: 500}}>{usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url}</span> and property <span style={{fontWeight: 500}}>{tokenData?.consoleProperty}</span>
              </p>
            </span>
          </div>
        </Alert> : null }
    { tokenData?.analyticsAccount ? 
        <Alert variant="primary" className="alert-outline-coloured" key="meta-title">
          <div className="alert-icon" />
          <div className="alert-message">
            <span className="alert-for-mobile">
              <p className="mb-0">
                Showing analytics data for url <span style={{fontWeight: 500}}>{usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url}</span> and property <span style={{fontWeight: 500}}>{tokenData?.analyticsProperty ? tokenData?.analyticsProperty : tokenData?.analyticsAccount}</span>
              </p>
            </span>
          </div>
        </Alert> : null }
    </>
  )
}

export default GoogleAnalyticsModal

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Button } from 'react-bootstrap'

import { CardBody } from 'reactstrap'

import SiteMapForm from 'components/AddSitemap'

import 'react-credit-cards/es/styles-compiled.css'
import paths from 'constants/paths'
import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import ColoredModal from 'pages/modals/ColorModal'
import PricingPlan from 'pages/auth/PricingPlan'
import { changePlan } from 'redux/actions/register'
import {
  addWebsite,
  ifwebSiteData,
  lightHouseSuccessClear,
  setBackLinksData,
  setStartScan,
} from 'redux/actions/freetrial/startFreeTrial'
import { getDashboardBacklinks } from 'redux/actions/dashboard'
import 'modules/welcomeBoard/components/addSitemap/addSitemap.scss'
import { useDeviceCheck } from 'hooks/deviceHook'
import { PLAN } from 'constants/enum'

const AddSitemapsMain = ({ callFrom }) => {
  const {
    urlDetails,
    randomNumber,
    prevPageData,
    competitorListArray,
    keywordsListArray,
    initiateWebScan,
    sitemapDataToSent,
    sitemaps,
    isLightHouseLoading,
    lightHouseStatusData,
    storedBacklinksData,
    sitemapLoader,
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const { selectedKeywordsList } = useSelector(({ dashboard }) => dashboard)

  const userId = useSelector(({ register }) => register?.registerData?._id)

  const isWebAddedThroughCached = useSelector(({ websiteCache }) => websiteCache?.isAddedThroughCache)

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  const [isWebScanStarted, setIsWebSacnStarted] = useState(false)
  const [planName, setPlanName] = useState(PLAN.BASIC_PLAN.name)
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const registerData = useSelector(({ register }) => register.registerData)

  let twitterHandle = ''
  let facebookHandle = ''
  let instagramHandle = ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
    } else {
      navigate(callFrom === 'dashboard' ? paths.dashboardAddWebsite : paths.addNewWebsite)
    }
  }, [randomNumber])

  let urlItem = []
  urlItem = urlDetails && urlDetails.length && urlDetails.filter((item) => item.checked)
  let finalUrl = urlItem?.[0]?.effective_url || prevPageData?.url

  const checkAddCompetitors = () => {
    setIsWebSacnStarted(true)
    dispatch(setStartScan(true))

    let urlObj
    if (prevPageData?.url?.includes('http://') || prevPageData?.url?.includes('https://')) {
      urlObj = new URL(prevPageData?.url)
    }
    let domain = urlObj?.hostname || prevPageData?.url
    if (domain.includes('www.')) {
      domain = domain.replace('www.', '')
    }
    // const input = {
    //   sessionId: randomNumber,
    //   userId: userId,
    //   competitors: competitorListArray,
    //   domain: domain,
    //   region: prevPageData?.regions?.[0]?.region,
    //   country: prevPageData?.regions?.[0]?.country,
    //   keywords: keywordsListArray,
    //   action: 'getTopKeyword',
    //   sitemapUrls: (sitemapDataToSent?.length ? sitemapDataToSent : sitemaps),
    // };
    const input = {
      sessionId: randomNumber,
      domain: domain,
      region: prevPageData?.regions?.[0]?.region,
      country: prevPageData?.regions?.[0]?.country,
      keywords: selectedKeywordsList?.length ? selectedKeywordsList : keywordsListArray,
      action: 'getTopKeyword',
      sitemapUrls: sitemapDataToSent?.length ? sitemapDataToSent : sitemaps,
    }
    dispatch({ type: 'GET_URL_DETAILS', payload: input })
    dispatch({ type: 'TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT' })

    let finalDataForBacklinks = {
      competitors: competitorListArray || [],
      keywords: selectedKeywordsList?.length ? selectedKeywordsList : keywordsListArray || [],
      region: prevPageData?.regions?.[0]?.region || '',
      long: prevPageData?.regions?.[0]?.long || '',
      lat: prevPageData?.regions?.[0]?.lat || '',
      iso3: prevPageData?.regions?.[0]?.iso3 || '',
      twitterDetail: twitterHandle || '',
      facebookDetail: facebookHandle || '',
      instagramDetail: instagramHandle || '',
      country: prevPageData?.regions?.[0]?.country || 'Canada',
      primaryLocation: prevPageData?.regions?.[0]?.primaryLocation || '',
      city: prevPageData?.regions?.[0]?.city || 'Toronto',
      state: prevPageData?.regions?.[0]?.state || 'Ontario',
      regions: prevPageData?.regions || [],
      sitemapUrls: sitemapDataToSent?.length ? sitemapDataToSent : sitemaps || [],
    }
    if (finalUrl.length) {
      dispatch(
        setBackLinksData({
          hostName: prevPageData.url,
          url: finalUrl,
          userId,
          email: registerData.email,
          urlInfo: JSON.stringify(finalDataForBacklinks),
          webCached: isWebAddedThroughCached ? 'true' : 'false',
        }),
      )
    }
  }

  useEffect(() => {
    if (initiateWebScan && !isLightHouseLoading && lightHouseStatusData?.statusCode === 200) {
      let reqData = {
        url: finalUrl,
        randomNumber: randomNumber,
        email: registerData?.email,
        verified: false,
        webCached: isWebAddedThroughCached ? 'true' : 'false',
      }

      let finalData = {
        competitors: competitorListArray,
        keywords: selectedKeywordsList?.length ? selectedKeywordsList : keywordsListArray,
      }

      {
        callFrom === 'trial'
          ? dispatch({
              type: 'ADD_WEBSITE_FROM_TRIAL',
              payload: {
                hostName: prevPageData.url,
                url: finalUrl,
                userId,
                email: registerData.email,
                urlInfo: JSON.stringify(finalData),
              },
            })
          : null
      }
      {
        callFrom === 'dashboard' ? dispatch(getDashboardBacklinks(storedBacklinksData)) : null
      }
      {
        callFrom === 'trial' ? dispatch(addWebsite(reqData)) : null
      }
      dispatch(lightHouseSuccessClear())
      dispatch(setStartScan(false))
      dispatch(ifwebSiteData(false))
      dispatch(setBackLinksData({}))
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
      dispatch({ type: 'CLEAR_URL_DETAILS' })
      dispatch({ type: 'GET_SITEOUTAGE_DATA_CLEAR' })
      dispatch({ type: 'CLEAR_ADD_WEBSITE_THROUGH_CACHE_FAILURE' })
      navigate(paths.mainDashboard)
    }
  }, [initiateWebScan, isLightHouseLoading, lightHouseStatusData, lightHouseStatusData?.statusCode])

  const changeExisitingPlan = (data) => {
    setIsOpen(!isOpen)
    setPlanName(data.planName)
    dispatch(
      changePlan({
        email: registerData.email,
        planName: data.planName,
        planType: 'Stripe',
        planDuration: data.planType,
      }),
    )
  }

  return (
    <div
      className={`${callFrom === 'trial' ? 'add-sitemap' : ''} 
                  ${sitemaps?.length > 10 && isMobile ? 'add-sitemap-one' : 'add-sitemap-two'}`}
      style={{ height: sitemaps?.length > 4 ? 'auto' : '80.5vh' }}
    >
      <Helmet title="Start trial" />
      {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={callFrom === 'trial' ? 'add-sitemap-text' : 'add-sitemap-text-dashboard'}>
          {isMobile ? (
            <div className="add-sitemap-stepnumber-mobile">
              <h3 className="mb-0">7</h3>
            </div>
          ) : null}
          <div className="add-sitemap-text-mobile">
            <p className="add-sitemap-text-first">Empower Your SEO Journey: Precision, Simplicity, Results</p>
            <p className="add-sitemap-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      {error ? (
        <ColoredModal
          isOpen={error}
          message={errorMessage}
          onClose={() => {
            setError(false)
            setErrorMessage(false)
          }}
        />
      ) : (
        <></>
      )}
      <PricingPlan
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        changeExisitingPlan={(data) => changeExisitingPlan(data)}
      />

      <Card
        className={`trail-flow mb-0 add-website-card-tranparency ${callFrom === 'trial' ? 'add-sitemap-card' : 'add-sitemap-dashboard-card'}`}
        style={{ padding: 15 }}
      >
        <CardBody
          className={`add-website-card-tranparency ${callFrom === 'trial' ? 'add-sitemap-card-body' : ''}`}
        >
          <Card
            className={`add-website-card-tranparency ${callFrom === 'trial' ? 'add-sitemap-nested-card' : 'add-sitemap-dashboard-nested-card'}`}
          >
            <CommonCardHeader
              startSpanClass={'done-step'}
              websiteSpanClass={'done-step'}
              locationSpanClass={'done-step'}
              googleSpanClass={'done-step'}
              keywordSpanClass={'done-step'}
              competitorSpanClass={'done-step'}
              sitemapSpanClass={'active-step'}
              callFrom={callFrom}
            />

            <CardBody className={`bg-white add-website-card-tranparency`}>
              <Row>
                <Col>
                  <SiteMapForm fromTrial={callFrom === 'dashboard' ? false : true} />
                </Col>
              </Row>
              <Row className={`mt-2rem add-sitemap-navigate-btn`}>
                <Col className={`${isMobile ? 'text-center' : ''}`}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    disabled={isWebScanStarted}
                    variant={`outline-secondary`}
                    onClick={() => {
                      navigate(callFrom === 'dashboard' ? paths.dashboardAddCompetitors : paths.addCompetitor)
                    }}
                    style={{ height: 40, width: 250 }}
                  >
                    Back
                  </Button>
                </Col>
                <Col className={'display-next-end'}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    onClick={() => checkAddCompetitors()}
                    style={{ height: 40, width: 250 }}
                    disabled={isWebScanStarted || sitemapLoader}
                  >
                    Finish
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  )
}

export default AddSitemapsMain

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const RobotsText = ({ data }) => {
  return (
    <>
      {data?.robotsTxtTest?.data ? (
        <>
          {/* <h5>{data?.url}</h5> */}
          <div className="vr-url-block">
            <div>
              {!data?.robotsTxtTest?.data?.testResults?.status ? (
                ''
              ) : data?.robotsTxtTest?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.robotsTxtTest?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p> {data?.robotsTxtTest?.data?.testResults?.message} </p>
            <p>
              {' '}
              {data?.robotsTxtTest?.data?.testResults?.status === 1 ? (
                <a href={data?.robotsTxtTest?.data?.robotsTxt?.robotsTxtUrl} target="_blank">
                  {' '}
                  {data?.robotsTxtTest?.data?.robotsTxt?.robotsTxtUrl}{' '}
                </a>
              ) : (
                ''
              )}{' '}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default RobotsText

import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop'

import ToastMessage from 'components/uiCore/ToastMessage'

import { personalInfoUpdate } from 'redux/actions/dashboard'

import user from '../../assets/img/avatars/user.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import moment from 'moment-timezone'
import Select from 'react-select'
import 'pages/pages/PersonalInformation.scss'
import { PERSONAL_INFO_UPDATE_CLEAR } from 'redux/actionType'

const PersonalInformation = () => {

  const REACT_APP_IMAGE_UPLOAD_URL = process.env.REACT_APP_IMAGE_UPLOAD_URL
  const { userData, persnoalInfoLoading, toastMessage, toastError } = useSelector(
    ({ dashboard }) => dashboard,
  )
  const {registerData} = useSelector(({ register }) => register)
  const [showToast, setShowToast] = useState(false)
  const [presignedUrl, setPresignedUrl] = useState(registerData?.profilePicture)
  const [imageLoader, setImageLoader] = useState(false)
  const [userName, setUserName] = useState({
    firstName: registerData?.firstName ? registerData?.firstName : localStorage.getItem('firstName'),
    lastName: registerData?.lastName ? registerData?.lastName : localStorage.getItem('lastName'),
    timezone: registerData?.timezone ? registerData?.timezone : userData?.timezone
  })
  const [countryOptions, setCountryOptions] = useState([])
  
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)

  const initialValues = {
    firstName: userName?.firstName,
    lastName: userName?.lastName,
    timezone: userName?.timezone,
    profilePicture: null
  }

  const personalinformationSchema = object().shape({
    firstName: string().required('Please Enter First Name.'),
    lastName: string().required('Please Enter Last Name.'),
    timezone: string().required('Timezone is required')
  })
  const userDataLocalStorage = localStorage.getItem('userData')
  const formik = useFormik({
    initialValues,
    validationSchema: personalinformationSchema,
    onSubmit: (values) => {
        const {firstName,lastName, profilePicture, timezone} =values
        const payload = profilePicture !== null ? { userData: { firstName,lastName, profilePicture, timezone } } : { userData: { firstName,lastName, timezone } } 

        dispatch(personalInfoUpdate(payload))
        setUserName({ firstName: values.firstName, lastName: values.lastName, timezone: values?.timezone })
      },
      })
      
  const { values, handleSubmit, handleChange, handleBlur } = formik

  useEffect(() => {
    const isoCountries = moment.tz.countries()
    const finalData = []
    isoCountries.map((country) => {
      const zones = moment.tz.zonesForCountry(country, { with_offset: true })
      zones.map((zone) => {
        const data = {
          value: zone.name,
          label: `${zone.name} (GMT${moment.tz(zone.name).format('Z')})`,
          zone: zone.name
        }
        finalData.push(data)
      })
    })
    const uniqueArray = _.uniqBy(finalData, (obj) => JSON.stringify(obj.label))

    setCountryOptions(uniqueArray)
  }, [])

  useEffect(() => {
    if (!persnoalInfoLoading && (toastError || toastMessage)) {
      setShowToast(true)
      setTimeout(() => {
        dispatch({type: PERSONAL_INFO_UPDATE_CLEAR})
      }, 1000)
    }
  }, [persnoalInfoLoading])

  useEffect(() => {
    if (!persnoalInfoLoading && toastMessage) {
      const payload = values?.profilePicture !== null ? {
        ...JSON.parse(userDataLocalStorage),
        firstName: userName.firstName,
        lastName: userName.lastName,
        timezone: userName?.timezone,
        profilePicture: presignedUrl
      } : {
        ...JSON.parse(userDataLocalStorage),
        firstName: userName.firstName,
        lastName: userName.lastName,
        timezone: userName?.timezone,
      }

      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.setItem('firstName', userName.firstName)
      localStorage.setItem('lastName', userName.lastName)
      values?.profilePicture !== null ? localStorage.setItem('profilePicture', presignedUrl) : null
      dispatch({ type: 'CHANGE_PLAN_SUCCESS', payload })
    }
  }, [toastMessage, persnoalInfoLoading])

  const handleImageChange = (event) => {
    beforeUpload(event) 
  }

  const beforeUpload = async (file) => {
    if (file.status && file.status !== 'done') {
      return false
    }

    const allowedFileTypes = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg']

    if (!allowedFileTypes.includes(file.type)) {
      message.error('Invalid file type. Please upload a PNG, GIF, JPG, or JPEG file.')
      return false
    }
    
    const fileSizeInBytes = file.size
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024)
    if (fileSizeInMB > 1) {
      message.error('File size exceeds the maximum limit of 1MB')
      return false
    }
    if (!(fileSizeInMB > 1) && allowedFileTypes.includes(file.type)) {
      setImageLoader(true)
      await fetchPresignedUrl(file)
    }

    return false
  }

  const fetchPresignedUrl = async (file) => {
    const token = await localStorage.getItem('token') 
    const { type, name } = file
    const params = {
      fileType: type,
      filename: name,
      token,
    }

    try {
      const response = await axios.get(REACT_APP_IMAGE_UPLOAD_URL, { params })
      const { signedRequest, url } = response?.data

      const options = {
        headers: {
          'Content-Type': type,
        },
      }

      await axios.put(signedRequest, file, options)
      setPresignedUrl(url)
      formik.setFieldValue('profilePicture', url)
      setImageLoader(false)
    } catch (error) {
      console.error('Failed to fetch presigned URL', error)
      message.error('Failed to fetch presigned URL')
      setImageLoader(false)
    }
  }

  const ImageUploader = () => {
    return (
      <div className="text-center">
        <ImgCrop showGrid rotationSlider aspectSlider useRatio cropShape="round">
          <Upload
            accept="image/*"
            beforeUpload={handleImageChange} 
            style={{ display: 'none' }}
            ref={fileInputRef}
            multiple={false}
            maxCount={1}
            showUploadList={false}
          >
            <img
              alt="user"
              src={presignedUrl ? presignedUrl : user}
              className="rounded-circle img-responsive mt-2"
              width="128"
              height="128"
            />
            <div className="mt-2">
              <Button variant="primary">
                {
                  imageLoader ?
                    <>
                      <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                        <FontAwesomeIcon icon={faUpload} /> Upload
                        <Spinner animation="border" style={{ marginBottom: '2px' }} key={1} size="sm" />
                      </div>
                    </> :
                    <> <FontAwesomeIcon icon={faUpload} /> Upload</>
                }
              </Button>
            </div>
          </Upload>
        </ImgCrop>
      </div>
    )
  }

  return (
    <>
      {(toastMessage || toastError) ? <ToastMessage
        show={showToast}
        onClose={() => {
          setShowToast(false)
        }}
        variant={toastMessage ? "success" : toastError ? "danger" : "warning"}
        toastMessage={toastMessage ? toastMessage : toastError ? toastError : 'Something went wrong!'}
      />: null}
      <Card className='mb-0'>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs="12 mb-3" className='profile-picture-mobile'>
                {ImageUploader()}
              </Col>
              <Col md="8">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputUsername">Email Address:</Form.Label>
                  <Form.Control
                    type="email"
                    id="inputEmailAddress"
                    disabled
                    value={userData?.email}
                    placeholder="Email"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputFirstName">First Name:</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstName"
                    placeholder="First name"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <span className="fs-6" style={{ color: 'red' }}>
                      {formik.errors.firstName}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputLastName">Last Name:</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastName"
                    placeholder="Last name"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <span className="fs-6" style={{ color: 'red' }}>
                      {formik.errors.lastName}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputTimezone">Your Timezone:</Form.Label>
                    <Select
                        options={countryOptions}
                        onChange={(option) => {
                            formik.setFieldValue('timezone', option?.zone)
                        }}
                        placeholder=""
                        isSearchable={true}
                        className="profile-inputs"
                        classNamePrefix="profile-timezone"
                        name="timezone"
                        value={countryOptions.find(option => option?.value === values.timezone)}
                    />
                    {formik.touched.timezone && formik.errors.timezone ? (
                        <span className="fs-6" style={{ color: 'red' }}>{formik.errors.timezone}</span>
                    ) : null}
                </Form.Group>
              </Col>
              <Col md="4" className='profile-picture-desktop'>
                {ImageUploader()}
              </Col>
            </Row>
            <Button variant="primary" onClick={handleSubmit}>
              Save Information
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default PersonalInformation

import React from 'react'

const DisallowDirective = ({ data }) => {
  return (
    <>
      {data ? (
        <>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <h5>{data?.url}</h5>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <p>
              {data.disallowDirectiveChecker?.data?.finalRes ? (
                <>{data.disallowDirectiveChecker?.data?.finalRes?.message || ''}</>
              ) : (
                <></>
              )}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DisallowDirective

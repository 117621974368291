import CommonFreeToolCard from './components/CommonFreeToolCard'
import Header from 'components/Header/Header'
import { freeTools, metaInformation } from 'constants.js'
import 'modules/Free tools/FreeToolsList.scss'
import { useNavigate } from 'react-router-dom'

const FreeToolsList = () => {
  const navigate = useNavigate()
  const testKeys = Object.keys(freeTools)

  const handleNavigate = (heading) => {
    const testKeys = Object.keys(metaInformation)
    const getClickedTestKey = testKeys.filter((key) => metaInformation[key]?.title === heading)
    navigate(`${getClickedTestKey[0]}`)
  }

  return (
    <>
      <Header isDemoReportPage customClassName="layout-container trial-header" />
      <div className="d-flex flex-wrap justify-content-center gap-4 free-tools-list">
        {testKeys?.map((testName) => {
          return (
            <CommonFreeToolCard
              key={freeTools[testName].heading}
              title={freeTools[testName].heading}
              description={freeTools[testName].what}
              onClick={() => handleNavigate(freeTools[testName].heading)}
              icon={freeTools[testName].icon}
            />
          )
        })}
      </div>
    </>
  )
}

export default FreeToolsList

export const GET_URL_DETAILS = 'GET_URL_DETAILS'
export const GET_URL_DETAILS_SUCCESS = 'GET_URL_DETAILS_SUCCESS'
export const GET_URL_DETAILS_FAILURE = 'GET_URL_DETAILS_FAILURE'

export const SEO_COMPETITOR_PERCENTAGE_ACTUAL = 'SEO_COMPETITOR_PERCENTAGE_ACTUAL'
export const SPEED_GRAPH_CHART_DATA_REQUEST = 'SPEED_GRAPH_CHART_DATA_REQUEST'
export const SPEED_GRAPH_CHART_DATA_SUCCESS = 'SPEED_GRAPH_CHART_DATA_SUCCESS'
export const SPEED_GRAPH_CHART_DATA_FAILURE = 'SPEED_GRAPH_CHART_DATA_FAILURE'
export const CLEAR_SPEED_GRAPH_CHART_DATA = 'CLEAR_SPEED_GRAPH_CHART_DATA'

export const TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX'
export const CLEAR_TOGGLE_CHECKBOX = 'CLEAR_TOGGLE_CHECKBOX'

export const TOGGLE_DASHBOARD_CHECKBOX = 'TOGGLE_DASHBOARD_CHECKBOX'

export const STORE_PREVIOUS_PAGE_DATA = 'STORE_PREVIOUS_PAGE_DATA'
export const IS_CALL_FOR = 'IS_CALL_FOR'

export const CHECK_URL = 'CHECK_URL'
export const CHECK_URL_SUCCESS = 'CHECK_URL_SUCCESS'
export const CHECK_URL_FAILURE = 'CHECK_URL_FAILURE'
export const CHECK_URL_CLEAR = 'CHECK_URL_CLEAR'
export const IS_WEBSITE_DATA = 'IS_WEBSITE_DATA'
export const PERSONAL_INFO_UPDATE_CLEAR = 'PERSONAL_INFO_UPDATE_CLEAR'
export const DELETE_USER_CLEAR = 'DELETE_USER_CLEAR'

export const TOGGLE_KEYWORD_CHECKBOX = 'TOGGLE_KEYWORD_CHECKBOX'
export const TOGGLE_KEYWORD = 'TOGGLE_KEYWORD'
export const SITEMAP_DATA_TO_SENT = 'SITEMAP_DATA_TO_SENT'

export const GET_BACKLINKS = 'GET_BACKLINKS'
export const GET_BACKLINKS_SUCCESS = 'GET_BACKLINKS_SUCCESS'
export const GET_BACKLINKS_FAILURE = 'GET_BACKLINKS_FAILURE'

export const GET_BACKLINKS_STATS = 'GET_BACKLINKS_STATS'
export const GET_BACKLINKS_STATS_SUCCESS = 'GET_BACKLINKS_STATS_SUCCESS'
export const GET_BACKLINKS_STATS_FAILURE = 'GET_BACKLINKS_STATS_FAILURE'

export const ADD_USER = 'ADD_USER'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'
export const ADD_USER_CLEAR = 'ADD_USER_CLEAR'

export const ADD_WEBSITE = 'ADD_WEBSITE'
export const WEBSITE_SUCCESS = 'WEBSITE_SUCCESS'
export const STOP_ADD_WEBSITE_LOADER = "STOP_ADD_WEBSITE_LOADER"

export const ADD_WEBSITE_TO_USER = 'ADD_WEBSITE_TO_USER'

export const REGISTER_START = 'REGISTER_START'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_SOCIAL_SUCCESS = 'REGISTER_SOCIAL_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const REGISTER_CLEAR = 'REGISTER_CLEAR'
export const CLEAR_REGISTER_USERDATA = 'CLEAR_REGISTER_USERDATA'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_CLEAR = 'LOGIN_CLEAR'

export const VERIFY_START = 'VERIFY_START'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAILURE = 'VERIFY_FAILURE'
export const VERIFY_CLEAR = 'VERIFY_CLEAR'

export const STORE_USER_DATA_START = 'STORE_USER_DATA_START'

export const PROCESS_PAYMENT_START = 'PROCESS_PAYMENT_START'
export const PROCESS_PAYMENT_SUCCESS = 'PROCESS_PAYMENT_SUCCESS'
export const PROCESS_PAYMENT_FAIL = 'PROCESS_PAYMENT_FAIL'
export const GET_STRIPE_SECRET_KEY = "GET_STRIPE_SECRET_KEY"
export const GET_STRIPE_SECRET_KEY_SUCCESS = "GET_STRIPE_SECRET_KEY_SUCCESS"
export const GET_STRIPE_SECRET_KEY_FAILURE = "GET_STRIPE_SECRET_KEY_FAILURE"
export const CLEAR_STRIPE_SECRET_KEY = "CLEAR_STRIPE_SECRET_KEY"
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS"
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE"

export const GET_BILLING_INFORMATION = "GET_BILLING_INFORMATION" 
export const GET_BILLING_INFORMATION_SUCCESS = "GET_BILLING_INFORMATION_SUCCESS" 
export const GET_BILLING_INFORMATION_FAILURE = "GET_BILLING_INFORMATION_FAILURE"

export const STORE_RANDOM_NUMBER = 'STORE_RANDOM_NUMBER'

export const GET_USER_DOMAINS = 'GET_USER_DOMAINS'
export const GET_USER_DOMAINS_SUCCESS = 'GET_USER_DOMAINS_SUCCESS'
export const GET_USER_DOMAINS_FAILURE = 'GET_USER_DOMAINS_FAILURE'

export const PATCH_USER_DOMAINS_SUCCESS = 'PATCH_USER_DOMAINS_SUCCESS'
export const PATCH_USER_DOMAINS_DASHBOARD = 'PATCH_USER_DOMAINS_DASHBOARD'
export const PATCH_USER_DOMAINS_DASHBOARD_SUCCESS = 'PATCH_USER_DOMAINS_DASHBOARD_SUCCESS'
export const PATCH_USER_DOMAINS_DASHBOARD_FAILURE = 'PATCH_USER_DOMAINS_DASHBOARD_FAILURE'

export const CHANGE_ACTIVE_LINK = 'CHANGE_ACTIVE_LINK'
export const VERIFY_PASSWORD_CLEAR = 'VERIFY_PASSWORD_CLEAR'

export const REMOVE_COMPETITORS = 'REMOVE_COMPETITORS'
export const REMOVE_COMPETITORS_SUCCESS_1 = 'REMOVE_COMPETITORS_SUCCESS_1'
export const REMOVE_COMPETITORS_SUCCESS_2 = 'REMOVE_COMPETITORS_SUCCESS_2'
export const REMOVE_COMPETITORS_FAILURE = 'REMOVE_COMPETITORS_FAILURE'

export const URL_SCAN_STATUS = "URL_SCAN_STATUS"
export const URL_SCAN_STATUS_FAILURE = "URL_SCAN_STATUS_FAILURE"

export const PERSONAL_INFO_UPDATE = "PERSONAL_INFO_UPDATE"
export const PERSONAL_INFO_UPDATE_SUCESS = "PERSONAL_INFO_UPDATE_SUCESS"
export const PERSONAL_INFO_UPDATE_ERROR = "PERSONAL_INFO_UPDATE_ERROR"

export const DELETE_USER = "DELETE_USER_UPDATE"
export const DELETE_USER_SUCESS = "DELETE_USER_SUCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const GET_URL_SCAN_STATUS = "GET_URL_SCAN_STATUS"
export const GET_URL_SCAN_STATUS_SUCCESS = "GET_URL_SCAN_STATUS_SUCCESS"
export const GET_URL_SCAN_STATUS_FAILURE = "GET_URL_SCAN_STATUS_FAILURE"

export const KEYWORD_POSITION_TRACKING = "KEYWORD_POSITION_TRACKING"
export const KEYWORD_POSITION_TRACKING_SUCCESS = "KEYWORD_POSITION_TRACKING_SUCCESS"
export const KEYWORD_POSITION_TRACKING_FAILURE = "KEYWORD_POSITION_TRACKING_FAILURE"
export const KEYWORD_ACTIVE_REGION_TAB = 'KEYWORD_ACTIVE_REGION_TAB'
export const KEYWORD_POSITION_HISTORY = "KEYWORD_POSITION_HISTORY"
export const KEYWORD_POSITION_HISTORY_SUCCESS = "KEYWORD_POSITION_HISTORY_SUCCESS"
export const KEYWORD_POSITION_HISTORY_FAILURE = "KEYWORD_POSITION_HISTORY_FAILURE"
// Free tools
export const FREE_WHOIS_START = 'FREE_WHOIS_START'
export const FREE_WHOIS_SUCCESS = 'FREE_WHOIS_SUCCESS'
export const FREE_WHOIS_FAIL = 'FREE_WHOIS_FAIL'

export const FREE_DOMAIN_SCORE_START = 'FREE_DOMAIN_SCORE_START'
export const FREE_DOMAIN_SCORE_SUCCESS = 'FREE_DOMAIN_SCORE_SUCCESS'
export const FREE_DOMAIN_SCORE_FAIL = 'FREE_DOMAIN_SCORE_FAIL'

export const BACKLINKS_CHECK_START = 'BACKLINKS_CHECK_START'
export const BACKLINKS_CHECK_SUCCESS = 'BACKLINKS_CHECK_SUCCESS'
export const BACKLINKS_CHECK_FAIL = 'BACKLINKS_CHECK_FAIL'

export const DELETE_WEBSITE = 'DELETE_WEBSITE'
export const DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS'
export const DELETE_WEBSITE_FAILURE = 'DELETE_WEBSITE_FAILURE'
export const CLEAR_DELETE_WEBSITE = 'CLEAR_DELETE_WEBSITE'

export const LOGOUT = 'LOGOUT'

export const START_SUBSCRIPTION = 'START_SUBSCRIPTION'
export const START_SUBSCRIPTION_SUCCESS = 'START_SUBSCRIPTION_SUCCESS'
export const START_SUBSCRIPTION_FAILURE = 'START_SUBSCRIPTION_FAILURE'
export const START_SUBSCRIPTION_CLEAR = 'START_SUBSCRIPTION_CLEAR'
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS"
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE"
export const CLEAR_CANCEL_SUBSCRIPTION = "CLEAR_CANCEL_SUBSCRIPTION"
export const UPGRADE_SUBSCRIPTION = "UPGRADE_SUBSCRIPTION"
export const UPGRADE_SUBSCRIPTION_SUCCESS = "UPGRADE_SUBSCRIPTION_SUCCESS"
export const UPGRADE_SUBSCRIPTION_FAILURE = "UPGRADE_SUBSCRIPTION_FAILURE"
export const CLEAR_UPGRADE_SUBSCRIPTION = "CLEAR_UPGRADE_SUBSCRIPTION"

export const OPEN_UPGRADE_PLAN_MODAL = "OPEN_UPGRADE_PLAN_MODAL"

export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE'
export const CREATE_PAYMENT_CLEAR = 'CREATE_PAYMENT_CLEAR'

export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE'

export const TOGGLE_COMPETITORS = 'TOGGLE_COMPETITORS'
export const RECHECK_COMPETITORS = 'RECHECK_COMPETITORS'

export const REMOVE_KEYWORD = 'REMOVE_KEYWORD'
export const REMOVE_KEYWORD_SUCCESS = 'REMOVE_KEYWORD_SUCCESS'
export const REMOVE_KEYWORD_FAILURE = 'REMOVE_KEYWORD_FAILURE'
export const CLEAR_REMOVE_KEYWORD = 'CLEAR_REMOVE_KEYWORD'

export const VERIFY_TOKEN = 'VERIFY_TOKEN'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE'

export const CHANGE_SOCIAL_SETTINGS = 'CHANGE_SOCIAL_SETTINGS'
export const CHANGE_SOCIAL_SETTINGS_SUCCESS = 'CHANGE_SOCIAL_SETTINGS_SUCCESS'
export const CHANGE_SOCIAL_SETTINGS_FAILURE = 'CHANGE_SOCIAL_SETTINGS_FAILURE'

export const ADD_COMPETITORS_SETTINGS = 'ADD_COMPETITORS_SETTINGS'
export const ADD_COMPETITORS_SETTINGS_SUCCESS = 'ADD_COMPETITORS_SETTINGS_SUCCESS'
export const ADD_COMPETITORS_SETTINGS_FAILURE = 'ADD_COMPETITORS_SETTINGS_FAILURE'
export const CLEAR_ADD_COMPETITORS_SETTINGS = 'CLEAR_ADD_COMPETITORS_SETTINGS'

export const ADD_KEYWORDS_SETTINGS = 'ADD_KEYWORDS_SETTINGS'
export const ADD_KEYWORDS_SETTINGS_SUCCESS = 'ADD_KEYWORDS_SETTINGS_SUCCESS'
export const ADD_KEYWORDS_SETTINGS_FAILURE = 'ADD_KEYWORDS_SETTINGS_FAILURE'
export const CLEAR_ADD_KEYWORDS_SETTINGS = 'CLEAR_ADD_KEYWORDS_SETTINGS'

export const REMOVE_COMPETITORS_SETTINGS = 'REMOVE_COMPETITORS_SETTINGS'
export const REMOVE_COMPETITORS_SETTINGS_SUCCESS = 'REMOVE_COMPETITORS_SETTINGS_SUCCESS'
export const REMOVE_COMPETITORS_SETTINGS_FAILURE = 'REMOVE_COMPETITORS_SETTINGS_FAILURE'
export const CLEAR_REMOVE_COMPETITORS_SETTINGS = 'CLEAR_REMOVE_COMPETITORS_SETTINGS'

export const CHANGE_REGION_SETTINGS = 'CHANGE_REGION_SETTINGS'
export const CHANGE_REGION_SETTINGS_SUCCESS = 'CHANGE_REGION_SETTINGS_SUCCESS'
export const CHANGE_REGION_SETTINGS_FAILURE = 'CHANGE_REGION_SETTINGS_FAILURE'

export const CHANGE_PING_SETTINGS_SUCCESS = 'CHANGE_PING_SETTINGS_SUCCESS'
export const CHANGE_PING_SETTINGS_FAILURE = 'CHANGE_PING_SETTINGS_FAILURE'

export const CHANGE_UPTIME_SETTINGS_SUCCESS = 'CHANGE_UPTIME_SETTINGS_SUCCESS'
export const CHANGE_UPTIME_SETTINGS_FAILURE = 'CHANGE_UPTIME_SETTINGS_FAILURE'

export const CHANGE_HTTP_LOAD_SETTINGS_SUCCESS = 'CHANGE_HTTP_LOAD_SETTINGS_SUCCESS'
export const CHANGE_HTTP_LOAD_SETTINGS_FAILURE = 'CHANGE_HTTP_LOAD_SETTINGS_FAILURE'

export const HEADING_ANALYSIS_TEST = 'HEADING_ANALYSIS_TEST'
export const HEADING_ANALYSIS_TEST_SUCCESS = 'HEADING_ANALYSIS_TEST_SUCCESS'
export const HEADING_ANALYSIS_TEST_FAILURE = 'HEADING_ANALYSIS_TEST_FAILURE'

export const SEO_FRIENDLY_URL = 'SEO_FRIENDLY_URL'
export const SEO_FRIENDLY_URL_SUCCESS = 'SEO_FRIENDLY_URL_SUCCESS'
export const SEO_FRIENDLY_URL_FAILURE = 'SEO_FRIENDLY_URL_FAILURE'

export const IMG_ALT_TEST = 'IMG_ALT_TEST'
export const IMG_ALT_TEST_SUCCESS = 'IMG_ALT_TEST_SUCCESS'
export const IMG_ALT_TEST_FAILURE = 'IMG_ALT_TEST_FAILURE'

export const BROKEN_LINKS_TEST = 'BROKEN_LINKS_TEST'
export const BROKEN_LINKS_TEST_SUCCESS = 'BROKEN_LINKS_TEST_SUCCESS'
export const BROKEN_LINKS_TEST_FAILURE = 'BROKEN_LINKS_TEST_FAILURE'

export const RECHECK_BACKLINKS_SUMMARY = 'RECHECK_BACKLINKS_SUMMARY'
export const RECHECK_BACKLINKS_SUMMARY_FAILURE = 'RECHECK_BACKLINKS_SUMMARY_FAILURE'
export const RECHECK_BACKLINKS_SUMMARY_SUCCESS = 'RECHECK_BACKLINKS_SUMMARY_SUCCESS'

export const RECHECK_COMMON_KEYWORDS = 'RECHECK_COMMON_KEYWORDS'
export const RECHECK_COMMON_KEYWORDS_SUCCESS = 'RECHECK_COMMON_KEYWORDS_SUCCESS'
export const RECHECK_COMMON_KEYWORDS_FAILURE = 'RECHECK_COMMON_KEYWORDS_FAILURE'

export const TRACK_KEYWORDS_SUCCESS = 'TRACK_KEYWORDS_SUCCESS'
export const TRACK_KEYWORDS_FAILURE = 'TRACK_KEYWORDS_FAILURE'

export const SET_START_DATE_CHART = 'SET_START_DATE_CHART'
export const SET_END_DATE_CHART = 'SET_END_DATE_CHART'

export const SET_REGION_SUCCESS = 'SET_REGION_SUCCESS'
export const SET_REGION_FAILURE = 'SET_REGION_FAILURE'

export const GET_TRACK_KEYWORDS = 'GET_TRACK_KEYWORDS'
export const GET_TRACK_KEYWORDS_SUCCESS = 'GET_TRACK_KEYWORDS_SUCCESS'
export const GET_TRACK_KEYWORDS_FAILURE = 'GET_TRACK_KEYWORDS_FAILURE'

export const GET_TOP_KEYWORDS = 'GET_TOP_KEYWORDS'
export const GET_TOP_KEYWORDS_SUCCESS = 'GET_TOP_KEYWORDS_SUCCESS'
export const GET_TOP_KEYWORDS_FAILURE = 'GET_TOP_KEYWORDS_FAILURE'

export const GET_NEW_TOP_KEYWORDS = 'GET_NEW_TOP_KEYWORDS'
export const GET_NEW_TOP_KEYWORDS_SUCCESS = 'GET_NEW_TOP_KEYWORDS_SUCCESS'
export const GET_NEW_TOP_KEYWORDS_FAILURE = 'GET_NEW_TOP_KEYWORDS_FAILURE'
export const FREE_PAGE_OBJECT = 'FREE_PAGE_OBJECT'
export const FREE_PAGE_OBJECT_SUCCESS = 'FREE_PAGE_OBJECT_SUCCESS'
export const FREE_PAGE_OBJECT_FAILURE = 'FREE_PAGE_OBJECT_FAILURE'

export const FREE_SPF_RECORD = 'FREE_SPF_RECORD'
export const FREE_SPF_RECORD_SUCCESS = 'FREE_SPF_RECORD_SUCCESS'
export const FREE_SPF_RECORD_FAILURE = 'FREE_SPF_RECORD_FAILURE'

export const FREE_NO_FOLLOW = 'FREE_NO_FOLLOW'
export const FREE_NO_FOLLOW_SUCCESS = 'FREE_NO_FOLLOW_SUCCESS'
export const FREE_NO_FOLLOW_FAILURE = 'FREE_NO_FOLLOW_FAILURE'

export const FREE_TEST = 'FREE_TEST'
export const FREE_TEST_SUCCESS = 'FREE_TEST_SUCCESS'
export const FREE_TEST_FAILURE = 'FREE_TEST_FAILURE'

export const QUICK_SEO_CHECKUP = 'QUICK_SEO_CHECKUP'
export const QUICK_SEO_CHECKUP_SUCCESS = 'QUICK_SEO_CHECKUP_SUCCESS'
export const QUICK_SEO_CHECKUP_FAILURE = 'QUICK_SEO_CHECKUP_FAILURE'
export const CLEAR_QUICK_SEO_CHECKUP = 'CLEAR_QUICK_SEO_CHECKUP'

export const GET_QUICK_CHECKUP_REPORT = 'GET_QUICK_CHECKUP_REPORT'
export const GET_QUICK_CHECKUP_REPORT_SUCCESS = 'GET_QUICK_CHECKUP_REPORT_SUCCESS'
export const GET_QUICK_CHECKUP_REPORT_FAILURE = 'GET_QUICK_CHECKUP_REPORT_FAILURE'
export const CLEAR_GET_QUICK_CHECKUP_REPORT = 'CLEAR_GET_QUICK_CHECKUP_REPORT'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS'
export const VERIFY_PASSWORD_FAILURE = 'VERIFY_PASSWORD_FAILURE'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_CLEAR = 'CHANGE_PASSWORD_CLEAR'

export const CLEAR_URL_DETAILS = 'CLEAR_URL_DETAILS'
export const CLEAR_URL_DETAILS_2 = 'CLEAR_URL_DETAILS_2'
export const CLEAR_URL_DETAILS_3 = 'CLEAR_URL_DETAILS_3'
export const CLEAR_KEYWORDS_DETAILS = 'CLEAR_KEYWORDS_DETAILS'
export const CLEAR_COMPETITORS_DETAILS = 'CLEAR_COMPETITORS_DETAILS'
export const SET_KEYWORD_STRING = 'SET_KEYWORD_STRING'
export const CLEAR_VALIDATE_URL_DETAILS = 'CLEAR_VALIDATE_URL_DETAILS'

export const CHANGE_PLAN = 'CHANGE_PLAN'
export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS'
export const CHANGE_PLAN_FAILURE = 'CHANGE_PLAN_FAILURE'

export const GET_SITEOUTAGE_DATA = 'GET_SITEOUTAGE_DATA'
export const GET_SITEOUTAGE_DATA_SUCCESS = 'GET_SITEOUTAGE_DATA_SUCCESS'
export const GET_SITEOUTAGE_DATA_FAILURE = 'GET_SITEOUTAGE_DATA_FAILURE'
export const GET_SITEOUTAGE_DATA_CLEAR = 'GET_SITEOUTAGE_DATA_CLEAR'

export const GET_URL_REPORT = 'GET_URL_REPORT'
export const GET_URL_REPORT_SUCCESS = 'GET_URL_REPORT_SUCCESS'
export const GET_URL_REPORT_FAILURE = 'GET_URL_REPORT_FAILURE'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE'

export const GET_SITEMAP = 'GET_SITEMAP'
export const GET_SITEMAP_SUCCESS = 'GET_SITEMAP_SUCCESS'
export const GET_SITEMAP_FAILURE = 'GET_SITEMAP_FAILURE'

export const GET_LIGHTHOUSE = 'GET_LIGHTHOUSE'
export const GET_LIGHTHOUSE_SUCCESS = 'GET_LIGHTHOUSE_SUCCESS'
export const GET_LIGHTHOUSE_FAILURE = 'GET_LIGHTHOUSE_FAILURE'
export const GET_LIGHTHOUSE_CLEAR = 'GET_LIGHTHOUSE_CLEAR'
export const GET_LIGHTHOUSE_STATUS = "GET_LIGHTHOUSE_STATUS"
export const GET_LIGHTHOUSE_STATUS_SUCCESS = "GET_LIGHTHOUSE_STATUS_SUCCESS"
export const GET_LIGHTHOUSE_STATUS_FAILURE = "GET_LIGHTHOUSE_STATUS_FAILURE"
export const CLEAR_GET_LIGHTHOUSE_STATUS = "CLEAR_GET_LIGHTHOUSE_STATUS"
export const CLEAR_LIGHTHOUSE_INTERVAL_ID = 'CLEAR_LIGHTHOUSE_INTERVAL_ID'
export const LIGHTHOUSE_INTERVAL_ID = 'LIGHTHOUSE_INTERVAL_ID'
export const GET_LIGHTHOUSE_QUICK_SEO = "GET_LIGHTHOUSE_QUICK_SEO"
export const GET_LIGHTHOUSE_QUICK_SEO_SUCCESS = "GET_LIGHTHOUSE_QUICK_SEO_SUCCESS"
export const GET_LIGHTHOUSE_QUICK_SEO_FAILURE = "GET_LIGHTHOUSE_QUICK_SEO_FAILURE"
export const CLEAR_GET_LIGHTHOUSE_QUICK_SEO = "CLEAR_GET_LIGHTHOUSE_QUICK_SEO"

export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'

export const CLEAR_DOMAINS = 'CLEAR_DOMAINS'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const ADD_WEBSITE_FROM_TRIAL = 'ADD_WEBSITE_FROM_TRIAL'

export const VALIDATE_URL_DETAILS = 'VALIDATE_URL_DETAILS'
export const VALIDATE_URL_DETAILS_SUCCESS = 'VALIDATE_URL_DETAILS_SUCCESS'
export const VALIDATE_URL_DETAILS_FAILURE = 'VALIDATE_URL_DETAILS_FAILURE'
export const VALIDATE_URL_ABOUT_TO_HAPPEN = 'VALIDATE_URL_ABOUT_TO_HAPPEN'

export const GET_BACKLINKS_GRAPH = 'GET_BACKLINKS_GRAPH'
export const GET_BACKLINKS_GRAPH_SUCCESS = 'GET_BACKLINKS_GRAPH_SUCCESS'
export const GET_BACKLINKS_GRAPH_FAILURE = 'GET_BACKLINKS_GRAPH_FAILURE'

export const GET_LOST_BACKLINKS = 'GET_LOST_BACKLINKS'
export const GET_LOST_BACKLINKS_SUCCESS = 'GET_LOST_BACKLINKS_SUCCESS'
export const GET_LOST_BACKLINKS_FAILURE = 'GET_LOST_BACKLINKS_FAILURE'

export const GET_BACKLINKS_MOST_RECENTLY = 'GET_BACKLINKS_MOST_RECENTLY'
export const GET_BACKLINKS_MOST_RECENTLY_SUCCESS = 'GET_BACKLINKS_MOST_RECENTLY_SUCCESS'
export const GET_BACKLINKS_MOST_RECENTLY_FAILURE = 'GET_BACKLINKS_MOST_RECENTLY_FAILURE'
export const CLEAR_GET_BACKLINKS_MOST_RECENTLY = 'CLEAR_GET_BACKLINKS_MOST_RECENTLY'

export const GET_BACKLINKS_MOST_IMPORTANT = 'GET_BACKLINKS_MOST_IMPORTANT'
export const GET_BACKLINKS_MOST_IMPORTANT_SUCCESS = 'GET_BACKLINKS_MOST_IMPORTANT_SUCCESS'
export const GET_BACKLINKS_MOST_IMPORTANT_FAILURE = 'GET_BACKLINKS_MOST_IMPORTANT_FAILURE'

export const GET_BACKLINKS_REFERRING_DOMAIN = 'GET_BACKLINKS_REFERRING_DOMAIN'
export const GET_BACKLINKS_REFERRING_DOMAIN_SUCCESS = 'GET_BACKLINKS_REFERRING_DOMAIN_SUCCESS'
export const GET_BACKLINKS_REFERRING_DOMAIN_FAILURE = 'GET_BACKLINKS_REFERRING_DOMAIN_FAILURE'

export const GET_BACKLINKS_ANCHOR_TEXT = 'GET_BACKLINKS_ANCHOR_TEXT'
export const GET_BACKLINKS_ANCHOR_TEXT_SUCCESS = 'GET_BACKLINKS_ANCHOR_TEXT_SUCCESS'
export const GET_BACKLINKS_ANCHOR_TEXT_FAILURE = 'GET_BACKLINKS_ANCHOR_TEXT_FAILURE'

export const GET_BACKLINKS_TLD_DISTRIBUTIONS = 'GET_BACKLINKS_TLD_DISTRIBUTIONS'
export const GET_BACKLINKS_TLD_DISTRIBUTIONS_SUCCESS = 'GET_BACKLINKS_TLD_DISTRIBUTIONS_SUCCESS'
export const GET_BACKLINKS_TLD_DISTRIBUTIONS_FAILURE = 'GET_BACKLINKS_TLD_DISTRIBUTIONS_FAILURE'

export const GET_BACKLINKS_MOSTRECENTLY_CSV = 'GET_BACKLINKS_MOSTRECENTLY_CSV'
export const GET_BACKLINKS_MOSTRECENTLY_CSV_SUCCESS = 'GET_BACKLINKS_MOSTRECENTLY_CSV_SUCCESS'
export const GET_BACKLINKS_MOSTRECENTLY_CSV_FAILURE = 'GET_BACKLINKS_MOSTRECENTLY_CSV_FAILURE'
export const CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV = 'CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV'

export const GET_BACKLINKS_MOSTIMPORTANT_CSV = 'GET_BACKLINKS_MOSTIMPORTANT_CSV'
export const GET_BACKLINKS_MOSTIMPORTANT_CSV_SUCCESS = 'GET_BACKLINKS_MOSTIMPORTANT_CSV_SUCCESS'
export const GET_BACKLINKS_MOSTIMPORTANT_CSV_FAILURE = 'GET_BACKLINKS_MOSTIMPORTANT_CSV_FAILURE'
export const CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV = 'CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV'

export const GET_BACKLINKS_REFERRINGDOMAINS_CSV = 'GET_BACKLINKS_REFERRINGDOMAINS_CSV'
export const GET_BACKLINKS_REFERRINGDOMAINS_CSV_SUCCESS = 'GET_BACKLINKS_REFERRINGDOMAINS_CSV_SUCCESS'
export const GET_BACKLINKS_REFERRINGDOMAINS_CSV_FAILURE = 'GET_BACKLINKS_REFERRINGDOMAINS_CSV_FAILURE'
export const CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_CSV = 'CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_CSV'

export const GET_BACKLINKS_MOSTRECENTLY_JSON = 'GET_BACKLINKS_MOSTRECENTLY_JSON'
export const GET_BACKLINKS_MOSTRECENTLY_JSON_SUCCESS = 'GET_BACKLINKS_MOSTRECENTLY_JSON_SUCCESS'
export const GET_BACKLINKS_MOSTRECENTLY_JSON_FAILURE = 'GET_BACKLINKS_MOSTRECENTLY_JSON_FAILURE'
export const CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON = 'CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON'

export const GET_BACKLINKS_MOSTIMPORTANT_JSON = 'GET_BACKLINKS_MOSTIMPORTANT_JSON'
export const GET_BACKLINKS_MOSTIMPORTANT_JSON_SUCCESS = 'GET_BACKLINKS_MOSTIMPORTANT_JSON_SUCCESS'
export const GET_BACKLINKS_MOSTIMPORTANT_JSON_FAILURE = 'GET_BACKLINKS_MOSTIMPORTANT_JSON_FAILURE'
export const CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON = 'CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON'

export const GET_BACKLINKS_REFERRINGDOMAINS_JSON = 'GET_BACKLINKS_REFERRINGDOMAINS_JSON'
export const GET_BACKLINKS_REFERRINGDOMAINS_JSON_SUCCESS = 'GET_BACKLINKS_REFERRINGDOMAINS_JSON_SUCCESS'
export const GET_BACKLINKS_REFERRINGDOMAINS_JSON_FAILURE = 'GET_BACKLINKS_REFERRINGDOMAINS_JSON_FAILURE'
export const CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_JSON = 'CLEAR_GET_BACKLINKS_REFERRINGDOMAINS_JSON'

export const GET_REGIONAL_LOCATION_CONFIGURATION = 'GET_REGIONAL_LOCATION_CONFIGURATION'
export const GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS = 'GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS'
export const GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE = 'GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE'
export const CLEAR_REGIONAL_LOCATION_CONFIGURATION = 'CLEAR_REGIONAL_LOCATION_CONFIGURATION'

export const GET_TOP_KEYWORDS_LOCATION = 'GET_TOP_KEYWORDS_LOCATION'
export const GET_TOP_KEYWORDS_LOCATION_SUCCESS = 'GET_TOP_KEYWORDS_LOCATION_SUCCESS'
export const GET_TOP_KEYWORDS_LOCATION_FAILURE = 'GET_TOP_KEYWORDS_LOCATION_FAILURE'

export const STORE_NEW_TOP_KEYWORDS = 'STORE_NEW_TOP_KEYWORDS'
export const STORE_NEW_TOP_KEYWORDS_SUCCESS = 'STORE_NEW_TOP_KEYWORDS_SUCCESS'
export const STORE_NEW_TOP_KEYWORDS_FAILURE = 'STORE_NEW_TOP_KEYWORDS_FAILURE'
export const CLEAR_STORE_NEW_TOP_KEYWORD = 'CLEAR_STORE_NEW_TOP_KEYWORD'

export const UPDATE_SEO_TEST_PROGRESS_PERCENTAGE = 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE'
export const UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE = 'UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE'

export const GET_COMPETITOR_SEO_SCORE = 'GET_COMPETITOR_SEO_SCORE'
export const GET_COMPETITOR_SEO_SCORE_SUCCESS = 'GET_COMPETITOR_SEO_SCORE_SUCCESS'
export const GET_COMPETITOR_SEO_SCORE_FAILURE = 'GET_COMPETITOR_SEO_SCORE_FAILURE'

export const MAIN_URL_SEO_SCORE = 'MAIN_URL_SEO_SCORE'
export const MAIN_URL_SEO_SCORE_SUCCESS = 'MAIN_URL_SEO_SCORE_SUCCESS'
export const MAIN_URL_SEO_SCORE_FAILURE = 'MAIN_URL_SEO_SCORE_FAILURE'

export const TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT = 'TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT'
export const ABOUT_TO_TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT =
  'ABOUT_TO_TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT'

export const WHITELIST_BROKEN_URL = 'WHITELIST_BROKEN_URL'
export const WHITELIST_BROKEN_URL_SUCCESS = 'WHITELIST_BROKEN_URL_SUCCESS'
export const WHITELIST_BROKEN_URL_FAILURE = 'WHITELIST_BROKEN_URL_FAILURE'

export const GET_TOP_FIVE_KEYWORD = 'GET_TOP_FIVE_KEYWORD'
export const GET_TOP_FIVE_KEYWORD_SUCCESS = 'GET_TOP_FIVE_KEYWORD_SUCCESS'
export const GET_TOP_FIVE_KEYWORD_FAILURE = 'GET_TOP_FIVE_KEYWORD_FAILURE'

export const GET_AVG_POSITION_KEYWORD = 'GET_AVG_POSITION_KEYWORD'
export const GET_AVG_POSITION_KEYWORD_SUCCESS = 'GET_AVG_POSITION_KEYWORD_SUCCESS'
export const GET_AVG_POSITION_KEYWORD_FAILURE = 'GET_AVG_POSITION_KEYWORD_FAILURE'

export const ADD_WEBSITE_THROUGH_CACHE = 'ADD_WEBSITE_THROUGH_CACHE'
export const ADD_WEBSITE_THROUGH_CACHE_SUCCESS = 'ADD_WEBSITE_THROUGH_CACHE_SUCCESS'
export const ADD_WEBSITE_THROUGH_CACHE_FAILURE = 'ADD_WEBSITE_THROUGH_CACHE_FAILURE'
export const CLEAR_ADD_WEBSITE_THROUGH_CACHE_FAILURE = 'CLEAR_ADD_WEBSITE_THROUGH_CACHE_FAILURE'

export const GET_BROKEN_LINKS_LIST = 'GET_BROKEN_LINKS_LIST'
export const GET_BROKEN_LINKS_LIST_SUCCESS = 'GET_BROKEN_LINKS_LIST_SUCCESS'
export const GET_BROKEN_LINKS_LIST_FAILURE = 'GET_BROKEN_LINKS_LIST_FAILURE'

export const STOP_DASHBOARD_PATCH_API_CALLS = 'STOP_DASHBOARD_PATCH_API_CALLS'
export const CLEAR_STOP_DASHBOARD_PATCH_API_CALLS = 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS'

export const STOP_BACKLINKS_STATS_API_CALL = 'STOP_BACKLINKS_STATS_API_CALL'
export const CLEAR_STOP_BACKLINKS_STATS_API_CALL = 'CLEAR_STOP_BACKLINKS_STATS_API_CALL'

export const GET_NUMBER_OF_RESULT = 'GET_NUMBER_OF_RESULT'
export const GET_NUMBER_OF_RESULT_SUCCESS = 'GET_NUMBER_OF_RESULT_SUCCESS'
export const GET_NUMBER_OF_RESULT_FAILURE = 'GET_NUMBER_OF_RESULT_FAILURE'
export const GET_UPDATED_TOP_KEYWORD = 'GET_UPDATED_TOP_KEYWORD'
export const GET_UPDATED_KEYWORD_RESEARCH = 'GET_UPDATED_KEYWORD_RESEARCH'

export const GET_TEST_RESULTS = "GET_TEST_RESULTS"
export const GET_TEST_RESULTS_SUCCESS = "GET_TEST_RESULTS_SUCCESS"
export const GET_TEST_RESULTS_FAILURE = "GET_TEST_RESULTS_FAILURE"

// Reporting
// List
export const WHITE_LABEL_TEMPLATE_LIST_START = 'WHITE_LABEL_TEMPLATE_LIST_START'
export const WHITE_LABEL_TEMPLATE_LIST_SUCCESS = 'WHITE_LABEL_TEMPLATE_LIST_SUCCESS'
export const WHITE_LABEL_TEMPLATE_LIST_ERROR = 'WHITE_LABEL_TEMPLATE_LIST_ERROR'
export const WHITE_LABEL_TEMPLATE_LIST_CLEAR = 'WHITE_LABEL_TEMPLATE_LIST_CLEAR'
// CREATE TEMPLATE
export const WHITE_LABEL_TEMPLATE_CREATE_START = 'WHITE_LABEL_TEMPLATE_CREATE_START'
export const WHITE_LABEL_TEMPLATE_CREATE_SUCCESS = 'WHITE_LABEL_TEMPLATE_CREATE_SUCCESS'
export const WHITE_LABEL_TEMPLATE_CREATE_ERROR = 'WHITE_LABEL_TEMPLATE_CREATE_ERROR'
export const WHITE_LABEL_TEMPLATE_CREATE_CLEAR = 'WHITE_LABEL_TEMPLATE_CREATE_CLEAR'
// DELETE TEMPLATE
export const WHITE_LABEL_TEMPLATE_DELETE_START = 'WHITE_LABEL_TEMPLATE_DELETE_START'
export const WHITE_LABEL_TEMPLATE_DELETE_SUCCESS = 'WHITE_LABEL_TEMPLATE_DELETE_SUCCESS'
export const WHITE_LABEL_TEMPLATE_DELETE_ERROR = 'WHITE_LABEL_TEMPLATE_DELETE_ERROR'
export const WHITE_LABEL_TEMPLATE_DELETE_CLEAR = 'WHITE_LABEL_TEMPLATE_DELETE_CLEAR'
// Generate report

export const WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST =
  'WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST'
export const WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_SUCCESS =
  'WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_SUCCESS'
export const WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_ERROR = 'WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_ERROR'
export const WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_CLEAR = 'WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_CLEAR'

//Generate Whitelabel Reort
export const GENERATE_WHITE_LABEL_REPORT_REQUEST = 'GENERATE_WHITE_LABEL_REPORT_REQUEST'
export const GENERATE_WHITE_LABEL_REPORT_SUCCESS = 'GENERATE_WHITE_LABEL_REPORT_SUCCESS'
export const GENERATE_WHITE_LABEL_REPORT_FAILURE = 'GENERATE_WHITE_LABEL_REPORT_FAILURE'
export const GENERATE_WHITE_LABEL_REPORT_CLEAR = 'GENERATE_WHITE_LABEL_REPORT_CLEAR'

export const ADD_EVERYWHERE_KEYWORDS = 'ADD_EVERYWHERE_KEYWORDS'
export const ADD_EVERYWHERE_KEYWORDS_SUCCESS = 'ADD_EVERYWHERE_KEYWORDS_SUCCESS'
export const ADD_EVERYWHERE_KEYWORDS_FAILURE = 'ADD_EVERYWHERE_KEYWORDS_FAILURE'

export const KEYWORDS_RESEARCH_DATA = 'KEYWORDS_RESEARCH_DATA'
export const KEYWORDS_RESEARCH_DATA_SUCCESS = 'KEYWORDS_RESEARCH_DATA_SUCCESS'
export const KEYWORDS_RESEARCH_DATA_FAILURE = 'KEYWORDS_RESEARCH_DATA_FAILURE'

export const IS_START_SCAN = 'IS_START_SCAN'
export const SET_BACKLINKS_DATA = 'SET_BACKLINKS_DATA'
export const MODAL_OPEN_LIGHTHOUSE = 'MODAL_OPEN_LIGHTHOUSE'

export const SUPPORT_REQUEST = 'SUPPORT_REQUEST'
export const SUPPORT_REQUEST_SUCCESS = 'SUPPORT_REQUEST_SUCCESS'
export const SUPPORT_REQUEST_FAILURE = 'SUPPORT_REQUEST_FAILURE'
export const CLEAR_SUPPORT_REQUEST = 'CLEAR_SUPPORT_REQUEST'

export const WEBSITE_LIMIT = 'WEBSITE_LIMIT'
export const WEBSITE_LIMIT_SUCCESS = 'WEBSITE_LIMIT_SUCCESS'
export const WEBSITE_LIMIT_FAILURE = 'WEBSITE_LIMIT_FAILURE'
export const CLEAR_WEBSITE_LIMIT = 'CLEAR_WEBSITE_LIMIT'

export const KEYWORD_LIMIT = 'KEYWORD_LIMIT'
export const KEYWORD_LIMIT_SUCCESS = 'KEYWORD_LIMIT_SUCCESS'
export const KEYWORD_LIMIT_FAILURE = 'KEYWORD_LIMIT_FAILURE'
export const CLEAR_KEYWORD_LIMIT = 'CLEAR_KEYWORD_LIMIT'
export const AVAILABLE_KEYWORD_LIMIT = 'AVAILABLE_KEYWORD_LIMIT'
export const AVAILABLE_KEYWORD_LIMIT_SUCCESS = 'AVAILABLE_KEYWORD_LIMIT_SUCCESS'
export const AVAILABLE_KEYWORD_LIMIT_FAILURE  = 'AVAILABLE_KEYWORD_LIMIT_FAILURE'

export const COMPETITOR_LIMIT = 'COMPETITOR_LIMIT'
export const COMPETITOR_LIMIT_SUCCESS = 'COMPETITOR_LIMIT_SUCCESS'
export const COMPETITOR_LIMIT_FAILURE = 'COMPETITOR_LIMIT_FAILURE'
export const CLEAR_COMPETITOR_LIMIT = 'CLEAR_COMPETITOR_LIMIT'

export const QUICK_SCAN_LIMIT = 'QUICK_SCAN_LIMIT'
export const QUICK_SCAN_LIMIT_SUCCESS = 'QUICK_SCAN_LIMIT_SUCCESS'
export const QUICK_SCAN_LIMIT_FAILURE = 'QUICK_SCAN_LIMIT_FAILURE'
export const CLEAR_QUICK_SCAN_LIMIT = 'CLEAR_QUICK_SCAN_LIMIT'

export const KEYWORD_RESEARCH_LOADING = 'KEYWORD_RESEARCH_LOADING' 
export const CLEAR_KEYWORD_RESEARCH_LOADING = 'CLEAR_KEYWORD_RESEARCH_LOADING' 

export const DISCARD_TRIAL_PERIOD = 'DISCARD_TRIAL_PERIOD' 
export const DISCARD_TRIAL_PERIOD_SUCCESS = 'DISCARD_TRIAL_PERIOD_SUCCESS' 
export const DISCARD_TRIAL_PERIOD_FAILURE = 'DISCARD_TRIAL_PERIOD_FAILURE' 
export const CLEAR_DISCARD_TRIAL_PERIOD = 'CLEAR_DISCARD_TRIAL_PERIOD' 

export const NEW_NOTIFICATION = "NEW_NOTIFICATION"
export const NEW_NOTIFICATION_SUCCESS = "NEW_NOTIFICATION_SUCCESS"
export const NEW_NOTIFICATION_FAILURE = "NEW_NOTIFICATION_FAILURE"

export const READ_SINGLE_NOTIFICATION = "READ_SINGLE_NOTIFICATION"
export const READ_SINGLE_NOTIFICATION_SUCCESS = "READ_SINGLE_NOTIFICATION_SUCCESS"
export const READ_SINGLE_NOTIFICATION_FAILURE = "READ_SINGLE_NOTIFICATION_FAILURE"
export const CLEAR_READ_SINGLE_NOTIFICATION = "CLEAR_READ_SINGLE_NOTIFICATION"

export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION"
export const READ_ALL_NOTIFICATION_SUCCESS = "READ_ALL_NOTIFICATION_SUCCESS"
export const READ_ALL_NOTIFICATION_FAILURE = "READ_ALL_NOTIFICATION_FAILURE"
export const CLEAR_READ_ALL_NOTIFICATION = "CLEAR_READ_ALL_NOTIFICATION"

export const STRIPE_CHECKOUT = "STRIPE_CHECKOUT"
export const STRIPE_CHECKOUT_SUCCESS = "STRIPE_CHECKOUT_SUCCESS"
export const STRIPE_CHECKOUT_FAILURE = "STRIPE_CHECKOUT_FAILURE"
export const CLEAR_STRIPE_CHECKOUT = "CLEAR_STRIPE_CHECKOUT"

export const SAVE_SUBSCRIPTION = "SAVE_SUBSCRIPTION"
export const CLEAR_SAVE_SUBSCRIPTION = "CLEAR_SAVE_SUBSCRIPTION"

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD"
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS"
export const ADD_PAYMENT_METHOD_FAILURE = "ADD_PAYMENT_METHOD_FAILURE"
export const CLEAR_ADD_PAYMENT_METHOD = "CLEAR_ADD_PAYMENT_METHOD"

export const TRIAL_SEO_CHECKUP = "TRIAL_SEO_CHECKUP"
export const TRIAL_SEO_CHECKUP_SUCCESS = "TRIAL_SEO_CHECKUP_SUCCESS"
export const TRIAL_SEO_CHECKUP_FAILURE = "TRIAL_SEO_CHECKUP_FAILURE"
export const CLEAR_TRIAL_SEO_CHECKUP = "CLEAR_TRIAL_SEO_CHECKUP"

export const GET_TRIAL_SEO_REPORT = "GET_TRIAL_SEO_REPORT"
export const GET_TRIAL_SEO_REPORT_SUCCESS = "GET_TRIAL_SEO_REPORT_SUCCESS"
export const GET_TRIAL_SEO_REPORT_FAILURE = "GET_TRIAL_SEO_REPORT_FAILURE"
export const CLEAR_GET_TRIAL_SEO_REPORT = "CLEAR_GET_TRIAL_SEO_REPORT"

export const OPEN_REGISTER_MODAL = "OPEN_REGISTER_MODAL"

export const GET_PUBLIC_KEY = 'GET_PUBLIC_KEY' 
export const GET_PUBLIC_KEY_SUCCESS = 'GET_PUBLIC_KEY_SUCCESS' 
export const GET_PUBLIC_KEY_FAILURE = 'GET_PUBLIC_KEY_FAILURE' 
export const CLEAR_GET_PUBLIC_KEY = 'CLEAR_GET_PUBLIC_KEY' 

export const GOOGLE_ANALYTICS_MODAL = 'GOOGLE_ANALYTICS_MODAL' 

export const GET_AUTH_URL = 'GET_AUTH_URL' 
export const GET_AUTH_URL_SUCCESS  = 'GET_AUTH_URL_SUCCESS' 
export const GET_AUTH_URL_FAILURE = 'GET_AUTH_URL_FAILURE' 
export const CLEAR_GET_AUTH_URL = 'CLEAR_GET_AUTH_URL' 

export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN' 
export const GET_AUTH_TOKEN_SUCCESS  = 'GET_AUTH_TOKEN_SUCCESS' 
export const GET_AUTH_TOKEN_FAILURE = 'GET_AUTH_TOKEN_FAILURE' 
export const CLEAR_GET_AUTH_TOKEN = 'CLEAR_GET_AUTH_TOKEN' 

export const GENERATE_RESEARCH = 'GENERATE_RESEARCH' 
export const GENERATE_RESEARCH_SUCCESS  = 'GENERATE_RESEARCH_SUCCESS' 
export const GENERATE_RESEARCH_FAILURE = 'GENERATE_RESEARCH_FAILURE' 
export const CLEAR_GENERATE_RESEARCH = 'CLEAR_GENERATE_RESEARCH' 
export const CLEAR_GENERATE_RESEARCH_DATA = 'CLEAR_GENERATE_RESEARCH_DATA' 

export const ANALYTICS_DATA = 'ANALYTICS_DATA' 
export const ANALYTICS_DATA_SUCCESS  = 'ANALYTICS_DATA_SUCCESS' 
export const ANALYTICS_DATA_FAILURE = 'ANALYTICS_DATA_FAILURE' 
export const CLEAR_ANALYTICS_DATA = 'CLEAR_ANALYTICS_DATA'

export const DISCONNECT_GOOGLE = 'DISCONNETCT_GOOGLE' 
export const DISCONNECT_GOOGLE_SUCCESS = 'DISCONNETCT_GOOGLE_SUCCESS' 
export const DISCONNECT_GOOGLE_FAILURE = 'DISCONNETCT_GOOGLE_FAILURE' 
export const CLEAR_DISCONNECT_GOOGLE = 'CLEAR_DISCONNECT_GOOGLE' 

export const STORE_ANALYTICS_DATA = 'STORE_ANALYTICS_DATA' 
export const STORE_ANALYTICS_DATA_SUCCESS = 'STORE_ANALYTICS_DATA_SUCCESS' 
export const STORE_ANALYTICS_DATA_FAILURE = 'STORE_ANALYTICS_DATA_FAILURE' 
export const CLEAR_STORE_ANALYTICS_DATA = 'CLEAR_STORE_ANALYTICS_DATA'

export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA'
export const GET_ANALYTICS_DATA_SUCCESS = 'GET_ANALYTICS_DATA_SUCCESS'
export const GET_ANALYTICS_DATA_FAILURE = 'GET_ANALYTICS_DATA_FAILURE'
export const CLEAR_GET_ANALYTICS_DATA = 'CLEAR_GET_ANALYTICS_DATA'
export const CLEAR_GET_ANALYTICS_ERROR = 'CLEAR_GET_ANALYTICS_ERROR'

export const CONTACT_US = 'CONTACT_US'
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS'
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE'
export const CLEAR_CONTACT_US = 'CLEAR_CONTACT_US'

export const GENERATE_TICKET = 'GENERATE_TICKET' 
export const GENERATE_TICKET_SUCCESS = 'GENERATE_TICKET_SUCCESS' 
export const GENERATE_TICKET_FAILURE = 'GENERATE_TICKET_FAILURE' 
export const CLEAR_GENERATE_TICKET = 'CLEAR_GENERATE_TICKET'
import { Button, Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import * as Icon from 'react-feather'
import { useSelector } from 'react-redux'

const UrlDetail = ({ activeLink }) => {
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains || [])

  return (
    <Card className="mb-0 url-details">
      <Card.Header>
        <h5 className="mb-0 f-bold card-title">
          <> {''}</>
        </h5>
        <p className="mb-3">Summary</p>
        <Button variant="outline-secondary">View Reports</Button>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>
                Site IP -{' '}
                <b className="f-bold h4">
                  {
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  }
                </b>
              </strong>
            </p>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {usersDomain?.[activeLink]?.statsData?.[0]?.siteIP
                      ? `Your website's site IP is ${usersDomain?.[activeLink]?.statsData?.[0]?.siteIP}`
                      : 'No data available'}
                  </Tooltip>
                }
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>SEO Score</strong>
            </p>
            <h3 className={'cursor-pointer'}>
              {' '}
              {
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              }
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {''} </Tooltip>}>
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>Backlinks</strong>
            </p>
            <h3 className="f-bold">
              {
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              }
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {''} </Tooltip>}>
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>

        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>Keyword Avg. Position</strong>
            </p>
            <h3 className="f-bold">
              {
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              }
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {''} </Tooltip>}>
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>Desktop Loading Time</strong>
            </p>
            <h3>
              <span className="f-bold">
                {
                  <>
                    <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                  </>
                }
              </span>
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {''} </Tooltip>}>
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Mobile Loading Time</strong> &nbsp;
            </p>
            <h3>
              <span>
                {
                  <>
                    <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                  </>
                }
              </span>
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {''} </Tooltip>}>
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>Site Outage</strong> &nbsp;
            </p>
            {
              <h3>
                {' '}
                <Spinner size="sm" color="dark" className="ml-2 mr-2" />{' '}
              </h3>
            }
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-top`}> {''} </Tooltip>}>
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>Indexed URL's</strong> &nbsp;
            </p>
            <h3 className="f-bold">
              {
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              }{' '}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Total number of Url index in search engine </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>

        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong>Branded URL Results</strong> &nbsp;
            </p>
            <h3 className="f-bold">
              {
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              }{' '}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Total number of Branded URL in search engine </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default UrlDetail

import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Alert, Button, Form } from 'react-bootstrap'

function ResetPassword(props) {
  return (
    <Formik
      initialValues={{
        email: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          props.resetPassoword(values.email)
        } catch (error) {
          const message = error.message || 'Something went wrong'

          setStatus({ success: false })
          setErrors({ submit: message })
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
          </Form.Group>
          <div className="text-center mt-3">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              Reset password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPassword

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const SslFreakTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.sslFreakTest.heading}
      whatIsIT={freeTools.sslFreakTest.what}
      howToFixBeforeCode={freeTools.sslFreakTest.howSectionBeforeCode}
    />
  )
}

export default SslFreakTest

import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Nav, Row, Tab, Table } from 'react-bootstrap'

import * as moment from 'moment'
import * as _ from 'lodash'
import { DatePicker, Space } from 'antd'

import { getAvgPositionKeywordApi, getTopFiveKeywordApi } from 'redux/actions/topKeywords'
import { storeUserData, verifyTokenStart } from 'redux/actions/register'
import { getUrlReport } from 'redux/actions/freeTools'
import {
  changeActiveLink,
  getBacklinksStats,
  getCompetitorSeoScore,
  getMainUrlSeoScore,
  getSiteOutageData,
  getTestResults,
  getUserData,
  patchUserDomainsDashboard,
} from 'redux/actions/dashboard/index.js'

import { Loader } from 'components/uiCore'
import SeoScoreProgress from 'pages/dashboards/SeoProgress.js'
import MainUrlNav from 'components/MainUrlNav'


import { getDataForDashboard } from 'utils/seoIssue.js'
import paths from 'constants/paths'
import { getActiveUserData, isMainUrlMatch } from 'constants/Utils'
import './reportsTable.scss'

const d = new Date()
let monthName = moment(d).format('YYYY MMMM')
const ReportsTable = () => {
  const [datePickerValue, setDatePickerValue] = useState()

  const [isTabChanged, setIsTabChanged] = useState(false)
  const [currentMonth, setCurrentMonth] = useState(monthName)
  const [lastUpdatedMonth, setLastUpdatedMonth] = useState('')
  const [nextScanDate, setNextScanDate] = useState('')

  const [finalSeoScore, setFinalSeoScore] = useState(0)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [competitorSeoPercentage, setCompetitorSeoPercentage] = useState(0)

  const {
    userData,
    activeLink,
    usersDomains: usersDomain,
    chartDataForAntD,
    stopPatchApiCall,
    activeKeywordData,
    activeSiteOutageData,
    mainUrlScoreDataArray,
    competitorSeoScoreData,
    stopBacklinksStatsApiCall,
    seoProgressPercentage,
    testResultData,
    loadingTestResults
  } = useSelector(({ dashboard }) => dashboard)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const interval = useRef(null)
  const getUserDataInterval = useRef(null)
  const backlinksInterval = useRef(null)
  const backlinksStatsInterval = useRef(null)
  const patchUserRemainInterval = useRef(null)

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)
   
  let competitorsLength = activeSearchData?.competitors?.length || 0
  let formattedDate

  // Component Will mount
  useEffect(() => {
    async function fetchMyAPI() {
      dispatch({ type: 'CLEAR_URL_DETAILS' })
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
      const data = await localStorage.getItem('userData')
      dispatch(getTestResults())
      await verifyTokenStart()
      if (data && JSON.parse(data)._id) {
        dispatch(storeUserData(JSON.parse(data)))
        if (usersDomain && usersDomain.length) {
        } else {
          dispatch(patchUserDomainsDashboard())
        }

        setTimeout(() => {
          dispatch(patchUserDomainsDashboard())
          dispatch(getBacklinksStats())
          dispatch(getUserData())
          dispatch(getSiteOutageData())
          dispatch(getTopFiveKeywordApi())
          dispatch(getAvgPositionKeywordApi())
          dispatch(getTestResults())
        }, 2000)
      } else {
        navigate('/')
      }

      patchDomains()
      patchUserData()
      patchBacklinks()
    }
    fetchMyAPI()
  }, [])

  // Component Will unmount
  useEffect(() => {
    return () => {
      clearInterval(interval.current)
      clearInterval(getUserDataInterval.current)
      clearInterval(backlinksInterval.current)
      clearInterval(backlinksStatsInterval.current)
      clearInterval(patchUserRemainInterval.current)
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
    }
  }, [])

  useEffect(() => {
    if (isTabChanged) {
      if (!stopPatchApiCall && usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
        clearInterval(interval.current)
        clearInterval(backlinksInterval.current)
        clearInterval(backlinksStatsInterval.current)
        patchDomains()
        patchBacklinks()
      }

      if(usersDomain && activeLink > -1 && usersDomain[activeLink]?.items){
        clearInterval(getUserDataInterval.current)
        patchUserData()
      }

    }
  }, [activeLink])

  useEffect(() => {
    if (stopPatchApiCall) {
      clearInterval(interval.current)
      clearInterval(backlinksInterval.current)
      clearInterval(patchUserRemainInterval.current)
    }
    if (stopBacklinksStatsApiCall) {
      clearInterval(backlinksStatsInterval.current)
    }
  }, [stopPatchApiCall, stopBacklinksStatsApiCall])

  const patchDomains = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id && !stopPatchApiCall) {
      interval.current = setInterval(() => {
        if (JSON.parse(data)._id) {
          dispatch(patchUserDomainsDashboard())
          dispatch(getSiteOutageData())
        }
      }, 10000)
    }
  }

  const patchBacklinks = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      if (!stopPatchApiCall) {
        backlinksInterval.current = setInterval(() => {
          if (
            usersDomain &&
            activeLink !== undefined &&
            usersDomain[activeLink] &&
            usersDomain[activeLink].items &&
            usersDomain[activeLink].items.length &&
            usersDomain[activeLink].items?.[0] &&
            usersDomain[activeLink].items?.[0]?.['_id']
          ) {
            dispatch(getUrlReport(usersDomain[activeLink].items?.[0]?.['_id']))
          }
          dispatch(getBacklinksStats())
          dispatch(getTopFiveKeywordApi())
          dispatch(getSiteOutageData())
          dispatch(getAvgPositionKeywordApi())
        }, 20000)
      }

      backlinksStatsInterval.current = setInterval(() => {
        dispatch(getBacklinksStats())
      }, 10000)
    }
  }

  const patchUserData = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      getUserDataInterval.current = setInterval(() => {
        dispatch(getUserData())
      }, 10000)
    }
  }

  useEffect(() => {
    if (usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
      const filteredData =
        mainUrlScoreDataArray?.length &&
        mainUrlScoreDataArray.filter((item) => {
          const mainUrl = item?.data?.[0]?.mainUrl
          const redirectUrl = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url

          const isUrlMatched = isMainUrlMatch(mainUrl, redirectUrl)
          return isUrlMatched
        })
      const result =
        filteredData?.length &&
        filteredData
          .filter((item) => {
            return item.data.some((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment.utc(
                usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
              return checkDate === lastUpdatedDate
            })
          })
          .map((item) => {
            return {
              id: item._id,
              data: item.data.filter((obj) => {
                const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                  'MM-DD',
                )
                const lastUpdatedDate = moment.utc(
                  usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
                return checkDate === lastUpdatedDate
              }),
            }
          })
      const sum =
        result?.length &&
        result?.[0]?.data.reduce((accumulator, subItem) => {
          return accumulator + subItem.count
        }, 0)

      let totalSeoScore

      if (sum) {
        totalSeoScore = sum + finalSeoScore
      } else {
        totalSeoScore = finalSeoScore
      }
      let totalCompetitorPercent
      if (competitorSeoScoreData?.length > 0) {
        let filterDataOfCompetitor = []
        competitorSeoScoreData.forEach((item) => {
          item?.data &&
            item?.data?.length &&
            item?.data?.forEach((itemData) => {
              if (
                itemData.mainUrl ===
                usersDomain[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
              ) {
                let dataToPush = {
                  ...itemData,
                  competitiorUrl: item._id,
                }
                filterDataOfCompetitor.push(dataToPush)
              }
            })
        })

        let todayFilterDataOfCompetitor = filterDataOfCompetitor.filter((item) => {
          const checkDate = moment(`${item?.year}-${item?.month}-${item?.day}`, 'YYYY-MM-DD').format('MM-DD')
          const lastUpdatedDate = moment.utc(
            usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
          return checkDate === lastUpdatedDate
        })

        const sumForToday = todayFilterDataOfCompetitor.reduce((accumulator, subItem) => {
          return accumulator + subItem.count
        }, 0)

        if (competitorsLength === 1) {
          totalCompetitorPercent = (sumForToday / 69) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 2) {
          totalCompetitorPercent = (sumForToday / 138) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 3) {
          totalCompetitorPercent = (sumForToday / 207) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 4) {
          totalCompetitorPercent = (sumForToday / 276) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 5) {
          totalCompetitorPercent = (sumForToday / 345) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        } else if (competitorsLength === 6) {
          totalCompetitorPercent = (sumForToday / 414) * 100
          setCompetitorSeoPercentage(totalCompetitorPercent > 100 ? 100 : totalCompetitorPercent.toFixed(0))
        }
      } else {
      }

      if (totalSeoScore !== 0) {
        let totalPercent
        if (finalSeoScore === 0) {
          totalPercent = (totalSeoScore / 73) * 100
        } else {
          totalPercent = (totalSeoScore / 83) * 100
        }
        if (competitorsLength) {
          if (totalPercent >= 100 && totalCompetitorPercent >= 100) {
            setTimeout(() => {
             // dispatch({ type: 'STOP_DASHBOARD_PATCH_API_CALLS' })
              dispatch({ type: 'STOP_BACKLINKS_STATS_API_CALL'})
              clearInterval(getUserDataInterval.current)
            }, 2000)
          }
        } else {
          if (totalPercent >= 100) {
            setTimeout(() => {
             // dispatch({ type: 'STOP_DASHBOARD_PATCH_API_CALLS' })
              dispatch({ type: 'STOP_BACKLINKS_STATS_API_CALL'})
              clearInterval(getUserDataInterval.current)
            }, 2000)
          }
        }
        dispatch({ type: 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE', payload: totalPercent })
        setProgressPercentage(totalPercent.toFixed(0))
      } else {
        if (!totalSeoScore || totalSeoScore === 0 || totalSeoScore === undefined) {
          dispatch({ type: 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE', payload: 1 })
          setProgressPercentage(1)
        } else {
          let totalPercent = (totalSeoScore / 75) * 100

          dispatch({ type: 'UPDATE_SEO_TEST_PROGRESS_PERCENTAGE', payload: totalPercent.toFixed(0) })
          setProgressPercentage(totalPercent.toFixed(0))
        }
      }
    }
  }, [usersDomain, activeLink, competitorSeoScoreData, finalSeoScore])

  useEffect(() => {
    let totalCount = 0
    const dashboardItem = usersDomain && activeLink !== undefined && usersDomain?.[activeLink]
    if (
      dashboardItem?.items?.[0]?.performance &&
      dashboardItem?.items?.[0]?.accessbility &&
      dashboardItem?.items?.[0]?.bestPractices
    ) {
      totalCount++
    }
    if (dashboardItem?.statsData?.[0]?.googlePageRank) {
      totalCount++
    }
    if (dashboardItem?.statsData?.[0]?.siteIP || dashboardItem?.statsData?.[0]?.siteIP === null) {
      totalCount++
    }

    if (dashboardItem?.statsData?.[0]?.backlinks) {
      totalCount++
    }

    // if(avgData) {
    totalCount++
    // }

    if (dashboardItem?.items?.[0]?.desktopSpeed?.displayValue) {
      totalCount++
    }

    if (dashboardItem?.items?.[0]?.mobileSpeed?.displayValue) {
      totalCount++
    }

    if (
      activeSiteOutageData?.[activeSiteOutageData?.length - 1]?.dataset?.[
        activeSiteOutageData?.[activeSiteOutageData?.length - 1]?.dataset?.length - 1
      ]?.values.length >= 13
    ) {
      totalCount++
    }

    if (
      dashboardItem?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts !== null &&
      dashboardItem?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts !== undefined
    ) {
      totalCount++
    }

    if (
      dashboardItem?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls !== null &&
      dashboardItem?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls !== undefined
    ) {
      totalCount++
    }

    setFinalSeoScore(totalCount)
  }, [usersDomain, activeLink, activeSiteOutageData, chartDataForAntD, activeKeywordData])

  useEffect(() => {
    async function triggerMainSeo() {
      if (usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
        const userData = await localStorage.getItem('userData')
        const userDataParse = JSON.parse(userData)
        let mainUrlList = []
        if (userData && userDataParse?._id) {
          usersDomain &&
            usersDomain?.length &&
            usersDomain.forEach((domain) => {
              if (domain?.items && domain?.items?.length) {
                domain?.items.forEach((domainItem) => {
                  if (userDataParse?._id == domainItem?.user) {
                    mainUrlList.push(domainItem?._id)
                  }
                })
              }
            })
          let finalUrl = mainUrlList?.toString()
          if (finalUrl && finalUrl?.length && finalUrl?.includes(',')) {
            finalUrl = finalUrl.split(',')
          } else {
            finalUrl = [finalUrl]
          }
          if (finalUrl && finalUrl?.length) {
            if (progressPercentage && progressPercentage > 99) {
            } else {

              dispatch(getMainUrlSeoScore({ mainUrlList: finalUrl }))
            }
            if (competitorsLength) {
              if (competitorSeoPercentage > -1 && competitorSeoPercentage < 100) {
                dispatch(getCompetitorSeoScore({ mainUrlList: finalUrl }))
              } else {
              }
            }
          }
        }
      }
    }
    triggerMainSeo()
  }, [usersDomain, activeLink])

  function onChange(date, dateString) {
    setCurrentMonth(dateString)
    setLastUpdatedMonth(dateString)
    const userData = testResultData && testResultData?.[activeLink]?.results?.map((items) => {
      return {
      ...items,
      date: moment.utc(items?.createdAt).format('DD-MM-YYYY'),
      }
    })

    const filterUserData = userData?.filter(
      (userCreatedAt) =>
        moment.utc(userCreatedAt?.createdAt).format('YYYY MMMM') === dateString,
    )
    const uniqData = _.uniqBy(filterUserData, 'date')
    setDatePickerValue(uniqData ? uniqData : testResultData && testResultData?.[activeLink]?.results)
  }

  useEffect(() => {
    if (usersDomain && activeLink > -1 && usersDomain[activeLink]?.items) {
      const userData =
        testResultData &&
        testResultData?.[activeLink]?.results?.map((items) => {
          return {
            ...items,
            date: moment.utc(items?.createdAt).format('DD-MM-YYYY'),
          }
        })
      const filterUserData = userData?.filter(
        (userCreatedAt) =>
          moment.utc(userCreatedAt?.createdAt).format('YYYY MMMM') === currentMonth,
      )
      const uniqData = _.uniqBy(filterUserData, 'date')
      setDatePickerValue(uniqData)
      setLastUpdatedMonth(currentMonth)
      let todayDate = new Date()
      var nextDate1 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 1)
      setNextScanDate(formattedDate)
      if (filterUserData?.length === 0) {
        let previousMonth = moment(currentMonth, 'YYYY MMMM').subtract(1, 'month').format('YYYY MMMM')
        const newFilterUserData = userData?.filter(
          (userCreatedAt) =>
            moment.utc(userCreatedAt?.createdAt).format('YYYY MMMM') ===
            previousMonth,
        )
        const uniqData = _.uniqBy(newFilterUserData, 'date')
        setDatePickerValue(uniqData)
        setLastUpdatedMonth(previousMonth)

        if(uniqData && uniqData?.length){
          setCurrentMonth(previousMonth)
        }
        
        let today = new Date()
        var lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        setNextScanDate(formattedDate)
        if (newFilterUserData?.length === 0) {
          setLastUpdatedMonth(currentMonth)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDomain, activeLink])

  const currentUTCTime = moment.utc()
  const newUTCTime = currentUTCTime.clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

  if(currentUTCTime.unix() >= newUTCTime.unix()){
    const nextdate = newUTCTime.clone().add(1, 'day')
    formattedDate = nextdate.local().format('DD/MM/YYYY hh:mm A')
  } else{
    formattedDate = newUTCTime.local().format('DD/MM/YYYY hh:mm A')
  }
  
  return (
    <>
      <Col xs="auto" className="d-none d-sm-block">
        <h3 className='f-bold'>Archived reports</h3>
      </Col>
      <Card className='mb-0'>
        <Card.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0}>
            <Nav
              variant="tabs"
              onSelect={(selectedKey) => {
                dispatch(changeActiveLink(parseInt(selectedKey)))
                setIsTabChanged(true)
              }}
            >
              {usersDomain && usersDomain.length ? (
                <>
                  {usersDomain.map((item, index) => {
                    return <MainUrlNav key={index+1} item={item} index={index} />
                  })}
                </>
              ) : null }
            </Nav>

            <Space direction="vertical">
              <DatePicker
                picker="month"
                className="range-picker date-picker-mobile"
                onChange={onChange}
                defaultValue={moment()}
                value={moment(currentMonth, 'YYYY MMMM')}
                format={'YYYY MMMM'}
                allowClear={false}
              />
            </Space>

            <Tab.Content>
              {usersDomain && usersDomain.length ? (
                usersDomain.map((item, index) => {
                  return (
                    <Tab.Pane eventKey={index} key={`TabContent-${index + 1}`}>
                      <Row className={'mb-3 mt-3'}>
                        <Col>
                          <SeoScoreProgress
                            item={item}
                            SeoProgressPercentage={
                              seoProgressPercentage >= 100 ? 100 : parseInt(seoProgressPercentage)
                            }
                          />
                        </Col>
                        <Col>
                          <Card className="archived-report">
                            <Card.Header className='signalScan-header'>
                              <div className="card-actions float-end signalScan-nextDate">
                                {datePickerValue && datePickerValue?.length ? (
                                  <>
                                    Next Signal Scan:{' '}
                                    {nextScanDate}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <Card.Title tag="h5" className='f-bold'>Archived reports</Card.Title>
                              <h6 className="card-subtitle text-muted">
                                {lastUpdatedMonth}
                              </h6>
                            </Card.Header>

                            {datePickerValue && datePickerValue?.length ? (
                              <Table striped responsive>
                                <thead>
                                  <tr>
                                    <th style={{ width: '10%' }}>#</th>
                                    <th style={{ width: '20%' }}>Date</th>
                                    <th style={{ width: '15%' }}>Score</th>
                                    <th>Passed</th>
                                    <th>Failed</th>
                                    <th style={{ width: '15%' }}>Warnings</th>
                                    <th style={{ width: '15%' }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {datePickerValue &&
                                    datePickerValue?.map((items, index) => {
                                      const data = getDataForDashboard(items)
                                      return (
                                        <tr key={`datePicker-${index + 1}`}>
                                          <td>{index + 1}</td>
                                          <td>{moment.utc(items?.createdAt).format('YYYY, MMMM DD')}</td>
                                          <td>{data?.positiveCounter + data?.warningCounter}</td>
                                          <td>{data?.positiveCounter}</td>
                                          <td>{data?.negativeCounter}</td>
                                          <td>{data?.warningCounter}</td>
                                          <td className="table-action">
                                            <a
                                              onClick={() => {
                                                navigate(paths.urlReport, {
                                                  state: { url: items },
                                                })
                                              }}
                                            >
                                              <u>View Report</u>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </Table>
                            ) : !loadingTestResults && usersDomain && usersDomain?.length && !datePickerValue?.length ? <div className='p-1'><h3 className='text-center f-bold'>No reports found for this month</h3> </div>: (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 120,
                                  }}
                                >
                                  <div className="seo-score-chart-alert-loader">
                                    <div className="text-center">
                                      <Loader />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  )
                })
              ) : (
                <></>
              )}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  )
}
export default ReportsTable

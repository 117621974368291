import { CommonAlert } from 'components/uiCore'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const TitleTest = ({ data }) => {
  return (
    <>
      {data?.information?.seoCheckup?.title ? (
        <>
          {/* <h5>{data?.url}</h5> */}
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.information?.seoCheckup?.title?.titleStatus ? (
                ''
              ) : data?.information?.seoCheckup?.title?.titleStatus === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.information?.seoCheckup?.title?.titleStatus === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p> {data?.information?.seoCheckup?.title?.titleMessage} </p>
            <p>
              {data?.information?.seoCheckup?.title?.title ? data?.information?.seoCheckup?.title?.title : null}
            </p>

            <CommonAlert
              variant={
                !data?.information?.seoCheckup?.title?.titleStatus
                  ? 'danger'
                  : data?.information?.seoCheckup?.title?.titleStatus === 3
                  ? 'danger'
                  : data?.information?.seoCheckup?.title?.titleStatus === 2
                  ? 'warning'
                  : 'success'
              }
              key={'meta-title'}
              alertText={
                <div className='alert-for-mobile'>
                  <span style={{fontWeight:400, color:'#000000'}}>Text:</span>{' '}
                  {data?.information?.seoCheckup?.title?.title ? (
                    <span>{data?.information?.seoCheckup?.title?.title}</span>
                  ) : (
                    <>No Parameters Found</>
                  )}
                  <div className='alert-for-mobile'>
                  <span style={{fontWeight:400, color:'#000000'}}>Length:</span>{' '}
                    {data?.information?.seoCheckup?.title?.title ? (
                      <span>{data?.information?.seoCheckup?.title?.title.length}</span>
                    ) : (
                      <>No Parameters Found</>
                    )}
                  </div>
                </div>
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default TitleTest

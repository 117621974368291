import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Alert, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import * as Yup from 'yup'
import { Formik } from 'formik'

import ColoredModal from 'pages/modals/ColorModal.js'
import SocialButton from 'components/fbButton.js'
import path from 'constants/paths.js'
import { useDeviceCheck } from 'hooks/deviceHook'

import email from 'assets/img/icons/email_1.png'
import google from 'assets/img/icons/google.png'

function SignIn() {
  const { errorMessage, loginSuccess, loginError, registerData, registerSocialSuccess } = useSelector(
    ({ register }) => register,
  )

  const [loginSuccessFlag, setLoginSuccessFlag] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  useEffect(() => {
    if (loginSuccess) {
      dispatch({ type: 'LOGIN_CLEAR' })
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })

      if (
        registerData &&
        registerData?.subscriptions &&
        registerData?.subscriptions?.length &&
        (registerData?.subscriptions?.[0]?.isActive || registerData?.subscriptions?.[0]?.isTrial)
      ) {
        if (registerData && registerData?.websites && registerData?.websites?.length) {
          navigate(path.mainDashboard)
        } else {
          navigate(path.newUserWelcomePage)
        }
      } else {
        navigate(path.newUserSubscriptionPage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess, registerData])

  useEffect(() => {
    if (registerSocialSuccess && registerData && Object.keys(registerData)?.length) {
      dispatch({ type: 'LOGIN_CLEAR' })
      dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
      dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })

      if (
        registerData &&
        registerData?.subscriptions &&
        registerData?.subscriptions?.length &&
        (registerData?.subscriptions?.[0]?.isActive || registerData?.subscriptions?.[0]?.isTrial)
      ) {
        if (registerData && registerData?.websites && registerData?.websites?.length) {
          navigate(path.mainDashboard)
        } else {
          navigate(path.newUserWelcomePage)
        }
      } else {
        navigate(path.newUserSubscriptionPage)
      }
    }
  }, [registerSocialSuccess, registerData])

  useEffect(() => {
    if (loginError) {
      setLoginSuccessFlag(true)
    }
  }, [loginError])

  const login = (values) => {
    dispatch({ type: 'LOGIN_START', payload: values })
  }

  return (
    <>
      {loginSuccessFlag ? (
        <ColoredModal
          isOpen={loginSuccessFlag}
          message={errorMessage}
          onClose={() => {
            setLoginSuccessFlag(false)
            dispatch({ type: 'LOGIN_CLEAR' })
            dispatch({ type: 'CLEAR_STOP_DASHBOARD_PATCH_API_CALLS' })
            dispatch({ type: 'CLEAR_STOP_BACKLINKS_STATS_API_CALL' })
          }}
        />
      ) : (
        <></>
      )}
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            //await signIn(values.email, values.password);
            login(values)
            //navigate("/private");
          } catch (error) {
            const message = error.message || 'Something went wrong'

            setStatus({ success: false })
            setErrors({ submit: message })
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}

            <Form.Group className="mb-3 px-1 signin-form-input">
              <Form.Label className="signin-form-label">Your Email</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                name="email"
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
                className="auth-inputs"
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-2 px-1 signin-form-input">
              <Form.Label className="signin-form-label">Your Password</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                name="password"
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
                className="auth-inputs"
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              )}
              <small className="login-password">
                <p className="fs-5 mt-1 mb-0">
                  <Link to={path.resetPassword}>Did you forgot your password?</Link>
                </p>
              </small>

              <div className="rememberMe-Mobile">
                <small>
                  <Form.Check
                    className="d-flex flex-row gap-1 align-items-center"
                    type="checkbox"
                    id="rememberMe"
                    label="Remember me next time"
                    defaultChecked
                  />
                </small>
                <small>
                  <Link to={path.resetPassword}>Did you forgot your password?</Link>
                </small>
              </div>
            </Form.Group>

            <div className="px-1 rememberMe">
              <Form.Check
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
                className="fs-2"
              />
            </div>

            <div className="text-center login-button" style={{ margin: '2.5rem 0' }}>
              <span className="login-button-single">
                <Button
                  type="submit"
                  variant="primary"
                  color="primary"
                  size="md"
                  className="text-start mr-1 responsive-btn"
                  disabled={isSubmitting}
                  style={{
                    height: '33px',
                    background: 'white',
                    color: 'grey',
                    width: '210px',
                    border: '1px solid black',
                    fontWeight: '500',
                  }}
                >
                  <img
                    src={email}
                    width={20}
                    height={20}
                    style={{ marginRight: '8px', marginBottom: '1px' }}
                  />{' '}
                  Sign in via Email
                </Button>
              </span>
              <span className="login-button-single">
                <SocialButton
                  containerStyle={isMobile ? { width: '100%' } : {}}
                  provider="google"
                  appId="74542118637-c261k854oj1m08ov29tcvrsvkcbrnhpm.apps.googleusercontent.com"
                  onLoginSuccess={(data) => {
                    if (data._profile.email && data._token.accessToken) {
                      let apiData = {
                        token: data._token.accessToken,
                        social: true,
                        type: 'google',
                      }

                      dispatch({ type: 'LOGIN_START', payload: apiData })
                    }
                  }}
                  onLoginFailure={(data) => {
                    const error = data?.toString()
                    if (error?.includes('popup_closed_by_user')) {
                      return
                    } else {
                      message.error(data)
                    }
                  }}
                >
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    className="text-start mr-1 responsive-btn"
                    style={{
                      height: '33px',
                      background: 'white',
                      color: 'grey',
                      border: '1px solid black',
                      width: '210px',
                      fontWeight: '500',
                    }}
                  >
                    <img
                      src={google}
                      width={15}
                      height={15}
                      style={{ marginRight: '8px', marginBottom: '2px' }}
                    />{' '}
                    Continue with Google
                  </Button>
                </SocialButton>
              </span>
              <span className="login-button-single">
                <SocialButton
                  containerStyle={isMobile ? { width: '100%' } : {}}
                  provider="facebook"
                  appId="378272869423880"
                  onLoginSuccess={(data) => {
                    if (data._profile.email && data._token.accessToken) {
                      let apiData = {
                        token: data._token.accessToken,

                        social: true,
                        type: 'facebook',
                      }
                      dispatch({ type: 'LOGIN_START', payload: apiData })
                    }
                  }}
                >
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    className="text-start responsive-btn-fb"
                    style={{ height: '33px', width: '210px', fontWeight: '500' }}
                  >
                    <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '8px', marginBottom: '-1' }} />{' '}
                    Continue with Facebook
                  </Button>
                </SocialButton>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SignIn

import { put, takeLatest, takeEvery, all, delay } from 'redux-saga/effects'

// action type
import {
  GET_URL_DETAILS,
  GET_URL_DETAILS_SUCCESS,
  GET_URL_DETAILS_FAILURE,
  CHECK_URL,
  CHECK_URL_FAILURE,
  CHECK_URL_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  ADD_WEBSITE,
  WEBSITE_SUCCESS,
  PROCESS_PAYMENT_START,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAIL,
  ADD_WEBSITE_TO_USER,
  START_SUBSCRIPTION_SUCCESS,
  START_SUBSCRIPTION_FAILURE,
  START_SUBSCRIPTION,
  STORE_USER_DATA_START,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  VALIDATE_URL_DETAILS,
  VALIDATE_URL_DETAILS_SUCCESS,
  VALIDATE_URL_DETAILS_FAILURE,
  GET_SITEMAP,
  GET_SITEMAP_SUCCESS,
  GET_SITEMAP_FAILURE,
  GET_LIGHTHOUSE,
  GET_LIGHTHOUSE_SUCCESS,
  GET_LIGHTHOUSE_FAILURE,
  GET_LIGHTHOUSE_STATUS,
  GET_LIGHTHOUSE_STATUS_FAILURE,
  GET_LIGHTHOUSE_STATUS_SUCCESS,
  GET_BILLING_INFORMATION,
  GET_BILLING_INFORMATION_SUCCESS,
  GET_BILLING_INFORMATION_FAILURE,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION_FAILURE,
  UPGRADE_SUBSCRIPTION_SUCCESS,
  DISCARD_TRIAL_PERIOD,
  DISCARD_TRIAL_PERIOD_SUCCESS,
  DISCARD_TRIAL_PERIOD_FAILURE,
  STRIPE_CHECKOUT,
  STRIPE_CHECKOUT_SUCCESS,
  STRIPE_CHECKOUT_FAILURE,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  TRIAL_SEO_CHECKUP_SUCCESS,
  TRIAL_SEO_CHECKUP_FAILURE,
  TRIAL_SEO_CHECKUP,
  GET_TRIAL_SEO_REPORT,
  GET_TRIAL_SEO_REPORT_SUCCESS,
  GET_TRIAL_SEO_REPORT_FAILURE,
  GET_PUBLIC_KEY,
  GET_PUBLIC_KEY_SUCCESS,
  GET_PUBLIC_KEY_FAILURE,
} from '../../actionType'

// api
import {
  postLocalRequestWithoutToken,
  getRequest,
  paymentRequest,
  putRequestWithToken,
  postLocalRequestWithToken,
  getRequestWithoutToken,
} from '../../../constants/httpHelperService'

const freeTrialApi = async (input) => {
  // return axios
  //   .post(`${BASE_URL}subscriptions`, input)
  //   .then(response => response.data)
  //   .catch(error => {
  //     throw error;
  //   });
  return await paymentRequest('subscriptions', input)
    .then((res) => res)
    .catch((error) => {
      throw error
    })
}

function* freeTrialSubscriptionData(action) {
  try {
    const localData = yield freeTrialApi(action.payload)
    const token = localStorage.getItem("token")
    let data = {
      ...localData?.user,
      plan: localData?.user?.planData,
      token
    }
    yield put({ type: STORE_USER_DATA_START, payload: data })
    yield put({ type: START_SUBSCRIPTION_SUCCESS, payload: localData })
  } catch (e) {
    yield put({ type: START_SUBSCRIPTION_FAILURE, e })
  }
}

function* freeTrialSubscriptionSaga() {
  yield takeLatest(START_SUBSCRIPTION, freeTrialSubscriptionData)
}

const cancelSubscriptionApi = async () => {
  return await putRequestWithToken('subscriptions/cancel-subscription')
}

function* cancelSubscriptionData(action) {
  try {
    const data = yield cancelSubscriptionApi()
    yield put({ type: CANCEL_SUBSCRIPTION_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CANCEL_SUBSCRIPTION_FAILURE, error: error?.response?.data?.message })
  }
}

function* cancelSubscriptionSaga() {
  yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscriptionData)
}

const upgradeSubscriptionApi = async (input) => {
  return await putRequestWithToken('subscriptions/upgrade-subscription', input)
}

function* upgradeSubscriptionData(action) {
  try {
    const data = yield upgradeSubscriptionApi(action?.payload)
    yield delay(5000)
    yield put({ type: UPGRADE_SUBSCRIPTION_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: UPGRADE_SUBSCRIPTION_FAILURE, error: error?.response?.data?.message })
  }
}

function* upgradeSubscriptionSaga() {
  yield takeLatest(UPGRADE_SUBSCRIPTION, upgradeSubscriptionData)
}

const storeData = async (input) => {
  return await postLocalRequestWithToken('session/store', input)
    .then((res) => res)
    .catch((error) => {
      throw error
    })
}

const validateURL = async (input) => {
  return await postLocalRequestWithToken('search/validate/url', input)
}

function* freeTrialData(action) {
  try {
    const localData = yield storeData(action.payload)
    yield put({ type: GET_URL_DETAILS_SUCCESS, payload: localData })
  } catch (e) {
    yield put({ type: GET_URL_DETAILS_FAILURE, e })
  }
}

function* freeTrialSaga() {
  yield takeLatest(GET_URL_DETAILS, freeTrialData)
}

function* validateFreeTrialData(action) {
  try {
    const localData = yield validateURL(action.payload)
    yield put({ type: VALIDATE_URL_DETAILS_SUCCESS, payload: localData })
  } catch (e) {
    yield put({ type: VALIDATE_URL_DETAILS_FAILURE, e })
  }
}

function* validateFreeTrialSaga() {
  yield takeLatest(VALIDATE_URL_DETAILS, validateFreeTrialData)
}

const checkUrlApi = async (input) => {
  return await postLocalRequestWithToken('verify-url', input)
    .then((res) => res)
    .catch((error) => error)
}

function* checkUrlData(action) {
  try {
    const data = yield checkUrlApi(action.payload)

    if (data.status) {
      yield put({ type: CHECK_URL_SUCCESS, payload: data })
    } else {
      yield put({ type: CHECK_URL_FAILURE, e: data.response.data })
    }
  } catch (e) {
    yield put({ type: CHECK_URL_FAILURE, e })
  }
}

function* checkUrlApiSaga() {
  yield takeEvery(CHECK_URL, checkUrlData)
}

const addUserApi = async (input) => {
  return await postLocalRequestWithToken('auth/google-analytics', input)
    .then((res) => res)
    .catch((error) => error)
}

const addAuthenticatedWebsite = async (input) => {
  return await postLocalRequestWithToken('auth/add-website', input)
    .then((res) => res)
    .catch((error) => error)
}

function* addUserData(action) {
  try {
    let data
    if (action.payload.selectedWebsite) {
      data = yield addAuthenticatedWebsite(action.payload)
    } else {
      data = yield addUserApi(action.payload)
    }
    if (data.websiteData) {
      yield put({ type: WEBSITE_SUCCESS, payload: data })
    } else if (data.statusCode !== 500) {
      yield put({ type: ADD_USER_SUCCESS, payload: data })
    } else {
      yield put({ type: ADD_USER_FAILURE, payload: data })
    }
  } catch (e) {
    yield put({ type: ADD_USER_FAILURE, payload: e })
  }
}

function* addUserSaga() {
  yield takeLatest(ADD_USER, addUserData)
}

function* addWebsiteData(action) {
  try {
    let data

    data = yield addAuthenticatedWebsite(action.payload)

    if (data && data.success) {
      yield put({ type: ADD_USER_SUCCESS, payload: data })
    } else {
      yield put({ type: ADD_USER_FAILURE, payload: data?.response?.data || [] })
    }
  } catch (e) {
    yield put({ type: ADD_USER_FAILURE, payload: e })
  }
}

function* addWebsiteSaga() {
  yield takeLatest(ADD_WEBSITE, addWebsiteData)
}

const processPaymentApi = async (input) => {
  return await paymentRequest('paypal/create/subscription', input)
    .then((res) => res)
    .catch((error) => error)
}

function* processPaymentData(action) {
  try {
    const data = yield processPaymentApi(action.payload)
    yield put({ type: PROCESS_PAYMENT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: PROCESS_PAYMENT_FAIL, e })
  }
}

function* processPaymentSaga() {
  yield takeEvery(PROCESS_PAYMENT_START, processPaymentData)
}

function* addWebsiteToUserData() {
  try {
    //yield put({type: PROCESS_PAYMENT_SUCCESS, payload: data});
  } catch (e) {
    //yield put({type: PROCESS_PAYMENT_FAIL, e});
  }
}

function* addWebsiteToUserSaga() {
  yield takeEvery(ADD_WEBSITE_TO_USER, addWebsiteToUserData)
}

const getCountriesApi = async () => {
  return await getRequest('countries')
    .then((res) => {
      return res
    })
    .catch((error) => error)
}

function* getCountriesData(action) {
  try {
    const data = yield getCountriesApi(action.payload)
    yield put({ type: GET_COUNTRIES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_COUNTRIES_FAILURE, e })
  }
}

function* getCountriesSaga() {
  yield takeEvery(GET_COUNTRIES, getCountriesData)
}

const getSitemapApi = async (input) => {
  return await getRequest(`get-sitemap-urls?url=${input}`)
    .then((res) => {
      return res
    })
    .catch((error) => error)
}

function* getSitemapData(action) {
  try {
    const data = yield getSitemapApi(action.payload)
    yield put({ type: GET_SITEMAP_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_SITEMAP_FAILURE, e })
  }
}

function* getSitemapSaga() {
  yield takeLatest(GET_SITEMAP, getSitemapData)
}

const getLighthouseApi = async (input) => {
  return await getRequest(`get-lighthouse?url=${input}`)
    .then((res) => {
      return res
    })
    .catch((error) => error)
}

function* geLighthouseData(action) {
  try {
    const data = yield getLighthouseApi(action.payload)
    yield put({ type: GET_LIGHTHOUSE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_LIGHTHOUSE_FAILURE, e })
  }
}

function* getLighthouseSaga() {
  yield takeLatest(GET_LIGHTHOUSE, geLighthouseData)
}


const getLighthouseStatusApi = async(input) => {
 return await getRequest(`get-lighthouse-status?url=${input}`)
 .then((res) => {
  return res
 })
 .catch(error => error)
}
function* getLighthouseStatusData(action){
  try {
    const data = yield getLighthouseStatusApi(action?.payload?.url)
    yield put({type: GET_LIGHTHOUSE_STATUS_SUCCESS, payload: data})
  } catch (error) {
    yield put({type: GET_LIGHTHOUSE_STATUS_FAILURE, e: error})
  }
}

function* getLighthouseStatusSaga(){
  yield takeEvery(GET_LIGHTHOUSE_STATUS, getLighthouseStatusData)
}
const getLocationsApi = async () => {
  return await getRequest('locations')
    .then((res) => {
      return res
    })
    .catch((error) => error)
}

function* getLocationsData() {
  try {
    const data = yield getLocationsApi()
    yield put({ type: GET_LOCATIONS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_LOCATIONS_FAILURE, e })
  }
}

function* getLocationsSaga() {
  yield takeEvery(GET_LOCATIONS, getLocationsData)
}

const getBillingInformationApi = async() => {
  return await getRequest(`subscriptions/billing-information`)
}
function* getBillingInformationData(action){
  
  try {
    const data = yield getBillingInformationApi()
    yield put({type:GET_BILLING_INFORMATION_SUCCESS, payload: data})
  } catch (error) {
    yield put({type:GET_BILLING_INFORMATION_FAILURE, error: error?.response?.data})
  }
}
function* getBillingInformationSaga(){
  yield takeLatest(GET_BILLING_INFORMATION, getBillingInformationData)
}


///////////////////////////////////////////////////////////

const handleDiscardTrialPeriodApi = async () => {
  return await getRequest(`subscriptions/trial-end`)
    .then((res) => res)
    .catch((error) => error)
}

function* handleDiscardTrialPeriod() {
  try {
    const data = yield handleDiscardTrialPeriodApi()
    yield put({ type: DISCARD_TRIAL_PERIOD_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: DISCARD_TRIAL_PERIOD_FAILURE, error: error?.response?.data })
  }
}

function* handleDiscardTrialSaga() {
  yield takeLatest(DISCARD_TRIAL_PERIOD, handleDiscardTrialPeriod)
}

///////////////////////////////////////////////////////////

const stripeCheckoutApi = async (input) => {
  return await postLocalRequestWithToken(`subscriptions/create-checkout-session`, input)
}

function* handleStripeCheckout(action) {
  try {
    const data = yield stripeCheckoutApi(action?.payload)
    yield put({ type: STRIPE_CHECKOUT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: STRIPE_CHECKOUT_FAILURE, error: error?.response?.data })
  }
}

function* handleStripeCheckoutSaga() {
  yield takeLatest(STRIPE_CHECKOUT, handleStripeCheckout)
}
///////////////////////////////////////////////////////////

const handleAddPaymentMethodAPi = async (input) => {
  return await getRequest(`subscriptions/customer-portal?customerId=${input?.customerId}`)
}

function* handleAddPaymentMethod(action) {
  try {
    const data = yield handleAddPaymentMethodAPi(action?.payload)
    yield put({ type: ADD_PAYMENT_METHOD_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: ADD_PAYMENT_METHOD_FAILURE, error: error?.response?.data })
  }
}

function* handleAddPaymentMethodSaga() {
  yield takeLatest(ADD_PAYMENT_METHOD, handleAddPaymentMethod)
}

///////////////////////////////////////////////////////////

const trialSeoCheckupApi = async (input) => {
  return await postLocalRequestWithoutToken(`trial-seo-checkup`, input)
}

function* trialSeoCheckup(action) {
  try {
    const data = yield trialSeoCheckupApi(action?.payload)
    yield put({ type: TRIAL_SEO_CHECKUP_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: TRIAL_SEO_CHECKUP_FAILURE, error: error?.response?.data })
  }
}

function* trialSeoCheckupSaga() {
  yield takeLatest(TRIAL_SEO_CHECKUP, trialSeoCheckup)
}

const getTrialSeoReportApi = async (input) => {
  return await getRequestWithoutToken(`trial-tests?url=${input?.url}&type=${input?.type}`)
}

function* getTrialSeoReport(action) {
  try {
    const data = yield getTrialSeoReportApi(action?.payload)
    yield put({ type: GET_TRIAL_SEO_REPORT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_TRIAL_SEO_REPORT_FAILURE, error: error?.response?.data })
  }
}

function* getTrialSeoReportSaga() {
  yield takeLatest(GET_TRIAL_SEO_REPORT, getTrialSeoReport)
}

const getPublicKeyApi = async () => {
  return await getRequestWithoutToken(`get-public-key`)
}

function* getPublicKeyData() {
  try {
    const data = yield getPublicKeyApi()
    yield put({ type: GET_PUBLIC_KEY_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_PUBLIC_KEY_FAILURE, error: error?.response?.data })
  }
}

function* getPublicKeySaga() {
  yield takeLatest(GET_PUBLIC_KEY, getPublicKeyData)
}

export default function* startFreeTrialSaga() {
  yield all([
    freeTrialSaga(),
    checkUrlApiSaga(),
    addUserSaga(),
    processPaymentSaga(),
    addWebsiteSaga(),
    freeTrialSubscriptionSaga(),
    addWebsiteToUserSaga(),
    getCountriesSaga(),
    getLocationsSaga(),
    validateFreeTrialSaga(),
    getSitemapSaga(),
    getLighthouseSaga(),
    getLighthouseStatusSaga(),
    getBillingInformationSaga(),
    cancelSubscriptionSaga(),
    upgradeSubscriptionSaga(),
    handleDiscardTrialSaga(),
    handleStripeCheckoutSaga(),
    handleAddPaymentMethodSaga(),
    trialSeoCheckupSaga(),
    getTrialSeoReportSaga(),
    getPublicKeySaga()
  ])
}

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE'
export const SIDEBAR_VISIBILITY_SHOW = 'SIDEBAR_VISIBILITY_SHOW'
export const SIDEBAR_VISIBILITY_HIDE = 'SIDEBAR_VISIBILITY_HIDE'
export const SIDEBAR_STICKY_TOGGLE = 'SIDEBAR_STICKY_TOGGLE'
export const SIDEBAR_STICKY_ENABLE = 'SIDEBAR_STICKY_ENABLE'
export const SIDEBAR_STICKY_DISABLE = 'SIDEBAR_STICKY_DISABLE'

// Layout
export const LAYOUT_BOXED_TOGGLE = 'LAYOUT_BOXED_TOGGLE'
export const LAYOUT_BOXED_ENABLE = 'LAYOUT_BOXED_ENABLE'
export const LAYOUT_BOXED_DISABLE = 'LAYOUT_BOXED_DISABLE'

// Theme
export const THEME_TOGGLE = 'THEME_TOGGLE'

import { useGoogleLogin } from '@react-oauth/google'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthToken } from 'redux/actions/dashboard'

const GoogleButton = ({ url }) => {

  const dispatch = useDispatch()
    
  const { getAuthUrlLoader } = useSelector(({dashboard}) => dashboard) 

  const onLoginSuccess = (token) => {
    const payload = {
        url,
        authCode: token?.code
    }

    dispatch(getAuthToken(payload))
  }
  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'profile email openid https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly',
    onSuccess: onLoginSuccess,
  })
  
  return (
    <Button
      color="primary"
      type="button"
      className="d-flex align-items-center"
      onClick={login}
      disabled={getAuthUrlLoader}
    >
      Connect with google
      {getAuthUrlLoader ? (
        <Spinner animation="border" style={{ marginLeft: '10px' }} key={1} size="sm" />
      ) : null}
    </Button>
  )
}

export default GoogleButton

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const FlashTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.flashTest.heading}
      whatIsIT={freeTools.flashTest.what}
    />
  )
}

export default FlashTest

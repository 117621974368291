import { combineReducers } from 'redux'

import freeTrialReducer from './freeTrial/startFreeTrial'
import registerReducer from './register'
import freeToolsReducer from './freeTools/freeTools'
import dashboardReducer from './dashboard'
import keywordsTrackingReducer from './keywordsTracking'
import sidebar from './sidebarReducers'

import backlinksDataReducer from './backlinks'
import TopKeywordReducer from './topKeywords'
import WebsiteCacheReducer from './websiteCache'
import brokenLinksListReducer from './brokenLinks'
import { templateReducer } from './reporting/template'
import { reportReducer } from './reporting/generateReport'

export default combineReducers({
  sidebar,
  startFreeTrial: freeTrialReducer,
  register: registerReducer,
  freeTools: freeToolsReducer,
  dashboard: dashboardReducer,
  keywordsTracking: keywordsTrackingReducer,
  backlinksData: backlinksDataReducer,
  topKeywords: TopKeywordReducer,
  websiteCache: WebsiteCacheReducer,
  brokenLinksList: brokenLinksListReducer,
  template: templateReducer,
  report: reportReducer,
})

import { Card, Col, Row } from 'react-bootstrap'
import * as _ from 'lodash'
import { Area } from '@ant-design/charts'

import 'pages/dashboards/speedGraph.scss'

const Page = ({ activeSearchData, graphData }) => {
  const activeUrlGraphdata = graphData?.filter((item) => activeSearchData?.websiteId === item?.websiteId)

  const formatData = (data, type) => {
    return data?.map((dataPoint) => ({
      x: dataPoint?.date,
      y: (dataPoint?.speedIndex * 1000)?.toFixed(2),
      loadingSpeed: dataPoint?.loadingSpeed?.toFixed(2),
      pageSize: dataPoint?.pageSize?.toFixed(2),
      loadingTime: dataPoint?.speedIndex?.toFixed(2),
      minTime: parseInt(dataPoint?.minSpeed),
      maxTime: parseInt(dataPoint?.maxSpeed),
      minHour: dataPoint?.minSpeedHour,
      maxHour: dataPoint?.maxSpeedHour,
      type: type,
    }))
  }

  const formatForLast24hr = (data, type) => {
    return data?.map((dataPoint) => ({
      x: dataPoint?.hour,
      loadingSpeed: dataPoint?.data?.loadingSpeed?.toFixed(2),
      pageSize: dataPoint?.data?.pageSize?.toFixed(2),
      loadingTime: dataPoint?.data?.speedIndex?.toFixed(2),
      minTime: parseInt(dataPoint?.data?.minSpeed),
      maxTime: parseInt(dataPoint?.data?.maxSpeed),
      type: type,
    }))
  }

  let newDesktopData, newMobileData

  if (
    activeUrlGraphdata?.[0]?.desktopData?.[0]?.hour >= 0 &&
    activeUrlGraphdata?.[0]?.mobileData?.[0]?.hour >= 0
  ) {
    const sortedDesktopData = _.sortBy(activeUrlGraphdata?.[0]?.desktopData, ['date', 'hour'])
    const sortedMobileData = _.sortBy(activeUrlGraphdata?.[0]?.mobileData, ['date', 'hour'])

    newDesktopData = formatForLast24hr(sortedDesktopData, 'Desktop')
    newMobileData = formatForLast24hr(sortedMobileData, 'Mobile')
  } else {
    newDesktopData = formatData(activeUrlGraphdata?.[0]?.desktopData, 'Desktop')
    newMobileData = formatData(activeUrlGraphdata?.[0]?.mobileData, 'Mobile')
  }

  let dData = _.uniqBy(newDesktopData, 'x')
  let mData = _.uniqBy(newMobileData, 'x')

  let desktopData = dData
  if (typeof dData?.[0]?.x === 'string') {
    desktopData = dData?.sort((a, b) => {
      const [monthA, dayA, yearA] = a?.x?.split('-').map(Number)
      const [monthB, dayB, yearB] = b?.x?.split('-').map(Number)

      if (yearA !== yearB) {
        return yearA - yearB
      }
      if (monthA !== monthB) {
        return monthA - monthB
      }
      return dayA - dayB
    })
  }

  desktopData = desktopData?.map((item) => {
    if (typeof item?.x === 'string') {
      return {
        ...item,
        x: item?.x?.substring(0, 5),
      }
    } else {
      return {
        ...item,
      }
    }
  })

  let mobileData = mData
  if (typeof mData?.[0]?.x === 'string') {
    mobileData = mData?.sort((a, b) => {
      const [monthA, dayA, yearA] = a?.x?.split('-').map(Number)
      const [monthB, dayB, yearB] = b?.x?.split('-').map(Number)

      if (yearA !== yearB) {
        return yearA - yearB
      }
      if (monthA !== monthB) {
        return monthA - monthB
      }
      return dayA - dayB
    })
  }

  mobileData = mobileData?.map((item) => {
    if (typeof item?.x === 'string') {
      return {
        ...item,
        x: item?.x?.substring(0, 5),
      }
    } else {
      return {
        ...item,
      }
    }
  })

  const prepareChartData = (data) => {
    return data.reduce((acc, item) => {
      const { x, loadingTime, pageSize, loadingSpeed, type, minTime, maxTime, minHour, maxHour } = item
      acc.push({
        x: formatHourLabel(x),
        value: +loadingTime,
        category: `${type} Loading Time`,
        minTime,
        maxTime,
        minHour,
        maxHour,
      })
      acc.push({ x: formatHourLabel(x), value: +pageSize, category: `${type} Page Size` })
      acc.push({
        x: formatHourLabel(x),
        value: +loadingSpeed,
        category: `${type} Loading Speed`,
      })
      return acc
    }, [])
  }

  const formatHourLabel = (value) => {
    if (typeof value === 'string') {
      return value
    } else {
      if (value < 12) {
        return `${value}`
      } else {
        return `${value}`
      }
    }
  }

  const desktopChartData = prepareChartData(desktopData)
  const mobileChartData = prepareChartData(mobileData)

  const RenderTooltipRow = (label, value, color, data) => {
    let updatedValue = value
    if (label?.includes('Loading Time')) {
      updatedValue = `${value}ms`

      if (label?.includes('Loading Time')) {
        return (
          <>
            <div className="tooltip-row">
              <div className="tooltip-circle" style={{ backgroundColor: color }}></div>
              <div className="tooltip-text">
                <span className="tooltip-text-label">{label}:</span>
              </div>
            </div>
            <div className="tooltip-text-value">
              <ul className="d-flex flex-column gap-2" style={{ listStyle: 'none' }}>
                <li>avg: {updatedValue}</li>
                <li>
                  min: {data?.data?.minTime}ms {data?.data?.minHour ? `@ ${data?.data?.minHour}` : ''}
                </li>
                <li>
                  max: {data?.data?.maxTime}ms {data?.data?.maxHour ? `@ ${data?.data?.maxHour}` : ''}
                </li>
              </ul>
            </div>
          </>
        )
      }
    } else if (label?.includes('Page Size')) {
      updatedValue = `${value}KB`

      return (
        <div className="tooltip-row">
          <div className="tooltip-circle" style={{ backgroundColor: color }}></div>
          <div className="tooltip-text">
            <span className="tooltip-text-label">{label}:</span>
            <span className="tooltip-text-value">{updatedValue}</span>
          </div>
        </div>
      )
    } else if (label?.includes('Loading Speed')) {
      updatedValue = `${value}KB/s`

      return (
        <div className="tooltip-row">
          <div className="tooltip-circle" style={{ backgroundColor: color }}></div>
          <div className="tooltip-text">
            <span className="tooltip-text-label">{label}:</span>
            <span className="tooltip-text-value">{updatedValue}</span>
          </div>
        </div>
      )
    }
  }

  const commonConfig = {
    xField: 'x',
    yField: 'value',
    seriesField: 'category',
    smooth: true,
    animation: false,
    tooltip: {
      customContent: (title, items) => {
        return (
          <div className="tooltip-container">
            <h2 className="tooltip-title">{title}</h2>
            {items.map((data) => (
              <div key={data._id} className="tooltip-row-container">
                {RenderTooltipRow(data?.data?.category, data?.value, data?.color, data)}
              </div>
            ))}
          </div>
        )
      },
      text: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    legend: { marker: { symbol: 'circle' } },
    point: {
      size: 5,
      shape: 'circle',
    },
    yAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
  }

  const desktopAreaConfig = {
    ...commonConfig,
    data: desktopChartData,
  }

  const mobileAreaConfig = {
    ...commonConfig,
    data: mobileChartData,
  }

  // eslint-disable-next-line no-unused-vars
  let chart

  return (
    <Row>
      <Col lg="6">
        <Card className="flex-fill url-details-inner">
          <Card.Header>
            <Card.Title tag="h1" className="mb-0 f-bold">
              Desktop Loading Speed
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Area {...desktopAreaConfig} onReady={(chartInstance) => (chart = chartInstance)} />
          </Card.Body>
        </Card>
      </Col>
      <Col lg="6">
        <Card className="flex-fill  url-details-inner">
          <Card.Header>
            <Card.Title tag="h1" className="mb-0 f-bold">
              Mobile Loading Speed
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Area {...mobileAreaConfig} onReady={(chartInstance) => (chart = chartInstance)} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
export default Page

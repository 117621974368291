import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import paths from 'constants/paths'
import 'modules/trialSEOCheckup/components/SubscriptionRequiredTest.scss'

const SubscriptionRequired = ({
  url,
  heading,
  message,
  registerMessage,
  isRegisterMessageAvailable = false,
}) => {
  const navigate = useNavigate()
  return (
    <>
      {url ? (
        <div>
          <h5>{url}</h5>
        </div>
      ) : null}

      <div className="required-card">
        <h2 className="required-card-heading">{heading}</h2>
        <p className="required-card-message">{message}</p>
        {isRegisterMessageAvailable ? <p className="required-card-message">{registerMessage}</p> : null}

        <div>
          <Button onClick={() => navigate(paths.signInPage)}>Login now</Button>
        </div>
      </div>
    </>
  )
}

export default SubscriptionRequired

import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const TrafficAnalyticsEventsHeader = ({ analytics }) => {
  const [websiteData, setWebsiteData] = useState([])
  const [lastDayUsersCount, setLastDayUsersCount] = useState()

  useEffect(() => {
    const dataToDisplay = analytics?.summary?.length ? analytics?.summary : []
    setWebsiteData(dataToDisplay)

    const lastDayaTotalUsers = analytics?.lastDayUsersByCountry?.length && analytics?.lastDayUsersByCountry?.reduce((acc, obj) => acc + parseInt(obj?.users), 0) 
    setLastDayUsersCount(lastDayaTotalUsers)
  }, [analytics])

  return (
    <Row style={{ marginTop: 22 }}>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="card-subtitle text-muted"> Event count </h4>

                {websiteData?.[0]?.eventCount ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                        {websiteData?.[0]?.eventCount}
                      </h3>
                    </div>
                  </div>
                ) : (
                  <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                )}
              </div>
              <div className="d-inline-block ms-3" />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="card-subtitle text-muted"> Key events </h4>
                {websiteData?.[0]?.keyEvents ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                        {websiteData?.[0]?.keyEvents}
                      </h3>
                    </div>
                  </div>
                ) : (
                  <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                )}
              </div>
              <div className="d-inline-block ms-3" />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="card-subtitle text-muted"> Users </h4>
                {websiteData?.[0]?.users ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                        {websiteData?.[0]?.users}
                      </h3>
                    </div>
                  </div>
                ) : (
                  <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                )}
              </div>
              <div className="d-inline-block ms-3" />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="card-subtitle text-muted"> New users </h4>
                {websiteData?.[0]?.newUsers ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                        {websiteData?.[0]?.newUsers}
                      </h3>
                    </div>
                  </div>
                ) : (
                  <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                )}
              </div>
              <div className="d-inline-block ms-3" />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="card-subtitle text-muted">Users in last 48 hours</h4>
                {lastDayUsersCount ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                        {lastDayUsersCount}
                      </h3>
                    </div>
                  </div>
                ) : (
                  <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                )}
              </div>
              <div className="d-inline-block ms-3" />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default TrafficAnalyticsEventsHeader

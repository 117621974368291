import { message } from 'antd'
import paths from 'constants/paths'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CLEAR_QUICK_SCAN_LIMIT } from 'redux/actionType'

const QuickScanModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { quickScanLimitLoader, quickScanLimitData, quickScanLimitError } = useSelector(({ dashboard }) => dashboard)

    const [openQuickScanLimitExceed, setOpenQuickScanLimitExceed] = useState(false)

    useEffect(() => {
        if (quickScanLimitLoader) {
            return
        } else if (quickScanLimitData && Object.keys(quickScanLimitData)?.length && !quickScanLimitData?.limitAvailable) {
            setOpenQuickScanLimitExceed(true)
        } else {
            if (!quickScanLimitLoader && quickScanLimitError && Object.keys(quickScanLimitError)?.length) {
                message.error(quickScanLimitError?.message)
                dispatch({ type: CLEAR_QUICK_SCAN_LIMIT })
            }
        }
    }, [quickScanLimitLoader, quickScanLimitData, quickScanLimitError])

    return (
        <Modal
            show={openQuickScanLimitExceed}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => {
                setOpenQuickScanLimitExceed(!openQuickScanLimitExceed)
                dispatch({ type: CLEAR_QUICK_SCAN_LIMIT })
            }}
            onClose={() => {
                setOpenWebsiteLimitExceed(false)
                dispatch({ type: CLEAR_QUICK_SCAN_LIMIT })
            }}
            onHide={() => {
                setOpenWebsiteLimitExceed(false)
                dispatch({ type: CLEAR_QUICK_SCAN_LIMIT })
            }}
            style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
            className="br-15 pe-0"
        >
            <Modal.Header>
                <h3 className='mb-0 f-bold'>Quick Scan Tracking Limit Reached</h3>
            </Modal.Header>
            <Modal.Body>
                You've reached your limit of using quick scan for websites for this period. Our real-time internet scanning service incurs significant costs to ensure accurate and up-to-date data for your quick scanned websites. This is why we've set a limit on the number of websites you can scan each month, to maintain the quality of our service for all customers.
                <br /> <br />
                Current Plan: {quickScanLimitData?.quickScanLimit} quick scans per month
                <br /> <br />
                You will be able to scan websites from the next effective month.
                <br /><br />
                Thank you for choosing us for your SEO needs. We're here to help you make the most of your digital presence!
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-between'>
                <Button
                    color="primary"
                    variant="primary"
                    onClick={() => {
                        navigate(paths.upgradePlan)
                        setOpenQuickScanLimitExceed(false)
                        dispatch({ type: CLEAR_QUICK_SCAN_LIMIT })
                    }}
                >
                    Upgrade your plan
                </Button>

                <Button
                    color="danger"
                    variant="danger"
                    onClick={() => {
                        setOpenQuickScanLimitExceed(false)
                        dispatch({ type: CLEAR_QUICK_SCAN_LIMIT })
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QuickScanModal

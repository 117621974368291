import {
  FREE_WHOIS_SUCCESS,
  FREE_WHOIS_START,
  FREE_WHOIS_FAIL,
  FREE_DOMAIN_SCORE_SUCCESS,
  FREE_DOMAIN_SCORE_START,
  FREE_DOMAIN_SCORE_FAIL,
  FREE_PAGE_OBJECT,
  FREE_PAGE_OBJECT_SUCCESS,
  FREE_PAGE_OBJECT_FAILURE,
  FREE_SPF_RECORD,
  FREE_SPF_RECORD_SUCCESS,
  FREE_NO_FOLLOW,
  FREE_NO_FOLLOW_SUCCESS,
  FREE_NO_FOLLOW_FAILURE,
  FREE_TEST,
  FREE_TEST_SUCCESS,
  FREE_TEST_FAILURE,
  QUICK_SEO_CHECKUP,
  QUICK_SEO_CHECKUP_SUCCESS,
  QUICK_SEO_CHECKUP_FAILURE,
  GET_QUICK_CHECKUP_REPORT,
  GET_QUICK_CHECKUP_REPORT_SUCCESS,
  GET_QUICK_CHECKUP_REPORT_FAILURE,
  GET_URL_REPORT,
  GET_URL_REPORT_SUCCESS,
  GET_URL_REPORT_FAILURE,
  CLEAR_QUICK_SEO_CHECKUP,
  CLEAR_GET_QUICK_CHECKUP_REPORT,
} from '../../actionType'

const InitialState = {
  success: false,
  error: false,
  loading: false,
  backLinksData: {},
  quickSeoFailed: false,
  quickSeoCheckupStart: false,
  getQuickSeoReportError: false
}

const freeToolsReducer = (state = InitialState, action) => {
  switch (action.type) {

    /////////

    case FREE_WHOIS_START:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        whoisData: {},
      }

    case FREE_WHOIS_SUCCESS:
      if (action.payload && action.payload.whoisData) {
        return {
          ...state,
          success: true,
          error: false,
          loading: false,
          whoisData: action.payload.whoisData,
        }
      } else {
        return {
          ...state,
          success: true,
          error: false,
          loading: false,
          whoisData: {},
        }
      }
    case FREE_WHOIS_FAIL:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        whoisData: {},
      }
    case FREE_DOMAIN_SCORE_START:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        domainScoreData: {},
      }

    case FREE_DOMAIN_SCORE_SUCCESS:
      if (action.payload && action.payload.statusCode === 200) {
        return {
          ...state,
          success: true,
          error: false,
          loading: false,
          domainScoreData: action.payload.finalScore,
        }
      } else {
        return {
          ...state,
          success: true,
          error: false,
          loading: false,
          domainScoreData: {},
        }
      }
    case FREE_DOMAIN_SCORE_FAIL:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        domainScoreData: {},
      }

      case FREE_PAGE_OBJECT:
        return {
          ...state,
          success: false,
          error: false,
          loading: true,
          pageObject: {},
        }
  
      case FREE_PAGE_OBJECT_SUCCESS:
        if (action?.payload?.statusCode === 200) {
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            pageObject: action?.payload?.resData || {},
          }
        } else {
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            pageObject: {},
          }
        }
  
      case FREE_PAGE_OBJECT_FAILURE:
        return {
          ...state,
          success: false,
          error: true,
          loading: false,
          pageObject: {},
        }
  
      case FREE_SPF_RECORD:
        return {
          ...state,
          success: false,
          error: false,
          loading: true,
          spfRecord: {},
        }
  
      case FREE_SPF_RECORD_SUCCESS:
        if (action?.payload?.statusCode === 200) {
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            spfRecord: action?.payload?.resData || {},
          }
        } else {
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            spfRecord: {},
          }
        }
  
      case FREE_NO_FOLLOW_FAILURE:
        return {
          ...state,
          success: false,
          error: true,
          loading: false,
          noFollow: {},
        }
  
      case FREE_NO_FOLLOW:
        return {
          ...state,
          success: false,
          error: false,
          loading: true,
          noFollow: {},
        }
  
      case FREE_NO_FOLLOW_SUCCESS:
        if (action?.payload?.statusCode === 200) {
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            noFollow: action?.payload?.resData?.noFollowUrl || {},
          }
        } else {
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            noFollow: {},
          }
        }

            /////////

    case FREE_TEST:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        data: {},
      }

    case FREE_TEST_SUCCESS:
      if (action?.payload?.statusCode === 200) {
        return {
          ...state,
          success: true,
          error: false,
          loading: false,
          data: action?.payload?.resData || {},
        }
      } else {
        return {
          ...state,
          success: true,
          error: false,
          loading: false,
          data: {},
        }
      }

    case FREE_TEST_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        data: {},
      }

    case QUICK_SEO_CHECKUP: {
      return {
        ...state,
        url: action?.payload?.url,
        success: false,
        error: false,
        loading: true,
        data: {},
        quickSeoCheckupStart: true,
      }
    }
    case QUICK_SEO_CHECKUP_SUCCESS: {
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        quickSeoSuccess: action?.payload?.status === 200,
        quickSeoFailed: action?.payload?.status !== 200,
        quickSeoCheckupStart: false,
        // data: {}
      }
    }
    case QUICK_SEO_CHECKUP_FAILURE: {
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        data: {},
        quickSeoFailed: action?.payload?.status !== 200,
        quickSeoCheckupStart: false,
      }
    }

    case CLEAR_QUICK_SEO_CHECKUP:{
      return {
        ...state,
        url: "",
        success: false,
        error: false,
        quickSeoSuccess: false,
        quickSeoFailed: false,
        quickSeoCheckupStart: false,
        data: {},
        loading: false,
      }
    }

    case GET_QUICK_CHECKUP_REPORT: {
      return {
        ...state,
        success: false,
        getQuickSeoReportError: false,
        loading: false,
      }
    }
    case GET_QUICK_CHECKUP_REPORT_SUCCESS: {
      let reportData
      if (state?.url === action?.payload?.resData?.url) {
        reportData = action?.payload
      } else {
        reportData = {}
      }
      return {
        ...state,
        success: true,
        getQuickSeoReportError: false,
        loading: false,
        data: reportData,
      }
    }
    case GET_QUICK_CHECKUP_REPORT_FAILURE: {
      return {
        ...state,
        success: false,
        getQuickSeoReportError: true,
        loading: false,
        data: {},
      }
    }

    case CLEAR_GET_QUICK_CHECKUP_REPORT:{
      return {
        ...state,
        success: false,
        getQuickSeoReportError: false,
        loading: false,
        data: {},
      }
    }
    case GET_URL_REPORT: {
      return {
        ...state,
        success: false,
        error: false,
        loading: false,
      }
    }
    case GET_URL_REPORT_SUCCESS: {
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        urlReport: action.payload,
      }
    }
    case GET_URL_REPORT_FAILURE: {
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        // urlReport: {}
      }
    }
    default:
      return state
  }
}
export default freeToolsReducer

import { CardBody } from 'reactstrap'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Col, Row } from 'react-bootstrap'

import { message } from 'antd'
import { useFormik } from 'formik'
import { object, string } from 'yup'

import {
  generateResearch,
  getAnalyticsAccountData,
  getAvailableKeywordLimit,
  getUserData,
  saveAnalyticsData,
} from 'redux/actions/dashboard'
import {
  CLEAR_ANALYTICS_DATA,
  CLEAR_DISCONNECT_GOOGLE,
  CLEAR_GET_AUTH_URL,
  CLEAR_STORE_ANALYTICS_DATA,
} from 'redux/actionType'

import paths from 'constants/paths'
import { useDeviceCheck } from 'hooks/deviceHook'
import { getRefreshToken } from 'constants/Utils'

import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import ConnectGoogleForm from 'modules/welcomeBoard/components/addGoogle/ConnectGoogleForm.jsx'
import GoogleAuth from 'modules/welcomeBoard/components/addGoogle/GoogleAuth.jsx'
import GoogleButton from 'modules/welcomeBoard/components/addGoogle/components/GoogleButton.jsx'
import { Loader } from 'components/uiCore'

import 'modules/welcomeBoard/components/addGoogle/AddGoogle.scss'

const AddGoogle = ({ callFrom }) => {
  const width = 600
  const height = 600
  const left = window.innerWidth / 2 - width / 2
  const top = window.innerHeight / 2 - height / 2

  const { isMobile } = useDeviceCheck()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { prevPageData } = useSelector(({ startFreeTrial }) => startFreeTrial)
  const {
    userData,
    getAuthUrlLoader,
    getAuthUrlSuccess,
    authUrl,
    getAuthUrlFailure,
    getAuthUrlError,
    disconnectGoogleLoader,
    disconnectGoogleFailure,
    disconnectGoogleError,
    disconnectGoogleSuccess,
    disconnectGoogleMessage,
    storeAnalyticsDataLoader,
    storeAnalyticsDataFailure,
    storeAnalyticsDataError,
    storeAnalyticsDataSuccess,
    storeAnalyticsDataMessage,
    analyticsData,
    analyticsDataLoader,
    analyticsDataFailure,
    analyticsDataError,
    analyticsDataSuccess,
  } = useSelector(({ dashboard }) => dashboard)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [email, setEmail] = useState(analyticsData?.analyticsData?.email || '')

  const tokenData = getRefreshToken(prevPageData?.url, userData)

  const initialValues = {
    email: email,
    account: '',
    analyticsProperty: '',
    searchConsoleProperty: '',
  }

  const googleAnalyticsFormik = useFormik({
    initialValues,
    onSubmit: (values) => {
      let payload
      const label = propertyOptions?.[0]?.label
      if (label === 'NONE') {
        payload = {
          url: prevPageData?.url,
          analyticsAccount: values.account,
          consoleProperty: values.searchConsoleProperty,
          email: values.email,
        }
      } else {
        payload = {
          url: prevPageData?.url,
          analyticsAccount: values.account,
          analyticsProperty: values.analyticsProperty,
          consoleProperty: values.searchConsoleProperty,
          analyticsAccountNumber: propertyOptions?.[0]?.propertyNumber,
          email: values.email,
        }
      }
      dispatch(saveAnalyticsData(payload))
    },
  })

  const filterPropertyForSelectedAccount =
    analyticsData?.analyticsData?.accountSummaries?.length &&
    analyticsData?.analyticsData?.accountSummaries?.filter(
      (item) => item?.displayName === googleAnalyticsFormik?.values?.account,
    )

  const propertyOptions = (filterPropertyForSelectedAccount?.length &&
    filterPropertyForSelectedAccount?.[0]?.propertySummaries?.map((item) => {
      return {
        value: item?.displayName,
        label: item?.displayName,
        propertyNumber: item?.property,
      }
    })) || [{ value: '', label: 'NONE' }]

  useEffect(() => {
    if (analyticsData?.analyticsData?.email) {
      if (!googleAnalyticsFormik.values.email) {
        googleAnalyticsFormik.setFieldValue('email', analyticsData?.analyticsData?.email)
      }
    }
  }, [analyticsData])

  useEffect(() => {
    if (!analyticsDataLoader && analyticsDataFailure) {
      message.error(analyticsDataError?.message)
      dispatch({ type: CLEAR_ANALYTICS_DATA })
    } else if (!analyticsDataLoader && analyticsDataSuccess) {
      dispatch(getUserData())
    }
  }, [analyticsDataLoader, analyticsDataFailure, analyticsDataError, analyticsDataSuccess])

  useEffect(() => {
    if (!getAuthUrlLoader && getAuthUrlSuccess) {
      const authPopup = window.open(
        authUrl,
        '_blank',
        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
         width=${width},height=${height},left=${left},top=${top}`,
      )
      setIsPopupOpen(true)
      const isPopupClosed = setInterval(() => {
        if (!authPopup || authPopup.closed) {
          clearInterval(isPopupClosed)
          dispatch(getUserData())
          setIsPopupOpen(false)
          dispatch({ type: CLEAR_GET_AUTH_URL })
        }
      }, 1000)
    } else if (!getAuthUrlLoader && getAuthUrlFailure) {
      message.error(getAuthUrlError?.message)
    }
  }, [getAuthUrlLoader, getAuthUrlSuccess, getAuthUrlFailure, getAuthUrlError])

  useEffect(() => {
    if (!disconnectGoogleLoader && disconnectGoogleFailure) {
      message.error(disconnectGoogleError?.message)
      dispatch({ type: CLEAR_DISCONNECT_GOOGLE })
      dispatch(getUserData())
    } else if (!disconnectGoogleLoader && disconnectGoogleSuccess) {
      message.success(disconnectGoogleMessage?.message)
      dispatch({ type: CLEAR_DISCONNECT_GOOGLE })
      dispatch(getUserData())
    }
  }, [
    disconnectGoogleLoader,
    disconnectGoogleFailure,
    disconnectGoogleError,
    disconnectGoogleSuccess,
    disconnectGoogleMessage,
  ])

  useEffect(() => {
    if (!storeAnalyticsDataLoader && storeAnalyticsDataFailure) {
      message.error(storeAnalyticsDataError?.message)
      dispatch({ type: CLEAR_STORE_ANALYTICS_DATA })
      dispatch(getUserData())
    } else if (!storeAnalyticsDataLoader && storeAnalyticsDataSuccess) {
      message.success(storeAnalyticsDataMessage?.message)
      dispatch({ type: CLEAR_STORE_ANALYTICS_DATA })
      dispatch(getUserData())
      dispatch(generateResearch({ url: prevPageData?.url, startDate: '2024-06-15', endDate: '2024-06-30' }))
      navigate(callFrom === 'dashboard' ? paths.dashboardAddKeywords : paths.addKeywords)
      dispatch(getAvailableKeywordLimit())
    }
  }, [
    storeAnalyticsDataLoader,
    storeAnalyticsDataFailure,
    storeAnalyticsDataError,
    storeAnalyticsDataSuccess,
    storeAnalyticsDataMessage,
  ])

  useEffect(() => {
    if (!isPopupOpen && tokenData?.refreshToken) {
      const payload = { url: prevPageData?.url }
      dispatch(getAnalyticsAccountData(payload))
    }
  }, [isPopupOpen, tokenData])

  const handleNext = () => {
    if (analyticsData && tokenData?.refreshToken) {
      const { values } = googleAnalyticsFormik
      const { account, analyticsProperty, searchConsoleProperty } = values
      if (account || analyticsProperty || searchConsoleProperty) {
        googleAnalyticsFormik.handleSubmit()
      } else {
        navigate(callFrom === 'dashboard' ? paths.dashboardAddKeywords : paths.addKeywords)
        dispatch(getAvailableKeywordLimit())
      }
    } else {
      navigate(callFrom === 'dashboard' ? paths.dashboardAddKeywords : paths.addKeywords)
      dispatch(getAvailableKeywordLimit())
    }
  }

  return (
    <div className={callFrom === 'trial' ? 'add-connections' : ''}>
      <Helmet title="Start trail" />
      {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={callFrom === 'trial' ? 'add-connections-text' : 'add-connections-text-dashboard'}>
          {isMobile ? (
            <div className="add-connections-stepnumber-mobile">
              <h3 className="mb-0">4</h3>
            </div>
          ) : null}
          <div className="add-connections-text-mobile">
            <p className="add-connections-text-first">
              Empower Your SEO Journey: Precision, Simplicity, Results
            </p>
            <p className="add-connections-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      <div
        className={`${isMobile && !tokenData?.refreshToken && callFrom === 'trial' ? 'card-wrapper' : ''}`}
      >
        <Card
          className={`trail-flow mb-0 add-website-card-tranparency ${callFrom === 'trial' ? 'add-connections-card' : 'add-connections-dashboard-card'}`}
          style={{ padding: !isMobile ? 15 : null }}
        >
          <CardBody className={`add-website-card-tranparency ${isMobile ? 'p-0' : ''}`}>
            <Card
              className={`mb-0 add-website-card-tranparency ${callFrom === 'trial' ? 'add-connections-nested-card' : 'add-connections-dashboard-nested-card'}`}
            >
              <CommonCardHeader
                startSpanClass={'done-step'}
                websiteSpanClass={'done-step'}
                locationSpanClass={'done-step'}
                googleSpanClass={'active-step'}
                keywordSpanClass={'inactive-step'}
                competitorSpanClass={'inactive-step'}
                sitemapSpanClass={'inactive-step'}
                callFrom={callFrom}
              />

              <CardBody
                className={`bg-white add-website-card-tranparency ${isMobile ? 'p-0 text-center' : ''}`}
              >
                <Row className={isMobile ? 'mr-0 add-connections-row' : ''}>
                  <h3 className="f-bold">Connect google with {prevPageData?.url}</h3>
                  <p style={{ paddingRight: '0' }}>
                    <b>Let's connect your PMS account with your google account data</b>
                  </p>
                  <br />
                </Row>
                <div className={`${isMobile ? 'd-flex justify-content-center p-3 flex-column' : ''}`}>
                  {!analyticsDataLoader ? (
                    !tokenData?.refreshToken ? (
                      <GoogleAuth>
                        <GoogleButton url={prevPageData?.url} />
                      </GoogleAuth>
                    ) : (
                      <ConnectGoogleForm
                        tokenData={tokenData}
                        googleAnalyticsFormik={googleAnalyticsFormik}
                      />
                    )
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        alignSelf: 'center',
                        flexDirection: 'row',
                        minHeight: 380,
                      }}
                    >
                      <div className="text-center">
                        <Loader />
                      </div>
                    </div>
                  )}
                </div>
                <Row
                  className={`mt-2rem ${callFrom === 'trial' ? 'add-connections-navigate-btn add-connections-row' : 'add-connections-navigate-dashboard'}`}
                >
                  <Col
                    className={`${isMobile ? 'text-center' : ''}`}
                    style={{ paddingRight: callFrom === 'trial' ? '0' : '' }}
                  >
                    <Button
                      className="addwebsite-navigate-buttons"
                      variant={`outline-secondary`}
                      style={{ height: 40, width: 250 }}
                      onClick={() =>
                        navigate(callFrom === 'dashboard' ? paths.dashboardaddLocation : paths.addLocation)
                      }
                    >
                      Back
                    </Button>
                  </Col>
                  <Col
                    className={'display-next-end'}
                    style={{ paddingRight: callFrom === 'trial' ? '0' : '' }}
                  >
                    <Button
                      className="addwebsite-navigate-buttons"
                      style={{ height: 40, width: 250 }}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default AddGoogle

import { useSelector } from 'react-redux'
import { Card, Col, Row } from 'react-bootstrap'

import moment from 'moment'
import { uniqBy } from 'lodash'
import { Line } from '@ant-design/charts'

export const CoreWebVitalGraph = () => {
  const { usersDomains: usersDomain, activeLink } = useSelector(({ dashboard }) => dashboard)
  const activeUrlData = usersDomain?.[activeLink]?.items?.length ? usersDomain?.[activeLink]?.items : []

  const sortData = (data) => {
    if (!data) return []
    return data?.sort((a, b) => {
      const [monthA, dayA, yearA] = a?.x?.split('-').map(Number)
      const [monthB, dayB, yearB] = b?.x?.split('-').map(Number)

      if (yearA !== yearB) {
        return yearA - yearB
      }
      if (monthA !== monthB) {
        return monthA - monthB
      }
      return dayA - dayB
    })
  }

  const formatData = (data, valueKey, category, devideBy = 1, roundValue = 1) => {
    if (!data?.length) return []
    return data?.map((item) => {
      if (item?.[valueKey] && Object.keys(item?.[valueKey])?.length) {
        return {
          x: moment(item?.createdAt).format('MM-DD-YYYY'),
          y:
            valueKey === 'clsd' || valueKey === 'clsm'
              ? parseFloat(item?.[valueKey]?.numericValue / devideBy) || 0.0
              : parseFloat((item?.[valueKey]?.numericValue / devideBy).toFixed(roundValue)) || 0.0,
          category,
        }
      }
    })
  }

  const filterUndefined = (array) => {
    if (!array?.length) return []
    return array?.filter((item) => item !== undefined)
  }

  //largest contentful paint
  const largestContentfulPaintForDesktop = activeUrlData?.length
    ? formatData(activeUrlData, 'lcpd', 'Desktop', 1000)
    : []
  const largestContentfulPaintForMobile = activeUrlData?.length
    ? formatData(activeUrlData, 'lcpm', 'Mobile', 1000)
    : []

  let largestContentfulPaint = []
  if (largestContentfulPaintForDesktop) {
    largestContentfulPaint = [...largestContentfulPaint, ...largestContentfulPaintForDesktop]
  }

  if (largestContentfulPaintForMobile) {
    largestContentfulPaint = [...largestContentfulPaint, ...largestContentfulPaintForMobile]
  }

  largestContentfulPaint = filterUndefined(largestContentfulPaint)
  largestContentfulPaint = sortData(largestContentfulPaint)
  largestContentfulPaint = uniqBy(largestContentfulPaint, (item) => `${item.x}-${item.category}`)

  //First Input Delay
  const firstInputDelayForDesktop = activeUrlData?.length ? formatData(activeUrlData, 'fidd', 'Desktop') : []
  const firstInputDelayForMobile = activeUrlData?.length ? formatData(activeUrlData, 'fidm', 'Mobile') : []

  let firstInputDelay = []
  if (firstInputDelayForDesktop) {
    firstInputDelay = [...firstInputDelay, ...firstInputDelayForDesktop]
  }

  if (firstInputDelayForMobile) {
    firstInputDelay = [...firstInputDelay, ...firstInputDelayForMobile]
  }

  firstInputDelay = filterUndefined(firstInputDelay)
  firstInputDelay = sortData(firstInputDelay)
  firstInputDelay = uniqBy(firstInputDelay, (item) => `${item.x}-${item.category}`)

  //Cumulative Layout Shift
  const cumulativeLayoutShiftForDesktop = activeUrlData?.length
    ? formatData(activeUrlData, 'clsd', 'Desktop', 1)
    : []
  const cumulativeLayoutShiftForMobile = activeUrlData?.length
    ? formatData(activeUrlData, 'clsm', 'Mobile', 1)
    : []

  let cumulativeLayoutShift = []
  if (cumulativeLayoutShiftForDesktop) {
    cumulativeLayoutShift = [...cumulativeLayoutShift, ...cumulativeLayoutShiftForDesktop]
  }

  if (cumulativeLayoutShiftForMobile) {
    cumulativeLayoutShift = [...cumulativeLayoutShift, ...cumulativeLayoutShiftForMobile]
  }

  cumulativeLayoutShift = filterUndefined(cumulativeLayoutShift)
  cumulativeLayoutShift = sortData(cumulativeLayoutShift)
  cumulativeLayoutShift = uniqBy(cumulativeLayoutShift, (item) => `${item.x}-${item.category}`)

  const graphConfig = {
    width: 800,
    height: 400,
    autoFit: true,
    xField: 'x',
    yField: 'y',
    seriesField: 'category',
    smooth: true,
    animation: false,
    legend: { marker: { symbol: 'circle' } },
    tooltip: {
      text: {
        style: {
          fontFamily: 'Seravek',
        },
      },
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    point: {
      size: 5,
      shape: 'circle',
      fill: '#bbb',
    },
    yAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'Seravek',
        },
      },
    },
  }

  const largestContentfulPaintConfig = {
    ...graphConfig,
    data: largestContentfulPaint,
    tooltip: {
      ...graphConfig?.tooltip,
      formatter: (item) => {
        return {
          name: item?.category,
          value: `${item?.y} sec`,
        }
      },
    },
  }
  const firstInputDelayConfig = {
    ...graphConfig,
    data: firstInputDelay,
    tooltip: {
      ...graphConfig?.tooltip,
      formatter: (item) => {
        return {
          name: item?.category,
          value: `${item?.y} ms`,
        }
      },
    },
  }

  const cumulativeLayoutShiftConfig = {
    ...graphConfig,
    data: cumulativeLayoutShift,
  }
  return (
    <>
      <Row>
        <Col md={6}>
          <Card className="flex-fill url-details-inner">
            <Card.Header>
              <Card.Title tag="h1" className="mb-0 f-bold">
                Largest Contentful Paint
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Line {...largestContentfulPaintConfig} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="flex-fill url-details-inner">
            <Card.Header>
              <Card.Title tag="h1" className="mb-0 f-bold">
                First Input Delay
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Line {...firstInputDelayConfig} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col md={6}>
          <Card className="flex-fill url-details-inner">
            <Card.Header>
              <Card.Title tag="h1" className="mb-0 f-bold">
                Cumulative Layout Shift
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Line {...cumulativeLayoutShiftConfig} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export const commonSEOIssueInfo = {
  metaTitle: `This test evaluates the length and effectiveness of the meta title tag on your webpage. The meta title is a critical HTML element that summarizes page content for search engines and users. It impacts your site's SEO and click-through rates. This test ensures your title is within the optimal character range for maximum visibility and relevance in search results.`,
  metaDescription: `This test evaluates the effectiveness of the meta description for each page. It checks for optimal length, relevance to the page content, inclusion of target keywords, and its potential to drive click-through rates from search engine results pages (SERPs). A well-crafted meta description acts as an organic ad text, encouraging users to click through to your site.`,
  seoResult:
    'See how your page might appear in search engine search results. Website information is typically summarized by your webpage title, URL, and meta-description. In the case of overly long elements, search engines will truncate them. To optimize readability, webpage titles and descriptions should be no longer than 60 characters.',
  socialMediaMetaTag: `This test checks for the presence of social media meta tags on your webpage, which are invisible to visitors but critical for social sharing. Tags like Open Graph for Facebook and Twitter Cards define how your content is displayed when shared on social platforms, influencing user engagement and amplifying your brand's reach. Make sure these tags are in place to enhance your content's social media performance.`,
  commonKeywords:
    "This test analyzes your page's content to identify the most frequent keywords and assesses their density. Proper keyword density can affect your SEO ranking, as it helps avoid over-optimization, which search engines may penalize. The recommended balance is to feature a keyword no more than once per 200 words to maintain natural flow and relevance.",
  keywordUsageTest: `This test evaluates how effectively and naturally the most common keywords of your website are integrated into your content. Proper keyword usage involves placing these keywords in strategic locations like titles, headings, and throughout the body of the text without overstuffing. This balance helps improve your SEO by making your content more relevant and readable to both search engines and users.`,
  relatedKeywords:
    'Related keywords will show search terms for which this URL shows up in the top 20 organic results.',
  keywordCloud:
    'The Keyword Cloud is a visual representation of keywords used on your website. This will show you which words are frequently used in the content of your webpage. Keywords having higher density are presented in larger fonts and displayed in alphabetic order.',
  seoFriendly:
    "This test checks if your website uses SEO-friendly URLs. SEO-friendly URLs are important because they are easy for both users and search engines to read and understand, which can improve your site's search engine rankings and user experience.",
  sitemapData:
    'This test evaluates the presence and effectiveness of your XML sitemap, which provides search engines with a roadmap of your website’s content. A well-configured sitemap helps ensure that all important pages are indexed and can improve the efficiency of the crawling process.',
  robotsTxt:
    'This test checks if a robots.txt file exists on your website. The presence of a robots.txt file is important as it instructs search engine crawlers on how to index and crawl your site, which can impact your SEO performance.',
  socialMedia: `This test checks if your website is connected to social media platforms. Being connected to social media can enhance your online presence, drive traffic, and improve user engagement.`,
  cdnUsage:
    "This test checks if your website is using a Content Delivery Network (CDN). CDNs distribute your content across multiple servers globally, reducing load times and improving the reliability and performance of your site. Using a CDN can enhance user experience and boost your site's SEO.",
  cssAnalysis:
    'This test checks the efficiency and quality of the CSS used on your webpage. Properly optimized CSS ensures faster load times, reduces rendering issues, and improves overall site performance. It also helps maintain a clean and maintainable codebase.',
  cachePolicy:
    'This test checks if your webpage is effectively storing and fetching data from the cache. Serving cached pages can significantly reduce load times for users, especially returning visitors, and improve overall site performance.',
  brokenlinks:
    'Check to view the backlinks for your website. Backlinks are any links to your website from an external site. Relevant backlinks from authority sites are critical for higher search engine rankings. Our backlink checker also helps identify low-quality backlinks that can lead to search engine penalties for your website',
  cssCache: `This test checks if your webpage is using caching headers for all CSS resources. Proper caching headers ensure that CSS files are stored in the user's browser cache, reducing load times on subsequent visits and improving overall site performance.`,
  cssMinification: `This test checks if your webpage's CSS files are minified. Minification reduces the size of CSS files by removing unnecessary characters like spaces, line breaks, and comments, which helps improve load times and overall site performance.`,
  jsMinification: `This test checks if your webpage's JavaScript files are minified. Minification reduces the size of JavaScript files by removing unnecessary characters like spaces, line breaks, and comments, which helps improve load times and overall site performance.`,
  jsCache: `This test checks if your webpage is using caching headers for all JavaScript resources. Proper caching headers ensure that JavaScript files are stored in the user's browser cache, reducing load times on subsequent visits and improving overall site performance.`,
  cssCache: `This test checks if your webpage is using caching headers for all CSS resources. Proper caching headers ensure that CSS files are stored in the user's browser cache, reducing load times on subsequent visits and improving overall site performance.`,
  imageCache: `This test checks if your website's images are being effectively cached. Proper caching of images ensures that they are stored in the user's browser cache, reducing load times on subsequent visits and improving overall site performance.`,
  imageAlt: `This test checks if your website's images use alt attributes. Alt attributes are important for accessibility, as they provide text descriptions of images for screen readers. They also help search engines understand the content of your images, which can improve your SEO performance.`,
  descriptiveLinkTest: `This test checks if your website uses descriptive link text. Descriptive link text is important for accessibility and SEO because it provides context about the linked content, making it easier for users and search engines to understand the purpose of the link.`,
  headingAnalysis:
    'This test evaluates the structure and usage of heading tags (H1, H2, H3, etc.) on your webpage. Proper use of heading tags helps organize your content, making it easier for search engines and users to understand the main topics and subtopics. This test ensures that your headings are used effectively to enhance readability and SEO performance.',
  desktopScreenshot: 'Check how your website renders on a desktop device.',
  mobileScreenshot: 'Check how your website renders on a mobile device.',
  disalllowDirective:
    'Check if your robots.txt file is instructing search engine crawlers to avoid parts of your website. The disallow directive is used in robots.txt to tell search engines not to crawl and index a file, page, or directory.',
  docType: `This test checks if your webpage includes a Doctype declaration. The Doctype declaration is essential as it tells the browser which version of HTML you are using, ensuring that your webpage is rendered correctly. Without a proper Doctype, browsers may enter "quirks mode," which can lead to inconsistent rendering and potential issues with your site's appearance and functionality.`,
  faviconTest:
    'This test checks if your website has a favicon. A favicon is important for branding and helps users easily identify your site in their browser tabs, bookmarks, and history.',
  flashTest: `This test checks if your website uses Flash content. Flash is outdated and not supported by most modern browsers and devices. Using Flash can lead to a poor user experience and negatively impact your site's accessibility and SEO.`,
  framesetTest: `This test checks if your webpage uses frames. Frames were commonly used divide a browser window into multiple sections where each section could load a separate HTML document. Frames can cause issues with accessibility, SEO, and user experience. It's recommended to use more modern layout techniques, such as iframes or CSS, to create responsive and accessible designs.`,
  googleAnalytics:
    "This test checks if your website is using Google Analytics. Google Analytics is important for tracking and analyzing website traffic, helping you understand your audience and improve your site's performance.",
  htmlCompression:
    'This test checks if your website is using content compression, such as Gzip or Brotli. Content compression reduces the size of your web files, which can significantly improve page load times and overall performance, enhancing user experience and SEO.',
  htmlPage:
    'This test checks the size of your HTML page. Large HTML pages can lead to slower load times, which can negatively impact user experience and SEO performance. Keeping your HTML page size optimized is crucial for maintaining a fast and efficient website.',
  htmlValidation: `This test checks if your webpage's HTML code is valid according to web standards. Valid HTML ensures that your webpage renders correctly across different browsers and devices, improving user experience and accessibility.`,
  httpsTest:
    'This test checks if your webpage is serving mixed content, where some resources (like images, scripts, or stylesheets) are loaded over HTTP while the main page is served over HTTPS. Mixed content can lead to security vulnerabilities, browser warnings, and a poor user experience. Ensuring all content is served over HTTPS is crucial for maintaining security and trustworthiness.',
  inlineCss:
    'This test checks if your webpage uses inline CSS styles. Excessive use of inline CSS can lead to poor maintainability and performance issues. It is recommended to use external CSS files for better performance and easier maintenance.',
  jsError:
    'This test checks if your website has any JavaScript errors. JavaScript errors can negatively impact user experience and functionality, causing parts of your website to not work as intended.',
  mediaQuery:
    "Check if your page implements responsive design functionalities using the media query technique. The '@media' rule allows different style rules for screen sizes. Media query techniques allow different presentation and content to be served depending on the output device, helping ensure that your website renders optimally on all devices and platforms.",
  microdataSchema: `This test checks if your webpage is using microdata schema correctly. Microdata schema is a structured data format that helps search engines understand the content of your page more accurately, leading to enhanced search results such as rich snippets. Proper implementation of microdata can improve your site's visibility and click-through rate in search engine results.`,
  nestedTable:
    "This test checks if your webpage uses nested tables. Nested tables can complicate the structure of your webpage, leading to slower rendering times, reduced accessibility, and a poor user experience. It's recommended to avoid using nested tables.",
  noFollow:
    'Check if your webpage is using the robots meta tag or the X-Robots-Tag HTTP header to instruct search engines not to follow the links on your page. Outgoing links marked with this tag will tell search engines not to follow or crawl that particular link. Google recommends that nofollow tags are used for paid advertisements on your site and links to pages that have not been vetted as trusted sites (e.g., links posted by users of your site).',
  noIndex: `This test checks if your webpage has a "noindex" directive, which instructs search engines not to index the page. While this can be useful for certain pages, it is important to ensure that it is only applied to pages that should not appear in search results. Misusing the "noindex" directive can lead to important pages being excluded from search engine indexing, negatively impacting your site's visibility.`,
  pageCache: `This test checks if your webpage is using server-side caching. Server-side caching can significantly improve page load times by storing a copy of your webpage and serving it to users quickly. Proper caching helps enhance user experience and improve your site's SEO performance.`,
  pageObject:
    'Check if all the objects requested by this webpage can be retrieved. If they are not retrievable, your page may display incorrectly, leading to a bad user experience and lower search engine rankings.',
  plainText: `This test checks if your webpage displays email addresses in plaintext. Displaying email addresses in plaintext can make them vulnerable to being harvested by spambots, leading to an increase in spam and potential security risks. It's recommended to obfuscate email addresses or use contact forms to protect them from automated scraping.`,
  redirectTest: `This test checks if your webpage uses redirects and evaluates their impact. Redirects can be useful for directing users and search engines to the correct page, but excessive or improper use can lead to slower load times and a poor user experience. It's important to use redirects appropriately and minimize their use to ensure optimal site performance.`,
  safeBrowsing:
    "This test checks if your website is flagged by Google Safe Browsing as unsafe. Safe Browsing protects users by warning them about potentially harmful sites that contain malware, phishing, or other malicious content. Ensuring your site is not flagged helps maintain user trust and protects your site's reputation.",
  serverSignature:
    'This test checks if your server signature is exposed. A server signature reveals details about the server software, which can provide potential attackers with information to exploit vulnerabilities. Disabling the server signature helps to enhance the security of your website by minimizing the information available to attackers.',
  siteSpeed:
    "This test checks the loading speed of your website. Fast loading times are crucial for providing a good user experience and can positively impact your site's SEO. Slow loading times can lead to higher bounce rates and lower search engine rankings.",
  spfRecord: `This test checks if your domain has a properly configured SPF (Sender Policy Framework) record. SPF records help prevent unauthorized users from sending emails on behalf of your domain, reducing the risk of spam and phishing attacks. Properly configured SPF records are essential for improving email deliverability and maintaining your domain's reputation.`,
  urlCanonicalization:
    'This test checks if your webpage has proper URL canonicalization in place. Canonicalization helps prevent duplicate content issues by specifying a preferred version of a webpage when multiple URLs can serve the same content. Proper URL canonicalization ensures that search engines index the correct version of your page, improving SEO and avoiding potential ranking issues.',
  brotilTest: `This test checks if your website is using Brotli compression for serving web content. Brotli is a modern compression algorithm that offers better compression rates compared to older methods like Gzip, leading to faster load times and reduced bandwidth usage. Implementing Brotli can significantly improve your site's performance, especially for users on slower networks.`,
  dnsSecurity: 'Check if website has DNS Security enabled',
  http2Test:
    'This test checks if your webpage is served over the HTTP/2 protocol. HTTP/2 offers significant performance improvements over HTTP/1.1, including faster load times, better multiplexing, and reduced latency. Serving your content over HTTP/2 can greatly enhance user experience and improve overall site performance.',
  sslFreak:
    'This test checks if your website is using SSL and HTTPS to secure data transmission. SSL (Secure Sockets Layer) and HTTPS (Hypertext Transfer Protocol Secure) ensure that data sent between your website and its users is encrypted, protecting sensitive information from interception and ensuring the integrity and trustworthiness of your site.',
  tlsLogjam:
    'This test checks if your website is vulnerable to the TLS Logjam attack. The Logjam vulnerability allows attackers to downgrade the strength of the encryption used in TLS connections, potentially exposing sensitive data to interception. Ensuring that your server uses strong Diffie-Hellman groups and disables support for weak ciphers is essential to protect against this vulnerability.',
  renderBlocking:
    'This test checks if your webpage has resources (like CSS or JavaScript files) that block the rendering of your page. Render-blocking resources can delay the loading of your page, negatively impacting user experience and site performance. Optimizing the loading of these resources can improve load times and user engagement.',
  mordenImage: `This test checks if your website is using modern image formats like WebP or AVIF. Modern image formats offer better compression and quality compared to older formats like JPEG or PNG, leading to faster load times and improved performance, which can enhance user experience and SEO.`,
  aspectRatio: `This test checks if all images on your webpage are displayed with the correct aspect ratio. Maintaining the correct aspect ratio is important to avoid image distortion, which can create an unpleasant user experience.`,
  responsiveImage: `This test checks if all images on your webpage are properly sized for different users' viewports. Properly sized images are important because they enhance user experience by improving page load times and reducing bandwidth usage, which can also positively impact your SEO performance.`,
  consoleErrors:
    'This test checks the Chrome DevTools Console for warnings and errors. Console warnings and errors can indicate underlying issues that may affect the performance, functionality, and user experience of your site.',
  advancedConsoleErrors:
    'This test performs an advanced check of the Chrome DevTools Console for warnings and errors. Console warnings and errors can indicate significant underlying issues that may affect the performance, functionality, and user experience of your site.',
  domSize: `This test checks the number of nodes in your webpage's DOM tree. A large number of DOM nodes can lead to slower page rendering and reduced performance, negatively impacting user experience and SEO. Keeping the number of DOM nodes optimized is crucial for maintaining a fast and efficient website. The recommended number of DOM nodes is below 1500 for optimal performance.`,
  adsTxt:
    'This test checks if your website has an ads.txt file and whether it is correctly configured. The ads.txt file helps protect your website from unauthorized digital ad sales, ensuring transparency and preventing ad fraud. Proper configuration of ads.txt is essential for maintaining the integrity of your ad inventory and maximizing revenue.',
  jsExecutionTime: `This test checks the execution time of JavaScript on your website. Fast JavaScript execution is crucial for providing a smooth user experience and can positively impact your site's performance and SEO. Slow JavaScript execution can lead to delays in page interactivity and lower user satisfaction.`,
  characterSet: `This test checks if the webpage has a character encoding declaration ("charset"). If the server doesn't specify a character encoding format when it sends an HTML file, the browser may display some characters incorrectly to users. Google recommends using the Unicode/UTF-8 character set when possible.`,
  viewport:
    'This test checks if your webpage includes a proper viewport meta tag. The viewport meta tag controls how your website is displayed on different devices, ensuring that it is responsive and accessible on mobile devices and tablets. A correctly configured viewport ensures that users have an optimal viewing experience regardless of the device they use.',
  metaRefresh:
    'This test checks if your webpage uses a meta refresh tag to automatically redirect users after a set time. While meta refresh can be useful, it may cause usability issues and negatively impact SEO, especially if the refresh is immediate or without clear user intent. Best practices recommend using server-side redirects instead.',
  custom404:
    'This test checks if your website has a custom 404 error page. A custom 404 page enhances the user experience by providing helpful navigation options and a consistent site look when a page is not found. Properly implemented, a custom 404 page can help retain visitors who might otherwise leave your site.',
  layoutShift:
    'This test checks the Cumulative Layout Shift (CLS) of your webpage. CLS measures the visual stability of your page by tracking unexpected layout shifts during the loading process. A low CLS score indicates that the page content remains stable as it loads, providing a smooth and predictable user experience.',
  contentfulPaint:
    'This test checks the time it takes for the largest content element on your webpage to become visible (Largest Contentful Paint or LCP). LCP is a critical performance metric that impacts user experience. A fast LCP ensures that the main content is quickly visible to users, improving engagement and satisfaction.',
  competitorDomain: 'This will show some possible competitors for your domain.',
  hsts: 'This test checks if your website is using HTTP Strict Transport Security (HSTS). HSTS is a security feature that ensures browsers only interact with your site over HTTPS, preventing protocol downgrade attacks and cookie hijacking. Implementing HSTS enhances your site’s security by forcing all connections to be secure.',
  canonicalURL:
    'This test checks if your webpage has a properly implemented canonical URL tag. The canonical tag informs search engines of the preferred version of a page when duplicate content is accessible through multiple URLs. Proper use of the canonical tag helps prevent duplicate content issues, ensuring that the correct version of your page is indexed and ranked by search engines.',
  directoryBrowsing:
    'This test checks if directory browsing is enabled on your website. Directory browsing allows users to see a list of files in a web directory if no index file is present. While this can be useful for development, it poses a security risk in production environments by exposing sensitive files or information. Disabling directory browsing helps to protect your site from unauthorized access.',
  unsafeCrossOriginLinks: `This test checks if all links to external pages that use the target="_blank" attribute also include the rel="noopener" or rel="noreferrer" attribute. When linking to external sites with target="_blank", security and performance issues can arise. Without rel="noopener" or rel="noreferrer", the external page can access your window object via the window.opener property, potentially redirecting your page to a malicious URL or causing performance issues due to shared processes. Adding these attributes prevents such risks.`,
  Backlinks:
    'Check to view the backlinks for your website. Backlinks are any links to your website from an external site. Relevant backlinks from authority sites are critical for higher search engine rankings. Our backlink checker also helps identify low-quality backlinks that can lead to search engine penalties for your website.',
  fontSize:
    'This test checks if the font sizes used on your webpage are appropriate for readability across different devices, especially mobile devices. Proper font sizing ensures that text is legible without requiring users to zoom in or strain their eyes, enhancing overall user experience and accessibility.',
  tapTargets:
    'This test checks if the tap targets (such as buttons and links) on your webpage are large enough and well-spaced for easy tapping on touch devices. Properly sized and spaced tap targets prevent user frustration by reducing the likelihood of accidental taps, enhancing the overall user experience, especially on mobile devices.',
  contentWidth:
    'This test checks if the content on your webpage is properly sized to fit within the viewport. Ensuring that content-width is appropriately set prevents horizontal scrolling and ensures that users can view your content comfortably on all devices, particularly on mobile devices.',
  speedIndex:
    'This test checks the Speed Index of your webpage, which measures how quickly content is visually displayed during page load. A lower Speed Index indicates that content is displayed quickly, providing a better user experience. Optimizing your Speed Index can significantly enhance the perceived performance of your site.',
  firstContentfulPaint:
    'This test checks the First Contentful Paint (FCP) of your webpage, which measures the time it takes for the first piece of content to appear on the screen. FCP is a key performance metric that impacts user perception of how fast your site is loading. A fast FCP enhances user experience by showing visible content as quickly as possible.',
  largestContentfulPaint:
    'This test checks the Largest Contentful Paint (LCP) of your webpage, which measures the time it takes for the largest content element (such as an image or text block) to become fully visible on the screen. LCP is a crucial performance metric that reflects how quickly users perceive the main content of the page to be loaded. A fast LCP improves user experience and engagement.',
  cumulativeLayoutShift:
    'This test provides a comprehensive analysis of the factors contributing to Cumulative Layout Shift (CLS) on your webpage. CLS measures the visual stability of your page by tracking unexpected layout shifts during loading. By analyzing various signals, this test helps identify specific elements causing layout instability and offers insights to improve the user experience.',
  timetoInteractive:
    'This test examines the responsiveness of your webpage, measuring how quickly it becomes fully interactive. A webpage is considered fully interactive when it responds quickly to user inputs like clicks, taps, and keystrokes. Optimizing interactive signals is crucial for providing a smooth and engaging user experience.',
  firstInputDelay: `This test measures the First Input Delay (FID) on your webpage, which is the time it takes for the browser to respond to a user's first interaction. A low FID indicates that your page is responsive and provides a good user experience. Optimizing FID is important to ensure that users do not experience frustrating delays when interacting with your site.`,
}

import { TEST_TYPES } from "constants/enum"

export const getDataForDashboard = (data, isUrlData = false) => {
  let updatedErrorsArray = []

  let passedTestArray = []
  let failedTestArray = []
  let warningTestArray = []

  let positiveCounter = 0,
    warningCounter = 0,
    negativeCounter = 0,
    commonSeo = 0,
    commonSeoS = 0,
    commonSeoW = 0,
    commonSeoF = 0,
    speedOptS = 0,
    speedOptW = 0,
    speedOptF = 0,
    serverS = 0,
    serverW = 0,
    serverF = 0,
    mobileUseS = 0,
    mobileUseW = 0,
    mobileUseF = 0,
    advanceS = 0,
    advanceW = 0,
    advanceF = 0
  if (data) {
    if (data?.information?.seoCheckup?.title?.titleStatus === 1) {
      passedTestArray.push('meta-title')
      positiveCounter++
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.information?.seoCheckup?.title?.titleStatus === 2) {
      warningCounter++
      warningTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.title?.titleStatus,
            id: 'meta-title',
            message: data?.information?.seoCheckup?.title?.titleMessage,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.title?.titleStatus,
            id: 'meta-title',
            message: data?.information?.seoCheckup?.title?.titleMessage,
          },
        ]
      }
    }

    if (data?.information?.seoCheckup?.description?.descriptionStatus === 1) {
      positiveCounter++
      passedTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.information?.seoCheckup?.description?.descriptionStatus === 2) {
      warningCounter++
      warningTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.description?.descriptionStatus,
            id: 'meta-description',
            message: data?.information?.seoCheckup?.description?.descriptionMessage,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.description?.descriptionStatus,
            id: 'meta-description',
            message: data?.information?.seoCheckup?.description?.descriptionMessage,
          },
        ]
      }
    }

    //social media Test
    if (data?.metaTagsTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.metaTagsTest?.status === 2) {
      warningCounter++
      warningTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaTagsTest?.status,
            id: 'social-media-meta-tag',
            message: data?.metaTagsTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaTagsTest?.status,
            id: 'social-media-meta-tag',
            message: data?.metaTagsTest?.[0]?.data?.metaTagsResults?.message,
          },
        ]
      }
    }

    // keyword-usage-test
    if (data?.keywordsUsageTest
      ?.status === 1) {
      positiveCounter++
      passedTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.keywordsUsageTest
      ?.status === 2) {
      warningCounter++
      warningTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.keywordsUsageTest
            ?.status,
            id: 'keyword-usage-test',
            message: data?.keywordsUsageTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.keywordsUsageTest
            ?.status,
            id: 'keyword-usage-test',
            message: data?.keywordsUsageTest?.message,
          },
        ]
      }
    }

    // seo-friendly
    // if (data?.seoFriendlyUrlAnalysis?.[0]?.data?.seoUnfriendly?.length === 0) {
    //   positiveCounter++
    //   passedTestArray.push('seo-friendly')
    //   if (isUrlData) {
    //     commonSeoS++
    //   }
    // } else {
    //   negativeCounter++
    //   failedTestArray.push('seo-friendly')
    //   if (isUrlData) {
    //     commonSeoF++
    //     updatedErrorsArray = [
    //       ...updatedErrorsArray,
    //       {
    //         priority: 3,
    //         id: 'seo-friendly',
    //         message: 'This webpage contains URLs that are not SEO friendly!',
    //       },
    //     ]
    //   }
    // }

    if (data?.seoFriendlyUrlAnalysis
      ?.status === 1) {
      positiveCounter++
      passedTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.seoFriendlyUrlAnalysis
      ?.status === 2) {
      warningCounter++
      warningTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.seoFriendlyUrlAnalysis
              ?.status,
            id: 'seo-friendly',
            message: data?.seoFriendlyUrlAnalysis
              ?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.seoFriendlyUrlAnalysis
              ?.status,
            id: 'seo-friendly',
            message: data?.seoFriendlyUrlAnalysis
              ?.message,
          },
        ]
      }
    }

    // sitemaps
    if (data?.siteMapData?.status === 1) {
      positiveCounter++
      passedTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.siteMapData?.status === 2) {
      warningCounter++
      warningTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteMapData?.status,
            id: 'sitemap-data',
            message: data?.siteMapData?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteMapData?.status,
            id: 'sitemap-data',
            message: data?.siteMapData?.message,
          },
        ]
      }
    }

    // broken-links
    if (data?.brokenLinks?.[0]?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('broken-links')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.brokenLinks?.[0]?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('broken-links')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brokenLinks?.[0]?.data?.testResults?.status,
            id: 'broken-links',
            message: data?.brokenLinks?.[0]?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('broken-links')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brokenLinks?.[0]?.data?.testResults?.status,
            id: 'broken-links',
            message: data?.brokenLinks?.[0]?.data?.testResults?.message,
          },
        ]
      }
    }

    // robots
    if (data?.robotsTxtTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.robotsTxtTest?.status === 2) {
      warningCounter++
      warningTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.robotsTxtTest?.status,
            id: 'robots-txt',
            message: data?.robotsTxtTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.robotsTxtTest?.status,
            id: 'robots-txt',
            message: data?.robotsTxtTest?.message,
          },
        ]
      }
    }

    // cdn-usage
    if (data?.cdnUsageData?.status === 1) {
      positiveCounter++
      passedTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cdnUsageData?.status === 2) {
      warningCounter++
      warningTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cdnUsageData?.status,
            id: 'cdn-usage',
            message: data?.cdnUsageData?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cdnUsageData?.status,
            id: 'cdn-usage',
            message: data?.cdnUsageData?.message,
          },
        ]
      }
    }

    // cssAnalysis
    if (data?.cssAnalysis?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssAnalysis?.status === 2) {
      warningCounter++
      warningTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssAnalysis?.status,
            id: 'css-analysis',
            message: data?.cssAnalysis?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssAnalysis?.status,
            id: 'css-analysis',
            message: data?.cssAnalysis?.message,
          },
        ]
      }
    }

    // cssCache
    if (data?.cssCache?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssCache?.status === 2) {
      warningCounter++
      warningTestArray.push('css-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssCache?.status,
            id: 'css-cache',
            message: data?.cssCache?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('css-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssCache?.status,
            id: 'css-cache',
            message: data?.cssCache?.message,
          },
        ]
      }
    }

    // cssMinification
    if (data?.cssMinification?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-minification')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssMinification?.status === 2) {
      warningCounter++
      warningTestArray.push('css-minification')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssMinification?.status,
            id: 'css-minification',
            message: data?.cssMinification?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('css-minification')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssMinification?.status,
            id: 'css-minification',
            message: data?.cssMinification?.message,
          },
        ]
      }
    }

    // jsMinification
    if (data?.jsMinification?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-minification')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.jsMinification?.status === 2) {
      warningCounter++
      warningTestArray.push('js-minification')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsMinification?.status,
            id: 'js-minification',
            message: data?.jsMinification?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-minification')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsMinification?.status,
            id: 'js-minification',
            message: data?.jsMinification?.message,
          },
        ]
      }
    }

    // jsCache
    if (data?.jsCache?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.jsCache?.status === 2) {
      warningCounter++
      warningTestArray.push('js-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsCache?.status,
            id: 'js-cache',
            message: data?.jsCache?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsCache?.status,
            id: 'js-cache',
            message: data?.jsCache?.message,
          },
        ]
      }
    }

    // imageCache
    if (data?.imageCache?.status === 1) {
      positiveCounter++
      passedTestArray.push('image-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.imageCache?.status === 2) {
      warningCounter++
      warningTestArray.push('image-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageCache?.status,
            id: 'image-cache',
            message: data?.imageCache?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('image-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageCache?.status,
            id: 'image-cache',
            message: data?.imageCache?.message,
          },
        ]
      }
    }

    // cachePolicy
    if (data?.cachePolicy?.status === 1) {
      positiveCounter++
      passedTestArray.push('cache-policy')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.cachePolicy?.status === 2) {
      warningCounter++
      warningTestArray.push('cache-policy')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cachePolicy
              ?.status,
            id: 'cache-policy',
            message: data?.cachePolicy
              ?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('cache-policy')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cachePolicy
              ?.status,
            id: 'cache-policy',
            message: data?.cachePolicy
              ?.message,
          },
        ]
      }
    }

    // imageAlt
    if (data?.imageAlt?.status === 1) {
      positiveCounter++
      passedTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.imageAlt?.status === 2) {
      warningCounter++
      warningTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageAlt?.status,
            id: 'image-alt',
            message: data?.imageAlt?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageAlt?.status,
            id: 'image-alt',
            message: data?.imageAlt?.message,
          },
        ]
      }
    }

    // headingAnalysis
    if (data?.headingAnalysis?.status === 1) {
      positiveCounter++
      passedTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.headingAnalysis?.status === 2) {
      warningCounter++
      warningTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.headingAnalysis?.status,
            id: 'heading-analysis',
            message: data?.headingAnalysis?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.headingAnalysis?.status,
            id: 'heading-analysis',
            message: data?.headingAnalysis?.message,
          },
        ]
      }
    }

    // domainCompetitors
    //  if (data?.domainCompetitors?.[0]?.data?.testResults?.status === 1) {
    //     positiveCounter++;
    //     if (isUrlData) {
    //         commonSeoS++;
    //     }
    // } else if(data?.domainCompetitors?.[0]?.data?.testResults?.status === 2) {
    //     warningCounter++;
    //     if (isUrlData) {
    //         commonSeoW++;
    //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.domainCompetitors?.[0]?.data?.testResults?.status, id: 'competitor', message: data?.domainCompetitors?.[0]?.data?.testResults?.message }];
    //     }
    // } else {
    //     negativeCounter++;
    //     if (isUrlData) {
    //         commonSeoF++;
    //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.domainCompetitors?.[0]?.data?.testResults?.status, id: 'competitor', message: data?.domainCompetitors?.[0]?.data?.testResults?.message }];
    //     }
    // }

    //  // disallowDirectiveChecker
    //  if (data?.disallowDirectiveChecker?.[0]?.data?.finalRes?.status === 1) {
    //     positiveCounter++;
    //     if (isUrlData) {
    //         commonSeoS++;
    //     }
    // } else if(data?.disallowDirectiveChecker?.[0]?.data?.finalRes?.status === 2) {
    //     warningCounter++;
    //     if (isUrlData) {
    //         commonSeoW++;
    //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.disallowDirectiveChecker?.[0]?.data?.finalRes?.status, id: 'disalllow-directive', message: data?.disallowDirectiveChecker?.[0]?.data?.finalRes?.message }];
    //     }
    // } else {
    //     negativeCounter++;
    //     if (isUrlData) {
    //         commonSeoF++;
    //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.disallowDirectiveChecker?.[0]?.data?.finalRes?.status, id: 'disalllow-directive', message: data?.disallowDirectiveChecker?.[0]?.data?.finalRes?.message }];
    //     }
    // }

    // docType
    if (data?.docType?.status === 1) {
      positiveCounter++
      passedTestArray.push('doc-type')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.docType?.status === 2) {
      warningCounter++
      warningTestArray.push('doc-type')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.docType?.status,
            id: 'doc-type',
            message: data?.docType?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('doc-type')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.docType?.status,
            id: 'doc-type',
            message: data?.docType?.message,
          },
        ]
      }
    }

    // redirect-test
    if (data?.redirectTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.redirectTest?.status === 2) {
      warningCounter++
      warningTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.redirectTest?.status,
            id: 'redirect-test',
            message: data?.redirectTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.redirectTest?.[0]?.testResults?.status,
            id: 'redirect-test',
            message: data?.redirectTest?.message,
          },
        ]
      }
    }

    // faviconCheckerTest
    if (data?.faviconCheckerTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.faviconCheckerTest?.status === 2) {
      warningCounter++
      warningTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.faviconCheckerTest?.status,
            id: 'favicon-test',
            message: data?.faviconCheckerTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.faviconCheckerTest?.status,
            id: 'favicon-test',
            message: data?.faviconCheckerTest?.message,
          },
        ]
      }
    }

    // flash-test
    if (data?.flashTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('flash-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.flashTest?.status === 2) {
      warningCounter++
      warningTestArray.push('flash-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.flashTest?.status,
            id: 'flash-test',
            message: data?.flashTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('flash-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.flashTest?.status,
            id: 'flash-test',
            message: data?.flashTest?.message,
          },
        ]
      }
    }

    // html-page-size
    if (data?.htmlPageSize?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.htmlPageSize?.status === 2) {
      warningCounter++
      warningTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlPageSize?.status,
            id: 'html-page-size',
            message: data?.htmlPageSize?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlPageSize?.status,
            id: 'fhtml-page-size',
            message: data?.htmlPageSize?.message,
          },
        ]
      }
    }

    // framesetTest
    if (data?.framesetTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.framesetTest?.status === 2) {
      warningCounter++
      warningTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.framesetTest?.status,
            id: 'frameset-test',
            message: data?.framesetTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.framesetTest?.status,
            id: 'frameset-test',
            message: data?.framesetTest?.message,
          },
        ]
      }
    }

    // googleAnalytics
    if (data?.googleAnalytics?.status === 1) {
      positiveCounter++
      passedTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.googleAnalytics?.status === 2) {
      warningCounter++
      warningTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.googleAnalytics?.status,
            id: 'google-analytics',
            message: data?.googleAnalytics?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.googleAnalytics?.status,
            id: 'google-analytics',
            message: data?.googleAnalytics?.message,
          },
        ]
      }
    }

    // htmlCompression
    if (data?.htmlCompression?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-compression')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.htmlCompression?.status === 2) {
      warningCounter++
      warningTestArray.push('html-compression')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlCompression?.status,
            id: 'html-compression',
            message: data?.htmlCompression?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('html-compression')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlCompression?.status,
            id: 'html-compression',
            message: data?.htmlCompression?.message,
          },
        ]
      }
    }

    // hsts
    if (data?.hstsTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('hsts')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.hstsTest?.status === 2) {
      warningCounter++
      warningTestArray.push('hsts')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.hstsTest?.status,
            id: 'hsts',
            message: data?.hstsTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('hsts')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.hstsTest?.status,
            id: 'hsts',
            message: data?.hstsTest?.message,
          },
        ]
      }
    }

    // htmlValidation
    if (data?.htmlValidation?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.htmlValidation?.status === 2) {
      warningCounter++
      warningTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlValidation?.status,
            id: 'html-validation',
            message: data?.htmlValidation?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlValidation?.status,
            id: 'html-validation',
            message: data?.htmlValidation?.message,
          },
        ]
      }
    }

    // https-test
    if (data?.httpsTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('https-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.httpsTest?.status === 2) {
      warningCounter++
      warningTestArray.push('https-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.httpsTest?.status,
            id: 'https-test',
            message: data?.httpsTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('https-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.httpsTest?.status,
            id: 'https-test',
            message: data?.httpsTest?.message,
          },
        ]
      }
    }

    // inline-css
    if (data?.inlineCss?.status === 1) {
      positiveCounter++
      passedTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.inlineCss?.status === 2) {
      warningCounter++
      warningTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.inlineCss?.status,
            id: 'inline-css',
            message: data?.inlineCss?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.inlineCss?.status,
            id: 'inline-css',
            message: data?.inlineCss?.message,
          },
        ]
      }
    }

    // js-error
    if (data?.jsError?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-error')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.jsError?.status === 2) {
      warningCounter++
      warningTestArray.push('js-error')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsError?.status,
            id: 'js-error',
            message: data?.jsError?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-error')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsError?.status,
            id: 'js-error',
            message: data?.jsError?.message,
          },
        ]
      }
    }

    // microdata-schema
    if (data?.microSchemaTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.microSchemaTest?.status === 2) {
      warningCounter++
      warningTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microSchemaTest?.status,
            id: 'microdata-schema',
            message: data?.microSchemaTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microSchemaTest?.status,
            id: 'microdata-schema',
            message: data?.microSchemaTest?.message,
          },
        ]
      }
    }

    // nested-table
    if (data?.nestedTable?.status === 1) {
      positiveCounter++
      passedTestArray.push('nested-table')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.nestedTable?.status === 2) {
      warningCounter++
      warningTestArray.push('nested-table')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.nestedTable?.status,
            id: 'nested-table',
            message: data?.nestedTable?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('nested-table')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.nestedTable?.status,
            id: 'nested-table',
            message: data?.nestedTable?.message,
          },
        ]
      }
    }

    // no-index
    if (data?.noIndexChecker?.status === 1) {
      positiveCounter++
      passedTestArray.push('no-index')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.noIndexChecker?.status === 2) {
      warningCounter++
      warningTestArray.push('no-index')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.noIndexChecker?.status,
            id: 'no-index',
            message: data?.noIndexChecker?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('no-index')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.noIndexChecker?.status,
            id: 'no-index',
            message: data?.noIndexChecker?.message,
          },
        ]
      }
    }

    // canonical-url
    if (data?.canonicalUrl?.status === 1) {
      positiveCounter++
      passedTestArray.push('canonical-url')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.canonicalUrl?.status === 2) {
      warningCounter++
      warningTestArray.push('canonical-url')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.canonicalUrl?.status,
            id: 'canonical-url',
            message: data?.canonicalUrl?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('canonical-url')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.canonicalUrl?.status,
            id: 'canonical-url',
            message: data?.canonicalUrl?.message,
          },
        ]
      }
    }

    // page-cache
    if (data?.pageCache?.status === 1) {
      positiveCounter++
      passedTestArray.push('page-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.pageCache?.status === 2) {
      warningCounter++
      warningTestArray.push('page-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageCache?.status,
            id: 'page-cache',
            message: data?.pageCache?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('page-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageCache?.status,
            id: 'page-cache',
            message: data?.pageCache?.message,
          },
        ]
      }
    }

    // page-object
    if (data?.pageObject?.status === 1) {
      positiveCounter++
      passedTestArray.push('page-object')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.pageObject?.status === 2) {
      warningCounter++
      warningTestArray.push('page-object')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageObject?.status,
            id: 'page-object',
            message: data?.pageObject?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('page-object')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageObject?.status,
            id: 'page-object',
            message: data?.pageObject?.message,
          },
        ]
      }
    }

    // plain-text
    if (data?.plainTextEmail?.status === 1) {
      positiveCounter++
      passedTestArray.push('plain-text')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.plainTextEmail?.status === 2) {
      warningCounter++
      warningTestArray.push('plain-text')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.plainTextEmail?.status,
            id: 'plain-text',
            message: data?.plainTextEmail?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('plain-text')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.plainTextEmail?.status,
            id: 'plain-text',
            message: data?.plainTextEmail?.message,
          },
        ]
      }
    }

    // safe-browsing
    if (data?.safeBrowsing?.status === 1) {
      positiveCounter++
      passedTestArray.push('safe-browsing')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.safeBrowsing?.status === 2) {
      warningCounter++
      warningTestArray.push('safe-browsing')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.safeBrowsing?.status,
            id: 'safe-browsing',
            message: data?.safeBrowsing?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('safe-browsing')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.safeBrowsing?.status,
            id: 'safe-browsing',
            message: data?.safeBrowsing?.message,
          },
        ]
      }
    }

    // serverSignature
    if (data?.serverSignature?.status === 1) {
      positiveCounter++
      passedTestArray.push('server-signature')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.serverSignature?.status === 2) {
      warningCounter++
      warningTestArray.push('server-signature')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.serverSignature?.status,
            id: 'server-signature',
            message: data?.serverSignature?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('server-signature')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.serverSignature?.status,
            id: 'server-signature',
            message: data?.serverSignature?.message,
          },
        ]
      }
    }

    // site-speed
    if (data?.siteSpeed?.status === 1) {
      positiveCounter++
      passedTestArray.push('site-speed')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.siteSpeed?.status === 2) {
      warningCounter++
      warningTestArray.push('site-speed')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteSpeed?.status,
            id: 'site-speed',
            message: data?.siteSpeed?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('site-speed')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteSpeed?.status,
            id: 'site-speed',
            message: data?.siteSpeed?.message,
          },
        ]
      }
    }

    // social-media
    if (data?.socialMediaTwitterData?.status === 1) {
      positiveCounter++
      passedTestArray.push('social-media')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.socialMediaTwitterData?.status === 2) {
      warningCounter++
      warningTestArray.push('social-media')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.socialMediaTwitterData?.status,
            id: 'social-media',
            message: data?.socialMediaTwitterData?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('social-media')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.socialMediaTwitterData?.status,
            id: 'social-media',
            message: data?.socialMediaTwitterData?.message,
          },
        ]
      }
    }

    // spf-record
    if (data?.spfRecord?.status === 1) {
      positiveCounter++
      passedTestArray.push('spf-record')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.spfRecord?.status === 2) {
      warningCounter++
      warningTestArray.push('spf-record')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.spfRecord?.status,
            id: 'spf-record',
            message: data?.spfRecord?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('spf-record')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.spfRecord?.status,
            id: 'spf-record',
            message: data?.spfRecord?.message,
          },
        ]
      }
    }

    // url-canonicalization
    if (data?.urlCanonicalization?.status === 1) {
      positiveCounter++
      passedTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.urlCanonicalization?.status === 2) {
      warningCounter++
      warningTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.urlCanonicalization?.status,
            id: 'url-canonicalization',
            message: data?.urlCanonicalization?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.urlCanonicalization?.status,
            id: 'url-canonicalization',
            message: data?.urlCanonicalization?.message,
          },
        ]
      }
    }

    // brotil-test
    if (data?.brotilTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('brotil-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.brotilTest?.status === 2) {
      warningCounter++
      warningTestArray.push('brotil-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brotilTest?.status,
            id: 'brotil-test',
            message: data?.brotilTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('brotil-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brotilTest?.status,
            id: 'brotil-test',
            message: data?.brotilTest?.message,
          },
        ]
      }
    }

    // http2-test
    if (data?.http2Test?.status === 1) {
      positiveCounter++
      passedTestArray.push('http2-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.http2Test?.status === 2) {
      warningCounter++
      warningTestArray.push('http2-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.http2Test?.status,
            id: 'http2-test',
            message: data?.http2Test?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('http2-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.http2Test?.status,
            id: 'http2-test',
            message: data?.http2Test?.message,
          },
        ]
      }
    }

    // ssl-freak
    if (data?.sslFreakTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('ssl-freak')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.sslFreakTest?.status === 2) {
      warningCounter++
      warningTestArray.push('ssl-freak')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.sslFreakTest?.status,
            id: 'ssl-freak',
            message: data?.sslFreakTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('ssl-freak')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.sslFreakTest?.status,
            id: 'ssl-freak',
            message: data?.sslFreakTest?.message,
          },
        ]
      }
    }

    // tls-logjam
    if (data?.tlsLogjamTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('tls-logjam')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.tlsLogjamTest?.status === 2) {
      warningCounter++
      warningTestArray.push('tls-logjam')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tlsLogjamTest?.status,
            id: 'tls-logjam',
            message: data?.tlsLogjamTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('tls-logjam')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tlsLogjamTest?.status,
            id: 'tls-logjam',
            message: data?.tlsLogjamTest?.message,
          },
        ]
      }
    }

    // render-blocking
    if (data?.renderBlocking?.status === 1) {
      positiveCounter++
      passedTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.renderBlocking?.status === 2) {
      warningCounter++
      warningTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.renderBlocking?.status,
            id: 'render-blocking',
            message: data?.renderBlocking?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.renderBlocking?.status,
            id: 'render-blocking',
            message: data?.renderBlocking?.message,
          },
        ]
      }
    }

    // unsafe-cross-origin-links
    if (data?.unsafeCrossOriginLinksTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.unsafeCrossOriginLinksTest?.status === 2) {
      warningCounter++
      warningTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.unsafeCrossOriginLinksTest?.status,
            id: 'unsafe-cross-origin-links',
            message: data?.unsafeCrossOriginLinksTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.unsafeCrossOriginLinksTest?.status,
            id: 'unsafe-cross-origin-links',
            message: data?.unsafeCrossOriginLinksTest?.message,
          },
        ]
      }
    }

    // morden-image
    if (data?.mordenImage?.status === 1) {
      positiveCounter++
      passedTestArray.push('morden-image')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.mordenImage?.status === 2) {
      warningCounter++
      warningTestArray.push('morden-image')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.mordenImage?.status,
            id: 'morden-image',
            message: data?.mordenImage?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('morden-image')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.mordenImage?.status,
            id: 'morden-image',
            message: data?.mordenImage?.message,
          },
        ]
      }
    }

    // aspect-ratio
    if (data?.aspectRatio?.status === 1) {
      positiveCounter++
      passedTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.aspectRatio?.status === 2) {
      warningCounter++
      warningTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.aspectRatio?.status,
            id: 'aspect-ratio',
            message: data?.aspectRatio?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.aspectRatio?.status,
            id: 'aspect-ratio',
            message: data?.aspectRatio?.message,
          },
        ]
      }
    }

    // responsive-image
    if (data?.responsiveImage?.status === 1) {
      positiveCounter++
      passedTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.responsiveImage?.status === 2) {
      warningCounter++
      warningTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.responsiveImage?.status,
            id: 'responsive-image',
            message: data?.responsiveImage?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.responsiveImage?.status,
            id: 'responsive-image',
            message: data?.responsiveImage?.message,
          },
        ]
      }
    }

    // console errors
    if (data?.consoleErrors?.status === 1) {
      positiveCounter++
      passedTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.consoleErrors?.status === 2) {
      warningCounter++
      warningTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.consoleErrors?.status,
            id: 'console-errors',
            message: data?.consoleErrors?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.consoleErrors?.status,
            id: 'console-errors',
            message: data?.consoleErrors?.message,
          },
        ]
      }
    }

    // advance console errors
    if (data?.advancedConsoleErrors?.status === 1) {
      positiveCounter++
      passedTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.advancedConsoleErrors?.status === 2) {
      warningCounter++
      warningTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.advancedConsoleErrors?.status,
            id: 'advanced-console-errors',
            message: data?.advancedConsoleErrors?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.advancedConsoleErrors?.status,
            id: 'advanced-console-errors',
            message: data?.advancedConsoleErrors?.message,
          },
        ]
      }
    }

    //  dom-size
    if (data?.domSize?.status === 1) {
      positiveCounter++
      passedTestArray.push('dom-size')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.domSize?.status === 2) {
      warningCounter++
      warningTestArray.push('dom-size')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domSize?.status,
            id: 'dom-size',
            message: data?.domSize?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('dom-size')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domSize?.status,
            id: 'dom-size',
            message: data?.domSize?.message,
          },
        ]
      }
    }

    // ads-txt
    if (data?.adsTxt?.status === 1) {
      positiveCounter++
      passedTestArray.push('ads-txt')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.adsTxt?.status === 2) {
      warningCounter++
      warningTestArray.push('ads-txt')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.adsTxt?.status,
            id: 'ads-txt',
            message: data?.adsTxt?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('ads-txt')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.adsTxt?.status,
            id: 'ads-txt',
            message: data?.adsTxt?.message,
          },
        ]
      }
    }

    // js-execution-time
    if (data?.bootupTime?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.bootupTime?.status === 2) {
      warningCounter++
      warningTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.bootupTime?.status,
            id: 'js-execution-time',
            message: data?.bootupTime?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.bootupTime?.status,
            id: 'js-execution-time',
            message: data?.bootupTime?.message,
          },
        ]
      }
    }

    // characterSet
    if (data?.characterSet?.status === 1) {
      positiveCounter++
      passedTestArray.push('character-set')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.characterSet?.status === 2) {
      warningCounter++
      warningTestArray.push('character-set')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.characterSet?.status,
            id: 'character-set',
            message: data?.characterSet?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('character-set')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.characterSet?.status,
            id: 'character-set',
            message: data?.characterSet?.message,
          },
        ]
      }
    }

    // viewport
    if (data?.viewport?.status === 1) {
      positiveCounter++
      passedTestArray.push('viewport')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.viewport?.status === 2) {
      warningCounter++
      warningTestArray.push('viewport')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.viewport?.status,
            id: 'viewport',
            message: data?.viewport?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('viewport')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.viewport?.status,
            id: 'viewport',
            message: data?.viewport?.message,
          },
        ]
      }
    }

    // font-size
    if (data?.fontSizeTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('font-size')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.fontSizeTest?.status === 2) {
      warningCounter++
      warningTestArray.push('font-size')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.fontSizeTest?.status,
            id: 'font-size',
            message: data?.fontSizeTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('font-size')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.fontSizeTest?.status,
            id: 'font-size',
            message: data?.fontSizeTest?.message,
          },
        ]
      }
    }

    // tapTargetTest
    if (data?.tapTargetTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.tapTargetTest?.status === 2) {
      warningCounter++
      warningTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tapTargetTest?.status,
            id: 'tap-target',
            message: data?.tapTargetTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tapTargetTest?.status,
            id: 'tap-target',
            message: data?.tapTargetTest?.message,
          },
        ]
      }
    }

    // contentWidthTest
    if (data?.contentWidthTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('content-width')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.contentWidthTest?.status === 2) {
      warningCounter++
      warningTestArray.push('content-width')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentWidthTest?.status,
            id: 'content-width',
            message: data?.contentWidthTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('content-width')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentWidthTest?.status,
            id: 'content-width',
            message: data?.contentWidthTest?.message,
          },
        ]
      }
    }

    // speedIndexTest
     if (data?.speedIndexTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.speedIndexTest?.status === 2) {
      warningCounter++
      warningTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.speedIndexTest?.status,
            id: 'speed-index',
            message: data?.speedIndexTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.speedIndexTest?.status,
            id: 'speed-index',
            message: data?.speedIndexTest?.message,
          },
        ]
      }
    }

    // firstContentfulPaintTest
    if (data?.firstContentfulPaintTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.firstContentfulPaintTest?.status === 2) {
      warningCounter++
      warningTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstContentfulPaintTest?.status,
            id: 'first-contentful-paint',
            message: data?.firstContentfulPaintTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstContentfulPaintTest?.status,
            id: 'first-contentful-paint',
            message: data?.firstContentfulPaintTest?.message,
          },
        ]
      }
    }

    // largestContentfulPaintTest
    if (data?.largestContentfulPaintTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.largestContentfulPaintTest?.status === 2) {
      warningCounter++
      warningTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.largestContentfulPaintTest?.status,
            id: 'last-contentful-paint',
            message: data?.largestContentfulPaintTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.largestContentfulPaintTest?.status,
            id: 'last-contentful-paint',
            message: data?.largestContentfulPaintTest?.message,
          },
        ]
      }
    }

    // cumulativeLayoutShiftTest
    if (data?.cumulativeLayoutShiftTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.cumulativeLayoutShiftTest?.status === 2) {
      warningCounter++
      warningTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cumulativeLayoutShiftTest?.status,
            id: 'cumulative-layout-shift-test',
            message: data?.cumulativeLayoutShiftTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cumulativeLayoutShiftTest?.status,
            id: 'cumulative-layout-shift-test',
            message: data?.cumulativeLayoutShiftTest?.message,
          },
        ]
      }
    }

    // interactiveTest
    if (data?.interactiveTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.interactiveTest?.status === 2) {
      warningCounter++
      warningTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.interactiveTest?.status,
            id: 'interactive-test',
            message: data?.interactiveTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.interactiveTest?.status,
            id: 'interactive-test',
            message: data?.interactiveTest?.message,
          },
        ]
      }
    }

    // firstInputDelayTest
    if (data?.firstInputDelayTest?.status === 1) {
      positiveCounter++
      passedTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.firstInputDelayTest?.status === 2) {
      warningCounter++
      warningTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstInputDelayTest?.status,
            id: 'first-input-delay',
            message: data?.firstInputDelayTest?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstInputDelayTest?.status,
            id: 'first-input-delay',
            message: data?.firstInputDelayTest?.message,
          },
        ]
      }
    }

    // meta-refresh
    if (data?.metaRefresh?.status === 1) {
      positiveCounter++
      passedTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.metaRefresh?.status === 2) {
      warningCounter++
      warningTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaRefresh?.status,
            id: 'meta-refresh',
            message: data?.metaRefresh?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaRefresh?.status,
            id: 'meta-refresh',
            message: data?.metaRefresh?.message,
          },
        ]
      }
    }

    // custom-404
    if (data?.custom404?.status === 1) {
      positiveCounter++
      passedTestArray.push('custom-404')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.custom404?.status === 2) {
      warningCounter++
      warningTestArray.push('custom-404')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.custom404?.status,
            id: 'custom-404',
            message: data?.custom404?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('custom-404')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.custom404?.status,
            id: 'custom-404',
            message: data?.custom404?.message,
          },
        ]
      }
    }

    // layout-shift
    if (data?.layoutShift?.status === 1) {
      positiveCounter++
      passedTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.layoutShift?.status === 2) {
      warningCounter++
      warningTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.layoutShift?.status,
            id: 'layout-shift',
            message: data?.layoutShift?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.layoutShift?.status,
            id: 'layout-shift',
            message: data?.layoutShift?.message,
          },
        ]
      }
    }

    // contentful-paint
    if (data?.contentfulPaint?.status === 1) {
      positiveCounter++
      passedTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.contentfulPaint?.status === 2) {
      warningCounter++
      warningTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentfulPaint?.status,
            id: 'contentful-paint',
            message: data?.contentfulPaint?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentfulPaint?.status,
            id: 'contentful-paint',
            message: data?.contentfulPaint?.message,
          },
        ]
      }
    }

    // directory-browsing
    if (data?.directoryBrowsing?.status === 1) {
      positiveCounter++
      passedTestArray.push('directory-browsing')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.directoryBrowsing?.status === 2) {
      warningCounter++
      warningTestArray.push('directory-browsing')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.directoryBrowsing?.status,
            id: 'directory-browsing',
            message: data?.directoryBrowsing?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('directory-browsing')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.directoryBrowsing?.status,
            id: 'directory-browsing',
            message: data?.directoryBrowsing?.message,
          },
        ]
      }
    }

    // descriptive-test
    if (data?.descriptiveLinkTest
      ?.status === 1) {
      positiveCounter++
      passedTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.descriptiveLinkTest
      ?.status === 2) {
      warningCounter++
      warningTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.descriptiveLinkTest
              ?.status,
            id: 'descriptive-test',
            message: data?.descriptiveLinkTest
              ?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.descriptiveLinkTest
              ?.status,
            id: 'descriptive-test',
            message: data?.descriptiveLinkTest
              ?.message,
          },
        ]
      }
    }

    return {
      updatedErrorsArray,
      positiveCounter,
      warningCounter,
      negativeCounter,
      commonSeo,
      commonSeoS,
      commonSeoW,
      commonSeoF,
      speedOptS,
      speedOptW,
      speedOptF,
      serverS,
      serverW,
      serverF,
      mobileUseS,
      mobileUseW,
      mobileUseF,
      advanceS,
      advanceW,
      advanceF,
    }
  } else {
    return {}
  }
}

export const getData = (data, isUrlData = false) => {
  let updatedErrorsArray = []
  let positiveCounter = 0,
    negativeCounter = 0,
    commonSeo = 0,
    commonSeoS = 0,
    commonSeoW = 0,
    commonSeoF = 0,
    speedOpt = 0,
    server = 0,
    mobileUse = 0,
    advance = 0
  if (data?.microDataSchema?.data?.data?.meta?.title) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    if (isUrlData) {
      commonSeoF++
    }
  }
  if (data?.microDataSchema?.data?.data?.meta?.['meta-description']) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'meta-description',
        message: `The meta description tag is missing from your page. You should include this tag in order to provide a brief description of your page which can be used by search engines. Well-written and inviting meta descriptions may also help click-through rates to your site in search engine results.`,
      },
    ]
  }
  if (data?.microDataSchema?.data?.data?.meta?.title) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 3, id: 'meta-title', message: `The meta title tag is missing from your page. ` },
    ]
  }
  if (data?.keywordCloudTest?.data?.commonKeywords?.length) {
    positiveCounter++
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
      commonSeoS++
    } else {
      commonSeo++
      commonSeo++
    }
  } else {
    negativeCounter++
  }
  if (data && data?.canonicalUrl?.data) {
    positiveCounter++
    advance++
  } else {
    negativeCounter++
  }
  if (data?.cdnUsageData?.data?.serveWithoutCDN?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 2, id: 'cdn-usage', message: `Some of your webpage's resources is not serving using CDN.` },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }
  if (data?.cssAnalysis?.data?.warnings?.length || data?.cssAnalysis?.data?.errors?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 1,
        id: 'css-analysis',
        message: `Some of your webpage's CSS resources is having errors/warning/issues.`,
      },
    ]
  } else {
    positiveCounter++
  }
  if (data?.cssCache?.data?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'css-cache',
        message: `Your website is not using cache headers for your CSS resources. Setting cache headers can help speed up the serving of your webpages for users that regularly visit your site.`,
      },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }
  if (data?.cssMinification?.data?.nonMinifiedCss?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'css-minification',
        message: `Some of your webpage's CSS resources is not minified.`,
      },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }
  if (data?.disallowDirectiveChecker?.data?.status) {
    positiveCounter++
    advance++
  } else {
    negativeCounter++
  }
  if (data?.docType?.data?.element?.length > 3) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 2, id: 'doc-type', message: `Your webpage does have doctype.` },
    ]
  }
  if (data?.faviconCheckerTest?.data?.status === 1) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 2, id: 'favicon-test', message: `Your webpage does have favicon.` },
    ]
  }
  if (data?.flashTest?.data?.flash) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 1,
        id: 'flash-test',
        message: `Your webpage does contain flash elements. We advice to remove that.`,
      },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }
  if (data && data?.framesetTest?.data?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 1, id: 'frameset-test', message: `Your webpage does contain frame tag.` },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }

  if (data?.googleAnalytics?.data?.data) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'google-analytics',
        message: `A Google Analytics script is not detected on this page. While there are several tools available to monitor your site's visitors and traffic sources, Google Analytics is a free, commonly recommended program to help diagnose potential SEO issues.`,
      },
    ]
  }
  if (data?.headingAnalysis?.data?.headingOrder?.list) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'heading-analysis',
        message: `Your webpage does not contain any H1 headings. H1 headings help indicate the important topics of your page to search engines. While less important than good meta-titles and descriptions, H1 headings may still help define the topic of your page to search engines.`,
      },
    ]
  }

  if (data?.htmlCompression?.data?.result?.status) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
  }
  if (data && data?.htmlPageSize?.status) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 3, id: 'html-page', message: data?.htmlPageSize?.data?.message },
    ]
  }

  if (data?.htmlValidation?.data?.errors?.length || data?.htmlValidation?.data?.warnings?.length) {
    negativeCounter++
    if (isUrlData) {
      commonSeoF++
    }
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 1, id: 'html-validation', message: `Some of your webpage's HTML code is having errors.` },
    ]
  } else {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  }
  if (data?.httpsTest?.data?.title) {
    positiveCounter++
    server++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'https-test',
        message: `This webpage is using mixed content! While the initial HTML is loaded over a secure HTTPS connection, other resources (such as images, videos, stylesheets, scripts) may be loaded over an insecure HTTP connection, which may result in blocked content or unexpected page behavior.`,
      },
    ]
  }
  if (data?.imageAlt?.data?.imgAlt?.length) {
    negativeCounter++
    if (isUrlData) {
      commonSeoW++
    }
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'image-alt',
        message: `Your webpage is using "img" tags with empty or missing "alt" attribute.`,
      },
    ]
  } else {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  }

  if (data?.imageCache?.data?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'image-cache',
        message: `Your website is not using cache headers for your images. Setting cache headers can help speed up the serving of your webpages for users that regularly visit your site and see the same images. Learn more about how to add expires headers to your images.`,
      },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }

  // cachePolicy
  if (data?.cachePolicy?.[0]?.data?.testResults?.status === 3) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: data?.cachePolicy?.[0]?.data?.testResults?.status,
        id: 'cache-policy',
        message: data?.cachePolicy?.[0]?.data?.testResults?.message,
      },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }

  if (data?.jsMinification?.data?.nonMinifiedJs?.length) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'js-minification',
        message: `Some of your webpage's JS resources are not minified.`,
      },
    ]
  } else {
    positiveCounter++
    speedOpt++
  }
  if (data?.inlineCss?.data?.data?.length) {
    negativeCounter++
    if (isUrlData) {
      commonSeoF++
    }
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 3, id: 'inline-css', message: ` Your webpage is using inline CSS styles!` },
    ]
  } else {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  }
  if (data?.jsCache?.data?.length) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'js-cache',
        message: `Your website is not using cache headers for your JavaScript resources. Setting cache headers can help speed up the serving of your webpages for users that regularly visit your site.`,
      },
    ]
  }
  if (data?.jsError?.data?.warnings?.length || data?.jsError?.data?.serverErrors?.length) {
    negativeCounter++
    if (isUrlData) {
      commonSeoF++
    }
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 1, id: 'js-error', message: `Some of your webpage's JS resources is having errors.` },
    ]
  } else {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  }
  if (data?.keywordCloudTest?.data?.keywordCloud) {
    positiveCounter++
  } else {
    negativeCounter++
  }
  // if (data?.mediaQuery?.data?.res_issues && data?.mediaQuery?.data?.res_issues?.length) {
  //   negativeCounter++
  // } else {
  //   positiveCounter++
  //   mobileUse++
  //   mobileUse++
  // }

  if (data?.microDataSchema?.data?.data?.meta?.title) {
    positiveCounter++
    advance++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 1,
        id: 'microdata-schema',
        message: `Your webpage doesn't take the advantages of HTML Microdata specifications in order to markup structured data. View Google's guide for getting started with microdata.`,
      },
    ]
  }

  if (data?.nestedTable?.data?.status) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 1, id: 'nested-table', message: `Your webpage has nested table structure` },
    ]
  }

  if (data?.noFollow?.data?.noFollowUrl?.list?.length) {
    positiveCounter++
    advance++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 1,
        id: 'no-follow',
        message: `Your webpage uses the noindex meta tag. This means that your webpage will be read but not indexed by search engines.`,
      },
    ]
  }

  if (data?.noIndexChecker?.data?.status) {
    positiveCounter++
    advance++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 1,
        id: 'no-index',
        message: `Your webpage uses the noindex meta tag. This means that your webpage will be read but not indexed by search engines.`,
      },
    ]
  }
  if (data?.pageCache?.data?.status) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'page-cache',
        message: `It does not appear that you are caching your pages. Cached pages serve up static html and avoid potentially time consuming queries to your database. It also helps lower server load by up to 80%. Caching most visibly benefits high traffic pages that access a database, but whose content does not change on every page view. Common caching methods include Alternative PHP Cache, Quickcache, and WP Super Cache (for Wordpress sites). Caching mechanisms also typically compress HTML, further reducing page size and load time.`,
      },
    ]
  }
  if (data?.pageObject?.data?.pageObject?.length) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'page-object',
        message: `The Document Object Model (DOM) of this webpage has 3,433 nodes which is greater than the recommended value of 1,500 nodes. A large DOM size negatively affects site performance and increases the page load time.`,
      },
    ]
  }
  if (data?.plainTextEmail?.data?.email) {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'plain-text',
        message: `We've found 1 email addresses in your page code. We advise you to protect email links in a way that hides them from the spam harvesters.`,
      },
    ]
  } else {
    positiveCounter++
    server++
  }
  if (data && data?.redirectTest) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'redirect-test',
        message: `Your website is not connected with social media using the API's provided by Facebook, Google +, Twitter, Pinterest, or using addthis.com`,
      },
    ]
  }
  if (data && data?.safeBrowsing?.data?.status) {
    positiveCounter++
    server++
  } else {
    negativeCounter++
  }
  if (data?.serverSignature?.data?.status) {
    positiveCounter++
    server++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 2,
        id: 'server-signature',
        message: `Your server signature is on. Turning off your server signature is generally a good idea from a security standpoint. Read more on how to turn off server signature and improve your website's security.`,
      },
    ]
  }
  if (data && data?.siteSpeed?.data?.status === 1) {
    positiveCounter++
    speedOpt++
  } else {
    negativeCounter++

    updatedErrorsArray = [
      ...updatedErrorsArray,
      { priority: 3, id: 'site-speed', message: data?.siteSpeed?.data?.message },
    ]
  }
  if (data?.socialMediaTwitterData?.data?.twitterData?.length) {
    positiveCounter++
    if (isUrlData) {
      commonSeoS++
    } else {
      commonSeo++
    }
  } else {
    negativeCounter++
    if (isUrlData) {
      commonSeoF++
    }
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'social-media',
        message: `Your website is not connected with social media using the API's provided by Facebook, Google +, Twitter, Pinterest, or using addthis.com`,
      },
    ]
  }
  if (data?.spfRecord?.data?.spfData?.result?.isSpf) {
    positiveCounter++
    advance++
  } else {
    negativeCounter++
  }
  if (data?.urlCanonicalization?.data?.res_status) {
    positiveCounter++
    server++
  } else {
    negativeCounter++
    updatedErrorsArray = [
      ...updatedErrorsArray,
      {
        priority: 3,
        id: 'url-canonicalization',
        message: `${data?.urlCanonicalization?.data?.urls[0]} and ${data?.urlCanonicalization?.data?.urls[1]} should resolve to the same URL, but currently do not.`,
      },
    ]
  }
  return {
    updatedErrorsArray,
    positiveCounter,
    negativeCounter,
    commonSeo,
    commonSeoS,
    commonSeoW,
    commonSeoF,
    speedOpt,
    server,
    mobileUse,
    advance,
  }
}

export const getDataForReport = (data, isUrlData = true) => {
  let updatedErrorsArray = []
  let passedTestArray = []
  let failedTestArray = []
  let warningTestArray = []
  let positiveCounter = 0,
    warningCounter = 0,
    negativeCounter = 0,
    commonSeo = 0,
    commonSeoS = 0,
    commonSeoW = 0,
    commonSeoF = 0,
    speedOptS = 0,
    speedOptW = 0,
    speedOptF = 0,
    serverS = 0,
    serverW = 0,
    serverF = 0,
    mobileUseS = 0,
    mobileUseW = 0,
    mobileUseF = 0,
    advanceS = 0,
    advanceW = 0,
    advanceF = 0
  // if (data?.microDataSchema?.data?.data?.meta?.title) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         commonSeoS++;
  //     } else {
  //         commonSeo++;
  //     }
  // } else {
  //     negativeCounter++
  //     if (isUrlData) {
  //         commonSeoF++;
  //     }
  // }

  if (data?.information?.seoCheckup?.description?.descriptionStatus === 1) {
    positiveCounter++
    passedTestArray.push('meta-description')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.information?.seoCheckup?.description?.descriptionStatus === 2) {
    warningCounter++
    warningTestArray.push('meta-description')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.information?.seoCheckup?.description?.descriptionStatus,
          id: 'meta-description',
          message: data?.information?.seoCheckup?.description?.descriptionMessage,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('meta-description')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.information?.seoCheckup?.description?.descriptionStatus,
          id: 'meta-description',
          message: data?.information?.seoCheckup?.description?.descriptionMessage,
        },
      ]
    }
  }

  if (data?.information?.seoCheckup?.title?.titleStatus === 1) {
    positiveCounter++
    passedTestArray.push('meta-title')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.information?.seoCheckup?.title?.titleStatus === 2) {
    warningCounter++
    warningTestArray.push('meta-title')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.information?.seoCheckup?.title?.titleStatus,
          id: 'meta-title',
          message: data?.information?.seoCheckup?.title?.titleMessage,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('meta-title')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.information?.seoCheckup?.title?.titleStatus,
          id: 'meta-title',
          message: data?.information?.seoCheckup?.title?.titleMessage,
        },
      ]
    }
  }

  //social media Test
  if (data?.microDataSchema?.data?.metaTagsResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('social-media-meta-tag')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.microDataSchema?.data?.metaTagsResults?.status === 2) {
    warningCounter++
    warningTestArray.push('social-media-meta-tag')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.microDataSchema?.data?.metaTagsResults?.status,
          id: 'social-media-meta-tag',
          message: data?.microDataSchema?.data?.metaTagsResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('social-media-meta-tag')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.microDataSchema?.data?.metaTagsResults?.status,
          id: 'social-media-meta-tag',
          message: data?.microDataSchema?.data?.metaTagsResults?.message,
        },
      ]
    }
  }

  // most common
  // if (data?.keywordCloudTest?.data?.testResults?.status === 1) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         commonSeoS++;
  //     }
  // } else if(data?.keywordCloudTest?.data?.testResults?.status === 2) {
  //     warningCounter++;
  //     if (isUrlData) {
  //         commonSeoW++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.keywordCloudTest?.data?.testResults?.status, id: 'common-keywords', message: data?.keywordCloudTest?.data?.testResults?.message }];
  //     }
  // } else {
  //     negativeCounter++;
  //     if (isUrlData) {
  //         commonSeoF++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.keywordCloudTest?.data?.testResults?.status, id: 'common-keywords', message: data?.keywordCloudTest?.data?.testResults?.message }];
  //     }
  // }

  // keyword-usage-test
  if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('keyword-usage-test')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 2) {
    warningCounter++
    warningTestArray.push('keyword-usage-test')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
          id: 'keyword-usage-test',
          message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('keyword-usage-test')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
          id: 'keyword-usage-test',
          message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
        },
      ]
    }
  }

  // // related-keywords
  // if (data?.relatedKeywordsFinal?.data?.testResult?.status === 1) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         commonSeoS++;
  //     }
  // } else if(data?.relatedKeywordsFinal?.data?.testResult?.status === 2) {
  //     warningCounter++;
  //     if (isUrlData) {
  //         commonSeoW++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.relatedKeywordsFinal?.data?.testResult?.status, id: 'related-keywords', message: data?.relatedKeywordsFinal?.data?.testResult?.message }];
  //     }
  // } else {
  //     negativeCounter++;
  //     if (isUrlData) {
  //         commonSeoF++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.relatedKeywordsFinal?.data?.testResult?.status, id: 'related-keywords', message: data?.relatedKeywordsFinal?.data?.testResult?.message }];
  //     }
  // }

  // seo-friendly
  if (data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length === 0) {
    positiveCounter++
    passedTestArray.push('seo-friendly')
    if (isUrlData) {
      commonSeoS++
    }
  } else {
    negativeCounter++
    failedTestArray.push('seo-friendly')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        { priority: 3, id: 'seo-friendly', message: 'This webpage contains URLs that are not SEO friendly!' },
      ]
    }
  }

  // sitemaps
  if (data?.siteMapData?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('sitemap-data')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.siteMapData?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('sitemap-data')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.siteMapData?.data?.testResults?.status,
          id: 'sitemap-data',
          message: data?.siteMapData?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('sitemap-data')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.siteMapData?.data?.testResults?.status,
          id: 'sitemap-data',
          message: data?.siteMapData?.data?.testResults?.message,
        },
      ]
    }
  }

  // broken-links
  if (data?.brokenLinks?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('brokenLinks-data')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.brokenLinks?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('brokenLinks-data')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.brokenLinks?.data?.testResults?.status,
          id: 'brokenLinks-data',
          message: data?.brokenLinks?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('brokenLinks-data')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.brokenLinks?.data?.testResults?.status,
          id: 'broken-links',
          message: data?.brokenLinks?.data?.testResults?.message,
        },
      ]
    }
  }

  // robots
  if (data?.robotsTxtTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('robots-txt')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.robotsTxtTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('robots-txt')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.robotsTxtTest?.data?.testResults?.status,
          id: 'robots-txt',
          message: data?.robotsTxtTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('robots-txt')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.robotsTxtTest?.data?.testResults?.status,
          id: 'robots-txt',
          message: data?.robotsTxtTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // cdn-usage
  if (data?.cdnUsageData?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('cdn-usage')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.cdnUsageData?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('cdn-usage')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cdnUsageData?.data?.testResult?.status,
          id: 'cdn-usage',
          message: data?.cdnUsageData?.data?.testResult?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('cdn-usage')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cdnUsageData?.data?.testResult?.status,
          id: 'cdn-usage',
          message: data?.cdnUsageData?.data?.testResult?.message,
        },
      ]
    }
  }

  // cssAnalysis
  if (data?.cssAnalysis?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('css-analysis')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.cssAnalysis?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('css-analysis')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cssAnalysis?.data?.testResults?.status,
          id: 'css-analysis',
          message: data?.cssAnalysis?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('css-analysis')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cssAnalysis?.data?.testResults?.status,
          id: 'css-analysis',
          message: data?.cssAnalysis?.data?.testResults?.message,
        },
      ]
    }
  }

  // cssCache
  if (data?.cssCache?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('css-cache')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.cssCache?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('css-cache')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cssCache?.data?.testResults?.status,
          id: 'css-cache',
          message: data?.cssCache?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('css-cache')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cssCache?.data?.testResults?.status,
          id: 'css-cache',
          message: data?.cssCache?.data?.testResults?.message,
        },
      ]
    }
  }

  // cssMinification
  if (data?.cssMinification?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('css-minification')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.cssMinification?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('css-minification')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cssMinification?.data?.testResults?.status,
          id: 'css-minification',
          message: data?.cssMinification?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('css-minification')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cssMinification?.data?.testResults?.status,
          id: 'css-minification',
          message: data?.cssMinification?.data?.testResults?.message,
        },
      ]
    }
  }

  // jsMinification
  if (data?.jsMinification?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('js-minification')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.jsMinification?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('js-minification')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.jsMinification?.data?.testResults?.status,
          id: 'js-minification',
          message: data?.jsMinification?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('js-minification')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.jsMinification?.data?.testResults?.status,
          id: 'js-minification',
          message: data?.jsMinification?.data?.testResults?.message,
        },
      ]
    }
  }

  // jsCache
  if (data?.jsCache?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('js-cache')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.jsCache?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('js-cache')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.jsCache?.data?.testResults?.status,
          id: 'js-cache',
          message: data?.jsCache?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('js-cache')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.jsCache?.data?.testResults?.status,
          id: 'js-cache',
          message: data?.jsCache?.data?.testResults?.message,
        },
      ]
    }
  }

  // imageCache
  if (data?.imageCache?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('image-cache')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.imageCache?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('image-cache')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.imageCache?.data?.testResults?.status,
          id: 'image-cache',
          message: data?.imageCache?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('image-cache')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.imageCache?.data?.testResults?.status,
          id: 'image-cache',
          message: data?.imageCache?.data?.testResults?.message,
        },
      ]
    }
  }

  // imageAlt
  if (data?.imageAlt?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('image-alt')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.imageAlt?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('image-alt')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.imageAlt?.data?.testResults?.status,
          id: 'image-alt',
          message: data?.imageAlt?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('image-alt')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.imageAlt?.data?.testResults?.status,
          id: 'image-alt',
          message: data?.imageAlt?.data?.testResults?.message,
        },
      ]
    }
  }

  // headingAnalysis
  if (data?.headingAnalysis?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('heading-analysis')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.headingAnalysis?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('heading-analysis')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.headingAnalysis?.data?.testResults?.status,
          id: 'heading-analysis',
          message: data?.headingAnalysis?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('heading-analysis')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.headingAnalysis?.data?.testResults?.status,
          id: 'heading-analysis',
          message: data?.headingAnalysis?.data?.testResults?.message,
        },
      ]
    }
  }

  // domainCompetitors
  // if (data?.domainCompetitors?.data?.testResults?.status === 1) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         commonSeoS++;
  //     }
  // } else if (data?.domainCompetitors?.data?.testResults?.status === 2) {
  //     warningCounter++;
  //     if (isUrlData) {
  //         commonSeoW++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.domainCompetitors?.data?.testResults?.status, id: 'competitor', message: data?.domainCompetitors?.data?.testResults?.message }];
  //     }
  // } else {
  //     negativeCounter++;
  //     if (isUrlData) {
  //         commonSeoF++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.domainCompetitors?.data?.testResults?.status, id: 'competitor', message: data?.domainCompetitors?.data?.testResults?.message }];
  //     }
  // }

  //  // disallowDirectiveChecker
  //  if (data?.disallowDirectiveChecker?.data?.finalRes?.status === 1) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         commonSeoS++;
  //     }
  // } else if(data?.disallowDirectiveChecker?.data?.finalRes?.status === 2) {
  //     warningCounter++;
  //     if (isUrlData) {
  //         commonSeoW++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.disallowDirectiveChecker?.data?.finalRes?.status, id: 'disalllow-directive', message: data?.disallowDirectiveChecker?.data?.finalRes?.message }];
  //     }
  // } else {
  //     negativeCounter++;
  //     if (isUrlData) {
  //         commonSeoF++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.disallowDirectiveChecker?.data?.finalRes?.status, id: 'disalllow-directive', message: data?.disallowDirectiveChecker?.data?.finalRes?.message }];
  //     }
  // }

  // docType
  if (data?.docType?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('doc-type')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.docType?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('doc-type')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.docType?.data?.testResults?.status,
          id: 'doc-type',
          message: data?.docType?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('doc-type')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.docType?.data?.testResults?.status,
          id: 'doc-type',
          message: data?.docType?.data?.testResults?.message,
        },
      ]
    }
  }

  // redirect-test
  if (data?.redirectTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('redirect-test')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.redirectTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('redirect-test')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.redirectTest?.data?.testResults?.status,
          id: 'redirect-test',
          message: data?.redirectTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('redirect-test')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.redirectTest?.data?.testResults?.status,
          id: 'redirect-test',
          message: data?.redirectTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // faviconCheckerTest
  if (data?.faviconCheckerTest?.data?.status === 1) {
    positiveCounter++
    passedTestArray.push('favicon-test')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.faviconCheckerTest?.data?.status === 2) {
    warningCounter++
    warningTestArray.push('favicon-test')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.faviconCheckerTest?.data?.status,
          id: 'favicon-test',
          message: data?.faviconCheckerTest?.data?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('favicon-test')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.faviconCheckerTest?.data?.status,
          id: 'favicon-test',
          message: data?.faviconCheckerTest?.data?.message,
        },
      ]
    }
  }

  // flash-test
  if (data?.flashTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('flash-test')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.flashTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('flash-test')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.flashTest?.data?.testResults?.status,
          id: 'flash-test',
          message: data?.flashTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('flash-test')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.flashTest?.data?.testResults?.status,
          id: 'flash-test',
          message: data?.flashTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // html-page-size
  if (data?.htmlPageSize?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('html-page-size')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.htmlPageSize?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('html-page-size')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.htmlPageSize?.data?.testResults?.status,
          id: 'html-page-size',
          message: data?.htmlPageSize?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('html-page-size')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.htmlPageSize?.data?.testResults?.status,
          id: 'html-page-size',
          message: data?.htmlPageSize?.data?.testResults?.message,
        },
      ]
    }
  }

  // framesetTest
  if (data?.framesetTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('frameset-test')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.framesetTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('frameset-test')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.framesetTest?.data?.testResults?.status,
          id: 'frameset-test',
          message: data?.framesetTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('frameset-test')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.framesetTest?.data?.testResults?.status,
          id: 'frameset-test',
          message: data?.framesetTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // googleAnalytics
  if (data?.googleAnalytics?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('google-analytics')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.googleAnalytics?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('google-analytics')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.googleAnalytics?.data?.testResults?.status,
          id: 'google-analytics',
          message: data?.googleAnalytics?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('google-analytics')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.googleAnalytics?.data?.testResults?.status,
          id: 'google-analytics',
          message: data?.googleAnalytics?.data?.testResults?.message,
        },
      ]
    }
  }

  // htmlCompression
  if (data?.htmlCompression?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('html-compression')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.htmlCompression?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('html-compression')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.htmlCompression?.data?.testResults?.status,
          id: 'html-compression',
          message: data?.htmlCompression?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('html-compression')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.htmlCompression?.data?.testResults?.status,
          id: 'html-compression',
          message: data?.htmlCompression?.data?.testResults?.message,
        },
      ]
    }
  }

  // hsts
  if (data?.hstsTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('hsts')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.hstsTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('hsts')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.hstsTest?.data?.testResults?.status,
          id: 'hsts',
          message: data?.hstsTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('hsts')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.hstsTest?.data?.testResults?.status,
          id: 'hsts',
          message: data?.hstsTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // htmlValidation
  if (data?.htmlValidation?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('html-validation')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.htmlValidation?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('html-validation')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.htmlValidation?.data?.testResults?.status,
          id: 'html-validation',
          message: data?.htmlValidation?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('html-validation')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.htmlValidation?.data?.testResults?.status,
          id: 'html-validation',
          message: data?.htmlValidation?.data?.testResults?.message,
        },
      ]
    }
  }

  // https-test
  if (data?.httpsTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('https-test')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.httpsTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('https-test')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.httpsTest?.data?.testResults?.status,
          id: 'https-test',
          message: data?.httpsTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('https-test')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.httpsTest?.data?.testResults?.status,
          id: 'https-test',
          message: data?.httpsTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // inline-css
  if (data?.inlineCss?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('inline-css')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.inlineCss?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('inline-css')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.inlineCss?.data?.testResults?.status,
          id: 'inline-css',
          message: data?.inlineCss?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('inline-css')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.inlineCss?.data?.testResults?.status,
          id: 'inline-css',
          message: data?.inlineCss?.data?.testResults?.message,
        },
      ]
    }
  }

  // js-error
  if (data?.jsError?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('js-error')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.jsError?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('js-error')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.jsError?.data?.testResults?.status,
          id: 'js-error',
          message: data?.jsError?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('js-error')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.jsError?.data?.testResults?.status,
          id: 'js-error',
          message: data?.jsError?.data?.testResults?.message,
        },
      ]
    }
  }

  // media-query
  // if (data?.mediaQuery?.data?.testResults?.status === 1) {
  //   positiveCounter++
  //   passedTestArray.push('media-query')
  //   if (isUrlData) {
  //     mobileUseS++
  //   }
  // } else if (data?.mediaQuery?.data?.testResults?.status === 2) {
  //   warningCounter++
  //   warningTestArray.push('media-query')
  //   if (isUrlData) {
  //     mobileUseW++
  //     updatedErrorsArray = [
  //       ...updatedErrorsArray,
  //       {
  //         priority: data?.mediaQuery?.data?.testResults?.status,
  //         id: 'media-query',
  //         message: data?.mediaQuery?.data?.testResults?.message,
  //       },
  //     ]
  //   }
  // } else {
  //   negativeCounter++
  //   failedTestArray.push('media-query')
  //   if (isUrlData) {
  //     mobileUseF++
  //     updatedErrorsArray = [
  //       ...updatedErrorsArray,
  //       {
  //         priority: data?.mediaQuery?.data?.testResults?.status,
  //         id: 'media-query',
  //         message: data?.mediaQuery?.data?.testResults?.message,
  //       },
  //     ]
  //   }
  // }

  // microdata-schema
  if (data?.microDataSchema?.data?.microSchemaResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('microdata-schema')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.microDataSchema?.data?.microSchemaResults?.status === 2) {
    warningCounter++
    warningTestArray.push('microdata-schema')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.microDataSchema?.data?.microSchemaResults?.status,
          id: 'microdata-schema',
          message: data?.microDataSchema?.data?.microSchemaResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('microdata-schema')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.microDataSchema?.data?.microSchemaResults?.status,
          id: 'microdata-schema',
          message: data?.microDataSchema?.data?.microSchemaResults?.message,
        },
      ]
    }
  }

  // nested-table
  if (data?.nestedTable?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('nested-table')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.nestedTable?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('nested-table')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.nestedTable?.data?.testResults?.status,
          id: 'nested-table',
          message: data?.nestedTable?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('nested-table')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.nestedTable?.data?.testResults?.status,
          id: 'nested-table',
          message: data?.nestedTable?.data?.testResults?.message,
        },
      ]
    }
  }

  // no-follow
  // if (data?.noFollow?.data?.testResults?.status === 1) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         advanceS++;
  //     }
  // } else if(data?.noFollow?.data?.testResults?.status === 2) {
  //     warningCounter++;
  //     if (isUrlData) {
  //         advanceW++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.noFollow?.data?.testResults?.status, id: 'no-follow', message: data?.noFollow?.data?.testResults?.message }];
  //     }
  // } else {
  //     negativeCounter++;
  //     if (isUrlData) {
  //         advanceF++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.noFollow?.data?.testResults?.status, id: 'no-follow', message: data?.noFollow?.data?.testResults?.message }];
  //     }
  // }

  // no-index
  if (data?.noIndexChecker?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('no-index')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.noIndexChecker?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('no-index')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.noIndexChecker?.data?.testResult?.status,
          id: 'no-index',
          message: data?.noIndexChecker?.data?.testResult?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('no-index')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.noIndexChecker?.data?.testResult?.status,
          id: 'no-index',
          message: data?.noIndexChecker?.data?.testResult?.message,
        },
      ]
    }
  }

  // canonical-url
  if (data?.canonicalUrl?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('canonical-url')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.canonicalUrl?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('canonical-url')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.canonicalUrl?.data?.testResult?.status,
          id: 'canonical-url',
          message: data?.canonicalUrl?.data?.testResult?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('canonical-url')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.canonicalUrl?.data?.testResult?.status,
          id: 'canonical-url',
          message: data?.canonicalUrl?.data?.testResult?.message,
        },
      ]
    }
  }

  // page-cache
  if (data?.pageCache?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('page-cache')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.pageCache?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('page-cache')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.pageCache?.data?.testResults?.status,
          id: 'page-cache',
          message: data?.pageCache?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('page-cache')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.pageCache?.data?.testResults?.status,
          id: 'page-cache',
          message: data?.pageCache?.data?.testResults?.message,
        },
      ]
    }
  }

  // page-object
  if (data?.pageObject?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('page-object')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.pageObject?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('page-object')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.pageObject?.data?.testResults?.status,
          id: 'page-object',
          message: data?.pageObject?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('page-object')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.pageObject?.data?.testResults?.status,
          id: 'page-object',
          message: data?.pageObject?.data?.testResults?.message,
        },
      ]
    }
  }

  // plain-text
  if (data?.plainTextEmail?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('plain-text')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.plainTextEmail?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('plain-text')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.plainTextEmail?.data?.testResults?.status,
          id: 'plain-text',
          message: data?.plainTextEmail?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('plain-text')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.plainTextEmail?.data?.testResults?.status,
          id: 'plain-text',
          message: data?.plainTextEmail?.data?.testResults?.message,
        },
      ]
    }
  }

  // safe-browsing
  if (data?.safeBrowsing?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('safe-browsing')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.safeBrowsing?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('safe-browsing')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.safeBrowsing?.data?.testResults?.status,
          id: 'safe-browsing',
          message: data?.safeBrowsing?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('safe-browsing')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.safeBrowsing?.data?.testResults?.status,
          id: 'safe-browsing',
          message: data?.safeBrowsing?.data?.testResults?.message,
        },
      ]
    }
  }

  // serverSignature
  if (data?.serverSignature?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('server-signature')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.serverSignature?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('server-signature')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.serverSignature?.data?.testResults?.status,
          id: 'server-signature',
          message: data?.serverSignature?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('server-signature')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.serverSignature?.data?.testResults?.status,
          id: 'server-signature',
          message: data?.serverSignature?.data?.testResults?.message,
        },
      ]
    }
  }

  // site-speed
  if (data?.siteSpeed?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('site-speed')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.siteSpeed?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('site-speed')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.siteSpeed?.data?.testResults?.status,
          id: 'site-speed',
          message: data?.siteSpeed?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('site-speed')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.siteSpeed?.data?.testResults?.status,
          id: 'site-speed',
          message: data?.siteSpeed?.data?.testResults?.message,
        },
      ]
    }
  }

  // social-media
  if (data?.socialMediaTwitterData?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('social-media')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.socialMediaTwitterData?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('social-media')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.socialMediaTwitterData?.data?.testResults?.status,
          id: 'social-media',
          message: data?.socialMediaTwitterData?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('social-media')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.socialMediaTwitterData?.data?.testResults?.status,
          id: 'social-media',
          message: data?.socialMediaTwitterData?.data?.testResults?.message,
        },
      ]
    }
  }

  // spf-record
  if (data?.spfRecord?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('spf-record')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.spfRecord?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('spf-record')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.spfRecord?.data?.testResult?.status,
          id: 'spf-record',
          message: data?.spfRecord?.data?.testResult?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('spf-record')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.spfRecord?.data?.testResult?.status,
          id: 'spf-record',
          message: data?.spfRecord?.data?.testResult?.message,
        },
      ]
    }
  }

  // url-canonicalization
  if (data?.urlCanonicalization?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('url-canonicalization')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.urlCanonicalization?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('url-canonicalization')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.urlCanonicalization?.data?.testResult?.status,
          id: 'url-canonicalization',
          message: data?.urlCanonicalization?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('url-canonicalization')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.urlCanonicalization?.data?.testResult?.status,
          id: 'url-canonicalization',
          message: data?.urlCanonicalization?.data?.testResults?.message,
        },
      ]
    }
  }

  // brotil-test
  if (data?.brotilTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('brotil-test')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.brotilTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('brotil-test')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.brotilTest?.data?.testResults?.status,
          id: 'brotil-test',
          message: data?.brotilTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('brotil-test')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.brotilTest?.data?.testResults?.status,
          id: 'brotil-test',
          message: data?.brotilTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // // dns-security
  // if (data?.dnsSecurityTest?.data?.testResults?.status === 1) {
  //     positiveCounter++;
  //     if (isUrlData) {
  //         serverS++;
  //     }
  // } else if(data?.dnsSecurityTest?.data?.testResults?.status === 2) {
  //     warningCounter++;
  //     if (isUrlData) {
  //         serverW++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.dnsSecurityTest?.data?.testResults?.status, id: 'dns-security', message: data?.dnsSecurityTest?.data?.testResults?.message }];
  //     }
  // } else {
  //     negativeCounter++;
  //     if (isUrlData) {
  //         serverF++;
  //         updatedErrorsArray = [...updatedErrorsArray, { priority: data?.dnsSecurityTest?.data?.testResults?.status, id: 'dns-security', message: data?.dnsSecurityTest?.data?.testResults?.message }];
  //     }
  // }

  // http2-test
  if (data?.http2Test?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('http2-test')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.http2Test?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('http2-test')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.http2Test?.data?.testResults?.status,
          id: 'http2-test',
          message: data?.http2Test?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('http2-test')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.http2Test?.data?.testResults?.status,
          id: 'http2-test',
          message: data?.http2Test?.data?.testResults?.message,
        },
      ]
    }
  }

  // ssl-freak
  if (data?.sslFreakTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('ssl-freak')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.sslFreakTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('ssl-freak')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.sslFreakTest?.data?.testResults?.status,
          id: 'ssl-freak',
          message: data?.sslFreakTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('ssl-freak')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.sslFreakTest?.data?.testResults?.status,
          id: 'ssl-freak',
          message: data?.sslFreakTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // tls-logjam
  if (data?.tlsLogjamTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('tls-logjam')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.tlsLogjamTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('tls-logjam')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.tlsLogjamTest?.data?.testResults?.status,
          id: 'tls-logjam',
          message: data?.tlsLogjamTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('tls-logjam')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.tlsLogjamTest?.data?.testResults?.status,
          id: 'tls-logjam',
          message: data?.tlsLogjamTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // render-blocking
  if (data?.renderBlocking?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('render-blocking')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.renderBlocking?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('render-blocking')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.renderBlocking?.data?.testResults?.status,
          id: 'render-blocking',
          message: data?.renderBlocking?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('render-blocking')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.renderBlocking?.data?.testResults?.status,
          id: 'render-blocking',
          message: data?.renderBlocking?.data?.testResults?.message,
        },
      ]
    }
  }

  // unsafe-cross-origin-links
  if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('unsafe-cross-origin-links')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('unsafe-cross-origin-links')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
          id: 'unsafe-cross-origin-links',
          message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('unsafe-cross-origin-links')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
          id: 'unsafe-cross-origin-links',
          message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
        },
      ]
    }
  }

  // morden-image
  if (data?.mordenImage?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('morden-image')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.mordenImage?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('morden-image')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.mordenImage?.data?.testResults?.status,
          id: 'morden-image',
          message: data?.mordenImage?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('morden-image')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.mordenImage?.data?.testResults?.status,
          id: 'morden-image',
          message: data?.mordenImage?.data?.testResults?.message,
        },
      ]
    }
  }

  // aspect-ratio
  if (data?.aspectRatio?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('aspect-ratio')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.aspectRatio?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('aspect-ratio')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.aspectRatio?.data?.testResults?.status,
          id: 'aspect-ratio',
          message: data?.aspectRatio?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('aspect-ratio')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.aspectRatio?.data?.testResults?.status,
          id: 'aspect-ratio',
          message: data?.aspectRatio?.data?.testResults?.message,
        },
      ]
    }
  }

  // responsive-image
  if (data?.responsiveImage?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('responsive-image')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.responsiveImage?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('responsive-image')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.responsiveImage?.data?.testResults?.status,
          id: 'responsive-image',
          message: data?.responsiveImage?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('responsive-image')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.responsiveImage?.data?.testResults?.status,
          id: 'responsive-image',
          message: data?.responsiveImage?.data?.testResults?.message,
        },
      ]
    }
  }

  // console errors
  if (data?.consoleErrors?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('console-errors')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.consoleErrors?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('console-errors')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.consoleErrors?.data?.testResults?.status,
          id: 'console-errors',
          message: data?.consoleErrors?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('console-errors')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.consoleErrors?.data?.testResults?.status,
          id: 'console-errors',
          message: data?.consoleErrors?.data?.testResults?.message,
        },
      ]
    }
  }

  //  dom-size
  if (data?.domSize?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('dom-size')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.domSize?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('dom-size')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.domSize?.data?.testResults?.status,
          id: 'dom-size',
          message: data?.domSize?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('dom-size')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.domSize?.data?.testResults?.status,
          id: 'dom-size',
          message: data?.domSize?.data?.testResults?.message,
        },
      ]
    }
  }

  // ads-txt
  if (data?.adsTxt?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('ads-txt')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.adsTxt?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('ads-txt')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.adsTxt?.data?.testResults?.status,
          id: 'ads-txt',
          message: data?.adsTxt?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('ads-txt')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.adsTxt?.data?.testResults?.status,
          id: 'dom-size',
          message: data?.adsTxt?.data?.testResults?.message,
        },
      ]
    }
  }

  // js-execution-time
  if (data?.bootupTime?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('js-execution-time')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.bootupTime?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('js-execution-time')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.bootupTime?.data?.testResults?.status,
          id: 'js-execution-time',
          message: data?.bootupTime?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('js-execution-time')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.bootupTime?.data?.testResults?.status,
          id: 'js-execution-time',
          message: data?.bootupTime?.data?.testResults?.message,
        },
      ]
    }
  }

  // characterSet
  if (data?.characterSet?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('character-set')
    if (isUrlData) {
      commonSeoS++
    }
  } else if (data?.characterSet?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('character-set')
    if (isUrlData) {
      commonSeoW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.characterSet?.data?.testResults?.status,
          id: 'character-set',
          message: data?.characterSet?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('character-set')
    if (isUrlData) {
      commonSeoF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.characterSet?.data?.testResults?.status,
          id: 'character-set',
          message: data?.characterSet?.data?.testResults?.message,
        },
      ]
    }
  }

  // viewport
  if (data?.viewport?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('viewport')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.viewport?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('viewport')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.viewport?.data?.testResults?.status,
          id: 'viewport',
          message: data?.viewport?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('viewport')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.viewport?.data?.testResults?.status,
          id: 'viewport',
          message: data?.viewport?.data?.testResults?.message,
        },
      ]
    }
  }
   // font-size
   if (data?.fontSizeTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('font-size')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.fontSizeTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('font-size')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.fontSizeTest?.[0]?.data?.testResults?.status,
          id: 'font-size',
          message: data?.fontSizeTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('font-size')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.fontSizeTest?.[0]?.data?.testResults?.status,
          id: 'font-size',
          message: data?.fontSizeTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // tapTargetTest
   if (data?.tapTargetTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('tap-target')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.tapTargetTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('tap-target')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.tapTargetTest?.[0]?.data?.testResults?.status,
          id: 'tap-target',
          message: data?.tapTargetTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('tap-target')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.tapTargetTest?.[0]?.data?.testResults?.status,
          id: 'tap-target',
          message: data?.tapTargetTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // contentWidthTest
   if (data?.contentWidthTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('content-width')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.contentWidthTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('content-width')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.contentWidthTest?.[0]?.data?.testResults?.status,
          id: 'content-width',
          message: data?.contentWidthTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('content-width')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.contentWidthTest?.[0]?.data?.testResults?.status,
          id: 'content-width',
          message: data?.contentWidthTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // speedIndexTest
   if (data?.speedIndexTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('speed-index')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.speedIndexTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('speed-index')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.speedIndexTest?.[0]?.data?.testResults?.status,
          id: 'speed-index',
          message: data?.speedIndexTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('speed-index')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.speedIndexTest?.[0]?.data?.testResults?.status,
          id: 'speed-index',
          message: data?.speedIndexTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // firstContentfulPaintTest
   if (data?.firstContentfulPaintTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('first-contentful-paint')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.firstContentfulPaintTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('first-contentful-paint')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.firstContentfulPaintTest?.[0]?.data?.testResults?.status,
          id: 'first-contentful-paint',
          message: data?.firstContentfulPaintTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('first-contentful-paint')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.firstContentfulPaintTest?.[0]?.data?.testResults?.status,
          id: 'first-contentful-paint',
          message: data?.firstContentfulPaintTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // largestContentfulPaintTest
   if (data?.largestContentfulPaintTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('last-contentful-paint')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.largestContentfulPaintTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('last-contentful-paint')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.largestContentfulPaintTest?.[0]?.data?.testResults?.status,
          id: 'last-contentful-paint',
          message: data?.largestContentfulPaintTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('last-contentful-paint')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.largestContentfulPaintTest?.[0]?.data?.testResults?.status,
          id: 'last-contentful-paint',
          message: data?.largestContentfulPaintTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // cumulativeLayoutShiftTest
   if (data?.cumulativeLayoutShiftTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('cumulative-layout-shift-test')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.cumulativeLayoutShiftTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('cumulative-layout-shift-test')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cumulativeLayoutShiftTest?.[0]?.data?.testResults?.status,
          id: 'cumulative-layout-shift-test',
          message: data?.cumulativeLayoutShiftTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('cumulative-layout-shift-test')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.cumulativeLayoutShiftTest?.[0]?.data?.testResults?.status,
          id: 'cumulative-layout-shift-test',
          message: data?.cumulativeLayoutShiftTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // interactiveTest
   if (data?.interactiveTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('interactive-test')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.interactiveTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('interactive-test')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.interactiveTest?.[0]?.data?.testResults?.status,
          id: 'interactive-test',
          message: data?.interactiveTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('interactive-test')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.interactiveTest?.[0]?.data?.testResults?.status,
          id: 'interactive-test',
          message: data?.interactiveTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }
   // firstInputDelayTest
   if (data?.firstInputDelayTest?.[0]?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('first-input-delay')
    if (isUrlData) {
      mobileUseS++
    }
  } else if (data?.firstInputDelayTest?.[0]?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('first-input-delay')
    if (isUrlData) {
      mobileUseW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.firstInputDelayTest?.[0]?.data?.testResults?.status,
          id: 'first-input-delay',
          message: data?.firstInputDelayTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('first-input-delay')
    if (isUrlData) {
      mobileUseF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.firstInputDelayTest?.[0]?.data?.testResults?.status,
          id: 'first-input-delay',
          message: data?.firstInputDelayTest?.[0]?.data?.testResults?.message,
        },
      ]
    }
  }

  // meta-refresh
  if (data?.metaRefresh?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('meta-refresh')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.metaRefresh?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('meta-refresh')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.metaRefresh?.data?.testResult?.status,
          id: 'meta-refresh',
          message: data?.metaRefresh?.data?.testResult?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('meta-refresh')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.metaRefresh?.data?.testResult?.status,
          id: 'meta-refresh',
          message: data?.metaRefresh?.data?.testResult?.message,
        },
      ]
    }
  }

  // custom-404
  if (data?.custom404?.data?.testResult?.status === 1) {
    positiveCounter++
    passedTestArray.push('custom-404')
    if (isUrlData) {
      advanceS++
    }
  } else if (data?.custom404?.data?.testResult?.status === 2) {
    warningCounter++
    warningTestArray.push('custom-404')
    if (isUrlData) {
      advanceW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.custom404?.data?.testResult?.status,
          id: 'custom-404',
          message: data?.custom404?.data?.testResult?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('custom-404')
    if (isUrlData) {
      advanceF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.custom404?.data?.testResult?.status,
          id: 'custom-404',
          message: data?.custom404?.data?.testResult?.message,
        },
      ]
    }
  }

  // layout-shift
  if (data?.layoutShift?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('layout-shift')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.layoutShift?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('layout-shift')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.layoutShift?.data?.testResults?.status,
          id: 'layout-shift',
          message: data?.layoutShift?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('layout-shift')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.layoutShift?.data?.testResults?.status,
          id: 'layout-shift',
          message: data?.layoutShift?.data?.testResults?.message,
        },
      ]
    }
  }

  // contentful-paint
  if (data?.contentfulPaint?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('contentful-paint')
    if (isUrlData) {
      speedOptS++
    }
  } else if (data?.contentfulPaint?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('contentful-paint')
    if (isUrlData) {
      speedOptW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.contentfulPaint?.data?.testResults?.status,
          id: 'contentful-paint',
          message: data?.contentfulPaint?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('contentful-paint')
    if (isUrlData) {
      speedOptF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.contentfulPaint?.data?.testResults?.status,
          id: 'contentful-paint',
          message: data?.contentfulPaint?.data?.testResults?.message,
        },
      ]
    }
  }

  // directory-browsing
  if (data?.directoryBrowsing?.data?.testResults?.status === 1) {
    positiveCounter++
    passedTestArray.push('directory-browsing')
    if (isUrlData) {
      serverS++
    }
  } else if (data?.directoryBrowsing?.data?.testResults?.status === 2) {
    warningCounter++
    warningTestArray.push('directory-browsing')
    if (isUrlData) {
      serverW++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.directoryBrowsing?.data?.testResults?.status,
          id: 'directory-browsing',
          message: data?.directoryBrowsing?.data?.testResults?.message,
        },
      ]
    }
  } else {
    negativeCounter++
    failedTestArray.push('directory-browsing')
    if (isUrlData) {
      serverF++
      updatedErrorsArray = [
        ...updatedErrorsArray,
        {
          priority: data?.directoryBrowsing?.data?.testResults?.status,
          id: 'directory-browsing',
          message: data?.directoryBrowsing?.data?.testResults?.message,
        },
      ]
    }
  }

  return {
    updatedErrorsArray,
    warningCounter,
    positiveCounter,
    negativeCounter,
    commonSeo,
    commonSeoS,
    commonSeoW,
    commonSeoF,
    speedOptS,
    speedOptW,
    speedOptF,
    serverS,
    serverW,
    serverF,
    mobileUseS,
    mobileUseW,
    mobileUseF,
    advanceS,
    advanceW,
    advanceF,
  }
}

export const getReportData = (data, isUrlData = true, testType) => {
  let updatedErrorsArray = []
  let passedTestArray = []
  let failedTestArray = []
  let warningTestArray = []
  let positiveCounter = 0,
    warningCounter = 0,
    negativeCounter = 0,
    commonSeo = 0,
    commonSeoS = 0,
    commonSeoW = 0,
    commonSeoF = 0,
    speedOptS = 0,
    speedOptW = 0,
    speedOptF = 0,
    serverS = 0,
    serverW = 0,
    serverF = 0,
    mobileUseS = 0,
    mobileUseW = 0,
    mobileUseF = 0,
    advanceS = 0,
    advanceW = 0,
    advanceF = 0

  if (testType === "ALL" || testType === TEST_TYPES['meta-description']) {
    if (data?.information?.seoCheckup?.description?.descriptionStatus === 1) {
      positiveCounter++
      passedTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.information?.seoCheckup?.description?.descriptionStatus === 2) {
      warningCounter++
      warningTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.description?.descriptionStatus,
            id: 'meta-description',
            message: data?.information?.seoCheckup?.description?.descriptionMessage,
          },
        ]
      }
    } else if (data?.information?.seoCheckup?.description?.descriptionStatus === 3) {
      negativeCounter++
      failedTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.description?.descriptionStatus,
            id: 'meta-description',
            message: data?.information?.seoCheckup?.description?.descriptionMessage,
          },
        ]
      }
    }
  }

  if (testType === "ALL" || testType === TEST_TYPES['meta-title']) {
    if (data?.information?.seoCheckup?.title?.titleStatus === 1) {
      positiveCounter++
      passedTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.information?.seoCheckup?.title?.titleStatus === 2) {
      warningCounter++
      warningTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.title?.titleStatus,
            id: 'meta-title',
            message: data?.information?.seoCheckup?.title?.titleMessage,
          },
        ]
      }
    } else if (data?.information?.seoCheckup?.title?.titleStatus === 3) {
      negativeCounter++
      failedTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.title?.titleStatus,
            id: 'meta-title',
            message: data?.information?.seoCheckup?.title?.titleMessage,
          },
        ]
      }
    }
  }

  //social media Test
  if (testType === "ALL" || testType === TEST_TYPES['meta-tags']) {
    if (data?.microDataSchema?.data?.metaTagsResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.microDataSchema?.data?.metaTagsResults?.status === 2) {
      warningCounter++
      warningTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.metaTagsResults?.status,
            id: 'social-media-meta-tag',
            message: data?.microDataSchema?.data?.metaTagsResults?.message,
          },
        ]
      }
    } else if (data?.microDataSchema?.data?.metaTagsResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.metaTagsResults?.status,
            id: 'social-media-meta-tag',
            message: data?.microDataSchema?.data?.metaTagsResults?.message,
          },
        ]
      }
    }
  }

  // keyword-usage-test
  if (testType === "ALL" || testType === TEST_TYPES["keywords-usage-test"]) {
    if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 2) {
      warningCounter++
      warningTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
            id: 'keyword-usage-test',
            message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
          },
        ]
      }
    } else if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
            id: 'keyword-usage-test',
            message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
          },
        ]
      }
    }
  }

  // seo-friendly
  if (testType === "ALL" || testType === TEST_TYPES["seo-friendly-url-analysis"]) {    
    if (data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length === 0) {
      positiveCounter++
      passedTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoS++
      }
    } else {
      negativeCounter++
      failedTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: 3,
            id: 'seo-friendly',
            message: 'This webpage contains URLs that are not SEO friendly!',
          },
        ]
      }
    }
  }

  // sitemaps
  if (testType === "ALL" || testType === TEST_TYPES["sitemap-data"]) {
    if (data?.siteMapData?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.siteMapData?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteMapData?.data?.testResults?.status,
            id: 'sitemap-data',
            message: data?.siteMapData?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.siteMapData?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteMapData?.data?.testResults?.status,
            id: 'sitemap-data',
            message: data?.siteMapData?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // broken-links
  // if (data?.brokenLinks?.data?.testResults?.status === 1) {
  //   positiveCounter++
  //   passedTestArray.push('brokenLinks-data')
  //   if (isUrlData) {
  //     commonSeoS++
  //   }
  // } else if (data?.brokenLinks?.data?.testResults?.status === 2) {
  //   warningCounter++
  //   warningTestArray.push('brokenLinks-data')
  //   if (isUrlData) {
  //     commonSeoW++
  //     updatedErrorsArray = [
  //       ...updatedErrorsArray,
  //       {
  //         priority: data?.brokenLinks?.data?.testResults?.status,
  //         id: 'brokenLinks-data',
  //         message: data?.brokenLinks?.data?.testResults?.message,
  //       },
  //     ]
  //   }
  // } else if (data?.brokenLinks?.data?.testResults?.status === 3) {
  //   negativeCounter++
  //   failedTestArray.push('brokenLinks-data')
  //   if (isUrlData) {
  //     commonSeoF++
  //     updatedErrorsArray = [
  //       ...updatedErrorsArray,
  //       {
  //         priority: data?.brokenLinks?.data?.testResults?.status,
  //         id: 'broken-links',
  //         message: data?.brokenLinks?.data?.testResults?.message,
  //       },
  //     ]
  //   }
  // }

  // robots
  if (testType === "ALL" || testType === TEST_TYPES["robots-txt"]) {
    if (data?.robotsTxtTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.robotsTxtTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.robotsTxtTest?.data?.testResults?.status,
            id: 'robots-txt',
            message: data?.robotsTxtTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.robotsTxtTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.robotsTxtTest?.data?.testResults?.status,
            id: 'robots-txt',
            message: data?.robotsTxtTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // cdn-usage
  if (testType === "ALL" || testType === TEST_TYPES["cdn-usage-data"]) {
    if (data?.cdnUsageData?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cdnUsageData?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cdnUsageData?.data?.testResult?.status,
            id: 'cdn-usage',
            message: data?.cdnUsageData?.data?.testResult?.message,
          },
        ]
      }
    } else if (data?.cdnUsageData?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cdnUsageData?.data?.testResult?.status,
            id: 'cdn-usage',
            message: data?.cdnUsageData?.data?.testResult?.message,
          },
        ]
      }
    }
  }

  // cssAnalysis
  if (testType === "ALL" || testType === TEST_TYPES["css-analysis"]) {
    if (data?.cssAnalysis?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssAnalysis?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssAnalysis?.data?.testResults?.status,
            id: 'css-analysis',
            message: data?.cssAnalysis?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.cssAnalysis?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssAnalysis?.data?.testResults?.status,
            id: 'css-analysis',
            message: data?.cssAnalysis?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // cssCache
  if (testType === "ALL" || testType === TEST_TYPES["css-cache"]) {
    if (data?.cssCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('css-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssCache?.data?.testResults?.status,
            id: 'css-cache',
            message: data?.cssCache?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.cssCache?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('css-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssCache?.data?.testResults?.status,
            id: 'css-cache',
            message: data?.cssCache?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // cssMinification
  if (testType === "ALL" || testType === TEST_TYPES["css-minification"]) {
    if (data?.cssMinification?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-minification')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssMinification?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('css-minification')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssMinification?.data?.testResults?.status,
            id: 'css-minification',
            message: data?.cssMinification?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.cssMinification?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('css-minification')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssMinification?.data?.testResults?.status,
            id: 'css-minification',
            message: data?.cssMinification?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // jsMinification
  if (testType === "ALL" || testType === TEST_TYPES["js-minification"]) {
    if (data?.jsMinification?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-minification')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.jsMinification?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-minification')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsMinification?.data?.testResults?.status,
            id: 'js-minification',
            message: data?.jsMinification?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.jsMinification?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('js-minification')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsMinification?.data?.testResults?.status,
            id: 'js-minification',
            message: data?.jsMinification?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // jsCache
  if (testType === "ALL" || testType === TEST_TYPES["js-cache"]) {
    if (data?.jsCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.jsCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsCache?.data?.testResults?.status,
            id: 'js-cache',
            message: data?.jsCache?.data?.testResults?.message,
          },
        ]
      }
    } else  if (data?.jsCache?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('js-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsCache?.data?.testResults?.status,
            id: 'js-cache',
            message: data?.jsCache?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // imageCache
  if (testType === "ALL" || testType === TEST_TYPES["image-cache"]) {
    if (data?.imageCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('image-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.imageCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('image-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageCache?.data?.testResults?.status,
            id: 'image-cache',
            message: data?.imageCache?.data?.testResults?.message,
          },
        ]
      }
    } else  if (data?.imageCache?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('image-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageCache?.data?.testResults?.status,
            id: 'image-cache',
            message: data?.imageCache?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // cachePolicy
  if (testType === "ALL" || testType === TEST_TYPES["cache-policy"]) {
    if (data?.cachePolicy?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('cache-policy')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cachePolicy?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('cache-policy')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cachePolicy?.data?.testResults?.status,
            id: 'cache-policy',
            message: data?.cachePolicy?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.cachePolicy?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('cache-policy')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cachePolicy?.data?.testResults?.status,
            id: 'cache-policy',
            message: data?.cachePolicy?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // imageAlt
  if (testType === "ALL" || testType === TEST_TYPES["image-alt"]) {
    if (data?.imageAlt?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.imageAlt?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageAlt?.data?.testResults?.status,
            id: 'image-alt',
            message: data?.imageAlt?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.imageAlt?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageAlt?.data?.testResults?.status,
            id: 'image-alt',
            message: data?.imageAlt?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // descriptiveTest
  if (testType === "ALL" || testType === TEST_TYPES["descriptive-link"]) {
    if (data?.descriptiveLinkTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.descriptiveLinkTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.descriptiveLinkTest?.data?.testResults?.status,
            id: 'descriptive-test',
            message: data?.descriptiveLinkTest?.data?.testResults?.message,
          },
        ]
      }
    } else  if (data?.descriptiveLinkTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.descriptiveLinkTest?.data?.testResults?.status,
            id: 'descriptive-test',
            message: data?.descriptiveLinkTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // headingAnalysis
  if (testType === "ALL" || testType === TEST_TYPES["heading-analysis"]) {
    if (data?.headingAnalysis?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.headingAnalysis?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.headingAnalysis?.data?.testResults?.status,
            id: 'heading-analysis',
            message: data?.headingAnalysis?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.headingAnalysis?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.headingAnalysis?.data?.testResults?.status,
            id: 'heading-analysis',
            message: data?.headingAnalysis?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // docType
  if (testType === "ALL" || testType === TEST_TYPES["doc-type"]) {
    if (data?.docType?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('doc-type')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.docType?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('doc-type')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.docType?.data?.testResults?.status,
            id: 'doc-type',
            message: data?.docType?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.docType?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('doc-type')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.docType?.data?.testResults?.status,
            id: 'doc-type',
            message: data?.docType?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // redirect-test
  if (testType === "ALL" || testType === TEST_TYPES["redirect-test"]) {
    if (data?.redirectTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.redirectTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.redirectTest?.data?.testResults?.status,
            id: 'redirect-test',
            message: data?.redirectTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.redirectTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.redirectTest?.data?.testResults?.status,
            id: 'redirect-test',
            message: data?.redirectTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // faviconCheckerTest
  if (testType === "ALL" || testType === TEST_TYPES["favicon-checker-test"]) {
    if (data?.faviconCheckerTest?.data?.status === 1) {
      positiveCounter++
      passedTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.faviconCheckerTest?.data?.status === 2) {
      warningCounter++
      warningTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.faviconCheckerTest?.data?.status,
            id: 'favicon-test',
            message: data?.faviconCheckerTest?.data?.message,
          },
        ]
      }
    } else if (data?.faviconCheckerTest?.data?.status === 3) {
      negativeCounter++
      failedTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.faviconCheckerTest?.data?.status,
            id: 'favicon-test',
            message: data?.faviconCheckerTest?.data?.message,
          },
        ]
      }
    }
  }

  // flash-test
  if (testType === "ALL" || testType === TEST_TYPES["flash-test"]) {
    if (data?.flashTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('flash-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.flashTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('flash-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.flashTest?.data?.testResults?.status,
            id: 'flash-test',
            message: data?.flashTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.flashTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('flash-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.flashTest?.data?.testResults?.status,
            id: 'flash-test',
            message: data?.flashTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // html-page-size
  if (testType === "ALL" || testType === TEST_TYPES["html-page-size"]) {
    if (data?.htmlPageSize?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.htmlPageSize?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlPageSize?.data?.testResults?.status,
            id: 'html-page-size',
            message: data?.htmlPageSize?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.htmlPageSize?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlPageSize?.data?.testResults?.status,
            id: 'html-page-size',
            message: data?.htmlPageSize?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // framesetTest
  if (testType === "ALL" || testType === TEST_TYPES["frameset-test"]) {
    if (data?.framesetTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.framesetTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.framesetTest?.data?.testResults?.status,
            id: 'frameset-test',
            message: data?.framesetTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.framesetTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.framesetTest?.data?.testResults?.status,
            id: 'frameset-test',
            message: data?.framesetTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // googleAnalytics
  if (testType === "ALL" || testType === TEST_TYPES["google-analytics"]) {
    if (data?.googleAnalytics?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.googleAnalytics?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.googleAnalytics?.data?.testResults?.status,
            id: 'google-analytics',
            message: data?.googleAnalytics?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.googleAnalytics?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.googleAnalytics?.data?.testResults?.status,
            id: 'google-analytics',
            message: data?.googleAnalytics?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // htmlCompression
  if (testType === "ALL" || testType === TEST_TYPES["html-compression"]) {
    if (data?.htmlCompression?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-compression')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.htmlCompression?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('html-compression')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlCompression?.data?.testResults?.status,
            id: 'html-compression',
            message: data?.htmlCompression?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.htmlCompression?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('html-compression')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlCompression?.data?.testResults?.status,
            id: 'html-compression',
            message: data?.htmlCompression?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // hsts
  if (testType === "ALL" || testType === TEST_TYPES["hsts-test"]) {
    if (data?.hstsTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('hsts')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.hstsTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('hsts')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.hstsTest?.data?.testResults?.status,
            id: 'hsts',
            message: data?.hstsTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.hstsTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('hsts')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.hstsTest?.data?.testResults?.status,
            id: 'hsts',
            message: data?.hstsTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // htmlValidation
  if (testType === "ALL" || testType === TEST_TYPES["html-validation"]) {
    if (data?.htmlValidation?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.htmlValidation?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlValidation?.data?.testResults?.status,
            id: 'html-validation',
            message: data?.htmlValidation?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.htmlValidation?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlValidation?.data?.testResults?.status,
            id: 'html-validation',
            message: data?.htmlValidation?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // https-test
  if (testType === "ALL" || testType === TEST_TYPES["https-test"]) {
    if (data?.httpsTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('https-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.httpsTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('https-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.httpsTest?.data?.testResults?.status,
            id: 'https-test',
            message: data?.httpsTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.httpsTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('https-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.httpsTest?.data?.testResults?.status,
            id: 'https-test',
            message: data?.httpsTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // inline-css
  if (testType === "ALL" || testType === TEST_TYPES["inline-css"]) {
    if (data?.inlineCss?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.inlineCss?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.inlineCss?.data?.testResults?.status,
            id: 'inline-css',
            message: data?.inlineCss?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.inlineCss?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.inlineCss?.data?.testResults?.status,
            id: 'inline-css',
            message: data?.inlineCss?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // js-error
  if (testType === "ALL" || testType === TEST_TYPES["js-error"]) {
    if (data?.jsError?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-error')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.jsError?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-error')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsError?.data?.testResults?.status,
            id: 'js-error',
            message: data?.jsError?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.jsError?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('js-error')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsError?.data?.testResults?.status,
            id: 'js-error',
            message: data?.jsError?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // microdata-schema
  if (testType === "ALL" || testType === TEST_TYPES['microdata-schema-analysis']) {
    if (data?.microDataSchema?.data?.microSchemaResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.microDataSchema?.data?.microSchemaResults?.status === 2) {
      warningCounter++
      warningTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.microSchemaResults?.status,
            id: 'microdata-schema',
            message: data?.microDataSchema?.data?.microSchemaResults?.message,
          },
        ]
      }
    } else if (data?.microDataSchema?.data?.microSchemaResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.microSchemaResults?.status,
            id: 'microdata-schema',
            message: data?.microDataSchema?.data?.microSchemaResults?.message,
          },
        ]
      }
    }
  }

  // nested-table
  if (testType === "ALL" || testType === TEST_TYPES["nested-table"]) {
    if (data?.nestedTable?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('nested-table')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.nestedTable?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('nested-table')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.nestedTable?.data?.testResults?.status,
            id: 'nested-table',
            message: data?.nestedTable?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.nestedTable?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('nested-table')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.nestedTable?.data?.testResults?.status,
            id: 'nested-table',
            message: data?.nestedTable?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // no-index
  if (testType === "ALL" || testType === TEST_TYPES["no-index-checker"]) {
    if (data?.noIndexChecker?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('no-index')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.noIndexChecker?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('no-index')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.noIndexChecker?.data?.testResult?.status,
            id: 'no-index',
            message: data?.noIndexChecker?.data?.testResult?.message,
          },
        ]
      }
    } else if (data?.noIndexChecker?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('no-index')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.noIndexChecker?.data?.testResult?.status,
            id: 'no-index',
            message: data?.noIndexChecker?.data?.testResult?.message,
          },
        ]
      }
    }
  }

  // canonical-url
  if (testType === "ALL" || testType === TEST_TYPES["canonical-url"]) {
    if (data?.canonicalUrl?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('canonical-url')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.canonicalUrl?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('canonical-url')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.canonicalUrl?.data?.testResult?.status,
            id: 'canonical-url',
            message: data?.canonicalUrl?.data?.testResult?.message,
          },
        ]
      }
    } else if (data?.canonicalUrl?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('canonical-url')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.canonicalUrl?.data?.testResult?.status,
            id: 'canonical-url',
            message: data?.canonicalUrl?.data?.testResult?.message,
          },
        ]
      }
    }
  }

  // page-cache
  if (testType === "ALL" || testType === TEST_TYPES["page-cache"]) {
    if (data?.pageCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('page-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.pageCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('page-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageCache?.data?.testResults?.status,
            id: 'page-cache',
            message: data?.pageCache?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.pageCache?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('page-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageCache?.data?.testResults?.status,
            id: 'page-cache',
            message: data?.pageCache?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // page-object
  if (testType === "ALL" || testType === TEST_TYPES["page-object"]) {
    if (data?.pageObject?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('page-object')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.pageObject?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('page-object')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageObject?.data?.testResults?.status,
            id: 'page-object',
            message: data?.pageObject?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.pageObject?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('page-object')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageObject?.data?.testResults?.status,
            id: 'page-object',
            message: data?.pageObject?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // plain-text
  if (testType === "ALL" || testType === TEST_TYPES["plain-text-email"]) {
    if (data?.plainTextEmail?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('plain-text')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.plainTextEmail?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('plain-text')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.plainTextEmail?.data?.testResults?.status,
            id: 'plain-text',
            message: data?.plainTextEmail?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.plainTextEmail?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('plain-text')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.plainTextEmail?.data?.testResults?.status,
            id: 'plain-text',
            message: data?.plainTextEmail?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // safe-browsing
  if (testType === "ALL" || testType === TEST_TYPES["safe-browsing"]) {
    if (data?.safeBrowsing?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('safe-browsing')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.safeBrowsing?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('safe-browsing')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.safeBrowsing?.data?.testResults?.status,
            id: 'safe-browsing',
            message: data?.safeBrowsing?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.safeBrowsing?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('safe-browsing')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.safeBrowsing?.data?.testResults?.status,
            id: 'safe-browsing',
            message: data?.safeBrowsing?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // serverSignature
  if (testType === "ALL" || testType === TEST_TYPES["server-signature"]) {
    if (data?.serverSignature?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('server-signature')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.serverSignature?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('server-signature')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.serverSignature?.data?.testResults?.status,
            id: 'server-signature',
            message: data?.serverSignature?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.serverSignature?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('server-signature')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.serverSignature?.data?.testResults?.status,
            id: 'server-signature',
            message: data?.serverSignature?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // site-speed
  if (testType === "ALL" || testType === TEST_TYPES["page-speed"]) {
    if (data?.siteSpeed?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('site-speed')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.siteSpeed?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('site-speed')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteSpeed?.data?.testResults?.status,
            id: 'site-speed',
            message: data?.siteSpeed?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.siteSpeed?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('site-speed')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteSpeed?.data?.testResults?.status,
            id: 'site-speed',
            message: data?.siteSpeed?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // social-media
  if (testType === "ALL" || testType === TEST_TYPES["social-media-twitter-data"]) {
    if (data?.socialMediaTwitterData?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('social-media')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.socialMediaTwitterData?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('social-media')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.socialMediaTwitterData?.data?.testResults?.status,
            id: 'social-media',
            message: data?.socialMediaTwitterData?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.socialMediaTwitterData?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('social-media')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.socialMediaTwitterData?.data?.testResults?.status,
            id: 'social-media',
            message: data?.socialMediaTwitterData?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // spf-record
  if (testType === "ALL" || testType === TEST_TYPES["spf-record"]) {
    if (data?.spfRecord?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('spf-record')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.spfRecord?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('spf-record')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.spfRecord?.data?.testResult?.status,
            id: 'spf-record',
            message: data?.spfRecord?.data?.testResult?.message,
          },
        ]
      }
    } else if (data?.spfRecord?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('spf-record')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.spfRecord?.data?.testResult?.status,
            id: 'spf-record',
            message: data?.spfRecord?.data?.testResult?.message,
          },
        ]
      }
    }
  }

  // url-canonicalization
  if (testType === "ALL" || testType === TEST_TYPES["url-canonicalization"]) {
    if (data?.urlCanonicalization?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.urlCanonicalization?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.urlCanonicalization?.data?.testResult?.status,
            id: 'url-canonicalization',
            message: data?.urlCanonicalization?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.urlCanonicalization?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.urlCanonicalization?.data?.testResult?.status,
            id: 'url-canonicalization',
            message: data?.urlCanonicalization?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // brotil-test
  if (testType === "ALL" || testType === TEST_TYPES["brotli-test"]) {
    if (data?.brotilTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('brotil-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.brotilTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('brotil-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brotilTest?.data?.testResults?.status,
            id: 'brotil-test',
            message: data?.brotilTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.brotilTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('brotil-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brotilTest?.data?.testResults?.status,
            id: 'brotil-test',
            message: data?.brotilTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // http2-test
  if (testType === "ALL" || testType === TEST_TYPES["http2-test"]) {
    if (data?.http2Test?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('http2-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.http2Test?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('http2-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.http2Test?.data?.testResults?.status,
            id: 'http2-test',
            message: data?.http2Test?.data?.testResults?.message,
          },
        ]
      }
    } else  if (data?.http2Test?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('http2-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.http2Test?.data?.testResults?.status,
            id: 'http2-test',
            message: data?.http2Test?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // ssl-freak
  if (testType === "ALL" || testType === TEST_TYPES["ssl-freak-test"]) {
    if (data?.sslFreakTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('ssl-freak')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.sslFreakTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('ssl-freak')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.sslFreakTest?.data?.testResults?.status,
            id: 'ssl-freak',
            message: data?.sslFreakTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.sslFreakTest?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('ssl-freak')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.sslFreakTest?.data?.testResults?.status,
            id: 'ssl-freak',
            message: data?.sslFreakTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // tls-logjam
  if (testType === "ALL" || testType === TEST_TYPES["tls-log-jam-test"]) {
    if (data?.tlsLogjamTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('tls-logjam')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.tlsLogjamTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('tls-logjam')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tlsLogjamTest?.data?.testResults?.status,
            id: 'tls-logjam',
            message: data?.tlsLogjamTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.tlsLogjamTest?.data?.testResults?.status === 3){
      negativeCounter++
      failedTestArray.push('tls-logjam')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tlsLogjamTest?.data?.testResults?.status,
            id: 'tls-logjam',
            message: data?.tlsLogjamTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // render-blocking
  if (testType === "ALL" || testType === TEST_TYPES["render-blocking"]) {
    if (data?.renderBlocking?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.renderBlocking?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.renderBlocking?.data?.testResults?.status,
            id: 'render-blocking',
            message: data?.renderBlocking?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.renderBlocking?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.renderBlocking?.data?.testResults?.status,
            id: 'render-blocking',
            message: data?.renderBlocking?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // unsafe-cross-origin-links
  if (testType === "ALL" || testType === TEST_TYPES["unsafe-cross-origin-links-test"]) {
    if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
            id: 'unsafe-cross-origin-links',
            message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 3){
      negativeCounter++
      failedTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
            id: 'unsafe-cross-origin-links',
            message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // morden-image
  if (testType === "ALL" || testType === TEST_TYPES["morden-image"]) {
    if (data?.mordenImage?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('morden-image')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.mordenImage?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('morden-image')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.mordenImage?.data?.testResults?.status,
            id: 'morden-image',
            message: data?.mordenImage?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.mordenImage?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('morden-image')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.mordenImage?.data?.testResults?.status,
            id: 'morden-image',
            message: data?.mordenImage?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // aspect-ratio
  if (testType === "ALL" || testType === TEST_TYPES["aspect-ratio"]) {
    if (data?.aspectRatio?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.aspectRatio?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.aspectRatio?.data?.testResults?.status,
            id: 'aspect-ratio',
            message: data?.aspectRatio?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.aspectRatio?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.aspectRatio?.data?.testResults?.status,
            id: 'aspect-ratio',
            message: data?.aspectRatio?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // responsive-image
  if (testType === "ALL" || testType === TEST_TYPES["responsive-image"]) {
    if (data?.responsiveImage?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.responsiveImage?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.responsiveImage?.data?.testResults?.status,
            id: 'responsive-image',
            message: data?.responsiveImage?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.responsiveImage?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.responsiveImage?.data?.testResults?.status,
            id: 'responsive-image',
            message: data?.responsiveImage?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // console errors
  if (testType === "ALL" || testType === TEST_TYPES["console-errors"]) {
    if (data?.consoleErrors?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.consoleErrors?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.consoleErrors?.data?.testResults?.status,
            id: 'console-errors',
            message: data?.consoleErrors?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.consoleErrors?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.consoleErrors?.data?.testResults?.status,
            id: 'console-errors',
            message: data?.consoleErrors?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // advance console errors
  if (testType === "ALL" || testType === TEST_TYPES["advanced-console-errors"]) {
    if (data?.advancedConsoleErrors?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.advancedConsoleErrors?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.advancedConsoleErrors?.data?.testResults?.status,
            id: 'advanced-console-errors',
            message: data?.advancedConsoleErrors?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.advancedConsoleErrors?.data?.testResults?.status === 3){
      negativeCounter++
      failedTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.advancedConsoleErrors?.data?.testResults?.status,
            id: 'advanced-console-errors',
            message: data?.advancedConsoleErrors?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //  dom-size
  if (testType === "ALL" || testType === TEST_TYPES["dom-size"]) {
    if (data?.domSize?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('dom-size')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.domSize?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('dom-size')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domSize?.data?.testResults?.status,
            id: 'dom-size',
            message: data?.domSize?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.domSize?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('dom-size')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domSize?.data?.testResults?.status,
            id: 'dom-size',
            message: data?.domSize?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // ads-txt
  if (testType === "ALL" || testType === TEST_TYPES["ads-txt"]) {
    if (data?.adsTxt?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('ads-txt')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.adsTxt?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('ads-txt')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.adsTxt?.data?.testResults?.status,
            id: 'ads-txt',
            message: data?.adsTxt?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.adsTxt?.data?.testResults?.status === 3){
      negativeCounter++
      failedTestArray.push('ads-txt')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.adsTxt?.data?.testResults?.status,
            id: 'dom-size',
            message: data?.adsTxt?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // js-execution-time
  if (testType === "ALL" || testType === TEST_TYPES["bootup-time"]) {
    if (data?.bootupTime?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.bootupTime?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.bootupTime?.data?.testResults?.status,
            id: 'js-execution-time',
            message: data?.bootupTime?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.bootupTime?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.bootupTime?.data?.testResults?.status,
            id: 'js-execution-time',
            message: data?.bootupTime?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // characterSet
  if (testType === "ALL" || testType === TEST_TYPES["character-set"]) {
    if (data?.characterSet?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('character-set')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.characterSet?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('character-set')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.characterSet?.data?.testResults?.status,
            id: 'character-set',
            message: data?.characterSet?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.characterSet?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('character-set')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.characterSet?.data?.testResults?.status,
            id: 'character-set',
            message: data?.characterSet?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // viewport
  if (testType === "ALL" || testType === TEST_TYPES.viewport) {
    if (data?.viewport?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('viewport')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.viewport?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('viewport')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.viewport?.data?.testResults?.status,
            id: 'viewport',
            message: data?.viewport?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.viewport?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('viewport')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.viewport?.data?.testResults?.status,
            id: 'viewport',
            message: data?.viewport?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //font-size
  if (testType === "ALL" || testType === TEST_TYPES["font-size"]) {
    if (data?.fontSizeTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('font-size')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.fontSizeTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('font-size')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.fontSizeTest?.data?.testResults?.status,
            id: 'font-size',
            message: data?.fontSizeTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.fontSizeTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('font-size')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.fontSizeTest?.data?.testResults?.status,
            id: 'font-size',
            message: data?.fontSizeTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //tapTargetTest
  if (testType === "ALL" || testType === TEST_TYPES["tap-target"]) {
    if (data?.tapTargetTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.tapTargetTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tapTargetTest?.data?.testResults?.status,
            id: 'tap-target',
            message: data?.tapTargetTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.tapTargetTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tapTargetTest?.data?.testResults?.status,
            id: 'tap-target',
            message: data?.tapTargetTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //contentWidthTest
  if (testType === "ALL" || testType === TEST_TYPES["content-width"]) {
    if (data?.contentWidthTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('content-width')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.contentWidthTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('content-width')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentWidthTest?.data?.testResults?.status,
            id: 'content-width',
            message: data?.contentWidthTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.contentWidthTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('content-width')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentWidthTest?.data?.testResults?.status,
            id: 'content-width',
            message: data?.contentWidthTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //speedIndexTest
  if (testType === "ALL" || testType === TEST_TYPES["speed-index-test"]) {
    if (data?.speedIndexTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.speedIndexTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.speedIndexTest?.data?.testResults?.status,
            id: 'speed-index',
            message: data?.speedIndexTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.speedIndexTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.speedIndexTest?.data?.testResults?.status,
            id: 'speed-index',
            message: data?.speedIndexTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //firstContentfulPaintTest
  if (testType === "ALL" || testType === TEST_TYPES["first-contentful-paint"]) {
    if (data?.firstContentfulPaintTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.firstContentfulPaintTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstContentfulPaintTest?.data?.testResults?.status,
            id: 'first-contentful-paint',
            message: data?.firstContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.firstContentfulPaintTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstContentfulPaintTest?.data?.testResults?.status,
            id: 'first-contentful-paint',
            message: data?.firstContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //largestContentfulPaintTest
  if (testType === "ALL" || testType === TEST_TYPES["largest-contentful-paint"]) {
    if (data?.largestContentfulPaintTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.largestContentfulPaintTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.largestContentfulPaintTest?.data?.testResults?.status,
            id: 'last-contentful-paint',
            message: data?.largestContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.largestContentfulPaintTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.largestContentfulPaintTest?.data?.testResults?.status,
            id: 'last-contentful-paint',
            message: data?.largestContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //cumulativeLayoutShiftTest
  if (testType === "ALL" || testType === TEST_TYPES["cumulative-layout-shift"]) {
    if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cumulativeLayoutShiftTest?.data?.testResults?.status,
            id: 'cumulative-layout-shift-test',
            message: data?.cumulativeLayoutShiftTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cumulativeLayoutShiftTest?.data?.testResults?.status,
            id: 'cumulative-layout-shift-test',
            message: data?.cumulativeLayoutShiftTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //interactiveTest
  if (testType === "ALL" || testType === TEST_TYPES.interactive) {
    if (data?.interactiveTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.interactiveTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.interactiveTest?.data?.testResults?.status,
            id: 'interactive-test',
            message: data?.interactiveTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.interactiveTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.interactiveTest?.data?.testResults?.status,
            id: 'interactive-test',
            message: data?.interactiveTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  //firstInputDelayTest
  if (testType === "ALL" || testType === TEST_TYPES["first-input-delay"]) {
    if (data?.firstInputDelayTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.firstInputDelayTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstInputDelayTest?.data?.testResults?.status,
            id: 'first-input-delay',
            message: data?.firstInputDelayTest?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.firstInputDelayTest?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstInputDelayTest?.data?.testResults?.status,
            id: 'first-input-delay',
            message: data?.firstInputDelayTest?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // meta-refresh
  if (testType === "ALL" || testType === TEST_TYPES["meta-refresh"]) {
    if (data?.metaRefresh?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.metaRefresh?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaRefresh?.data?.testResult?.status,
            id: 'meta-refresh',
            message: data?.metaRefresh?.data?.testResult?.message,
          },
        ]
      }
    } else if (data?.metaRefresh?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaRefresh?.data?.testResult?.status,
            id: 'meta-refresh',
            message: data?.metaRefresh?.data?.testResult?.message,
          },
        ]
      }
    }
  }

  // custom-404
  if (testType === "ALL" || testType === TEST_TYPES["custom-404"]) {
    if (data?.custom404?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('custom-404')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.custom404?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('custom-404')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.custom404?.data?.testResult?.status,
            id: 'custom-404',
            message: data?.custom404?.data?.testResult?.message,
          },
        ]
      }
    } else if (data?.custom404?.data?.testResult?.status === 3) {
      negativeCounter++
      failedTestArray.push('custom-404')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.custom404?.data?.testResult?.status,
            id: 'custom-404',
            message: data?.custom404?.data?.testResult?.message,
          },
        ]
      }
    }
  }

  // layout-shift
  if (testType === "ALL" || testType === TEST_TYPES["layout-shift"]) {
    if (data?.layoutShift?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.layoutShift?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.layoutShift?.data?.testResults?.status,
            id: 'layout-shift',
            message: data?.layoutShift?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.layoutShift?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.layoutShift?.data?.testResults?.status,
            id: 'layout-shift',
            message: data?.layoutShift?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // contentful-paint
  if (testType === "ALL" || testType === TEST_TYPES["contentful-paint"]) {
    if (data?.contentfulPaint?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.contentfulPaint?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentfulPaint?.data?.testResults?.status,
            id: 'contentful-paint',
            message: data?.contentfulPaint?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.contentfulPaint?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentfulPaint?.data?.testResults?.status,
            id: 'contentful-paint',
            message: data?.contentfulPaint?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // directory-browsing
  if (testType === "ALL" || testType === TEST_TYPES["directory-browsing"]) {
    if (data?.directoryBrowsing?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('directory-browsing')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.directoryBrowsing?.data?.testResults?.status === 2) {
      // eslint-disable-next-line no-unused-vars
      warningCounter++
      warningTestArray.push('directory-browsing')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.directoryBrowsing?.data?.testResults?.status,
            id: 'directory-browsing',
            message: data?.directoryBrowsing?.data?.testResults?.message,
          },
        ]
      }
    } else  if (data?.directoryBrowsing?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('directory-browsing')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.directoryBrowsing?.data?.testResults?.status,
            id: 'directory-browsing',
            message: data?.directoryBrowsing?.data?.testResults?.message,
          },
        ]
      }
    }
  }

  // competitor-domain
  if (testType === "ALL" || testType === TEST_TYPES["competitor-domain"]) {
    if (data?.domainCompetitors?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('competitor-domain')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.domainCompetitors?.data?.testResults?.status === 2) {
      // eslint-disable-next-line no-unused-vars
      warningCounter++
      warningTestArray.push('competitor-domain')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domainCompetitors?.data?.testResults?.status,
            id: 'competitor-domain',
            message: data?.domainCompetitors?.data?.testResults?.message,
          },
        ]
      }
    } else  if (data?.domainCompetitors?.data?.testResults?.status === 3)  {
      negativeCounter++
      failedTestArray.push('competitor-domain')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domainCompetitors?.data?.testResults?.status,
            id: 'competitor-domain',
            message: data?.domainCompetitors?.data?.testResults?.message,
          },
        ]
      }
    }
  }

    // dns-security
    if (testType === "ALL" || testType === TEST_TYPES["dns-security"]) {
      if (data?.dnsSecurityTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('dns-security')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.dnsSecurityTest?.data?.testResults?.status === 2) {
        // eslint-disable-next-line no-unused-vars
        warningCounter++
        warningTestArray.push('dns-security')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.dnsSecurityTest?.data?.testResults?.status,
              id: 'dns-security',
              message: data?.dnsSecurityTest?.data?.testResults?.message,
            },
          ]
        }
      } else  if (data?.dnsSecurityTest?.data?.testResults?.status === 3)  {
        negativeCounter++
        failedTestArray.push('dns-security')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.dnsSecurityTest?.data?.testResults?.status,
              id: 'dns-security',
              message: data?.dnsSecurityTest?.data?.testResults?.message,
            },
          ]
        }
      }
    }

    // no-follow
    if (testType === "ALL" || testType === TEST_TYPES["no-follow"]) {
      if (data?.noFollow?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('no-follow')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.noFollow?.data?.testResults?.status === 2) {
        // eslint-disable-next-line no-unused-vars
        warningCounter++
        warningTestArray.push('no-follow')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.noFollow?.data?.testResults?.status,
              id: 'no-follow',
              message: data?.noFollow?.data?.testResults?.message,
            },
          ]
        }
      } else  if (data?.noFollow?.data?.testResults?.status === 3)  {
        negativeCounter++
        failedTestArray.push('no-follow')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.noFollow?.data?.testResults?.status,
              id: 'no-follow',
              message: data?.noFollow?.data?.testResults?.message,
            },
          ]
        }
      }
    }

    // disalllow-directive
    if (testType === "ALL" || testType === TEST_TYPES["disallow-directive"]) {
      if (data?.disallowDirectiveChecker?.data?.finalRes?.status === 1) {
        positiveCounter++
        passedTestArray.push('disalllow-directive')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.disallowDirectiveChecker?.data?.finalRes?.status === 2) {
        // eslint-disable-next-line no-unused-vars
        warningCounter++
        warningTestArray.push('disalllow-directive')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.disallowDirectiveChecker?.data?.finalRes?.status,
              id: 'disalllow-directive',
              message: data?.disallowDirectiveChecker?.data?.finalRes?.message,
            },
          ]
        }
      } else  if (data?.disallowDirectiveChecker?.data?.finalRes?.status === 3)  {
        negativeCounter++
        failedTestArray.push('disalllow-directive')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.disallowDirectiveChecker?.data?.finalRes?.status,
              id: 'disalllow-directive',
              message: data?.disallowDirectiveChecker?.data?.finalRes?.message,
            },
          ]
        }
      }
    }

    // desktop-screenshot
    if (testType === "ALL" || testType === TEST_TYPES["desktop-screenshot"]) {
      if (data?.desktopSnapShotTest?.data?.length > 2) {
        positiveCounter++
        passedTestArray.push('desktop-screenshot')
        if (isUrlData) {
          serverS++
        }
      } else  if (data?.desktopSnapShotTest?.data?.length < 2)  {
        negativeCounter++
        failedTestArray.push('desktop-screenshot')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.desktopSnapShotTest?.data?.testResults?.status,
              id: 'desktop-screenshot',
              message: data?.desktopSnapShotTest?.data?.testResults?.message,
            },
          ]
        }
      }
    }

    // mobile-screenshot
    if (testType === "ALL" || testType === TEST_TYPES["mobile-screenshot"]) {
      if (data?.mobileSnapShotTest?.data?.length > 2) {
        positiveCounter++
        passedTestArray.push('mobile-screenshot')
        if (isUrlData) {
          serverS++
        }
      } else  if (data?.mobileSnapShotTest?.data?.length < 1)  {
        negativeCounter++
        failedTestArray.push('mobile-screenshot')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.mobileSnapShotTest?.data?.testResults?.status,
              id: 'mobile-screenshot',
              message: data?.mobileSnapShotTest?.data?.testResults?.message,
            },
          ]
        }
      }
    }

     // keyword-cloud
     if (testType === "ALL" || testType === TEST_TYPES["keyword-cloud"]) {
      if (data?.keywordCloudTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('keyword-cloud')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.keywordCloudTest?.data?.testResults?.status === 2) {
        // eslint-disable-next-line no-unused-vars
        warningCounter++
        warningTestArray.push('keyword-cloud')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.keywordCloudTest?.data?.testResults?.status,
              id: 'keyword-cloud',
              message: data?.keywordCloudTest?.data?.testResults?.message,
            },
          ]
        }
      } else  if (data?.keywordCloudTest?.data?.testResults?.status === 3)  {
        negativeCounter++
        failedTestArray.push('keyword-cloud')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.keywordCloudTest?.data?.testResults?.status,
              id: 'keyword-cloud',
              message: data?.keywordCloudTest?.data?.testResults?.message,
            },
          ]
        }
      }
    }

  return {
    updatedErrorsArray,
    positiveCounter,
    negativeCounter,
    commonSeo,
    commonSeoS,
    commonSeoW,
    commonSeoF,
    speedOptS,
    speedOptW,
    speedOptF,
    serverS,
    serverW,
    serverF,
    mobileUseS,
    mobileUseW,
    mobileUseF,
    advanceS,
    advanceW,
    advanceF,
  }
}
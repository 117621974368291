import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import ResetPassword from '../../components/auth/ResetPassword'
import { useDispatch, useSelector } from 'react-redux'

import { Card, CardBody } from 'reactstrap'
import ColoredModal from '../modals/ColorModal'

const ResetPasswordPage = () => {
  const [resetFlag, setResetFlag] = useState('')
  const registerSuccess = useSelector(({ register }) => register.registerSuccess)
  const registerError = useSelector(({ register }) => register.registerError)
  const errorMessage = useSelector(({ register }) => register.errorMessage)
  const dispatch = useDispatch()

  const resetPassoword = (email) => {
    dispatch({ type: 'RESET_PASSWORD', payload: email })
  }

  useEffect(() => {
    if (registerSuccess) {
      // dispatch({ type: 'REGISTER_CLEAR' })
      // alert('Please check your email to reset your password.')
      setResetFlag(true)
    }
  }, [registerSuccess])

  return (
    <React.Fragment>
      <Helmet title="Reset Password" />
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">Enter your email to reset your password.</p>
      </div>
      {registerError ? (
        <ColoredModal
          isOpen={registerError}
          message={errorMessage}
          onClose={() => dispatch({ type: 'REGISTER_CLEAR' })}
        />
      ) : (
        <></>
      )}
      {resetFlag ? (
        <ColoredModal
          type={'success'}
          isOpen={resetFlag}
          message={'Please check your email to reset your password'}
          onClose={() => {
            setResetFlag(false)
            dispatch({ type: 'REGISTER_CLEAR' })
          }}
        />
      ) : (
        <></>
      )}
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <ResetPassword resetPassoword={resetPassoword} />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ResetPasswordPage

import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import * as Icon from 'react-feather'
import { Table } from 'react-bootstrap'

const SSLHttpTest = ({ data }) => {
  return (
    <>
      {data && data?.sslFreakTest ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.sslFreakTest?.data?.testResults?.status ? (
                ''
              ) : data?.sslFreakTest?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.sslFreakTest?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              {' '}
              <p>{data?.sslFreakTest?.data?.testResults?.message}</p>{' '}
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              {/* {data?.sslFreakTest?.data?.sslFreak?.sslHopperData?.body?.label4 &&
              data?.sslFreakTest?.data?.sslFreak?.sslHopperData?.body?.label4?.length ? (
                <>
                  {data?.sslFreakTest?.data?.sslFreak?.sslHopperData?.body?.label4.map((item, index) => {
                    return (
                      <div key={`sslhopperData-${index + 1}`}>
                        {item.status ? (
                          <Icon.Check size={16} style={{ marginRight: 5 }} color="green" />
                        ) : (
                          <Icon.X size={16} style={{ marginRight: 5 }} color="red" />
                        )}
                        <span className="my-0">{item.text}</span>
                      </div>
                    )
                  })}
                  <div>
                    {data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.length &&
                    data?.sslFreakTest?.data?.sslFreak?.sslFreakData.includes('Not') ? (
                      <Icon.Check size={16} style={{ marginRight: 5 }} color="green" />
                    ) : (
                      <Icon.X size={16} style={{ marginRight: 5 }} color="red" />
                    )}
                    {data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.length ? (
                      <span>&nbsp;{`${data?.sslFreakTest?.data?.sslFreak?.sslFreakData.slice(5)}`}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
              <br />
              {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.label3 &&
              data?.sslFreakTest?.data?.sslFreak?.sslCertData?.label3?.length ? (
                <h6>Certificate Chain</h6>
              ) : (
                <></>
              )}
              <ul>
                {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.label3 &&
                data?.sslFreakTest?.data?.sslFreak?.sslCertData?.label3?.length ? (
                  <>
                    {' '}
                    {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.label3.map((item, index) => {
                      return (
                        <li
                          key={`sslFreak-${index + 1}`}
                          style={{ marginBottom: 10 }}
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      )
                    })}
                  </>
                ) : (
                  <></>
                )}
              </ul> */}

              {data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.length ?
                <p className='mb-0'>
                  - {data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.includes("https") ? 
                    data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.split("https")[1] : 
                    data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.includes("http") ?  
                    data?.sslFreakTest?.data?.sslFreak?.sslFreakData?.split("http")[1] : null
                }
              </p> : null
              }

              {
                data?.sslFreakTest?.data?.sslFreak?.sslCertData ? 
                (<><p className='mb-0'>- {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.certificateExpire}</p>
                <p className='mb-0'>- {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.ipAddress}</p>
                <p className='mb-0'>- {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.listedName}</p>
                <p className='mb-0'>- Server Type: {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.serverType}</p> </>) : null
              }
              <br />
             {   data?.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate ? (
                  <Table responsive className="custom-table-report">
                  <thead>
                    <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }} colSpan="2">
                      Certificate Chain:
                    </th>
                  </thead>
                  <tbody>
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.commonName ? (
                      <tr>
                        <td> Common Name</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.commonName} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.sanList ? (
                      <tr>
                        <td> Subject Alternative Names (SANs)</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.sanList?.map(item => `${item}, `)} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.notValidBefore ? (
                      <tr>
                        <td> Not Valid Before</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.notValidBefore} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.notValidAfter ? (
                      <tr>
                        <td> Not Valid After</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.notValidAfter} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.issuer ? (
                      <tr>
                        <td> Issuer</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.serverCertificate?.issuer} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                </Table>
                ) : null}
                <br />
              {data?.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate ? (
                <Table responsive className="custom-table-report">
                <thead>
                  <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }} colSpan="2">
                    Intermediate certificate:
                  </th>
                </thead>
                <tbody>
                  {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.commonName ? (
                    <tr>
                      <td> Common Name</td>
                      <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.commonName} </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.organization ? (
                    <tr>
                      <td> Organization</td>
                      <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.organization} </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.location ? (
                    <tr>
                      <td> Location</td>
                      <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.location} </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.notValidBefore ? (
                    <tr>
                      <td> Not Valid Before</td>
                      <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.notValidBefore} </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.notValidAfter ? (
                    <tr>
                      <td> Not Valid After</td>
                      <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.notValidAfter} </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.issuer ? (
                    <tr>
                      <td> Issuer</td>
                      <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.intermediateCertificate?.issuer} </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              </Table>
              ) : null}
                <br />
               { data?.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate ? (
                  <Table responsive className="custom-table-report">
                  <thead> 
                    <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }} colSpan="2">
                      Root certificate:
                    </th>
                  </thead>
                  <tbody>
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.commonName ? (
                      <tr>
                        <td> Common Name</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.commonName} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.organization ? (
                      <tr>
                        <td> Organization</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.organization} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.location ? (
                      <tr>
                        <td> Location</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.location} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.notValidBefore ? (
                      <tr>
                        <td> Not Valid Before</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.notValidBefore} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.notValidAfter ? (
                      <tr>
                        <td> Not Valid After</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.notValidAfter} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.issuer ? (
                      <tr>
                        <td> Issuer</td>
                        <td> {data.sslFreakTest?.data?.sslFreak?.sslCertData?.rootCertificate?.issuer} </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                </Table>
                ) : null}
               
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SSLHttpTest

import {
  GET_TRACK_KEYWORDS,
  KEYWORD_POSITION_HISTORY,
} from '../../actionType'

export const getTrackKeywords = (data) => ({
  type: GET_TRACK_KEYWORDS,
  payload: data,
})

export const getKeywordsPositionHistory = (data) => ({
  type: KEYWORD_POSITION_HISTORY,
  payload: data,
})

import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  PictureOutlined,
} from '@ant-design/icons'
import { Card, Row, Col, Typography, Space, Button, Divider, Input, message, Progress, Skeleton } from 'antd'
import ImgCrop from 'antd-img-crop'
import Dragger from 'antd/lib/upload/Dragger'
import { useNavigate } from 'react-router-dom'
import TextArea from 'antd/lib/input/TextArea'
import { Form, FormikProvider } from 'formik'
import { useEffect, useState } from 'react'
import axios from 'axios'

import { useNewTemplateFormik } from './useNewTemplateFormik'

import { useDispatch, useSelector } from 'react-redux'
import { createTemplateClear } from 'redux/actions/reporting/template'

import './style.scss'

export const TemplateForms = () => {
  const { Title } = Typography
  const templateSuccess = useSelector(({ template }) => template.isCreateTemplateSuccess)
  const createTemplateData = useSelector(({ template }) => template.createTemplateData)
  const dispatch = useDispatch()
  const [presignedUrl, setPresignedUrl] = useState('')

  const formik = useNewTemplateFormik(presignedUrl)
  const token = localStorage.getItem('token')

  const REACT_APP_IMAGE_UPLOAD_URL = process.env.REACT_APP_IMAGE_UPLOAD_URL

  const { values, handleSubmit, errors, touched, handleChange, handleBlur } = formik || {}

  const beforeUpload = async (file) => {
    if (file.status && file.status !== 'done') {
      return false
    }

    const allowedFileTypes = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg']
    if (!allowedFileTypes.includes(file.type)) {
      message.error('Invalid file type. Please upload a PNG, GIF, JPG, or JPEG file.')
      return false
    }
    // Check file size
    const fileSizeInBytes = file.size
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024)
    if (fileSizeInMB > 1) {
      message.error('File size exceeds the maximum limit of 1MB')
      return false
    }
    if (!(fileSizeInMB > 1) && allowedFileTypes.includes(file.type)) {
      await fetchPresignedUrl(file)
    }

    return false
  }

  const fetchPresignedUrl = async (file) => {
    const { type, name } = file
    const params = {
      fileType: type,
      filename: name,
      token,
    }

    try {
      const response = await axios.get(REACT_APP_IMAGE_UPLOAD_URL, { params })
      const { signedRequest, url } = response?.data

      const options = {
        headers: {
          'Content-Type': type,
        },
      }

      await axios.put(signedRequest, file, options)
      setPresignedUrl(url)
    } catch (error) {
      console.error('Failed to fetch presigned URL', error)
      message.error('Failed to fetch presigned URL')
    }
  }

  const history = useNavigate()

  useEffect(() => {
    if (templateSuccess) {
      dispatch(createTemplateClear())
      dispatch({ type: 'WHITE_LABEL_TEMPLATE_CREATE_CLEAR' })
      setPresignedUrl('')
      if (createTemplateData && createTemplateData.resData) {
        message.success(`Template created successfully`)
        history('/reporting/white-label/templates', {
          state: { templateActiveTab: 'templates' },
        })
      }
    }
  }, [templateSuccess, dispatch, history, createTemplateData])

  return (
    <Card className="new-template-card">
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <div className="left-side-section">
                <Space align="center" className="pb-3">
                  <Button
                    type="link"
                    icon={<ArrowLeftOutlined className="back-icon" />}
                    size="small"
                    onClick={() => history('/reporting/white-label/templates')}
                  />
                  <Title level={4} className="mb-0">
                    PDF Brand Template
                  </Title>
                </Space>
                <Title level={5} className="mb-0 pb-2 create-template-description">
                  Apply your brand basic template for all future Branded PDFs
                </Title>
                <Divider />
                <div className="input-filed-with-label">
                  <label className="template-name-label">Template name</label>
                  <Input
                    placeholder="Template name goes here"
                    className="template-name-input"
                    name="templateName"
                    value={values.templateName}
                    status={errors.templateName && touched.templateName ? 'error' : ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.templateName && touched.templateName ? (
                    <div className="text-danger">{errors.templateName}</div>
                  ) : null}
                </div>

                <Space align="center" className="pb-1">
                  <Button type="primary" size="small" shape="circle" className="one-number-btn">
                    1
                  </Button>
                  <Title level={4} className="mb-0 company-log-title">
                    Company logo
                  </Title>
                </Space>
                <Title level={4} className="mb-0 create-template-description">
                  Allowed types (max 1Mb): png, gif, jpg or jpeg
                </Title>
                <ImgCrop fillColor='transparent' showGrid rotationSlider aspectSlider useRatio cropShape="rect">
                  <Dragger
                    accept="image/*"
                    name="companyLogo"
                    multiple={false}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    showUploadList={false}
                    className="company-logo-upload"
                  >
                    <Space className="image-with-text-div" align="start">
                      {presignedUrl ? (
                        <img
                          src={presignedUrl}
                          alt="company-logo"
                          width={100}
                          height={100}
                          className="company-logo-preview"
                        />
                      ) : (
                        <PictureOutlined className="picture-icon" />
                      )}
                      <p className="ant-upload-text">Drag and drop or select your logo here </p>
                    </Space>
                  </Dragger>
                </ImgCrop>
                {errors.companyLogo && touched.companyLogo ? (
                  <div className="text-danger">{errors.companyLogo}</div>
                ) : null}
                <Divider />
                <Space align="center" className="pb-1">
                  <Button type="primary" size="small" shape="circle" className="one-number-btn">
                    2
                  </Button>
                  <Title level={4} className="mb-0 company-log-title">
                    Report header
                  </Title>
                </Space>
                <TextArea
                  maxLength={144}
                  placeholder="Header message goes here."
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  className="template-name-input"
                  name="reportHeader"
                  values={values.reportHeader}
                  onChange={handleChange}
                />
                <Divider />
                <Space align="center" className="pb-1">
                  <Button type="primary" size="small" shape="circle" className="one-number-btn">
                    3
                  </Button>
                  <Title level={4} className="mb-0 company-log-title">
                    Report footer
                  </Title>
                </Space>
                <TextArea
                  maxLength={144}
                  placeholder="Footer message goes here."
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  className="template-name-input mb-4"
                  name="reportFooter"
                  values={values.reportFooter}
                  onChange={handleChange}
                />
                <Button htmlType="submit" type="primary" className="save-template-btn">
                  Save template
                </Button>
              </div>
            </Form>
          </FormikProvider>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Card className="right-side-section">
            <div className="header-container d-flex align-items-center">
              <div className="template-logo">
                <Button type="primary" size="small" shape="circle" className="step-counter">
                  1
                </Button>
                {presignedUrl ? (
                  <img
                    src={presignedUrl}
                    alt="company-logo"
                    width={100}
                    height={100}
                    className="company-logo-preview"
                  />
                ) : (
                  <PictureOutlined className="picture-icon" />
                )}
              </div>
              <Divider type="vertical" className="vertical-line" />
              <div className="template-header">
                <Button type="primary" size="small" shape="circle" className="step-counter">
                  2
                </Button>
                <span className="header-title">
                  {values?.reportHeader ? values?.reportHeader : 'Sample text in the header'}
                </span>
              </div>
            </div>
            <Card
              bordered
              className="template-seo-report"
              title={
                <>
                  <span className="title">https://your-url-goes-here.com </span>
                  <p className="description mb-0">SEO Report</p>
                </>
              }
            >
              <div className="summary-graph">
                <div className="summary-graph-wrapper">
                  <div className="summary-graph-total">
                    <Progress
                      type="circle"
                      className=""
                      percent={66}
                      format={(percent) => `${percent} / 100`}
                      strokeColor={{ '66%': '#3f81ea' }}
                    />
                    <span className="progress-label">SEO Score</span>
                  </div>
                </div>
                <div className="summary-graph-split">
                  <div className="progress-failed mb-2">
                    <label>12 Failed</label>
                    <Progress type="line" status="exception" percent={12} showInfo={false} />
                  </div>
                  <div className="progress-warning mb-2">
                    <label>0 Warnings</label>
                    <Progress type="line" showInfo={false} />
                  </div>
                  <div className="progress-passed">
                    <label>39 Passed</label>
                    <Progress
                      type="line"
                      showInfo={false}
                      percent={39}
                      strokeColor={{ '0%': '#34bc6e', '39%': '#34bc6e' }}
                    />
                  </div>
                </div>
              </div>
            </Card>
            <Card
              title="Common SEO issues"
              className="mt-4 mb-4 common-seo-issue-card"
              bordered
              extra={
                <div className="d-flex gap-4">
                  <div>
                    <label>Score:</label>
                    <Progress percent={0} showInfo={false} />
                  </div>
                  <div>
                    <label>Failed: 2</label>
                    <Progress status="exception" percent={2} showInfo={false} />
                  </div>
                  <div>
                    <label>Warnings: 5</label>
                    <Progress
                      className="w-100"
                      percent={5}
                      showInfo={false}
                      strokeColor={{ '0%': ' #ffab00', '5%': ' #ffab00' }}
                    />
                  </div>
                  <div>
                    <label>Passed: 13</label>
                    <Progress
                      percent={13}
                      showInfo={false}
                      strokeColor={{ '0%': '#34bc6e', '39%': '#34bc6e' }}
                    />
                  </div>
                </div>
              }
            >
              <Row justify="space-around" align="middle">
                <Col span={10} className="d-flex align-items-center gap-2">
                  <CheckCircleFilled className="passed status-icon" />
                  <Skeleton
                    size="small"
                    block="true"
                    shape="round"
                    className="icon-skeleton"
                    title={true}
                    paragraph={false}
                  />
                </Col>
                <Col span={14}>
                  <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                </Col>
              </Row>
              <Divider className="mt-0 mb-0" />
              <Row>
                <Col span={10} className="d-flex align-items-center gap-2">
                  <CloseCircleFilled className="failed status-icon" />
                  <Skeleton
                    size="small"
                    block="true"
                    shape="round"
                    className="icon-skeleton"
                    title={true}
                    paragraph={false}
                  />
                </Col>
                <Col span={14}>
                  <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                </Col>
              </Row>
              <Divider className="mt-0 mb-0" />
              <Row>
                <Col span={10} className="d-flex align-items-center gap-2">
                  <ExclamationCircleFilled className="warning status-icon" />
                  <Skeleton
                    size="small"
                    block="true"
                    shape="round"
                    className="icon-skeleton"
                    title={true}
                    paragraph={false}
                  />
                </Col>
                <Col span={14}>
                  <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                </Col>
              </Row>
              <Divider className="mt-0 mb-0" />
              <Row>
                <Col span={10} className="d-flex align-items-center gap-2">
                  <div className="status-icon default-icon" />
                  <Skeleton
                    size="small"
                    block="true"
                    shape="round"
                    className="icon-skeleton"
                    title={true}
                    paragraph={false}
                  />
                </Col>
                <Col span={14}>
                  <Skeleton size="small" shape="round" title={false} paragraph={{ rows: 2 }} />
                </Col>
              </Row>
            </Card>
            <Divider className="mt-0 mb-0" />
            <div className="template-footer">
              <Button type="primary" size="small" shape="circle" className="step-counter">
                3
              </Button>
              <span className="title">
                {values?.reportFooter ? values?.reportFooter : 'Sample text in the footer'}
              </span>
            </div>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-scroll'
import * as Scroll from 'react-scroll'
import { Accordion, Badge, ProgressBar, Button } from 'react-bootstrap'

import * as _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faPen } from '@fortawesome/free-solid-svg-icons'

import { Loader } from 'components/uiCore'
import usePalette from '../../hooks/usePalette'
import { Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap'
import PieChart from '../charts/ApexCharts/Pie'
import { getUrlReport } from '../../redux/actions/freeTools/index'
import {
  getUserData,
  getBacklinksStats,
  getBacklinksMostRecently,
  patchUserDomainsDashboard,
} from '../../redux/actions/dashboard/index'
import { useDispatch, useSelector } from 'react-redux'
import TitleTest from '../free-trial/tests-components/titleTest'
import DescriptionTest from '../free-trial/tests-components/descriptionTest'
import Issues from '../dashboards/IssuesViewReport'

import ResultPreviewTest from './tests-components/resultPreview'
import MostCommonTest from './tests-components/MostCommonTest'
import SSLHttpTest from './tests-components/SslTest'
import TlsLogjam from './tests-components/TlsLogjam'
import Http2Test from './tests-components/http2'
import DnsSecurityTest from './tests-components/DnsSecurityTest'
import BrotilTest from './tests-components/BrotilTest'
import UrlCanonicalization from './tests-components/UrlCanonicalization'
import SpfRecord from './tests-components/SpfRecord'
import SiteSpeed from './tests-components/SiteSpeed'
import ServerSignature from './tests-components/ServerSignature'
import SafeBrowsing from './tests-components/SafeBrowsing'
import RedirectTest from './tests-components/RedirectTest'
import PlainTextEmail from './tests-components/PlainTextEmail'
import PageCache from './tests-components/PageCache'
import NoIndexChecker from './tests-components/NoIndexChecker'
import NestedTable from './tests-components/NestedTable'
import KeywordCloudTest from './tests-components/KeywordCloudTest'
import HttpsTest from './tests-components/HttpsTest'
import HtmlPageSize from './tests-components/HtmlPageSize'
import HtmlCompression from './tests-components/HtmlCompression'
import GoogleAnalytics from './tests-components/GoogleAnalytics'
import FramesetTest from './tests-components/FramesetTest'
import FlashTest from './tests-components/FlashTest'
import FaviconCheckerTest from './tests-components/FaviconCheckerTest'
import DocType from './tests-components/DocType'
import DisallowDirective from './tests-components/DisallowDirective'
import CSSCache from './tests-components/CSSCache'
import CDNUsage from './tests-components/CDNUsage'
import CSSAnalysis from './tests-components/CSSAnalysis'
import CSSMinification from './tests-components/CSSMinification'
import DesktopScreenshot from './tests-components/DesktopScreenshot'
import MobileScreenshot from './tests-components/MobileScreenshot'
import HeadingAnalysis from './tests-components/HeadingAnalysis'
import HtmlValidation from './tests-components/HtmlValidation'
import ImageAlt from './tests-components/ImageAlt'
import LinksALTDescriptions from './tests-components/LinksALTDescriptions'
import RelatedKeywordsTest from './tests-components/RelatedKeywordsTest'
import SeoFriendlyUrlAnalysis from './tests-components/SeoFriendlyUrlAnalysis'
import SiteMapData from './tests-components/SiteMapData'
import ImageCache from './tests-components/ImageCache'
import JSMinification from './tests-components/JSMinification'
import InlineCss from './tests-components/InlineCss'
import JSCache from './tests-components/JSCache'
import JSError from './tests-components/JSError'
import MicroDataSchema from './tests-components/MicroDataSchema'
import NoFollow from './tests-components/NoFollow'
import PageObject from './tests-components/PageObject'
import SocialMediaTest from './tests-components/SocialMediaTest'
import CommonSEOHOC from './tests-components/CommonSEOHOC'
import ModalHOC from './tests-components/ModalHOC'
import RenderBlocking from './tests-components/RenderBlocking'
import MordenImage from './tests-components/MordenImage'
import AspectRatio from './tests-components/AspectRatio'
import ResponsiveImage from './tests-components/ResponsiveImage'
import ConsoleErrors from './tests-components/ConsoleErrors'
import DomSize from './tests-components/DomSize'
import BootupTime from './tests-components/bootupTime'
import CharacterSet from './tests-components/characterSet'
import Viewport from './tests-components/viewport'
import MetaRefresh from './tests-components/MetaRefresh'
import AdsTxt from './tests-components/AdsTxt'
import Custom404 from './tests-components/Custom404'
import LayoutShift from './tests-components/LayoutShift'
import ContentfulPaint from './tests-components/ContentfulPaint'
import SocialMediaMetaTags from './tests-components/socialMediaMetaTags'
import KeywordUsageTest from './tests-components/KeywordUsage'
import RobotsText from './tests-components/robotsTxt'
import { commonSEOIssueInfo } from '../../constants/strings'
import HSTS from './tests-components/hsts'
import CompetitorDomain from './tests-components/CompetitorDomain'

import BrokenLinks from './tests-components/Brokenlinks'
import CanonicalURL from './tests-components/CanonicalURL'
import DirectoryBrowsing from './tests-components/DirectoryBrowsing'
import crossIcon from '../../assets/img/icons/cross.png'
import tickIcon from '../../assets/img/icons/tick-mark.png'
import warningIcon from '../../assets/img/icons/warning.png'
import UnsafeCrossOriginLinks from './tests-components/UnsafeCrossOriginLinks'
import Backlinks from './tests-components/Backlinks'
import { getSEOScore } from '../../constants/Utils'
import MenuTitleTest from './tests-components/menuTitleTest'
import FontSizeTest from './tests-components/FontSizeTest'
import TapTargets from './tests-components/TapTargets'
import ContentWidth from './tests-components/ContentWidth'
import SpeedIndex from './tests-components/SpeedIndex'
import FirstContentfulPaint from './tests-components/FirstContentfulPaint'
import LargestContentfulPaint from './tests-components/LargestContentfulPaint'
import CumulativeLayoutShift from './tests-components/CumulativeLayoutShift'
import TimetoInteractiveTest from './tests-components/TimetoInteractiveTest'
import FirstInputDelay from './tests-components/FirstInputDelay'
import CachePolicy from './tests-components/CachePolicy.jsx'
import AdvancedConsoleErrors from 'pages/free-trial/tests-components/AdvancedConsoleError.js'
import moment from 'moment'
import { CLEAR_GENERATE_TICKET } from 'redux/actionType'
import { message } from 'antd'

var Element = Scroll.Element
var scroller = Scroll.scroller

let interval
const DefaultNew = () => {
  const palette = usePalette()

  const urlData = useSelector(({ freeTools }) => freeTools?.urlReport?.urlDataId)
  const competitor1 = useSelector(({ freeTools }) => freeTools?.urlReport?.competitor1)
  const competitor2 = useSelector(({ freeTools }) => freeTools?.urlReport?.competitor2)
  const competitor3 = useSelector(({ freeTools }) => freeTools?.urlReport?.competitor3)
  const competitor4 = useSelector(({ freeTools }) => freeTools?.urlReport?.competitor4)
  const competitor5 = useSelector(({ freeTools }) => freeTools?.urlReport?.competitor5)
  const keywordsData = useSelector(({ dashboard }) => dashboard?.keywordsData)
  const mostRecentlyBacklinksData = useSelector(({ dashboard }) => dashboard?.mostRecentlyBacklinksData)
  const userData = useSelector(({ dashboard }) => dashboard?.userData)

  const {
    generateTicketLoader,
    generateTicketFailure,
    generateTicketSuccess,
    generateTicketError,
    generateTicketMessage,
  } = useSelector(({ dashboard }) => dashboard)

  const [positiveCounter, setPositiveCounter] = useState(0)
  const [negativeCounter, setNegativeCounter] = useState(0)
  const [warningCounter, setWarningCounter] = useState(0)

  const [positiveCounter1, setPositiveCounter1] = useState(0)
  const [negativeCounter1, setNegativeCounter1] = useState(0)
  const [warningCounter1, setWarningCounter1] = useState(0)

  const [positiveCounter2, setPositiveCounter2] = useState(0)
  const [negativeCounter2, setNegativeCounter2] = useState(0)
  const [warningCounter2, setWarningCounter2] = useState(0)

  const [positiveCounter3, setPositiveCounter3] = useState(0)
  const [negativeCounter3, setNegativeCounter3] = useState(0)
  const [warningCounter3, setWarningCounter3] = useState(0)

  const [positiveCounter4, setPositiveCounter4] = useState(0)
  const [negativeCounter4, setNegativeCounter4] = useState(0)
  const [warningCounter4, setWarningCounter4] = useState(0)

  const [positiveCounter5, setPositiveCounter5] = useState(0)
  const [negativeCounter5, setNegativeCounter5] = useState(0)
  const [warningCounter5, setWarningCounter5] = useState(0)

  const [commonSeoSCounter, setCommonSeoSCounter] = useState(0)
  const [commonSeoWCounter, setCommonSeoWCounter] = useState(0)
  const [commonSeoFCounter, setCommonSeoFCounter] = useState(0)

  const [speedOptimizeSCounter, setSpeedOptimizeSCounter] = useState(0)
  const [speedOptimizeWCounter, setSpeedOptimizeWCounter] = useState(0)
  const [speedOptimizeFCounter, setSpeedOptimizeFCounter] = useState(0)

  const [serverSCounter, setServerSCounter] = useState(0)
  const [serverWCounter, setServerWCounter] = useState(0)
  const [serverFCounter, setServerFCounter] = useState(0)

  const [mobileUsabilitySCounter, setMobileUsabilitySCounter] = useState(0)
  const [mobileUsabilityWCounter, setMobileUsabilityWCounter] = useState(0)
  const [mobileUsabilityFCounter, setMobileUsabilityFCounter] = useState(0)

  const [advanceSeoSCounter, setAdvanceSeoSCounter] = useState(0)
  const [advanceSeoWCounter, setAdvanceSeoWCounter] = useState(0)
  const [advanceSeoFCounter, setAdvanceSeoFCounter] = useState(0)

  const [errors, setErrors] = useState([])
  const [cdnUsageDataFlag, setCdnUsageDataFlag] = useState(false)
  const [cssAnalysisFlag, setCssAnalysisFlag] = useState(false)

  const [headingAnalysisFlag, setHeadingAnalysisFlag] = useState(false)
  const [htmlValidationFlag, setHtmlValidationFlag] = useState(false)
  const [inlineCssFlag, setInlinecssFlag] = useState(false)
  const [jsMinificationFlag, setJsMinification] = useState(false)
  const [renderBlockingFlag, setRenderBlockingFlag] = useState(false)
  const [mordenImageFlag, setMordenImageFlag] = useState(false)
  const [domSizeFlag, setDomSizeFlag] = useState(false)
  const [adsTxtFlag, setAdsTxtFlag] = useState(false)
  const [consoleErrorsFlag, setConsoleErrorsFlag] = useState(false)
  const [advancedConsoleErrorsFlag, setAdvancedConsoleErrorsFlag] = useState(false)
  const [aspectRatioFlag, setAspectRatioFlag] = useState(false)
  const [responsiveImageFlag, setResponsiveImageFlag] = useState(false)
  const [microSchemaData, setMicroSchemaData] = useState(false)
  const [socialMediaFlag, setSocialMediaFlag] = useState(false)
  const [cssMinificationFlag, setCssMinificationFlag] = useState(false)
  const [jsErrorFlag, setJsErrorFlag] = useState(false)
  const [pageObjectFlag, setPageObjectFlag] = useState(false)
  const [imageAltFlag, setImageAltFlag] = useState(false)
  const [noFollowFlag, setNoFollowFlag] = useState(false)
  const [imageCacheFlag, setImageCacheFlag] = useState(false)
  const [cachePolicyFlag, setCachePolicyFlag] = useState(false)
  const [jsCacheFlag, setJsCacheFlag] = useState(false)
  const [relatedKeywordsFlag, setRelatedKeywordFlag] = useState(false)
  const [seoUrlAnalysisFlag, setSeoUrlAnalysisFlag] = useState(false)
  const [siteMapDataFlag, setSiteMapDataFlag] = useState(false)
  const [brokenLinkFlag, setBrokenLinkFlag] = useState(false)
  const [dnsSecurityFlag, setDnsSecurityFlag] = useState(false)
  const [unsafeCrossOriginLinksFlag, setUnsafeCrossOriginLinksFlag] = useState(false)
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const [relatedKeywordList, setRelatedKeywordList] = useState([])
  const [totalBacklinks, setTotalBacklink] = useState(0)
  const [referingDomains, setReferringDomain] = useState(0)
  const [mostRecentlyBacklinks, setMostRecentlyBacklinks] = useState()

  const dispatch = useDispatch()
  const location = useLocation()

  let interval2 = []

  const seoCheckupData = location?.state?.url?.items?.[0] || location?.state?.url || {}

  useEffect(() => {
    if (seoCheckupData) {
      const data = localStorage.getItem('userData')
      dispatch(getUrlReport(seoCheckupData?.urlDataId))
      dispatch(patchUserDomainsDashboard())
      dispatch(getBacklinksStats())
      dispatch(getBacklinksMostRecently({ userId: JSON.parse(data)?._id, limit: 10, page: 1 }))
      dispatch(getUserData())
      patchDomains()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (seoCheckupData) {
        let intervalId = setInterval(async () => {
          const data = localStorage.getItem('userData')
          dispatch(patchUserDomainsDashboard())
          dispatch(getBacklinksStats())
          dispatch(getBacklinksMostRecently({ userId: JSON.parse(data)?._id, limit: 10, page: 1 }))
          dispatch(getUserData())
        }, 8000)
        interval2.push(intervalId)
      }
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (urlData && urlData?.url) {
      let splittedValue = urlData?.url?.split('/')?.slice(0, 3)
      let hostName = splittedValue[splittedValue?.length - 1]
      if (hostName?.includes('www.')) {
        hostName = hostName?.replace('www.', '')
      }
      const activeSearchData =
        (userData &&
          userData.searchResults &&
          userData.searchResults.length &&
          _.findLast(
            userData.searchResults,
            (item) => item && item.url && item.url.toLowerCase()?.includes(hostName.toLowerCase()),
          )) ||
        {}
      let selectedKeyword =
        keywordsData?.length > 0 && keywordsData?.find((item) => item?.url?.includes(hostName))
      if (selectedKeyword?.values?.length > 0) {
        let filteredKeyword = selectedKeyword?.values?.filter(
          (item, index, self) => index === self.findIndex((t) => t?.keyword === item?.keyword),
        )

        let finalKeywordsList = filteredKeyword?.filter((item) => {
          return activeSearchData?.keywords?.includes(item.keyword)
        })

        setRelatedKeywordList(finalKeywordsList)
      } else if (relatedKeywordList?.length > 0) {
        let rkArray = [...relatedKeywordList]
        rkArray = []
        setRelatedKeywordList(rkArray)
      }
    }
  }, [keywordsData, usersDomain])

  useEffect(() => {
    return () => {
      interval2.map((id) => {
        clearInterval(id)
      })
      dispatch({ type: 'CLEAR_GET_BACKLINKS_MOST_RECENTLY' })
    }
  }, [])

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setMostRecentlyBacklinks([])
    if (usersDomain?.length > 0) {
      let linksArray = []
      let selectedUserDomain =
        mostRecentlyBacklinksData &&
        mostRecentlyBacklinksData?.length &&
        mostRecentlyBacklinksData?.find(
          (domain) =>
            domain?.items?.[0]?.information?.seoCheckup?.redirect_url ===
            urlData?.information?.seoCheckup?.redirect_url,
        )
      if (selectedUserDomain?.statsData?.[0]) {
        setReferringDomain(selectedUserDomain?.statsData?.[0]?.domains)
        setTotalBacklink(selectedUserDomain?.statsData?.[0]?.backlinks)
      }
      linksArray = selectedUserDomain?.mostRecentlyBacklinksData
      setMostRecentlyBacklinks(linksArray)
    }
  }, [mostRecentlyBacklinksData])

  const patchDomains = () => {
    interval = setInterval(() => {
      dispatch(getUrlReport(seoCheckupData?.urlDataId))
    }, 10000)
  }

  const executeScroll = () => {
    scroller.scrollTo('myScrollToElement', {
      duration: 500,
      delay: 100,
      smooth: true,
      containerId: 'quick-seo-checkup',
      offset: 100, // Scrolls to element + 50 pixels down the page
    })
  }

  useEffect(() => {
    if (urlData) {
      let isUrlData = true
      const assets = getData(urlData, isUrlData)
      const {
        updatedErrorsArray,
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setErrors(updatedErrorsArray)

      setWarningCounter(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)

      setCommonSeoSCounter(commonSeoS)
      setCommonSeoWCounter(commonSeoW)
      setCommonSeoFCounter(commonSeoF)

      setSpeedOptimizeSCounter(speedOptS)
      setSpeedOptimizeWCounter(speedOptW)
      setSpeedOptimizeFCounter(speedOptF)

      setServerSCounter(serverS)
      setServerWCounter(serverW)
      setServerFCounter(serverF)

      setMobileUsabilitySCounter(mobileUseS)
      setMobileUsabilityWCounter(mobileUseW)
      setMobileUsabilityFCounter(mobileUseF)

      setAdvanceSeoSCounter(advanceS)
      setAdvanceSeoWCounter(advanceW)
      setAdvanceSeoFCounter(advanceF)
    }
  }, [urlData])

  useEffect(() => {
    if (competitor1) {
      const assets = getData(competitor1)
      const {
        updatedErrorsArray,
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setWarningCounter1(updatedErrorsArray.filter((item) => item.priority === 2).length)

      setWarningCounter1(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter1(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter1(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [competitor1])

  useEffect(() => {
    if (competitor2) {
      const assets = getData(competitor2)
      const {
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setWarningCounter2(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter2(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter2(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [competitor2])

  useEffect(() => {
    if (competitor3) {
      const assets = getData(competitor3)
      const {
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setWarningCounter3(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter3(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter3(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [competitor3])

  useEffect(() => {
    if (competitor4) {
      const assets = getData(competitor4)
      const {
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setWarningCounter4(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter4(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter4(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [competitor4])

  useEffect(() => {
    if (competitor5) {
      const assets = getData(competitor5)
      const {
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setWarningCounter5(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter5(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter5(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [competitor5])

  useEffect(() => {
    if (!generateTicketLoader && generateTicketFailure) {
      if (generateTicketError) {
        message.error(generateTicketError?.message)
        dispatch({ type: CLEAR_GENERATE_TICKET })
      }
    } else if (!generateTicketLoader && generateTicketSuccess) {
      if (generateTicketMessage) {
        message.success(generateTicketMessage?.message)
        dispatch({ type: CLEAR_GENERATE_TICKET })
      }
    }
  }, [
    generateTicketLoader,
    generateTicketFailure,
    generateTicketSuccess,
    generateTicketError,
    generateTicketMessage,
  ])

  const getData = (data, isUrlData = true) => {
    let updatedErrorsArray = []
    let passedTestArray = []
    let failedTestArray = []
    let warningTestArray = []
    let positiveCounter = 0,
      warningCounter = 0,
      negativeCounter = 0,
      commonSeo = 0,
      commonSeoS = 0,
      commonSeoW = 0,
      commonSeoF = 0,
      speedOptS = 0,
      speedOptW = 0,
      speedOptF = 0,
      serverS = 0,
      serverW = 0,
      serverF = 0,
      mobileUseS = 0,
      mobileUseW = 0,
      mobileUseF = 0,
      advanceS = 0,
      advanceW = 0,
      advanceF = 0

    if (data?.information?.seoCheckup?.description?.descriptionStatus === 1) {
      positiveCounter++
      passedTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.information?.seoCheckup?.description?.descriptionStatus === 2) {
      warningCounter++
      warningTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.description?.descriptionStatus,
            id: 'meta-description',
            message: data?.information?.seoCheckup?.description?.descriptionMessage,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('meta-description')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.description?.descriptionStatus,
            id: 'meta-description',
            message: data?.information?.seoCheckup?.description?.descriptionMessage,
          },
        ]
      }
    }

    if (data?.information?.seoCheckup?.title?.titleStatus === 1) {
      positiveCounter++
      passedTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.information?.seoCheckup?.title?.titleStatus === 2) {
      warningCounter++
      warningTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.title?.titleStatus,
            id: 'meta-title',
            message: data?.information?.seoCheckup?.title?.titleMessage,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('meta-title')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.information?.seoCheckup?.title?.titleStatus,
            id: 'meta-title',
            message: data?.information?.seoCheckup?.title?.titleMessage,
          },
        ]
      }
    }

    //social media Test
    if (data?.microDataSchema?.data?.metaTagsResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.microDataSchema?.data?.metaTagsResults?.status === 2) {
      warningCounter++
      warningTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.metaTagsResults?.status,
            id: 'social-media-meta-tag',
            message: data?.microDataSchema?.data?.metaTagsResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('social-media-meta-tag')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.metaTagsResults?.status,
            id: 'social-media-meta-tag',
            message: data?.microDataSchema?.data?.metaTagsResults?.message,
          },
        ]
      }
    }

    // keyword-usage-test
    if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 2) {
      warningCounter++
      warningTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
            id: 'keyword-usage-test',
            message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('keyword-usage-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
            id: 'keyword-usage-test',
            message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
          },
        ]
      }
    }

    // seo-friendly
    if (data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length === 0) {
      positiveCounter++
      passedTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoS++
      }
    } else {
      negativeCounter++
      failedTestArray.push('seo-friendly')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: 3,
            id: 'seo-friendly',
            message: 'This webpage contains URLs that are not SEO friendly!',
          },
        ]
      }
    }

    // sitemaps
    if (data?.siteMapData?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.siteMapData?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteMapData?.data?.testResults?.status,
            id: 'sitemap-data',
            message: data?.siteMapData?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('sitemap-data')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteMapData?.data?.testResults?.status,
            id: 'sitemap-data',
            message: data?.siteMapData?.data?.testResults?.message,
          },
        ]
      }
    }

    // broken-links
    if (data?.brokenLinks?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('brokenLinks-data')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.brokenLinks?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('brokenLinks-data')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brokenLinks?.data?.testResults?.status,
            id: 'brokenLinks-data',
            message: data?.brokenLinks?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('brokenLinks-data')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brokenLinks?.data?.testResults?.status,
            id: 'broken-links',
            message: data?.brokenLinks?.data?.testResults?.message,
          },
        ]
      }
    }

    // robots
    if (data?.robotsTxtTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.robotsTxtTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.robotsTxtTest?.data?.testResults?.status,
            id: 'robots-txt',
            message: data?.robotsTxtTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('robots-txt')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.robotsTxtTest?.data?.testResults?.status,
            id: 'robots-txt',
            message: data?.robotsTxtTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // cdn-usage
    if (data?.cdnUsageData?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cdnUsageData?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cdnUsageData?.data?.testResult?.status,
            id: 'cdn-usage',
            message: data?.cdnUsageData?.data?.testResult?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('cdn-usage')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cdnUsageData?.data?.testResult?.status,
            id: 'cdn-usage',
            message: data?.cdnUsageData?.data?.testResult?.message,
          },
        ]
      }
    }

    // cssAnalysis
    if (data?.cssAnalysis?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssAnalysis?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssAnalysis?.data?.testResults?.status,
            id: 'css-analysis',
            message: data?.cssAnalysis?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('css-analysis')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssAnalysis?.data?.testResults?.status,
            id: 'css-analysis',
            message: data?.cssAnalysis?.data?.testResults?.message,
          },
        ]
      }
    }

    // cssCache
    if (data?.cssCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('css-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssCache?.data?.testResults?.status,
            id: 'css-cache',
            message: data?.cssCache?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('css-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssCache?.data?.testResults?.status,
            id: 'css-cache',
            message: data?.cssCache?.data?.testResults?.message,
          },
        ]
      }
    }

    // cssMinification
    if (data?.cssMinification?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('css-minification')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cssMinification?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('css-minification')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssMinification?.data?.testResults?.status,
            id: 'css-minification',
            message: data?.cssMinification?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('css-minification')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cssMinification?.data?.testResults?.status,
            id: 'css-minification',
            message: data?.cssMinification?.data?.testResults?.message,
          },
        ]
      }
    }

    // jsMinification
    if (data?.jsMinification?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-minification')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.jsMinification?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-minification')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsMinification?.data?.testResults?.status,
            id: 'js-minification',
            message: data?.jsMinification?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-minification')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsMinification?.data?.testResults?.status,
            id: 'js-minification',
            message: data?.jsMinification?.data?.testResults?.message,
          },
        ]
      }
    }

    // jsCache
    if (data?.jsCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.jsCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsCache?.data?.testResults?.status,
            id: 'js-cache',
            message: data?.jsCache?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsCache?.data?.testResults?.status,
            id: 'js-cache',
            message: data?.jsCache?.data?.testResults?.message,
          },
        ]
      }
    }

    // imageCache
    if (data?.imageCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('image-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.imageCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('image-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageCache?.data?.testResults?.status,
            id: 'image-cache',
            message: data?.imageCache?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('image-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageCache?.data?.testResults?.status,
            id: 'image-cache',
            message: data?.imageCache?.data?.testResults?.message,
          },
        ]
      }
    }

    // cachePolicy
    if (data?.cachePolicy?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('cache-policy')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.cachePolicy?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('cache-policy')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cachePolicy?.data?.testResults?.status,
            id: 'cache-policy',
            message: data?.cachePolicy?.data?.testResults?.message,
          },
        ]
      }
    } else if (data?.cachePolicy?.data?.testResults?.status === 3) {
      negativeCounter++
      failedTestArray.push('cache-policy')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cachePolicy?.data?.testResults?.status,
            id: 'cache-policy',
            message: data?.cachePolicy?.data?.testResults?.message,
          },
        ]
      }
    }

    // imageAlt
    if (data?.imageAlt?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.imageAlt?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageAlt?.data?.testResults?.status,
            id: 'image-alt',
            message: data?.imageAlt?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('image-alt')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.imageAlt?.data?.testResults?.status,
            id: 'image-alt',
            message: data?.imageAlt?.data?.testResults?.message,
          },
        ]
      }
    }

    // descriptiveTest
    if (data?.descriptiveLinkTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.descriptiveLinkTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.descriptiveLinkTest?.data?.testResults?.status,
            id: 'descriptive-test',
            message: data?.descriptiveLinkTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('descriptive-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.descriptiveLinkTest?.data?.testResults?.status,
            id: 'descriptive-test',
            message: data?.descriptiveLinkTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // headingAnalysis
    if (data?.headingAnalysis?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.headingAnalysis?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.headingAnalysis?.data?.testResults?.status,
            id: 'heading-analysis',
            message: data?.headingAnalysis?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('heading-analysis')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.headingAnalysis?.data?.testResults?.status,
            id: 'heading-analysis',
            message: data?.headingAnalysis?.data?.testResults?.message,
          },
        ]
      }
    }

    // docType
    if (data?.docType?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('doc-type')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.docType?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('doc-type')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.docType?.data?.testResults?.status,
            id: 'doc-type',
            message: data?.docType?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('doc-type')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.docType?.data?.testResults?.status,
            id: 'doc-type',
            message: data?.docType?.data?.testResults?.message,
          },
        ]
      }
    }

    // redirect-test
    if (data?.redirectTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.redirectTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.redirectTest?.data?.testResults?.status,
            id: 'redirect-test',
            message: data?.redirectTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('redirect-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.redirectTest?.data?.testResults?.status,
            id: 'redirect-test',
            message: data?.redirectTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // faviconCheckerTest
    if (data?.faviconCheckerTest?.data?.status === 1) {
      positiveCounter++
      passedTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.faviconCheckerTest?.data?.status === 2) {
      warningCounter++
      warningTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.faviconCheckerTest?.data?.status,
            id: 'favicon-test',
            message: data?.faviconCheckerTest?.data?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('favicon-test')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.faviconCheckerTest?.data?.status,
            id: 'favicon-test',
            message: data?.faviconCheckerTest?.data?.message,
          },
        ]
      }
    }

    // flash-test
    if (data?.flashTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('flash-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.flashTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('flash-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.flashTest?.data?.testResults?.status,
            id: 'flash-test',
            message: data?.flashTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('flash-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.flashTest?.data?.testResults?.status,
            id: 'flash-test',
            message: data?.flashTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // html-page-size
    if (data?.htmlPageSize?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.htmlPageSize?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlPageSize?.data?.testResults?.status,
            id: 'html-page-size',
            message: data?.htmlPageSize?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('html-page-size')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlPageSize?.data?.testResults?.status,
            id: 'html-page-size',
            message: data?.htmlPageSize?.data?.testResults?.message,
          },
        ]
      }
    }

    // framesetTest
    if (data?.framesetTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.framesetTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.framesetTest?.data?.testResults?.status,
            id: 'frameset-test',
            message: data?.framesetTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('frameset-test')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.framesetTest?.data?.testResults?.status,
            id: 'frameset-test',
            message: data?.framesetTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // googleAnalytics
    if (data?.googleAnalytics?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.googleAnalytics?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.googleAnalytics?.data?.testResults?.status,
            id: 'google-analytics',
            message: data?.googleAnalytics?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('google-analytics')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.googleAnalytics?.data?.testResults?.status,
            id: 'google-analytics',
            message: data?.googleAnalytics?.data?.testResults?.message,
          },
        ]
      }
    }

    // htmlCompression
    if (data?.htmlCompression?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-compression')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.htmlCompression?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('html-compression')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlCompression?.data?.testResults?.status,
            id: 'html-compression',
            message: data?.htmlCompression?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('html-compression')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlCompression?.data?.testResults?.status,
            id: 'html-compression',
            message: data?.htmlCompression?.data?.testResults?.message,
          },
        ]
      }
    }

    // hsts
    if (data?.hstsTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('hsts')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.hstsTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('hsts')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.hstsTest?.data?.testResults?.status,
            id: 'hsts',
            message: data?.hstsTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('hsts')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.hstsTest?.data?.testResults?.status,
            id: 'hsts',
            message: data?.hstsTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // htmlValidation
    if (data?.htmlValidation?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.htmlValidation?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlValidation?.data?.testResults?.status,
            id: 'html-validation',
            message: data?.htmlValidation?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('html-validation')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.htmlValidation?.data?.testResults?.status,
            id: 'html-validation',
            message: data?.htmlValidation?.data?.testResults?.message,
          },
        ]
      }
    }

    // https-test
    if (data?.httpsTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('https-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.httpsTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('https-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.httpsTest?.data?.testResults?.status,
            id: 'https-test',
            message: data?.httpsTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('https-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.httpsTest?.data?.testResults?.status,
            id: 'https-test',
            message: data?.httpsTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // inline-css
    if (data?.inlineCss?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.inlineCss?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.inlineCss?.data?.testResults?.status,
            id: 'inline-css',
            message: data?.inlineCss?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('inline-css')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.inlineCss?.data?.testResults?.status,
            id: 'inline-css',
            message: data?.inlineCss?.data?.testResults?.message,
          },
        ]
      }
    }

    // js-error
    if (data?.jsError?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-error')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.jsError?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-error')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsError?.data?.testResults?.status,
            id: 'js-error',
            message: data?.jsError?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-error')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.jsError?.data?.testResults?.status,
            id: 'js-error',
            message: data?.jsError?.data?.testResults?.message,
          },
        ]
      }
    }

    // microdata-schema
    if (data?.microDataSchema?.data?.microSchemaResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.microDataSchema?.data?.microSchemaResults?.status === 2) {
      warningCounter++
      warningTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.microSchemaResults?.status,
            id: 'microdata-schema',
            message: data?.microDataSchema?.data?.microSchemaResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('microdata-schema')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.microDataSchema?.data?.microSchemaResults?.status,
            id: 'microdata-schema',
            message: data?.microDataSchema?.data?.microSchemaResults?.message,
          },
        ]
      }
    }

    // nested-table
    if (data?.nestedTable?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('nested-table')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.nestedTable?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('nested-table')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.nestedTable?.data?.testResults?.status,
            id: 'nested-table',
            message: data?.nestedTable?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('nested-table')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.nestedTable?.data?.testResults?.status,
            id: 'nested-table',
            message: data?.nestedTable?.data?.testResults?.message,
          },
        ]
      }
    }

    // no-index
    if (data?.noIndexChecker?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('no-index')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.noIndexChecker?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('no-index')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.noIndexChecker?.data?.testResult?.status,
            id: 'no-index',
            message: data?.noIndexChecker?.data?.testResult?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('no-index')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.noIndexChecker?.data?.testResult?.status,
            id: 'no-index',
            message: data?.noIndexChecker?.data?.testResult?.message,
          },
        ]
      }
    }

    // canonical-url
    if (data?.canonicalUrl?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('canonical-url')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.canonicalUrl?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('canonical-url')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.canonicalUrl?.data?.testResult?.status,
            id: 'canonical-url',
            message: data?.canonicalUrl?.data?.testResult?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('canonical-url')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.canonicalUrl?.data?.testResult?.status,
            id: 'canonical-url',
            message: data?.canonicalUrl?.data?.testResult?.message,
          },
        ]
      }
    }

    // page-cache
    if (data?.pageCache?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('page-cache')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.pageCache?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('page-cache')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageCache?.data?.testResults?.status,
            id: 'page-cache',
            message: data?.pageCache?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('page-cache')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageCache?.data?.testResults?.status,
            id: 'page-cache',
            message: data?.pageCache?.data?.testResults?.message,
          },
        ]
      }
    }

    // page-object
    if (data?.pageObject?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('page-object')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.pageObject?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('page-object')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageObject?.data?.testResults?.status,
            id: 'page-object',
            message: data?.pageObject?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('page-object')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.pageObject?.data?.testResults?.status,
            id: 'page-object',
            message: data?.pageObject?.data?.testResults?.message,
          },
        ]
      }
    }

    // plain-text
    if (data?.plainTextEmail?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('plain-text')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.plainTextEmail?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('plain-text')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.plainTextEmail?.data?.testResults?.status,
            id: 'plain-text',
            message: data?.plainTextEmail?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('plain-text')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.plainTextEmail?.data?.testResults?.status,
            id: 'plain-text',
            message: data?.plainTextEmail?.data?.testResults?.message,
          },
        ]
      }
    }

    // safe-browsing
    if (data?.safeBrowsing?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('safe-browsing')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.safeBrowsing?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('safe-browsing')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.safeBrowsing?.data?.testResults?.status,
            id: 'safe-browsing',
            message: data?.safeBrowsing?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('safe-browsing')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.safeBrowsing?.data?.testResults?.status,
            id: 'safe-browsing',
            message: data?.safeBrowsing?.data?.testResults?.message,
          },
        ]
      }
    }

    // serverSignature
    if (data?.serverSignature?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('server-signature')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.serverSignature?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('server-signature')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.serverSignature?.data?.testResults?.status,
            id: 'server-signature',
            message: data?.serverSignature?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('server-signature')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.serverSignature?.data?.testResults?.status,
            id: 'server-signature',
            message: data?.serverSignature?.data?.testResults?.message,
          },
        ]
      }
    }

    // site-speed
    if (data?.siteSpeed?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('site-speed')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.siteSpeed?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('site-speed')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteSpeed?.data?.testResults?.status,
            id: 'site-speed',
            message: data?.siteSpeed?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('site-speed')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.siteSpeed?.data?.testResults?.status,
            id: 'site-speed',
            message: data?.siteSpeed?.data?.testResults?.message,
          },
        ]
      }
    }

    // social-media
    if (data?.socialMediaTwitterData?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('social-media')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.socialMediaTwitterData?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('social-media')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.socialMediaTwitterData?.data?.testResults?.status,
            id: 'social-media',
            message: data?.socialMediaTwitterData?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('social-media')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.socialMediaTwitterData?.data?.testResults?.status,
            id: 'social-media',
            message: data?.socialMediaTwitterData?.data?.testResults?.message,
          },
        ]
      }
    }

    // spf-record
    if (data?.spfRecord?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('spf-record')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.spfRecord?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('spf-record')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.spfRecord?.data?.testResult?.status,
            id: 'spf-record',
            message: data?.spfRecord?.data?.testResult?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('spf-record')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.spfRecord?.data?.testResult?.status,
            id: 'spf-record',
            message: data?.spfRecord?.data?.testResult?.message,
          },
        ]
      }
    }

    // url-canonicalization
    if (data?.urlCanonicalization?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.urlCanonicalization?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.urlCanonicalization?.data?.testResult?.status,
            id: 'url-canonicalization',
            message: data?.urlCanonicalization?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('url-canonicalization')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.urlCanonicalization?.data?.testResult?.status,
            id: 'url-canonicalization',
            message: data?.urlCanonicalization?.data?.testResults?.message,
          },
        ]
      }
    }

    // brotil-test
    if (data?.brotilTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('brotil-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.brotilTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('brotil-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brotilTest?.data?.testResults?.status,
            id: 'brotil-test',
            message: data?.brotilTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('brotil-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.brotilTest?.data?.testResults?.status,
            id: 'brotil-test',
            message: data?.brotilTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // http2-test
    if (data?.http2Test?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('http2-test')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.http2Test?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('http2-test')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.http2Test?.data?.testResults?.status,
            id: 'http2-test',
            message: data?.http2Test?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('http2-test')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.http2Test?.data?.testResults?.status,
            id: 'http2-test',
            message: data?.http2Test?.data?.testResults?.message,
          },
        ]
      }
    }

    // ssl-freak
    if (data?.sslFreakTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('ssl-freak')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.sslFreakTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('ssl-freak')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.sslFreakTest?.data?.testResults?.status,
            id: 'ssl-freak',
            message: data?.sslFreakTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('ssl-freak')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.sslFreakTest?.data?.testResults?.status,
            id: 'ssl-freak',
            message: data?.sslFreakTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // tls-logjam
    if (data?.tlsLogjamTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('tls-logjam')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.tlsLogjamTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('tls-logjam')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tlsLogjamTest?.data?.testResults?.status,
            id: 'tls-logjam',
            message: data?.tlsLogjamTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('tls-logjam')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tlsLogjamTest?.data?.testResults?.status,
            id: 'tls-logjam',
            message: data?.tlsLogjamTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // render-blocking
    if (data?.renderBlocking?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.renderBlocking?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.renderBlocking?.data?.testResults?.status,
            id: 'render-blocking',
            message: data?.renderBlocking?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('render-blocking')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.renderBlocking?.data?.testResults?.status,
            id: 'render-blocking',
            message: data?.renderBlocking?.data?.testResults?.message,
          },
        ]
      }
    }

    // unsafe-cross-origin-links
    if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
            id: 'unsafe-cross-origin-links',
            message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('unsafe-cross-origin-links')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
            id: 'unsafe-cross-origin-links',
            message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // morden-image
    if (data?.mordenImage?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('morden-image')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.mordenImage?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('morden-image')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.mordenImage?.data?.testResults?.status,
            id: 'morden-image',
            message: data?.mordenImage?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('morden-image')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.mordenImage?.data?.testResults?.status,
            id: 'morden-image',
            message: data?.mordenImage?.data?.testResults?.message,
          },
        ]
      }
    }

    // aspect-ratio
    if (data?.aspectRatio?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.aspectRatio?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.aspectRatio?.data?.testResults?.status,
            id: 'aspect-ratio',
            message: data?.aspectRatio?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('aspect-ratio')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.aspectRatio?.data?.testResults?.status,
            id: 'aspect-ratio',
            message: data?.aspectRatio?.data?.testResults?.message,
          },
        ]
      }
    }

    // responsive-image
    if (data?.responsiveImage?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.responsiveImage?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.responsiveImage?.data?.testResults?.status,
            id: 'responsive-image',
            message: data?.responsiveImage?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('responsive-image')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.responsiveImage?.data?.testResults?.status,
            id: 'responsive-image',
            message: data?.responsiveImage?.data?.testResults?.message,
          },
        ]
      }
    }

    // console errors
    if (data?.consoleErrors?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.consoleErrors?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.consoleErrors?.data?.testResults?.status,
            id: 'console-errors',
            message: data?.consoleErrors?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('console-errors')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.consoleErrors?.data?.testResults?.status,
            id: 'console-errors',
            message: data?.consoleErrors?.data?.testResults?.message,
          },
        ]
      }
    }

    if (data?.advancedConsoleErrors?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.advancedConsoleErrors?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.advancedConsoleErrors?.data?.testResults?.status,
            id: 'advanced-console-errors',
            message: data?.advancedConsoleErrors?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('advanced-console-errors')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.advancedConsoleErrors?.data?.testResults?.status,
            id: 'advanced-console-errors',
            message: data?.advancedConsoleErrors?.data?.testResults?.message,
          },
        ]
      }
    }

    //  dom-size
    if (data?.domSize?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('dom-size')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.domSize?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('dom-size')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domSize?.data?.testResults?.status,
            id: 'dom-size',
            message: data?.domSize?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('dom-size')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.domSize?.data?.testResults?.status,
            id: 'dom-size',
            message: data?.domSize?.data?.testResults?.message,
          },
        ]
      }
    }

    // ads-txt
    if (data?.adsTxt?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('ads-txt')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.adsTxt?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('ads-txt')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.adsTxt?.data?.testResults?.status,
            id: 'ads-txt',
            message: data?.adsTxt?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('ads-txt')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.adsTxt?.data?.testResults?.status,
            id: 'dom-size',
            message: data?.adsTxt?.data?.testResults?.message,
          },
        ]
      }
    }

    // js-execution-time
    if (data?.bootupTime?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.bootupTime?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.bootupTime?.data?.testResults?.status,
            id: 'js-execution-time',
            message: data?.bootupTime?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('js-execution-time')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.bootupTime?.data?.testResults?.status,
            id: 'js-execution-time',
            message: data?.bootupTime?.data?.testResults?.message,
          },
        ]
      }
    }

    // characterSet
    if (data?.characterSet?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('character-set')
      if (isUrlData) {
        commonSeoS++
      }
    } else if (data?.characterSet?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('character-set')
      if (isUrlData) {
        commonSeoW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.characterSet?.data?.testResults?.status,
            id: 'character-set',
            message: data?.characterSet?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('character-set')
      if (isUrlData) {
        commonSeoF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.characterSet?.data?.testResults?.status,
            id: 'character-set',
            message: data?.characterSet?.data?.testResults?.message,
          },
        ]
      }
    }

    // viewport
    if (data?.viewport?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('viewport')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.viewport?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('viewport')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.viewport?.data?.testResults?.status,
            id: 'viewport',
            message: data?.viewport?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('viewport')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.viewport?.data?.testResults?.status,
            id: 'viewport',
            message: data?.viewport?.data?.testResults?.message,
          },
        ]
      }
    }

    //font-size
    if (data?.fontSizeTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('font-size')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.fontSizeTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('font-size')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.fontSizeTest?.data?.testResults?.status,
            id: 'font-size',
            message: data?.fontSizeTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('font-size')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.fontSizeTest?.data?.testResults?.status,
            id: 'font-size',
            message: data?.fontSizeTest?.data?.testResults?.message,
          },
        ]
      }
    }

    //tapTargetTest
    if (data?.tapTargetTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.tapTargetTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tapTargetTest?.data?.testResults?.status,
            id: 'tap-target',
            message: data?.tapTargetTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('tap-target')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.tapTargetTest?.data?.testResults?.status,
            id: 'tap-target',
            message: data?.tapTargetTest?.data?.testResults?.message,
          },
        ]
      }
    }

    //contentWidthTest
    if (data?.contentWidthTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('content-width')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.contentWidthTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('content-width')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentWidthTest?.data?.testResults?.status,
            id: 'content-width',
            message: data?.contentWidthTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('content-width')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentWidthTest?.data?.testResults?.status,
            id: 'content-width',
            message: data?.contentWidthTest?.data?.testResults?.message,
          },
        ]
      }
    }

    //speedIndexTest
    if (data?.speedIndexTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.speedIndexTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.speedIndexTest?.data?.testResults?.status,
            id: 'speed-index',
            message: data?.speedIndexTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('speed-index')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.speedIndexTest?.data?.testResults?.status,
            id: 'speed-index',
            message: data?.speedIndexTest?.data?.testResults?.message,
          },
        ]
      }
    }

    //firstContentfulPaintTest
    if (data?.firstContentfulPaintTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.firstContentfulPaintTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstContentfulPaintTest?.data?.testResults?.status,
            id: 'first-contentful-paint',
            message: data?.firstContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('first-contentful-paint')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstContentfulPaintTest?.data?.testResults?.status,
            id: 'first-contentful-paint',
            message: data?.firstContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    }

    //largestContentfulPaintTest
    if (data?.largestContentfulPaintTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.largestContentfulPaintTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.largestContentfulPaintTest?.data?.testResults?.status,
            id: 'last-contentful-paint',
            message: data?.largestContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('last-contentful-paint')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.largestContentfulPaintTest?.data?.testResults?.status,
            id: 'last-contentful-paint',
            message: data?.largestContentfulPaintTest?.data?.testResults?.message,
          },
        ]
      }
    }
    //cumulativeLayoutShiftTest
    if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cumulativeLayoutShiftTest?.data?.testResults?.status,
            id: 'cumulative-layout-shift-test',
            message: data?.cumulativeLayoutShiftTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('cumulative-layout-shift-test')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.cumulativeLayoutShiftTest?.data?.testResults?.status,
            id: 'cumulative-layout-shift-test',
            message: data?.cumulativeLayoutShiftTest?.data?.testResults?.message,
          },
        ]
      }
    }
    //interactiveTest
    if (data?.interactiveTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.interactiveTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.interactiveTest?.data?.testResults?.status,
            id: 'interactive-test',
            message: data?.interactiveTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('interactive-test')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.interactiveTest?.data?.testResults?.status,
            id: 'interactive-test',
            message: data?.interactiveTest?.data?.testResults?.message,
          },
        ]
      }
    }
    //firstInputDelayTest
    if (data?.firstInputDelayTest?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseS++
      }
    } else if (data?.firstInputDelayTest?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstInputDelayTest?.data?.testResults?.status,
            id: 'first-input-delay',
            message: data?.firstInputDelayTest?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('first-input-delay')
      if (isUrlData) {
        mobileUseF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.firstInputDelayTest?.data?.testResults?.status,
            id: 'first-input-delay',
            message: data?.firstInputDelayTest?.data?.testResults?.message,
          },
        ]
      }
    }

    // meta-refresh
    if (data?.metaRefresh?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.metaRefresh?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaRefresh?.data?.testResult?.status,
            id: 'meta-refresh',
            message: data?.metaRefresh?.data?.testResult?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('meta-refresh')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.metaRefresh?.data?.testResult?.status,
            id: 'meta-refresh',
            message: data?.metaRefresh?.data?.testResult?.message,
          },
        ]
      }
    }

    // custom-404
    if (data?.custom404?.data?.testResult?.status === 1) {
      positiveCounter++
      passedTestArray.push('custom-404')
      if (isUrlData) {
        advanceS++
      }
    } else if (data?.custom404?.data?.testResult?.status === 2) {
      warningCounter++
      warningTestArray.push('custom-404')
      if (isUrlData) {
        advanceW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.custom404?.data?.testResult?.status,
            id: 'custom-404',
            message: data?.custom404?.data?.testResult?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('custom-404')
      if (isUrlData) {
        advanceF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.custom404?.data?.testResult?.status,
            id: 'custom-404',
            message: data?.custom404?.data?.testResult?.message,
          },
        ]
      }
    }

    // layout-shift
    if (data?.layoutShift?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.layoutShift?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.layoutShift?.data?.testResults?.status,
            id: 'layout-shift',
            message: data?.layoutShift?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('layout-shift')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.layoutShift?.data?.testResults?.status,
            id: 'layout-shift',
            message: data?.layoutShift?.data?.testResults?.message,
          },
        ]
      }
    }

    // contentful-paint
    if (data?.contentfulPaint?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptS++
      }
    } else if (data?.contentfulPaint?.data?.testResults?.status === 2) {
      warningCounter++
      warningTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentfulPaint?.data?.testResults?.status,
            id: 'contentful-paint',
            message: data?.contentfulPaint?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('contentful-paint')
      if (isUrlData) {
        speedOptF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.contentfulPaint?.data?.testResults?.status,
            id: 'contentful-paint',
            message: data?.contentfulPaint?.data?.testResults?.message,
          },
        ]
      }
    }

    // directory-browsing
    if (data?.directoryBrowsing?.data?.testResults?.status === 1) {
      positiveCounter++
      passedTestArray.push('directory-browsing')
      if (isUrlData) {
        serverS++
      }
    } else if (data?.directoryBrowsing?.data?.testResults?.status === 2) {
      // eslint-disable-next-line no-unused-vars
      warningCounter++
      warningTestArray.push('directory-browsing')
      if (isUrlData) {
        serverW++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.directoryBrowsing?.data?.testResults?.status,
            id: 'directory-browsing',
            message: data?.directoryBrowsing?.data?.testResults?.message,
          },
        ]
      }
    } else {
      negativeCounter++
      failedTestArray.push('directory-browsing')
      if (isUrlData) {
        serverF++
        updatedErrorsArray = [
          ...updatedErrorsArray,
          {
            priority: data?.directoryBrowsing?.data?.testResults?.status,
            id: 'directory-browsing',
            message: data?.directoryBrowsing?.data?.testResults?.message,
          },
        ]
      }
    }

    return {
      updatedErrorsArray,
      positiveCounter,
      negativeCounter,
      commonSeo,
      commonSeoS,
      commonSeoW,
      commonSeoF,
      speedOptS,
      speedOptW,
      speedOptF,
      serverS,
      serverW,
      serverF,
      mobileUseS,
      mobileUseW,
      mobileUseF,
      advanceS,
      advanceW,
      advanceF,
    }
  }

  const competitor1Flag = _.isNull(competitor1)
  const competitor2Flag = _.isNull(competitor2)
  const competitor3Flag = _.isNull(competitor3)
  const competitor4Flag = _.isNull(competitor4)
  const competitor5Flag = _.isNull(competitor5)

  const multiData = []

  multiData.push(urlData)
  if (!competitor1Flag) {
    multiData.push(competitor1)
  }

  if (!competitor2Flag) {
    multiData.push(competitor2)
  }
  if (!competitor3Flag) {
    multiData.push(competitor3)
  }
  if (!competitor4Flag) {
    multiData.push(competitor4)
  }
  if (!competitor5Flag) {
    multiData.push(competitor5)
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <div className="report-wrapper">
          {urlData && urlData !== '' && urlData._id === seoCheckupData?.urlDataId ? (
            <>
              <div className="report-summary">
                <Row>
                  <Col lg="4" xl="4" xs="12">
                    <h3
                      style={{
                        display: 'inline-block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                        fontWeight: '400',
                      }}
                    >
                      {urlData?.url || ''}
                      <p style={{ fontSize: '14px', marginTop: '5px' }}>
                        {moment.utc(urlData?.createdAt).format('YYYY, MMMM DD')}
                      </p>
                    </h3>
                    <p>Your general SEO Checkup Score</p>
                  </Col>
                  <Col lg="4" xl="4" xs="12">
                    <p> SEO Score: {Math.round(((positiveCounter + warningCounter) / 74) * 100)} </p>
                    <ProgressBar>
                      <ProgressBar
                        variant="danger"
                        now={getSEOScore(Math.round(((positiveCounter + warningCounter) / 74) * 100)).red}
                        key={1}
                      />
                      <ProgressBar
                        variant="warning"
                        now={getSEOScore(Math.round(((positiveCounter + warningCounter) / 74) * 100)).yellow}
                        key={2}
                      />
                      <ProgressBar
                        variant="success"
                        now={getSEOScore(Math.round(((positiveCounter + warningCounter) / 74) * 100)).green}
                        key={3}
                      />
                    </ProgressBar>
                  </Col>
                  <Col
                    lg="4"
                    xl="4"
                    xs="12"
                    className="display-end report-buttons"
                    style={{ margin: 'auto' }}
                  >
                    <Button
                      style={{ height: 'fit-content' }}
                      variant="outline-primary"
                      color="outline-primary"
                      className="m-2"
                    >
                      <FontAwesomeIcon icon={faFilePdf} /> Download PDF
                    </Button>
                    <Button
                      style={{ height: 'fit-content' }}
                      variant="outline-primary"
                      color="outline-primary"
                      className="m-2"
                    >
                      <FontAwesomeIcon icon={faPen} /> White Label
                    </Button>
                  </Col>
                </Row>
                <PieChart
                  showDetails
                  competitor1={competitor1?.url}
                  competitor2={competitor2?.url}
                  competitor3={competitor3?.url}
                  competitor4={competitor4?.url}
                  competitor5={competitor5?.url}
                  positiveCounter={positiveCounter}
                  negativeCounter={negativeCounter}
                  warningCounter={warningCounter}
                  positiveCounter1={positiveCounter1}
                  negativeCounter1={negativeCounter1}
                  warningCounter1={warningCounter1}
                  positiveCounter2={positiveCounter2}
                  negativeCounter2={negativeCounter2}
                  warningCounter2={warningCounter2}
                  positiveCounter3={positiveCounter3}
                  negativeCounter3={negativeCounter3}
                  warningCounter3={warningCounter3}
                  positiveCounter4={positiveCounter4}
                  negativeCounter4={negativeCounter4}
                  warningCounter4={warningCounter4}
                  positiveCounter5={positiveCounter5}
                  negativeCounter5={negativeCounter5}
                  warningCounter5={warningCounter5}
                />
                <Row>
                  <Col className="d-none d-lg-block d-sm-none">
                    <Card>
                      <CardHeader>
                        <CardTitle className="f-bold">Issues to fix</CardTitle>
                      </CardHeader>

                      <CardBody>
                        <Row>
                          <Col>
                            <Issues errors={errors} executeScroll={executeScroll} showIssueTitle={false} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* ******** */}
                <Row className="quick-seo-checkup" id="quick-seo-checkup">
                  <Col>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="4">
                            <h3 className="f-bold">PRIMARY SEO SIGNALS</h3>
                          </Col>
                          <Col>
                            <Row className="display-end">
                              <Col md="3">
                                <span>
                                  Score:{' '}
                                  {(
                                    (commonSeoSCounter * 100) /
                                    (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                  ).toFixed(0)}
                                </span>
                                <ProgressBar
                                  className="mb-3"
                                  variant={
                                    (
                                      (commonSeoSCounter * 100) /
                                      (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                    ).toFixed(0) > 75
                                      ? 'success'
                                      : (
                                            (commonSeoSCounter * 100) /
                                            (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                  }
                                  now={
                                    (
                                      (commonSeoSCounter * 100) /
                                      (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                    ).toFixed(0) || 0
                                  }
                                  label=""
                                />
                              </Col>
                              <Col md="3">
                                <span>Success: {commonSeoSCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="success"
                                  now={commonSeoSCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Warning: {commonSeoWCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="warning"
                                  now={commonSeoWCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Failed: {commonSeoFCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="danger"
                                  now={commonSeoFCounter || 0}
                                  label=""
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </CardHeader>
                      <CardBody>
                        {urlData?.information?.seoCheckup?.title ? (
                          <CommonSEOHOC
                            id={'meta-title'}
                            //isAvailable={urlData?.information?.seoCheckup?.title?.titleCheck}
                            status={urlData?.information?.seoCheckup?.title?.titleStatus}
                            title={'Meta Title Signal'}
                            showDevider={false}
                            data={urlData?.information?.seoCheckup?.title}
                            infoMessage={commonSEOIssueInfo.metaTitle}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`meta ${index + 1}`}>
                                    <TitleTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.information?.seoCheckup ? (
                          <CommonSEOHOC
                            id={'meta-description'}
                            isAvailable={urlData?.information?.seoCheckup?.description?.descriptionCheck}
                            status={urlData?.information?.seoCheckup?.description?.descriptionStatus}
                            title={'Meta Description Signal'}
                            data={urlData?.information?.seoCheckup?.description}
                            infoMessage={commonSEOIssueInfo.metaDescription}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`metadescription ${index + 1}`}>
                                    <DescriptionTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.information?.seoCheckup?.title ? (
                          <CommonSEOHOC
                            id={'seo-result'}
                            isConsidered={false}
                            status={urlData?.keywordCloudTest?.data?.testResults?.status}
                            title={'Google Search Results Preview - SERP View'}
                            infoMessage={commonSEOIssueInfo.seoResult}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`GoogleSearch ${index + 1}`}>
                                    <ResultPreviewTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.microDataSchema?.data ? (
                          <CommonSEOHOC
                            id={'social-media-meta-tag'}
                            status={urlData?.microDataSchema?.data?.metaTagsResults?.status}
                            title={'Social Media Meta Tags Signals'}
                            infoMessage={commonSEOIssueInfo.socialMediaMetaTag}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`SocialSignals ${index + 1}`}>
                                    <SocialMediaMetaTags data={item} />{' '}
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.keywordCloudTest?.data ? (
                          <CommonSEOHOC
                            id={'common'}
                            isConsidered={false}
                            status={urlData?.keywordCloudTest?.data?.testResults?.status}
                            title={'Keyword Density Signals Check'}
                            infoMessage={commonSEOIssueInfo.commonKeywords}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`CommonKeywords ${index + 1}`}>
                                    <MostCommonTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {urlData?.microDataSchema?.data ? (
                          <CommonSEOHOC
                            id={'keyword-usage-test'}
                            isAvailable={urlData?.microDataSchema?.data?.keywordSuccessFlag}
                            status={urlData?.microDataSchema?.data?.keywordsUsageTestResults?.status}
                            title={'Keyword Usage Test Signals'}
                            infoMessage={commonSEOIssueInfo.keywordUsageTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`KeywordUsageSignals ${index + 1}`}>
                                    <KeywordUsageTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.keywordCloudTest?.data ? (
                          <CommonSEOHOC
                            id={'keyword-cloud'}
                            isConsidered={false}
                            isAvailable={urlData?.keywordCloudTest?.data?.keywordCloud}
                            title={'Keyword Cloud Signals - Visual & Count'}
                            infoMessage={commonSEOIssueInfo.keywordCloud}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`KeywordCloudSignals ${index + 1}`}>
                                    <KeywordCloudTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.relatedKeywordsFinal?.data ? (
                          <CommonSEOHOC
                            id={'related-keywords'}
                            isConsidered={false}
                            isAvailable={urlData?.relatedKeywordsFinal?.data?.testResult?.status}
                            status={urlData?.relatedKeywordsFinal?.data?.testResult?.status}
                            title={'Related Keywords Test Signals'}
                            infoMessage={commonSEOIssueInfo.relatedKeywords}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`RelatedKeywordSignals ${index + 1}`}>
                                    <RelatedKeywordsTest
                                      data={item}
                                      showDetail={index === 0}
                                      onShowDetail={() => setRelatedKeywordFlag(!relatedKeywordsFlag)}
                                      relatedKeywordList={relatedKeywordList}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.domainCompetitors?.data ? (
                          <CommonSEOHOC
                            id={'competitor'}
                            isConsidered={false}
                            isAvailable={urlData?.domainCompetitors?.data?.length}
                            title={'Competitor Domain Signals'}
                            infoMessage={commonSEOIssueInfo.competitorDomain}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`CompetitorDomainSignals-${index + 1}`}>
                                    <CompetitorDomain data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        <Element name="myScrollToElement">
                          {/* Heading Tags Test */}
                          {urlData?.headingAnalysis?.data ? (
                            <CommonSEOHOC
                              id={'heading-analysis'}
                              // isAvailable={urlData?.headingAnalysis?.data?.headingOrder?.list}
                              isAvailable={urlData?.headingAnalysis?.data?.testResults?.status}
                              status={urlData?.headingAnalysis?.data?.testResults?.status}
                              title={'Heading Tag Analysis Signals'}
                              infoMessage={commonSEOIssueInfo.headingAnalysis}
                              url={urlData?.url}
                              scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                              email={userData?.email}
                              phoneNumber={userData?.phoneNumber}
                              plan={userData?.plan?.name}
                              signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                              userName={`${userData?.firstName} ${userData?.lastName}`}
                            >
                              {multiData?.length ? (
                                multiData.map((item, index) =>
                                  item ? (
                                    <div key={`HeadingTagSignals ${index + 1}`}>
                                      <HeadingAnalysis
                                        data={item}
                                        showDetail={true}
                                        onShowDetail={() => setHeadingAnalysisFlag(!headingAnalysisFlag)}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  ),
                                )
                              ) : (
                                <></>
                              )}
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                        </Element>
                        {urlData?.robotsTxtTest?.data ? (
                          <CommonSEOHOC
                            id={'robots-txt'}
                            status={urlData?.robotsTxtTest?.data?.testResults?.status}
                            title={'Robots.txt Signal Analysis'}
                            data={urlData?.information?.seoCheckup?.robotsTestResults}
                            infoMessage={commonSEOIssueInfo.robotsTxt}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`RobotsSignals ${index + 1}`}>
                                    <RobotsText data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.siteMapData?.data ? (
                          <CommonSEOHOC
                            id={'sitemap-data'}
                            // isAvailable={urlData?.siteMapData?.data?.siteMapUrl?.length}
                            isAvailable={urlData?.siteMapData?.data?.testResults?.status}
                            status={urlData?.siteMapData?.data?.testResults?.status}
                            title={'Sitemap Signal Test'}
                            infoMessage={commonSEOIssueInfo.sitemapData}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`SitemaSignals ${index + 1}`}>
                                    <SiteMapData
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setSiteMapDataFlag(!siteMapDataFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.seoFriendlyUrlAnalysis?.data ? (
                          <CommonSEOHOC
                            id={'seo-friendly'}
                            isAvailable={
                              !urlData?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length
                            }
                            status={urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status}
                            title={'SEO Friendly URL Signal Test'}
                            infoMessage={commonSEOIssueInfo.seoFriendly}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`FriendlySignals ${index + 1}`}>
                                    <SeoFriendlyUrlAnalysis
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setSeoUrlAnalysisFlag(!seoUrlAnalysisFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.imageAlt?.data ? (
                          <CommonSEOHOC
                            id={'image-alt'}
                            // isAvailable={!urlData?.imageAlt?.data?.imgAlt?.length}
                            isAvailable={urlData?.imageAlt?.data?.testResults?.status}
                            status={urlData?.imageAlt?.data?.testResults?.status}
                            title={'Image Alt Test'}
                            infoMessage={commonSEOIssueInfo.imageAlt}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`ImageAltSignals ${index + 1}`}>
                                    <ImageAlt
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setImageAltFlag(!imageAltFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.descriptiveLinkTest?.data ? (
                          <CommonSEOHOC
                            id={'descriptive-test'}
                            // isAvailable={!urlData?.imageAlt?.data?.imgAlt?.length}
                            isAvailable={urlData?.descriptiveLinkTest?.data?.testResults?.status}
                            status={urlData?.descriptiveLinkTest?.data?.testResults?.status}
                            title={'Descriptive Link Signal Test'}
                            infoMessage={commonSEOIssueInfo.descriptiveLinkTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`DescriptiveLinkSignals ${index + 1}`}>
                                    <LinksALTDescriptions data={item} showDetail={true} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.responsiveImage?.data ? (
                          <CommonSEOHOC
                            id={'responsive-image'}
                            status={urlData?.responsiveImage?.data?.testResults?.status}
                            title={'Excessive Image Data Size Signal Test'}
                            infoMessage={commonSEOIssueInfo.responsiveImage}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`ExcessiveImageSignals ${index + 1}`}>
                                    <ResponsiveImage
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setResponsiveImageFlag(!responsiveImageFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                            <ModalHOC
                              show={responsiveImageFlag}
                              toggleShow={() => setResponsiveImageFlag(!responsiveImageFlag)}
                              title={'Full list of images with excessive data size'}
                              bodyClassName="m-3"
                              titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                            >
                              <Table bordered>
                                <thead>
                                  <tr>
                                    <th style={{ width: '40%' }}>Image</th>
                                    <th style={{ width: '40%' }}>Total Size</th>
                                    <th style={{ width: '40%' }}>Potential Savings</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {urlData?.responsiveImage?.data?.data?.details?.items?.length &&
                                    urlData?.responsiveImage?.data?.data?.details?.items.map(
                                      (item, index) => {
                                        return (
                                          <tr key={`excessivesize ${index + 1}`}>
                                            <td>{item.url}</td>
                                            <td>{`${item?.totalBytes / 1000 || '-'} KB`}</td>
                                            <td>{`${item?.wastedBytes / 1000 || '-'} KB`}</td>
                                          </tr>
                                        )
                                      },
                                    )}
                                </tbody>
                              </Table>
                            </ModalHOC>
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.aspectRatio?.data ? (
                          <CommonSEOHOC
                            id={'aspect-ratio'}
                            status={urlData?.aspectRatio?.data?.testResults?.status}
                            title={'Image Aspect Ratio Signal Test'}
                            infoMessage={commonSEOIssueInfo.aspectRatio}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`ImageAspectSignals ${index + 1}`}>
                                    <AspectRatio
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setAspectRatioFlag(!aspectRatioFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.inlineCss?.data ? (
                          <CommonSEOHOC
                            id={'inline-css'}
                            status={urlData?.inlineCss?.data?.testResults?.status}
                            title={'Inline CSS Signal Test'}
                            infoMessage={commonSEOIssueInfo.inlineCss}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`InlineCSSSignals ${index + 1}`}>
                                    <InlineCss
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setInlinecssFlag(!inlineCssFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.htmlValidation?.data ? (
                          <CommonSEOHOC
                            id={'html-validation'}
                            status={urlData?.htmlValidation?.data?.testResults?.status}
                            title={'Html Validation'}
                            infoMessage={commonSEOIssueInfo.htmlValidation}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`HtmlValidationSignals ${index + 1}`}>
                                    <HtmlValidation
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setHtmlValidationFlag(!htmlValidationFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.googleAnalytics ? (
                          <CommonSEOHOC
                            id={'google-analytics'}
                            status={urlData?.googleAnalytics?.data?.testResults?.status}
                            title={'Google Analytics Signal Test'}
                            infoMessage={commonSEOIssueInfo.googleAnalytics}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`GoogleAnalyticsSignals ${index + 1}`}>
                                    <GoogleAnalytics data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.faviconCheckerTest?.data ? (
                          <CommonSEOHOC
                            id={'favicon-test'}
                            status={urlData?.faviconCheckerTest?.data?.status}
                            title={'Favicon Checker Signal Test'}
                            infoMessage={commonSEOIssueInfo.faviconTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`FaviconCheckerSignals ${index + 1}`}>
                                    <FaviconCheckerTest data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* Internal Broken Links Test */}
                        {urlData?.brokenLinks?.data ? (
                          <CommonSEOHOC
                            id={'brokenLinks-data'}
                            // isAvailable={urlData?.brokenLinks?.data?.siteMapUrl?.length}
                            isAvailable={urlData?.brokenLinks?.data?.testResults?.status}
                            status={urlData?.brokenLinks?.data?.testResults?.status}
                            title={'Internal Broken Links Checker'}
                            infoMessage={commonSEOIssueInfo.brokenLinks}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`InternalBrokenLinksSignals ${index + 1}`}>
                                    <BrokenLinks
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setBrokenLinkFlag(!brokenLinkFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.jsError?.data ? (
                          <CommonSEOHOC
                            id={'js-error'}
                            status={urlData?.jsError?.data?.testResults?.status}
                            title={'JS Error Signal Test'}
                            infoMessage={commonSEOIssueInfo.jsError}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`JSErrorLinksSignals ${index + 1}`}>
                                    <JSError
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setJsErrorFlag(!jsErrorFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.consoleErrors?.data ? (
                          <CommonSEOHOC
                            id={'console-errors'}
                            status={urlData?.consoleErrors?.data?.testResults?.status}
                            title={'Historic Console Error Signal Check'}
                            infoMessage={commonSEOIssueInfo.consoleErrors}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`ConsoleErrorsSignals ${index + 1}`}>
                                    <ConsoleErrors
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setConsoleErrorsFlag(!consoleErrorsFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.advancedConsoleErrors?.data ? (
                          <CommonSEOHOC
                            id={'advanced-console-errors'}
                            status={urlData?.advancedConsoleErrors?.data?.testResults?.status}
                            title={'Advanced Console Error Signal Check'}
                            infoMessage={commonSEOIssueInfo?.advancedConsoleErrors}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length
                              ? multiData.map((item, index) =>
                                  item ? (
                                    <div key={`AdvancedConsoleErrorsSignals ${index + 1}`}>
                                      <AdvancedConsoleErrors
                                        data={item}
                                        showDetail={true}
                                        onShowDetail={() =>
                                          setAdvancedConsoleErrorsFlag(!advancedConsoleErrorsFlag)
                                        }
                                      />
                                    </div>
                                  ) : null,
                                )
                              : null}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.characterSet?.data ? (
                          <CommonSEOHOC
                            id={'character-set'}
                            status={urlData?.characterSet?.data?.testResults?.status}
                            title={'Charset Declaration Signal Test'}
                            infoMessage={commonSEOIssueInfo.characterSet}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`CharsetDeclarationSignals ${index + 1}`}>
                                    <CharacterSet data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.socialMediaTest?.data || urlData?.socialMediaTwitterData?.data ? (
                          <CommonSEOHOC
                            id={'social-media'}
                            status={
                              (urlData?.socialMediaTest?.data
                                ? urlData?.socialMediaTest?.data?.facebookTestResults?.status === 3
                                  ? false
                                  : true
                                : false) ||
                              (urlData?.socialMediaTest?.data
                                ? urlData?.socialMediaTest?.data?.linkedinTestResults?.status === 3
                                  ? false
                                  : true
                                : false) ||
                              (urlData?.socialMediaInstagramData?.data
                                ? urlData?.socialMediaInstagramData?.data?.testResults?.status === 3
                                  ? false
                                  : true
                                : false) ||
                              (urlData?.socialMediaTwitterData?.data
                                ? urlData?.socialMediaTwitterData?.data?.testResults?.status === 3
                                  ? false
                                  : true
                                : false)
                                ? 1
                                : 3
                            }
                            title={'Social Media Signal Test'}
                            infoMessage={commonSEOIssueInfo.socialMedia}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`SocialMediaSignals ${index + 1}`}>
                                    <SocialMediaTest
                                      data={item}
                                      showDetail={index === 0}
                                      onShowDetail={() => setSocialMediaFlag(!socialMediaFlag)}
                                      facebookURL={item?.socialMediaTest?.data?.socialMediaData?.facebookURL}
                                      facebookData={
                                        item?.socialMediaTest?.data
                                          ? item?.socialMediaTest?.data?.facebookTestResults?.status === 3
                                            ? false
                                            : true
                                          : false
                                      }
                                      linkedInURL={item?.socialMediaTest?.data?.socialMediaData?.linkedinURL}
                                      linkedInData={
                                        item?.socialMediaTest?.data
                                          ? item?.socialMediaTest?.data?.linkedinTestResults?.status === 3
                                            ? false
                                            : true
                                          : false
                                      }
                                      twitterURL={
                                        item?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                          ?.valueData?.[3]
                                      }
                                      instaURL={item?.socialMediaInstagramData?.data?.socialBlade?.url}
                                      instagramData={
                                        item?.socialMediaInstagramData?.data
                                          ? item?.socialMediaInstagramData?.data?.testResults?.status === 3
                                            ? false
                                            : true
                                          : false
                                      }
                                      twitterData={
                                        item?.socialMediaTwitterData?.data
                                          ? item?.socialMediaTwitterData?.data?.testResults?.status === 3
                                            ? false
                                            : true
                                          : false
                                      }
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                            <ModalHOC
                              show={socialMediaFlag}
                              toggleShow={() => setSocialMediaFlag(!socialMediaFlag)}
                              title={'Social Media Data'}
                              bodyClassName="m-3"
                              titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                            >
                              <Row className="mt-3">
                                <Col>
                                  {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData?.valueData
                                    ?.length ? (
                                    <>
                                      <h3 style={{ textAlign: 'center' }}>
                                        <b>Twitter Data</b>
                                      </h3>
                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Location</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[0] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[0]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>

                                      <hr className="my-2" />

                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Joined</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[1] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[1]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>

                                      <hr className="my-2" />

                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Followers</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[2] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[2]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>

                                      <hr className="my-2" />

                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Following</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[3] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[3]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>

                                      <hr className="my-2" />

                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Followers Ratio</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[4] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[4]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>

                                      <hr className="my-2" />

                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Tweets</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[5] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[5]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>

                                      <hr className="my-2" />

                                      <div className="d-flex">
                                        <div className="flex-grow-1 ms-3">
                                          <p className="my-1">
                                            <strong>Listed</strong>
                                          </p>
                                          <h3>
                                            {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[6] === '' ? (
                                              '-'
                                            ) : (
                                              <>
                                                {
                                                  urlData?.socialMediaTwitterData?.data?.twitterData
                                                    ?.twitterData?.valueData?.[6]
                                                }
                                              </>
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col>
                                  <h3 className="social-media-modal-header">
                                    <b>Facebook Data</b>
                                  </h3>
                                  <Table bordered>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '50%' }}>Data</th>
                                        <th style={{ width: '50%' }}>Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {urlData?.socialMediaTest?.data?.facebookData?.length ? (
                                        urlData?.socialMediaTest?.data?.facebookData.map((item, index) => {
                                          return (
                                            <tr key={`facebook ${index + 1}`}>
                                              <td>{item.key}</td>
                                              <td>{item.value}</td>
                                            </tr>
                                          )
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </ModalHOC>
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="4">
                            <h3 className="f-bold">WEBSITE SITE LOADING SIGNALS</h3>
                          </Col>
                          <Col>
                            <Row className="display-end">
                              <Col md="3">
                                <span>
                                  Score:{' '}
                                  {(
                                    (speedOptimizeSCounter * 100) /
                                    (speedOptimizeFCounter + speedOptimizeWCounter + speedOptimizeSCounter)
                                  ).toFixed(0)}
                                </span>
                                <ProgressBar
                                  className="mb-3"
                                  variant={
                                    (
                                      (speedOptimizeSCounter * 100) /
                                      (speedOptimizeFCounter + speedOptimizeWCounter + speedOptimizeSCounter)
                                    ).toFixed(0) > 75
                                      ? 'success'
                                      : (
                                            (speedOptimizeSCounter * 100) /
                                            (speedOptimizeFCounter +
                                              speedOptimizeWCounter +
                                              speedOptimizeSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                  }
                                  now={
                                    (
                                      (speedOptimizeSCounter * 100) /
                                      (speedOptimizeFCounter + speedOptimizeWCounter + speedOptimizeSCounter)
                                    ).toFixed(0) || 0
                                  }
                                  label=""
                                />
                              </Col>
                              <Col md="3">
                                <span>Success: {speedOptimizeSCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="success"
                                  now={speedOptimizeSCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Warning: {speedOptimizeWCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="warning"
                                  now={speedOptimizeWCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Failed: {speedOptimizeFCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="danger"
                                  now={speedOptimizeFCounter || 0}
                                  label=""
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </CardHeader>
                      <CardBody>
                        {/* Speed optimizations section */}

                        {urlData?.htmlPageSize ? (
                          <CommonSEOHOC
                            id={'html-page'}
                            status={urlData?.htmlPageSize?.data?.testResults?.status}
                            title={'HTML Page Size Signal Test'}
                            infoMessage={commonSEOIssueInfo.htmlPage}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                            showDevider={false}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`HTMLPageSizeSignals ${index + 1}`}>
                                    <HtmlPageSize data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.domSize?.data ? (
                          <CommonSEOHOC
                            id={'dom-size'}
                            status={urlData?.domSize?.data?.testResults?.status}
                            title={'DOM Size Signal Test'}
                            infoMessage={commonSEOIssueInfo.domSize}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`DOMSizeSignals ${index + 1}`}>
                                    <DomSize
                                      data={item}
                                      showDetail={index === 0}
                                      onShowDetail={() => setDomSizeFlag(!domSizeFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                            <ModalHOC
                              show={domSizeFlag}
                              toggleShow={() => setDomSizeFlag(!domSizeFlag)}
                              title={'Dom Elements'}
                              bodyClassName="m-3"
                              titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                            >
                              <Table bordered>
                                <thead>
                                  <tr>
                                    <th style={{ width: '40%' }}>Elements List</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {urlData?.domSize?.data?.domSizeResult?.details?.items?.length &&
                                    urlData?.domSize?.domSizeResult?.data?.details?.items.map(
                                      (item, index) => {
                                        return (
                                          <tr key={`domElements ${index + 1}`}>
                                            <td>{item.url}</td>
                                          </tr>
                                        )
                                      },
                                    )}
                                </tbody>
                              </Table>
                            </ModalHOC>
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {usersDomain?.length > 0 ? (
                          <CommonSEOHOC
                            id={'backlinks'}
                            isConsidered={false}
                            title={'Backlinks'}
                            infoMessage={commonSEOIssueInfo.Backlinks}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`BacklinksSignals ${index + 1}`}>
                                    <Backlinks
                                      data={item}
                                      showDetail={index === 0}
                                      totalBacklinks={totalBacklinks}
                                      referingDomains={referingDomains}
                                      recentBacklinks={mostRecentlyBacklinks}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.htmlCompression?.data ? (
                          <CommonSEOHOC
                            id={'html-compression'}
                            status={urlData?.htmlCompression?.data?.testResults?.status}
                            title={'Web Content Compression Check'}
                            infoMessage={commonSEOIssueInfo.htmlCompression}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`HTMLCompressionSignals ${index + 1}`}>
                                    <HtmlCompression data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* Site Loading Speed  */}
                        {urlData?.siteSpeed?.data ? (
                          <CommonSEOHOC
                            id={'site-speed'}
                            status={urlData?.siteSpeed?.data?.testResults?.status}
                            title={'Site Loading Speed Signal Test'}
                            infoMessage={commonSEOIssueInfo.siteSpeed}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`SiteLoadingSignals-${index + 1}`}>
                                    <SiteSpeed data={item} />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.bootupTime?.data ? (
                          <CommonSEOHOC
                            id={'js-execution-time'}
                            status={urlData?.bootupTime?.data?.testResults?.status}
                            title={'JS Execution Signal Test'}
                            infoMessage={commonSEOIssueInfo.jsExecutionTime}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <BootupTime data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.pageObject?.data ? (
                          <CommonSEOHOC
                            id={'page-object'}
                            status={urlData?.pageObject?.data?.testResults?.status}
                            title={'Page Objects Signal Test'}
                            infoMessage={commonSEOIssueInfo.pageObject}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <PageObject
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setPageObjectFlag(!pageObjectFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.pageCache?.data ? (
                          <CommonSEOHOC
                            id={'page-cache'}
                            status={urlData?.pageCache?.data?.testResults?.status}
                            title={'Page Cache Test (Server Side Caching) Signal Analysis'}
                            infoMessage={commonSEOIssueInfo.pageCache}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <PageCache data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.flashTest?.data ? (
                          <CommonSEOHOC
                            id={'flash-test'}
                            status={urlData?.flashTest?.data?.testResults?.status}
                            title={'Flash Signal Test '}
                            infoMessage={commonSEOIssueInfo.flashTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <FlashTest data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.cdnUsageData?.data ? (
                          <CommonSEOHOC
                            id={'cdn-usage'}
                            isAvailable={urlData?.cdnUsageData?.data?.testResult?.status}
                            status={urlData?.cdnUsageData?.data?.testResult?.status}
                            title={'CDN Usage Signal Test'}
                            infoMessage={commonSEOIssueInfo.cdnUsage}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <CDNUsage
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setCdnUsageDataFlag(!cdnUsageDataFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.mordenImage?.data ? (
                          <CommonSEOHOC
                            id={'morden-image'}
                            status={urlData?.mordenImage?.data?.testResults?.status}
                            title={'Morden Image Signal Test'}
                            infoMessage={commonSEOIssueInfo.mordenImage}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <MordenImage
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setMordenImageFlag(!mordenImageFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* Missing: Image Metadata Test */}
                        {/* cache poliy */}
                        {urlData?.cachePolicy?.data ? (
                          <CommonSEOHOC
                            id={'cache-policy'}
                            isAvailable={urlData?.cachePolicy?.data?.testResults?.status}
                            status={urlData?.cachePolicy?.data?.testResults?.status}
                            title={'Cache Policy Signal Test'}
                            infoMessage={commonSEOIssueInfo.cachePolicy}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length
                              ? multiData.map((item) =>
                                  item ? (
                                    <CachePolicy
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setCachePolicyFlag(!cachePolicyFlag)}
                                    />
                                  ) : null,
                                )
                              : null}
                          </CommonSEOHOC>
                        ) : null}

                        {urlData?.imageCache?.data ? (
                          <CommonSEOHOC
                            id={'image-cache'}
                            isAvailable={urlData?.imageCache?.data?.testResults?.status}
                            status={urlData?.imageCache?.data?.testResults?.status}
                            title={'Image Cache Signal Test'}
                            infoMessage={commonSEOIssueInfo.imageCache}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <ImageCache
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setImageCacheFlag(!imageCacheFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.jsCache?.data ? (
                          <CommonSEOHOC
                            id={'js-cache'}
                            isAvailable={urlData?.jsCache?.data?.testResults?.status}
                            status={urlData?.jsCache?.data?.testResults?.status}
                            title={'JavaScript Caching Signal Test'}
                            infoMessage={commonSEOIssueInfo.jsCache}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <JSCache
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setJsCacheFlag(!jsCacheFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.cssCache?.data ? (
                          <CommonSEOHOC
                            id={'css-cache'}
                            isAvailable={urlData?.cssCache?.data?.testResults?.status}
                            status={urlData?.cssCache?.data?.testResults?.status}
                            title={'CSS Caching Signal Test'}
                            infoMessage={commonSEOIssueInfo.cssCache}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? <CSSCache data={item} showDetail={true} /> : <></>,
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.jsMinification?.data ? (
                          <CommonSEOHOC
                            id={'js-minification'}
                            isAvailable={urlData?.jsMinification?.data?.testResults?.status}
                            status={urlData?.jsMinification?.data?.testResults?.status}
                            title={'JavaScript Minification Signal Test'}
                            infoMessage={commonSEOIssueInfo.jsMinification}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <JSMinification
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setJsMinification(!jsMinificationFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.cssMinification?.data ? (
                          <CommonSEOHOC
                            id={'css-minification'}
                            // isAvailable={!urlData?.cssMinification?.data?.nonMinifiedCss?.length}
                            isAvailable={urlData?.cssMinification?.data?.testResults?.status}
                            status={urlData?.cssMinification?.data?.testResults?.status}
                            title={'CSS Minification Signal Test'}
                            infoMessage={commonSEOIssueInfo.cssMinification}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <CSSMinification
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setCssMinificationFlag(!cssMinificationFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.renderBlocking?.data ? (
                          <CommonSEOHOC
                            id={'render-blocking'}
                            status={urlData?.renderBlocking?.data?.testResults?.status}
                            title={'Render Blocking Signal Test'}
                            infoMessage={commonSEOIssueInfo.renderBlocking}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <RenderBlocking
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setRenderBlockingFlag(!renderBlockingFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.nestedTable?.data ? (
                          <CommonSEOHOC
                            id={'nested-table'}
                            status={urlData?.nestedTable?.data?.testResults?.status}
                            title={'Nested Tables Signal Test'}
                            infoMessage={commonSEOIssueInfo.nestedTable}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <NestedTable data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.framesetTest?.data ? (
                          <CommonSEOHOC
                            id={'frameset-test'}
                            status={urlData?.framesetTest?.data?.testResults.status}
                            title={'Frameset Signal Test'}
                            infoMessage={commonSEOIssueInfo.framesetTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <FramesetTest data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.docType?.data ? (
                          <CommonSEOHOC
                            id={'doc-type'}
                            status={urlData?.docType?.data?.testResults?.status}
                            title={'Doctype Signal Test'}
                            infoMessage={commonSEOIssueInfo.docType}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <DocType data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* URL Redirects Test */}
                        {urlData?.redirectTest ? (
                          <CommonSEOHOC
                            id={'redirect-test'}
                            status={urlData?.redirectTest?.data?.testResults?.status}
                            title={'Redirect Signal Test'}
                            infoMessage={commonSEOIssueInfo.redirectTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? <RedirectTest data={item} showDetail={index === 0} /> : <></>,
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.contentfulPaint?.data ? (
                          <CommonSEOHOC
                            id={'contentful-paint'}
                            status={urlData?.contentfulPaint?.data?.testResults?.status}
                            title={'Contentful Paint Signal Test'}
                            infoMessage={commonSEOIssueInfo.contentfulPaint}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <ContentfulPaint data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.layoutShift?.data ? (
                          <CommonSEOHOC
                            id={'layout-shift'}
                            status={urlData?.layoutShift?.data?.testResults?.status}
                            title={'Cumulative Layout Shift Signal Test'}
                            infoMessage={commonSEOIssueInfo.layoutShift}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <LayoutShift data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {urlData?.cssAnalysis?.data ? (
                          <CommonSEOHOC
                            id={'css-analysis'}
                            status={urlData?.cssAnalysis?.data?.testResults?.status}
                            title={'CSS Analysis'}
                            infoMessage={commonSEOIssueInfo.cssAnalysis}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <CSSAnalysis
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setCssAnalysisFlag(!cssAnalysisFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="4">
                            <h3 className="f-bold">SERVER CONFIGURATION & SECURITY SIGNALS</h3>
                          </Col>
                          <Col>
                            <Row className="display-end">
                              <Col md="3">
                                <span>
                                  Score:{' '}
                                  {(
                                    (serverSCounter * 100) /
                                    (serverFCounter + serverWCounter + serverSCounter)
                                  ).toFixed(0)}
                                </span>
                                <ProgressBar
                                  className="mb-3"
                                  variant={
                                    (
                                      (serverSCounter * 100) /
                                      (serverFCounter + serverWCounter + serverSCounter)
                                    ).toFixed(0) > 75
                                      ? 'success'
                                      : (
                                            (serverSCounter * 100) /
                                            (serverFCounter + serverWCounter + serverSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                  }
                                  now={
                                    (
                                      (serverSCounter * 100) /
                                      (serverFCounter + serverWCounter + serverSCounter)
                                    ).toFixed(0) || 0
                                  }
                                  label=""
                                />
                              </Col>
                              <Col md="3">
                                <span>Success: {serverSCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="success"
                                  now={serverSCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Warning: {serverWCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="warning"
                                  now={serverWCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Failed: {serverFCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="danger"
                                  now={serverFCounter || 0}
                                  label=""
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </CardHeader>
                      <CardBody>
                        {/* Server and security section */}

                        {urlData?.urlCanonicalization?.data ? (
                          <CommonSEOHOC
                            id={'url-canonicalization'}
                            title={'URL Canonicalization Signal Test'}
                            infoMessage={commonSEOIssueInfo.urlCanonicalization}
                            status={urlData?.urlCanonicalization?.data?.testResult?.status}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                            showDevider={false}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <UrlCanonicalization data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* Mixed Content Test (HTTP over HTTPS) */}
                        {urlData?.httpsTest?.data ? (
                          <CommonSEOHOC
                            id={'https-test'}
                            status={urlData?.httpsTest?.data?.testResults?.status}
                            title={'Mixed Content Test (HTTP over HTTPS)'}
                            infoMessage={commonSEOIssueInfo.httpsTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <HttpsTest data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.http2Test?.data ? (
                          <CommonSEOHOC
                            id={'http2-test'}
                            status={urlData?.http2Test?.data?.testResults?.status}
                            title={'HTTP2 Signal Test'}
                            infoMessage={commonSEOIssueInfo.http2Test}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <Http2Test data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.hstsTest?.data ? (
                          <CommonSEOHOC
                            id={'hsts'}
                            status={urlData?.hstsTest?.data?.testResults?.status}
                            title={'HSTS Signal Analysis'}
                            infoMessage={commonSEOIssueInfo.hsts}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <HSTS data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.safeBrowsing?.data ? (
                          <CommonSEOHOC
                            id={'safe-browsing'}
                            status={urlData?.safeBrowsing?.data?.testResults?.status}
                            title={'Safe Browsing Signal Test'}
                            infoMessage={commonSEOIssueInfo.safeBrowsing}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <SafeBrowsing data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.serverSignature?.data ? (
                          <CommonSEOHOC
                            id={'server-signature'}
                            status={urlData?.serverSignature?.data?.testResults?.status}
                            title={'Server Signature Analysis'}
                            infoMessage={commonSEOIssueInfo.serverSignature}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <ServerSignature data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.directoryBrowsing?.data ? (
                          <CommonSEOHOC
                            id={'directory-browsing'}
                            status={urlData?.directoryBrowsing?.data?.testResults?.status}
                            title={'Directory Browsing Analysis'}
                            infoMessage={commonSEOIssueInfo.directoryBrowsing}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <DirectoryBrowsing data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.plainTextEmail?.data ? (
                          <CommonSEOHOC
                            id={'plain-text'}
                            status={urlData?.plainTextEmail?.data?.testResults?.status}
                            title={'Plaintext Emails Signal Test'}
                            infoMessage={commonSEOIssueInfo.plainText}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <PlainTextEmail data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.unsafeCrossOriginLinksTest?.data ? (
                          <CommonSEOHOC
                            id={'unsafe-cross-origin-links'}
                            status={urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status}
                            title={'Unsafe Cross-Origin Signal Analysis'}
                            infoMessage={commonSEOIssueInfo.unsafeCrossOriginLinks}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <UnsafeCrossOriginLinks
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() =>
                                      setUnsafeCrossOriginLinksFlag(!unsafeCrossOriginLinksFlag)
                                    }
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* SSL Checker and HTTPS Test */}
                        {urlData?.sslFreakTest?.data ? (
                          <CommonSEOHOC
                            id={'ssl-freak'}
                            status={urlData?.sslFreakTest?.data?.testResults?.status}
                            title={'SSL and HTTPS Signals '}
                            infoMessage={commonSEOIssueInfo.sslFreak}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`sslFreak-${index + 1}`}>
                                    <SSLHttpTest data={item} />{' '}
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.brotilTest?.data ? (
                          <CommonSEOHOC
                            id={'brotil-test'}
                            status={urlData?.brotilTest?.data?.testResults?.status}
                            title={'Brotli Analysis Test'}
                            infoMessage={commonSEOIssueInfo.brotilTest}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <BrotilTest data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.dnsSecurityTest?.data ? (
                          <CommonSEOHOC
                            id={'dns-security'}
                            isConsidered={false}
                            status={urlData?.dnsSecurityTest?.data?.testResults?.status}
                            title={'DNS Security'}
                            infoMessage={commonSEOIssueInfo.dnsSecurity}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`dnsSecurityTest-${index + 1}`}>
                                    <DnsSecurityTest
                                      data={item}
                                      showDetail={true}
                                      onShowDetail={() => setDnsSecurityFlag(!dnsSecurityFlag)}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.tlsLogjamTest?.data ? (
                          <CommonSEOHOC
                            id={'tls-logjam'}
                            status={urlData?.tlsLogjamTest?.data?.testResults?.status}
                            title={'TLS Logjam Signal Test'}
                            infoMessage={commonSEOIssueInfo.tlsLogjam}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <TlsLogjam data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                      </CardBody>
                    </Card>
                    {/* Missing: Unsafe Cross-Origin Links Test */}

                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="4">
                            <h3 className="f-bold">MOBILE RESPONSIVE & MOBILE SPEED SIGNALS</h3>
                          </Col>
                          <Col>
                            <Row className="display-end">
                              <Col md="3">
                                <span>
                                  Score:{' '}
                                  {(
                                    (mobileUsabilitySCounter * 100) /
                                    (mobileUsabilityFCounter +
                                      mobileUsabilityWCounter +
                                      mobileUsabilitySCounter)
                                  ).toFixed(0)}
                                </span>
                                <ProgressBar
                                  className="mb-3"
                                  variant={
                                    (
                                      (mobileUsabilitySCounter * 100) /
                                      (mobileUsabilityFCounter +
                                        mobileUsabilityWCounter +
                                        mobileUsabilitySCounter)
                                    ).toFixed(0) > 75
                                      ? 'success'
                                      : (
                                            (mobileUsabilitySCounter * 100) /
                                            (mobileUsabilityFCounter +
                                              mobileUsabilityWCounter +
                                              mobileUsabilitySCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                  }
                                  now={
                                    (
                                      (mobileUsabilitySCounter * 100) /
                                      (mobileUsabilityFCounter +
                                        mobileUsabilityWCounter +
                                        mobileUsabilitySCounter)
                                    ).toFixed(0) || 0
                                  }
                                  label=""
                                />
                              </Col>
                              <Col md="3">
                                <span>Success: {mobileUsabilitySCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="success"
                                  now={mobileUsabilitySCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Warning: {mobileUsabilityWCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="warning"
                                  now={mobileUsabilityWCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Failed: {mobileUsabilityFCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="danger"
                                  now={mobileUsabilityFCounter || 0}
                                  label=""
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </CardHeader>
                      <CardBody>
                        {/* Mobile usability section */}

                        {urlData?.viewport?.data ? (
                          <CommonSEOHOC
                            id={'viewport'}
                            status={urlData?.viewport?.data?.testResults?.status}
                            title={'Viewport Analysis'}
                            infoMessage={commonSEOIssueInfo.viewport}
                            showDevider={false}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <Viewport data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {urlData?.fontSizeTest?.data ? (
                          <CommonSEOHOC
                            id={'font-size'}
                            status={urlData?.fontSizeTest?.data?.testResults?.status}
                            title={'Font Size Signals'}
                            infoMessage={commonSEOIssueInfo.fontSize}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length
                              ? multiData.map((item) => (item ? <FontSizeTest data={item} /> : null))
                              : null}
                          </CommonSEOHOC>
                        ) : null}
                        {/* tapTargetTest title*/}
                        {urlData?.tapTargetTest?.data ? (
                          <CommonSEOHOC
                            id={'tap-target'}
                            status={urlData?.tapTargetTest?.data?.testResults?.status}
                            title={'Tap Target Signals'}
                            infoMessage={commonSEOIssueInfo.tapTargets}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <TapTargets data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* contentWidthTest title*/}
                        {urlData?.contentWidthTest?.data ? (
                          <CommonSEOHOC
                            id={'content-width'}
                            status={urlData?.contentWidthTest?.data?.testResults?.status}
                            title={'Content-width Signals'}
                            infoMessage={commonSEOIssueInfo.contentWidth}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <ContentWidth data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* speedIndexTest title*/}
                        {urlData?.speedIndexTest?.data ? (
                          <CommonSEOHOC
                            id={'speed-index'}
                            status={urlData?.speedIndexTest?.data?.testResults?.status}
                            title={'Speed Index Signals'}
                            infoMessage={commonSEOIssueInfo.speedIndex}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <SpeedIndex data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* firstContentfulPaintTest title*/}
                        {urlData?.firstContentfulPaintTest?.data ? (
                          <CommonSEOHOC
                            id={'first-contentful-paint'}
                            status={urlData?.firstContentfulPaintTest?.data?.testResults?.status}
                            title={'First Contentful Paint Signals'}
                            infoMessage={commonSEOIssueInfo.firstContentfulPaint}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <FirstContentfulPaint data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* largestContentfulPaintTest title*/}
                        {urlData?.largestContentfulPaintTest?.data ? (
                          <CommonSEOHOC
                            id={'last-contentful-paint'}
                            status={urlData?.largestContentfulPaintTest?.data?.testResults?.status}
                            title={'Largest Contentful Paint Signals'}
                            infoMessage={commonSEOIssueInfo.largestContentfulPaint}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <LargestContentfulPaint data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* cumulativeLayoutShiftTest title*/}
                        {urlData?.cumulativeLayoutShiftTest?.data ? (
                          <CommonSEOHOC
                            id={'cumulative-layout-shift-test'}
                            status={urlData?.cumulativeLayoutShiftTest?.data?.testResults?.status}
                            title={'Cumulative Layout Shift Signals'}
                            infoMessage={commonSEOIssueInfo.cumulativeLayoutShift}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <CumulativeLayoutShift data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* interactiveTest title*/}
                        {urlData?.interactiveTest?.data ? (
                          <CommonSEOHOC
                            id={'interactive'}
                            status={urlData?.interactiveTest?.data?.testResults?.status}
                            title={'Interactive Signals'}
                            infoMessage={commonSEOIssueInfo.timetoInteractive}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <TimetoInteractiveTest data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                        {/* firstInputDelayTest title*/}
                        {urlData?.firstInputDelayTest?.data ? (
                          <CommonSEOHOC
                            id={'first-input-delay'}
                            status={urlData?.firstInputDelayTest?.data?.testResults?.status}
                            title={'First Input Delay Signals'}
                            infoMessage={commonSEOIssueInfo.firstInputDelay}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <FirstInputDelay data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.mobileSnapShotTest?.data ? (
                          <CommonSEOHOC
                            id={'mobile-screenshot'}
                            status={urlData?.mobileSnapShotTest?.data?.length > 2 ? 1 : 3}
                            isConsidered={false}
                            title={'Mobile Screenshot Analysis'} //Mobile Snapshot
                            infoMessage={commonSEOIssueInfo.mobileScreenshot}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? <MobileScreenshot data={item} showDetail={index === 0} /> : <></>,
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.desktopSnapShotTest?.data ? (
                          <CommonSEOHOC
                            id={'desktop-screenshot'}
                            status={urlData?.desktopSnapShotTest?.data?.length > 2 ? 1 : 3}
                            isConsidered={false}
                            title={'Desktop Screenshot Analysis'}
                            infoMessage={commonSEOIssueInfo.desktopScreenshot}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? <DesktopScreenshot data={item} showDetail={index === 0} /> : <></>,
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}
                      </CardBody>
                    </Card>
                    {/* Advanced SEO section */}

                    <Card className="mb-0">
                      <CardHeader>
                        <Row>
                          <Col md="4">
                            <h3 className="f-bold">Advanced SEO Signal Test</h3>
                          </Col>
                          <Col>
                            <Row className="display-end">
                              <Col md="3">
                                <span>
                                  Score:{' '}
                                  {(
                                    (advanceSeoSCounter * 100) /
                                    (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                  ).toFixed(0)}
                                </span>
                                <ProgressBar
                                  className="mb-3"
                                  variant={
                                    (
                                      (advanceSeoSCounter * 100) /
                                      (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                    ).toFixed(0) > 75
                                      ? 'success'
                                      : (
                                            (advanceSeoSCounter * 100) /
                                            (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                  }
                                  now={
                                    (
                                      (advanceSeoSCounter * 100) /
                                      (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                    ).toFixed(0) || 0
                                  }
                                  label=""
                                />
                              </Col>
                              <Col md="3">
                                <span>Success: {advanceSeoSCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="success"
                                  now={advanceSeoSCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Warning: {advanceSeoWCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="warning"
                                  now={advanceSeoWCounter || 0}
                                  label=""
                                />
                              </Col>

                              <Col md="3">
                                <span>Failed: {advanceSeoFCounter}</span>
                                <ProgressBar
                                  className="mb-3"
                                  variant="danger"
                                  now={advanceSeoFCounter || 0}
                                  label=""
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </CardHeader>
                      <CardBody>
                        {urlData?.microDataSchema?.data ? (
                          <CommonSEOHOC
                            id={'microdata-schema'}
                            status={urlData?.microDataSchema?.data?.microSchemaResults?.status}
                            title={'Microdata Schema Analysis'} //Structured Data Test
                            infoMessage={commonSEOIssueInfo.microdataSchema}
                            showDevider={false}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <MicroDataSchema
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setMicroSchemaData(!microSchemaData)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.custom404?.data ? (
                          <CommonSEOHOC
                            id={'custom-404'}
                            title={'Custom 404 Signal Test'}
                            infoMessage={commonSEOIssueInfo.custom404}
                            status={urlData?.custom404?.data?.testResult?.status}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <Custom404 data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.noIndexChecker?.data ? (
                          <CommonSEOHOC
                            id={'no-index'}
                            status={urlData?.noIndexChecker?.data?.testResult?.status}
                            title={'No Index Checker Signal Test'}
                            infoMessage={commonSEOIssueInfo.noIndex}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <NoIndexChecker data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.canonicalUrl?.data ? (
                          <CommonSEOHOC
                            id={'canonical-url'}
                            title={'Canonical URL Signal Test'}
                            infoMessage={commonSEOIssueInfo.canonicalURL}
                            status={urlData?.canonicalUrl?.data?.testResult?.status}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <CanonicalURL data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.noFollow ? (
                          <CommonSEOHOC
                            id={'no-follow'}
                            isConsidered={false}
                            status={urlData?.noFollow?.data?.testResults?.status}
                            title={'No Follow Signal Test'}
                            infoMessage={commonSEOIssueInfo.noFollow}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <NoFollow
                                    data={item}
                                    showDetail={index === 0}
                                    onShowDetail={() => setNoFollowFlag(!noFollowFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.disallowDirectiveChecker?.data ? (
                          <CommonSEOHOC
                            id={'disalllow-directive'}
                            isConsidered={false}
                            isAvailable={urlData?.disallowDirectiveChecker?.data?.finalRes?.status}
                            title={'Disallow Directive Signal Test'}
                            infoMessage={commonSEOIssueInfo.disalllowDirective}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <DisallowDirective data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.metaRefresh?.data ? (
                          <CommonSEOHOC
                            id={'meta-refresh'}
                            title={'Meta Refresh Signal Test'}
                            infoMessage={commonSEOIssueInfo.metaRefresh}
                            status={urlData?.metaRefresh?.data?.testResult?.status}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) => (item ? <MetaRefresh data={item} /> : <></>))
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.spfRecord?.data ? (
                          <CommonSEOHOC
                            id={'spf-record'}
                            title={'SPF Record Analysis'}
                            infoMessage={commonSEOIssueInfo.spfRecord}
                            status={urlData?.spfRecord?.data?.testResult?.status}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item, index) =>
                                item ? (
                                  <div key={`spfRecordTest-${index + 1}]`}>
                                    <SpfRecord data={item} />{' '}
                                  </div>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {urlData?.adsTxt?.data ? (
                          <CommonSEOHOC
                            id={'ads-txt'}
                            status={urlData?.adsTxt?.data?.testResults?.status}
                            title={'Ads.txt Analysis'}
                            infoMessage={commonSEOIssueInfo.adsTxt}
                            url={urlData?.url}
                            scanDate={moment.utc(urlData?.createdAt).format('DD/MM/YYYY')}
                            email={userData?.email}
                            phoneNumber={userData?.phoneNumber}
                            plan={userData?.plan?.name}
                            signUpDate={moment.utc(userData?.createdAt).format('DD/MM/YYYY')}
                            userName={`${userData?.firstName} ${userData?.lastName}`}
                          >
                            {multiData?.length ? (
                              multiData.map((item) =>
                                item ? (
                                  <AdsTxt
                                    data={item}
                                    showDetail={true}
                                    onShowDetail={() => setAdsTxtFlag(!adsTxtFlag)}
                                  />
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                          </CommonSEOHOC>
                        ) : (
                          <></>
                        )}

                        {/* Missing: Spell Check Test */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* ********** */}
              </div>
              <div className="report-sidebar d-none d-lg-block d-sm-none sidebar-visibility">
                {(urlData && urlData !== '' && urlData._id === seoCheckupData?.urlDataId && (
                  <Accordion defaultActiveKey="0" style={{ height: 'calc(100vh - 182px)' }}>
                    <Accordion.Item eventKey="0" className="headerStyle">
                      {/* <Accordion.Header> */}
                      <Container className="sidebar-row-container" fluid>
                        <Row className="f-8">
                          <Col className="report-test-section-name" md="4">
                            GENERAL
                          </Col>
                          <Col>
                            {' '}
                            <span className="pass-block">
                              {' '}
                              <img style={{ height: 14 }} src={tickIcon} />{' '}
                              <span style={{ color: palette.success }}>{positiveCounter}</span>
                            </span>{' '}
                            <span className="pass-block">
                              {' '}
                              <img style={{ height: 14 }} src={warningIcon} />{' '}
                              <span style={{ color: palette.warning, marginRight: 5 }}>{warningCounter}</span>
                            </span>{' '}
                            <span className="pass-block">
                              <img style={{ height: 14 }} src={crossIcon} />{' '}
                              <span style={{ color: palette.danger }}>{negativeCounter}</span>
                            </span>
                          </Col>
                        </Row>
                      </Container>
                      {/* </Accordion.Header> */}
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <Container className="sidebar-row-container">
                          <Row className="f-8">
                            <Col className="report-test-section-name" md="4">
                              PRIMARY SEO SIGNALS
                            </Col>
                            <Col style={{ margin: 'auto' }}>
                              <span className="pass-block" style={{ alignItem: 'center' }}>
                                {' '}
                                <img style={{ height: 14 }} src={tickIcon} />{' '}
                                <span style={{ color: palette.success }}>{commonSeoSCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                {' '}
                                <img style={{ height: 14 }} src={warningIcon} />{' '}
                                <span style={{ color: palette.warning, marginRight: 5 }}>
                                  {commonSeoWCounter}
                                </span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={crossIcon} />{' '}
                                <span style={{ color: palette.danger }}>{commonSeoFCounter}</span>
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <p>
                          {urlData?.information?.seoCheckup?.title?.titleStatus === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.information?.seoCheckup?.title?.titleStatus === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="meta-title" spy={true} smooth={true} offset={-150} duration={500}>
                            Meta Title Signal
                          </Link>
                        </p>

                        <p>
                          {urlData?.information?.seoCheckup?.description?.descriptionStatus === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.information?.seoCheckup?.description?.descriptionStatus === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="meta-description" spy={true} smooth={true} offset={-150} duration={500}>
                            Meta Description Signal
                          </Link>
                        </p>

                        <p>
                          {urlData?.microDataSchema?.data?.metaTagsResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.microDataSchema?.data?.metaTagsResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link
                            to="social-media-meta-tag"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                          >
                            Social Media Meta Tags Signals
                          </Link>
                        </p>

                        <p>
                          {urlData?.microDataSchema?.data?.keywordSuccessFlag === true ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="keyword-usage-test" spy={true} smooth={true} offset={-150} duration={500}>
                            Keyword Usage Test Signals
                          </Link>
                        </p>
                        <p>
                          {urlData?.headingAnalysis?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.headingAnalysis?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="heading-analysis" spy={true} smooth={true} offset={-150} duration={500}>
                            Heading Tag Analysis Signals
                          </Link>
                        </p>

                        <p>
                          {urlData?.robotsTxtTest?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.robotsTxtTest?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="robots-txt" spy={true} smooth={true} offset={-150} duration={500}>
                            Robots.txt Signal Analysis
                          </Link>
                        </p>

                        <p>
                          {urlData?.siteMapData?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.siteMapData?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="sitemap-data" spy={true} smooth={true} offset={-150} duration={500}>
                            Sitemap Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="seo-friendly" spy={true} smooth={true} offset={-150} duration={500}>
                            SEO Friendly URL Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.imageAlt?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.imageAlt?.data?.testResults?.status === 2 ? (
                            <span style={{ color: palette.warning }}>Warning</span>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="image-alt" spy={true} smooth={true} offset={-150} duration={500}>
                            Image Alt Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.descriptiveLinkTest?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.descriptiveLinkTest?.data?.testResults?.status === 2 ? (
                            <span style={{ color: palette.warning }}>Warning</span>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="descriptive-test" spy={true} smooth={true} offset={-150} duration={500}>
                            Descriptive Link Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.responsiveImage?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.responsiveImage?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="responsive-image" spy={true} smooth={true} offset={-150} duration={500}>
                            Excessive Image Data Size Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.aspectRatio?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.aspectRatio?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="aspect-ratio" spy={true} smooth={true} offset={-150} duration={500}>
                            Image Aspect Ratio Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.inlineCss?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.inlineCss?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="inline-css" spy={true} smooth={true} offset={-150} duration={500}>
                            Inline CSS Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.htmlValidation?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.htmlValidation?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="html-validation" spy={true} smooth={true} offset={-150} duration={500}>
                            Html Validation
                          </Link>
                        </p>

                        <p>
                          {urlData?.googleAnalytics?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.googleAnalytics?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;{' '}
                          <Link to="google-analytics" spy={true} smooth={true} offset={-150} duration={500}>
                            Google Analytics Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.faviconCheckerTest?.data?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="favicon-test" spy={true} smooth={true} offset={-150} duration={500}>
                            Favicon Checker Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.brokenLinks?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.brokenLinks?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="brokenLinks-data" spy={true} smooth={true} offset={-150} duration={500}>
                            Internal Broken Links Checker Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.jsError?.data?.testResults.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.jsError?.data?.testResults.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="js-error" spy={true} smooth={true} offset={-150} duration={500}>
                            JS Error Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.consoleErrors?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.consoleErrors?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="console-errors" spy={true} smooth={true} offset={-150} duration={500}>
                            Historic Console Error Signal Check
                          </Link>
                        </p>

                        <p>
                          {urlData?.advancedConsoleErrors?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.advancedConsoleErrors?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link
                            to="advanced-console-errors"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                          >
                            Advanced Console Error Signal Check
                          </Link>
                        </p>

                        <p>
                          {urlData?.characterSet?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.characterSet?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="character-set" spy={true} smooth={true} offset={-150} duration={500}>
                            Charset Declaration Signal Test
                          </Link>
                        </p>

                        <p>
                          {urlData?.socialMediaTwitterData?.data?.testResults?.status === 1 ? (
                            <Badge bg={'success'} className="me-1 mb-1">
                              Passed
                            </Badge>
                          ) : urlData?.socialMediaTwitterData?.data?.testResults?.status === 2 ? (
                            <Badge bg={'warning'} className="me-1 mb-1">
                              Warning
                            </Badge>
                          ) : (
                            <Badge bg={'danger'} className="me-1 mb-1">
                              Failed
                            </Badge>
                          )}
                          &nbsp;
                          <Link to="social-media" spy={true} smooth={true} offset={-150} duration={500}>
                            Social Media Signal Test
                          </Link>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <Container className="sidebar-row-container">
                          <Row className="f-8">
                            <Col className="report-test-section-name" md="4">
                              WEBSITE SITE LOADING SIGNALS
                            </Col>
                            <Col style={{ margin: 'auto' }}>
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={tickIcon} />{' '}
                                <span style={{ color: palette.success }}>{speedOptimizeSCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={warningIcon} />{' '}
                                <span style={{ color: palette.warning }}>{speedOptimizeWCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={crossIcon} />{' '}
                                <span style={{ color: palette.danger }}>{speedOptimizeFCounter}</span>
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <MenuTitleTest
                          linkTo={'html-page'}
                          testStatus={urlData?.htmlPageSize?.data?.testResults?.status}
                          title={'HTML Page Size Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'dom-size'}
                          testStatus={urlData?.domSize?.data?.testResults?.status}
                          title={'DOM Size Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'html-compression'}
                          testStatus={urlData?.htmlCompression?.data?.testResults?.status}
                          title={'Web Content Compression Check'}
                        />
                        <MenuTitleTest
                          linkTo={'site-speed'}
                          testStatus={urlData?.siteSpeed?.data?.testResults?.status}
                          title={'Site Loading Speed Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'js-execution-time'}
                          testStatus={urlData?.bootupTime?.data?.testResults?.status}
                          title={'JS Execution Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'page-object'}
                          testStatus={urlData?.pageObject?.data?.testResults?.status}
                          title={'Page Objects Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'page-cache'}
                          testStatus={urlData?.pageCache?.data?.testResults?.status}
                          title={'Page Cache Test (Server Side Caching) Signal Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'flash-test'}
                          testStatus={urlData?.flashTest?.data?.testResults?.status}
                          title={'Flash Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'cdn-usage'}
                          testStatus={urlData?.cdnUsageData?.data?.testResult?.status}
                          title={'CDN Usage Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'morden-image'}
                          testStatus={urlData?.mordenImage?.data?.testResults?.status}
                          title={'Morden Image Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'cache-policy'}
                          testStatus={urlData?.cachePolicy?.data?.testResults?.status}
                          title={'Cache Policy Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'image-cache'}
                          testStatus={urlData?.imageCache?.data?.testResults?.status}
                          title={'Image Cache Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'js-cache'}
                          testStatus={urlData?.jsCache?.data?.testResults?.status}
                          title={'JavaScript Caching Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'css-cache'}
                          testStatus={urlData?.cssCache?.data?.testResults?.status}
                          title={'CSS Caching Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'js-minification'}
                          testStatus={urlData?.jsMinification?.data?.testResults?.status}
                          title={'JavaScript Minification Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'css-minification'}
                          testStatus={urlData?.cssMinification?.data?.testResults?.status}
                          title={'CSS Minification Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'render-blocking'}
                          testStatus={urlData?.renderBlocking?.data?.testResults?.status}
                          title={'Render Blocking Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'nested-table'}
                          testStatus={urlData?.nestedTable?.data?.testResults?.status}
                          title={'Nested Tables Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'frameset-test'}
                          testStatus={urlData?.framesetTest?.data?.testResults?.status}
                          title={'Frameset Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'doc-type'}
                          testStatus={urlData?.docType?.data?.testResults?.status}
                          title={'Doctype Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'redirect-test'}
                          testStatus={urlData?.redirectTest?.data?.testResults?.status}
                          title={'Redirect Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'contentful-paint'}
                          testStatus={urlData?.contentfulPaint?.data?.testResults?.status}
                          title={'Contentful Paint Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'layout-shift'}
                          testStatus={urlData?.layoutShift?.data?.testResults?.status}
                          title={'Cumulative Layout Shift Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'css-analysis'}
                          testStatus={urlData?.cssAnalysis?.data?.testResults?.status}
                          title={'Css Analysis Test'}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <Container className="sidebar-row-container">
                          <Row className="f-8">
                            <Col className="report-test-section-name" md="4">
                              SERVER CONFIGURATION & SECURITY SIGNALS
                            </Col>
                            <Col style={{ margin: 'auto' }}>
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={tickIcon} />{' '}
                                <span style={{ color: palette.success }}>{serverSCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={warningIcon} />{' '}
                                <span style={{ color: palette.warning }}>{serverWCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={crossIcon} />{' '}
                                <span style={{ color: palette.danger }}>{serverFCounter}</span>
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <MenuTitleTest
                          linkTo={'url-canonicalization'}
                          testStatus={urlData?.urlCanonicalization?.data?.testResult?.status}
                          title={'URL Canonicalization Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'https-test'}
                          testStatus={urlData?.httpsTest?.data?.testResults?.status}
                          title={'Mixed Content Test (HTTP over HTTPS)'}
                        />
                        <MenuTitleTest
                          linkTo={'http2-test'}
                          testStatus={urlData?.http2Test?.data?.testResults?.status}
                          title={'HTTP2 Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'hsts'}
                          testStatus={urlData?.hstsTest?.data?.testResults?.status}
                          title={'HSTS Signal Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'safe-browsing'}
                          testStatus={urlData?.safeBrowsing?.data?.testResults?.status}
                          title={'Safe Browsing Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'server-signature'}
                          testStatus={urlData?.serverSignature?.data?.testResults?.status}
                          title={'Server Signature Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'directory-browsing'}
                          testStatus={urlData?.directoryBrowsing?.data?.testResults?.status}
                          title={'Directory Browsing Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'plain-text'}
                          testStatus={urlData?.plainTextEmail?.data?.testResults?.status}
                          title={'Plaintext Emails Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'unsafe-cross-origin-links'}
                          testStatus={urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status}
                          title={'Unsafe Cross-Origin Signal Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'ssl-freak'}
                          testStatus={urlData?.sslFreakTest?.data?.testResults?.status}
                          title={'SSL and HTTPS Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'brotil-test'}
                          testStatus={urlData?.brotilTest?.data?.testResults?.status}
                          title={'Brotli Analysis Test'}
                        />
                        <MenuTitleTest
                          linkTo={'tls-logjam'}
                          testStatus={urlData?.tlsLogjamTest?.data?.testResults?.status}
                          title={'TLS Logjam Signal Test'}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <Container className="sidebar-row-container">
                          <Row className="f-8">
                            <Col className="report-test-section-name" md="4">
                              MOBILE RESPONSIVE & MOBILE SPEED SIGNALS
                            </Col>
                            <Col style={{ margin: 'auto' }}>
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={tickIcon} />{' '}
                                <span style={{ color: palette.success }}>{mobileUsabilitySCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={warningIcon} />{' '}
                                <span style={{ color: palette.warning }}>{mobileUsabilityWCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={crossIcon} />{' '}
                                <span style={{ color: palette.danger }}>{mobileUsabilityFCounter}</span>
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <MenuTitleTest
                          linkTo={'viewport'}
                          testStatus={urlData?.viewport?.data?.testResults?.status}
                          title={'Viewport Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'font-size'}
                          testStatus={urlData?.fontSizeTest?.data?.testResults?.status}
                          title={'Font Size Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'tap-target'}
                          testStatus={urlData?.tapTargetTest?.data?.testResults?.status}
                          title={'Tap Target Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'content-width'}
                          testStatus={urlData?.contentWidthTest?.data?.testResults?.status}
                          title={'Content-width Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'speed-index'}
                          testStatus={urlData?.speedIndexTest?.data?.testResults?.status}
                          title={'Speed Index Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'first-contentful-paint'}
                          testStatus={urlData?.firstContentfulPaintTest?.data?.testResults?.status}
                          title={'First Contentful Paint Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'last-contentful-paint'}
                          testStatus={urlData?.largestContentfulPaintTest?.data?.testResults?.status}
                          title={'Largest Contentful Paint Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'cumulative-layout-shift-test'}
                          testStatus={urlData?.cumulativeLayoutShiftTest?.data?.testResults?.status}
                          title={'Cumulative Layout Shift Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'interactive'}
                          testStatus={urlData?.interactiveTest?.data?.testResults?.status}
                          title={'Interactive Signals'}
                        />
                        <MenuTitleTest
                          linkTo={'first-input-delay'}
                          testStatus={urlData?.firstInputDelayTest?.data?.testResults?.status}
                          title={'First Input Delay Signals'}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <Container className="sidebar-row-container">
                          <Row className="f-8">
                            <Col className="report-test-section-name" md="4">
                              ADVANCED SEO SIGNAL TEST
                            </Col>
                            <Col style={{ margin: 'auto' }}>
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={tickIcon} />{' '}
                                <span style={{ color: palette.success }}>{advanceSeoSCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={warningIcon} />{' '}
                                <span style={{ color: palette.warning }}>{advanceSeoWCounter}</span>
                              </span>{' '}
                              <span className="pass-block">
                                <img style={{ height: 14 }} src={crossIcon} />{' '}
                                <span style={{ color: palette.danger }}>{advanceSeoFCounter}</span>
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <MenuTitleTest
                          linkTo={'microdata-schema'}
                          testStatus={urlData?.microDataSchema?.data?.microSchemaResults?.status}
                          title={'Microdata Schema Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'custom-404'}
                          testStatus={urlData?.custom404?.data?.testResult?.status}
                          title={'Custom 404 Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'no-index'}
                          testStatus={urlData?.noIndexChecker?.data?.testResult?.status}
                          title={'No Index Checker Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'canonical-url'}
                          testStatus={urlData?.canonicalUrl?.data?.testResult?.status}
                          title={'Canonical URL Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'meta-refresh'}
                          testStatus={urlData?.metaRefresh?.data?.testResult?.status}
                          title={'Meta Refresh Signal Test'}
                        />
                        <MenuTitleTest
                          linkTo={'spf-record'}
                          testStatus={urlData?.spfRecord?.data?.testResult?.status}
                          title={'SPF Record Analysis'}
                        />
                        <MenuTitleTest
                          linkTo={'ads-txt'}
                          testStatus={urlData?.adsTxt?.data?.testResults?.status}
                          title={'Ads.txt Analysis'}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <Container className="sidebar-row-container">
                          <Row className="f-8">
                            <Col className="report-test-section-name">NON TRANSFORMATIVE SIGNALS</Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <p>
                          &nbsp;
                          <Link to="seo-result" spy={true} smooth={true} offset={-150} duration={500}>
                            Google Search Results Preview - SERP View
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="common" spy={true} smooth={true} offset={-150} duration={500}>
                            Keyword Density Signals Check
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="keyword-cloud" spy={true} smooth={true} offset={-150} duration={500}>
                            Keyword Cloud Signals - Visual & Count
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="related-keywords" spy={true} smooth={true} offset={-150} duration={500}>
                            Related Keywords Test Signals
                          </Link>
                        </p>
                        {/* <p>&nbsp;Mobile Screenshot </p> */}
                        <p>
                          &nbsp;
                          <Link to="competitor" spy={true} smooth={true} offset={-150} duration={500}>
                            Competitor Domain Signals
                          </Link>{' '}
                        </p>
                        <p>
                          &nbsp;
                          <Link to="backlinks" spy={true} smooth={true} offset={-150} duration={500}>
                            Backlinks
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="dns-security" spy={true} smooth={true} offset={-150} duration={500}>
                            DNS Security
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="mobile-screenshot" spy={true} smooth={true} offset={-150} duration={500}>
                            Mobile Screenshot Analysis
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="desktop-screenshot" spy={true} smooth={true} offset={-150} duration={500}>
                            Desktop Screenshot
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link to="no-follow" spy={true} smooth={true} offset={-150} duration={500}>
                            No Follow Signal Test
                          </Link>
                        </p>
                        <p>
                          &nbsp;
                          <Link
                            to="disalllow-directive"
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                          >
                            Disallow Directive Signal Test
                          </Link>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )) || <></>}
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                  minHeight: '80vh',
                }}
              >
                <div className="text-center">
                  <Loader />
                </div>
              </div>
            </>
          )}
        </div>
      </Row>
    </Container>
  )
}

export default DefaultNew

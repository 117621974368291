import { useState } from 'react'
// import crossIcon from "../../../assets/img/icons/cross.png";
// import tickIcon from "../../../assets/img/icons/tick-mark.png";
// import warningIcon from "../../../assets/img/icons/warning.png";

import * as Icon from 'react-feather'
import ModalHOC from '../tests-components/ModalHOC'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const DnsSecurityTest = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [dnsSecurityFlag, setDnsSecurityFlag] = useState(false)

  const dns_security_column = [
    {
      id:'item',
      displayName:"DNS Security Data"
    }
  ]

  const dnsSecurityCsv = data?.dnsSecurityTest?.data?.dnsSecurity?.label3.map(item => {
    return {
      item: item
    }
  })
  return (
    <>
      {data && data?.dnsSecurityTest ? (
        <>
          <div className="vr-url-block">
            <div className="vr-report-status-icon" />
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>

          <div className="vr-detail-block">
            <p>{data?.dnsSecurityTest?.data?.testResults?.message}</p>
            {showDetail && data?.dnsSecurityTest?.data?.testResults?.status !== 1 && data?.dnsSecurityTest?.data?.dnsSecurity?.label3?.length ? (
              <>
                <p
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    if (testType === "") {
                      setDnsSecurityFlag(!dnsSecurityFlag)
                    } else {
                      dispatch(openRegisterModal(true))
                    }
                  }}
                >
                  {' '}
                  See Details
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <ModalHOC
            show={dnsSecurityFlag}
            toggleShow={() => setDnsSecurityFlag(!dnsSecurityFlag)}
            title={'DNS Security'}
            bodyClassName="m-3"
            titleStyles={{ fontWeight: '600', fontSize: '22px' }}
            showDownload
            datas={dnsSecurityCsv}
            columns={dns_security_column}
            fileName='DNS Security'
          >
            {data?.dnsSecurityTest?.data?.dnsSecurity?.label3?.length ? (
              <>
                {data?.dnsSecurityTest?.data?.dnsSecurity?.label3.map((item, index) => {
                  return (
                    <div key={`dnsSecurityTest-${index+1}`}>
                      {item.substring(0, 2) === 'No' ? (
                        <Icon.X size={16} style={{ marginRight: 5 }} color="red" />
                      ) : (
                        <Icon.Check size={16} style={{ marginRight: 5 }} color="green" />
                      )}
                      <span>&nbsp;{item}</span>
                    </div>
                  )
                })}
                <br />
              </>
            ) : (
              <></>
            )}
          </ModalHOC>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DnsSecurityTest

import './demo.css'

import tickIcon from '../../../../assets/img/icons/tick-mark.png'
import crossIcon from '../../../../assets/img/icons/cross.png'

const CommonSEOWhiteLabel = ({ status, testName, isConsidered = true }) => {
  return (
    <>
      {/* {showDevider ? <hr /> : <></>} */}
      <tr>
        <td className="status-icon">
          {isConsidered ? (
            <>
              {' '}
              <img
                src={
                  status === 1
                    ? tickIcon
                    : status == 2
                      ? 'https://tools1.dev-positionmysite.com/warning.png'
                      : crossIcon
                }
                width="24"
                height="24"
                alt="tick-mark-icon"
              />{' '}
            </>
          ) : (
            <>
              <th scope="row" className="status-icon">
                <div className="default-icon" />
              </th>
            </>
          )}
        </td>
        <td className="meta-title">
          <h6>{testName}</h6>
        </td>
      </tr>
    </>
  )
}

export default CommonSEOWhiteLabel

import { useEffect, useState } from 'react'
import { Card, Pagination, Table } from 'react-bootstrap'

import 'pages/dashboards/GenerateResearchTable.scss'

const TrafficAnalyticsSourceTable = ({ analytics }) => {
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1)
  const [websiteData, setWebsiteData] = useState([])

  useEffect(() => {
    const dataToDisplay = analytics?.userAcquisition?.length ? analytics?.userAcquisition : []
        
    setWebsiteData(dataToDisplay)
  }, [analytics])

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = websiteData?.length && websiteData?.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(websiteData?.length / itemsPerPage)

  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1)
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(pageNumber)
    }
  }

  let paginationItems = []
  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }
  } else {
    // More than 5 pages, handle ellipsis
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage <= 2) {
      endPage = 5
    } else if (currentPage >= totalPages - 1) {
      startPage = totalPages - 4
    }

    if (startPage > 2) {
      paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" disabled />)
    }

    // Pages
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
          {i}
        </Pagination.Item>,
      )
    }

    if (endPage < totalPages - 1) {
      paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" disabled />)
    }
  }

  const RenderComponent = () => {
      if (websiteData?.length) {
        return (
          <Card>
            <Card.Header>
              <h3 className='f-bold'>Analytics based on source</h3>
              <hr />
            </Card.Header>
            <Card.Body>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First user source/medium</th>
                    <th scope="col">City</th>
                    <th>New users</th>
                    <th>Engaged sessions</th>
                    <th>Engagement rate</th>
                    <th>Engaged sessions per user</th>
                    <th>Average engagement time</th>
                    <th>Event count</th>
                    <th>Key events</th>
                    <th>Total revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.length &&
                    currentItems?.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td>{item?.userSourceMedium}</td>
                        <td>{item?.userCity}</td>
                        <td>{item?.newUsers}</td>
                        <td>{item?.engagedSessions}</td>
                        <td>{item?.engagementRate}</td>
                        <td>{item?.engagedSessionsPerUser}</td>
                        <td>{item?.averageEngagementTime}</td>
                        <td>{item?.eventCount}</td>
                        <td>{item?.userKeyEventRate}</td>
                        <td>{item?.totalRevenue}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Pagination className="research-data-pagination">
                <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                {paginationItems}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>{' '}
            </Card.Body>
          </Card>
        )
      } else {
        return null
      }
    } 

  return <RenderComponent />
}

export default TrafficAnalyticsSourceTable

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody, CardHeader, CardTitle } from 'reactstrap'
import { Button, ButtonGroup, Card, Col, Row, Spinner, Tab } from 'react-bootstrap'
import { message } from 'antd'

import Header from 'components/Header/Header'
import PlanData from '../../utils/paymentUtil'
import paths from 'constants/paths'

import { stripeCheckout } from 'redux/actions/freetrial/startFreeTrial'
import Subscription from 'assets/img/pms/subscription.svg'
import 'modules/subscriptionPlans/subscriptionPlans.scss'
import { useDeviceCheck } from 'hooks/deviceHook'
import { PLAN } from 'constants/enum'

const SubscpriptionPlans = () => {
  const { stripeFailure, stripeError, redirectUrlForStripe, stripeSuccess } = useSelector(
    ({ startFreeTrial }) => startFreeTrial,
  )
  const { registerData } = useSelector(({ register }) => register)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState('Monthly')
  const [loaderForSubscription, setLoaderForSubscription] = useState('')
  const { isMobile } = useDeviceCheck()

  useEffect(() => {
    const fetchUserdata = async () => {
      const userData = await localStorage.getItem('userData')

      if (userData && JSON.parse(userData)?._id) {
        dispatch({ type: 'STORE_USER_DATA_START', payload: JSON.parse(userData) })
      }
      if (
        userData &&
        JSON.parse(userData)?.subscriptions &&
        JSON.parse(userData)?.subscriptions?.length &&
        (JSON.parse(userData)?.subscriptions?.[0]?.isActive ||
          JSON.parse(userData)?.subscriptions?.[0]?.isTrial)
      ) {
        if (
          JSON.parse(userData) &&
          JSON.parse(userData)?.websites &&
          JSON.parse(userData)?.websites?.length
        ) {
          navigate(paths.mainDashboard)
        } else {
          navigate(paths.newUserWelcomePage)
        }
      } else {
        navigate(paths.newUserSubscriptionPage)
      }
    }
    fetchUserdata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (stripeFailure && stripeError && Object.keys(stripeError)?.length) {
      setLoaderForSubscription('')
      message.error(stripeError?.message)
      dispatch({ type: 'CLEAR_STRIPE_CHECKOUT' })
    }

    if (stripeSuccess && redirectUrlForStripe) {
      setLoaderForSubscription('')
      window.open(redirectUrlForStripe, '_self')
      dispatch({ type: 'CLEAR_STRIPE_CHECKOUT' })
    }
  }, [stripeError, stripeFailure, stripeSuccess, redirectUrlForStripe])

  const cardPayment = (planName, planDuration) => {
    const resultData =
      PlanData &&
      PlanData?.find(
        (item) => item?.name === planName && item?.type === planDuration && item?.platform === 'Stripe',
      )

    const id = resultData?._id
    const paymentData = {
      plan: id,
      customerId: registerData?.customerId,
    }

    dispatch(stripeCheckout(paymentData))
  }

  return (
    <>
      <Header customClassName="subscription-header" isDemoReportPage isEmailMobile isEmailLaptop />
      <Card className="subscription-plans">
        <CardHeader className="pb-0 subscription-plans-header">
          <CardTitle>
            <h3 className="mt-3 title-one">Empower Your SEO Journey: Precision, Simplicity, Results</h3>
            <h3 className="mt-3 title-second">Unlock the Power of SEO: 24-Hour Full-Service Trial!</h3>
          </CardTitle>
          <hr />
        </CardHeader>
        <CardBody className="pt-0">
          <Tab.Container id="pricing-tabs" activeKey={activeTab}>
            <Row className="justify-content-center mt-3 mb-2">
              <Col xs="auto">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    onClick={() => setActiveTab('Monthly')}
                    className={activeTab === 'Monthly' ? 'active' : ''}
                  >
                    Monthly billing
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setActiveTab('Yearly')}
                    className={activeTab === 'Yearly' ? 'active' : ''}
                  >
                    Annual billing
                  </Button>
                </ButtonGroup>
                <img
                  src={Subscription}
                  alt="subscription"
                  className="subscription-plans-img"
                  width={isMobile ? 180 : 400}
                  height={isMobile ? 180 : 450}
                />
              </Col>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="Monthly">
                <Row className="py-4">
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 text-left plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>{PLAN.PREMIUM_PLAN.name}</h5>
                      <h3 className={'plan-price'}>
                        ${PLAN.PREMIUM_PLAN.monthly_price}
                        <span className="fs-3" style={{ color: '#3b82f6' }}>
                          /mo
                        </span>
                      </h3>
                      <p>
                        ${PLAN.PREMIUM_PLAN.yearly_price.perMonth}/mo billed yearly, saved{' '}
                        {PLAN.PREMIUM_PLAN.yearly_price.saved}
                      </p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">15</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          cardPayment(PLAN.PREMIUM_PLAN.name, 'Monthly')
                          setLoaderForSubscription(`${PLAN.PREMIUM_PLAN.name} monthly`)
                        }}
                      >
                        {loaderForSubscription === `${PLAN.PREMIUM_PLAN.name} monthly` ? (
                          <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                          <></>
                        )}
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>{PLAN.PRO_PLAN.name}</h5>
                      <h3 className={'plan-price'}>
                        ${PLAN.PRO_PLAN.monthly_price}
                        <span className="fs-3" style={{ color: '#3b82f6' }}>
                          /mo
                        </span>
                      </h3>
                      <p>
                        ${PLAN.PRO_PLAN.yearly_price.perMonth}/mo billed yearly, saved{' '}
                        {PLAN.PRO_PLAN.yearly_price.saved}
                      </p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          cardPayment(PLAN.PRO_PLAN.name, 'Monthly')
                          setLoaderForSubscription(`${PLAN.PRO_PLAN.name} monthly`)
                        }}
                      >
                        {loaderForSubscription === `${PLAN.PRO_PLAN.name} monthly` ? (
                          <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                          <></>
                        )}
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0 plantable" style={{ border: '1px solid #cfcfcf' }}>
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>{PLAN.BASIC_PLAN.name}</h5>
                      <h3 className={'plan-price'}>
                        ${PLAN.BASIC_PLAN.monthly_price}
                        <span className="fs-3" style={{ color: '#3b82f6' }}>
                          /mo
                        </span>
                      </h3>
                      <p>
                        ${PLAN.BASIC_PLAN.yearly_price.perMonth}/mo billed yearly, saved{' '}
                        {PLAN.BASIC_PLAN.yearly_price.saved}
                      </p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">NOT INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          cardPayment(PLAN.BASIC_PLAN.name, 'Monthly')
                          setLoaderForSubscription(`${PLAN.BASIC_PLAN.name} monthly`)
                        }}
                      >
                        {loaderForSubscription === `${PLAN.BASIC_PLAN.name} monthly` ? (
                          <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                          <></>
                        )}
                        Choose
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="Yearly">
                <Row className="py-4">
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>{PLAN.PREMIUM_PLAN.name}</h5>
                      <h3 className={'plan-price'}>
                        ${PLAN.PREMIUM_PLAN.yearly_price.perMonth}
                        <span className="fs-3" style={{ color: '#3b82f6' }}>
                          /mo
                        </span>
                      </h3>
                      <p>
                        billed yearly, {PLAN.PREMIUM_PLAN.yearly_price.saved} off, $
                        {PLAN.PREMIUM_PLAN.yearly_price.perYear}/year
                      </p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">15</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">300</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          cardPayment(PLAN.PREMIUM_PLAN.name, 'Yearly')
                          setLoaderForSubscription(`${PLAN.PREMIUM_PLAN.name} yearly`)
                        }}
                      >
                        {loaderForSubscription === `${PLAN.PREMIUM_PLAN.name} yearly` ? (
                          <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                          <></>
                        )}
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col
                    sm="4"
                    className="mb-3 mb-md-0 plantable pricing-plan"
                    style={{ border: '1px solid #cfcfcf' }}
                  >
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>{PLAN.PRO_PLAN.name}</h5>
                      <h3 className={'plan-price'}>
                        ${PLAN.PRO_PLAN.yearly_price.perMonth}
                        <span className="fs-3" style={{ color: '#3b82f6' }}>
                          /mo
                        </span>
                      </h3>
                      <p>
                        billed yearly, {PLAN.PRO_PLAN.yearly_price.saved} off, $
                        {PLAN.PRO_PLAN.yearly_price.perYear}/year
                      </p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">5</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">100</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          cardPayment(PLAN.PRO_PLAN.name, 'Yearly')
                          setLoaderForSubscription(`${PLAN.PRO_PLAN.name} yearly`)
                        }}
                      >
                        {loaderForSubscription === `${PLAN.PRO_PLAN.name} yearly` ? (
                          <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                          <></>
                        )}
                        Choose
                      </Button>
                    </div>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0 plantable" style={{ border: '1px solid #cfcfcf' }}>
                    <div className="mb-4 mt-4 text-left">
                      <h5 className={'plan-name'}>{PLAN.BASIC_PLAN.name}</h5>
                      <h3 className={'plan-price'}>
                        ${PLAN.BASIC_PLAN.yearly_price.perMonth}
                        <span className="fs-3" style={{ color: '#3b82f6' }}>
                          /mo
                        </span>
                      </h3>
                      <p>
                        billed yearly, {PLAN.BASIC_PLAN.yearly_price.saved} off, $
                        {PLAN.BASIC_PLAN.yearly_price.perYear}/year
                      </p>
                    </div>
                    <hr />

                    <div className="mb-2 mt-2 text-left">
                      <h6>SEO ANALYSIS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED WEBSITE</h6>
                      <span className="display-10">3</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED KEYWORDS</h6>
                      <span className="display-10">50</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>MONITORED REGIONS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>ANALYZED COMPETITORS</h6>
                      <span className="display-10">1</span>
                    </div>
                    <hr />
                    <div className="mb-2 mt-2 text-left">
                      <h6>WHITE LABEL REPORTS</h6>
                      <span className="display-10">NOT INCLUDED</span>
                    </div>
                    <div className="mt-auto">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-100 mt-2"
                        onClick={() => {
                          cardPayment(PLAN.BASIC_PLAN.name, 'Yearly')
                          setLoaderForSubscription(`${PLAN.BASIC_PLAN.name} yearly`)
                        }}
                      >
                        {loaderForSubscription === `${PLAN.BASIC_PLAN.name} yearly` ? (
                          <Spinner animation="border" key={1} className="me-2" size="sm" />
                        ) : (
                          <></>
                        )}
                        Choose
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </CardBody>
      </Card>
    </>
  )
}

export default SubscpriptionPlans

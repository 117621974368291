import React from 'react'
import { Outlet } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

import Main from 'components/Main.js'

const Trail = ({ children }) => {
  return (
    <React.Fragment>
      <Main className="d-flex w-100 justify-content-center trial-main">
        <Container className="d-flex flex-column px-0">
          <Row className="h-100">
            <Col sm="12" md="12" lg="12" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                {children}
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </Main>
      {/* <Settings /> */}
    </React.Fragment>
  )
}

export default Trail

import {
  GET_BROKEN_LINKS_LIST,
  GET_BROKEN_LINKS_LIST_SUCCESS,
  GET_BROKEN_LINKS_LIST_FAILURE,
} from '../../actionType'

const initialState = {
  loading: false,
  getBrokenLinkListloader: false,
  getBrokenLinkListSuccess: false,
  getBrokenLinkListError: false,
  getBrokenLinkListData: [],
}

const brokenLinksListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BROKEN_LINKS_LIST:
      return {
        ...state,
        getBrokenLinkListloader: true,
        getBrokenLinkListSuccess: false,
        getBrokenLinkListError: false,
      }
    case GET_BROKEN_LINKS_LIST_SUCCESS:
      return {
        ...state,
        getBrokenLinkListData: action.payload,
        getBrokenLinkListloader: false,
        getBrokenLinkListSuccess: true,
        getBrokenLinkListError: false,
      }

    case GET_BROKEN_LINKS_LIST_FAILURE:
      return {
        ...state,
        getBrokenLinkListloader: false,
        getBrokenLinkListSuccess: false,
        getBrokenLinkListError: true,
      }

    default:
      return state
  }
}
export default brokenLinksListReducer

import {
  GET_URL_DETAILS,
  STORE_PREVIOUS_PAGE_DATA,
  CHECK_URL,
  CHECK_URL_CLEAR,
  TOGGLE_CHECKBOX,
  TOGGLE_KEYWORD_CHECKBOX,
  ADD_USER,
  ADD_USER_CLEAR,
  STORE_RANDOM_NUMBER,
  PROCESS_PAYMENT_START,
  ADD_WEBSITE,
  START_SUBSCRIPTION,
  START_SUBSCRIPTION_CLEAR,
  ADD_WEBSITE_TO_USER,
  GET_COUNTRIES,
  GET_LOCATIONS,
  CLEAR_URL_DETAILS,
  CLEAR_URL_DETAILS_2,
  VALIDATE_URL_DETAILS,
  CLEAR_VALIDATE_URL_DETAILS,
  VALIDATE_URL_ABOUT_TO_HAPPEN,
  CLEAR_URL_DETAILS_3,
  GET_SITEMAP,
  SITEMAP_DATA_TO_SENT,
  GET_LIGHTHOUSE,
  GET_LIGHTHOUSE_CLEAR,
  SET_BACKLINKS_DATA,
  IS_START_SCAN,
  MODAL_OPEN_LIGHTHOUSE,
  IS_WEBSITE_DATA,
  STOP_ADD_WEBSITE_LOADER,
  GET_LIGHTHOUSE_STATUS,
  GET_BILLING_INFORMATION,
  CANCEL_SUBSCRIPTION,
  IS_CALL_FOR,
  DISCARD_TRIAL_PERIOD,
  STRIPE_CHECKOUT,
  ADD_PAYMENT_METHOD,
  TRIAL_SEO_CHECKUP,
  GET_TRIAL_SEO_REPORT,
  OPEN_REGISTER_MODAL,
  GET_PUBLIC_KEY
} from '../../actionType'

export const startFreeTrialAction = (data) => ({
  type: GET_URL_DETAILS,
  payload: data,
})

export const validateFreeTrialAction = (data) => ({
  type: VALIDATE_URL_DETAILS,
  payload: data,
})

export const addWebsiteToUser = (data) => ({
  type: ADD_WEBSITE_TO_USER,
  payload: data,
})

export const storePreviousPageData = (data) => ({
  type: STORE_PREVIOUS_PAGE_DATA,
  payload: data,
})

export const storeRandomNumber = () => ({
  type: STORE_RANDOM_NUMBER,
})

export const toggleCheckbox = (data) => ({
  type: TOGGLE_CHECKBOX,
  payload: data,
})

export const clearUrlDetails = () => ({
  type: CLEAR_URL_DETAILS,
})

export const clearUrlDetails2 = () => ({
  type: CLEAR_URL_DETAILS_2,
})
export const clearUrlDetails3 = () => ({
  type: CLEAR_URL_DETAILS_3,
})
export const checkUrl = (data) => ({
  type: CHECK_URL,
  payload: data,
})

export const clearValidateUrlDetails = () => ({
  type: CLEAR_VALIDATE_URL_DETAILS,
})
export const validateURLAboutToHappen = () => ({
  type: VALIDATE_URL_ABOUT_TO_HAPPEN,
})
export const toggleKeywordCheckbox = (data) => ({
  type: TOGGLE_KEYWORD_CHECKBOX,
  payload: data,
})

export const setSitemapDataToSent = (data) => ({
  type: SITEMAP_DATA_TO_SENT,
  payload: data,
})

export const addUser = (data) => ({
  type: ADD_USER,
  payload: data,
})

export const addUserClear = () => ({
  type: ADD_USER_CLEAR,
})

export const processPayment = (data) => ({
  type: PROCESS_PAYMENT_START,
  payload: data,
})

export const addWebsite = (data) => ({
  type: ADD_WEBSITE,
  payload: data,
})

export const startSubscriptions = (data) => {
  return {
    type: START_SUBSCRIPTION,
    payload: data,
  }
}

export const startSubscriptionsClear = () => ({
  type: START_SUBSCRIPTION_CLEAR,
})

export const getCountries = () => ({
  type: GET_COUNTRIES,
})

export const getSitemaps = (data) => ({
  type: GET_SITEMAP,
  payload: data,
})

export const getLocations = () => ({
  type: GET_LOCATIONS,
})


export function getLightHouseRequest(payload) {
  return {
    type: GET_LIGHTHOUSE,
    payload,
  }
}

export function lightHouseSuccessClear() {
  return {
    type: GET_LIGHTHOUSE_CLEAR,
  }
}

export function setBackLinksData(data) {
  return {
    type: SET_BACKLINKS_DATA,
    payload: data,
  }
}

export function setStartScan(data) {
  return {
    type: IS_START_SCAN,
    payload: data,
  }
}

export function ifwebSiteData(data) {
  return {
    type: IS_WEBSITE_DATA,
    payload: data,
  }
}

export function openModalLighthouse(payload) {
  return {
    type: MODAL_OPEN_LIGHTHOUSE,
    payload
  }
}

export function stopAddWebsiteLoader() {
  return {
    type: STOP_ADD_WEBSITE_LOADER,
  }
}

export const getLighthouseStatus = (data) => ({
  type: GET_LIGHTHOUSE_STATUS,
  payload: data
})

export const getBillingInformation = (data) => ({
  type: GET_BILLING_INFORMATION,
  payload: data
})

export const cancelSubsciption = (data) => ({
  type: CANCEL_SUBSCRIPTION,
  payload: data
})

export const isCallForAddWebsite = (data) => ({
  type: IS_CALL_FOR,
  payload: data
})

export const discardTrialPeriod = () => ({
  type: DISCARD_TRIAL_PERIOD,
})

export const stripeCheckout = (data) => ({
  type: STRIPE_CHECKOUT,
  payload: data
})

export const addPaymentMethod = (data) => ({
  type: ADD_PAYMENT_METHOD,
  payload: data
})

export const trialSeoCheckup = (data) => ({
  type: TRIAL_SEO_CHECKUP,
  payload: data
})

export const getTrialSeoReport = (data) => ({
  type: GET_TRIAL_SEO_REPORT,
  payload: data
})

export const openRegisterModal = (data) => ({
  type: OPEN_REGISTER_MODAL,
  payload: data
})

export const getPublicKey = () => ({
  type: GET_PUBLIC_KEY,
})
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import * as Icon from 'react-feather'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import numeral from 'numeral'
import paths from 'constants/paths'
import { isMainUrlMatch } from 'constants/Utils'

const UrlDetail = ({
  item,
  activeLink,
  siteOutageError,
  siteOutageLoader,
  avgPosition,
  avgData,
  avgPreviousPosition,
  progressPercentage,
  graphData,
  activeSearchData,
}) => {
  const seoProgressPercentage = useSelector(({ dashboard }) => dashboard?.seoProgressPercentage)
  const mainUrlScoreDataArray = useSelector(({ dashboard }) => dashboard?.mainUrlScoreDataArray)
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains || [])

  const [totalSeoScore, setTotalSeoScore] = useState(0)
  const [totalTestCountforMainUrl, setTotalTestCountForMainUrl] = useState(0)
  const [isKeywordLoader, setIsKeywordLoader] = useState(true)

  useEffect(() => {
    if (avgData && avgData?.length && (avgPosition >= 0 || avgPosition === undefined)) {
      setIsKeywordLoader(false)
    } else if (!avgData?.length && progressPercentage < 100) {
      setIsKeywordLoader(true)
    } else if (!avgData?.length && progressPercentage >= 100) {
      setIsKeywordLoader(false)
    }
  }, [avgPosition, avgData, progressPercentage])

  useEffect(() => {
    const filteredData =
      mainUrlScoreDataArray?.length &&
      mainUrlScoreDataArray.filter((item) => {
        const mainUrl = item?.data?.[0]?.mainUrl
        const redirectUrl = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url

        const isUrlMatched = isMainUrlMatch(mainUrl, redirectUrl)
        return isUrlMatched
      })
    const result =
      filteredData?.length &&
      filteredData
        .filter((item) => {
          return item.data.some((obj) => {
            const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
            const lastUpdatedDate = moment
              .utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt)
              .format('MM-DD')
            return checkDate === lastUpdatedDate && obj.testResult
          })
        })
        .map((item) => {
          return {
            id: item._id,
            data: item.data.filter((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment
                .utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt)
                .format('MM-DD')
              return checkDate === lastUpdatedDate && obj.testResult
            }),
          }
        })
    const sum =
      result?.length &&
      result?.[0]?.data.reduce((accumulator, subItem) => {
        return accumulator + subItem.count
      }, 0)
    let scoreOutOf100 = Math.round((sum / 73) * 100)
    setTotalSeoScore(scoreOutOf100)

    const filteredMainUrl =
      filteredData?.length &&
      filteredData
        .filter((item) => {
          return item.data.some((obj) => {
            const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
            const lastUpdatedDate = moment
              .utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt)
              .format('MM-DD')
            return checkDate === lastUpdatedDate
          })
        })
        .map((item) => {
          return {
            id: item._id,
            data: item.data.filter((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment
                .utc(usersDomain?.[activeLink]?.items?.[0]?.createdAt)
                .format('MM-DD')
              return checkDate === lastUpdatedDate
            }),
          }
        })
    const filteredMainUrlTestCount =
      filteredMainUrl?.length &&
      filteredMainUrl?.[0]?.data.reduce((accumulator, subItem) => {
        return accumulator + subItem.count
      }, 0)
    setTotalTestCountForMainUrl(filteredMainUrlTestCount)
  }, [seoProgressPercentage, activeLink])

  const navigate = useNavigate()
  const activeDomainInformation =
    usersDomain &&
    activeLink !== undefined &&
    usersDomain[activeLink] &&
    usersDomain[activeLink]?.items &&
    usersDomain[activeLink]?.items.length &&
    usersDomain[activeLink]?.items[0]

  const activeUrlGraphdata = graphData?.filter((item) => activeSearchData?.websiteId === item?.websiteId)

  const desktopSpeedData =
    activeUrlGraphdata?.length &&
    activeUrlGraphdata?.[0]?.desktopData?.sort((a, b) => {
      const [dayA, monthA, yearA] = a?.date?.split('-').map(Number)
      const [dayB, monthB, yearB] = b?.date?.split('-').map(Number)

      if (yearA !== yearB) {
        return yearA - yearB
      }
      if (monthA !== monthB) {
        return monthA - monthB
      }
      return dayA - dayB
    })

  const mobileSpeedData =
    activeUrlGraphdata?.length &&
    activeUrlGraphdata?.[0]?.mobileData?.sort((a, b) => {
      const [dayA, monthA, yearA] = a?.date?.split('-').map(Number)
      const [dayB, monthB, yearB] = b?.date?.split('-').map(Number)

      if (yearA !== yearB) {
        return yearA - yearB
      }
      if (monthA !== monthB) {
        return monthA - monthB
      }
      return dayA - dayB
    })

  const checkBacklinksposition = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (usersDomain?.[activeLink]?.statsData?.length === 1) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentBacklinks = parseInt(usersDomain[activeLink]?.statsData?.[0]?.backlinks)
        let previousBacklinks = parseInt(usersDomain[activeLink]?.statsData?.[1]?.backlinks)
        if (currentBacklinks - previousBacklinks === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentBacklinks - previousBacklinks > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    } else {
      // return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" />
    }
  }

  const checkDesktopLoadingTimePosition = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (desktopSpeedData?.length === 1) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentDloadingTime = parseFloat(
          (desktopSpeedData?.[desktopSpeedData?.length - 1]?.loadingSpeed / 1000).toFixed(2),
        )
        let previousDloadingTime = parseFloat(
          (desktopSpeedData?.[desktopSpeedData?.length - 2]?.loadingSpeed / 1000).toFixed(2),
        )
        if (currentDloadingTime - previousDloadingTime === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentDloadingTime - previousDloadingTime > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    }
  }

  const checkMobileLoadingTimePosition = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (mobileSpeedData?.length === 1) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentMloadingTime = parseFloat(
          (mobileSpeedData?.[mobileSpeedData?.length - 1]?.loadingSpeed / 1000).toFixed(2),
        )
        let previousMloadingTime = parseFloat(
          (mobileSpeedData?.[mobileSpeedData?.length - 2]?.loadingSpeed / 1000).toFixed(2),
        )
        if (currentMloadingTime - previousMloadingTime === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentMloadingTime - previousMloadingTime > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    } else {
      return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
    }
  }

  const checkIndexUrlPosition = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (usersDomain[activeLink]?.items?.length === 1) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentIndexUrlCount =
          parseInt(usersDomain[activeLink]?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts) || 0
        let previousIndexUrlCount =
          parseInt(usersDomain[activeLink]?.items?.[1]?.indexUrlTest?.[0]?.data?.searchCounts) || 0
        if (currentIndexUrlCount - previousIndexUrlCount === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentIndexUrlCount - previousIndexUrlCount > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    }
  }

  const checkBrandedURLResults = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (usersDomain[activeLink]?.items?.length === 1) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else {
        let currentBrandedURLCount =
          parseInt(usersDomain[activeLink]?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls) || 0
        let previousBrandedURLCount =
          parseInt(usersDomain[activeLink]?.items?.[1]?.brandedUrlTest?.[0]?.data?.brandedUrls) || 0
        if (currentBrandedURLCount - previousBrandedURLCount === 0) {
          return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
        } else if (currentBrandedURLCount - previousBrandedURLCount > 0) {
          return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
        } else {
          return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
        }
      }
    }
  }

  const checkBacklinkspositionChangeMessage = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (usersDomain?.[activeLink]?.statsData?.length === 1) {
        return `There is no change in number of incoming links to your domain.`
      } else {
        let currentBacklinks = parseInt(usersDomain[activeLink]?.statsData?.[0]?.backlinks)
        let previousBacklinks = parseInt(usersDomain[activeLink]?.statsData?.[1]?.backlinks)
        if (currentBacklinks - previousBacklinks === 0) {
          return `There is no change in number of incoming links to your domain.`
        } else if (currentBacklinks - previousBacklinks > 0) {
          return `The total number of incoming links to your domain has increased from ${usersDomain?.[activeLink]?.statsData?.[1]?.backlinks} to ${usersDomain?.[activeLink]?.statsData?.[0]?.backlinks}.`
        } else {
          return `The total number of incoming links to your domain has increased from ${usersDomain?.[activeLink]?.statsData?.[1]?.backlinks} to ${usersDomain?.[activeLink]?.statsData?.[0]?.backlinks}.`
        }
      }
    }
  }

  const checkDesktopLoadingTimePositionChangeMessage = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (desktopSpeedData?.length === 1) {
        return `There is no change in desktop loading time for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      } else {
        let currentDiplayValue = (
          desktopSpeedData?.[desktopSpeedData?.length - 1]?.loadingSpeed / 1000
        ).toFixed(2)
        let previousDiplayValue = (
          desktopSpeedData?.[desktopSpeedData?.length - 2]?.loadingSpeed / 1000
        ).toFixed(2)
        let currentDloadingTime = parseFloat(
          (desktopSpeedData?.[desktopSpeedData?.length - 1]?.loadingSpeed / 1000).toFixed(2),
        )
        let previousDloadingTime = parseFloat(
          (desktopSpeedData?.[desktopSpeedData?.length - 2]?.loadingSpeed / 1000).toFixed(2),
        )
        if (currentDloadingTime - previousDloadingTime === 0) {
          return `There is no change in desktop loading time for ${usersDomain?.[activeLink]?.items[0]?.url}.`
        } else if (currentDloadingTime - previousDloadingTime > 0) {
          return `Desktop Loading Time has increased from ${previousDiplayValue} to ${currentDiplayValue} for ${usersDomain?.[activeLink]?.items[0]?.url}`
        } else {
          return `Desktop Loading Time has decreased from ${previousDiplayValue} to ${currentDiplayValue} for ${usersDomain?.[activeLink]?.items[0]?.url}`
        }
      }
    }
  }

  const checkMobileLoadingTimePositionChangeMessage = () => {
    if (usersDomain?.length && activeLink > -1) {
      if (mobileSpeedData?.length === 1) {
        return `There is no change in mobile loading time for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      } else {
        let currentDiplayValue = (
          mobileSpeedData?.[mobileSpeedData?.length - 1]?.loadingSpeed / 1000
        ).toFixed(2)
        let previousDiplayValue = (
          mobileSpeedData?.[mobileSpeedData?.length - 2]?.loadingSpeed / 1000
        ).toFixed(2)
        let currentMloadingTime = parseFloat(
          (mobileSpeedData?.[mobileSpeedData?.length - 1]?.loadingSpeed / 1000).toFixed(2),
        )
        let previousMloadingTime = parseFloat(
          (mobileSpeedData?.[mobileSpeedData?.length - 2]?.loadingSpeed / 1000).toFixed(2),
        )
        if (currentMloadingTime - previousMloadingTime === 0) {
          return `There is no change in mobile loading time for ${usersDomain?.[activeLink]?.items[0]?.url}.`
        } else if (currentMloadingTime - previousMloadingTime > 0) {
          return `mobile loading time has increased from ${previousDiplayValue} to ${currentDiplayValue} for ${usersDomain?.[activeLink]?.items[0]?.url}`
        } else {
          return `mobile loading time has decreased from ${previousDiplayValue} to ${currentDiplayValue} for ${usersDomain?.[activeLink]?.items[0]?.url}`
        }
      }
    }
  }

  const checkAveragePositionChangeMessage = () => {
    if (avgPosition) {
      let currentAveragePos = parseInt(avgPosition)
      let previousAveragePos = parseInt(avgPreviousPosition)
      if (currentAveragePos - previousAveragePos === 0) {
        return `There is no change in keywords position for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      } else if (currentAveragePos - previousAveragePos > 0) {
        return `keywords position has increased from ${previousAveragePos} to ${currentAveragePos} for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      } else {
        return `keywords position has decreased from ${previousAveragePos} to ${currentAveragePos} for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      }
    }
  }

  const checkSEOScorePosition = () => {
    if (totalSeoScore > 0) {
      const filteredData =
        mainUrlScoreDataArray?.length &&
        mainUrlScoreDataArray.filter((item) => {
          return (
            item.data[0].mainUrl ===
            usersDomain[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
          )
        })
      const result =
        filteredData?.length &&
        filteredData
          .filter((item) => {
            return item.data.some((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment
                .utc(usersDomain?.[activeLink]?.items?.[1]?.createdAt)
                .format('MM-DD')
              return checkDate === lastUpdatedDate && obj.testResult
            })
          })
          .map((item) => {
            return {
              id: item._id,
              data: item.data.filter((obj) => {
                const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                  'MM-DD',
                )
                const lastUpdatedDate = moment
                  .utc(usersDomain?.[activeLink]?.items?.[1]?.createdAt)
                  .format('MM-DD')
                return checkDate === lastUpdatedDate && obj.testResult
              }),
            }
          })
      const previousPassedTestCount =
        result?.length &&
        result?.[0]?.data.reduce((accumulator, subItem) => {
          return accumulator + subItem.count
        }, 0)
      const previousSeoScore = Math.round((previousPassedTestCount / 73) * 100)
      if (totalSeoScore - previousSeoScore === 0) {
        return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
      } else if (totalSeoScore - previousSeoScore > 0) {
        return <FontAwesomeIcon icon={faCaretUp} color={'#20c997'} className="align-middle" />
      } else {
        return <FontAwesomeIcon icon={faCaretDown} color={'#d9534f'} className="align-middle" />
      }
    }
  }

  const checkSEOScorePositionChangeMessage = () => {
    if (totalSeoScore > 0) {
      const filteredData =
        mainUrlScoreDataArray?.length &&
        mainUrlScoreDataArray.filter((item) => {
          return (
            item.data[0].mainUrl ===
            usersDomain[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
          )
        })
      const result =
        filteredData?.length &&
        filteredData
          .filter((item) => {
            return item.data.some((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment
                .utc(usersDomain?.[activeLink]?.items?.[1]?.createdAt)
                .format('MM-DD')
              return checkDate === lastUpdatedDate && obj.testResult
            })
          })
          .map((item) => {
            return {
              id: item._id,
              data: item.data.filter((obj) => {
                const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                  'MM-DD',
                )
                const lastUpdatedDate = moment
                  .utc(usersDomain?.[activeLink]?.items?.[1]?.createdAt)
                  .format('MM-DD')
                return checkDate === lastUpdatedDate && obj.testResult
              }),
            }
          })
      const previousPassedTestCount =
        result?.length &&
        result?.[0]?.data.reduce((accumulator, subItem) => {
          return accumulator + subItem.count
        }, 0)
      const previousSeoScore = Math.round((previousPassedTestCount / 73) * 100)
      if (totalSeoScore - previousSeoScore === 0) {
        return `There is no change in SEO Score for ${usersDomain?.[activeLink]?.items[0]?.url}`
      } else if (totalSeoScore - previousSeoScore > 0) {
        return `SEO Score has increased from ${previousSeoScore} to ${totalSeoScore} out of 100 for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      } else {
        return `SEO Score has decreased from ${previousSeoScore} to ${totalSeoScore} out of 100 for ${usersDomain?.[activeLink]?.items[0]?.url}.`
      }
    }
  }

  return (
    <Card className="mb-0 url-details">
      <Card.Header>
        <h5 className="mb-0 f-bold card-title">
          <>
            {' '}
            {item && item.items && item.items.length && item.items[0].url
              ? item.items[0].url.length > 30
                ? `${item.items[0].url.substring(0, 30)}...`
                : item.items[0].url
              : ''}
            {item && item.items && item.items.length && item.items[0].url && item.items[0].url.length > 30 ? (
              <OverlayTrigger
                placement={'bottom'}
                overlay={
                  <Tooltip id={`tooltip-top`}>{`${
                    item && item.items && item.items.length && item.items[0].url
                  }`}</Tooltip>
                }
              >
                <Icon.HelpCircle
                  className="d-none d-sm-block"
                  size="16"
                  color="#afafaf"
                  style={{ float: 'right' }}
                />
              </OverlayTrigger>
            ) : (
              <></>
            )}
          </>
        </h5>
        <p className="mb-3">Summary</p>
        <Button
          onClick={() => {
            navigate(paths.signalScanPage, {
              state: { url: item },
            })
          }}
          variant="outline-secondary"
        >
          View Reports
        </Button>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">
                Site IP -{' '}
                <b className="f-bold h4">
                  {usersDomain &&
                  activeLink !== undefined &&
                  usersDomain[activeLink] &&
                  usersDomain[activeLink]?.statsData?.[0]?.siteIP ? (
                    usersDomain[activeLink]?.statsData?.[0]?.siteIP
                  ) : usersDomain[activeLink]?.statsData?.[0]?.siteIP !== null ? (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  ) : (
                    '-'
                  )}
                </b>
              </strong>
            </p>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {usersDomain[activeLink]?.statsData?.[0]?.siteIP
                      ? `Site IP for ${usersDomain?.[activeLink]?.items[0]?.url} is ${usersDomain[activeLink]?.statsData?.[0]?.siteIP}`
                      : 'No data available'}
                  </Tooltip>
                }
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">SEO Score</strong>
            </p>
            <h3>
              {' '}
              {totalTestCountforMainUrl >= 63 ? (
                <>
                  {checkSEOScorePosition()}&nbsp; <span className="f-bold"> {parseInt(totalSeoScore)} </span>
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}> {checkSEOScorePositionChangeMessage()} </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Backlinks</strong>
            </p>
            <h3 className="f-bold">
              {usersDomain &&
              activeLink !== undefined &&
              usersDomain[activeLink] &&
              (usersDomain?.[activeLink]?.statsData?.[0]?.backlinks ||
                usersDomain?.[activeLink]?.statsData?.[0]?.backlinks === 0) ? (
                <>
                  {checkBacklinksposition()} &nbsp;{' '}
                  <span className="f-bold">
                    {numeral(usersDomain[activeLink]?.statsData?.[0]?.backlinks).format('0,0')}
                  </span>
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}{' '}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}> {checkBacklinkspositionChangeMessage()} </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>

        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Keyword Avg. Position</strong>
            </p>
            <h3 className="f-bold">
              {!isKeywordLoader ? (
                <>
                  {avgPosition ? (
                    <>
                      {' '}
                      <FontAwesomeIcon
                        icon={faExchangeAlt}
                        className="align-middle"
                        color="#b2b2b2"
                      /> &nbsp; <span className="f-bold">{avgPosition}</span>
                    </>
                  ) : (
                    <>No Data</>
                  )}
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}> {checkAveragePositionChangeMessage()} </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />

        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Desktop Loading Time</strong>
            </p>
            <h3>
              <span className="f-bold">
                {graphData?.length ? (
                  desktopSpeedData?.[desktopSpeedData?.length - 1]?.loadingSpeed ? (
                    <>
                      {' '}
                      {checkDesktopLoadingTimePosition()} &nbsp;{' '}
                      <span className="f-bold">
                        {' '}
                        {(desktopSpeedData?.[desktopSpeedData?.length - 1]?.loadingSpeed / 1000).toFixed(
                          2,
                        )}s{' '}
                      </span>{' '}
                    </>
                  ) : (
                    <span className="f-bold">0</span>
                  )
                ) : (
                  <>
                    <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                  </>
                )}
              </span>
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}> {checkDesktopLoadingTimePositionChangeMessage()} </Tooltip>
                }
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Mobile Loading Time</strong> &nbsp;
            </p>
            <h3>
              <span>
                {graphData?.length ? (
                  mobileSpeedData?.[mobileSpeedData?.length - 1]?.loadingSpeed ? (
                    <>
                      {checkMobileLoadingTimePosition()} &nbsp;{' '}
                      <span className="f-bold">
                        {' '}
                        {(mobileSpeedData?.[mobileSpeedData?.length - 1]?.loadingSpeed / 1000).toFixed(
                          2,
                        )}s{' '}
                      </span>{' '}
                    </>
                  ) : (
                    <span className="f-bold">0</span>
                  )
                ) : (
                  <>
                    <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                  </>
                )}
              </span>
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}> {checkMobileLoadingTimePositionChangeMessage()} </Tooltip>
                }
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Site Outage</strong> &nbsp;
            </p>
            {siteOutageLoader || siteOutageLoader === undefined ? (
              <h3>
                {' '}
                <Spinner size="sm" color="dark" className="ml-2 mr-2" />{' '}
              </h3>
            ) : (
              <>
                {' '}
                {siteOutageError ? (
                  <h3 className="text-danger f-bold">Outage issue</h3>
                ) : (
                  <h3 className="text-success  f-bold">No issues</h3>
                )}
              </>
            )}
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {' '}
                    {siteOutageError
                      ? `There are downtimes reported for ${usersDomain?.[activeLink]?.items[0]?.url}`
                      : `There are no downtimes reported for ${usersDomain?.[activeLink]?.items[0]?.url}`}{' '}
                  </Tooltip>
                }
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Indexed URL's</strong> &nbsp;
            </p>
            <h3 className="f-bold">
              {usersDomain &&
              activeLink !== undefined &&
              usersDomain[activeLink] &&
              usersDomain[activeLink]?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts !== null &&
              usersDomain[activeLink]?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts !== undefined ? (
                <>
                  {checkIndexUrlPosition()} &nbsp;{' '}
                  <span className="f-bold">
                    {usersDomain[
                      activeLink
                    ]?.items?.[0]?.indexUrlTest?.[0]?.data?.searchCounts?.toLocaleString('en-US')}
                  </span>
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}{' '}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Total number of Url index in search engine </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>

        <hr className="my-1" />
        <Row className="d-flex">
          <Col md="10">
            <p className="my-1">
              <strong className="f-bold">Branded URL Results</strong> &nbsp;
            </p>
            <h3 className="f-bold">
              {usersDomain &&
              activeLink !== undefined &&
              usersDomain[activeLink] &&
              usersDomain[activeLink]?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls !== null &&
              usersDomain[activeLink]?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls !== undefined ? (
                <>
                  {checkBrandedURLResults()} &nbsp;{' '}
                  <span className="f-bold">
                    {usersDomain[
                      activeLink
                    ]?.items?.[0]?.brandedUrlTest?.[0]?.data?.brandedUrls?.toLocaleString('en-US')}
                  </span>
                </>
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}{' '}
            </h3>
          </Col>
          <Col md="2" className="d-none d-xl-block d-md-block d-lg-block">
            <div style={{ lineHeight: 2, float: 'right' }}>
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip id={`tooltip-top`}>Total number of Branded URL in search engine </Tooltip>}
              >
                <Icon.HelpCircle size="16" color="#afafaf" />
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default UrlDetail

import {
  WHITE_LABEL_TEMPLATE_CREATE_START,
  WHITE_LABEL_TEMPLATE_CREATE_SUCCESS,
  WHITE_LABEL_TEMPLATE_CREATE_ERROR,
  WHITE_LABEL_TEMPLATE_CREATE_CLEAR,
  WHITE_LABEL_TEMPLATE_LIST_START,
  WHITE_LABEL_TEMPLATE_LIST_SUCCESS,
  WHITE_LABEL_TEMPLATE_LIST_ERROR,
  WHITE_LABEL_TEMPLATE_LIST_CLEAR,
  WHITE_LABEL_TEMPLATE_DELETE_START,
  WHITE_LABEL_TEMPLATE_DELETE_SUCCESS,
  WHITE_LABEL_TEMPLATE_DELETE_ERROR,
  WHITE_LABEL_TEMPLATE_DELETE_CLEAR,
  GENERATE_WHITE_LABEL_REPORT_REQUEST,
  GENERATE_WHITE_LABEL_REPORT_SUCCESS,
  GENERATE_WHITE_LABEL_REPORT_CLEAR,
  GENERATE_WHITE_LABEL_REPORT_FAILURE,
} from 'redux/actionType'

const initialState = {
  isCreateTemplateLoading: false,
  isCreateTemplateSuccess: false,
  isListTemplateLoading: false,
  isDeleteTemplateLoading: false,
  deleteStatus: false,
}

export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    //Start Generating Report
    case GENERATE_WHITE_LABEL_REPORT_REQUEST:
      return {
        ...state,
        isGenerateReportLoading: true,
        isGetUrlDetailsSuccess: false,
      }
    case GENERATE_WHITE_LABEL_REPORT_SUCCESS:
      let urlData = []
      if (action.payload && action.payload?.urlDataId && action.payload?.urlDataId?._id) {
        urlData.push(action.payload?.urlDataId)
      }
      return {
        ...state,
        isGenerateReportLoading: false,
        isGetUrlDetailsSuccess: true,
        generatedReportData: urlData,
      }
    case GENERATE_WHITE_LABEL_REPORT_FAILURE:
      return {
        ...state,
        isGenerateReportLoading: false,
        isGetUrlDetailsSuccess: false,
      }
    case GENERATE_WHITE_LABEL_REPORT_CLEAR:
      return {
        ...state,
        isGenerateReportLoading: false,
        isGetUrlDetailsSuccess: false,
        generatedReportData: '',
      }

    // Start create template
    case WHITE_LABEL_TEMPLATE_CREATE_START:
      return {
        ...state,
        isCreateTemplateLoading: true,
        isCreateTemplateSuccess: false,
      }
    case WHITE_LABEL_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        createTemplateData: action.payload,
        isCreateTemplateSuccess: true,
        isCreateTemplateLoading: false,
      }
    case WHITE_LABEL_TEMPLATE_CREATE_ERROR:
      return {
        ...state,
        createTemplateData: [],
        createTemplateError: action.error,
        isCreateTemplateSuccess: false,
        isCreateTemplateLoading: false,
      }
    case WHITE_LABEL_TEMPLATE_CREATE_CLEAR:
      return {
        ...state,
        createTemplateData: [],
        createTemplateError: '',
        isCreateTemplateSuccess: false,
        isCreateTemplateLoading: false,
      }
    // End create template

    // Start List template
    case WHITE_LABEL_TEMPLATE_LIST_START:
      return { ...state, isListTemplateLoading: true }
    case WHITE_LABEL_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        listTemplateData: action.payload,
        isListTemplateLoading: false,
      }
    case WHITE_LABEL_TEMPLATE_LIST_ERROR:
      return {
        ...state,
        listTemplateData: [],
        createTemplateError: action.error,
        isListTemplateLoading: false,
      }
    case WHITE_LABEL_TEMPLATE_LIST_CLEAR:
      return {
        ...state,
        listTemplateData: [],
        createTemplateError: '',
        isListTemplateLoading: false,
      }
    // End List template

    // Start Delete template
    case WHITE_LABEL_TEMPLATE_DELETE_START:
      return { ...state, isDeleteTemplateLoading: true, deleteStatus: false }
    case WHITE_LABEL_TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        deleteTemplateData: action.payload,
        deleteStatus: action.payload.statusCode === 200,
        isDeleteTemplateLoading: false,
      }
    case WHITE_LABEL_TEMPLATE_DELETE_ERROR:
      return {
        ...state,
        listTemplateData: [],
        createTemplateError: action.error,
        deleteStatus: false,
        isDeleteTemplateLoading: false,
      }
    case WHITE_LABEL_TEMPLATE_DELETE_CLEAR:
      return {
        ...state,
        listTemplateData: [],
        createTemplateError: '',
        deleteStatus: false,
        isDeleteTemplateLoading: false,
      }
    // End Delete template

    default:
      return state
  }
}

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Col,
  Row,
  Dropdown,
  Button,
  FormControl,
  DropdownButton,
  InputGroup,
  Form,
  Popover,
  OverlayTrigger,
  Alert,
  Modal,
} from 'react-bootstrap'
import 'react-credit-cards/es/styles-compiled.css'
import { CardBody } from 'reactstrap'
import * as _ from 'lodash'

import { Loader } from 'components/uiCore'
import paths from 'constants/paths'
import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import PricingPlan from 'pages/auth/PricingPlan'
import ColoredModal from 'pages/modals/ColorModal'
import { changePlan } from 'redux/actions/register'
import { CLEAR_COMPETITOR_LIMIT } from 'redux/actionType'
import { getCompetitorLimit } from 'redux/actions/dashboard'
import { message } from 'antd'
import { LimitOption, PLAN } from 'constants/enum'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons'
import 'modules/welcomeBoard/components/addCompetitor/AddCompetitor.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      Our system quickly checks google's database to ascertain which websites are related to yours. If none
      were found this means that a lot of work is needed in order to improve the ranking and relationship your
      website has with Google. There are many reasons why this could happen don't panic that's why we are here
      to help.
    </Popover.Body>
  </Popover>
)

const AddCompetitorsMain = ({ callFrom }) => {
  const { competitorLimitLoader, competitorLimitData, competitorLimitError } = useSelector(
    ({ dashboard }) => dashboard,
  )

  const [title1, setTitle1] = useState('https')
  const [title2, setTitle2] = useState('https')
  const [title3, setTitle3] = useState('https')
  const [title4, setTitle4] = useState('https')
  const [title5, setTitle5] = useState('https')

  const [competitor1, setCompetitor1] = useState('')
  const [competitor1Disabled, setCompetitor1Disabled] = useState(false)
  const [competitor2, setCompetitor2] = useState('')
  const [competitor2Disabled, setCompetitor2Disabled] = useState(false)
  const [competitor3, setCompetitor3] = useState('')
  const [competitor3Disabled, setCompetitor3Disabled] = useState(false)
  const [competitor4, setCompetitor4] = useState('')
  const [competitor4Disabled, setCompetitor4Disabled] = useState(false)
  const [competitor5, setCompetitor5] = useState('')
  const [competitor5Disabled, setCompetitor5Disabled] = useState(false)

  const [previousCompetitor, setPreviousCompetitor] = useState('')

  const [editButton1, setEditButton1] = useState('Edit')
  const [editButton2, setEditButton2] = useState('Edit')
  const [editButton3, setEditButton3] = useState('Edit')
  const [editButton4, setEditButton4] = useState('Edit')
  const [editButton5, setEditButton5] = useState('Edit')

  const [planName, setPlanName] = useState(PLAN.BASIC_PLAN.name)

  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [openCompetitorLimitExceed, setOpenCompetitorLimitExceed] = useState(false)

  const { registerData } = useSelector(({ register }) => register)

  const {
    randomNumber,
    prevPageData,
    loading,
    competitorList,
    competitorListArray,
    keywordsListArray,
    competitorUpdated,
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  useEffect(() => {

    window.scrollTo(0, 0)  

    setPlanName(registerData?.plan?.name || PLAN.BASIC_PLAN.name)

    competitorListArray &&
      competitorListArray.length &&
      competitorListArray.forEach((element, index) => {
        if (index === 0) {
          setCompetitor1(element)
          setCompetitor1Disabled(true)
        }
        if (index === 1) {
          setCompetitor2(element)
          setCompetitor2Disabled(true)
        }
        if (index === 2) {
          setCompetitor3(element)
          setCompetitor3Disabled(true)
        }
        if (index === 3) {
          setCompetitor4(element)
          setCompetitor4Disabled(true)
        }
        if (index === 4) {
          setCompetitor5(element)
          setCompetitor5Disabled(true)
        }
      })
    dispatch({ type: 'ABOUT_TO_TRIGGER_TO_SAVE_COMPETITOR_SEARCH_RESULT' })
  }, [])

  useEffect(() => {
    if (competitorLimitLoader) {
      return
    } else if (!competitorLimitLoader && competitorLimitData && Object?.keys(competitorLimitData)?.length) {
      if (competitorLimitData?.limitAvailable) {
        checkAddCompetitors()
        dispatch({ type: CLEAR_COMPETITOR_LIMIT })
      } else {
        if (
          competitorLimitData &&
          Object.keys(competitorLimitData)?.length &&
          !competitorLimitData?.limitAvailable
        ) {
          setOpenCompetitorLimitExceed(true)
        }
      }
    } else {
      if (!competitorLimitLoader && competitorLimitError && Object.keys(competitorLimitError)?.length) {
        message.error(competitorLimitError?.message)
        dispatch({ type: CLEAR_COMPETITOR_LIMIT })
      }
    }
  }, [competitorLimitLoader, competitorLimitData, competitorLimitError])

  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
    } else {
      navigate(callFrom === 'dashboard' ? paths.dashboardAddWebsite : paths.addNewWebsite)
    }
  }, [randomNumber])

  const renderCheckbox = (competitorList) => {
    return (
      <Form className="row pt-3">
        {competitorList.map((item, index) => (
          <>
            <Form.Group className="col-sm-3 text-left">
              <Form.Check
                className='add-competitor-render'
                id={item.competitor}
                checked={item.checked}
                onChange={() => {
                  addCompetitors(item)
                }}
                type="checkbox"
                label={`${item.competitor}`}
              />
            </Form.Group>
          </>
        ))}
      </Form>
    )
  }

  useEffect(() => {
    if (competitorUpdated) {
      checkCompetitorArray()
      dispatch({ type: 'CLEAR_TOGGLE_CHECKBOX' })
      setPreviousCompetitor('')
    }
  }, [competitorUpdated])

  const addCompetitors = (item, oldCompetitorData = {}) => {
    if (planName === PLAN.PREMIUM_PLAN.name) {
      if (
        competitorListArray?.includes(item?.competitor) ||
        competitorListArray?.length < 5 ||
        competitorListArray?.includes(oldCompetitorData?.competitor)
      ) {
        if (oldCompetitorData && oldCompetitorData?.competitor && Object.keys(oldCompetitorData)) {
          dispatch({ type: 'TOGGLE_CHECKBOX', payload: oldCompetitorData })
        }
        dispatch({ type: 'TOGGLE_CHECKBOX', payload: item })
      }
    } else if (planName === PLAN.PRO_PLAN.name) {
      if (
        competitorListArray?.includes(item?.competitor) ||
        competitorListArray?.length < 3 ||
        competitorListArray?.includes(oldCompetitorData?.competitor)
      ) {
        if (oldCompetitorData && oldCompetitorData?.competitor && Object.keys(oldCompetitorData)) {
          dispatch({ type: 'TOGGLE_CHECKBOX', payload: oldCompetitorData })
        }
        dispatch({ type: 'TOGGLE_CHECKBOX', payload: item })
      }
    } else if (planName === PLAN.BASIC_PLAN.name) {
      if (
        competitorListArray?.includes(item?.competitor) ||
        competitorListArray?.length < 1 ||
        competitorListArray?.includes(oldCompetitorData?.competitor)
      ) {
        if (oldCompetitorData && oldCompetitorData?.competitor && Object.keys(oldCompetitorData)) {
          dispatch({ type: 'TOGGLE_CHECKBOX', payload: oldCompetitorData })
        }
        dispatch({ type: 'TOGGLE_CHECKBOX', payload: item })
        checkCompetitorArray()
      }
    } else {
      return
    }
  }

  const checkCompetitorArray = () => {
    if (competitorListArray.length > 0) {
      setCompetitor1(competitorListArray[0])
      setCompetitor1Disabled(true)
    } else {
      setCompetitor1('')
      setCompetitor1Disabled(false)
    }
    if (competitorListArray.length > 1) {
      setCompetitor2(competitorListArray[1])
      setCompetitor2Disabled(true)
    } else {
      setCompetitor2('')
      setCompetitor2Disabled(false)
    }
    if (competitorListArray.length > 2) {
      setCompetitor3(competitorListArray[2])
      setCompetitor3Disabled(true)
    } else {
      setCompetitor3('')
      setCompetitor3Disabled(false)
    }
    if (competitorListArray.length > 3) {
      setCompetitor4(competitorListArray[3])
      setCompetitor4Disabled(true)
    } else {
      setCompetitor4('')
      setCompetitor4Disabled(false)
    }
    if (competitorListArray.length > 4) {
      setCompetitor5(competitorListArray[4])
      setCompetitor5Disabled(true)
    } else {
      setCompetitor5('')
      setCompetitor5Disabled(false)
    }
  }

  const checkAddCompetitors = () => {
    let competitors = []
    if (planName === PLAN.PREMIUM_PLAN.name) {
      competitors = competitorListArray.length > 5 ? competitorListArray.splice(0, 5) : competitorListArray
    } else if (planName === PLAN.PRO_PLAN.name) {
      competitors = competitorListArray.length > 3 ? competitorListArray.splice(0, 3) : competitorListArray
    } else if (planName === PLAN.BASIC_PLAN.name) {
      competitors = competitorListArray.length > 1 ? competitorListArray.splice(0, 1) : competitorListArray
    }
    competitors = _.uniq(competitors)

    if (competitors.length === 1) {
      if (competitor2 && competitor2.length > 2) {
        let websiteNew, updatedComp
        if (competitor2.endsWith('/')) {
          websiteNew = competitor2.substring(0, competitor2.length - 1)
        } else {
          websiteNew = competitor2
        }
        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }
        competitors.push(`${title2}://${updatedComp}`)
      }
      if (competitor3 && competitor3.length > 2) {
        let websiteNew, updatedComp
        if (competitor3.endsWith('/')) {
          websiteNew = competitor3.substring(0, competitor3.length - 1)
        } else {
          websiteNew = competitor3
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title3}://${updatedComp}`)
      }
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 2) {
      if (competitor3 && competitor3.length > 2) {
        let websiteNew, updatedComp
        if (competitor3.endsWith('/')) {
          websiteNew = competitor3.substring(0, competitor3.length - 1)
        } else {
          websiteNew = competitor3
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title3}://${updatedComp}`)
      }
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 3) {
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 4) {
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 0) {
      if (competitor1 && competitor1.length > 2) {
        let websiteNew, updatedComp
        if (competitor1.endsWith('/')) {
          websiteNew = competitor1.substring(0, competitor1.length - 1)
        } else {
          websiteNew = competitor1
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title1}://${updatedComp}`)
      }
      if (competitor2 && competitor2.length > 2) {
        let websiteNew, updatedComp
        if (competitor2.endsWith('/')) {
          websiteNew = competitor2.substring(0, competitor2.length - 1)
        } else {
          websiteNew = competitor2
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title2}://${updatedComp}`)
      }
      if (competitor3 && competitor3.length > 2) {
        let websiteNew, updatedComp
        if (competitor3.endsWith('/')) {
          websiteNew = competitor3.substring(0, competitor3.length - 1)
        } else {
          websiteNew = competitor3
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title3}://${updatedComp}`)
      }
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }
        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }
        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else {
    }
    const tofindDuplicates = (competitors) =>
      competitors.filter((item, index) => competitors.indexOf(item) !== index)
    const duplicateElement = tofindDuplicates(competitors)

    if (duplicateElement && duplicateElement.length > 0) {
      setError(true)
      let duplicateElementString = duplicateElement.join(',')
      setErrorMessage(
        `We found duplicate website added in the list, which is ${duplicateElementString}. Please correct them.`,
      )
    } else {
      let newArray = []
      newArray =
        competitors &&
        competitors.length &&
        competitors.map((element) => {
          let comp = element.toLowerCase()
          if (comp.startsWith('http')) {
            return element
          } else {
            return `https://${element}`
          }
        })

      if (planName === PLAN.PREMIUM_PLAN.name) {
        newArray = newArray.length > 5 ? newArray.splice(0, 5) : newArray
      } else if (planName === PLAN.PRO_PLAN.name) {
        newArray = newArray.length > 3 ? newArray.splice(0, 3) : newArray
      } else if (planName === PLAN.BASIC_PLAN.name) {
        newArray = newArray.length > 1 ? newArray.splice(0, 1) : newArray
      }
      let urlObj
      if (prevPageData?.url?.includes('http://') || prevPageData?.url?.includes('https://')) {
        urlObj = new URL(prevPageData?.url)
      }
      let domain = urlObj?.hostname || prevPageData?.url
      if (domain.includes('www.')) {
        domain = domain.replace('www.', '')
      }
      const input = {
        sessionId: randomNumber,
        competitors: newArray,
        domain: prevPageData.url,
        region: prevPageData?.regions?.[0]?.region,
        country: prevPageData?.regions?.[0]?.country,
        keywords: keywordsListArray,
        action: 'getTopKeyword',
      }
   
      dispatch({ type: 'GET_URL_DETAILS', payload: input })
      navigate(callFrom === 'dashboard' ? paths.dashboardAddSitemaps : paths.addSitemap)
    }
  }

  const checkCompetitorLimit = () => {
    let competitors = []
    if (planName === PLAN.PREMIUM_PLAN.name) {
      competitors = competitorListArray.length > 5 ? competitorListArray.splice(0, 5) : competitorListArray
    } else if (planName === PLAN.PRO_PLAN.name) {
      competitors = competitorListArray.length > 3 ? competitorListArray.splice(0, 3) : competitorListArray
    } else if (planName === PLAN.BASIC_PLAN.name) {
      competitors = competitorListArray.length > 1 ? competitorListArray.splice(0, 1) : competitorListArray
    }
    competitors = _.uniq(competitors)

    if (competitors.length === 1) {
      if (competitor2 && competitor2.length > 2) {
        let websiteNew, updatedComp
        if (competitor2.endsWith('/')) {
          websiteNew = competitor2.substring(0, competitor2.length - 1)
        } else {
          websiteNew = competitor2
        }
        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }
        competitors.push(`${title2}://${updatedComp}`)
      }
      if (competitor3 && competitor3.length > 2) {
        let websiteNew, updatedComp
        if (competitor3.endsWith('/')) {
          websiteNew = competitor3.substring(0, competitor3.length - 1)
        } else {
          websiteNew = competitor3
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title3}://${updatedComp}`)
      }
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 2) {
      if (competitor3 && competitor3.length > 2) {
        let websiteNew, updatedComp
        if (competitor3.endsWith('/')) {
          websiteNew = competitor3.substring(0, competitor3.length - 1)
        } else {
          websiteNew = competitor3
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title3}://${updatedComp}`)
      }
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 3) {
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 4) {
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else if (competitors.length === 0) {
      if (competitor1 && competitor1.length > 2) {
        let websiteNew, updatedComp
        if (competitor1.endsWith('/')) {
          websiteNew = competitor1.substring(0, competitor1.length - 1)
        } else {
          websiteNew = competitor1
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title1}://${updatedComp}`)
      }
      if (competitor2 && competitor2.length > 2) {
        let websiteNew, updatedComp
        if (competitor2.endsWith('/')) {
          websiteNew = competitor2.substring(0, competitor2.length - 1)
        } else {
          websiteNew = competitor2
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title2}://${updatedComp}`)
      }
      if (competitor3 && competitor3.length > 2) {
        let websiteNew, updatedComp
        if (competitor3.endsWith('/')) {
          websiteNew = competitor3.substring(0, competitor3.length - 1)
        } else {
          websiteNew = competitor3
        }

        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title3}://${updatedComp}`)
      }
      if (competitor4 && competitor4.length > 2) {
        let websiteNew, updatedComp
        if (competitor4.endsWith('/')) {
          websiteNew = competitor4.substring(0, competitor4.length - 1)
        } else {
          websiteNew = competitor4
        }
        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title4}://${updatedComp}`)
      }
      if (competitor5 && competitor5.length > 2) {
        let websiteNew, updatedComp
        if (competitor5.endsWith('/')) {
          websiteNew = competitor5.substring(0, competitor5.length - 1)
        } else {
          websiteNew = competitor5
        }
        if (websiteNew.startsWith('https://')) {
          updatedComp = websiteNew.replace('https://', '')
        } else if (websiteNew.startsWith('http://')) {
          updatedComp = websiteNew.replace('http://', '')
        } else {
          updatedComp = websiteNew
        }

        competitors.push(`${title5}://${updatedComp}`)
      }
    } else {
    }
    const tofindDuplicates = (competitors) =>
      competitors.filter((item, index) => competitors.indexOf(item) !== index)
    const duplicateElement = tofindDuplicates(competitors)

    if (duplicateElement && duplicateElement.length > 0) {
      setError(true)
      let duplicateElementString = duplicateElement.join(',')
      setErrorMessage(
        `We found duplicate website added in the list, which is ${duplicateElementString}. Please correct them.`,
      )
    } else {
      let newArray = []
      newArray =
        competitors &&
        competitors.length &&
        competitors.map((element) => {
          let comp = element.toLowerCase()
          if (comp.startsWith('http')) {
            return element
          } else {
            return `https://${element}`
          }
        })
      const competitor_payload = {
        competitors: newArray?.length ? newArray : [],
        limitOption: LimitOption.COMPETITOR,
        url: prevPageData?.url,
      }
      dispatch(getCompetitorLimit(competitor_payload))
    }
  }

  const changeExisitingPlan = (data) => {
    //setPlanName={setPlanName} setPlanPrice={setPlanPrice} setPlanDuration={setPlanDuration}
    setIsOpen(!isOpen)
    setPlanName(data.planName)
    // setPlanPrice(data.planPrice)
    // setPlanDuration(data.planType)
    dispatch(
      changePlan({
        email: registerData.email,
        planName: data.planName,
        planType: 'Stripe',
        planDuration: data.planType,
      }),
    )
  }

  return (
    <div className={callFrom === "trial" ? "add-competitor" : ''}>
      <Helmet title="Start trail" />
      {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={callFrom === "trial" ? "add-competitor-text" : "add-competitor-text-dashboard"}>
         {isMobile ? <div className="add-competitor-stepnumber-mobile">
            <h3 className="mb-0">6</h3>
          </div> : null} 
          <div className="add-competitor-text-mobile">
            <p className="add-competitor-text-first">Empower Your SEO Journey: Precision, Simplicity, Results</p>
            <p className="add-competitor-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      {error ? (
        <ColoredModal
          isOpen={error}
          message={errorMessage}
          onClose={() => {
            setError(false)
            setErrorMessage(false)
          }}
        />
      ) : (
        <></>
      )}
      {callFrom === 'trial' ? (
        <PricingPlan
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          changeExisitingPlan={(data) => changeExisitingPlan(data)}
        />
      ) : null}

      <Modal
        show={openCompetitorLimitExceed}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setOpenCompetitorLimitExceed(!openCompetitorLimitExceed)
          dispatch({ type: CLEAR_COMPETITOR_LIMIT })
        }}
        onClose={() => {
          setOpenCompetitorLimitExceed(false)
          dispatch({ type: CLEAR_COMPETITOR_LIMIT })
        }}
        onHide={() => {
          setOpenCompetitorLimitExceed(false)
          dispatch({ type: CLEAR_COMPETITOR_LIMIT })
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15 pe-0"
      >
        <Modal.Header>
          <h3 className="mb-0 f-bold">Competitor Tracking Update</h3>
        </Modal.Header>
        <Modal.Body>
          You've added competitors for your tracked website(s) under your current plan. To ensure the highest
          quality of data and maintain service efficiency, competitor changes are locked in until the next
          billing cycle.
          <br />
          <br />
          Current Plan Competitor Limits: {competitorLimitData?.competitorsLimit}{' '}
          {competitorLimitData?.competitorsLimit === 1 ? 'competitor' : 'competitors'}
          <br />
          <br />
          Due to the extensive data analysis involved in real-time scanning of search engine results, each
          plan is designed with specific limitations. These are in place to provide all our users with a
          seamless experience, allowing us to deliver accurate and actionable SEO insights at a reasonable
          cost.
          <br />
          <br />
          Important:
          <br />
          <ul>
            <li>
              Once competitors are added for a website, they cannot be changed within the same billing cycle.
            </li>
            <li>
              To update your competitors, you will need to remove the current website from your tracking list
              and re-add it with new competitors in your next billing cycle.
            </li>
          </ul>
          We understand the dynamic nature of the digital landscape and strive to offer flexible solutions
          within these necessary constraints. For more extensive tracking needs, consider our {PLAN.PREMIUM_PLAN.name} Plan,
          which offers expanded competitor analysis among other advanced features.
          <br />
          <br />
          Thank you for choosing our services for your competitive analysis needs. We're here to support your
          SEO strategy with precision and reliability.
        </Modal.Body>
        <Modal.Footer className={`d-flex flex-row ${registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? 'justify-content-end' : 'justify-content-between'}`}>
          {registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : <Button
            color="primary"
            variant="primary"
            onClick={() => {
              navigate(paths.upgradePlan)
              setOpenCompetitorLimitExceed(false)
              dispatch({ type: CLEAR_COMPETITOR_LIMIT })
            }}
          >
            Upgrade your plan
          </Button>}

          <Button
            color="danger"
            variant="danger"
            onClick={() => {
              setOpenCompetitorLimitExceed(false)
              dispatch({ type: CLEAR_COMPETITOR_LIMIT })
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Card
        className={`trail-flow mb-0 add-website-card-tranparency ${callFrom === "trial" ? 'add-competitor-card' : 'add-competitor-card-dashboard'}`}
        style={{
          padding: 15,
        }}
      >
        <CardBody className={`add-website-card-tranparency ${callFrom === "trial" ? 'add-competitor-card-body' : 'add-competitor-dashboard-card-body'}`}>
          <Card className={`add-website-card-tranparency ${callFrom === "trial" ? 'add-competitor-nested-card' : 'add-competitor-dashboard-nested-card'}`}>
            <CommonCardHeader
              startSpanClass={'done-step'}
              websiteSpanClass={'done-step'}
              locationSpanClass={'done-step'}
              googleSpanClass={'done-step'}
              keywordSpanClass={'done-step'}
              competitorSpanClass={'active-step'}
              sitemapSpanClass={'inactive-step'}
              callFrom={callFrom}
            />

            <CardBody className={`bg-white add-website-card-tranparency`}>
              <Row>
                <Col>
                  <h3 className="f-bold">Add URLs for Competitors of {prevPageData?.url}</h3>
                  {planName === PLAN.BASIC_PLAN.name ? (
                    <>
                      <p>Add up to one competitors for side by side comparision</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PRO_PLAN.name ? (
                    <>
                      <p>Add up to three competitors for side by side comparision</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <p>Add up to five competitors for side by side comparision</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.BASIC_PLAN.name ||
                  planName === PLAN.PRO_PLAN.name ||
                  planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <InputGroup className="mb-3">
                        <DropdownButton variant="secondary" title={title1} id="input-group-dropdown-1">
                          <Dropdown.Item
                            onClick={() => {
                              setTitle1('https')
                            }}
                          >
                            https
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setTitle1('http')
                            }}
                          >
                            http
                          </Dropdown.Item>
                        </DropdownButton>
                        <FormControl
                          aria-label="example.com"
                          placeholder={'competitor1.com'}
                          value={competitor1}
                          disabled={competitor1Disabled}
                          onFocus={() => {
                            setEditButton1('Update')
                            if (competitor1) {
                              setPreviousCompetitor(competitor1)
                            }
                          }}
                          onChange={(e) => {
                            setCompetitor1(e.target.value)
                          }}
                          onBlur={(e) => {
                            setEditButton1('Edit')
                            if (e.target.value) {
                              if (previousCompetitor) {
                                addCompetitors(
                                  { competitor: e.target.value },
                                  { competitor: previousCompetitor, checked: true },
                                )
                              } else {
                                addCompetitors({ competitor: e.target.value })
                              }
                            }
                          }}
                        />
                        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ marginLeft: '10px', cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : '' }}
                            onBlur={(e) => {
                              if (!e?.relatedTarget?.classList?.contains('form-control')) {
                                setEditButton1('Edit')
                                if (competitor1?.trim()) {
                                  setCompetitor1Disabled(true)
                                }
                              }
                            }}
                            onClick={() => {
                              if (editButton1 === 'Edit') {
                                if (competitor1?.trim()) {
                                  setEditButton1('Update')
                                  setCompetitor1Disabled(false)
                                }
                              } else {
                                setEditButton1('Edit')
                                if (competitor1?.trim()) {
                                  setCompetitor1Disabled(true)
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            {isMobile ? null : editButton1}
                          </Button>

                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            variant="outline-danger"
                            onClick={() => {
                              if (competitor1) {
                                addCompetitors({ competitor: competitor1, checked: true })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                            {isMobile ? null : "Clear"}
                          </Button>
                        </div>
                      </InputGroup>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <InputGroup className="mb-3">
                        <DropdownButton variant="secondary" title={title2} id="input-group-dropdown-1">
                          <Dropdown.Item
                            onClick={() => {
                              setTitle2('https')
                            }}
                          >
                            https
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setTitle2('http')
                            }}
                          >
                            http
                          </Dropdown.Item>
                        </DropdownButton>
                        <FormControl
                          aria-label="example.com"
                          placeholder={'competitor2.com'}
                          value={competitor2}
                          disabled={competitor2Disabled}
                          onFocus={() => {
                            setEditButton2('Update')
                            if (competitor2) {
                              setPreviousCompetitor(competitor2)
                            }
                          }}
                          onChange={(e) => {
                            setCompetitor2(e.target.value)
                          }}
                          onBlur={(e) => {
                            setEditButton2('Edit')
                            if (e.target.value) {
                              if (previousCompetitor) {
                                addCompetitors(
                                  { competitor: e.target.value },
                                  { competitor: previousCompetitor, checked: true },
                                )
                              } else {
                                addCompetitors({ competitor: e.target.value })
                              }
                            }
                          }}
                        />
                        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ marginLeft: '10px', cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            onBlur={(e) => {
                              if (!e?.relatedTarget?.classList?.contains('form-control')) {
                                setEditButton2('Edit')
                                if (competitor2?.trim()) {
                                  setCompetitor2Disabled(true)
                                }
                              }
                            }}
                            onClick={() => {
                              if (editButton2 === 'Edit') {
                                if (competitor2?.trim()) {
                                  setEditButton2('Update')
                                  setCompetitor2Disabled(false)
                                }
                              } else {
                                setEditButton2('Edit')
                                if (competitor2?.trim()) {
                                  setCompetitor2Disabled(true)
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            {isMobile ? null : editButton2}
                          </Button>

                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            variant="outline-danger"
                            onClick={() => {
                              if (competitor2) {
                                addCompetitors({ competitor: competitor2, checked: true })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                            {isMobile ? null : "Clear"}
                          </Button>
                        </div>
                      </InputGroup>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <InputGroup className="mb-3">
                        <DropdownButton variant="secondary" title={title3} id="input-group-dropdown-1">
                          <Dropdown.Item
                            onClick={() => {
                              setTitle3('https')
                            }}
                          >
                            https
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setTitle3('http')
                            }}
                          >
                            http
                          </Dropdown.Item>
                        </DropdownButton>
                        <FormControl
                          aria-label="example.com"
                          placeholder={'competitor3.com'}
                          value={competitor3}
                          disabled={competitor3Disabled}
                          onFocus={() => {
                            setEditButton3('Update')
                            if (competitor3) {
                              setPreviousCompetitor(competitor3)
                            }
                          }}
                          onChange={(e) => {
                            setCompetitor3(e.target.value)
                          }}
                          onBlur={(e) => {
                            setEditButton3('Edit')
                            if (e.target.value) {
                              if (previousCompetitor) {
                                addCompetitors(
                                  { competitor: e.target.value },
                                  { competitor: previousCompetitor, checked: true },
                                )
                              } else {
                                addCompetitors({ competitor: e.target.value })
                              }
                            }
                          }}
                        />
                        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ marginLeft: '10px', cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            onBlur={(e) => {
                              if (!e?.relatedTarget?.classList?.contains('form-control')) {
                                setEditButton3('Edit')
                                if (competitor3?.trim()) {
                                  setCompetitor3Disabled(true)
                                }
                              }
                            }}
                            onClick={() => {
                              if (editButton3 === 'Edit') {
                                if (competitor3?.trim()) {
                                  setEditButton3('Update')
                                  setCompetitor3Disabled(false)
                                }
                              } else {
                                setEditButton3('Edit')
                                if (competitor3?.trim()) {
                                  setCompetitor3Disabled(true)
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            {isMobile ? null : editButton3}
                          </Button>

                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            variant="outline-danger"
                            onClick={() => {
                              if (competitor3) {
                                addCompetitors({ competitor: competitor3, checked: true })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                            {isMobile ? null : "Clear"}
                          </Button>
                        </div>
                      </InputGroup>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <InputGroup className="mb-3">
                        <DropdownButton variant="secondary" title={title4} id="input-group-dropdown-1">
                          <Dropdown.Item
                            onClick={() => {
                              setTitle4('https')
                            }}
                          >
                            https
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setTitle4('http')
                            }}
                          >
                            http
                          </Dropdown.Item>
                        </DropdownButton>
                        <FormControl
                          aria-label="example.com"
                          placeholder={'competitor4.com'}
                          value={competitor4}
                          disabled={competitor4Disabled}
                          onFocus={() => {
                            setEditButton4('Update')
                            if (competitor4) {
                              setPreviousCompetitor(competitor4)
                            }
                          }}
                          onChange={(e) => {
                            setCompetitor4(e.target.value)
                          }}
                          onBlur={(e) => {
                            setEditButton4('Edit')
                            if (e.target.value) {
                              if (previousCompetitor) {
                                addCompetitors(
                                  { competitor: e.target.value },
                                  { competitor: previousCompetitor, checked: true },
                                )
                              } else {
                                addCompetitors({ competitor: e.target.value })
                              }
                            }
                          }}
                        />
                        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ marginLeft: '10px', cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            onBlur={(e) => {
                              if (!e?.relatedTarget?.classList?.contains('form-control')) {
                                setEditButton4('Edit')
                                if (competitor4?.trim()) {
                                  setCompetitor4Disabled(true)
                                }
                              }
                            }}
                            onClick={() => {
                              if (editButton4 === 'Edit') {
                                if (competitor4?.trim()) {
                                  setEditButton4('Update')
                                  setCompetitor4Disabled(false)
                                }
                              } else {
                                setEditButton4('Edit')
                                if (competitor4?.trim()) {
                                  setCompetitor4Disabled(true)
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            {isMobile ? null : editButton4}
                          </Button>

                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            variant="outline-danger"
                            onClick={() => {
                              if (competitor4) {
                                addCompetitors({ competitor: competitor4, checked: true })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                            {isMobile ? null : "Clear"}
                          </Button>
                        </div>
                      </InputGroup>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <InputGroup className="mb-3">
                        <DropdownButton variant="secondary" title={title5} id="input-group-dropdown-1">
                          <Dropdown.Item
                            onClick={() => {
                              setTitle5('https')
                            }}
                          >
                            https
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setTitle5('http')
                            }}
                          >
                            http
                          </Dropdown.Item>
                        </DropdownButton>
                        <FormControl
                          aria-label="example.com"
                          placeholder={'competitor5.com'}
                          value={competitor5}
                          disabled={competitor5Disabled}
                          onFocus={() => {
                            setEditButton5('Update')
                            if (competitor5) {
                              setPreviousCompetitor(competitor5)
                            }
                          }}
                          onChange={(e) => {
                            setCompetitor5(e.target.value)
                          }}
                          onBlur={(e) => {
                            setEditButton5('Edit')
                            if (e.target.value) {
                              if (previousCompetitor) {
                                addCompetitors(
                                  { competitor: e.target.value },
                                  { competitor: previousCompetitor, checked: true },
                                )
                              } else {
                                addCompetitors({ competitor: e.target.value })
                              }
                            }
                          }}
                        />
                        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            icon={faPen}
                            style={{ marginLeft: '10px', cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            onBlur={(e) => {
                              if (!e?.relatedTarget?.classList?.contains('form-control')) {
                                setEditButton5('Edit')
                                if (competitor5?.trim()) {
                                  setCompetitor5Disabled(true)
                                }
                              }
                            }}
                            onClick={() => {
                              if (editButton5 === 'Edit') {
                                if (competitor5?.trim()) {
                                  setEditButton5('Update')
                                  setCompetitor5Disabled(false)
                                }
                              } else {
                                setEditButton5('Edit')
                                if (competitor5?.trim()) {
                                  setCompetitor5Disabled(true)
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            {isMobile ? null : editButton5}
                          </Button>

                          <Button
                            className="d-flex flex-row justify-content-center align-items-center gap-1"
                            style={{ cursor: 'pointer', padding: isMobile ? "0.5rem 0.7rem" : ''  }}
                            variant="outline-danger"
                            onClick={() => {
                              if (competitor5) {
                                addCompetitors({ competitor: competitor5, checked: true })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                            {isMobile ? null : "Clear"}
                          </Button>
                        </div>
                      </InputGroup>
                    </>
                  ) : (
                    <></>
                  )}

                  <Row className="mt-2rem">
                    <h3 className="f-bold">Suggestion for Competitors</h3>
                    {competitorList && competitorList.length ? (
                      <p> Select competitors for side by side comparision</p>
                    ) : (
                      <></>
                    )}
                    {!(competitorList && competitorList.length) && loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <Alert
                          variant={'warning'}
                          className="alert-outline-coloured"
                          key={'meta-title'}
                        >
                          <div className="alert-icon">
                          </div>
                          <div className="alert-message">
                            <p style={{ textAlign: 'center' }} className="alert-for-mobile">
                              {' '}
                              Select competitors for side by side comparison, Please allow up to 1 minute for
                              a real time scan{' '}
                            </p>
                          </div>
                        </Alert>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* <Col><Spinner /></Col> */}
                    {loading && !(competitorList && competitorList.length) ? (
                      <Col>
                        <Row className="w-100">
                          <div className="spacing-wo-border">
                            <Col xs="12" style={{ marginLeft: '27px' }}>
                              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                <div className="text-center">
                                  <Loader />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Row>
                      </Col>
                    ) : (
                      <>
                        {competitorList && competitorList.length ? (
                          renderCheckbox(competitorList)
                        ) : (
                          <>
                            <p className="exact-center">
                              {
                                'We are currently scouring the web for your competitors the moment you entered your URL. We use live data and analyze your progress in real-time. Sometimes it can take a little bit of time for our algorithm to produce enough data to analyze, please stand by as we will slowly update your dashboard with updated results.'
                              }
                            </p>
                            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                              <p>
                                <b>See why we couldn't find any results for the URL you entered.</b>
                              </p>
                            </OverlayTrigger>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </Col>

                {competitorLimitLoader &&
                !Object?.keys(competitorLimitError)?.length &&
                !Object.keys(competitorLimitData)?.length &&
                !loading ? (
                  <Row className="w-100">
                    <div className="spacing-wo-border">
                      <Col xs="12" style={{ marginLeft: '27px' }}>
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                          <div className="text-center">
                            <Loader />
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : null}
              </Row>

              <Row className={`mt-2rem add-competitor-navigate-btn`}>
                <Col className={`${isMobile ? "text-center" : ''}`}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    variant={`outline-secondary`}
                    onClick={() => {
                      // dispatch({ type: 'CLEAR_KEYWORDS_DETAILS' });
                      navigate(callFrom === 'dashboard' ? paths.dashboardAddKeywords : paths.addKeywords)
                    }}
                    style={{ height: 40, width: 250 }}
                  >
                    Back
                  </Button>
                </Col>
                <Col className={'display-next-end'}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    onClick={() => checkCompetitorLimit()}
                    style={{ height: 40, width: 250 }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  )
}

export default AddCompetitorsMain

import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'

import { Settings } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import user from '../../assets/img/avatars/user.png'

const NavbarUser = () => {
  const data = useSelector(({ register }) => register?.registerData)
  const navigate = useNavigate()
  
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img src={data?.profilePicture ? data?.profilePicture : user} className="avatar img-fluid rounded-circle me-1" alt={data.firstName} />
          <span className="text-dark">
            {data.firstName} {data.lastName}
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item>
          {data.firstName} {data.lastName}
        </Dropdown.Item>
        <Dropdown.Item>{data.email}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => navigate('/account/profile')}>My Account</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate('/account/billing')}>Billngs</Dropdown.Item>
        <Dropdown.Item>My Profile</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate('/auth/sign-in')}>
          <span>Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NavbarUser

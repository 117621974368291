import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { Card, Col, Row, Button, FormControl, InputGroup, Alert, Modal, Spinner } from 'react-bootstrap'

import 'react-credit-cards/es/styles-compiled.css'
import { CardBody } from 'reactstrap'
import ColoredModal from 'pages/modals/ColorModal'
import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import { clearUrlDetails, getCountries, getLocations } from 'redux/actions/freetrial/startFreeTrial'
import paths from 'constants/paths'
import { Loader } from 'components/uiCore'
import { getWebsiteLimit } from 'redux/actions/dashboard'
import { CLEAR_WEBSITE_LIMIT } from 'redux/actionType'
import { LimitOption, PLAN } from 'constants/enum'
import { message } from 'antd'
import AddWebsite from 'assets/img/pms/addWebsite.png'
import 'modules/welcomeBoard/components/addWebsite/addWebesiteTrial.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const AddWebsiteMain = ({ callFrom }) => {
  const [website, setWebsite] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)
  const [isURLValidateError, setIsURLValidateError] = useState(false)
  const [urlValidationLoader, setURLValidationLoader] = useState(false)
  const [showCrawlerAlert, setShowCrawlerAlert] = useState(false)
  const [selectedFireWall, setSeletedFirewall] = useState('')

  const [showBackButton, setShowBackButton] = useState(false)

  const [openWebsiteLimitExceed, setOpenWebsiteLimitExceed] = useState(false)

  const title = 'https'
  const firewallOptions = [
    {
      value:
        'https://community.akamai.com/customers/s/article/How-to-allow-deny-an-IP-GEO-in-WAF-WAP-Network-List-Management?language=en_US',
      label: 'Akamai',
    },
    { value: 'https://developers.cloudflare.com/waf/tools/ip-access-rules/create/', label: 'Cloudflare' },
    {
      value:
        'https://learn.microsoft.com/en-us/answers/questions/1179573/how-to-define-ip-whitelist-in-azure-application-ga',
      label: 'Azure',
    },
    { value: 'https://docs.wallarm.com/user-guides/ip-lists/allowlist/', label: 'Wallarm' },
    { value: 'https://docs.imperva.com/howto/c85245b7', label: 'Imperva' },
  ]

  const {
    randomNumber,
    linkArray: urlDetails,
    prevPageData,
    validateURLSuccess,
    validateURL,
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const currentUser = useSelector(({ register }) => register.registerData)
  const userId = useSelector(({ register }) => register?.registerData?._id)
  const registerData = useSelector(({ register }) => register.registerData)
  const { websiteLimitData, websiteLimitLoader, websiteLimitError } = useSelector(
    ({ dashboard }) => dashboard,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck()

  useEffect(() => {
    const storeData = async () => {
      window.scrollTo(0,0)
      dispatch({ type: 'STORE_RANDOM_NUMBER' })
      dispatch({ type: 'ADD_USER_CLEAR' })
      dispatch({ type: 'CLEAR_ADD_WEBSITE_THROUGH_CACHE_FAILURE' })
      dispatch(getCountries())
      dispatch(getLocations())
      dispatch(clearUrlDetails())
      dispatch({ type: 'VALIDATE_URL_ABOUT_TO_HAPPEN' })
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)?._id && !currentUser?._id) {
        dispatch({ type: 'STORE_USER_DATA_START', payload: JSON.parse(data) })
        if (JSON.parse(data) && JSON.parse(data).websites && JSON.parse(data).websites.length) {
          // navigate('/dashboard/revised');
        }
      }
    }
    storeData()
  }, [])

  // useEffect(() => {
  //   const redirectPage = async() => {
  //     const data = await localStorage.getItem('userData')

  //     if(JSON.parse(data)?.websites?.length){
  //       navigate(paths.mainDashboard)
  //     }
  //   }
  //   redirectPage()
  // }, [])

  const startTrail = () => {
    if (website && website.length > 5) {
      setIsURLValidateError(false)
      setSeletedFirewall('')
      setURLValidationLoader(true)
      dispatch({ type: 'VALIDATE_URL_DETAILS', payload: { url: website } })
      setTimeout(() => {
        setShowCrawlerAlert(true)
      }, 15000)
    } else {
      setURLValidationLoader(false)
      setError(true)
      setErrorMessage('Enter Valid URL')
    }
  }

  useEffect(() => {
    if (showCrawlerAlert) {
      setTimeout(() => {
        setShowBackButton(true)
        setURLValidationLoader(false)
      }, 120000)
    }
  }, [showCrawlerAlert])

  useEffect(() => {
    if (validateURLSuccess) {
      if (validateURL?.status === 1) {
      
        const input = {
          sessionId: randomNumber,
          url: website,
          action: 'getUrlDetails',
        }
        dispatch({ type: 'GET_URL_DETAILS', payload: input })
        dispatch({ type: 'STORE_PREVIOUS_PAGE_DATA', payload: { ...prevPageData, url: website } })
      } else {
        setURLValidationLoader(false)
        setShowCrawlerAlert(false)
        setIsURLValidateError(true)
      }
    } else {
      // setURLValidationLoader(false);
      // setShowCrawlerAlert(false);
    }
  }, [validateURLSuccess, validateURL])

  useEffect(() => {
    if (website?.length) {
      if (urlDetails && urlDetails.length) {
        setShowCrawlerAlert(false)
        navigate(callFrom === 'dashboard' ? paths.dashboardSelectWebsite : paths.selectWebsite)
      }
    }
  }, [urlDetails])

  useEffect(() => {
    if (websiteLimitLoader) {
      return
    } else if (!websiteLimitLoader && websiteLimitData && Object?.keys(websiteLimitData)?.length) {
      if (websiteLimitData?.limitAvailable) {
        skipTestWithCache()
        dispatch({ type: CLEAR_WEBSITE_LIMIT })
      } else {
        if (websiteLimitData && Object.keys(websiteLimitData)?.length && !websiteLimitData?.limitAvailable) {
          setOpenWebsiteLimitExceed(true)
        }
      }
    } else {
      if (!websiteLimitLoader && websiteLimitError && Object.keys(websiteLimitError)?.length) {
        message.error(websiteLimitError?.message)
        dispatch({ type: CLEAR_WEBSITE_LIMIT })
      }
    }
  }, [websiteLimitLoader, websiteLimitData, websiteLimitError])

  const onChange = (e) => {
    const websiteUrl = e.target.value
    setWebsite(websiteUrl.trim())
    setIsURLValidateError(false)
    setShowCrawlerAlert(false)
    setShowBackButton(false)
  }

  const skipTestWithCache = () => {
    setShowCrawlerAlert(false)
    let websiteNew
    if (website.endsWith('/')) {
      websiteNew = website.substring(0, website.length - 1)
    } else {
      websiteNew = website
    }
    const splitedValue = websiteNew.split('/').slice(0, 3)
    let hostName = splitedValue[splitedValue.length - 1]
    dispatch({ type: 'ADD_WEBSITE_THROUGH_CACHE' })
    dispatch({
      type: 'STORE_PREVIOUS_PAGE_DATA',
      payload: { ...prevPageData, url: `${title}://${hostName}` },
    })
    navigate(callFrom === 'dashboard' ? paths.dashboardaddLocation : paths.addLocation)
  }

  const handleSelectWebsite = () => {
    let websiteNew
    if (website.endsWith('/')) {
      websiteNew = website.substring(0, website.length - 1)
    } else {
      websiteNew = website
    }
    const splitedValue = websiteNew.split('/').slice(0, 3)
    let hostName = splitedValue[splitedValue.length - 1]
    dispatch(getWebsiteLimit({ url: `${title}://${hostName}`, limitOption: LimitOption?.WEBSITE }))
  }

  return (
    <div className={callFrom === "trial" ? "add-website" : "add-website-dashboard"}>
      <Helmet title="Start trail" />
      {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={`d-flex align-items-center ${callFrom === "trial" ? 'add-website-text' : 'add-website-text-dashboard'}`}>
         {isMobile ? <div className="add-website-stepnumber-mobile">
            <h3 className="mb-0">1</h3>
          </div> : null}
          <div className="add-website-text-mobile">
            <p className="add-website-text-first">Empower Your SEO Journey: Precision, Simplicity, Results</p>
            <p className="add-website-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      <ColoredModal
        isOpen={error}
        onClose={() => {
          setError(false)
          setErrorMessage('')
        }}
        message={errorMessage}
      />

      <Modal
        show={openWebsiteLimitExceed}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setOpenWebsiteLimitExceed(!openWebsiteLimitExceed)
          dispatch({ type: CLEAR_WEBSITE_LIMIT })
        }}
        onClose={() => {
          setOpenWebsiteLimitExceed(false)
          dispatch({ type: CLEAR_WEBSITE_LIMIT })
        }}
        onHide={() => {
          setOpenWebsiteLimitExceed(false)
          dispatch({ type: CLEAR_WEBSITE_LIMIT })
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15 pe-0"
      >
        <Modal.Header>
          <h3 className="mb-0">Website Tracking Limit Reached</h3>
        </Modal.Header>
        <Modal.Body>
          You've reached your limit of adding or replacing websites for this period. Our real-time internet
          scanning service incurs significant costs to ensure accurate and up-to-date data for your tracked
          websites. This is why we've set a limit on the number of websites you can track or replace each
          month, to maintain the quality of our service for all customers.
          <br /> <br />
          Current Plan: {websiteLimitData?.websiteLimit}{' '}
          {websiteLimitData?.websiteLimit === 1 ? 'Website' : 'Websites'}
          <br /> <br />
          You may re-add any previously tracked websites from the list below, but the total cannot exceed your
          plan's active website limit at any time.
          <br /> <br />
          {websiteLimitData?.websites?.map((website) => {
            return (
              <Row>
                <Col lg="8" className="d-flex flex-row justify-content-between align-items-center mb-1">
                  <p className="mb-0">{website}</p>
                </Col>
              </Row>
            )
          })}
          <br />
          Looking for more flexibility? Our {PLAN.PREMIUM_PLAN.name} Plan offers unlimited website tracking, tailored for
          businesses requiring extensive monitoring.
          <br /> <br />
          Thank you for choosing us for your SEO needs. We're here to help you make the most of your digital
          presence!
        </Modal.Body>
        <Modal.Footer className={`d-flex flex-row ${registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "justify-content-end" : 'justify-content-between'}`}>
         {registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : <Button
            color="primary"
            variant="primary"
            onClick={() => {
              navigate(paths.upgradePlan)
              setOpenWebsiteLimitExceed(false)
              dispatch({ type: CLEAR_WEBSITE_LIMIT })
            }}
          >
            Upgrade your plan
          </Button>}

          <Button
            color="danger"
            variant="danger"
            onClick={() => {
              setOpenWebsiteLimitExceed(false)
              dispatch({ type: CLEAR_WEBSITE_LIMIT })
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    <div className={callFrom === "trial" ? 'add-website-card-container' : ''}>
      <Card
        className={`trail-flow add-website-card-tranparency ${callFrom === 'trial' ? 'add-website-card' : 'add-website-dashboard-card'} ${isURLValidateError ? 'add-website-card-scroll' : ''}`}
        style={{ padding: !isMobile ? 15 : null }}
      >
        <CardBody className='add-website-card-tranparency'>
          <Card
            className={`add-website-card-tranparency ${callFrom === 'trial' ? 'add-website-nested-card' : 'add-website-dashboard-nested-card'}`}
          >
            <CommonCardHeader
              startSpanClass={'active-step'}
              websiteSpanClass={'inactive-step'}
              locationSpanClass={'inactive-step'}
              googleSpanClass={'inactive-step'}
              keywordSpanClass={'inactive-step'}
              competitorSpanClass={'inactive-step'}
              sitemapSpanClass={'inactive-step'}
              callFrom={callFrom}
            />

            <CardBody className="bg-white add-website-card-tranparency add-website-card-body">
              <Row className="add-website-card-row">
                <h3 className="f-bold d-none d-lg-block d-md-block d-sm-none">Main Website</h3>
                <p>
                  <b>Please enter the URL of the website you wish to monitor and analyze</b>
                </p>
                <p style={{ marginBottom: isMobile ? 0 : 'auto' }}>
                  You can track any website within your SEO dashboard or track as many URL's as your package
                  permits
                </p>
                <InputGroup className="mb-3 add-website-card-input">
                  <FormControl
                    aria-label="example.com"
                    className='add-website-card-tranparency add-website-input'
                    placeholder={'example.com'}
                    onChange={(e) => onChange(e)}
                    onKeyDown={(event) => {
                      if (event?.key === 'Enter') {
                        startTrail()
                      }
                    }}
                  />
                </InputGroup>
                <br />
                {isURLValidateError ? (
                  <Alert variant={'danger'} className="alert-outline-coloured" key={'timeoutWarning'}>
                    <div className="alert-icon">{/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}</div>
                    <div className="alert-message">
                      <p style={{ textAlign: 'center' }} className="alert-for-mobile">
                        We cannot access your url in order to perform our test! Either the URL is incorrect,
                        {website} is experiencing downtime, or our tool is being blocked by your server.
                      </p>
                    </div>
                  </Alert>
                ) : (
                  <></>
                )}
                {isURLValidateError ? (
                  <>
                    <div className="pl-1 pt-3">
                      <p>
                        Please choose the firewall currently in use by your hosting provider from the the
                        dropdown menu.Then, please select your service provider from the list. It will open a
                        new window with instruction to add our server IP 3.14.177.6 to your list.This will
                        allow our crawler to access {website} without being blocked. However, please remember
                        that you may revoke this access at any time. This access in only granted to our
                        crawler which should not be confused with a malicious host. <br />
                        <br />
                      </p>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Please choose the Firewall"
                        options={firewallOptions}
                        isSearchable
                        onChange={(e) => {
                          setSeletedFirewall(e?.value)
                        }}
                      />
                      <br />
                      {selectedFireWall?.length ? (
                        <>
                          Please click on this URL :{' '}
                          <a target="_blank" href={selectedFireWall}>
                            {' '}
                            {selectedFireWall}{' '}
                          </a>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      <br />
                      <br />
                      <p>
                        If your are unsure which WAF you are currently using, please contact our support team
                        at <b style={{ textDecoration: 'underline' }}>support@positionmysite.ca</b> or you can
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          href="https://securityforeveryone.com/"
                        >
                          {' '}
                          click here{' '}
                        </a>{' '}
                        to try and determine which provider you are currently using. <br />
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {urlValidationLoader ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      alignSelf: 'center',
                      flexDirection: 'row',
                      minHeight: 100,
                    }}
                  >
                    <Row className="w-100">
                      <div className="spacing-wo-border">
                        <Col xs="12">
                          <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <div className="text-center">
                              <Loader />
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </div>
                ) : (
                  <></>
                )}

                {showCrawlerAlert && !isURLValidateError ? (
                  <div>
                    <Alert variant={'warning'} className="alert-outline-coloured" key={'timeoutWarning'}>
                      <div className="alert-icon">{/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}</div>
                      <div className="alert-message">
                        <p style={{ textAlign: 'center' }} className="alert-for-mobile">
                          Our web crawler is currently trying to visit {website} but is facing some delays.
                          This usually happens when {website}'s security firewall is preventing our crawler
                          from accessing it. We'll keep trying for up to 120 seconds. In case we're unable to
                          connect, we recommend temporarily adjusting your security settings to grant our
                          crawler access to {website}. For guidance on how to do this, please{' '}
                          <Link
                            to={callFrom === 'trial' ? '/trial/firewall-options' : '/pages/firewall-options'}
                          >
                            click here
                          </Link>
                          .
                        </p>
                      </div>
                    </Alert>
                  </div>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                <div className={'display-end'}>
                  {!isURLValidateError ? (
                    <>
                      {showBackButton ? (
                        <Button
                          variant="outline-primary"
                          className="nxt-btn"
                          onClick={() => {
                            callFrom === 'trial' ? window.location.reload() : navigate(paths.mainDashboard)
                          }}
                          style={{ height: 40, width: 250, marginRight: 5 }}
                        >
                          {/* <Button onClick={ ()=> navigate('/trial/select-website')} className={'w-50'} style={{ height: 40 }}> */}
                          Back
                        </Button>
                      ) : null}
                      <Button
                        className="nxt-btn trial-container-nextbtn-mobile"
                        onClick={() => startTrail()}
                        style={{ height: 40, width: 250 }}
                        disabled={showCrawlerAlert ? true : false}
                      >
                        {/* <Button onClick={ ()=> navigate('/trial/select-website')} className={'w-50'} style={{ height: 40 }}> */}
                        Next
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ height: 40, width: 250 }}
                      onClick={() => {
                        handleSelectWebsite()
                      }}
                    >
                      {websiteLimitLoader ? <Spinner animation="border" className="me-2" size="sm" /> : null}
                      Continue with Cache
                    </Button>
                  )}
                </div>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      {callFrom === 'trial' && !isURLValidateError && (
        <img src={AddWebsite} className="add-website-img" alt="Add Website" width={100} height={100} />
      )}
</div>
    </div>
  )
}

export default AddWebsiteMain

import { Badge } from "react-bootstrap"
import { Link } from "react-scroll"

const MenuTitleTest = ({linkTo, testStatus, title}) => {
    return (
        <p>
            {testStatus === 1 ? (
                <Badge bg={'success'} className="me-1 mb-1">
                    Passed
                </Badge>
            ) : testStatus === 2 ? (
                <Badge bg={'warning'} className="me-1 mb-1">
                    Warning
                </Badge>
            ) : (
                <Badge bg={'danger'} className="me-1 mb-1">
                    Failed
                </Badge>
            )}
            &nbsp;
            <Link to={linkTo} spy={true} smooth={true} offset={-150} duration={500}>
                {title}
            </Link>
        </p>
    )
}
export default MenuTitleTest
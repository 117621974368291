import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { object, string } from 'yup'
import { createTemplateRequest } from '../../../../../redux/actions/reporting/template'
import { getLocalStoraGeItem } from '../../../../../utils/helper'

const schema = object().shape({
  templateName: string().required('Template name is Required'),
  // TODO:need this code
  // companyLogo: string().required('CompanyLogo name is Required'),
  // companyLogo: mixed()
  //   .required('A file is required')
  //   .test(
  //     'fileSize',
  //     'File too large',
  //     (value) => value && value.size <= FILE_SIZE,
  //   )
  //   .test(
  //     'fileFormat',
  //     'Unsupported Format',
  //     (value) => value && SUPPORTED_FORMATS.includes(value.type),
  //   ),
})
export const useNewTemplateFormik = (presignedUrl) => {
  const dispatch = useDispatch()
  const userData = getLocalStoraGeItem('userData')
  const { _id } = userData || {}
  const formik = useFormik({
    initialValues: {
      templateName: '',
      companyLogo: null,
      reportHeader: '',
      reportFooter: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const data = {
        templateName: values.templateName,
        header: values.reportHeader,
        footer: values.reportFooter,
        logo: presignedUrl,
        userId: _id,
      }
      dispatch(createTemplateRequest(data))
    },
  })
  return formik
}

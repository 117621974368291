import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const Custom404Test = () => {
  return (
    <CommonFreeTool
      heading={freeTools.custom404Test.heading}
      whatIsIT={freeTools.custom404Test.what}
      howToFixBeforeCode={freeTools.custom404Test.howSectionBeforeCode}
    />
  )
}

export default Custom404Test

import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Form, Alert, Tab, Nav as Navb, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as _ from 'lodash'
import { getAbbr, getActiveUserData, getRefreshToken } from 'constants/Utils'

import * as Yup from 'yup'


import {
  Button,
  Card,
  Spinner,
  Input,
  Nav,
  NavItem,
  NavLink,
  InputGroup,
  TabContent,
  TabPane,
} from 'reactstrap'
import {
  removeKeyword,
  getUserData,
  deleteWebsite,
  patchUserDomainsDashboard,
  changeActiveLink,
  getKeywordLimit,
  openUpgradePlanModal,
  getAvailableKeywordLimit,
  getCompetitorLimit,
  generateResearch,
  getAnalyticsAccountData,
} from 'redux/actions/dashboard/index.js'
import {
  storeUserData,
  verifyTokenStart,
  addCompetitorsSettings,
  removeCompetitorSettings,
  changeRegionSettings,
  addKeywordSettings,
} from 'redux/actions/register/index.js'
import { getCountries, getLocations } from 'redux/actions/freetrial/startFreeTrial.js'
import { AlertCircle, Plus } from 'react-feather'

import { Trash2 } from 'react-feather'
import paths from 'constants/paths'
import GoogleAutoLocation from 'components/GoogleAutoLocation'
import { CLEAR_ADD_COMPETITORS_SETTINGS, CLEAR_ADD_KEYWORDS_SETTINGS, CLEAR_ANALYTICS_DATA, CLEAR_COMPETITOR_LIMIT, CLEAR_DISCONNECT_GOOGLE, CLEAR_GENERATE_RESEARCH, CLEAR_KEYWORD_LIMIT, CLEAR_REMOVE_KEYWORD, CLEAR_STORE_ANALYTICS_DATA } from 'redux/actionType'
import { LimitOption, PLAN } from 'constants/enum'
import { message } from 'antd'
import { useFormik } from 'formik'
import GoogleAnalyticsModal from 'components/GoogleAnalyticsModal'
import { Loader } from 'components/uiCore'
import GoogleAuth from 'modules/welcomeBoard/components/addGoogle/GoogleAuth'
import GoogleButton from 'modules/welcomeBoard/components/addGoogle/components/GoogleButton'
let countriesOptions

const SettingsComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const searchParam = new URLSearchParams(location.search)
  const urlFromQuery = searchParam.get('hostname') 
  const errorMessage = searchParam.get('message') 
  const activeTabFromQuery = searchParam.get('activeTab') 

  const planName = useSelector(({ register }) => register?.registerData?.plan?.name)
  const countries = useSelector(({ startFreeTrial }) => startFreeTrial.countries)
  const locations = useSelector(({ startFreeTrial }) => startFreeTrial.locations)
  const loading = useSelector(({ dashboard }) => dashboard.loading)
  const settingsLoader = useSelector(({ dashboard }) => dashboard.settingsLoader)
  const settingsRegionLoader = useSelector(({ dashboard }) => dashboard.settingsRegionLoader)
  const regionLoaderType = useSelector(({ dashboard }) => dashboard.regionLoaderType)
  const settingsAddKeywordLoader = useSelector(({ dashboard }) => dashboard.settingsAddKeywordLoader)
  const userId = useSelector(({ register }) => register.registerData && register.registerData._id)
  const usersDomain = useSelector(({ dashboard }) => dashboard.usersDomains)
  const activeLink = useSelector(({ dashboard }) => dashboard.activeLink)
  const userData = useSelector(({ dashboard }) => dashboard?.userData)
  const websiteDeleteSuccess = useSelector(({ dashboard }) => dashboard?.websiteDeleteSuccess)

  const { keywordLimitLoader, 
          keywordLimitData, 
          keywordLimitError, 
          availableKeywordLimit, 
          removeLoading, 
          availableKeywordLimitLoader, 
          settingsAddKeywordSuccess, 
          keywordRemoveSuccess,
          competitorLimitLoader,
          competitorLimitData,
          competitorLimitError,
          removeCompetitorLoader,
          removeCompetitorSuccess,
          addCompetitorSuccess,
          addCompetitorError,
          getAuthUrlLoader,
          getAuthUrlSuccess,
          getAuthUrlFailure,
          getAuthUrlError,
          authUrl,
          generateResearchLoader,
          generateResearchFailure,
          generateResearchError,
          generateResearchData,
          analyticsDataLoader, 
          analyticsDataFailure, 
          analyticsDataError,
          disconnectGoogleLoader, 
          disconnectGoogleFailure, 
          disconnectGoogleError,
          disconnectGoogleSuccess, 
          disconnectGoogleMessage,
          storeAnalyticsDataLoader, 
          storeAnalyticsDataFailure, 
          storeAnalyticsDataError, 
          storeAnalyticsDataSuccess, 
          storeAnalyticsDataMessage, 
          analyticsData,
          analyticsDataSuccess
        } = useSelector(({ dashboard }) => dashboard)

  const [activeTab, setActiveTab] = useState((location && location.state && location.state.activeTab) ||  '1')
  const [enteredKeyword, setEnteredKeyword] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('Canada')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [cityOptions, setCityOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])

  const [selectedRegion2, setSelectedRegion2] = useState('')
  const [selectedState2, setSelectedState2] = useState('')
  const [selectedCity2, setSelectedCity2] = useState('')
  const [cityOptions2, setCityOptions2] = useState([])
  const [stateOptions2, setStateOptions2] = useState([])

  const [selectedRegion3, setSelectedRegion3] = useState('')
  const [selectedState3, setSelectedState3] = useState('')
  const [selectedCity3, setSelectedCity3] = useState('')
  const [cityOptions3, setCityOptions3] = useState([])
  const [stateOptions3, setStateOptions3] = useState([])

  const [selectedRegion4, setSelectedRegion4] = useState('')
  const [selectedState4, setSelectedState4] = useState('')
  const [selectedCity4, setSelectedCity4] = useState('')
  const [cityOptions4, setCityOptions4] = useState([])
  const [stateOptions4, setStateOptions4] = useState([])

  const [selectedRegion5, setSelectedRegion5] = useState('')
  const [selectedState5, setSelectedState5] = useState('')
  const [selectedCity5, setSelectedCity5] = useState('')
  const [cityOptions5, setCityOptions5] = useState([])
  const [stateOptions5, setStateOptions5] = useState([])

  const [selectedRegion6, setSelectedRegion6] = useState('')
  const [selectedState6, setSelectedState6] = useState('')
  const [selectedCity6, setSelectedCity6] = useState('')
  const [cityOptions6, setCityOptions6] = useState([])
  const [stateOptions6, setStateOptions6] = useState([])
  const [latAndLong, setLatAndLong] = useState({})
  const [latAndLong2, setLatAndLong2] = useState({})
  const [latAndLong3, setLatAndLong3] = useState({})
  const [latAndLong4, setLatAndLong4] = useState({})
  const [latAndLong5, setLatAndLong5] = useState({})
  const [latAndLong6, setLatAndLong6] = useState({})

  const [openCompetitorLimitExceed, setOpenCompetitorLimitExceed] = useState(false)
  const [openKeywordLimitExceed, setOpenKeywordLimitExceed] = useState(false)
  const [removedKeyword, setRemovedKeyword] = useState("")
  const [removedCompetitor, setRemovedCompetitor] = useState("")
  const [openCompetitorAddingLimitExceed, setOpenCompetitorAddingLimitExceed] = useState(false)
  const [showQueryError, setShowQueryError] = useState(true)

  const enableGoogleAutocomplete = true

  const id =
    usersDomain &&
    activeLink !== undefined &&
    usersDomain[activeLink] &&
    usersDomain[activeLink].items &&
    usersDomain[activeLink]?.items?.[0]?.['_id']

  let competitorThreshold,
    keywordThreshold = 1

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  const tokenData = getRefreshToken(usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url, userData) 
   
  if (userData && userData.planData && userData.planData.name === PLAN.PRO_PLAN.name) {
    competitorThreshold = 3
    keywordThreshold = 100
  } else if (userData && userData.planData && userData.planData.name === PLAN.BASIC_PLAN.name) {
    competitorThreshold = 1
    keywordThreshold = 50
  } else if (userData && userData.planData && userData.planData.name === PLAN.PREMIUM_PLAN.name) {
    competitorThreshold = 5
    keywordThreshold = 300
  }

  useEffect(() => {
    if (urlFromQuery && usersDomain && usersDomain?.length && !errorMessage) {
      const getHostNames = usersDomain?.length && usersDomain?.map(item => {
          const splittedURL = item?.items?.[0]?.url?.split('/').slice(0, 3)
          let hostName = splittedURL?.[splittedURL?.length - 1] || ''
          
          if (hostName.includes('www.')) {
            hostName = hostName.replace('www.', '')
          }

          return hostName
      })

      const urlIndex = getHostNames?.indexOf(urlFromQuery)
      if (urlIndex !== -1) {
        dispatch(changeActiveLink(urlIndex))
      }

    } else if (errorMessage && showQueryError) {
      message.error(errorMessage)
      setShowQueryError(false)
    }

    if (activeTabFromQuery) {
      setActiveTab(activeTabFromQuery)
    }
    
  }, [urlFromQuery, usersDomain, activeTabFromQuery, errorMessage, showQueryError])
  useEffect(() => {
    if (activeSearchData) {
      if (activeSearchData?.regions?.length) {
        setSelectedRegion(
          enableGoogleAutocomplete ? activeSearchData?.regions?.[0]?.region : activeSearchData?.region,
        )
        setSelectedState(activeSearchData?.regions?.[0]?.state)
        setSelectedCity(activeSearchData?.regions?.[0]?.city)
        setLatAndLong({
          lat: activeSearchData?.regions?.[0]?.lat,
          long: activeSearchData?.regions?.[0]?.long,
          country: activeSearchData?.regions?.[0]?.country,
          address: activeSearchData?.regions?.[0]?.address,
        })
      } else {
        setSelectedRegion('')
        setSelectedState('')
        setSelectedCity('')
        setLatAndLong({})
      }
      if (activeSearchData?.regions?.length > 1) {
        setSelectedRegion2(
          enableGoogleAutocomplete
            ? activeSearchData?.regions?.[1]?.region
            : activeSearchData?.regions?.[1]?.region,
        )
        setSelectedState2(activeSearchData?.regions?.[1]?.state)
        setSelectedCity2(activeSearchData?.regions?.[1]?.city)
        setLatAndLong2({
          lat: activeSearchData?.regions?.[1]?.lat,
          long: activeSearchData?.regions?.[1]?.long,
          country: activeSearchData?.regions?.[1]?.country,
          address: activeSearchData?.regions?.[1]?.address,
        })
      } else {
        setSelectedRegion2('')
        setSelectedState2('')
        setSelectedCity2('')
        setLatAndLong2({})
      }
      if (activeSearchData?.regions?.length > 2) {
        setSelectedRegion3(
          enableGoogleAutocomplete
            ? activeSearchData?.regions?.[2]?.region
            : activeSearchData?.regions?.[2]?.region,
        )
        setSelectedState3(activeSearchData?.regions?.[2]?.state)
        setSelectedCity3(activeSearchData?.regions?.[2]?.city)
        setLatAndLong3({
          lat: activeSearchData?.regions?.[2]?.lat,
          long: activeSearchData?.regions?.[2]?.long,
          country: activeSearchData?.regions?.[2]?.country,
          address: activeSearchData?.regions?.[2]?.address,
        })
      } else {
        setSelectedRegion3('')
        setSelectedState3('')
        setSelectedCity3('')
        setLatAndLong3({})
      }
      if (activeSearchData?.regions?.length > 3) {
        setSelectedRegion4(
          enableGoogleAutocomplete
            ? activeSearchData?.regions?.[3]?.region
            : activeSearchData?.regions?.[3]?.region,
        )
        setSelectedState4(activeSearchData?.regions?.[3]?.state)
        setSelectedCity4(activeSearchData?.regions?.[3]?.city)
        setLatAndLong4({
          lat: activeSearchData?.regions?.[3]?.lat,
          long: activeSearchData?.regions?.[3]?.long,
          country: activeSearchData?.regions?.[3]?.country,
          address: activeSearchData?.regions?.[3]?.address,
        })
      } else {
        setSelectedRegion4('')
        setSelectedState4('')
        setSelectedCity4('')
        setLatAndLong4({})
      }
      if (activeSearchData?.regions?.length > 4) {
        setSelectedRegion5(
          enableGoogleAutocomplete
            ? activeSearchData?.regions?.[4]?.region
            : activeSearchData?.regions?.[4]?.region,
        )
        setSelectedState5(activeSearchData?.regions?.[4]?.state)
        setSelectedCity5(activeSearchData?.regions?.[4]?.city)
        setLatAndLong5({
          lat: activeSearchData?.regions?.[4]?.lat,
          long: activeSearchData?.regions?.[4]?.long,
          country: activeSearchData?.regions?.[4]?.country,
          address: activeSearchData?.regions?.[4]?.address,
        })
      } else {
        setSelectedRegion5('')
        setSelectedState5('')
        setSelectedCity5('')
        setLatAndLong5({})
      }
      if (activeSearchData?.regions?.length > 5) {
        setSelectedRegion6(
          enableGoogleAutocomplete
            ? activeSearchData?.regions?.[5]?.region
            : activeSearchData?.regions?.[5]?.region,
        )
        setSelectedState6(activeSearchData?.regions?.[5]?.state)
        setSelectedCity6(activeSearchData?.regions?.[5]?.city)
        setLatAndLong6({
          lat: activeSearchData?.regions?.[5]?.lat,
          long: activeSearchData?.regions?.[5]?.long,
          country: activeSearchData?.regions?.[5]?.country,
          address: activeSearchData?.regions?.[5]?.address,
        })
      } else {
        setSelectedRegion6('')
        setSelectedState6('')
        setSelectedCity6('')
        setLatAndLong6({})
      }
    }
  }, [activeSearchData])

  useEffect(() => {
    countriesOptions =
      countries && countries.length
        ? countries.map((item) => {
            return { label: item, value: item }
          })
        : []

    dispatch(getCountries())
    dispatch(getLocations())
    init()
  }, [])

  useEffect(() => {
    countriesOptions =
      countries && countries.length
        ? countries.map((item) => {
            return { label: item, value: item }
          })
        : []
  }, [countries])

  useEffect(() => {
    if (websiteDeleteSuccess && usersDomain?.length && usersDomain?.length > 0) {
      dispatch({ type: 'CLEAR_DELETE_WEBSITE' })
      navigate(paths.mainDashboard)
    }
    if (websiteDeleteSuccess && usersDomain?.length === 0) {
      dispatch({ type: 'CLEAR_DELETE_WEBSITE' })
      navigate(paths.addNewWebsite)
    }
  }, [loading, websiteDeleteSuccess])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions(stateOptions)
    }
  }, [selectedRegion])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion && item.state === selectedState
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions(cityOptions)
    }
  }, [selectedState])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion2
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions2(stateOptions)
    }
  }, [selectedRegion2])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion2 && item.state === selectedState2
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions2(cityOptions)
    }
  }, [selectedState2])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion3
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions3(stateOptions)
    }
  }, [selectedRegion3])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion3 && item.state === selectedState3
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions3(cityOptions)
    }
  }, [selectedState3])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion4
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions4(stateOptions)
    }
  }, [selectedRegion4])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion4 && item.state === selectedState4
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions4(cityOptions)
    }
  }, [selectedState4])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion5
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions5(stateOptions)
    }
  }, [selectedRegion5])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion5 && item.state === selectedState5
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions5(cityOptions)
    }
  }, [selectedState5])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion6
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      stateOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setStateOptions6(stateOptions)
    }
  }, [selectedRegion6])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion6 && item.state === selectedState6
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      cityOptions?.sort((a, b) => a.value.localeCompare(b.value))
      setCityOptions6(cityOptions)
    }
  }, [selectedState6])

  useEffect(() => {
    if(keywordLimitLoader){
      return
    }else if(!keywordLimitLoader && keywordLimitData && Object?.keys(keywordLimitData)?.length){
      if(keywordLimitData?.limitAvailable){
        addKeyword()
        dispatch({type:CLEAR_KEYWORD_LIMIT})
      }else {
        if(keywordLimitData && Object.keys(keywordLimitData)?.length && !keywordLimitData?.limitAvailable){
          setOpenKeywordLimitExceed(true)
        }
      }
    }else {
      if(!keywordLimitLoader && keywordLimitError && Object.keys(keywordLimitError)?.length){
        message.error(keywordLimitError?.message)
        dispatch({type:CLEAR_KEYWORD_LIMIT})
      }
    }
  }, [keywordLimitLoader, keywordLimitData, keywordLimitError])

  useEffect(() => {
    if(competitorLimitLoader){
      return
    }else if(!competitorLimitLoader && competitorLimitData && Object?.keys(competitorLimitData)?.length){
      if(competitorLimitData?.limitAvailable){
        addCompetitor()
        dispatch({type:CLEAR_COMPETITOR_LIMIT})
      }else {
        if(competitorLimitData && Object.keys(competitorLimitData)?.length && !competitorLimitData?.limitAvailable){
          setOpenCompetitorLimitExceed(true)
        }
      }
    }else {
      if(!competitorLimitLoader && competitorLimitError && Object.keys(competitorLimitError)?.length){
        message.error(competitorLimitError?.message)
        dispatch({type:CLEAR_COMPETITOR_LIMIT})
      }
    }
  }, [competitorLimitLoader, competitorLimitData, competitorLimitError])

  useEffect(() => {
    if((!removeLoading && keywordRemoveSuccess) || (!settingsAddKeywordLoader && settingsAddKeywordSuccess)){
      dispatch(getAvailableKeywordLimit())
      dispatch({type: CLEAR_ADD_KEYWORDS_SETTINGS})
      dispatch({type: CLEAR_REMOVE_KEYWORD})
    }
  }, [removeLoading, settingsAddKeywordLoader,keywordRemoveSuccess, settingsAddKeywordSuccess])

  useEffect(()=> {
    const updateUserData = async () => { 
      if(removeCompetitorSuccess || addCompetitorSuccess){
        dispatch(getUserData())
        dispatch({type: CLEAR_ADD_KEYWORDS_SETTINGS})
        dispatch({type: CLEAR_ADD_COMPETITORS_SETTINGS})
      }
      if(addCompetitorError && addCompetitorError?.status === 500){
        setOpenCompetitorAddingLimitExceed(true)
      }

      if(addCompetitorSuccess){
        handleResetForm()
      }
    }
    updateUserData()
  }, [removeCompetitorSuccess, addCompetitorSuccess, addCompetitorError])

  useEffect(() => {
    if (!getAuthUrlLoader && getAuthUrlSuccess) {
      window.open(
        authUrl,
        '_target',
      );
    } else if (!getAuthUrlLoader && getAuthUrlFailure) {
      message.error(getAuthUrlError?.message)
    }
  }, [getAuthUrlLoader, getAuthUrlSuccess, getAuthUrlFailure, getAuthUrlError])

  useEffect(() => {
    if( tokenData && tokenData?.refreshToken && usersDomain && usersDomain?.length) {
      const url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
      
      if (!analyticsData && Object.keys(tokenData)?.length <= 3) {
        dispatch(getAnalyticsAccountData({url}))
      }
    }
  }, [usersDomain, analyticsData, tokenData])

  useEffect(() => {
    if (!generateResearchLoader && generateResearchFailure) {
      message.error(generateResearchError?.message)
      dispatch({type: CLEAR_GENERATE_RESEARCH})
    }
  }, [generateResearchLoader, generateResearchFailure, generateResearchError])

  useEffect(() => {
    if (!disconnectGoogleLoader && disconnectGoogleFailure) {
      message.error(disconnectGoogleError?.message)
      dispatch({type: CLEAR_DISCONNECT_GOOGLE})
      dispatch(getUserData())
    } else if (!disconnectGoogleLoader && disconnectGoogleSuccess) {
      message.success(disconnectGoogleMessage?.message)
      dispatch({type: CLEAR_DISCONNECT_GOOGLE})
      dispatch(getUserData())
    }
  }, [disconnectGoogleLoader, disconnectGoogleFailure, disconnectGoogleError, disconnectGoogleSuccess, disconnectGoogleMessage])

  useEffect(() => {
    if (!storeAnalyticsDataLoader && storeAnalyticsDataFailure) {
      message.error(storeAnalyticsDataError?.message)
      dispatch({type: CLEAR_STORE_ANALYTICS_DATA})
      dispatch(getUserData())
    } else if (!storeAnalyticsDataLoader && storeAnalyticsDataSuccess) {
      message.success(storeAnalyticsDataMessage?.message)
      dispatch({type: CLEAR_STORE_ANALYTICS_DATA})
      dispatch(getUserData())
    }
  }, [storeAnalyticsDataLoader, storeAnalyticsDataFailure, storeAnalyticsDataError, storeAnalyticsDataSuccess, storeAnalyticsDataMessage])

  useEffect(() => {
    if (!analyticsDataLoader && analyticsDataFailure) {
      message.error(analyticsDataError?.message)
      dispatch({type: CLEAR_ANALYTICS_DATA})
    } else if (!analyticsDataLoader && analyticsDataSuccess) {
      dispatch(getUserData())
    }
  }, [analyticsDataLoader, analyticsDataFailure, analyticsDataError, analyticsDataSuccess])

  const init = async () => {
    const data = await localStorage.getItem('userData')
    await dispatch(verifyTokenStart())
    if (data && JSON.parse(data)._id) {
      await dispatch(storeUserData(JSON.parse(data)))
      if (usersDomain && usersDomain.length) {
      } else {
        await dispatch(patchUserDomainsDashboard())
      }
      await dispatch(getUserData())
      dispatch(getAvailableKeywordLimit())
    } else {
      navigate('/')
    }
     
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const appendKeyword = (data) => {
    let trimedData = data.trim()
    if (trimedData.length > 1) {
      let data1 = enteredKeyword + enteredKeyword && enteredKeyword.length ? ',' : '' + trimedData
      keywordFormik.setFieldValue("enteredKeyword", data1)
    }
  }

  const showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = e.target.result

      appendKeyword(text)
    }
    reader.readAsText(e.target.files[0])
  }

  const addKeyword = () => {
    const { enteredKeyword } = keywordFormik.values
    if (usersDomain && enteredKeyword && enteredKeyword.length) {
      let finalKeywords = []
      let data = enteredKeyword.split(',')
      data.forEach((element) => {
        if (element.length > 2) {
          finalKeywords.push(element.trim())
        }
      })
      let uniqueData = _.uniq(finalKeywords)

      let url = usersDomain[activeLink].items[0].url
      let website = usersDomain[activeLink].items[0].id
      dispatch(addKeywordSettings({ keywords: uniqueData, url, website }))
      handleResetForm()
    }
  }

  const checkCompetitorLimit = () => {
    const { enteredCompetitor } = competitorFormik.values
    const trimmedEnteredCompetitor = enteredCompetitor?.trim()

    if (trimmedEnteredCompetitor && trimmedEnteredCompetitor?.length) {
      let competitor
      if (trimmedEnteredCompetitor?.startsWith('https://')) {
        competitor = trimmedEnteredCompetitor
      } else if (trimmedEnteredCompetitor?.startsWith('http://')) {
        competitor = trimmedEnteredCompetitor
      } else {
        competitor = `https://${trimmedEnteredCompetitor}`
      }
      const competitor_payload = {
        competitors: [competitor],
        limitOption: LimitOption.COMPETITOR,
        url: usersDomain?.[activeLink]?.items?.[0]?.url
      }
      dispatch(getCompetitorLimit(competitor_payload))
    }
  }
  const addCompetitor = async () => {
    const { enteredCompetitor } = competitorFormik.values
    const trimmedEnteredCompetitor = enteredCompetitor?.trim()

    if (usersDomain && trimmedEnteredCompetitor && trimmedEnteredCompetitor?.length) {
      let website = usersDomain[activeLink].items[0].id
      let location = await getLocationData(selectedRegion, selectedState, selectedCity)
      let updatedComp
      if (trimmedEnteredCompetitor?.startsWith('https://')) {
        updatedComp = trimmedEnteredCompetitor
      } else if (trimmedEnteredCompetitor?.startsWith('http://')) {
        updatedComp = trimmedEnteredCompetitor
      } else {
        updatedComp = `https://${trimmedEnteredCompetitor}`
      }
      dispatch(
        addCompetitorsSettings({
          competitor: updatedComp,
          url: usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
          website,
          urlDataId: id,
          region: location.region,
        }),
      )
    }
  }

  const removeCompetitor = (competitor) => {
    if (usersDomain) {
      let url = usersDomain[activeLink].items[0].url
      let website = usersDomain[activeLink].items[0].id
      setRemovedCompetitor(competitor)
      dispatch(removeCompetitorSettings({ competitor: competitor, url, website }))
    }
  }

  const changeRegion = async () => {
    if (usersDomain) {
      let url = usersDomain[activeLink].items[0].url
      let website = usersDomain[activeLink].items[0].id

      let regions = []
      let firstLocation

      if (selectedCity !== '') {
        firstLocation = await getLocationData(
          selectedRegion,
          selectedState,
          selectedCity,
          latAndLong?.lat,
          latAndLong?.long,
          ' ',
        )
        let data = {
          region: enableGoogleAutocomplete ? selectedRegion : firstLocation.region,
          long: enableGoogleAutocomplete ? latAndLong.long : firstLocation.long,
          lat: enableGoogleAutocomplete ? latAndLong.lat : firstLocation.lat,
          iso3: enableGoogleAutocomplete ? ' ' : firstLocation.iso3,
          city: enableGoogleAutocomplete ? selectedCity : firstLocation.city,
          state: enableGoogleAutocomplete ? selectedState : firstLocation.state,
          country: enableGoogleAutocomplete ? latAndLong.country : latAndLong?.country,
          address: latAndLong.address,
        }
        regions.push(data)
      }
      if (selectedCity2 !== '') {
        let secondLocation = await getLocationData(
          selectedRegion2,
          selectedState2,
          selectedCity2,
          latAndLong2?.lat,
          latAndLong2?.long,
          ' ',
        )
        let data = {
          region: enableGoogleAutocomplete ? selectedRegion2 : secondLocation.region,
          long: enableGoogleAutocomplete ? latAndLong2.long : secondLocation.long,
          lat: enableGoogleAutocomplete ? latAndLong2.lat : secondLocation.lat,
          iso3: enableGoogleAutocomplete ? ' ' : secondLocation.iso3,
          city: enableGoogleAutocomplete ? selectedCity2 : secondLocation.city,
          state: enableGoogleAutocomplete ? selectedState2 : secondLocation.state,
          country: enableGoogleAutocomplete ? latAndLong2.country : latAndLong2?.country,
          address: latAndLong2.address,
        }
        regions.push(data)
      }

      if (selectedCity3 !== '') {
        let thirdLocation = await getLocationData(
          selectedRegion3,
          selectedState3,
          selectedCity3,
          latAndLong3?.lat,
          latAndLong3?.long,
          ' ',
        )
        let data = {
          region: enableGoogleAutocomplete ? selectedRegion3 : thirdLocation.region,
          long: enableGoogleAutocomplete ? latAndLong3.long : thirdLocation.long,
          lat: enableGoogleAutocomplete ? latAndLong3.lat : thirdLocation.lat,
          iso3: enableGoogleAutocomplete ? ' ' : thirdLocation.iso3,
          city: enableGoogleAutocomplete ? selectedCity3 : thirdLocation.city,
          state: enableGoogleAutocomplete ? selectedState3 : thirdLocation.state,
          country: enableGoogleAutocomplete ? latAndLong3.country : latAndLong3?.country,
          address: latAndLong3.address,
        }
        regions.push(data)
      }

      if (selectedCity4 !== '') {
        let forthLocation = await getLocationData(
          selectedRegion4,
          selectedState4,
          selectedCity4,
          latAndLong4?.lat,
          latAndLong4?.long,
          ' ',
        )
        let data = {
          region: enableGoogleAutocomplete ? selectedRegion4 : forthLocation.region,
          long: enableGoogleAutocomplete ? latAndLong4.long : forthLocation.long,
          lat: enableGoogleAutocomplete ? latAndLong4.lat : forthLocation.lat,
          iso3: enableGoogleAutocomplete ? ' ' : forthLocation.iso3,
          city: enableGoogleAutocomplete ? selectedCity4 : forthLocation.city,
          state: enableGoogleAutocomplete ? selectedState4 : forthLocation.state,
          country: enableGoogleAutocomplete ? latAndLong4.country : latAndLong4?.country,
          address: latAndLong4.address,
        }
        regions.push(data)
      }

      if (selectedCity5 !== '') {
        let fifthLocation = await getLocationData(
          selectedRegion5,
          selectedState5,
          selectedCity5,
          latAndLong5?.lat,
          latAndLong5?.long,
          ' ',
        )
        let data = {
          region: enableGoogleAutocomplete ? selectedRegion5 : fifthLocation.region,
          long: enableGoogleAutocomplete ? latAndLong5.long : fifthLocation.long,
          lat: enableGoogleAutocomplete ? latAndLong5.lat : fifthLocation.lat,
          iso3: enableGoogleAutocomplete ? ' ' : fifthLocation.iso3,
          city: enableGoogleAutocomplete ? selectedCity5 : fifthLocation.city,
          state: enableGoogleAutocomplete ? selectedState5 : fifthLocation.state,
          country: enableGoogleAutocomplete ? latAndLong5.country : latAndLong5?.country,
          address: latAndLong5.address,
        }
        regions.push(data)
      }

      if (selectedCity6 !== '') {
        let sixthLocation = await getLocationData(
          selectedRegion6,
          selectedState6,
          selectedCity6,
          latAndLong6?.lat,
          latAndLong6?.long,
          ' ',
        )
        let data = {
          region: enableGoogleAutocomplete ? selectedRegion6 : sixthLocation.region,
          long: enableGoogleAutocomplete ? latAndLong6.long : sixthLocation.long,
          lat: enableGoogleAutocomplete ? latAndLong6.lat : sixthLocation.lat,
          iso3: enableGoogleAutocomplete ? ' ' : sixthLocation.iso3,
          city: enableGoogleAutocomplete ? selectedCity6 : sixthLocation.city,
          state: enableGoogleAutocomplete ? selectedState6 : sixthLocation.state,
          country: enableGoogleAutocomplete ? latAndLong6.country : latAndLong6?.country,
          address: latAndLong6.address,
        }
        regions.push(data)
      }

      if (selectedRegion === 'Global') {
        let data = {
          region: '',
          long: '-',
          lat: '-',
          iso3: '-',
          city: '-',
          state: '-',
          country: 'Global',
        }
        regions.unshift(data)
      }

      if (
        selectedRegion2 === 'Global' ||
        selectedRegion3 === 'Global' ||
        selectedRegion4 === 'Global' ||
        selectedRegion5 === 'Global' ||
        selectedRegion6 === 'Global'
      ) {
        let data = {
          region: '',
          long: '-',
          lat: '-',
          iso3: '-',
          city: '-',
          state: '-',
          country: 'Global',
        }
        regions.push(data)
      }
      dispatch(
        changeRegionSettings({
          data: {
            region: selectedRegion === 'Global' ? '' : selectedRegion || 'CA',
            long:
              selectedRegion === 'Global'
                ? '-'
                : (enableGoogleAutocomplete ? latAndLong.long : firstLocation?.long) || '43.7417',
            lat:
              selectedRegion === 'Global'
                ? '-'
                : (enableGoogleAutocomplete ? latAndLong.lat : firstLocation?.lat) || '-79.3733',
            iso3:
              selectedRegion === 'Global'
                ? '-'
                : (enableGoogleAutocomplete ? ' ' : firstLocation?.iso3) || 'CAN',
            city: selectedRegion === 'Global' ? '-' : selectedCity,
            country: selectedRegion === 'Global' ? 'Global' : latAndLong.country,
            state: selectedRegion === 'Global' ? '-' : selectedState,
            url,
            website,
            regions,
          },
          loaderType: 'Regional',
        }),
      )
      setTimeout(() => {
        if (!settingsRegionLoader && regionLoaderType === '') {
          navigate(paths.keywordPositionTracker)
        }
      }, 2500)
    }
  }

  const getLocationData = (country, state, city, getLat, getLong, getIso3) => {
    let region = country || 'CA'
    let long = getLong || '43.7417'
    let lat = getLat || '-79.3733'
    let iso3 = getIso3 || 'CAN'

    let locationData =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === country && item.city === city && item.state === state
          })
        : []

    if (locationData && locationData.length) {
      region = locationData[0].countryShortCodeISO2
      long = locationData[0].long
      lat = locationData[0].lat
      iso3 = locationData[0].countryShortCodeISO3
    }

    return { region, long, lat, iso3, country, state, city }
  }

  const removeInbuiltKeyword = (keyword) => {
    let url = usersDomain[activeLink].items[0].url
    let website = usersDomain[activeLink].items[0].id
    setRemovedKeyword(keyword)
    dispatch(removeKeyword({ keyword, url, website }))
  }

  const handleLocationSelected = (place) => {
    switch (place.callFrom) {
      case 'primaryLocation':
        setSelectedRegion(place.region)
        setSelectedState(place.state)
        setSelectedCity(place.city)
        setLatAndLong({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location1':
        setSelectedRegion2(place.region)
        setSelectedState2(place.state)
        setSelectedCity2(place.city)
        setLatAndLong2({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location2':
        setSelectedRegion3(place.region)
        setSelectedState3(place.state)
        setSelectedCity3(place.city)
        setLatAndLong3({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location3':
        setSelectedRegion4(place.region)
        setSelectedState4(place.state)
        setSelectedCity4(place.city)
        setLatAndLong4({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location4':
        setSelectedRegion5(place.region)
        setSelectedState5(place.state)
        setSelectedCity5(place.city)
        setLatAndLong5({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      case 'location5':
        setSelectedRegion6(place.region)
        setSelectedState6(place.state)
        setSelectedCity6(place.city)
        setLatAndLong6({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
      default:
        setSelectedRegion(place.region)
        setSelectedState(place.state)
        setSelectedCity(place.city)
        setLatAndLong({ lat: place.lat, long: place.long, country: place.country, address: place.address })
        break
    }
  }

  const handleResetForm = () => {
      competitorFormik.resetForm()
      keywordFormik.resetForm()
  }

  const competitorSchema = Yup.object().shape({
    enteredCompetitor: Yup.string()
    .trim()
    .matches(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)*$/,'Invalid format')
  })
  
  const initialValues_for_competitor = {
    enteredCompetitor: ''
  }
 
  const competitorFormik = useFormik({
    initialValues: initialValues_for_competitor,
    validationSchema: competitorSchema,
    onSubmit: (values) => {
      if(activeSearchData?.competitors?.length === competitorThreshold){
        if(!values.enteredCompetitor){
          competitorFormik.setErrors({enteredCompetitor: 'This field is required'})
        }else{
          setOpenCompetitorAddingLimitExceed(true)
        }
      }else{
        checkCompetitorLimit()
      }
    },
  })
  
  const initialValues_for_keyword = {
    enteredKeyword: ''
  }
 
  const keywordFormik = useFormik({
    initialValues: initialValues_for_keyword,
    onSubmit: (values) => {
        if(!values.enteredKeyword){
          keywordFormik.setErrors({enteredKeyword: 'This field is required'})
        }else{
          dispatch(getKeywordLimit({keywords:[values.enteredKeyword], limitOption: LimitOption.KEYWORD}))
        }
      }
  })

  return (
    <Container fluid className="p-0">
      <Modal
        show={openCompetitorLimitExceed}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setOpenCompetitorLimitExceed(!openCompetitorLimitExceed)
          dispatch({ type: CLEAR_COMPETITOR_LIMIT })
          handleResetForm()
        }}
        onClose={() => {
          setOpenCompetitorLimitExceed(false)
          dispatch({ type: CLEAR_COMPETITOR_LIMIT })
          handleResetForm()
        }}
        onHide={() => {
          setOpenCompetitorLimitExceed(false)
          dispatch({ type: CLEAR_COMPETITOR_LIMIT })
          handleResetForm()
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15 pe-0"
      >
        <Modal.Header>
          <h3 className="mb-0 f-bold">Competitor Tracking Update</h3>
        </Modal.Header>
        <Modal.Body>
          You've added competitors for your tracked website(s) under your current plan. To ensure the highest
          quality of data and maintain service efficiency, competitor changes are locked in until the next
          billing cycle.
          <br />
          <br />
          Current Plan Competitor Limits: {competitorLimitData?.competitorsLimit}{' '}
          {competitorLimitData?.competitorsLimit === 1 ? 'competitor' : 'competitors'}
          <br />
          <br />
          Due to the extensive data analysis involved in real-time scanning of search engine results, each
          plan is designed with specific limitations. These are in place to provide all our users with a
          seamless experience, allowing us to deliver accurate and actionable SEO insights at a reasonable
          cost.
          <br />
          <br />
          Important:
          <br />
          <ul>
            <li>
              Once competitors are added for a website, they cannot be changed within the same billing cycle.
            </li>
            <li>
              To update your competitors, you will need to remove the current website from your tracking list
              and re-add it with new competitors in your next billing cycle.
            </li>
          </ul>
          We understand the dynamic nature of the digital landscape and strive to offer flexible solutions
          within these necessary constraints. For more extensive tracking needs, consider our{' '}
          {PLAN.PREMIUM_PLAN.name} Plan, which offers expanded competitor analysis among other advanced
          features.
          <br />
          <br />
          Thank you for choosing our services for your competitive analysis needs. We're here to support your
          SEO strategy with precision and reliability.
        </Modal.Body>
        <Modal.Footer
          className={`d-flex flex-row ${userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? 'justify-content-end' : 'justify-content-between'}`}
        >
          {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : (
            <Button
              color="primary"
              variant="primary"
              onClick={() => {
                navigate(paths.upgradePlan)
                setOpenCompetitorLimitExceed(false)
                dispatch({ type: CLEAR_COMPETITOR_LIMIT })
              }}
            >
              Upgrade your plan
            </Button>
          )}

          <Button
            color="danger"
            variant="danger"
            onClick={() => {
              setOpenCompetitorLimitExceed(false)
              dispatch({ type: CLEAR_COMPETITOR_LIMIT })
              handleResetForm()
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openCompetitorAddingLimitExceed}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setOpenCompetitorAddingLimitExceed(!openCompetitorAddingLimitExceed)
          handleResetForm()
        }}
        onClose={() => {
          setOpenCompetitorAddingLimitExceed(false)
          handleResetForm()
        }}
        onHide={() => {
          setOpenCompetitorAddingLimitExceed(false)
          handleResetForm()
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15 pe-0"
      >
        <Modal.Header>
          <h3 className="mb-0 f-bold">Competitor Tracking Update</h3>
        </Modal.Header>
        <Modal.Body>
          Your limit for adding competitors for your current plan is reached. Please delete one to add more
          competitors or upgrade your plan.
          <br />
          <br />
          Current Plan Competitor Limits: {competitorThreshold}{' '}
          {competitorThreshold === 1 ? 'competitor' : 'competitors'}
          <br />
          <br />
          Thank you for choosing our services for your competitive analysis needs. We're here to support your
          SEO strategy with precision and reliability.
        </Modal.Body>
        <Modal.Footer
          className={`d-flex flex-row ${userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? 'justify-content-end' : 'justify-content-between'}`}
        >
          {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : (
            <Button
              color="primary"
              variant="primary"
              onClick={() => {
                navigate(paths.upgradePlan)
                setOpenCompetitorAddingLimitExceed(false)
              }}
            >
              Upgrade your plan
            </Button>
          )}

          <Button
            color="danger"
            variant="danger"
            onClick={() => {
              setOpenCompetitorAddingLimitExceed(false)
              handleResetForm()
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openKeywordLimitExceed}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setOpenKeywordLimitExceed(!openKeywordLimitExceed)
          dispatch({ type: CLEAR_KEYWORD_LIMIT })
          handleResetForm()
        }}
        onClose={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({ type: CLEAR_KEYWORD_LIMIT })
          handleResetForm()
        }}
        onHide={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({ type: CLEAR_KEYWORD_LIMIT })
          handleResetForm()
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15 pe-0"
      >
        <Modal.Header>
          <h3 className="mb-0 f-bold">Keyword Tracking Limit Reached</h3>
        </Modal.Header>
        <Modal.Body>
          You've maximized the keyword tracking capabilities of your current plan. To continue expanding your
          insights and to add more keywords, an upgrade is necessary. Our plans are designed to scale with
          your growing needs, offering more keywords and location tracking options.
          <br />
          <br />
          Current Plan: {keywordLimitData?.keywordsLimit} Keywords
          <br />
          <br />
          Upgrade Now and unlock your full SEO potential.
          <br />
          <br />
          Thank you for using our services to enhance your digital marketing efforts. We're committed to
          supporting your growth every step of the way.
        </Modal.Body>
        <Modal.Footer
          className={`d-flex flex-row ${userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? 'justify-content-end' : 'justify-content-between'}`}
        >
          {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : (
            <Button
              color="primary"
              variant="primary"
              onClick={() => {
                navigate(paths.upgradePlan)
                setOpenKeywordLimitExceed(false)
                dispatch({ type: CLEAR_KEYWORD_LIMIT })
              }}
            >
              Upgrade your plan
            </Button>
          )}

          <Button
            color="danger"
            variant="danger"
            onClick={() => {
              setOpenKeywordLimitExceed(false)
              dispatch({ type: CLEAR_KEYWORD_LIMIT })
              handleResetForm()
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col lg="6" xl="4" className="d-flex">
          <h3 className="f-bold">Dashboard URL Settings</h3>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg="12" xl="12" className="d-flex">
          <Input
            type="select"
            id="exampleCustomSelect"
            name="customSelect"
            className="mb-3 py-3 text-lg"
            value={activeLink}
            onChange={(event) => {
              dispatch(changeActiveLink(event.target.value))
            }}
          >
            <option value="">Select the URL</option>
            {usersDomain &&
              usersDomain.length &&
              usersDomain.map((item, index) => {
                return (
                  <option value={index}>
                    {item && item.items && item.items.length && item.items[0].url}
                  </option>
                )
              })}
          </Input>
        </Col>
      </Row>
      <Card className="py-3 px-4 mb-0">
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1')
                  }}
                >
                  Competitors
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2')
                  }}
                >
                  Keywords
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggle('3')
                  }}
                >
                  Region
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    toggle('4')
                  }}
                >
                  Connections
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => {
                    toggle('5')
                  }}
                >
                  Remove URL
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="pl-3 pt-3">
                <Row>
                  <Col md="1" className="mb-3">
                    <Button
                      onClick={() => {
                        navigate(-1)

                        // }
                      }}
                    >
                      Go Back
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <Form onSubmit={competitorFormik.handleSubmit}>
                      <InputGroup className="mb-3">
                        <Input
                          name="enteredCompetitor"
                          id="enteredCompetitor"
                          className="py-3 text-lg"
                          placeholder="Add new competitor url"
                          onChange={competitorFormik.handleChange}
                          onBlur={competitorFormik.handleBlur}
                          value={competitorFormik.values.enteredCompetitor}
                        />
                        {settingsLoader || competitorLimitLoader ? (
                          <div className="align-self-center justify-content-center px-2">
                            <Spinner color="dark" className="ml-2 mr-2" />
                          </div>
                        ) : (
                          <>
                            {/* <InputGroupAddon addonType="prepend" color="primary"> */}
                            <Button className="py-3" onClick={competitorFormik.handleSubmit}>
                              <Plus />
                            </Button>
                          </>
                        )}
                        {competitorFormik.touched.enteredCompetitor &&
                        competitorFormik.errors.enteredCompetitor ? (
                          <div style={{ width: 'inherit', marginTop: '2px' }}>
                            <span className="fs-6" style={{ color: 'red' }}>
                              {competitorFormik.errors.enteredCompetitor}
                            </span>
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <h6 className="mb-3">Currently Added Competitors</h6>
                    {activeSearchData.competitors && activeSearchData.competitors.length ? (
                      activeSearchData.competitors.map((item, index) => {
                        return (
                          <>
                            <Row lg="12" className="border-bottom mx-1 pt-3">
                              <Col lg="1" md="1" sm="1" xs="1">
                                <p>{index + 1}</p>
                              </Col>
                              <Col lg="10" md="10" sm="10" xs="10">
                                <Link to={`http://${item}`} className={'text-dark'}>
                                  {item}
                                </Link>
                              </Col>
                              <Col className="d-flex justify-content-end" xs="1" sm="1" md="1">
                                <div className={'cursor-pointer'}>
                                  {removeCompetitorLoader && removedCompetitor === item ? (
                                    <div className="align-self-end justify-content-center">
                                      <Spinner color="dark" className="ml-2 mr-2" />
                                    </div>
                                  ) : (
                                    <Trash2
                                      onClick={() => {
                                        removeCompetitor(item)
                                      }}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </>
                        )
                      })
                    ) : (
                      <p>No competitors has been added</p>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="pl-1 pt-3">
                <Row>
                  <Col lg="12">
                    <Alert variant="primary" className="alert-outline-coloured" key="meta-title">
                      <div className="alert-icon" />
                      <div className="alert-message">
                        <span className="alert-for-mobile">
                          <p className="mb-0">
                            You are using{' '}
                            {availableKeywordLimit?.keywordsLimit -
                              availableKeywordLimit?.availableKeywordsLimit || 0}{' '}
                            out of {availableKeywordLimit?.keywordsLimit} maximum keywords limit for all your
                            websites. If you need more keywords. please consider to:&nbsp;
                            <Link
                              to={''}
                              onClick={() => dispatch(openUpgradePlanModal(true))}
                              className={'text-danger'}
                            >
                              Upgrade your plan
                            </Link>
                          </p>
                        </span>
                      </div>
                    </Alert>
                  </Col>
                </Row>

                <Row>
                  <Col md="1" className="mb-3">
                    <Button
                      onClick={() => {
                        navigate(-1)

                        // }
                      }}
                    >
                      Go Back
                    </Button>
                  </Col>
                  <Col className="mb-3">
                    <label className="custom-file-upload btn btn-primary" onChange={(e) => showFile(e)}>
                      <input type="file" />
                      Upload Keywords
                    </label>
                  </Col>

                  <br />
                  <Col lg="12">
                    <Form onSubmit={keywordFormik.handleSubmit}>
                      <InputGroup className="mb-3">
                        <Input
                          name="enteredKeyword"
                          id="enteredKeyword"
                          className="py-3 text-lg"
                          placeholder="Add Keyword"
                          value={keywordFormik.values.enteredKeyword}
                          onChange={keywordFormik.handleChange}
                          onBlur={keywordFormik.handleBlur}
                        />

                        {settingsAddKeywordLoader || keywordLimitLoader ? (
                          <div className="align-self-center justify-content-center px-2">
                            <Spinner color="dark" className="ml-2 mr-2" />
                          </div>
                        ) : (
                          <>
                            <Button className="py-3" onSubmit={keywordFormik.handleSubmit}>
                              <Plus />
                            </Button>
                          </>
                        )}

                        {keywordFormik.touched.enteredKeyword && keywordFormik.errors.enteredKeyword ? (
                          <div style={{ width: 'inherit', marginTop: '2px' }}>
                            <span className="fs-6" style={{ color: 'red' }}>
                              {keywordFormik.errors.enteredKeyword}
                            </span>
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <h6 className="mb-3">Custom Keywords</h6>
                    {activeSearchData.keywords && activeSearchData.keywords.length ? (
                      activeSearchData.keywords.map((item, index) => {
                        return (
                          <>
                            <Row lg="12" className="border-bottom mx-1 pt-3">
                              <Col lg="1" md="1" sm="1" xs="1">
                                <p>{index + 1}</p>
                              </Col>
                              <Col lg="10" md="10" sm="10" xs="10">
                                {item}
                              </Col>
                              <Col className="d-flex justify-content-end" xs="1" sm="1" md="1">
                                <div className={'cursor-pointer'}>
                                  {(removeLoading || availableKeywordLimitLoader) &&
                                  removedKeyword === item ? (
                                    <div className="align-self-end justify-content-center">
                                      <Spinner color="dark" className="ml-2 mr-2" />
                                    </div>
                                  ) : (
                                    <Trash2
                                      onClick={() => {
                                        removeInbuiltKeyword(item)
                                      }}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </>
                        )
                      })
                    ) : (
                      <p>No Keywords has been added</p>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3" className="pl-1 pt-3">
                <Row>
                  <Col lg="12">
                    <h4>Primary Location</h4>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Navb variant="tabs" className="w-100">
                        <Navb.Item>
                          <Navb.Link eventKey="first" className="d-flex flex-row align-items-center">
                            {selectedRegion !== '' && selectedState !== '' && selectedCity !== ''
                              ? selectedRegion === 'Global'
                                ? 'Global'
                                : `${
                                    selectedCity?.trim()?.length > 0 ? selectedCity + ',' : ''
                                  } ${selectedState} ${
                                    enableGoogleAutocomplete
                                      ? selectedRegion
                                      : getAbbr(selectedRegion, locations)
                                  }`
                              : 'Location 1'}{' '}
                            &nbsp;
                            {latAndLong?.address?.length ? (
                              <OverlayTrigger
                                placement={'right'}
                                overlay={<Tooltip>{latAndLong?.address}</Tooltip>}
                              >
                                <AlertCircle size="16" color="#afafaf" />
                              </OverlayTrigger>
                            ) : null}
                          </Navb.Link>
                        </Navb.Item>
                      </Navb>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <br />
                          <h6>
                            Enter your location to enable PMS SEO Site Signals tracking&nbsp;
                            <OverlayTrigger
                              placement={'right'}
                              overlay={
                                <Tooltip>
                                  Based on this address, we'll automatically select the regional keyword
                                  database. This ensures the most accurate keyword rank tracking for your
                                  specified location.
                                </Tooltip>
                              }
                            >
                              <AlertCircle size="16" color="#afafaf" />
                            </OverlayTrigger>
                          </h6>
                          <GoogleAutoLocation
                            handleLocationSelected={handleLocationSelected}
                            callFrom="primaryLocation"
                          />
                          <br />
                          <br />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                    <br />
                    <h4>Additional Locations</h4>

                    <Tab.Container id="left-tabs-example-1" defaultActiveKey="first">
                      <Navb variant="tabs">
                        <Navb.Item>
                          <Navb.Link eventKey="first" className="d-flex flex-row align-items-center">
                            {selectedRegion2 !== '' && selectedState2 !== '' && selectedCity2 !== ''
                              ? selectedRegion2 === 'Global'
                                ? 'Global'
                                : `${
                                    selectedCity2?.trim()?.length > 0 ? selectedCity2 + ',' : ''
                                  } ${selectedState2} ${
                                    enableGoogleAutocomplete
                                      ? selectedRegion2
                                      : getAbbr(selectedRegion2, locations)
                                  }`
                              : 'Location 1'}
                            &nbsp;
                            {latAndLong2?.address?.length ? (
                              <OverlayTrigger
                                placement={'right'}
                                overlay={<Tooltip>{latAndLong2?.address}</Tooltip>}
                              >
                                <AlertCircle size="16" color="#afafaf" />
                              </OverlayTrigger>
                            ) : null}
                          </Navb.Link>
                        </Navb.Item>
                        {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Navb.Item>
                              <Navb.Link eventKey="second" className="d-flex flex-row align-items-center">
                                {selectedRegion3 !== '' && selectedState3 !== '' && selectedCity3 !== ''
                                  ? selectedRegion3 === 'Global'
                                    ? 'Global'
                                    : `${
                                        selectedCity3?.trim()?.length > 0 ? selectedCity3 + ',' : ''
                                      } ${selectedState3} ${
                                        enableGoogleAutocomplete
                                          ? selectedRegion3
                                          : getAbbr(selectedRegion3, locations)
                                      }`
                                  : 'Location 2'}
                                &nbsp;
                                {latAndLong3?.address?.length ? (
                                  <OverlayTrigger
                                    placement={'right'}
                                    overlay={<Tooltip>{latAndLong3?.address}</Tooltip>}
                                  >
                                    <AlertCircle size="16" color="#afafaf" />
                                  </OverlayTrigger>
                                ) : null}
                              </Navb.Link>
                            </Navb.Item>
                          </>
                        ) : (
                          <></>
                        )}
                        {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Navb.Item>
                              <Navb.Link eventKey="third" className="d-flex flex-row align-items-center">
                                {selectedRegion4 !== '' && selectedState4 !== '' && selectedCity4 !== ''
                                  ? selectedRegion4 === 'Global'
                                    ? 'Global'
                                    : `${
                                        selectedCity4?.trim()?.length > 0 ? selectedCity4 + ',' : ''
                                      } ${selectedState4} ${
                                        enableGoogleAutocomplete
                                          ? selectedRegion4
                                          : getAbbr(selectedRegion4, locations)
                                      }`
                                  : 'Location 3'}
                                &nbsp;
                                {latAndLong4?.address?.length ? (
                                  <OverlayTrigger
                                    placement={'right'}
                                    overlay={<Tooltip>{latAndLong4?.address}</Tooltip>}
                                  >
                                    <AlertCircle size="16" color="#afafaf" />
                                  </OverlayTrigger>
                                ) : null}
                              </Navb.Link>
                            </Navb.Item>
                          </>
                        ) : (
                          <></>
                        )}
                        {planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Navb.Item>
                              <Navb.Link eventKey="four" className="d-flex flex-row align-items-center">
                                {selectedRegion5 !== '' && selectedState5 !== '' && selectedCity5 !== ''
                                  ? selectedRegion5 === 'Global'
                                    ? 'Global'
                                    : `${
                                        selectedCity5?.trim()?.length > 0 ? selectedCity5 + ',' : ''
                                      } ${selectedState5} ${
                                        enableGoogleAutocomplete
                                          ? selectedRegion5
                                          : getAbbr(selectedRegion5, locations)
                                      }`
                                  : 'Location 4'}
                                &nbsp;
                                {latAndLong5?.address?.length ? (
                                  <OverlayTrigger
                                    placement={'right'}
                                    overlay={<Tooltip>{latAndLong5?.address}</Tooltip>}
                                  >
                                    <AlertCircle size="16" color="#afafaf" />
                                  </OverlayTrigger>
                                ) : null}
                              </Navb.Link>
                            </Navb.Item>
                          </>
                        ) : (
                          <></>
                        )}
                        {planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Navb.Item>
                              <Navb.Link eventKey="five" className="d-flex flex-row align-items-center">
                                {selectedRegion6 !== '' && selectedState6 !== '' && selectedCity6 !== ''
                                  ? selectedRegion6 === 'Global'
                                    ? 'Global'
                                    : `${
                                        selectedCity6?.trim()?.length > 0 ? selectedCity6 + ',' : ''
                                      } ${selectedState6} ${
                                        enableGoogleAutocomplete
                                          ? selectedRegion6
                                          : getAbbr(selectedRegion6, locations)
                                      }`
                                  : 'Location 5'}
                                &nbsp;
                                {latAndLong6?.address?.length ? (
                                  <OverlayTrigger
                                    placement={'right'}
                                    overlay={<Tooltip>{latAndLong6?.address}</Tooltip>}
                                  >
                                    <AlertCircle size="16" color="#afafaf" />
                                  </OverlayTrigger>
                                ) : null}
                              </Navb.Link>
                            </Navb.Item>
                          </>
                        ) : (
                          <></>
                        )}
                      </Navb>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <h6 className="mt-2">
                            Enter your location to enable PMS SEO Site Signals tracking{' '}
                            <OverlayTrigger
                              placement={'right'}
                              overlay={
                                <Tooltip>
                                  Based on this address, we'll automatically select the regional keyword
                                  database. This ensures the most accurate keyword rank tracking for your
                                  specified location.
                                </Tooltip>
                              }
                            >
                              <AlertCircle size="16" color="#afafaf" />
                            </OverlayTrigger>
                          </h6>
                          <GoogleAutoLocation
                            handleLocationSelected={handleLocationSelected}
                            callFrom="location1"
                          />
                          <br />
                          <br />
                        </Tab.Pane>
                        {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Tab.Pane eventKey="second">
                              <h6 className="mt-2">
                                Enter your location to enable PMS SEO Site Signals tracking{' '}
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={
                                    <Tooltip>
                                      Based on this address, we'll automatically select the regional keyword
                                      database. This ensures the most accurate keyword rank tracking for your
                                      specified location.
                                    </Tooltip>
                                  }
                                >
                                  <AlertCircle size="16" color="#afafaf" />
                                </OverlayTrigger>
                              </h6>
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location2"
                              />
                              <br />
                              <br />
                            </Tab.Pane>
                          </>
                        ) : (
                          <></>
                        )}
                        {planName === PLAN.PRO_PLAN.name || planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Tab.Pane eventKey="third">
                              <h6 className="mt-2">
                                Enter your location to enable PMS SEO Site Signals tracking{' '}
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={
                                    <Tooltip>
                                      Based on this address, we'll automatically select the regional keyword
                                      database. This ensures the most accurate keyword rank tracking for your
                                      specified location.
                                    </Tooltip>
                                  }
                                >
                                  <AlertCircle size="16" color="#afafaf" />
                                </OverlayTrigger>
                              </h6>
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location3"
                              />
                              <br />
                              <br />
                            </Tab.Pane>
                          </>
                        ) : (
                          <></>
                        )}
                        {planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Tab.Pane eventKey="four">
                              <h6 className="mt-2">
                                Enter your location to enable PMS SEO Site Signals tracking{' '}
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={
                                    <Tooltip>
                                      Based on this address, we'll automatically select the regional keyword
                                      database. This ensures the most accurate keyword rank tracking for your
                                      specified location.
                                    </Tooltip>
                                  }
                                >
                                  <AlertCircle size="16" color="#afafaf" />
                                </OverlayTrigger>
                              </h6>
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location4"
                              />
                              <br />
                              <br />
                            </Tab.Pane>
                          </>
                        ) : (
                          <></>
                        )}
                        {planName === PLAN.PREMIUM_PLAN.name ? (
                          <>
                            <Tab.Pane eventKey="five">
                              <h6 className="mt-2">
                                Enter your location to enable PMS SEO Site Signals tracking{' '}
                                <OverlayTrigger
                                  placement={'right'}
                                  overlay={
                                    <Tooltip>
                                      Based on this address, we'll automatically select the regional keyword
                                      database. This ensures the most accurate keyword rank tracking for your
                                      specified location.
                                    </Tooltip>
                                  }
                                >
                                  <AlertCircle size="16" color="#afafaf" />
                                </OverlayTrigger>
                              </h6>
                              <GoogleAutoLocation
                                handleLocationSelected={handleLocationSelected}
                                callFrom="location5"
                              />
                              <br />
                              <br />
                            </Tab.Pane>
                          </>
                        ) : (
                          <></>
                        )}
                      </Tab.Content>
                    </Tab.Container>
                    <br />
                    <br />
                    {settingsRegionLoader && regionLoaderType === 'Regional' ? (
                      <div className="align-self-center justify-content-center px-2">
                        <Spinner color="dark" className="ml-2 mr-2" />
                      </div>
                    ) : (
                        <Button
                          className="py-3"
                          onClick={() => changeRegion()}
                          disabled={
                            !(Boolean(selectedRegion) && Boolean(selectedState) && Boolean(selectedCity))
                          }
                        >
                          Apply
                        </Button>
                    )}
                  </Col>
                </Row>
              </TabPane>
                <TabPane tabId="4" className="pl-1 pt-3">
              { !analyticsDataLoader && activeSearchData && Object.keys(activeSearchData)?.length ? (
                   !tokenData?.refreshToken ? (
                    <>
                      <p>Let's Connect Your PMS Account With Your Google Account Data</p>
                      
                      <GoogleAuth>
                        <GoogleButton url={usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url} />
                      </GoogleAuth>
                    </>
                  ) : (
                    <GoogleAnalyticsModal />
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      alignSelf: 'center',
                      flexDirection: 'row',
                      minHeight: 380,
                    }}
                  >
                    <div className="text-center">
                      <Loader />
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane tabId="5" className="pl-1 pt-3">
                <Alert variant="danger" className="alert-outline-danger border" dismissible>
                  <div className="alert-message">
                    <h4 className="alert-heading alert-for-mobile f-bold">
                      Warning! By deleting URL you will not be able to:
                    </h4>
                    <ul className="alert-for-mobile">
                      <li>Receive weekly emails regarding website's performance</li>
                      <li>Monitor the loading time of {usersDomain?.[activeLink]?.items[0].url}</li>
                      <li>Monitor the uptime of {usersDomain?.[activeLink]?.items[0].url}</li>
                      <li>Monitor the backlinks of {usersDomain?.[activeLink]?.items[0].url}</li>
                      <li>Check the top keywords of {usersDomain?.[activeLink]?.items[0].url}</li>
                    </ul>
                  </div>
                </Alert>
                <div className="btn-list">
                  <Button
                    color="danger"
                    variant="danger"
                    onClick={() => {
                      let data = {
                        userId,
                        domainId: usersDomain?.[activeLink]?.items[0].id,
                        url: usersDomain?.[activeLink]?.items[0].url,
                      }
                      if (usersDomain.length === 1) {
                        !loading && dispatch(deleteWebsite(data))
                      } else {
                        !loading && dispatch(deleteWebsite(data))
                      }
                    }}
                  >
                    {loading ? <Spinner size="sm" color="dark" className="ml-2 mr-2" /> : 'Remove URL'}
                  </Button>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Card>

      <Row className="mb-3" />
    </Container>
  )
}

export default SettingsComponent

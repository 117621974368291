import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Tab, Nav, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, UncontrolledAlert } from 'reactstrap'

import {
  getUserData,
  changeActiveLink,
  patchUserDomainsDashboard,
  setGraphPayload,
} from 'redux/actions/dashboard/index.js'
import { storeUserData, verifyTokenStart } from 'redux/actions/register/index.js'

import SpeedGraph from 'pages/dashboards/SpeedGraph.js'
import paths from 'constants/paths'
import MainUrlNav from 'components/MainUrlNav'
import { getActiveUserData } from 'constants/Utils'
import { Loader } from 'components/uiCore'
import { Info } from 'react-feather'

const SettingsComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    usersDomains: usersDomain,
    loading,
    activeLink,
    userData,
    speedGraphLoader,
    speedGraphSuccess,
    dashboardGraphData,
  } = useSelector(({ dashboard }) => dashboard)

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  const [currentGraphDateRange, setCurrentGraphDateRange] = useState('last7days')

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loading) {
      navigate(paths.mainDashboard)
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const init = async () => {
    const data = localStorage.getItem('userData')
    dispatch(verifyTokenStart())
    if (data && JSON.parse(data)._id) {
      dispatch(storeUserData(JSON.parse(data)))
      dispatch(patchUserDomainsDashboard())
      dispatch(getUserData())
      dispatch(setGraphPayload({ dateRange: 'last7days' }))
    } else {
      navigate('/')
    }
  }

  const handleGraphData = (data) => {
    switch (data) {
      case 'last24hrs':
        dispatch(setGraphPayload({ dateRange: data }))
        setCurrentGraphDateRange(data)
        break
      case 'last7days':
        dispatch(setGraphPayload({ dateRange: data }))
        setCurrentGraphDateRange(data)
        break
      case 'last30days':
        dispatch(setGraphPayload({ dateRange: data }))
        setCurrentGraphDateRange(data)
        break
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <Col>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={activeLink || 0}
            activeKey={activeLink || 0}
          >
            <Nav variant="tabs" onSelect={(selectedKey) => dispatch(changeActiveLink(parseInt(selectedKey)))}>
              {usersDomain && usersDomain.length ? (
                <>
                  {usersDomain?.map((item, index) => {
                    return <MainUrlNav key={index + 1} item={item} index={index} />
                  })}
                </>
              ) : (
                <></>
              )}
            </Nav>
            <Tab.Content>
              {usersDomain && usersDomain.length ? (
                <>
                  {usersDomain.map((item, index) => (
                    <Tab.Pane eventKey={index} className={'mt-3'} key={`tab-${index + 1}`}>
                      <Row>
                        <Col lg="12">
                          <Card>
                            <CardHeader className="align-items-center">
                              <CardTitle className="mb-0">
                                <h3 className="f-bold">
                                  Site Speed overview
                                  <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        Please note that we scan websites daily, so a variation in loading
                                        speed is normal due to regular updates and changes.
                                      </Tooltip>
                                    }
                                  >
                                    <Info style={{ marginLeft: '0.5rem' }} size="16" color="#afafaf" />
                                  </OverlayTrigger>
                                </h3>
                              </CardTitle>
                              <p className="mb-0">
                                {item && item?.items && item?.items?.length && item?.items?.[0]?.url
                                  ? item?.items?.[0]?.url
                                  : ''}
                              </p>
                              <hr className="mb-0" />
                            </CardHeader>
                            <CardBody>
                              {!speedGraphLoader && dashboardGraphData?.length ? (
                                <>
                                  <div>
                                    <SpeedGraph
                                      graphData={dashboardGraphData}
                                      activeSearchData={activeSearchData}
                                    />
                                  </div>
                                  <div className="d-flex flex-row">
                                    <Button
                                      variant={`${currentGraphDateRange === 'last24hrs' ? 'primary' : 'outline-primary'}`}
                                      className="mr-1 py-2 px-4 fs-4"
                                      onClick={() => handleGraphData('last24hrs')}
                                    >
                                      Last 24 hours
                                    </Button>

                                    <Button
                                      variant={`${currentGraphDateRange === 'last7days' ? 'primary' : 'outline-primary'}`}
                                      className="mr-1 py-2 px-4 fs-4"
                                      onClick={() => handleGraphData('last7days')}
                                    >
                                      Last 7 days
                                    </Button>

                                    <Button
                                      variant={`${currentGraphDateRange === 'last30days' ? 'primary' : 'outline-primary'}`}
                                      className="mr-1 py-2 px-4 fs-4"
                                      onClick={() => handleGraphData('last30days')}
                                    >
                                      Last 30 days
                                    </Button>
                                  </div>
                                </>
                              ) : !speedGraphLoader &&
                                speedGraphSuccess &&
                                dashboardGraphData?.length === 0 ? (
                                <Row>
                                  <Col lg="12">
                                    <UncontrolledAlert color="info alert">
                                      <div className="alert-message">
                                        <p className="info">No data for this url</p>
                                      </div>
                                    </UncontrolledAlert>
                                  </Col>
                                  <div className="d-flex flex-row">
                                    <Button
                                      variant={`${currentGraphDateRange === 'last24hrs' ? 'primary' : 'outline-primary'}`}
                                      className="mr-1 py-2 px-4 fs-4"
                                      onClick={() => handleGraphData('last24hrs')}
                                    >
                                      Last 24 hours
                                    </Button>

                                    <Button
                                      variant={`${currentGraphDateRange === 'last7days' ? 'primary' : 'outline-primary'}`}
                                      className="mr-1 py-2 px-4 fs-4"
                                      onClick={() => handleGraphData('last7days')}
                                    >
                                      Last 7 days
                                    </Button>

                                    <Button
                                      variant={`${currentGraphDateRange === 'last30days' ? 'primary' : 'outline-primary'}`}
                                      className="mr-1 py-2 px-4 fs-4"
                                      onClick={() => handleGraphData('last30days')}
                                    >
                                      Last 30 days
                                    </Button>
                                  </div>
                                </Row>
                              ) : speedGraphLoader ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    minHeight: 380,
                                  }}
                                >
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </div>
                              ) : null}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  ))}
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    alignSelf: 'center',
                    flexDirection: 'row',
                    minHeight: 380,
                  }}
                >
                  <div className="text-center">
                    <Loader />
                  </div>
                </div>
              )}
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  )
}

export default SettingsComponent

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { CommonAlert } from 'components/uiCore'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const UnsafeCrossOriginLinks = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [unsafeCrossOriginLinksFlag, setUnsafeCrossOriginLinksFlag] = useState(false)

  const unsafe_cross_origin_column = [
    {
      id:"unsafeCrossOriginLink",
      displayName:"Unsafe Cross Origin Links"
    }
  ]

  const unsafeCrossOriginCSV = data?.unsafeCrossOriginLinksTest?.data?.unsafeCrossOriginList?.list?.map((item) => {
    return {
      unsafeCrossOriginLink: item?.snippet
    }
  })
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.unsafeCrossOriginLinksTest?.data?.testResults?.status ? (
            ''
          ) : data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 4 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        {data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 4 ? (
          <CommonAlert
            variant={'success'}
            key={'meta-title'}
            alertText={
              <div>
                <span>{data?.unsafeCrossOriginLinksTest?.data?.testResults?.cachedMessage}</span>
                <Link to="/pages/firewall-options" style={{ textDecoration: 'underline' }} target="_blank">
                  click here
                </Link>
              </div>
            }
          />
        ) : (
          <></>
        )}
        <p> {data.unsafeCrossOriginLinksTest?.data?.testResults?.message} </p>

        {showDetail &&
        data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 3 &&
        !data?.unsafeCrossOriginLinksTest?.data?.testResults?.message?.includes(
          "We have detected a problem with your server's security configuration",
        ) ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setUnsafeCrossOriginLinksFlag(!unsafeCrossOriginLinksFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={unsafeCrossOriginLinksFlag}
        toggleShow={() => setUnsafeCrossOriginLinksFlag(!unsafeCrossOriginLinksFlag)}
        title={`Full list of links using target='_blank' without rel='noopener' or rel='noreferrer' attribute`}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '600', fontSize: '22px' }}
        showDownload
        datas={unsafeCrossOriginCSV}
        columns={unsafe_cross_origin_column}
        fileName='Unsafe Cross-Origin Signal Analysis'
      >
        <ul>
          {data?.unsafeCrossOriginLinksTest?.data?.unsafeCrossOriginList?.list?.length ? (
            data?.unsafeCrossOriginLinksTest?.data?.unsafeCrossOriginList?.list.map((item) => {
              return <li>{item?.snippet}</li>
            })
          ) : (
            <li>No Link found.</li>
          )}
        </ul>
      </ModalHOC>
    </>
  )
}

export default UnsafeCrossOriginLinks

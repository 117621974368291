import {
  BACKLINKS_CHECK_START,
  GET_BACKLINKS,
  GET_BACKLINKS_STATS,
  GET_USER_DOMAINS,
  CHANGE_ACTIVE_LINK,
  DELETE_WEBSITE,
  GET_USER_DATA,
  REMOVE_KEYWORD,
  PATCH_USER_DOMAINS_DASHBOARD,
  CLEAR_DOMAINS,
  GET_SITEOUTAGE_DATA,
  ADD_WEBSITE_FROM_TRIAL,
  GET_BACKLINKS_GRAPH,
  GET_BACKLINKS_MOST_RECENTLY,
  GET_BACKLINKS_MOST_IMPORTANT,
  GET_BACKLINKS_REFERRING_DOMAIN,
  GET_BACKLINKS_ANCHOR_TEXT,
  GET_BACKLINKS_TLD_DISTRIBUTIONS,
  GET_REGIONAL_LOCATION_CONFIGURATION,
  GET_COMPETITOR_SEO_SCORE,
  MAIN_URL_SEO_SCORE,
  WHITELIST_BROKEN_URL,
  CLEAR_STOP_DASHBOARD_PATCH_API_CALLS,
  CLEAR_STOP_BACKLINKS_STATS_API_CALL,
  ADD_EVERYWHERE_KEYWORDS,
  GET_UPDATED_KEYWORD_RESEARCH,
  KEYWORDS_RESEARCH_DATA,
  REMOVE_COMPETITORS,
  URL_SCAN_STATUS,
  GET_URL_SCAN_STATUS,
  KEYWORD_POSITION_TRACKING,
  PERSONAL_INFO_UPDATE,
  DELETE_USER,
  OPEN_UPGRADE_PLAN_MODAL,
  GET_LIGHTHOUSE_QUICK_SEO,
  WEBSITE_LIMIT,
  KEYWORD_LIMIT,
  COMPETITOR_LIMIT,
  QUICK_SCAN_LIMIT,
  AVAILABLE_KEYWORD_LIMIT,
  GET_TEST_RESULTS,
  NEW_NOTIFICATION,
  READ_SINGLE_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  GET_LOST_BACKLINKS,
  GET_AUTH_URL,
  GENERATE_RESEARCH,
  GOOGLE_ANALYTICS_MODAL,
  ANALYTICS_DATA,
  DISCONNECT_GOOGLE,
  STORE_ANALYTICS_DATA,
  GET_ANALYTICS_DATA,
  GET_AUTH_TOKEN,
  CONTACT_US,
  SPEED_GRAPH_CHART_DATA_REQUEST,
  GENERATE_TICKET
} from 'redux/actionType.js'

export const getBacklinks = (data) => ({
  type: GET_BACKLINKS,
  payload: data,
})

export const getBacklinksStats = () => ({
  type: GET_BACKLINKS_STATS,
})

export const getDashboardBacklinks = (data) => ({
  type: BACKLINKS_CHECK_START,
  payload: data,
})

export const addWebsiteFromTrial = (data) => ({
  type: ADD_WEBSITE_FROM_TRIAL,
  payload: data,
})

export const getUserDomains = (data) => ({
  type: GET_USER_DOMAINS,
  payload: data,
})

export const patchUserDomainsDashboard = () => ({
  type: PATCH_USER_DOMAINS_DASHBOARD
})

export const getSiteOutageData = () => ({
  type: GET_SITEOUTAGE_DATA,
})

export const changeActiveLink = (data) => ({
  type: CHANGE_ACTIVE_LINK,
  payload: data,
})

export const deleteWebsite = (data) => ({
  type: DELETE_WEBSITE,
  payload: data,
})

export const setGraphPayload = (data) => ({
  type: SPEED_GRAPH_CHART_DATA_REQUEST,
  payload: data,
})

export const getUserData = () => ({
  type: GET_USER_DATA,
})

export const removeKeyword = (data) => ({
  type: REMOVE_KEYWORD,
  data,
})

export const clearDomains = () => ({
  type: CLEAR_DOMAINS,
})

export const getBacklinksGraph = (data) => ({
  type: GET_BACKLINKS_GRAPH,
  payload: data,
})

export const getBacklinksMostRecently = (data) => ({
  type: GET_BACKLINKS_MOST_RECENTLY,
  payload: data,
})

export const getBacklinksMostImportant = (data) => ({
  type: GET_BACKLINKS_MOST_IMPORTANT,
  payload: data,
})

export const getBacklinksReferringDomain = (data) => ({
  type: GET_BACKLINKS_REFERRING_DOMAIN,
  payload: data,
})

export const getBacklinksAnchorText = (data) => ({
  type: GET_BACKLINKS_ANCHOR_TEXT,
  payload: data,
})

export const getBacklinksTldDistribution = (data) => ({
  type: GET_BACKLINKS_TLD_DISTRIBUTIONS,
  payload: data,
})

export const saveRegionalLocation = (data) => ({
  type: GET_REGIONAL_LOCATION_CONFIGURATION,
  payload: data,
})

export const getCompetitorSeoScore = (data) => ({
  type: GET_COMPETITOR_SEO_SCORE,
  payload: data,
})

export const getMainUrlSeoScore = (data) => ({
  type: MAIN_URL_SEO_SCORE,
  payload: data,
})

export const whitelistBrokenUrl = (data) => ({
  type: WHITELIST_BROKEN_URL,
  payload: data,
})

export const clearTrackDashboardPatchApicallStatus = (data) => ({
  type: CLEAR_STOP_DASHBOARD_PATCH_API_CALLS,
  payload: data,
})

export const clearTrackBacklinkStatsAPICallStatus = (data) => ({
  type: CLEAR_STOP_BACKLINKS_STATS_API_CALL,
  payload: data,
})

export const addKeywordEverywhere = (data) => ({
  type: ADD_EVERYWHERE_KEYWORDS,
  data,
})

export const keywordResearch = (data) => ({
  type: KEYWORDS_RESEARCH_DATA,
  data,
})

export const getUpdatedKeywordsResearch = (data) => ({
  type: GET_UPDATED_KEYWORD_RESEARCH,
  payload: data,
})

export const removeCompetitors = (data) => ({
  type: REMOVE_COMPETITORS,
  payload: data
})

export const urlScanStatus = (data) => ({
  type: URL_SCAN_STATUS,
  payload: data
})

export const geturlScanStatus = () => ({
  type: GET_URL_SCAN_STATUS,
})

export const personalInfoUpdate = (data) => ({
  type: PERSONAL_INFO_UPDATE,
  payload: data
})

export const deleteUser = () => ({
  type: DELETE_USER
})

export const keywordPositionTracking = (data) => ({
  type: KEYWORD_POSITION_TRACKING,
  payload: data
})

export function openUpgradePlanModal(payload) {
  return {
    type: OPEN_UPGRADE_PLAN_MODAL,
    payload
  }
}

export const getLighthouseForQuickSeo = (data) => ({
  type: GET_LIGHTHOUSE_QUICK_SEO,
  payload: data
})

export const getWebsiteLimit = (data) => ({
  type: WEBSITE_LIMIT,
  payload: data
})

export const getKeywordLimit = (data) => ({
  type: KEYWORD_LIMIT,
  payload: data
})

export const getCompetitorLimit = (data) => ({
  type: COMPETITOR_LIMIT,
  payload: data
})

export const getQuickScanLimit = (data) => ({
  type: QUICK_SCAN_LIMIT,
  payload: data
})

export const getAvailableKeywordLimit = () => ({
  type: AVAILABLE_KEYWORD_LIMIT,
})

export const getTestResults = () => ({
  type: GET_TEST_RESULTS,
})

export const getNotifications = () => ({
  type: NEW_NOTIFICATION,
})

export const readSingleNotifications = (data) => ({
  type: READ_SINGLE_NOTIFICATION,
  payload: data
})

export const readAllNotifications = () => ({
  type: READ_ALL_NOTIFICATION,
})

export const getLostBacklinks = (data) => ({
  type: GET_LOST_BACKLINKS,
  payload: data
})

export const googleAnalyticsModal = (data) => ({
  type: GOOGLE_ANALYTICS_MODAL,
  payload: data
})

export const getAuthUrl = (data) => ({
  type: GET_AUTH_URL,
  payload: data
})

export const getAuthToken = (data) => ({
  type: GET_AUTH_TOKEN,
  payload: data
})

export const generateResearch = (data) => ({
  type: GENERATE_RESEARCH,
  payload: data
})

export const getAnalyticsAccountData = (data) => ({
  type: ANALYTICS_DATA,
  payload: data
})

export const disconnectGoogle = (data) => ({
  type: DISCONNECT_GOOGLE,
  payload: data
})

export const saveAnalyticsData = (data) => ({
  type: STORE_ANALYTICS_DATA,
  payload: data
})

export const getAnalyticsData = (data) => ({
  type: GET_ANALYTICS_DATA,
  payload: data
})

export const contactUs = (data) => ({
  type: CONTACT_US,
  payload: data
})

export const generateTicket = (data) => ({
  type: GENERATE_TICKET,
  payload: data
})
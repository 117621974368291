import React from 'react'
import { Card, Row, Col, ProgressBar, Accordion, Container } from 'react-bootstrap'

const SEOPieChart = ({
  isQuickCheck,
  totalPercent,
  showDetails,
  positiveCounter,
  warningCounter,
  negativeCounter,
  positiveCounter1,
  warningCounter1,
  negativeCounter1,
  competitor1,
  testType
}) => {
  
  const countPercent = (count) => {
    if (testType === "ALL") {
      const percent = (count * 100) / 68
      return percent.toFixed(1)
    } else {
      const percent = (count * 100)
      return percent
    }
  }
  return (
    <Card>
      <Card.Body>
        {isQuickCheck && totalPercent < 100 ? (
          <>
            <p> Signal Scan Loading</p>
            <ProgressBar
              striped
              style={{ borderRadius: 0 }}
              animated={totalPercent < 100 ? true : false}
              variant="primary"
              now={totalPercent > 100 ? 100 : totalPercent}
              label={`${totalPercent > 100 ? 100 : totalPercent.toFixed(0)}%`}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Row>
          <Col>
            <Row>
              <Col sm="12" md="4">
                <p>
                  {' '}
                  {positiveCounter || 0} Passed / {countPercent(positiveCounter)}%
                </p>
                <ProgressBar className="mb-3" variant="success" now={positiveCounter || 0} max={testType === "ALL" ? 68 : positiveCounter} label="" />
              </Col>

              <Col sm="12" md="4">
                <p>
                  {warningCounter || 0} Warning / {countPercent(warningCounter)}%
                </p>
                <ProgressBar className="mb-3" variant="warning" now={warningCounter || 0} max={testType === "ALL" ? 68 : warningCounter} label="" />
              </Col>

              <Col sm="12" md="4">
                <p>
                  {negativeCounter || 0} Failed / {countPercent(negativeCounter)}%
                </p>
                <ProgressBar className="mb-3" variant="danger" now={negativeCounter || 0} max={testType === "ALL" ? 68 : negativeCounter} label="" />
              </Col>
            </Row>
          </Col>
        </Row>
        {showDetails && competitor1?.length ? (
          <>
            <hr />
            <h4 className="f-bold">Competitors</h4>
            <Row>
              <Accordion defaultActiveKey="6">
                {competitor1?.length ? (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col>{competitor1}</Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <Accordion.Body className="bg-white">
                      <Col>
                        <Row>
                          <Col>
                            <p>
                              {' '}
                              {positiveCounter1 || 0} Passed /{' '}
                              {countPercent(positiveCounter1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="success"
                              now={positiveCounter1 || 0}
                              max={testType === "ALL" ? 68 : positiveCounter1}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {warningCounter1 || 0} Warning /{' '}
                              {countPercent(warningCounter1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="warning"
                              now={warningCounter1 || 0}
                              max={testType === "ALL" ? 68 : warningCounter1}
                              label=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {negativeCounter1 || 0} Failed /{' '}
                              {countPercent(negativeCounter1)}%
                            </p>
                            <ProgressBar
                              className="mb-3"
                              variant="danger"
                              now={negativeCounter1 || 0}
                              max={testType === "ALL" ? 68 : negativeCounter1}
                              label=""
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <></>
                )}
              </Accordion>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Card.Body>
    </Card>
  )
}

export default SEOPieChart

import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, Col, Row, Form, Button, Alert, Modal } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import ReactChipInput from 'react-chips'
import { CardBody } from 'reactstrap'
import * as _ from 'lodash'
import 'react-credit-cards/es/styles-compiled.css'

import { Loader } from 'components/uiCore'
import paths from 'constants/paths'
import CommonCardHeader from 'pages/trial/new-flow/CommonCardHeader'
import { CLEAR_KEYWORD_LIMIT } from 'redux/actionType'
import { getKeywordLimit, saveAnalyticsData } from 'redux/actions/dashboard'
import { LimitOption, PLAN } from 'constants/enum'
import { message } from 'antd'
import 'modules/welcomeBoard/components/addKeyword/AddKeyword.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const AddKeywordsMain = ({ callFrom }) => {
  const [loader, setLoader] = useState(false)
  const [chips, setChips] = useState([])
  const sampleText = 'keyword1,keyword2,keyword3'
  const [planName, setPlanName] = useState(PLAN.BASIC_PLAN.name)
  const chipsRef = useRef(null)

  const { randomNumber, addUserSuccess, prevPageData, loading, keywords, keywordsListArray } = useSelector(
    ({ startFreeTrial }) => startFreeTrial,
  )

  const { registerData } = useSelector(({ register }) => register)
  const { 
          availableKeywordLimit, 
          keywordLimitLoader, 
          keywordLimitData, 
          keywordLimitError, 
          keywordsListForWebsite, 
          selectedKeywordsList,
          generateResearchLoader
      } = useSelector(({ dashboard }) => dashboard)
  const [openKeywordLimitExceed, setOpenKeywordLimitExceed] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { state, pathname } = location
  const { isMobile } = useDeviceCheck()

  useEffect(() => {

    window.scrollTo(0, 0)  
    setPlanName(registerData?.plan?.name || PLAN.BASIC_PLAN.name)
    let data
    if (keywordsListForWebsite?.length > 0) {
       data = [...selectedKeywordsList, ...chips]
    } else {
      data = [...keywordsListArray, ...chips]
    }
    let uniqueData = _.uniq(data)

    setChips(uniqueData)
  }, [])

  useEffect(() => {
    if (keywordLimitLoader) {
      return
    } else if (!keywordLimitLoader && keywordLimitData && Object?.keys(keywordLimitData)?.length) {
      if (keywordLimitData?.limitAvailable) {
        onKeywordsNextClick()
        dispatch({ type: CLEAR_KEYWORD_LIMIT })
      } else {
        if (keywordLimitData && Object.keys(keywordLimitData)?.length && !keywordLimitData?.limitAvailable) {
          setOpenKeywordLimitExceed(true)
        }
      }
    } else {
      if (!keywordLimitLoader && keywordLimitError && Object.keys(keywordLimitError)?.length) {
        message.error(keywordLimitError?.message)
        dispatch({ type: CLEAR_KEYWORD_LIMIT })
      }
    }
  }, [keywordLimitLoader, keywordLimitData, keywordLimitError])

  useEffect(() => {
    if (randomNumber && randomNumber.toFixed(0).length > 3) {
    } else {
      navigate(callFrom === 'dashboard' ? paths.dashboardAddWebsite : paths.addNewWebsite)
    }
  }, [randomNumber])

  useEffect(() => {
    if (addUserSuccess) {
      setLoader(false)
      // navigate('/dashboard/new');
    }
  }, [addUserSuccess])

  const keywordsChange = () => {
    let data
    if (keywordsListForWebsite?.length > 0) {
       data = [...selectedKeywordsList, ...chips]
    } else {
      data = [...keywordsListArray, ...chips]

    }
    let uniqueData = _.uniq(data)

    setChips(uniqueData)
  }

  const addChip = (value) => {
    const tempChips = chips.slice()
    tempChips.push(value)
    setChips(tempChips)
  }

  const handleInputChange = (e) => {
    const currentChips = chips
    const removedKeys = currentChips.filter((chip) => !e.includes(chip))
    if (selectedKeywordsList?.length > 0) {
      dispatch({ type: 'TOGGLE_KEYWORD', payload: removedKeys?.[0] })
    } else {
      dispatch({ type: 'TOGGLE_KEYWORD_CHECKBOX', payload: removedKeys?.[0] })
    }
    setChips(e)
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault()
    }
  }

  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData('Text')
    const keywords = pastedData.split(',').map((keyword) => keyword.trim())

    // Filter out any keywords that already exist in the chips state
    const uniqueKeywords = keywords.filter((keyword) => !chips.includes(keyword))

    setChips([...chips, uniqueKeywords])
    event.preventDefault()
  }

  const handleKeyDown = (event) => {
    // Check for "Enter" key or comma
    if (event.key === 'Enter' || event.key === ',') {
      if (chipsRef.current) {
        let inputValue = chipsRef.current.state.value.trim()

        // If comma, remove the comma from the end
        // if (event.key === ',') {
        //   inputValue = inputValue.slice(0, -1).trim();
        // }
        if (inputValue && !chips.includes(inputValue)) {
          //setChips([...chips, inputValue]);
          setChips([...chips, inputValue])
          // Clear the input value
          chipsRef.current.state.value = ''
        }
        event.preventDefault()
      } else {
      }
    } else {
    }
  }

  const removeChip = (index) => {
    const tempChips = chips.slice()
    tempChips.splice(index, 1)
    if (selectedKeywordsList?.length > 0) {
      dispatch({ type: 'TOGGLE_KEYWORD', payload: chips[index] })
    } else {
      dispatch({ type: 'TOGGLE_KEYWORD_CHECKBOX', payload: chips[index] })
    }
    setChips(tempChips)
  }

  const removeChipByItem = (item) => {
    let index = chips.findIndex((itemName) => itemName === item)
    if (index > -1) {
      const tempChips = chips.slice()
      tempChips.splice(index, 1)
      //dispatch({ type: 'TOGGLE_KEYWORD_CHECKBOX', payload: chips[index] })
      setChips(tempChips)
    }
  }

  const renderKeywordsCheckbox = (keywords) => {
    return (
      <Form className="row pt-3">
        {keywords.map((item, i) => (    
            i < 21 ? (
                <Form.Group key={`keyword-${i+1}`} controlId={item.item} className="col-md-2 text-left">
                  <Form.Check
                    className='add-keywords-render'
                    id={item.item}
                    type="checkbox"
                    checked={item.checked}
                    label={<span style={{ wordBreak: 'break-word', }}>{item.item.toLowerCase()}</span>}
                    onChange={() => {
                      keywordsListForWebsite?.length > 0 ? 
                        dispatch({ type: 'TOGGLE_KEYWORD', payload: item.item }) 
                        : 
                        dispatch({ type: 'TOGGLE_KEYWORD_CHECKBOX', payload: item.item }) 
                      keywordsChange()
                      if (item.checked) {
                        removeChipByItem(item.item)
                      }
                    }}
                  />
                </Form.Group>
            ) : null 
        ))}
      </Form>
    )
  }

  const onKeywordsNextClick = () => {
    let data = chips
    if (data && data.length) {
      data = data.map((item) => {
        if (item && item.length && typeof item === 'object') {
          return (item && item.length && item[0].trim().toLowerCase()) || ''
        } else {
          return item && item.length && item.trim().toLowerCase()
        }
      })
    }
    let allKey = data
    if (chipsRef.current.state.value) {
      allKey = [...data, chipsRef.current.state.value]
      setChips(allKey)
    }

    const input = {
      domain: prevPageData.url,
      region: prevPageData?.regions?.[0]?.region,
      country: prevPageData?.regions?.[0]?.country,
      regions: prevPageData?.regions,
      sessionId: randomNumber,
      keywords: allKey,
      action: 'getTopKeyword',
    }
    setLoader(true)
    dispatch({ type: 'GET_URL_DETAILS', payload: input })
    navigate(callFrom === 'dashboard' ? paths.dashboardAddCompetitors : paths.addCompetitor)
  }

  const appendKeyword = (data) => {
    let keywordArrayTemp = []
    data.forEach((item) => {
      if (item.length > 1) {
        keywordArrayTemp.push(item)
      }
    })
    let data1 = [...chips, ...keywordArrayTemp]
    let uniqueData = _.uniq(data1)
    setChips(uniqueData)
  }

  const showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = e.target.result
      const data = text.split(',')

      appendKeyword(data)
    }
    reader.readAsText(e.target.files[0])
  }

  return (
    <div className={callFrom === "trial" ? "add-keywords" : ""}>
      <Helmet title="Start trail" />
      {(callFrom === 'trial' || (callFrom === 'dashboard' && isMobile)) && (
        <div className={callFrom === "trial" ? "add-keywords-text" : "add-keywords-text-dashboard"}>
         {isMobile ? <div className="add-keywords-stepnumber-mobile">
            <h3 className="mb-0">5</h3>
          </div> : null}
          <div className="add-keywords-text-mobile">
            <p className="add-keywords-text-first">Empower Your SEO Journey: Precision, Simplicity, Results</p>
            <p className="add-keywords-text-second">Let’s Complete Your Full Signal Account Setup</p>
          </div>
        </div>
      )}
      {loader ? (
        <>
          <Spinner />{' '}
        </>
      ) : (
        <></>
      )}
      <Modal
        show={openKeywordLimitExceed}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={() => {
          setOpenKeywordLimitExceed(!openKeywordLimitExceed)
          dispatch({ type: CLEAR_KEYWORD_LIMIT })
        }}
        onClose={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({ type: CLEAR_KEYWORD_LIMIT })
        }}
        onHide={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({ type: CLEAR_KEYWORD_LIMIT })
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15 pe-0"
      >
        <Modal.Header>
          <h3 className="mb-0 f-bold">Keyword Tracking Limit Reached</h3>
        </Modal.Header>
        <Modal.Body>
          You've maximized the keyword tracking capabilities of your current plan. To continue expanding your
          insights and to add more keywords, an upgrade is necessary. Our plans are designed to scale with
          your growing needs, offering more keywords and location tracking options.
          <br />
          <br />
          Current Plan: {keywordLimitData?.keywordsLimit} Keywords
          <br />
          <br />
          Upgrade Now and unlock your full SEO potential.
          <br />
          <br />
          Thank you for using our services to enhance your digital marketing efforts. We're committed to
          supporting your growth every step of the way.
        </Modal.Body>
        <Modal.Footer className={`d-flex flex-row ${registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "justify-content-end" : 'justify-content-between'}`}>
         {registerData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : <Button
            color="primary"
            variant="primary"
            onClick={() => {
              navigate(paths.upgradePlan)
              setOpenKeywordLimitExceed(false)
              dispatch({ type: CLEAR_KEYWORD_LIMIT })
            }}
          >
            Upgrade your plan
          </Button>}

          <Button
            color="danger"
            variant="danger"
            onClick={() => {
              setOpenKeywordLimitExceed(false)
              dispatch({ type: CLEAR_KEYWORD_LIMIT })
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Card
        className={`trail-flow mb-0 add-website-card-tranparency ${callFrom === "trial" ? 'add-keywords-card' : 'add-keywords-dashboard-card'}`}
        style={{
          padding: 15,
        }}
      >
        <CardBody className={` add-website-card-tranparency ${callFrom === "trial" ? 'add-keywords-card-body' : 'add-keywords-dashboard-card-body'}`}>
          <Card className={`add-website-card-tranparency ${callFrom === "trial" ? 'add-keywords-nested-card' : 'add-keywords-dashboard-nested-card'}`}>
            <CommonCardHeader
              startSpanClass={'done-step'}
              websiteSpanClass={'done-step'}
              locationSpanClass={'done-step'}
              googleSpanClass={'done-step'}
              keywordSpanClass={'active-step'}
              competitorSpanClass={'inactive-step'}
              sitemapSpanClass={'inactive-step'}
              callFrom={callFrom}
            />
            <CardBody className="bg-white add-website-card-tranparency" >
              <Row>
                <Col>
                  <h3 className="f-bold">Add your Keywords for {prevPageData?.url}</h3>
                  {planName === PLAN.BASIC_PLAN.name ? (
                    <>
                      <p>Add upto 50 keywords to monitor placement for {prevPageData?.url} on the SERP </p>
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PRO_PLAN.name ? (
                    <>
                      <p>Add upto 100 keywords to monitor placement for {prevPageData?.url} on the SERP</p>

                      {availableKeywordLimit?.keywordsLimit ===
                      availableKeywordLimit?.availableKeywordsLimit ? null : (
                        <Alert variant="primary" className="alert-outline-coloured" key="meta-title">
                          <div className="alert-icon" />
                          <div className="alert-message">
                            <span className="alert-for-mobile">
                              <p className="mb-0">
                                You are using{' '}
                                {availableKeywordLimit?.keywordsLimit -
                                  availableKeywordLimit?.availableKeywordsLimit || 0}{' '}
                                out of {availableKeywordLimit?.keywordsLimit} maximum keywords limit for all
                                your websites.
                              </p>
                            </span>
                          </div>
                        </Alert>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {planName === PLAN.PREMIUM_PLAN.name ? (
                    <>
                      <p>Add upto 300 keywords to monitor placement for {prevPageData?.url} on the SERP </p>
                    </>
                  ) : (
                    <></>
                  )}

                  <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <Col md="8">
                      <label htmlFor="basic-url">
                        You can import additional keywords by .TXT or .CSV file,{' '}
                        <a href={`data:text/csv;charset=UTF-8,${sampleText}`} download="keyword.csv">
                          sample csv
                        </a>{' '}
                        |{' '}
                        <a href={`data:text/plain;charset=UTF-8,${sampleText}`} download="keyword.txt">
                          sample txt
                        </a>{' '}
                      </label>
                    </Col>
                    <Col className="display-end add-keywords-upload">
                      <label className="custom-file-upload btn btn-primary" onChange={(e) => showFile(e)}>
                        <input type="file" />
                        Upload Keywords
                      </label>
                    </Col>
                  </Row>

                  <div onKeyDown={handleKeyDown} onPaste={handlePaste} tabIndex={0}>
                    <ReactChipInput
                      ref={chipsRef}
                      value={chips}
                      onSubmit={addChip}
                      onRemove={removeChip}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      fromSuggestionsOnly={false}
                    />
                  </div>

                  <Row className="mt-2rem">
                    {keywords?.length ? (
                      <>
                        <h3 className="f-bold">Suggested Keywords for {prevPageData?.url}</h3>
                        <p>Select keywords </p>
                      </>
                    ) : null}
                    {!(keywords && keywords?.length) && loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <Alert
                          // variant={data?.information?.seoCheckup?.title?.titleStatus === 1 ?'success' : 'danger'}
                          variant={'warning'}
                          className="alert-outline-coloured"
                          key={'meta-title'}
                        >
                          <div className="alert-icon">
                            {/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}
                          </div>
                          <div className="alert-message">
                            <p style={{ textAlign: 'center' }} className="alert-for-mobile">
                              {' '}
                              In real-time, our keyword suggestion AI crawls {prevPageData?.url} and checks
                              your competitors' keywords to determine the best possible keywords to boost
                              ranking of {prevPageData?.url}. Please allow our system up to 2 minutes to
                              perform this task.{' '}
                            </p>
                          </div>
                        </Alert>
                      </div>
                    ) : (
                      <></>
                    )}
                    {keywords && keywords?.length ? (
                      <>
                        <>
                          {' '}
                          {(keywordsListForWebsite && keywordsListForWebsite?.length) || (keywords && keywords?.length) ? (
                            renderKeywordsCheckbox(keywordsListForWebsite?.length ? keywordsListForWebsite : keywords)
                          ) : (
                            <p className="mt-1 ">Keywords not found</p>
                          )}
                        </>
                      </>
                    ) : !loading ? (
                      <div>
                        <p className="mt-1">
                          Keywords suggestions for {prevPageData?.url} url is not found, if you still want
                          then please consider adding keywords manually.
                        </p>
                      </div>
                    ) : null}
                    {(!(keywords && keywords?.length) && loading) ||
                    (keywordLimitLoader &&
                      !Object?.keys(keywordLimitError)?.length &&
                      !Object.keys(keywordLimitData)?.length) || generateResearchLoader ? (
                      <div style={{ textAlign: 'center' }}>
                        <div>
                          <Col>
                            <Row className="w-100">
                              <div className="spacing-wo-border">
                                <Col xs="12" style={{ marginLeft: '27px' }}>
                                  <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                    <div className="text-center">
                                      <Loader />
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </Row>
                          </Col>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row className={`mt-2rem add-keywords-navigate-btn`}>
                <Col className={`${isMobile ? "text-center" : ''}`}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    variant={`outline-secondary`}
                    onClick={() => {
                      dispatch({ type: 'CLEAR_KEYWORDS_DETAILS' })
                      navigate(callFrom === 'dashboard' ? paths.dashboardAddGoogle : paths.addGoogle, {
                        state: { autoComplete: state?.autoComplete },
                      })
                    }}
                    style={{ height: 40, width: 250 }}
                  >
                    Back
                  </Button>
                </Col>
                <Col className={'display-next-end'}>
                  <Button
                    className="addwebsite-navigate-buttons"
                    onClick={() =>
                      dispatch(getKeywordLimit({ keywords: chips, limitOption: LimitOption.KEYWORD }))
                    }
                    style={{ height: 40, width: 250 }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  )
}

export default AddKeywordsMain

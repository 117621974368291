import { useEffect, useState } from 'react'
import { Row, Col, Badge } from 'react-bootstrap'
import { getDataForDashboard } from '../../utils/seoIssue'
import { getColor, getPriority } from 'constants/Utils'
import { issuesTitle } from 'constants/enum'

const Issues = (props) => {
  const [errors, setErrors] = useState([])
  const { issuesData, showIssueTitle } = props || {}

  useEffect(() => {
    if (issuesData) {
      let isUrlData = true
      const assets = getDataForDashboard(issuesData, isUrlData)
      if (assets && assets.updatedErrorsArray && assets.updatedErrorsArray.length) {
        setErrors(assets.updatedErrorsArray)
      }
    }
  }, [issuesData])

  return (
    <div style={{ maxHeight: 330, overflowY: 'scroll', overflowX: 'hidden' }}>
      {errors?.length
        ? errors.map((item, index) => {
            const { message, priority, id } = item || {}
            return message && message?.length ? (
              <Row key={`error-${index + 1}`}>
                <Col md="2">
                  <Badge bg={getColor(priority)} className="me-1 mb-1 f-bold">
                    {getPriority(priority)}
                  </Badge>
                </Col>

                <Col md="9 d-flex flex-column">
                  {showIssueTitle ? <span className="font-weight-600 fw-bold">{issuesTitle[id]}</span> : <></>}
                  <span>{message}</span>
                </Col>
                <hr className="mt-3" />
              </Row>
            ) : null
          })
        : null}
    </div>
  )
}

export default Issues

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const RenderBlocking = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [renderBlockingFlag, setRenderBlockingFlag] = useState(false)

  const columns = [
    {
      id: 'resourceUrl',
      displayName:'Resource List'
    }
  ]

  const renderBlockingCsv = data?.renderBlocking?.data?.renderBlockingResult?.details?.items?.map(item => {
    return {
      resourceUrl: item?.url
    }
  })
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.renderBlocking?.data?.testResults?.status ? (
            ''
          ) : data?.renderBlocking?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.renderBlocking?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />
        <div>
          {' '}
          <p>{data?.renderBlocking?.data?.testResults?.message}</p>{' '}
        </div>
      </div>
      <div className="vr-detail-block">
        <div className="vr-report-status-icon" />
        <div>
          {showDetail && data?.renderBlocking?.data?.testResults?.status !== 1 && data?.renderBlocking?.data?.renderBlockingResult?.details?.items?.length ? (
            <>
              {' '}
              <p
                className="text-primary cursor-pointer"
                onClick={() => {
                  if (testType === "") {
                    setRenderBlockingFlag(!renderBlockingFlag)
                  } else {
                    dispatch(openRegisterModal(true))
                  }
                }}
              >
                {' '}
                See Details
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ModalHOC
        show={renderBlockingFlag}
        toggleShow={() => setRenderBlockingFlag(!renderBlockingFlag)}
        title={'Render Blocking Signal Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns = {columns}
        datas= {renderBlockingCsv}
        fileName='Render Blocking Signal Test'
      >
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Resource List</th>
            </tr>
          </thead>
          <tbody>
            {data?.renderBlocking?.data?.renderBlockingResult?.details?.items?.length &&
              data?.renderBlocking?.data?.renderBlockingResult?.details?.items.map((item, index) => {
                return (
                  <tr key={`RenderBlocking-${index+1}`}>
                    <td>{item.url}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ModalHOC>
    </>
  )
}

export default RenderBlocking

import {
  WHITE_LABEL_TEMPLATE_CREATE_START,
  WHITE_LABEL_TEMPLATE_CREATE_SUCCESS,
  WHITE_LABEL_TEMPLATE_CREATE_ERROR,
  WHITE_LABEL_TEMPLATE_CREATE_CLEAR,
  WHITE_LABEL_TEMPLATE_LIST_START,
  WHITE_LABEL_TEMPLATE_LIST_SUCCESS,
  WHITE_LABEL_TEMPLATE_LIST_ERROR,
  WHITE_LABEL_TEMPLATE_LIST_CLEAR,
  WHITE_LABEL_TEMPLATE_DELETE_START,
  WHITE_LABEL_TEMPLATE_DELETE_SUCCESS,
  WHITE_LABEL_TEMPLATE_DELETE_ERROR,
  WHITE_LABEL_TEMPLATE_DELETE_CLEAR,
  GENERATE_WHITE_LABEL_REPORT_REQUEST,
  GENERATE_WHITE_LABEL_REPORT_SUCCESS,
  GENERATE_WHITE_LABEL_REPORT_FAILURE,
  GENERATE_WHITE_LABEL_REPORT_CLEAR,
} from 'redux/actionType'

// Start Create Template
export function createTemplateRequest(payload) {
  return {
    type: WHITE_LABEL_TEMPLATE_CREATE_START,
    payload,
  }
}
export function createTemplateSuccess(payload) {
  return {
    type: WHITE_LABEL_TEMPLATE_CREATE_SUCCESS,
    payload,
  }
}
export function createTemplateError(error) {
  return {
    type: WHITE_LABEL_TEMPLATE_CREATE_ERROR,
    error,
  }
}
export function createTemplateClear() {
  return {
    type: WHITE_LABEL_TEMPLATE_CREATE_CLEAR,
  }
}
//  End

// Start List Template
export function listTemplateRequest(payload) {
  return {
    type: WHITE_LABEL_TEMPLATE_LIST_START,
    payload,
  }
}
export function listTemplateSuccess(payload) {
  return {
    type: WHITE_LABEL_TEMPLATE_LIST_SUCCESS,
    payload,
  }
}
export function listTemplateError(error) {
  return {
    type: WHITE_LABEL_TEMPLATE_LIST_ERROR,
    error,
  }
}
export function listTemplateClear() {
  return {
    type: WHITE_LABEL_TEMPLATE_LIST_CLEAR,
  }
}
// End List Template

// Start Delete Template
export function deleteTemplateRequest(payload) {
  return {
    type: WHITE_LABEL_TEMPLATE_DELETE_START,
    payload,
  }
}
export function deleteTemplateSuccess(payload) {
  return {
    type: WHITE_LABEL_TEMPLATE_DELETE_SUCCESS,
    payload,
  }
}
export function deleteTemplateError(error) {
  return {
    type: WHITE_LABEL_TEMPLATE_DELETE_ERROR,
    error,
  }
}
export function deleteTemplateClear() {
  return {
    type: WHITE_LABEL_TEMPLATE_DELETE_CLEAR,
  }
}
// End Delete Template

export function generateWhiteLabelReportSuccess(payload) {
  return {
    type: GENERATE_WHITE_LABEL_REPORT_SUCCESS,
    payload,
  }
}

export function generateWhiteLabelReportRequest(payload) {
  return {
    type: GENERATE_WHITE_LABEL_REPORT_REQUEST,
    payload,
  }
}

export function generateWhiteLabelReportError(payload) {
  return {
    type: GENERATE_WHITE_LABEL_REPORT_FAILURE,
    payload,
  }
}

export function generateWhiteLabelReportClear() {
  return {
    type: GENERATE_WHITE_LABEL_REPORT_CLEAR,
  }
}

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const FaviconTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.faviconTest.heading}
      whatIsIT={freeTools.faviconTest.what}
      howToFixBeforeCode={freeTools.faviconTest.howSectionBeforeCode}
      codeSection={freeTools.faviconTest.codeSection}  
      howToFixAfterCode={freeTools.faviconTest.howSectionAfterCode}
    />
  )
}

export default FaviconTest

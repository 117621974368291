import Select from 'react-select'
import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import { message } from 'antd'

import { REGISTER_CLEAR } from 'redux/actionType'
import { Button, Form, Spinner } from 'react-bootstrap'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

import paths from 'constants/paths'

const RegisterForm = () => {
  
  const { registerSuccess, registerLoading, registerError, errorMessage } = useSelector(({ register }) => register)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [countryOptions, setCountryOptions] = useState([])
  const [disabledSignUp, setDisabledSignUp] = useState(false)

  useEffect(() => {
    if (!registerLoading && registerSuccess) {
      dispatch(openRegisterModal(false))
      message.success('Successfully registered')
      navigate(paths.signInPage)
      dispatch({ type: REGISTER_CLEAR })
    } else if (!registerLoading && registerError) {
      dispatch(openRegisterModal(false))
      message.error(errorMessage)
      dispatch({ type: REGISTER_CLEAR })
    }
  }, [registerLoading, registerSuccess, registerError, errorMessage])

  useEffect(() => {
    const isoCountries = moment.tz.countries()
    const finalData = []
    isoCountries.map((country) => {
      const zones = moment.tz.zonesForCountry(country, { with_offset: true })
      zones.map((zone) => {
        const data = {
          value: country,
          label: `${zone.name} (GMT${moment.tz(zone.name).format('Z')})`,
          zone: zone.name,
        }
        finalData.push(data)
      })
    })
    const uniqueArray = _.uniqBy(finalData, (obj) => JSON.stringify(obj.label))

    setCountryOptions(uniqueArray)
  }, [])

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().min(1, 'Too Short!').max(70, 'Too Long!').required('First Name is Required'),
    lastName: Yup.string().min(1, 'Too Short!').max(70, 'Too Long!').required('Last Name is Required'),
    email: Yup.string().email('Invalid email').required('Email is Required'),
    phoneNumber: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .required('A phone number is required'),
    password: Yup.string()
      .required('Password is Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!#%*?&]{8,20}$/,
        'Password should contain minimum 8 characters, at least one uppercase, one lowercase, one number and one special character.',
      ),
    timezone: Yup.string().required('Timezone is required'),
  })

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    timezone: '',
  }

  const startTrial = (data) => {
    dispatch({ type: 'REGISTER_START', payload: data })
  }

  const handleRegister = (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      startTrial(values)
      if (registerSuccess) {
        setDisabledSignUp(true)
      }
    } catch (error) {
      const message = error.message || 'Something went wrong'

      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

  const registrationFormik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: handleRegister,
  })

  const { values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue } =
    registrationFormik

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 px-2">
        <Form.Label className="signin-form-label">First Name</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          value={values.firstName}
          isInvalid={Boolean(touched.firstName && errors.firstName)}
          onBlur={handleBlur}
          onChange={handleChange}
          className="auth-inputs"
        />
        {!!touched.firstName && (
          <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3 px-2 signin-form-input">
        <Form.Label className="signin-form-label">Last Name</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          value={values.lastName}
          isInvalid={Boolean(touched.lastName && errors.lastName)}
          onBlur={handleBlur}
          onChange={handleChange}
          className="auth-inputs"
        />
        {!!touched.lastName && (
          <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3 px-2 signin-form-input">
        <Form.Label className="signin-form-label">Email Address</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={values.email}
          isInvalid={Boolean(touched.email && errors.email)}
          onBlur={handleBlur}
          onChange={handleChange}
          className="auth-inputs"
        />
        {!!touched.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
      </Form.Group>
      <Form.Group className="mb-3 px-2 signin-form-input">
        <Form.Label className="signin-form-label">Your Timezone</Form.Label>
        <Select
          options={countryOptions}
          onChange={(option) => {
            setFieldValue('timezone', option?.zone)
          }}
          placeholder=""
          isSearchable={true}
          className="auth-inputs"
          classNamePrefix="auth-timezone"
          name="timezone"
        />
        {!!touched.timezone && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.timezone}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3 px-2 signin-form-input">
        <Form.Label className="signin-form-label">Phone Number</Form.Label>
        <PhoneInput
          country={'us'}
          name="phoneNumber"
          onChange={(phone) => setFieldValue('phoneNumber', phone)}
          value={values.phoneNumber}
          inputStyle={{ width: '100%' }}
          containerClass={`${errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''} `}
          className="auth-inputs"
        />

        {!!touched.phoneNumber && (
          <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3 px-2 signin-form-input">
        <Form.Label className="signin-form-label">Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={values.password}
          isInvalid={Boolean(touched.password && errors.password)}
          onBlur={handleBlur}
          onChange={handleChange}
          className="auth-inputs"
        />
        {!!touched.password && (
          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
        )}
      </Form.Group>
      <div>
        <div className="mt-3 px-2 d-flex justify-content-between">
          <Button
            type="Submit"
            variant="primary"
            color='primary'
            size="md"
            disabled={disabledSignUp}
            className="responsive-btn"
          >
            Sign up{' '}
            {registerLoading ? <Spinner animation="border" key={1} className="me-2" size="sm" /> : null}
          </Button>

          <Button
            variant='danger'
            onClick={() => {
              dispatch(openRegisterModal(false))
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default RegisterForm

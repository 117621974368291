import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { CommonAlert } from 'components/uiCore'

const HSTS = ({ data }) => {
  return (
    <>
      {data ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.hstsTest?.data?.testResults?.status ? (
                ''
              ) : data?.hstsTest?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.hstsTest?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              {' '}
              <p>{data?.hstsTest?.data?.testResults?.message}</p>{' '}
            </div>
            {data?.hstsTest?.data?.testResults?.status === 1 && data?.hstsTest?.data?.finalRes ? (
              <>
                <CommonAlert
                  variant={'success'}
                  key={'meta-title'}
                  alertText={
                    <div className='alert-for-mobile'>
                      <b className='f-bold'>Header:</b> {data?.hstsTest?.data?.finalRes} : <></>
                    </div>
                  }
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default HSTS

import axios from 'axios'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST } from 'redux/actionType'
import {
  generateReportDownloadPDFSuccess,
  generateReportDownloadPDFError,
} from 'redux/actions/reporting/generateReport'

const baseLocalApi = process.env.REACT_APP_NEST_LOCAL_API

const generateReportDownloadPDFApi = (data) => {
  let token = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  return axios.post(`${baseLocalApi}report-template/download`, { data }, { headers, responseType: 'blob' })
}

function* generateReportDownloadPDFSaga(action) {
  try {
    const response = yield call(generateReportDownloadPDFApi, action.payload)
    if (response.status === 200) {
      yield put(generateReportDownloadPDFSuccess(response.data))
    } else {
      yield put(generateReportDownloadPDFError(response)) // handle error case
    }
  } catch (error) {
    yield put(generateReportDownloadPDFError(error))
  }
}

export function* generateDownloadPFDWatcher() {
  yield takeEvery(WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST, generateReportDownloadPDFSaga)
}

export default function* reportSaga() {
  yield all([generateDownloadPFDWatcher()])
}

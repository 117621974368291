import React from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import * as Icon from 'react-feather'

const RedirectTest = ({ data, showDetail }) => {
  return (
    <>
      {data && data?.redirectTest ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.redirectTest?.data?.testResults?.status ? (
                ''
              ) : data?.redirectTest?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.redirectTest?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p>{data?.redirectTest?.data?.testResults?.message}</p>
            <p>{data?.redirectTest?.data?.description}</p>
            {showDetail &&
            data?.redirectTest?.data?.testResults?.status !== 1 &&
            data?.redirectTest?.data?.redirects.length > 1 ? (
              <>
                {data?.redirectTest?.data?.redirects.map((url, index) => {
                  return (
                    <div className="redirect-url" key={`RedirectTest-${index+1}`}>
                      <a className="redirect-url-one" href="javascript:void(0)">
                        {url?.url}
                      </a>
                      {data?.redirectTest?.data?.redirects.length !== index + 1 ? (
                        <span className="grey-label">
                          {' '}
                          <Icon.ArrowRight size={16} />{' '}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default RedirectTest

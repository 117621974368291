import Payment from 'payment'
import * as _ from 'lodash'
import crypto from 'crypto'
import forge from 'node-forge'
import { config } from 'dotenv'

config()
export const appendInFormData = (data) => {
  let formBody = []
  for (var key in data) {
    if (data[key] !== undefined && data[key] !== '' && data[key] !== null) {
      var encodedKey = encodeURIComponent(key)
      var encodedValue = encodeURIComponent(data[key])
      formBody.push(encodedKey + '=' + encodedValue)
    }
  }
  formBody = formBody.join('&')
  return formBody
}

export const getRandom10Int = () => {
  const min = Math.ceil(1000000000)
  const max = Math.floor(9000000000)
  return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
}

export const getAbbr = (country, locations) => {
  let locationData =
    locations && locations.length
      ? locations.filter((item) => {
          return item.country === country
        })
      : []
  if (locationData && locationData.length) {
    return locationData[0].countryShortCodeISO2
  } else {
    return country
  }
}

export const getColor = (priority) => {
  if (priority === 1) {
    return 'success'
  } else if (priority === 2) {
    return 'warning'
  } else {
    return 'danger'
  }
}

export const getPriority = (priority) => {
  if (priority === 1) {
    return 'LOW'
  } else if (priority === 2) {
    return 'MEDIUM'
  } else {
    return 'HIGH'
  }
}

export const getExtractedValue = (data) => {
  var stringArray = data.split(':')
  return stringArray.length > 1 ? stringArray[1].trim() : ''
}

export const sizeDetermination = (size) => {
  if (size) {
    const sizes = ['Bytes', 'KB', 'MB']
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`
  } else {
    return 0 + ' KB'
  }
}

export const getDifficulty = (difficulty) => {
  if (difficulty <= 0.39) {
    // 0-39 - Low
    return `Low - ${Math.floor(difficulty * 100)}`
  } else if (difficulty >= 0.4 && difficulty <= 0.6) {
    // 40 - 60 - Medium
    return `Medium - ${Math.floor(difficulty * 100)}`
  } else if (difficulty >= 0.61 && difficulty <= 0.75) {
    // 61 - 75 - High
    return `High - ${Math.floor(difficulty * 100)}%`
  } else if (difficulty >= 0.76 && difficulty <= 1.0) {
    // 75 - 100 - Very High
    return `Very High - ${Math.floor(difficulty * 100)}`
  } else {
    return `-`
  }
}

export const getPlanPrice = (plan, frequency) => {
  if (frequency === 'Monthly') {
    switch (plan) {
      case 'Enterprise':
        return '76.95'
      case 'Business Pro':
        return '39.95'
      case 'Business Plus':
        return '24.95'
      default:
        return '24.95'
    }
  } else {
    switch (plan) {
      case 'Enterprise':
        return '699.95'
      case 'Business Pro':
        return '417.89'
      case 'Business Plus':
        return '263.89'
      default:
        return '263.89'
    }
  }
  // return '$39.95';
}
// PROD
const planData = [
  {
    _id: '5e848c5e0a76c05ea3283d37',
    plan: 'plan_Gxhhjp47wgWgB7',
    price: 263.89,
    name: 'Business Plus',
    description: 'Business Plus',
    platform: 'Stripe',
    type: 'Yearly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.737Z',
    updatedAt: '2020-03-24T14:04:29.737Z',
  },
  {
    _id: '5e848c5e0a76c05ea3283d38',
    plan: 'plan_GxhgkScr7bbrSe',
    price: 417.89,
    name: 'Business Pro',
    description: 'Business Pro',
    platform: 'Stripe',
    type: 'Yearly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '5e848c5e0a76c05ea3283d39',
    plan: 'plan_GxhgMzpVhX6IYY',
    price: 699.95,
    name: 'Enterprise',
    description: 'Enterprise',
    platform: 'Stripe',
    type: 'Yearly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '5e848c5e0a76c05ea3283d3a',
    plan: 'plan_GxhfNQWvc34gBW',
    price: 22.45,
    name: 'Business Plus',
    description: 'Business Plus',
    platform: 'Stripe',
    type: 'Monthly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '5e848c5e0a76c05ea3283d3b',
    plan: 'plan_GxhfArQhbdhWaG',
    price: 35.95,
    name: 'Business Pro',
    description: 'Business Pro',
    platform: 'Stripe',
    type: 'Monthly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '5e848c5e0a76c05ea3283d3c',
    plan: 'plan_GxheYyPJQADzZE',
    price: 71.95,
    name: 'Enterprise',
    description: 'Enterprise',
    platform: 'Stripe',
    type: 'Monthly',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '5e7a136d9fd2f9da544a7ef4',
    name: 'Business Pro',
    type: 'Monthly',
    price: 35.95,
    plan: 'P-93W17077C99374841LZ66JGQ',
    description: 'Business Pro',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.738Z',
    updatedAt: '2020-03-24T14:04:29.738Z',
  },
  {
    _id: '5e7a136d9fd2f9da544a7ef5',
    name: 'Business Plus',
    type: 'Monthly',
    price: 22.45,
    plan: 'P-2ED94593LK4585511LZ66JGI',
    description: 'Business Plus',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '5e7a136d9fd2f9da544a7ef6',
    name: 'Business Pro',
    type: 'Yearly',
    price: 417.89,
    plan: 'P-6JU89572565045043LZ66JHQ',
    description: 'Business Pro',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '5e7a136d9fd2f9da544a7ef7',
    name: 'Enterprise',
    type: 'Yearly',
    price: 699.95,
    plan: 'P-74C410164X8177204LZ66JHA',
    description: 'Enterprise',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '5e7a136d9fd2f9da544a7ef8',
    name: 'Enterprise',
    type: 'Monthly',
    price: 71.95,
    plan: 'P-1M271313GA290305VLZ66JGA',
    description: 'Enterprise',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
  {
    _id: '5e7a136d9fd2f9da544a7ef9',
    name: 'Business Plus',
    type: 'Yearly',
    price: 263.89,
    plan: 'P-0LD362523A9163233LZ66JHI',
    description: 'Business Plus',
    platform: 'PayPal',
    __v: 0,
    createdAt: '2020-03-24T14:04:29.739Z',
    updatedAt: '2020-03-24T14:04:29.739Z',
  },
]
// DEV
// const planData = [
//      {
//          "_id": "5e7a136d9fd2f9da544a7eee",
//          "plan": "plan_Gxhhjp47wgWgB7",
//          "price": 263.89,
//          "name": "Business Plus",
//          "description": "Business Plus",
//          "platform": "Stripe",
//          "type": "Yearly",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.737Z",
//          "updatedAt": "2020-03-24T14:04:29.737Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7eef",
//          "plan": "plan_GxhgkScr7bbrSe",
//          "price": 417.89,
//          "name": "Business Pro",
//          "description": "Business Pro",
//          "platform": "Stripe",
//          "type": "Yearly",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.738Z",
//          "updatedAt": "2020-03-24T14:04:29.738Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef0",
//          "plan": "plan_GxhgMzpVhX6IYY",
//          "price": 699.95,
//          "name": "Enterprise",
//          "description": "Enterprise",
//          "platform": "Stripe",
//          "type": "Yearly",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.738Z",
//          "updatedAt": "2020-03-24T14:04:29.738Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef1",
//          "plan": "plan_GxhfNQWvc34gBW",
//          "price": 22.45,
//          "name": "Business Plus",
//          "description": "Business Plus",
//          "platform": "Stripe",
//          "type": "Monthly",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.738Z",
//          "updatedAt": "2020-03-24T14:04:29.738Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef2",
//          "plan": "plan_GxhfArQhbdhWaG",
//          "price": 35.95,
//          "name": "Business Pro",
//          "description": "Business Pro",
//          "platform": "Stripe",
//          "type": "Monthly",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.738Z",
//          "updatedAt": "2020-03-24T14:04:29.738Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef3",
//          "plan": "plan_GxheYyPJQADzZE",
//          "price": 71.95,
//          "name": "Enterprise",
//          "description": "Enterprise",
//          "platform": "Stripe",
//          "type": "Monthly",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.738Z",
//          "updatedAt": "2020-03-24T14:04:29.738Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef4",
//          "name": "Business Pro",
//          "type": "Monthly",
//          "price": 35.95,
//          "plan": "P-93W17077C99374841LZ66JGQ",
//          "description": "Business Pro",
//          "platform": "PayPal",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.738Z",
//          "updatedAt": "2020-03-24T14:04:29.738Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef5",
//          "name": "Business Plus",
//          "type": "Monthly",
//          "price": 22.45,
//          "plan": "P-2ED94593LK4585511LZ66JGI",
//          "description": "Business Plus",
//          "platform": "PayPal",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.739Z",
//          "updatedAt": "2020-03-24T14:04:29.739Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef6",
//          "name": "Business Pro",
//          "type": "Yearly",
//          "price": 417.89,
//          "plan": "P-6JU89572565045043LZ66JHQ",
//          "description": "Business Pro",
//          "platform": "PayPal",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.739Z",
//          "updatedAt": "2020-03-24T14:04:29.739Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef7",
//          "name": "Enterprise",
//          "type": "Yearly",
//          "price": 699.95,
//          "plan": "P-74C410164X8177204LZ66JHA",
//          "description": "Enterprise",
//          "platform": "PayPal",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.739Z",
//          "updatedAt": "2020-03-24T14:04:29.739Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef8",
//          "name": "Enterprise",
//          "type": "Monthly",
//          "price": 71.95,
//          "plan": "P-1M271313GA290305VLZ66JGA",
//          "description": "Enterprise",
//          "platform": "PayPal",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.739Z",
//          "updatedAt": "2020-03-24T14:04:29.739Z"
//      },
//      {
//          "_id": "5e7a136d9fd2f9da544a7ef9",
//          "name": "Business Plus",
//          "type": "Yearly",
//          "price": 263.89,
//          "plan": "P-0LD362523A9163233LZ66JHI",
//          "description": "Business Plus",
//          "platform": "PayPal",
//          "__v": 0,
//          "createdAt": "2020-03-24T14:04:29.739Z",
//          "updatedAt": "2020-03-24T14:04:29.739Z"
//      }
//  ]

export default planData

function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
      break
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12,
      )} ${clearValue.slice(12, 16)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  let maxLength = 4

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number)
    maxLength = issuer === 'amex' ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`)
}

export const storePlans = [
  {
    name: 'Enterprise',
    price: 71.95,
    type: 'Monthly',
    plan: 'P-1M271313GA290305VLZ66JGA',
    description: 'Enterprise',
    platform: 'PayPal',
  },
  {
    name: 'Business Plus',
    price: 22.45,
    type: 'Monthly',
    plan: 'P-2ED94593LK4585511LZ66JGI',
    description: 'Business Plus',
    platform: 'PayPal',
  },
  {
    name: 'Business Pro',
    price: 35.95,
    type: 'Monthly',
    plan: 'P-93W17077C99374841LZ66JGQ',
    description: 'Business Pro',
    platform: 'PayPal',
  },
  {
    name: 'Enterprise',
    price: 699.95,
    type: 'Yearly',
    plan: 'P-74C410164X8177204LZ66JHA',
    description: 'Enterprise',
    platform: 'PayPal',
  },
  {
    name: 'Business Plus',
    price: 263.89,
    type: 'Yearly',
    plan: 'P-0LD362523A9163233LZ66JHI',
    description: 'Business Plus',
    platform: 'PayPal',
  },
  {
    name: 'Business Pro',
    price: 417.89,
    type: 'Yearly',
    plan: 'P-6JU89572565045043LZ66JHQ',
    description: 'Business Pro',
    platform: 'PayPal',
  },
]

export function checkCommonKeyword(keywords) {
  if (keywords && keywords.length) {
    const successKeywords = keywords.filter((item) => item.count < 12)
    if (successKeywords.length === 10) {
      return {
        name: 'Success',
        color: 'success',
      }
    } else if (successKeywords.length >= 7 && successKeywords.length < 10) {
      return {
        name: 'Warning',
        color: 'warning',
      }
    } else if (successKeywords.length <= 6) {
      return {
        name: 'Failed',
        color: 'danger',
      }
    }

    return {
      name: 'Please Wait',
      color: 'dark',
    }
  } else {
    return {
      name: 'Please Wait',
      color: 'dark',
    }
  }
}

export function checkRelatedKeyword(keywords) {
  if (keywords && keywords.length) {
    const successKeywords = keywords.filter((item) => item.matched)
    if (successKeywords.length >= 5) {
      return {
        name: 'Success',
        color: 'success',
      }
    } else if (successKeywords.length >= 3 && successKeywords.length <= 4) {
      return {
        name: 'Warning',
        color: 'warning',
      }
    } else if (successKeywords.length < 3) {
      return {
        name: 'Failed',
        color: 'danger',
      }
    }
    return {
      name: 'Failed',
      color: 'danger',
    }
  } else {
    return {
      name: 'Failed',
      color: 'danger',
    }
  }
}

export function checkCompetitorKeyword(keywords) {
  if (keywords && keywords.length) {
    const successKeywords = keywords.filter((item) => item.match)

    if (successKeywords.length >= 6) {
      return {
        name: 'Success',
        color: 'success',
      }
    } else if (successKeywords.length >= 3 && successKeywords.length <= 5) {
      return {
        name: 'Warning',
        color: 'warning',
      }
    } else if (successKeywords.length < 3) {
      return {
        name: 'Failed',
        color: 'danger',
      }
    }

    return {
      name: 'Failed',
      color: 'danger',
    }
  } else {
    return {
      name: 'Failed',
      color: 'danger',
    }
  }
}

export function checkCompetitor(competitors) {
  if (competitors && competitors.length) {
    if (competitors.length >= 6) {
      return {
        name: 'Success',
        color: 'success',
      }
    } else if (competitors.length >= 4 && competitors.length <= 5) {
      return {
        name: 'Warning',
        color: 'warning',
      }
    } else if (competitors.length < 4) {
      return {
        name: 'Failed',
        color: 'danger',
      }
    }
    return {
      name: 'Failed',
      color: 'danger',
    }
  } else {
    return {
      name: 'Failed',
      color: 'danger',
    }
  }
}

export function getFontSize(count) {
  if (count >= 4 && count < 8) {
    return 20
  } else if (count >= 8 && count < 12) {
    return 26
  } else if (count >= 12 && count < 20) {
    return 31
  } else if (count >= 2 && count < 4) {
    return 17
  } else if (count >= 20) {
    return 40
  } else {
    return 17
  }
}

export function checkPosition(items) {
  var droppedPosition = 0
  var samePosition = 0
  var increasedPosition = 0
  var total = 0
  var average = 0
  if (items && items.length) {
    if (items.length === 1) {
      samePosition =
        (items[0].keywordClouds &&
          items[0].keywordClouds.length &&
          items[0].keywordClouds[0].data &&
          items[0].keywordClouds[0].data.commonKeywords &&
          items[0].keywordClouds[0].data.commonKeywords.length) ||
        0
      items[0].keywordClouds &&
        items[0].keywordClouds.length &&
        items[0].keywordClouds[0].data &&
        items[0].keywordClouds[0].data.commonKeywords &&
        items[0].keywordClouds[0].data.commonKeywords.length &&
        items[0].keywordClouds[0].data.commonKeywords.forEach((obj, index) => {
          obj.position > 1 ? (total = total + obj.position) : void 0
          if (items[0].keywordClouds[0].data.commonKeywords.length - 1 === index) {
            average = total / items[0].keywordClouds[0].data.commonKeywords.length
          }
        })
    } else {
      const keywordsArray = items.map((ck) => {
        if (
          ck.keywordClouds &&
          ck.keywordClouds.length &&
          ck.keywordClouds[0].data &&
          ck.keywordClouds[0].data.commonKeywords &&
          ck.keywordClouds[0].data.commonKeywords.length
        ) {
          return { commonKeywords: ck.keywordClouds[0].data.commonKeywords }
        } else {
          return { commonKeywords: [] }
        }
      })
      keywordsArray.forEach((obj, mainIndex) => {
        if (mainIndex === 0) {
          if (
            obj.commonKeywords.length > 1 &&
            keywordsArray &&
            keywordsArray.length &&
            keywordsArray[1].commonKeywords.length > 1
          ) {
            obj.commonKeywords.forEach((keyword, index) => {
              if (
                keyword.name === keywordsArray[1].commonKeywords[index].name &&
                keyword.position > keywordsArray[1].commonKeywords[index].position
              ) {
                droppedPosition++
              } else if (
                keyword.name === keywordsArray[1].commonKeywords[index].name &&
                keyword.position === keywordsArray[1].commonKeywords[index].position
              ) {
                samePosition++
              } else if (
                keyword.name === keywordsArray[1].commonKeywords[index].name &&
                keyword.position < keywordsArray[1].commonKeywords[index].position
              ) {
                increasedPosition++
              }
              if (mainIndex === 0) {
                keyword.position > 1 ? (total = total + keyword.position) : void 0
              }
              if (obj.commonKeywords.length - 1 === index) {
                average = total / obj.commonKeywords.length
              }
            })
          }
        }
      })
    }
    return { average, total, droppedPosition, samePosition, increasedPosition }
  }
}

export function checkPositionForUserEntered(items) {
  var total = 0
  var average = 0
  // if (items && items.data && items.data.length) {
  //   if (items.data.length === 1) {
  //     samePosition = items.data[0].dataset && items.data[0].dataset.length && items.data[0].dataset.length || 0
  //     items.data[0].dataset && items.data[0].dataset.length && items.data[0].dataset.forEach((obj, index) => {
  //       obj.position > 1 ? total = total + obj.position : void (0);
  //       if (items.data[0].dataset.length - 1 === index) {
  //         average = total / items.data[0].dataset.length
  //       }
  //     });
  //   } else {
  //     items && items.data && items.data.length && items.data.map((obj, mainIndex) => {
  //       if (mainIndex === 0) {
  //         if (obj && obj.dataset.length > 1 && items && items.data && items.data.length && items.data[1].dataset.length > 1) {
  //           obj.dataset.map((keyword, index) => {

  //             if (keyword && keyword.name === items.data && items.data.length && items.data[1] && items.data[1].dataset && items.data[1].dataset.length && items.data[1].dataset[index] && items.data[1].dataset[index].name && keyword.position > items.data[1].dataset[index].position) {
  //               droppedPosition++
  //             } else if (keyword && keyword.name === items.data && items.data.length && items.data[1] && items.data[1].dataset && items.data[1].dataset.length && items.data[1].dataset[index] && items.data[1].dataset[index].name && keyword.position === items.data[1].dataset[index].position) {
  //               samePosition++
  //             } else if (keyword && keyword.name === items.data && items.data.length && items.data[1] && items.data[1].dataset && items.data[1].dataset.length && items.data[1].dataset[index] && items.data[1].dataset[index].name && keyword.position < items.data[1].dataset[index].position) {
  //               increasedPosition++
  //             }
  //             if (mainIndex === 0) {
  //               keyword && keyword.position > 1 ? total = total + keyword.position : void (0);
  //             }
  //             if (obj && obj.dataset.length - 1 === index) {
  //               average = total / obj.dataset.length
  //             }
  //           });
  //         }
  //       }
  //     })
  //   }

  if (items && items.length) {
    items.forEach((item) => {
      total = total + item?.position || 0
    })
    average = total / items.length
  }
  return { average }
}

export function checkPositionForKeyword(activeKeywordData, keyword, currentPosition, obj) {
  var droppedPosition = 0
  var samePosition = 0
  var increasedPosition = 0
  var total = 0
  var average = 0
  if (obj === undefined) {
    return { position: -1, average, total, droppedPosition, samePosition, increasedPosition, currentPosition }
  }

  let position = 0
  if (activeKeywordData && activeKeywordData.length) {
    if (activeKeywordData.length === 1) {
    } else {
      let data = _.findLastIndex(activeKeywordData[activeKeywordData.length - 2]?.dataset, function (o) {
        return o.keyword === keyword
      })
      if (data > -1) {
        position = activeKeywordData[activeKeywordData.length - 2]?.dataset[data].position?.[0]?.position || 0
      }
      // activeKeywordData[activeKeywordData.length-2]?.dataset.forEach( (set, index) => {
      //   // let data = _.findLastIndex(tableNewData, function(o) { return o.name == element });
      //   // if(data> -1) {
      //   //   tableFilteredData.push(tableNewData[data]);
      //   // }
      //   if(set.keyword === keyword) {
      //     position = set.position[0];
      //   }
      // });
    }

    if (currentPosition < position && position !== 0 && currentPosition !== 0) {
      droppedPosition = 1
      average = position - currentPosition
    } else if (currentPosition > position && position !== 0 && currentPosition !== 0) {
      increasedPosition = 1
      average = currentPosition - position
    } else {
      samePosition = 1
    }
    return { position, average, total, droppedPosition, samePosition, increasedPosition, currentPosition }
  }
}

export const mergeByProperty = (target, source, prop) => {
  source.forEach((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop]
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement)
  })
}

export const getCummulativeSumForBacklinks = (array, index, currentData) => {
  let total = currentData
  array.forEach((item, itemIndex) => {
    if (itemIndex <= index) {
      total = total + item.count
    }
  })
  return total
}

export const checkMovements = (activeSearchData, currentData, activeKeywordData) => {
  var droppedPosition = 0
  var samePosition = 0
  var increasedPosition = 0

  currentData &&
    currentData.length &&
    currentData.forEach((keywordItem) => {
      let count = 0
      let total = 0
      activeKeywordData &&
        activeKeywordData.data &&
        activeKeywordData.data.length &&
        activeKeywordData.data.forEach((item, index) => {
          if (index !== activeKeywordData.data.length - 1 && index >= activeKeywordData.data.length - 2) {
            let keywordData = _.intersectionBy(item.dataset, [{ keyword: keywordItem.name }], 'keyword')

            if (keywordData?.[0]?.keyword === keywordItem?.name) {
              total = total + keywordData?.[0]?.position?.[0]?.position

              count++
            }
          }
        })
      let avg = 0
      if (total !== 0) {
        avg = total / count
      }
      if (
        keywordItem?.regions?.[0]?.position < avg &&
        avg !== 0 &&
        keywordItem?.regions?.[0]?.position !== 0
      ) {
        increasedPosition++
      } else if (
        keywordItem?.regions?.[0]?.position > avg &&
        avg !== 0 &&
        keywordItem?.regions?.[0]?.position !== 0
      ) {
        droppedPosition++
      } else {
        samePosition++
      }
    })
  return { samePosition, droppedPosition, increasedPosition }
}

export const checkPage = (position) => {
  if (position > -1 && position <= 10) {
    return 'Page 1'
  } else if (position > 10 && position <= 20) {
    return 'Page 2'
  } else if (position > 20 && position <= 30) {
    return 'Page 3'
  } else if (position > 30 && position <= 40) {
    return 'Page 4'
  } else if (position > 40 && position <= 50) {
    return 'Page 5'
  } else if (position > 50 && position <= 60) {
    return 'Page 6'
  } else if (position > 60 && position <= 70) {
    return 'Page 7'
  } else if (position > 70 && position <= 80) {
    return 'Page 8'
  } else if (position > 80 && position <= 90) {
    return 'Page 9'
  } else if (position > 90 && position <= 100) {
    return 'Page 10'
  } else {
    return 'Not in Top 100'
  }
}

export const getSEOScore = (position) => {
  let seoScore = typeof position === 'string' ? parseInt(position) : position
  //0-49 Red, 50 to 74 - yellow, Dark green 75 to 100.
  if (seoScore >= 0 && seoScore <= 49) {
    return {
      red: seoScore,
      yellow: 0,
      green: 0,
    }
  } else if (seoScore >= 50 && seoScore <= 74) {
    return {
      red: 49,
      yellow: seoScore - 49,
      green: 0,
    }
  } else if (seoScore >= 75 && seoScore <= 100) {
    return {
      red: 49,
      yellow: 25,
      green: seoScore - 74,
    }
  } else {
    return {
      red: 0,
      yellow: 0,
      green: 0,
    }
  }
}

export const getColorBasedOnSEOScore = (position) => {
  let seoScore = typeof position === 'string' ? parseInt(position) : position
  //0-49 Red, 50 to 74 - yellow, Dark green 75 to 100.
  if (seoScore >= 0 && seoScore <= 49) {
    return {
      color: '#d9534f',
    }
  } else if (seoScore >= 50 && seoScore <= 74) {
    return {
      color: '#E5A54B',
    }
  } else if (seoScore >= 75 && seoScore <= 100) {
    return {
      color: '#4BBF73',
    }
  } else {
    return {}
  }
}

export const getActiveUserData = (usersDomain, activeLink, userData) => {
  const splittedURL =
    (usersDomain &&
      activeLink !== undefined &&
      usersDomain?.[activeLink] &&
      usersDomain?.[activeLink]?.items &&
      usersDomain?.[activeLink]?.items?.[0]?.url?.split('/').slice(0, 3)) ||
    ''

  let hostName = splittedURL?.[splittedURL?.length - 1] || ''
  if (hostName.includes('www.')) {
    hostName = hostName.replace('www.', '')
  }

  const activeSearchData =
    (userData &&
      userData?.searchResults &&
      userData?.searchResults?.length &&
      _.findLast(
        userData?.searchResults,
        (item) => item && item?.url && item?.url?.toLowerCase().includes(hostName.toLowerCase()),
      )) ||
    {}

  return { activeSearchData, hostName }
}

export const isMainUrlMatch = (url, redirect_url) => {
  let main_url = url?.endsWith('/') ? url?.slice(0, -1) : url

  let main_Redirect_Url =
    redirect_url?.length && redirect_url?.endsWith('/') ? redirect_url?.slice(0, -1) : redirect_url

  return main_url === main_Redirect_Url
}

export const encrypt = (publicKey, data) => {
  const buffer = Buffer.from(data, 'utf8')
  const encrypted = crypto.publicEncrypt(publicKey, buffer)
  return encrypted.toString('hex')
}

export const encryptUrl = (body) => {
  const encryptionPublicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY
  const publicKey = Buffer.from(encryptionPublicKey, 'base64').toString('utf8')
  const encryptedData = encrypt(publicKey, body)
  return encryptedData
}

export const getRefreshToken = (url, userData) => {
  const getActiveUrlRefreshToken =
    userData?.refreshTokens?.length && userData?.refreshTokens?.filter((obj) => url?.includes(obj?.hostname))

  return getActiveUrlRefreshToken?.[0]
}

export const encryptData = (data) => {
  try {
    const publicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY?.replace(/\\n/g, '\n')
    const rsa = forge.pki.publicKeyFromPem(publicKey)
    const encrypted = rsa.encrypt(data, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    })
    return forge.util.bytesToHex(encrypted)
  } catch (error) {
    console.error('Encryption error:', error)
    throw error
  }
}

export const shortMessageForWhiteLabel = (message, splitFrom = 'If you are using Akamai') => {
  return message?.includes(splitFrom) ? message?.split(splitFrom)?.[0] : message
}

import { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Alert, Form, Modal, ProgressBar, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faPen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-scroll'

import { Accordion, Badge } from 'react-bootstrap'
import * as Icon from 'react-feather'
import { Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap'
import PieChart from '../charts/ApexCharts/Pie'
import { getQuickCheckupReport, quickSeoCheckup } from '../../redux/actions/freeTools/index'
import { useDispatch, useSelector } from 'react-redux'
import Issues from '../dashboards/IssuesViewReport'

import { getSEOScore } from 'constants/Utils'
import CommonSEOHOC from './tests-components/CommonSEOHOC'
import CDNUsage from './tests-components/CDNUsage'
import ModalHOC from './tests-components/ModalHOC'
import CSSAnalysis from './tests-components/CSSAnalysis'
import { commonSEOIssueInfo } from '../../constants/strings'
import crossIcon from '../../../src/assets/img/icons/cross.png'
import tickIcon from '../../../src/assets/img/icons/tick-mark.png'
import warningIcon from '../../../src/assets/img/icons/warning.png'
import { Loader } from 'components/uiCore'

import CSSCache from './tests-components/CSSCache'
import usePalette from '../../hooks/usePalette'
import DesktopScreenshot from './tests-components/DesktopScreenshot'
import MobileScreenshot from './tests-components/MobileScreenshot'
import SpfRecord from './tests-components/SpfRecord'
import SocialMediaTest from './tests-components/SocialMediaTest'
import HtmlCompression from './tests-components/HtmlCompression'
import HtmlValidation from './tests-components/HtmlValidation'
import HttpsTest from './tests-components/HttpsTest'
import ImageAlt from './tests-components/ImageAlt'
import LinksALTDescriptions from './tests-components/LinksALTDescriptions'
import InlineCss from './tests-components/InlineCss'
import JSError from './tests-components/JSError'
import NoFollow from './tests-components/NoFollow'
import PageCache from './tests-components/PageCache'
import PageObject from './tests-components/PageObject'
import RedirectTest from './tests-components/RedirectTest'
import SafeBrowsing from './tests-components/SafeBrowsing'
import ServerSignature from './tests-components/ServerSignature'
import SiteSpeed from './tests-components/SiteSpeed'
import BrotilTest from './tests-components/BrotilTest'
import DnsSecurityTest from './tests-components/DnsSecurityTest'
import TlsLogjam from './tests-components/TlsLogjam'
import AdsTxt from './tests-components/AdsTxt'
import MetaRefresh from './tests-components/MetaRefresh'
import MicroDataSchema from './tests-components/MicroDataSchema'
import Custom404 from './tests-components/Custom404'
import SeoFriendlyUrlAnalysis from './tests-components/SeoFriendlyUrlAnalysis'
import DirectoryBrowsing from './tests-components/DirectoryBrowsing'
import HSTS from './tests-components/hsts'
import UnsafeCrossOriginLinks from './tests-components/UnsafeCrossOriginLinks'
import DomSize from './tests-components/DomSize'
import BootupTime from './tests-components/bootupTime'
import MordenImage from './tests-components/MordenImage'
import JSCache from './tests-components/JSCache'
import ImageCache from './tests-components/ImageCache'
import RenderBlocking from './tests-components/RenderBlocking'
import ContentfulPaint from './tests-components/ContentfulPaint'
import LayoutShift from './tests-components/LayoutShift'
import CSSMinification from './tests-components/CSSMinification'
import JSMinification from './tests-components/JSMinification'
import FlashTest from './tests-components/FlashTest'
import NestedTable from './tests-components/NestedTable'
import FramesetTest from './tests-components/FramesetTest'
import FaviconCheckerTest from './tests-components/FaviconCheckerTest'
import DocType from './tests-components/DocType'
import UrlCanonicalization from './tests-components/UrlCanonicalization'
import Http2Test from './tests-components/http2'
import PlainTextEmail from './tests-components/PlainTextEmail'
import SSLHttpTest from './tests-components/SslTest'
import NoIndexChecker from './tests-components/NoIndexChecker'
import CanonicalURL from './tests-components/CanonicalURL'
import HeadingAnalysis from './tests-components/HeadingAnalysis'
import GoogleAnalytics from './tests-components/GoogleAnalytics'
import MetaTitleTest from './tests-components/MetaTitle'
import MetaDescriptionTest from './tests-components/MetaDescription'
import CharacterSet from './tests-components/characterSet'
import ConsoleErrors from './tests-components/ConsoleErrors'
import AspectRatio from './tests-components/AspectRatio'
import ResponsiveImage from './tests-components/ResponsiveImage'
import KeywordUsageTest from './tests-components/KeywordUsage'
import SocialMediaMetaTags from './tests-components/socialMediaMetaTags'
import MetaResultPreviewTest from './tests-components/MetaResultPreview'
import MetaRobotsText from './tests-components/MetaRobotsTest'
import SiteMapData from './tests-components/SiteMapData'
import Viewport from './tests-components/viewport'
import MenuTitleTest from './tests-components/menuTitleTest'
import TapTargets from './tests-components/TapTargets'
import ContentWidth from './tests-components/ContentWidth'
import SpeedIndex from './tests-components/SpeedIndex'
import FirstContentfulPaint from './tests-components/FirstContentfulPaint'
import LargestContentfulPaint from './tests-components/LargestContentfulPaint'
import CumulativeLayoutShift from './tests-components/CumulativeLayoutShift'
import TimetoInteractiveTest from './tests-components/TimetoInteractiveTest'
import FirstInputDelay from './tests-components/FirstInputDelay'
import FontSizeTest from './tests-components/FontSizeTest'
import CachePolicy from './tests-components/CachePolicy'
import AdvancedConsoleErrors from './tests-components/AdvancedConsoleError'
import { getLighthouseStatus, ifwebSiteData, lightHouseSuccessClear, openModalLighthouse, setStartScan } from 'redux/actions/freetrial/startFreeTrial'
import paths from 'constants/paths'
import { useNavigate } from 'react-router-dom'
import { supportRequest } from 'redux/actions/register'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { CLEAR_GET_LIGHTHOUSE_QUICK_SEO, CLEAR_GET_LIGHTHOUSE_STATUS, CLEAR_GET_QUICK_CHECKUP_REPORT, CLEAR_QUICK_SCAN_LIMIT, CLEAR_QUICK_SEO_CHECKUP } from 'redux/actionType'
import { openUpgradePlanModal } from 'redux/actions/dashboard'

const QuickSEOCheckup = () => {
  const quickSEOUrl = useSelector(({ freeTools }) => freeTools?.url)
  const quickSeoSuccess = useSelector(({ freeTools }) => freeTools?.quickSeoSuccess)
  const {quickSeoFailed, getQuickSeoReportError} = useSelector(({ freeTools }) => freeTools)
  const quickSeoCheckupStart = useSelector(({ freeTools }) => freeTools?.quickSeoCheckupStart)
  const urlData = useSelector(({ freeTools }) => freeTools?.data?.resData)
  const { toastMessage, toastError, supportRequestLoader } = useSelector(({register}) => register)

  const { lighthouseQuickSeoStatus, lighthouseForQuickseoLoader, quickSeoURL, quickScanLimitLoader, quickScanLimitData, quickScanLimitError } = useSelector(({dashboard}) => dashboard)
  const { isModalOpenLighthouse, lightHouseStatusData } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const lighthouseInterval = useRef()

  const [positiveCounter, setPositiveCounter] = useState(0)
  const [negativeCounter, setNegativeCounter] = useState(0)
  const [warningCounter, setWarningCounter] = useState(0)
  const [errors, setErrors] = useState([])
  const [cdnUsageDataFlag, setCdnUsageDataFlag] = useState(false)
  const [cssCacheFlag, setCssCacheFlag] = useState(false)

  const [cssAnalysisFlag, setCssAnalysisFlag] = useState(false)
  const [headingAnalysisFlag, setHeadingAnalysisFlag] = useState(false)
  const [htmlValidationFlag, setHtmlValidationFlag] = useState(false)
  const [inlineCssFlag, setInlinecssFlag] = useState(false)
  const [jsMinificationFlag, setJsMinification] = useState(false)
  const [microSchemaData, setMicroSchemaData] = useState(false)
  const [socialMediaFlag, setSocialMediaFlag] = useState(false)
  const [cssMinificationFlag, setCssMinificationFlag] = useState(false)
  const [jsErrorFlag, setJsErrorFlag] = useState(false)
  const [pageObjectFlag, setPageObjectFlag] = useState(false)
  const [imageAltFlag, setImageAltFlag] = useState(false)
  const [noFollowFlag, setNoFollowFlag] = useState(false)
  const [imageCacheFlag, setImageCacheFlag] = useState(false)
  const [cachePolicyFlag, setCachePolicyFlag] = useState(false)
  const [jsCacheFlag, setJsCacheFlag] = useState(false)
  const [dnsSecurityFlag, setDnsSecurityFlag] = useState(false)
  const [adsTxtFlag, setAdsTxtFlag] = useState(false)
  const [seoUrlAnalysisFlag, setSeoUrlAnalysisFlag] = useState(false)
  const [unsafeCrossOriginLinksFlag, setUnsafeCrossOriginLinksFlag] = useState(false)
  const [domSizeFlag, setDomSizeFlag] = useState(false)
  const [mordenImageFlag, setMordenImageFlag] = useState(false)
  const [renderBlockingFlag, setRenderBlockingFlag] = useState(false)
  const [consoleErrorsFlag, setConsoleErrorsFlag] = useState(false)
  const [advancedConsoleErrorsFlag, setAdvancedConsoleErrorsFlag] = useState(false)
  const [aspectRatioFlag, setAspectRatioFlag] = useState(false)
  const [responsiveImageFlag, setResponsiveImageFlag] = useState(false)
  const [siteMapDataFlag, setSiteMapDataFlag] = useState(false)

  const [commonSeoSCounter, setCommonSeoSCounter] = useState(0)
  const [commonSeoWCounter, setCommonSeoWCounter] = useState(0)
  const [commonSeoFCounter, setCommonSeoFCounter] = useState(0)

  const [speedOptimizeSCounter, setSpeedOptimizeSCounter] = useState(0)
  const [speedOptimizeWCounter, setSpeedOptimizeWCounter] = useState(0)
  const [speedOptimizeFCounter, setSpeedOptimizeFCounter] = useState(0)

  const [serverSCounter, setServerSCounter] = useState(0)
  const [serverWCounter, setServerWCounter] = useState(0)
  const [serverFCounter, setServerFCounter] = useState(0)

  const [mobileUsabilitySCounter, setMobileUsabilitySCounter] = useState(0)
  const [mobileUsabilityWCounter, setMobileUsabilityWCounter] = useState(0)
  const [mobileUsabilityFCounter, setMobileUsabilityFCounter] = useState(0)

  const [advanceSeoSCounter, setAdvanceSeoSCounter] = useState(0)
  const [advanceSeoWCounter, setAdvanceSeoWCounter] = useState(0)
  const [advanceSeoFCounter, setAdvanceSeoFCounter] = useState(0)

  const [showContactUs, setShowContactUs] = useState(false)
  const [userData, setUserData] = useState([])

  const [isLighthouseRateLimitExceed, setIsLighthouseRateLimitExceed] = useState(false)
  const [openQuickScanLimitExceed ,setOpenQuickScanLimitExceed] = useState(false)


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const palette = usePalette()
  const interval2 = useRef()
  let url = window.location.href
  url = url?.split("=")?.[1]

  const setUrl = (data) => {
      if (data && data.startsWith('https://')){
        return data
      }else{
        return `https://${data}`
      }

  }

  useEffect(() => {
    if(lighthouseForQuickseoLoader){
      if(urlData && Object.keys(urlData)?.length){
        clearInterval(interval2.current)
        dispatch({type: CLEAR_QUICK_SEO_CHECKUP})
        dispatch({type: CLEAR_GET_QUICK_CHECKUP_REPORT})
      }else{
        return;
      }
    }else{
      if(Object.keys(lighthouseQuickSeoStatus)?.length){
        if(lighthouseQuickSeoStatus?.statusCode === 200 || lighthouseQuickSeoStatus?.statusCode === 202){
          if(lighthouseInterval.current){
            return;
          }else{

            let url_For_Payload
            let queryString = new URLSearchParams(window.location.search)
            url_For_Payload = queryString.get('url')
            const payload_url = setUrl(quickSeoURL || url_For_Payload)
            lighthouseInterval.current = setInterval(() => {
              dispatch(getLighthouseStatus({url: payload_url}))
            }, 10000)
          }
        }else{
          if(lighthouseQuickSeoStatus?.statusCode === 429){
            setIsLighthouseRateLimitExceed(true)
          }else{
            dispatch(openModalLighthouse(true))
          }
        }
      }
    }

  }, [lighthouseQuickSeoStatus, lighthouseForQuickseoLoader, quickSeoURL])

  useEffect(()=>{
        
    if(Object?.keys(lightHouseStatusData)?.length !== 0 &&
      lightHouseStatusData?.statusCode !== 200 && 
      lightHouseStatusData?.statusCode !== 429 && 
      lightHouseStatusData?.statusCode !== 202 ){

      (Object.keys(lightHouseStatusData)?.length !== 0 && lightHouseStatusData?.statusCode !== 200 && lightHouseStatusData?.statusCode !== 429) && dispatch(openModalLighthouse(true))
    }

    if(Object.keys(lightHouseStatusData)?.length !== 0 &&
      lightHouseStatusData?.statusCode === 429){
        setIsLighthouseRateLimitExceed(true)
    }

    if(Object.keys(lightHouseStatusData)?.length !== 0 &&
    lightHouseStatusData?.statusCode === 200){
      const payload_url = setUrl( quickSeoURL || url)
      dispatch(quickSeoCheckup({ url: payload_url, keywords: [] }))
    }

  },[lightHouseStatusData])

  useEffect(() => {

    if(Object.keys(lightHouseStatusData)?.length !== 0){

      if(lightHouseStatusData?.statusCode === 500 || 
        lightHouseStatusData?.statusCode === 200
        ){
          clearInterval(lighthouseInterval.current)
          lighthouseInterval.current = null
        }
      if(lightHouseStatusData?.statusCode === 202){
        return
      }
    }  
  }, [lightHouseStatusData, lightHouseStatusData?.statusCode])

  useEffect(() => {
    return () => {
      clearInterval(lighthouseInterval.current)
    }
  }, [])

  useEffect(() => {
    if(!supportRequestLoader && (toastError || toastMessage)){
      dispatch(openModalLighthouse(false))
      dispatch(setStartScan(false))
      dispatch(ifwebSiteData(false))
      dispatch(lightHouseSuccessClear())
      dispatch({ type: 'CLEAR_URL_DETAILS' })
      
      setTimeout(() =>{
        setShowContactUs(false)
        navigate(paths.mainDashboard)
      }, 500)

      setTimeout(() => {
        dispatch({type: "CLEAR_SUPPORT_REQUEST"})
      }, 1500)
    }
  }, [supportRequestLoader])

  useEffect(() => {
    let url
    if (quickSEOUrl) {
      url = quickSEOUrl
    } else {
      let queryString = new URLSearchParams(window.location.search)
      url = queryString.get('url')
    }
    if (url) {
      let queryString = new URLSearchParams(window.location.search)
      let url = queryString.get('url')
      if (quickSeoCheckupStart) {
        clearInterval(interval2.current)
        interval2.current = null
      }

      patchDomains()
      if (quickSeoSuccess) {
        dispatch(getQuickCheckupReport(setUrl( quickSeoURL || url)))
      }
    }

    return () => {
      clearInterval(interval2.current)
      interval2.current = null
    }
  }, [quickSEOUrl, quickSeoSuccess, quickSeoCheckupStart])

  useEffect(() => {
    const totalCount = positiveCounter + warningCounter + negativeCounter
    const totalPercent = (totalCount / 72) * 100

    if(totalPercent >= 100){
      clearInterval(interval2.current)
      interval2.current = null
    }
  }, [positiveCounter, negativeCounter, warningCounter])

  const patchDomains = () => {

    if (interval2.current) {
      return
    }
    let url

    if (quickSEOUrl) {
      url = quickSEOUrl
    } else {
      let queryString = new URLSearchParams(window.location.search)
      url = queryString.get('url')
    }
    if (url && quickSeoSuccess) {
      interval2.current = setInterval(() => {
        if (quickSeoSuccess) {
          dispatch(getQuickCheckupReport(setUrl( quickSeoURL || url)))
        }
      }, 12000)

      dispatch({type: CLEAR_GET_LIGHTHOUSE_QUICK_SEO})
      dispatch({type: CLEAR_GET_LIGHTHOUSE_STATUS})
      setIsLighthouseRateLimitExceed(false)
    }

  }

  useEffect(() => {
    return () => {
      clearInterval(interval2.current)
      dispatch({type: CLEAR_QUICK_SEO_CHECKUP})
      dispatch({ type: CLEAR_GET_QUICK_CHECKUP_REPORT })
    }
  }, [])

  const executeScroll = (id) => {
    const elementId = document?.getElementById(id)
    elementId?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    })
  }

  useEffect(() => {
    // setErrors([])

    let updatedErrorsArray = []
    let passedTestArray = []
    let failedTestArray = []
    let warningTestArray = []
    let positiveCounter = 0,
      negativeCounter = 0
    let warningCounter = 0,
      commonSeoS = 0,
      commonSeoW = 0,
      commonSeoF = 0,
      speedOptS = 0,
      speedOptW = 0,
      speedOptF = 0,
      serverS = 0,
      serverW = 0,
      serverF = 0,
      mobileUseS = 0,
      mobileUseW = 0,
      mobileUseF = 0,
      advanceS = 0,
      advanceW = 0,
      advanceF = 0
    if (urlData) {
      let data = urlData
      let isUrlData = true
      if (data?.metaData?.data?.response?.description?.descriptionStatus === 1) {
        positiveCounter++
        passedTestArray.push('meta-description')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.metaData?.data?.response?.description?.descriptionStatus === 2) {
        warningCounter++
        warningTestArray.push('meta-description')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaData?.data?.response?.description?.descriptionStatus,
              id: 'meta-description',
              message: data?.metaData?.data?.response?.description?.descriptionMessage,
            },
          ]
        }
      } else if (data?.metaData?.data?.response?.description?.descriptionStatus === 3) {
        negativeCounter++
        failedTestArray.push('meta-description')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaData?.data?.response?.description?.descriptionStatus,
              id: 'meta-description',
              message: data?.metaData?.data?.response?.description?.descriptionMessage,
            },
          ]
        }
      }

      if (data?.metaData?.data?.response?.title?.titleStatus === 1) {
        positiveCounter++
        passedTestArray.push('meta-title')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.metaData?.data?.response?.title?.titleStatus === 2) {
        warningCounter++
        warningTestArray.push('meta-title')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaData?.data?.response?.title?.titleStatus,
              id: 'meta-title',
              message: data?.metaData?.data?.response?.title?.titleMessage,
            },
          ]
        }
      } else if (data?.metaData?.data?.response?.title?.titleStatus === 3) {
        negativeCounter++
        failedTestArray.push('meta-title')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaData?.data?.response?.title?.titleStatus,
              id: 'meta-title',
              message: data?.metaData?.data?.response?.title?.titleMessage,
            },
          ]
        }
      }

      //social media Test
      if (data?.microDataSchema?.data?.metaTagsResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('social-media-meta-tag')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.microDataSchema?.data?.metaTagsResults?.status === 2) {
        warningCounter++
        warningTestArray.push('social-media-meta-tag')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.microDataSchema?.data?.metaTagsResults?.status,
              id: 'social-media-meta-tag',
              message: data?.microDataSchema?.data?.metaTagsResults?.message,
            },
          ]
        }
      } else if (data?.microDataSchema?.data?.metaTagsResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('social-media-meta-tag')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.microDataSchema?.data?.metaTagsResults?.status,
              id: 'social-media-meta-tag',
              message: data?.microDataSchema?.data?.metaTagsResults?.message,
            },
          ]
        }
      }

      // keyword-usage-test
      if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('keyword-usage-test')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 2) {
        warningCounter++
        warningTestArray.push('keyword-usage-test')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
              id: 'keyword-usage-test',
              message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
            },
          ]
        }
      } else if (data?.microDataSchema?.data?.keywordsUsageTestResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('keyword-usage-test')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.microDataSchema?.data?.keywordsUsageTestResults?.status,
              id: 'keyword-usage-test',
              message: data?.microDataSchema?.data?.keywordsUsageTestResults?.message,
            },
          ]
        }
      }

      // seo-friendly
      if (data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length === 0) {
        positiveCounter++
        passedTestArray.push('seo-friendly')
        if (isUrlData) {
          commonSeoS++
        }
      } else {
        negativeCounter++
        failedTestArray.push('seo-friendly')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: 3,
              id: 'seo-friendly',
              message: 'This webpage contains URLs that are not SEO friendly!',
            },
          ]
        }
      }

      // sitemaps
      if (data?.siteMapData?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('sitemap-data')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.siteMapData?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('sitemap-data')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.siteMapData?.data?.testResults?.status,
              id: 'sitemap-data',
              message: data?.siteMapData?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.siteMapData?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('sitemap-data')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.siteMapData?.data?.testResults?.status,
              id: 'sitemap-data',
              message: data?.siteMapData?.data?.testResults?.message,
            },
          ]
        }
      }

      // robots
      if (data?.metaData?.data?.response?.robotsTestResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('robots-txt')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.metaData?.data?.response?.robotsTestResults?.status === 2) {
        warningCounter++
        warningTestArray.push('robots-txt')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaData?.data?.response?.robotsTestResults?.status,
              id: 'robots-txt',
              message: data?.metaData?.data?.response?.robotsTestResults?.message,
            },
          ]
        }
      } else if (data?.metaData?.data?.response?.robotsTestResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('robots-txt')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaData?.data?.response?.robotsTestResults?.status,
              id: 'robots-txt',
              message: data?.metaData?.data?.response?.robotsTestResults?.message,
            },
          ]
        }
      }

      // cdn-usage
      if (data?.cdnUsageData?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('cdn-usage')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.cdnUsageData?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('cdn-usage')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cdnUsageData?.data?.testResult?.status,
              id: 'cdn-usage',
              message: data?.cdnUsageData?.data?.testResult?.message,
            },
          ]
        }
      } else if (data?.cdnUsageData?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('cdn-usage')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cdnUsageData?.data?.testResult?.status,
              id: 'cdn-usage',
              message: data?.cdnUsageData?.data?.testResult?.message,
            },
          ]
        }
      }

      // cssAnalysis
      if (data?.cssAnalysis?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('css-analysis')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.cssAnalysis?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('css-analysis')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cssAnalysis?.data?.testResults?.status,
              id: 'css-analysis',
              message: data?.cssAnalysis?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.cssAnalysis?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('css-analysis')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cssAnalysis?.data?.testResults?.status,
              id: 'css-analysis',
              message: data?.cssAnalysis?.data?.testResults?.message,
            },
          ]
        }
      }

      // cssCache
      if (data?.cssCache?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('css-cache')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.cssCache?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('css-cache')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cssCache?.data?.testResults?.status,
              id: 'css-cache',
              message: data?.cssCache?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.cssCache?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('css-cache')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cssCache?.data?.testResults?.status,
              id: 'css-cache',
              message: data?.cssCache?.data?.testResults?.message,
            },
          ]
        }
      }

      // cssMinification
      if (data?.cssMinification?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('css-minification')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.cssMinification?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('css-minification')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cssMinification?.data?.testResults?.status,
              id: 'css-minification',
              message: data?.cssMinification?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.cssMinification?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('css-minification')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cssMinification?.data?.testResults?.status,
              id: 'css-minification',
              message: data?.cssMinification?.data?.testResults?.message,
            },
          ]
        }
      }

      // jsMinification
      if (data?.jsMinification?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('js-minification')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.jsMinification?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('js-minification')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.jsMinification?.data?.testResults?.status,
              id: 'js-minification',
              message: data?.jsMinification?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.jsMinification?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('js-minification')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.jsMinification?.data?.testResults?.status,
              id: 'js-minification',
              message: data?.jsMinification?.data?.testResults?.message,
            },
          ]
        }
      }

      // jsCache
      if (data?.jsCache?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('js-cache')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.jsCache?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('js-cache')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.jsCache?.data?.testResults?.status,
              id: 'js-cache',
              message: data?.jsCache?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.jsCache?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('js-cache')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.jsCache?.data?.testResults?.status,
              id: 'js-cache',
              message: data?.jsCache?.data?.testResults?.message,
            },
          ]
        }
      }

      // imageCache
      if (data?.imageCache?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('image-cache')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.imageCache?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('image-cache')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.imageCache?.data?.testResults?.status,
              id: 'image-cache',
              message: data?.imageCache?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.imageCache?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('image-cache')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.imageCache?.data?.testResults?.status,
              id: 'image-cache',
              message: data?.imageCache?.data?.testResults?.message,
            },
          ]
        }
      }
      // cachePolicy
      if (data?.cachePolicy?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('cache-policy')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.cachePolicy?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('cache-policy')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cachePolicy?.data?.testResults?.status,
              id: 'cache-policy',
              message: data?.cachePolicy?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.cachePolicy?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('cache-policy')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cachePolicy?.data?.testResults?.status,
              id: 'cache-policy',
              message: data?.cachePolicy?.data?.testResults?.message,
            },
          ]
        }
      }

      // imageAlt
      if (data?.imageAlt?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('image-alt')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.imageAlt?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('image-alt')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.imageAlt?.data?.testResults?.status,
              id: 'image-alt',
              message: data?.imageAlt?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.imageAlt?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('image-alt')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.imageAlt?.data?.testResults?.status,
              id: 'image-alt',
              message: data?.imageAlt?.data?.testResults?.message,
            },
          ]
        }
      }

      // descriptiveTest
      if (data?.descriptiveLinkTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('descriptive-test')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.descriptiveLinkTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('descriptive-test')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.descriptiveLinkTest?.data?.testResults?.status,
              id: 'descriptive-test',
              message: data?.descriptiveLinkTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.descriptiveLinkTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('descriptive-test')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.descriptiveLinkTest?.data?.testResults?.status,
              id: 'descriptive-test',
              message: data?.descriptiveLinkTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // headingAnalysis
      if (data?.headingAnalysis?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('heading-analysis')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.headingAnalysis?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('heading-analysis')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.headingAnalysis?.data?.testResults?.status,
              id: 'heading-analysis',
              message: data?.headingAnalysis?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.headingAnalysis?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('heading-analysis')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.headingAnalysis?.data?.testResults?.status,
              id: 'heading-analysis',
              message: data?.headingAnalysis?.data?.testResults?.message,
            },
          ]
        }
      }

      // docType
      if (data?.docType?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('doc-type')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.docType?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('doc-type')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.docType?.data?.testResults?.status,
              id: 'doc-type',
              message: data?.docType?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.docType?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('doc-type')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.docType?.data?.testResults?.status,
              id: 'doc-type',
              message: data?.docType?.data?.testResults?.message,
            },
          ]
        }
      }

      // redirect-test
      if (data?.redirectTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('redirect-test')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.redirectTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('redirect-test')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.redirectTest?.data?.testResults?.status,
              id: 'redirect-test',
              message: data?.redirectTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.redirectTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('redirect-test')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.redirectTest?.data?.testResults?.status,
              id: 'redirect-test',
              message: data?.redirectTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // faviconCheckerTest
      if (data?.faviconCheckerTest?.data?.status === 1) {
        positiveCounter++
        passedTestArray.push('favicon-test')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.faviconCheckerTest?.data?.status === 2) {
        warningCounter++
        warningTestArray.push('favicon-test')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.faviconCheckerTest?.data?.status,
              id: 'favicon-test',
              message: data?.faviconCheckerTest?.data?.message,
            },
          ]
        }
      } else if (data?.faviconCheckerTest?.data?.status === 3) {
        negativeCounter++
        failedTestArray.push('favicon-test')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.faviconCheckerTest?.data?.status,
              id: 'favicon-test',
              message: data?.faviconCheckerTest?.data?.message,
            },
          ]
        }
      }

      // flash-test
      if (data?.flashTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('flash-test')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.flashTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('flash-test')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.flashTest?.data?.testResults?.status,
              id: 'flash-test',
              message: data?.flashTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.flashTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('flash-test')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.flashTest?.data?.testResults?.status,
              id: 'flash-test',
              message: data?.flashTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // html-page-size
      if (data?.htmlPageSize?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('html-page-size')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.htmlPageSize?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('html-page-size')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.htmlPageSize?.data?.testResults?.status,
              id: 'html-page-size',
              message: data?.htmlPageSize?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.htmlPageSize?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('html-page-size')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.htmlPageSize?.data?.testResults?.status,
              id: 'html-page-size',
              message: data?.htmlPageSize?.data?.testResults?.message,
            },
          ]
        }
      }

      // framesetTest
      if (data?.framesetTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('frameset-test')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.framesetTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('frameset-test')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.framesetTest?.data?.testResults?.status,
              id: 'frameset-test',
              message: data?.framesetTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.framesetTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('frameset-test')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.framesetTest?.data?.testResults?.status,
              id: 'frameset-test',
              message: data?.framesetTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // googleAnalytics
      if (data?.googleAnalytics?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('google-analytics')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.googleAnalytics?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('google-analytics')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.googleAnalytics?.data?.testResults?.status,
              id: 'google-analytics',
              message: data?.googleAnalytics?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.googleAnalytics?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('google-analytics')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.googleAnalytics?.data?.testResults?.status,
              id: 'google-analytics',
              message: data?.googleAnalytics?.data?.testResults?.message,
            },
          ]
        }
      }

      // htmlCompression
      if (data?.htmlCompression?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('html-compression')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.htmlCompression?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('html-compression')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.htmlCompression?.data?.testResults?.status,
              id: 'html-compression',
              message: data?.htmlCompression?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.htmlCompression?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('html-compression')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.htmlCompression?.data?.testResults?.status,
              id: 'html-compression',
              message: data?.htmlCompression?.data?.testResults?.message,
            },
          ]
        }
      }

      // hsts
      if (data?.hstsTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('hsts')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.hstsTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('hsts')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.hstsTest?.data?.testResults?.status,
              id: 'hsts',
              message: data?.hstsTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.hstsTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('hsts')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.hstsTest?.data?.testResults?.status,
              id: 'hsts',
              message: data?.hstsTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // htmlValidation
      if (data?.htmlValidation?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('html-validation')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.htmlValidation?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('html-validation')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.htmlValidation?.data?.testResults?.status,
              id: 'html-validation',
              message: data?.htmlValidation?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.htmlValidation?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('html-validation')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.htmlValidation?.data?.testResults?.status,
              id: 'html-validation',
              message: data?.htmlValidation?.data?.testResults?.message,
            },
          ]
        }
      }

      // https-test
      if (data?.httpsTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('https-test')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.httpsTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('https-test')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.httpsTest?.data?.testResults?.status,
              id: 'https-test',
              message: data?.httpsTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.httpsTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('https-test')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.httpsTest?.data?.testResults?.status,
              id: 'https-test',
              message: data?.httpsTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // inline-css
      if (data?.inlineCss?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('inline-css')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.inlineCss?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('inline-css')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.inlineCss?.data?.testResults?.status,
              id: 'inline-css',
              message: data?.inlineCss?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.inlineCss?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('inline-css')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.inlineCss?.data?.testResults?.status,
              id: 'inline-css',
              message: data?.inlineCss?.data?.testResults?.message,
            },
          ]
        }
      }

      // js-error
      if (data?.jsError?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('js-error')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.jsError?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('js-error')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.jsError?.data?.testResults?.status,
              id: 'js-error',
              message: data?.jsError?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.jsError?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('js-error')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.jsError?.data?.testResults?.status,
              id: 'js-error',
              message: data?.jsError?.data?.testResults?.message,
            },
          ]
        }
      }

      // microdata-schema
      if (data?.microDataSchema?.data?.microSchemaResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('microdata-schema')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.microDataSchema?.data?.microSchemaResults?.status === 2) {
        warningCounter++
        warningTestArray.push('microdata-schema')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.microDataSchema?.data?.microSchemaResults?.status,
              id: 'microdata-schema',
              message: data?.microDataSchema?.data?.microSchemaResults?.message,
            },
          ]
        }
      } else if (data?.microDataSchema?.data?.microSchemaResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('microdata-schema')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.microDataSchema?.data?.microSchemaResults?.status,
              id: 'microdata-schema',
              message: data?.microDataSchema?.data?.microSchemaResults?.message,
            },
          ]
        }
      }

      // nested-table
      if (data?.nestedTable?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('nested-table')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.nestedTable?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('nested-table')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.nestedTable?.data?.testResults?.status,
              id: 'nested-table',
              message: data?.nestedTable?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.nestedTable?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('nested-table')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.nestedTable?.data?.testResults?.status,
              id: 'nested-table',
              message: data?.nestedTable?.data?.testResults?.message,
            },
          ]
        }
      }

      // no-index
      if (data?.noIndexChecker?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('no-index')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.noIndexChecker?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('no-index')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.noIndexChecker?.data?.testResult?.status,
              id: 'no-index',
              message: data?.noIndexChecker?.data?.testResult?.message,
            },
          ]
        }
      } else if (data?.noIndexChecker?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('no-index')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.noIndexChecker?.data?.testResult?.status,
              id: 'no-index',
              message: data?.noIndexChecker?.data?.testResult?.message,
            },
          ]
        }
      }

      // canonical-url
      if (data?.canonicalUrl?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('canonical-url')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.canonicalUrl?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('canonical-url')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.canonicalUrl?.data?.testResult?.status,
              id: 'canonical-url',
              message: data?.canonicalUrl?.data?.testResult?.message,
            },
          ]
        }
      } else if (data?.canonicalUrl?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('canonical-url')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.canonicalUrl?.data?.testResult?.status,
              id: 'canonical-url',
              message: data?.canonicalUrl?.data?.testResult?.message,
            },
          ]
        }
      }

      // page-cache
      if (data?.pageCache?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('page-cache')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.pageCache?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('page-cache')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.pageCache?.data?.testResults?.status,
              id: 'page-cache',
              message: data?.pageCache?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.pageCache?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('page-cache')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.pageCache?.data?.testResults?.status,
              id: 'page-cache',
              message: data?.pageCache?.data?.testResults?.message,
            },
          ]
        }
      }

      // page-object
      if (data?.pageObject?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('page-object')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.pageObject?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('page-object')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.pageObject?.data?.testResults?.status,
              id: 'page-object',
              message: data?.pageObject?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.pageObject?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('page-object')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.pageObject?.data?.testResults?.status,
              id: 'page-object',
              message: data?.pageObject?.data?.testResults?.message,
            },
          ]
        }
      }

      // plain-text
      if (data?.plainTextEmail?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('plain-text')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.plainTextEmail?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('plain-text')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.plainTextEmail?.data?.testResults?.status,
              id: 'plain-text',
              message: data?.plainTextEmail?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.plainTextEmail?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('plain-text')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.plainTextEmail?.data?.testResults?.status,
              id: 'plain-text',
              message: data?.plainTextEmail?.data?.testResults?.message,
            },
          ]
        }
      }

      // safe-browsing
      if (data?.safeBrowsing?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('safe-browsing')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.safeBrowsing?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('safe-browsing')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.safeBrowsing?.data?.testResults?.status,
              id: 'safe-browsing',
              message: data?.safeBrowsing?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.safeBrowsing?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('safe-browsing')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.safeBrowsing?.data?.testResults?.status,
              id: 'safe-browsing',
              message: data?.safeBrowsing?.data?.testResults?.message,
            },
          ]
        }
      }

      // serverSignature
      if (data?.serverSignature?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('server-signature')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.serverSignature?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('server-signature')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.serverSignature?.data?.testResults?.status,
              id: 'server-signature',
              message: data?.serverSignature?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.serverSignature?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('server-signature')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.serverSignature?.data?.testResults?.status,
              id: 'server-signature',
              message: data?.serverSignature?.data?.testResults?.message,
            },
          ]
        }
      }

      // site-speed
      if (data?.siteSpeed?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('site-speed')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.siteSpeed?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('site-speed')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.siteSpeed?.data?.testResults?.status,
              id: 'site-speed',
              message: data?.siteSpeed?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.siteSpeed?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('site-speed')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.siteSpeed?.data?.testResults?.status,
              id: 'site-speed',
              message: data?.siteSpeed?.data?.testResults?.message,
            },
          ]
        }
      }

      // social-media
      if (data?.socialMediaTwitterData?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('social-media')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.socialMediaTwitterData?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('social-media')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.socialMediaTwitterData?.data?.testResults?.status,
              id: 'social-media',
              message: data?.socialMediaTwitterData?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.socialMediaTwitterData?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('social-media')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.socialMediaTwitterData?.data?.testResults?.status,
              id: 'social-media',
              message: data?.socialMediaTwitterData?.data?.testResults?.message,
            },
          ]
        }
      }

      // spf-record
      if (data?.spfRecord?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('spf-record')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.spfRecord?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('spf-record')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.spfRecord?.data?.testResult?.status,
              id: 'spf-record',
              message: data?.spfRecord?.data?.testResult?.message,
            },
          ]
        }
      } else if (data?.spfRecord?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('spf-record')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.spfRecord?.data?.testResult?.status,
              id: 'spf-record',
              message: data?.spfRecord?.data?.testResult?.message,
            },
          ]
        }
      }

      // url-canonicalization
      if (data?.urlCanonicalization?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('url-canonicalization')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.urlCanonicalization?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('url-canonicalization')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.urlCanonicalization?.data?.testResult?.status,
              id: 'url-canonicalization',
              message: data?.urlCanonicalization?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.urlCanonicalization?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('url-canonicalization')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.urlCanonicalization?.data?.testResult?.status,
              id: 'url-canonicalization',
              message: data?.urlCanonicalization?.data?.testResults?.message,
            },
          ]
        }
      }

      // brotil-test
      if (data?.brotilTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('brotil-test')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.brotilTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('brotil-test')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.brotilTest?.data?.testResults?.status,
              id: 'brotil-test',
              message: data?.brotilTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.brotilTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('brotil-test')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.brotilTest?.data?.testResults?.status,
              id: 'brotil-test',
              message: data?.brotilTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // http2-test
      if (data?.http2Test?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('http2-test')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.http2Test?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('http2-test')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.http2Test?.data?.testResults?.status,
              id: 'http2-test',
              message: data?.http2Test?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.http2Test?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('http2-test')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.http2Test?.data?.testResults?.status,
              id: 'http2-test',
              message: data?.http2Test?.data?.testResults?.message,
            },
          ]
        }
      }

      // ssl-freak
      if (data?.sslFreakTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('ssl-freak')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.sslFreakTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('ssl-freak')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.sslFreakTest?.data?.testResults?.status,
              id: 'ssl-freak',
              message: data?.sslFreakTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.sslFreakTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('ssl-freak')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.sslFreakTest?.data?.testResults?.status,
              id: 'ssl-freak',
              message: data?.sslFreakTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // tls-logjam
      if (data?.tlsLogjamTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('tls-logjam')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.tlsLogjamTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('tls-logjam')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.tlsLogjamTest?.data?.testResults?.status,
              id: 'tls-logjam',
              message: data?.tlsLogjamTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.tlsLogjamTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('tls-logjam')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.tlsLogjamTest?.data?.testResults?.status,
              id: 'tls-logjam',
              message: data?.tlsLogjamTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // render-blocking
      if (data?.renderBlocking?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('render-blocking')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.renderBlocking?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('render-blocking')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.renderBlocking?.data?.testResults?.status,
              id: 'render-blocking',
              message: data?.renderBlocking?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.renderBlocking?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('render-blocking')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.renderBlocking?.data?.testResults?.status,
              id: 'render-blocking',
              message: data?.renderBlocking?.data?.testResults?.message,
            },
          ]
        }
      }

      // unsafe-cross-origin-links
      if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('unsafe-cross-origin-links')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('unsafe-cross-origin-links')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
              id: 'unsafe-cross-origin-links',
              message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('unsafe-cross-origin-links')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.unsafeCrossOriginLinksTest?.data?.testResults?.status,
              id: 'unsafe-cross-origin-links',
              message: data?.unsafeCrossOriginLinksTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // morden-image
      if (data?.mordenImage?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('morden-image')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.mordenImage?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('morden-image')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.mordenImage?.data?.testResults?.status,
              id: 'morden-image',
              message: data?.mordenImage?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.mordenImage?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('morden-image')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.mordenImage?.data?.testResults?.status,
              id: 'morden-image',
              message: data?.mordenImage?.data?.testResults?.message,
            },
          ]
        }
      }

      // aspect-ratio
      if (data?.aspectRatio?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('aspect-ratio')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.aspectRatio?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('aspect-ratio')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.aspectRatio?.data?.testResults?.status,
              id: 'aspect-ratio',
              message: data?.aspectRatio?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.aspectRatio?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('aspect-ratio')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.aspectRatio?.data?.testResults?.status,
              id: 'aspect-ratio',
              message: data?.aspectRatio?.data?.testResults?.message,
            },
          ]
        }
      }

      // responsive-image
      if (data?.responsiveImage?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('responsive-image')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.responsiveImage?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('responsive-image')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.responsiveImage?.data?.testResults?.status,
              id: 'responsive-image',
              message: data?.responsiveImage?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.responsiveImage?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('responsive-image')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.responsiveImage?.data?.testResults?.status,
              id: 'responsive-image',
              message: data?.responsiveImage?.data?.testResults?.message,
            },
          ]
        }
      }

      // console errors
      if (data?.consoleErrors?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('console-errors')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.consoleErrors?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('console-errors')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.consoleErrors?.data?.testResults?.status,
              id: 'console-errors',
              message: data?.consoleErrors?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.consoleErrors?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('console-errors')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.consoleErrors?.data?.testResults?.status,
              id: 'console-errors',
              message: data?.consoleErrors?.data?.testResults?.message,
            },
          ]
        }
      }

      // advanced console errors
      if (data?.advancedConsoleErrors?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('advanced-console-errors')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.advancedConsoleErrors?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('advanced-console-errors')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.advancedConsoleErrors?.data?.testResults?.status,
              id: 'advanced-console-errors',
              message: data?.advancedConsoleErrors?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.advancedConsoleErrors?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('advanced-console-errors')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.advancedConsoleErrors?.data?.testResults?.status,
              id: 'advanced-console-errors',
              message: data?.advancedConsoleErrors?.data?.testResults?.message,
            },
          ]
        }
      }

      //  dom-size
      if (data?.domSize?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('dom-size')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.domSize?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('dom-size')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.domSize?.data?.testResults?.status,
              id: 'dom-size',
              message: data?.domSize?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.domSize?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('dom-size')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.domSize?.data?.testResults?.status,
              id: 'dom-size',
              message: data?.domSize?.data?.testResults?.message,
            },
          ]
        }
      }

      // ads-txt
      if (data?.adsTxt?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('ads-txt')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.adsTxt?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('ads-txt')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.adsTxt?.data?.testResults?.status,
              id: 'ads-txt',
              message: data?.adsTxt?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.adsTxt?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('ads-txt')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.adsTxt?.data?.testResults?.status,
              id: 'dom-size',
              message: data?.adsTxt?.data?.testResults?.message,
            },
          ]
        }
      }

      // js-execution-time
      if (data?.bootupTime?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('js-execution-time')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.bootupTime?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('js-execution-time')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.bootupTime?.data?.testResults?.status,
              id: 'js-execution-time',
              message: data?.bootupTime?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.bootupTime?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('js-execution-time')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.bootupTime?.data?.testResults?.status,
              id: 'js-execution-time',
              message: data?.bootupTime?.data?.testResults?.message,
            },
          ]
        }
      }

      // characterSet
      if (data?.characterSet?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('character-set')
        if (isUrlData) {
          commonSeoS++
        }
      } else if (data?.characterSet?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('character-set')
        if (isUrlData) {
          commonSeoW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.characterSet?.data?.testResults?.status,
              id: 'character-set',
              message: data?.characterSet?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.characterSet?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('character-set')
        if (isUrlData) {
          commonSeoF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.characterSet?.data?.testResults?.status,
              id: 'character-set',
              message: data?.characterSet?.data?.testResults?.message,
            },
          ]
        }
      }

      // viewport
      if (data?.viewport?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('viewport')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.viewport?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('viewport')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.viewport?.data?.testResults?.status,
              id: 'viewport',
              message: data?.viewport?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.viewport?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('viewport')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.viewport?.data?.testResults?.status,
              id: 'viewport',
              message: data?.viewport?.data?.testResults?.message,
            },
          ]
        }
      }
      //font-size
      if (data?.fontSizeTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('font-size')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.fontSizeTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('font-size')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.fontSizeTest?.data?.testResults?.status,
              id: 'font-size',
              message: data?.fontSizeTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('font-size')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.fontSizeTest?.data?.testResults?.status,
              id: 'font-size',
              message: data?.fontSizeTest?.data?.testResults?.message,
            },
          ]
        }
      }

      //tapTargetTest
      if (data?.tapTargetTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('tap-target')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.tapTargetTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('tap-target')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.tapTargetTest?.data?.testResults?.status,
              id: 'tap-target',
              message: data?.tapTargetTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('tap-target')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.tapTargetTest?.data?.testResults?.status,
              id: 'tap-target',
              message: data?.tapTargetTest?.data?.testResults?.message,
            },
          ]
        }
      }

      //contentWidthTest
      if (data?.contentWidthTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('content-width')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.contentWidthTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('content-width')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.contentWidthTest?.data?.testResults?.status,
              id: 'content-width',
              message: data?.contentWidthTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('content-width')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.contentWidthTest?.data?.testResults?.status,
              id: 'content-width',
              message: data?.contentWidthTest?.data?.testResults?.message,
            },
          ]
        }
      }

      //speedIndexTest
      if (data?.speedIndexTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('speed-index')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.speedIndexTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('speed-index')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.speedIndexTest?.data?.testResults?.status,
              id: 'speed-index',
              message: data?.speedIndexTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('speed-index')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.speedIndexTest?.data?.testResults?.status,
              id: 'speed-index',
              message: data?.speedIndexTest?.data?.testResults?.message,
            },
          ]
        }
      }

      //firstContentfulPaintTest
      if (data?.firstContentfulPaintTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('first-contentful-paint')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.firstContentfulPaintTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('first-contentful-paint')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.firstContentfulPaintTest?.data?.testResults?.status,
              id: 'first-contentful-paint',
              message: data?.firstContentfulPaintTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('first-contentful-paint')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.firstContentfulPaintTest?.data?.testResults?.status,
              id: 'first-contentful-paint',
              message: data?.firstContentfulPaintTest?.data?.testResults?.message,
            },
          ]
        }
      }

      //largestContentfulPaintTest
      if (data?.largestContentfulPaintTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('last-contentful-paint')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.largestContentfulPaintTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('last-contentful-paint')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.largestContentfulPaintTest?.data?.testResults?.status,
              id: 'last-contentful-paint',
              message: data?.largestContentfulPaintTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('last-contentful-paint')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.largestContentfulPaintTest?.data?.testResults?.status,
              id: 'last-contentful-paint',
              message: data?.largestContentfulPaintTest?.data?.testResults?.message,
            },
          ]
        }
      }
      //cumulativeLayoutShiftTest
      if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('cumulative-layout-shift-test')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.cumulativeLayoutShiftTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('cumulative-layout-shift-test')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cumulativeLayoutShiftTest?.data?.testResults?.status,
              id: 'cumulative-layout-shift-test',
              message: data?.cumulativeLayoutShiftTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('cumulative-layout-shift-test')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.cumulativeLayoutShiftTest?.data?.testResults?.status,
              id: 'cumulative-layout-shift-test',
              message: data?.cumulativeLayoutShiftTest?.data?.testResults?.message,
            },
          ]
        }
      }
      //interactiveTest
      if (data?.interactiveTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('interactive-test')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.interactiveTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('interactive-test')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.interactiveTest?.data?.testResults?.status,
              id: 'interactive-test',
              message: data?.interactiveTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('interactive-test')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.interactiveTest?.data?.testResults?.status,
              id: 'interactive-test',
              message: data?.interactiveTest?.data?.testResults?.message,
            },
          ]
        }
      }
      //firstInputDelayTest
      if (data?.firstInputDelayTest?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('first-input-delay')
        if (isUrlData) {
          mobileUseS++
        }
      } else if (data?.firstInputDelayTest?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('first-input-delay')
        if (isUrlData) {
          mobileUseW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.firstInputDelayTest?.data?.testResults?.status,
              id: 'first-input-delay',
              message: data?.firstInputDelayTest?.data?.testResults?.message,
            },
          ]
        }
      } else {
        negativeCounter++
        failedTestArray.push('first-input-delay')
        if (isUrlData) {
          mobileUseF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.firstInputDelayTest?.data?.testResults?.status,
              id: 'first-input-delay',
              message: data?.firstInputDelayTest?.data?.testResults?.message,
            },
          ]
        }
      }

      // meta-refresh
      if (data?.metaRefresh?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('meta-refresh')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.metaRefresh?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('meta-refresh')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaRefresh?.data?.testResult?.status,
              id: 'meta-refresh',
              message: data?.metaRefresh?.data?.testResult?.message,
            },
          ]
        }
      } else if (data?.metaRefresh?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('meta-refresh')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.metaRefresh?.data?.testResult?.status,
              id: 'meta-refresh',
              message: data?.metaRefresh?.data?.testResult?.message,
            },
          ]
        }
      }

      // custom-404
      if (data?.custom404?.data?.testResult?.status === 1) {
        positiveCounter++
        passedTestArray.push('custom-404')
        if (isUrlData) {
          advanceS++
        }
      } else if (data?.custom404?.data?.testResult?.status === 2) {
        warningCounter++
        warningTestArray.push('custom-404')
        if (isUrlData) {
          advanceW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.custom404?.data?.testResult?.status,
              id: 'custom-404',
              message: data?.custom404?.data?.testResult?.message,
            },
          ]
        }
      } else if (data?.custom404?.data?.testResult?.status === 3) {
        negativeCounter++
        failedTestArray.push('custom-404')
        if (isUrlData) {
          advanceF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.custom404?.data?.testResult?.status,
              id: 'custom-404',
              message: data?.custom404?.data?.testResult?.message,
            },
          ]
        }
      }

      // layout-shift
      if (data?.layoutShift?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('layout-shift')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.layoutShift?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('layout-shift')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.layoutShift?.data?.testResults?.status,
              id: 'layout-shift',
              message: data?.layoutShift?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.layoutShift?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('layout-shift')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.layoutShift?.data?.testResults?.status,
              id: 'layout-shift',
              message: data?.layoutShift?.data?.testResults?.message,
            },
          ]
        }
      }

      // contentful-paint
      if (data?.contentfulPaint?.data?.testResults?.status === 1) {
        positiveCounter++
        passedTestArray.push('contentful-paint')
        if (isUrlData) {
          speedOptS++
        }
      } else if (data?.contentfulPaint?.data?.testResults?.status === 2) {
        warningCounter++
        warningTestArray.push('contentful-paint')
        if (isUrlData) {
          speedOptW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.contentfulPaint?.data?.testResults?.status,
              id: 'contentful-paint',
              message: data?.contentfulPaint?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.contentfulPaint?.data?.testResults?.status === 3) {
        negativeCounter++
        failedTestArray.push('contentful-paint')
        if (isUrlData) {
          speedOptF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.contentfulPaint?.data?.testResults?.status,
              id: 'contentful-paint',
              message: data?.contentfulPaint?.data?.testResults?.message,
            },
          ]
        }
      }

      // directory-browsing
      if (data?.directoryBrowsing?.data?.testResults?.status === 1) {
        // eslint-disable-next-line no-unused-vars
        positiveCounter++
        passedTestArray.push('directory-browsing')
        if (isUrlData) {
          serverS++
        }
      } else if (data?.directoryBrowsing?.data?.testResults?.status === 2) {
        // eslint-disable-next-line no-unused-vars
        warningCounter++
        warningTestArray.push('directory-browsing')
        if (isUrlData) {
          serverW++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.directoryBrowsing?.data?.testResults?.status,
              id: 'directory-browsing',
              message: data?.directoryBrowsing?.data?.testResults?.message,
            },
          ]
        }
      } else if (data?.directoryBrowsing?.data?.testResults?.status === 3) {
        // eslint-disable-next-line no-unused-vars
        negativeCounter++
        failedTestArray.push('directory-browsing')
        if (isUrlData) {
          serverF++
          updatedErrorsArray = [
            ...updatedErrorsArray,
            {
              priority: data?.directoryBrowsing?.data?.testResults?.status,
              id: 'directory-browsing',
              message: data?.directoryBrowsing?.data?.testResults?.message,
            },
          ]
        }
      }

      setWarningCounter(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)

      setCommonSeoSCounter(commonSeoS)
      setCommonSeoWCounter(commonSeoW)
      setCommonSeoFCounter(commonSeoF)

      setSpeedOptimizeSCounter(speedOptS)
      setSpeedOptimizeWCounter(speedOptW)
      setSpeedOptimizeFCounter(speedOptF)

      setServerSCounter(serverS)
      setServerWCounter(serverW)
      setServerFCounter(serverF)

      setMobileUsabilitySCounter(mobileUseS)
      setMobileUsabilityWCounter(mobileUseW)
      setMobileUsabilityFCounter(mobileUseF)

      setAdvanceSeoSCounter(advanceS)
      setAdvanceSeoWCounter(advanceW)
      setAdvanceSeoFCounter(advanceF)
      setErrors(updatedErrorsArray)

    }
  }, [urlData])

  const multiData = []

  multiData.push(urlData)

  const getDataToDisplay = (jsonld, index = 0, key) => {
    let keys = Object.keys(jsonld)
    let data = keys.map((innerkey, i) => {
      if (jsonld.hasOwnProperty(innerkey)) {
        if (typeof jsonld?.[innerkey] == 'object') {
          return getDataToDisplay(jsonld?.[innerkey], index + 1, innerkey)
        } else {
          return (
            <>
              <p key={`quickSeo-${i + 1}`}>
                {i === 0 ? (
                  <p style={{ paddingLeft: 20 * index }}>
                    <b>{key && key.toString().length > 2 ? key : ''}</b>{' '}
                  </p>
                ) : (
                  <></>
                )}
                <p style={{ paddingLeft: 20 * index }}>
                  <b>{innerkey}</b>:{' '}
                  {jsonld?.[innerkey].length > 100
                    ? jsonld?.[innerkey].substring(0, 50) + '...'
                    : jsonld?.[innerkey]}
                </p>
              </p>{' '}
              {i === keys.length - 1 ? <hr /> : <></>}{' '}
            </>
          )
        }
      } else {
        return null
      }
    })
    return data
  }

 
  const fetchLocalStorageData = async() => {
    const userData = await localStorage.getItem("userData")
    setUserData(JSON.parse(userData))
  }

  const supportRequestSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Phone number is required'),
    websiteUrl: Yup.string()
      .required("Please provide url")  
  });
  

  const phone = JSON.parse(localStorage.getItem("userData"))?.phoneNumber

  const initialValues = {
    phoneNumber: phone || userData?.phoneNumber,
    websiteUrl: url
  }
 
  const supportRequestFormik = useFormik({
    initialValues,
    validationSchema: supportRequestSchema,
    onSubmit: (values) => {
      const payload = {
        email: userData?.email, 
        name: `${userData?.firstName} ${userData?.lastName}`, 
        url: values?.websiteUrl, 
        phoneNo: values?.phoneNumber
      }
    
      dispatch(supportRequest(payload))
    }
  })

  return (
    <>
      <Container fluid className="p-0">
        {isModalOpenLighthouse || quickSeoFailed || getQuickSeoReportError ? <Modal
          show={isModalOpenLighthouse || quickSeoFailed || getQuickSeoReportError}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { }}
          style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
          className="br-15 pe-0"
        >
          <Modal.Body>
            "Oops! We couldn't initiate the analysis because the server returned a 'Bad Request' error. This usually happens if the URL is incorrect, inaccessible, if there's a formatting issue with the request or lighthouse server is being blocked by your firewall. Please verify the URL is correct, including the http:// or https:// prefix, and try again. If the problem persists, please contact our support team for assistance."
          </Modal.Body>
          <Modal.Footer className='d-flex flex-row justify-content-between'>
            <Button color="outline-primary" variant="outline-primary" onClick={() => {
              fetchLocalStorageData()
              setShowContactUs(true)
            }}>
              Contact us
            </Button>

            <Button
              color="primary"
              variant="primary"
              onClick={() => {
                navigate(paths.mainDashboard)
                dispatch(openModalLighthouse(false))
                dispatch(setStartScan(false))
                dispatch(ifwebSiteData(false))
                dispatch(lightHouseSuccessClear())
                dispatch({ type: 'CLEAR_URL_DETAILS' })
              }}
            >
              Start Over
            </Button>
          </Modal.Footer>
        </Modal> : null}

        {showContactUs ? (
            <Modal
              show={showContactUs}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {setShowContactUs(false)}}
              style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)'}}
              className="br-15"
              
            >
              <Modal.Header className='pb-0'>
                <h3>Support Requests - PMS SEO Site Signals</h3>
              </Modal.Header>
              <Modal.Body>
              <Form onSubmit={supportRequestFormik.handleSubmit}>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="userEmail">Email Address:</Form.Label>
                  <Form.Control
                    type='email'
                    id="userEmail"
                    name="userEmail"
                    placeholder="Enter your email"
                    disabled
                    onChange={supportRequestFormik.handleChange}
                    onBlur={supportRequestFormik.handleBlur}
                    value={userData?.email}
                  />
                  {supportRequestFormik.touched.userEmail && supportRequestFormik.errors.userEmail ? (
                    <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.userEmail}</span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="userName">Name:</Form.Label>
                  <Form.Control
                    type='text'
                    id="userName"
                    name="userName"
                    disabled
                    placeholder="Enter your name"
                    onChange={supportRequestFormik.handleChange}
                    onBlur={supportRequestFormik.handleBlur}
                    value={`${userData?.firstName} ${userData?.lastName}`}
                  />
                  {supportRequestFormik.touched.userName && supportRequestFormik.errors.userName ? (
                    <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.userName}</span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="websiteUrl">Website url:</Form.Label>
                  <Form.Control
                    type='text'
                    id="websiteUrl"
                    name="websiteUrl"
                    placeholder="Enter website url"
                    onChange={supportRequestFormik.handleChange}
                    onBlur={supportRequestFormik.handleBlur}
                    value={supportRequestFormik.values.websiteUrl}
                  />
                  {supportRequestFormik.touched.websiteUrl && supportRequestFormik.errors.websiteUrl ? (
                    <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.websiteUrl}</span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="phoneNumber">Phone No:</Form.Label>
                  <Form.Control
                    type='text'
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter your number"
                    onChange={supportRequestFormik.handleChange}
                    onBlur={supportRequestFormik.handleBlur}
                    value={supportRequestFormik.values.phoneNumber}
                  />
                  {supportRequestFormik.touched.phoneNumber && supportRequestFormik.errors.phoneNumber ? (
                    <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.phoneNumber}</span>
                  ) : null}
                </Form.Group>

                <div className='d-flex flex-row justify-content-between'>
                  <Button color="primary" variant="primary" onClick={supportRequestFormik?.handleSubmit}>
                    {
                      supportRequestLoader ?
                        <>
                          <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                            Send
                            <Spinner animation="border" style={{ marginBottom: '2px' }} key={1} size="sm" />
                          </div>
                        </> :
                        <>Send</>
                    }
                  </Button>
                  <Button variant="danger" color="danger" onClick={() => setShowContactUs(false)}>Cancel</Button>
                </div>
              </Form>
              </Modal.Body>
            </Modal>
          ) :
            null
          }

        <Row>
          <div className="report-wrapper">
            {urlData && urlData !== '' ? (
              <>
                <div className="report-summary">
                  <Row>
                    <Col lg="4" xl="4">
                      <h3 className="f-bold">{urlData?.url || ''}</h3>
                      <p>Your general SEO Checkup Score</p>
                    </Col>
                    <Col lg="4" xl="4">
                      <p> SEO Score: {Math.round(((positiveCounter + warningCounter) / 63) * 100)} </p>
                      <ProgressBar>
                        <ProgressBar
                          variant="danger"
                          now={getSEOScore(positiveCounter + warningCounter).red}
                          key={1}
                        />
                        <ProgressBar
                          variant="warning"
                          now={getSEOScore(positiveCounter + warningCounter).yellow}
                          key={2}
                        />
                        <ProgressBar
                          variant="success"
                          now={getSEOScore(positiveCounter + warningCounter).green}
                          key={3}
                        />
                      </ProgressBar>
                    </Col>
                    <Col lg="4" xl="4" className="display-end report-buttons" style={{margin:'auto'}}>
                      <Button style={{height:'fit-content'}} variant="outline-primary" color='outline-primary' className="me-1">
                        <FontAwesomeIcon icon={faFilePdf} /> Download PDF
                      </Button>
                      <Button style={{height:'fit-content'}} variant="outline-primary" color='outline-primary' className="me-1">
                        <FontAwesomeIcon icon={faPen} /> White Label
                      </Button>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <PieChart
                        showDetails={false}
                        positiveCounter={positiveCounter}
                        negativeCounter={negativeCounter}
                        warningCounter={warningCounter}
                        isQuickCheck={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <CardTitle className='f-bold'>Issues to fix</CardTitle>
                        </CardHeader>

                        <CardBody>
                          <Issues errors={errors} executeScroll={executeScroll} showIssueTitle={false} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row className="quick-seo-checkup">
                    <Col>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col md="4">
                              <h3> PRIMARY SEO SIGNALS </h3>
                            </Col>
                            <Col>
                              <Row className="display-end">
                                <Col md="3">
                                  <span>
                                    Score:{' '}
                                    {(
                                      (commonSeoSCounter * 100) /
                                      (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                    ).toFixed(0)}
                                  </span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant={
                                      (
                                        (commonSeoSCounter * 100) /
                                        (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                      ).toFixed(0) > 75
                                        ? 'success'
                                        : (
                                            (commonSeoSCounter * 100) /
                                            (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                    }
                                    now={
                                      (
                                        (commonSeoSCounter * 100) /
                                        (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                      ).toFixed(0) || 0
                                    }
                                    label=""
                                  />
                                </Col>
                                <Col md="3">
                                  <span>Success: {commonSeoSCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="success"
                                    now={commonSeoSCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Warning: {commonSeoWCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="warning"
                                    now={commonSeoWCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Failed: {commonSeoFCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="danger"
                                    now={commonSeoFCounter || 0}
                                    label=""
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {urlData?.metaData?.data?.response?.title ? (
                            <CommonSEOHOC
                              id={'meta-title'}
                              status={urlData?.metaData?.data?.response?.title?.titleStatus}
                              title={'Meta Title Signal'}
                              showDevider={false}
                              data={urlData?.metaData?.data?.response?.title}
                              infoMessage={commonSEOIssueInfo.metaTitle}
                            >
                              <MetaTitleTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.metaData?.data?.response?.description ? (
                            <>
                              <CommonSEOHOC
                                id={'meta-description'}
                                isAvailable={urlData?.metaData?.data?.response?.description?.descriptionCheck}
                                status={urlData?.metaData?.data?.response?.description?.descriptionStatus}
                                title={'Meta Description Signal'}
                                data={urlData?.metaData?.data?.response?.description}
                                infoMessage={commonSEOIssueInfo.metaDescription}
                              >
                                <MetaDescriptionTest data={urlData} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.keywordCloudTest?.data ? (
                            <CommonSEOHOC
                              id={'seo-result'}
                              isConsidered={false}
                              status={urlData?.keywordCloudTest?.data?.testResults?.status}
                              title={'Google Search Results Preview - SERP View'}
                              infoMessage={commonSEOIssueInfo.seoResult}
                            >
                              <MetaResultPreviewTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.microDataSchema?.data ? (
                            <CommonSEOHOC
                              id={'social-media-meta-tag'}
                              status={urlData?.microDataSchema?.data?.metaTagsResults?.status}
                              title={'Social Media Meta Tags Signals'}
                              infoMessage={commonSEOIssueInfo.socialMediaMetaTag}
                            >
                              <SocialMediaMetaTags data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.microDataSchema?.data ? (
                            <CommonSEOHOC
                              id={'keyword-usage-test'}
                              isAvailable={urlData?.microDataSchema?.data?.keywordSuccessFlag}
                              status={urlData?.microDataSchema?.data?.keywordsUsageTestResults?.status}
                              title={'Keyword Usage Test Signals'}
                              infoMessage={commonSEOIssueInfo.keywordUsageTest}
                            >
                              <KeywordUsageTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          
                          {urlData?.headingAnalysis?.data ? (
                            <CommonSEOHOC
                              id={'heading-analysis'}
                              // isAvailable={urlData?.headingAnalysis?.data?.headingOrder?.list}
                              isAvailable={urlData?.headingAnalysis?.data?.testResults?.status}
                              status={urlData?.headingAnalysis?.data?.testResults?.status}
                              title={'Heading Tag Analysis Signals'}
                              infoMessage={commonSEOIssueInfo.headingAnalysis}
                            >
                              <HeadingAnalysis
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setHeadingAnalysisFlag(!headingAnalysisFlag)}
                              />
                              <ModalHOC
                                show={headingAnalysisFlag}
                                toggleShow={() => setHeadingAnalysisFlag(!headingAnalysisFlag)}
                                title={'Heading Analysis'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0" className="bg-white">
                                    <Accordion.Header>{`H1 Tags`}</Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {urlData?.headingAnalysis?.data?.h1?.length ? (
                                        <>
                                          <Table striped>
                                            <tbody>
                                              {urlData?.headingAnalysis?.data?.h1.map((item) => {
                                                return (
                                                  <tr>
                                                    <td>{item}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </Table>
                                          {urlData?.headingAnalysis?.data?.h1.filter(
                                            (item) => item?.length === 0,
                                          )?.length ? (
                                            <p className="text-danger">
                                              {
                                                urlData?.headingAnalysis?.data?.h1.filter(
                                                  (item) => item?.length === 0,
                                                )?.length
                                              }{' '}
                                              empty H1 tags found{' '}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-danger">No H1 Tag Found in the website.</p>
                                        </>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1" className="bg-white">
                                    <Accordion.Header>{`H2 Tags`}</Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {urlData?.headingAnalysis?.data?.h2?.length ? (
                                        <>
                                          <Table striped>
                                            <tbody>
                                              {urlData?.headingAnalysis?.data?.h2.map((item) => {
                                                return (
                                                  <tr>
                                                    <td>{item}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </Table>
                                          {urlData?.headingAnalysis?.data?.h2.filter(
                                            (item) => item.length === 0,
                                          ).length ? (
                                            <p className="text-danger">
                                              {
                                                urlData?.headingAnalysis?.data?.h2.filter(
                                                  (item) => item.length === 0,
                                                ).length
                                              }{' '}
                                              empty H2 tags found{' '}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-danger">No H2 Tag Found in the website.</p>
                                        </>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>

                                  <Accordion.Item eventKey="2" className="bg-white">
                                    <Accordion.Header>{`H3 Tags`}</Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {urlData?.headingAnalysis?.data?.h3?.length ? (
                                        <>
                                          <Table striped>
                                            <tbody>
                                              {urlData?.headingAnalysis?.data?.h3.map((item) => {
                                                return (
                                                  <tr>
                                                    <td>{item}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </Table>
                                          {urlData?.headingAnalysis?.data?.h3.filter(
                                            (item) => item.length === 0,
                                          ).length ? (
                                            <p className="text-danger">
                                              {
                                                urlData?.headingAnalysis?.data?.h3.filter(
                                                  (item) => item.length === 0,
                                                ).length
                                              }{' '}
                                              empty H3 tags found{' '}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-danger">No H3 Tag Found in the website.</p>
                                        </>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>

                                  <Accordion.Item eventKey="3" className="bg-white">
                                    <Accordion.Header>{`H4 Tags`}</Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {urlData?.headingAnalysis?.data?.h4?.length ? (
                                        <>
                                          <Table striped>
                                            <tbody>
                                              {urlData?.headingAnalysis?.data?.h4.map((item) => {
                                                return (
                                                  <tr>
                                                    <td>{item}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </Table>
                                          {urlData?.headingAnalysis?.data?.h4.filter(
                                            (item) => item.length === 0,
                                          ).length ? (
                                            <p className="text-danger">
                                              {
                                                urlData?.headingAnalysis?.data?.h4.filter(
                                                  (item) => item.length === 0,
                                                ).length
                                              }{' '}
                                              empty H4 tags found{' '}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-danger">No H4 Tag Found in the website.</p>
                                        </>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>

                                  <Accordion.Item eventKey="4" className="bg-white">
                                    <Accordion.Header>{`H5 Tags`}</Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {urlData?.headingAnalysis?.data?.h5?.length ? (
                                        <>
                                          <Table striped>
                                            <tbody>
                                              {urlData?.headingAnalysis?.data?.h5.map((item) => {
                                                return (
                                                  <tr>
                                                    <td>{item}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </Table>
                                          {urlData?.headingAnalysis?.data?.h5.filter(
                                            (item) => item.length === 0,
                                          ).length ? (
                                            <p className="text-danger">
                                              {
                                                urlData?.headingAnalysis?.data?.h5.filter(
                                                  (item) => item.length === 0,
                                                ).length
                                              }{' '}
                                              empty H5 tags found{' '}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-danger">No H5 Tag Found in the website.</p>
                                        </>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>

                                  <Accordion.Item eventKey="5" className="bg-white">
                                    <Accordion.Header>{`H6 Tags`}</Accordion.Header>
                                    <Accordion.Body className="bg-white">
                                      {urlData?.headingAnalysis?.data?.h6?.length ? (
                                        <>
                                          <Table striped>
                                            <tbody>
                                              {urlData?.headingAnalysis?.data?.h6.map((item) => {
                                                return (
                                                  <tr>
                                                    <td>{item}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </Table>
                                          {urlData?.headingAnalysis?.data?.h6.filter(
                                            (item) => item.length === 0,
                                          ).length ? (
                                            <p className="text-danger">
                                              {
                                                urlData?.headingAnalysis?.data?.h6.filter(
                                                  (item) => item.length === 0,
                                                ).length
                                              }{' '}
                                              empty H6 tags found{' '}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-danger">No H6 Tag Found in the website.</p>
                                        </>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.metaData?.data?.response?.robotsTestResults ? (
                            <CommonSEOHOC
                              id={'robots-txt'}
                              isAvailable={urlData?.metaData?.data?.response?.robotsTestResults?.status}
                              status={urlData?.metaData?.data?.response?.robotsTestResults?.status}
                              title={'Robots.txt Signal Analysis'}
                              data={urlData?.metaData?.data?.response?.robotsTestResults}
                              infoMessage={commonSEOIssueInfo.robotsTxt}
                            >
                              <MetaRobotsText data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                         
                          {urlData?.siteMapData?.data ? (
                            <CommonSEOHOC
                              id={'sitemap-data'}
                              // isAvailable={urlData?.siteMapData?.data?.siteMapUrl?.length}
                              isAvailable={urlData?.siteMapData?.data?.testResults?.status}
                              status={urlData?.siteMapData?.data?.testResults?.status}
                              title={'Sitemap Signal Test'}
                              infoMessage={commonSEOIssueInfo.sitemapData}
                            >
                              <SiteMapData
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setSiteMapDataFlag(!siteMapDataFlag)}
                              />
                              <ModalHOC
                                show={siteMapDataFlag}
                                toggleShow={() => setSiteMapDataFlag(!siteMapDataFlag)}
                                title={'Sitemap list'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <p>Here is a list of all your primary sitemap links</p>
                                <Row className="mt-3">
                                  <Col>
                                    {urlData?.siteMapData?.data?.siteMapUrl?.length &&
                                      urlData?.siteMapData?.data?.siteMapUrl.map((item) => {
                                        return (
                                          <li>
                                            <a href={item} target="_BLANK">
                                              {' '}
                                              {item}
                                            </a>
                                          </li>
                                        )
                                      })}
                                  </Col>
                                </Row>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.seoFriendlyUrlAnalysis?.data ? (
                            <CommonSEOHOC
                              id={'seo-friendly'}
                              isAvailable={
                                !urlData?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length
                              }
                              status={urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status}
                              title={'SEO Friendly URL Signal Test'}
                              infoMessage={commonSEOIssueInfo.seoFriendly}
                            >
                              <SeoFriendlyUrlAnalysis
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setSeoUrlAnalysisFlag(!seoUrlAnalysisFlag)}
                              />
                              <ModalHOC
                                show={seoUrlAnalysisFlag}
                                toggleShow={() => setSeoUrlAnalysisFlag(!seoUrlAnalysisFlag)}
                                title={'Full list of unfriendly URLs'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Row className="mt-3">
                                  <Col>
                                    {urlData?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.length &&
                                      urlData?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly.map((item) => {
                                        return (
                                          <li>
                                            <a href="javascript:void(0)"> {item} </a>
                                          </li>
                                        )
                                      })}
                                  </Col>
                                </Row>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.imageAlt?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'image-alt'}
                                // isAvailable={!urlData?.imageAlt?.data?.imgAlt?.length}
                                isAvailable={urlData?.imageAlt?.data?.testResults?.status}
                                status={urlData?.imageAlt?.data?.testResults?.status}
                                title={'Image Alt Test'}
                                infoMessage={commonSEOIssueInfo.imageAlt}
                              >
                                <ImageAlt
                                  data={urlData}
                                  showDetail={true}
                                  onShowDetail={() => setImageAltFlag(!imageAltFlag)}
                                />
                                <ModalHOC
                                  show={imageAltFlag}
                                  toggleShow={() => setImageAltFlag(!imageAltFlag)}
                                  title={'Full list of "img" tags with empty or missing "alt" attribute'}
                                  bodyClassName="m-3"
                                  titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                                >
                                  <ul>
                                    {urlData?.imageAlt?.data?.imgAlt?.length ? (
                                      urlData?.imageAlt?.data?.imgAlt.map((item) => {
                                        return <li>{item}</li>
                                      })
                                    ) : (
                                      <li>No Image found wihtout alt tag</li>
                                    )}
                                  </ul>
                                </ModalHOC>
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}

                          {urlData?.descriptiveLinkTest?.data ? (
                            <CommonSEOHOC
                              id={'descriptive-test'}
                              // isAvailable={!urlData?.imageAlt?.data?.imgAlt?.length}
                              isAvailable={urlData?.descriptiveLinkTest?.data?.testResults?.status}
                              status={urlData?.descriptiveLinkTest?.data?.testResults?.status}
                              title={'Descriptive Link Signal Test'}
                              infoMessage={commonSEOIssueInfo.descriptiveLinkTest}
                            >
                              {multiData?.length ? (
                                multiData.map((item) =>
                                  item ? <LinksALTDescriptions data={item} showDetail={true} /> : <></>,
                                )
                              ) : (
                                <></>
                              )}
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.responsiveImage?.data ? (
                            <CommonSEOHOC
                              id={'responsive-image'}
                              status={urlData?.responsiveImage?.data?.testResults?.status}
                              title={'Excessive Image Data Size Signal Test'}
                              infoMessage={commonSEOIssueInfo.responsiveImage}
                            >
                              <ResponsiveImage
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setResponsiveImageFlag(!responsiveImageFlag)}
                              />
                              <ModalHOC
                                show={responsiveImageFlag}
                                toggleShow={() => setResponsiveImageFlag(!responsiveImageFlag)}
                                title={'Full list of oversized images'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Image</th>
                                      <th style={{ width: '40%' }}>Total Size</th>
                                      <th style={{ width: '40%' }}>Potential Savings</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.responsiveImage?.data?.data?.details?.items?.length &&
                                      urlData?.responsiveImage?.data?.data?.details?.items.map((item) => {
                                        return (
                                          <tr>
                                            <td>{item.url}</td>
                                            <td>{`${item?.totalBytes / 1000 || '-'} KB`}</td>
                                            <td>{`${item?.wastedBytes / 1000 || '-'} KB`}</td>
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.aspectRatio?.data ? (
                            <CommonSEOHOC
                              id={'aspect-ratio'}
                              status={urlData?.aspectRatio?.data?.testResults?.status}
                              title={'Image Aspect Ratio Signal Test'}
                              infoMessage={commonSEOIssueInfo.aspectRatio}
                            >
                              <AspectRatio
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setAspectRatioFlag(!aspectRatioFlag)}
                              />
                              <ModalHOC
                                show={aspectRatioFlag}
                                toggleShow={() => setAspectRatioFlag(!aspectRatioFlag)}
                                title={'Full list of images with incorrect aspect ratio'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Resource </th>
                                      <th style={{ width: '40%' }}>Actual Size</th>
                                      <th style={{ width: '40%' }}>Render Size</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.aspectRatio?.data?.data?.details?.items?.length &&
                                      urlData?.aspectRatio?.data?.data?.details?.items.map((item) => {
                                        return (
                                          <tr>
                                            <td>{item.url}</td>
                                            <td>{item.actualAspectRatio}</td>
                                            <td>{item.displayedAspectRatio}</td>
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.inlineCss?.data ? (
                            <CommonSEOHOC
                              id={'inline-css'}
                              status={urlData?.inlineCss?.data?.testResults?.status}
                              title={'Inline CSS Signal Test'}
                              infoMessage={commonSEOIssueInfo.inlineCss}
                            >
                              <InlineCss
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setInlinecssFlag(!inlineCssFlag)}
                              />
                              <ModalHOC
                                show={inlineCssFlag}
                                toggleShow={() => setInlinecssFlag(!inlineCssFlag)}
                                title={'Full list of tags with inline css style'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table striped>
                                  <tbody>
                                    {urlData?.inlineCss?.data?.finalRes?.data?.length ? (
                                      urlData?.inlineCss?.data?.finalRes?.data.map((item) => {
                                        return (
                                          <tr>
                                            <td>{item.html}</td>
                                          </tr>
                                        )
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                        {urlData?.htmlValidation?.data ? (
                            <CommonSEOHOC
                              id={'html-validation'}
                              status={urlData?.htmlValidation?.data?.testResults?.status}
                              title={'Html Validation'}
                              infoMessage={commonSEOIssueInfo.htmlValidation}
                            >
                              <HtmlValidation
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setHtmlValidationFlag(!htmlValidationFlag)}
                              />
                              <ModalHOC
                                show={htmlValidationFlag}
                                toggleShow={() => setHtmlValidationFlag(!htmlValidationFlag)}
                                title={'Html Validation'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table striped>
                                  <thead>
                                    <th>HTML Extract</th>
                                    <th>Reason</th>
                                  </thead>
                                  <tbody>
                                    {urlData.htmlValidation?.data?.errors?.length ? (
                                      <>
                                        {urlData.htmlValidation?.data?.errors.map((item) => {
                                          return (
                                            <tr>
                                              <td>{item.extract}</td>
                                              <td>{item.message}</td>
                                            </tr>
                                          )
                                        })}{' '}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {urlData.htmlValidation?.data?.warnings?.length ? (
                                      <>
                                        {' '}
                                        {urlData.htmlValidation?.data?.warnings.map((item) => {
                                          return (
                                            <tr>
                                              <td>{item.extract}</td>
                                              <td>{item.message}</td>
                                            </tr>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.googleAnalytics ? (
                            <CommonSEOHOC
                              id={'google-analytics'}
                              status={urlData?.googleAnalytics?.data?.testResults?.status}
                              title={'Google Analytics Signal Test'}
                              infoMessage={commonSEOIssueInfo.googleAnalytics}
                            >
                              <GoogleAnalytics data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.faviconCheckerTest?.data ? (
                            <CommonSEOHOC
                              id={'favicon-test'}
                              status={urlData?.faviconCheckerTest?.data?.status}
                              title={'Favicon Checker Signal Test'}
                              infoMessage={commonSEOIssueInfo.faviconTest}
                            >
                              <FaviconCheckerTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.jsError?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'js-error'}
                                status={urlData?.jsError?.data?.testResults?.status}
                                title={'JS Error Signal Test'}
                                infoMessage={commonSEOIssueInfo.jsError}
                              >
                                <JSError
                                  data={urlData}
                                  showDetail={true}
                                  onShowDetail={() => setJsErrorFlag(!jsErrorFlag)}
                                />
                                <ModalHOC
                                  show={jsErrorFlag}
                                  toggleShow={() => setJsErrorFlag(!jsErrorFlag)}
                                  title={'JS Error Signal Test'}
                                  bodyClassName="m-3"
                                  titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                                >
                                  {urlData?.jsError?.data?.finalData?.errors?.length ? (
                                    <>
                                      <Table bordered>
                                        <thead>
                                          <tr>
                                            <th style={{ width: '10%' }}>Type</th>
                                            <th style={{ width: '40%' }}>Errors</th>
                                            <th style={{ width: '30%' }}>File</th>
                                            <th style={{ width: '10%' }}>Line No</th>
                                            <th style={{ width: '10%' }}>Character No</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {urlData?.jsError?.data?.finalData?.errors?.length ? (
                                            urlData?.jsError?.data?.finalData?.errors.map((item) => {
                                              return (
                                                <tr>
                                                  <td>{item.type}</td>
                                                  <td>{item.error}</td>
                                                  <td>{item.file}</td>
                                                  <td>{item.lineno}</td>
                                                  <td>{item.charno}</td>
                                                </tr>
                                              )
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </tbody>
                                      </Table>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </ModalHOC>
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.consoleErrors?.data ? (
                            <CommonSEOHOC
                              id={'console-errors'}
                              status={urlData?.consoleErrors?.data?.testResults?.status}
                              title={'Historic Console Error Signal Check'}
                              infoMessage={commonSEOIssueInfo.consoleErrors}
                            >
                              <ConsoleErrors
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setConsoleErrorsFlag(!consoleErrorsFlag)}
                              />
                              <ModalHOC
                                show={consoleErrorsFlag}
                                toggleShow={() => setConsoleErrorsFlag(!consoleErrorsFlag)}
                                title={'Historic Console Error Signal Check'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table striped>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Description</th>
                                      <th style={{ width: '10%' }}>Source</th>
                                      <th style={{ width: '40%' }}>url</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.consoleErrors?.data?.consoleErrorsResults?.details?.items
                                      ?.length &&
                                      urlData?.consoleErrors?.data?.consoleErrorsResults?.details?.items.map(
                                        (item) => {
                                          return (
                                            <tr>
                                              <td>{item?.description}</td>
                                              <td>{item?.source}</td>
                                              <td>{item?.sourceLocation?.url}</td>
                                            </tr>
                                          )
                                        },
                                      )}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.advancedConsoleErrors?.data ? (
                            <CommonSEOHOC
                              id={'advanced-console-errors'}
                              status={urlData?.advancedConsoleErrors?.data?.testResults?.status}
                              title={'Advanced Console Error Signal Check'}
                              infoMessage={commonSEOIssueInfo?.advancedConsoleErrors}
                            >
                              <AdvancedConsoleErrors
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setAdvancedConsoleErrorsFlag(!advancedConsoleErrorsFlag)}
                              />
                              <ModalHOC
                                show={advancedConsoleErrorsFlag}
                                toggleShow={() => setAdvancedConsoleErrorsFlag(!advancedConsoleErrorsFlag)}
                                title={'Advanced Console Error Signal Check'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table striped>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Message</th>
                                      <th style={{ width: '40%' }}>Url</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.length &&
                                      urlData?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.map(
                                        (item) => {
                                          return (
                                            <tr>
                                              <td>{item?.message}</td>
                                              <td>{item?.url}</td>
                                            </tr>
                                          )
                                        },
                                      )}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : null }

                          {urlData?.characterSet?.data ? (
                            <CommonSEOHOC
                              id={'character-set'}
                              status={urlData?.characterSet?.data?.testResults?.status}
                              title={'Charset Declaration Signal Test'}
                              infoMessage={commonSEOIssueInfo.characterSet}
                            >
                              <CharacterSet data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                         
                          {urlData?.socialMediaTest?.data || urlData?.socialMediaTwitterData?.data ? (
                            <CommonSEOHOC
                              id={'social-media'}
                              status={
                                urlData?.socialMediaInstagramData?.data?.testResults?.status === 1 ||
                                urlData?.socialMediaTwitterData?.data?.testResults?.status === 1
                                  ? 1
                                  : 3
                              }
                              title={'Social Media Signal Test'}
                              infoMessage={commonSEOIssueInfo.socialMedia}
                            >
                              {multiData?.length ? (
                                multiData.map((item, index) =>
                                  item ? (
                                    <SocialMediaTest
                                      data={item}
                                      showDetail={index === 0}
                                      onShowDetail={() => setSocialMediaFlag(!socialMediaFlag)}
                                      twitterURL={
                                        item?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                          ?.valueData?.[3]
                                      }
                                      instaURL={item?.socialMediaInstagramData?.data?.socialBlade?.url}
                                      instagramData={
                                        item?.socialMediaInstagramData?.data
                                          ? item?.socialMediaInstagramData?.data?.testResults?.status === 3
                                            ? false
                                            : true
                                          : false
                                      }
                                      twitterData={
                                        item?.socialMediaTwitterData?.data
                                          ? item?.socialMediaTwitterData?.data?.testResults?.status === 3
                                            ? false
                                            : true
                                          : false
                                      }
                                    />
                                  ) : (
                                    <></>
                                  ),
                                )
                              ) : (
                                <></>
                              )}
                              <ModalHOC
                                show={socialMediaFlag}
                                toggleShow={() => setSocialMediaFlag(!socialMediaFlag)}
                                title={'Social Media Data'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Row className="mt-3">
                                  <Col>
                                    {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                      ?.valueData?.length ? (
                                      <>
                                        <h3 style={{ textAlign: 'center' }}>
                                          <b>Twitter Data</b>
                                        </h3>
                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Location</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[0] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[0]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>

                                        <hr className="my-2" />

                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Joined</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[1] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[1]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>

                                        <hr className="my-2" />

                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Followers</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[2] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[2]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>

                                        <hr className="my-2" />

                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Following</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[3] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[3]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>

                                        <hr className="my-2" />

                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Followers Ratio</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[4] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[4]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>

                                        <hr className="my-2" />

                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Tweets</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[5] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[5]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>

                                        <hr className="my-2" />

                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <p className="my-1">
                                              <strong>Listed</strong>
                                            </p>
                                            <h3>
                                              {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                                ?.valueData?.[6] === '' ? (
                                                '-'
                                              ) : (
                                                <>
                                                  {
                                                    urlData?.socialMediaTwitterData?.data?.twitterData
                                                      ?.twitterData?.valueData?.[6]
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col>
                                    <h3 className="social-media-modal-header">
                                      <b>Facebook Data</b>
                                    </h3>
                                    <Table bordered>
                                      <thead>
                                        <tr>
                                          <th style={{ width: '50%' }}>Data</th>
                                          <th style={{ width: '50%' }}>Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {urlData?.socialMediaTest?.data?.facebookData?.length ? (
                                          urlData?.socialMediaTest?.data?.facebookData.map((item) => {
                                            return (
                                              <tr>
                                                <td>{item.key}</td>
                                                <td>{item.value}</td>
                                              </tr>
                                            )
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col md="4">
                              <h3>WEBSITE SITE LOADING SIGNALS</h3>
                            </Col>
                            <Col>
                              <Row className="display-end">
                                <Col md="3">
                                  <span>
                                    Score:{' '}
                                    {(
                                      (speedOptimizeSCounter * 100) /
                                      (speedOptimizeFCounter + speedOptimizeWCounter + speedOptimizeSCounter)
                                    ).toFixed(0)}
                                  </span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant={
                                      (
                                        (speedOptimizeSCounter * 100) /
                                        (speedOptimizeFCounter +
                                          speedOptimizeWCounter +
                                          speedOptimizeSCounter)
                                      ).toFixed(0) > 75
                                        ? 'success'
                                        : (
                                            (speedOptimizeSCounter * 100) /
                                            (speedOptimizeFCounter +
                                              speedOptimizeWCounter +
                                              speedOptimizeSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                    }
                                    now={
                                      (
                                        (speedOptimizeSCounter * 100) /
                                        (speedOptimizeFCounter +
                                          speedOptimizeWCounter +
                                          speedOptimizeSCounter)
                                      ).toFixed(0) || 0
                                    }
                                    label=""
                                  />
                                </Col>
                                <Col md="3">
                                  <span>Success: {speedOptimizeSCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="success"
                                    now={speedOptimizeSCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Warning: {speedOptimizeWCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="warning"
                                    now={speedOptimizeWCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Failed: {speedOptimizeFCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="danger"
                                    now={speedOptimizeFCounter || 0}
                                    label=""
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {urlData?.htmlPageSize ? (
                            <>
                              <hr />
                              <CommonSEOHOC
                                id={'html-page'}
                                status={urlData?.htmlPageSize?.data?.testResults?.status}
                                title={'HTML Page Size Signal Test'}
                                infoMessage={commonSEOIssueInfo.htmlPage}
                                showDevider={false}
                              >
                                <div className="vr-url-block">
                                  <div>
                                    {!urlData?.htmlPageSize?.data?.testResults?.status ? (
                                      ''
                                    ) : urlData?.htmlPageSize?.data?.testResults?.status === 3 ? (
                                      <img
                                        style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                                        src={crossIcon}
                                      />
                                    ) : urlData?.htmlPageSize?.data?.testResults?.status === 2 ? (
                                      <img
                                        style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                                        src={warningIcon}
                                      />
                                    ) : (
                                      <img
                                        style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                                        src={tickIcon}
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <h5>{urlData?.url}</h5>
                                  </div>
                                </div>
                                <div className="vr-detail-block">
                                  <div className="vr-report-status-icon" />
                                  <div>
                                    {' '}
                                    <p>{urlData?.htmlPageSize?.data?.testResults?.message}</p>{' '}
                                  </div>
                                </div>
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.domSize?.data ? (
                            <CommonSEOHOC
                              id={'dom-size'}
                              status={urlData?.domSize?.data?.testResults?.status}
                              title={'DOM Size Signal Test'}
                              infoMessage={commonSEOIssueInfo.domSize}
                            >
                              <DomSize
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setDomSizeFlag(!domSizeFlag)}
                              />
                              <ModalHOC
                                show={domSizeFlag}
                                toggleShow={() => setDomSizeFlag(!domSizeFlag)}
                                title={'Dom Elements'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Elements List</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.domSize?.data?.domSizeResult?.details?.items?.length &&
                                      urlData?.domSize?.domSizeResult?.data?.details?.items.map((item) => {
                                        return (
                                          <tr>
                                            <td>{item.url}</td>
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.htmlCompression?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'html-compression'}
                                status={urlData?.htmlCompression?.data?.testResults?.status}
                                title={'Web Content Compression Check'}
                                infoMessage={commonSEOIssueInfo.htmlCompression}
                              >
                                <HtmlCompression data={urlData} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.siteSpeed?.data ? (
                            <CommonSEOHOC
                              id={'site-speed'}
                              status={urlData?.siteSpeed?.data?.testResults?.status}
                              title={'Site Loading Speed Signal Test'}
                              infoMessage={commonSEOIssueInfo.siteSpeed}
                            >
                              <SiteSpeed data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.bootupTime?.data ? (
                            <CommonSEOHOC
                              id={'js-execution-time'}
                              status={urlData?.bootupTime?.data?.testResults?.status}
                              title={'JS Execution Signal Test'}
                              infoMessage={commonSEOIssueInfo.jsExecutionTime}
                            >
                              <BootupTime data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.pageObject?.data ? (
                            <CommonSEOHOC
                              id={'page-object'}
                              status={urlData?.pageObject?.data?.testResults?.status}
                              title={'Page Objects Signal Test'}
                              infoMessage={commonSEOIssueInfo.pageObject}
                            >
                              <PageObject
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setPageObjectFlag(!pageObjectFlag)}
                              />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.pageCache?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'page-cache'}
                                status={urlData?.pageCache?.data?.testResults?.status}
                                title={'Page Cache Test (Server Side Caching) Signal Analysis'}
                                infoMessage={commonSEOIssueInfo.pageCache}
                              >
                                <PageCache data={urlData} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.flashTest?.data ? (
                            <CommonSEOHOC
                              id={'flash-test'}
                              status={urlData?.flashTest?.data?.testResults?.status}
                              title={'Flash Signal Test '}
                              infoMessage={commonSEOIssueInfo.flashTest}
                            >
                              <FlashTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.cdnUsageData?.data ? (
                            <CommonSEOHOC
                              id={'cdn-usage'}
                              status={urlData?.cdnUsageData?.data?.testResult?.status}
                              title={'CDN Usage Signal Test'}
                              infoMessage={commonSEOIssueInfo.cdnUsage}
                            >
                              <CDNUsage
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setCdnUsageDataFlag(!cdnUsageDataFlag)}
                              />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.mordenImage?.data ? (
                            <CommonSEOHOC
                              id={'morden-image'}
                              status={urlData?.mordenImage?.data?.testResults?.status}
                              title={'Morden Image Signal Test'}
                              infoMessage={commonSEOIssueInfo.mordenImage}
                            >
                              <MordenImage
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setMordenImageFlag(!mordenImageFlag)}
                              />
                              <ModalHOC
                                show={mordenImageFlag}
                                toggleShow={() => setMordenImageFlag(!mordenImageFlag)}
                                title={'Full list of unoptimized images'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Image</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.mordenImage?.data?.data?.details?.items?.length &&
                                      urlData?.mordenImage?.data?.data?.details?.items.map((item) => {
                                        return (
                                          <tr>
                                            <td>{item.url}</td>
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.cachePolicy?.data ? (
                            <CommonSEOHOC
                              id={'cache-policy'}
                              isAvailable={urlData?.cachePolicy?.data?.testResults?.status}
                              status={urlData?.cachePolicy?.data?.testResults?.status}
                              title={'Cache Policy Signal Test'}
                              infoMessage={commonSEOIssueInfo.cachePolicy}
                            >
                                      <CachePolicy
                                        data={urlData}
                                        showDetail={true}
                                        onShowDetail={() => setCachePolicyFlag(!cachePolicyFlag)}
                                      />
                            </CommonSEOHOC>
                          ) : null}

                          {urlData?.imageCache?.data ? (
                            <CommonSEOHOC
                              id={'image-cache'}
                              isAvailable={urlData?.imageCache?.data?.testResults?.status}
                              status={urlData?.imageCache?.data?.testResults?.status}
                              title={'Image Cache Signal Test'}
                              infoMessage={commonSEOIssueInfo.imageCache}
                            >
                              <ImageCache
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setImageCacheFlag(!imageCacheFlag)}
                              />
                              <ModalHOC
                                show={imageCacheFlag}
                                toggleShow={() => setImageCacheFlag(!imageCacheFlag)}
                                title={'Image Cache Signal Test'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                {urlData.imageCache?.data?.withoutCachedResults &&
                                urlData?.imageCache?.data?.withoutCachedResults.length ? (
                                  <>
                                    <h4> Uncached Image Resource list </h4>
                                    <ul>
                                      {' '}
                                      {urlData.imageCache?.data?.withoutCachedResults.map((item) => {
                                        return <li>{item.url}</li>
                                      })}
                                    </ul>
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.jsCache?.data ? (
                            <CommonSEOHOC
                              id={'js-cache'}
                              isAvailable={urlData?.jsCache?.data?.testResults?.status}
                              status={urlData?.jsCache?.data?.testResults?.status}
                              title={'JavaScript Caching Signal Test'}
                              infoMessage={commonSEOIssueInfo.jsCache}
                            >
                              <JSCache
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setJsCacheFlag(!jsCacheFlag)}
                              />
                              <ModalHOC
                                show={jsCacheFlag}
                                toggleShow={() => setJsCacheFlag(!jsCacheFlag)}
                                title={'JavaScript Caching Signal Test'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                                alert={urlData?.jsCache?.data?.alertInfo}
                              >
                                {urlData?.jsCache?.data?.withoutCachedResults &&
                                urlData?.jsCache?.data?.withoutCachedResults.length ? (
                                  <>
                                    {' '}
                                    <h4> Uncached Javascript Resource list </h4>
                                    <ul>
                                      {' '}
                                      {urlData?.jsCache?.data?.withoutCachedResults.map((item) => {
                                        return <li>{item.url}</li>
                                      })}
                                    </ul>{' '}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                           {urlData?.cssCache?.data ? (
                            <CommonSEOHOC
                              id={'css-cache'}
                              status={urlData?.cssCache?.data?.testResults?.status}
                              title={'CSS Caching Signal Test'}
                              infoMessage={commonSEOIssueInfo.cssCache}
                            >
                              <CSSCache
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setCssCacheFlag(!cssCacheFlag)}
                              />
                              <ModalHOC
                                show={cssCacheFlag}
                                toggleShow={() => setCssCacheFlag(!cssCacheFlag)}
                                title={'CSS Caching Signal Test'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <h5>Not Cached Resources</h5>
                                {urlData.cssCache?.data?.withoutCachedResults?.length ? (
                                  <ul>
                                    {' '}
                                    {urlData.cssAnalysis?.data?.withoutCachedResults?.map((item) => {
                                      return <li>{item.url}</li>
                                    })}
                                  </ul>
                                ) : (
                                  <></>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          
                          {urlData?.jsMinification?.data ? (
                            <CommonSEOHOC
                              id={'js-minification'}
                              isAvailable={urlData?.jsMinification?.data?.testResults?.status}
                              status={urlData?.jsMinification?.data?.testResults?.status}
                              title={'JavaScript Minification Signal Test'}
                              infoMessage={commonSEOIssueInfo.jsMinification}
                            >
                              <JSMinification
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setJsMinification(!jsMinificationFlag)}
                              />
                              <ModalHOC
                                show={jsMinificationFlag}
                                toggleShow={() => setJsMinification(!jsMinificationFlag)}
                                title={'Full list of JS files'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Non Minified JS</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.jsMinification?.data?.jsMinificationResults?.items.length &&
                                      urlData?.jsMinification?.data?.jsMinificationResults?.items.map(
                                        (item) => {
                                          return (
                                            <tr>
                                              <td>{item?.url}</td>
                                            </tr>
                                          )
                                        },
                                      )}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.cssMinification?.data ? (
                            <CommonSEOHOC
                              id={'css-minification'}
                              isAvailable={urlData?.cssMinification?.data?.testResults?.status}
                              status={urlData?.cssMinification?.data?.testResults?.status}
                              title={'CSS Minification Signal Test'}
                              infoMessage={commonSEOIssueInfo.cssMinification}
                            >
                              <CSSMinification
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setCssMinificationFlag(!cssMinificationFlag)}
                              />
                              <ModalHOC
                                show={cssMinificationFlag}
                                toggleShow={() => setCssMinificationFlag(!cssMinificationFlag)}
                                title={'Full list of CSS files'}
                                bodyClassName="m-3"
                              >
                                {urlData.cssMinification?.data?.cssMinificationResults?.items.length ? (
                                  <>
                                    <Table bordered>
                                      <thead>
                                        <tr>
                                          <th style={{ width: '40%' }}>Non Minified CSS</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {urlData.cssMinification?.data?.cssMinificationResults?.items.map(
                                          (item) => {
                                            return (
                                              <tr>
                                                <td>{item?.url}</td>
                                              </tr>
                                            )
                                          },
                                        )}
                                      </tbody>
                                    </Table>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.renderBlocking?.data ? (
                            <CommonSEOHOC
                              id={'render-blocking'}
                              status={urlData?.renderBlocking?.data?.testResults?.status}
                              title={'Render Blocking Signal Test'}
                              infoMessage={commonSEOIssueInfo.renderBlocking}
                            >
                              <RenderBlocking
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setRenderBlockingFlag(!renderBlockingFlag)}
                              />
                              <ModalHOC
                                show={renderBlockingFlag}
                                toggleShow={() => setRenderBlockingFlag(!renderBlockingFlag)}
                                title={'Render Blocking Signal Test'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '40%' }}>Resource List</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {urlData?.renderBlocking?.data?.renderBlockingResult?.details?.items
                                      ?.length &&
                                      urlData?.renderBlocking?.data?.renderBlockingResult?.details?.items.map(
                                        (item) => {
                                          return (
                                            <tr>
                                              <td>{item.url}</td>
                                            </tr>
                                          )
                                        },
                                      )}
                                  </tbody>
                                </Table>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.nestedTable?.data ? (
                            <CommonSEOHOC
                              id={'nested-table'}
                              status={urlData?.nestedTable?.data?.testResults?.status}
                              title={'Nested Tables Signal Test'}
                              infoMessage={commonSEOIssueInfo.nestedTable}
                            >
                              <NestedTable data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.framesetTest?.data ? (
                            <CommonSEOHOC
                              id={'frameset-test'}
                              status={urlData?.framesetTest?.data?.testResults?.status}
                              title={'Frameset Signal Test'}
                              infoMessage={commonSEOIssueInfo.framesetTest}
                            >
                              <FramesetTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.docType?.data ? (
                            <CommonSEOHOC
                              id={'doc-type'}
                              status={urlData?.docType?.data?.testResults?.status}
                              title={'Doctype Signal Test'}
                              infoMessage={commonSEOIssueInfo.docType}
                            >
                              <DocType data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.redirectTest ? (
                            <CommonSEOHOC
                              id={'redirect-test'}
                              status={urlData?.redirectTest?.data?.testResults?.status}
                              title={'Redirect Signal Test'}
                              infoMessage={commonSEOIssueInfo.redirectTest}
                            >
                              <RedirectTest data={urlData} showDetail={true} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.contentfulPaint?.data ? (
                            <CommonSEOHOC
                              id={'contentful-paint'}
                              status={urlData?.contentfulPaint?.data?.testResults?.status}
                              title={'Contentful Paint Signal Test'}
                              infoMessage={commonSEOIssueInfo.contentfulPaint}
                            >
                              <ContentfulPaint data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.layoutShift?.data ? (
                            <CommonSEOHOC
                              id={'layout-shift'}
                              status={urlData?.layoutShift?.data?.testResults?.status}
                              title={'Cumulative Layout Shift Signal Test'}
                              infoMessage={commonSEOIssueInfo.layoutShift}
                            >
                              <LayoutShift data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.cssAnalysis?.data ? (
                            <CommonSEOHOC
                              id={'css-analysis'}
                              status={urlData?.cssAnalysis?.data?.testResults?.status}
                              title={'CSS Analysis'}
                              infoMessage={commonSEOIssueInfo.cssAnalysis}
                            >
                              <CSSAnalysis
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setCssAnalysisFlag(!cssAnalysisFlag)}
                                showUrl={false}
                              />
                              <ModalHOC
                                show={cssAnalysisFlag}
                                toggleShow={() => setCssAnalysisFlag(!cssAnalysisFlag)}
                                title={'CSS Analysis'}
                                bodyClassName="m-3"
                              >
                                <h5>ERRORS</h5>
                                {urlData.cssAnalysis?.data?.errorText?.length ? (
                                  <ul>
                                    {' '}
                                    {urlData.cssAnalysis?.data?.errorText?.map((item) => {
                                      return (
                                        <li>
                                          {' '}
                                          <b>{item.errorUrl}</b>
                                          <ul>
                                            {item &&
                                              item.tempLinks &&
                                              item.tempLinks.length &&
                                              item.tempLinks?.map((item) => {
                                                return <li> {item.label}</li>
                                              })}
                                          </ul>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                ) : (
                                  <></>
                                )}
                                <h5>WARNING</h5>
                                {urlData.cssAnalysis?.data?.warningText?.length ? (
                                  <ul>
                                    {' '}
                                    {urlData.cssAnalysis?.data?.warningText?.map((item) => {
                                      return (
                                        <li>
                                          {' '}
                                          <b>{item.warningUrl}</b>
                                          <ul>
                                            {item &&
                                              item.tempLinks &&
                                              item.tempLinks.length &&
                                              item.tempLinks?.map((item) => {
                                                return <li> {item.label}</li>
                                              })}
                                          </ul>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                ) : (
                                  <></>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col md="4">
                              <h3>SERVER CONFIGURATION & SECURITY SIGNALS</h3>
                            </Col>
                            <Col>
                              <Row className="display-end">
                                <Col md="3">
                                  <span>
                                    Score:{' '}
                                    {(
                                      (serverSCounter * 100) /
                                      (serverFCounter + serverWCounter + serverSCounter)
                                    ).toFixed(0)}
                                  </span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant={
                                      (
                                        (serverSCounter * 100) /
                                        (serverFCounter + serverWCounter + serverSCounter)
                                      ).toFixed(0) > 75
                                        ? 'success'
                                        : (
                                            (serverSCounter * 100) /
                                            (serverFCounter + serverWCounter + serverSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                    }
                                    now={
                                      (
                                        (serverSCounter * 100) /
                                        (serverFCounter + serverWCounter + serverSCounter)
                                      ).toFixed(0) || 0
                                    }
                                    label=""
                                  />
                                </Col>
                                <Col md="3">
                                  <span>Success: {serverSCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="success"
                                    now={serverSCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Warning: {serverWCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="warning"
                                    now={serverWCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Failed: {serverFCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="danger"
                                    now={serverFCounter || 0}
                                    label=""
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {urlData?.urlCanonicalization?.data ? (
                            <CommonSEOHOC
                              id={'url-canonicalization'}
                              title={'URL Canonicalization Signal Test'}
                              infoMessage={commonSEOIssueInfo.urlCanonicalization}
                              status={urlData?.urlCanonicalization?.data?.testResult?.status}
                              showDevider={false}
                            >
                              <UrlCanonicalization data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.httpsTest?.data ? (
                            <CommonSEOHOC
                              id={'https-test'}
                              status={urlData?.httpsTest?.data?.testResults?.status}
                              title={'Mixed Content Test (HTTP over HTTPS)'}
                              infoMessage={commonSEOIssueInfo.httpsTest}
                            >
                              <HttpsTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.http2Test?.data ? (
                            <CommonSEOHOC
                              id={'http2-test'}
                              status={urlData?.http2Test?.data?.testResults?.status}
                              title={'HTTP2 Signal Test'}
                              infoMessage={commonSEOIssueInfo.http2Test}
                            >
                              <Http2Test data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.hstsTest?.data ? (
                            <CommonSEOHOC
                              id={'hsts'}
                              status={urlData?.hstsTest?.data?.testResults?.status}
                              title={'HSTS Signal Analysis'}
                              infoMessage={commonSEOIssueInfo.hsts}
                            >
                              <HSTS data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.safeBrowsing?.data ? (
                            <CommonSEOHOC
                              id={'safe-browsing'}
                              status={urlData?.safeBrowsing?.data?.testResults?.status}
                              title={'Safe Browsing Signal Test'}
                              infoMessage={commonSEOIssueInfo.safeBrowsing}
                            >
                              <SafeBrowsing data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.serverSignature?.data ? (
                            <CommonSEOHOC
                              id={'server-signature'}
                              status={urlData?.serverSignature?.data?.testResults?.status}
                              title={'Server Signature Analysis'}
                              infoMessage={commonSEOIssueInfo.serverSignature}
                            >
                              <ServerSignature data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.directoryBrowsing?.data ? (
                            <CommonSEOHOC
                              id={'directory-browsing'}
                              status={urlData?.directoryBrowsing?.data?.testResults?.status}
                              title={'Directory Browsing Analysis'}
                              infoMessage={commonSEOIssueInfo.directoryBrowsing}
                            >
                              <DirectoryBrowsing data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.plainTextEmail?.data ? (
                            <CommonSEOHOC
                              id={'plain-text'}
                              status={urlData?.plainTextEmail?.data?.testResults?.status}
                              title={'Plaintext Emails Signal Test'}
                              infoMessage={commonSEOIssueInfo.plainText}
                            >
                              <PlainTextEmail data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.unsafeCrossOriginLinksTest?.data ? (
                            <CommonSEOHOC
                              id={'unsafe-cross-origin-links'}
                              status={urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status}
                              title={'Unsafe Cross-Origin Signal Analysis'}
                              infoMessage={commonSEOIssueInfo.unsafeCrossOriginLinks}
                            >
                              <UnsafeCrossOriginLinks
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() =>
                                  setUnsafeCrossOriginLinksFlag(!unsafeCrossOriginLinksFlag)
                                }
                              />
                              <ModalHOC
                                show={unsafeCrossOriginLinksFlag}
                                toggleShow={() => setUnsafeCrossOriginLinksFlag(!unsafeCrossOriginLinksFlag)}
                                title={`Full list of links using target='_blank' without rel='noopener' or rel='noreferrer' attribute`}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <ul>
                                  {urlData?.unsafeCrossOriginLinksTest?.data?.unsafeCrossOriginList?.list
                                    ?.length ? (
                                    urlData?.unsafeCrossOriginLinksTest?.data?.unsafeCrossOriginList?.list.map(
                                      (item) => {
                                        return <li>{item?.snippet}</li>
                                      },
                                    )
                                  ) : (
                                    <li>No Link found.</li>
                                  )}
                                </ul>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.sslFreakTest?.data ? (
                            <CommonSEOHOC
                              id={'ssl-freak'}
                              status={urlData?.sslFreakTest?.data?.testResults?.status}
                              title={'SSL and HTTPS Signals'}
                              infoMessage={commonSEOIssueInfo.sslFreak}
                            >
                              <SSLHttpTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.brotilTest?.data ? (
                            <CommonSEOHOC
                              id={'brotil-test'}
                              status={urlData?.brotilTest?.data?.testResults?.status}
                              title={'Brotli Analysis Test'}
                              infoMessage={commonSEOIssueInfo.brotilTest}
                            >
                              <BrotilTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.dnsSecurityTest?.data ? (
                            <CommonSEOHOC
                              id={'dns-security'}
                              isConsidered={false}
                              status={urlData?.dnsSecurityTest?.data?.testResults?.status}
                              title={'DNS Security'}
                              infoMessage={commonSEOIssueInfo.dnsSecurity}
                            >
                              <DnsSecurityTest
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setDnsSecurityFlag(!dnsSecurityFlag)}
                              />
                              <ModalHOC
                                show={dnsSecurityFlag}
                                toggleShow={() => setDnsSecurityFlag(!dnsSecurityFlag)}
                                title={'DNS Security'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                {urlData?.dnsSecurityTest?.data?.dnsSecurity?.label3?.length ? (
                                  <>
                                    {urlData?.dnsSecurityTest?.data?.dnsSecurity?.label3.map((item) => {
                                      return (
                                        <div>
                                          {item.substring(0, 2) === 'No' ? (
                                            <Icon.X size={16} style={{ marginRight: 5 }} color="red" />
                                          ) : (
                                            <Icon.Check size={16} style={{ marginRight: 5 }} color="green" />
                                          )}
                                          <span>&nbsp;{item}</span>
                                        </div>
                                      )
                                    })}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.tlsLogjamTest?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'tls-logjam'}
                                status={urlData?.tlsLogjamTest?.data?.testResults?.status}
                                title={'TLS Logjam Signal Test'}
                                infoMessage={commonSEOIssueInfo.tlsLogjam}
                              >
                                <TlsLogjam data={urlData} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col md="4">
                              <h3>MOBILE RESPONSIVE & MOBILE SPEED SIGNALS</h3>
                            </Col>
                            <Col>
                              <Row className="display-end">
                                <Col md="3">
                                  <span>
                                    Score:{' '}
                                    {(
                                      (mobileUsabilitySCounter * 100) /
                                      (mobileUsabilityFCounter +
                                        mobileUsabilityWCounter +
                                        mobileUsabilitySCounter)
                                    ).toFixed(0)}
                                  </span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant={
                                      (
                                        (mobileUsabilitySCounter * 100) /
                                        (mobileUsabilityFCounter +
                                          mobileUsabilityWCounter +
                                          mobileUsabilitySCounter)
                                      ).toFixed(0) > 75
                                        ? 'success'
                                        : (
                                            (mobileUsabilitySCounter * 100) /
                                            (mobileUsabilityFCounter +
                                              mobileUsabilityWCounter +
                                              mobileUsabilitySCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                    }
                                    now={
                                      (
                                        (mobileUsabilitySCounter * 100) /
                                        (mobileUsabilityFCounter +
                                          mobileUsabilityWCounter +
                                          mobileUsabilitySCounter)
                                      ).toFixed(0) || 0
                                    }
                                    label=""
                                  />
                                </Col>
                                <Col md="3">
                                  <span>Success: {mobileUsabilitySCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="success"
                                    now={mobileUsabilitySCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Warning: {mobileUsabilityFCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="warning"
                                    now={mobileUsabilityFCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Failed: {mobileUsabilityFCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="danger"
                                    now={mobileUsabilityFCounter || 0}
                                    label=""
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {urlData?.viewport?.data ? (
                            <CommonSEOHOC
                              id={'viewport'}
                              status={urlData?.viewport?.data?.testResults?.status}
                              title={'Viewport Analysis'}
                              infoMessage={commonSEOIssueInfo.viewport}
                              showDevider={false}
                            >
                              <Viewport data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                        
                          {urlData?.fontSizeTest?.data ? (
                            <CommonSEOHOC
                              id={'font-size'}
                              status={urlData?.fontSizeTest?.data?.testResults?.status}
                              title={'Font Size Signals'}
                              infoMessage={commonSEOIssueInfo.fontSize}
                            >
                              <FontSizeTest data={urlData} />
                            </CommonSEOHOC>
                          ) : null}
                          {urlData?.tapTargetTest?.data ? (
                            <CommonSEOHOC
                              id={'tap-target'}
                              status={urlData?.tapTargetTest?.data?.testResults?.status}
                              title={'Tap Target Signals'}
                              infoMessage={commonSEOIssueInfo.tapTargets}
                            >
                              <TapTargets data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* contentWidthTest title*/}
                          {urlData?.contentWidthTest?.data ? (
                            <CommonSEOHOC
                              id={'content-width'}
                              status={urlData?.contentWidthTest?.data?.testResults?.status}
                              title={'Content-width Signals'}
                              infoMessage={commonSEOIssueInfo.contentWidth}
                            >
                              <ContentWidth data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* speedIndexTest title*/}
                          {urlData?.speedIndexTest?.data ? (
                            <CommonSEOHOC
                              id={'speed-index'}
                              status={urlData?.speedIndexTest?.data?.testResults?.status}
                              title={'Speed Index Signals'}
                              infoMessage={commonSEOIssueInfo.speedIndex}
                            >
                              <SpeedIndex data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* firstContentfulPaintTest title*/}
                          {urlData?.firstContentfulPaintTest?.data ? (
                            <CommonSEOHOC
                              id={'first-contentful-paint'}
                              status={urlData?.firstContentfulPaintTest?.data?.testResults?.status}
                              title={'First Contentful Paint Signals'}
                              infoMessage={commonSEOIssueInfo.firstContentfulPaint}
                            >
                              <FirstContentfulPaint data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* largestContentfulPaintTest title*/}
                          {urlData?.largestContentfulPaintTest?.data ? (
                            <CommonSEOHOC
                              id={'last-contentful-paint'}
                              status={urlData?.largestContentfulPaintTest?.data?.testResults?.status}
                              title={'Last Contentful Paint Signals'}
                              infoMessage={commonSEOIssueInfo.largestContentfulPaint}
                            >
                              <LargestContentfulPaint data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* cumulativeLayoutShiftTest title*/}
                          {urlData?.cumulativeLayoutShiftTest?.data ? (
                            <CommonSEOHOC
                              id={'cumulative-layout-shift-test'}
                              status={urlData?.cumulativeLayoutShiftTest?.data?.testResults?.status}
                              title={'Cumulative Layout Shift Signals'}
                              infoMessage={commonSEOIssueInfo.cumulativeLayoutShift}
                            >
                              <CumulativeLayoutShift data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* interactiveTest title*/}
                          {urlData?.interactiveTest?.data ? (
                            <CommonSEOHOC
                              id={'interactive'}
                              status={urlData?.interactiveTest?.data?.testResults?.status}
                              title={'Interactive Signals'}
                              infoMessage={commonSEOIssueInfo.timetoInteractive}
                            >
                              <TimetoInteractiveTest data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {/* firstInputDelayTest title*/}
                          {urlData?.firstInputDelayTest?.data ? (
                            <CommonSEOHOC
                              id={'first-input-delay'}
                              status={urlData?.firstInputDelayTest?.data?.testResults?.status}
                              title={'First Input Delay Signals'}
                              infoMessage={commonSEOIssueInfo.firstInputDelay}
                            >
                              <FirstInputDelay data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}

                          {urlData?.mobileSnapShotTest?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'mobile-screenshot'}
                                status={urlData?.mobileSnapShotTest?.data?.length > 2 ? 1 : 3}
                                isConsidered={false}
                                title={'Mobile Screenshot Analysis'} //Mobile Snapshot
                                infoMessage={commonSEOIssueInfo.mobileScreenshot}
                              >
                                <MobileScreenshot data={urlData} showDetail={true} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}

                          {urlData?.desktopSnapShotTest?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'desktop-screenshot'}
                                status={urlData?.desktopSnapShotTest?.data?.length > 2 ? 1 : 3}
                                isConsidered={false}
                                title={'Desktop Screenshot Analysis'}
                                infoMessage={commonSEOIssueInfo.desktopScreenshot}
                              >
                                <DesktopScreenshot data={urlData} showDetail={true} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                         
                        </CardBody>
                      </Card>
                      <Card className='mb-0'>
                        <CardHeader>
                          <Row>
                            <Col md="4">
                              <h3>Advanced SEO Signal Test</h3>
                            </Col>
                            <Col>
                              <Row className="display-end">
                                <Col md="3">
                                  <span>
                                    Score:{' '}
                                    {(
                                      (advanceSeoSCounter * 100) /
                                      (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                    ).toFixed(0)}
                                  </span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant={
                                      (
                                        (advanceSeoSCounter * 100) /
                                        (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                      ).toFixed(0) > 75
                                        ? 'success'
                                        : (
                                            (advanceSeoSCounter * 100) /
                                            (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                          ).toFixed(0) <= 50
                                        ? 'danger'
                                        : 'warning'
                                    }
                                    now={
                                      (
                                        (advanceSeoSCounter * 100) /
                                        (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                      ).toFixed(0) || 0
                                    }
                                    label=""
                                  />
                                </Col>
                                <Col md="3">
                                  <span>Success: {advanceSeoSCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="success"
                                    now={advanceSeoSCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Warning: {advanceSeoWCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="warning"
                                    now={advanceSeoWCounter || 0}
                                    label=""
                                  />
                                </Col>

                                <Col md="3">
                                  <span>Failed: {advanceSeoFCounter}</span>
                                  <ProgressBar
                                    className="mb-3"
                                    variant="danger"
                                    now={advanceSeoFCounter || 0}
                                    label=""
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {urlData?.microDataSchema?.data ? (
                            <CommonSEOHOC
                              id={'microdata-schema'}
                              status={urlData?.microDataSchema?.data?.microSchemaResults?.status}
                              title={'Microdata Schema Analysis'} //Structured Data Test
                              infoMessage={commonSEOIssueInfo.microdataSchema}
                              showDevider={false}
                            >
                              <MicroDataSchema
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setMicroSchemaData(!microSchemaData)}
                              />

                              <ModalHOC
                                show={microSchemaData}
                                toggleShow={() => setMicroSchemaData(!microSchemaData)}
                                title={'Microdata Schema Analysis'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                {urlData?.microDataSchema?.data?.data?.jsonld ? (
                                  <>
                                    <div>
                                      {Object.keys(urlData?.microDataSchema?.data?.data?.jsonld)?.map(
                                        (key, i) => {
                                          if (
                                            urlData?.microDataSchema?.data?.data?.jsonld?.hasOwnProperty(key)
                                          ) {
                                            if (
                                              typeof urlData?.microDataSchema?.data?.data?.jsonld?.[key]?.[0] ==
                                              'object'
                                            ) {
                                              return getDataToDisplay(
                                                urlData?.microDataSchema?.data?.data?.jsonld?.[key]?.[0],
                                              )
                                            } else {
                                              return (
                                                <p key={`urlData-${i + 1}`}>
                                                  <span>
                                                    {key} {'1'}:{' '}
                                                    {urlData?.microDataSchema?.data?.data?.jsonld?.[key]}
                                                  </span>
                                                </p>
                                              )
                                            }
                                          } else {
                                            return ''
                                          }
                                        },
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.custom404?.data ? (
                            <CommonSEOHOC
                              id={'custom-404'}
                              title={'Custom 404 Signal Test'}
                              infoMessage={commonSEOIssueInfo.custom404}
                              status={urlData?.custom404?.data?.testResult?.status}
                            >
                              <Custom404 data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.noIndexChecker?.data ? (
                            <CommonSEOHOC
                              id={'no-index'}
                              status={urlData?.noIndexChecker?.data?.testResult?.status}
                              title={'No Index Checker Signal Test'}
                              infoMessage={commonSEOIssueInfo.noIndex}
                            >
                              <NoIndexChecker data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.canonicalUrl?.data ? (
                            <CommonSEOHOC
                              id={'canonical-url'}
                              title={'Canonical URL Signal Test'}
                              infoMessage={commonSEOIssueInfo.canonicalURL}
                              status={urlData?.canonicalUrl?.data?.testResult?.status}
                            >
                              <CanonicalURL data={urlData} showUrl={true} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.noFollow?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'no-follow'}
                                isConsidered={false}
                                status={urlData?.noFollow?.data?.testResults?.status}
                                title={'No Follow Signal Test'}
                                infoMessage={commonSEOIssueInfo.noFollow}
                              >
                                <NoFollow
                                  data={urlData}
                                  showDetail={true}
                                  onShowDetail={() => setNoFollowFlag(!noFollowFlag)}
                                />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.disallowDirectiveChecker?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'disalllow-directive'}
                                isConsidered={false}
                                isAvailable={urlData?.disallowDirectiveChecker?.data?.finalRes?.status}
                                title={'Disallow Directive Signal Test'}
                                infoMessage={commonSEOIssueInfo.disalllowDirective}
                              >
                                <div className="vr-detail-block">
                                  <div className="vr-report-status-icon" />
                                  <h5>{urlData?.url}</h5>
                                </div>
                                <div className="vr-detail-block">
                                  <div className="vr-report-status-icon" />
                                  <p>
                                    {urlData?.disallowDirectiveChecker?.data ? (
                                      <>{urlData?.disallowDirectiveChecker?.data?.message || ''}</>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                </div>
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          
                          {urlData?.metaRefresh?.data ? (
                            <CommonSEOHOC
                              id={'meta-refresh'}
                              title={'Meta Refresh Signal Test'}
                              infoMessage={commonSEOIssueInfo.metaRefresh}
                              status={urlData?.metaRefresh?.data?.testResult?.status}
                            >
                              <MetaRefresh data={urlData} />
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                          {urlData?.spfRecord?.data ? (
                            <>
                              <CommonSEOHOC
                                id={'spf-record'}
                                title={'SPF Record Analysis'}
                                infoMessage={commonSEOIssueInfo.spfRecord}
                                status={urlData?.spfRecord?.data?.testResult?.status}
                              >
                                <SpfRecord data={urlData} />
                              </CommonSEOHOC>
                            </>
                          ) : (
                            <></>
                          )}
                          {urlData?.adsTxt?.data ? (
                            <CommonSEOHOC
                              id={'ads-txt'}
                              status={urlData?.adsTxt?.data?.testResults?.status}
                              title={'Ads.txt Analysis'}
                              infoMessage={commonSEOIssueInfo.adsTxt}
                            >
                              <AdsTxt
                                data={urlData}
                                showDetail={true}
                                onShowDetail={() => setAdsTxtFlag(!adsTxtFlag)}
                              />
                              <ModalHOC
                                show={adsTxtFlag}
                                toggleShow={() => setAdsTxtFlag(!adsTxtFlag)}
                                title={'Ads.txt Content'}
                                bodyClassName="m-3"
                                titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                              >
                                <pre>{urlData?.adsTxt?.data?.adsTxtResults}</pre>
                              </ModalHOC>
                            </CommonSEOHOC>
                          ) : (
                            <></>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="report-sidebar d-none d-lg-block d-sm-none sidebar-visibility">
                  {(urlData && urlData !== '' && (
                    <>
                      <Accordion defaultActiveKey="0" style={{height: 'calc(100vh - 182px)'}}>
                        <Accordion.Item eventKey="0" className="bg-white">
                          <Accordion.Header>
                            <Container className="sidebar-row-container" fluid>
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  GENERAL
                                </Col>
                                <Col>
                                  {' '}
                                  <span className="pass-block">
                                    {' '}
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{positiveCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    {' '}
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning, marginRight: 5 }}>
                                      {warningCounter}
                                    </span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{negativeCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                            {/* General Pass: {positiveCounter} Failed: {negativeCounter} */}
                          </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  PRIMARY SEO SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block" style={{ alignItem: 'center' }}>
                                    {' '}
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{commonSeoSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    {' '}
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning, marginRight: 5 }}>
                                      {commonSeoWCounter}
                                    </span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{commonSeoFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              {urlData?.metaData?.data?.response?.title?.titleStatus === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.metaData?.data?.response?.title?.titleStatus === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="meta-title" spy={true} smooth={true} offset={-150} duration={500}>
                                Meta Title Signal
                              </Link>
                            </p>

                            <p>
                              {urlData?.metaData?.data?.response?.description?.descriptionStatus === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.metaData?.data?.response?.description?.descriptionStatus === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="meta-description"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Meta Description Signal
                              </Link>
                            </p>

                            <p>
                              {urlData?.microDataSchema?.data?.metaTagsResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.microDataSchema?.data?.metaTagsResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="social-media-meta-tag"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Social Media Meta Tags Signals
                              </Link>
                            </p>

                            <p>
                              {urlData?.microDataSchema?.data?.keywordSuccessFlag === true ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="keyword-usage-test"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Keyword Usage Test Signals
                              </Link>
                            </p>

                            <p>
                              {urlData?.headingAnalysis?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.headingAnalysis?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="heading-analysis"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Heading Tag Analysis Signals
                              </Link>
                            </p>

                            <p>
                              {urlData?.metaData?.data?.response?.robotsTestResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.metaData?.data?.response?.robotsTestResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="robots-txt" spy={true} smooth={true} offset={-150} duration={500}>
                                Robots.txt Signal Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.siteMapData?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.siteMapData?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="sitemap-data" spy={true} smooth={true} offset={-150} duration={500}>
                                Sitemap Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="seo-friendly" spy={true} smooth={true} offset={-150} duration={500}>
                                SEO Friendly URL Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.imageAlt?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.imageAlt?.data?.testResults?.status === 2 ? (
                                <span style={{ color: palette.warning }}>Warning</span>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="image-alt" spy={true} smooth={true} offset={-150} duration={500}>
                                Image Alt Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.descriptiveLinkTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.descriptiveLinkTest?.data?.testResults?.status === 2 ? (
                                <span style={{ color: palette.warning }}>Warning</span>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="descriptive-test"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Descriptive Link Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.responsiveImage?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.responsiveImage?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="responsive-image"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Excessive Image Data Size Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.aspectRatio?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.aspectRatio?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="aspect-ratio" spy={true} smooth={true} offset={-150} duration={500}>
                                Image Aspect Ratio Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.inlineCss?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.inlineCss?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="inline-css" spy={true} smooth={true} offset={-150} duration={500}>
                                Inline CSS Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.htmlValidation?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.htmlValidation?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="html-validation"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Html Validation
                              </Link>
                            </p>

                            <p>
                              {urlData?.googleAnalytics?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.googleAnalytics?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="google-analytics"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Google Analytics Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.faviconCheckerTest?.data?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="favicon-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Favicon Checker Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.jsError?.data?.testResults.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.jsError?.data?.testResults.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="js-error" spy={true} smooth={true} offset={-150} duration={500}>
                                JS Error Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.consoleErrors?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.consoleErrors?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="console-errors" spy={true} smooth={true} offset={-150} duration={500}>
                                Historic Console Error Signal Check
                              </Link>
                            </p>

                            <p>
                              {urlData?.advancedConsoleErrors?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.advancedConsoleErrors?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="advanced-console-errors" spy={true} smooth={true} offset={-150} duration={500}>
                                Advanced Console Error Signal Check
                              </Link>
                            </p>

                            <p>
                              {urlData?.characterSet?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.characterSet?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="character-set" spy={true} smooth={true} offset={-150} duration={500}>
                                Charset Declaration Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.socialMediaTwitterData?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.socialMediaTwitterData?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="social-media" spy={true} smooth={true} offset={-150} duration={500}>
                                Social Media Signal Test
                              </Link>
                            </p>

                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  WEBSITE SITE LOADING SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{speedOptimizeSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{speedOptimizeWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{speedOptimizeFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              {urlData && urlData?.htmlPageSize?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData && urlData?.htmlPageSize?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="html-page" spy={true} smooth={true} offset={-150} duration={500}>
                                HTML Page Size Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.domSize?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.domSize?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="dom-size" spy={true} smooth={true} offset={-150} duration={500}>
                                DOM Size Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.htmlCompression?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.htmlCompression?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="html-compression"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Web Content Compression Check
                              </Link>
                            </p>

                            <p>
                              {urlData && urlData?.siteSpeed?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData && urlData?.siteSpeed?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="site-speed" spy={true} smooth={true} offset={-150} duration={500}>
                                Site Loading Speed Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.bootupTime?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.bootupTime?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="js-execution-time"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                JS Execution Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.pageObject?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.pageObject?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="page-object" spy={true} smooth={true} offset={-150} duration={500}>
                                Page Objects Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.pageCache?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.pageCache?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="page-cache" spy={true} smooth={true} offset={-150} duration={500}>
                                Page Cache Test (Server Side Caching) Signal Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.flashTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.flashTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="flash-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Flash Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.cdnUsageData?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.cdnUsageData?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="cdn-usage" spy={true} smooth={true} offset={-150} duration={500}>
                                CDN Usage Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.mordenImage?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.mordenImage?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="morden-image" spy={true} smooth={true} offset={-150} duration={500}>
                                Morden Image Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.cachePolicy?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.cachePolicy?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="cache-policy" spy={true} smooth={true} offset={-150} duration={500}>
                                Cache Policy Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.imageCache?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.imageCache?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="image-cache" spy={true} smooth={true} offset={-150} duration={500}>
                                Image Cache Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.jsCache?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.jsCache?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="js-cache" spy={true} smooth={true} offset={-150} duration={500}>
                                JavaScript Caching Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.cssCache?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.cssCache?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="css-cache" spy={true} smooth={true} offset={-150} duration={500}>
                                CSS Caching Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.jsMinification?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.jsMinification?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="js-minification"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                JavaScript Minification Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.cssMinification?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.cssMinification?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="css-minification"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                CSS Minification Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.renderBlocking?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.renderBlocking?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="render-blocking"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Render Blocking Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.nestedTable?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.nestedTable?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="nested-table" spy={true} smooth={true} offset={-150} duration={500}>
                                Nested Tables Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData && urlData?.framesetTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData && urlData?.framesetTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="frameset-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Frameset Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.docType?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.docType?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="doc-type" spy={true} smooth={true} offset={-150} duration={500}>
                                Doctype Signal Test
                              </Link>
                            </p>
                            <p>
                              {urlData?.redirectTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.redirectTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="redirect-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Redirect Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.contentfulPaint?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.contentfulPaint?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="contentful-paint"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Contentful Paint Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.layoutShift?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.layoutShift?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link to="layout-shift" spy={true} smooth={true} offset={-150} duration={500}>
                                Cumulative Layout Shift Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.cssAnalysis?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.cssAnalysis?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="css-analysis" spy={true} smooth={true} offset={-150} duration={500}>
                                Css Analysis Test
                              </Link>{' '}
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  SERVER CONFIGURATION & SECURITY SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{serverSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{serverWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{serverFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              {urlData?.urlCanonicalization?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.urlCanonicalization?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="url-canonicalization"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                URL Canonicalization Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.httpsTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.httpsTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="https-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Mixed Content Test (HTTP over HTTPS)
                              </Link>
                            </p>

                            <p>
                              {urlData?.http2Test?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.http2Test?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="http2-test" spy={true} smooth={true} offset={-150} duration={500}>
                                HTTP2 Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.hstsTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.hstsTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="hsts" spy={true} smooth={true} offset={-150} duration={500}>
                                HSTS Signal Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData && urlData?.safeBrowsing?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData && urlData?.safeBrowsing?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="safe-browsing" spy={true} smooth={true} offset={-150} duration={500}>
                                Safe Browsing Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.serverSignature?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.serverSignature?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="server-signature"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Server Signature Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.directoryBrowsing?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.directoryBrowsing?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="directory-browsing"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Directory Browsing Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.plainTextEmail?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.plainTextEmail?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="plain-text" spy={true} smooth={true} offset={-150} duration={500}>
                                Plaintext Emails Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status === 2 ? (
                                <span style={{ color: palette.warning }}>Warning</span>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="unsafe-cross-origin-links"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Unsafe Cross-Origin Signal Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.sslFreakTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.sslFreakTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="ssl-freak" spy={true} smooth={true} offset={-150} duration={500}>
                                 SSL and HTTPS Signals
                              </Link>
                            </p>

                            <p>
                              {urlData?.brotilTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.brotilTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="brotil-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Brotli Analysis Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.tlsLogjamTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.tlsLogjamTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="tls-logjam" spy={true} smooth={true} offset={-150} duration={500}>
                                TLS Logjam Signal Test
                              </Link>
                            </p>

                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  MOBILE RESPONSIVE & MOBILE SPEED SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{mobileUsabilitySCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{mobileUsabilityWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{mobileUsabilityFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <MenuTitleTest
                              linkTo={'viewport'}
                              testStatus={urlData?.viewport?.data?.testResults?.status}
                              title={'Viewport Analysis'}
                            />
                            {/* <MenuTitleTest linkTo={'media-query'} testStatus={urlData?.mediaQuery?.data?.testResults?.status} title={'MediaQuery Analysis'}/> */}
                            <MenuTitleTest
                              linkTo={'font-size'}
                              testStatus={urlData?.fontSizeTest?.data?.testResults?.status}
                              title={'Font Size Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'tap-target'}
                              testStatus={urlData?.tapTargetTest?.data?.testResults?.status}
                              title={'Tap Target Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'content-width'}
                              testStatus={urlData?.contentWidthTest?.data?.testResults?.status}
                              title={'Content-width Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'speed-index'}
                              testStatus={urlData?.speedIndexTest?.data?.testResults?.status}
                              title={'Speed Index Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'first-contentful-paint'}
                              testStatus={urlData?.firstContentfulPaintTest?.data?.testResults?.status}
                              title={'First Contentful Paint Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'last-contentful-paint'}
                              testStatus={urlData?.largestContentfulPaintTest?.data?.testResults?.status}
                              title={'Last Contentful Paint Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'cumulative-layout-shift-test'}
                              testStatus={urlData?.cumulativeLayoutShiftTest?.data?.testResults?.status}
                              title={'Cumulative Layout Shift Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'interactive'}
                              testStatus={urlData?.interactiveTest?.data?.testResults?.status}
                              title={'Interactive Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'first-input-delay'}
                              testStatus={urlData?.firstInputDelayTest?.data?.testResults?.status}
                              title={'First Input Delay Signals'}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  ADVANCED SEO SIGNAL TEST
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{advanceSeoSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{advanceSeoWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{advanceSeoFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              {urlData?.microDataSchema?.data?.microSchemaResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.microDataSchema?.data?.microSchemaResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="microdata-schema"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Microdata Schema Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.custom404?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.custom404?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="custom-404" spy={true} smooth={true} offset={-150} duration={500}>
                                Custom 404 Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.noIndexChecker?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.noIndexChecker?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="no-index" spy={true} smooth={true} offset={-150} duration={500}>
                                No Index Checker Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.canonicalUrl?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.canonicalUrl?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="canonical-url" spy={true} smooth={true} offset={-150} duration={500}>
                                Canonical URL Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.metaRefresh?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.metaRefresh?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="meta-refresh" spy={true} smooth={true} offset={-150} duration={500}>
                                Meta Refresh Signal Test
                              </Link>{' '}
                            </p>

                            <p>
                              {urlData?.spfRecord?.data?.testResult?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.spfRecord?.data?.testResult?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="spf-record" spy={true} smooth={true} offset={-150} duration={500}>
                                SPF Record Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.adsTxt?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.adsTxt?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="ads-txt" spy={true} smooth={true} offset={-150} duration={500}>
                                Ads.txt Analysis
                              </Link>{' '}
                            </p>

                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name">NON TRANSFORMATIVE SIGNALS</Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              &nbsp;
                              <Link to="seo-result" spy={true} smooth={true} offset={-150} duration={500}>
                                Google Search Results Preview - SERP View
                              </Link>
                            </p>

                            <p>
                              &nbsp;
                              <Link to="dns-security" spy={true} smooth={true} offset={-150} duration={500}>
                                DNS Security
                              </Link>
                            </p>

                            <p>
                              &nbsp;
                              <Link
                                to="mobile-screenshot"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Mobile Screenshot Analysis
                              </Link>
                            </p>

                            <p>
                              &nbsp;
                              <Link
                                to="desktop-screenshot"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Desktop Screenshot Analysis
                              </Link>
                            </p>

                            <p>
                              &nbsp;
                              <Link to="no-follow" spy={true} smooth={true} offset={-150} duration={500}>
                                No Follow Signal Test
                              </Link>
                            </p>

                            <p>
                              &nbsp;
                              <Link
                                to="disalllow-directive"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Disallow Directive Signal Test
                              </Link>
                            </p>

                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  )) || <></>}
                </div>
              </>
            ) : (
              <>
                {/* {!quickSeoFailed ? ( */}
                  {!isModalOpenLighthouse && !quickSeoFailed && !getQuickSeoReportError ?  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      alignSelf: 'center',
                      flexDirection: 'column',
                      minHeight: '80vh',
                    }}
                  >
                    <div className="text-center">
                      <Loader />
                    </div>
                    <br /><br />
                    {isLighthouseRateLimitExceed ? (
                      <Alert
                        variant="warning"
                        className="alert-outline-coloured"
                        key="meta-title"
                      >
                        <div className="alert-icon" />
                        <div className="alert-message">
                          <span className='alert-for-mobile'>
                            We've detected that your server or firewall is configured with stringent rate limiting settings. To ensure successful processing, we're momentarily pausing to respect these limits. Please allow approximately 61 seconds for us to safely reinitiate the request for {quickSEOUrl} information. We appreciate your patience as we work to continue the scan in compliance with your server's policies. please stand by this message will update or disappear shortly.
                          </span>
                        </div>
                      </Alert>
                    ) : null}
                  </div> : null}

              </>
            )}
          </div>
        </Row>
      </Container>
    </>
  )
}

export default QuickSEOCheckup

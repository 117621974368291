import * as Icon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'react-bootstrap'
import 'modules/Free tools/components/CommonFreeToolsCard.scss'

const CommonFreeToolCard = ({ icon, title, description, onClick }) => {
  return (
    <Card className="tools-card-wrapper" onClick={onClick}>
      <Card.Body className="tools-card-wrapper-body">
        <div className="tools-icon-wrapper">
          <FontAwesomeIcon className="tools-icon" icon={Icon[icon]} />
        </div>
        <h3 className="tools-card-heading">{title?.length > 25 ? `${title?.substring(0, 25)}...` : title}</h3>
        <p className="tools-card-description">
          {description?.length > 100 ? `${description?.substring(0, 100)}...` : description}
        </p>
      </Card.Body>
    </Card>
  )
}

export default CommonFreeToolCard

import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_CLEAR,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CLEAR,
  STORE_USER_DATA_START,
  LOGOUT,
  VERIFY_START,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_TOKEN,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_CLEAR,
  CHANGE_PLAN,
  CHANGE_PLAN_SUCCESS,
  CHANGE_PLAN_FAILURE,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAILURE,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_CLEAR,
  SUPPORT_REQUEST_FAILURE,
  SUPPORT_REQUEST,
  CLEAR_SUPPORT_REQUEST,
  SUPPORT_REQUEST_SUCCESS,
  GET_STRIPE_SECRET_KEY_FAILURE,
  GET_STRIPE_SECRET_KEY_SUCCESS,
  CLEAR_STRIPE_SECRET_KEY,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS,
  CLEAR_REGISTER_USERDATA,
  REGISTER_SOCIAL_SUCCESS
} from '../../actionType'

const InitialState = {
  registerSuccess: false,
  registerError: false,
  registerLoading: false,
  registerData: {},
  loginSuccess: false,
  loginError: false,
  loginLoading: false,
  logoutSuccess: false,
  loginData: {},
  errorMessage: '',
  navigateToDashboard: false,
  navigateToSeoCheckupReport: false,
  navigateToProfile: false,
  navigateToUpgrade: false,
  navigateToHelp: false,
  navigateToSettings: false,
  verifyToken: true,
  changePasswordSuccess: false,
  changePasswordError: false,
  toastError:"",
  toastMessage:"",
  supportRequestLoader: false,
  stripeSecretKey:'',
  getSecretKeyError: '',
  paymentMethods:[],
  paymentMethodsLoader: false,
  registerSocialSuccess: false
}

const registerReducer = (state = InitialState, action) => {
  switch (action.type) {
    case REGISTER_START:
      return {
        ...state,
        registerSuccess: false,
        registerError: false,
        registerLoading: true,
        registerData: {},
      }

    case REGISTER_SUCCESS:
      return {
        ...state,
        registerSuccess: true,
        registerError: false,
        errorMessage: action.payload && action.payload.message,
        registerLoading: false,
        registerData: action.payload,
      }

    case REGISTER_SOCIAL_SUCCESS:
      return {
        ...state,
        registerSocialSuccess: true,
        registerError: false,
        errorMessage: action?.payload && action?.payload?.message,
        registerLoading: false,
        registerData: action?.payload,
      }
    case REGISTER_FAILURE:

      return {
        ...state,
        registerSuccess: false,
        registerError: true,
        registerLoading: false,
        errorMessage: action?.payload?.message || action?.e?.message,
        registerData: {},
      }
    case REGISTER_CLEAR:
      return {
        ...state,
        registerSuccess: false,
        registerError: false,
        registerLoading: false,
        errorMessage: '',
        registerSocialSuccess: false
      }
    
    case CLEAR_REGISTER_USERDATA:{
      return {
        ...state,
        registerData: {}
      }
    }  
    
    case LOGIN_START:
      return {
        ...state,
        loginSuccess: false,
        loginError: false,
        loginLoading: true,
        logoutSuccess: false,
        registerData: {},
      }

    case LOGIN_SUCCESS:
      if (action.payload.token) {
        localStorage.setItem('token', action.payload.token)
        localStorage.setItem('isLogin', true)
        localStorage.setItem('userData', JSON.stringify(action.payload))
        return {
          ...state,
          loginSuccess: true,
          loginError: false,
          loginLoading: false,
          verifyToken: true,
          registerData: action.payload,
        }
      } else {
        return {
          ...state,
          loginSuccess: false,
          loginError: true,
          errorMessage: action.payload.message,
          loginLoading: false,
          registerData: action.payload,
        }
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loginSuccess: false,
        loginError: true,
        loginLoading: false,
        registerData: {},
        errorMessage: action.payload?.response?.data?.message || 'something went wrong',
      }
    case LOGIN_CLEAR:
      return {
        ...state,
        loginSuccess: false,
        loginError: false,
        loginLoading: false,
        registerSuccess: false,
        registerError: false,
        registerLoading: false,
        verifySuccess: false,
        verifyError: false,
        errorMessage: '',
      }
    case STORE_USER_DATA_START:
      if(action?.payload){
        localStorage.removeItem("userData")
        localStorage.setItem("userData",JSON.stringify(action?.payload))
      }
      return {
        ...state,
        registerData: action.payload,
      }
    case LOGOUT:
      localStorage.removeItem('token', '')
      localStorage.setItem('isLogin', false)
      localStorage.removeItem('userData')
      return {
        ...state,
        logoutSuccess: true,
        verifyToken: true,
      }

    case RESET_PASSWORD: {
      return {
        ...state,
      }
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        registerError: false,
        registerSuccess: true,
      }
    }

    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        registerError: true,

        errorMessage: action.payload.message,
      }
    }

    case VERIFY_START:
      return {
        ...state,
        verifySuccess: false,
        verifyError: false,
        verifyLoading: true,
        registerData: {},
      }

    case VERIFY_SUCCESS:
      if (action.payload.token) {
        localStorage.setItem('token', action.payload.token)
        localStorage.setItem('isLogin', true)
        localStorage.setItem('userData', JSON.stringify(action.payload))
        return {
          ...state,
          verifySuccess: true,
          verifyError: false,
          verifyLoading: false,
          verifyData: action.payload,
          registerData: action.payload,
        }
      } else {
        return {
          ...state,
          verifySuccess: false,
          verifyError: true,
          errorMessage: action.payload.message,
          verifyLoading: false,
          // verifyData: action.payload,
        }
      }
    case VERIFY_FAILURE:
      return {
        ...state,
        verifySuccess: false,
        verifyError: true,
        verifyLoading: false,
        registerData: {},
      }
    case VERIFY_TOKEN:
      return {
        ...state,
        verifyToken: true,
      }
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        verifyToken: true,
      }
    case VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        verifyToken: false,
      }

    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordError: false,
      }
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordSuccess: true,
      }
    }

    case CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        changePasswordError: true,
        errorMessage: action.payload?.message || 'something went wrong',
      }
    }

    case CHANGE_PASSWORD_CLEAR: {
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordError: false,
      }
    }

    case CHANGE_PLAN: {
      return {
        ...state,
      }
    }
    case CHANGE_PLAN_SUCCESS: {
      if(action?.payload){
        localStorage.removeItem("userData")
        localStorage.setItem("userData",JSON.stringify(action?.payload))
      }
      return {
        ...state,
        registerData: action.payload ? action.payload : state.registerData,
      }
    }

    case CHANGE_PLAN_FAILURE: {
      return {
        ...state,
      }
    }

    case VERIFY_PASSWORD: {
      return {
        ...state,
        toastMessage: '',
        toastError:'',
        passwordVerificationLoading: true
      }
    }
    case VERIFY_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordVerificationLoading: false,
        toastMessage: action?.payload?.message
      }
    }

    case VERIFY_PASSWORD_CLEAR: {
      return {
        ...state,
        passwordVerificationLoading: false,
        toastMessage: "",
        toastError: ""
      }
    }

    case VERIFY_PASSWORD_FAILURE: {
      return {
        ...state,
        passwordVerificationLoading: false,
        toastError: action?.error?.message
      }
    }
    case SUPPORT_REQUEST: {
      return {
        ...state,
        supportRequestLoader: true
      }
    }
    case SUPPORT_REQUEST_SUCCESS: {
      return {
        ...state,
        toastMessage: action?.payload?.message,
        supportRequestLoader: false
      }
    }

    case SUPPORT_REQUEST_FAILURE: {
      return {
        ...state,
        toastError: action?.error,
        supportRequestLoader: false
      }
    }

    case CLEAR_SUPPORT_REQUEST:{
      return {
        ...state,
        toastError:'',
        toastMessage:'',
      supportRequestLoader: false
      }
    }

    case GET_STRIPE_SECRET_KEY_SUCCESS: {
      return {
        ...state, 
        stripeSecretKey: action?.payload?.clientSecret
      }
    }

    case GET_STRIPE_SECRET_KEY_FAILURE: {
      return {
        ...state, 
        getSecretKeyError: action?.error
      }
    }

    case CLEAR_STRIPE_SECRET_KEY:{
      return {
        ...state,
        stripeSecretKey:'',
        getSecretKeyError:''
      }
    }

    case GET_PAYMENT_METHODS: {
  
      return {
        ...state,
        paymentMethodsLoader: true
      }
    }

    case GET_PAYMENT_METHODS_SUCCESS: {
      const getpaymentMethods = action?.payload?.data?.length && action?.payload?.data?.map(item => {
        return {
          cardName: item?.card?.brand,
          lastFourDigit: item?.card?.last4
        } 
      })
      return {
        ...state,
        paymentMethods: getpaymentMethods,
        paymentMethodsLoader: false
      }
    }

    case GET_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        paymentMethodFail: action?.error,
        paymentMethodsLoader: false
      }
    }

    default:
      return state
  }
}
export default registerReducer

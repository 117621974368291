import { Button, Col, Modal, Row } from 'react-bootstrap'
import TestInformationText from './TestInformationText'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { useDeviceCheck } from 'hooks/deviceHook'
import { TestSupportForm } from './testSupportForm'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const CommonSEOHOC = ({
  showDevider = true,
  id,
  status,
  isAvailable,
  infoMessage,
  title,
  children,
  isConsidered = true,
  url,
  scanDate,
}) => {
  const { isMobile } = useDeviceCheck()
  const {
    generateTicketLoader,
    generateTicketSuccess,
    generateTicketFailure,
    usersDomains: usersDomain,
    activeLink,
  } = useSelector(({ dashboard }) => dashboard)

  const token = localStorage.getItem('token')

  const [showSupportForm, setShowSupportForm] = useState(false)

  const splittedURL =
    (usersDomain &&
      activeLink !== undefined &&
      usersDomain?.[activeLink] &&
      usersDomain?.[activeLink]?.items &&
      usersDomain?.[activeLink]?.items?.[0]?.url?.split('/').slice(0, 3)) ||
    ''

  let hostName = splittedURL?.[splittedURL?.length - 1] || ''
  if (hostName.includes('www.')) {
    hostName = hostName.replace('www.', '')
  }

  useEffect(() => {
    if (!generateTicketLoader && (generateTicketSuccess || generateTicketFailure)) {
      setShowSupportForm(false)
    }
  }, [generateTicketLoader, generateTicketFailure, generateTicketSuccess])

  const supportMessage = {
    reportDate: scanDate,
    url,
    state: status === 1 ? 'PASS' : status === 2 ? 'WARNING' : status === 3 ? 'FAIL' : 'NON-TRANSFORMATIVE',
    testName: title,
    resultMessage: infoMessage,
    hostName,
  }

  return (
    <>
      <Modal
        show={showSupportForm}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
        toggle={() => {
          setShowSupportForm(!openKeywordLimitExceed)
        }}
        onClose={() => {
          setShowSupportForm(false)
        }}
        onHide={() => {
          setShowSupportForm(false)
        }}
      >
        <Modal.Header>
          <Modal.Title className="mb-0">Need Support for {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TestSupportForm
            handleClick={() => {
              setShowSupportForm(false)
            }}
            testSupportMessage={supportMessage}
          />
        </Modal.Body>
      </Modal>
      {showDevider ? <hr /> : <></>}
      <Row id={id}>
        <Col md="4" xs="12" className="test-name">
          {isConsidered ? (
            <>
              {!status ? (
                <span>
                  <img style={{ height: 25, marginRight: 5 }} src={crossIcon} />
                </span>
              ) : status === 3 ? (
                <span>
                  <img style={{ height: 25, marginRight: 5 }} src={crossIcon} />
                </span>
              ) : status === 2 ? (
                <span>
                  <img style={{ height: 25, marginRight: 5 }} src={warningIcon} />
                </span>
              ) : status === 4 ? (
                <span>
                  <img style={{ height: 25, marginRight: 5 }} src={warningIcon} />
                </span>
              ) : (
                <span>
                  <img style={{ height: 25, marginRight: 5 }} src={tickIcon} />
                </span>
              )}
              {id != 'meta-title' &&
              id !== 'html-page' &&
              id !== 'morden-image' &&
              id !== 'redirect-test' &&
              id !== 'seo-friendly' &&
              id !== 'unsafe-cross-origin-links' &&
              id !== 'canonical-url' &&
              id != 'desktop-screenshot' &&
              id != 'mobile-screenshot' &&
              id != 'aspect-ratio' &&
              id != 'js-execution-time' &&
              id != 'character-set' &&
              id != 'meta-description' &&
              id != 'favicon-test' &&
              id != 'nested-table' &&
              id != 'image-alt' &&
              id != 'descriptive-test' &&
              id != 'robots-txt' &&
              id != 'sitemap-data' &&
              id != 'heading-analysis' &&
              id != 'social-media-meta-tag' &&
              id != 'keyword-usage-test' &&
              id != 'cdn-usage' &&
              id != 'css-cache' &&
              id != 'js-cache' &&
              id != 'css-minification' &&
              id != 'js-minification' &&
              id != 'image-cache' &&
              id != 'cache-policy' &&
              id !== 'url-canonicalization' &&
              id !== 'meta-refresh' &&
              id !== 'custom-404' &&
              id !== 'spf-record' &&
              id !== 'no-index' &&
              id !== 'media-query' &&
              id !== 'safe-browsing' &&
              id !== 'viewport' &&
              id !== 'font-size' &&
              id !== 'tap-target' &&
              id !== 'content-width' &&
              id !== 'speed-index' &&
              id !== 'first-contentful-paint' &&
              id !== 'last-contentful-paint' &&
              id !== 'cumulative-layout-shift-test' &&
              id !== 'interactive' &&
              id !== 'first-input-delay' &&
              id !== 'plain-text' &&
              id !== 'server-signature' &&
              id !== 'doc-type' &&
              id !== 'contentful-paint' &&
              id !== 'http2-test' &&
              id !== 'layout-shift' &&
              id !== 'ssl-freak' &&
              id !== 'frameset-test' &&
              id !== 'render-blocking' &&
              id !== 'flash-test' &&
              id !== 'page-cache' &&
              id !== 'page-object' &&
              id !== 'html-compression' &&
              id !== 'dom-size' &&
              id !== 'site-speed' &&
              id !== 'tls-logjam' &&
              id !== 'no-follow' &&
              id !== 'brotil-test' &&
              id !== 'ads-txt' &&
              id !== 'css-analysis' &&
              id !== 'console-errors' &&
              id !== 'js-error' &&
              id !== 'inline-css' &&
              id !== 'microdata-schema' &&
              id !== 'social-media' &&
              id != 'https-test' &&
              id != 'responsive-image' &&
              id != 'google-analytics' &&
              id !== 'hsts' &&
              id !== 'html-validation' &&
              id != 'brokenLinks-data' &&
              id != 'directory-browsing' &&
              id != 'advanced-console-errors' ? (
                isAvailable ? (
                  <span>
                    <img style={{ height: 25, marginRight: 5 }} src={tickIcon} />
                  </span>
                ) : (
                  <span>
                    <img style={{ height: 25, marginRight: 5 }} src={crossIcon} />
                  </span>
                )
              ) : (
                ''
              )}
              {/* {isAvailable ? <Icon.CheckCircle color="green" /> : <Icon.XCircle color="red" />} */}
              &nbsp;<span className="d-flex align-items-center">{title}</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faDotCircle}
                style={{ height: 25, marginRight: 5 }}
                color="#ccc"
                size="lg"
              />{' '}
              &nbsp;<span className="d-flex align-items-center">{title}</span>
            </>
          )}
        </Col>

        <Col md="7" xs="12">
          {children}
          {(status === 2 || status === 3) && isConsidered && token ? (
            <Button
              variant="outline-warning"
              style={{ marginLeft: !isMobile ? '1.1rem' : '' }}
              onClick={() => {
                setShowSupportForm(true)
              }}
            >
              Need support ?
            </Button>
          ) : null}
        </Col>
        <Col md="1" xs="12" className="d-none d-lg-block d-sm-none">
          {<TestInformationText testId={id} message={infoMessage} />}
        </Col>
      </Row>
    </>
  )
}

export default CommonSEOHOC

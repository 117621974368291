import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const UnsafeCrossOriginTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.unsafeCrossOriginLinksTest.heading}
      whatIsIT={freeTools.unsafeCrossOriginLinksTest.what}
      howToFixBeforeCode={freeTools.unsafeCrossOriginLinksTest.howSectionBeforeCode}
      codeSection={freeTools.unsafeCrossOriginLinksTest.codeSection}
      howToFixAfterCode={freeTools.unsafeCrossOriginLinksTest.howSectionAfterCode}
    />
  )
}

export default UnsafeCrossOriginTest

import { message } from 'antd'
import { Loader } from 'components/uiCore'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { CLEAR_GENERATE_RESEARCH_DATA, CLEAR_GET_ANALYTICS_DATA } from 'redux/actionType'
import { disconnectGoogle } from 'redux/actions/dashboard'
import 'modules/welcomeBoard/components/addGoogle/ConnectGoogle.scss'
import { useEffect } from 'react'

const ConnectGoogleForm = ({ tokenData, googleAnalyticsFormik }) => {
  const dispatch = useDispatch()
  const { analyticsData, disconnectGoogleLoader, storeAnalyticsDataLoader } = useSelector(
    ({ dashboard }) => dashboard,
  )

  const { prevPageData } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const accountOptions =
    analyticsData?.analyticsData?.accountSummaries?.length &&
    analyticsData?.analyticsData?.accountSummaries?.map((item) => {
      return {
        value: item?.displayName,
        label: item?.displayName,
      }
    })

  const searchConsoleOptions =
    analyticsData?.consoleData?.length &&
    analyticsData?.consoleData?.map((item) => {
      return {
        value: item,
        label: item,
      }
    })

  const filterPropertyForSelectedAccount =
    analyticsData?.analyticsData?.accountSummaries?.length &&
    analyticsData?.analyticsData?.accountSummaries?.filter(
      (item) => item?.displayName === googleAnalyticsFormik?.values?.account,
    )

  const propertyOptions = (filterPropertyForSelectedAccount?.length &&
    filterPropertyForSelectedAccount?.[0]?.propertySummaries?.map((item) => {
      return {
        value: item?.displayName,
        label: item?.displayName,
        propertyNumber: item?.property,
      }
    })) || [{ value: '', label: 'NONE' }]

  const removeGoogleAccount = () => {
    const url = prevPageData?.url
    if (url) {
      dispatch(disconnectGoogle({ url }))
      dispatch({ type: CLEAR_GENERATE_RESEARCH_DATA })
      dispatch({ type: CLEAR_GET_ANALYTICS_DATA })
    } else {
      message.error('Please provide url to disconnect google account')
    }
  }

  useEffect(() => {
    if (tokenData && Object.keys(tokenData)?.length) {
      googleAnalyticsFormik.setFieldValue('account', tokenData?.analyticsAccount)
      googleAnalyticsFormik.setFieldValue('analyticsProperty', tokenData?.analyticsProperty)
      googleAnalyticsFormik.setFieldValue('searchConsoleProperty', tokenData?.consoleProperty)
    }
  }, [tokenData])

  const { handleSubmit, values } = googleAnalyticsFormik

  return (
    <>
      <Form className="connect-google-form" onSubmit={handleSubmit}>
        <h3 className="connect-google-form-heading">Google Account</h3>
        <Form.Group>
          <div className="connect-google-form-group">
            <Form.Label className="connect-google-form-label">Email:</Form.Label>
            <Form.Control
              type="email"
              className="connect-google-form-control"
              name="email"
              value={values?.email}
              disabled={true}
            />
          </div>
          <div
            className="connect-google-form-revoke"
            style={{ width: disconnectGoogleLoader ? '290px' : '' }}
          >
            <Button
              className="d-flex align-items-center"
              color="danger"
              variant="danger"
              type="button"
              onClick={removeGoogleAccount}
              disabled={disconnectGoogleLoader}
            >
              Revoke google account
              {disconnectGoogleLoader ? (
                <Spinner animation="border" style={{ marginLeft: '10px' }} key={1} size="sm" />
              ) : null}
            </Button>
          </div>
        </Form.Group>
        {googleAnalyticsFormik.touched.email && googleAnalyticsFormik.errors.email ? (
          <span className="fs-6 connect-google-form-error">*{googleAnalyticsFormik.errors.email}</span>
        ) : null}

        <hr />

        <h3 className="connect-google-form-heading">Google Analytics</h3>
        <Form.Group className="connect-google-form-group">
          <Form.Label className="connect-google-form-label">Account:</Form.Label>
          <Select
            className="connect-google-form-input"
            options={accountOptions}
            placeholder="Account"
            name="account"
            defaultValue={accountOptions?.find((obj) => obj?.value === tokenData?.analyticsAccount)}
            onChange={(option) => {
              googleAnalyticsFormik.setFieldValue('account', option?.value)
              googleAnalyticsFormik.setFieldValue('analyticsProperty', '')
            }}
          />
        </Form.Group>
        {googleAnalyticsFormik.touched.account && googleAnalyticsFormik.errors.account ? (
          <span className="fs-6 connect-google-form-error">*{googleAnalyticsFormik.errors.account}</span>
        ) : null}

        {googleAnalyticsFormik?.values?.account || tokenData?.analyticsAccount ? (
          <Form.Group className="connect-google-form-group">
            <Form.Label className="connect-google-form-label">Property:</Form.Label>
            <Select
              className="connect-google-form-input"
              options={propertyOptions}
              placeholder="Property"
              defaultValue={propertyOptions?.find((obj) => obj?.value === tokenData?.analyticsProperty)}
              name="analyticsProperty"
              onChange={(option) => {
                googleAnalyticsFormik.setFieldValue('analyticsProperty', option?.value)
              }}
            />
          </Form.Group>
        ) : null}

        {googleAnalyticsFormik.touched.analyticsProperty &&
        googleAnalyticsFormik.errors.analyticsProperty &&
        googleAnalyticsFormik?.values?.account &&
        !tokenData?.analyticsProperty ? (
          <span className="fs-6 connect-google-form-error">
            *{googleAnalyticsFormik.errors.analyticsProperty}
          </span>
        ) : null}

        <hr />

        <h3 className="connect-google-form-heading">Google Search Console</h3>

        <Form.Group className="connect-google-form-group">
          <Form.Label className="connect-google-form-label">Property:</Form.Label>
          <Select
            className="connect-google-form-input"
            options={searchConsoleOptions}
            placeholder=""
            defaultValue={searchConsoleOptions?.find((obj) => obj?.value === tokenData?.consoleProperty)}
            name="searchConsoleProperty"
            onChange={(option) => {
              googleAnalyticsFormik.setFieldValue('searchConsoleProperty', option?.value)
            }}
          />
        </Form.Group>
        {googleAnalyticsFormik.touched.searchConsoleProperty &&
        googleAnalyticsFormik.errors.searchConsoleProperty ? (
          <span className="fs-6 connect-google-form-error">
            *{googleAnalyticsFormik.errors.searchConsoleProperty}
          </span>
        ) : null}
      </Form>

      {storeAnalyticsDataLoader ? (
        <Row className="w-100">
          <div className="spacing-wo-border">
            <Col xs="12" style={{ marginLeft: '27px' }}>
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <div className="text-center">
                  <Loader />
                </div>
              </div>
            </Col>
          </div>
        </Row>
      ) : null}
    </>
  )
}

export default ConnectGoogleForm

import { useState } from 'react'
import { Table } from 'reactstrap'

import crossIcon from 'assets/img/icons/cross.png'
import tickIcon from 'assets/img/icons/tick-mark.png'
import warningIcon from 'assets/img/icons/warning.png'
import ModalHOC from 'pages/free-trial/tests-components/ModalHOC.js'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const AdvancedConsoleErrors = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [advancedConsoleErrorsFlag, setAdvancedConsoleErrorsFlag] = useState(false)

  const columns = [
    {
      id: 'message',
      displayName: 'Message',
    },
    {
      id: 'url',
      displayName: 'Url',
    }
  ]

  const advancedConsoleErrorCsv = data?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.length &&
    data?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.map((item) => {
      return {
        Message: item?.message,
        Url: item?.url,
      }
    })

  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.advancedConsoleErrors?.data?.testResults?.status ? (
            ''
          ) : data?.advancedConsoleErrors?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.advancedConsoleErrors?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data?.advancedConsoleErrors?.data?.testResults?.message}</p>
        {showDetail &&
        data?.advancedConsoleErrors?.data?.testResults?.status !== 1 &&
        data?.advancedConsoleErrors?.data?.consoleErrorsResults?.details?.items?.length ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setAdvancedConsoleErrorsFlag(!advancedConsoleErrorsFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={advancedConsoleErrorsFlag}
        toggleShow={() => setAdvancedConsoleErrorsFlag(!advancedConsoleErrorsFlag)}
        title={'Advanced Console Error Signal Check'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns={columns}
        datas={advancedConsoleErrorCsv}
        fileName="Advanced Console Error Signal Check"
        alternativeDownload={true}
      >
        <Table striped className="d-none d-sm-block">
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Message</th>
              <th style={{ width: '40%' }}>Url</th>
            </tr>
          </thead>
          <tbody>
            {data?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.length &&
              data?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.map((item, index) => {
                return (
                  <tr key={`AdvancedConsoleErrors-${index + 1}`}>
                    <td>{item?.message}</td>
                    <td>{item?.url}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {/*Start Advanced Console Error Signal Check Mobile view */}
        <ul className="list-group text-secondary list-group-flush d-block d-sm-none list-unstyled">
          {data?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.length
            ? data?.advancedConsoleErrors?.data?.advancedConsoleErrorsResults?.map((item) => (
                <div key={item.description}>
                  <li className="border-bottom mb-3">
                    <p className="mb-0 f-bold">Message</p>
                    <p className="mb-2">{item?.message}</p>
                  </li>
                  <li className="border-bottom mb-3">
                    <p className="mb-0 f-bold">Url</p>
                    <p className="mb-2">{item?.url}</p>
                  </li>
                </div>
              ))
            : null}{' '}
        </ul>
        {/*End Advanced Console Error Signal Check Mobile view */}
      </ModalHOC>
    </>
  )
}

export default AdvancedConsoleErrors

import React from 'react';
import SocialLogin from '../pages/social-login/index';

const SocialButton = ({ triggerLogin, containerStyle, children, ...props }) => {
  return (
    <button
      style={{
        padding: 0,
        // borderRadius: 50,
        background: 'white',
        border: 0,

        borderRadius: '50px',
        ...containerStyle,
      }}
      type="button"
      onClick={triggerLogin}
      {...props}
    >
      {children}
    </button>
  );
};

export default SocialLogin(SocialButton);

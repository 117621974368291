import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import { Table } from 'reactstrap'

const LinksALTDescriptions = ({ data }) => {
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.descriptiveLinkTest?.data?.testResults?.status ? (
            ''
          ) : data?.descriptiveLinkTest?.data?.testResults?.status === 3 ? (
            <img
              style={{
                height: 15,
                marginTop: 5,
                marginRight: 5,
                display: 'block',
              }}
              src={crossIcon}
            />
          ) : data?.descriptiveLinkTest?.data?.testResults?.status === 2 ? (
            <img
              style={{
                height: 15,
                marginTop: 5,
                marginRight: 5,
                display: 'block',
              }}
              src={warningIcon}
            />
          ) : (
            <img
              style={{
                height: 15,
                marginTop: 5,
                marginRight: 5,
                display: 'block',
              }}
              src={tickIcon}
            />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
            {data.descriptiveLinkTest?.data?.testResults ? (
              <>{data.descriptiveLinkTest?.data?.testResults?.message || ''}</>
            ) : null}
        </p>
      </div>

      {data?.descriptiveLinkTest?.data?.descriptiveLinkResults?.items?.length ? (
        <div className="vr-detail-block">
          <div className="mt-3 ">
            <Table responsive className="custom-table-report">
              <thead>
                <th style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }}>Link description</th>
                <th style={{ paddingTop: 10, paddingBottom: 10 }} className="text-center">
                  Link Text
                </th>
              </thead>
              <tbody>
                {data?.descriptiveLinkTest?.data?.descriptiveLinkResults?.items &&
                data?.descriptiveLinkTest?.data?.descriptiveLinkResults?.items?.length ? (
                  <>
                    {data?.descriptiveLinkTest?.data?.descriptiveLinkResults?.items?.map((item, index) => {
                      return (
                        <tr key={`descriptiveLinkTest-${index +1}`}>
                          <td width="80%">{item.href}</td>
                          <td width="20%" className="text-center">
                            {item.text}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default LinksALTDescriptions

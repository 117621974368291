import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const AspectRatio = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [aspectRatioFlag, setAspectRatioFlag] = useState(false)

  const columns = [
    {
      id: 'resource',
      displayName: 'Resource',
    },
    {
      id: 'actualSize',
      displayName: 'Actual Size',
    },
    {
      id: 'renderSize',
      displayName: 'Render Size',
    },
  ]

  const aspectRatioCsvData = data?.aspectRatio?.data?.data?.details?.items?.map(item => {
    return {
      Resource:item.url,
      ['Actual Size']:item.actualAspectRatio?.replace(/\n|\s{2,}/g, ''),
      ['Render Size']:item.displayedAspectRatio?.replace(/\n|\s{2,}/g, ''),
    }
  })
  
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.aspectRatio?.data?.testResults?.status ? (
            ''
          ) : data?.aspectRatio?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.aspectRatio?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data?.aspectRatio?.data?.testResults?.message}</p>
        {showDetail && data?.aspectRatio?.data?.testResults?.status !== 1 && data?.aspectRatio?.data?.testResults?.status === 3 ? (
          <>
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                if (testType === "") {
                  setAspectRatioFlag(!aspectRatioFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
            }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={aspectRatioFlag}
        toggleShow={() => setAspectRatioFlag(!aspectRatioFlag)}
        title={'Full list of images with incorrect aspect ratio'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns={columns}
        datas = {aspectRatioCsvData}
        fileName='Image Aspect Ratio Signal Test'
        alternativeDownload={true}
      >
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Resource </th>
              <th style={{ width: '40%' }}>Actual Size</th>
              <th style={{ width: '40%' }}>Render Size</th>
            </tr>
          </thead>
          <tbody>
            {data?.aspectRatio?.data?.data?.details?.items?.length &&
              data?.aspectRatio?.data?.data?.details?.items.map((item) => {
                return (
                  <tr>
                    <td>{item.url}</td>
                    <td>{item.actualAspectRatio}</td>
                    <td>{item.displayedAspectRatio}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ModalHOC>
    </>
  )
}

export default AspectRatio

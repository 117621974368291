export const issuesTitle = {
  'google-analytics': 'Google Analytics Signal Test',
  'html-validation': 'Html Validation',
  'inline-css': 'Inline CSS Signal Test',
  'page-cache': 'Page Cache Test (Server Side Caching) Signal Analysis',
  'page-object': 'Page Objects Signal Test',
  'plain-text': 'Plaintext Emails Signal Test',
  'server-signature': 'Server Signature Analysis',
  'site-speed': 'Site Speed Test',
  'social-media': 'Social Media Signal Test',
  'spf-record': 'SPF Record Analysis',
  'http2-test': 'HTTP2 Signal Test',
  'render-blocking': 'Render Blocking Signal Test',
  'morden-image': 'Morden Image Signal Test',
  'aspect-ratio': 'Image Aspect Ratio Signal Test',
  'responsive-image': 'Excessive Image Data Size Signal Test',
  'console-errors': 'Historic Console Error Signal Check',
  'advanced-console-errors': 'Advanced Console Error Signal Check',
  'ads-txt': 'Ads.txt Analysis',
  'js-execution-time': 'JS Execution Signal Test',
  'character-set': 'Charset Declaration Signal Test',
  'custom-404': 'Custom 404 Signal Test',
  'layout-shift': 'Cumulative Layout Shift Signal Test',
  'contentful-paint': 'Contentful Paint Signal Test',
  'meta-title': 'Meta Title Signal',
  'social-media-meta-tag': 'Social Media Meta Tags Signals',
  'keyword-usage-test': 'Keyword Usage Test Signals',
  'seo-friendly': 'SEO Friendly URL Signal Test',
  'sitemap-data': 'Sitemap Signal Test',
  'broken-links': 'Broken Links Test',
  'robots-txt': 'Robots.txt Signal Analysis',
  'cdn-usage': 'CDN Usage Signal Test',
  'css-analysis': 'Css Analysis Test',
  'image-cache': 'Image Cache Signal Test',
  'doc-type': 'Doctype Signal Test',
  'favicon-test': 'Favicon Checker Signal Test',
  'flash-test': 'Flash Signal Test',
  'fhtml-page-size': 'HTML Page Size Test',
  'frameset-test': 'Frameset Signal Test',
  'css-minification': 'CSS Minification Signal Test',
  backlinks: 'Backlinks',
  'mobile-screenshot': 'Mobile Screenshot Analysis',
  'no-follow': 'No Follow Signal Test',
  'dns-security': 'DNS Security',
  'disalllow-directive': 'Disallow Directive Signal Test',
  competitor: 'Competitor Domain Signals',
  'desktop-screenshot': 'Desktop Screenshot',
  'keyword-cloud': 'Keyword Cloud Signals - Visual & Count',
  'related-keywords': 'Related Keywords Test Signals',
  common: 'Most Common Keywords Signals',
  'seo-result': 'Google Search Results Preview - SERP View',
  'meta-refresh': 'Meta Refresh Signal Test',
  'canonical-url': 'Canonical URL Signal Test',
  'no-index': 'No Index Checker Signal Test',
  'microdata-schema': 'Microdata Schema Analysis',
  'media-query': 'MediaQuery Analysis',
  viewport: 'Viewport Analysis',
  'unsafe-cross-origin-links': 'Unsafe Cross-Origin Signal Analysis',
  'tls-logjam': 'TLS Logjam Signal Test',
  'brotil-test': 'Brotli Analysis Test',
  'ssl-freak': 'SSL Freak Test',
  'directory-browsing': 'Directory Browsing Analysis',
  'safe-browsing': 'Safe Browsing Signal Test',
  hsts: 'HSTS Signal Analysis',
  'url-canonicalization': 'URL Canonicalization Signal Test',
  'redirect-test': 'Redirect Signal Test',
  'nested-table': 'Nested Tables Signal Test',
  'js-minification': 'JavaScript Minification Signal Test',
  'css-cache': 'CSS Caching Signal Test',
  'js-cache': 'JavaScript Caching Signal Test',
  'html-compression': 'Web Content Compression Check',
  'dom-size': 'DOM Size Signal Test',
  'html-page': 'HTML Page Size Signal Test',
  'js-error': 'JS Error Signal Test',
  'brokenLinks-data': 'Internal Broken Links Checker Test',
  'descriptive-test': 'Descriptive Link Signal Test',
  'image-alt': 'Image Alt Test',
  'heading-analysis': 'Heading Tag Analysis Signals',
  'meta-description': 'Meta Description Signal',
  'cache-policy': 'Cache Policy Signal Test',
}

export const SEO_TESTS = {
  "adsTxt" : 'Ads.txt Analysis',
  "aspectRatio": 'Image Aspect Ratio Signal Test',
  "bootupTime": 'JS Execution Signal Test',
  "brotilTest": 'Brotil Analysis Test',
  "cachePolicy": 'Cache Policy Signal Test',
  "canonicalUrl": 'Canonical URL Signal Test',
  "cdnUsageData": 'CDN Usage Signal Test',
  "characterSet": 'Charset Declaration Signal Test',
  "consoleErrors": 'Historic Console Error Signal Check',
  'advancedConsoleErrors': 'Advanced Console Error Signal Check',
  "contentWidthTest": 'Content-width Signals',
  "contentfulPaint": 'Contentful Paint Signal Test',
  "cssAnalysis": 'CSS Analysis',
  "cssCache": ' CSS Caching Signal Test',
  "cssMinification": 'CSS Minification Signal Test',
  "cumulativeLayoutShiftTest": 'Cumulative Layout Shift Signal Test',
  "custom404": 'Custom 404 Signal Test',
  "descriptiveLinkTest": 'Descriptive Link Signal Test',
  "directoryBrowsing": 'Directory Browsing Analysis',
  "docType": 'Doctype Signal Test',
  "domSize": 'DOM Size Signal Test',
  "faviconCheckerTest": 'Favicon Checker Signal Test',
  "firstContentfulPaintTest": 'First Contentful Paint Signals',
  "firstInputDelayTest": 'First Input Delay Signals',
  "flashTest": 'Flash Signal Test',
  "fontSizeTest": 'Font Size Signals',
  "framesetTest": 'Frameset Signal Test',
  "googleAnalytics": 'Google Analytics Signal Test',
  "headingAnalysis": 'Heading Tag Analysis Signals',
  "hstsTest": 'HSTS Signal Analysis',
  "htmlCompression": 'Web Content Compression Check',
  "htmlPageSize": 'HTML Page Size Signal Test',
  "htmlValidation": 'Html Validation',
  "http2Test": 'HTTP2 Signal Test',
  "httpsTest": 'Mixed Content Test (HTTP over HTTPS)',
  "imageAlt": 'Image Alt Test',
  "imageCache": 'Image Cache Signal Test',
  "inlineCss": 'Inline CSS Signal Test',
  "interactiveTest": 'Interactive Signals',
  "jsCache": 'JavaScript Caching Signal Test',
  "jsError": 'JS Error Signal Test',
  "jsMinification": ' JavaScript Minification Signal Test',
  "largestContentfulPaintTest": 'Contentful Paint Signal Test',
  "layoutShift": 'Cumulative Layout Shift Signal Test',
  "metaRefresh": 'Meta Refresh Signal Test',
  "keywordsUsageTest": 'Keyword Usage Test Signals',
  "metaTagsTest": 'Social Media Meta Tags Signals',
  "microSchemaTest": 'Microdata Schema Analysis',
  "mordenImage": 'Morden Image Signal Test',
  "nestedTable": 'Nested Tables Signal Test',
  "noIndexChecker": 'No Index Checker Signal Test',
  "pageCache": 'Page Cache Test (Server Side Caching) Signal Analysis',
  "pageObject": 'Page Objects Signal Test',
  "plainTextEmail": 'Plaintext Emails Signal Test',
  "redirectTest": 'Redirect Signal Test',
  "renderBlocking": 'Render Blocking Signal Test',
  "responsiveImage": 'Excessive Image Data Size Signal Test',
  "robotsTxtTest": 'Robots.txt Signal Analysis',
  "safeBrowsing": 'Safe Browsing Signal Test',
  "seoFriendlyUrlAnalysis": 'SEO Friendly URL Signal Test',
  "serverSignature": 'Server Signature Analysis',
  "siteMapData": ' Sitemap Signal Test',
  "siteSpeed": 'Site Loading Speed Signal Test',
  "socialMediaTwitterData": 'Social Media Signal Test',
  "speedIndexTest": 'Speed Index Signals',
  "spfRecord": 'SPF Record Analysis',
  "sslFreakTest": 'SSL and HTTPS Signals',
  "tapTargetTest": 'Tap Target Signals',
  "tlsLogjamTest": 'TLS Logjam Signal Test',
  "unsafeCrossOriginLinksTest": 'Unsafe Cross-Origin Signal Analysis',
  "urlCanonicalization": 'URL Canonicalization Signal Test',
  "viewport": 'Viewport Analysis'
}

export const LimitOption = {
  WEBSITE : 0,
  COMPETITOR : 1,
  KEYWORD : 2,
  QUICKSCAN: 3
}

export const NotificationType = {
  BACKLINKS : 0,
  TOP_KEYWORDS : 1,
  KEYWORD_POSITION : 2,
  SUBSCRIPTION: 3
}

export const PLAN = {
  BASIC_PLAN: {
    name: "PositionMySite SEO Advanced",
    monthly_price: 28.95,
    yearly_price: {
      perMonth: 24.95,
      perYear: 299.40,
      saved: "14%"
    }
  },
  PRO_PLAN: {
    name: "PositionMySite SEO Pro",
    monthly_price: 49.95,
    yearly_price: {
      perMonth: 42.95,
      perYear: 515.40,
      saved: "14%"
    }
  },
  PREMIUM_PLAN: {
    name: "PositionMySite SEO Enterprise",
    monthly_price: 89.95,
    yearly_price: {
      perMonth: 80.95,
      perYear: 971.40,
      saved: "12%"
    }
  }
}

export const TEST_TYPES = {
  "ads-txt": "AdsTxt",
  "aspect-ratio": "AspectRatio",
  "advanced-console-errors": "AdvancedConsoleErrors",
  "bootup-time": "BootupTime",
  "brotli-test": "BrotilTest",
  "cache-policy": "CachePolicy",
  "canonical-url": "CanonicalUrl",
  "cdn-usage-data": "CdnUsageData",
  "character-set": "CharacterSet",
  "console-errors": "ConsoleErrors",
  "content-width": "ContentWidth",
  "contentful-paint": "ContentfulPaint",
  "competitor-domain":"CompetitorDomain",
  "css-analysis": "CssAnalysis",
  "css-cache": "CssCache",
  "css-minification": "CssMinification",
  "cumulative-layout-shift": "CumulativeLayoutShift",
  "custom-404": "Custom404",
  "descriptive-link": "DescriptiveLink",
  'disallow-directive': 'DisalllowDirective', 
  "meta-description": 'Description',
  "directory-browsing": "DirectoryBrowsing",
  "desktop-screenshot": "DesktopScreenshot",
  "doc-type": "DocType",
  "dom-size": "DomSize",
  'dns-security': 'DNSSecurity',
  "favicon-checker-test": "FaviconCheckerTest",
  "first-contentful-paint": "FirstContentfulPaint",
  "first-input-delay": "FirstInputDelay",
  "flash-test": "FlashTest",
  "font-size": "FontSize",
  "frameset-test": "FramesetTest",
  "google-analytics": "GoogleAnalytics",
  "heading-analysis": "HeadingAnalysis",
  "hsts-test": "HstsTest",
  "html-compression": "HtmlCompression",
  "html-page-size": "HtmlPageSize",
  "html-validation": "HtmlValidation",
  "http2-test": "Http2Test",
  "https-test": "HttpsTest",
  "image-alt": "ImageAlt",
  "image-cache": "ImageCache",
  "inline-css": "InlineCss",
  "interactive": "Interactive",
  "js-cache": "JsCache",
  "js-error": "JsError",
  "js-minification": "JsMinification",
  "largest-contentful-paint": "LargestContentfulPaint",
  "layout-shift": "LayoutShift",
  "meta-refresh": "MetaRefresh",
  "keywords-usage-test": "KeywordsUsageTest",
  "keyword-cloud": "KeywordCloud",
  "morden-image": "MordenImage",
  "mobile-screenshot": "MobileScreenshot",
  "microdata-schema-analysis": "MicrodataSchemaAnalysis",
  "meta-tags": "MetaTags",
  "nested-table": "NestedTable",
  "no-index-checker": "NoIndexChecker",
  "no-follow": "NoFollow",
  "page-cache": "PageCache",
  "page-object": "PageObject",
  "plain-text-email": "PlainTextEmail",
  "page-speed":"PageSpeed",
  "redirect-test": "RedirectTest",
  "render-blocking": "RenderBlocking",
  "responsive-image": "ResponsiveImage",
  "robots-txt": "Robots.txt",
  "related-keywords": "RelatedKeywords",
  "safe-browsing": "SafeBrowsing",
  "seo-friendly-url-analysis": "SeoFriendlyUrlAnalysis",
  "server-signature": "ServerSignature",
  "sitemap-data": "SiteMapData",
  "site-speed": "SiteSpeed",
  "social-media-twitter-data": "SocialMediaTwitterData",
  "speed-index-test": "SpeedIndexTest",
  "spf-record": "SpfRecord",
  "ssl-freak-test": "SslFreakTest",
  "tap-target": "TapTarget",
  "tls-log-jam-test": "TlsLogjamTest",
  "meta-title": "Title",
  "unsafe-cross-origin-links-test": "UnsafeCrossOriginLinksTest",
  "url-canonicalization": "UrlCanonicalization",
  "viewport": "Viewport"
}
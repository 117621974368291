import Chart from 'react-apexcharts'

const NewTopKeywordsTrendsChart = ({ chartData }) => {
  const categories = []
  const seriesData = []

  if (chartData && chartData?.length) {
    chartData.forEach((item) => {
      item['monthName'] = item.month
      seriesData.push(item?.value)
      categories.push(`${item?.monthName}, ${item?.year}`)
    })
  }

  const data = [
    {
      name: 'Trends',
      data: seriesData,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
    },
    tooltip: {
      x: {
        format: 'MMM yyyy',
      },
    },
  }

  return (
    <div className="chart w-100">
      <Chart options={options} series={data} type="area" height="350" />
    </div>
  )
}

export default NewTopKeywordsTrendsChart

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const ContentWidthTest = () => {
  return (
    <CommonFreeTool heading={freeTools.contentWidthTest.heading} whatIsIT={freeTools.contentWidthTest.what} />
  )
}

export default ContentWidthTest

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, TabContent, Tab } from 'react-bootstrap'

import {
  getUserData,
  patchUserDomainsDashboard,
  changeActiveLink,
} from '../../../redux/actions/dashboard/index'

import {
  storeUserData,
  verifyTokenStart,
} from '../../../redux/actions/register'

import SiteOutageMap from './MapComponent'
import MainUrlNav from 'components/MainUrlNav'

const SettingsComponentOutage = () => {

  const { usersDomains: usersDomain, activeLink } = useSelector(({dashboard}) => dashboard)
  const userId = useSelector(({ register }) => register?.registerData?._id)
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
   
    async function fetchAPI(){
      const data = await localStorage.getItem('userData')
      await verifyTokenStart()
      if (data && JSON.parse(data)._id) {
        dispatch(storeUserData(JSON.parse(data)))
        dispatch(patchUserDomainsDashboard())
        dispatch(getUserData())
      } else {
        navigate('/')
      }
    }

    fetchAPI()
  }, []) 


    const activeDomain =
      usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink].items &&
      usersDomain[activeLink].items.length &&
      usersDomain[activeLink].items[0].id
    const activeDomainUrl =
      usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink].items &&
      usersDomain[activeLink].items.length &&
      usersDomain[activeLink].items[0].url

    return (
      <Container fluid className="p-0">
        <Row>
          <Col className="dashboard-main-tab">
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0} activeKey={activeLink || 0}>
              <Nav
                variant="tabs"
                onSelect={(selectedKey) => {
                  dispatch(changeActiveLink(parseInt(selectedKey)))
                }}
              >
                {usersDomain && usersDomain?.length ? (
                  <>
                    {usersDomain?.map((item, index) => {
                      return <MainUrlNav item={item} index={index} key={index+1} />
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Nav>
              <TabContent activeTab={activeLink}>
                <SiteOutageMap
                  user={userId}
                  activeLink={activeDomain}
                  activeDomainUrl={activeDomainUrl}
                />
              </TabContent>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    )
  }

export default SettingsComponentOutage

import { useFormik } from 'formik'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { generateTicket } from 'redux/actions/dashboard'
import Select from 'react-select'
import { object, string } from 'yup'

export const TestSupportForm = ({ handleClick, testSupportMessage }) => {
  const dispatch = useDispatch()
  const { generateTicketLoader } = useSelector(({ dashboard }) => dashboard)

  const defaultSubject = `${testSupportMessage?.hostName?.toUpperCase()} - ${testSupportMessage?.testName?.toUpperCase()}`

  const assistanceOptions = [
    { value: 'detailed-guidance', label: 'Please provide detailed instructions on fixing this issue.' },
    {
      value: 'professional-consultation',
      label: 'I need a professional consultation to address this problem.',
    },
    { value: 'service-package-inquiry', label: 'I am interested in a service package to optimize my site.' },
    { value: 'technical-assistance', label: 'I need technical assistance to implement the solution.' },
    { value: 'audit-review', label: 'Please perform a detailed audit and review of my website.' },
    { value: 'seo-strategy-session', label: 'I would like to schedule an SEO strategy session.' },
    { value: 'custom-solutions', label: 'I need a custom solution for this issue.' },
    { value: 'ongoing-support-plan', label: 'I am interested in ongoing support and maintenance.' },
  ]

  const initialValues = {
    subject: defaultSubject,
    message: '',
    assistance: '',
  }

  const testSupportValidationSchema = object().shape({
    assistance: string().required('Please select an assistance option'),
  })

  const generateTicketHandler = (values) => {
    if (values?.message !== undefined) {
      const payload = {
        subject: values.subject,
        assistanceNeededFor: values.assistance,
        clientMessage: values?.message,
        ...testSupportMessage,
      }

      dispatch(generateTicket(payload))
    }
  }

  const testSupportFormik = useFormik({
    initialValues,
    validationSchema: testSupportValidationSchema,
    onSubmit: generateTicketHandler,
  })

  const { errors, touched, values, handleSubmit, handleChange, handleBlur, setFieldValue } = testSupportFormik
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Subject:</Form.Label>
          <Form.Control type="text" name="subject" value={values?.subject} disabled />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Select Assistance Needed:</Form.Label>
          <Select
            options={assistanceOptions}
            onChange={(option) => {
              setFieldValue('assistance', option?.label)
            }}
            placeholder=""
            name="assistance"
          />
          {!!touched.assistance && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.assistance}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="my-3">
          <Form.Label>Message:</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={values.message}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Form.Group>

        <div className="d-flex justify-content-between">
          <Button
            className="d-flex gap-2 align-items-center"
            disabled={generateTicketLoader}
            type="submit"
            onClick={handleSubmit}
          >
            Submit request
            {generateTicketLoader ? <Spinner animation="border" key={1} size="sm" /> : null}
          </Button>

          <Button variant="outline-danger" onClick={handleClick}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )
}

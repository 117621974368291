import paths from 'constants/paths'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const Backlinks = ({ data, showDetail, totalBacklinks, referingDomains, recentBacklinks }) => {
  const navigate = useNavigate()

  return (
    <>
     {showDetail ? <div className="vr-url-block">
        <div>
          <div className="vr-report-status-icon" />
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>: null}
      {showDetail ? (
        <>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              {' '}
              <p>
                {' '}
                This domain has {totalBacklinks} total backlinks from {referingDomains} referring domains.
                Some recently found backlinks for this domain are listed below:
              </p>{' '}
            </div>
            {recentBacklinks?.length > 0
              ? recentBacklinks?.map((item, index) => {
                  return (
                    <div style={{ display: 'flex', marginBottom: 3 }} key={`Backlinks-${index+1}`}>
                      <div className="check-back">{item?.firstSeen}</div>
                      <div>
                        <span className="wordBreak">
                          <a
                            href={item?.url}
                            target="_blank"
                          >
                            {item?.url?.length > 70 ? <OverlayTrigger
                              key={`displayData-${index + 1}`}
                              placement={'top'}
                              overlay={<Tooltip id={`tooltip-top`}>{item?.url}</Tooltip>}
                            >
                              <span>{item?.url?.substring(0, 70).concat('...')}</span>
                            </OverlayTrigger>: item?.url}
                          </a>
                        </span>
                      </div>
                    </div>
                  )
                })
              : ''}
            <div style={{ marginBottom: 12, marginTop: 6 }}>
              {' '}
              <Button variant="outline-primary" onClick={() => navigate(paths.backlinksSignal)} size="lg">
                View Backlinks
              </Button>{' '}
            </div>
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  )
}

export default Backlinks

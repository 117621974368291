import { useState, useLayoutEffect } from 'react'
const breakpoint = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  tabletL: 950,
  laptop: 1024,
  desktop: 1220,
}

export const useDeviceCheck = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setTablet] = useState(false)
  const [isDesktop, setDesktop] = useState(false)

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < breakpoint.tablet)
      setTablet(window.innerWidth < breakpoint.laptop)
      setDesktop(window.innerWidth >= breakpoint.desktop)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { isMobile, isTablet, isDesktop }
}

import React from 'react'

const Code = ({ children }) => {
  return (
   <div>
      {children}
    </div>
  )
}

export default Code

import { Card, Tabs } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import { Templates } from 'pages/reporting/white-label/templates'
import { Report } from 'pages/reporting/white-label/reports'

import './style.scss'

export const WhiteLabelTabs = () => {
  const { TabPane } = Tabs
  const location = useLocation()
  const activeTab = (location && location.state && location.state.templateActiveTab) || 'reports'
  const elements = [
    {
      tabTitle: 'Generate report',
      tabBody: <Report />,
      path: 'white-label/reports',
    },
    {
      tabTitle: 'Template settings',
      tabBody: <Templates />,
      path: 'white-label/templates',
    },
  ]
  const history = useNavigate()

  return (
    <Card className="white-label-report-tabs">
      <Tabs
        defaultActiveKey={activeTab}
        onChange={(path) => {
          history(`../${path}`)
        }}
        tabBarGutter={60}
        tabBarStyle={{ backgroundColor: 'white' }}
        className="tabs"
      >
        {elements.map((element) => (
          <TabPane tab={element.tabTitle} className="TabsBody" key={element.path}>
            {/* {element.path === activeTab ? element.tabBody : <></>} */}
            {element.tabBody}
          </TabPane>
        ))}
      </Tabs>
    </Card>
  )
}

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const CachePolicyTest = () => {
  return (
    <CommonFreeTool heading={freeTools.cachePolicyTest.heading} whatIsIT={freeTools.cachePolicyTest.what} />
  )
}

export default CachePolicyTest

import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'
import { Input } from 'reactstrap'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import * as Yup from 'yup'

import { Loader } from 'components/uiCore'

import paths from 'constants/paths'

import {
  getLighthouseStatus,
  ifwebSiteData,
  lightHouseSuccessClear,
  openModalLighthouse,
  setSitemapDataToSent,
  setStartScan,
} from 'redux/actions/freetrial/startFreeTrial'

import './addSitemap.scss'
import { supportRequest } from 'redux/actions/register'
import { useDeviceCheck } from 'hooks/deviceHook'

const SiteMapForm = ({ fromTrial = false }) => {
  const { sitemaps, isScanStarted, isModalOpenLighthouse, sitemapLoader, isWebsiteData, lightHouseStatusData, linkArray: urlDetails, prevPageData } =
  useSelector(({ startFreeTrial }) => startFreeTrial)

  const { toastMessage, toastError, supportRequestLoader } = useSelector(({register}) => register)
  
  const dispatch = useDispatch()
  const lighthouseInterval = useRef(null)

  const formik = useFormik({
    initialValues: {
      sitemap: [''],
    },
  })
  const [websiteDataExist, setWebsiteDataExist] = useState(false)

  const [isLighthouseRateLimitExceed, setIsLighthouseRateLimitExceed] = useState(false)
  const [showContactUs, setShowContactUs] = useState(false)
  const [userData, setUserData] = useState([])
   
  const { values } = formik

  const mainURL = urlDetails.filter((item) => item.checked === true)
  const url = mainURL?.[0]?.effective_url || prevPageData?.url 
  
  useEffect(() => {
    if (isWebsiteData) {
      setWebsiteDataExist(true)
    }
  }, [isWebsiteData])

  useEffect(() => {
    const finalMergeData = sitemaps.concat(values.sitemap.filter((item) => item !== ''))
    dispatch(setSitemapDataToSent(finalMergeData))
  }, [values])
  
  useEffect(()=>{
        
    if(isScanStarted && 
      Object?.keys(lightHouseStatusData)?.length !== 0 &&
      lightHouseStatusData?.statusCode !== 200 && 
      lightHouseStatusData?.statusCode !== 429 && 
      lightHouseStatusData?.statusCode !== 202 ){

      (Object.keys(lightHouseStatusData)?.length !== 0 && lightHouseStatusData?.statusCode !== 200 && lightHouseStatusData?.statusCode !== 429) && dispatch(openModalLighthouse(true))
    }

    if(isScanStarted && 
      Object.keys(lightHouseStatusData)?.length !== 0 &&
      lightHouseStatusData?.statusCode === 429){
        setIsLighthouseRateLimitExceed(true)
    }

  },[lightHouseStatusData, isScanStarted])

  useEffect(() => {

    if(Object.keys(lightHouseStatusData)?.length !== 0){

      if(lightHouseStatusData?.statusCode === 500 || 
        lightHouseStatusData?.statusCode === 200
        ){
          clearInterval(lighthouseInterval.current)
        }
      if(lightHouseStatusData?.statusCode === 202){
        return
      }

    }

    if(isScanStarted){
      if(lighthouseInterval?.current){
        return
      }else{
        dispatch(getLighthouseStatus({url}))
        lighthouseInterval.current = setInterval(() => {
          dispatch(getLighthouseStatus({url}))
        }, 10000)
      }  
    }

  }, [lightHouseStatusData, lightHouseStatusData?.statusCode, isScanStarted])

  useEffect(() => {
    return () => {
      clearInterval(lighthouseInterval.current)
    }
  }, [])

  useEffect(() => {
    if(!supportRequestLoader && (toastError || toastMessage)){
      dispatch(openModalLighthouse(false))
      dispatch(setStartScan(false))
      dispatch(ifwebSiteData(false))
      dispatch(lightHouseSuccessClear())
      dispatch({ type: 'CLEAR_URL_DETAILS' })
      
      setTimeout(() =>{
        setShowContactUs(false)
        navigate(fromTrial ? paths.newUserWelcomePage : paths.mainDashboard)
      }, 500)

    }
  }, [supportRequestLoader])

  const navigate = useNavigate()
  const { isMobile } = useDeviceCheck() 


  const fetchLocalStorageData = async() => {
    const userData = await localStorage.getItem("userData")
    setUserData(JSON.parse(userData))
  }

  const handleChange = (e, index) => {
    const value = e.target.value
    if (!value.includes(' ')) {
      formik.setFieldValue(`sitemap[${index}]`, value)
    }
  }
  useEffect(() => {
    ;() => dispatch(openModalLighthouse(false))
  }, [])

  const supportRequestSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^\d+$/, 'Phone number must contain only digits')
      .required('Phone number is required'),
    websiteUrl: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([/?].*)?$/,
      'Invalid URL format')
      .required("Please provide a URL")
});
  

  const phone = JSON.parse(localStorage.getItem("userData"))?.phoneNumber

  const initialValues = {
    phoneNumber: phone || userData?.phoneNumber,
    websiteUrl: url
  }
 
  const supportRequestFormik = useFormik({
    initialValues,
    validationSchema: supportRequestSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        email: userData?.email, 
        name: `${userData?.firstName} ${userData?.lastName}`, 
        url: values?.websiteUrl, 
        phoneNo: values?.phoneNumber
      }
    
      dispatch(supportRequest(payload))
    }
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div classNname="sitemap-container">
          <div
            className={`${isScanStarted ? 'pe-none sitemap-data' : ''} d-flex flex-column`}
            style={{ 
                    height: isScanStarted && !isMobile ? !fromTrial ? '28.625rem' : "22rem" : "auto", 
                    overflowY: isScanStarted ? "scroll" : 'auto' 
                  }}
          >
            {sitemaps?.length ? (
              <>
                <h5 className='f-bold'>
                  Great news! We've located sitemaps for {url} from the robots.txt file. This helps us
                  accurately analyze SEO signals for your site. The sitemap we are using is as follows:
                </h5>
                <ul>
                  {sitemaps?.length
                    ? sitemaps.map((item, index) => {
                        return (
                          <li key={`sitemap-${index + 1}`} className="mb-2 square">
                            {item}
                          </li>
                        )
                      })
                    : null}
                </ul>
                <ul>
                  <h4 className='f-bold'>Options:</h4>

                  <li>
                    <p>
                      Use Identified Sitemap: To Continue with the current sitemap for comprehensive analysis.
                      Please Press Start Scan
                    </p>
                  </li>

                  <li>
                    <p>
                      Add Additional Sitemaps: If {url} has more than one sitemap, you can add them
                      manually for a more detailed analysis.
                    </p>

                    <FieldArray
                      name="sitemap"
                      render={(arrayHelpers) => (
                        <div>
                          {formik.values.sitemap.map((_, index) => (
                            <div key={`sitemapValue-${index + 1}`} className="d-flex mb-2">
                              <Input
                                style={{ marginRight: 10 }}
                                name={`sitemap[${index}]`}
                                value={formik.values.sitemap[index]}
                                onChange={(e) => handleChange(e, index)}
                                className='trail-container-card-tranparency trail-container-input'
                              />

                              {formik.values.sitemap.length > 1 && (
                                <Button
                                  color="primary dashboard-checkup-url-btn"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </Button>
                              )}
                              {formik.values.sitemap.length - 1 === index && (
                                <Button
                                  style={{ marginLeft: 7 }}
                                  className="ml-2"
                                  color="primary dashboard-checkup-url-btn"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </Button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </li>
                </ul>
              </>
            ) : sitemapLoader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  alignSelf: 'center',
                  flexDirection: 'row',
                  minHeight: 380,
                }}
              >
                <div className="text-center">
                  <Loader />
                </div>
              </div>
            ) : null}

            {sitemaps?.length === 0 && !sitemapLoader ? (
              <>
                <h4 className='f-bold'>
                  We were unable to find a sitemap location for {url} in robots.txt file. A sitemap helps
                  search engines understand the structure of {url} and crawl it more effectively.
                </h4>

                <ul>
                  <h4 className='f-bold'>Options:</h4>

                  <li>
                    <p>
                      Enter Sitemap URL Manually: If you know the location of your sitemap, you can enter it
                      below to proceed with the sitemap test.
                    </p>

                    <FieldArray
                      name="sitemap"
                      render={(arrayHelpers) => (
                        <div>
                          {formik.values.sitemap.map((_, index) => (
                            <div key={`sitemapValue-${index + 1}`} className="d-flex mb-2">
                              <Input
                                style={{ marginRight: 10 }}
                                name={`sitemap[${index}]`}
                                value={formik.values.sitemap[index]}
                                type="text"
                                pattern="^[^\s]+$"
                                onChange={formik.handleChange}
                              />

                              {formik.values.sitemap.length > 1 && (
                                <Button
                                  color="primary dashboard-checkup-url-btn"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </Button>
                              )}
                              {formik.values.sitemap.length - 1 === index && (
                                <Button
                                  style={{ marginLeft: 7 }}
                                  className="ml-2"
                                  color="primary dashboard-checkup-url-btn"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </Button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </li>

                  <li>
                    Skip Sitemap Test: If you choose <strong>Start Scan</strong> to skip, {url} will not
                    pass this specific SEO Site Signal test, which could impact your overall SEO score.
                  </li>
                </ul>
              </>
            ) : null}
          </div>
          {isScanStarted ? (
            <div className={`${isModalOpenLighthouse ? "sitemap-modal" : 'sitemap-loader'} ${fromTrial ? 'trial-flow' : ''}`}>
              {isModalOpenLighthouse ? (
                <Modal
                  show={isModalOpenLighthouse}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => {}}
                  style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)'}}
                  className="br-15 pe-0"
                >
                  <Modal.Body>
                    "Oops! We couldn't initiate the analysis because the server returned a 'Bad Request' error. This usually happens if the URL is incorrect, inaccessible, if there's a formatting issue with the request or lighthouse server is being blocked by your firewall. Please verify the URL is correct, including the http:// or https:// prefix, and try again. If the problem persists, please contact our support team for assistance."
                  </Modal.Body>
                  <Modal.Footer className='d-flex flex-row justify-content-between'>
                    <Button variant="outline-primary" onClick={() => {
                      fetchLocalStorageData()
                      setShowContactUs(true)
                    }}>
                      Contact us
                    </Button>

                    <Button
                      color="primary"
                      variant="primary"
                      onClick={() => {
                        navigate(fromTrial ? paths.newUserWelcomePage : paths.mainDashboard)
                        dispatch(openModalLighthouse(false))
                        dispatch(setStartScan(false))
                        dispatch(ifwebSiteData(false))
                        dispatch(lightHouseSuccessClear())
                        dispatch({ type: 'CLEAR_URL_DETAILS' })
                      }}
                    >
                      Start Over
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                  <div>
                    <Loader />
                  </div>
              )}
              <br /><br />
              {isLighthouseRateLimitExceed ? (
                <div style={{ width: "95%" }}>
                  <Alert
                    variant="warning"
                    className="alert-outline-coloured"
                    key="meta-title"
                  >
                    <div className="alert-icon" />
                    <div className="alert-message">
                      <span className="alert-for-mobile">
                        We've detected that your server or firewall is configured with stringent rate limiting settings. To ensure successful processing, we're momentarily pausing to respect these limits. Please allow approximately 61 seconds for us to safely reinitiate the request for {url} information. We appreciate your patience as we work to continue the scan in compliance with your server's policies. please stand by this message will update or disappear shortly.
                      </span>
                    </div>
                  </Alert>
                </div>
              ) : null}
            </div>
          ) : null}
          {websiteDataExist ? (
            <div className={`sitemap-loader ${fromTrial ? 'trial-flow' : ''}`}>
              <Modal
                show={websiteDataExist}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {}}
                style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
                className="br-15"
              >
                <Modal.Body>
                  We regret to inform you that we are currently unable to proceed due to an issue with the
                  provided data. We apologize for any inconvenience this may have caused. Please try again
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate(fromTrial ? paths.newUserWelcomePage : paths.mainDashboard)
                      dispatch(openModalLighthouse(false))
                      dispatch(setStartScan(false))
                      dispatch(ifwebSiteData(false))
                      dispatch(lightHouseSuccessClear())
                      dispatch({ type: 'CLEAR_URL_DETAILS' })
                    }}
                  >
                    Start Over
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : null}
          {showContactUs ? (
            <Modal
              show={showContactUs}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {setShowContactUs(false)}}
              style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
              className="br-15"
            >
              <Modal.Header className='pb-0'>
                <h3>Support Requests - PMS SEO Site Signals</h3>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={supportRequestFormik.handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="userEmail">Email Address:</Form.Label>
                    <Form.Control
                      type='email'
                      id="userEmail"
                      name="userEmail"
                      placeholder="Enter your email"
                      disabled
                      onChange={supportRequestFormik.handleChange}
                      onBlur={supportRequestFormik.handleBlur}
                      value={userData?.email}
                    />
                    {supportRequestFormik.touched.userEmail && supportRequestFormik.errors.userEmail ? (
                      <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.userEmail}</span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="userName">Name:</Form.Label>
                    <Form.Control
                      type='text'
                      id="userName"
                      name="userName"
                      disabled
                      placeholder="Enter your name"
                      onChange={supportRequestFormik.handleChange}
                      onBlur={supportRequestFormik.handleBlur}
                      value={`${userData?.firstName} ${userData?.lastName}`}
                    />
                    {supportRequestFormik.touched.userName && supportRequestFormik.errors.userName ? (
                      <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.userName}</span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="websiteUrl">Website url:</Form.Label>
                    <Form.Control
                      type='text'
                      id="websiteUrl"
                      name="websiteUrl"
                      placeholder="Enter website url"
                      onChange={supportRequestFormik.handleChange}
                      onBlur={supportRequestFormik.handleBlur}
                      value={supportRequestFormik.values.websiteUrl}
                    />
                    {supportRequestFormik.touched.websiteUrl && supportRequestFormik.errors.websiteUrl ? (
                      <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.websiteUrl}</span>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="phoneNumber">Phone No:</Form.Label>
                    <Form.Control
                      type='text'
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter your number"
                      onChange={supportRequestFormik.handleChange}
                      onBlur={supportRequestFormik.handleBlur}
                      value={supportRequestFormik.values.phoneNumber}
                    />
                    {supportRequestFormik.touched.phoneNumber && supportRequestFormik.errors.phoneNumber ? (
                      <span className="fs-6" style={{ color: 'red' }}>{supportRequestFormik.errors.phoneNumber}</span>
                    ) : null}
                  </Form.Group>

                  <div className='d-flex flex-row justify-content-between'>
                    <Button variant="primary" onClick={supportRequestFormik?.handleSubmit}>
                      {
                        supportRequestLoader ?
                          <>
                            <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                              Send
                              <Spinner animation="border" style={{ marginBottom: '2px' }} key={1} size="sm" />
                            </div>
                          </> :
                          <>Send</>
                      }
                    </Button>
                    <Button variant="danger" onClick={() => setShowContactUs(false)}>Cancel</Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          ) :
            null
          }
        </div>
      </form>
    </FormikProvider>
  )
}

export default SiteMapForm

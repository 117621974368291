import React from 'react'
import { Alert } from 'react-bootstrap'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const MetaDescriptionTest = ({ data }) => {
  return (
    <>
      {data?.metaData?.data?.response?.description ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.metaData?.data?.response?.description?.descriptionStatus ? (
                ''
              ) : data?.metaData?.data?.response?.description?.descriptionStatus === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.metaData?.data?.response?.description?.descriptionStatus === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p> {data?.metaData?.data?.response?.description?.descriptionMessage} </p>
            {/* <p>{data?.metaData?.data?.response?.description?.description ? <p>{data?.metaData?.data?.response?.description?.description}</p> : <></>}</p> */}

            <Alert
              // variant={data?.metaData?.data?.response?.description?.descriptionStatus === 1 ?'success' : 'danger'}
              variant={
                !data?.metaData?.data?.response?.description?.descriptionStatus
                  ? ''
                  : data?.metaData?.data?.response?.description?.descriptionStatus === 3
                  ? 'danger'
                  : data?.metaData?.data?.response?.description?.descriptionStatus === 2
                  ? 'warning'
                  : 'success'
              }
              className="alert-outline-coloured"
              key={'meta-description'}
            >
              <div className="alert-icon">{/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}</div>
              <div className="alert-message">
              <span style={{fontWeight:400, color:'#000000'}}>Text:</span>{' '}
                {data?.metaData?.data?.response?.description?.description ? (
                  <span>{data?.metaData?.data?.response?.description?.description}</span>
                ) : (
                  <>No Parameters Found</>
                )}
                <div>
                <span style={{fontWeight:400, color:'#000000'}}>Length:</span>{' '}
                  {data?.metaData?.data?.response?.description?.description ? (
                    <span>{data?.metaData?.data?.response?.description?.description.length}</span>
                  ) : (
                    <>No Parameters Found</>
                  )}
                </div>
              </div>
            </Alert>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MetaDescriptionTest

import CommonFreeTool from '../CommonFreeTool'
import { freeTools } from 'constants.js'

const CompetitorDomain = () => {
  return (
    <CommonFreeTool
      heading={freeTools.competitorDomainsTest.heading}
      whatIsIT={freeTools.competitorDomainsTest.what}
    />
  )
}

export default CompetitorDomain

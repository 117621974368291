import { Button, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { Divider, Progress, Space, message } from 'antd'

import SocialButton from 'components/fbButton.js'
import { useDeviceCheck } from 'hooks/deviceHook'
import Registration from 'modules/Free tools/components/RegisterForm.jsx'

import 'modules/Free tools/components/FreeAccount.scss'
import google from 'assets/img/icons/google.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Header from 'components/Header/Header'

const FreeAccount = () => {
  const dispatch = useDispatch()
  const { isMobile } = useDeviceCheck()

  const getColor = (score) => {
    let webScore = typeof score === 'string' ? parseInt(score) : score
    if (webScore <= 65) {
      return ['#d95350', 'transparent']
    }
    if (webScore >= 66 && webScore <= 75) {
      return ['#e5a54b', 'transparent']
    }
    if (webScore >= 76 && webScore <= 84) {
      return ['#e5a54b', 'transparent']
    }
    if (webScore >= 85 && webScore <= 100) {
      return ['#52c41a', 'transparent']
    }
  }

  return (
    <>
      <Header isDemoReportPage customClassName="layout-container trial-header" />
      <div className={`free-account-wrapper gap-6 ${isMobile ? 'p-4' : ''}`}>
        <div className="d-flex flex-column gap-4">
          <h1 className="free-account-heading">
            Create a free account in order to check more than one website per day
          </h1>
          <div className="free-account-url-summary">
            <div className="free-account-url-summary-sec1 d-flex gap-3">
              <span
                style={{
                  background: '#3F80EA',
                  height: 'fit-content',
                  padding: '5px 10px',
                  borderRadius: '0.25rem',
                }}
              >
                <FontAwesomeIcon icon={faChartSimple} color="white" style={{ fontSize: '2.5rem' }} />
              </span>
              <span>
                <p className="fs-4 m-0">Last analysis summary for</p>
                <p className="fs-3 m-0">
                  <Link onClick={() => console.log('clicked')}>https://www.example.com/</Link>
                </p>
              </span>
            </div>
            <hr className="mt-0" />
            <div>
              <Col xs="12 mb-4" lg="12" className="dashboard-progress-score">
                <div className="w-100">
                  <Space className="d-flex justify-content-evenly gap-4" wrap>
                    <div className="progress-dash">
                      <div>
                        <Progress
                          strokeLinecap={'square'}
                          strokeWidth={18}
                          type="circle"
                          strokeColor={getColor(72)}
                          format={(percent) => `${percent}`}
                          percent={58}
                          size={[500, 500]}
                        />
                      </div>
                      <p className="text-progress-helper my-1"> SEO </p>
                    </div>

                    <div className="progress-dash">
                      <div>
                        <Progress
                          strokeLinecap={'square'}
                          strokeWidth={18}
                          strokeColor={['#52c41a', 'transparent']}
                          type="circle"
                          format={(percent) => `${percent}`}
                          percent={70}
                          size={[500, 500]}
                        />
                      </div>
                      <p className="text-progress-helper my-1"> 55 Passed </p>
                    </div>

                    <div className="progress-dash">
                      <div>
                        <Progress
                          strokeLinecap={'square'}
                          strokeWidth={18}
                          strokeColor={['#d95350', 'transparent']}
                          type="circle"
                          format={(percent) => `${percent}`}
                          percent={20}
                          size={[500, 500]}
                        />
                      </div>
                      <p className="text-progress-helper my-1"> 10 Failed </p>
                    </div>

                    <div className="progress-dash">
                      <div>
                        <Progress
                          strokeLinecap={'square'}
                          strokeWidth={18}
                          strokeColor={['#e5a54b', 'transparent']}
                          type="circle"
                          format={(percent) => `${percent}`}
                          percent={10}
                          size={[500, 500]}
                        />
                      </div>
                      <p className="text-progress-helper my-1"> 9 Warnings </p>
                    </div>
                  </Space>
                </div>
              </Col>
            </div>
          </div>
          <p className="fs-4 fw-bold m-0">
            Register for free and check how your website is doing now and unlock some features for better
            insights to your website
          </p>
        </div>

        <div className="free-account-social">
          <Registration />
          <SocialButton
            containerStyle={isMobile ? { width: '100%' } : {}}
            provider="google"
            appId="74542118637-c261k854oj1m08ov29tcvrsvkcbrnhpm.apps.googleusercontent.com"
            onLoginSuccess={(data) => {
              if (data._profile.email && data._token.accessToken) {
                let apiData = {
                  token: data._token.accessToken,
                  social: true,
                  type: 'google',
                }

                dispatch({ type: 'REGISTER_START', payload: apiData })
              }
            }}
            onLoginFailure={(data) => {
              const error = data?.toString()
              if (error?.includes('popup_closed_by_user')) {
                return
              } else {
                message.error(data)
              }
            }}
          >
            <Button type="button" variant="outline-primary" className="free-account-social-login">
              <img src={google} width={20} height={20} />
              <p className="mb-0">Sign up with Google</p>
            </Button>
          </SocialButton>

          <SocialButton
            containerStyle={isMobile ? { width: '100%' } : {}}
            provider="facebook"
            appId="378272869423880"
            onLoginSuccess={(data) => {
              if (data._profile.email && data._token.accessToken) {
                let apiData = {
                  token: data._token.accessToken,

                  social: true,
                  type: 'facebook',
                }
                dispatch({ type: 'REGISTER_START', payload: apiData })
              }
            }}
          >
            <Button type="button" variant="primary" className="free-account-social-login">
              <p className="mb-0">Sign up with Facebook</p>
            </Button>
          </SocialButton>
        </div>
      </div>
    </>
  )
}

export default FreeAccount

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Row, Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const SeoFriendlyUrlAnalysis = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [seoUrlAnalysisFlag, setSeoUrlAnalysisFlag] = useState(false)

  const internalLinksCsv = data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.map(item => {
    return {
      ['Internal Links']: item
    }
  })

  const thirdPartyLinksCsv = data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.thirdPartyLinks?.map(item => {
    return {
      ['Third Party Links']: item
    }
  })

  const dataForDownload=[
    {key:"Internal Links", datas: internalLinksCsv},
    {key:"Third Party Links", datas: thirdPartyLinksCsv}
  ]
  let csvDataForSeoSignal = []
  dataForDownload?.forEach(({key, datas}) => {
    if(datas?.length){
      csvDataForSeoSignal?.push([key])
    
    datas?.forEach((row) => {
      csvDataForSeoSignal?.push([row?.[key]])
    })
    csvDataForSeoSignal.push([])
  }
  });
  
  return (
    <>
      {data && data?.seoFriendlyUrlAnalysis ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.seoFriendlyUrlAnalysis?.data?.testResults?.status ? (
                ''
              ) : data?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div>
              <p>{data.seoFriendlyUrlAnalysis?.data?.testResults?.message}</p>
            </div>

            <div className="vr-report-status-icon" />
            {showDetail && data?.seoFriendlyUrlAnalysis?.data?.testResults?.status !== 1 && data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length ? (
              <p
                className="text-primary cursor-pointer"
                onClick={() => {
                  if (testType === "") {
                    setSeoUrlAnalysisFlag(!seoUrlAnalysisFlag)
                  } else {
                    dispatch(openRegisterModal(true))
                  }
                }}
              >
                See Details
              </p>
            ) : null}
          </div>
          <ModalHOC
            show={seoUrlAnalysisFlag}
            toggleShow={() => setSeoUrlAnalysisFlag(!seoUrlAnalysisFlag)}
            title={'Full list of unfriendly URLs'}
            bodyClassName="m-3"
            titleStyles={{ fontWeight: '400', fontSize: '22px' }}
            showDownload
            datas={csvDataForSeoSignal}
            fileName='SEO Friendly URL Signal Test'
          >
            <Row className="mt-3">
              <Col>
                <h4 className='f-bold'>Internal Links</h4>
                {data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly &&
                  data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks &&
                  data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.map((item, index) => {
                    return (
                      <li key={`seoUnfriendly-${index+1}`}>
                        <a href="javascript:void(0)"> {item} </a>
                      </li>
                    )
                  })}
                <br />
                {data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.thirdPartyLinks?.length ? <>
                  <h4 className='f-bold'>Third Party Links</h4>
                  {data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly &&
                    data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.thirdPartyLinks &&
                    data?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.thirdPartyLinks?.map((item, index) => {
                      return (
                        <li key={`thirdParty-${index +1}`}>
                          <a href="javascript:void(0)"> {item} </a>
                        </li>
                      )
                    })}
                </> : null}
              </Col>
            </Row>
          </ModalHOC>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SeoFriendlyUrlAnalysis

import { useSelector } from 'react-redux'
import { findLast, uniqBy } from 'lodash'
import { Col, Card, Row, ProgressBar } from 'react-bootstrap'
import { VerticalAlignMiddleOutlined } from '@ant-design/icons'

import { checkPositionForUserEntered, getActiveUserData } from 'constants/Utils.js'

const TopKeywordsHeader = () => {

  let unchangedKeywords = 0
  let increasedKeywords = 0
  let decreasedKeywords = 0
  
  const {
    activeLink,
    activeKeywordTrackingData,
    usersDomains: usersDomain,
    userData,
    activeKeywordData,
    activeRegionTab,
  } = useSelector(({ dashboard }) => dashboard)

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  let keywordDataInitial =
    activeKeywordTrackingData &&
    activeKeywordTrackingData?.length &&
    activeKeywordTrackingData?.filter((item) => {
      return item._id?.websiteId === usersDomain?.[activeLink]?.items?.[0]?.id
    })

  let keywordDataFiltered = keywordDataInitial?.[0]?.regions.filter((item) => {
    if (
      activeSearchData?.regions?.[activeRegionTab]?.region === item?.region &&
      activeSearchData?.regions?.[activeRegionTab]?.country === item?.country &&
      activeSearchData?.regions?.[activeRegionTab]?.state === item?.state &&
      activeSearchData?.regions?.[activeRegionTab]?.city === item?.city &&
      activeSearchData?.regions?.[activeRegionTab]?.address === item?.address
    ) {
      return item || {}
    }
  })

  let keywordTrackingData = keywordDataFiltered?.[0]?.keywords?.map((item) => {
    return {
      name: item?.keyword,
      regions: keywordDataFiltered?.[0],
      position: item?.positionData?.position,
      searchVolumn: item?.positionData?.searchVolume,
      trendsData: item?.positionData?.trends,
      volumnData: item?.positionData?.cpc,
      topDomains: item?.positionData?.topDomains,
      unchanged: item?.unchanged,
      increased: item?.increased,
      decreased: item?.decreased
    }
  })

  let filteredKeywords = keywordTrackingData?.filter((keywordObj) => {
    let found = activeSearchData?.keywords?.some((keyword) => keyword === keywordObj?.name)
    if (found) {
      return keywordObj
    }
  })

  if (activeSearchData?.keywords?.length > filteredKeywords?.length) {
    activeSearchData?.keywords?.filter((keyword) => {
      const found = filteredKeywords?.some((keywordObj) => keywordObj.name === keyword)
      if (!found) {
        filteredKeywords.push({ name: keyword, data: 'loading' })
      }
    })
  }

  filteredKeywords?.map((keyword) => {
    if (keyword?.unchanged) {
      unchangedKeywords += 1
    } else if (keyword?.increased) {
      increasedKeywords += 1
    } else {
      if (keyword?.decreased) {
        decreasedKeywords += 1
      }
    }
  }) 
  
  const tableNewDataTemp =
    (activeKeywordData &&
      activeKeywordData?.data &&
      activeKeywordData?.data.length &&
      activeKeywordData?.data[activeKeywordData?.data.length - 1]?.dataset &&
      activeKeywordData?.data[activeKeywordData?.data.length - 1]?.dataset.map((item) => {
        return {
          name: item.keyword,
          regions: item.regions,

          volumnData: item.volumnData,
        }
      })) ||
    []

  let tableNewData = uniqBy(tableNewDataTemp, 'name')
  let tableFilteredData = []
  activeSearchData?.keywords?.length &&
    activeSearchData?.keywords.forEach((element) => {
      let found = false
      tableNewData.forEach((innerElement) => {
        if (innerElement.name === element) {
          found = true
          tableFilteredData.push({
            name: element,
            ...innerElement,
          })
        }
      })
    })
  
  return (
    <Row>
      <Col md="9">
        <Row>
          <Col>
            <p>{increasedKeywords || 0} Increased</p>
            <ProgressBar
              className="mb-3"
              variant="success"
              now={increasedKeywords || 0}
              label=""
              max={filteredKeywords?.length}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{decreasedKeywords ||  0} Dropped</p>
            <ProgressBar
              className="mb-3"
              variant="danger"
              now={decreasedKeywords || 0}
              label=""
              max={filteredKeywords?.length}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{unchangedKeywords || 0} Unchanged</p>
            <ProgressBar
              className="mb-3"
              variant="warning"
              now={unchangedKeywords || 0}
              label=""
              max={filteredKeywords?.length}
            />
          </Col>
        </Row>
      </Col>
      <Col md="3" className="d-flex">
        <Card className="flex-fill url-details-inner mt-2 mt-md-0">
          <Card.Body className="py-4 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-start text-center">
              <div className="flex-grow-1">
                <p className="mb-2">Average Position</p>
                <h3 className="mb-2 f-bold d-flex align-items-center justify-content-center gap-1">
                  {' '}
                  <VerticalAlignMiddleOutlined style={{ fontSize: '175%' }} />{' '}
                  <span>{(checkPositionForUserEntered(filteredKeywords || []) &&
                    checkPositionForUserEntered(filteredKeywords || []).average.toFixed(2)) ||
                    '-'}</span>
                </h3>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default TopKeywordsHeader

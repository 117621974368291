import {
  GET_BACKLINKS_MOSTRECENTLY_CSV,
  CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV,
  GET_BACKLINKS_MOSTIMPORTANT_CSV,
  CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV,
  GET_BACKLINKS_MOSTRECENTLY_JSON,
  GET_BACKLINKS_MOSTIMPORTANT_JSON,
  CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON,
  CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON,
  GET_BACKLINKS_REFERRINGDOMAINS_JSON,
  GET_BACKLINKS_REFERRINGDOMAINS_CSV,
} from '../../actionType'

export const getMostRecentlyCSV = (data) => ({
  type: GET_BACKLINKS_MOSTRECENTLY_CSV,
  payload: data,
})

export const getMostImportantCSV = (data) => ({
  type: GET_BACKLINKS_MOSTIMPORTANT_CSV,
  payload: data,
})

export const clearMostRecentlyCSV = () => ({
  type: CLEAR_GET_BACKLINKS_MOSTRECENTLY_CSV,
})

export const clearMostImportantCSV = () => ({
  type: CLEAR_GET_BACKLINKS_MOSTIMPORTANT_CSV,
})

export const getMostRecentlyJSON = (data) => ({
  type: GET_BACKLINKS_MOSTRECENTLY_JSON,
  payload: data,
})

export const getMostImportantJSON = (data) => ({
  type: GET_BACKLINKS_MOSTIMPORTANT_JSON,
  payload: data,
})

export const clearMostRecentlyJSON = () => ({
  type: CLEAR_GET_BACKLINKS_MOSTRECENTLY_JSON,
})

export const clearMostImportantJSON = () => ({
  type: CLEAR_GET_BACKLINKS_MOSTIMPORTANT_JSON,
})

export const getReferringDomainJSON = (data) => ({
  type: GET_BACKLINKS_REFERRINGDOMAINS_JSON,
  payload: data,
})

export const getReferringDomainCSV = (data) => ({
  type: GET_BACKLINKS_REFERRINGDOMAINS_CSV,
  payload: data,
})

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from './ModalHOC'
import { Table } from 'reactstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'
const CachePolicy = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [cachePolicyFlag, setCachePolicyFlag] = useState(false)

  const cachedInternalLinksCSV = data?.cachePolicy?.data?.internalLinks?.urlsWithCache?.map(item => {
      return {
        ['Cached Internal Links'] : item
      }
  })
  
  const notCachedInternalLinksCSV = data?.cachePolicy?.data?.internalLinks?.urlsWithoutCache?.map(item => {
    return {
      ['Non Cached Internal Links'] : item
    }
}) || []

const thirdPartyLinksCSV = data.cachePolicy?.data?.thirdPartyLinks?.map(item => {
  return {
    ["Third Party Links"]: item
  }
}) || []

const dataForDownload=[
    {key:"Cached Internal Links", datas:cachedInternalLinksCSV},
    {key:"Non Cached Internal Links", datas:notCachedInternalLinksCSV},
    {key:"Third Party Links", datas:thirdPartyLinksCSV},
  ]
  let csvDataForCacheSignalTest = []
  dataForDownload?.forEach(({key, datas}) => {
    if(datas?.length){
    csvDataForCacheSignalTest?.push([key])
    
    datas?.forEach((row) => {
      csvDataForCacheSignalTest?.push([row?.[key]])
    })
    csvDataForCacheSignalTest?.push([])
  }
  });
  
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.cachePolicy?.data?.testResults?.status ? (
            ''
          ) : data?.cachePolicy?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.cachePolicy?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : data?.cachePolicy?.data?.testResults?.status === 1 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          ) : null}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>{data.cachePolicy?.data?.testResults?.message || ''}</p>
        {showDetail && (data.cachePolicy?.data?.internalLinks || data.cachePolicy?.data?.thirdPartyLinks) ? (
          <p 
            className="text-primary cursor-pointer" 
            onClick={() => {
              if (testType === "") {
                setCachePolicyFlag(!cachePolicyFlag)
              } else {
                dispatch(openRegisterModal(true)) 
              }
            }}>
            See Details
          </p>
        ) : null}
      </div>
      <ModalHOC
        show={cachePolicyFlag}
        toggleShow={() => setCachePolicyFlag(!cachePolicyFlag)}
        title={'Cache Policy Signal Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload={
          data.cachePolicy?.data?.internalLinks?.urlsWithCache?.length ||
          data.cachePolicy?.data?.internalLinks?.urlsWithoutCache?.length ||
          data.cachePolicy?.data?.thirdPartyLinks?.length
        }
        datas={csvDataForCacheSignalTest}
        fileName="Cache Policy Signal Test"
        alternativeDownload={true}
      >
        {data.cachePolicy?.data?.internalLinks?.urlsWithCache?.length ||
        data.cachePolicy?.data?.internalLinks?.urlsWithoutCache?.length ? (
          <h4 className="f-bold">Internal Links</h4>
        ) : null}
        {data.cachePolicy?.data?.internalLinks?.urlsWithCache &&
        data.cachePolicy?.data?.internalLinks?.urlsWithCache?.length ? (
          <Table striped>
            <thead>
              <th>Cached List</th>
            </thead>
            <tbody>
              {data.cachePolicy?.data?.internalLinks?.urlsWithCache?.map((item, index) => {
                return (
                  <tr>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(url, '_blank', 'noreferrer')}
                    >
                      {item?.length > 190 ? (
                        <OverlayTrigger
                          key={`displayData-${index + 1}`}
                          placement={'top'}
                          overlay={<Tooltip id={`tooltip-top`}>{item}</Tooltip>}
                        >
                          <span>{item?.substring(0, 190).concat('...')}</span>
                        </OverlayTrigger>
                      ) : (
                        item
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        ) : null}
        {data.cachePolicy?.data?.internalLinks?.urlsWithoutCache &&
        data.cachePolicy?.data?.internalLinks?.urlsWithoutCache?.length ? (
          <Table striped>
            <thead>
              <th>Non-Cached Links</th>
            </thead>
            <tbody>
              {data.cachePolicy?.data?.internalLinks?.urlsWithoutCache?.map((item, index) => {
                return (
                  <tr>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(url, '_blank', 'noreferrer')}
                    >
                      {item?.length > 190 ? (
                        <OverlayTrigger
                          key={`displayData-${index + 1}`}
                          placement={'top'}
                          overlay={<Tooltip id={`tooltip-top`}>{item}</Tooltip>}
                        >
                          <span>{item?.substring(0, 190).concat('...')}</span>
                        </OverlayTrigger>
                      ) : (
                        item
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        ) : null}
        {(
          !data.cachePolicy?.data?.internalLinks?.urlsWithCache?.length &&
          !data.cachePolicy?.data?.internalLinks?.urlsWithoutCache?.length
        ) ? (
          <div className="d-flex justify-content-center">No Links found</div>
        ) : null}
        {data.cachePolicy?.data?.thirdPartyLinks && data.cachePolicy?.data?.thirdPartyLinks?.length ? (
          <>
            <h4 className='f-bold'>Third Party Links</h4>
            <Table striped>
              <thead>
                <th>Links</th>
              </thead>
              <tbody>
                {data.cachePolicy?.data?.thirdPartyLinks?.map((item, index) => {
                  return (
                    <tr>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(url, '_blank', 'noreferrer')}
                      >
                        {item?.length > 190 ? (
                          <OverlayTrigger
                            key={`displayData-${index + 1}`}
                            placement={'top'}
                            overlay={<Tooltip id={`tooltip-top`}>{item}</Tooltip>}
                          >
                            <span>{item?.substring(0, 190).concat('...')}</span>
                          </OverlayTrigger>
                        ) : (
                          item
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </>
        ) : null}
      </ModalHOC>
    </>
  )
}
export default CachePolicy

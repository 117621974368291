import { Button, Card, Table, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

import { Loader } from 'components/uiCore'

import { getLocalStoraGeItem } from 'utils/helper'
import {
  deleteTemplateClear,
  deleteTemplateRequest,
  listTemplateRequest,
} from 'redux/actions/reporting/template'

import './style.scss'

export const Templates = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const [isId, setIsId] = useState(0)

  const { listTemplateData, isListTemplateLoading, deleteStatus } = useSelector((state) => state.template)

  const userData = getLocalStoraGeItem('userData')
  const { _id } = userData || {}

  useEffect(() => {
    if (_id) {
      dispatch(listTemplateRequest({ userId: _id }))
    }
  }, [_id, dispatch])

  const deleteOnClick = (id) => {
    dispatch(deleteTemplateRequest({ templateId: id }))
    setIsId(id)
  }

  useEffect(() => {
    if (deleteStatus) {
      message.success(`Template deleted successfully`)
      dispatch(listTemplateRequest({ userId: _id }))
      dispatch(deleteTemplateClear())
    }
  }, [deleteStatus, dispatch, _id])

  const columns = [
    {
      title: '#',
      dataIndex: '_id',
      key: 'index',
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Update at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (record) => moment(record).format('MMM DD, YYYY'),
      width: 300,
    },
    {
      title: 'Template name',
      dataIndex: 'templateName',
      key: 'templateName',
      width: 980,
    },
    {
      render: (record, index) => {
        return (
          <Button
            id={index}
            icon={<DeleteOutlined />}
            size="middle"
            danger
            loading={isId === record._id}
            onClick={() => deleteOnClick(record._id, index)}
            className="btn-delete"
          />
        )
      },
    },
  ]

  return (
    <section className="templates-section">
      {isListTemplateLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            alignSelf: 'center',
            flexDirection: 'row',
            height: '100%',
          }}
        >
          <div className="text-center">
            <Loader loaderText="Fetching templates, Please wait" />
          </div>
        </div>
      ) : (
        <>
          <Card title="Saved Templates" className="templates-card">
            <Table
              dataSource={listTemplateData?.resData || []}
              columns={columns}
              pagination={false}
              className="template-table"
              rowKey="id"
            />
          </Card>
          <Button
            type="primary"
            className="create-template-btn"
            onClick={() => history('/reporting/white-label/templates/new')}
          >
            Create new templates
          </Button>
        </>
      )}
    </section>
  )
}

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const PageCacheTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.pageCacheTest.heading}
      whatIsIT={freeTools.pageCacheTest.what}
      howToFixBeforeCode={freeTools.pageCacheTest.howSectionBeforeCode}
    />
  )
}

export default PageCacheTest

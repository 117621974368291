import { Button, Card, Row } from 'react-bootstrap'
import { CardBody } from 'reactstrap'
import Select from 'react-select'
import { useState } from 'react'
import paths from 'constants/paths'
import { useNavigate } from 'react-router-dom'

const FirewallOptions = ({callFrom = "dashboard"}) => {
  const navigate = useNavigate()
  const firewallOptions = [
    {
      value:
        'https://community.akamai.com/customers/s/article/How-to-allow-deny-an-IP-GEO-in-WAF-WAP-Network-List-Management?language=en_US',
      label: 'Akamai',
    },
    {
      value: 'https://developers.cloudflare.com/waf/tools/ip-access-rules/create/',
      label: 'Cloudflare',
    },
    {
      value:
        'https://learn.microsoft.com/en-us/answers/questions/1179573/how-to-define-ip-whitelist-in-azure-application-ga',
      label: 'Azure',
    },
    {
      value: 'https://docs.wallarm.com/user-guides/ip-lists/allowlist/',
      label: 'Wallarm',
    },
    { value: 'https://docs.imperva.com/howto/c85245b7', label: 'Imperva' },
  ]
  const [selectedFireWall, setSeletedFirewall] = useState('')

  return (
    <Card className="trail-flow " style={{ padding: 15 }}>
      <CardBody className="bg-white">
        <div className="pl-1 pt-3">
          <p>
            Please choose the firewall currently in use by your hosting provider from the the dropdown
            menu.Then, please select your service provider from the list. It will open a new window with
            instruction to add our server IP 3.14.177.6 to your list.This will allow our crawler to access
            your website without being blocked. However, please remember that you may revoke this access at
            any time. This access in only granted to our crawler which should not be confused with a malicious
            host. <br />
            <br />
          </p>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Please choose the Firewall"
            options={firewallOptions}
            isSearchable
            onChange={(e) => {
              setSeletedFirewall(e?.value)
            }}
          />
          <br />
          {selectedFireWall?.length ? (
            <>
              Please click on this URL :{' '}
              <a target="_blank" href={selectedFireWall}>
                {' '}
                {selectedFireWall}{' '}
              </a>{' '}
            </>
          ) : (
            <></>
          )}
          <br />
          <br />
          <p>
            If your are unsure which WAF you are currently using, please contact our support team at{' '}
            <b style={{ textDecoration: 'underline' }}>support@positionmysite.ca</b> or you can
            <a
              style={{ textDecoration: 'underline' }}
              target="_blank"
              href="https://securityforeveryone.com/"
            >
              {' '}
              click here{' '}
            </a>{' '}
            to try and determine which provider you are currently using. <br />
          </p>
        </div>
       {callFrom === "trial" ? <Row>
          <div>
            <Button variant='outline-primary' className='nxt-btn' onClick={() => navigate(paths.addNewWebsite)} style={{ height: 40, width: 250, marginRight: 5 }}>

              Back
            </Button>

          </div>
        </Row> : null}
      </CardBody>
    </Card>
  )
}

export default FirewallOptions

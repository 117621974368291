import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const InlineCss = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [inlineCssFlag, setInlinecssFlag] = useState(false)

  const columns = [
    {
      id:"inlineCss",
      displayName: 'Tags using inline css'
    }
  ]

  const inlineCsvData = data?.inlineCss?.data?.finalRes?.data?.map(item => {
    return {
      ["Tags using inline css"]: item?.html?.replace(/\n|\s{2,}/g, '')
    }
  })
    return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.inlineCss?.data?.testResults?.status ? (
            ''
          ) : data?.inlineCss?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.inlineCss?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>

      <div className="vr-detail-block">
        <p>{data?.inlineCss?.data?.testResults?.message}</p>
        {showDetail && data?.inlineCss?.data?.testResults?.status !== 1 && data?.inlineCss?.data?.finalRes?.data?.length ? (
          <>
            {' '}
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                 if (testType === "") {
                  setInlinecssFlag(!inlineCssFlag)
                 } else {
                  dispatch(openRegisterModal(true))
                 }
            }}>
              {' '}
              See Details
            </p>{' '}
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={inlineCssFlag}
        toggleShow={() => setInlinecssFlag(!inlineCssFlag)}
        title={'Full list of tags with inline css style'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns={columns}
        datas={inlineCsvData}
        fileName='Inline CSS Signal Test'
        alternativeDownload={true}
      >
        <Table striped>
          <tbody>
            {data?.inlineCss?.data?.finalRes?.data?.length ? (
              data?.inlineCss?.data?.finalRes?.data.map((item, index) => {
                return (
                  <tr key={`InlineCss-${index+1}`}>
                    <td>{item.html}</td>
                  </tr>
                )
              })
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </ModalHOC>
    </>
  )
}

export default InlineCss

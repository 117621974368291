import { Alert } from 'react-bootstrap'

export const CommonAlert = (props) => {
  const { variant, alertKey, alertText } = props
  return (
    <>
      <Alert
        // variant={data?.information?.seoCheckup?.title?.titleStatus === 1 ?'success' : 'danger'}
        variant={variant}
        className="alert-outline-coloured"
        key={alertKey}
      >
        <div className="alert-icon">{/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}</div>
        <div className="alert-message">{alertText}</div>
      </Alert>
    </>
  )
}

import React from 'react'
import CommonFreeTool from '../CommonFreeTool'
import { freeTools } from 'constants.js'

const HeadingTagsTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.headingTagsTest.heading}
      whatIsIT={freeTools.headingTagsTest.what}
      howToFixBeforeCode={freeTools.headingTagsTest.howSectionBeforeCode}
      codeSection={freeTools.headingTagsTest.codeSection}
    />
  )
}

export default HeadingTagsTest

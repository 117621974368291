import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, Modal, Spinner, Card } from 'react-bootstrap'

import { getUserData, openUpgradePlanModal } from 'redux/actions/dashboard'
import PlanData, { PLAN_NAME } from 'utils/paymentUtil.js'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import paths from 'constants/paths'
import { PLAN } from 'constants/enum'

const UpgradePlan = () => {
  const { userData } = useSelector(({ dashboard }) => dashboard)
  const { upgradeSubscriptionLoader, upgradeSubscriptionMessage, upgradeSubscriptionError } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const [showConfirmation, setShowConfimation] = useState(false)
  const [planName, setPlanName] = useState('')
  const [planDuration, setPlanDuration] = useState('')

  useEffect(() => {
    const fetchUserdata = async () => {
      const data = await localStorage.getItem('userData')
      const query = new URLSearchParams(window.location.search)

      if (query.get('userId')) {
        const userId = query.get('userId')
        dispatch(getUserData())
      } else {
        dispatch(getUserData())
      }
    }
    fetchUserdata()
  }, [])

  useEffect(() => {
    async function upgradeMesage(){
      if(!upgradeSubscriptionLoader && (upgradeSubscriptionMessage || upgradeSubscriptionError)){
        const data = await localStorage.getItem('userData')
        if(upgradeSubscriptionMessage){

          message.success(upgradeSubscriptionMessage)
          dispatch(getUserData())
          navigate(paths.mainDashboard)

        }else if(upgradeSubscriptionError){
          message.error(upgradeSubscriptionError)
          dispatch(getUserData())
          navigate(paths.mainDashboard)
        }

          dispatch({type: "CLEAR_UPGRADE_SUBSCRIPTION"})
      }
    }
    upgradeMesage()
  }, [upgradeSubscriptionLoader, upgradeSubscriptionMessage, upgradeSubscriptionError])
  
  const upgradePlan = (planName, planDuration) => {
    if (!showConfirmation) {
      setShowConfimation(true)
      setPlanName(planName)
      setPlanDuration(planDuration)
    } else {
      const resultData =
        PlanData &&
        PlanData.find(
          (item) => item.name === planName && item.type === planDuration && item.platform === 'Stripe',
        )

      const id = resultData?._id

      const paymentData = {
        planId: id,
      }

      dispatch({ type: 'UPGRADE_SUBSCRIPTION', payload: paymentData })
    }
  }

  useEffect(() => {
    if (upgradeSubscriptionLoader) {
      return
    } else {
      setShowConfimation(false)
    }
  }, [upgradeSubscriptionLoader])
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmation}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        toggle={() => setShowConfimation(!showConfirmation)}
        onClose={() => setShowConfimation(false)}
        onHide={() => setShowConfimation(false)}
      >
        <Modal.Header className="bb-none">
          <h3 className="mb-0">Confirm your plan</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">Are you ready to take your SEO to the next level? Upgrading to {planName === PLAN.PRO_PLAN.name ? PLAN.PRO_PLAN.name : planName === PLAN.BASIC_PLAN.name ? PLAN.BASIC_PLAN.name : PLAN.PREMIUM_PLAN.name} will unlock powerful features to boost your success. Confirm your upgrade now to experience enhanced capabilities and superior service! 🚀</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <div>
              <Button
                onClick={() => upgradePlan(planName, planDuration)}
                className="w-20 btn-primary d-flex flex-row align-items-center gap-1"
                variant="primary"
                disabled={upgradeSubscriptionLoader}
              >
                Yes
                {upgradeSubscriptionLoader ? <Spinner animation="border" key={1} size="sm" /> : null}
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <Button
                onClick={() => {
                  setShowConfimation(!showConfirmation)
                  dispatch(openUpgradePlanModal(true))
                }}
                className="w-20 btn-primary"
                variant="danger"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Card className='mb-0'>
        <Card.Header className='pb-0'>
          <h3 className="f-bold">Select your plan to upgrade</h3>
          <hr />
        </Card.Header>
        <Card.Body className='pt-0'>
          {userData?.planData?.type === 'Monthly' ? (
            <Row className="d-flex-flex-row justify-content-around">
              <Col className="mb-3 mb-md-0 mx-3 text-left plantable" style={{ border: '1px solid #cfcfcf' }}>
                <div className="mb-4 mt-4 text-left">
                  <h5 className={'plan-name f-bold'}>{PLAN.PREMIUM_PLAN.name}</h5>
                  <h3 className={'plan-price f-bold'}>
                    ${PLAN.PREMIUM_PLAN.monthly_price}
                    <span className="fs-3" style={{ color: '#3b82f6' }}>
                      /mo
                    </span>
                  </h3>
                  <p>${PLAN.PREMIUM_PLAN.yearly_price.perMonth}/mo billed yearly, saved {PLAN.PREMIUM_PLAN.yearly_price.saved}</p>
                </div>
                <hr />

                <div className="mb-2 mt-2 text-left">
                  <h6>SEO ANALYSIS</h6>
                  <span className="display-10">300</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED WEBSITE</h6>
                  <span className="display-10">15</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED KEYWORDS</h6>
                  <span className="display-10">300</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED REGIONS</h6>
                  <span className="display-10">5</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>ANALYZED COMPETITORS</h6>
                  <span className="display-10">5</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>WHITE LABEL REPORTS</h6>
                  <span className="display-10">INCLUDED</span>
                </div>
                <div className="mt-auto">
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={userData?.plan?.name === PLAN.PREMIUM_PLAN.name}
                    onClick={() => {
                      upgradePlan(PLAN.PREMIUM_PLAN.name, 'Monthly')
                    }}
                  >
                       {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "Selected" : "Choose"}
                  </Button>
                </div>
              </Col>

              <Col className="mb-3 mb-md-0 mx-3 plantable" style={{ border: '1px solid #cfcfcf' }}>
                <div className="mb-4 mt-4 text-left">
                  <h5 className={'plan-name f-bold'}>{PLAN.PRO_PLAN.name}</h5>
                  <h3 className={'plan-price f-bold'}>
                    ${PLAN.PRO_PLAN.monthly_price}
                    <span className="fs-3" style={{ color: '#3b82f6' }}>
                      /mo
                    </span>
                  </h3>
                  <p>${PLAN.PRO_PLAN.yearly_price.perMonth}/mo billed yearly, saved {PLAN.PRO_PLAN.yearly_price.saved}</p>
                </div>
                <hr />

                <div className="mb-2 mt-2 text-left">
                  <h6>SEO ANALYSIS</h6>
                  <span className="display-10">100</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED WEBSITE</h6>
                  <span className="display-10">5</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED KEYWORDS</h6>
                  <span className="display-10">100</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED REGIONS</h6>
                  <span className="display-10">3</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>ANALYZED COMPETITORS</h6>
                  <span className="display-10">3</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>WHITE LABEL REPORTS</h6>
                  <span className="display-10">INCLUDED</span>
                </div>
                <div className="mt-auto">
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={userData?.plan?.name === PLAN.PRO_PLAN.name || userData?.plan?.name === PLAN.PREMIUM_PLAN.name}
                    onClick={() => {
                      upgradePlan(PLAN.PRO_PLAN.name, 'Monthly')
                    }}
                  >
                       {userData?.plan?.name === PLAN.PRO_PLAN.name ? "Selected" : "Choose"}
                  </Button>
                </div>
              </Col>

              <Col className="mb-3 mx-3 mb-md-0 plantable" style={{ border: '1px solid #cfcfcf' }}>
                <div className="mb-4 mt-4 text-left">
                  <h5 className={'plan-name f-bold'}>{PLAN.BASIC_PLAN.name}</h5>
                  <h3 className={'plan-price f-bold'}>
                    ${PLAN.BASIC_PLAN.monthly_price}
                    <span className="fs-3" style={{ color: '#3b82f6' }}>
                      /mo
                    </span>
                  </h3>
                  <p>${PLAN.BASIC_PLAN.yearly_price.perMonth}/mo billed yearly, saved {PLAN.BASIC_PLAN.yearly_price.saved}</p>
                </div>
                <hr />

                <div className="mb-2 mt-2 text-left">
                  <h6>SEO ANALYSIS</h6>
                  <span className="display-10">50</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED WEBSITE</h6>
                  <span className="display-10">3</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED KEYWORDS</h6>
                  <span className="display-10">50</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED REGIONS</h6>
                  <span className="display-10">1</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>ANALYZED COMPETITORS</h6>
                  <span className="display-10">1</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>WHITE LABEL REPORTS</h6>
                  <span className="display-10">NOT INCLUDED</span>
                </div>
                <div className="mt-auto">
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={userData?.plan?.name === PLAN.BASIC_PLAN.name || userData?.plan?.name === PLAN.PRO_PLAN.name || userData?.plan?.name === PLAN.PREMIUM_PLAN.name}
                    onClick={() => {
                      upgradePlan(PLAN.BASIC_PLAN.name, 'Monthly')
                    }}
                  >
                    {userData?.plan?.name === PLAN.BASIC_PLAN.name ? "Selected" : "Choose"}
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="mb-3 mb-md-0 mx-3 plantable" style={{ border: '1px solid #cfcfcf' }}>
                <div className="mb-4 mt-4 text-left">
                  <h5 className={'plan-name f-bold'}>{PLAN.PREMIUM_PLAN.name}</h5>
                  <h3 className={'plan-price f-bold'}>
                    ${PLAN.PREMIUM_PLAN.yearly_price.perMonth}
                    <span className="fs-3" style={{ color: '#3b82f6' }}>
                      /mo
                    </span>
                  </h3>
                  <p>billed yearly, {PLAN.PREMIUM_PLAN.yearly_price.saved} off, ${PLAN.PREMIUM_PLAN.yearly_price.perYear}/year</p>
                </div>
                <hr />

                <div className="mb-2 mt-2 text-left">
                  <h6>SEO ANALYSIS</h6>
                  <span className="display-10">300</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED WEBSITE</h6>
                  <span className="display-10">15</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED KEYWORDS</h6>
                  <span className="display-10">300</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED REGIONS</h6>
                  <span className="display-10">5</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>ANALYZED COMPETITORS</h6>
                  <span className="display-10">5</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>WHITE LABEL REPORTS</h6>
                  <span className="display-10">INCLUDED</span>
                </div>
                <div className="mt-auto">
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={userData?.plan?.name === PLAN.PREMIUM_PLAN.name}
                    onClick={() => {
                      upgradePlan(PLAN.PREMIUM_PLAN.name, 'Yearly')
                    }}
                  >
                     {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "Selected" : "Choose"}
                  </Button>
                </div>
              </Col>

              <Col className="mb-3 mb-md-0 mx-3 plantable" style={{ border: '1px solid #cfcfcf' }}>
                <div className="mb-4 mt-4 text-left">
                  <h5 className={'plan-name f-bold'}>{PLAN.PRO_PLAN.name}</h5>
                  <h3 className={'plan-price f-bold'}>
                    ${PLAN.PRO_PLAN.yearly_price.perMonth}
                    <span className="fs-3" style={{ color: '#3b82f6' }}>
                      /mo
                    </span>
                  </h3>
                  <p>billed yearly, {PLAN.PRO_PLAN.yearly_price.saved} off, ${PLAN.PRO_PLAN.yearly_price.perYear}/year</p>
                </div>
                <hr />

                <div className="mb-2 mt-2 text-left">
                  <h6>SEO ANALYSIS</h6>
                  <span className="display-10">100</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED WEBSITE</h6>
                  <span className="display-10">5</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED KEYWORDS</h6>
                  <span className="display-10">100</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED REGIONS</h6>
                  <span className="display-10">3</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>ANALYZED COMPETITORS</h6>
                  <span className="display-10">3</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>WHITE LABEL REPORTS</h6>
                  <span className="display-10">INCLUDED</span>
                </div>
                <div className="mt-auto">
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={userData?.plan?.name === PLAN.PRO_PLAN.name || userData?.plan?.name === PLAN.PREMIUM_PLAN.name}
                    onClick={() => {
                      upgradePlan(PLAN.PRO_PLAN.name, 'Yearly')
                    }}
                  >
                     {userData?.plan?.name === PLAN_NAME.BUSINESS_PRO ? "Selected" : "Choose"}
                  </Button>
                </div>
              </Col>

              <Col className="mb-3 mx-3 mb-md-0 plantable" style={{ border: '1px solid #cfcfcf' }}>
                <div className="mb-4 mt-4 text-left">
                  <h5 className={'plan-name f-bold'}>{PLAN.BASIC_PLAN.name}</h5>
                  <h3 className={'plan-price f-bold'}>
                    ${PLAN.BASIC_PLAN.yearly_price.perMonth}
                    <span className="fs-3" style={{ color: '#3b82f6' }}>
                      /mo
                    </span>
                  </h3>
                  <p>billed yearly, {PLAN.BASIC_PLAN.yearly_price.saved} off, ${PLAN.BASIC_PLAN.yearly_price.perYear}/year</p>
                </div>
                <hr />

                <div className="mb-2 mt-2 text-left">
                  <h6>SEO ANALYSIS</h6>
                  <span className="display-10">50</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED WEBSITE</h6>
                  <span className="display-10">3</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED KEYWORDS</h6>
                  <span className="display-10">50</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>MONITORED REGIONS</h6>
                  <span className="display-10">1</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>ANALYZED COMPETITORS</h6>
                  <span className="display-10">1</span>
                </div>
                <hr />
                <div className="mb-2 mt-2 text-left">
                  <h6>WHITE LABEL REPORTS</h6>
                  <span className="display-10">NOT INCLUDED</span>
                </div>
                <div className="mt-auto">
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={userData?.plan?.name === PLAN.BASIC_PLAN.name || userData?.plan?.name === PLAN.PRO_PLAN.name || userData?.plan?.name === PLAN.PREMIUM_PLAN.name}
                    onClick={() => {
                      upgradePlan(PLAN.BASIC_PLAN.name, 'Yearly')
                    }}
                  >
                      {userData?.plan?.name === PLAN.BASIC_PLAN.name ? "Selected" : "Choose"}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default UpgradePlan

import {
  BACKLINKS_CHECK_START,
  BACKLINKS_CHECK_SUCCESS,
  BACKLINKS_CHECK_FAIL,
  GET_USER_DOMAINS,
  GET_USER_DOMAINS_SUCCESS,
  GET_USER_DOMAINS_FAILURE,
  GET_BACKLINKS,
  GET_BACKLINKS_SUCCESS,
  GET_BACKLINKS_FAILURE,
  PATCH_USER_DOMAINS_DASHBOARD,
  PATCH_USER_DOMAINS_DASHBOARD_FAILURE,
  CHANGE_ACTIVE_LINK,
  DELETE_WEBSITE,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  GET_USER_DATA,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  TOGGLE_COMPETITORS,
  REMOVE_KEYWORD,
  REMOVE_KEYWORD_SUCCESS,
  REMOVE_KEYWORD_FAILURE,
  RECHECK_BACKLINKS_SUMMARY,
  RECHECK_BACKLINKS_SUMMARY_SUCCESS,
  RECHECK_BACKLINKS_SUMMARY_FAILURE,
  ADD_KEYWORDS_SETTINGS,
  ADD_KEYWORDS_SETTINGS_SUCCESS,
  ADD_KEYWORDS_SETTINGS_FAILURE,
  ADD_COMPETITORS_SETTINGS,
  ADD_COMPETITORS_SETTINGS_SUCCESS,
  ADD_COMPETITORS_SETTINGS_FAILURE,
  CHANGE_SOCIAL_SETTINGS,
  CHANGE_SOCIAL_SETTINGS_SUCCESS,
  CHANGE_SOCIAL_SETTINGS_FAILURE,
  CHANGE_REGION_SETTINGS,
  CHANGE_REGION_SETTINGS_SUCCESS,
  CHANGE_REGION_SETTINGS_FAILURE,
  REMOVE_COMPETITORS_SETTINGS,
  REMOVE_COMPETITORS_SETTINGS_FAILURE,
  REMOVE_COMPETITORS_SETTINGS_SUCCESS,
  HEADING_ANALYSIS_TEST,
  HEADING_ANALYSIS_TEST_SUCCESS,
  HEADING_ANALYSIS_TEST_FAILURE,
  IMG_ALT_TEST,
  IMG_ALT_TEST_SUCCESS,
  IMG_ALT_TEST_FAILURE,
  SEO_FRIENDLY_URL,
  SEO_FRIENDLY_URL_SUCCESS,
  SEO_FRIENDLY_URL_FAILURE,
  BROKEN_LINKS_TEST,
  BROKEN_LINKS_TEST_SUCCESS,
  BROKEN_LINKS_TEST_FAILURE,
  GET_BACKLINKS_STATS_SUCCESS,
  GET_BACKLINKS_STATS,
  GET_BACKLINKS_STATS_FAILURE,
  GET_SITEOUTAGE_DATA,
  GET_SITEOUTAGE_DATA_SUCCESS,
  GET_SITEOUTAGE_DATA_FAILURE,
  GET_SITEOUTAGE_DATA_CLEAR,
  CLEAR_DOMAINS,
  ADD_WEBSITE_FROM_TRIAL,
  GET_BACKLINKS_GRAPH,
  GET_BACKLINKS_GRAPH_SUCCESS,
  GET_BACKLINKS_GRAPH_FAILURE,
  GET_BACKLINKS_MOST_RECENTLY,
  GET_BACKLINKS_MOST_RECENTLY_SUCCESS,
  GET_BACKLINKS_MOST_RECENTLY_FAILURE,
  GET_BACKLINKS_MOST_IMPORTANT,
  GET_BACKLINKS_MOST_IMPORTANT_SUCCESS,
  GET_BACKLINKS_REFERRING_DOMAIN,
  GET_BACKLINKS_REFERRING_DOMAIN_SUCCESS,
  GET_BACKLINKS_MOST_IMPORTANT_FAILURE,
  GET_BACKLINKS_REFERRING_DOMAIN_FAILURE,
  GET_BACKLINKS_ANCHOR_TEXT,
  GET_BACKLINKS_ANCHOR_TEXT_SUCCESS,
  GET_BACKLINKS_ANCHOR_TEXT_FAILURE,
  CLEAR_GET_BACKLINKS_MOST_RECENTLY,
  CLEAR_REMOVE_KEYWORD,
  GET_REGIONAL_LOCATION_CONFIGURATION,
  GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS,
  GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE,
  CLEAR_REGIONAL_LOCATION_CONFIGURATION,
  UPDATE_SEO_TEST_PROGRESS_PERCENTAGE,
  GET_COMPETITOR_SEO_SCORE,
  GET_COMPETITOR_SEO_SCORE_SUCCESS,
  MAIN_URL_SEO_SCORE,
  MAIN_URL_SEO_SCORE_SUCCESS,
  CLEAR_DELETE_WEBSITE,
  WHITELIST_BROKEN_URL,
  WHITELIST_BROKEN_URL_SUCCESS,
  STOP_DASHBOARD_PATCH_API_CALLS,
  CLEAR_STOP_DASHBOARD_PATCH_API_CALLS,
  STOP_BACKLINKS_STATS_API_CALL,
  CLEAR_STOP_BACKLINKS_STATS_API_CALL,
  ADD_EVERYWHERE_KEYWORDS,
  ADD_EVERYWHERE_KEYWORDS_SUCCESS,
  ADD_EVERYWHERE_KEYWORDS_FAILURE,
  GET_UPDATED_KEYWORD_RESEARCH,
  KEYWORDS_RESEARCH_DATA,
  KEYWORDS_RESEARCH_DATA_SUCCESS,
  KEYWORDS_RESEARCH_DATA_FAILURE,
  REMOVE_COMPETITORS,
  REMOVE_COMPETITORS_FAILURE,
  REMOVE_COMPETITORS_SUCCESS_1,
  URL_SCAN_STATUS_FAILURE,
  GET_URL_SCAN_STATUS_SUCCESS,
  UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE,
  SEO_COMPETITOR_PERCENTAGE_ACTUAL,
  KEYWORD_POSITION_TRACKING_SUCCESS,
  KEYWORD_POSITION_TRACKING_FAILURE,
  KEYWORD_POSITION_TRACKING,
  KEYWORD_ACTIVE_REGION_TAB,
  PERSONAL_INFO_UPDATE,
  PERSONAL_INFO_UPDATE_SUCESS,
  PERSONAL_INFO_UPDATE_ERROR,
  DELETE_USER,
  DELETE_USER_SUCESS,
  DELETE_USER_ERROR,
  PERSONAL_INFO_UPDATE_CLEAR,
  DELETE_USER_CLEAR,
  OPEN_UPGRADE_PLAN_MODAL,
  GET_LIGHTHOUSE_QUICK_SEO,
  GET_LIGHTHOUSE_QUICK_SEO_SUCCESS,
  GET_LIGHTHOUSE_QUICK_SEO_FAILURE,
  CLEAR_GET_LIGHTHOUSE_QUICK_SEO,
  WEBSITE_LIMIT,
  WEBSITE_LIMIT_SUCCESS,
  WEBSITE_LIMIT_FAILURE,
  CLEAR_WEBSITE_LIMIT,
  KEYWORD_LIMIT,
  KEYWORD_LIMIT_SUCCESS,
  KEYWORD_LIMIT_FAILURE,
  CLEAR_KEYWORD_LIMIT,
  COMPETITOR_LIMIT,
  COMPETITOR_LIMIT_SUCCESS,
  COMPETITOR_LIMIT_FAILURE,
  CLEAR_COMPETITOR_LIMIT,
  QUICK_SCAN_LIMIT,
  QUICK_SCAN_LIMIT_SUCCESS,
  QUICK_SCAN_LIMIT_FAILURE,
  CLEAR_QUICK_SCAN_LIMIT,
  AVAILABLE_KEYWORD_LIMIT,
  AVAILABLE_KEYWORD_LIMIT_SUCCESS,
  AVAILABLE_KEYWORD_LIMIT_FAILURE,
  CLEAR_ADD_KEYWORDS_SETTINGS,
  CLEAR_REMOVE_COMPETITORS_SETTINGS,
  CLEAR_ADD_COMPETITORS_SETTINGS,
  GET_TEST_RESULTS_SUCCESS,
  GET_TEST_RESULTS_FAILURE,
  PATCH_USER_DOMAINS_SUCCESS,
  KEYWORD_RESEARCH_LOADING,
  CLEAR_KEYWORD_RESEARCH_LOADING,
  NEW_NOTIFICATION,
  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_FAILURE,
  READ_SINGLE_NOTIFICATION,
  READ_SINGLE_NOTIFICATION_SUCCESS,
  READ_SINGLE_NOTIFICATION_FAILURE,
  CLEAR_READ_SINGLE_NOTIFICATION,
  GET_TEST_RESULTS,
  CLEAR_USER_DATA,
  READ_ALL_NOTIFICATION,
  READ_ALL_NOTIFICATION_SUCCESS,
  READ_ALL_NOTIFICATION_FAILURE,
  CLEAR_READ_ALL_NOTIFICATION,
  GET_LOST_BACKLINKS,
  GET_LOST_BACKLINKS_SUCCESS,
  GET_LOST_BACKLINKS_FAILURE,
  GET_AUTH_URL,
  GET_AUTH_URL_SUCCESS,
  GET_AUTH_URL_FAILURE,
  GENERATE_RESEARCH,
  GENERATE_RESEARCH_SUCCESS,
  GENERATE_RESEARCH_FAILURE,
  CLEAR_GET_AUTH_URL,
  GOOGLE_ANALYTICS_MODAL,
  ANALYTICS_DATA,
  ANALYTICS_DATA_SUCCESS,
  ANALYTICS_DATA_FAILURE,
  CLEAR_GENERATE_RESEARCH,
  CLEAR_ANALYTICS_DATA,
  DISCONNECT_GOOGLE,
  DISCONNECT_GOOGLE_SUCCESS,
  DISCONNECT_GOOGLE_FAILURE,
  CLEAR_DISCONNECT_GOOGLE,
  STORE_ANALYTICS_DATA,
  STORE_ANALYTICS_DATA_SUCCESS,
  STORE_ANALYTICS_DATA_FAILURE,
  CLEAR_STORE_ANALYTICS_DATA,
  GET_ANALYTICS_DATA,
  GET_ANALYTICS_DATA_SUCCESS,
  GET_ANALYTICS_DATA_FAILURE,
  CLEAR_GET_ANALYTICS_DATA,
  CLEAR_GET_ANALYTICS_ERROR,
  CLEAR_GENERATE_RESEARCH_DATA,
  TOGGLE_KEYWORD,
  GET_AUTH_TOKEN,
  GET_AUTH_TOKEN_SUCCESS,
  GET_AUTH_TOKEN_FAILURE,
  CLEAR_GET_AUTH_TOKEN,
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  CLEAR_CONTACT_US,
  SPEED_GRAPH_CHART_DATA_SUCCESS,
  SPEED_GRAPH_CHART_DATA_REQUEST,
  SPEED_GRAPH_CHART_DATA_FAILURE,
  CLEAR_SPEED_GRAPH_CHART_DATA,
  GENERATE_TICKET,
  GENERATE_TICKET_SUCCESS,
  GENERATE_TICKET_FAILURE,
  CLEAR_GENERATE_TICKET
} from 'redux/actionType.js'

import * as moment from 'moment'
import * as _ from 'lodash'
import { PLAN } from 'constants/enum'

const InitialState = {
  success: false,
  error: false,
  loading: false,
  titleTest: false,
  urlSummary: [],
  links: [],
  analyzeError: false,
  analyzeLoading: false,
  analyzeSuccess: false,
  analyzeData: [],
  reRenderError: false,
  reRenderLoading: false,
  reRenderSuccess: false,
  localCompetitors: [],
  changed: false,
  removedKeywords: false,
  settingsRegionLoader: false,
  regionLoaderType: '',
  settingsDeleteCompetitorLoader: false,
  settingsDeleteKeywordLoader: false,
  settingsAddKeywordLoader: false,
  settingsAddKeywordSuccess: false,
  settingsLoader: false,
  socialSettingLoader: false,
  backlinkGraphLoader: false,
  backlinkGraphData: [],
  backlinkGraphError: false,
  getBacklinkDataLoader: false,
  backlinksDomainsList: [],
  getBacklinksMostRecentlyLoader: false,
  getBacklinksMostImportantLoader: false,
  getBacklinksReferringDomainLoader: false,
  getBacklinksAnchorTextLoader: false,
  isKeywordAddedLoader: false,
  isKeywordAddedSuccess: false,
  keywordRemoveSuccess: false,
  keywordRemoveLoader: false,
  seoProgressPercentage: 0,
  seoCompetitorPercentage: 0,
  websiteDeleteSuccess: false,
  websiteDeleteFailure: false,
  websiteDeleteLoader: false,
  stopPatchApiCall: false,
  stopBacklinksStatsApiCall: false,
  addKeywordEverywheres: false,
  addKeywordEverywhereSuccess: false,
  clearDeleteWebsite: false,
  addKeywordEverywhereData: '',
  indexUrlTest: '',
  keywordResearchData: [],
  backlinksDataForPopup: {},
  seoCompetitorPercentageActual: 0,
  activeKeywordTrackingData: [],
  keywordTrackingSuccess: false,
  regionSuccess: false,
  activeRegionTab: 0,
  activeSiteOutageData: [],
  personalInformationData:{},
  persnoalInfoLoading: false,
  personalInfoSuccess: false,
  personalInfoFailure: false,
  deleteUser: {},
  deleteUserLoading: false,
  toastError:'',
  toastMessage:'',
  openUpgradePlan: false,
  quickSeoURL: '',
  websiteLimitLoader: false,
  websiteLimitError: {},
  websiteLimitData:{},
  keywordLimitLoader: false,
  keywordLimitError: {},
  keywordLimitData:{},
  competitorLimitLoader: false,
  competitorLimitError: {},
  competitorLimitData:{},
  quickScanLimitLoader: false,
  quickScanLimitError: {},
  quickScanLimitData:{},
  keywordResearch: false,
  removeCompetitorSuccess: false,
  addCompetitorSuccess: false,
  testResultData: [],
  loadingForActiveLink: null,
  notificationLoader: false,
  notificationsData: [],
  notificationsError: {},
  singleNotificationLoader: false,
  singleNotificationSuccess: false,
  singleNotificationError: {},
  loadingTestResults: false,
  allNotificationsLoader: false,
  allNotificationsError: {},
  allNotificationsSuccess: false,
  analytics: {},
  selectedKeywordsList: [],
  contactUsLoader: false,
  contactUsFailure: false,
  contactUsSuccess: false,
  contactUsError: {},
  contactUsMessage: {},
  dashboardGraphData: [],
  generateTicketLoader: false,
  generateTicketFailure: false,
  generateTicketSuccess: false,
  generateTicketError: {},
  generateTicketMessage: {}
}

const dashboardReducer = (state = InitialState, action) => {
  switch (action.type) {
    case STOP_DASHBOARD_PATCH_API_CALLS:
      return {
        ...state,
        stopPatchApiCall: true,
      }

    case CLEAR_STOP_DASHBOARD_PATCH_API_CALLS:
      return {
        ...state,
        stopPatchApiCall: false,
      }

    case STOP_BACKLINKS_STATS_API_CALL:
      return {
        ...state,
        stopBacklinksStatsApiCall: true,
      }

    case CLEAR_STOP_BACKLINKS_STATS_API_CALL:
      return {
        ...state,
        stopBacklinksStatsApiCall: false,
      }

    case ADD_COMPETITORS_SETTINGS:
      return {
        ...state,
        settingsLoader: true,
        addCompetitorSuccess: false
      }

    case ADD_COMPETITORS_SETTINGS_SUCCESS: {
      const { usersDomains: domainsArray15, activeLink: currentActive15 } = state
      let url15 = domainsArray15[currentActive15].items[0].url
      const splitedValue15 = url15.split('/').slice(0, 3)

      let hostName15 = splitedValue15[splitedValue15.length - 1]
      if (hostName15.includes('www.')) {
        hostName15 = hostName15.replace('www.', '')
      }
      const tempAddCompetitorsList =
        action.data &&
        action.data.searchResults &&
        action.data.searchResults.length &&
        _.findLast(action.data.searchResults, (item) => item && item.url && item.url.includes(hostName15))
      let localCompetitors15 = (tempAddCompetitorsList && tempAddCompetitorsList.competitors) || []
      let localKeywords15 = (tempAddCompetitorsList && tempAddCompetitorsList.keywords) || []
      localCompetitors15 =
        localCompetitors15 &&
        localCompetitors15.length &&
        localCompetitors15.map((item) => {
          return {
            name: item,
            checked: true,
          }
        })
      return {
        ...state,
        settingsLoader: false,
        userData: action.data,
        localCompetitors: localCompetitors15,
        localKeywords: localKeywords15,
        changed: !state.changed,
        addCompetitorSuccess: true
      }
    }

    case ADD_COMPETITORS_SETTINGS_FAILURE:
      return {
        ...state,
        settingsLoader: false,
        addCompetitorError: action?.error
      }

    case CLEAR_ADD_COMPETITORS_SETTINGS:
      return {
        ...state,
        settingsLoader: false,
        addCompetitorSuccess: false
      }

    case WHITELIST_BROKEN_URL: {
      return {
        ...state,
        loadingWhiteList: true,
        whiteListURLSuccess: false,
      }
    }

    case WHITELIST_BROKEN_URL_SUCCESS: {
      return {
        ...state,
        loadingWhiteList: false,
        whiteListURLSuccess: true,
      }
    }

    case GET_COMPETITOR_SEO_SCORE: {
      return {
        ...state,
      }
    }

    case GET_COMPETITOR_SEO_SCORE_SUCCESS: {
      let competitorSeoScoreData = action?.payload
      return {
        ...state,
        competitorSeoScoreData,
      }
    }

    case MAIN_URL_SEO_SCORE: {
      return {
        ...state,
      }
    }

    case MAIN_URL_SEO_SCORE_SUCCESS: {
      return {
        ...state,
        mainUrlScoreDataArray: action?.payload,
      }
    }

    case UPDATE_SEO_TEST_PROGRESS_PERCENTAGE: {
      const { usersDomains: usersDomain, activeLink, userData } = state
      const splitedValue =
        (usersDomain &&
          activeLink !== undefined &&
          usersDomain[activeLink] &&
          usersDomain[activeLink].items &&
          usersDomain[activeLink].items[0].url.split('/').slice(0, 3)) ||
        ''
      let hostName = splitedValue[splitedValue.length - 1] || ''
      if (hostName.includes('www.')) {
        hostName = hostName.replace('www.', '')
      }

      const activeSearchData =
        (userData &&
          userData.searchResults &&
          userData.searchResults.length &&
          _.findLast(
            userData.searchResults,
            (item) => item && item.url && item.url.toLowerCase().includes(hostName.toLowerCase()),
          )) ||
        {}
      let competitorDataCollection = []
      if (action.payload && action.payload > 99) {
        if (state.seoProgressPercentage !== 100) {
          state?.competitorSeoScoreData &&
            state?.competitorSeoScoreData?.length &&
            state?.competitorSeoScoreData.forEach((item) => {
              item.data &&
                item.data.forEach((dataItem) => {
                  const mainUrl = dataItem?.mainUrl?.length &&
                  dataItem?.mainUrl?.endsWith('/')
                  ? dataItem?.mainUrl?.slice(0, -1) : dataItem?.mainUrl

                  const redirectUrl = state.usersDomains?.[state.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
                  const newRedirectUrl = redirectUrl?.length &&
                  redirectUrl?.endsWith('/')
                  ? redirectUrl?.slice(0, -1) : redirectUrl

                  if (mainUrl === newRedirectUrl) {
                    let combineData = {
                      ...dataItem,
                      competitiorUrl: item._id,
                    }
                    competitorDataCollection.push(combineData)
                  }
                })
            })
          let dataForChartNew = []
          competitorDataCollection = competitorDataCollection?.filter(item => { return activeSearchData?.competitors?.includes(item?.competitiorUrl) })
          
          competitorDataCollection &&
            competitorDataCollection.length &&
            competitorDataCollection.forEach((compt) => {
              if (compt?.testResult) {
                var key = moment(`${compt?.year}-${compt?.month}-${compt?.day}`, 'YYYY-MM-DD').format('MM-DD-YYYY')
                let competitiorScore = Math.round((compt?.count / 73) * 100)
                if (key !== null) {
                  dataForChartNew.push({
                    x: key,
                    competitor: compt.competitiorUrl,
                    y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
                  })
                }
              }
            })
          
          state?.mainUrlScoreDataArray?.length &&
            state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
              let url1 = mainUrlSeoScore?._id?.length &&
                mainUrlSeoScore?._id?.endsWith('/')
                ? mainUrlSeoScore?._id?.slice(0, -1) : mainUrlSeoScore?._id

              let url2 = state?.usersDomains?.[state?.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url?.length &&
                state?.usersDomains?.[state?.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url?.endsWith('/')
                ? state?.usersDomains?.[state?.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url?.slice(0, -1)
                : state?.usersDomains?.[state?.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url

              if (url1 === url2) {
                mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
                  if (innerMainUrlScore?.testResult) {
                    var key = moment(
                      `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                      'YYYY-MM-DD',
                    ).format('MM-DD-YYYY')

                    let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                    if (key !== null) {
                      if (action?.payload >= 100) {
                        dataForChartNew.push({
                          x: key,
                          mainUrl: true,
                          competitor:
                            state.usersDomains?.[state.activeLink]?.items?.[0]?.information?.seoCheckup
                              ?.redirect_url,
                          y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                        })
                      } else {
                        dataForChartNew.push({
                          x: key,
                          mainUrl: true,
                          competitor:
                            state.usersDomains?.[state.activeLink]?.items?.[0]?.information?.seoCheckup
                              ?.redirect_url,
                          y: 0,
                        })
                      }
                    }
                  }
                })
              }
            })
          /////
          let dataForChartNew1 = _.sortBy(dataForChartNew, ['y'])
          return {
            ...state,
            seoProgressPercentage: action.payload,
            chartDataForAntD: dataForChartNew1,
          }
        } else {
          return {
            ...state,
          }
        }
      } else {
        return {
          ...state,
          seoProgressPercentage: action.payload,
        }
      }
    }

    case UPDATE_SEO_TEST_COMPETITOR_PERCENTAGE: {
      return {
        ...state,
        seoCompetitorPercentage: action?.payload,
      }
    }

    case SEO_COMPETITOR_PERCENTAGE_ACTUAL: {
      return {
        ...state,
        seoCompetitorPercentageActual: action?.payload,
      }
    }

    case CLEAR_DOMAINS: {
      return {
        ...state,
        usersDomains: [],
        activeLink: 0,
        chartDataForAntD: [],
        backlinkGraphData: [],
        seoProgressPercentage: 0,
      }
    }

    case CLEAR_USER_DATA:{
      return {
        ...state,
        userData: {}
      }
    }
    
    case GET_BACKLINKS: {
      return {
        ...state,
        getBacklinksDataLoader: true,
      }
    }

    case GET_BACKLINKS_SUCCESS: {
      let data =
        state.usersDomains &&
        state.usersDomains.length &&
        state.usersDomains.map((element) => {
          let backlinksData = {}
          let referringDomains = {}
          let anchorTexts = {}
          let backlinksGraph = []
          let noFollowDataGraph = []
          let doFollowDataGraph = []
          let referringDomainsGraph = []
          let statsData = state.usersDomains[state.activeLink]?.statsData || []
          let referringDomainURLs = []
          let tldDistributions = []

          const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
          let hostName = splitedValue[splitedValue.length - 1]
          if (hostName.includes('www.')) {
            hostName = hostName.replace('www.', '')
          }

          action.payload &&
            action.payload.resp &&
            action.payload.resp.forEach((item) => {
              if (item.url.includes(hostName)) {
                backlinksData = item.backlinksData
                referringDomains = item.referringDomains
                anchorTexts = item.anchorTexts
                tldDistributions = item.tldDistribution
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.data.forEach((item) => {
              if (item._id.includes(hostName)) {
                backlinksGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.doFollowData.forEach((item) => {
              if (item._id.includes(hostName)) {
                noFollowDataGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.noFollowData.forEach((item) => {
              if (item._id.includes(hostName)) {
                doFollowDataGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.referringDomains.forEach((item) => {
              if (item._id.includes(hostName)) {
                referringDomainsGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.backlinksStats.forEach((item) => {
              const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
              let hostName = splitedValue[splitedValue.length - 1]
              if (hostName.includes('www.')) {
                hostName = hostName.replace('www.', '')
              }

              if (item.hostName.includes(hostName)) {
                statsData = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.referringDomainURLArray.forEach((item) => {
              const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
              let hostName = splitedValue[splitedValue.length - 1]
              if (hostName.includes('www.')) {
                hostName = hostName.replace('www.', '')
              }

              if (item.hostName.includes(hostName)) {
                referringDomainURLs = item.urls
              }
            })

          return {
            ...element,
            backlinksData: backlinksData,
            referringDomains: referringDomains,
            anchorTexts,
            backlinksGraph,
            noFollowDataGraph,
            doFollowDataGraph,
            referringDomainsGraph,
            referringDomainURLs,
            tldDistributions,
            statsData,
          }
        })

      return {
        ...state,
        usersDomains: data,
        getBacklinksDataLoader: false,
      }
    }

    case GET_BACKLINKS_FAILURE: {
      return {
        ...state,
        getBacklinksDataLoader: false,
      }
    }

    case GET_BACKLINKS_STATS: {
      return {
        ...state,
      }
    }

    case GET_BACKLINKS_MOST_RECENTLY: {
      return {
        ...state,
        getBacklinksMostRecentlyLoader: true,
      }
    }

    case GET_BACKLINKS_MOST_RECENTLY_SUCCESS: {
      if (action.payload && action.payload.resp && action.payload.resp?.length) {
        let data =
          state.usersDomains &&
          state.usersDomains.length &&
          state.usersDomains.map((element) => {
            let mostRecentlyBacklinksData = {}

            const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
            let hostName = splitedValue[splitedValue.length - 1]
            if (hostName.includes('www.')) {
              hostName = hostName.replace('www.', '')
            }

            action.payload &&
              action.payload.resp &&
              action.payload.resp.forEach((item) => {
                if (item.url.includes(hostName)) {
                  mostRecentlyBacklinksData = item.mostRecentlyBacklinksData
                }
              })

            return {
              ...element,
              mostRecentlyBacklinksData: mostRecentlyBacklinksData,
            }
          })
        return {
          ...state,
          mostRecentlyBacklinksData: data,
          getBacklinksMostRecentlyLoader: false,
        }
      } else {
        return {
          ...state,
          getBacklinksMostRecentlyLoader: false,
        }
      }
    }

    case GET_BACKLINKS_MOST_RECENTLY_FAILURE: {
      return {
        ...state,
        getBacklinksMostRecentlyLoader: false,
      }
    }

    case CLEAR_GET_BACKLINKS_MOST_RECENTLY: {
      return {
        ...state,
        mostRecentlyBacklinksData: [],
        getBacklinksMostRecentlyLoader: false,
      }
    }

    case GET_BACKLINKS_MOST_IMPORTANT: {
      return {
        ...state,
        getBacklinksMostImportantLoader: true,
      }
    }

    case GET_BACKLINKS_MOST_IMPORTANT_SUCCESS: {
      if (action.payload && action.payload.resp && action.payload.resp?.length) {
        let data =
          state.usersDomains &&
          state.usersDomains.length &&
          state.usersDomains.map((element) => {
            let mostImpBacklinksData = {}

            const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
            let hostName = splitedValue[splitedValue.length - 1]
            if (hostName.includes('www.')) {
              hostName = hostName.replace('www.', '')
            }

            action.payload &&
              action.payload.resp &&
              action.payload.resp.forEach((item) => {
                if (item.url.includes(hostName)) {
                  mostImpBacklinksData = item.mostImpBacklinksData
                }
              })

            return {
              ...element,
              mostImpBacklinksData: mostImpBacklinksData,
            }
          })

        return {
          ...state,
          mostImpBacklinksData: data,
          getBacklinksMostImportantLoader: false,
        }
      } else {
        return {
          ...state,
          getBacklinksMostImportantLoader: false,
        }
      }
    }

    case GET_BACKLINKS_MOST_IMPORTANT_FAILURE: {
      return {
        ...state,
        getBacklinksMostImportantLoader: false,
      }
    }

    //Added for future use
    case GET_BACKLINKS_ANCHOR_TEXT: {
      return {
        ...state,
        getBacklinksAnchorTextLoader: true,
      }
    }

    case GET_BACKLINKS_ANCHOR_TEXT_SUCCESS: {
      let data =
        state.usersDomains &&
        state.usersDomains.length &&
        state.usersDomains.map((element) => {
          let backlinksAnchorTexts = {}
          const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
          let hostName = splitedValue[splitedValue.length - 1]
          if (hostName.includes('www.')) {
            hostName = hostName.replace('www.', '')
          }

          action.payload &&
            action.payload.resp &&
            action.payload.resp.forEach((item) => {
              if (item.url.includes(hostName)) {
                backlinksAnchorTexts = item.anchorTexts
              }
            })

          return {
            ...element,
            backlinksAnchorTexts: backlinksAnchorTexts,
          }
        })

      return {
        ...state,
        backlinksAnchorTexts: data,
        getBacklinksAnchorTextLoader: false,
      }
    }

    case GET_BACKLINKS_ANCHOR_TEXT_FAILURE: {
      return {
        ...state,
        getBacklinksAnchorTextLoader: false,
      }
    }

    case GET_BACKLINKS_REFERRING_DOMAIN: {
      return {
        ...state,
        getBacklinksReferringDomainLoader: true,
      }
    }

    case GET_BACKLINKS_REFERRING_DOMAIN_SUCCESS: {
      let data =
        state.usersDomains &&
        state.usersDomains.length &&
        state.usersDomains.map((element) => {
          let referringDomains = {}

          const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
          let hostName = splitedValue[splitedValue.length - 1]
          if (hostName.includes('www.')) {
            hostName = hostName.replace('www.', '')
          }

          action.payload &&
            action.payload.resp &&
            action.payload.resp.forEach((item) => {
              if (item.url.includes(hostName)) {
                referringDomains = item.referringDomains
              }
            })

          return {
            ...element,
            referringDomains: referringDomains,
          }
        })

      return {
        ...state,
        referringDomains: data,
        getBacklinksReferringDomainLoader: false,
      }
    }

    case GET_BACKLINKS_REFERRING_DOMAIN_FAILURE: {
      return {
        ...state,
        getBacklinksReferringDomainLoader: false,
      }
    }

    case GET_BACKLINKS_GRAPH: {
      return {
        ...state,
        backlinkGraphLoader: true,
        backlinkGraphError: false,
      }
    }

    case GET_BACKLINKS_GRAPH_SUCCESS: {
      let data =
        state.usersDomains &&
        state.usersDomains.length &&
        state.usersDomains.map((element) => {
          let backlinksGraph = []
          let noFollowDataGraph = []
          let doFollowDataGraph = []
          let referringDomainsGraph = []
          let statsData = state.usersDomains[state.activeLink]?.statsData || []

          const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
          let hostName = splitedValue[splitedValue.length - 1]
          if (hostName.includes('www.')) {
            hostName = hostName.replace('www.', '')
          }

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.data.forEach((item) => {
              if (item?._id?.includes(hostName)) {
                backlinksGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.doFollowData.forEach((item) => {
              if (item?._id?.includes(hostName)) {
                noFollowDataGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.noFollowData.forEach((item) => {
              if (item?._id?.includes(hostName)) {
                doFollowDataGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.referringDomains.forEach((item) => {
              if (item?._id?.includes(hostName)) {
                referringDomainsGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData.backlinksStats.forEach((item) => {
              const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
              let hostName = splitedValue[splitedValue.length - 1]
              if (hostName.includes('www.')) {
                hostName = hostName.replace('www.', '')
              }

              if (item?.hostName?.includes(hostName)) {
                statsData = item.data
              }
            })
          return {
            ...element,
            backlinksGraph,
            noFollowDataGraph,
            doFollowDataGraph,
            referringDomainsGraph,
            statsData:
              statsData.length > 0 ? statsData : element?.statsData?.length > 0 ? element.statsData : [],
          }
        })
      return {
        ...state,
        backlinkGraphData: data,
        backlinkGraphLoader: false,
        backlinkGraphError: false,
      }
    }

    case GET_BACKLINKS_GRAPH_FAILURE: {
      return {
        ...state,
        backlinkGraphLoader: false,
        backlinkGraphData: [],
        backlinkGraphError: true,
      }
    }

    case GET_BACKLINKS_STATS_SUCCESS: {
      let data =
        state.usersDomains &&
        state.usersDomains.length &&
        state.usersDomains.map((element) => {
          let backlinksGraph = []
          let noFollowDataGraph = []
          let doFollowDataGraph = []
          let referringDomainsGraph = []
          let statsData = state.usersDomains[state.activeLink]?.statsData || []
          let stats1Data = []
          let referringDomainURLs = []

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.data?.forEach((item) => {
              if (item._id === element.items[0]['url']) {
                backlinksGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.doFollowData?.forEach((item) => {
              if (item._id === element.items[0]['url']) {
                noFollowDataGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.noFollowData?.forEach((item) => {
              if (item._id === element.items[0]['url']) {
                doFollowDataGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.referringDomains?.forEach((item) => {
              if (item._id === element.items[0]['url']) {
                referringDomainsGraph = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.backlinksStats?.forEach((item) => {
              const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
              let hostName = splitedValue[splitedValue.length - 1]
              if (hostName.includes('www.')) {
                hostName = hostName.replace('www.', '')
              }

              if (item.hostName.includes(hostName)) {
                statsData = item.data
              }
            })
          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.backlinksStats?.forEach((item) => {
              const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
              let hostName = splitedValue[splitedValue.length - 1]
              if (hostName.includes('www.')) {
                hostName = hostName.replace('www.', '')
              }

              if (item.hostName.includes(hostName)) {
                stats1Data = item.data
              }
            })

          action.payload &&
            action.payload.graphData &&
            action.payload.graphData?.referringDomainURLArray?.forEach((item) => {
              const splitedValue = element.items[0]['url'].split('/').slice(0, 3)
              let hostName = splitedValue[splitedValue.length - 1]
              if (hostName.includes('www.')) {
                hostName = hostName.replace('www.', '')
              }

              if (item.hostName.includes(hostName)) {
                referringDomainURLs = item.urls
              }
            })
          return {
            ...element,
            backlinksGraph,
            noFollowDataGraph,
            doFollowDataGraph,
            referringDomainsGraph,
            statsData:
              statsData.length > 0 ? statsData : element?.statsData?.length > 0 ? element.statsData : [],
            stats1Data,
            referringDomainURLs,
          }
        })
      return {
        ...state,
        usersDomains: data,
        backLinksStatsData: action.payload,
      }
    }

    case GET_BACKLINKS_STATS_FAILURE: {
      return {
        ...state,
      }
    }

    case ADD_WEBSITE_FROM_TRIAL: {
      let urlInfo = JSON.parse(action.payload.urlInfo)

      let keywords = urlInfo.keywords
      var today = new Date()
      today.setMinutes(today.getMinutes() + 5)

      let newUrl = [
        {
          items: [
            {
              createdAt: today.toISOString(),
              hostName: action?.payload?.hostName,
              added: 'first',
              information: {
                seoCheckup: {},
              },
              timestamp: today.toISOString(),
              updatedAt: today.toISOString(),
              url: action.payload.url,
            },
          ],
        },
      ]
      let userDomainsTemp
      if (state.usersDomains && state.usersDomains.length) {
        userDomainsTemp = [...state.usersDomains, ...newUrl]
      } else {
        userDomainsTemp = newUrl
      }
      return {
        ...state,
        usersDomains: userDomainsTemp,
        lineChartDataForAutomatic: [],
        lineChartDataForAutomaticOriginal: [],
        tempDataforChart: [],
        tempDataforChartOriginal: [],
        activeKeywordData: [],

        localKeywords: keywords || [],
        success: false,
        error: false,
        backLinkLoading: true,
        reRenderLoading: true,
        activeLink: userDomainsTemp.length - 1,
      }
    }

    case BACKLINKS_CHECK_START: {
      const { usersDomains } = state
      let urlInfo = JSON.parse(action.payload.urlInfo)
      let keywords = urlInfo.keywords

      var today = new Date()
      today.setMinutes(today.getMinutes() + 5)

      let newUrl = [
        {
          items: [
            {
              createdAt: today.toISOString(),
              id: action.payload.url,
              added: 'first',
              information: {
                seoCheckup: {},
              },
              timestamp: today.toISOString(),
              updatedAt: today.toISOString(),
              url: action.payload.url,
            },
          ],
        },
      ]

      let userDomainsTemp = [ ...usersDomains, ...newUrl]

      return {
        ...state,
        usersDomains: userDomainsTemp,
        lineChartDataForAutomatic: [],
        lineChartDataForAutomaticOriginal: [],
        tempDataforChart: [],
        tempDataforChartOriginal: [],
        activeKeywordData: [],

        localKeywords: keywords || [],
        success: false,
        error: false,
        backLinkLoading: true,
        reRenderLoading: true,
        activeLink: userDomainsTemp.length - 1,
        seoProgressPercentage: 0,
      }
    }

    case BACKLINKS_CHECK_SUCCESS: {
      let xDomain = _.find(state.usersDomains, function (obj) {
        return obj && obj.items && obj.items[0].id === obj.items[0].url
      })
      let tempDomainArray = action.payload
      if (xDomain) {
        let matched =
          action.payload &&
          action.payload.length &&
          action.payload.filter((domain) => domain.items[0].url === xDomain.items[0].url)
        if (matched.length) {
          tempDomainArray = action.payload
          for (let i = 0; i < tempDomainArray?.length; i++) {
            if (tempDomainArray?.[i]?.items?.[0]?.url === xDomain.items[0].url) {
              tempDomainArray[i].items[0].added = 'first'
            }
          }
        } else {
          tempDomainArray = _.union([xDomain], [action.payload])
        }
      }

      let a = _.sortBy(tempDomainArray, (obj) => {
        return obj && obj.items && obj.items[0].createdAt
      })

      return {
        ...state,
        success: true,
        error: false,
        backLinkLoading: false,
        usersDomains: a,
      }
    }

    case BACKLINKS_CHECK_FAIL: {
      return {
        ...state,
        urlMessage: action.data.message || [],
        success: false,
        addUrlError: true,
        backLinkLoading: false,
        reRenderLoading: false,
        backlinksDataForPopup: action?.data,
      }
    }

    case GET_USER_DOMAINS:
      return {
        ...state,
        success: false,
        error: false,
        domainLoading: true,
      }

    case PATCH_USER_DOMAINS_SUCCESS: {
      const { userData: userDataForPatch } = state
      const { payload: payloadForPatch } = action

      let xDomain2 = _.find(state.usersDomains, function (obj) {
        return obj && obj.items && obj.items[0].id === obj.items[0].url
      })

      let tempDomainArray3 = [...payloadForPatch]

      let tempData = []
      tempDomainArray3?.forEach((item1) => {
        let filterData =
          state.usersDomains &&
          state.usersDomains.length &&
          state.usersDomains.filter((item2) => {
            return item1?.items?.[0]?.url === item2?.items?.[0]?.url
          })
        if (filterData && filterData.length) {
          tempData.push(filterData[0])
        }
      })

      let tempDomainArray2 = tempDomainArray3.map((item) => {
        let statsData = {}
        state.usersDomains &&
          state.usersDomains.length &&
          state.usersDomains.forEach((innerItem) => {
            if (innerItem._id === item._id) {
              statsData = innerItem.statsData
            }
          })
        return {
          ...item,
          statsData: statsData,
        }
      })

      if (xDomain2) {
        let matched =
        action.payload &&
        action.payload.length &&
        action.payload.filter((domain) => domain.items[0].url === xDomain2.items[0].url)
        if (matched && matched.length) {
          tempDomainArray2 = payloadForPatch
        } else {
          tempDomainArray2 = _.union([xDomain2], payloadForPatch)
        }
      }

      let aForPatch = _.sortBy(tempDomainArray2, (obj) => {
        return obj && obj.items && obj.items[0].createdAt
      })

      let bForPatch = aForPatch

      const activeLinkForPatch = state.activeLink > 0 ? state.activeLink : 0

      if (payloadForPatch?.length) {
        let url = (bForPatch[activeLinkForPatch] && bForPatch[activeLinkForPatch].items[0].url) || ''
        const splitedValue = url.split('/').slice(0, 3)

        let hostName = splitedValue[splitedValue.length - 1]
        if (hostName.includes('www.')) {
          hostName = hostName.replace('www.', '')
        }
        ///////
        ///////
        const temp1 =
          userDataForPatch &&
          userDataForPatch.searchResults &&
          userDataForPatch.searchResults.length &&
          _.findLast(
            userDataForPatch.searchResults,
            (item) => item && item.url && item.url.includes(hostName),
          )

        let localKeywords3 = (temp1 && temp1.keywords) || []

        let dataPreparation =
          bForPatch &&
          bForPatch[activeLinkForPatch] &&
          bForPatch[activeLinkForPatch].items &&
          bForPatch[activeLinkForPatch].items.length &&
          bForPatch[activeLinkForPatch].items[0] &&
          bForPatch[activeLinkForPatch].items[0].competitorsKeywords &&
          bForPatch[activeLinkForPatch].items[0]?.competitorsKeywords &&
          bForPatch[activeLinkForPatch].items[0]?.competitorsKeywords.length &&
          bForPatch[activeLinkForPatch].items[0]?.competitorsKeywords[0].data?.competitorsKeywords.map(
            (item) => {
              return {
                type: 'area',
                name: item.name,
                showInLegend: true,
                competitor: item.name,
                dataPoints: [],
                data: [],
                data1: [],
                data2: [],
                pointStart: null,
                axisX: {
                  valueFormatString: 'DD MMM',
                },
                xValueFormatString: 'DD MMM',
                yValueFormatString: '#,##0.##',
              }
            },
          )
        
        let chartDataForAntD = []
        const filteredData =
          state?.competitorSeoScoreData &&
          state?.competitorSeoScoreData?.length &&
          state?.competitorSeoScoreData.filter((item) => {
            return (
              item?.data?.[0]?.mainUrl ===
              state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url
            )
          })
      
        const result =
          filteredData &&
          filteredData?.length &&
          filteredData
            .filter((item) => {
              return item.data.some((obj) => {
                const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                  'MM-DD',
                )
                const lastUpdatedDate = moment.utc(state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.createdAt).format('MM-DD')
                return checkDate === lastUpdatedDate
              })
            })
            .map((item) => {
              return {
                id: item._id,
                data: item.data.filter((obj) => {
                  const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                    'MM-DD',
                  )
                  const lastUpdatedDate = moment.utc(state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.createdAt).format('MM-DD')

                   const latestUpdatedDate = moment.utc(state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.updatedAt).format('MM-DD') 

                  return checkDate === lastUpdatedDate || checkDate === latestUpdatedDate
                }),
              }
            })
        const isSumLessThan64 =
          result &&
          result?.length &&
          result.some((item) => {
            const sum = item.data.reduce((accumulator, subItem) => {
              return accumulator + subItem.count
            }, 0)

            return sum < 63
          })

        let competitorDataCollection = []

        state?.competitorSeoScoreData &&
          state?.competitorSeoScoreData?.length &&
          state?.competitorSeoScoreData.forEach((item) => {
            item.data &&
              item.data.forEach((dataItem) => {
                const mainUrl = dataItem?.mainUrl?.length &&
                dataItem?.mainUrl?.endsWith('/')
                ? dataItem?.mainUrl?.slice(0, -1) : dataItem?.mainUrl

                  const redirectUrl = state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url
                  const newRedirectUrl = redirectUrl?.length &&
                  redirectUrl?.endsWith('/')
                  ? redirectUrl?.slice(0, -1) : redirectUrl

                if (mainUrl === newRedirectUrl) {
                  let combineData = {
                    ...dataItem,
                    competitiorUrl: item._id,
                  }
                  competitorDataCollection.push(combineData)
                }
              })
          })
          
        competitorDataCollection = competitorDataCollection?.filter(item => {
          return temp1?.competitors?.includes(item?.competitiorUrl)})
          let dataForChartNew = []
          
        competitorDataCollection &&
          competitorDataCollection.length &&
          competitorDataCollection.forEach((compt) => {
            if (compt?.testResult) {
              var key = moment(`${compt?.year}-${compt?.month}-${compt?.day}`, 'YYYY-MM-DD').format('MM-DD-YYYY')
              let competitiorScore = Math.round((compt?.count / 73) * 100)
              if (key !== null) {
                dataForChartNew.push({
                  x: key,
                  competitor: compt.competitiorUrl,
                  y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
                })
              }
            }
          })
        
        state?.mainUrlScoreDataArray?.length &&
          state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
            let url1 = mainUrlSeoScore?._id?.length &&
            mainUrlSeoScore?._id?.endsWith('/')
            ? mainUrlSeoScore?._id?.slice(0, -1) : mainUrlSeoScore?._id

          let url2 = state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url?.length &&
            state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url?.endsWith('/')
            ? state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url?.slice(0, -1)
            : state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url

           if (url1 === url2) {
              mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
                if (innerMainUrlScore?.testResult) {
                  var key = moment(
                    `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                    'YYYY-MM-DD',
                  ).format('MM-DD-YYYY')

                  let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                  if (key !== null) {
                    if (state.seoProgressPercentage >= 100) {
                      dataForChartNew.push({
                        x: key,
                        mainUrl: true,
                        competitor:
                          state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information.seoCheckup
                            .redirect_url,
                        y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                      })
                    } else {
                      dataForChartNew.push({
                        x: key,
                        mainUrl: true,
                        competitor:
                          state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information.seoCheckup
                            .redirect_url,
                        y: 0,
                      })
                    }
                  }
                }
              })
            }
          })

        let dataForChartNew1 = _.sortBy(dataForChartNew, ['y'])
        dataPreparation &&
          dataPreparation.length &&
          dataPreparation.forEach((item, mainIndex) => {
            state?.competitorSeoScoreData?.length &&
              state?.competitorSeoScoreData.forEach((competitior) => {
                if (competitior?._id === item.name) {
                  competitior?.data.forEach((compt) => {
                    if (compt?.testResult) {
                      var key = moment(`${compt?.year}-${compt?.month}-${compt?.day}`, 'YYYY-MM-DD').format(
                        'MM-DD',
                      )
                      let competitiorScore = Math.round((compt?.count / 73) * 100)
                      if (key !== null) {
                        dataPreparation[mainIndex].id = item.name
                        if (!isSumLessThan64) {
                          dataPreparation[mainIndex].data.push({
                            x: key,
                            competitor: dataPreparation[mainIndex].competitor,
                            y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
                          })
                          chartDataForAntD.push({
                            x: key,
                            competitor: dataPreparation[mainIndex].competitor,
                            y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
                          })
                        } else {
                          dataPreparation[mainIndex].data.push({
                            x: key,
                            competitor: dataPreparation[mainIndex].competitor,
                            y: 0,
                          })
                          chartDataForAntD.push({
                            x: key,
                            competitor: dataPreparation[mainIndex].competitor,
                            y: 0,
                          })
                        }
                      }
                    }
                  })
                }
              })
            bForPatch &&
              bForPatch[activeLinkForPatch] &&
              bForPatch[activeLinkForPatch].items &&
              bForPatch[activeLinkForPatch].items.length &&
              bForPatch[activeLinkForPatch].items.forEach((innerItem, innerIndex) => {
                innerItem &&
                  innerItem.competitorsKeywords &&
                  innerItem.competitorsKeywords.length &&
                  innerItem.competitorsKeywords[0].data.competitorsKeywords.forEach((thirdItem) => {
                    if (thirdItem.name === item.name) {
                      if (innerIndex === bForPatch[activeLinkForPatch].items.length - 1) {
                        dataPreparation[mainIndex].id = item.name
                      }
                    }
                  })
                state?.mainUrlScoreDataArray?.length &&
                  state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
                    if (
                      mainUrlSeoScore?._id ===
                      state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup
                        ?.redirect_url
                    ) {
                      mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
                        if (innerMainUrlScore?.testResult) {
                          var key = moment(
                            `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                            'YYYY-MM-DD',
                          ).format('MM-DD')
                          let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                          if (key !== null) {
                            if (state.seoProgressPercentage >= 100) {
                              chartDataForAntD.push({
                                x: key,
                                competitor: state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.url,
                                y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                              })
                            } else {
                              chartDataForAntD.push({
                                x: key,
                                competitor: state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.url,
                                y: 0,
                              })
                            }
                          }
                        }
                      })
                    }
                  })

                dataPreparation[mainIndex].data = dataPreparation[mainIndex].data.reverse()
              })
          })
        if (chartDataForAntD.length === 0) {
          state?.mainUrlScoreDataArray?.length &&
            state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
              if (
                mainUrlSeoScore?._id ===
                state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.information?.seoCheckup?.redirect_url
              ) {
                mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
                  if (innerMainUrlScore?.testResult) {
                    var key = moment(
                      `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                      'YYYY-MM-DD',
                    ).format('MM-DD')
                    let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                    if (key !== null) {
                      if (state.seoProgressPercentage >= 100) {
                        chartDataForAntD.push({
                          x: key,
                          competitor: state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.url,
                          y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                        })
                      } else {
                        chartDataForAntD.push({
                          x: key,
                          competitor: state?.usersDomains?.[activeLinkForPatch]?.items?.[0]?.url,
                          y: 0,
                        })
                      }
                    }
                  }
                })
              }
            })
        }

        let aForPatch = _.sortBy(tempDomainArray3, (obj) => {
          return obj && obj.items && obj.items[0].createdAt
        })

        return {
          ...state,
          success: true,
          error: false,
          domainLoading: false,
          usersDomains: bForPatch?.length ? bForPatch : state.usersDomains,
          chartDataForAntD: dataForChartNew1,
          localKeywords: localKeywords3,
          backLinkLoading: false,
          tempDomainArrayForCompetitors: aForPatch,
          activeLink: state.activeLink > 0 ? state.activeLink : 0,
        }
      } else {
        return {
          ...state,
          success: true,
          error: false,
          domainLoading: false,
          activeLink: 0,
          usersDomains: state.usersDomains,
          localKeywords: [],
          backLinkLoading: false,
          tempDomainArrayForCompetitors: [],
        }
      }
    }

    
    case PATCH_USER_DOMAINS_DASHBOARD:
      return {
        ...state,
        success: false,
        error: false,
        domainLoading: false,
      }

    case GET_USER_DOMAINS_FAILURE:
    case PATCH_USER_DOMAINS_DASHBOARD_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        domainLoading: false,
        usersDomains: state.usersDomains && state.usersDomains.length ? state.usersDomains : {},
      }

    case GET_USER_DOMAINS_SUCCESS: {
      const { usersDomains: usersDomains } = state
      const { payload: payloadForPatch } = action

      let xDomain2 = _.find(state.usersDomains, function (obj) {
        return obj && obj.items && obj.items[0].id === obj.items[0].url
      })

      let tempDomainArray3 = action.payload

      let tempData = []
      tempDomainArray3 &&
        tempDomainArray3.length &&
        tempDomainArray3.forEach((item1) => {
          let filterData =
            state.usersDomains &&
            state.usersDomains.length &&
            state.usersDomains.filter((item2) => {
              return item1?.items?.[0]?.url === item2?.items?.[0]?.url
            })
          if (filterData && filterData.length) {
            tempData.push(filterData[0])
          }
        })
        
      
      let tempDomainArray2 = _.merge(tempDomainArray3, tempData)
  
      if (xDomain2) {
        let matched =
          action.payload &&
          action.payload.length &&
          action.payload.filter((domain) => domain.items[0].url === xDomain2.items[0].url)
        if (matched && matched.length) {
          tempDomainArray2 = action.payload
        } else {
          tempDomainArray2 = _.union([xDomain2], action.payload)
        }
      }
      let aForPatch = _.sortBy(tempDomainArray2, (obj) => {
        return obj && obj.items && obj.items[0].createdAt
      })

      let cForPatch = _.merge(aForPatch, usersDomains)
      if (payloadForPatch.length) {
        return {
          ...state,
          success: true,
          error: false,
          domainLoading: false,
          activeLink: state.activeLink > 0 ? state.activeLink : 0,
          usersDomains: cForPatch?.length ? cForPatch : state.usersDomains,
        }
      } else {
        return {
          ...state,
          success: true,
          error: false,
          domainLoading: false,
          activeLink: 0,
        }
      }
    }

    case CHANGE_ACTIVE_LINK: {
      const {
        userData: ud,
        originalUserData,
        usersDomains: domains,
        chartStartDate,
        chartEndDate,
        siteOutageData,
        tempDomainArrayForCompetitors,
        activeLink,
      } = state

      let url1 = domains[action.payload]?.items?.[0]?.url
      const splitedValue1 = url1.split('/').slice(0, 3)

      let filterData =
        siteOutageData &&
        siteOutageData.length &&
        siteOutageData.filter((item) => {
          var updatedUrl = url1 || ''
          if (updatedUrl.indexOf('https') == 0) {
            updatedUrl = updatedUrl.substring(8)
          } else if (updatedUrl.indexOf('http') == 0) {
            updatedUrl = updatedUrl.substring(7)
          } else {
          }
          return item.url === updatedUrl
        })

      let activeLinkForPatch = activeLink > -1 ? activeLink : 0
      let localCompetitors1ForDashboard = []

      let hostName2 = splitedValue1[splitedValue1.length - 1]
      if (hostName2.includes('www.')) {
        hostName2 = hostName2.replace('www.', '')
      }
      const temp3 =
        ud &&
        ud.searchResults &&
        ud.searchResults.length &&
        _.findLast(ud.searchResults, (item) => item && item.url && item.url.includes(hostName2))
      let localCompetitors1 = (temp3 && temp3.competitors) || []
      let localKeywords1 = (temp3 && temp3.keywords) || []

      localKeywords1 =
        uds &&
        uds[activeLinkForPatch] &&
        uds[activeLinkForPatch].items[0].id === uds[activeLinkForPatch].items[0].url
          ? state.localKeywords
          : localKeywords1
      localCompetitors1 =
        uds &&
        uds[activeLinkForPatch] &&
        uds[activeLinkForPatch].items[0].id === uds[activeLinkForPatch].items[0].url
          ? state.localCompetitors
          : localCompetitors1

      localCompetitors1ForDashboard = localCompetitors1.map((item) => {
        return {
          name: item,
          checked: true,
        }
      })
      let filterDomains =
        tempDomainArrayForCompetitors &&
        tempDomainArrayForCompetitors[action.payload] &&
        tempDomainArrayForCompetitors[action.payload].items &&
        tempDomainArrayForCompetitors[action.payload].items.length
          ? tempDomainArrayForCompetitors[action.payload].items
          : []
      if (chartStartDate && chartEndDate) {
        filterDomains =
          domains &&
          domains[action.payload] &&
          domains[action.payload].items &&
          domains[action.payload].items.length &&
          domains[action.payload].items.filter((item) => {
            return moment(item.createdAt).isBetween(chartStartDate, chartEndDate, undefined, [])
          })
      }

      let dataPreparationChangeLink =
        filterDomains &&
        filterDomains.length &&
        filterDomains[0] &&
        filterDomains[0].competitorsKeywords &&
        filterDomains[0].competitorsKeywords.length &&
        filterDomains[0].competitorsKeywords[0].data &&
        filterDomains[0].competitorsKeywords[0].data.competitorsKeywords &&
        filterDomains[0].competitorsKeywords[0].data.competitorsKeywords.map((item) => {
          return {
            type: 'area',
            name: item.name,
            showInLegend: true,
            competitor: item.name,
            dataPoints: [],
            data: [],
            data1: [],
            data2: [],
            xValueFormatString: 'DD MMM',
            yValueFormatString: '#,##0.##',
          }
        })
      let min = 100,
        max = 0
      let chartDataForAntD = []

      const filteredData =
        state?.competitorSeoScoreData &&
        state?.competitorSeoScoreData?.length &&
        state?.competitorSeoScoreData.filter((item) => {
          return (
            item?.data?.[0]?.mainUrl ===
            domains?.[action.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url
          )
        })
          
      const result =
        filteredData &&
        filteredData?.length &&
        filteredData
          .filter((item) => {
            return item.data.some((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment.utc(domains?.[action.payload]?.items?.[0]?.createdAt).format('MM-DD')

              const latestUpdatedDate = moment.utc(domains?.[action.payload]?.items?.[0]?.createdAt).format('MM-DD')

              return checkDate === lastUpdatedDate || checkDate === latestUpdatedDate
            })
          })
          .map((item) => {
            return {
              id: item._id,
              data: item.data.filter((obj) => {
                const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format(
                  'MM-DD',
                )
                const lastUpdatedDate = moment.utc(domains?.[action.payload]?.items?.[0]?.createdAt).format('MM-DD')

                const latestUpdatedDate = moment.utc(domains?.[action.payload]?.items?.[0]?.updatedAt).format('MM-DD')
                return checkDate === lastUpdatedDate || checkDate === latestUpdatedDate
              }),
            }
          })

      const isSumLessThan64 =
        result &&
        result?.length &&
        result.some((item) => {
          const sum = item.data.reduce((accumulator, subItem) => {
            return accumulator + subItem.count
          }, 0)
          return sum < 63
        })

      ////
      let competitorDataCollection = []
      state?.competitorSeoScoreData &&
        state?.competitorSeoScoreData?.length &&
        state?.competitorSeoScoreData.forEach((item) => {
          item.data &&
            item.data.forEach((dataItem) => {

              const mainUrl = dataItem?.mainUrl?.length &&
                    dataItem?.mainUrl?.endsWith('/')
                  ? dataItem?.mainUrl?.slice(0, -1) : dataItem?.mainUrl

                  const redirectUrl = domains?.[action.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url
                  const newRedirectUrl = redirectUrl?.length &&
                  redirectUrl?.endsWith('/')
                  ? redirectUrl?.slice(0, -1) : redirectUrl

              if (mainUrl === newRedirectUrl) {
                let combineData = {
                  ...dataItem,
                  competitiorUrl: item._id,
                }
                competitorDataCollection.push(combineData)
              }
            })
        })

      competitorDataCollection = competitorDataCollection?.filter(item => {return temp3?.competitors?.includes(item?.competitiorUrl)})  
      
      let dataForChartNew = []

      competitorDataCollection &&
        competitorDataCollection.length &&
        competitorDataCollection.forEach((compt) => {
          if (compt?.testResult) {
            var key = moment(`${compt?.year}-${compt?.month}-${compt?.day}`, 'YYYY-MM-DD').format('MM-DD-YYYY')
            let competitiorScore = Math.round((compt?.count / 73) * 100)
            if (key !== null) {
              dataForChartNew.push({
                x: key,
                competitor: compt.competitiorUrl,
                y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
              })
            }
          }
        })
       state?.mainUrlScoreDataArray?.length &&
        state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
          let url1 = mainUrlSeoScore?._id?.length && 
          mainUrlSeoScore?._id?.endsWith('/') 
          ? mainUrlSeoScore?._id?.slice(0, -1) : mainUrlSeoScore?._id

        let url2 = domains?.[action?.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url?.length && 
          domains?.[action?.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url?.endsWith('/') 
          ? domains?.[action?.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url?.slice(0, -1) 
          : domains?.[action?.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url

          if (url1 === url2) {
            mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
              if (innerMainUrlScore?.testResult) {
                var key = moment(
                  `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                  'YYYY-MM-DD',
                  ).format('MM-DD-YYYY')
                
                let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                if (key !== null) {
                  if (state.seoProgressPercentage >= 100) {
                    dataForChartNew.push({
                      x: key,
                      mainUrl: true,
                      competitor:
                        domains?.[action.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url,
                      y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                    })
                  } else {
                    dataForChartNew.push({
                      x: key,
                      mainUrl: true,
                      competitor:
                        domains?.[action.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url,
                      y: 0,
                    })
                  }
                }
              }
            })
          }
        })
      let dataForChartNew1 = _.sortBy(dataForChartNew, ['y'])
      /////

      dataPreparationChangeLink &&
        dataPreparationChangeLink.length &&
        dataPreparationChangeLink.forEach((item, mainIndex) => {
          state?.competitorSeoScoreData?.length &&
            state?.competitorSeoScoreData.forEach((competitior) => {
              if (competitior?._id === item.name) {
                competitior?.data.forEach((compt) => {
                  if (compt?.testResult) {
                    var key = moment(`${compt?.year}-${compt?.month}-${compt?.day}`, 'YYYY-MM-DD').format(
                      'MM-DD',
                    )

                    let competitiorScore = Math.round((compt?.count / 73) * 100)
                    if (key !== null) {
                      dataPreparationChangeLink[mainIndex].id = item.name

                      if (!isSumLessThan64 && state.seoProgressPercentage >= 100) {
                        dataPreparationChangeLink[mainIndex].data.push({
                          x: key,
                          competitor: dataPreparationChangeLink[mainIndex].competitor,
                          y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
                        })
                        chartDataForAntD.push({
                          x: key,
                          competitor: dataPreparationChangeLink[mainIndex].competitor,
                          y: (competitiorScore && parseInt(competitiorScore.toFixed(0))) || 0,
                        })
                      } else {
                        dataPreparationChangeLink[mainIndex].data.push({
                          x: key,
                          competitor: dataPreparationChangeLink[mainIndex].competitor,
                          y: 0,
                        })
                        chartDataForAntD.push({
                          x: key,
                          competitor: dataPreparationChangeLink[mainIndex].competitor,
                          y: 0,
                        })
                      }
                    }
                  }
                })
              }
            })

          state?.mainUrlScoreDataArray?.length &&
            state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
              if (
                mainUrlSeoScore?._id ===
                domains?.[action.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url
              ) {
                mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
                  if (innerMainUrlScore?.testResult) {
                    var key = moment(
                      `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                      'YYYY-MM-DD',
                    ).format('MM-DD')
                   
                    let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                    if (key !== null) {
                   
                      if (state.seoProgressPercentage >= 100) {
                        chartDataForAntD.push({
                          x: key,
                          competitor: domains?.[action.payload]?.items?.[0]?.url,
                          y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                        })
                      } else {
                        chartDataForAntD.push({
                          x: key,
                          competitor: domains?.[action.payload]?.items?.[0]?.url,
                          y: 0,
                        })
                      }
                    }
                  }
                })
              }
            })

          tempDomainArrayForCompetitors &&
            tempDomainArrayForCompetitors[action.payload] &&
            tempDomainArrayForCompetitors[action.payload].items &&
            tempDomainArrayForCompetitors[action.payload].items.length &&
            tempDomainArrayForCompetitors[action.payload].items.forEach((innerItem, innerIndex) => {
              innerItem &&
                innerItem.competitorsKeywords &&
                innerItem.competitorsKeywords.length &&
                innerItem.competitorsKeywords[0].data &&
                innerItem.competitorsKeywords[0].data.competitorsKeywords.forEach((thirdItem) => {
                  if (thirdItem.name === item.name) {
                    if (innerIndex === domains[0].items.length - 1) {
                      dataPreparationChangeLink[mainIndex].id = item.name
                    }
                  }
                })
            })

          dataPreparationChangeLink[mainIndex].data = dataPreparationChangeLink[mainIndex].data.reverse()
        })

      if (chartDataForAntD.length === 0) {
        state?.mainUrlScoreDataArray?.length &&
          state?.mainUrlScoreDataArray?.forEach((mainUrlSeoScore) => {
            if (
              mainUrlSeoScore?._id ===
              domains?.[action.payload]?.items?.[0]?.information?.seoCheckup?.redirect_url
            ) {
              mainUrlSeoScore?.data.forEach((innerMainUrlScore) => {
                if (innerMainUrlScore?.testResult) {
                  var key = moment(
                    `${innerMainUrlScore?.year}-${innerMainUrlScore?.month}-${innerMainUrlScore?.day}`,
                    'YYYY-MM-DD',
                  ).format('MM-DD')
                  
                  let mainSeoScore = Math.round((innerMainUrlScore?.count / 73) * 100)
                  if (key !== null) {
                    if (state.seoProgressPercentage >= 100) {
                      chartDataForAntD.push({
                        x: key,
                        competitor: domains?.[action.payload]?.items?.[0]?.url,
                        y: (mainSeoScore && parseInt(mainSeoScore.toFixed(0))) || 0,
                      })
                    } else {
                      chartDataForAntD.push({
                        x: key,
                        competitor: domains?.[action.payload]?.items?.[0]?.url,
                        y: 0,
                      })
                    }
                  }
                }
              })
            }
          })
      }

      ////////////
      let keywordFilteredData
      if (originalUserData && originalUserData.keywordsData && originalUserData.keywordsData.length) {
        let updatedKeywordData = originalUserData.keywordsData.map((item) => {
          let temp = moment(item.updatedAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD MM YYYY')
          return {
            ...item,
            date: temp,
          }
        })
        let keywordsData = _.chain(updatedKeywordData)
          .groupBy('url')
          .map((value, key) => ({ url: key, values: value }))
          .value()

        let keywordsDayWiseData = keywordsData.map((item) => {
          let a = _.chain(item.values)
            .groupBy('date')
            .map((value, key) => ({ date: key, dataset: value }))
            .value()
          return { url: item.url, data: a }
        })

        keywordFilteredData =
          keywordsDayWiseData &&
          keywordsDayWiseData.length &&
          keywordsDayWiseData.filter(
            (item) =>
              item.url.includes(url1) ||
              (temp3 && temp3.url && item && item.url && item.url.includes(temp3.url)),
          )
      }

      /////////////

      return {
        ...state,
        min: min,
        max: max,
        activeLink: action.payload,
        seoProgressPercentage: 0,
        seoCompetitorPercentage: 0,
        activeKeywordData:
          (keywordFilteredData && keywordFilteredData.length && keywordFilteredData[0]) || [],
        dataForAirbnb: [],
        lineChartDataForAutomatic: dataPreparationChangeLink,
        lineChartDataForAutomaticOriginal: dataPreparationChangeLink,
        localCompetitors: localCompetitors1ForDashboard,
        localCompetitorsForDashboard:
          localCompetitors1ForDashboard && localCompetitors1ForDashboard.length
            ? localCompetitors1ForDashboard
            : [],
        localKeywords: localKeywords1,
        chartDataForAntD: dataForChartNew1,
        activeSiteOutageData: filterData && filterData.length && filterData[0].data && filterData[0].data,
        tempDataforChart: [],
        tempDataforChartOriginal: [],
        stopPatchApiCall: false,
        stopBacklinksStatsApiCall: false,
      }
    }

    case REMOVE_COMPETITORS: {
      return {
        ...state,
        removeCompetitorsLoader: true,
      }
    }

    case REMOVE_COMPETITORS_SUCCESS_1: {
      return {
        ...state,
        backlinksDataForPopup: {},
        removeCompetitorsLoader: false,
      }
    }

    case REMOVE_COMPETITORS_FAILURE: {
      return {
        ...state,
        removeCompetitorsLoader: false,
      }
    }

    case SPEED_GRAPH_CHART_DATA_REQUEST: {
      return {
        ...state,
        speedGraphLoader: true,
      }
    }

    case SPEED_GRAPH_CHART_DATA_SUCCESS:
      return {
        ...state,
        speedGraphSuccess: true,
        dashboardGraphData: action?.payload,
        speedGraphLoader: false,
      }

    case SPEED_GRAPH_CHART_DATA_FAILURE: {
      return {
        ...state,
        speedGraphLoader: false,
        speedGraphFailure: true,
        speedGraphError: action?.error
      }
    }  

    case CLEAR_SPEED_GRAPH_CHART_DATA: {
      return {
        ...state,
        speedGraphSuccess: false,
        speedGraphLoader: false,
        speedGraphFailure: false,
        speedGraphError: {}
      }
    }

    case DELETE_WEBSITE:
      return {
        ...state,
        error: false,
        loading: true,
        triggerSuccess: false,
        domainLoading: true,
        websiteDeleteLoader: true,
      }

    case DELETE_WEBSITE_SUCCESS:
      
      const {userData} = state
      const data = localStorage.getItem('userData')
      let updatedUserDataForLocalStorage
      if(!action?.payload?.length){
        updatedUserDataForLocalStorage = {
          ...userData,
          token: JSON.parse(data)?.token,
          websites:[],
          searchResults:[]
        }
        localStorage.removeItem("userData")
        localStorage.setItem("userData", JSON.stringify(updatedUserDataForLocalStorage))
      }
      let resDomains = _.sortBy(action.payload, (obj) => {
        return obj && obj.items && obj.items[0].createdAt
      })

      return {
        ...state,
        error: false,
        triggerSuccess: true,
        usersDomains: resDomains,
        activeLink: 0,
        domainLoading: false,
        backLinkLoading: false,
        reRenderLoading: false,

        commonLoading: false,
        competitorKeywordsLoading: false,
        cloudLoading: false,
        robotsLoading: false,
        competitorsTestLoading: false,
        relatedKeywordsLoading: false,

        headingAnalysisLoading: false,
        seoFriendlyUrlLoading: false,
        inLineCssLoading: false,
        htmlValidationLoading: false,
        cssAnalysisLoading: false,
        googleAnalyticsLoading: false,

        websiteDeleteSuccess: true,
        websiteDeleteLoader: false,
        loading: false,
      }

    case DELETE_WEBSITE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        triggerSuccess: false,
        domainLoading: false,

        websiteDeleteFailure: true,
      }

    case CLEAR_DELETE_WEBSITE:
      return {
        ...state,
        websiteDeleteSuccess: false,
        websiteDeleteFailure: false,
        websiteDeleteLoader: false,
        clearDeleteWebsite: true,
      }

    case GET_USER_DATA:
      return {
        ...state,
        //userData: {},
      }

    case GET_USER_DATA_SUCCESS:
      const { usersDomains: uds } = state
      const userData1 = action?.data?.searchData

      const activeLink1 = state.activeLink > 0 ? state.activeLink : 0

      if (uds && uds.length) {
        let urlData = uds[activeLink1]?.items?.[0]?.url
        const splitedValues = urlData?.split('/').slice(0, 3)
        let competitorsData = []
        let keywordFilteredData = []
        let keywordsData = []
        let urlHostName = splitedValues[splitedValues?.length - 1]
        if (urlHostName.includes('www.')) {
          urlHostName = urlHostName.replace('www.', '')
        }
        const temp2 =
          userData1 &&
          userData1.searchResults &&
          userData1.searchResults.length &&
          _.findLast(userData1.searchResults, (item) => item && item.url && item.url.includes(urlHostName))
        let localCompetitorsArray = (temp2 && temp2.competitors) || []
        let localKeywordsArray = (temp2 && temp2.keywords) || []

        localKeywordsArray =
          uds && uds[activeLink1] && uds[activeLink1].items[0].id === uds[activeLink1].items[0].url
            ? state.localKeywords
            : localKeywordsArray
        localCompetitorsArray =
          uds && uds[activeLink1] && uds[activeLink1].items[0].id === uds[activeLink1].items[0].url
            ? state.localCompetitors
            : localCompetitorsArray

        localCompetitorsArray = localCompetitorsArray.map((item) => {
          return {
            name: item,
            checked: false,
          }
        })

        if (action.data.competitorsData && action.data.competitorsData.length) {
          competitorsData = _.chain(action.data.competitorData)
            .groupBy('url')
            .map((value, key) => ({ url: key, values: value }))
            .value()
        }

        if (action.data.keywordsData && action.data.keywordsData.length) {
          let updatedKeywordData = action.data.keywordsData.map((item) => {
            let temp = moment(item.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD MM YYYY')
            return {
              ...item,
              date: temp,
            }
          })
          keywordsData = _.chain(updatedKeywordData)
            .groupBy('url')
            .map((value, key) => ({ url: key, values: value }))
            .value()

          let keywordsDayWiseData = keywordsData.map((item) => {
            let a = _.chain(item.values)
              .groupBy('date')
              .map((value, key) => ({ date: key, dataset: value }))
              .value()
            return { url: item.url, data: a }
          })

          keywordFilteredData =
            keywordsDayWiseData &&
            keywordsDayWiseData.length &&
            keywordsDayWiseData.filter(
              (item) =>
                (item && item.url && item.url === urlData) ||
                (temp2 && temp2.url && item && item.url && item.url === temp2?.url),
            )
        }
        return {
          ...state,
          userData: action.data.searchData,
          originalUserData: action.data,
          keywordsData: keywordsData || [],
          activeKeywordData:
            (keywordFilteredData && keywordFilteredData.length && keywordFilteredData[0]) || [],
          competitorsData: competitorsData || [],
          localCompetitors: localCompetitorsArray || [],
          localKeywords: localKeywordsArray || [],
        }
      } else {
        let competitorsData = []
        let keywordsData = []
        let competitorsDayWiseData = []
        let uniqDates = []
        let keywordsDayWiseData = []
        if (action.data.competitorsData && action.data.competitorsData.length) {
          let updatedData = action.data.keywordsData.map((item) => {
            let temp = moment(item.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD MM YYYY')
            return {
              ...item,
              x: moment(temp, 'DD MM YYYY').toDate(),
              y: item.score,
            }
          })
          competitorsData = _.chain(updatedData)
            .groupBy('url')
            .map((value, key) => ({ url: key, values: value }))
            .value()
          competitorsDayWiseData = competitorsData.map((item) => {
            let a = _.chain(item.values)
              .groupBy('competitor')
              .map((value, key) => ({
                type: 'area',
                name: key,
                showInLegend: true,
                competitor: key,
                dataPoints: value,
                xValueFormatString: 'DD MMM YYYY',
                yValueFormatString: '#,##0.##',
              }))
              .value()
            return { url: item.url, data: a }
          })
        }

        if (action.data.keywordsData && action.data.keywordsData.length) {
          let updatedKeywordData = action.data.competitorsData.map((item) => {
            let temp = moment(item.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD MM YYYY')
            return {
              ...item,
              date: temp,
            }
          })
          keywordsData = _.chain(updatedKeywordData)
            .groupBy('url')
            .map((value, key) => ({ url: key, values: value }))
            .value()

          keywordsDayWiseData = keywordsData.map((item) => {
            let a = _.chain(item.values)
              .groupBy('date')
              .map((value, key) => ({ date: key, dataset: value }))
              .value()
            return { url: item.url, data: a }
          })
        }

        return {
          ...state,
          userData: action.data.searchData,
          originalUserData: action.data,
          lineChartData: competitorsDayWiseData,
          uniqDates,
          keywordsData: keywordsData || [],
          activeKeywordData: keywordsDayWiseData[0] || [],

          competitorsData: competitorsData || [],
        }
      }
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        userData: {},
      }

    case TOGGLE_COMPETITORS: {
      const { localCompetitors } = state
      let { exceed: limit } = action.data

      const temp = localCompetitors.map((item) => {
        if (item.name === action.data.item.name) {
          return {
            ...item,
            checked: !item.checked,
          }
        } else {
          if (item.checked && limit) {
            limit = false
            return {
              ...item,
              checked: false,
            }
          } else {
            return {
              ...item,
            }
          }
        }
      })

      return {
        ...state,
        localCompetitors: temp,
      }
    }

    case REMOVE_KEYWORD:
      return {
        ...state,
        removeLoading: true,
        keywordToRemove: action.data.keyword,
        keywordRemoveLoader: true,
      }

    case REMOVE_KEYWORD_SUCCESS:
      const { usersDomains: domainsArray1, activeLink: currentActive1, keywordToRemove } = state
      let newCustomEntered = []
      let newRelatedFinalKeywords = []
      if (
        domainsArray1[currentActive1].items[0].information.seoCheckup.customEnteredKeywords &&
        domainsArray1[currentActive1].items[0].information.seoCheckup.customEnteredKeywords.length
      ) {
        newCustomEntered = _.remove(
          domainsArray1[currentActive1].items[0].information.seoCheckup.customEnteredKeywords,
          (item) => {
            if (item.word === keywordToRemove) {
              return false
            } else {
              return true
            }
          },
        )
      }

      if (
        domainsArray1[currentActive1].items[0].information.seoCheckup.relatedKeywordsFinal &&
        domainsArray1[currentActive1].items[0].information.seoCheckup.relatedKeywordsFinal.length
      ) {
        newRelatedFinalKeywords = _.remove(
          domainsArray1[currentActive1].items[0].information.seoCheckup.relatedKeywordsFinal,
          (item) => {
            if (item.word === keywordToRemove) {
              return false
            } else {
              return true
            }
          },
        )
      }

      domainsArray1[currentActive1].items[0].information.seoCheckup.relatedKeywordsFinal =
        newRelatedFinalKeywords
      domainsArray1[currentActive1].items[0].information.seoCheckup.customEnteredKeywords = newCustomEntered

      let urlRemoveKeywords = domainsArray1[currentActive1].items[0].url
      const splitedValueRemoveKeywords = urlRemoveKeywords.split('/').slice(0, 3)

      let hostNameRemoveKeywords = splitedValueRemoveKeywords[splitedValueRemoveKeywords.length - 1]
      if (hostNameRemoveKeywords.includes('www.')) {
        hostNameRemoveKeywords = hostNameRemoveKeywords.replace('www.', '')
      }
      const tempAddCompetitorsListRemoveKeywords =
        action.data &&
        action.data.searchResults &&
        action.data.searchResults.length &&
        _.findLast(
          action.data.searchResults,
          (item) => item && item.url && item.url.includes(hostNameRemoveKeywords),
        )

      let localKeywordsRemoveKeywords =
        (tempAddCompetitorsListRemoveKeywords && tempAddCompetitorsListRemoveKeywords.keywords) || []
      return {
        ...state,
        usersDomains: domainsArray1,
        changed: !state.changed,
        keywordToRemove: '',
        removedKeywords: !state.removedKeywords,
        removeLoading: false,
        userData: action.data,
        localKeywords: localKeywordsRemoveKeywords,
        keywordRemoveSuccess: true,
        keywordRemoveLoader: false,
      }

    case CLEAR_REMOVE_KEYWORD: {
      return {
        ...state,
        keywordRemoveSuccess: false,
        keywordRemoveLoader: false,
        removeLoading: false
      }
    }

    case REMOVE_KEYWORD_FAILURE:
      return {
        ...state,
        removeLoading: false,
        keywordToRemove: '',
      }

    case HEADING_ANALYSIS_TEST:
      return {
        ...state,
        headingAnalysisLoading: true,
      }
    case HEADING_ANALYSIS_TEST_SUCCESS:
      const { usersDomains: domainsArrayHeading, activeLink: currentActiveHeading } = state
      domainsArrayHeading[currentActiveHeading].items[0].information.seoCheckup.headingAnalysis = action.data
      return {
        ...state,
        usersDomains: domainsArrayHeading,
        changed: !state.changed,
      }
    case HEADING_ANALYSIS_TEST_FAILURE:
      return {
        ...state,
        headingAnalysisLoading: false,
      }
    case IMG_ALT_TEST:
      const { usersDomains: domainsRecheckImgAlt, activeLink: currentActiveRecheckImgAlt } = state
      domainsRecheckImgAlt[currentActiveRecheckImgAlt].items[0].information.seoCheckup.imgAltLoading = true
      return {
        ...state,
        usersDomains: domainsRecheckImgAlt,
        changed: !state.changed,
      }
    case IMG_ALT_TEST_SUCCESS:
      return {
        ...state,
      }
    case IMG_ALT_TEST_FAILURE:
      const { usersDomains: domainsRecheckImgAltF, activeLink: currentActiveRecheckImgAltF } = state
      domainsRecheckImgAltF[currentActiveRecheckImgAltF].items[0].information.seoCheckup.imgAltLoading = false
      return {
        ...state,
        usersDomains: domainsRecheckImgAltF,
        changed: !state.changed,
      }
    case BROKEN_LINKS_TEST:
      const { usersDomains: domainsRecheckBrokenLink, activeLink: currentActiveRecheckBrokenLink } = state
      domainsRecheckBrokenLink[
        currentActiveRecheckBrokenLink
      ].items[0].information.seoCheckup.brokenLinksLoading = true
      return {
        ...state,
        //brokenLinksLoading: true,
        usersDomains: domainsRecheckBrokenLink,
        changed: !state.changed,
      }
    case BROKEN_LINKS_TEST_SUCCESS:
      return {
        ...state,
      }
    case BROKEN_LINKS_TEST_FAILURE:
      const { usersDomains: domainsRecheckBrokenLinkF, activeLink: currentActiveRecheckBrokenLinkF } = state
      domainsRecheckBrokenLinkF[
        currentActiveRecheckBrokenLinkF
      ].items[0].information.seoCheckup.brokenLinksLoading = false
      return {
        ...state,
        // brokenLinksLoading: false,
        usersDomains: domainsRecheckBrokenLinkF,
        changed: !state.changed,
      }

    case SEO_FRIENDLY_URL:
      return {
        ...state,
        seoFriendlyUrlLoading: true,
      }
    case SEO_FRIENDLY_URL_SUCCESS:
      // const { usersDomains: domainsArraySeoFriendlyUrl, activeLink: currentActiveSeoFriendlyUrl } = state;
      // domainsArraySeoFriendlyUrl[currentActiveSeoFriendlyUrl].items[0].information.seoCheckup.seoFriendlyUrlAnalysis = action.data;
      return {
        ...state,
        // usersDomains: domainsArraySeoFriendlyUrl,
        // changed: !state.changed,
      }
    case SEO_FRIENDLY_URL_FAILURE:
      return {
        ...state,
        seoFriendlyUrlLoading: false,
      }

    case REMOVE_COMPETITORS_SETTINGS:
      return {
        ...state,
        removeCompetitorLoader: true,
        removeCompetitorSuccess: false
      }
    case REMOVE_COMPETITORS_SETTINGS_SUCCESS:
      const { usersDomains: domainsArray16, activeLink: currentActive16 } = state

      let url16 = domainsArray16[currentActive16].items[0].url
      const splitedValue16 = url16.split('/').slice(0, 3)

      let hostName16 = splitedValue16[splitedValue16.length - 1]
      if (hostName16.includes('www.')) {
        hostName16 = hostName16.replace('www.', '')
      }
      let competitorThreshold16
      const tempAddCompetitorsList1 =
        action.data &&
        action.data.searchResults &&
        action.data.searchResults.length &&
        _.findLast(action.data.searchResults, (item) => item && item.url && item.url.includes(hostName16))
      let localCompetitors16 = (tempAddCompetitorsList1 && tempAddCompetitorsList1.competitors) || []
      let localKeywords16 = (tempAddCompetitorsList1 && tempAddCompetitorsList1.keywords) || []
      if (action?.data && action?.data?.planData) {
        if (action?.data?.planData?.name === PLAN.PRO_PLAN.name) {
          competitorThreshold16 = 3
        } else if (action?.data?.planData?.name === PLAN.BASIC_PLAN.name) {
          competitorThreshold16 = 1
        } else if (action?.data?.planData?.name === PLAN.PREMIUM_PLAN.name) {
          competitorThreshold16 = 5
        }
      }
      // const splitedCompetitors16 = domainsArray16[currentActive16].items[0].information.seoCheckup.competitorsKeywords.slice(0, competitorThreshold16);
      // localCompetitors16 = localCompetitors16.map((item) => {
      //   let matched = splitedCompetitors16.filter((com) => com.name.toLowerCase().includes(item.toLowerCase()));
      //   return {
      //     name: item,
      //     checked: matched.length > 0 ? true : false,
      //   }
      // })
      // const splitedCompetitors16 = domainsArray16[currentActive16]?.items?.[0].competitorsKeywords && domainsArray16[currentActive16].items[0].competitorsKeywords.length && domainsArray16[currentActive16].items[0].competitorsKeywords[0] && domainsArray16[currentActive16].items[0].competitorsKeywords[0].data.competitorsKeywords && domainsArray16[currentActive16].items[0].competitorsKeywords[0].data.competitorsKeywords.slice(0, competitorThreshold16);
      localCompetitors16 =
        localCompetitors16 &&
        localCompetitors16.length &&
        localCompetitors16.map((item) => {
          // let matched = splitedCompetitors16 && splitedCompetitors16.length && splitedCompetitors16.filter((com) => com.name.toLowerCase().includes(item.toLowerCase()));
          return {
            name: item,
            checked: true,
          }
        })
      return {
        ...state,
        removeCompetitorLoader: false,
        userData: action.data,
        localCompetitors: localCompetitors16,
        localKeywords: localKeywords16,
        changed: !state.changed,
        removeCompetitorSuccess: true
      }
    case REMOVE_COMPETITORS_SETTINGS_FAILURE:
      return {
        ...state,
        removeCompetitorLoader: false,
        removeCompetitorSuccess: false
      }
    case CLEAR_REMOVE_COMPETITORS_SETTINGS: 
      return{
        ...state,
        removeCompetitorLoader: false,
        removeCompetitorSuccess: false
      }  
    case ADD_KEYWORDS_SETTINGS:
      return {
        ...state,
        settingsAddKeywordLoader: true,
        settingsAddKeywordSuccess: false,
      }
    case ADD_KEYWORDS_SETTINGS_SUCCESS:
      const { usersDomains: domainsArrayAddKeywords, activeLink: currentActiveAddKeywords } = state

      let urlAddKeywords = domainsArrayAddKeywords[currentActiveAddKeywords].items[0].url
      const splitedValueAddKeywords = urlAddKeywords.split('/').slice(0, 3)

      let hostNameAddKeywords = splitedValueAddKeywords[splitedValueAddKeywords.length - 1]
      if (hostNameAddKeywords.includes('www.')) {
        hostNameAddKeywords = hostNameAddKeywords.replace('www.', '')
      }
      const tempUserDataAddKeywords =
        action.data &&
        action.data.searchResults &&
        action.data.searchResults.length &&
        _.findLast(
          action.data.searchResults,
          (item) => item && item.url && item.url.includes(hostNameAddKeywords),
        )

      let localKeywordsAddKeywords = (tempUserDataAddKeywords && tempUserDataAddKeywords.keywords) || []

      // const updatedDataOfKeywords = state.keywordResearchData && state.keywordResearchData.length && state.keywordResearchData?.map( (item, index) => {
      //       if(item.loading === true) {
      //         return {
      //           ...item,
      //           loading: false,
      //         }
      //       } else {
      //         return item
      //       }
      // })

      const updatedDataOfKeywords =
        state.keywordResearchData &&
        state.keywordResearchData.length &&
        state.keywordResearchData?.map((item) => ({
          ...item,
          data: item.data.map((keywordData) => ({
            ...keywordData,
            keywordsData: {
              ...keywordData.keywordsData,
              ...(keywordData.keywordsData.loading === true ? { loading: false } : {}),
            },
          })),
        }))

      return {
        ...state,
        settingsAddKeywordLoader: false,
        userData: action.data,
        localKeywords: localKeywordsAddKeywords,
        changed: !state.changed,
        settingsAddKeywordSuccess: true,
        keywordResearchData: updatedDataOfKeywords,
      }
    case ADD_KEYWORDS_SETTINGS_FAILURE:
      return {
        ...state,
        settingsAddKeywordLoader: false,
      }

    case CLEAR_ADD_KEYWORDS_SETTINGS:
      return {
        ...state,
        settingsAddKeywordLoader: false,
        settingsAddKeywordSuccess: false
      }

    case GET_REGIONAL_LOCATION_CONFIGURATION: {
      return {
        ...state,
        saveRegionalLocationLoader: true,
        saveRegionalLocationSuccess: false,
        saveRegionalLocationError: false,
      }
    }
    case GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        saveRegionalLocationLoader: false,
        saveRegionalLocationSuccess: true,
        userData: action.payload,
        saveRegionalLocationError: false,
      }
    }

    case GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        saveRegionalLocationLoader: false,
        saveRegionalLocationSuccess: false,
        saveRegionalLocationError: true,
      }
    }

    case CLEAR_REGIONAL_LOCATION_CONFIGURATION: {
      return {
        ...state,
        saveRegionalLocationLoader: false,
        saveRegionalLocationSuccess: false,
        saveRegionalLocationError: false,
      }
    }

    case CHANGE_REGION_SETTINGS:
      return {
        ...state,
        settingsRegionLoader: true,
        regionLoaderType: action.loaderType,
        regionSuccess: false,
      }
    case CHANGE_REGION_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsRegionLoader: false,
        regionSuccess: true,
        userData: action.data,
        changed: !state.changed,
        regionLoaderType: '',
      }
    case CHANGE_REGION_SETTINGS_FAILURE:
      return {
        ...state,
        settingsRegionLoader: false,
        regionLoaderType: '',
        regionSuccess: false,
      }

    case CHANGE_SOCIAL_SETTINGS: {
      const { usersDomains: domainsArray21, activeLink: currentActive21 } = state
      delete domainsArray21[currentActive21].items[0].socialMediaTwitterData
      delete domainsArray21[currentActive21].items[0].socialMediaTest
      delete domainsArray21[currentActive21].items[0].socialMediaInstagramData
      return {
        ...state,
        socialSettingLoader: true,
        usersDomains: domainsArray21,
      }
    }
    case CHANGE_SOCIAL_SETTINGS_SUCCESS:
      return {
        ...state,
        userData: action.data,
        socialSettingLoader: false,
      }
    case CHANGE_SOCIAL_SETTINGS_FAILURE:
      return {
        ...state,
        socialSettingLoader: false,
      }

    case RECHECK_BACKLINKS_SUMMARY:
      return {
        ...state,
        backLinkSummaryLoader: true,
      }
    case RECHECK_BACKLINKS_SUMMARY_SUCCESS:
      const { usersDomains: domainsArrayBackLinksSummary } = state

      let { _id: idBackLinksSummary } = action.data

      let domainBackLinksSummary = domainsArrayBackLinksSummary.map((item) => {
        if (item.items[0]._id === idBackLinksSummary) {
          let temp = item

          temp.items[0] = action.data

          return {
            ...temp,
          }
        } else {
          return item
        }
      })

      return {
        ...state,
        usersDomains: domainBackLinksSummary,
        changed: !state.changed,
        backLinkSummaryLoader: false,
      }

    case RECHECK_BACKLINKS_SUMMARY_FAILURE:
      return {
        ...state,
        backLinkSummaryLoader: false,
      }

    case GET_SITEOUTAGE_DATA: {
      return {
        ...state,
        siteOutageLoading: true,
      }
    }
    case GET_SITEOUTAGE_DATA_SUCCESS: {
      const { usersDomains: domainsArray5, activeLink: currentActive5 } = state

      if (domainsArray5 && domainsArray5.length) {
        let url = domainsArray5?.[currentActive5]?.items?.[0]?.url
        let filterData =
          action.payload &&
          action.payload.siteOutageData2 &&
          action.payload.siteOutageData2.length &&
          action.payload.siteOutageData2.filter((item) => {
            var updatedUrl = url || ''
            if (updatedUrl.indexOf('https') == 0) {
              updatedUrl = updatedUrl.substring(8)
            } else if (updatedUrl.indexOf('http') == 0) {
              updatedUrl = updatedUrl.substring(7)
            } else {
            }
            return item.url === updatedUrl
          })

        return {
          ...state,
          siteOutageData: (action.payload && action.payload?.siteOutageData2) || [],
          activeSiteOutageData: filterData && filterData.length && filterData[0].data && filterData[0].data,
          siteOutageLoading: false,
        }
      } else {
        return {
          ...state,
          siteOutageLoading: false,
        }
      }
    }

    case GET_SITEOUTAGE_DATA_CLEAR: {
      return {
        ...state,
        activeSiteOutageData: [],
        siteOutageLoading: false,
      }
    }

    case GET_SITEOUTAGE_DATA_FAILURE: {
      return {
        ...state,
        siteOutageLoading: false,
      }
    }

    case ADD_EVERYWHERE_KEYWORDS:
      return {
        ...state,
        addKeywordEverywheres: true,
        addKeywordEverywhereData: '',
        addKeywordEverywhereSuccess: false,
      }
    case ADD_EVERYWHERE_KEYWORDS_SUCCESS:
      // const filteredData = state?.keywordResearchData && state?.keywordResearchData?.length && state?.keywordResearchData.filter((item) => {
      //   return item.url === state.usersDomains[state.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url;
      // });

      let newArray = state?.keywordResearchData.map((item) => {
        if (
          item?.url ===
          state.usersDomains[state.activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        ) {
          return { ...item, data: [...item.data, { keywordsData: action.data?.[0] }] }
        }
        return item
      })

      return {
        ...state,
        addKeywordEverywheres: false,
        addKeywordEverywhereSuccess: true,
        keywordResearchData: newArray,
        addKeywordEverywhereData: action.data,
      }
    case ADD_EVERYWHERE_KEYWORDS_FAILURE:
      return {
        ...state,
        addKeywordEverywheres: false,
        addKeywordEverywhereFailed: action.data,
        addKeywordEverywhereData: '',
      }

    case KEYWORDS_RESEARCH_DATA:
      return {
        ...state,
        keywordResearch: true,
        keywordResearchSuccess: false,
      }
    case KEYWORDS_RESEARCH_DATA_SUCCESS:
      return {
        ...state,
        keywordResearch: false,
        keywordResearchSuccess: true,
        keywordResearchData: action?.data,
      }
    case KEYWORDS_RESEARCH_DATA_FAILURE:
      return {
        ...state,
        keywordResearch: false,
        keywordResearchFailed: action.data,
        keywordResearchData: '',
      }

    case GET_UPDATED_KEYWORD_RESEARCH: {
      return {
        ...state,
        keywordResearchData: action.payload,
        updatedKeywordResearchSuccess: true,
      }
    }

    case URL_SCAN_STATUS_FAILURE: {
      return {
        ...state,
        urlScanStatusError: e,
      }
    }
    case GET_URL_SCAN_STATUS_SUCCESS: {
      let sortedUrlData = _.sortBy(action?.payload, (url) => {
        return url && url.data && url.data?.[0].createdAt
      })
      return {
        ...state,
        urlScanDetails: sortedUrlData,
      }
    }

    case PERSONAL_INFO_UPDATE: {
      return {
        ...state,
        persnoalInfoLoading: true,
      }
    }
    case PERSONAL_INFO_UPDATE_SUCESS: {
      return {
        ...state,
        personalInformationData: action?.payload || {},
        persnoalInfoLoading: false,
        personalInfoSuccess: true,
        toastMessage: action?.payload?.message
      }
    }

    case PERSONAL_INFO_UPDATE_ERROR: {
      return {
        ...state,
        personalInformationData: e,
        persnoalInfoLoading: false,
        personalInfoFailure: true,
        toastMessage: action?.error?.message
      }
    }

    case PERSONAL_INFO_UPDATE_CLEAR: {
      return {
        ...state,
        personalInformationData: {},
        persnoalInfoLoading: false,
        personalInfoFailure: false,
        personalInfoSuccess: false,
        toastMessage: "",
        toastError: ""
      }
    }

    case DELETE_USER: {
      return {
        ...state,
        deleteUserLoading: true,
      }
    }
    case DELETE_USER_SUCESS: {
      return {
        ...state,
        deleteUser: action?.payload || {},
        deleteUserLoading: false,
        toastMessageUser: action?.payload?.message
      }
    }

    case DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUser: e,
        deleteUserLoading: false,
        toastMessageUser: action?.error?.message
      }
    }

    case DELETE_USER_CLEAR: {
      return {
        ...state,
        deleteUser: {},
        deleteUserLoading: false,
        toastMessage: "",
        toastError: ""
      }
    }

    case KEYWORD_POSITION_TRACKING: {
      return {
        ...state,
        keywordTrackingSuccess: false,
      }
    }
    case KEYWORD_POSITION_TRACKING_SUCCESS: {
      return {
        ...state,
        activeKeywordTrackingData: action?.payload ? action?.payload : state?.activeKeywordTrackingData || [],
        keywordTrackingSuccess: true,
      }
    }

    case KEYWORD_POSITION_TRACKING_FAILURE: {
      return {
        ...state,
        keywordTrackingError: e,
        keywordTrackingSuccess: false,
      }
    }

    case KEYWORD_ACTIVE_REGION_TAB: {
      return {
        ...state,
        activeRegionTab: action?.payload,
      }
    }

    case OPEN_UPGRADE_PLAN_MODAL: {
      return {
        ...state,
        openUpgradePlan: action?.payload
      }
    }

    case GET_LIGHTHOUSE_QUICK_SEO:{
      return{
        ...state,
        lighthouseForQuickseoLoader: true,
        quickSeoURL: action?.payload?.url
      }
    }

    case GET_LIGHTHOUSE_QUICK_SEO_SUCCESS:{
      return{
        ...state,
        lighthouseForQuickseoLoader: false,
        lighthouseQuickSeoStatus: action?.payload
      }
    }

    case GET_LIGHTHOUSE_QUICK_SEO_FAILURE:{
      return{
        ...state,
        lighthouseForQuickseoLoader: false,
        lighthouseQuickSeoError: action?.e
      }
    }

    case CLEAR_GET_LIGHTHOUSE_QUICK_SEO:{
      return{
        ...state,
        lighthouseForQuickseoLoader: false,
        lighthouseQuickSeoError: "",
        lighthouseQuickSeoStatus: {},
        quickSeoURL: ''
      }
    }

    case WEBSITE_LIMIT: {
      return {
        ...state,
        websiteLimitLoader: true
      }
    }

    case WEBSITE_LIMIT_SUCCESS: {
      return {
        ...state,
        websiteLimitLoader: false,
        websiteLimitData: action?.payload
      }
    }

    case WEBSITE_LIMIT_FAILURE: {
      return {
        ...state,
        websiteLimitLoader: false,
        websiteLimitError: action?.error
      }
    }

    case CLEAR_WEBSITE_LIMIT: {
      return {
        ...state,
        websiteLimitLoader: false,
        websiteLimitError: {},
        websiteLimitData:{}
      }
    }

    case KEYWORD_LIMIT: {
      return {
        ...state,
        keywordLimitLoader: true
      }
    }
    case KEYWORD_LIMIT_SUCCESS: {
      return {
        ...state,
        keywordLimitLoader: false,
        keywordLimitData: action?.payload
      }
    }

    case KEYWORD_LIMIT_FAILURE: {
      return {
        ...state,
        keywordLimitLoader: false,
        keywordLimitError: action?.error
      }
    }

    case CLEAR_KEYWORD_LIMIT: {
      return {
        ...state,
        keywordLimitLoader: false,
        keywordLimitError: {},
        keywordLimitData:{}
      }
    }

    case COMPETITOR_LIMIT: {
      return {
        ...state,
        competitorLimitLoader: true
      }
    }
    case COMPETITOR_LIMIT_SUCCESS: {
      return {
        ...state,
        competitorLimitLoader: false,
        competitorLimitData: action?.payload
      }
    }

    case COMPETITOR_LIMIT_FAILURE: {
      return {
        ...state,
        competitorLimitLoader: false,
        competitorLimitError: action?.error
      }
    }

    case CLEAR_COMPETITOR_LIMIT: {
      return {
        ...state,
        competitorLimitLoader: false,
        competitorLimitError: {},
        competitorLimitData:{}
      }
    }

    case QUICK_SCAN_LIMIT: {
      return {
        ...state,
        quickScanLimitLoader: true
      }
    }
    case QUICK_SCAN_LIMIT_SUCCESS: {
      return {
        ...state,
        quickScanLimitLoader: false,
        quickScanLimitData: action?.payload
      }
    }

    case QUICK_SCAN_LIMIT_FAILURE: {
      return {
        ...state,
        quickScanLimitLoader: false,
        quickScanLimitError: action?.error
      }
    }

    case CLEAR_QUICK_SCAN_LIMIT: {
      return {
        ...state,
        quickScanLimitLoader: false,
        quickScanLimitError: {},
        quickScanLimitData:{}
      }
    }

    case AVAILABLE_KEYWORD_LIMIT: {
      return {
        ...state,
        availableKeywordLimitLoader: true
      }
    }
    case AVAILABLE_KEYWORD_LIMIT_SUCCESS: {
      return {
        ...state,
        availableKeywordLimitLoader: false,
        availableKeywordLimit: action?.payload
      }
    }

    case AVAILABLE_KEYWORD_LIMIT_FAILURE: {
      return {
        ...state,
        availableKeywordLimitLoader: false,
        availableKeywordLimitError: action?.error
      }
    }

    case GET_TEST_RESULTS: {
      return {
        ...state,
        loadingTestResults: true
      }
    }
    case GET_TEST_RESULTS_SUCCESS: {

      let sortedTestData = _.sortBy(action?.payload, (url) => {
        return url && url.results && url.results?.[0].createdAt
      })
      return {
        ...state,
        testResultData: sortedTestData,
        loadingTestResults: false
      }
    }

    case GET_TEST_RESULTS_FAILURE: {
      return {
        ...state,
        testResultError: e,
        loadingTestResults: false
      }
    }

    case KEYWORD_RESEARCH_LOADING: {
      if(action?.payload?.activeLink >= 0){
        return {
          ...state,
          loadingForActiveLink: action?.payload?.activeLink
        }
      }
    }

    case CLEAR_KEYWORD_RESEARCH_LOADING:{
      return{
        ...state,
        loadingForActiveLink: null
      }
    }

    case NEW_NOTIFICATION: {
      return {
        ...state,
        notificationLoader: true
      }
    }

    case NEW_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notificationLoader: false,
        notificationsData: action?.payload
      }
    }

    case NEW_NOTIFICATION_FAILURE: {
      return {
        ...state,
        notificationLoader: false,
        notificationsError: action?.error
      }
    }

    case READ_SINGLE_NOTIFICATION: {
      return {
        ...state,
        singleNotificationLoader: true
      }
    }

    case READ_SINGLE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        singleNotificationLoader: false,
        singleNotificationSuccess: true
      }
    }

    case READ_SINGLE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        singleNotificationLoader: false,
        singleNotificationError: action?.error,
        singleNotificationSuccess: false
      }
    }

    case CLEAR_READ_SINGLE_NOTIFICATION: {
      return {
        ...state,
        singleNotificationLoader: false,
        singleNotificationError: {},
        singleNotificationSuccess: false
      }
    }

    case READ_ALL_NOTIFICATION: {
      return {
        ...state,
        allNotificationsLoader: true
      }
    }

    case READ_ALL_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        allNotificationsLoader: false,
        allNotificationsSuccess: true
      }
    }

    case READ_ALL_NOTIFICATION_FAILURE: {
      return {
        ...state,
        allNotificationsLoader: false,
        allNotificationsError: action?.error,
        allNotificationsSuccess: false
      }
    }

    case CLEAR_READ_ALL_NOTIFICATION: {
      return {
        ...state,
        allNotificationsLoader: false,
        allNotificationsError: {},
        allNotificationsSuccess: false
      }
    }

    case GET_LOST_BACKLINKS: {
      return{
        ...state,
        lostBacklinksLoader: true
      }
    }

    case GET_LOST_BACKLINKS_SUCCESS: {
      return{
        ...state,
        lostBacklinksLoader: false,
        lostBacklinks: action?.payload
      }
    }

    case GET_LOST_BACKLINKS_FAILURE: {
      return{
        ...state,
        lostBacklinksLoader: false,
        lostBacklinksError: action?.error
      }
    }

    case GOOGLE_ANALYTICS_MODAL: {
      return {
        ...state,
        isGoogleAnalyticsModalOpen: action?.payload
      }
    }

    case GET_AUTH_URL: {
      return {
        ...state,
         getAuthUrlLoader: true,
      }
    }

    case GET_AUTH_URL_SUCCESS: {
      return {
        ...state,
         getAuthUrlLoader: false,
         getAuthUrlSuccess: true,
         authUrl: action?.payload
      }
    }

    case GET_AUTH_URL_FAILURE: {
      return {
        ...state,
         getAuthUrlLoader: false,
         getAuthUrlFailure: true,
         getAuthUrlError: action?.error
      }
    }

    case CLEAR_GET_AUTH_URL: {
      return {
        ...state,
        getAuthUrlLoader: false,
        getAuthUrlFailure: false,
        getAuthUrlError: {},
        getAuthUrlSuccess: false,
        authUrl: ''
      }
    } 

    case GENERATE_RESEARCH: {
      return {
        ...state,
        generateResearchLoader: true,
      }
    }

    case GENERATE_RESEARCH_SUCCESS: {
      let keywordsList = action?.payload?.[0]?.result?.slice(0, 20)
      keywordsList = keywordsList?.length && keywordsList?.map(obj => {
        return {
          item: obj?.keywords,
          checked: false
        }
      })

      return {
        ...state,
        generateResearchLoader: false,
        generateResearchSuccess: true,
        generateResearchData: action?.payload,
        keywordsListForWebsite: keywordsList,
        selectedKeywordsList: []
      }
    }

    case GENERATE_RESEARCH_FAILURE: {
      return {
        ...state,
        generateResearchLoader: false,
        generateResearchFailure: true,
        generateResearchError: action?.error
      }
    }

    case TOGGLE_KEYWORD: {
      let selectedKeywordsList = state?.selectedKeywordsList || []
      const keywordsUpdatedList = state?.keywordsListForWebsite?.map((item) => {
        if (item?.item === action?.payload) {
          if (item.checked) {
            let index = selectedKeywordsList?.findIndex((item) => action?.payload === item)
            if (index > -1) {
              selectedKeywordsList?.splice(index, 1)
            }
          } else {
            selectedKeywordsList?.push(item.item)
          }
        }
        return {
          ...item,
          checked: item?.item === action?.payload ? !item?.checked : item?.checked,
        }
      })

      return {
        ...state,
        keywordsListForWebsite: keywordsUpdatedList,
        selectedKeywordsList: selectedKeywordsList,
        selectedKeywordsString: selectedKeywordsList?.join(','),
      }
    }

    case CLEAR_GENERATE_RESEARCH: {
      return {
        ...state,
        generateResearchLoader: false,
        generateResearchFailure: false,
        generateResearchError: {},
        generateResearchSuccess: false,
      }
    }

    case CLEAR_GENERATE_RESEARCH_DATA: {
      return {
        ...state,
        generateResearchLoader: false,
        generateResearchFailure: false,
        generateResearchError: {},
        generateResearchSuccess: false,
        generateResearchData: []
      }
    }

    case GET_AUTH_TOKEN: {
      return {
        ...state,
        analyticsDataLoader: true,
      }
    }

    case GET_AUTH_TOKEN_SUCCESS: {
      return {
        ...state,
        analyticsDataLoader: false,
        analyticsDataSuccess: true,
        analyticsData: action?.payload
      }
    }

    case GET_AUTH_TOKEN_FAILURE: {
      return {
        ...state,
        analyticsDataLoader: false,
        analyticsDataFailure: true,
        analyticsDataError: action?.error
      }
    }

    case CLEAR_GET_AUTH_TOKEN: {
      return {
        ...state,
        analyticsDataLoader: false,
        analyticsDataSuccess: false,
        analyticsDataFailure: false,
        analyticsDataError: {}
      }
    } 

    case DISCONNECT_GOOGLE: {
      return {
        ...state,
        disconnectGoogleLoader: true
      }
    }

    case DISCONNECT_GOOGLE_SUCCESS: {
      return {
        ...state,
        disconnectGoogleLoader: false,
        disconnectGoogleSuccess: true,
        disconnectGoogleMessage: action?.payload
      }
    }

    case DISCONNECT_GOOGLE_FAILURE: {
      return {
        ...state,
        disconnectGoogleLoader: false,
        disconnectGoogleFailure: true,
        disconnectGoogleError: action?.error
      }
    }

    case CLEAR_DISCONNECT_GOOGLE: {
      return {
        ...state,
        disconnectGoogleLoader: false,
        disconnectGoogleFailure: false,
        disconnectGoogleSuccess: false,
        disconnectGoogleError: {},
        disconnectGoogleMessage: {}
      }
    }

    case STORE_ANALYTICS_DATA: {
      return {
        ...state,
        storeAnalyticsDataLoader: true,
      }
    }

    case STORE_ANALYTICS_DATA_SUCCESS: {
      return {
        ...state,
        storeAnalyticsDataLoader: false,
        storeAnalyticsDataSuccess: true,
        storeAnalyticsDataMessage: action?.payload
      }
    }

    case STORE_ANALYTICS_DATA_FAILURE: {
      return {
        ...state,
        storeAnalyticsDataLoader: false,
        storeAnalyticsDataFailure: true,
        storeAnalyticsDataError: action?.error
      }
    }

    case CLEAR_STORE_ANALYTICS_DATA: {
      return {
        ...state,
        storeAnalyticsDataLoader: false,
        storeAnalyticsDataFailure: false,
        storeAnalyticsDataSuccess: false,
        storeAnalyticsDataError: {},
        storeAnalyticsDataMessage: {}
      }
    }

    case GET_ANALYTICS_DATA: {
      return {
        ...state,
        getAnalyticsDataLoader: true,
      }
    }

    case GET_ANALYTICS_DATA_SUCCESS: {
      return {
        ...state,
        getAnalyticsDataLoader: false,
        getAnalyticsDataSuccess: true,
        analytics: action?.payload
      }
    }

    case GET_ANALYTICS_DATA_FAILURE: {
      return {
        ...state,
        getAnalyticsDataLoader: false,
        getAnalyticsDataFailure: true,
        analyticsError: action?.error
      }
    }

    case CLEAR_GET_ANALYTICS_ERROR: {
      return {
        ...state,
        getAnalyticsDataLoader: false,
        getAnalyticsDataFailure: false,
        analyticsError: {},
      }
    }
    case CLEAR_GET_ANALYTICS_DATA: {
      return {
        ...state,
        getAnalyticsDataLoader: false,
        getAnalyticsDataFailure: false,
        getAnalyticsDataSuccess: false,
        analyticsError: {},
        analytics: {}
      }
    }

    case CONTACT_US: {
      return {
        ...state,
        contactUsLoader: true
      }
    }

    case CONTACT_US_SUCCESS: {
      return {
        ...state,
        contactUsLoader: false,
        contactUsSuccess: true,
        contactUsMessage: action?.payload
      }
    }

    case CONTACT_US_FAILURE: {
      return {
        ...state,
        contactUsLoader: false,
        contactUsFailure: true,
        contactUsError: action?.error
      }
    }

    case CLEAR_CONTACT_US: {
      return {
        ...state,
        contactUsLoader: false,
        contactUsFailure: false,
        contactUsSuccess: false,
        contactUsError: {},
        contactUsMessage: {}
      }
    }

    case GENERATE_TICKET: {
       return {
        ...state,
        generateTicketLoader: true
      }
    }

    case GENERATE_TICKET_SUCCESS: {
      return {
        ...state,
        generateTicketLoader: false,
        generateTicketSuccess: true,
        generateTicketMessage: action?.payload
      }
    }

    case GENERATE_TICKET_FAILURE: {
      return {
        ...state,
        generateTicketLoader: false,
        generateTicketFailure: true,
        generateTicketError: action?.error
      }
    }

    case CLEAR_GENERATE_TICKET: {
      return {
        ...state,
        generateTicketLoader: false,
        generateTicketFailure: false,
        generateTicketSuccess: false,
        generateTicketError: {},
        generateTicketMessage: {}
      }
    }
    default:
      return state
  }
}
export default dashboardReducer

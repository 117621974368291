import { useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const CSSMinification = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [cssMinificationFlag, setCssMinificationFlag] = useState(false)

  const columns = [
    {
      id: 'nonMinifiedCSSUrl',
      displayName:'Non Minified CSS'
    }
  ]

  const cssMinificationCsv = data?.cssMinification?.data?.cssMinificationResults?.items?.map(item => {
    return {
      ['Non Minified CSS']: item?.url
    }
  })

  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.cssMinification?.data?.testResults?.status ? (
            ''
          ) : data?.cssMinification?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.cssMinification?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
          {data?.cssMinification?.data?.testResults ? (
            <>{data?.cssMinification?.data?.testResults?.message || ''}</>
          ) : (
            <></>
          )}
        </p>

        {showDetail &&
        data?.cssMinification?.data?.testResults?.status !== 1 &&
        data?.cssMinification?.data?.cssMinificationResults?.items &&
        data?.cssMinification?.data?.cssMinificationResults?.items?.length ? (
          <>
            <p
              className="text-primary cursor-pointer"
              onClick={() => {
                if (testType === "") {
                  setCssMinificationFlag(!cssMinificationFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}
            >
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={cssMinificationFlag}
        toggleShow={() => setCssMinificationFlag(!cssMinificationFlag)}
        title={'Full list of CSS files'}
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        bodyClassName="m-3"
        showDownload
        columns={columns}
        datas = {cssMinificationCsv}
        fileName='CSS Minification Signal Test'
        alternativeDownload={true}
      >
        {data.cssMinification?.data?.cssMinificationResults?.items.length ? (
          <>
            <Table bordered>
              <thead>
                <tr>
                  <th style={{ width: '40%' }}>Non Minified CSS</th>
                </tr>
              </thead>
              <tbody>
                {data.cssMinification?.data?.cssMinificationResults?.items.map((item) => {
                  return (
                    <tr>
                      <td>{item?.url}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <></>
        )}
      </ModalHOC>
    </>
  )
}

export default CSSMinification

import { Alert } from 'react-bootstrap'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const MetaTitleTest = ({ data }) => {
  return (
    <>
      {data?.metaData?.data?.response?.title ? (
        <>
          {/* <h5>{data?.url}</h5> */}
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.metaData?.data?.response?.title?.titleStatus ? (
                ''
              ) : data?.metaData?.data?.response?.title.titleStatus === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.metaData?.data?.response?.title.titleStatus === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p> {data?.metaData?.data?.response?.title.titleMessage} </p>
            <p>
              {data?.metaData?.data?.response?.title.title ? (
                <p>{data?.metaData?.data?.response?.title.title}</p>
              ) : (
                <></>
              )}
            </p>

            <Alert
              // variant={data?.metaData?.data?.response?.title.titleStatus === 1 ?'success' : 'danger'}
              variant={
                !data?.metaData?.data?.response?.title.titleStatus
                  ? ''
                  : data?.metaData?.data?.response?.title.titleStatus === 3
                  ? 'danger'
                  : data?.metaData?.data?.response?.title.titleStatus === 2
                  ? 'warning'
                  : 'success'
              }
              className="alert-outline-coloured"
              key={'meta-title'}
            >
              <div className="alert-icon">{/* <FontAwesomeIcon icon={faBell} fixedWidth /> */}</div>
              <div className="alert-message">
              <span style={{fontWeight:400, color:'#000000'}}>Text:</span>{' '}
                {data?.metaData?.data?.response?.title.title ? (
                  <span>{data?.metaData?.data?.response?.title.title}</span>
                ) : (
                  <>No Parameters Found</>
                )}
                <div>
                <span style={{fontWeight:400, color:'#000000'}}>Length:</span>{' '}
                  {data?.metaData?.data?.response?.title.title ? (
                    <span>{data?.metaData?.data?.response?.title.title.length}</span>
                  ) : (
                    <>No Parameters Found</>
                  )}
                </div>
              </div>
            </Alert>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MetaTitleTest

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const AdvancedConsoleErrorsTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.advancedConsoleErrorsTest.heading}
      whatIsIT={freeTools.advancedConsoleErrorsTest.what}
    />
  )
}

export default AdvancedConsoleErrorsTest

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

const Captcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcxR94UAAAAAJ1uu6cR_UJcz-FfZnk2riqPeLa3">
        <GoogleReCaptcha onVerify={() => {}} />
      </GoogleReCaptchaProvider>
  )
} 
export default Captcha

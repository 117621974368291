import {
  ADD_WEBSITE_THROUGH_CACHE,
  ADD_WEBSITE_THROUGH_CACHE_SUCCESS,
  ADD_WEBSITE_THROUGH_CACHE_FAILURE,
  CLEAR_ADD_WEBSITE_THROUGH_CACHE_FAILURE,
} from '../../actionType'

const initialState = {
  isAddedThroughCache: false,
}

const WebsiteCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WEBSITE_THROUGH_CACHE: {
      return {
        ...state,
        isAddedThroughCache: true,
      }
    }

    case ADD_WEBSITE_THROUGH_CACHE_SUCCESS: {
      return {
        ...state,
      }
    }

    case ADD_WEBSITE_THROUGH_CACHE_FAILURE: {
      return {
        ...state,
      }
    }

    case CLEAR_ADD_WEBSITE_THROUGH_CACHE_FAILURE: {
      return {
        ...state,
        isAddedThroughCache: false,
      }
    }

    default:
      return state
  }
}
export default WebsiteCacheReducer

import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Row, Col } from 'react-bootstrap'

// import Timeline from "./Timeline";
import InstagramActivity from './Instagram-Timeline'

const SocialInstagram = () => (
  <React.Fragment>
    <Helmet title="Social Dashboard" />
    <Container className="p-0">
      <Row>
        <Col lg="12">
          <InstagramActivity />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)

export default SocialInstagram

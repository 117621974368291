import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findLast, sortBy } from 'lodash'
import { Button, Table, Tab, Modal, Nav as Navb, OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as Icon from 'react-feather'
import { Spinner } from 'reactstrap'

import { Loader } from 'components/uiCore'

import { keywordPositionTracking, removeKeyword } from '../../redux/actions/dashboard'

import { checkPage, getAbbr, getActiveUserData, isMainUrlMatch } from 'constants/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faChartArea, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import TrendsAreaChart from './TrendsAreaChart'
import ModalContainer from 'components/modal'
import { getKeywordsPositionHistory } from 'redux/actions/keywordsTracking'
import KeywordsPoistionHistoryAreaChart from './KeywordsHistoryAreaChart'

const TopKeywordsTable = ({ onClick, showHistory = true }) => {
  const {
    userData,
    usersDomains: usersDomain,
    activeLink,
    activeKeywordTrackingData,
    keywordRemoveLoader,
    regionSuccess,
  } = useSelector(({ dashboard }) => dashboard)
  const locations = useSelector(({ startFreeTrial }) => startFreeTrial.locations)
  const { keywordsPositionHistoryData } = useSelector(({ keywordsTracking }) => keywordsTracking)

  const [openTrendsModal, setOpenTrendsModal] = useState(false)
  const [openHistoryModal, setOpenHistoryModal] = useState(false)
  const [activeTrendsData, setActiveTrendsData] = useState([])
  const [selectedNavTab, setSelectedNavTab] = useState(0)
  const [topDomainList, setTopDomainList] = useState()
  const [topDomainFlag, setTopDomainFlag] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [rowKeywordDeleted, setRowKeywordDeleted] = useState()
  const [keywordHistory, setKeywordHistory] = useState([])

  const dispatch = useDispatch()

  const keywordTrackingInterval = useRef(null)

  async function fetchKeywordsPositionApi() {
    const data = await localStorage.getItem('userData')
    dispatch(keywordPositionTracking({ userId: JSON.parse(data)._id }))
    dispatch(getKeywordsPositionHistory({ userId: JSON.parse(data)._id }))
    keywordTrackingInterval.current = setInterval(() => {
      dispatch(keywordPositionTracking({ userId: JSON.parse(data)._id }))
      dispatch(getKeywordsPositionHistory({ userId: JSON.parse(data)._id }))
    }, 25000)
  }

  useEffect(() => {
    fetchKeywordsPositionApi()
  }, [])

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  let keywordDataInitial =
    activeKeywordTrackingData &&
    activeKeywordTrackingData?.length &&
    activeKeywordTrackingData?.filter((item) => {
      return item._id?.websiteId === usersDomain?.[activeLink]?.items?.[0]?.id
    })

  let keywordDataFiltered = keywordDataInitial?.[0]?.regions.filter((item) => {
    if (
      activeSearchData?.regions?.[selectedNavTab]?.region === item?.region &&
      activeSearchData?.regions?.[selectedNavTab]?.country === item?.country &&
      activeSearchData?.regions?.[selectedNavTab]?.state === item?.state &&
      activeSearchData?.regions?.[selectedNavTab]?.city === item?.city &&
      activeSearchData?.regions?.[selectedNavTab]?.address === item?.address
    ) {
      return item || {}
    }
  })

  let keywordTrackingData = keywordDataFiltered?.[0]?.keywords?.map((item) => {
    return {
      name: item?.keyword,
      regions: keywordDataFiltered?.[0],
      position: item?.positionData?.position,
      searchVolumn: item?.positionData?.searchVolume,
      trendsData: item?.positionData?.trends,
      volumnData: item?.positionData?.cpc,
      topDomains: item?.positionData?.topDomains,
      positionDifference: item?.positionDifference,
    }
  })

  let filteredKeywords = keywordTrackingData?.filter((keywordObj) => {
    let found = activeSearchData?.keywords?.some((keyword) => keyword === keywordObj?.name)
    if (found) {
      return keywordObj
    }
  })

  const keywordTrackingDataFinal = sortBy(filteredKeywords, 'name')

  //for keyword add
  if (activeSearchData?.keywords?.length > keywordTrackingDataFinal?.length) {
    activeSearchData?.keywords?.filter((keyword) => {
      const found = keywordTrackingDataFinal?.some((keywordObj) => keywordObj.name === keyword)
      if (!found) {
        keywordTrackingDataFinal.push({ name: keyword, data: 'loading' })
      }
    })

    keywordTrackingDataFinal.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
        sensitivity: 'base',
      }),
    )
  }

  //for clear interval
  useEffect(() => {
    return () => {
      clearInterval(keywordTrackingInterval.current)
    }
  }, [])

  //for region add
  useEffect(() => {
    if (regionSuccess) {
      activeSearchData?.keywords?.forEach((keyword) => {
        keywordTrackingDataFinal.push({ name: keyword, data: 'loading' })
      })
    }
    // handleStopKeywordApiCalls()
  }, [regionSuccess, activeKeywordTrackingData])

  useEffect(() => {
    dispatch({ type: 'KEYWORD_ACTIVE_REGION_TAB', payload: selectedNavTab })
  }, [selectedNavTab])

  const handleTopDomainModel = (data, keyword) => {
    setTopDomainFlag(!topDomainFlag)
    setTopDomainList(data)
    setKeyword(keyword)
  }

  const onRemoveKeyword = (keyword, index) => {
    const data = localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      let url = usersDomain[activeLink].items?.[0]?.url
      let website = usersDomain[activeLink].items[0].id
      dispatch(removeKeyword({ keyword, url, website }))
      setRowKeywordDeleted(index)
    }
  }

  const findPositionChange = (positionDiff) => {
    if (positionDiff < 0) {
      return <FontAwesomeIcon icon={faCaretUp} className="align-middle" color={'#20c997'} />
    } else if (positionDiff > 0) {
      return <FontAwesomeIcon icon={faCaretDown} className="align-middle" color={'#d9534f'} />
    } else {
      return <FontAwesomeIcon icon={faExchangeAlt} className="align-middle" color="#b2b2b2" />
    }
  }

  let keywordsHistoryForActiveLink = keywordsPositionHistoryData?.length
    ? keywordsPositionHistoryData?.filter(
        (websites) => websites?._id?.websiteId === activeSearchData?.websiteId,
      )
    : []

  let keywordDataFilteredHistory = keywordsHistoryForActiveLink?.[0]?.regions?.filter((item) => {
    if (
      activeSearchData?.regions?.[selectedNavTab]?.region === item?.region &&
      activeSearchData?.regions?.[selectedNavTab]?.country === item?.country &&
      activeSearchData?.regions?.[selectedNavTab]?.state === item?.state &&
      activeSearchData?.regions?.[selectedNavTab]?.city === item?.city &&
      activeSearchData?.regions?.[selectedNavTab]?.address === item?.address
    ) {
      return item || {}
    }
  })

  const handleHistoryOfKeyword = (keyword, keywordsHistory) => {
    const filtereKeywordHistoryData = keywordsHistory?.filter((item) => item?.keyword === keyword)
    setKeywordHistory(filtereKeywordHistoryData?.[0]?.positionData)
    setKeyword(keyword)
  }

  const handleActiveTrendsData = (trendsData, keyword) => {
    setActiveTrendsData(trendsData)
    setKeyword(keyword)
  }

  const checkPositionOfKeyword = (keyword, keywordsHistory) => {
    const filtereKeywordHistoryData = keywordsHistory?.filter((item) => item?.keyword === keyword)
    if (
      filtereKeywordHistoryData?.[0]?.positionData[0]?.position === 0 ||
      filtereKeywordHistoryData?.[0]?.positionData[0]?.position === null ||
      filtereKeywordHistoryData?.[0]?.positionData[0]?.position === undefined
    ) {
      return false
    } else {
      return true
    }
  }

  const handleShowDifference = (difference) => {
    if (difference > 0) {
      return `(${-difference})`
    } else if (difference < 0) {
      return `(${-difference})`
    } else {
      return difference ? `(${difference})` : `(0)`
    }
  }

  const mainUrl = activeSearchData?.url
  const redirectUrl = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
  const isUrlMatched = isMainUrlMatch(mainUrl, redirectUrl)

  return (
    <>
      <Modal
        show={openTrendsModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setOpenTrendsModal(false)
          setKeyword('')
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
      >
        <Modal.Header>
          <h3 className="f-bold mb-0" style={{ fontSize: '16px' }}>
            Trends Data For: <span className="top-domain-model-header">{keyword}</span>
          </h3>
        </Modal.Header>
        <Modal.Body>
          <TrendsAreaChart chartData={activeTrendsData} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => {
              setOpenTrendsModal(false)
              setKeyword('')
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openHistoryModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setOpenHistoryModal(false)
          setKeyword('')
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
      >
        <Modal.Header>
          <h3 className="f-bold mb-0" style={{ fontSize: '16px' }}>
            Keyword Position History For: <span className="top-domain-model-header">{keyword}</span>
          </h3>
        </Modal.Header>
        <Modal.Body>
          <KeywordsPoistionHistoryAreaChart chartData={keywordHistory} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => {
              setOpenHistoryModal(false)
              setKeyword('')
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Tab.Container id="left-tabs-example" defaultActiveKey="0">
        {activeSearchData &&
        isUrlMatched &&
        activeSearchData?.regions &&
        activeSearchData?.regions?.length ? (
          <Navb
            variant="tabs"
            onSelect={(selectedKey) => {
              setSelectedNavTab(parseInt(selectedKey))
            }}
          >
            {activeSearchData.regions.map((item, index) => {
              return (
                <Navb.Item className="location-tab-tracking" key={`Nav-${index + 1}`}>
                  <Navb.Link eventKey={index.toString()}>
                    {item.country !== '' && item.state !== '' && item.city !== ''
                      ? item.country === 'Global'
                        ? 'Global'
                        : `${item?.city?.trim()?.length > 0 ? item?.city + ',' : ''} ${item.state} ${getAbbr(item.country, locations) || item.region}`
                      : ''}
                    {item?.address ? (
                      <OverlayTrigger placement={'right'} overlay={<Tooltip>{item?.address}</Tooltip>}>
                        <Icon.AlertCircle
                          size="16"
                          color="#afafaf"
                          style={{ marginLeft: '2px', marginBottom: '2px' }}
                        />
                      </OverlayTrigger>
                    ) : null}
                  </Navb.Link>
                </Navb.Item>
              )
            })}
            <Button
              style={{ height: 'fit-content', marginTop: '5px' }}
              className="plus-button-desktop"
              color="primary dashboard-checkup-url-btn ms-2"
              onClick={onClick}
            >
              +
            </Button>
          </Navb>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                alignSelf: 'center',
                flexDirection: 'row',
                minHeight: 380,
              }}
            >
              <div className="text-center">
                <Loader />
              </div>
            </div>
          </>
        )}
        <ModalContainer
          show={topDomainFlag}
          toggleShow={() => setTopDomainFlag(!topDomainFlag)}
          title={`${keyword}`}
          bodyClassName="m-3"
          titleStyles={{ fontWeight: '400', fontSize: '16px' }}
          headerTitle="Top Domains for: "
        >
          {topDomainList?.length > 0 ? (
            <Table bordered>
              <thead>
                <th>Domain</th>
                <th>URL</th>
              </thead>
              <tbody>
                {topDomainList?.length
                  ? topDomainList?.slice(0, 20).map((item) => {
                      return (
                        <tr>
                          <td>{item.domain}</td>
                          <td className="view-top-domain">
                            <a href={`${item.link}`}>Visit URL</a>
                          </td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          ) : (
            'No data found'
          )}
        </ModalContainer>
        <Tab.Content>
          {activeSearchData && activeSearchData?.regions && activeSearchData?.regions?.length && isUrlMatched
            ? activeSearchData?.regions.map((_, searchDataIndex) => {
                return (
                  <Tab.Pane eventKey={searchDataIndex.toString()} key={`Table-${searchDataIndex + 1}`}>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Keywords</th>
                          <th>
                            Position&nbsp;
                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Search engine results position for a particular keyword for your domain.
                                </Tooltip>
                              }
                            >
                              <Icon.AlertCircle size="16" color="#afafaf" />
                            </OverlayTrigger>
                          </th>
                          {showHistory ? <th>History</th> : null}
                          <th scope="col">Trends</th>
                          <th>
                            Top domains&nbsp;
                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Top 100 organic search results for a particular keyword.
                                </Tooltip>
                              }
                            >
                              <Icon.AlertCircle size="16" color="#afafaf" />
                            </OverlayTrigger>
                          </th>
                          <th scope="col">Search Volume</th>
                          <th scope="col">CPC($)</th>
                          <th style={{ width: '10%' }}>Action</th>
                        </tr>
                      </thead>
                      {activeKeywordTrackingData?.length || activeSearchData?.keywords?.length ? (
                        <tbody>
                          {keywordTrackingDataFinal && keywordTrackingDataFinal?.length ? (
                            keywordTrackingDataFinal?.map((item, index) => {
                              return (
                                <tr key={`tableData-${index + 1}`}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>
                                    {item?.data === 'loading' || item.serpPosition === 'loading' ? (
                                      <Spinner />
                                    ) : item.position === 0 || item.position === undefined ? (
                                      'Not found'
                                    ) : (
                                      <span>
                                        {findPositionChange(item?.positionDifference)}
                                        {'  '}
                                        {checkPage(item.position)} - Position
                                        {item.position && item.position % 10 === 0
                                          ? 10
                                          : item.position % 10}{' '}
                                        - Spot {item.position}/100 {'  '}
                                        {handleShowDifference(item?.positionDifference)}
                                      </span>
                                    )}
                                  </td>
                                  {showHistory ? (
                                    <td>
                                      {item?.data === 'loading' ? (
                                        <Spinner />
                                      ) : checkPositionOfKeyword(
                                          item?.name,
                                          keywordDataFilteredHistory?.[0]?.keywords,
                                        ) ? (
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setOpenHistoryModal(true)
                                            handleHistoryOfKeyword(
                                              item?.name,
                                              keywordDataFilteredHistory?.[0]?.keywords,
                                            )
                                            // setActiveTrendsData(item.trendsData)
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faChartArea} className="align-middle" />{' '}
                                        </span>
                                      ) : (
                                        'Not Found'
                                      )}
                                    </td>
                                  ) : null}
                                  <td>
                                    {item?.data === 'loading' || item.volumnData === 'loading' ? (
                                      <Spinner />
                                    ) : (
                                      (
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setOpenTrendsModal(true)
                                            handleActiveTrendsData(item.trendsData, item?.name)
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faChartArea} className="align-middle" />{' '}
                                        </span>
                                      ) || '-'
                                    )}
                                  </td>
                                  <td style={{ zIndex: 1 }} className="d-md-table-cell view-top-domain">
                                    {item?.data !== 'loading' && item?.topDomains ? (
                                      <a onClick={() => handleTopDomainModel(item?.topDomains, item?.name)}>
                                        View list
                                      </a>
                                    ) : (
                                      <Spinner />
                                    )}
                                  </td>
                                  <td>
                                    {item?.data === 'loading' || item.volumnData === 'loading' ? (
                                      <Spinner />
                                    ) : (
                                      item.searchVolumn || '-'
                                    )}
                                  </td>
                                  <td>
                                    {item?.data === 'loading' || item.volumnData === 'loading' ? (
                                      <Spinner />
                                    ) : (
                                      item.volumnData?.value || '-'
                                    )}
                                  </td>
                                  <td className="table-action">
                                    <div className={'cursor-pointer'}>
                                      {keywordRemoveLoader && rowKeywordDeleted === index ? (
                                        <Spinner />
                                      ) : (
                                        <Icon.Trash
                                          className="align-middle"
                                          size={18}
                                          onClick={() => onRemoveKeyword(item?.name, index)}
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      ) : null}
                    </Table>
                  </Tab.Pane>
                )
              })
            : null}
        </Tab.Content>
      </Tab.Container>
    </>
  )
}

export default TopKeywordsTable

import {
  GET_TRACK_KEYWORDS,
  GET_TRACK_KEYWORDS_SUCCESS,
  GET_TRACK_KEYWORDS_FAILURE,
  GET_REGIONAL_LOCATION_CONFIGURATION,
  GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS,
  GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE,
  CLEAR_REGIONAL_LOCATION_CONFIGURATION,
  KEYWORD_POSITION_HISTORY_SUCCESS,
  KEYWORD_POSITION_HISTORY_FAILURE,
  TRACK_KEYWORDS_SUCCESS,
  TRACK_KEYWORDS_FAILURE,
} from '../../actionType'

const initialState = {
  saveRegionalLocationLoader: false,
  saveRegionalLocationSuccess: false,
  saveRegionalLocationError: false,

  getMoreKeywordSuccess: false,
  getMoreKeywordLoader: false,
  getMoreKeywordError: false,
}

const keywordTrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case GET_TRACK_KEYWORDS:
      return {
        ...state,
      }
    case GET_TRACK_KEYWORDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_TRACK_KEYWORDS_FAILURE:
      return {
        ...state,
      }

    case GET_REGIONAL_LOCATION_CONFIGURATION: {
      return {
        ...state,
        saveRegionalLocationLoader: true,
        saveRegionalLocationSuccess: false,
        saveRegionalLocationError: false,
      }
    }
    case GET_REGIONAL_LOCATION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        saveRegionalLocationLoader: false,
        saveRegionalLocationSuccess: true,
        saveRegionalLocationError: false,
      }
    }

    case GET_REGIONAL_LOCATION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        saveRegionalLocationLoader: false,
        saveRegionalLocationSuccess: false,
        saveRegionalLocationError: true,
      }
    }

    case CLEAR_REGIONAL_LOCATION_CONFIGURATION: {
      return {
        ...state,
        saveRegionalLocationLoader: false,
        saveRegionalLocationSuccess: false,
        saveRegionalLocationError: false,
      }
    }

    case KEYWORD_POSITION_HISTORY_SUCCESS: {
      return {
        ...state,
        keywordsPositionHistoryData : action?.payload
      }
    }

    case KEYWORD_POSITION_HISTORY_FAILURE: {
      return {
        ...state,
        keywordPositionHistoryError: e
      }
    }

    case TRACK_KEYWORDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case TRACK_KEYWORDS_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}
export default keywordTrackingReducer

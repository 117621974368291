import React from 'react'
import { Outlet } from 'react-router-dom'

import { Col, Container, Row } from 'react-bootstrap'

import Header from 'components/Header/Header'
import 'layouts/layout.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const TrailLayoutWitContainer = ({ children }) => {

  const { isMobile } = useDeviceCheck()
  return (
    <React.Fragment>
      <Header customClassName="layout-container trial-header" isEmailLaptop isEmailMobile />
      <div className="d-flex w-100 justify-content-center trail-layout-main">
        <Container className="d-flex flex-column h-100 mx-lg-auto mx-md-auto mx-sm-0">
          <Row className="h-100" style={{width: isMobile ? "100vw" : ''}}>
            <Col sm="12" md="12" lg="12" className="mx-auto px-sm-0 d-table h-100 trail-layout-col" style={{background: isMobile ? "#f0f4fc" : ''}}>
              <div className=" align-middle">
                {children}
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer-trail">
        <div />
      </footer>
      {/* <Settings /> */}
    </React.Fragment>
  )
}

export default TrailLayoutWitContainer

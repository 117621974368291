import { useEffect, useState } from 'react'
import { Col, Row, ProgressBar } from 'react-bootstrap'

import { getDataForDashboard } from '../../utils/seoIssue'

import { Loader } from 'components/uiCore'

const IssuesFooter = (props) => {
  const [positiveCounter, setPositiveCounter] = useState(0)
  const [negativeCounter, setNegativeCounter] = useState(0)
  const [warningCounter, setWarningCounter] = useState(0)

  useEffect(() => {
    if (props.issuesData) {
      let isUrlData = true
      const assets = getDataForDashboard(props?.issuesData, isUrlData)

      const {
        commonSeoW,
        speedOptW,
        serverW,
        mobileUseW,
        advanceW,
        commonSeoS,
        speedOptS,
        serverS,
        mobileUseS,
        advanceS,
        commonSeoF,
        speedOptF,
        serverF,
        mobileUseF,
        advanceF,
      } = assets

      setWarningCounter(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)

      setPositiveCounter(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [props?.issuesData])

  // useEffect(() => {
  //     dispatch(getUrlReport(props?.data))
  // }, [props.data])

  return (
    <>
      {negativeCounter < 73 ? (
        <Row className="mt-3">
          <Col md="12">
            <Row>
              <Col>
                <p>
                  {' '}
                  {positiveCounter} Passed / {((positiveCounter * 100) / 73).toFixed(1)}%{' '}
                </p>
                <ProgressBar className="mb-3" variant="success" now={positiveCounter} label="" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <p>
                  {warningCounter} Warning / {((warningCounter * 100) / 73).toFixed(1)}%
                </p>
                <ProgressBar className="mb-3" variant="warning" now={warningCounter} label="" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="mb-2">
                <p>
                  {negativeCounter} Failed / {((negativeCounter * 100) / 73).toFixed(1)}%
                </p>
                <ProgressBar className="mb-3" variant="danger" now={negativeCounter} label="" />
              </Col>
              <br />
            </Row>
          </Col>
        </Row>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            alignSelf: 'center',
            flexDirection: 'row',
            minHeight: 200,
          }}
        >
          <div className="text-center">
            <Loader />
          </div>
        </div>
      )}
    </>
  )
}

export default IssuesFooter

import {
  FREE_WHOIS_START,
  FREE_DOMAIN_SCORE_START,
  FREE_PAGE_OBJECT,
  FREE_SPF_RECORD,
  FREE_NO_FOLLOW,
  FREE_TEST,
  QUICK_SEO_CHECKUP,
  GET_QUICK_CHECKUP_REPORT,
  GET_URL_REPORT,
} from '../../actionType'
export const checkWhois = (data) => ({
  type: FREE_WHOIS_START,
  payload: data,
})

export const checkDomainScore = (data) => ({
  type: FREE_DOMAIN_SCORE_START,
  payload: data,
})

export const checkPageObject = (data) => ({
  type: FREE_PAGE_OBJECT,
  payload: data,
})

export const checkSPFRecord = (data) => ({
  type: FREE_SPF_RECORD,
  payload: data,
})

export const checkNoFollow = (data) => ({
  type: FREE_NO_FOLLOW,
  payload: data,
})

export const checkFreeTest = (data) => ({
  type: FREE_TEST,
  payload: data,
})

export const quickSeoCheckup = (data) => ({
  type: QUICK_SEO_CHECKUP,
  payload: data,
})

export const getUrlReport = (data) => ({
  type: GET_URL_REPORT,
  payload: data,
})

export const getQuickCheckupReport = (data) => ({
  type: GET_QUICK_CHECKUP_REPORT,
  payload: data,
})

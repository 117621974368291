import { useEffect, useState } from 'react'
import { Card, Dropdown, Nav, Row, Spinner, Tab, Table, Button, Form, Modal, Alert, Col } from 'react-bootstrap'
import { MoreHorizontal } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as _ from 'lodash'
import Select from 'react-select'

import { Loader } from 'components/uiCore'
import ModalContainer from '../../../components/modal'
import TopKeywordsTable from '../TopKeywordsTable'
import TrendsAreaChart from '../TrendsAreaChart'

import {
  changeActiveLink,
  getUserData,
  patchUserDomainsDashboard,
  saveRegionalLocation,
  getBacklinksStats,
  getSiteOutageData,
  getKeywordLimit,
  openUpgradePlanModal,
  getAvailableKeywordLimit,
} from '../../../redux/actions/dashboard'
import { getAvgPositionKeywordApi } from '../../../redux/actions/topKeywords'
import { getTrackKeywords } from '../../../redux/actions/keywordsTracking'
import { getCountries, getLocations } from '../../../redux/actions/freetrial/startFreeTrial'
import { addKeywordSettings } from '../../../redux/actions/register'

import { PlusCircleOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import '../../dashboards/ReportsTable/reportsTable.scss'
import { checkPositionForKeyword, checkPositionForUserEntered, getActiveUserData } from 'constants/Utils'
import paths from 'constants/paths'
import { CLEAR_ADD_KEYWORDS_SETTINGS, CLEAR_KEYWORD_LIMIT, CLEAR_REMOVE_KEYWORD } from 'redux/actionType'
import { LimitOption, PLAN } from 'constants/enum'
import { faAngleDoubleDown, faAngleDoubleUp, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MainUrlNav from 'components/MainUrlNav'

const KeywordPositionTracker = () => {

  let unchangedKeywords = 0
  let increasedKeywords = 0
  let decreasedKeywords = 0

  const [openModal, setOpenModal] = useState(false)
  const [keywordsOpen, setKeywordsOpen] = useState(false)
  const [topDomainFlag, setTopDomainFlag] = useState(false)
  const [isTrackKeywordLoaded, setIsTrackKeywordLoaded] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [topDomainList, setTopDomainList] = useState()
  const [selectedNavTab, setSelectedNavTab] = useState(0)
  const [openTrendsModal, setOpenTrendsModal] = useState(false)
  const [keywordTracker, setKeywordTracker] = useState()
  const [addKeyword, setAddKeyword] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('Canada')
  const [selectedState, setSelectedState] = useState()
  const [selectedCity, setSelectedCity] = useState()
  const [countriesOptions, setCountriesOptions] = useState('Canada')
  const [stateOptions, setStateOptions] = useState('Canada')
  const [cityOptions, setCityOptions] = useState('Canada')
  const [activeRegionalDBRegion, setActiveRegionalDBRegion] = useState('')
  const [currentRegionalState, setCurrentRegionalState] = useState('')
  const [currentRegionalCity, setCurrentRegionalCity] = useState('')
  const [isRegionChanged, setIsRegionChanged] = useState(false)
  const [openKeywordLimitExceed, setOpenKeywordLimitExceed] = useState(false)
  const [keywordError, setKeywordError] = useState("")


  const {
    usersDomains: usersDomain,
    activeLink,
    activeKeywordData,
    userData,
    settingsAddKeywordLoader,
    settingsAddKeywordSuccess,
    keywordRemoveSuccess,
    keywordLimitLoader,
    keywordLimitData,
    keywordLimitError,
    removeLoading,
    availableKeywordLimit,
    activeKeywordTrackingData,
    activeRegionTab,
  } = useSelector(({ dashboard }) => dashboard)

  const { countries, locations } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const { data: keywordsData, saveRegionalLocationSuccess } = useSelector(({ keywordsTracking }) => keywordsTracking)

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)

  const localKeywords =
    (activeSearchData &&
      activeSearchData.keywords &&
      activeSearchData.keywords.length &&
      activeSearchData.keywords.map((item) => {
        return { name: item }
      })) ||
    []
  let interval = []
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(getCountries())
      dispatch(getLocations())
      dispatch(getAvailableKeywordLimit())
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        dispatch(patchUserDomainsDashboard())
        dispatch(getUserData())
        setTimeout(() => {
          dispatch(patchUserDomainsDashboard())
          dispatch(getTrackKeywords(JSON.parse(data)._id))

          dispatch(getBacklinksStats())
          dispatch(getUserData())
          dispatch(getSiteOutageData())
          dispatch(getAvgPositionKeywordApi())
        }, 2000)
        let intervalId = setInterval(() => {
          dispatch(getTrackKeywords(JSON.parse(data)._id))
          dispatch(getUserData())
        }, 5000)
        interval.push(intervalId)
      }
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    let country =
      countries && countries.length
        ? countries.map((item) => {
            return { label: item, value: item }
          })
        : []
    setCountriesOptions(country)
  }, [countries])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion
          })
        : []

    if (data && data.length) {
      let stateData = _.uniqBy(data, 'state')
      let stateOptions = stateData.map((item) => {
        return { label: item.state, value: item.state }
      })
      setStateOptions(stateOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion])

  useEffect(() => {
    let data =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === selectedRegion && item.state === selectedState
          })
        : []

    if (data && data.length) {
      let cityOptions = data.map((item) => {
        return { label: item.city, value: item.city }
      })
      setCityOptions(cityOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState])

  useEffect(() => {
    if (saveRegionalLocationSuccess) {
      const data = localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        dispatch(getUserData())
      }
      setActiveRegionalDBRegion(selectedRegion)
      setCurrentRegionalState(selectedState)
      setCurrentRegionalCity(selectedCity)
      setSelectedRegion('')
      setSelectedState('')
      setSelectedCity('')
      setTimeout(() => {
        handleClose()
        setIsRegionChanged(false)
      }, 4000)
    }
  }, [saveRegionalLocationSuccess])

  useEffect(() => {
    let activeData
    if (activeSearchData) {
      setActiveRegionalDBRegion(activeSearchData?.regionalRegion)
      setCurrentRegionalState(activeSearchData?.regionalState)
      setCurrentRegionalCity(activeSearchData?.regionalCity)
    }
    if (keywordsData && keywordsData?.length && keywordsData?.length && activeKeywordData) {
      // totalKeywordData = activeKeywordData
      let isPreviousKeywordsExistOnCurrentDate = true
      let keywordDoNotExistCurrent = []
      activeData = JSON.parse(JSON.stringify(activeKeywordData))
      keywordsData?.forEach((element) => {
        if (element?.url === activeData?.url) {
          element?.results.map((innerElement) => {
            let found = false
            activeData?.data?.[activeData?.data.length - 1]?.dataset.forEach((activeKeyword) => {
              if (innerElement.keyword === activeKeyword.keyword) {
                found = true
                activeKeyword['topDomains'] = innerElement?.topDomains
                activeKeyword['noOfResult'] = activeKeyword?.searchVolumn
                activeKeyword['livePosition'] = activeKeyword?.position?.[0]?.position
                activeKeyword['trendsChartData'] = activeKeyword?.trendsData || []
                activeKeyword['searchVolume'] = activeKeyword?.volumnData
                activeKeyword['cpc'] = activeKeyword?.volumnData
              } else {
              }
            })
            if (!found) {
              isPreviousKeywordsExistOnCurrentDate = false
              keywordDoNotExistCurrent.push(innerElement.keyword)
              function checkNestedJSON(obj) {
                return _.isObject(obj)
                  ? _.some(obj, (value) => checkNestedJSON(value))
                  : _.includes(obj, innerElement.keyword)
              }
              const result = _.findIndex(activeData?.data, (obj) => checkNestedJSON(obj))
              activeData?.data?.[result]?.dataset.forEach((activeKeyword) => {
                if (innerElement.keyword === activeKeyword.keyword) {
                  activeKeyword['topDomains'] = innerElement?.topDomains
                  activeKeyword['noOfResult'] = activeKeyword?.searchVolumn
                  activeKeyword['livePosition'] = activeKeyword?.position?.[0]?.position
                  activeKeyword['trendsChartData'] = activeKeyword?.trendsData || []
                  activeKeyword['searchVolume'] = activeKeyword?.volumnData
                  activeKeyword['cpc'] = activeKeyword?.volumnData
                } else {
                }
              })
            }
          })
        }
      })
      if (!isPreviousKeywordsExistOnCurrentDate) {
        const allDatset = _.flatMap(activeData?.data, 'dataset')
        const uniqueKeywordsDetails = _.uniqBy(allDatset, 'keyword')
        const lastDetails = _.last(activeData?.data).dataset
        uniqueKeywordsDetails.forEach((details) => {
          if (!_.some(lastDetails, details)) {
            let checkKeyword = _.includes(keywordDoNotExistCurrent, details.keyword)
            if (checkKeyword) {
              lastDetails.push(details)
            }
          }
        })
      }
      activeSearchData &&
        activeSearchData.regions &&
        activeSearchData.regions.length &&
        activeSearchData.regions.map((outerItem) => {
          activeData?.data?.[activeData?.data.length - 1]?.dataset.map((item) => {
            let currentRegionData =
              item?.regions &&
              item?.regions.length &&
              item?.regions.findLast(
                (element) =>
                  element.country === outerItem.country &&
                  element.state === outerItem.state &&
                  element.city === outerItem.city,
              )
            let data = checkPositionForKeyword(
              activeKeywordData.data,
              item.name,
              currentRegionData?.position || 0,
              currentRegionData,
            )
            item['droppedPosition'] = data?.droppedPosition
            item['increasedPosition'] = data?.increasedPosition
            item['samePosition'] = data?.samePosition
            item['position'] = data?.position
            item['average'] = data?.average
          })
        })

      activeSearchData?.keywords?.length &&
        activeSearchData?.keywords.forEach((element) => {
          let found = false
          activeData?.data?.[activeData?.data.length - 1]?.dataset.map((item) => {
            if (item?.keyword === element) {
              found = true
            }
          })
          if (!found) {
            activeData?.data?.[activeData?.data.length - 1]?.dataset.push({ keyword: element })
          }
        })
      let filteredKeywords = activeData?.data?.[activeData?.data.length - 1]?.dataset.filter((element) => {
        let flag = false
        localKeywords.forEach((element1) => {
          if (element1?.name === element.keyword) {
            flag = true
          }
        })
        return flag
      })
      setKeywordTracker(filteredKeywords)
      setIsTrackKeywordLoaded(true)
    } else {
      setKeywordTracker(activeData)
    }
  }, [keywordsData, activeKeywordData, usersDomain, activeSearchData, keywordRemoveSuccess])

  useEffect(() => {
    return () => {
      interval.map((id) => {
        clearInterval(id)
      })
    }
  }, [])

  useEffect(() => {
    if (settingsAddKeywordSuccess) {
      handleClose()
      const data = localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        setTimeout(() => {
          dispatch(getUserData())
          dispatch(getTrackKeywords(JSON.parse(data)._id))
        }, 2000)
      }
    }
  }, [settingsAddKeywordSuccess])

  useEffect(() => {
    if(keywordLimitLoader){
      return
    }else if(!keywordLimitLoader && keywordLimitData && Object?.keys(keywordLimitData)?.length){
      if(keywordLimitData?.limitAvailable){
        onAddKeyword()
        dispatch({type:CLEAR_KEYWORD_LIMIT})
      }else {
        if(keywordLimitData && Object.keys(keywordLimitData)?.length && !keywordLimitData?.limitAvailable){
          setOpenKeywordLimitExceed(true)
          handleClose()
        }
      }
    }else {
      if(!keywordLimitLoader && keywordLimitError && Object.keys(keywordLimitError)?.length){
        message.error(keywordLimitError?.message)
        dispatch({type:CLEAR_KEYWORD_LIMIT})
      }
    }
  }, [keywordLimitLoader, keywordLimitData, keywordLimitError])

  const onAddKeyword = async () => {
    if (addKeyword) {
      let splittedKeyword = addKeyword.split(',')
      let finalKeyword = splittedKeyword.filter((item) => item !== '')
      let trimmedKeyword = finalKeyword.map((keyword) => keyword?.trim())
      let uniqueKeyword = _.uniq(trimmedKeyword)
      let currentUrl = usersDomain[activeLink].items?.[0]?.information?.seoCheckup?.redirect_url
      let website = usersDomain[activeLink].items[0].id
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)._id) {
        let obj = { keywords: uniqueKeyword, url: currentUrl, website: website }
        dispatch(addKeywordSettings(obj))
      }

      if (!settingsAddKeywordLoader) {
        navigate(paths.keywordPositionTracker)
      }
    }
  }

  const checkKeywordLimit = () => {
    if (addKeyword) {
      let splittedKeyword = addKeyword.split(',')
      let finalKeyword = splittedKeyword.filter((item) => item !== '')
      let trimmedKeyword = finalKeyword.map((keyword) => keyword?.trim())
      let uniqueKeyword = _.uniq(trimmedKeyword)
      let keywordsExist = false 
      uniqueKeyword.forEach(keyword => {
        if(uniqueKeyword?.length > 1){
          if(activeSearchData.keywords.includes(keyword.toLowerCase())){
            keywordsExist = true
            setKeywordError(`* Few keywords are already added, please remove them to proceed adding rest others`)
          }
        }else{
          if(activeSearchData.keywords.includes(keyword.toLowerCase())){
            keywordsExist = true
            setKeywordError(`* Keyword ${keyword} is already added`)
          }
        }
      })
      if(!keywordsExist){
        dispatch(getKeywordLimit({ keywords: uniqueKeyword, limitOption: LimitOption.KEYWORD }))
      }
    }
  }

  const handleClose = () => {
    setOpenModal(false)
    setKeywordsOpen(false)
    setAddKeyword("")
  }

  const handleKeywordShow = () => {
    setKeywordsOpen(true)
    setAddKeyword("")
    setKeywordError("")
  }

  const message =
    'Please select the location of your primary target audience - our keyword tracking tool will retrieve region specific data from the appropriate keyword database'

  const KeywordMessage =
    'Add your desired keywords for here. You can add multiple keywords separated by comma'

  useEffect(() => {
    if (usersDomain && usersDomain.length && keywordsData && keywordsData.length) {
      let data = keywordsData.filter((element) => {
        return (
          activeLink !== undefined &&
          usersDomain[activeLink] &&
          usersDomain[activeLink].items &&
          usersDomain[activeLink].items.length &&
          usersDomain[activeLink].items?.[0]?.information?.seoCheckup?.redirect_url === element.url
        )
      })
  
      if (data.length) {
        usersDomain[activeLink].items[0].trackingData = data[0].results
        usersDomain[activeLink].items[0].region = data[0].region
      } 
    }
  }, [keywordsData, activeLink, usersDomain])

  useEffect(() => {
    if((!removeLoading && keywordRemoveSuccess) || (!settingsAddKeywordLoader && settingsAddKeywordSuccess)){
      dispatch(getAvailableKeywordLimit())
      dispatch({type: CLEAR_ADD_KEYWORDS_SETTINGS})
      dispatch({type: CLEAR_REMOVE_KEYWORD})
    }
  }, [removeLoading, settingsAddKeywordLoader,keywordRemoveSuccess, settingsAddKeywordSuccess])

  const saveRegionConfiguration = async () => {
    let region = []
    setIsRegionChanged(true)
    let currentUrl = usersDomain[selectedNavTab].items?.[0]?.information?.seoCheckup?.redirect_url
    let newLocation = await getLocationData(selectedRegion, selectedState, selectedCity)
    region.push(newLocation)
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      dispatch(
        saveRegionalLocation({
          userId: JSON.parse(data)._id,
          hostname: currentUrl,
          location: {
            city: selectedCity,
            state: selectedState,
            country: selectedRegion,
            iso3: newLocation?.iso3,
            lat: newLocation.lat,
            long: newLocation.long,
            region: newLocation.region,
            regions: region,
          },
          type: 'keyword-tracker',
        }),
      )
      keywordTracker.map((data) => {
        data.livePosition = ''
        data.noOfResult = ''
        data.topDomains = ''
      })
      setActiveRegionalDBRegion('')
      setCurrentRegionalCity('')
      setCurrentRegionalState('')
    }
  }

  const getLocationData = (country, state, city) => {
    let region = 'CA'
    let long = '43.7417'
    let lat = '-79.3733'
    let iso3 = 'CAN'

    let locationData =
      locations && locations.length
        ? locations.filter((item) => {
            return item.country === country && item.city === city && item.state === state
          })
        : []

    if (locationData && locationData.length) {
      region = locationData[0].countryShortCodeISO2
      long = locationData[0].long
      lat = locationData[0].lat
      iso3 = locationData[0].countryShortCodeISO3
    }

    return { region, long, lat, iso3, country, state, city }
  }

  let keywordDataInitial =
    activeKeywordTrackingData &&
    activeKeywordTrackingData?.length &&
    activeKeywordTrackingData?.filter((item) => {
      return item._id?.websiteId === usersDomain?.[activeLink]?.items?.[0]?.id
    })

  let keywordDataFiltered = keywordDataInitial?.[0]?.regions.filter((item) => {
    if (
      activeSearchData?.regions?.[activeRegionTab]?.region === item?.region &&
      activeSearchData?.regions?.[activeRegionTab]?.country === item?.country &&
      activeSearchData?.regions?.[activeRegionTab]?.state === item?.state &&
      activeSearchData?.regions?.[activeRegionTab]?.city === item?.city &&
      activeSearchData?.regions?.[activeRegionTab]?.address === item?.address
    ) {
      return item || {}
    }
  })

  let keywordTrackingData = keywordDataFiltered?.[0]?.keywords?.map((item) => {
    return {
      name: item?.keyword,
      position: item?.positionData?.position,
      unchanged: item?.unchanged,
      increased: item?.increased,
      decreased: item?.decreased
    }
  })

  let filteredKeywords = keywordTrackingData?.filter((keywordObj) => {
    let found = activeSearchData?.keywords?.some((keyword) => keyword === keywordObj?.name)
    if (found) {
      return keywordObj
    }
  })

  filteredKeywords?.map((keyword) => {
    if (keyword?.unchanged) {
      unchangedKeywords += 1
    } else if (keyword?.increased) {
      increasedKeywords += 1
    } else {
      if(keyword?.decreased){
        decreasedKeywords += 1
      }
    }
  }) 

  return (
    <>
      <Modal
        show={openTrendsModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setOpenTrendsModal(false)
        }}
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
      >
        <Modal.Header>
          <h3>Trends Data</h3>
        </Modal.Header>
        <Modal.Body>
          <TrendsAreaChart chartData={[]} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={() => {
              setOpenTrendsModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
         show={openKeywordLimitExceed}
         aria-labelledby="contained-modal-title-vcenter"
         centered
         toggle={() => {
          setOpenKeywordLimitExceed(!openKeywordLimitExceed)
          dispatch({type: CLEAR_KEYWORD_LIMIT})
        }}
         onClose={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({type: CLEAR_KEYWORD_LIMIT})
        }}
         onHide={() => {
          setOpenKeywordLimitExceed(false)
          dispatch({type: CLEAR_KEYWORD_LIMIT})
        }}
         style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)'}}
         className="br-15 pe-0"
       >
          <Modal.Header>
            <h3 className='mb-0 f-bold'>Keyword Tracking Limit Reached</h3>
          </Modal.Header>        
        <Modal.Body>
          You've maximized the keyword tracking capabilities of your current plan. To continue expanding your insights and to add more keywords, an upgrade is necessary. Our plans are designed to scale with your growing needs, offering more keywords and location tracking options.
          <br /><br />
          Current Plan: {keywordLimitData?.keywordsLimit} Keywords
          <br /><br />
          Upgrade Now and unlock your full SEO potential.
          <br /><br />
          Thank you for using our services to enhance your digital marketing efforts. We're committed to supporting your growth every step of the way.
        </Modal.Body>
         <Modal.Footer className={`d-flex flex-row ${userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? "justify-content-end" : 'justify-content-between'}`}>
         {userData?.plan?.name === PLAN.PREMIUM_PLAN.name ? null : <Button
             color="primary"
             variant="primary"
             onClick={() => {
              navigate(paths.upgradePlan)
              setOpenKeywordLimitExceed(false)
              dispatch({type: CLEAR_KEYWORD_LIMIT})
             }}
           >
             Upgrade your plan
           </Button>}

           <Button
             color="danger"
             variant="danger"
             onClick={() => {
              setOpenKeywordLimitExceed(false)
              dispatch({type: CLEAR_KEYWORD_LIMIT})
             }}
           >
             Close
           </Button>
         </Modal.Footer>
       </Modal>

      <Alert
        variant="primary"
        className="alert-outline-coloured"
        key="meta-title"
      >
        <div className="alert-icon" />
        <div className="alert-message">
          <span className='alert-for-mobile'>
              <p className="mb-0">
                You are using{' '}
                {availableKeywordLimit?.keywordsLimit - availableKeywordLimit?.availableKeywordsLimit ||
                  0}{' '}
                out of {availableKeywordLimit?.keywordsLimit} maximum keywords limit for all your websites. If you need
                more keywords. please consider to:&nbsp;
                <Link to={''} onClick={() => dispatch(openUpgradePlanModal(true))} className={'text-danger'}>
                  Upgrade your plan
                </Link>
              </p>
          </span>
        </div>
      </Alert> 

      <Card className='mb-0'>
        <Card.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0}>
            <Nav
              variant="tabs"
              onSelect={(selectedKey) => {
                dispatch(changeActiveLink(parseInt(selectedKey)))
                setSelectedNavTab(parseInt(selectedKey))
              }}
            >
              {usersDomain && usersDomain?.length ? (
                <>
                  {usersDomain?.map((item, index) => {
                    return <MainUrlNav index={index} item={item} key={index+1} />
                  })}
                  </>
              ) : null }
            </Nav>
            <ModalContainer
              show={openModal}
              onHide={handleClose}
              toggleShow={() => setOpenModal(!openModal)}
              showRegional={openModal}
              headerTitle={'Regional database:'}
              title={
                currentRegionalCity && currentRegionalState && activeRegionalDBRegion && !isRegionChanged ? (
                  `${currentRegionalCity}, ${currentRegionalState}, ${activeRegionalDBRegion}`
                ) : (
                  <></>
                )
              }
              message={message}
              titleStyles={{ fontWeight: '500', fontSize: '16px' }}
            >
              <div className="pl-1 pt-3">
                <h6>Country</h6>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={countriesOptions}
                  isSearchable
                  onChange={(e) => {
                    if (selectedRegion !== e.value) {
                      setStateOptions([])
                      setCityOptions([])
                      setSelectedState('')
                      setSelectedCity('')
                    }
                    setSelectedRegion(e.value)
                  }}
                />
              </div>
              {stateOptions && stateOptions.length ? (
                <div className="pl-1 pt-3">
                  <h6>Province / State</h6>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={stateOptions}
                    isSearchable
                    onChange={(e) => {
                      if (selectedState !== e.value) {
                        setCityOptions([])
                        setSelectedCity('')
                      }
                      setSelectedState(e.value)
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {cityOptions && cityOptions.length ? (
                <div className="pl-1 pt-3">
                  <h6>City</h6>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={cityOptions}
                    isSearchable
                    onChange={(e) => {
                      setSelectedCity(e.value)
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-center mt-5 mb-55">
                <div>
                  <Button
                    onClick={() => saveRegionConfiguration()}
                    disabled={!selectedRegion?.length || !selectedState?.length || !selectedCity?.length}
                    className="w-20 btn-primary"
                    variant="primary"
                  >
                    {isRegionChanged ? (
                      <Spinner animation="border" key={1} className="me-2" size="sm" />
                    ) : (
                      <></>
                    )}{' '}
                    Change
                  </Button>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div>
                  <Button
                    onClick={() => setOpenModal(!openModal)}
                    className="w-20 mr-20 btn-primary"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </ModalContainer>
            <ModalContainer
              show={keywordsOpen}
              onHide={handleClose}
              toggleShow={() => setKeywordsOpen(!keywordsOpen)}
              showRegional={keywordsOpen}
              headerTitle={'Add keywords'}
              message={keywordsOpen ? KeywordMessage : message}
              handleKeywordShow={keywordsOpen}
              titleStyles={{ fontWeight: '400', fontSize: '16px' }}
              setKeywordsOpen={setKeywordsOpen}
            >
              <Form.Control
                as={'input'}
                rows={5}
                placeholder="Enter Keywords"
                onKeyDown={(event) => {
                  if(event.key === "Enter"){
                    checkKeywordLimit()
                  }
                }}
                onChange={(event) => {
                  setKeywordError("")
                  setAddKeyword(event?.target?.value)}}
                value={addKeyword}
              />
              <span style={{color: 'red', fontSize:'12px'}}>{keywordError}</span>
              <div className="d-flex justify-content-center mb-2 mt-3">
                <Button variant="primary" className='d-flex flex-row justify-content-center align-items-center' disabled={!addKeyword || settingsAddKeywordLoader || keywordLimitLoader} onClick={() => checkKeywordLimit()}>
                  {settingsAddKeywordLoader || keywordLimitLoader ? (
                    <Spinner animation="border" key={1} className="me-2" size="sm" />
                  ) : (
                    <></>
                  )}{' '}
                  Add Keyword{' '}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={() => handleClose()}
                  className="w-20 mr-20 btn-primary"
                  variant="danger"
                >
                  Cancel
                </Button>
              </div>
            </ModalContainer>

            <ModalContainer
              show={topDomainFlag}
              toggleShow={() => setTopDomainFlag(!topDomainFlag)}
              title={`${topDomainList?.keyword}`}
              bodyClassName="m-3"
              titleStyles={{ fontWeight: '500', fontSize: '16px' }}
              headerTitle="Top Domains for: "
            >
              <Table bordered>
                <thead>
                  <th>Domain</th>
                  <th>URL</th>
                </thead>
                <tbody>
                  {topDomainList?.topDomains?.length ? (
                    <>
                      {topDomainList?.topDomains.slice(0, 20).map((item) => {
                        return (
                          <tr key={`domain-${item?.domain}`}>
                            <td>{item.domain}</td>
                            <td className="view-top-domain">
                              <a href={`${item.link}`}>Visit URL</a>
                            </td>
                          </tr>
                        )
                      })}{' '}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            </ModalContainer>

            <Tab.Content>

            <Row style={{ marginTop: 22 }}>
                {usersDomain && usersDomain?.length ? (
                    <>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Average Position </h4>

                                {filteredKeywords?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                      <VerticalAlignMiddleOutlined style={{ fontSize: '175%' }} />
                                    </div>
                                    <div>
                                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                        {(checkPositionForUserEntered(filteredKeywords || []) &&
                                          checkPositionForUserEntered(filteredKeywords || []).average.toFixed(2))} &nbsp;{' '}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Increased </h4>
                                {filteredKeywords?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginBottom: 4 }}>
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleUp}
                                        color={'#20c997'}
                                        className="align-middle"
                                      />
                                    </div>
                                    <div>
                                      <h3
                                        style={{ color: '#20c997', marginLeft: 5, marginTop: 5 }}
                                        className="mb-2 f-bold"
                                      >
                                        {increasedKeywords} &nbsp;{' '}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Unchanged </h4>
                                {filteredKeywords?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faExchangeAlt}
                                        className="align-middle"
                                      />
                                    </div>
                                    <div>
                                      <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                        {unchangedKeywords} &nbsp;{' '}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" xl className="d-flex">
                        <Card className="flex-fill">
                          <Card.Body className=" py-4">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h4 className="card-subtitle text-muted"> Dropped </h4>
                                {filteredKeywords?.length ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginBottom: 4 }}>
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleDown}
                                        color={'#d9534f'}
                                        className="align-middle"
                                      />
                                    </div>
                                    <div>
                                      <h3
                                        style={{ color: '#d9534f', marginLeft: 5, marginTop: 5 }}
                                        className="mb-2 f-bold"
                                      >
                                        {decreasedKeywords} &nbsp;
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <h3 style={{ marginTop: 5, fontWeight:'400' }}>No Data</h3>
                                )}
                              </div>
                              <div className="d-inline-block ms-3" />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  ) : null }
              </Row>
              
              {usersDomain && usersDomain.length && isTrackKeywordLoaded ? (
                usersDomain.map((item, index) => {
                  return (
                    <Tab.Pane eventKey={index} key={`trackerContent-${index + 1}`}>
                      <Row>
                        <Col>
                        <Card className="archived-report mb-0">
                          <Card.Header className='pb-0'>
                            <Card.Title tag="h5" className="keyword-header d-flex flex-wrap justify-content-between gap-3">
                              <div>
                                <h3 className='f-bold'>Keywords Overview</h3>
                                <h6 className="card-subtitle text-muted">
                                  {item.items.length && item.items[0].url}
                                </h6>
                              </div>
                              <div className='d-flex flex-wrap gap-2'> 
                                <Button style={{height:'fit-content'}} className="keyword-header-buttons" onClick={handleKeywordShow}>
                                  <span className="keyword-header-buttons-content">
                                    <PlusCircleOutlined />
                                    &nbsp; Add Keywords
                                  </span>
                                </Button>
                                <Button 
                                  style={{height:'fit-content'}}
                                  className="keyword-header-buttons" 
                                  onClick={() => {
                                    navigate(paths.dashboardSettings, {
                                      state: { activeTab: '3' },
                                    })
                                  }}
                                  >
                                  <span className="keyword-header-buttons-content">
                                    <PlusCircleOutlined />
                                    &nbsp; Add Region
                                  </span>
                                </Button>                              
                              </div>
                            </Card.Title>
                          </Card.Header>

                          <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            <Card.Body>
                              <TopKeywordsTable
                                onClick={() => {
                                  navigate(paths.dashboardSettings, {
                                    state: { activeTab: '3' },
                                  })
                                }}
                              />
                            </Card.Body>
                          </Tab.Container>
                        </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  )
                })
              ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      alignSelf: 'center',
                      flexDirection: 'row',
                      minHeight: 380,
                    }}
                  >
                    <div className="text-center">
                      <Loader />
                    </div>
                  </div>
              )}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  )
}
export default KeywordPositionTracker

import React from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'

const FaviconCheckerTest = ({ data }) => {
  return (
    <>
      {data && data?.faviconCheckerTest ? (
        <>
          <div className="vr-url-block">
            {data.faviconCheckerTest?.data?.status === 1 ? (
              <img
                className="d-none d-md-block d-xl-block"
                style={{ height: 15, marginTop: 6, marginRight: 5 }}
                src={tickIcon}
              />
            ) : data.faviconCheckerTest?.data?.status === 2 ? (
              <img
                className="d-none d-md-block d-xl-block"
                style={{ height: 15, marginTop: 6, marginRight: 5 }}
                src={warningIcon}
              />
            ) : (
              <img
                className="d-none d-md-block d-xl-block"
                style={{ height: 15, marginTop: 6, marginRight: 5 }}
                src={crossIcon}
              />
            )}
            <span>
              <h5>{data?.url}</h5>
            </span>
          </div>
          <div className="vr-detail-block">
            <div>
              <p>{data.faviconCheckerTest?.data?.result}</p>{' '}
            </div>
          </div>
          <div className="vr-detail-block">
            {data?.faviconCheckerTest?.data?.status === 1 ? (
              <>
                {' '}
                <img
                  className="mb-3"
                  height={20}
                  width={20}
                  alt=""
                  src={
                    `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=16&url=${data.url}` ||
                    data?.faviconCheckerTest?.data?.favicon ||
                    data?.faviconCheckerTest?.data?.favicon ||
                    ''
                  }
                />
              </>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default FaviconCheckerTest

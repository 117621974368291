import { useEffect, useState } from 'react'

import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Button, Row, Col, Spinner } from 'reactstrap'
import { CommonAlert } from 'components/uiCore'
import { useDispatch, useSelector } from 'react-redux'
import { whitelistBrokenUrl } from '../../../redux/actions/dashboard'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const BrokenLinks = ({ data, showDetail, testType = "" }) => {
  const [brokenLinkFlag, setBrokenLinkFlag] = useState(false)
  const [rowWhitelisted, setRowWhitelisted] = useState('')
  const [internalBorkenLinkList, setInternalBrokenLinkList] = useState([])

  const loadingWhiteList = useSelector(({ dashboard }) => dashboard.loadingWhiteList)
  const whiteListURLSuccess = useSelector(({ dashboard }) => dashboard.whiteListURLSuccess)

  const dispatch = useDispatch()

  const columns = [{
    id:'brokenLinks',
    displayName:'Broken Links'
  }]
  const brokenLinksCsv = internalBorkenLinkList?.map(item => {
    return {
      brokenLinks: item
    }
  })

  useEffect(() => {
    if (data?.brokenLinks?.data?.internalBrokenLinkList?.length) {
      setInternalBrokenLinkList(data?.brokenLinks?.data?.internalBrokenLinkList)
    }
  }, [])

  useEffect(() => {
    if (whiteListURLSuccess && typeof rowWhitelisted === 'number') {
      let originallist = data?.brokenLinks?.data?.internalBrokenLinkList
      originallist = originallist.filter((_, index) => index != rowWhitelisted)
      setInternalBrokenLinkList([])
      setRowWhitelisted('')
      setInternalBrokenLinkList(originallist)
    }
  }, [whiteListURLSuccess])

  const addToWhitelist = (url, index) => {
    let payload = {
      brokenLink: url,
      urlDataId: data?._id,
      url: data?.information?.seoCheckup?.redirect_url,
      user: data?.user,
      brokenId: data?.brokenLinks?._id,
    }
    setRowWhitelisted(index)
    dispatch(whitelistBrokenUrl(payload))
  }

  return (
    <>
      {data && data?.brokenLinks ? (
        <>
          <div className="vr-url-block">
            <div className="d-none d-md-block d-xl-block">
              {!data?.brokenLinks?.data?.testResults?.status ? (
                ''
              ) : data?.brokenLinks?.data?.testResults?.status === 3 ? (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
              ) : data?.brokenLinks?.data?.testResults?.status === 2 ? (
                <img
                  style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }}
                  src={warningIcon}
                />
              ) : (
                <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
              )}
            </div>
            <div>
              <h5>{data?.url}</h5>
            </div>
          </div>
          <div className="vr-detail-block">
            <p>
              {data?.brokenLinks?.data?.testResults ? (
                <>{data?.brokenLinks?.data?.testResults?.message || ''}</>
              ) : (
                <></>
              )}
            </p>
            {/* {data?.siteMapData?.data?.siteMapUrl?.length ? (
                            <div className="mb-3">
                                {data?.siteMapData?.data?.siteMapUrl?.map((item) => {
                                    return (
                                        <div>
                                            <span>{item}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : <></>} */}
            {showDetail && data?.brokenLinks?.data?.testResults?.status === 3 ? (
              <p 
                className="text-primary cursor-pointer" 
                onClick={() => {
                  if (testType === "") {
                    setBrokenLinkFlag(!brokenLinkFlag)
                  } else {
                    dispatch(openRegisterModal(true))
                  }
                }}>
                {' '}
                See Details
              </p>
            ) : (
              <></>
            )}
          </div>
          <ModalHOC
            show={brokenLinkFlag}
            toggleShow={() => setBrokenLinkFlag(!brokenLinkFlag)}
            title={'Internal Broken Links Checker Test'}
            bodyClassName="m-3"
            titleStyles={{ fontWeight: '600', fontSize: '22px' }}
            showDownload
            columns={columns}
            datas={brokenLinksCsv}
            fileName='Internal Broken Links Checker Test'
          >
            <Row className="mt-3">
              <Col>
                <CommonAlert
                  variant={'warning'}
                  key={'brokenLinks-disclaimer'}
                  alertText={
                    <div>
                      <b>Disclaimer:</b> Our system has found the following possible broken links on your
                      website. Click on each link to confirm if the link is broken. If the link is not broken
                      delete the entry from the Broken Link. <br /> The reason for false positives is that our
                      live web crawler may be blocked when we open that URL.
                    </div>
                  }
                />
                <br />
                <br />
                <p>Here is a list of all your broken links:</p>
                <br />
                {internalBorkenLinkList?.length &&
                  internalBorkenLinkList.map((item, index) => {
                    return (
                      <>
                        <li style={{ height: 47 }}>
                          <a href={item} target="_BLANK">
                            {' '}
                            {item}
                          </a>{' '}
                          <Button
                            style={{ float: 'right' }}
                            color="danger"
                            variant="danger"
                            onClick={() => {
                              addToWhitelist(item, index)
                            }}
                          >
                            {' '}
                            {loadingWhiteList && rowWhitelisted === index ? (
                              <Spinner
                                size="sm"
                                animation="border"
                                key={1}
                                variant={'primary'}
                                className="me-2"
                              />
                            ) : (
                              <></>
                            )}{' '}
                            Delete{' '}
                          </Button>
                        </li>
                        <hr />
                      </>
                    )
                  })}
              </Col>
            </Row>
          </ModalHOC>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default BrokenLinks

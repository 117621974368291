import { all, fork } from 'redux-saga/effects'
import startFreeTrialSaga from './freeTrial/startFreeTrial'
import registerSaga from './register'
import freeToolsSaga from './freeTools'
import dashboardSaga from './dashboard'
import keywordsTrackingSaga from './keywordsTracking'
import backlinksDataSaga from './backlinks'
import TopKeywordsSaga from './topKeywords'
import brokenLinkListSaga from './brokenLinks'
import templateSaga from './reporting/template'
import reportSaga from './reporting/generateReport'

export default function* rootSaga() {
  yield all([
    fork(startFreeTrialSaga),
    fork(registerSaga),
    fork(freeToolsSaga),
    fork(dashboardSaga),
    fork(keywordsTrackingSaga),
    fork(backlinksDataSaga),
    fork(TopKeywordsSaga),
    fork(brokenLinkListSaga),
    fork(templateSaga),
    fork(reportSaga),
  ])
}

import React from 'react'
import { Card, Col, Table, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import * as moment from 'moment'
import { Spinner } from 'reactstrap'
import ActivityGraph from './ActivityGraph'

const TwitterActivity = () => {
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink)
  const item = usersDomain && activeLink !== undefined && usersDomain[activeLink]
  let chartData = []
  let mentionedData = []
  let hashtagData = []
  let historyData = []

  item?.items.forEach((element) => {
    let data1 = element.socialMediaInstagramData?.[0]?.data?.label5?.[0].replace(',', '') || 0
    let data2 = element.socialMediaInstagramData?.[0]?.data?.label5?.[1].replace(',', '') || 0
    let data3 = element.socialMediaInstagramData?.[0]?.data?.label5?.[2].replace(',', '') || 0
    chartData.push({
      name: 'Follower',
      y: parseInt(data1) || 0,
      x: moment(element.createdAt).format('MM-DD'),
    })
    chartData.push({
      name: 'Following',
      y: parseInt(data2) || 0,
      x: moment(element.createdAt).format('MM-DD'),
    })
    chartData.push({
      name: 'Engagements',
      y: parseInt(data3) || 0,
      x: moment(element.createdAt).format('MM-DD'),
    })
  })

  item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.gradeInfo &&
    item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.gradeInfo.forEach((element) => {
      if (element.length > 2 && element.startsWith('@')) {
        mentionedData.push(element.split(' '))
      }
      if (element.length > 2 && element.startsWith('#')) {
        hashtagData.push(element.split(' '))
      }
    })

  item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6 &&
    item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6.forEach((element, index) => {
      if (index % 5 === 0) {
        historyData.push({
          date: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6[index],
          day: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6[index + 1],
          followers: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6[index + 2],
          following: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6[index + 3],
          uploads: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label6[index + 4],
        })
      }
    })

  return (
    <>
      <Card className="flex-fill">
        <Card.Header>
          <Card.Title tag="h2" className="mb-0">
            Instagram Analysis
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <div className="flex">
                <ActivityGraph chartData={chartData} />
              </div>
            </Col>
            <Col>
              <br />
              <hr />
              <div className="flex">
                <h6>Followers</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[0],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[0] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Following</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[1],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[1] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Engagement Rate</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[2],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[2] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Followers Growth Rate</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[3],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[3] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Following Growth Rate</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[4],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[4] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Likes Rate</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[5],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[5] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Comments Rate</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[6],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[6] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Uploads</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[7],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[7] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Average Likes</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[8],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[8] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Average Comments</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[9],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[9] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>
              <hr />
              <div className="flex">
                <h6>Average Cost per post</h6>
                <p>
                  {item?.items?.[0]?.socialMediaInstagramData ? (
                    <>
                      {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7 ? (
                        <>
                          {item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.length ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[10],
                              }}
                            />
                          ) : item?.items?.[0]?.socialMediaInstagramData?.[0]?.data?.label7?.[10] === '' ? (
                            ''
                          ) : (
                            <>
                              <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                            </>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                    </>
                  )}
                </p>
              </div>

              <hr />
              <div className="flex">
                <h6>Top Mentions</h6>
                {mentionedData && mentionedData.length
                  ? mentionedData.map((element) => {
                      return (
                        <Row>
                          <Col>{element?.[0]}</Col>
                          <Col>{element?.[2]}</Col>
                        </Row>
                      )
                    })
                  : ''}
              </div>
            </Col>
          </Row>
          <hr />
          <div className="flex">
            <h6>Top Hastags</h6>
            {hashtagData && hashtagData.length
              ? hashtagData.map((element) => {
                  return (
                    <Row>
                      <Col>{element?.[0]}</Col>
                      <Col>{element?.[2]}</Col>
                    </Row>
                  )
                })
              : ''}
          </div>

          <hr />
          <div className="flex">
            <h6>Hisotry Data</h6>
            <Table striped className="table-responsive table">
              <tbody>
                {historyData && historyData.length
                  ? historyData.map((element) => {
                      return (
                        <tr>
                          <td>{element?.date}</td>
                          <td>{element?.day}</td>
                          <td>{element?.followers}</td>
                          <td>{element?.following}</td>
                          <td>{element?.uploads}</td>
                        </tr>
                      )
                    })
                  : ''}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default TwitterActivity

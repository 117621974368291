import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  WHITE_LABEL_TEMPLATE_CREATE_START,
  WHITE_LABEL_TEMPLATE_LIST_START,
  WHITE_LABEL_TEMPLATE_DELETE_START,
  GENERATE_WHITE_LABEL_REPORT_REQUEST,
} from 'redux/actionType'
import {
  createTemplateError,
  createTemplateSuccess,
  deleteTemplateError,
  deleteTemplateSuccess,
  generateWhiteLabelReportError,
  generateWhiteLabelReportSuccess,
  listTemplateError,
  listTemplateSuccess,
} from 'redux/actions/reporting/template'
import {
  deleteRequestWithToken,
  getRequest,
  postLocalRequestWithToken,
} from 'constants/httpHelperService'

// Start API call list
// List Template
const listTemplateApi = async (input) => {
  return await getRequest(`reports-template/get-all`)
    .then((res) => res)
    .catch((error) => error)
}

// Start Create Template
const createTemplateApi = async (input) => {
  return await postLocalRequestWithToken(`reports-template/create`, input)
    .then((res) => res)
    .catch((error) => error)
}

// Delete api call
const deleteTemplateApi = async (input) => {
  return await deleteRequestWithToken(`reports-template/delete?templateId=${input.templateId}`)
    .then((res) => res)
    .catch((error) => error)
}
// End api call list

function* listTemplateAsync(action) {
  try {
    const templateData = yield call(listTemplateApi, action.payload)
    yield put(listTemplateSuccess(templateData))
  } catch (errors) {
    yield put(listTemplateError('error'))
  }
}
function* createTemplateAsync(action) {
  try {
    const templateData = yield call(createTemplateApi, action.payload)
    yield put(createTemplateSuccess(templateData))
  } catch (errors) {
    yield put(createTemplateError('error'))
  }
}
function* deleteTemplateAsync(action) {
  try {
    const templateData = yield call(deleteTemplateApi, action.payload)
    yield put(deleteTemplateSuccess(templateData))
  } catch (errors) {
    yield put(deleteTemplateError('error'))
  }
}

// Start Watcher
function* createTemplateWatcher() {
  yield takeLatest(WHITE_LABEL_TEMPLATE_CREATE_START, createTemplateAsync)
}
function* listTemplateWatcher() {
  yield takeLatest(WHITE_LABEL_TEMPLATE_LIST_START, listTemplateAsync)
}
function* deleteTemplateWatcher() {
  yield takeLatest(WHITE_LABEL_TEMPLATE_DELETE_START, deleteTemplateAsync)
}

//*********************************************************************************

const generateReportApi = async (input) => {
  return await getRequest(`get-main-url-report?url=${input.id}`)
    .then((res) => res)
    .catch((error) => error)
}

function* generateWhiteLabelReportAsync(action) {
  try {
    const templateData = yield call(generateReportApi, action.payload)
    yield put(generateWhiteLabelReportSuccess(templateData))
  } catch (errors) {
    yield put(generateWhiteLabelReportError('error'))
  }
}

function* generateWhiteLabelReportWatcher() {
  yield takeLatest(GENERATE_WHITE_LABEL_REPORT_REQUEST, generateWhiteLabelReportAsync)
}
//*********************************************************************************

// End Watcher
export default function* templateSaga() {
  yield all([
    createTemplateWatcher(),
    listTemplateWatcher(),
    deleteTemplateWatcher(),
    generateWhiteLabelReportWatcher(),
  ])
}

import { useSelector } from 'react-redux'
import FirewallOptions from 'pages/firewallOptions'
import TrialFlowHeader from 'components/TrialFlowHeader'

const FirewallTrial = () => {
  const registerData = useSelector(({ register }) => register.registerData)

  return (
    <>
      <TrialFlowHeader email={registerData?.email} />
      <FirewallOptions callFrom="trial" />
    </>
  )
}

export default FirewallTrial
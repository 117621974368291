import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const FramesetTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.framesetTest.heading}
      whatIsIT={freeTools.framesetTest.what}
      howToFixBeforeCode={freeTools.framesetTest.howSectionBeforeCode}
    />
  )
}

export default FramesetTest

import { getFontSize } from '../../../constants/Utils'
import * as _ from 'lodash'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const KeywordCloudTest = ({ data }) => {
  let data1
  data1 = _.sortBy(data?.keywordCloudTest?.data?.keywordCloud, 'count')
  data1 = data1.reverse()
  let finalData = data1.slice(0, 125)
  let displayData = data?.keywordCloudTest?.data?.keywordCloud.filter((element1) => {
    let flag = false
    finalData.forEach((element2) => {
      if (element1.word === element2.word) {
        flag = true
      }
    })
    return flag
  })

  return (
    <>
      {data && data?.keywordCloudTest ? (
        <>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <h5>{data?.url}</h5>
          </div>
          <div className="vr-detail-block">
            <div className="vr-report-status-icon" />
            <div className="cloud-tag-listing">
              {displayData &&
                displayData.length &&
                displayData.map((item, index) => {
                  if (item.word && item.word.length < 10) {
                    return (
                      <OverlayTrigger
                        key={`displayData-${index + 1}`}
                        placement={'top'}
                        overlay={<Tooltip id={`tooltip-top`}>Found {item.count} times</Tooltip>}
                      >
                        <span style={{ fontSize: getFontSize(item.count) }}>{item.word} </span>
                      </OverlayTrigger>
                    )
                  } else {
                    return ''
                  }
                })}
            </div>
          </div>
          <br />
          <br />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default KeywordCloudTest

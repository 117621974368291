import { CardHeader, Col, Row } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const CommonCardHeader = ({
  startSpanClass,
  websiteSpanClass,
  locationSpanClass,
  googleSpanClass,
  keywordSpanClass,
  competitorSpanClass,
  sitemapSpanClass,
  callFrom
}) => {
  return (
    <CardHeader className='d-none d-lg-block d-md-block d-sm-none' style={{ backgroundColor: callFrom === "trial" ? '#fbfbfb' : '' }}>
      <Row>
        <Col>
          <div className={'text-center'}>
            <span className={startSpanClass}>
              {startSpanClass !== 'active-step' && startSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                1
              )}
            </span>{' '}
            Start
          </div>
        </Col>
        <Col>
          <div className={'text-center'}>
            <span className={websiteSpanClass}>
              {websiteSpanClass !== 'active-step' && websiteSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                2
              )}
            </span>{' '}
            Website
          </div>
        </Col>
        <Col>
          <div className={'text-center'}>
            <span className={locationSpanClass}>
              {locationSpanClass !== 'active-step' && locationSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                3
              )}
            </span>{' '}
            Location
          </div>
        </Col>
        <Col>
          <div className={'text-center'}>
            <span className={googleSpanClass}>
              {googleSpanClass !== 'active-step' && googleSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                4
              )}
            </span>{' '}
            Connections
          </div>
        </Col>
        <Col>
          <div className={'text-center'}>
            <span className={keywordSpanClass}>
              {keywordSpanClass !== 'active-step' && keywordSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                5
              )}
            </span>{' '}
            Keywords
          </div>
        </Col>
        <Col>
          <div className={'text-center'}>
            <span className={competitorSpanClass}>
              {competitorSpanClass !== 'active-step' && competitorSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                6
              )}
            </span>{' '}
            Competitors
          </div>
        </Col>
        <Col>
          <div className={'text-center'}>
            <span className={sitemapSpanClass}>
              {sitemapSpanClass !== 'active-step' && sitemapSpanClass === 'done-step' ? (
                <FontAwesomeIcon className="align-middle me-2" icon={faCheck} />
              ) : (
                7
              )}
            </span>{' '}
            Sitemap
          </div>
        </Col>
      </Row>
    </CardHeader>
  )
}

export default CommonCardHeader

import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const ImageCache = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [imageCacheFlag, setImageCacheFlag] = useState(false)

  const columns = [{
    id: 'imageResourceUrl',
    displayName: 'Uncached Image Resource list'
  }]

  const imageCacheCsv = data?.imageCache?.data?.withoutCachedResults?.map(item => {
    return {
      imageResourceUrl: item?.url
    }
  })
  return (
    <>
      <div className="vr-url-block">
        <div className="d-none d-md-block d-xl-block">
          {!data?.imageCache?.data?.testResults?.status ? (
            ''
          ) : data?.imageCache?.data?.testResults?.status === 3 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={crossIcon} />
          ) : data?.imageCache?.data?.testResults?.status === 2 ? (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={warningIcon} />
          ) : (
            <img style={{ height: 15, marginTop: 5, marginRight: 5, display: 'block' }} src={tickIcon} />
          )}
        </div>
        <div>
          <h5>{data?.url}</h5>
        </div>
      </div>
      <div className="vr-detail-block">
        <p>
          {data.imageCache?.data?.testResults ? (
            <>{data.imageCache?.data?.testResults?.message || ''}</>
          ) : (
            <></>
          )}
        </p>

        {showDetail &&
        data?.imageCache?.data?.testResults?.status !== 1 &&
        data.imageCache?.data?.withoutCachedResults &&
        data.imageCache?.data?.withoutCachedResults.length ? (
          <>
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                if (testType === "") {
                  setImageCacheFlag(!imageCacheFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={imageCacheFlag}
        toggleShow={() => setImageCacheFlag(!imageCacheFlag)}
        title={'Image Cache Signal Test'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '600', fontSize: '22px' }}
        showDownload
        columns = {columns}
        datas={imageCacheCsv}
        fileName='Image Cache Signal Test'
      >
        {data.imageCache?.data?.withoutCachedResults &&
        data?.imageCache?.data?.withoutCachedResults.length ? (
          <>
            <h4> Uncached Image Resource list </h4>
            <ul>
              {' '}
              {data.imageCache?.data?.withoutCachedResults.map((item, index) => {
                return <li key={`ImageCache-${index+1}`}>{item.url}</li>
              })}
            </ul>
          </>
        ) : (
          <> </>
        )}
      </ModalHOC>
    </>
  )
}

export default ImageCache

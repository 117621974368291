import { useState } from 'react'
import crossIcon from '../../../assets/img/icons/cross.png'
import tickIcon from '../../../assets/img/icons/tick-mark.png'
import warningIcon from '../../../assets/img/icons/warning.png'
import ModalHOC from '../tests-components/ModalHOC'
import { Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { openRegisterModal } from 'redux/actions/freetrial/startFreeTrial'

const MordenImage = ({ data, showDetail, testType = "" }) => {

  const dispatch = useDispatch()
  const [mordenImageFlag, setMordenImageFlag] = useState(false)

  const columns = [
    {
      id:'image',
      displayName:'Image'
    }
  ]

  const mordenImageCsv = data?.mordenImage?.data?.data?.details?.items?.map(item => {
    return {
      Image: item?.url
    }
  })
  return (
    <>
      <div className="vr-url-block">
        {data.mordenImage?.data?.testResults?.status === 1 ? (
          <img
            className="d-none d-md-block d-xl-block"
            style={{ height: 15, marginTop: 6, marginRight: 5 }}
            src={tickIcon}
          />
        ) : data.mordenImage?.data?.testResult?.status === 2 ? (
          <img
            className="d-none d-md-block d-xl-block"
            style={{ height: 15, marginTop: 6, marginRight: 5 }}
            src={warningIcon}
          />
        ) : (
          <img
            className="d-none d-md-block d-xl-block"
            style={{ height: 15, marginTop: 6, marginRight: 5 }}
            src={crossIcon}
          />
        )}
        <span>
          {' '}
          <h5>{data?.url}</h5>
        </span>
      </div>
      <div className="vr-detail-block">
        <p>{data.mordenImage?.data?.testResults?.message}</p>
      </div>
      <div className="vr-detail-block">
        {showDetail && data.mordenImage?.data?.testResults?.status === 3 ? (
          <>
            <p 
              className="text-primary cursor-pointer" 
              onClick={() => {
                if (testType === "") {
                  setMordenImageFlag(!mordenImageFlag)
                } else {
                  dispatch(openRegisterModal(true))
                }
              }}>
              {' '}
              See Details
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalHOC
        show={mordenImageFlag}
        toggleShow={() => setMordenImageFlag(!mordenImageFlag)}
        title={'Full list of unoptimized images'}
        bodyClassName="m-3"
        titleStyles={{ fontWeight: '400', fontSize: '22px' }}
        showDownload
        columns = {columns}
        datas = {mordenImageCsv}
        fileName='Morden Image Signal Test'
        alternativeDownload={true}
      >
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Image</th>
              {/* <th style={{ width: "40%" }}>Size</th>
                                                                    <th style={{ width: "40%" }}>Potential Savings</th> */}
            </tr>
          </thead>
          <tbody>
            {data?.mordenImage?.data?.data?.details?.items?.length &&
              data?.mordenImage?.data?.data?.details?.items.map((item, index) => {
                return (
                  <tr key={`MordenImage-${index+1}`}>
                    <td>{item.url}</td>
                    {/* <td>{`${item.totalBytes / 1000} KB`}</td>
                                                                        <td>{`${item.wastedBytes / 1000} KB`}</td> */}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ModalHOC>
    </>
  )
}

export default MordenImage

import { useEffect, useRef, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-scroll'
import * as Scroll from 'react-scroll'
import { Accordion, Badge, ProgressBar, Modal } from 'react-bootstrap'

import * as _ from 'lodash'
import { useLocation, useParams } from 'react-router-dom'

import { Loader } from 'components/uiCore'
import usePalette from '../../hooks/usePalette'
import { Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import TitleTest from 'pages/free-trial/tests-components/titleTest.js'
import DescriptionTest from 'pages/free-trial/tests-components/descriptionTest.js'
import Issues from 'pages/dashboards/IssuesViewReport.js'
import PieChart from 'modules/trialSEOCheckup/components/SEOPieChart.jsx'

import ResultPreviewTest from 'pages/free-trial/tests-components/resultPreview.js'
import MostCommonTest from 'pages/free-trial/tests-components/MostCommonTest'
import SSLHttpTest from 'pages/free-trial/tests-components/SslTest'
import TlsLogjam from 'pages/free-trial/tests-components/TlsLogjam'
import Http2Test from 'pages/free-trial/tests-components/http2'
import DnsSecurityTest from 'pages/free-trial/tests-components/DnsSecurityTest'
import BrotilTest from 'pages/free-trial/tests-components/BrotilTest'
import UrlCanonicalization from 'pages/free-trial/tests-components/UrlCanonicalization'
import SpfRecord from 'pages/free-trial/tests-components/SpfRecord'
import SiteSpeed from 'pages/free-trial/tests-components/SiteSpeed'
import ServerSignature from 'pages/free-trial/tests-components/ServerSignature'
import SafeBrowsing from 'pages/free-trial/tests-components/SafeBrowsing'
import RedirectTest from 'pages/free-trial/tests-components/RedirectTest'
import PlainTextEmail from 'pages/free-trial/tests-components/PlainTextEmail'
import PageCache from 'pages/free-trial/tests-components/PageCache'
import NoIndexChecker from 'pages/free-trial/tests-components/NoIndexChecker'
import NestedTable from 'pages/free-trial/tests-components/NestedTable'
import KeywordCloudTest from 'pages/free-trial/tests-components/KeywordCloudTest'
import HttpsTest from 'pages/free-trial/tests-components/HttpsTest'
import HtmlPageSize from 'pages/free-trial/tests-components/HtmlPageSize'
import HtmlCompression from 'pages/free-trial/tests-components/HtmlCompression'
import GoogleAnalytics from 'pages/free-trial/tests-components/GoogleAnalytics'
import FramesetTest from 'pages/free-trial/tests-components/FramesetTest'
import FlashTest from 'pages/free-trial/tests-components/FlashTest'
import FaviconCheckerTest from 'pages/free-trial/tests-components/FaviconCheckerTest'
import DocType from 'pages/free-trial/tests-components/DocType'
import DisallowDirective from 'pages/free-trial/tests-components/DisallowDirective'
import CSSCache from 'pages/free-trial/tests-components/CSSCache'
import CDNUsage from 'pages/free-trial/tests-components/CDNUsage'
import CSSAnalysis from 'pages/free-trial/tests-components/CSSAnalysis'
import CSSMinification from 'pages/free-trial/tests-components/CSSMinification'
import DesktopScreenshot from 'pages/free-trial/tests-components/DesktopScreenshot'
import MobileScreenshot from 'pages/free-trial/tests-components/MobileScreenshot'
import HeadingAnalysis from 'pages/free-trial/tests-components/HeadingAnalysis'
import HtmlValidation from 'pages/free-trial/tests-components/HtmlValidation'
import ImageAlt from 'pages/free-trial/tests-components/ImageAlt'
import LinksALTDescriptions from 'pages/free-trial/tests-components/LinksALTDescriptions'
import RelatedKeywordsTest from 'pages/free-trial/tests-components/RelatedKeywordsTest'
import SeoFriendlyUrlAnalysis from 'pages/free-trial/tests-components/SeoFriendlyUrlAnalysis'
import SiteMapData from 'pages/free-trial/tests-components/SiteMapData'
import ImageCache from 'pages/free-trial/tests-components/ImageCache'
import JSMinification from 'pages/free-trial/tests-components/JSMinification'
import InlineCss from 'pages/free-trial/tests-components/InlineCss'
import JSCache from 'pages/free-trial/tests-components/JSCache'
import JSError from 'pages/free-trial/tests-components/JSError'
import MicroDataSchema from 'pages/free-trial/tests-components/MicroDataSchema'
import NoFollow from 'pages/free-trial/tests-components/NoFollow'
import PageObject from 'pages/free-trial/tests-components/PageObject'
import SocialMediaTest from 'pages/free-trial/tests-components/SocialMediaTest'
import CommonSEOHOC from 'pages/free-trial/tests-components/CommonSEOHOC'
import ModalHOC from 'pages/free-trial/tests-components/ModalHOC'
import RenderBlocking from 'pages/free-trial/tests-components/RenderBlocking'
import MordenImage from 'pages/free-trial/tests-components/MordenImage'
import AspectRatio from 'pages/free-trial/tests-components/AspectRatio'
import ResponsiveImage from 'pages/free-trial/tests-components/ResponsiveImage'
import ConsoleErrors from 'pages/free-trial/tests-components/ConsoleErrors'
import DomSize from 'pages/free-trial/tests-components/DomSize'
import BootupTime from 'pages/free-trial/tests-components/bootupTime'
import CharacterSet from 'pages/free-trial/tests-components/characterSet'
import Viewport from 'pages/free-trial/tests-components/viewport'
import MetaRefresh from 'pages/free-trial/tests-components/MetaRefresh'
import AdsTxt from 'pages/free-trial/tests-components/AdsTxt'
import Custom404 from 'pages/free-trial/tests-components/Custom404'
import LayoutShift from 'pages/free-trial/tests-components/LayoutShift'
import ContentfulPaint from 'pages/free-trial/tests-components/ContentfulPaint'
import SocialMediaMetaTags from 'pages/free-trial/tests-components/socialMediaMetaTags'
import KeywordUsageTest from 'pages/free-trial/tests-components/KeywordUsage'
import RobotsText from 'pages/free-trial/tests-components/robotsTxt'
import { commonSEOIssueInfo } from '../../constants/strings'
import HSTS from 'pages/free-trial/tests-components/hsts'
import CompetitorDomain from 'pages/free-trial/tests-components/CompetitorDomain'

import BrokenLinks from 'pages/free-trial/tests-components/Brokenlinks'
import CanonicalURL from 'pages/free-trial/tests-components/CanonicalURL'
import DirectoryBrowsing from 'pages/free-trial/tests-components/DirectoryBrowsing'
import crossIcon from '../../assets/img/icons/cross.png'
import tickIcon from '../../assets/img/icons/tick-mark.png'
import warningIcon from '../../assets/img/icons/warning.png'
import UnsafeCrossOriginLinks from 'pages/free-trial/tests-components/UnsafeCrossOriginLinks'
import MenuTitleTest from 'pages/free-trial/tests-components/menuTitleTest'
import FontSizeTest from 'pages/free-trial/tests-components/FontSizeTest'
import TapTargets from 'pages/free-trial/tests-components/TapTargets'
import ContentWidth from 'pages/free-trial/tests-components/ContentWidth'
import SpeedIndex from 'pages/free-trial/tests-components/SpeedIndex'
import FirstContentfulPaint from 'pages/free-trial/tests-components/FirstContentfulPaint'
import LargestContentfulPaint from 'pages/free-trial/tests-components/LargestContentfulPaint'
import CumulativeLayoutShift from 'pages/free-trial/tests-components/CumulativeLayoutShift'
import TimetoInteractiveTest from 'pages/free-trial/tests-components/TimetoInteractiveTest'
import FirstInputDelay from 'pages/free-trial/tests-components/FirstInputDelay'
import CachePolicy from 'pages/free-trial/tests-components/CachePolicy.jsx'
import moment from 'moment'
import Header from 'components/Header/Header'
import 'modules/trialSEOCheckup/TrialSEOCheckup.scss'
import { CLEAR_GET_TRIAL_SEO_REPORT, CLEAR_TRIAL_SEO_CHECKUP } from 'redux/actionType'
import { getReportData } from 'utils/seoIssue'
import SubscriptionRequired from './components/SubscriptionRequiredTest'
import { getTrialSeoReport, trialSeoCheckup } from 'redux/actions/freetrial/startFreeTrial'
import { TEST_TYPES } from 'constants/enum'
import AdvancedConsoleErrors from 'pages/free-trial/tests-components/AdvancedConsoleError'
import RegisterForm from './components/Registration'
import { message } from 'antd'
import { advanceSeoSignalKeys, configurationAndSecuritySignalKeys, primarySeoSignalKeys, responsiveAndSpeedSignalKeys, websiteLoadingSignalKeys } from 'constants.js'

var scroller = Scroll.scroller

let interval
const TrialSEOCheckup = () => {
  const palette = usePalette()

  const {
    userData,
    mostRecentlyBacklinksData,
    keywordsData,
  } = useSelector(({ dashboard }) => dashboard)

  const {
    trialSeoCheckupLoader,
    trialSeoCheckupSuccess,
    quickSeoId,
    trialSeoReportLoader,
    showRegisterModal,
    trialFailureMessage,
  } = useSelector(({ startFreeTrial }) => startFreeTrial)

  const urlData = useSelector(({ startFreeTrial }) => startFreeTrial?.trialReport?.quickSeoTrialId)
  const competitor1 = useSelector(({ startFreeTrial }) => startFreeTrial?.trialReport?.competitor1)

  const [positiveCounter, setPositiveCounter] = useState(0)
  const [negativeCounter, setNegativeCounter] = useState(0)
  const [warningCounter, setWarningCounter] = useState(0)

  const [positiveCounter1, setPositiveCounter1] = useState(0)
  const [negativeCounter1, setNegativeCounter1] = useState(0)
  const [warningCounter1, setWarningCounter1] = useState(0)

  const [commonSeoSCounter, setCommonSeoSCounter] = useState(0)
  const [commonSeoWCounter, setCommonSeoWCounter] = useState(0)
  const [commonSeoFCounter, setCommonSeoFCounter] = useState(0)

  const [speedOptimizeSCounter, setSpeedOptimizeSCounter] = useState(0)
  const [speedOptimizeWCounter, setSpeedOptimizeWCounter] = useState(0)
  const [speedOptimizeFCounter, setSpeedOptimizeFCounter] = useState(0)

  const [serverSCounter, setServerSCounter] = useState(0)
  const [serverWCounter, setServerWCounter] = useState(0)
  const [serverFCounter, setServerFCounter] = useState(0)

  const [mobileUsabilitySCounter, setMobileUsabilitySCounter] = useState(0)
  const [mobileUsabilityWCounter, setMobileUsabilityWCounter] = useState(0)
  const [mobileUsabilityFCounter, setMobileUsabilityFCounter] = useState(0)

  const [advanceSeoSCounter, setAdvanceSeoSCounter] = useState(0)
  const [advanceSeoWCounter, setAdvanceSeoWCounter] = useState(0)
  const [advanceSeoFCounter, setAdvanceSeoFCounter] = useState(0)

  const [errors, setErrors] = useState([])

  const [domSizeFlag, setDomSizeFlag] = useState(false)
  const [adsTxtFlag, setAdsTxtFlag] = useState(false)
  const [responsiveImageFlag, setResponsiveImageFlag] = useState(false)
  const [socialMediaFlag, setSocialMediaFlag] = useState(false)
  const [noFollowFlag, setNoFollowFlag] = useState(false)
  const [relatedKeywordsFlag, setRelatedKeywordFlag] = useState(false)
  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const [relatedKeywordList, setRelatedKeywordList] = useState([])
  const [totalBacklinks, setTotalBacklink] = useState(0)
  const [referingDomains, setReferringDomain] = useState(0)
  const [mostRecentlyBacklinks, setMostRecentlyBacklinks] = useState()

  const [isSiteLoadingSignalsAvailable, setIsSiteLoadingSignalsAvailable] = useState(false)
  const [isConfigurationAndSecuritySignals, setIsConfigurationAndSecuritySignals] = useState(false)
  const [isResponsiveAndSpeedSignals, setIsResponsiveAndSpeedSignals] = useState(false)
  const [isAdvanceSeoSignalsAvailable, setIsAdvanceSeoSignalsAvailable] = useState(false)
  const [isPrimarySeoSignalsAvailable, setIsPrimarySeoSignalsAvailable] = useState(false)
  const [displayData, setDisplayData] = useState(false)

  const dispatch = useDispatch()
  const reportInterval = useRef()
  const { url, competitorUrl, type } = useParams() 
  const location = useLocation()
  const { state } = location

  let interval2 = []
  let totalPercent, totalCompetitorPercent

  let totalTest = positiveCounter + warningCounter + negativeCounter
  let totalCompetitorTest = positiveCounter1 + warningCounter1 + negativeCounter1
  const testType = TEST_TYPES?.[type] || "ALL"
  
  if (testType === 'ALL') {
    totalPercent = (totalTest / 68) * 100
    totalCompetitorPercent = (totalCompetitorTest / 68) * 100
  } else {
    totalPercent = totalTest * 100
    totalCompetitorPercent = totalCompetitorTest * 100
  }

  useEffect(() => {    
    const runTrialSeo = () => {
      if (!trialSeoCheckupLoader && (trialSeoCheckupSuccess || trialFailureMessage)) {
        return
      } else {
        if (competitorUrl) {
          const payload = {type: testType, url: url, competitor: competitorUrl}
          dispatch(trialSeoCheckup(payload))
        } else {
          const payload = {type: testType, url: url}
          dispatch(trialSeoCheckup(payload))
        }
      }
    }

    runTrialSeo()
  }, [])

  useEffect(() => {
    if (trialSeoCheckupLoader) {
      return
    } else {
      if (trialSeoCheckupSuccess) {
        dispatch(getTrialSeoReport({url: url, type: testType}))
        patchDomains()
        dispatch({ type: CLEAR_TRIAL_SEO_CHECKUP })
      } else if (trialFailureMessage) {
        message.error(trialFailureMessage?.message)
        dispatch({ type: CLEAR_TRIAL_SEO_CHECKUP })
      }
    }
  }, [trialSeoCheckupLoader, trialSeoCheckupSuccess, trialFailureMessage])

  useEffect(() => {
    if (urlData && Object.keys(urlData)?.length) {
      const testKeys = Object.keys(urlData)
      const siteLoadingSignals = websiteLoadingSignalKeys.filter((testKey) => {
        return testKeys.includes(testKey)
      })
      const configurationAndSecuritySignals = configurationAndSecuritySignalKeys.filter((testKey) => {
        return testKeys.includes(testKey)
      })
      const responsiveAndSpeedSignals = responsiveAndSpeedSignalKeys.filter((testKey) => {
        return testKeys.includes(testKey)
      })
      const advanceSeoSignals = advanceSeoSignalKeys.filter((testKey) => {
        return testKeys.includes(testKey)
      })
      const primarySeoSignals = primarySeoSignalKeys.filter((testKey) => {
        return testKeys.includes(testKey)
      })

      if (siteLoadingSignals && siteLoadingSignals?.length) {
        if (competitorUrl && testType !== "ALL") {
          if (totalCompetitorPercent >= 100) {
            setIsSiteLoadingSignalsAvailable(true)
          }
        } else {
           setIsSiteLoadingSignalsAvailable(true)
        }
      }

      if (configurationAndSecuritySignals && configurationAndSecuritySignals?.length) {
        if (competitorUrl && testType !== "ALL") {
          if (totalCompetitorPercent >= 100) {
            setIsConfigurationAndSecuritySignals(true)
          }
        } else {
           setIsConfigurationAndSecuritySignals(true)
        }
      }

      if (responsiveAndSpeedSignals && responsiveAndSpeedSignals?.length) {
        if (competitorUrl && testType !== "ALL") {
          if (totalCompetitorPercent >= 100) {
            setIsResponsiveAndSpeedSignals(true)
          }
        } else {
           setIsResponsiveAndSpeedSignals(true)
        } 
      }

      if (advanceSeoSignals && advanceSeoSignals?.length) {
        if (competitorUrl && testType !== "ALL") {
          if (totalCompetitorPercent >= 100) {
            setIsAdvanceSeoSignalsAvailable(true)
          }
        } else {
            setIsAdvanceSeoSignalsAvailable(true)
        }    
      } else if (testType === TEST_TYPES['microdata-schema-analysis']) {
        if (competitorUrl) {
          if (totalCompetitorPercent >= 100) {
            setIsAdvanceSeoSignalsAvailable(true)
          }
        } else {
            setIsAdvanceSeoSignalsAvailable(true)
        }  
      }

      if (primarySeoSignals && primarySeoSignals?.length) {
        if (competitorUrl && testType !== "ALL") {
          if (totalCompetitorPercent >= 100) {
            setIsPrimarySeoSignalsAvailable(true)
          }
        } else {
           setIsPrimarySeoSignalsAvailable(true)
        }  
      } else if (
        (testType === TEST_TYPES['meta-title'] ||
          testType === TEST_TYPES['meta-description'] ||
          testType === TEST_TYPES['meta-tags'] ||
          testType === TEST_TYPES['keywords-usage-test']) &&
        totalPercent >= 100
      ) {
        if (competitorUrl) {
          if (totalCompetitorPercent >= 100) {
            setIsPrimarySeoSignalsAvailable(true)
          }
        } else {
           setIsPrimarySeoSignalsAvailable(true)
        }  
      }
    }
  }, [urlData, totalPercent, totalCompetitorPercent])

  useEffect(() => {
    if (urlData && urlData?.url) {
      let splittedValue = urlData?.url?.split('/')?.slice(0, 3)
      let hostName = splittedValue[splittedValue?.length - 1]
      if (hostName?.includes('www.')) {
        hostName = hostName?.replace('www.', '')
      }
      const activeSearchData =
        (userData &&
          userData.searchResults &&
          userData.searchResults.length &&
          _.findLast(
            userData.searchResults,
            (item) => item && item.url && item.url.toLowerCase()?.includes(hostName.toLowerCase()),
          )) ||
        {}
      let selectedKeyword =
        keywordsData?.length > 0 && keywordsData?.find((item) => item?.url?.includes(hostName))
      if (selectedKeyword?.values?.length > 0) {
        let filteredKeyword = selectedKeyword?.values?.filter(
          (item, index, self) => index === self.findIndex((t) => t?.keyword === item?.keyword),
        )

        let finalKeywordsList = filteredKeyword?.filter((item) => {
          return activeSearchData?.keywords?.includes(item.keyword)
        })

        setRelatedKeywordList(finalKeywordsList)
      } else if (relatedKeywordList?.length > 0) {
        let rkArray = [...relatedKeywordList]
        rkArray = []
        setRelatedKeywordList(rkArray)
      }
    }
  }, [keywordsData, usersDomain])

  useEffect(() => {
    return () => {
      interval2.map((id) => {
        clearInterval(id)
      })
      dispatch({ type: 'CLEAR_GET_BACKLINKS_MOST_RECENTLY' })
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_GET_TRIAL_SEO_REPORT })
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setMostRecentlyBacklinks([])
    if (usersDomain?.length > 0) {
      let linksArray = []
      let selectedUserDomain =
        mostRecentlyBacklinksData &&
        mostRecentlyBacklinksData?.length &&
        mostRecentlyBacklinksData?.find(
          (domain) =>
            domain?.items?.[0]?.information?.seoCheckup?.redirect_url ===
            urlData?.information?.seoCheckup?.redirect_url,
        )
      if (selectedUserDomain?.statsData?.[0]) {
        setReferringDomain(selectedUserDomain?.statsData?.[0]?.domains)
        setTotalBacklink(selectedUserDomain?.statsData?.[0]?.backlinks)
      }
      linksArray = selectedUserDomain?.mostRecentlyBacklinksData
      setMostRecentlyBacklinks(linksArray)
    }
  }, [mostRecentlyBacklinksData])

  const patchDomains = () => {
    if (reportInterval.current) {
      return
    }

    if (quickSeoId) {
      reportInterval.current = setInterval(() => {
        dispatch(getTrialSeoReport({ url: url, type: testType }))
      }, 12000)
    }
  }

  useEffect(() => {
    return () => {
      clearInterval(reportInterval.current)
      reportInterval.current = null
    }
  }, [])

  useEffect(() => {
    if (!reportInterval.current) {
      return
    } else {
      if (competitorUrl) {
        if (totalPercent >= 100 && totalCompetitorPercent >= 100) {
          clearInterval(reportInterval.current)
          reportInterval.current = null
        }
      } else {
        if (totalPercent >= 100) {
          clearInterval(reportInterval.current)
          reportInterval.current = null
        }
      }
    }
  }, [totalPercent, totalCompetitorPercent])

  const executeScroll = () => {
    scroller.scrollTo('myScrollToElement', {
      duration: 500,
      delay: 100,
      smooth: true,
      containerId: 'quick-seo-checkup',
      offset: 100, // Scrolls to element + 50 pixels down the page
    })
  }

  useEffect(() => {
    if (urlData) {
      let isUrlData = true
      const assets = getReportData(urlData, isUrlData, testType)
      const {
        updatedErrorsArray,
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setErrors(updatedErrorsArray)

      setWarningCounter(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)

      setCommonSeoSCounter(commonSeoS)
      setCommonSeoWCounter(commonSeoW)
      setCommonSeoFCounter(commonSeoF)

      setSpeedOptimizeSCounter(speedOptS)
      setSpeedOptimizeWCounter(speedOptW)
      setSpeedOptimizeFCounter(speedOptF)

      setServerSCounter(serverS)
      setServerWCounter(serverW)
      setServerFCounter(serverF)

      setMobileUsabilitySCounter(mobileUseS)
      setMobileUsabilityWCounter(mobileUseW)
      setMobileUsabilityFCounter(mobileUseF)

      setAdvanceSeoSCounter(advanceS)
      setAdvanceSeoWCounter(advanceW)
      setAdvanceSeoFCounter(advanceF)
    }
  }, [urlData])

  useEffect(() => {
    if (competitor1) {
      let isUrlData = true
      const assets = getReportData(competitor1, isUrlData, testType)
      const {
        updatedErrorsArray,
        commonSeoS,
        commonSeoW,
        commonSeoF,
        speedOptS,
        speedOptW,
        speedOptF,
        serverS,
        serverW,
        serverF,
        mobileUseS,
        mobileUseW,
        mobileUseF,
        advanceS,
        advanceW,
        advanceF,
      } = assets

      setWarningCounter1(updatedErrorsArray.filter((item) => item.priority === 2).length)

      setWarningCounter1(commonSeoW + speedOptW + serverW + mobileUseW + advanceW)
      setPositiveCounter1(commonSeoS + speedOptS + serverS + mobileUseS + advanceS)
      setNegativeCounter1(commonSeoF + speedOptF + serverF + mobileUseF + advanceF)
    }
  }, [competitor1])

  const competitor1Flag = _.isNull(competitor1)

  const multiData = []

  multiData.push(urlData)
  if (!competitor1Flag) {
    multiData.push(competitor1)
  }

  useEffect(() => {
    if (testType === 'ALL') {
      setDisplayData(true)
    } else {
      if (
        ((isPrimarySeoSignalsAvailable ||
          isSiteLoadingSignalsAvailable ||
          isResponsiveAndSpeedSignals ||
          isConfigurationAndSecuritySignals ||
          isAdvanceSeoSignalsAvailable) &&
          totalPercent >= 100) ||
        trialFailureMessage
      ) {
        setDisplayData(true)
      }
    }
  }, [
    isPrimarySeoSignalsAvailable,
    isSiteLoadingSignalsAvailable,
    isResponsiveAndSpeedSignals,
    isConfigurationAndSecuritySignals,
    isAdvanceSeoSignalsAvailable,
    totalPercent,
    trialFailureMessage,
  ])

  return (
    <Container fluid className="p-0">
      <Modal
        show={showRegisterModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ boxShadow: '0 10px 32px rgba(71,186,193,.15)' }}
        className="br-15"
      >
        <Modal.Header>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h3 style={{ margin: 0 }}>Please register for better insights!</h3>
          </div>
        </Modal.Header>

        <Modal.Body>
          <RegisterForm />
        </Modal.Body>
      </Modal>

      <Header isDemoReportPage customClassName="layout-container trial-header" />
      {!trialSeoCheckupLoader && displayData ? (
        <Row
          className={
            testType === 'ALL' ? (urlData && urlData !== '' ? 'main-wrapper' : '') : 'single-test-wrapper'
          }
        >
          <div className={testType === 'ALL' ? 'report-wrapper' : ''}>
            {urlData && urlData !== '' ? (
              <>
                <div className="report-summary" style={{ width: testType !== 'ALL' ? '100%' : '' }}>
                  <Card>
                    <CardBody>
                      <Row className="d-flex justify-content-between">
                        <Col lg="4" xl="4" xs="12">
                          <h3
                            style={{
                              display: 'inline-block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              fontWeight: '400',
                              marginBottom: '0.250rem',
                            }}
                          >
                            {urlData?.url || ''}
                            <p style={{ fontSize: '14px', marginTop: '5px', marginBottom: '0' }}>
                              {moment.utc(urlData?.createdAt).format('YYYY, MMMM DD')}
                            </p>
                          </h3>
                          <p className="mb-0">Your general SEO Checkup Score</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <PieChart
                    showDetails
                    competitor1={competitor1?.url}
                    positiveCounter={positiveCounter}
                    negativeCounter={negativeCounter}
                    warningCounter={warningCounter}
                    positiveCounter1={positiveCounter1}
                    negativeCounter1={negativeCounter1}
                    warningCounter1={warningCounter1}
                    isQuickCheck={true}
                    testType={testType}
                    totalPercent={totalPercent}
                  />
                  {errors?.length && testType === 'ALL' ? (
                    <Row>
                      <Col className="d-none d-lg-block d-sm-none">
                        <Card>
                          <CardHeader>
                            <CardTitle className="f-bold">Issues to fix</CardTitle>
                          </CardHeader>

                          <CardBody>
                            <Row>
                              <Col>
                                <Issues
                                  errors={errors}
                                  executeScroll={executeScroll}
                                  showIssueTitle={false}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  ) : null}

                  {/* ******** */}
                  <Row className="quick-seo-checkup" id="quick-seo-checkup">
                    <Col className="mb-4">
                      {isPrimarySeoSignalsAvailable ? (
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md="4">
                                <h3 className="f-bold">PRIMARY SEO SIGNALS</h3>
                              </Col>
                              <Col>
                                <Row className="display-end">
                                  <Col md="3">
                                    <span>
                                      Score:{' '}
                                      {(
                                        (commonSeoSCounter * 100) /
                                        (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                      ).toFixed(0)}
                                    </span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant={
                                        (
                                          (commonSeoSCounter * 100) /
                                          (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                        ).toFixed(0) > 75
                                          ? 'success'
                                          : (
                                                (commonSeoSCounter * 100) /
                                                (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                              ).toFixed(0) <= 50
                                            ? 'danger'
                                            : 'warning'
                                      }
                                      now={
                                        (
                                          (commonSeoSCounter * 100) /
                                          (commonSeoFCounter + commonSeoWCounter + commonSeoSCounter)
                                        ).toFixed(0) || 0
                                      }
                                      label=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <span>Success: {commonSeoSCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="success"
                                      now={commonSeoSCounter || 0}
                                      max={testType === 'ALL' ? 27 : commonSeoSCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Warning: {commonSeoWCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="warning"
                                      now={commonSeoWCounter || 0}
                                      max={testType === 'ALL' ? 27 : commonSeoWCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Failed: {commonSeoFCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="danger"
                                      now={commonSeoFCounter || 0}
                                      max={testType === 'ALL' ? 27 : commonSeoFCounter}
                                      label=""
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </CardHeader>
                          <CardBody>
                            {urlData?.information?.seoCheckup?.title &&
                            (testType === 'ALL' || testType === 'Title') ? (
                              <CommonSEOHOC
                                id={'meta-title'}
                                //isAvailable={urlData?.information?.seoCheckup?.title?.titleCheck}
                                status={urlData?.information?.seoCheckup?.title?.titleStatus}
                                title={'Meta Title Signal'}
                                showDevider={false}
                                data={urlData?.information?.seoCheckup?.title}
                                infoMessage={commonSEOIssueInfo.metaTitle}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`meta ${index + 1}`}>
                                        <TitleTest data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.information?.seoCheckup?.description &&
                            (testType === 'ALL' || testType === 'Description') ? (
                              <CommonSEOHOC
                                id={'meta-description'}
                                isAvailable={urlData?.information?.seoCheckup?.description?.descriptionCheck}
                                status={urlData?.information?.seoCheckup?.description?.descriptionStatus}
                                title={'Meta Description Signal'}
                                data={urlData?.information?.seoCheckup?.description}
                                infoMessage={commonSEOIssueInfo.metaDescription}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`metadescription ${index + 1}`}>
                                        <DescriptionTest data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.information?.seoCheckup?.title &&
                            (testType === 'ALL' || testType === TEST_TYPES['keyword-cloud']) ? (
                              <CommonSEOHOC
                                id={'seo-result'}
                                isConsidered={false}
                                status={urlData?.keywordCloudTest?.data?.testResults?.status}
                                title={'Google Search Results Preview - SERP View'}
                                infoMessage={commonSEOIssueInfo.seoResult}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`GoogleSearch ${index + 1}`}>
                                        <ResultPreviewTest data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            { testType === 'ALL' || testType === 'MetaTags' ? (
                              <CommonSEOHOC
                                id={'social-media-meta-tag'}
                                status={urlData?.microDataSchema?.data?.metaTagsResults?.status}
                                title={'Social Media Meta Tags Signals'}
                                infoMessage={commonSEOIssueInfo.socialMediaMetaTag}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`SocialSignals ${index + 1}`}>
                                        <SocialMediaMetaTags data={item} />{' '}
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Unlock your website's potential with social media meta tags!`}
                                        message={`Discover how optimizing your social media meta tags can amplify your website's visibility across platforms like Facebook, Twitter, and LinkedIn.`}
                                        registerMessage={`Register for free and start using PositionMySite to level up your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {testType === 'ALL' ? (
                              <CommonSEOHOC
                                id={'common'}
                                isConsidered={false}
                                status={urlData?.keywordCloudTest?.data?.testResults?.status}
                                title={'Keyword Density Signals Check'}
                                infoMessage={commonSEOIssueInfo.commonKeywords}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`CommonKeywords ${index + 1}`}>
                                        <MostCommonTest data={item} />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Optimize your content and discover keyword density signals!`}
                                        message={`Explore the impact of keyword density on your website's search engine performance. Uncover insights to refine your content strategy and improve your site's visibility.`}
                                        registerMessage={`Register for free and start using PositionMySite to level up your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            { testType === 'ALL' || testType === 'KeywordsUsageTest' ? (
                              <CommonSEOHOC
                                id={'keyword-usage-test'}
                                isAvailable={urlData?.microDataSchema?.data?.keywordSuccessFlag}
                                status={urlData?.microDataSchema?.data?.keywordsUsageTestResults?.status}
                                title={'Keyword Usage Test Signals'}
                                infoMessage={commonSEOIssueInfo.keywordUsageTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`KeywordUsageSignals ${index + 1}`}>
                                        <KeywordUsageTest data={item} />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Unveil Keyword Usage Test Signals!`}
                                        message={`Maximize your website's potential by harnessing the secrets of strategic keyword usage. Elevate your SEO strategy and dominate search engine rankings.`}
                                        registerMessage={`Register for free and start using PositionMySite to level up your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {testType === 'ALL' || testType === 'KeywordCloud' ? (
                              <CommonSEOHOC
                                id={'keyword-cloud'}
                                isConsidered={false}
                                isAvailable={urlData?.keywordCloudTest?.data?.keywordCloud}
                                title={'Keyword Cloud Signals - Visual & Count'}
                                infoMessage={commonSEOIssueInfo.keywordCloud}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`KeywordCloudSignals ${index + 1}`}>
                                        <KeywordCloudTest data={item} />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Optimize Your Content Strategy: Explore Keyword Cloud Signals!`}
                                        message={`Gain a deeper understanding of your website's keyword distribution through our Keyword Cloud Signals test. Analyze both the visual representation and quantitative data to refine your content strategy and bolster your SEO efforts.`}
                                        registerMessage={`Register for free and start using today PositionMySite to level up your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.relatedKeywordsFinal?.data ||
                            testType === 'ALL' ||
                            testType === 'RelatedKeywords' ? (
                              <CommonSEOHOC
                                id={'related-keywords'}
                                isConsidered={false}
                                isAvailable={urlData?.relatedKeywordsFinal?.data?.testResult?.status}
                                status={urlData?.relatedKeywordsFinal?.data?.testResult?.status}
                                title={'Related Keywords Test Signals'}
                                infoMessage={commonSEOIssueInfo.relatedKeywords}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`RelatedKeywordSignals ${index + 1}`}>
                                        <RelatedKeywordsTest
                                          data={item}
                                          showDetail={index === 0}
                                          onShowDetail={() => setRelatedKeywordFlag(!relatedKeywordsFlag)}
                                          relatedKeywordList={relatedKeywordList}
                                        />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Find the keywords where this URL is listed in the top 20 results of Google's organic listings.`}
                                        message={`Get useful insights and detailed metrics for your most important keywords: average position, search volume, CPC, and more.`}
                                        registerMessage={`Register for free and start using today the Top Keywords tool from PositionMySite`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.domainCompetitors?.data ||
                            testType === 'ALL' ||
                            testType === 'CompetitorDomain' ? (
                              <CommonSEOHOC
                                id={'competitor'}
                                isConsidered={false}
                                isAvailable={urlData?.domainCompetitors?.data?.length}
                                title={'Competitor Domain Signals'}
                                infoMessage={commonSEOIssueInfo.competitorDomain}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`CompetitorDomainSignals-${index + 1}`}>
                                        <CompetitorDomain data={item} />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Understand your competitors' SEO and backlink profile`}
                                        message={`Get related competitors and their domain authority score in relation to your domain.`}
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* Heading Tags Test */}
                            {urlData?.headingAnalysis?.data ? (
                              <CommonSEOHOC
                                id={'heading-analysis'}
                                isAvailable={urlData?.headingAnalysis?.data?.testResults?.status}
                                status={urlData?.headingAnalysis?.data?.testResults?.status}
                                title={'Heading Tag Analysis Signals'}
                                infoMessage={commonSEOIssueInfo.headingAnalysis}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`HeadingTagSignals ${index + 1}`}>
                                        <HeadingAnalysis data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.robotsTxtTest?.data ? (
                              <CommonSEOHOC
                                id={'robots-txt'}
                                status={urlData?.robotsTxtTest?.data?.testResults?.status}
                                title={'Robots.txt Signal Analysis'}
                                data={urlData?.information?.seoCheckup?.robotsTestResults}
                                infoMessage={commonSEOIssueInfo.robotsTxt}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`RobotsSignals ${index + 1}`}>
                                        <RobotsText data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.siteMapData?.data ? (
                              <CommonSEOHOC
                                id={'sitemap-data'}
                                isAvailable={urlData?.siteMapData?.data?.testResults?.status}
                                status={urlData?.siteMapData?.data?.testResults?.status}
                                title={'Sitemap Signal Test'}
                                infoMessage={commonSEOIssueInfo.sitemapData}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`SitemaSignals ${index + 1}`}>
                                        <SiteMapData data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.seoFriendlyUrlAnalysis?.data ? (
                              <CommonSEOHOC
                                id={'seo-friendly'}
                                isAvailable={
                                  !urlData?.seoFriendlyUrlAnalysis?.data?.seoUnfriendly?.internalLinks?.length
                                }
                                status={urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status}
                                title={'SEO Friendly URL Signal Test'}
                                infoMessage={commonSEOIssueInfo.seoFriendly}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`FriendlySignals ${index + 1}`}>
                                        <SeoFriendlyUrlAnalysis
                                          data={item}
                                          showDetail={true}
                                          testType={testType}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.imageAlt?.data ? (
                              <CommonSEOHOC
                                id={'image-alt'}
                                // isAvailable={!urlData?.imageAlt?.data?.imgAlt?.length}
                                isAvailable={urlData?.imageAlt?.data?.testResults?.status}
                                status={urlData?.imageAlt?.data?.testResults?.status}
                                title={'Image Alt Test'}
                                infoMessage={commonSEOIssueInfo.imageAlt}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`ImageAltSignals ${index + 1}`}>
                                        <ImageAlt data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.descriptiveLinkTest?.data ? (
                              <CommonSEOHOC
                                id={'descriptive-test'}
                                // isAvailable={!urlData?.imageAlt?.data?.imgAlt?.length}
                                isAvailable={urlData?.descriptiveLinkTest?.data?.testResults?.status}
                                status={urlData?.descriptiveLinkTest?.data?.testResults?.status}
                                title={'Descriptive Link Signal Test'}
                                infoMessage={commonSEOIssueInfo.descriptiveLinkTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`DescriptiveLinkSignals ${index + 1}`}>
                                        <LinksALTDescriptions data={item} showDetail={true} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.responsiveImage?.data ? (
                              <CommonSEOHOC
                                id={'responsive-image'}
                                status={urlData?.responsiveImage?.data?.testResults?.status}
                                title={'Excessive Image Data Size Signal Test'}
                                infoMessage={commonSEOIssueInfo.responsiveImage}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`ExcessiveImageSignals ${index + 1}`}>
                                        <ResponsiveImage data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                                <ModalHOC
                                  show={responsiveImageFlag}
                                  toggleShow={() => setResponsiveImageFlag(!responsiveImageFlag)}
                                  title={'Full list of images with excessive data size'}
                                  bodyClassName="m-3"
                                  titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                                >
                                  <Table bordered>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '40%' }}>Image</th>
                                        <th style={{ width: '40%' }}>Total Size</th>
                                        <th style={{ width: '40%' }}>Potential Savings</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {urlData?.responsiveImage?.data?.data?.details?.items?.length &&
                                        urlData?.responsiveImage?.data?.data?.details?.items.map(
                                          (item, index) => {
                                            return (
                                              <tr key={`excessivesize ${index + 1}`}>
                                                <td>{item.url}</td>
                                                <td>{`${item?.totalBytes / 1000 || '-'} KB`}</td>
                                                <td>{`${item?.wastedBytes / 1000 || '-'} KB`}</td>
                                              </tr>
                                            )
                                          },
                                        )}
                                    </tbody>
                                  </Table>
                                </ModalHOC>
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.aspectRatio?.data ? (
                              <CommonSEOHOC
                                id={'aspect-ratio'}
                                status={urlData?.aspectRatio?.data?.testResults?.status}
                                title={'Image Aspect Ratio Signal Test'}
                                infoMessage={commonSEOIssueInfo.aspectRatio}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`ImageAspectSignals ${index + 1}`}>
                                        <AspectRatio data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.inlineCss?.data ? (
                              <CommonSEOHOC
                                id={'inline-css'}
                                status={urlData?.inlineCss?.data?.testResults?.status}
                                title={'Inline CSS Signal Test'}
                                infoMessage={commonSEOIssueInfo.inlineCss}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`InlineCSSSignals ${index + 1}`}>
                                        <InlineCss data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.htmlValidation?.data ? (
                              <CommonSEOHOC
                                id={'html-validation'}
                                status={urlData?.htmlValidation?.data?.testResults?.status}
                                title={'Html Validation'}
                                infoMessage={commonSEOIssueInfo.htmlValidation}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`HtmlValidationSignals ${index + 1}`}>
                                        <HtmlValidation data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.googleAnalytics ? (
                              <CommonSEOHOC
                                id={'google-analytics'}
                                status={urlData?.googleAnalytics?.data?.testResults?.status}
                                title={'Google Analytics Signal Test'}
                                infoMessage={commonSEOIssueInfo.googleAnalytics}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`GoogleAnalyticsSignals ${index + 1}`}>
                                        <GoogleAnalytics data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.faviconCheckerTest?.data ? (
                              <CommonSEOHOC
                                id={'favicon-test'}
                                status={urlData?.faviconCheckerTest?.data?.status}
                                title={'Favicon Checker Signal Test'}
                                infoMessage={commonSEOIssueInfo.faviconTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`FaviconCheckerSignals ${index + 1}`}>
                                        <FaviconCheckerTest data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* Internal Broken Links Test */}
                            {urlData?.brokenLinks?.data || testType === 'ALL' ? (
                              <CommonSEOHOC
                                id={'brokenLinks-data'}
                                // isAvailable={urlData?.brokenLinks?.data?.siteMapUrl?.length}
                                isAvailable={urlData?.brokenLinks?.data?.testResults?.status}
                                status={urlData?.brokenLinks?.data?.testResults?.status}
                                title={'Internal Broken Links Checker'}
                                infoMessage={commonSEOIssueInfo.brokenLinks}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`InternalBrokenLinksSignals ${index + 1}`}>
                                        <BrokenLinks data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Check your webpage for broken links!`}
                                        message={`Finding and fixing broken links on your webpage will help both user experience and search engine rankings.`}
                                        registerMessage={`Register for free, and start using today the Broken Links Tool from  PositionMySite`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.jsError?.data ? (
                              <CommonSEOHOC
                                id={'js-error'}
                                status={urlData?.jsError?.data?.testResults?.status}
                                title={'JS Error Signal Test'}
                                infoMessage={commonSEOIssueInfo.jsError}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`JSErrorLinksSignals ${index + 1}`}>
                                        <JSError data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.consoleErrors?.data ? (
                              <CommonSEOHOC
                                id={'console-errors'}
                                status={urlData?.consoleErrors?.data?.testResults?.status}
                                title={'Historic Console Error Signal Check'}
                                infoMessage={commonSEOIssueInfo.consoleErrors}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`ConsoleErrorsSignals ${index + 1}`}>
                                        <ConsoleErrors data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.advancedConsoleErrors?.data ? (
                              <CommonSEOHOC
                                id={'advanced-console-errors'}
                                status={urlData?.advancedConsoleErrors?.data?.testResults?.status}
                                title={'Advanced Console Error Signal Check'}
                                infoMessage={commonSEOIssueInfo.advancedConsoleErrors}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`AdvancedConsoleErrorsSignals ${index + 1}`}>
                                        <AdvancedConsoleErrors
                                          data={item}
                                          showDetail={true}
                                          testType={testType}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.characterSet?.data ? (
                              <CommonSEOHOC
                                id={'character-set'}
                                status={urlData?.characterSet?.data?.testResults?.status}
                                title={'Charset Declaration Signal Test'}
                                infoMessage={commonSEOIssueInfo.characterSet}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`CharsetDeclarationSignals ${index + 1}`}>
                                        <CharacterSet data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.socialMediaTest?.data || urlData?.socialMediaTwitterData?.data ? (
                              <CommonSEOHOC
                                id={'social-media'}
                                status={
                                  (urlData?.socialMediaTest?.data
                                    ? urlData?.socialMediaTest?.data?.facebookTestResults?.status === 3
                                      ? false
                                      : true
                                    : false) ||
                                  (urlData?.socialMediaTest?.data
                                    ? urlData?.socialMediaTest?.data?.linkedinTestResults?.status === 3
                                      ? false
                                      : true
                                    : false) ||
                                  (urlData?.socialMediaInstagramData?.data
                                    ? urlData?.socialMediaInstagramData?.data?.testResults?.status === 3
                                      ? false
                                      : true
                                    : false) ||
                                  (urlData?.socialMediaTwitterData?.data
                                    ? urlData?.socialMediaTwitterData?.data?.testResults?.status === 3
                                      ? false
                                      : true
                                    : false)
                                    ? 1
                                    : 3
                                }
                                title={'Social Media Signal Test'}
                                infoMessage={commonSEOIssueInfo.socialMedia}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`SocialMediaSignals ${index + 1}`}>
                                        <SocialMediaTest
                                          data={item}
                                          showDetail={index === 0}
                                          onShowDetail={() => setSocialMediaFlag(!socialMediaFlag)}
                                          facebookURL={
                                            item?.socialMediaTest?.data?.socialMediaData?.facebookURL
                                          }
                                          facebookData={
                                            item?.socialMediaTest?.data
                                              ? item?.socialMediaTest?.data?.facebookTestResults?.status === 3
                                                ? false
                                                : true
                                              : false
                                          }
                                          linkedInURL={
                                            item?.socialMediaTest?.data?.socialMediaData?.linkedinURL
                                          }
                                          linkedInData={
                                            item?.socialMediaTest?.data
                                              ? item?.socialMediaTest?.data?.linkedinTestResults?.status === 3
                                                ? false
                                                : true
                                              : false
                                          }
                                          twitterURL={
                                            item?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                              ?.valueData?.[3]
                                          }
                                          instaURL={item?.socialMediaInstagramData?.data?.socialBlade?.url}
                                          instagramData={
                                            item?.socialMediaInstagramData?.data
                                              ? item?.socialMediaInstagramData?.data?.testResults?.status ===
                                                3
                                                ? false
                                                : true
                                              : false
                                          }
                                          twitterData={
                                            item?.socialMediaTwitterData?.data
                                              ? item?.socialMediaTwitterData?.data?.testResults?.status === 3
                                                ? false
                                                : true
                                              : false
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                                <ModalHOC
                                  show={socialMediaFlag}
                                  toggleShow={() => setSocialMediaFlag(!socialMediaFlag)}
                                  title={'Social Media Data'}
                                  bodyClassName="m-3"
                                  titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                                >
                                  <Row className="mt-3">
                                    <Col>
                                      {urlData?.socialMediaTwitterData?.data?.twitterData?.twitterData
                                        ?.valueData?.length ? (
                                        <>
                                          <h3 style={{ textAlign: 'center' }}>
                                            <b>Twitter Data</b>
                                          </h3>
                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Location</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[0] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[0]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <hr className="my-2" />

                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Joined</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[1] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[1]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <hr className="my-2" />

                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Followers</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[2] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[2]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <hr className="my-2" />

                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Following</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[3] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[3]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <hr className="my-2" />

                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Followers Ratio</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[4] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[4]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <hr className="my-2" />

                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Tweets</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[5] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[5]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>

                                          <hr className="my-2" />

                                          <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                              <p className="my-1">
                                                <strong>Listed</strong>
                                              </p>
                                              <h3>
                                                {urlData?.socialMediaTwitterData?.data?.twitterData
                                                  ?.twitterData?.valueData?.[6] === '' ? (
                                                  '-'
                                                ) : (
                                                  <>
                                                    {
                                                      urlData?.socialMediaTwitterData?.data?.twitterData
                                                        ?.twitterData?.valueData?.[6]
                                                    }
                                                  </>
                                                )}
                                              </h3>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col>
                                      <h3 className="social-media-modal-header">
                                        <b>Facebook Data</b>
                                      </h3>
                                      <Table bordered>
                                        <thead>
                                          <tr>
                                            <th style={{ width: '50%' }}>Data</th>
                                            <th style={{ width: '50%' }}>Value</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {urlData?.socialMediaTest?.data?.facebookData?.length ? (
                                            urlData?.socialMediaTest?.data?.facebookData.map(
                                              (item, index) => {
                                                return (
                                                  <tr key={`facebook ${index + 1}`}>
                                                    <td>{item.key}</td>
                                                    <td>{item.value}</td>
                                                  </tr>
                                                )
                                              },
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </ModalHOC>
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                          </CardBody>
                        </Card>
                      ) : null}
                      {isSiteLoadingSignalsAvailable ? (
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md="4">
                                <h3 className="f-bold">WEBSITE SITE LOADING SIGNALS</h3>
                              </Col>
                              <Col>
                                <Row className="display-end">
                                  <Col md="3">
                                    <span>
                                      Score:{' '}
                                      {(
                                        (speedOptimizeSCounter * 100) /
                                        (speedOptimizeFCounter +
                                          speedOptimizeWCounter +
                                          speedOptimizeSCounter)
                                      ).toFixed(0)}
                                    </span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant={
                                        (
                                          (speedOptimizeSCounter * 100) /
                                          (speedOptimizeFCounter +
                                            speedOptimizeWCounter +
                                            speedOptimizeSCounter)
                                        ).toFixed(0) > 75
                                          ? 'success'
                                          : (
                                                (speedOptimizeSCounter * 100) /
                                                (speedOptimizeFCounter +
                                                  speedOptimizeWCounter +
                                                  speedOptimizeSCounter)
                                              ).toFixed(0) <= 50
                                            ? 'danger'
                                            : 'warning'
                                      }
                                      now={
                                        (
                                          (speedOptimizeSCounter * 100) /
                                          (speedOptimizeFCounter +
                                            speedOptimizeWCounter +
                                            speedOptimizeSCounter)
                                        ).toFixed(0) || 0
                                      }
                                      label=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <span>Success: {speedOptimizeSCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="success"
                                      now={speedOptimizeSCounter || 0}
                                      max={testType === 'ALL' ? 25 : speedOptimizeSCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Warning: {speedOptimizeWCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="warning"
                                      now={speedOptimizeWCounter || 0}
                                      max={testType === 'ALL' ? 25 : speedOptimizeWCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Failed: {speedOptimizeFCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="danger"
                                      now={speedOptimizeFCounter || 0}
                                      max={testType === 'ALL' ? 25 : speedOptimizeFCounter}
                                      label=""
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </CardHeader>
                          <CardBody>
                            {/* Speed optimizations section */}

                            {urlData?.htmlPageSize ? (
                              <CommonSEOHOC
                                id={'html-page'}
                                status={urlData?.htmlPageSize?.data?.testResults?.status}
                                title={'HTML Page Size Signal Test'}
                                infoMessage={commonSEOIssueInfo.htmlPage}
                                showDevider={false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`HTMLPageSizeSignals ${index + 1}`}>
                                        <HtmlPageSize data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.domSize?.data ? (
                              <CommonSEOHOC
                                id={'dom-size'}
                                status={urlData?.domSize?.data?.testResults?.status}
                                title={'DOM Size Signal Test'}
                                infoMessage={commonSEOIssueInfo.domSize}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`DOMSizeSignals ${index + 1}`}>
                                        <DomSize
                                          data={item}
                                          showDetail={index === 0}
                                          onShowDetail={() => setDomSizeFlag(!domSizeFlag)}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                                <ModalHOC
                                  show={domSizeFlag}
                                  toggleShow={() => setDomSizeFlag(!domSizeFlag)}
                                  title={'Dom Elements'}
                                  bodyClassName="m-3"
                                  titleStyles={{ fontWeight: '600', fontSize: '22px' }}
                                >
                                  <Table bordered>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '40%' }}>Elements List</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {urlData?.domSize?.data?.domSizeResult?.details?.items?.length &&
                                        urlData?.domSize?.domSizeResult?.data?.details?.items.map(
                                          (item, index) => {
                                            return (
                                              <tr key={`domElements ${index + 1}`}>
                                                <td>{item.url}</td>
                                              </tr>
                                            )
                                          },
                                        )}
                                    </tbody>
                                  </Table>
                                </ModalHOC>
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {testType === "ALL" ? (
                              <CommonSEOHOC
                                id={'backlinks'}
                                isConsidered={false}
                                title={'Backlinks'}
                                infoMessage={commonSEOIssueInfo.Backlinks}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Get a full and detailed list of your backlinks!`}
                                        message={`To view your total number of backlinks and referring domains, please sign-up for a free trial!`}
                                      />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.htmlCompression?.data ? (
                              <CommonSEOHOC
                                id={'html-compression'}
                                status={urlData?.htmlCompression?.data?.testResults?.status}
                                title={'Web Content Compression Check'}
                                infoMessage={commonSEOIssueInfo.htmlCompression}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`HTMLCompressionSignals ${index + 1}`}>
                                        <HtmlCompression data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* Site Loading Speed  */}
                            {urlData?.siteSpeed?.data ? (
                              <CommonSEOHOC
                                id={'site-speed'}
                                status={urlData?.siteSpeed?.data?.testResults?.status}
                                title={'Site Loading Speed Signal Test'}
                                infoMessage={commonSEOIssueInfo.siteSpeed}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`SiteLoadingSignals-${index + 1}`}>
                                        <SiteSpeed data={item} />
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.bootupTime?.data ? (
                              <CommonSEOHOC
                                id={'js-execution-time'}
                                status={urlData?.bootupTime?.data?.testResults?.status}
                                title={'JS Execution Signal Test'}
                                infoMessage={commonSEOIssueInfo.jsExecutionTime}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <BootupTime data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.pageObject?.data ? (
                              <CommonSEOHOC
                                id={'page-object'}
                                status={urlData?.pageObject?.data?.testResults?.status}
                                title={'Page Objects Signal Test'}
                                infoMessage={commonSEOIssueInfo.pageObject}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <PageObject data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.pageCache?.data ? (
                              <CommonSEOHOC
                                id={'page-cache'}
                                status={urlData?.pageCache?.data?.testResults?.status}
                                title={'Page Cache Test (Server Side Caching) Signal Analysis'}
                                infoMessage={commonSEOIssueInfo.pageCache}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <PageCache data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.flashTest?.data ? (
                              <CommonSEOHOC
                                id={'flash-test'}
                                status={urlData?.flashTest?.data?.testResults?.status}
                                title={'Flash Signal Test '}
                                infoMessage={commonSEOIssueInfo.flashTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <FlashTest data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.cdnUsageData?.data ? (
                              <CommonSEOHOC
                                id={'cdn-usage'}
                                isAvailable={urlData?.cdnUsageData?.data?.testResult?.status}
                                status={urlData?.cdnUsageData?.data?.testResult?.status}
                                title={'CDN Usage Signal Test'}
                                infoMessage={commonSEOIssueInfo.cdnUsage}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <CDNUsage data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.mordenImage?.data ? (
                              <CommonSEOHOC
                                id={'morden-image'}
                                status={urlData?.mordenImage?.data?.testResults?.status}
                                title={'Morden Image Signal Test'}
                                infoMessage={commonSEOIssueInfo.mordenImage}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <MordenImage data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* Missing: Image Metadata Test */}
                            {/* cache poliy */}
                            {urlData?.cachePolicy?.data ? (
                              <CommonSEOHOC
                                id={'cache-policy'}
                                isAvailable={urlData?.cachePolicy?.data?.testResults?.status}
                                status={urlData?.cachePolicy?.data?.testResults?.status}
                                title={'Cache Policy Signal Test'}
                                infoMessage={commonSEOIssueInfo.cachePolicy}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length
                                  ? multiData.map((item) =>
                                      item ? (
                                        <CachePolicy data={item} showDetail={true} testType={testType} />
                                      ) : null,
                                    )
                                  : null}
                              </CommonSEOHOC>
                            ) : null}

                            {urlData?.imageCache?.data ? (
                              <CommonSEOHOC
                                id={'image-cache'}
                                isAvailable={urlData?.imageCache?.data?.testResults?.status}
                                status={urlData?.imageCache?.data?.testResults?.status}
                                title={'Image Cache Signal Test'}
                                infoMessage={commonSEOIssueInfo.imageCache}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <ImageCache data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.jsCache?.data ? (
                              <CommonSEOHOC
                                id={'js-cache'}
                                isAvailable={urlData?.jsCache?.data?.testResults?.status}
                                status={urlData?.jsCache?.data?.testResults?.status}
                                title={'JavaScript Caching Signal Test'}
                                infoMessage={commonSEOIssueInfo.jsCache}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <JSCache data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.cssCache?.data ? (
                              <CommonSEOHOC
                                id={'css-cache'}
                                isAvailable={urlData?.cssCache?.data?.testResults?.status}
                                status={urlData?.cssCache?.data?.testResults?.status}
                                title={'CSS Caching Signal Test'}
                                infoMessage={commonSEOIssueInfo.cssCache}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? <CSSCache data={item} showDetail={true} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.jsMinification?.data ? (
                              <CommonSEOHOC
                                id={'js-minification'}
                                isAvailable={urlData?.jsMinification?.data?.testResults?.status}
                                status={urlData?.jsMinification?.data?.testResults?.status}
                                title={'JavaScript Minification Signal Test'}
                                infoMessage={commonSEOIssueInfo.jsMinification}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <JSMinification data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.cssMinification?.data ? (
                              <CommonSEOHOC
                                id={'css-minification'}
                                // isAvailable={!urlData?.cssMinification?.data?.nonMinifiedCss?.length}
                                isAvailable={urlData?.cssMinification?.data?.testResults?.status}
                                status={urlData?.cssMinification?.data?.testResults?.status}
                                title={'CSS Minification Signal Test'}
                                infoMessage={commonSEOIssueInfo.cssMinification}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <CSSMinification data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.renderBlocking?.data ? (
                              <CommonSEOHOC
                                id={'render-blocking'}
                                status={urlData?.renderBlocking?.data?.testResults?.status}
                                title={'Render Blocking Signal Test'}
                                infoMessage={commonSEOIssueInfo.renderBlocking}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <RenderBlocking data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.nestedTable?.data ? (
                              <CommonSEOHOC
                                id={'nested-table'}
                                status={urlData?.nestedTable?.data?.testResults?.status}
                                title={'Nested Tables Signal Test'}
                                infoMessage={commonSEOIssueInfo.nestedTable}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <NestedTable data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.framesetTest?.data ? (
                              <CommonSEOHOC
                                id={'frameset-test'}
                                status={urlData?.framesetTest?.data?.testResults.status}
                                title={'Frameset Signal Test'}
                                infoMessage={commonSEOIssueInfo.framesetTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <FramesetTest data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.docType?.data ? (
                              <CommonSEOHOC
                                id={'doc-type'}
                                status={urlData?.docType?.data?.testResults?.status}
                                title={'Doctype Signal Test'}
                                infoMessage={commonSEOIssueInfo.docType}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <DocType data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* URL Redirects Test */}
                            {urlData?.redirectTest ? (
                              <CommonSEOHOC
                                id={'redirect-test'}
                                status={urlData?.redirectTest?.data?.testResults?.status}
                                title={'Redirect Signal Test'}
                                infoMessage={commonSEOIssueInfo.redirectTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? <RedirectTest data={item} showDetail={index === 0} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.contentfulPaint?.data ? (
                              <CommonSEOHOC
                                id={'contentful-paint'}
                                status={urlData?.contentfulPaint?.data?.testResults?.status}
                                title={'Contentful Paint Signal Test'}
                                infoMessage={commonSEOIssueInfo.contentfulPaint}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <ContentfulPaint data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.layoutShift?.data ? (
                              <CommonSEOHOC
                                id={'layout-shift'}
                                status={urlData?.layoutShift?.data?.testResults?.status}
                                title={'Cumulative Layout Shift Signal Test'}
                                infoMessage={commonSEOIssueInfo.layoutShift}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <LayoutShift data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {urlData?.cssAnalysis?.data ? (
                              <CommonSEOHOC
                                id={'css-analysis'}
                                status={urlData?.cssAnalysis?.data?.testResults?.status}
                                title={'CSS Analysis'}
                                infoMessage={commonSEOIssueInfo.cssAnalysis}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <CSSAnalysis data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                          </CardBody>
                        </Card>
                      ) : null}
                      {isConfigurationAndSecuritySignals ? (
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md="4">
                                <h3 className="f-bold">SERVER CONFIGURATION & SECURITY SIGNALS</h3>
                              </Col>
                              <Col>
                                <Row className="display-end">
                                  <Col md="3">
                                    <span>
                                      Score:{' '}
                                      {(
                                        (serverSCounter * 100) /
                                        (serverFCounter + serverWCounter + serverSCounter)
                                      ).toFixed(0)}
                                    </span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant={
                                        (
                                          (serverSCounter * 100) /
                                          (serverFCounter + serverWCounter + serverSCounter)
                                        ).toFixed(0) > 75
                                          ? 'success'
                                          : (
                                                (serverSCounter * 100) /
                                                (serverFCounter + serverWCounter + serverSCounter)
                                              ).toFixed(0) <= 50
                                            ? 'danger'
                                            : 'warning'
                                      }
                                      now={
                                        (
                                          (serverSCounter * 100) /
                                          (serverFCounter + serverWCounter + serverSCounter)
                                        ).toFixed(0) || 0
                                      }
                                      label=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <span>Success: {serverSCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="success"
                                      now={serverSCounter || 0}
                                      max={testType === 'ALL' ? 13 : serverSCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Warning: {serverWCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="warning"
                                      now={serverWCounter || 0}
                                      max={testType === 'ALL' ? 13 : serverWCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Failed: {serverFCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="danger"
                                      now={serverFCounter || 0}
                                      max={testType === 'ALL' ? 13 : serverFCounter}
                                      label=""
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </CardHeader>
                          <CardBody>
                            {/* Server and security section */}

                            {urlData?.urlCanonicalization?.data ? (
                              <CommonSEOHOC
                                id={'url-canonicalization'}
                                title={'URL Canonicalization Signal Test'}
                                infoMessage={commonSEOIssueInfo.urlCanonicalization}
                                status={urlData?.urlCanonicalization?.data?.testResult?.status}
                                showDevider={false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? <UrlCanonicalization data={item} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* Mixed Content Test (HTTP over HTTPS) */}
                            {urlData?.httpsTest?.data ? (
                              <CommonSEOHOC
                                id={'https-test'}
                                status={urlData?.httpsTest?.data?.testResults?.status}
                                title={'Mixed Content Test (HTTP over HTTPS)'}
                                infoMessage={commonSEOIssueInfo.httpsTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <HttpsTest data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.http2Test?.data ? (
                              <CommonSEOHOC
                                id={'http2-test'}
                                status={urlData?.http2Test?.data?.testResults?.status}
                                title={'HTTP2 Signal Test'}
                                infoMessage={commonSEOIssueInfo.http2Test}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <Http2Test data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.hstsTest?.data ? (
                              <CommonSEOHOC
                                id={'hsts'}
                                status={urlData?.hstsTest?.data?.testResults?.status}
                                title={'HSTS Signal Analysis'}
                                infoMessage={commonSEOIssueInfo.hsts}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <HSTS data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.safeBrowsing?.data ? (
                              <CommonSEOHOC
                                id={'safe-browsing'}
                                status={urlData?.safeBrowsing?.data?.testResults?.status}
                                title={'Safe Browsing Signal Test'}
                                infoMessage={commonSEOIssueInfo.safeBrowsing}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <SafeBrowsing data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.serverSignature?.data ? (
                              <CommonSEOHOC
                                id={'server-signature'}
                                status={urlData?.serverSignature?.data?.testResults?.status}
                                title={'Server Signature Analysis'}
                                infoMessage={commonSEOIssueInfo.serverSignature}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <ServerSignature data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.directoryBrowsing?.data ? (
                              <CommonSEOHOC
                                id={'directory-browsing'}
                                status={urlData?.directoryBrowsing?.data?.testResults?.status}
                                title={'Directory Browsing Analysis'}
                                infoMessage={commonSEOIssueInfo.directoryBrowsing}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <DirectoryBrowsing data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.plainTextEmail?.data ? (
                              <CommonSEOHOC
                                id={'plain-text'}
                                status={urlData?.plainTextEmail?.data?.testResults?.status}
                                title={'Plaintext Emails Signal Test'}
                                infoMessage={commonSEOIssueInfo.plainText}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <PlainTextEmail data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.unsafeCrossOriginLinksTest?.data ? (
                              <CommonSEOHOC
                                id={'unsafe-cross-origin-links'}
                                status={urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status}
                                title={'Unsafe Cross-Origin Signal Analysis'}
                                infoMessage={commonSEOIssueInfo.unsafeCrossOriginLinks}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <UnsafeCrossOriginLinks
                                        data={item}
                                        showDetail={true}
                                        testType={testType}
                                      />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* SSL Checker and HTTPS Test */}
                            {urlData?.sslFreakTest?.data ||
                            testType === 'ALL' ||
                            testType === 'SslFreakTest' ? (
                              <CommonSEOHOC
                                id={'ssl-freak'}
                                status={urlData?.sslFreakTest?.data?.testResults?.status}
                                title={'SSL and HTTPS Signals '}
                                infoMessage={commonSEOIssueInfo.sslFreak}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`sslFreak-${index + 1}`}>
                                        <SSLHttpTest data={item} />{' '}
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Secure Your Website: Discover SSL and HTTPS Signals!`}
                                        message={`Explore the SSL and HTTPS certificates of your website to ensure robust security and build trust with visitors. Discover how these certificates impact your site's credibility and search engine rankings.`}
                                        registerMessage={`Register for free and start using PositionMySite to level your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.brotilTest?.data ? (
                              <CommonSEOHOC
                                id={'brotil-test'}
                                status={urlData?.brotilTest?.data?.testResults?.status}
                                title={'Brotli Analysis Test'}
                                infoMessage={commonSEOIssueInfo.brotilTest}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <BrotilTest data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.dnsSecurityTest?.data ||
                            testType === 'ALL' ||
                            testType === 'DNSSecurity' ? (
                              <CommonSEOHOC
                                id={'dns-security'}
                                isConsidered={false}
                                status={urlData?.dnsSecurityTest?.data?.testResults?.status}
                                title={'DNS Security'}
                                infoMessage={commonSEOIssueInfo.dnsSecurity}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item && testType !== 'ALL' ? (
                                      <div key={`dnsSecurityTest-${index + 1}`}>
                                        <DnsSecurityTest data={item} showDetail={true} testType={testType} />
                                      </div>
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Secure Your Online Presence: Explore DNS Security Insights!`}
                                        message={`Gain valuable insights into your website's DNS security posture and strengthen your defense against cyber threats.`}
                                        registerMessage={`Register for free and start using PositionMySite to level up your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.tlsLogjamTest?.data ? (
                              <CommonSEOHOC
                                id={'tls-logjam'}
                                status={urlData?.tlsLogjamTest?.data?.testResults?.status}
                                title={'TLS Logjam Signal Test'}
                                infoMessage={commonSEOIssueInfo.tlsLogjam}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <TlsLogjam data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                          </CardBody>
                        </Card>
                      ) : null}
                      {/* Missing: Unsafe Cross-Origin Links Test */}

                      {isResponsiveAndSpeedSignals ? (
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md="4">
                                <h3 className="f-bold">MOBILE RESPONSIVE & MOBILE SPEED SIGNALS</h3>
                              </Col>
                              <Col>
                                <Row className="display-end">
                                  <Col md="3">
                                    <span>
                                      Score:{' '}
                                      {(
                                        (mobileUsabilitySCounter * 100) /
                                        (mobileUsabilityFCounter +
                                          mobileUsabilityWCounter +
                                          mobileUsabilitySCounter)
                                      ).toFixed(0)}
                                    </span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant={
                                        (
                                          (mobileUsabilitySCounter * 100) /
                                          (mobileUsabilityFCounter +
                                            mobileUsabilityWCounter +
                                            mobileUsabilitySCounter)
                                        ).toFixed(0) > 75
                                          ? 'success'
                                          : (
                                                (mobileUsabilitySCounter * 100) /
                                                (mobileUsabilityFCounter +
                                                  mobileUsabilityWCounter +
                                                  mobileUsabilitySCounter)
                                              ).toFixed(0) <= 50
                                            ? 'danger'
                                            : 'warning'
                                      }
                                      now={
                                        (
                                          (mobileUsabilitySCounter * 100) /
                                          (mobileUsabilityFCounter +
                                            mobileUsabilityWCounter +
                                            mobileUsabilitySCounter)
                                        ).toFixed(0) || 0
                                      }
                                      label=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <span>Success: {mobileUsabilitySCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="success"
                                      now={mobileUsabilitySCounter || 0}
                                      max={testType === 'ALL' ? 12 : mobileUsabilitySCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Warning: {mobileUsabilityWCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="warning"
                                      now={mobileUsabilityWCounter || 0}
                                      max={testType === 'ALL' ? 12 : mobileUsabilityWCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Failed: {mobileUsabilityFCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="danger"
                                      now={mobileUsabilityFCounter || 0}
                                      max={testType === 'ALL' ? 12 : mobileUsabilityFCounter}
                                      label=""
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </CardHeader>
                          <CardBody>
                            {/* Mobile usability section */}

                            {urlData?.viewport?.data ? (
                              <CommonSEOHOC
                                id={'viewport'}
                                status={urlData?.viewport?.data?.testResults?.status}
                                title={'Viewport Analysis'}
                                infoMessage={commonSEOIssueInfo.viewport}
                                showDevider={false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <Viewport data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {urlData?.fontSizeTest?.data ? (
                              <CommonSEOHOC
                                id={'font-size'}
                                status={urlData?.fontSizeTest?.data?.testResults?.status}
                                title={'Font Size Signals'}
                                infoMessage={commonSEOIssueInfo.fontSize}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length
                                  ? multiData.map((item) => (item ? <FontSizeTest data={item} /> : null))
                                  : null}
                              </CommonSEOHOC>
                            ) : null}
                            {/* tapTargetTest title*/}
                            {urlData?.tapTargetTest?.data ? (
                              <CommonSEOHOC
                                id={'tap-target'}
                                status={urlData?.tapTargetTest?.data?.testResults?.status}
                                title={'Tap Target Signals'}
                                infoMessage={commonSEOIssueInfo.tapTargets}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <TapTargets data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* contentWidthTest title*/}
                            {urlData?.contentWidthTest?.data ? (
                              <CommonSEOHOC
                                id={'content-width'}
                                status={urlData?.contentWidthTest?.data?.testResults?.status}
                                title={'Content-width Signals'}
                                infoMessage={commonSEOIssueInfo.contentWidth}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <ContentWidth data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* speedIndexTest title*/}
                            {urlData?.speedIndexTest?.data ? (
                              <CommonSEOHOC
                                id={'speed-index'}
                                status={urlData?.speedIndexTest?.data?.testResults?.status}
                                title={'Speed Index Signals'}
                                infoMessage={commonSEOIssueInfo.speedIndex}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <SpeedIndex data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* firstContentfulPaintTest title*/}
                            {urlData?.firstContentfulPaintTest?.data ? (
                              <CommonSEOHOC
                                id={'first-contentful-paint'}
                                status={urlData?.firstContentfulPaintTest?.data?.testResults?.status}
                                title={'First Contentful Paint Signals'}
                                infoMessage={commonSEOIssueInfo.firstContentfulPaint}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? <FirstContentfulPaint data={item} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* largestContentfulPaintTest title*/}
                            {urlData?.largestContentfulPaintTest?.data ? (
                              <CommonSEOHOC
                                id={'last-contentful-paint'}
                                status={urlData?.largestContentfulPaintTest?.data?.testResults?.status}
                                title={'Last Contentful Paint Signals'}
                                infoMessage={commonSEOIssueInfo.largestContentfulPaint}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? <LargestContentfulPaint data={item} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* cumulativeLayoutShiftTest title*/}
                            {urlData?.cumulativeLayoutShiftTest?.data ? (
                              <CommonSEOHOC
                                id={'cumulative-layout-shift-test'}
                                status={urlData?.cumulativeLayoutShiftTest?.data?.testResults?.status}
                                title={'Cumulative Layout Shift Signals'}
                                infoMessage={commonSEOIssueInfo.cumulativeLayoutShift}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? <CumulativeLayoutShift data={item} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* interactiveTest title*/}
                            {urlData?.interactiveTest?.data ? (
                              <CommonSEOHOC
                                id={'interactive'}
                                status={urlData?.interactiveTest?.data?.testResults?.status}
                                title={'Interactive Signals'}
                                infoMessage={commonSEOIssueInfo.timetoInteractive}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? <TimetoInteractiveTest data={item} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                            {/* firstInputDelayTest title*/}
                            {urlData?.firstInputDelayTest?.data ? (
                              <CommonSEOHOC
                                id={'first-input-delay'}
                                status={urlData?.firstInputDelayTest?.data?.testResults?.status}
                                title={'First Input Delay Signals'}
                                infoMessage={commonSEOIssueInfo.firstInputDelay}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <FirstInputDelay data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.mobileSnapShotTest?.data ? (
                              <CommonSEOHOC
                                id={'mobile-screenshot'}
                                status={urlData?.mobileSnapShotTest?.data?.length > 2 ? 1 : 3}
                                isConsidered={false}
                                title={'Mobile Screenshot Analysis'} //Mobile Snapshot
                                infoMessage={commonSEOIssueInfo.mobileScreenshot}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? <MobileScreenshot data={item} showDetail={index === 0} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.desktopSnapShotTest?.data ? (
                              <CommonSEOHOC
                                id={'desktop-screenshot'}
                                status={urlData?.desktopSnapShotTest?.data?.length > 2 ? 1 : 3}
                                isConsidered={false}
                                title={'Desktop Screenshot Analysis'}
                                infoMessage={commonSEOIssueInfo.desktopScreenshot}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? <DesktopScreenshot data={item} showDetail={index === 0} /> : <></>,
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}
                          </CardBody>
                        </Card>
                      ) : null}
                      {/* Advanced SEO section */}

                      {isAdvanceSeoSignalsAvailable ? (
                        <Card className="mb-0">
                          <CardHeader>
                            <Row>
                              <Col md="4">
                                <h3 className="f-bold">Advanced SEO Signal Test</h3>
                              </Col>
                              <Col>
                                <Row className="display-end">
                                  <Col md="3">
                                    <span>
                                      Score:{' '}
                                      {(
                                        (advanceSeoSCounter * 100) /
                                        (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                      ).toFixed(0)}
                                    </span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant={
                                        (
                                          (advanceSeoSCounter * 100) /
                                          (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                        ).toFixed(0) > 75
                                          ? 'success'
                                          : (
                                                (advanceSeoSCounter * 100) /
                                                (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                              ).toFixed(0) <= 50
                                            ? 'danger'
                                            : 'warning'
                                      }
                                      now={
                                        (
                                          (advanceSeoSCounter * 100) /
                                          (advanceSeoFCounter + advanceSeoWCounter + advanceSeoSCounter)
                                        ).toFixed(0) || 0
                                      }
                                      label=""
                                    />
                                  </Col>
                                  <Col md="3">
                                    <span>Success: {advanceSeoSCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="success"
                                      now={advanceSeoSCounter || 0}
                                      max={testType === 'ALL' ? 9 : advanceSeoSCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Warning: {advanceSeoWCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="warning"
                                      now={advanceSeoWCounter || 0}
                                      max={testType === 'ALL' ? 9 : advanceSeoWCounter}
                                      label=""
                                    />
                                  </Col>

                                  <Col md="3">
                                    <span>Failed: {advanceSeoFCounter}</span>
                                    <ProgressBar
                                      className="mb-3"
                                      variant="danger"
                                      now={advanceSeoFCounter || 0}
                                      max={testType === 'ALL' ? 9 : advanceSeoFCounter}
                                      label=""
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <hr />
                          </CardHeader>
                          <CardBody>
                            { testType === 'ALL' || testType === 'MicrodataSchemaAnalysis' ? (
                              <CommonSEOHOC
                                id={'microdata-schema'}
                                status={urlData?.microDataSchema?.data?.microSchemaResults?.status}
                                title={'Microdata Schema Analysis'} //Structured Data Test
                                infoMessage={commonSEOIssueInfo.microdataSchema}
                                showDevider={false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item && testType !== 'ALL' ? (
                                      <MicroDataSchema data={item} showDetail={true} testType={testType} />
                                    ) : (
                                      <SubscriptionRequired
                                        url={item?.url}
                                        heading={`Enhance Your Website's Visibility: Microdata Schema Analysis!`}
                                        message={`Unlock the power of microdata schema to improve your website's visibility in search results. Understand how structured data markup can boost your SEO and drive targeted traffic.`}
                                        registerMessage={`Register for free and start using PositionMySite to level up your SEO`}
                                        isRegisterMessageAvailable
                                      />
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.custom404?.data ? (
                              <CommonSEOHOC
                                id={'custom-404'}
                                title={'Custom 404 Signal Test'}
                                infoMessage={commonSEOIssueInfo.custom404}
                                status={urlData?.custom404?.data?.testResult?.status}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <Custom404 data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.noIndexChecker?.data ? (
                              <CommonSEOHOC
                                id={'no-index'}
                                status={urlData?.noIndexChecker?.data?.testResult?.status}
                                title={'No Index Checker Signal Test'}
                                infoMessage={commonSEOIssueInfo.noIndex}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <NoIndexChecker data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.canonicalUrl?.data ? (
                              <CommonSEOHOC
                                id={'canonical-url'}
                                title={'Canonical URL Signal Test'}
                                infoMessage={commonSEOIssueInfo.canonicalURL}
                                status={urlData?.canonicalUrl?.data?.testResult?.status}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <CanonicalURL data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.noFollow ? (
                              <CommonSEOHOC
                                id={'no-follow'}
                                isConsidered={false}
                                status={urlData?.noFollow?.data?.testResults?.status}
                                title={'No Follow Signal Test'}
                                infoMessage={commonSEOIssueInfo.noFollow}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <NoFollow
                                        data={item}
                                        showDetail={index === 0}
                                        onShowDetail={() => setNoFollowFlag(!noFollowFlag)}
                                      />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.disallowDirectiveChecker?.data ? (
                              <CommonSEOHOC
                                id={'disalllow-directive'}
                                isConsidered={false}
                                isAvailable={urlData?.disallowDirectiveChecker?.data?.finalRes?.status}
                                title={'Disallow Directive Signal Test'}
                                infoMessage={commonSEOIssueInfo.disalllowDirective}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <DisallowDirective data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.metaRefresh?.data ? (
                              <CommonSEOHOC
                                id={'meta-refresh'}
                                title={'Meta Refresh Signal Test'}
                                infoMessage={commonSEOIssueInfo.metaRefresh}
                                status={urlData?.metaRefresh?.data?.testResult?.status}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) => (item ? <MetaRefresh data={item} /> : <></>))
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.spfRecord?.data ? (
                              <CommonSEOHOC
                                id={'spf-record'}
                                title={'SPF Record Analysis'}
                                infoMessage={commonSEOIssueInfo.spfRecord}
                                status={urlData?.spfRecord?.data?.testResult?.status}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item, index) =>
                                    item ? (
                                      <div key={`spfRecordTest-${index + 1}]`}>
                                        <SpfRecord data={item} />{' '}
                                      </div>
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {urlData?.adsTxt?.data ? (
                              <CommonSEOHOC
                                id={'ads-txt'}
                                status={urlData?.adsTxt?.data?.testResults?.status}
                                title={'Ads.txt Analysis'}
                                infoMessage={commonSEOIssueInfo.adsTxt}
                                showDevider={testType === 'ALL' ? true : false}
                              >
                                {multiData?.length ? (
                                  multiData.map((item) =>
                                    item ? (
                                      <AdsTxt
                                        data={item}
                                        showDetail={true}
                                        onShowDetail={() => setAdsTxtFlag(!adsTxtFlag)}
                                      />
                                    ) : (
                                      <></>
                                    ),
                                  )
                                ) : (
                                  <></>
                                )}
                              </CommonSEOHOC>
                            ) : (
                              <></>
                            )}

                            {/* Missing: Spell Check Test */}
                          </CardBody>
                        </Card>
                      ) : null}
                    </Col>
                  </Row>

                  {/* ********** */}
                </div>
                {testType === 'ALL' ? (
                  <div className="demo-report-sidebar d-none d-lg-block d-sm-none sidebar-visibility">
                    {(urlData && urlData !== '' && (
                      <Accordion defaultActiveKey="0" style={{ height: 'calc(100vh - 182px)' }}>
                        <Accordion.Item eventKey="0" className="headerStyle">
                          {/* <Accordion.Header> */}
                          <Container className="sidebar-row-container" fluid>
                            <Row className="f-8">
                              <Col className="report-test-section-name" md="4">
                                GENERAL
                              </Col>
                              <Col>
                                {' '}
                                <span className="pass-block">
                                  {' '}
                                  <img style={{ height: 14 }} src={tickIcon} />{' '}
                                  <span style={{ color: palette.success }}>{positiveCounter}</span>
                                </span>{' '}
                                <span className="pass-block">
                                  {' '}
                                  <img style={{ height: 14 }} src={warningIcon} />{' '}
                                  <span style={{ color: palette.warning, marginRight: 5 }}>
                                    {warningCounter}
                                  </span>
                                </span>{' '}
                                <span className="pass-block">
                                  <img style={{ height: 14 }} src={crossIcon} />{' '}
                                  <span style={{ color: palette.danger }}>{negativeCounter}</span>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                          {/* </Accordion.Header> */}
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  PRIMARY SEO SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block" style={{ alignItem: 'center' }}>
                                    {' '}
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{commonSeoSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    {' '}
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning, marginRight: 5 }}>
                                      {commonSeoWCounter}
                                    </span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{commonSeoFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              {urlData?.information?.seoCheckup?.title?.titleStatus === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.information?.seoCheckup?.title?.titleStatus === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="meta-title" spy={true} smooth={true} offset={-150} duration={500}>
                                Meta Title Signal
                              </Link>
                            </p>

                            <p>
                              {urlData?.information?.seoCheckup?.description?.descriptionStatus === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.information?.seoCheckup?.description?.descriptionStatus === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="meta-description"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Meta Description Signal
                              </Link>
                            </p>

                            <p>
                              {urlData?.microDataSchema?.data?.metaTagsResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.microDataSchema?.data?.metaTagsResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="social-media-meta-tag"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Social Media Meta Tags Signals
                              </Link>
                            </p>

                            <p>
                              {urlData?.microDataSchema?.data?.keywordSuccessFlag === true ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="keyword-usage-test"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Keyword Usage Test Signals
                              </Link>
                            </p>
                            <p>
                              {urlData?.headingAnalysis?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.headingAnalysis?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="heading-analysis"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Heading Tag Analysis Signals
                              </Link>
                            </p>

                            <p>
                              {urlData?.robotsTxtTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.robotsTxtTest?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="robots-txt" spy={true} smooth={true} offset={-150} duration={500}>
                                Robots.txt Signal Analysis
                              </Link>
                            </p>

                            <p>
                              {urlData?.siteMapData?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.siteMapData?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="sitemap-data" spy={true} smooth={true} offset={-150} duration={500}>
                                Sitemap Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.seoFriendlyUrlAnalysis?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="seo-friendly" spy={true} smooth={true} offset={-150} duration={500}>
                                SEO Friendly URL Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.imageAlt?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.imageAlt?.data?.testResults?.status === 2 ? (
                                <span style={{ color: palette.warning }}>Warning</span>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="image-alt" spy={true} smooth={true} offset={-150} duration={500}>
                                Image Alt Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.descriptiveLinkTest?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.descriptiveLinkTest?.data?.testResults?.status === 2 ? (
                                <span style={{ color: palette.warning }}>Warning</span>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="descriptive-test"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Descriptive Link Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.responsiveImage?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.responsiveImage?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="responsive-image"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Excessive Image Data Size Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.aspectRatio?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.aspectRatio?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="aspect-ratio" spy={true} smooth={true} offset={-150} duration={500}>
                                Image Aspect Ratio Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.inlineCss?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.inlineCss?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="inline-css" spy={true} smooth={true} offset={-150} duration={500}>
                                Inline CSS Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.htmlValidation?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.htmlValidation?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="html-validation"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Html Validation
                              </Link>
                            </p>

                            <p>
                              {urlData?.googleAnalytics?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.googleAnalytics?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;{' '}
                              <Link
                                to="google-analytics"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Google Analytics Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.faviconCheckerTest?.data?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="favicon-test" spy={true} smooth={true} offset={-150} duration={500}>
                                Favicon Checker Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.brokenLinks?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.brokenLinks?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="brokenLinks-data"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Internal Broken Links Checker Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.jsError?.data?.testResults.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.jsError?.data?.testResults.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="js-error" spy={true} smooth={true} offset={-150} duration={500}>
                                JS Error Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.consoleErrors?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.consoleErrors?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="console-errors" spy={true} smooth={true} offset={-150} duration={500}>
                                Historic Console Error Signal Check
                              </Link>
                            </p>

                            <p>
                              {urlData?.advancedConsoleErrors?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.advancedConsoleErrors?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link
                                to="advanced-console-errors"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Advanced Console Error Signal Check
                              </Link>
                            </p>

                            <p>
                              {urlData?.characterSet?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.characterSet?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="character-set" spy={true} smooth={true} offset={-150} duration={500}>
                                Charset Declaration Signal Test
                              </Link>
                            </p>

                            <p>
                              {urlData?.socialMediaTwitterData?.data?.testResults?.status === 1 ? (
                                <Badge bg={'success'} className="me-1 mb-1">
                                  Passed
                                </Badge>
                              ) : urlData?.socialMediaTwitterData?.data?.testResults?.status === 2 ? (
                                <Badge bg={'warning'} className="me-1 mb-1">
                                  Warning
                                </Badge>
                              ) : (
                                <Badge bg={'danger'} className="me-1 mb-1">
                                  Failed
                                </Badge>
                              )}
                              &nbsp;
                              <Link to="social-media" spy={true} smooth={true} offset={-150} duration={500}>
                                Social Media Signal Test
                              </Link>
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  WEBSITE SITE LOADING SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{speedOptimizeSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{speedOptimizeWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{speedOptimizeFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <MenuTitleTest
                              linkTo={'html-page'}
                              testStatus={urlData?.htmlPageSize?.data?.testResults?.status}
                              title={'HTML Page Size Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'dom-size'}
                              testStatus={urlData?.domSize?.data?.testResults?.status}
                              title={'DOM Size Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'html-compression'}
                              testStatus={urlData?.htmlCompression?.data?.testResults?.status}
                              title={'Web Content Compression Check'}
                            />
                            <MenuTitleTest
                              linkTo={'site-speed'}
                              testStatus={urlData?.siteSpeed?.data?.testResults?.status}
                              title={'Site Loading Speed Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'js-execution-time'}
                              testStatus={urlData?.bootupTime?.data?.testResults?.status}
                              title={'JS Execution Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'page-object'}
                              testStatus={urlData?.pageObject?.data?.testResults?.status}
                              title={'Page Objects Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'page-cache'}
                              testStatus={urlData?.pageCache?.data?.testResults?.status}
                              title={'Page Cache Test (Server Side Caching) Signal Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'flash-test'}
                              testStatus={urlData?.flashTest?.data?.testResults?.status}
                              title={'Flash Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'cdn-usage'}
                              testStatus={urlData?.cdnUsageData?.data?.testResult?.status}
                              title={'CDN Usage Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'morden-image'}
                              testStatus={urlData?.mordenImage?.data?.testResults?.status}
                              title={'Morden Image Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'cache-policy'}
                              testStatus={urlData?.cachePolicy?.data?.testResults?.status}
                              title={'Cache Policy Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'image-cache'}
                              testStatus={urlData?.imageCache?.data?.testResults?.status}
                              title={'Image Cache Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'js-cache'}
                              testStatus={urlData?.jsCache?.data?.testResults?.status}
                              title={'JavaScript Caching Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'css-cache'}
                              testStatus={urlData?.cssCache?.data?.testResults?.status}
                              title={'CSS Caching Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'js-minification'}
                              testStatus={urlData?.jsMinification?.data?.testResults?.status}
                              title={'JavaScript Minification Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'css-minification'}
                              testStatus={urlData?.cssMinification?.data?.testResults?.status}
                              title={'CSS Minification Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'render-blocking'}
                              testStatus={urlData?.renderBlocking?.data?.testResults?.status}
                              title={'Render Blocking Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'nested-table'}
                              testStatus={urlData?.nestedTable?.data?.testResults?.status}
                              title={'Nested Tables Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'frameset-test'}
                              testStatus={urlData?.framesetTest?.data?.testResults?.status}
                              title={'Frameset Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'doc-type'}
                              testStatus={urlData?.docType?.data?.testResults?.status}
                              title={'Doctype Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'redirect-test'}
                              testStatus={urlData?.redirectTest?.data?.testResults?.status}
                              title={'Redirect Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'contentful-paint'}
                              testStatus={urlData?.contentfulPaint?.data?.testResults?.status}
                              title={'Contentful Paint Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'layout-shift'}
                              testStatus={urlData?.layoutShift?.data?.testResults?.status}
                              title={'Cumulative Layout Shift Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'css-analysis'}
                              testStatus={urlData?.cssAnalysis?.data?.testResults?.status}
                              title={'Css Analysis Test'}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  SERVER CONFIGURATION & SECURITY SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{serverSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{serverWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{serverFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <MenuTitleTest
                              linkTo={'url-canonicalization'}
                              testStatus={urlData?.urlCanonicalization?.data?.testResult?.status}
                              title={'URL Canonicalization Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'https-test'}
                              testStatus={urlData?.httpsTest?.data?.testResults?.status}
                              title={'Mixed Content Test (HTTP over HTTPS)'}
                            />
                            <MenuTitleTest
                              linkTo={'http2-test'}
                              testStatus={urlData?.http2Test?.data?.testResults?.status}
                              title={'HTTP2 Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'hsts'}
                              testStatus={urlData?.hstsTest?.data?.testResults?.status}
                              title={'HSTS Signal Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'safe-browsing'}
                              testStatus={urlData?.safeBrowsing?.data?.testResults?.status}
                              title={'Safe Browsing Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'server-signature'}
                              testStatus={urlData?.serverSignature?.data?.testResults?.status}
                              title={'Server Signature Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'directory-browsing'}
                              testStatus={urlData?.directoryBrowsing?.data?.testResults?.status}
                              title={'Directory Browsing Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'plain-text'}
                              testStatus={urlData?.plainTextEmail?.data?.testResults?.status}
                              title={'Plaintext Emails Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'unsafe-cross-origin-links'}
                              testStatus={urlData?.unsafeCrossOriginLinksTest?.data?.testResults?.status}
                              title={'Unsafe Cross-Origin Signal Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'ssl-freak'}
                              testStatus={urlData?.sslFreakTest?.data?.testResults?.status}
                              title={'SSL and HTTPS Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'brotil-test'}
                              testStatus={urlData?.brotilTest?.data?.testResults?.status}
                              title={'Brotli Analysis Test'}
                            />
                            <MenuTitleTest
                              linkTo={'tls-logjam'}
                              testStatus={urlData?.tlsLogjamTest?.data?.testResults?.status}
                              title={'TLS Logjam Signal Test'}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  MOBILE RESPONSIVE & MOBILE SPEED SIGNALS
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{mobileUsabilitySCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{mobileUsabilityWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{mobileUsabilityFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <MenuTitleTest
                              linkTo={'viewport'}
                              testStatus={urlData?.viewport?.data?.testResults?.status}
                              title={'Viewport Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'font-size'}
                              testStatus={urlData?.fontSizeTest?.data?.testResults?.status}
                              title={'Font Size Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'tap-target'}
                              testStatus={urlData?.tapTargetTest?.data?.testResults?.status}
                              title={'Tap Target Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'content-width'}
                              testStatus={urlData?.contentWidthTest?.data?.testResults?.status}
                              title={'Content-width Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'speed-index'}
                              testStatus={urlData?.speedIndexTest?.data?.testResults?.status}
                              title={'Speed Index Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'first-contentful-paint'}
                              testStatus={urlData?.firstContentfulPaintTest?.data?.testResults?.status}
                              title={'First Contentful Paint Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'last-contentful-paint'}
                              testStatus={urlData?.largestContentfulPaintTest?.data?.testResults?.status}
                              title={'Last Contentful Paint Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'cumulative-layout-shift-test'}
                              testStatus={urlData?.cumulativeLayoutShiftTest?.data?.testResults?.status}
                              title={'Cumulative Layout Shift Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'interactive'}
                              testStatus={urlData?.interactiveTest?.data?.testResults?.status}
                              title={'Interactive Signals'}
                            />
                            <MenuTitleTest
                              linkTo={'first-input-delay'}
                              testStatus={urlData?.firstInputDelayTest?.data?.testResults?.status}
                              title={'First Input Delay Signals'}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name" md="4">
                                  ADVANCED SEO SIGNAL TEST
                                </Col>
                                <Col style={{ margin: 'auto' }}>
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={tickIcon} />{' '}
                                    <span style={{ color: palette.success }}>{advanceSeoSCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={warningIcon} />{' '}
                                    <span style={{ color: palette.warning }}>{advanceSeoWCounter}</span>
                                  </span>{' '}
                                  <span className="pass-block">
                                    <img style={{ height: 14 }} src={crossIcon} />{' '}
                                    <span style={{ color: palette.danger }}>{advanceSeoFCounter}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <MenuTitleTest
                              linkTo={'microdata-schema'}
                              testStatus={urlData?.microDataSchema?.data?.microSchemaResults?.status}
                              title={'Microdata Schema Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'custom-404'}
                              testStatus={urlData?.custom404?.data?.testResult?.status}
                              title={'Custom 404 Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'no-index'}
                              testStatus={urlData?.noIndexChecker?.data?.testResult?.status}
                              title={'No Index Checker Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'canonical-url'}
                              testStatus={urlData?.canonicalUrl?.data?.testResult?.status}
                              title={'Canonical URL Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'meta-refresh'}
                              testStatus={urlData?.metaRefresh?.data?.testResult?.status}
                              title={'Meta Refresh Signal Test'}
                            />
                            <MenuTitleTest
                              linkTo={'spf-record'}
                              testStatus={urlData?.spfRecord?.data?.testResult?.status}
                              title={'SPF Record Analysis'}
                            />
                            <MenuTitleTest
                              linkTo={'ads-txt'}
                              testStatus={urlData?.adsTxt?.data?.testResults?.status}
                              title={'Ads.txt Analysis'}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>
                            <Container className="sidebar-row-container">
                              <Row className="f-8">
                                <Col className="report-test-section-name">NON TRANSFORMATIVE SIGNALS</Col>
                              </Row>
                            </Container>
                          </Accordion.Header>
                          <Accordion.Body className="bg-white">
                            <p>
                              &nbsp;
                              <Link to="seo-result" spy={true} smooth={true} offset={-150} duration={500}>
                                Google Search Results Preview - SERP View
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link to="common" spy={true} smooth={true} offset={-150} duration={500}>
                                Keyword Density Signals Check
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link to="keyword-cloud" spy={true} smooth={true} offset={-150} duration={500}>
                                Keyword Cloud Signals - Visual & Count
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link
                                to="related-keywords"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Related Keywords Test Signals
                              </Link>
                            </p>
                            {/* <p>&nbsp;Mobile Screenshot </p> */}
                            <p>
                              &nbsp;
                              <Link to="competitor" spy={true} smooth={true} offset={-150} duration={500}>
                                Competitor Domain Signals
                              </Link>{' '}
                            </p>
                            <p>
                              &nbsp;
                              <Link to="backlinks" spy={true} smooth={true} offset={-150} duration={500}>
                                Backlinks
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link to="dns-security" spy={true} smooth={true} offset={-150} duration={500}>
                                DNS Security
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link
                                to="mobile-screenshot"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Mobile Screenshot Analysis
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link
                                to="desktop-screenshot"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Desktop Screenshot
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link to="no-follow" spy={true} smooth={true} offset={-150} duration={500}>
                                No Follow Signal Test
                              </Link>
                            </p>
                            <p>
                              &nbsp;
                              <Link
                                to="disalllow-directive"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}
                              >
                                Disallow Directive Signal Test
                              </Link>
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )) || <></>}
                  </div>
                ) : null}
              </>
            ) : trialSeoReportLoader ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    minHeight: '80vh',
                  }}
                >
                  <div className="text-center">
                    <Loader />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <h2>Report not found!</h2>
              </div>
            )}
          </div>
        </Row>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              minHeight: '80vh',
            }}
          >
            <div className="text-center">
              <Loader />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}

export default TrialSEOCheckup

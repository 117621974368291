import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const ServerSignatureTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.serverSignatureTest.heading}
      whatIsIT={freeTools.serverSignatureTest.what}
      howToFixBeforeCode={freeTools.serverSignatureTest.howSectionBeforeCode}
      codeSection={freeTools.serverSignatureTest.codeSection}
      howToFixAfterCode={freeTools.serverSignatureTest.howSectionAfterCode}
    />
  )
}

export default ServerSignatureTest

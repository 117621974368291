import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import paths from 'constants/paths'

const VerifyUser = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const { verifySuccess } = useSelector(({ register }) => register)
  const currentUser = useSelector(({ register }) => register.registerData)

  useEffect(() => {
    const storeUserData = async () => {
      const data = await localStorage.getItem('userData')
      if (data && JSON.parse(data)._id && !currentUser._id) {
        dispatch({ type: 'STORE_USER_DATA_START', payload: JSON.parse(data) })
      }
    }
    storeUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleVerifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVerifyToken = async () => {
    if (params?.token) {
      // dispatch(verifyToken(params.token));
      dispatch({ type: 'VERIFY_START', payload: params.token })
    }
  }

  useEffect(() => {
    if (verifySuccess) {
      history(paths.signInPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifySuccess])

  return (
    <React.Fragment>
      <Helmet title="Verify" />
      <></>
    </React.Fragment>
  )
}

export default VerifyUser

import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Card, Col, Nav, Row, Tab, Table } from 'react-bootstrap'
import * as _ from 'lodash'

import { Loader, CommonAlert } from 'components/uiCore'
import { changeActiveLink, getSiteOutageData, getUserData, patchUserDomainsDashboard } from '../../../redux/actions/dashboard'
import { getBrokenLinkList } from '../../../redux/actions/brokenLinks'
import moment from 'moment'
import ModalContainer from '../../../components/modal'
import { getActiveUserData } from 'constants/Utils'
import MainUrlNav from 'components/MainUrlNav'

const BrokenLinks = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedTabNav, setSelectedTabNav] = useState('0')
  const [internalBrokenLinksData, setInternalBrokenLinksData] = useState([])
  const [externalBrokenLinks, setExternalBrokenLinksData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedBrokenLink, setSelectedBrokenLink] = useState('')
  const [currentUrl, setCurrentUrl] = useState('')
  const [loader, setLoader] = useState(true)
  const [showBlockMessage, setShowBlockMessage] = useState(true)

  const usersDomain = useSelector(({ dashboard }) => dashboard?.usersDomains)
  const userData = useSelector(({ dashboard }) => dashboard?.userData)
  const activeSiteOutageData = useSelector(({ dashboard }) => dashboard?.activeSiteOutageData)
  const activeLink = useSelector(({ dashboard }) => dashboard?.activeLink)
  const getBrokenLinkListData = useSelector(({ brokenLinksList }) => brokenLinksList?.getBrokenLinkListData)
  const getBrokenLinkListSuccess = useSelector(
    ({ brokenLinksList }) => brokenLinksList?.getBrokenLinkListSuccess,
  )

  const dispatch = useDispatch()
  const history = useNavigate()
  let siteOutageInterval = useRef(null)

  const { activeSearchData } = getActiveUserData(usersDomain, activeLink, userData)
   
  const navTabsName = [
    { label: 'Internal', key: '0' },
    { label: 'External', key: '1' },
  ]

  let brokenListIntervalIds = []

  useEffect(() => {
    async function fetchMyAPI() {
      const data = await localStorage.getItem('userData')
      dispatch(getUserData())
      if (data && JSON.parse(data)._id) {
        if (!usersDomain?.length) dispatch(patchUserDomainsDashboard())

        siteOutageInterval = setInterval(() => {
          dispatch(getSiteOutageData())
        }, 10000)
      }
    }
    fetchMyAPI()

    patchBrokenLinks()
  }, [])

  useEffect(() => {
    return () => {
      brokenListIntervalIds.map((id) => {
        clearInterval(id)
      })

      clearInterval(siteOutageInterval)
    }
  }, [])

  useEffect(() => {
    if (activeSiteOutageData?.length) {
      const isBlockedData =
        activeSiteOutageData &&
        activeSiteOutageData.length &&
        activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset &&
        activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length &&
        activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset[
          activeSiteOutageData[activeSiteOutageData.length - 1]?.dataset.length - 1
        ]?.values
      const findBlockData = isBlockedData.filter((item) => item.status !== '200') || []

      setShowBlockMessage(findBlockData?.length === isBlockedData?.length || activeSearchData?.webCached)
      if (findBlockData?.length === isBlockedData?.length || activeSearchData?.webCached) {
        setLoader(false)
      }
      clearInterval(siteOutageInterval)
    }
  }, [activeSiteOutageData])

  useEffect(() => {
    if (
      usersDomain &&
      activeLink !== undefined &&
      usersDomain[activeLink] &&
      usersDomain[activeLink].items &&
      usersDomain[activeLink].items.length
    ) {
      setCurrentUrl(usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url)
      if (getBrokenLinkListSuccess && getBrokenLinkListData?.length) {
        const findUrl = getBrokenLinkListData?.find((item) => {
          return item._id === usersDomain?.[activeLink]?._id
        })
        const findMatch = findUrl?.items?.filter((item) => {
          return item.urlDataId === usersDomain?.[activeLink]?.items?.[0].urlDataId
        })
        if (findMatch && findMatch?.length) {
          let brokenLinkData = findMatch?.[0]?.brokenLinks.find((item) => {
            return item?.data?.externalBrokenLinks > 0 || item?.data?.internalBrokenLinks > 0
          })
          let internalBrokenLinksArray = []
          let externalBrokenLinksArray = []
          if (brokenLinkData && brokenLinkData?.data) {
            if (brokenLinkData?.data?.internalBrokenLinkList?.length) {
              brokenLinkData?.data?.internalBrokenLinkList.forEach((data) => {
                if (data) {
                  internalBrokenLinksArray.push({ date: brokenLinkData?.createdAt, internalLink: data })
                }
              })
            }
            if (brokenLinkData?.data?.externalBrokenLinkList?.length) {
              brokenLinkData?.data?.externalBrokenLinkList.forEach((data) => {
                if (data) {
                  externalBrokenLinksArray.push({ date: brokenLinkData?.createdAt, externalLink: data })
                }
              })
            }
          }
          setInternalBrokenLinksData(internalBrokenLinksArray)
          setExternalBrokenLinksData(externalBrokenLinksArray)
        }
        if (findMatch && findMatch.length && activeSiteOutageData?.length) {
          setLoader(false)
        } else {
          setLoader(true)
        }
      }
    }
  }, [getBrokenLinkListData, usersDomain, activeLink, activeSiteOutageData])

  const patchBrokenLinks = async () => {
    const data = await localStorage.getItem('userData')
    if (data && JSON.parse(data)._id) {
      let backlinksIntervalId = setInterval(() => {
        dispatch(getBrokenLinkList({ userId: JSON.parse(data)._id }))
      }, [5000])
      brokenListIntervalIds.push(backlinksIntervalId)
    }
  }

  const openLinkModal = async (link) => {
    setOpenModal(!openModal)
    setSelectedBrokenLink(link)
  }

  return (
    <>
      <Col xs="auto" className="d-none d-sm-block">
        <h3 className='f-bold'>Broken Links</h3>
      </Col>
      {usersDomain && usersDomain.length ? (
        <>
          <Card>
            <Card.Body>
              <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || 0}>
                <Nav
                  variant="tabs"
                  onSelect={(selectedKey) => dispatch(changeActiveLink(parseInt(selectedKey)))}
                >
                  {usersDomain && usersDomain?.length ? (
                    <>
                      {usersDomain?.map((item, index) => {
                        return <MainUrlNav item={item} index={index} key={index+1} />
                      })}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flex: 1,
                          alignSelf: 'center',
                          flexDirection: 'row',
                          minHeight: 180,
                        }}
                      >
                        <div className="text-center">
                          <Loader />
                        </div>
                      </div>
                    </>
                  )}
                </Nav>

                <ModalContainer
                  show={openModal}
                  toggleShow={() => setOpenModal(!openModal)}
                  // onHide={handleClose}
                  // showRegional={openModal}
                  headerTitle={'Pages where this broken link is present:'}
                  message={selectedBrokenLink}
                  titleStyles={{ fontWeight: '400', fontSize: '16px' }}
                  // setKeywordsOpen={setKeywordsOpen}
                >
                  <Table bordered responsive>
                    <thead>
                      <th>#</th>
                      <th>Last checked</th>
                      <th>Referring pages</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{'1'}</td>
                        <td className="view-top-domain">
                          {internalBrokenLinksData[0]?.date
                            ? moment(internalBrokenLinksData[0]?.date, 'YYYY-MM-DDTHH:mm:ss.sssZ').format(
                                'DD MMM, YYYY HH:mm:ss',
                              )
                            : '-'}
                        </td>
                        <td className="view-top-domain">{currentUrl}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ModalContainer>
              </Tab.Container>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <Nav
                  variant="tabs"
                  onSelect={(selectedKey) => {
                    setSelectedTabNav(selectedKey)
                  }}
                >
                  {navTabsName && navTabsName.length ? (
                    <>
                      {navTabsName.map((item) => {
                        return (
                          <>
                            <Nav.Item>
                              <Nav.Link eventKey={item?.key} style={{ width: 200 }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {item?.label}
                              </Nav.Link>
                            </Nav.Item>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </Nav>
                <Tab.Content>
                  {
                    <>
                      {loader ? (
                        <div
                          style={{
                            marginTop: '1rem',
                          }}
                        >
                          <CommonAlert
                            variant={'warning'}
                            key={'broken-internal-loading'}
                            alertText={
                              <div
                                className='alert-for-mobile'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flex: 1,
                                  alignSelf: 'center',
                                  flexDirection: 'row',
                                  flexDirection: 'column',
                                }}
                              >
                                <ul style={{ listStyleType: 'none' }}>
                                  <h4 className='f-bold'>Sitemap and Link Analysis in Progress</h4>
                                  <li>
                                    We are currently scanning your page and evaluating both internal and
                                    external links for any issues. Due to the in-depth nature of this
                                    analysis, it may take up to 20 minutes to complete.
                                  </li>
                                  <li>
                                    Due to the in-depth nature of this analysis, it may take up to 20 minutes
                                    to complete.
                                  </li>
                                </ul>
                                <div style={{ margin: '0px 0px 13px 35px' }}>
                                  <Loader isText={false} />
                                </div>
                              </div>
                            }
                          />
                          <CommonAlert
                            variant={'warning'}
                            key={'broken-internal-loading'}
                            alertText={
                              <div
                                className='alert-for-mobile'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flex: 1,
                                  alignSelf: 'center',
                                  flexDirection: 'row',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <ul>
                                    <h4 className='f-bold'>What's Happening Now:</h4>
                                    <li>Batching URLs for efficient scanning</li>
                                    <li>Asynchronously verifying link status</li>
                                    <li>Applying rate limiting and retries for accurate results</li>
                                  </ul>
                                </div>
                                <div>
                                  <ul>
                                    <h4 className='f-bold'>Next Steps:</h4>
                                    <li>Batching URLs for efficient scanning</li>
                                    <li>
                                      You can continue using other features of the platform during this time.
                                    </li>
                                    <li>We'll notify you as soon as your report is ready for review.</li>
                                  </ul>
                                </div>
                              </div>
                            }
                          />
                        </div>
                      ) : null}
                      <Tab.Pane eventKey="0">
                        <Row className={'mb-3 mt-3'}>
                          <Col>
                            <Card className="archived-report">
                              {!loader && showBlockMessage ? (
                                <CommonAlert
                                  variant={'warning'}
                                  key={'broken-internal'}
                                  alertText={
                                    <div>
                                      <span className='alert-for-mobile'>
                                        This specific test is being blocked, and therefore, we are unable to
                                        detect a broken signal because data access is restricted by your
                                        firewall settings. To resolve this, please{' '}
                                        <Link to="/pages/firewall-options">click here</Link> for instructions
                                        on how to add PMS SEO Site Signals to your firewall's allow list.
                                      </span>
                                    </div>
                                  }
                                />
                              ) : null}
                              {internalBrokenLinksData?.length && !loader && !showBlockMessage ? (
                                <>
                                  <CommonAlert
                                    variant={'warning'}
                                    key={'broken-internal'}
                                    alertText={
                                      <div>
                                        <span className='alert-for-mobile'>
                                          The Internal URLs below were reported as broken links. These URLs
                                          are linked from other pages within the site, and should be fixed or
                                          removed.
                                        </span>
                                      </div>
                                    }
                                  />
                                  {activeSearchData && !showBlockMessage ? (
                                    <Table striped responsive> 
                                      <thead>
                                        <tr>
                                          <th style={{ width: '2%' }}>#</th>
                                          <th style={{ width: '10%' }}>Check Date</th>
                                          <th style={{ width: '20%' }}>Broken Links</th>
                                          <th style={{ width: '6%' }}>Referring pages</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {internalBrokenLinksData &&
                                          internalBrokenLinksData.map((link, index) => {
                                            return (
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {link?.date
                                                    ? moment(link?.date, 'YYYY-MM-DDTHH:mm:ss.sssZ').format(
                                                        'DD MMM, YYYY HH:mm:ss',
                                                      )
                                                    : '-'}
                                                </td>
                                                <td>{link?.internalLink || '-'}</td>
                                                <td className="row-item-underline">
                                                  {' '}
                                                  <a
                                                    onClick={() => {
                                                      openLinkModal(link?.internalLink)
                                                    }}
                                                  >
                                                    View URLs
                                                  </a>{' '}
                                                </td>
                                              </tr>
                                            )
                                          })}
                                      </tbody>
                                    </Table>
                                  ) : null}
                                </>
                              ) : internalBrokenLinksData?.length === 0 && !loader && !showBlockMessage ? (
                                <div className="no-data-text mb-7" style={{fontWeight:'400'}}>No Internal Broken Links found.</div>
                              ) : (
                                <></>
                              )}
                            </Card>
                          </Col>
                        </Row>
                      </Tab.Pane>

                      <Tab.Pane eventKey="1">
                        <Row className={'mb-3 mt-3'}>
                          <Col>
                            <Card className="archived-report">
                              {!loader && showBlockMessage ? (
                                <CommonAlert
                                  variant={'warning'}
                                  key={'broken-internal'}
                                  alertText={
                                    <div>
                                      <span className='alert-for-mobile'>
                                        This specific test is being blocked, and therefore, we are unable to
                                        detect a broken signal because data access is restricted by your
                                        firewall settings. To resolve this, please{' '}
                                        <Link to="/pages/firewall-options">click here</Link> for instructions
                                        on how to add PMS SEO Site Signals to your firewall's allow list.
                                      </span>
                                    </div>
                                  }
                                />
                              ) : null}
                              {!loader && externalBrokenLinks?.length > 0 && !showBlockMessage ? (
                                <>
                                  <CommonAlert
                                    variant={'warning'}
                                    key={'broken-internal'}
                                    alertText={
                                      <div>
                                        <span className='alert-for-mobile'>
                                          The External URLs below (pointing outside the website) were reported
                                          as broken links. These URLs are linked from other pages within the
                                          site, and should be fixed or removed.
                                        </span>
                                      </div>
                                    }
                                  />
                                  {activeSearchData && !showBlockMessage ? (
                                    <Table striped responsive>
                                      <thead>
                                        <tr>
                                          <th style={{ width: '2%' }}>#</th>
                                          <th style={{ width: '10%' }}>Check Date</th>
                                          <th style={{ width: '20%' }}>Broken Links</th>
                                          <th style={{ width: '6%' }}>Referring pages</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {externalBrokenLinks &&
                                          externalBrokenLinks.map((link, index) => {
                                            return (
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {link?.date
                                                    ? moment(link?.date, 'YYYY-MM-DDTHH:mm:ss.sssZ').format(
                                                        'DD MMM, YYYY HH:mm:ss',
                                                      )
                                                    : '-'}
                                                </td>
                                                <td>{link?.externalLink || '-'}</td>
                                                <td className="row-item-underline">
                                                  {' '}
                                                  <a
                                                    onClick={() => {
                                                      openLinkModal(link?.externalLink)
                                                    }}
                                                  >
                                                    View URLs
                                                  </a>{' '}
                                                </td>
                                              </tr>
                                            )
                                          })}
                                      </tbody>
                                    </Table>
                                  ) : null}
                                </>
                              ) : externalBrokenLinks?.length === 0 && !loader && !showBlockMessage ? (
                                <div className="no-data-text mb-7" style={{fontWeight:'400'}}>No External Broken Links found.</div>
                              ) : (
                                <></>
                              )}
                            </Card>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </>
                  }
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              alignSelf: 'center',
              flexDirection: 'row',
              minHeight: 380,
            }}
          >
            <div className="text-center">
              <Loader />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BrokenLinks

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import sdk from './sdk';
import SocialUser from './SocialUser';
import { cleanLocation, omit } from './utils';
import config from './config';

const SocialLogin = (WrappedComponent) => {
  const SocialLoginComponent = (props) => {
    const isStateless = !WrappedComponent.prototype.render;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const loadPromiseRef = useRef();
    const nodeRef = useRef(null);

    const { provider, onLoginSuccess, onLoginFailure, onLogoutSuccess, onLogoutFailure } = props;

    useEffect(() => {
      // Load required SDK
      const sdkInstance = sdk[provider];
      const { appId, redirect, gatekeeper, scope } = props;
      loadPromiseRef.current = sdkInstance.load({ appId, redirect, gatekeeper, scope }).then((token) => {
        if (props.autoCleanUri) {
          cleanLocation();
        }
        if (token) {
          setAccessToken(token);
        }
        setIsLoaded(true);
        if (props.autoLogin || accessToken) {
          if ((provider === 'instagram' || provider === 'github') && !accessToken) {
            sdkInstance.login(appId, redirect).catch(onLoginFailure);
          } else {
            sdkInstance.checkLogin(true).then(
              (res) => onLoginSuccess(res, accessToken),
              onLoginFailure
            );
          }
        }
        return null;
      }, onLoginFailure);
      return () => loadPromiseRef.current.cancel();
    }, []);

    const setInstance = (node) => {
      nodeRef.current = node;
      if (typeof props.getInstance === 'function') {
        props.getInstance(node);
      }
    };

    const login = () => {
      loadPromiseRef.current.then(null, onLoginFailure);
    };

    const logout = () => {
      if (isLoaded && isConnected) {
        sdk[provider].logout().then(onLogoutSuccess, onLogoutFailure);
      } else if (isLoaded && !isConnected) {
        onLoginFailure('User not connected');
      } else {
        onLoginFailure('SDK not loaded');
      }
    };

    const triggerLogout = props.onLogoutFailure || props.onLogoutSuccess ? logout : undefined;

    return (
      <WrappedComponent
        triggerLogin={login}
        triggerLogout={triggerLogout}
        ref={!isStateless ? setInstance : undefined}
        {...omit(props, [
          'appId',
          'scope',
          'autoCleanUri',
          'autoLogin',
          'gatekeeper',
          'getInstance',
          'onLoginFailure',
          'onLoginSuccess',
          'onLogoutFailure',
          'onLogoutSuccess',
          'provider',
          'redirect',
          'ref',
        ])}
      />
    );
  };

  SocialLoginComponent.propTypes = {
    appId: PropTypes.string.isRequired,
    autoCleanUri: PropTypes.bool,
    autoLogin: PropTypes.bool,
    gatekeeper: PropTypes.string,
    getInstance: PropTypes.func,
    onLoginFailure: PropTypes.func,
    onLoginSuccess: PropTypes.func,
    onLogoutFailure: PropTypes.func,
    onLogoutSuccess: PropTypes.func,
    provider: PropTypes.oneOf(config.providers).isRequired,
    redirect: (props, propName, componentName) => {
      if (props.provider === 'instagram' && (!props[propName] || typeof props[propName] !== 'string')) {
        return new Error(`Missing required \`${propName}\` prop of type \`string\` on ${componentName}.`);
      }
    },
    scope: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  };

  return SocialLoginComponent;
};

export default SocialLogin;

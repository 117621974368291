import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const MordenImageTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.mordenImageTest.heading}
      whatIsIT={freeTools.mordenImageTest.what}
      howToFixBeforeCode={freeTools.mordenImageTest.howSectionBeforeCode}
      codeSection={freeTools.mordenImageTest.codeSection}
      howToFixAfterCode={freeTools.mordenImageTest.howSectionAfterCode}
    />
  )
}

export default MordenImageTest

import {
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST,
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_SUCCESS,
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_ERROR,
  WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_CLEAR,
} from 'redux/actionType'

const initialState = {
  isGenerateReportSuccess: false,
  isGenerateReportError: false,
  isGenerateReportStatusSuccess: false,
  isGenerateReportStatusError: false,
  isGenerateReportDownloadPDFSuccess: false,
  isGenerateReportDownloadPDFError: false,
  isReportStartProcessing: false,
}

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_REQUEST:
      return {
        ...state,
        isGenerateReportDownloadPDFSuccess: false,
        isReportStartProcessing: true,
        generateReportDownloadPDFRequestData: action.payload,
      }
    case WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        isGenerateReportDownloadPDFSuccess: true,
        isReportStartProcessing: false,
        generateReportDownloadPDFData: action.payload,
      }
    case WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_ERROR:
      return {
        ...state,
        isGenerateReportDownloadPDFSuccess: false,
        isGenerateReportDownloadPDFError: true,
        isReportStartProcessing: false,
        generateReportDownloadPDFErrorData: action.payload,
      }
    case WHITE_LABEL_GENERATE_REPORT_DOWNLOAD_PDF_CLEAR:
      return {
        ...state,
        isGenerateReportDownloadPDFSuccess: false,
        isGenerateReportDownloadPDFError: false,
        isReportStartProcessing: false,
        generateReportData: '',
        generateReportDownloadPDFData: '',
        generateReportDownloadPDFErrorData: '',
      }

    default:
      return state
  }
}

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const MicrodataSchemaAnalysisTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.microdataSchemaAnalysisTest.heading}
      whatIsIT={freeTools.microdataSchemaAnalysisTest.what}
      howToFixBeforeCode={freeTools.microdataSchemaAnalysisTest.howSectionBeforeCode}
      codeSection={freeTools.microdataSchemaAnalysisTest.codeSection}
    />
  )
}

export default MicrodataSchemaAnalysisTest

import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const CssCacheTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.cssCachingTest.heading}
      whatIsIT={freeTools.cssCachingTest.what}
      howToFixBeforeCode={freeTools.cssCachingTest.howSectionBeforeCode}
    />
  )
}

export default CssCacheTest

import { useCallback } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { debounce } from 'lodash'

import './googleAutoLocation.scss'
import { useDeviceCheck } from 'hooks/deviceHook'

const GoogleAutoLocation = ({ handleLocationSelected, callFrom, customClass }) => {
  const { isMobile } = useDeviceCheck() 
  const allowedCountries = ['US', 'AU', 'CA', 'IN', 'GB', 'NZ', 'ZA']
  const handlePlaceSelect = useCallback(
    debounce((place) => {
      const addressComponents = place.address_components
      let city = ''
      let state = ''
      let country = ''
      let region = ''

      for (let i = 0; i < addressComponents?.length; i++) {
        const component = addressComponents[i]
        if (
          component.types.includes('locality') ||
          component.types.includes('sublocality') ||
          component.types.includes('postal_town') ||
          component.types.includes('neighborhood') ||
          component.types.includes('administrative_area_level_2') ||
          component.types.includes('administrative_area_level_3')
        ) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
          region = component.short_name
        }
      }

      const placeData = {
        city: city || ' ',
        state: state || ' ',
        country: country || ' ',
        lat: place.geometry.location?.lat().toString(),
        long: place.geometry.location?.lng().toString(),
        region,
        callFrom,
        address: place.formatted_address || '',
      }
      handleLocationSelected(placeData)
    }, 1000),
    [handleLocationSelected],
  )

  return (
    <div className="google-location-container" style={{ width: '100%', textAlign: isMobile ? 'center' : 'left' }}>
      {/* need to pass apiKey here */}
      <Autocomplete
        apiKey="AIzaSyB7lo78OsryUyQAnFEO1damWf1K3Jd1Ryg"
        className={customClass}
        onPlaceSelected={handlePlaceSelect}
        style={{width: isMobile ? "85%" : '100%'}}
        options={{
          types: ['geocode'],
          componentRestrictions: { country: allowedCountries },
        }}
        placeholder="Enter Location"
      />
    </div>
  )
}

export default GoogleAutoLocation

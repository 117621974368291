import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button, Dropdown } from 'react-bootstrap'
import CsvDownloader from 'react-csv-downloader'
import { useCSVDownloader } from 'react-papaparse'
import './ModalHOC.scss'

const ModalHOC = ({
  show,
  toggleShow,
  size = 'lg',
  centered = true,
  title,
  bodyClassName = '',
  titleStyles = {},
  children,
  alert = {},
  showDownload = false,
  dataForDownload = [],
  datas = [],
  columns = [],
  fileName = 'myFile',
  alternativeDownload = false,
}) => {
  const { CSVDownloader, Type } = useCSVDownloader()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(!dropdownOpen)

  return (
    <Modal
      show={show}
      toggle={toggleShow}
      onClose={toggleShow}
      onHide={toggleShow}
      size={size}
      centered={centered}
      className="custom-modal-view-report"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton style={titleStyles} className='f-bold'>
        {title}
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      <Modal.Footer>
        <p className="custom-modal-footer"> {alert?.status === 1 ? alert?.message : ''}</p>

        {/* download csv start*/}
        {showDownload ? (
          dataForDownload?.length ? (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <Dropdown.Toggle variant='outline-primary' color="primary" className='d-flex flex-row align-items-center gap-1 dropdown-arrow'>
                <FontAwesomeIcon icon={faFileCsv} />
                Export CSV
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dataForDownload?.map((item) => {
                  return item?.datas?.length ? (
                    !alternativeDownload ? (
                      <CsvDownloader filename={fileName} columns={item.columns} datas={item.datas}>
                        <Dropdown.Item>{item.key}</Dropdown.Item>
                      </CsvDownloader>
                    ) : (
                      <CSVDownloader type={Type.Link} filename={fileName} bom={true} data={item.datas}>
                        <Dropdown.Item>{item.key}</Dropdown.Item>
                      </CSVDownloader>
                    )
                  ) : null
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : alternativeDownload ? (
            <CSVDownloader type={Type.Link} filename={fileName} bom={true} data={datas}>
                <Button variant="outline-primary" outine className="me-1 d-flex flex-row align-items-center gap-1">
                  <FontAwesomeIcon icon={faFileCsv} /> Export CSV
                </Button>
            </CSVDownloader>
          ) : (
            <CsvDownloader filename={fileName} columns={columns} datas={datas}>
                  <Button variant="outline-primary" outine className="me-1 d-flex flex-row align-items-center gap-1">
                    <FontAwesomeIcon icon={faFileCsv} /> Export CSV
                  </Button>
            </CsvDownloader>
          )
        ) : null}

        <Button variant="outline-primary" onClick={toggleShow}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalHOC
import { useEffect, useState } from 'react'
import { Card, Nav, Row, Tab, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import {
  changeActiveLink,
  generateResearch,
  getUserData,
  patchUserDomainsDashboard,
} from 'redux/actions/dashboard/index.js'
import { CLEAR_GENERATE_RESEARCH_DATA } from 'redux/actionType'

import { getActiveUserData, getRefreshToken } from 'constants/Utils'

import MainUrlNav from 'components/MainUrlNav'
import GenerateResearchTable from 'pages/dashboards/GenerateResearchTable.jsx'
import { Loader } from 'components/uiCore'

const GoogleResearch = () => {
  const dispatch = useDispatch()

  const {
    userData,
    activeLink,
    generateResearchData,
    generateResearchLoader,
    usersDomains: usersDomain,
  } = useSelector(({ dashboard }) => dashboard)

  const [selectedNavTab, setSelectedNavTab] = useState(activeLink || 0)

  const { hostName } = getActiveUserData(usersDomain, activeLink, userData)
  const tokenData = getRefreshToken(
    usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url,
    userData,
  )

  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(getUserData())
      dispatch(patchUserDomainsDashboard())
    }
    fetchMyAPI()
  }, [])

  useEffect(() => {
    if (tokenData && tokenData?.refreshToken && Object.keys(tokenData)?.length > 3) {
      if (usersDomain?.length && !generateResearchData?.length) {
        const url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url
        dispatch(generateResearch({ url, startDate: '2024-06-15', endDate: '2024-06-30' }))
      }
    }
  }, [tokenData, usersDomain, generateResearchData, dispatch])

  const researchDataForActiveLink =
    generateResearchData?.length &&
    generateResearchData?.filter((obj) => {
      if (obj?.url?.includes(hostName)) {
        return obj
      }
    })

  const totalClicks = researchDataForActiveLink?.[0]?.result?.reduce((accumulator, subItem) => {
    return accumulator + subItem?.clicks
  }, 0)
  const totalImpression = researchDataForActiveLink?.[0]?.result?.reduce((accumulator, subItem) => {
    return accumulator + subItem?.impressions
  }, 0)
  let averageCTR = researchDataForActiveLink?.[0]?.result?.reduce((accumulator, subItem) => {
    return accumulator + subItem?.ctr
  }, 0)
  averageCTR = averageCTR / researchDataForActiveLink?.[0]?.result?.length

  let averagePosition = researchDataForActiveLink?.[0]?.result?.reduce((accumulator, subItem) => {
    return accumulator + subItem?.position
  }, 0)
  averagePosition = averagePosition / researchDataForActiveLink?.[0]?.result?.length

  const handleChangeActiveLink = (selectedIndex, activeIndex) => {
    if (selectedIndex !== activeIndex) {
      dispatch(changeActiveLink(selectedIndex))
      setSelectedNavTab(parseInt(selectedIndex))
      dispatch({ type: CLEAR_GENERATE_RESEARCH_DATA })
    }
  }

  return (
    <Card className="mb-0">
      <Card.Body>
        {usersDomain?.length ? (
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeLink || selectedNavTab || 0}>
            <Nav
              variant="tabs"
              onSelect={(selectedKey) => handleChangeActiveLink(parseInt(selectedKey), selectedNavTab)}
            >
              {usersDomain && usersDomain?.length ? (
                <>
                  {usersDomain?.map((item, index) => {
                    return <MainUrlNav index={index} item={item} key={`Nav-${index + 1}`} />
                  })}
                </>
              ) : null}
            </Nav>

            <Tab.Content>
              <Row style={{ marginTop: 22 }}>
                {usersDomain && usersDomain?.length ? (
                  <>
                    <Col md="6" xl className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body className=" py-4">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h4 className="card-subtitle text-muted"> Total Clicks </h4>

                              {totalClicks ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div>
                                    <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                      {totalClicks}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                              )}
                            </div>
                            <div className="d-inline-block ms-3" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6" xl className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body className=" py-4">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h4 className="card-subtitle text-muted"> Total Impression </h4>
                              {totalImpression ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div>
                                    <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                      {totalImpression}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                              )}
                            </div>
                            <div className="d-inline-block ms-3" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6" xl className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body className=" py-4">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h4 className="card-subtitle text-muted"> Average CTR </h4>
                              {averageCTR ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div>
                                    <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                      {averageCTR.toFixed(2)}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                              )}
                            </div>
                            <div className="d-inline-block ms-3" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6" xl className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body className=" py-4">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h4 className="card-subtitle text-muted"> Average Position </h4>
                              {averagePosition ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div>
                                    <h3 style={{ marginLeft: 5, marginTop: 5 }} className="mb-2 f-bold">
                                      {averagePosition.toFixed(2)}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                <h3 style={{ marginTop: 5, fontWeight: '400' }}>No Data</h3>
                              )}
                            </div>
                            <div className="d-inline-block ms-3" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                ) : null}
              </Row>
              {!generateResearchLoader ? (
                <Row>
                  <Col md="6" xl className="d-flex">
                    <Card className="flex-fill">
                      <Card.Body className=" py-4">
                        <GenerateResearchTable
                          generateResearchData={generateResearchData}
                          hostName={hostName}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    alignSelf: 'center',
                    flexDirection: 'row',
                    minHeight: 380,
                  }}
                >
                  <div className="text-center">
                    <Loader />
                  </div>
                </div>
              )}
            </Tab.Content>
          </Tab.Container>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              alignSelf: 'center',
              flexDirection: 'row',
              minHeight: 380,
            }}
          >
            <div className="text-center">
              <Loader />
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
export default GoogleResearch
import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const ImageAlt = () => {
  return (
    <CommonFreeTool
      heading={freeTools.imageAltTest.heading}
      whatIsIT={freeTools.imageAltTest.what}
      howToFixBeforeCode={freeTools.imageAltTest.howSectionBeforeCode}
      codeSection={freeTools.imageAltTest.codeSection}
      howToFixAfterCode={freeTools.imageAltTest.howSectionAfterCode}  
    />
  )
}

export default ImageAlt

import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import { Progress, Space } from 'antd'
import * as moment from 'moment'

import 'pages/dashboards/DashboardRevised/urlScanStatus.scss'
import { isMainUrlMatch } from 'constants/Utils'

const SpaceProgress = () => {
  const {
    usersDomains: usersDomain,
    activeLink,
    seoProgressPercentage,
    mainUrlScoreDataArray,
  } = useSelector(({ dashboard }) => dashboard)

  const [performance, setPerformance] = useState(0)
  const [accessbility, setAccessbility] = useState(0)
  const [bestPractices, setBestPractices] = useState(0)
  const [totalSeoScore, setTotalSeoScore] = useState(0)
  const [totalTestCountforMainUrl, setTotalTestCountForMainUrl] = useState(0)

  const item = usersDomain && activeLink !== undefined && usersDomain[activeLink]

  useEffect(() => {
    const filteredData =
      mainUrlScoreDataArray?.length &&
      mainUrlScoreDataArray.filter((item) => {
        let main_url = item?.data[0]?.mainUrl
        let redirect_url = usersDomain?.[activeLink]?.items?.[0]?.information?.seoCheckup?.redirect_url 
        
        const isUrlMatched = isMainUrlMatch(main_url, redirect_url)
        return isUrlMatched
      })
    const result =
      filteredData?.length &&
      filteredData
        .filter((item) => {
          return item.data.some((obj) => {
            const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
            const lastUpdatedDate = moment.utc(
              usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
            return checkDate === lastUpdatedDate && obj.testResult
          })
        })
        .map((item) => {
          return {
            id: item._id,
            data: item.data.filter((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment.utc(
                usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
              return checkDate === lastUpdatedDate && obj.testResult
            }),
          }
        })

    const sum =
      result?.length &&
      result?.[0]?.data.reduce((accumulator, subItem) => {
        return accumulator + subItem.count
      }, 0)
    let scoreOutOf100 = Math.round((sum / 73) * 100)
    setTotalSeoScore(scoreOutOf100)
    const filteredMainUrl =
      filteredData?.length &&
      filteredData
        .filter((item) => {
          return item.data.some((obj) => {
            const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
            const lastUpdatedDate = moment.utc(
              usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
            return checkDate === lastUpdatedDate
          })
        })
        .map((item) => {
          return {
            id: item._id,
            data: item.data.filter((obj) => {
              const checkDate = moment(`${obj?.year}-${obj?.month}-${obj?.day}`, 'YYYY-MM-DD').format('MM-DD')
              const lastUpdatedDate = moment.utc(
                usersDomain?.[activeLink]?.items?.[0]?.createdAt).format('MM-DD')
              return checkDate === lastUpdatedDate
            }),
          }
        })

    const filteredMainUrlTestCount =
      filteredMainUrl?.length &&
      filteredMainUrl?.[0]?.data.reduce((accumulator, subItem) => {
        return accumulator + subItem.count
      }, 0)
    setTotalTestCountForMainUrl(filteredMainUrlTestCount)
  }, [seoProgressPercentage, activeLink])

  useEffect(() => {
    if (item && item?.items && item?.items?.length && item?.items?.[0]?.performance) {
      setPerformance(item?.items?.[0]?.performance)
    }
    if (item && item?.items && item?.items?.length && item?.items?.[0]?.accessbility) {
      if (item?.items?.[0]?.accessbility !== '0') {
        setAccessbility(item?.items?.[0]?.accessbility)
      }
    }
    if (item && item?.items && item?.items?.length && item?.items?.[0]?.bestPractices) {
      if (item?.items?.[0]?.bestPractices !== '0') {
        setBestPractices(item?.items?.[0]?.bestPractices)
      }
    }
    if (item && item?.items && item?.items?.length && item?.items?.[0]?.totalSeoScore) {
    }
  }, [item])

  const getColor = (score) => {
    let webScore = typeof score === 'string' ? parseInt(score) : score
    if (webScore <= 65) {
      return ['#d95350', 'transparent']
    }
    if (webScore >= 66 && webScore <= 75) {
      return ['#e5a54b', 'transparent']
    }
    if (webScore >= 76 && webScore <= 84) {
      return ['#e5a54b', 'transparent']
    }
    if (webScore >= 85 && webScore <= 100) {
      return ['#52c41a', 'transparent']
    }
  }

  return (
    <Col xs="12 mb-4" lg="4" className="dashboard-progress-score">
      <div className="card-container-dashboard">
        <Space wrap style={{ justifyContent: 'center' }}>
          <div className="progress-dash">
            <div>
              {performance > -1 &&
                accessbility > -1 &&
                bestPractices > -1 &&
                totalTestCountforMainUrl >= 63 ? (
                <Progress
                  strokeLinecap={'square'}
                  strokeWidth={18}
                  type="circle"
                  strokeColor={getColor(totalSeoScore)}
                  format={(percent) => `${percent}`}
                  percent={totalSeoScore}
                  size={[500, 500]}
                />
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </div>
            <p className="text-progress-helper my-1"> SEO </p>
          </div>

          <div className="progress-dash">
            <div>
              {performance > -1 &&
                accessbility > -1 &&
                bestPractices > -1 &&
                totalTestCountforMainUrl >= 63 ? (
                <Progress
                  strokeLinecap={'square'}
                  strokeWidth={18}
                  strokeColor={getColor(usersDomain?.[activeLink]?.items?.[0]?.performance)}
                  type="circle"
                  format={(percent) => `${percent}`}
                  percent={Math.round(usersDomain?.[activeLink]?.items?.[0]?.performance)}
                  size={[500, 500]}
                />
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </div>
            <p className="text-progress-helper my-1"> PERFORMANCE </p>
          </div>

          <div className="progress-dash">
            <div>
              {performance > -1 &&
                accessbility > -1 &&
                bestPractices > -1 &&
                totalTestCountforMainUrl >= 63 ? (
                <Progress
                  strokeLinecap={'square'}
                  strokeWidth={18}
                  strokeColor={getColor(usersDomain?.[activeLink]?.items?.[0]?.accessbility)}
                  type="circle"
                  format={(percent) => `${percent}`}
                  percent={Math.round(usersDomain?.[activeLink]?.items?.[0]?.accessbility)}
                  size={[500, 500]}
                />
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </div>
            <p className="text-progress-helper my-1"> ACCESSIBILITY </p>
          </div>

          <div className="progress-dash">
            <div>
              {performance > -1 &&
                accessbility > -1 &&
                bestPractices > -1 &&
                totalTestCountforMainUrl >= 63 ? (
                <Progress
                  strokeLinecap={'square'}
                  strokeWidth={18}
                  strokeColor={getColor(usersDomain?.[activeLink]?.items?.[0]?.bestPractices)}
                  type="circle"
                  format={(percent) => `${percent}`}
                  percent={Math.round(usersDomain?.[activeLink]?.items?.[0]?.bestPractices)}
                  size={[500, 500]}
                />
              ) : (
                <>
                  <Spinner size="sm" color="dark" className="ml-2 mr-2" />
                </>
              )}
            </div>
            <p className="text-progress-helper my-1"> BEST PRACTICES </p>
          </div>
        </Space>
      </div>
    </Col>
  )
}

export default SpaceProgress

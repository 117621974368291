import { freeTools } from 'constants.js'
import CommonFreeTool from 'modules/Free tools/components/CommonFreeTool.jsx'

const NestedTableTest = () => {
  return (
    <CommonFreeTool
      heading={freeTools.nestedTableTest.heading}
      whatIsIT={freeTools.nestedTableTest.what}
      howToFixBeforeCode={freeTools.nestedTableTest.howSectionBeforeCode}
    />
  )
}

export default NestedTableTest
